import React, { Fragment, useRef, useState } from 'react';
import {
	Tab,
	Tabs,
	Badge,
	Box,
	Button,
	ClickAwayListener,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Link,
	List,
	ListItem,
	Switch,
	TextField,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddBadgeForm } from './AddBadgeForm';

import { AddStatusIcon, ErrorIcon } from '../../../../config/svg/formElementIcons';
import { DeleteIcon, RewardsIcon } from '../../../../config/svg/CommonSvg';
import UploadIcon from '../../../../images/upload-img-new.svg';
import { BottomArrowIcon, TickCircleIcon } from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
import { Skeleton } from '@material-ui/lab';
import AvatarEditor from 'react-avatar-editor';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../CommonComponent/NoSearchRecord';

function a11yProps(index: number) {
	return {
		id: `recognize-badges-tabpanel-${index}`,
		'aria-controls': `recognize-badges-tabpanel-${index}`,
	};
}

export const RecognizeTemplateDrawer: React.FC<any> = (props: any) => {
	const {
		showImageEditor,
		image,
		formError,
		setFormError,
		badgeFormData,
		setBadgeFormData,
		setFormEdited,
		editableDetails,
	} = props;
	const { t } = useTranslation();
	const [openAllowPost, setOpenAllowPost] = React.useState<Boolean>(false);
	// const inputFileRef = useRef<HTMLInputElement>(null);
	const preventDefault = (event: any) => event.preventDefault();
	const [recognizeTabSelected, setRecognizeTabSelected] = useState<any>({ activeTab: 0 });
	const handleTabChange = (event: any, newValue: Number) => {
		setRecognizeTabSelected({ activeTab: newValue });
	};
	const postOptionsList = [
		{
			label: 'All',
			value: 'All',
		},
		{
			label: 'Manager',
			value: 'Manager',
		},
	];

	// const handleFileChange = async (e: any) => {
	// 	const file = e.target.files[0];
	// 	e.target.value = '';
	// 	const fSize = file.size;
	// };
	const handleAllowPostDropdown = (e: any, type: string) => {
		setBadgeFormData({
			...badgeFormData,
			IsOnlyManager: type === 'Manager' ? true : false, // !badgeFormData.IsOnlyManager,
		});
		setOpenAllowPost(false);
		setFormEdited(true);
	};
	const handleRatingClickAway = () => {
		setOpenAllowPost(false);
	};
	const openRatingClickAway = () => {
		setOpenAllowPost(true);
	};

	return (
		<Fragment>
			<>
				<Tabs
					className='okr-tabs drawer-tabs'
					value={recognizeTabSelected?.activeTab || 0}
					onChange={handleTabChange}
					aria-label='Admin Tabs'
				>
					<Tab
						label={editableDetails && editableDetails?.recognitionCategoryId ? t('updateBadge') : t('addBadge')}
						value={0}
						{...a11yProps(0)}
					/>
				</Tabs>
				<Box className='drawer-tabs-panel'>
					{recognizeTabSelected?.activeTab === 0 && (
						<>
							<Box className='drawer-add-badge'>
								<form className='drawer-form-fields' noValidate autoComplete='off'>
									{/* <Box className='drawer-input-field'>
										<Box className='role-status-field'>
											<Grid component='label' container alignItems='center' spacing={1}>
												<Grid item>{t('Badge')}</Grid>
												<Grid item>
													<Box>
														<Switch
															id='roleStatus'
															name='isActive'
															//checked={cloneObj.isActive}
															//onChange={handleCloneForm}
															inputProps={{ 'aria-label': 'controlled' }}
														/>
													</Box>
												</Grid>
											</Grid>
										</Box>
									</Box> */}
									<Box className='drawer-input-field'>
										<TextField
											fullWidth
											className='field-col1 helperText-bottom'
											label={
												<Fragment>
													<RewardsIcon />
													{t('Badge Name')}
												</Fragment>
											}
											placeholder={t('Enter Name')}
											name='formName'
											value={badgeFormData.Name}
											disabled={editableDetails && editableDetails?.isDefault ? true : false}
											onChange={(e: any) => {
												setBadgeFormData({
													...badgeFormData,
													Name: e.target.value,
												});
												setFormError({
													...formError,
													Name: '',
												});
												setFormEdited(true);
											}}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{ maxLength: 19 }}
											InputProps={{ 'aria-label': t('Enter Name'), disableUnderline: true }}
											//error={ ''}
											error={(formError && formError.Name) || ''}
											helperText={
												formError &&
												formError.Name && (
													<>
														<ErrorIcon /> {formError.Name || ''}
													</>
												)
											}
										/>
										<Box className='badge-name-note'>
											<Typography variant='body1' className='short-des-text'>
												{t('Max length: 19 characters')}
											</Typography>
										</Box>
									</Box>
									<Box className='drawer-input-field recognize-badge-upload'>
										{/* <input ref={inputFileRef} type='file' style={{ display: 'none' }} onChange={handleFileChange} /> */}
										<Box className='bulk-user-content'>
											{editableDetails && editableDetails?.isDefault ? (
												<Box className='upload-file helperText-bottom' style={{ cursor: 'default' }}>
													<Box className='upload-file-inner'>
														{editableDetails.filePath ? (
															<img
																src={editableDetails.filePath}
																alt={badgeFormData?.Name || ''}
																width={150}
																height={150}
															/>
														) : (
															<></>
														)}
													</Box>
												</Box>
											) : (
												<Box className='upload-file helperText-bottom' onClick={() => showImageEditor(true)}>
													<Box className='upload-file-inner'>
														{!image ? (
															<Box className='recognize-badge-box'>
																<Box className='recognize-badge-icon'>
																	<Skeleton animation={false} variant='circle' width={100} height={100}>
																		<Badge
																			anchorOrigin={{
																				vertical: 'bottom',
																				horizontal: 'right',
																			}}
																			className={'badge-anchor-bottom'}
																		></Badge>
																	</Skeleton>
																</Box>
																<Skeleton animation={false} width='60%'>
																	<Typography>.</Typography>
																</Skeleton>
																<Skeleton animation={false} width='40%'>
																	<Typography>.</Typography>
																</Skeleton>
															</Box>
														) : (
															<Box className='recognize-badge-box recognize-uploaded-image'>
																<AvatarEditor
																	image={image ? image : 'https://dummyimage.com/600x400/000/fff'}
																	width={150}
																	height={150}
																	border={0}
																	borderRadius={150}
																	rotate={0}
																	disableBoundaryChecks={false}
																	// @ts-expect-error
																	disableHiDPIScaling={false}
																	id='team-logo'
																	className={'upload-canvas'}
																/>
															</Box>
														)}

														{!image && (
															<Typography variant='h4'>
																<Link id={'browse-btn'} href='#' onClick={preventDefault}>
																	{t('UsersBulkUploadBrowseFile')}
																</Link>
															</Typography>
														)}
													</Box>
													{formError.image && (
														<Typography className='error-field'>
															<ErrorIcon />
															{formError.image}
														</Typography>
													)}
												</Box>
											)}
											<Box className='note-box'>
												<Typography variant='body1' className='short-des-text'>
													<strong>{t('RoleEditNote')}:</strong> {t('fileType4OrgLogo')}
												</Typography>
											</Box>
										</Box>
									</Box>
									<Box className='drawer-input-field'>
										<Box className='field-col1'>
											<FormLabel>
												<TickCircleIcon />
												{t('Allow post by')}
											</FormLabel>
											<Box className={`custom-dropdown custom-type-dropdown-top ${openAllowPost ? 'active' : ''}`}>
												<OKRButton
													className={'dropdown-button button-placeholder'}
													text={!badgeFormData.IsOnlyManager ? 'All' : 'Manager'}
													icon={<BottomArrowIcon />}
													handleClick={() => openRatingClickAway()}
												/>
												{openAllowPost && (
													<ClickAwayListener onClickAway={handleRatingClickAway}>
														<Box className='ques-type-dropdown'>
															<List component='div' disablePadding>
																{postOptionsList && postOptionsList.length ? (
																	postOptionsList.map((item: any) => (
																		<ListItem
																			component='div'
																			onClick={(e: any) => handleAllowPostDropdown(e, item?.value)}
																		>
																			<Typography>{item?.label}</Typography>
																		</ListItem>
																	))
																) : (
																	<></>
																)}
																{/* <ListItem component='div' onClick={(e: any) => handleAllowPostDropdown(e, 'All')}>
																	<Typography>{'All'}</Typography>
																</ListItem>
																<ListItem component='div' onClick={(e: any) => handleAllowPostDropdown(e, 'Manaer')}>
																	<Typography>{'Manager'}</Typography>
																</ListItem> */}
															</List>
														</Box>
													</ClickAwayListener>
												)}
											</Box>
										</Box>
									</Box>
									<Box className='drawer-input-field'>
										<Box className='role-status-field'>
											<FormControlLabel
												control={
													<Switch
														id='roleStatus'
														name='isActive'
														checked={badgeFormData.IsActive}
														onChange={(e: any) => {
															setBadgeFormData({
																...badgeFormData,
																IsActive: e.target.checked,
															});
															setFormEdited(true);
														}}
														inputProps={{ 'aria-label': 'controlled' }}
													/>
												}
												label={t('statusLabel')}
												className='toggle-label '
											/>
										</Box>
									</Box>
								</form>
							</Box>
						</>
					)}
				</Box>
			</>
		</Fragment>
	);
};
