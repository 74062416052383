import React, { useState } from 'react';
import { Box, FormControlLabel, List, ListItem, Switch, Tab, Tabs, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../../../../styles/pages/admin/settings/addPerformReview.scss';
import {
	ChangeTemplateIcon,
	CoreValuesIcon,
	PerformReviewRolesIcon,
	SortingIcon,
	TemplateIcon,
} from '../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../Common/OKRButton';
import { Visibility } from '../../../../../config/svg/ProfileSvg';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompetencyRatingSettingTabValue, userVersionUpdateCompleted } from '../../../../../action/adminSettings';
import { Enums } from '../../../../../config/enums';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import _ from 'lodash';

// export const AssessmentFormSettings: React.FC<any> = (props: any) => {
// 	const { children, value, index, ...other } = props;
// 	return (
// 		<Box
// 			role='tabpanel'
// 			hidden={value !== index}
// 			id={`competency-tab-${index}`}
// 			aria-labelledby={`competency-tab-${index}`}
// 			{...other}
// 		>
// 			{value === index && <Box p={0}>{children}</Box>}
// 		</Box>
// 	);
// };

// CompetencyTabPanel.propTypes = {
// 	children: PropTypes.node,
// 	index: PropTypes.any.isRequired,
// 	value: PropTypes.any.isRequired,
// };

// function a11yProps(index: number) {
// 	return {
// 		id: `competency-tabpanel-${index}`,
// 		'aria-controls': `competency-tabpanel-${index}`,
// 	};
// }

export const AssessmentFormRoleSettings: React.FC<any> = (props: any) => {
	const {
		performanceReviewRoleList,
		assessmentFormSetting,
		performanceFormData,
		setToggleCoreClick,
		toggleCoreClick,
		setAssessmentFormSetting,
		setAssessmentError,
		assessmentError,
		setIsPerformanceFormEdited,
		editableDetails,
	} = props;
	const { t } = useTranslation();
	const { setOpenAssessmentSettingForm } = props;
	const coreRecord: any = { performanceRoleId: 0 };
	const handleClick = (record: any, type: number) => {
		setOpenAssessmentSettingForm({ open: true, data: record, type });
	};
	const [sortOrder, setSortOrder] = useState<any>('asc');
	const dispatch = useDispatch();
	const handleSortRoles = () => {
		let performaceRoleCopy = Object.assign({}, performanceReviewRoleList);
		performaceRoleCopy.entityList = _.orderBy(
			performaceRoleCopy.entityList,
			[(role: any) => role.performanceRoleName.toLowerCase()],
			sortOrder
		);
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		dispatch(userVersionUpdateCompleted(performaceRoleCopy));
	};
	const getAssementFormLabel = (record: any, type: number) => {
		if (
			assessmentFormSetting &&
			assessmentFormSetting[record.performanceRoleId] &&
			assessmentFormSetting[record.performanceRoleId][type]
		) {
			return (
				<>
					<OKRButton
						className='btn-link'
						text={assessmentFormSetting[record.performanceRoleId][type].ratingFormName}
						handleClick={() => {
							handleClick(record, type);
						}}
						disabled={editableDetails?.status === 2 || editableDetails?.status === 3}
					/>
				</>
			);
		}
		return (
			<>
				<OKRButton
					className='btn-link'
					icon={<TemplateIcon />}
					text={
						<>
							{t('selectedRatingForm')}
							<sup>*</sup>
						</>
					}
					handleClick={() => {
						handleClick(record, type);
					}}
					//disabled={editableDetails.status === 2}
				/>
			</>
		);
	};
	const isSelfRatingOn = () => {
		return performanceFormData.isSelfRatingPercentage;
	};
	const isThreeSixtyOn = () => {
		return performanceFormData.isThreeSixtyEnabled;
	};
	const HandleCoreToggleChange = (e: any) => {
		setIsPerformanceFormEdited(true);

		setAssessmentFormSetting({});
		setToggleCoreClick(e.target.checked);
	};
	const hasError = (index: any) => {
		return assessmentError && assessmentError.errorOn && assessmentError.errorOn[index];
	};
	const hasFormError = (index: any, columnIndex: any) => {
		return (
			assessmentError &&
			assessmentError.errorOn &&
			assessmentError.errorOn[index] &&
			assessmentError.errorOn[index].indexOf(columnIndex) !== -1
		);
	};
	return (
		<>
			<Box className='assessment-form-role-settings' id='assessment-form-role-settings-sec'>
				<Box className='perform-score-switch'>
					<FormControlLabel
						control={
							<Switch
								name='isVisibleIfEachCompleted'
								checked={toggleCoreClick}
								onChange={HandleCoreToggleChange}
								disabled={editableDetails?.status === 2 || editableDetails?.status === 3}
							/>
						}
						label={t('isAssessmentReqMssg')}
					/>
				</Box>
				<Box className='okr-list-panel-head'>
					<List component='ul'>
						<ListItem className='assessment-form-role-col1'>
							<Typography
								variant='body2'
								className={`sort-title ${sortOrder === 'asc' ? 'desc' : 'asc'}`}
								id={'assessment-form-role-name'}
								onClick={handleSortRoles}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('roleLabel')}</span> <SortingIcon />
								</span>
							</Typography>
						</ListItem>
						{isSelfRatingOn() && (
							<ListItem className='assessment-form-role-col2'>
								<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-self'}>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('selfAssessmentForm')}</span>
									</span>
								</Typography>
							</ListItem>
						)}
						<ListItem className='assessment-form-role-col3'>
							<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-manager'}>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('managerAssessmentForm')}</span>
								</span>
							</Typography>
						</ListItem>
						{isThreeSixtyOn() && (
							<ListItem className='assessment-form-role-col4'>
								<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-360'}>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('threeSixtyAssessmentForm')}</span>
									</span>
								</Typography>
							</ListItem>
						)}
					</List>
				</Box>
				<Box className='assessment-form-role-listing'>
					{toggleCoreClick ? (
						<Box className={`assessment-form-role-item ${hasError(0) ? 'error-row' : 'no-error-class'}`}>
							{/*error-row class for error */}
							<List disablePadding>
								<ListItem className='assessment-form-role-col1'>
									<Box>
										<Typography>{'All Employees'}</Typography>
										<Typography variant='h6'>{'All Employees included in performance cycle'}</Typography>
									</Box>
								</ListItem>
								{isSelfRatingOn() && (
									<ListItem className='assessment-form-role-col2'>
										{/* 								error class name error-btn  helperText-bottom
										 */}{' '}
										<Box className={hasFormError(0, 1) ? 'error-btn  helperText-bottom' : ''}>
											{getAssementFormLabel(coreRecord, 1)}
											{hasError(0) ? (
												<Typography className='error-field'>
													<ErrorIcon /> {t('addAssessmentForm')}
												</Typography>
											) : (
												''
											)}
										</Box>
									</ListItem>
								)}
								<ListItem className='assessment-form-role-col3'>
									<Box className={hasFormError(0, 2) ? 'error-btn  helperText-bottom' : ''}>
										{getAssementFormLabel(coreRecord, 2)}
									</Box>
								</ListItem>
								{isThreeSixtyOn() && (
									<ListItem className='assessment-form-role-col4'>
										<Box className={hasFormError(0, 3) ? 'error-btn  helperText-bottom' : ''}>
											{getAssementFormLabel(coreRecord, 3)}
										</Box>
									</ListItem>
								)}
							</List>
						</Box>
					) : (
						performanceReviewRoleList?.entityList.map((record: any) => (
							<Box
								className={`assessment-form-role-item ${
									hasError(record.performanceRoleId) ? 'error-row' : 'no-error-class'
								}`}
							>
								{/*error-row class for error */}
								<List disablePadding>
									<ListItem className='assessment-form-role-col1'>
										<Box>
											<Typography>{record.performanceRoleName}</Typography>
											<Typography variant='h6'># of Assesses: {record.employeeCount}</Typography>
										</Box>
									</ListItem>
									{isSelfRatingOn() && (
										<ListItem className='assessment-form-role-col2'>
											{/* 								error class name error-btn  helperText-bottom
											 */}{' '}
											<Box className={hasFormError(record.performanceRoleId, 1) ? 'error-btn  helperText-bottom' : ''}>
												{getAssementFormLabel(record, 1)}
												{hasFormError(record.performanceRoleId, 1) ? (
													<Typography className='error-field'>
														<ErrorIcon /> {t('addAssessmentForm')}
													</Typography>
												) : (
													''
												)}
											</Box>
										</ListItem>
									)}
									<ListItem className='assessment-form-role-col3'>
										<Box className={hasFormError(record.performanceRoleId, 2) ? 'error-btn  helperText-bottom' : ''}>
											{getAssementFormLabel(record, 2)}
											{hasFormError(record.performanceRoleId, 2) ? (
												<Typography className='error-field'>
													<ErrorIcon /> {t('addAssessmentForm')}
												</Typography>
											) : (
												''
											)}
										</Box>
									</ListItem>
									{isThreeSixtyOn() && (
										<ListItem className='assessment-form-role-col4'>
											<Box className={hasFormError(record.performanceRoleId, 3) ? 'error-btn  helperText-bottom' : ''}>
												{getAssementFormLabel(record, 3)}
												{hasFormError(record.performanceRoleId, 3) ? (
													<Typography className='error-field'>
														<ErrorIcon /> {t('addAssessmentForm')}
													</Typography>
												) : (
													''
												)}
											</Box>
										</ListItem>
									)}
								</List>
							</Box>
						))
					)}
				</Box>
			</Box>
		</>
	);
};
