import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	notesList: {
		entityList: [],
		pagingInfo: {},
	},
	getNotesStatus: '',
	conversationList: {
		entityList: [],
		pagingInfo: {},
	},
	getConversationStatus: '',
};

export default function cfrReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FETCH_GET_NOTES_PENDING:
			return {
				...state,
				notesList: {
					entityList: [],
					pagingInfo: {},
				},
				getNotesStatus: 'pending',
			};
		case actionTypes.FETCH_GET_NOTES_SUCCESS:
			return {
				...state,
				notesList: {
					entityList: action.payload.entityList,
					pagingInfo: action.payload.pagingInfo,
				},
				getNotesStatus: 'success',
			};
		case actionTypes.FETCH_GET_NOTES_FAILED:
			return {
				...state,
				notesList: action.error,
				getNotesStatus: 'failed',
			};

		case actionTypes.FETCH_CONVERSATION_PENDING:
			return {
				...state,
				conversationList: {
					entityList: [],
					pagingInfo: {},
				},
				getConversationStatus: 'pending',
			};
		case actionTypes.FETCH_CONVERSATION_SUCCESS:
			return {
				...state,
				conversationList: {
					entityList: action.payload.entityList,
					pagingInfo: action.payload.pagingInfo,
				},
				getConversationStatus: 'success',
			};
		case actionTypes.FETCH_CONVERSATION_FAILED:
			return {
				...state,
				conversationList: action.error,
				getConversationStatus: 'failed',
			};
		case actionTypes.RESET_CONVERSATION:
			return {
				...state,
				conversationList: {
					entityList: [],
					pagingInfo: {},
				},
				getConversationStatus: '',
			};
		case actionTypes.RESET_NOTES:
			return {
				...state,
				notesList: {
					entityList: [],
					pagingInfo: {},
				},
				getNotesStatus: '',
			};
		default:
			return state;
	}
}
