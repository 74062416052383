import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTemplateList, getTemplateColumnListByTemplateId, addColumnInTemplate, addNewTemplate } from '../../action/letterGeneration';
import { DefaultOrigin } from '../../config/constant';
import { Enums } from '../../config/enums';

import { Box, Drawer, Button, Select, MenuItem, Typography, TextField, FormLabel, Table, TableCell, TableRow, TableBody, TableContainer, TableHead } from '@material-ui/core';

import { AddIconSvg, DummyIcon } from '../../config/svg/CommonSvg';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import { SortingIcon } from '../../config/svg/CommonSvgIcon';
import { OKRButton } from '../Common/OKRButton';

import { useTranslation } from 'react-i18next';
import LinearLoader from '../Common/Loader';
import { getLocalStorageItem } from '../../services/StorageService';

type Anchor = 'right';

export default function LetterColumn() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    interface RowData {
        templateId: string;
        templateColumnName: string;
        placeHolder: string;
    }

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [openFilter, setOpenFilter] = React.useState(null);
    const [loader, setLoader] = useState<boolean>(false);

    const open = Boolean(openFilter);
    const id = open ? 'simple-popper' : undefined;

    const toggleDrawer = (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setState({ ...state, [anchor]: open });
            isShowTemplate(false);
            setNewTemplate(0);
            setRows([]);
        };

    const [showTemplate, isShowTemplate] = useState<boolean>(false);
    const [templates, setTemplates] = useState<{ id: string, name: string, placeHolder: string }[]>([]);
    const [rows, setRows] = useState<RowData[]>([]);
    const [newTemplate, setNewTemplate] = useState<any>(0);
    const [newColumn, setNewColumn] = useState<any>('');
    const [isSystemUser, setIsSystemUser] = useState<boolean>(false);

    useEffect(() => {
        addTemplatesInList();
        setIsSystemUser(JSON.parse(getLocalStorageItem('userDetail') || '').isSystemUser);
    }, [dispatch]);

    useEffect(() => {
        if (newTemplate && newTemplate !== 'Select Template') {
            const selectedTemplate = templates.find(template => template.id === newTemplate);
            if (selectedTemplate) {
                placeTemplateColumnList(selectedTemplate);
            }
        }
    }, [newTemplate, templates, dispatch]);

    const placeTemplateColumnList = async (template: { id: string; name: string; placeHolder: string; }) => {
        try {
            setLoader(true);
            let responseTemplateColumnList: any = {};
            let data = {
                templateId: template.id,
                subdomain: window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin,
            };
            responseTemplateColumnList = await dispatch(getTemplateColumnListByTemplateId(data));
            if (responseTemplateColumnList.status === Enums.STATUS_SUCCESS) {
                const matchedTemplate = responseTemplateColumnList.data.entityList.find((item: any) => item.templateId === template.id);
                if (matchedTemplate) {
                    setRows(responseTemplateColumnList.data.entityList);
                } else {
                    setRows([]);
                }
                setLoader(false);
            }
        } catch (error) {
            console.error('Failed to fetch data for template ID:', error);
        }
    };

    const addTemplatesInList = async () => {
        setLoader(true);
        let response: any = {};
        try {
            let data = {
                subdomain: window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin,
            };
            response = await dispatch(getTemplateList(data));
            if (response.status === Enums.STATUS_SUCCESS) {
                const loadedTemplates = response.data.entityList.map((element: any) => ({
                    id: element.templateId,
                    name: element.templateName
                }));
                setTemplates(prevTemplates => [...prevTemplates, ...loadedTemplates]);
                setLoader(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const showAddTemplate = () => {
        setNewTemplate("");
        isShowTemplate(true);
        setRows([]);
    }

    const handleAddTemplateOrColumn = async () => {
        try {
            setLoader(true);
            if (showTemplate && newTemplate.trim() !== "") {
                let response: any = {};
                const data = { templateName: newTemplate };
                if (response.status === Enums.STATUS_SUCCESS) {
                    addTemplatesInList();
                    setLoader(false);
                }
            } else if (newTemplate !== '0' && newTemplate !== '' && newColumn.trim() !== '') {
                let response: any = {};
                const data = { templateId: newTemplate, templateColumnName: newColumn, placeHolder: newColumn }
                response = await dispatch(addColumnInTemplate(data));
                if (response.status === Enums.STATUS_SUCCESS) {
                    placeTemplateColumnList({ id: newTemplate, name: templates.find(template => template.id === newTemplate)?.name || '', placeHolder: templates.find(template => template.id === newTemplate)?.name || '' });
                    setLoader(false);
                }
            }
        } catch (error) {
            console.error('Error in handleAddTemplate:', error);
        } finally {
            setNewTemplate('0');
            setNewColumn('');
        }
    };

    const handleNewTemplateChange = (e: any) => {
        setNewTemplate(e.target.value);
    };

    return (
        <>

            {loader && (
                <Box className='loader-wrap'>
                    <LinearLoader data={true} />
                </Box>
            )}
            <Box>
                {(['right'] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                        {isSystemUser && (<Button
                            className={open ? 'btn-circle-white active' : 'btn-circle-white'}
                            color='primary'
                            onClick={toggleDrawer(anchor, true)}
                            id={'quick-link-add'}
                        >
                            <DummyIcon />
                        </Button>)}
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            className='main-drawer-panel main-drawer-titlepanel letter-drawer'
                        >
                            <Button className='drawer-close-btn' id='drawer-close-btn' onClick={toggleDrawer(anchor, false)}>
                                <RightArrowIcon />
                            </Button>
                            <Box className='drawer-area'>
                                <Box className='drawer-head'>
                                    <Box className='drawer-head-left'>
                                        <Typography variant='h3'>{t('addTemplate')}</Typography>
                                    </Box>
                                </Box>
                                <Box className='drawer-content'>
                                    <Box className='drawer-inner-content'>
                                        <Box className='okr-drawer-form-area drawer-content-panel letter-column-content'>

                                            <form className='drawer-form-fields' noValidate autoComplete='off'>
                                                <Box className='drawer-input-field select-template-content'>

                                                    {!showTemplate &&
                                                        <Box className='select-template-field'>
                                                            <Box className='helperText-bottom'>
                                                                <FormLabel>
                                                                    Select Template <sup> *</sup>
                                                                </FormLabel>

                                                                <Select
                                                                    className='select_field'
                                                                    style={{ width: '100%' }}
                                                                    value={newTemplate}
                                                                    onChange={(e) => setNewTemplate(e.target.value)}
                                                                >
                                                                    <MenuItem disabled value="0">
                                                                        Select Template
                                                                    </MenuItem>
                                                                    {templates.map((template) => (
                                                                        <MenuItem key={template.id} value={template.id}>
                                                                            {template.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Box>
                                                        </Box>
                                                    }

                                                    {showTemplate &&
                                                        <Box className='new-template-field'>
                                                            <Box className='helperText-bottom'>
                                                                <FormLabel>
                                                                    New Template <sup> *</sup>
                                                                </FormLabel>
                                                                <TextField
                                                                    style={{ width: '100%' }}
                                                                    className='feedback-message helperText-bottom'
                                                                    value={newTemplate}
                                                                    onChange={handleNewTemplateChange}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    }

                                                    <OKRButton
                                                        icon={<AddIconSvg />}
                                                        title={t('addTemplate')}
                                                        className='add-btn2'
                                                        handleClick={() => showAddTemplate()}
                                                    />
                                                </Box>

                                                <Box className='drawer-input-field'>
                                                    <Box className='helperText-bottom'>
                                                        <FormLabel>
                                                            New Column <sup> *</sup>
                                                        </FormLabel>
                                                        <TextField
                                                            value={newColumn}
                                                            onChange={(e) => setNewColumn(e.target.value)}
                                                            style={{ width: '100%' }}
                                                            className='feedback-message helperText-bottom' />
                                                    </Box>
                                                </Box>
                                            </form>

                                            {rows && <Box className='drawer-blue-bg-full'>
                                                <TableContainer className='team-assessment-listing my-team-assessment-listing letter-column-listing'>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Box className='cell-wrap'>
                                                                        <Typography
                                                                            variant='body2'
                                                                            id={'sort-name'}
                                                                            className='sort-title'
                                                                        >
                                                                            <span className='sortable-label'>
                                                                                <span className='txt-wrap'>{t('existColumn')}</span> <SortingIcon />
                                                                            </span>
                                                                        </Typography>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((row, index) => (
                                                                <TableRow key={index} className='team-assessment-row'>
                                                                    <TableCell><Box className='cell-wrap'>{row.templateColumnName}</Box></TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>}

                                        </Box>
                                    </Box>
                                </Box>

                                <Box className='drawer-footer-actions'>
                                    <Box>
                                        <Button
                                            className='cancel-btn btn-link'
                                            id='profile-cancel-btn'
                                            color='primary'
                                            onClick={toggleDrawer(anchor, false)}
                                        >
                                            {t('cancelBtn')}
                                        </Button>
                                    </Box>
                                    {((newTemplate !== '0' && newTemplate !== '' && newColumn !== '') || (showTemplate && newTemplate.trim() !== '')) && (<Box>
                                        <Button
                                            className='submitBtn btn-primary'
                                            id='profile-submit-btn'
                                            color='primary'
                                            onClick={handleAddTemplateOrColumn}
                                        >
                                            {t('Add')}
                                        </Button>
                                    </Box>)}
                                </Box>
                            </Box>
                        </Drawer>
                    </React.Fragment>
                ))}
            </Box>
        </>
    );
}