import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import English from './locales/en/translation.json';
import Russian from './locales/ru/translation.json';
import { getLocalStorageItem } from '../services/StorageService';

const resources = {
	en: {
		translation: English,
	},
	ru: {
		translation: Russian,
	},
};

i18n.use(initReactI18next).init({
	lng: getLocalStorageItem('language') ? getLocalStorageItem('language') : 'en',
	fallbackLng: 'en',
	nsSeparator: false,
	keySeparator: false,
	// TODO: Break this out into a provider
	// debug: process.env.NODE_ENV !== 'production',
	interpolation: {
		escapeValue: false,
	},
	resources,
});

export default i18n;
