import { Box, ClickAwayListener, Popper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { OKRButton } from '../Common/OKRButton';
import { ReportsTooltipIcon } from '../../config/svg/CommonSvgIcon';

export const HeaderTooltip: React.FC<any> = (props) => {
	const { infoTooltipClass, tooltipText, btnId, tooltipPlacement } = props;
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};

	return (
		<>
			<OKRButton className='info-icon-btn' id={btnId} icon={<ReportsTooltipIcon />} handleClick={handleOpen} />
			<Popper
				className={`role-description-popup report-header-tooltip ${infoTooltipClass ? infoTooltipClass : ''}`}
				open={open}
				anchorEl={anchorEl}
				transition
				placement={tooltipPlacement ? tooltipPlacement : 'bottom-start'}
			>
				<ClickAwayListener onClickAway={handleClose}>
					<Box className='textarea-expand'>
						<Typography>{tooltipText}</Typography>
					</Box>
				</ClickAwayListener>
			</Popper>
		</>
	);
};
