import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { NoListFound } from './NoListFound';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import WelcomeImage from '../../images/my-goal-bg.svg';
import { OkrListingHead } from './OkrListing/OkrListHead';
import { TeamsOkrList } from '../Goals/TeamsOkr/TeamsOkrList';
import '../Dashboard/style.scss';
import { ContributorsListPopover } from '../Common/ContributorsListPopover';
import _ from 'lodash';
import { TeamsDashboard } from './TeamsOkr/TeamDashboard';
import { CreateObjective } from '../OkrAction/CreateAlignOkr/CreateObjective';
import { getLocalStorageItem } from '../../services/StorageService';
import noRecordFound from '../../images/search-placeholder.svg';
import { HOME } from '../../config/app-url';
import { CreateAlignOkr } from '../OkrAction/CreateAlignOkr';
import { checkPermission } from '../../config/rolePermission';
import { createOkrs } from '../Admin/AdminConstant';

export const OkrCardDetails: React.FC<any> = (props) => {
	const {
		teamsOKRDetails,
		setTeamsOKRDetails,
		teamsOKR,
		t,
		currentUser,
		handleMenuAction,
		showAddGoal,
		getMyTeamOKR,
		fetchOkr,
		goalDetail,
		cardDashboard,
		getFilter,
		myGoalList,
		setOpenImportDrawer,
		showApiMsgs,
		setLoader,
		handleOpenImportDrawer,
		openImportDrawer,
		showAddGoalFormAndUpdateList,
		setTabValue,
		tabValue,
		year,
		getPermissionResult,
	} = props;

	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const contributorDetails = teamsOKRDetails.contributorsLastSevenDaysProgress;
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [isExpandAll, setIsExpandAll] = useState<boolean>(false);
	const [popupView, setPopupView] = useState<number>(0);
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const [prevCycleMsg, setPrevCycleMsg] = useState<boolean>(false);
	useEffect(() => {
		if (currentCycleId === cycleId) {
			setPrevCycleMsg(false);
		} else if (currentCycleId !== cycleId) {
			setPrevCycleMsg(true);
		}
	}, [currentCycleId, cycleId]);
	const expandCollapseAll = (type: number) => {
		if (type === 1) {
			setIsExpandAll(!isExpandAll);
			const updatedList = teamsOKRDetails?.myGoalOkrResponses.map((item: any) => {
				if (isExpandAll) {
					item.expand = false;
				} else {
					item.expand = true;
				}
				return item;
			});
			setTeamsOKRDetails((prev: any) => {
				return {
					...prev,
					myGoalOkrResponses: updatedList,
				};
			});
		} else {
			setIsExpandAll(!isExpandAll);
			const updatedList = teamsOKRDetails?.myGoalOkrResponses.map((item: any) => {
				if (isExpandAll) {
					item.expand = false;
				} else {
					item.expand = true;
				}
				return item;
			});
			setTeamsOKRDetails(updatedList);
		}
	};

	const sortTeamGoalList = (e: any, column: any) => {
		let sortClass: any = 'desc';
		if (column === sortingColumn) {
			sortClass = sortOrder === 'asc' ? 'desc' : 'asc';
		}

		let list = teamsOKRDetails?.myGoalOkrResponses.map((subArray: any) => ({
			...subArray,
			myGoalsDetails: _.orderBy(subArray.myGoalsDetails, column, sortClass),
		}));
		list = _.orderBy(list, column, sortClass);
		setTeamsOKRDetails((prev: any) => {
			return {
				...prev,
				myGoalOkrResponses: list,
			};
		});
		setSortOrder(sortClass);
		setSortingColumn(column);
	};
	const isFilterSelected = () => {
		let filters: boolean = false;
		cardDashboard &&
			Object.keys(cardDashboard).forEach((element: any) => {
				if (cardDashboard[element] === true) {
					filters = true;
				}
			});
		return filters;
	};
	return (
		<Fragment>
			{props.teamsOkrProgressData &&
			!props.isFutureCycle &&
			props.teamsOkrProgressData.hasOwnProperty('teamAvgScore') ? (
				<TeamsDashboard
					{...props}
					handleMenuAction={handleMenuAction}
					teamsOKRData={teamsOKRDetails}
					teamsOKRDetails={props.teamsOkrProgressData}
					setAnchorEl={setAnchorEl}
					setOpen={setOpen}
					teamsOKR={teamsOKR}
				/>
			) : (
				''
			)}

			{!goalDetail.showAttributeDrawer && props.listOkrSuccess !== 'pending' && showAddGoal && (
				<Fragment>
					{props.okrMasterData && (
						<CreateObjective
							{...props}
							showAddGoal={showAddGoal}
							t={t}
							fetchOkr={fetchOkr}
							showAddGoalForm={props.showAddGoalForm}
							setGoalDetail={props.setGoalDetail}
							setIsGoalCreated={props.setIsGoalCreated}
							saveAndEditObjectiveAndKr={props.saveAndEditObjectiveAndKr}
							setFormSubmit={props.setFormSubmit}
							formSubmit={props.formSubmit}
							isGoalCreated={props.isGoalCreated}
							showApiMsgs={props.showApiMsgs}
							isTabChanged={props.isTabChanged}
							setIsTabChanged={props.setIsTabChanged}
							addObjectiveCallback={props.addObjectiveCallback}
						/>
					)}
				</Fragment>
			)}
			{teamsOKRDetails && teamsOKRDetails?.myGoalOkrResponses && teamsOKRDetails?.myGoalOkrResponses.length ? (
				<Fragment>
					<OkrListingHead
						sortGoalList={sortTeamGoalList}
						sortingColumn={sortingColumn}
						sortOrder={sortOrder}
						showArchiveList={false}
						expandCollapseAll={expandCollapseAll}
						isExpandAll={isExpandAll}
						getFilter={getFilter}
						myGoalList={myGoalList}
						setOpenImportDrawer={setOpenImportDrawer}
						year={year}
						cycleId={cycleId}
						setLoader={setLoader}
						handleOpenImportDrawer={handleOpenImportDrawer}
						openImportDrawer={openImportDrawer}
						showAddGoalFormAndUpdateList={showAddGoalFormAndUpdateList}
						HOME={HOME}
						setTabValue={setTabValue}
						getPermissionResult={getPermissionResult}
						{...props}
					/>

					<Box className='pad-container'>
						<TeamsOkrList
							{...props}
							teamsOKR={teamsOKR}
							teamOKRData={teamsOKRDetails?.myGoalOkrResponses}
							getMyTeamOKR={getMyTeamOKR}
							fetchOkr={fetchOkr}
							isExpandAll={isExpandAll}
							setIsExpandAll={setIsExpandAll}
						/>
					</Box>
				</Fragment>
			) : (
				<>
					{(showAddGoal && isFilterSelected()) ||
						(props.alignmentFilterData.length > 0 && (
							<OkrListingHead
								showAddGoal={showAddGoal}
								getFilter={getFilter}
								myGoalList={myGoalList}
								expandCollapseAll={expandCollapseAll}
								setOpenImportDrawer={setOpenImportDrawer}
								year={year}
								cycleId={cycleId}
								setLoader={setLoader}
								handleOpenImportDrawer={handleOpenImportDrawer}
								openImportDrawer={openImportDrawer}
								showAddGoalFormAndUpdateList={showAddGoalFormAndUpdateList}
								HOME={HOME}
								setTabValue={setTabValue}
								getPermissionResult={getPermissionResult}
								{...props}
							/>
						))}
					<Box className='welcome-content teams-dashboard' id='welcome-id'>
						{props.getPermissionResult &&
						checkPermission(props.getPermissionResult?.employeePermissions, createOkrs) ? (
							<Box className='okr-listing-head no-okr-head pad-container'>
								<CreateAlignOkr
									{...props}
									setOpenImportDrawer={setOpenImportDrawer}
									year={year}
									cycleId={cycleId}
									showApiMsgs={showApiMsgs}
									setLoader={setLoader}
									handleOpenImportDrawer={handleOpenImportDrawer}
									openImportDrawer={openImportDrawer}
									showAddGoalForm={showAddGoalFormAndUpdateList}
									panelOpenFrom={HOME}
									setTabValue={setTabValue}
									myGoalList={myGoalList}
									tabValue={tabValue}
								/>
							</Box>
						) : (
							<></>
						)}
						{showAddGoal ? (
							<Fragment>
								<NoListFound showSkeleton={true} />
							</Fragment>
						) : currentUser ? (
							<Box textAlign='center' className='team-okr-no-record pad-container'>
								<AnimatedIcon
									className=''
									width='516'
									type='image/svg+xml'
									alt='WelcomeImage'
									imagePath={WelcomeImage}
								/>
								<Typography variant='h2'>{t('usersGoalNoDataMsg1')}</Typography>
								<Typography>{t('usersGoalNoDataMsg2')}</Typography>
							</Box>
						) : (
							<Box textAlign='center' className='team-okr-no-record pad-container'>
								{prevCycleMsg ? (
									<>
										<AnimatedIcon
											className=''
											width='516'
											type='image/svg+xml'
											alt='WelcomeImage'
											imagePath={WelcomeImage}
										/>
										<Typography variant='h2'>{t('NoRecord')}</Typography>
										<Typography className='no-record-max'>
											{!props.isFutureCycle ? t('pastCycleNotFoundText') : t('futureCycleNotFoundText')}
										</Typography>
									</>
								) : (
									<>
										{isFilterSelected() || props.alignmentFilterData.length > 0 ? (
											<Box className='no-okr-area'>
												<Typography variant='h2' component='h2'>
													{t('noOkrFound')}
												</Typography>
												<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
											</Box>
										) : (
											<>
												<AnimatedIcon
													className=''
													width='516'
													type='image/svg+xml'
													alt='WelcomeImage'
													imagePath={WelcomeImage}
												/>
												<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
												<Typography>{t('myGoalNoDataMsg2')}</Typography>
											</>
										)}
									</>
								)}
							</Box>
						)}
					</Box>
				</>
			)}
			{open && (
				<ContributorsListPopover
					{...props}
					open={open}
					setOpen={setOpen}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					contributorDetails={contributorDetails}
					loginUserAvatar={false}
					poperContainer={false}
					popupView={popupView}
				/>
			)}
		</Fragment>
	);
};
