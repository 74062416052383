/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	FormControl,
	InputLabel,
	TextField,
	Button,
	Collapse,
	Avatar,
	ListItemAvatar,
	Box,
} from '@material-ui/core';
import GlobalSearch from '../Common/GlobalSearch';
import { getLocalStorageItem } from '../../services/StorageService';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import LinearLoader from '../Common/Loader';
import { RAISED_TYPE_ID, REQUEST_TYPE_KR, REQUEST_TYPE_OKR, REQUEST_TYPE_TEAMS } from '../../config/constant';
import {
	createUUID,
	getTime,
	getUserDetails,
	getUserName,
	getRandomBackgroundColor,
	getRandomColor,
	GAevent,
	isTrialVersion,
} from '../../config/utils';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../config/svg/Action';
import { Enums } from '../../config/enums';
import { ErrorIcon } from '../../config/svg/formElementIcons';

export default function AskFeedback(props) {
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const { t } = useTranslation();
	const {
		notificationBy,
		isAskFeedback,
		objective,
		type,
		objId,
		requestOneOnOne,
		askFeedbackStatus,
		giveFeedbackStatus,
		feedbackOneOnOneStatus,
		feedbackRequestId,
		isTeamsFeedback,
		getUserById,
		kr,
	} = props;

	const { objectiveName, myGoalsDetails, keyDetails, feedbackOnTypeId, okrViewKeyResults } = objective || {
		feedbackOnTypeId: 0,
		objectiveName: '',
		myGoalsDetails: [],
		okrViewKeyResults: [],
	};
	const [checked, setChecked] = useState(false);
	const [loader, setLoader] = useState(false);
	const keysDetail = keyDetails || myGoalsDetails || okrViewKeyResults || [];
	const [defaultId, setDefaultId] = useState(false);
	const [checkOneOnOne, setCheckOneOnOne] = useState(false);

	useEffect(() => {
		if (defaultId === false && requestOneOnOne) {
			let isPending = false;

			setCheckOneOnOne(isPending);
			if (isPending === true) {
				let defaultVal = objective.createdBy !== objective.owner ? objective.owner : objective.createdBy;
				setDefaultId(defaultVal);
				getUserById &&
					getUserById(`empId=${defaultVal}`).then((resp) => {
						if (resp && resp.status === 200) {
							const { data } = resp;
							const { entityList } = data;
							onSelectedUser(entityList);
						}
					});
			}
		}
	}, [objective.createdBy, objective.owner]);

	const typeOnId = keyDetails ? objective.feedbackOnId : objId ? objId : objective.goalObjectiveId;

	const [formData, setFormData] = useState({
		message:
			type === 'okr' || feedbackOnTypeId === 1
				? objective.assignmentTypeId !== 1
					? t('giveFeedbackOkr')
					: t('giveFeedbackKr')
				: t('giveFeedbackKr'),
		submit: false,
		selectedUser: [],
		// selectedUser: [],
	});

	useEffect(() => {
		if (isAskFeedback) {
			if (isTeamsFeedback) {
				let owner = [
					{
						employeeId: objective.leaderEmployeeId,
						firstName: objective.firstName,
						lastName: objective.lastName,
						imagePath: objective.imagePath,
					},
				];
				let isTeamOkr = userDetail.employeeId !== objective.leaderEmployeeId && objective.teamId > 0;

				setFormData({
					...formData,
					message: type === 'teamsOkrCard' ? t('askFeedBackTeams') : '',
					selectedUser: isTeamOkr ? owner : [], // set default user if teamsokr card
					submit: isTeamOkr ? true : false, // set submit true if teamsokr card
					employeeIds: isTeamOkr ? [objective.leaderEmployeeId] : [], // set employeeIds array if teamsokr card
				});
			} else {
				//Default user dispplay if teams okr
				let owner = [
					{
						employeeId: objective.owner,
						firstName: objective.ownerFirstName,
						lastName: objective.ownerLastName,
						imagePath: objective.ownerImagePath,
					},
				];
				let isTeamOkr = userDetail.employeeId !== objective.owner && objective.teamId > 0;

				setFormData({
					...formData,
					message:
						(type === 'okr' || type === 'kr') && !objective.teamId > 0 //condition if it is teams okr
							? type === 'okr' || feedbackOnTypeId === 1
								? objective.assignmentTypeId !== 1
									? t('askFeedbackOkr')
									: t('askFeedbackKr')
								: t('askFeedbackKr')
							: type === 'okr'
								? t('teamsObjectiveMsg', { objectiveType: 'Objective' })
								: t('teamsObjectiveMsg', { objectiveType: 'KR' }),
					selectedUser: isTeamOkr ? owner : [], // set default user if teamsokr
					submit: isTeamOkr ? true : false, // set submit true if teamsokr
					employeeIds: isTeamOkr ? [objective.owner] : [], // set employeeIds array if teamsokr
				});
			}
		} else if (requestOneOnOne) {
			setFormData({
				...formData,
				message: t('appreciateTimeOnetoOne'),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAskFeedback, requestOneOnOne]);

	useEffect(() => {
		if (type === 'teamsOkrCardGiveFeedBack') {
			setFormData({
				...formData,
				message: t('giveFeedBackTeams'),
			});
		}
	}, [type]);

	useEffect(() => {
		if (askFeedbackStatus === 'success' || askFeedbackStatus === 'error') {
			setLoader(false);
		} else if (giveFeedbackStatus === 'success' || giveFeedbackStatus === 'error') {
			setLoader(false);
		} else if (feedbackOneOnOneStatus === 'success' || feedbackOneOnOneStatus === 'error') {
			setLoader(false);
		} else if (
			askFeedbackStatus === 'pending' ||
			giveFeedbackStatus === 'pending' ||
			feedbackOneOnOneStatus === 'pending'
		) {
			setLoader(true);
		}

		if (askFeedbackStatus === 'success' || giveFeedbackStatus === 'success' || feedbackOneOnOneStatus === 'success') {
			props.setFormDataUpdated(false);
		}
	}, [askFeedbackStatus, giveFeedbackStatus, feedbackOneOnOneStatus]);

	const handleChange = (e) => {
		e.preventDefault();
		setChecked((prev) => !prev);
	};

	const setMessageValue = (event) => {
		props.setFormDataUpdated(true);
		event.preventDefault();
		event.stopPropagation();
		setFormData({ ...formData, message: event.target.value });
	};

	const submitRequest = (event, type) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 2) {
			props.handleDrawerConfirmation(event);
		} else {
			setFormData({ ...formData, submit: true });

			if (formData.message.trim() !== '') {
				isTrialVersion() && GAevent('Request1:1', 'Clicked send in Request1:1 to submit', 'Clicked on Request1:1');
				feedbackRequest(event);
			}
		}
	};

	const feedbackRequest = async (e) => {
		let feedbackOnTypeId =
			type === 'okr' ? REQUEST_TYPE_OKR : type === 'teamsOkrCard' ? REQUEST_TYPE_TEAMS : REQUEST_TYPE_KR;
		const userDetail = getUserDetails();
		if (userDetail) {
			if (isAskFeedback) {
				if (formData.selectedUser && formData.selectedUser.length > 0) {
					let feedbackOnId = 0;
					if (type === 'okr' && objective.objectiveType && objective.objectiveType === 2) {
						//=== for alignment-map
						feedbackOnTypeId = REQUEST_TYPE_KR;
					} else if (type === 'okr' && objective.assignmentTypeId && objective.assignmentTypeId === 1) {
						//=== for Dashboard
						feedbackOnTypeId = REQUEST_TYPE_KR;
					}
					if (type === 'okr') {
						// for alignmentmap --- objectiveType has value
						if (objective?.objectiveType) {
							feedbackOnId = objective.objectiveType === 2 && objective.krId ? objective.krId : objective.objectiveId;
						} else if (objective.assignmentTypeId) {
							feedbackOnId = objective.assignmentTypeId === 1 ? objective.goalKeyId : objective.goalObjectiveId;
						}
					} else {
						feedbackOnId = getSelectedKR();
					}

					let feedbackAsk = {};
					if (isTeamsFeedback) {
						// condition if user access it from teams card in Teams dashboard
						feedbackOnId = objective.teamId;
						feedbackAsk = {
							raisedTypeId: RAISED_TYPE_ID, //raisedTypeId 3 for teams give feedback
							raisedForId: formData.employeeIds,
							feedbackById: userDetail.employeeId,
							feedbackOnTypeId,
							feedbackOnId,
							requestRemark: formData.message,
						};
					} else {
						feedbackAsk = {
							raisedTypeId: objective.teamId > 0 ? 2 : 1,
							raisedForId: formData.employeeIds,
							feedbackById: userDetail.employeeId,
							feedbackOnTypeId,
							feedbackOnId,
							requestRemark: formData.message,
						};
					}

					await props.askFeedback(feedbackAsk);
					props.setOpenAskFeedback(false);
				}
			} else if (requestOneOnOne) {
				if (formData.selectedUser && formData.selectedUser.length > 0) {
					let typeId = 0;
					if (type === 'okr') {
						if (objective.assignmentTypeId) {
							typeId = objective.assignmentTypeId === 1 ? objective.goalKeyId : objective.goalObjectiveId;
						} else if (objective.objectiveType) {
							typeId = objective.objectiveType === 2 && objective.krId ? objective.krId : objective.objectiveId;
						}
					} else {
						typeId = getSelectedKR();
					}
					props.handleRequestOnToOne(e, feedbackOnTypeId, typeId, formData.employeeIds, formData.message, 2);
				}
			} else if (getLocalStorageItem('currentUser') || props.currentUser || keyDetails) {
				const currentUser = JSON.parse(getLocalStorageItem('currentUser')) || props.currentUser;
				let feedTypeId = feedbackOnTypeId;
				let feedbackOnId = 0;
				if (type === 'okr' || objective.feedbackOnTypeId === 1) {
					if (objective.objectiveType) {
						feedbackOnId = objective.objectiveType === 2 ? objective.krId : objective.objectiveId;
					} else if (objective.assignmentTypeId) {
						feedbackOnId = objective.assignmentTypeId === 1 ? objective.goalKeyId : objective.goalObjectiveId;
					}
				} else {
					feedbackOnId = getSelectedKR();
				}
				let raisedForId = currentUser ? currentUser.employeeId : 0;

				if (keyDetails) {
					feedTypeId = objective.feedbackOnTypeId;
					feedbackOnId = objective.feedbackOnId;
					raisedForId = notificationBy;
				}
				let giveFeedback = {};
				if (objective.teamId > 0) {
					giveFeedback = {
						feedbackDetailId: 0,
						feedbackOnId,
						raisedTypeId: RAISED_TYPE_ID, //raisedTypeId 2 for teams give feedback
						raisedForId,
						feedbackRequestId: feedbackRequestId ? feedbackRequestId : 0,
						feedbackOnTypeId: feedTypeId,
						sharedRemark: formData.message,
						status: 3,
						teamId: objective.teamId,
					};
				} else {
					giveFeedback = {
						feedbackDetailId: 0,
						raisedTypeId: 1,
						raisedForId,
						feedbackRequestId: feedbackRequestId ? feedbackRequestId : 0,
						feedbackOnTypeId: feedTypeId,
						feedbackOnId,
						sharedRemark: formData.message,
						status: 3,
					};
				}
				await props.giveFeedback(giveFeedback);
				props.setOpenGiveFeedback(false);
			} else if (props.alignmentMap) {
				let feedTypeId = feedbackOnTypeId;
				if (type === 'okr' && objective.objectiveType && objective.objectiveType === 2) {
					feedTypeId = REQUEST_TYPE_KR;
				}

				let feedbackOnId = 0;
				if (type === 'okr' && props.alignmentMap && objective.objectiveType) {
					feedbackOnId = objective.objectiveType === 2 ? objective.krId : objective.objectiveId;
				} else if ((type === 'okr' || objective.feedbackOnTypeId === 1) && objective.assignmentTypeId) {
					feedbackOnId = objective.assignmentTypeId === 1 ? objective.goalKeyId : objective.goalObjectiveId;
				} else {
					feedbackOnId = getSelectedKR();
				}
				let raisedForId = objective.employeeId || 0;

				if (keyDetails) {
					feedTypeId = objective.feedbackOnTypeId;
					feedbackOnId = objective.feedbackOnId;
					raisedForId = notificationBy;
				}
				const giveFeedback = {
					feedbackDetailId: 0,
					raisedTypeId: 1,
					raisedForId,
					feedbackRequestId: feedbackRequestId ? feedbackRequestId : 0,
					feedbackOnTypeId: feedTypeId,
					feedbackOnId,
					sharedRemark: formData.message,
					status: 3,
				};
				await props.giveFeedback(giveFeedback);
				props.setOpenGiveFeedback(false);
			} else if (props.teamsGiveFeedBack) {
				const giveFeedback = {
					feedbackDetailId: 0,
					feedbackOnId: objective.teamId,
					feedbackOnTypeId: REQUEST_TYPE_TEAMS, //feedbackOnTypeId 4 for teams only
					feedbackRequestId: 0,
					raisedForId: objective.teamId,
					raisedTypeId: RAISED_TYPE_ID, //raisedTypeId 2 for teams give feedback
					sharedRemark: formData.message,
					status: 3,
					teamId: objective.teamId,
				};
				props.giveFeedback(giveFeedback);
			}
			// eslint-disable-next-line no-restricted-globals
		}
	};

	const removeUser = async ({ employeeId }) => {
		const user = formData.selectedUser.filter((item) => item.employeeId !== employeeId);
		const userIds = formData.selectedUser.filter((item) => item.employeeId !== employeeId);

		setFormData({
			...formData,
			selectedUser: user,
			employeeIds: [...userIds.map((item) => item.employeeId)],
		});
	};
	const onSelectedUser = async (selected) => {
		const isExist =
			formData.selectedUser && formData.selectedUser.find((item) => item.employeeId === selected[0].employeeId);
		if (selected.length > 0 && !isExist) {
			const allUser = [...formData.selectedUser, { ...formData.selectedUser, ...selected[0] }];
			const allUserIds = allUser.map((item) => item.employeeId);

			setFormData(() => ({
				...formData,
				selectedUser: allUser,
				employeeIds: allUserIds,
			}));
		}
	};

	const getSelectedKR = () => {
		const selectedKR = keysDetail.find((item) => item.goalKeyId === typeOnId || item.krId === typeOnId);
		return selectedKR?.goalKeyId || selectedKR?.krId;
	};

	const handleEvent = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const callbackRef = useCallback((inputElement) => {
		setTimeout(() => {
			if (inputElement) {
				inputElement.value = '';
				inputElement.value = formData.message;
				inputElement.focus();
			}
		}, 100);
	}, []);

	return (
		<Fragment>
			<Box className='okr-drawer-area' onClick={handleEvent}>
				<Box className='drawer-area'>
					<Box className='drawer-head'>
						{!requestOneOnOne ? (
							<Typography variant='h3'>{isAskFeedback ? t('requestFeedback') : t('giveFeedback')}</Typography>
						) : (
							<Typography variant='h3'>{t('requestOnetoOne')}</Typography>
						)}
					</Box>
					{loader && (
						<LinearLoader data={props.giveFeedbackStatus === 'success' || props.askFeedbackStatus === 'success'} />
					)}
					<Box className='drawer-content' id='custom-scroll'>
						<Box className='drawer-inner-content'>
							<Box className='drawer-content-panel okr-drawer-form-area'>
								<Box className='okr-objective'>
									<Typography variant='h4' className={type === 'okr' ? 'active-obj' : ''}>
										{/* This is for Dashboard Listing */}
										{objective.assignmentTypeId && objective.assignmentTypeId !== 1
											? objectiveName
											: objective.keyDescription}

										{/* This is for Alignment map */}
										{objective.objectiveType ? objective.name : ''}

										{/* This is for redirection */}
										{!objective.objectiveType && !objective.assignmentTypeId && objective.objectiveName}

										{/* This is for Teams */}
										{isTeamsFeedback && objective && objective.teamName && (
											<Box className='feedbackDrawer'>
												<Box className='user-info user-team-info'>
													<Box className='user-img'>
														{objective?.teamLogo ? (
															<Avatar
																alt={`${objective?.firstName} ${objective?.lastName}`}
																src={objective?.teamLogo}
															/>
														) : (
															<Avatar
																style={{
																	backgroundColor: getRandomBackgroundColor(objective?.backGroundColorCode),
																	color: getRandomColor(objective?.colorCode),
																}}
															>
																{getUserName({ firstName: '', lastName: '', fullName: objective?.teamName })}
															</Avatar>
														)}
													</Box>
													<Box className='user-info-detail'>
														<Typography variant='h4'>{objective?.teamName}</Typography>
														{objective?.teamEmployeeCount !== undefined && objective?.teamEmployeeCount !== 0 ? (
															<Typography variant='subtitle2'>{objective.teamEmployeeCount} Members</Typography>
														) : (
															<Typography variant='subtitle2'>{t('noMemberLabel')}</Typography>
														)}
													</Box>
												</Box>
											</Box>
										)}
									</Typography>
								</Box>
								<Box className='feedback-keys key-result'>
									{(type === 'okr' || feedbackOnTypeId === 1) && objective.assignmentTypeId !== 1 ? (
										<Fragment>
											<Button
												variant='contained'
												className={checked ? 'btn-blue-chip expand' : 'btn-blue-chip'}
												onClick={(e) => handleChange(e)}
											>
												<RightArrowIcon />
												{`${keysDetail ? keysDetail.length : 0} ${t('keyResults')}`}
											</Button>
											<Collapse in={checked}>
												<List>
													{keysDetail &&
														keysDetail.map((item) => (
															<ListItem key={createUUID()}>
																<ListItemText primary={item.keyDescription || item.krName} />
															</ListItem>
														))}
												</List>
											</Collapse>
										</Fragment>
									) : (
										keysDetail &&
										keysDetail.length > 0 && (
											<List>
												<ListItem>
													<ListItemText
														className='active-key'
														primary={
															keysDetail.find((item) => item.goalKeyId === typeOnId)?.keyDescription ||
															keysDetail.find((item) => item.krId === typeOnId)?.krName
														}
													/>
												</ListItem>
											</List>
										)
									)}
								</Box>

								<Box className='feedback-comments-area view-feedback-area'>
									<form className='okr-form-fields' noValidate autoComplete='off'>
										{isAskFeedback || requestOneOnOne ? (
											<Fragment>
												<FormControl
													className='drawer-search-field helperText-bottom drawer-field-bottom'
													variant='outlined'
												>
													<InputLabel htmlFor='ask-feedback-from'>
														{isAskFeedback ? t('askFeedbackFromWho') : t('withWhomOnetoOne')}
													</InputLabel>
													{!requestOneOnOne ? (
														<GlobalSearch
															module={'myGoal'}
															onSelectedUser={onSelectedUser}
															fetchCycleUsers={true}
															{...props}
															formData={formData}
															customClass={'user-quick-search-type2'}
															customId={'ask-feedback-src'}
														/>
													) : (
														requestOneOnOne &&
														checkOneOnOne === false && (
															<GlobalSearch
																module={'myGoal'}
																onSelectedUser={onSelectedUser}
																fetchCycleUsers={true}
																{...props}
																formData={formData}
																customClass={'user-quick-search-type2'}
																customId={'req-one-src'}
															/>
														)
													)}
													{formData && formData.selectedUser && formData.selectedUser.length > 0 && (
														<Box className='contributors-chip '>
															<List>
																{formData &&
																	formData.selectedUser &&
																	formData.selectedUser.length > 0 &&
																	formData.selectedUser.map((item) => (
																		<ListItem
																			key={`assign_contributor_${item.employeeId}`}
																			className={`${requestOneOnOne && defaultId === item.employeeId ? 'no-background' : ''
																				}`}
																		>
																			{item.imagePath ? (
																				<Avatar alt={`${item.firstName} ${item.lastName}`} src={item.imagePath} />
																			) : (
																				<Avatar
																					style={{
																						backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																						color: getRandomColor(item?.colorCode),
																					}}
																				>
																					{getUserName(item)}
																				</Avatar>
																			)}
																			<Typography>{`${item.firstName} ${item.lastName}`}</Typography>
																			{requestOneOnOne && defaultId === item.employeeId ? (
																				''
																			) : (
																				<Button className='cancel-btn' onClick={() => removeUser(item)}>
																					<CancelIcon />
																				</Button>
																			)}
																		</ListItem>
																	))}
															</List>
														</Box>
													)}
													{formData.selectedUser.length === 0 && formData.submit ? (
														<Typography variant='caption' color='error' className='error-field'>
															<ErrorIcon />
															{t('selectEmployee')}
														</Typography>
													) : (
														''
													)}
												</FormControl>
												<FormControl
													className='feedback-message helperText-bottom'
													variant='outlined'
													style={{ width: '100%' }}
												>
													<TextField
														label={t('FeedbackMessageLabel')}
														multiline
														rows={4}
														placeholder={t('typeMessageHere')}
														value={formData.message}
														autoFocus
														onFocus={handleEvent}
														onChange={setMessageValue}
														error={formData.submit && formData.message.trim() === ''}
														helperText={
															formData.submit && formData.message.trim() === '' ? (
																<>
																	<ErrorIcon />
																	{t('enterValidMessage')}
																</>
															) : (
																''
															)
														}
													/>
												</FormControl>
												<Box className='okr-form-action feedback-action'>
													<Button
														className='cancel-btn btn-link'
														onClick={(event) => submitRequest(event, 2)}
														color='primary'
													>
														{t('cancelBtn')}
													</Button>
													<Button
														className='submitBtn btn-primary'
														disabled={formData.selectedUser.length === 0 || formData.message.trim() === ''}
														onClick={(event) => submitRequest(event, 1)}
														color='primary'
													>
														{t('send')}
													</Button>
												</Box>
											</Fragment>
										) : (
											<Fragment>
												{objective.askByRequestRemark && objective.askByCreatedOn ? (
													//=== if giving feedback when someone asked and redirected from notification
													<Box
														className={
															objective.feedbackProvideDetails.length > 1
																? `feedback-comments active`
																: `feedback-comments`
														}
													>
														<Box className={'comments-zoom-in animate-fadeIn'}>
															<List className='comments-list'>
																<ListItem alignItems='flex-start'>
																	<ListItemAvatar>
																		{objective.askByImagePath ? (
																			<Avatar src={objective.askByImagePath} />
																		) : (
																			<Avatar>
																				{getUserName({
																					firstName: objective.askByFirstName,
																					lastName: objective.askByLastName,
																				})}
																			</Avatar>
																		)}
																	</ListItemAvatar>
																	<ListItemText
																		primary={`${objective.askByFirstName ? objective.askByFirstName : ''} ${objective.askByLastName ? objective.askByLastName : ''
																			}`}
																		secondary={
																			<Fragment>
																				<Typography component='small' variant='body2'>
																					{objective.askByCreatedOn ? getTime(objective.askByCreatedOn) : ''}
																				</Typography>
																				<Typography component='span'>
																					{objective.askByRequestRemark ? objective.askByRequestRemark : ''}
																				</Typography>
																			</Fragment>
																		}
																	/>
																</ListItem>
																<List className='comments-sub-list'>
																	<ListItem alignItems='flex-start'>
																		<ListItemAvatar>
																			{userDetail.imagePath ? (
																				<Avatar src={userDetail.imagePath} />
																			) : (
																				<Avatar>{getUserName(userDetail)}</Avatar>
																			)}
																		</ListItemAvatar>
																		<ListItemText
																			primary={`${userDetail.firstName} ${userDetail.lastName}`}
																			className='feedback-field'
																			secondary={
																				<Fragment>
																					<TextField
																						style={{ width: '100%' }}
																						className='feedback-message helperText-bottom'
																						label=''
																						multiline
																						rows={4}
																						placeholder={t('typeMessageHere')}
																						value={formData.message}
																						inputRef={callbackRef}
																						// onFocus={handleEvent}
																						onChange={setMessageValue}
																						error={formData.submit && formData.message.trim() === ''}
																						helperText={
																							formData.submit && formData.message.trim() === '' ? (
																								<>
																									<ErrorIcon />
																									{t('enterValidMessage')}
																								</>
																							) : (
																								''
																							)
																						}
																					/>
																					<Box className='okr-form-action feedback-action'>
																						<Button
																							className='cancel-btn btn-link'
																							onClick={(event) => submitRequest(event, 2)}
																							color='primary'
																						>
																							{t('cancelBtn')}
																						</Button>
																						<Button
																							className='submitBtn btn-primary'
																							onClick={(event) => submitRequest(event, 1)}
																							color='primary'
																						>
																							{t('send')}
																						</Button>
																					</Box>
																				</Fragment>
																			}
																		/>
																	</ListItem>
																</List>
															</List>
														</Box>
													</Box>
												) : (
													//=== if giving feedback directly by going to others goal
													<Fragment>
														<FormControl className='feedback-message' variant='outlined' style={{ width: '100%' }}>
															<TextField
																label='Message'
																multiline
																rows={4}
																placeholder={t('typeMessageHere')}
																value={formData.message}
																inputRef={callbackRef}
																// onFocus={handleEvent}
																onChange={setMessageValue}
																error={formData.submit && formData.message.trim() === ''}
																helperText={
																	formData.submit && formData.message.trim() === '' ? t('enterValidMessage') : ''
																}
															/>
														</FormControl>
														<Box className='okr-form-action feedback-action'>
															<Button
																className='cancel-btn btn-link'
																onClick={(event) => submitRequest(event, 2)}
																color='primary'
															>
																{t('cancelBtn')}
															</Button>
															<Button
																className='submitBtn btn-primary'
																onClick={(event) => submitRequest(event, 1)}
																color='primary'
															>
																{t('send')}
															</Button>
														</Box>
													</Fragment>
												)}
											</Fragment>
										)}
									</form>
								</Box>
								{objective.feedbackProvideDetails && objective.feedbackProvideDetails.length > 0
									? objective.feedbackProvideDetails.map((feedback) => (
										<Box className='feedback-comments-area view-feedback-area'>
											<Box className='feedback-comments'>
												<Box className='comments-zoom-out'>
													<Box display='flex' justifyContent='space-between'>
														<Typography variant='body2'>
															Given By: {feedback.sharedByFirstname} {feedback.sharedByLastname}
														</Typography>
														<Typography variant='body2'>{getTime(feedback.sharedByCreatedOn)}</Typography>
													</Box>
													<Typography>{feedback.sharedRemark}</Typography>
												</Box>
											</Box>
										</Box>
									))
									: ''}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Fragment>
	);
}
