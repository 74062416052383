import React, { Fragment, useState, useEffect } from 'react';
import {
	Box,
	Chip,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
// import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import {
	AddIconSvg,
	CloneIcon,
	DeleteIcon,
	EditIcon,
	ListViewIcon,
	SortingIcon,
} from '../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../Common/OKRButton';
// import { NoRecord } from '../../CommonComponent/NoRecord';
// import { Visibility } from '../../../../../config/svg/ProfileSvg';
import { PopperMenu } from '../../../CommonComponent/PopperMenu';
import { NoRecord } from '../../../CommonComponent/NoRecord';
import { getLabelDescNumber, getScaleTypeIcon } from '../../../../../config/utils';
import { Enums } from '../../../../../config/enums';

export const ManageScaleForm: React.FC<any> = (props: any) => {
	const {
		handleTabChange,
		// customScaleDetails,
		// setCustomScaleDetails,
		manageScaleList,
		// setManageScaleList,
		sortingManageScaleListColumn,
		sortManageScaleListOrder,
		sortManageScaleList,
		handleScaleToggleUpdateFromListClick,
		handleScaleDeleteFromListClick,
		handleScaleEditFromListClick,
		handleScaleCloneFromListClick,
		handleScaleViewFromListClick,
		manageScaleListHighlightItemIndex,
		setManageScaleListHighlightItemIndex,
	} = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);

	useEffect(() => {
		if (manageScaleListHighlightItemIndex && manageScaleList && manageScaleList?.length) {
			// const selectedEl = document.getElementById(`scale_list_item_index_${manageScaleListHighlightItemIndex}`);
			// if (selectedEl) {
			// 	selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
			// }
			setTimeout(() => {
				setManageScaleListHighlightItemIndex(false);
			}, 10000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [manageScaleListHighlightItemIndex, manageScaleList]);

	const handleClose = () => {
		setAnchorEl(null);
		setPopperMenuClicked(false);
	};

	return (
		<Box className='manage-scale-form'>
			<Box className='drawer-input-field sub-head-button'>
				<Box className='add-user-field' onClick={(event: any) => handleTabChange(event, 1)}>
					<OKRButton className='add-btn2' id={'add-custom-scale-btn'} icon={<AddIconSvg />} color='primary' />
					<Typography variant='subtitle1'>
						<em>{t('customScale')}</em>
					</Typography>
				</Box>
			</Box>
			<>
				{manageScaleList && manageScaleList?.length ? (
					<Box className={'manage-scale-listing'}>
						<>
							{manageScaleList && manageScaleList?.length ? (
								<Box className='okr-list-panel-head'>
									<List component='ul' disablePadding>
										<ListItem className='manage-scale-col1'>
											<Typography
												variant='body2'
												onClick={(e) => sortManageScaleList(e, 'scaleName')}
												className={
													sortingManageScaleListColumn === 'scaleName'
														? `sort-title ${sortManageScaleListOrder}`
														: 'sort-title asc'
												}
											>
												<span className='sortable-label'>
													<span className='txt-wrap'>
														{t('scaleName')} <SortingIcon />
													</span>
												</span>
											</Typography>
										</ListItem>
										<ListItem className='manage-scale-col2'>
											<Typography
												variant='body2'
												onClick={(e) => sortManageScaleList(e, 'questionTypeId')}
												className={
													sortingManageScaleListColumn === 'questionTypeId'
														? `sort-title ${sortManageScaleListOrder}`
														: 'sort-title asc'
												}
											>
												<span className={`sortable-label `}>
													<span className='txt-wrap'>
														{t('questionType')} <SortingIcon />
													</span>
												</span>
											</Typography>
										</ListItem>
										<ListItem className='manage-scale-col3'>
											<Typography
												variant='body2'
												onClick={(e) => sortManageScaleList(e, 'category')}
												className={
													sortingManageScaleListColumn === 'category'
														? `sort-title ${sortManageScaleListOrder}`
														: 'sort-title asc'
												}
											>
												<span className={`sortable-label `}>
													<span className='txt-wrap'>
														{t('categoryLevel')} <SortingIcon />
													</span>
												</span>
											</Typography>
										</ListItem>
										<ListItem className='manage-scale-col4'>
											<Typography
												variant='body2'
												onClick={(e) => sortManageScaleList(e, 'status')}
												className={
													sortingManageScaleListColumn === 'status'
														? `sort-title ${sortManageScaleListOrder}`
														: 'sort-title asc'
												}
											>
												<span className={`sortable-label `}>
													<span className='txt-wrap'>
														{t('statusLabel')} <SortingIcon />
													</span>
												</span>
											</Typography>
										</ListItem>
									</List>
								</Box>
							) : (
								<></>
							)}
							{manageScaleList && manageScaleList?.length ? (
								manageScaleList?.map((item: any, indexVal: number) => {
									return (
										<Box
											className={`manage-scale-list-content ${
												manageScaleListHighlightItemIndex && indexVal === Enums.ZERO ? 'highlight-row' : ''
											}`}
											key={`scale_list_item_index_${indexVal}`}
											id={`scale_list_item_index_${item?.performanceScaleId}`}
										>
											<List component='ul' disablePadding key={`review-form-list-`}>
												<ListItem className='manage-scale-col1'>
													<Typography>{`${item?.scaleName || ''} ${getLabelDescNumber(item)}`}</Typography>
												</ListItem>
												<ListItem className='manage-scale-col2'>
													{getScaleTypeIcon(item?.questionTypeId)} {item?.questionType}
												</ListItem>
												<ListItem className='manage-scale-col3'>
													<Box>
														{item?.category ? <Chip label={t('defaultLabel')} /> : <Chip label={t('customLevel')} />}
													</Box>
												</ListItem>
												<ListItem className='manage-scale-col4'>
													<Box className='status-switch'>
														<FormControlLabel
															control={
																<Switch
																	id={`status_active_id_${indexVal}`}
																	checked={item?.status === Enums.ONE ? true : false}
																	onChange={(e) => handleScaleToggleUpdateFromListClick(e, item)}
																/>
															}
															label={''}
														/>
													</Box>
													<Box className='assessment-actions'>
														<PopperMenu
															popperClassName={'popper-menu manage-form-popper-menu'}
															anchorEl={anchorEl}
															handleClose={handleClose}
															includeKebabMenu={true}
															menuClicked={popperMenuClicked}
															setPopperMenuClicked={setPopperMenuClicked}
															{...props}
															children={
																<>
																	{item?.questionTypeId === Enums.FOUR ? (
																		<>
																			<ListItem
																				onClick={(e) => {
																					handleClose();
																					handleScaleViewFromListClick(e, item);
																				}}
																			>
																				<ListItemAvatar>
																					<ListViewIcon />
																				</ListItemAvatar>
																				<ListItemText primary={t('viewCourse')} />
																			</ListItem>
																			{!item?.category ? (
																				<ListItem
																					onClick={(e) => {
																						handleClose();
																						handleScaleDeleteFromListClick(e, item);
																					}}
																				>
																					<ListItemAvatar>
																						<DeleteIcon />
																					</ListItemAvatar>
																					<ListItemText primary={t('delete')} />
																				</ListItem>
																			) : (
																				<></>
																			)}
																		</>
																	) : item?.category ? (
																		<>
																			<ListItem
																				onClick={(e) => {
																					handleClose();
																					handleScaleViewFromListClick(e, item);
																				}}
																			>
																				<ListItemAvatar>
																					<ListViewIcon />
																				</ListItemAvatar>
																				<ListItemText primary={t('viewCourse')} />
																			</ListItem>
																			<ListItem
																				onClick={(e) => {
																					handleClose();
																					handleScaleCloneFromListClick(e, item);
																				}}
																			>
																				<ListItemAvatar>
																					<CloneIcon />
																				</ListItemAvatar>
																				<ListItemText primary={t('clone')} />
																			</ListItem>
																		</>
																	) : (
																		<>
																			<ListItem
																				onClick={(e) => {
																					handleClose();
																					handleScaleEditFromListClick(e, item);
																				}}
																			>
																				<ListItemAvatar>
																					<EditIcon />
																				</ListItemAvatar>
																				<ListItemText primary={t('editLabel')} />
																			</ListItem>
																			<ListItem
																				onClick={(e) => {
																					handleClose();
																					handleScaleCloneFromListClick(e, item);
																				}}
																			>
																				<ListItemAvatar>
																					<CloneIcon />
																				</ListItemAvatar>
																				<ListItemText primary={t('clone')} />
																			</ListItem>
																			<ListItem
																				onClick={(e) => {
																					handleClose();
																					handleScaleDeleteFromListClick(e, item);
																				}}
																			>
																				<ListItemAvatar>
																					<DeleteIcon />
																				</ListItemAvatar>
																				<ListItemText primary={t('delete')} />
																			</ListItem>
																		</>
																	)}
																</>
															}
														/>
													</Box>
												</ListItem>
											</List>
										</Box>
									);
								})
							) : (
								<></>
							)}
						</>
					</Box>
				) : (
					<></>
				)}
				{manageScaleList && manageScaleList?.length === 0 ? (
					<NoRecord noRecordMessage={t('noExcludeMainHead')} subHead={t('noExcludeSubHead')} {...props} />
				) : (
					<></>
				)}
			</>
		</Box>
	);
};
