import React, { useEffect } from 'react';
import { Box, ClickAwayListener } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { useDispatch, useSelector } from 'react-redux';
import { showOnCreatePopup } from '../../action/common';

export const LeftMenuItem: React.FC<any> = (props: any) => {
	const { btnIcon, btnTooltip, children, btnId, btnText, isActive, setMenuOpen } = props;
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	const { okrCreationInProgress } = useSelector((state: any) => state.commonReducer);

	useEffect(() => {
		if (open) {
			setMenuOpen && setMenuOpen(btnId);
		} else {
			setMenuOpen && setMenuOpen('');
		}
	}, [open]);
	const handleClick = (event: any) => {
		if (okrCreationInProgress) {
			event.preventDefault();
			event.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...event } }));
			return false;
		}
		setOpen((prev) => !prev);
	};

	const handleClickAway = (e: any) => {
		setOpen(false);
	};

	return (
		<Box>
			<ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
				<Box className='menu-links' onClick={(e) => handleClick(e)}>
					<OKRButton
						icon={btnIcon}
						title={btnTooltip}
						tooltipPlacement={'right'}
						className={open || isActive ? 'active' : ''}
						id={btnId ? btnId : ''}
						text={btnText ? btnText : ''}
					/>
					{open && children ? <Box className='left-sub-menu-dropdown'>{children}</Box> : null}
				</Box>
			</ClickAwayListener>
		</Box>
	);
};
