import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
	Box,
	ClickAwayListener,
	FormControlLabel,
	FormLabel,
	List,
	ListItem,
	Switch,
	TextField,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
	BottomArrowIcon,
	CalendarIcon,
	CategoryIcon,
	DocumentIcon,
	NameRenameIcon,
	ReasonIcon,
	ReviewersIcon,
	ScaleType,
} from '../../../../config/svg/CommonSvgIcon';
import { FeedbackTypeDropdown } from './FeedbackTypeDropdown';
import { UploadDocuments } from '../../Common/UploadDocuments';
import { NoSearchRecord } from '../../../Admin/CommonComponent/NoSearchRecord';
import { MultiSelect } from '../../../Admin/CommonComponent/MultiSelect';
import { Enums } from '../../../../config/enums';
import {
	getFeedbackCategoryData,
	getFeedbackTypeData,
	getPIPNomineeSearch,
} from '../../../../action/performanceImprovementPlan';
import { useDispatch } from 'react-redux';
import { formatFeedbackDate, getMonthDateYearTime, getUserDetails } from '../../../../config/utils';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';
import ReactDateRangePicker from '../../../Common/ReactDateRangePicker';
import _ from 'lodash';
import { ReviewDates } from '../../PerformanceImprovementPlan/CreatePip/ReviewDates';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { globalSearchAPIWithTeam } from '../../../../action/search';
import { OKRButton } from '../../../Common/OKRButton';
import { HeaderTooltip } from '../../../Reports/HeaderTooltip';
import { getSearchDefaultUser, getSearchFeedbackApi } from '../../../../action/personalFeedback';

export const CreateFeedbackDrawer: React.FC<any> = (props: any) => {
	const {
		feedbackFormDetails,
		feedbackFormType,
		updateFeedbackFormDetails,
		isCategoryVisible,
		setIsCategoryVisible,
		isTimeFrameVisible,
		setIsTimeFrameVisible,
		setFeedbackFormDetails,
		isTimeFrameRequired,
		setIsTimeFrameRequired,
		isCreateTimeframe,
		setIsCreateTimeframe,
		setValidationCheck,
		validationCheck,
		selectedDateRange,
		setSelectedDateRange,
		setIsFormEdited,
		showMsg,
		selectedStartDate,
		setSelectedStartDate,
		selectedEndDate,
		setSelectedEndDate,
		extendDateToggle,
		setExtendDateToggle,
		openCreatePipForm,
		setIsDatesDisabled,
		isDatesDisabled,
		minEndDateValue,
		setMinDateValue,
		timeFrameChangeReasonTypes,
		employeeFeedbackPermission,
	} = props;
	const { t } = useTranslation();
	const moment = extendMoment(Moment);
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 120);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 120);

	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
	const [feedbackTypesOptions, setFeedbackTypesOptions] = useState<any>([]);
	const [openReasonDropdown, setOpenReasonDropdown] = useState<Boolean>(false);
	const [feedbackReviewersList, setFeedbackReviewersList] = useState<any>([]);

	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
	});
	const [allReviewersList, setAllReviewersList] = useState<any[]>([]);
	const [selectedReason, setSelectedReason] = useState<any>({});
	const dispatch = useDispatch();
	const userDetail = getUserDetails();
	useEffect(() => {
		getFeedbackTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const searchReportingTo = async (
		value: string,
		// finder: any,
		// pageIndex: any,
		// pageSize: any,
		allReviewersListValue: any
	) => {
		if (value.length >= 3) {
			const requestParam = `finder=${value}&pageIndex=1&pageSize=${Enums.MAX_USER_SIZE}`;
			const resp: any = await dispatch(getSearchFeedbackApi(requestParam)); // change here
			if (resp && resp?.data && resp.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = resp?.data || {};
				// console.log(allReviewersListValue);
				let list = entityList && entityList?.length ? [...entityList] : [];
				list =
					list &&
					list.filter(
						(item: any) => item.employeeId !== userDetail.employeeId && !allReviewersListValue.includes(item.employeeId)
					);
				if (list && list.length > 0) {
					setReportingToOptions(
						list.map((userData: any) => {
							return {
								...userData,
								label: userData.firstName + ' ' + userData.lastName,
								value: userData.employeeId,
								secondaryText: userData.designation,
							};
						})
					);
				} else {
					setReportingToOptions([]);
					setEmptyRecords({ ...emptyRecords, reportingTo: true });
				}
			} else {
				setReportingToOptions([]);
				setEmptyRecords({ ...emptyRecords, reportingTo: true });
			}
		}
	};
	const handleUserSelectionClick = async (value: any) => {
		try {
			if (value && value.length > Enums.ZERO) {
				addDefaultReviewers(value);
				console.log(value);
			} else {
				updateFeedbackFormDetails('nomineeId', null);
				setFeedbackReviewersList([]);
				const nomineeDetails = {
					nomineeId: Enums.ZERO,
					nomineeDetails: null,
					nomineeError: '',
				};
				setIsFormEdited(true);
				setFeedbackFormDetails({ ...feedbackFormDetails, reviewers: [], ...nomineeDetails });
			}
			setReportingToOptions([]);
			setEmptyRecords({ ...emptyRecords, reportingTo: false });
		} catch (error) {
			console.error(error);
		}
	};

	const addDefaultReviewers = async (employeeData: any[]) => {
		const requestParam = `employeeId=${employeeData[0].employeeId}&emailModuleId=2`;
		const resp: any = await dispatch(getSearchDefaultUser(requestParam));
		if (resp && resp?.data && resp.data?.status === Enums.STATUS_SUCCESS) {
			let entity: any = resp.data.entity;
			let reviewers: any = [];
			if (entity.isBusinessUnitHead && entity.businessUnitHeadResponse) {
				reviewers.push({
					...entity.businessUnitHeadResponse,
					isDeleteDisabled: true,
					id: entity.businessUnitHeadResponse.businessUnitHeadId,
				});
			}
			if (entity.isHRBP && entity.hrbpResponse) {
				reviewers.push({ ...entity.hrbpResponse, isDeleteDisabled: true, id: entity.hrbpResponse.hrbpId });
			}
			if (entity.isSkipLevelManager && entity.skipLevelManagerResponse) {
				reviewers.push({
					...entity.skipLevelManagerResponse,
					isDeleteDisabled: true,
					id: entity.skipLevelManagerResponse.skipLevelManagerId,
				});
			}
			const nomineeDetails = {
				nomineeId: employeeData ? employeeData[0]?.employeeId : Enums.ZERO,
				nomineeDetails: employeeData || null,
				nomineeError: '',
			};
			setIsFormEdited(true);
			setFeedbackFormDetails({ ...feedbackFormDetails, reviewers: reviewers, ...nomineeDetails });
			setFeedbackReviewersList(reviewers);
		}
	};
	const getFeedbackTypes = async (feedbackTypeId = 0) => {
		const requestParam = ``;
		const resp: any = await dispatch(getFeedbackTypeData(requestParam));
		if (resp && resp?.data && resp.data?.status === Enums.STATUS_SUCCESS) {
			const { entityList } = resp?.data || {};
			let list: any[] = [];
			if (entityList && entityList.length > 0) {
				list = entityList.filter((item: any) => {
					return item.isEnabled;
				});
			}
			if (list && list.length > 0) {
				setFeedbackTypesOptions(list);
				if (feedbackFormDetails?.feedbackType?.feedbackTypeId) {
					getCategories(feedbackFormDetails.feedbackType.feedbackTypeId);
				}
			} else {
				setFeedbackTypesOptions([]);
			}
		}
	};
	const getCategories = async (feedbackTypeId = 0) => {
		const requestParam = `feedbackTypeId=${feedbackTypeId}`;
		const resp: any = await dispatch(getFeedbackCategoryData(requestParam));
		if (resp && resp?.data && resp.data?.status === Enums.STATUS_SUCCESS) {
			const { entityList } = resp?.data || {};
			let list: any[] = [];
			if (entityList && entityList.length > 0) {
				list = entityList.filter((item: any) => {
					return item.isEnabled;
				});
			}
			if (list && list.length > 0) {
				setCategoriesOptions(list);
			} else {
				setCategoriesOptions([]);
			}
		}
	};

	const handleTypeSelection = async (feedbackData: any) => {
		if (feedbackData && feedbackData.isCategoryRequired) {
			getCategories(feedbackData.feedbackTypeId);
			setIsCategoryVisible(true);
			updateFeedbackFormDetails('feedbackType', feedbackData);
		} else {
			setIsCategoryVisible(false);
			setIsTimeFrameVisible(false);
			setIsFormEdited(true);
			setFeedbackFormDetails({ ...feedbackFormDetails, ...{ feedbackCategory: null, feedbackType: feedbackData } });
			setValidationCheck({ ...validationCheck, feedbackType: '', feedbackCategory: '', timeFrame: '' });
		}
		setSelectedDateRange(null);
		setSelectedStartDate(null);
		setSelectedEndDate(null);
		setIsTimeFrameVisible(false);
		if (!feedbackFormDetails?.fromExtension) {
			setIsDatesDisabled({ start: false, end: false });
		}
	};

	const handleCategorySelection = async (feedbackData: any) => {
		updateFeedbackFormDetails('feedbackCategory', feedbackData);
		if (!(feedbackData && feedbackData.isTimeframeVisible)) {
			//updateFeedbackFormDetails('timeFrame', null);
		}
		setIsTimeFrameVisible(feedbackData.timeframe !== 3 || false);
		setIsTimeFrameRequired(feedbackData.timeframe === 1 || false);
		setIsCreateTimeframe(feedbackData.timeframeType === 2 || false);
		setSelectedDateRange(null);
		setSelectedStartDate(null);
		setSelectedEndDate(null);
		setExtendDateToggle(true);
		if (!feedbackFormDetails?.fromExtension) {
			setIsDatesDisabled({ start: false, end: false });
		}
	};

	const handleInputThrottled = (value: any, key: string) => {
		if (key === 'title') {
			//setFeedbackTitle(value);
		} else {
			//setFeedbackSummary(value);
		}
		updateFeedbackFormDetails(key, value);
	};
	const handleReasonDropdown = () => {
		setOpenReasonDropdown(true);
	};
	const handleReasonClickAway = () => {
		setOpenReasonDropdown(false);
	};
	const handleReasonSelect = (reasonType: any) => {
		setSelectedReason(reasonType);
		let hasAlreadySelectedValue = feedbackFormDetails?.feedbackTimeframeResponse.find(
			(item: any) => item?.feedbackTimeframeId === Enums.ZERO
		);
		let timeFrameResponse = feedbackFormDetails?.feedbackTimeframeResponse;
		if (!hasAlreadySelectedValue || hasAlreadySelectedValue.length === 0) {
			timeFrameResponse.push({
				feedbackTimeframeId: Enums.ZERO,
				feedbackId: 0,
				extendTimeframeStartDate: feedbackFormDetails.timeframeStartDate?._d
					? formatFeedbackDate(feedbackFormDetails.timeframeStartDate?._d)
					: '',
				extendTimeframeEndDate: feedbackFormDetails.timeframeEndDate?._d
					? formatFeedbackDate(feedbackFormDetails.timeframeEndDate?._d)
					: '',
				feedbackReasonId: reasonType?.feedbackReasonId,
				createdBy: userDetail.employeeId,
			});
		}
		const extendedTimeFrameData = {
			isTimeFrameChanged: true,
			feedbackTimeframeResponse:
				timeFrameResponse && timeFrameResponse?.length
					? timeFrameResponse?.map((rec: any) => {
							const item = { ...rec };
							if (item?.feedbackTimeframeId === Enums.ZERO) {
								item.feedbackReasonId = reasonType?.feedbackReasonId;
							}
							return item;
					  })
					: [],
		};
		handleReasonClickAway();
		updateFeedbackFormDetails('timeFrameDates', {
			...extendedTimeFrameData,
			timeframeStartDate: feedbackFormDetails?.timeframeStartDate,
			timeframeEndDate: feedbackFormDetails?.timeframeEndDate,
		});

		setValidationCheck({
			...validationCheck,
			reason: '',
		});
		handleReasonClickAway();
	};
	const getSelectedReasonLabelName = () => {
		const selectedItem =
			timeFrameChangeReasonTypes && timeFrameChangeReasonTypes?.length
				? timeFrameChangeReasonTypes.find((item: any) => item?.feedbackReasonId === Enums.ZERO)
				: null;
		// check here
		return selectedItem?.feedbackReasonId || Enums.ONE;
	};
	return (
		<Box className='drawer-content-panel create-feedback-drawer'>
			<form className='drawer-form-fields' noValidate autoComplete='off'>
				<Box className='drawer-form-fields'>
					<Box className='drawer-input-field' id='feedback_nominee_container'>
						<Box className='helperText-bottom'>
							<MultiSelect
								id='pip_nominee_dropdown'
								selectedOptions={feedbackFormDetails?.nomineeDetails || null}
								optionsList={reportingToOptions}
								onSelectOption={(value: any) => handleUserSelectionClick(value)}
								disabled={
									feedbackFormType !== 'Add' &&
									(feedbackFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE ||
										feedbackFormDetails?.isExtensionsAllowed)
								}
								isMulti={true}
								customFilter={() => {}}
								placeHolder={t('searchColleagueToNominate')}
								noOptionsMessage={<NoSearchRecord />}
								showNoOptions={emptyRecords.reportingTo === true}
								fetchAsyncData={true}
								isSingleSelection={true}
								performSearch={searchReportingTo}
								closeMenuOnSelect={true}
								labelTemplate={'avatarLabel'}
								selectClassName={'select-search-dropdown select-search-icon feedback-search-field '}
								selectClassNamePrefix={'react-select'}
								error={Boolean(validationCheck?.nomineeId)}
								helperText={
									Boolean(validationCheck?.nomineeId) && (
										<Fragment>
											<ErrorIcon /> {validationCheck?.nomineeId || ''}
										</Fragment>
									)
								}
								// hideLoggedIn={true}
								// loggedInUserIds={allReviewersList}
								allReviewersList={allReviewersList}
							/>
						</Box>
					</Box>
					<Box className='drawer-blue-bg-full'>
						<Box className='drawer-form-fields-group'>
							<Box className='drawer-input-field drawer-input-field-col2'>
								<Box className='field-col2 helperText-bottom' id='feedback_type'>
									<FormLabel id='scale-type' component='legend'>
										<ScaleType />
										{t('typeLabel')}
										<i style={{ color: 'red' }}>*</i>
									</FormLabel>
									<FeedbackTypeDropdown
										feedbackTypesOptions={feedbackTypesOptions}
										setSelectedValue={handleTypeSelection}
										selectedValue={feedbackFormDetails.feedbackType}
										disabled={feedbackFormDetails?.fromExtension}
									/>
									{Boolean(validationCheck?.feedbackType) && (
										<Typography className='error-field'>
											<ErrorIcon /> {validationCheck?.feedbackType || ''}
										</Typography>
									)}
								</Box>
								{feedbackFormDetails?.feedbackType &&
								feedbackFormDetails?.feedbackType?.feedbackTypeId &&
								isCategoryVisible ? (
									<Box className='field-col2 helperText-bottom' id='feedback_category'>
										<FormLabel id='scale-type' component='legend'>
											<CategoryIcon />
											{t('categoryLevel')}
											<i style={{ color: 'red' }}>*</i>
										</FormLabel>
										<FeedbackTypeDropdown
											feedbackTypesOptions={categoriesOptions}
											setSelectedValue={handleCategorySelection}
											selectedValue={feedbackFormDetails.feedbackCategory}
											disabled={feedbackFormDetails?.fromExtension}
										/>
										{Boolean(validationCheck?.feedbackCategory) && (
											<Typography className='error-field'>
												<ErrorIcon /> {validationCheck?.feedbackCategory || ''}
											</Typography>
										)}
										{/* <Typography variant='h6' className='input-label-pad'>
											25 days review period
										</Typography> */}
									</Box>
								) : (
									<></>
								)}
							</Box>
							{/**
							 * isTimeFrameVisible &&
							 */}
							{isTimeFrameVisible && (
								<>
									<Box className='drawer-input-field drawer-input-field-col2' id='feedback_timeframe'>
										<Box className='field-col2'>
											<Box className='drawer-input-field drawer-input-field-col2 feedback-start-end-date'>
												<Box className='field-col2'>
													<Box className='drawer-date-field helperText-bottom'>
														<FormLabel id='pip-timeframe' component='legend'>
															<CalendarIcon />
															{t('startDateLabel')}
															{isTimeFrameRequired && <i style={{ color: 'red' }}>*</i>}
														</FormLabel>
														<Box className='range-selector-calender'>
															<ReactDateRangePicker
																type={'FeedbackDates'}
																selectedCycleDetails={{}}
																minimumDate={
																	employeeFeedbackPermission.isPastDateFeedbackCreationAllow
																		? new Date(minDateValue)
																		: new Date()
																}
																maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
																handleDateSelection={(
																	range: any,
																	start: any,
																	end: any,
																	selectionType: any,
																	isCycleChanged: any
																) => {
																	if (range) {
																		if (isCreateTimeframe) {
																			const someDate = new Date(range._d);
																			const numberOfDaysToAdd =
																				feedbackFormDetails.feedbackCategory?.timeframeDuration - 1 || 10;
																			const result = moment(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));
																			setFeedbackFormDetails({
																				...feedbackFormDetails,
																				...{ timeframeStartDate: range, timeframeEndDate: result },
																			});
																			setValidationCheck({
																				...validationCheck,
																				timeframeStartDate: '',
																				timeframeEndDate: '',
																			});
																			setMinDateValue(range);
																			setSelectedStartDate(range);
																			setSelectedEndDate(result);
																		} else {
																			setSelectedStartDate(range);
																			setMinDateValue(range);
																			updateFeedbackFormDetails('timeframeStartDate', range);
																		}
																	}
																}}
																isDateRangeTextFieldVisible={true}
																isOnlyDueDateVisible={false}
																allCycleDetails={[]}
																isCycleVisible={false}
																isEndOfMonthVisible={false}
																numberOfCalendars={Enums.ONE}
																selectionType={'single'}
																selectedDateRange={selectedStartDate || null}
																setSelectedDateRange={(range: any) => {
																	if (range) {
																		updateFeedbackFormDetails('timeframeStartDate', range);
																		setSelectedStartDate(range);
																	}
																}}
																placeholderText={t('selectedDate')}
																isDatePickerDisable={isDatesDisabled.start}
															/>
														</Box>
														{Boolean(validationCheck?.timeframeStartDate) && (
															<Typography className='error-field'>
																<ErrorIcon /> {validationCheck?.timeframeStartDate || ''}
															</Typography>
														)}
													</Box>
												</Box>
												<Box className='field-col2'>
													<Box className='drawer-date-field helperText-bottom'>
														<FormLabel id='pip-timeframe' component='legend'>
															<CalendarIcon />
															{t('dueDate')}
															{isTimeFrameRequired && <i style={{ color: 'red' }}>*</i>}
														</FormLabel>
														<Box className='range-selector-calender'>
															<ReactDateRangePicker
																type={'FeedbackDates'}
																selectedCycleDetails={{}}
																minimumDate={minEndDateValue ? feedbackFormDetails.timeframeStartDate : new Date()}
																maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
																handleDateSelection={(
																	range: any,
																	start: any,
																	end: any,
																	selectionType: any,
																	isCycleChanged: any
																) => {
																	if (range) {
																		setSelectedEndDate(range);
																		if (!feedbackFormDetails?.fromExtension && feedbackFormDetails.feedbackId) {
																			let hasAlreadySelectedValue = feedbackFormDetails?.feedbackTimeframeResponse.find(
																				(item: any) => item?.feedbackTimeframeId === Enums.ZERO
																			);
																			if (!hasAlreadySelectedValue || hasAlreadySelectedValue.length === 0) {
																				let timeFrameResponse = feedbackFormDetails?.feedbackTimeframeResponse;
																				console.log(range);
																				timeFrameResponse.push({
																					feedbackTimeframeId: Enums.ZERO,
																					feedbackId: feedbackFormDetails.feedbackId,
																					extendTimeframeStartDate: feedbackFormDetails.timeframeStartDate?._d
																						? formatFeedbackDate(feedbackFormDetails.timeframeStartDate?._d)
																						: '',
																					extendTimeframeEndDate: range?._d ? formatFeedbackDate(range?._d) : '',
																					feedbackReasonId: 0,
																					createdBy: userDetail.employeeId,
																				});
																				setFeedbackFormDetails({
																					...feedbackFormDetails,
																					timeframeEndDate: range,
																					isTimeFrameChanged: true,
																					timeFrameResponse: timeFrameResponse,
																				});
																			}
																		} else {
																			setFeedbackFormDetails({
																				...feedbackFormDetails,
																				timeframeEndDate: range,
																				isTimeFrameChanged: true,
																			});
																		}
																	}
																}}
																isDateRangeTextFieldVisible={true}
																isOnlyDueDateVisible={false}
																allCycleDetails={[]}
																isCycleVisible={false}
																isEndOfMonthVisible={false}
																numberOfCalendars={Enums.ONE}
																selectionType={'single'}
																selectedDateRange={selectedEndDate || null}
																setSelectedDateRange={(range: any) => {
																	if (range) {
																		updateFeedbackFormDetails('timeframeEndDate', range);
																		setSelectedEndDate(range);
																	}
																}}
																placeholderText={t('selectedDate')}
																isDatePickerDisable={isDatesDisabled.end}
															/>
														</Box>
														{Boolean(validationCheck?.timeframeEndDate) && (
															<Typography className='error-field'>
																<ErrorIcon /> {validationCheck?.timeframeEndDate || ''}
															</Typography>
														)}
													</Box>
												</Box>
											</Box>
										</Box>
										<Box className='field-col2'>
											{openCreatePipForm.type === 'Edit' &&
												feedbackFormDetails?.fromExtension &&
												feedbackFormDetails?.feedbackCategoryResponse?.editEndDateType === 3 && (
													<Box className='extend-edit-date'>
														<FormControlLabel
															control={<Switch name='feedbackTypeStatus' checked={extendDateToggle} />}
															label={t('extendEndDate')}
															checked={extendDateToggle}
															onChange={(e: any) => {
																setExtendDateToggle(e.target.checked);
																let endDateValue: any = new Date(
																	openCreatePipForm.feedbackFormEditDetails.timeframeEndDate
																);
																if (e.target.checked) {
																	endDateValue = moment(
																		endDateValue.setDate(
																			endDateValue.getDate() +
																				(openCreatePipForm.feedbackFormEditDetails.feedbackCategoryResponse
																					.autoExtensionsDays -
																					1)
																		)
																	);
																}
																setFeedbackFormDetails({
																	...feedbackFormDetails,
																	timeframeEndDate: endDateValue,
																	isTimeFrameChanged: e.target.checked,
																});
																setSelectedEndDate(endDateValue);
															}}
														/>
														<HeaderTooltip
															infoTooltipClass='extend-feedback-info-tooltip'
															tooltipText={t('extendEndDateInfoText', {
																extendDays:
																	openCreatePipForm.feedbackFormEditDetails.feedbackCategoryResponse
																		.autoExtensionsDays || 0,
															})}
															tooltipPlacement='top'
														/>
													</Box>
												)}
										</Box>
									</Box>
									{feedbackFormDetails?.fromExtension && feedbackFormDetails?.isTimeFrameChanged && (
										<Box className='drawer-input-field' id='feedback_extend_reason'>
											<Box className='pip-input-field '>
												<Box className='helperText-bottom'>
													<FormLabel id='pip-reason' component='legend'>
														<ReasonIcon />
														{t('reasonLabel')}
														<i style={{ color: 'red' }}>*</i>
													</FormLabel>
													<Box
														className={`ques-type-selection custom-type-dropdown-top ${
															openReasonDropdown ? 'active' : ''
														}`}
													>
														<OKRButton
															className={'dropdown-button button-placeholder'}
															text={
																selectedReason && selectedReason.name
																	? selectedReason.name
																	: feedbackFormDetails?.feedbackTimeframeResponse?.length
																	? getSelectedReasonLabelName() ===
																	  feedbackFormDetails?.feedbackTimeframeResponse.feedbackReasonId
																		? t('timeframeExtension')
																		: getSelectedReasonLabelName() === Enums.TWO
																		? t('changeOfPlan')
																		: '- Select -'
																	: '- Select -'
															}
															icon={<BottomArrowIcon />}
															handleClick={() => handleReasonDropdown()}
														></OKRButton>

														{openReasonDropdown && (
															<ClickAwayListener onClickAway={handleReasonClickAway}>
																<Box className='ques-type-dropdown'>
																	<List component='div' disablePadding>
																		{timeFrameChangeReasonTypes && timeFrameChangeReasonTypes?.length ? (
																			timeFrameChangeReasonTypes?.map((item: any, reasonIndex: number) => {
																				return (
																					<ListItem
																						component='div'
																						className={
																							getSelectedReasonLabelName() === item?.feedbackReasonId ? 'active' : ''
																						}
																						onClick={() => {
																							handleReasonSelect(item);
																						}}
																					>
																						<Typography>{item?.name || ''}</Typography>
																					</ListItem>
																				);
																			})
																		) : (
																			<></>
																		)}
																	</List>
																</Box>
															</ClickAwayListener>
														)}
													</Box>
													{Boolean(validationCheck?.reason) && (
														<Typography className='error-field'>
															<ErrorIcon /> {validationCheck?.reason || ''}
														</Typography>
													)}
												</Box>
												<Typography variant='h6' className='input-label-pad'>
													{t('reasonNote')}
												</Typography>
											</Box>
										</Box>
									)}
								</>
							)}

							<Box className='drawer-input-field' id='feedback_title'>
								<TextField
									fullWidth
									id='feedback-title'
									label={
										<Fragment>
											<NameRenameIcon />
											{t('titleLabel')}
											<i style={{ color: 'red' }}>*</i>
										</Fragment>
									}
									className='helperText-bottom'
									maxRows={3}
									multiline
									placeholder={t('enterTitleText')}
									inputProps={{ maxLength: 50 }}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										'aria-label': t('titleLabel'),
										disableUnderline: true,
									}}
									name={'title'}
									value={feedbackFormDetails.title}
									onChange={(e: any) => handleInputThrottled(e.target.value, 'title')}
									helperText={
										Boolean(validationCheck?.title) && (
											<Fragment>
												<ErrorIcon />
												{validationCheck?.title || ''}
											</Fragment>
										)
									}
								/>
								<Typography variant='h6' className='input-label-pad'>
									{50 - feedbackFormDetails.title.length} {t('charactersRemaining')}
								</Typography>
							</Box>
							<Box className='drawer-input-field'>
								<TextField
									fullWidth
									id='feedback-summary'
									label={
										<Fragment>
											<DocumentIcon />
											{t('summaryLabel')}
										</Fragment>
									}
									className='helperText-bottom'
									maxRows={3}
									multiline
									placeholder={t('feedbackSummaryPlaceholder')}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										'aria-label': t('summaryLabel'),
										disableUnderline: true,
									}}
									name={'summary'}
									value={feedbackFormDetails.summary}
									onChange={(e: any) => handleInputThrottled(e.target.value, 'summary')}
								/>
							</Box>
							<Box className='drawer-input-field helperText-bottom'>
								<FormLabel id='pip-review-dates' component='legend'>
									<ReviewersIcon />
									{t('reviewersLabel')}
								</FormLabel>
								<ReviewDates
									t={t}
									pipFormType={feedbackFormType}
									pipFormDetails={feedbackFormDetails}
									updatePipFormDetails={updateFeedbackFormDetails}
									hideDate={true}
									keyName={'feedbackReviewerResponse'}
									hideMoreDated={true}
									minDateValue={minDateValue}
									maxDateValue={maxDateValue}
									hideCloneIcon={true}
									noValidate={true}
									showMsg={showMsg}
									feedbackReviewersList={feedbackReviewersList}
								/>
								<Box className='feedback-reviewers-notes'>
									<Typography variant='h6'>{t('feedbackReviewersNotes')}</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box className='drawer-input-field'>
						<UploadDocuments
							{...props}
							updatePipFormDetails={updateFeedbackFormDetails}
							pipFormDetails={feedbackFormDetails}
							featureListType={5}
							pipFormType={feedbackFormType}
							setLoader={() => {}}
							uploadDocumentType='pipFeedbackAttachments'
						/>
					</Box>
				</Box>
			</form>
		</Box>
	);
};
