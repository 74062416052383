import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { InputBase, Tabs, Tab, ClickAwayListener, Box, Typography, Avatar, Tooltip } from '@material-ui/core';
import { SearchIcon, LinkDeleteIcon, SearchBlueIcon, LinkTeamIcon } from '../../config/svg/CommonSvg';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import _ from 'lodash';
import { Enums } from '../../config/enums';
import { NoSearchRecord } from '../Admin/CommonComponent/NoSearchRecord';
import { getLocalStorageItem } from '../../services/StorageService';
import { OKRButton } from '../Common/OKRButton';

export const LinkObjectivePopover: React.FC<any> = (props) => {
	const {
		linkObjectiveOpen,
		setLinkObjectiveOpen,
		linkSelected,
		selectedLink,
		t,
		globalSearchAPIWithTeam,
		scrollTo,
		loggedInUserDetail,
		setIsLinkObjectiveInitiated,
		isLinkObjectiveInitiated,
	} = props;
	const [filteredList, setfilteredList] = useState<any[]>([]);
	const [searchValue, setsearchValue] = useState<string>('');
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [hideList, setHideList] = useState<boolean>(true);
	const [initial, setInitial] = useState<any>({
		isLoading: false,
		options: [],
		query: '',
		page: 1,
		selected: null,
		totalCount: 0,
	});
	const cycleId: any = getLocalStorageItem('cycleId') || 0;
	const [linkObjectiveLoading, setlinkObjectiveLoading] = useState<boolean>(false);
	useEffect(() => {
		if (isLinkObjectiveInitiated === false && linkObjectiveOpen === true) {
			setIsLinkObjectiveInitiated(true);
			fetchUserObjectiveList(null);
			scrollTo();
		}
	}, [isLinkObjectiveInitiated]);

	useEffect(() => {
		if (isLinkObjectiveInitiated === false && linkObjectiveOpen === true) {
			setIsLinkObjectiveInitiated(true);
			fetchUserObjectiveList(loggedInUserDetail);
		}
	}, [linkObjectiveOpen]);

	useEffect(() => {
		if (props?.selectedCycleDetails && props?.selectedCycleDetails?.organisationCycleId !== parseInt(cycleId)) {
			linkSelected({ objectiveId: 0 });
			setfilteredList([]);
			clearSearch();
		}
	}, [props.selectedCycleDetails]);

	const handleClose = (event: any) => {
		setLinkObjectiveOpen(false);
		if (initial.selected === null && searchValue !== '') {
			setInitial({
				...initial,
				isLoading: false,
				options: [],
				totalCount: 0,
				selected: null,
				page: 1,
			});
			setHideList(true);
			setsearchValue('');
			setCurrentPage(1);
		}
	};

	const onSelectedUser = (selected: any) => {
		if (selected && selected.employeeId) {
			fetchUserObjectiveList(selected);
			setHideList(true);
			setInitial({
				...initial,
				isLoading: false,
				options: [],
				totalCount: 0,
				selected: selected,
			});
		}
	};

	const fetchUserObjectiveList = async (userData: any) => {
		setlinkObjectiveLoading(true);
		let apiRes;
		if (userData) {
			if (isLinkObjectiveInitiated === false && linkObjectiveOpen === true) {
				apiRes = await props.getLinkObjectiveData(
					'?searchEmployeeCycleId=' + props?.selectedCycleDetails?.organisationCycleId
				);
			} else {
				apiRes = await props.getLinkObjectiveData(
					'?searchEmployeeId=' +
						userData.employeeId +
						'&searchEmployeeCycleId=' +
						props?.selectedCycleDetails?.organisationCycleId
				);
			}
		} else {
			apiRes = await props.getLinkObjectiveData('');
		}
		if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
			setfilteredList(apiRes.data.entityList);
		}
		setlinkObjectiveLoading(false);
		setLinkObjectiveOpen(true);
		scrollTo();
	};

	useEffect(() => {
		if (currentPage > 1) {
			_handleSearch(searchValue);
		}
	}, [currentPage]);

	const makeAndHandleRequest = async (query: any, page = 1) => {
		setInitial({ ...initial, isLoading: true, selected: null });
		let resp;
		if (currentPage > 1) {
			resp = await globalSearchAPIWithTeam(query, currentPage, 6, 1);
		} else {
			resp = await globalSearchAPIWithTeam(query, page, 6, 1);
		}
		const { entityList, paggingInfo } = resp.data;
		let list = entityList;
		if (list) {
			const options = list;
			return { options, totalCount: paggingInfo.totalRecords, totalPages: paggingInfo.totalPages };
		} else {
			return { options: [], totalCount: 0 };
		}
	};

	const _handleSearch = async (query: any) => {
		if (query.trim() !== '') {
			setsearchValue(query);
			const resp = await makeAndHandleRequest(query.trim());
			resp.options = resp.options.filter((user: any) => user.employeeId !== loggedInUserDetail.employeeId);

			setInitial({
				...initial,
				isLoading: false,
				options: initial.options.length ? [...initial.options, ...resp.options] : resp.options,
				totalCount: resp.totalCount,
				selected: null,
				totalPages: resp.totalPages,
			});
			setHideList(false);
		}
	};
	const clearSearch = () => {
		setsearchValue('');
		setInitial({
			...initial,
			isLoading: false,
			options: [],
			totalCount: 0,
			selected: null,
		});
	};
	const delayedQuery = useCallback(
		_.debounce((q) => _handleSearch(q), 500),
		[]
	);
	const handleInputThrottled = (value: any) => {
		setsearchValue(value);
		delayedQuery(value);
	};
	const NoRecordFound = (detail: any) => {
		const { text } = detail;

		return <NoSearchRecord />;
	};

	return (
		<Box id='objective-link'>
			<Box className={` ${linkObjectiveOpen ? 'link-objective-scroll' : ''}`}>
				{linkObjectiveOpen ? (
					<Box className='link-objective-popover'>
						<ClickAwayListener onClickAway={handleClose}>
							<Box className='link_objective_main'>
								{hideList && initial.selected !== null ? (
									<Fragment>
										<Box className='user-info selected-user-link'>
											<Box className='user-img'>
												{initial.selected.imagePath ? (
													<Avatar src={initial.selected.imagePath} />
												) : (
													<Avatar
														style={{
															backgroundColor: getRandomBackgroundColor(initial.selected.backGroundColorCode),
															color: getRandomColor(initial.selected.colorCode),
														}}
													>
														{getUserName(initial.selected)}
													</Avatar>
												)}
											</Box>
											<Box className='user-info-details'>
												<Box className='user-info-label'>
													<Typography variant='h4' key={initial.selected.employeeId}>
														<Box>{`${initial.selected.firstName} ${initial.selected.lastName}`}</Box>
													</Typography>
													<Typography variant='subtitle2' color='textSecondary'>
														{initial.selected.designation}
													</Typography>
												</Box>
											</Box>
											<Box
												className='search-user-close-icon'
												onClick={(e) => {
													clearSearch();
													setfilteredList([]);
													linkSelected(null);
													fetchUserObjectiveList(null);
													setHideList(true);
												}}
											>
												<LinkDeleteIcon />
											</Box>
										</Box>
									</Fragment>
								) : (
									<Box className='search-link-objective'>
										<div className='search-btn no-border'>
											<div className='search-icon'>{searchValue !== '' ? <SearchBlueIcon /> : <SearchIcon />}</div>
											<InputBase
												placeholder={'Search employee'}
												className='search-area objective-search no-border'
												autoFocus={true}
												onChange={(e) => handleInputThrottled(e.target.value)}
												onKeyDown={(e: any) => {
													if (e.keyCode === 13 && initial?.options?.length === 1) {
														onSelectedUser(initial.options[0]);
													}
												}}
												value={searchValue}
												id='search-emp'
											/>
											{searchValue && searchValue.trim().length > 0 && (
												<span
													onClick={(e) => {
														clearSearch();
														setHideList(true);
													}}
												>
													<LinkDeleteIcon />
												</span>
											)}
										</div>
									</Box>
								)}
								<Box className={` ${hideList ? 'disp-none' : ''} link-objective-search-users`} id='custom-scroll'>
									{initial.options.length > 0 ? (
										initial.options.map((option: any) => {
											return (
												<Fragment>
													<Box
														onClick={(e) => onSelectedUser(option)}
														className='user-info'
														id={`search-user-${option.employeeId}`}
													>
														<Box className='user-img'>
															{option.imagePath ? (
																<Avatar src={option.imagePath} />
															) : (
																<Avatar
																	style={{
																		backgroundColor: getRandomBackgroundColor(option.backGroundColorCode),
																		color: getRandomColor(option.colorCode),
																	}}
																>
																	{getUserName(option)}
																</Avatar>
															)}
														</Box>
														<Box className='user-info-details'>
															<Box className='user-info-label'>
																<Typography variant='h4' key={option.employeeId} className='info-details'>
																	{`${option.firstName} ${option.lastName}`}
																</Typography>
																<Typography variant='subtitle2' color='textSecondary'>
																	{option.designation}
																</Typography>
															</Box>
														</Box>
													</Box>
													{/* {roleData && roleData?.totalPages !== props.pageIndex && roleData?.roleId > 0 && ( */}
													{/* )} */}
												</Fragment>
											);
										})
									) : searchValue !== '' ? (
										<NoRecordFound />
									) : (
										''
									)}
									{initial.totalPages !== currentPage && (
										<Box className='alignCenter' display={'flex'} justifyContent={'center'}>
											<OKRButton
												id='load-more'
												className='btn-link'
												handleClick={() => {
													setCurrentPage(currentPage + 1);
												}}
												text={'Display additional results...'}
											></OKRButton>
										</Box>
									)}
								</Box>
								<Box className='linking-objective-content'>
									{initial.selected === null && (
										<Box className={` ${!hideList ? 'blur-list' : ''} link-objective-tabs`}>
											<Tabs className='okr-tabs' aria-label='OKR Tabs'>
												<Tab icon={<LinkTeamIcon />} value={2} label='My Team' />
											</Tabs>
										</Box>
									)}
									<Box className={` ${!hideList ? 'blur-list' : ''} link_objective_data`} id='custom-scroll'>
										{filteredList.length > 0 && !linkObjectiveLoading ? (
											<ul className='objective-ul'>
												{filteredList.map((item: any) => (
													<li
														className={`objective-li ${item.objectiveId === selectedLink ? 'active' : ''}`}
														id={`objective-li-${item.objectiveId}`}
														onClick={() => {
															linkSelected(item);
															setLinkObjectiveOpen(false);
														}}
													>
														<Typography className='objective-label'>{item.objectiveName}</Typography>
														<Typography className='objective-chips'>
															{item.dueCycle && <span className='objective-chip quarter-chip'> {item.dueCycle}</span>}
															{item.teamName && (
																<Tooltip title={item.teamName} arrow>
																	<span
																		className='objective-chip performance-chip'
																		style={{
																			backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																			color: getRandomColor(item?.colorCode),
																		}}
																	>
																		{' '}
																		{item.teamName}
																	</span>
																</Tooltip>
															)}
															{/*<span className='objective-chip management-chip'>	{item.performance}</span>*/}
														</Typography>
													</li>
												))}
											</ul>
										) : linkObjectiveLoading ? (
											<div className='teams-loader'>
												<span>{t('loading')}</span>
											</div>
										) : (
											<NoRecordFound />
										)}
									</Box>
								</Box>
							</Box>
						</ClickAwayListener>
					</Box>
				) : (
					<span></span>
				)}
			</Box>
		</Box>
	);
};
