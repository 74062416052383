import {
	Avatar,
	Badge,
	Box,
	Chip,
	ClickAwayListener,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { getUserName } from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';
import { RecommendedIcon } from '../../../config/svg/CommonSvgIcon';
import { ConversationIcon } from '../../../config/svg/CommonSvg';

export const TeamReviewData = (props: any) => {
	const { users, handleSettingsDrawerOpen, t } = props;
	const [openTeamMenu, setOpenTeamMenu] = useState<boolean>(false);
	const [anchorElTeam, setAnchorElTeam] = useState<HTMLElement | null>(null);
	const [openRecommendPopup, setOpenRecommendPopup] = useState<boolean>(false);
	const [anchorElRecommend, setAnchorElRecommend] = useState<HTMLElement | null>(null);

	const handleOpenTeam = (event: any) => {
		setAnchorElTeam(event.currentTarget);
		setOpenTeamMenu(true);
	};
	const handleCloseTeam = (event: any) => {
		setAnchorElTeam(null);
		setOpenTeamMenu(false);
	};
	const handleOpenRecommendPopup = (event: any) => {
		setAnchorElRecommend(event.currentTarget);
		setOpenRecommendPopup(true);
	};
	const handleCloseRecommendPopup = (event: any) => {
		setAnchorElRecommend(null);
		setOpenRecommendPopup(false);
	};

	return (
		<>
			<Fragment key={`permission${1}`}>
				<TableRow className={'team-review-table-row'}>
					<TableCell className='user-col1'>
						<Box className='cell-wrap'>
							<Box className='user-info v-center'>
								<Box className='user-img'>
									<Avatar className='avatar-default' key={`user-icon-${users.employeeId}`} src={users.imagePath || ''}>
										{getUserName({
											firstName: users.firstName || '',
											lastName: users.lastName || '',
											fullName: users.firstName + ' ' + users.lastName || '',
										})}
									</Avatar>
								</Box>
								<Box className='user-info-details'>
									<Tooltip arrow title={users.firstName + ' ' + users.lastName}>
										<Typography>{users.firstName + ' ' + users.lastName || ''}</Typography>
									</Tooltip>
								</Box>
							</Box>
						</Box>
					</TableCell>
					<TableCell className='user-col2'>
						<Box className='cell-wrap'>
							<Tooltip arrow title={users.teamName}>
								<Typography>{users.teamName}</Typography>
							</Tooltip>
							{users.employeeTeamDetails.length - 1 > 0 && (
								<Box
									className='other-teams-chip'
									aria-owns={openTeamMenu ? 'mouse-over-popover' : undefined}
									aria-haspopup='true'
									onMouseOver={handleOpenTeam}
									onMouseLeave={handleCloseTeam}
								>
									<Typography variant='body2'>{users.employeeTeamDetails.length - 1}</Typography>
									<Popper
										className='popper-menu-list team-filter-list-menu'
										transition
										open={openTeamMenu}
										anchorEl={anchorElTeam}
										placement='bottom-start'
									>
										<Paper>
											<ClickAwayListener onClickAway={handleCloseTeam}>
												<MenuList id={`team-review-team-list`}>
													{users.employeeTeamDetails.length > 0 &&
														users.employeeTeamDetails.map((item: any, index: number) => (
															<>
																{index > 0 && (
																	<MenuItem key={item?.teamId} id={`team-name-${item?.teamId}-${index}`}>
																		<ListItemText
																			primary={
																				<>
																					{item?.teamName && item?.teamName.length > 25 ? (
																						<Tooltip arrow title={item?.teamName} aria-label='Teams'>
																							<Typography variant='h5' className='team-filter-txt'>
																								{item?.teamName.substring(0, 25)}...
																							</Typography>
																						</Tooltip>
																					) : (
																						<Typography>{item?.teamName}</Typography>
																					)}
																				</>
																			}
																		/>
																	</MenuItem>
																)}
															</>
														))}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Popper>
								</Box>
							)}
						</Box>
					</TableCell>
					<TableCell className='user-col3'>
						<Box className='cell-wrap'>
							<Typography
								className={`font-weight-bold ${users.selfFinalRatingId > 0 ? 'cursor-pointer clickable-text' : ''}`}
							>
								{users.selfReviewStatus}
							</Typography>
							<Typography variant='body2' className='font-weight-normal'>
								{users.selfFinalRatingId > 0 && users.selfFinalRating}
							</Typography>
						</Box>
					</TableCell>
					<TableCell className='user-col4'>
						<Box className='cell-wrap'>
							{users.managerReviewStatus && (
								<OKRButton
									className='btn-link-type1'
									disabled={users.selfFinalRatingId <= 0}
									text={users.managerReviewStatus}
								/>
							)}
							{users.finalRatingId > 0 && (
								<Box className='manager-review-status'>
									<Typography variant='body2' className='font-weight-normal'>
										{users.selfFinalRatingId > 0 && users.selfFinalRating}
									</Typography>
									<Box
										className=''
										aria-owns={openRecommendPopup ? 'mouse-over-popover' : undefined}
										aria-haspopup='true'
									>
										<OKRButton
											handleClick={handleOpenRecommendPopup}
											className='recommended-btn'
											icon={<RecommendedIcon />}
										/>
										<Popper
											className='recommend-popper'
											transition
											open={openRecommendPopup}
											anchorEl={anchorElRecommend}
											placement='bottom-start'
										>
											<Paper>
												<ClickAwayListener onClickAway={handleCloseRecommendPopup}>
													<Typography>Test</Typography>
												</ClickAwayListener>
											</Paper>
										</Popper>
									</Box>
								</Box>
							)}
						</Box>
					</TableCell>
					<TableCell className='user-col5'>
						<Box className='cell-wrap'>
							<Typography variant='body2' className='font-weight-normal'>
								{users.performanceGoalReviewWorkFlowType}
							</Typography>
							<Box className='team-review-status'>
								<Chip className='lagging-chip' label={users.statusName} />
							</Box>
						</Box>
					</TableCell>
					<TableCell className='user-col6'>
						<Box className='cell-wrap'>
							<Typography>
								<OKRButton
									className={`conversation-btn`}
									wrapClassName={'conversation-btn-wrap'}
									icon={
										<Badge
											variant='dot'
											className='dot-bubble'
											overlap='circular'
											invisible={!users.isConversationRedDotAlert ? true : false}
										>
											<ConversationIcon />
										</Badge>
									}
									title={t('reviewConversation')}
									handleClick={(event: any) => handleSettingsDrawerOpen(event, users, 'conversation')}
								/>
							</Typography>
						</Box>
					</TableCell>
				</TableRow>
			</Fragment>
		</>
	);
};
