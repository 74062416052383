import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	getObjectiveList,
	resetOkr,
	addMyGoals,
	updateKrProgress,
	deleteObjective,
	getAlignObjective,
	editMyGoals,
	updateStatus,
	deleteContributor,
	unlockRequest,
	alignMyGoals,
	getOkrStatusList,
	getEmployeeViewGoals,
	getDashboardDetail,
	getDeltaScore,
	getDashboardUpdatedDetails,
	checkAlignStatus,
	downloadPdf,
	updateGoalDetails,
	getGoalsDetails,
	updateSequence,
	getOkrMasterData,
	addUpdateObjective,
	getKrContributors,
	updateGoalAndKrName,
	updateGoalAttributes,
	updateKrAttributes,
	acceptKr,
	updateKrProgressValue,
	becomeContributor,
	fetchObjectiveList,
	getKrDetail,
	getOkrDetail,
	getUserTeamsData,
	getOwnerTeamsData,
	getMyTeamDetails,
	updateNudgeTeams,
	getMyTeamOKRDetails,
	resetObjective,
	getLinkOwnerData,
	updateNudgePerson,
	getLinkObjectiveData,
	changeOwnerData,
	getRecentContributordDetail,
	virtualAlignment,
	getArchiveDetail,
	getDashboardProgress,
	getTeamsOkrProgress,
	getObjectiveDetails,
	getTasks,
	deleteTask,
	updateTask,
	postNewTask,
	taskCompleted,
	resetTasks,
	postProgressConversation,
	getConversationById,
	isCheckinSubmitted,
	getCheckInWeeklyDates,
	getCheckInData,
	updateCheckInData,
	getUserDirectEmployees,
	updateConfidenceData,
	getWeeklyTrendDetails,
	getConfidenceAlertDetails,
	peopleViewFeedback,
	fetchFeedbackReport,
	getOkrImport,
	getOkrImportCategory,
	postOkrImport,
	postMyOkrImport,
	updateCheckInVisibilityData,
	checkInImport,
	underAssign,
	getCheckInSettingList,
	saveCheckInSettingList,
	deleteCheckInSettingList,
	getCheckInAlreadyAdded,
} from '../../action/myGoal';
import { fetchFeedback } from '../../action/feedback';
import { addAlignmentFilter, updateObjetciveDueDate } from '../../action/alignment';
import {
	getOnboarding,
	updateOnboarding,
	updateOnboardingCount,
	getOnboardingCount,
	updateCalloutPanel,
} from '../../action/onboarding';
// import { Goals } from '../../components/Goals';
import {
	showNotification,
	updateDashboardProgress,
	isAnyDrawerOpened,
	showRefreshSnackBar,
} from '../../action/signalR';
import {
	getOrgList,
	fetchOrganizationsList,
	fetchOrgFailed,
	fetchOrgCompleted,
	getCheckinMasterData,
} from '../../action/organization';
import { searchEmployee, globalSearchAPIWithTeam, searchTeamEmpAPI, searchEmployeeInRole } from '../../action/search';
import {
	updatePage,
	getPreviousCycleDetails,
	updateRoute,
	updatePrevRoute,
	showLoader,
	updateGoalType,
	getUserById,
	showGuidedTour,
	getGuidedTourData,
	getGuidedTourActivity,
	currentDashboardTab,
	userClosedGuidedTour,
	updateCurrentCycleId,
	currentCycleIdObject,
	getCycleDetails,
	isImpersonated,
} from '../../action/common';
import {
	giveFeedback,
	askFeedback,
	resetFeedback,
	requestOneToOneFeedback,
	commentFeedback,
} from '../../action/feedback';
import {
	requestOneToOnePersonalFeedback,
	askDetailsPersonalFeedback,
	askPersonalFeedback,
	giveDetailsPersonalFeedback,
	giveUpdatePersonalFeedback,
	getCriteriaMaster,
	cancelRequestPersonalFeedback,
	requestAgainPersonalFeedback,
	viewPersonalFeedback,
} from '../../action/personalFeedback';
import { getUserDetails, getLicenseDetails } from '../../action/users';

import {
	getNotes,
	addNotes,
	deleteNotes,
	UpdateNotes,
	resetCount,
	getConversation,
	addConversation,
	deleteConversation,
	UpdateConversation,
	resetConversationCount,
	likeConversation,
	resetConversation,
	resetNotes,
} from '../../action/cfr';
import { fetchDirectsReportsMap, resetAlignment } from '../../action/alignment';
import { WeeklyCheckIns } from '../../components/Goals/WeeklyCheckIns';

const mapStateToProps = (state) => {
	return {
		globalSearchPage: state.commonReducer.globalSearchPage,

		listOkrPending: state.okrReducer.listOkrPending,
		listOkrResult: state.okrReducer.listOkrResult,
		listOkrSuccess: state.okrReducer.listOkrSuccess,
		listOkrError: state.okrReducer.listOkrError,

		listAlignOkrPending: state.okrReducer.listAlignOkrPending,
		listAlignOkrResult: state.okrReducer.listAlignOkrResult,
		listAlignOkrSuccess: state.okrReducer.listAlignOkrSuccess,
		listAlignOkrError: state.okrReducer.listAlignOkrError,

		fetchFeedbackPending: state.feedbackReducer.fetchFeedbackPending,
		fetchFeedbackSuccess: state.feedbackReducer.fetchFeedbackSuccess,
		fetchFeedbackResult: state.feedbackReducer.fetchFeedbackResult,
		fetchFeedbackError: state.feedbackReducer.fetchFeedbackError,

		searchResult: state.searchReducer.searchResult,
		giveFeedbackStatus: state.feedbackReducer.giveFeedbackStatus,
		askFeedbackStatus: state.feedbackReducer.askFeedbackStatus,
		feedbackOneOnOneStatus: state.feedbackReducer.feedback1To1Status,

		commentFeedbackStatus: state.feedbackReducer.commentFeedbackStatus,
		addGoalsStatus: state.okrReducer.addGoalsStatus,
		editGoalsStatus: state.okrReducer.editGoalsStatus,
		updateKrProgressStatus: state.okrReducer.updateKrProgressStatus,
		alignGoalsStatus: state.okrReducer.alignGoalsStatus,

		listOkrStatusSuccess: state.okrReducer.listOkrStatusSuccess,
		listOkrStatusResult: state.okrReducer.listOkrStatusResult,
		currentRoute: state.commonReducer.currentRoute,
		isDashboard: true,
		// new reducer state for okr master data
		okrMasterData: state.okrReducer.okrMasterData,
		okrMasterDataStatus: state.okrReducer.okrMasterDataStatus,
		okrType: state.commonReducer.okrType,
		directReportResult: state.alignReducer.directReportResult,
		directReportResultStatus: state.alignReducer.directReportResultStatus,
		okrFilterData: state.okrReducer.okrFilterData,
		alignmentFilterData: state.alignReducer.alignmentFilterData,
		deltaScoreData: state.okrReducer.deltaScoreResult,
		recentContributorData: state.okrReducer.recentContributorResult,
		recentContibutorSuccess: state.okrReducer.recentContibutorSuccess,
		guidedTourData: state.commonReducer.guidedTour,
		guideTourVisible: state.commonReducer.guideTourVisible,

		onboardingStatus: state.onboardingReducer.onboardingStatus,
		onboardingResult: state.onboardingReducer.onboardingResult,
		onboardingCount: state.onboardingReducer.onboardingCount,
		activeOnboardingPanel: state.onboardingReducer.activeOnboardingPanel,
		dashboardCurrentTab: state.commonReducer.dashboardCurrentTab,
		cycleDetailsAction: state.commonReducer.cycleObj,
		archiveList: state.okrReducer.archiveList,
		archiveListStatus: state.okrReducer.archiveListStatus,

		dashboardProgressStatus: state.okrReducer.dashboardProgressStatus,
		dashboardProgressData: state.okrReducer.dashboardProgressData,

		teamsOkrProgressStatus: state.okrReducer.teamsOkrProgressStatus,
		teamsOkrProgressData: state.okrReducer.teamsOkrProgressData,

		getPermissionStatus: state.rolesReducer.getPermissionStatus,
		getPermissionResult: state.rolesReducer.getPermissionResult,
		tasks: state.okrReducer.tasks,
		taskStatus: state.okrReducer.taskStatus,
		notesList: state.cfrReducer.notesList,
		getNotesStatus: state.cfrReducer.getNotesStatus,
		conversationList: state.cfrReducer.conversationList,
		getConversationStatus: state.cfrReducer.getConversationStatus,
		listOrgResult: state.organizationReducer.listOrgResult,
		checkInResult: state.okrReducer.checkInData,
		// notificationHighLight: state.signalRReducer.showNotification,
		isProgressUpdated: state.signalRReducer.updateProgress,
		appDrawerInfo: state.signalRReducer.appDrawerInfo,
		showRefreshOptionSnackBar: state.signalRReducer.showRefreshOptionSnackBar,
		currentCycleData: state.signReducer.currentCycleData,
		currentCycleId: state.commonReducer.currentCycleId,
		cycleIdDetails: state.commonReducer.cycleIdDetails,
		userSelectedCycle: state.commonReducer.userSelectedCycle,
		teamCycleDetails: state.commonReducer.teamCycleDetails,
		confidenceAlertData: state.okrReducer.confidenceAlertData,
		okrImportData: state.okrReducer.okrImportData,
		okrImportDataStatus: state.okrReducer.okrImportDataStatus,
		okrImportCategoryData: state.okrReducer.okrImportCategoryData,
		okrImportCategoryDataStatus: state.okrReducer.okrImportCategoryDataStatus,
		userImpersonated: state.commonReducer.userImpersonated,
		myOkrImportData: state.okrReducer.myOkrImportData,
		myOkrImportDataStatus: state.okrReducer.myOkrImportDataStatus,
		checkinMasterData: state.organizationReducer.checkinMasterData,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUserById,
			getObjectiveList,
			getAlignObjective,
			fetchFeedback,
			searchEmployee,
			globalSearchAPIWithTeam,
			searchTeamEmpAPI,
			giveFeedback,
			askFeedback,
			resetFeedback,
			requestOneToOneFeedback,
			resetOkr,
			commentFeedback,
			updatePage,
			addMyGoals,
			deleteObjective,
			updateKrProgress,
			editMyGoals,
			updateStatus,
			deleteContributor,
			getPreviousCycleDetails,
			unlockRequest,
			alignMyGoals,
			getOkrStatusList,
			getEmployeeViewGoals,
			updateRoute,
			getDashboardDetail,
			getDeltaScore,
			getDashboardUpdatedDetails,
			updatePrevRoute,
			checkAlignStatus,
			downloadPdf,
			updateGoalDetails,
			getGoalsDetails,
			requestOneToOnePersonalFeedback,
			askDetailsPersonalFeedback,
			askPersonalFeedback,
			giveDetailsPersonalFeedback,
			giveUpdatePersonalFeedback,
			getCriteriaMaster,
			cancelRequestPersonalFeedback,
			requestAgainPersonalFeedback,
			viewPersonalFeedback,
			updateSequence,
			getOkrMasterData, //new v2 action for okr master data
			addUpdateObjective, // V2 API for add edit objective
			updateGoalAndKrName,
			getKrContributors,
			updateGoalAttributes,
			updateKrAttributes,
			acceptKr,
			updateKrProgressValue,
			becomeContributor,
			fetchObjectiveList,
			getKrDetail,
			getOkrDetail,
			getUserTeamsData,
			getOwnerTeamsData,
			getMyTeamDetails,
			updateNudgeTeams,
			getMyTeamOKRDetails,
			showLoader,
			updateGoalType,
			resetObjective,
			fetchDirectsReportsMap,
			getLinkOwnerData,
			updateNudgePerson,
			getLinkObjectiveData,
			resetAlignment,
			changeOwnerData,
			addAlignmentFilter,
			getRecentContributordDetail,
			virtualAlignment,
			showGuidedTour,
			getGuidedTourData,
			getGuidedTourActivity,
			currentDashboardTab,
			userClosedGuidedTour,
			getOnboarding,
			updateOnboarding,
			updateOnboardingCount,
			getOnboardingCount,
			updateObjetciveDueDate,
			getArchiveDetail,
			updateCalloutPanel,
			getDashboardProgress,
			getTeamsOkrProgress,
			getObjectiveDetails,
			getTasks,
			deleteTask,
			updateTask,
			postNewTask,
			taskCompleted,
			resetTasks,
			getNotes,
			resetCount,
			addNotes,
			deleteNotes,
			UpdateNotes,
			postProgressConversation,
			getConversation,
			addConversation,
			deleteConversation,
			UpdateConversation,
			resetConversationCount,
			likeConversation,
			getUserDetails,
			resetConversation,
			resetNotes,
			getConversationById,
			getOrgList,
			fetchOrganizationsList,
			fetchOrgFailed,
			getLicenseDetails,
			fetchOrgCompleted,
			isCheckinSubmitted,
			getCheckInWeeklyDates,
			getCheckInData,
			updateCheckInData,
			getUserDirectEmployees,
			showNotification,
			updateDashboardProgress,
			isAnyDrawerOpened,
			showRefreshSnackBar,
			updateCurrentCycleId,
			currentCycleIdObject,
			getCycleDetails,
			updateConfidenceData,
			getWeeklyTrendDetails,
			getConfidenceAlertDetails,
			peopleViewFeedback,
			fetchFeedbackReport,
			getOkrImport,
			getOkrImportCategory,
			postOkrImport,
			isImpersonated,
			postMyOkrImport,
			getCheckinMasterData,
			updateCheckInVisibilityData,
			checkInImport,
			underAssign,
			searchEmployeeInRole,
			getCheckInSettingList,
			saveCheckInSettingList,
			deleteCheckInSettingList,
			getCheckInAlreadyAdded,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WeeklyCheckIns));
