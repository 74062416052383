import React, { Fragment, useState, useEffect } from 'react';
import { Box, Chip, List, ListItem, Typography, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CloneIcon, EditIcon, SortingIcon } from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
// import { NoRecord } from '../../CommonComponent/NoRecord';
import { Visibility } from '../../../../config/svg/ProfileSvg';
import { DeleteIcon } from '../../../../config/svg/CommonSvg';
// import { PopperMenu } from '../../CommonComponent/PopperMenu';
import { useDispatch } from 'react-redux';
import { ratingFormDelete } from '../../../../action/adminSettings';
import { Enums } from '../../../../config/enums';
import { getFullName, getMonthDateYear } from '../../../../config/utils';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { NoRecord } from '../../CommonComponent/NoRecord';

export const AssessmentFormsList: React.FC<any> = (props: any) => {
	const {
		setLoader,
		// openAssessmentForm,
		// setAssessmentTabSelected,
		// handleSelectedManageRatingItem,
		// assessmentPreview,
		// setAssessmentPreview,
		// totalAssessmentListUser,
		// setTotalAssessmentListUser,
		// assessmentListPageIndex,
		// setAssessmentListPageIndex,
		assessmentListPageSize,
		// setAssessmentListPageSize,
		getRatingFormListDetails,
		publishType,
		reviewFormList,
		// setReviewFormList,
		searchAssessmentListTextArray,
		// setSearchAssessmentListTextArray,
		sortAssessmentListOrder,
		setSortAssessmentListOrder,
		sortingAssessmentListColumn,
		setSortingAssessmentListColumn,
		handleAddAssessmentFormBtnClick,
		setAssessmentFormType,
		setHighlightRecordId,
		highlightRecordId,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const maxLen = 15;

	// const [anchorEl, setAnchorEl] = useState(null);
	// const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	// const [reviewFormList, setReviewFormList] = useState<any>(null);
	// const [totalUser, setTotalUser] = useState(0);
	// const [pageIndex, setPageIndex] = useState<any>(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// const [searchAssessmentListTextArray, setSearchTextArray] = useState<string>('');
	// const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	// const [sortAssessmentListOrder, setSortAssessmentListOrder] = useState<string>('asc');
	// const [sortingAssessmentListColumn, setSortingAssessmentListColumn] = useState<string>('');
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });

	useEffect(() => {
		getRatingFormListDetails({
			publishType: publishType || 1, // 1 = ALL, 2 = Published, 3 = Unpublished || Draft
			finder: searchAssessmentListTextArray,
			pageIndex: 1,
			sortingText: sortingAssessmentListColumn,
			order: sortAssessmentListOrder,
			pageSize: assessmentListPageSize,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (reviewFormList && reviewFormList?.records && reviewFormList?.records?.length && highlightRecordId) {
			setTimeout(() => {
				setHighlightRecordId(null);
			}, 10000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewFormList, highlightRecordId]);

	// const getRatingFormListDetails = async (requestPayload: any) => {
	// 	setLoader(true);
	// 	const response: any = await dispatch(ratingFormList(requestPayload));
	// 	if (response) {
	// 		!reviewFormList && setReviewFormList({ records: [] });
	// 		if (response && response.data && response.data.status === 200) {
	// 			const { entity } = response.data;
	// 			const { records } = entity;
	// 			const updatedData = { records: records };
	// 			setReviewFormList(updatedData);
	// 			setTotalAssessmentListUser(entity && entity.totalRecords);
	// 			setLoader(false);
	// 		} else {
	// 			setLoader(false);
	// 		}
	// 	}
	// 	setLoader(false);
	// };
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingAssessmentListColumn) {
			setSortAssessmentListOrder(sortAssessmentListOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortAssessmentListOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortAssessmentListOrder('desc');
		}

		getRatingFormListDetails({
			publishType: publishType || 1,
			finder: searchAssessmentListTextArray,
			pageIndex: 1,
			sortingText: column,
			order: newSortOrder,
			pageSize: assessmentListPageSize,
		});
		setSortingAssessmentListColumn(column);
	};

	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };
	// const handleOpenAssessmentPreview = (event: any, record: any) => {
	// 	setAssessmentPreview({ open: true, details: record });
	// };

	// const handleSelectRatingItem = (e: any, record: any, index: number, performanceCompetencyRecord: any) => {
	// 	const updatedReviewFormList =
	// 		reviewFormList && reviewFormList?.records && reviewFormList?.records?.length
	// 			? reviewFormList.records.map((item: any) => {
	// 					if (item.ratingFormId === record.ratingFormId) {
	// 						item.selectedItem = true;
	// 					} else {
	// 						item.selectedItem = false;
	// 					}

	// 					return item;
	// 			  })
	// 			: [];

	// 	handleSelectedManageRatingItem({ ...record, selectedItem: true }, performanceCompetencyRecord);

	// 	setReviewFormList({ records: [...updatedReviewFormList] });
	// };

	const handleCloseModal = async (e: any, type: any) => {
		//e.preventDefault();
		if (type === 1) {
			callDeleteRatingFormAPI();
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const handleDeleteRecordClick = (e: any, record: any, type: string) => {
		e.preventDefault();
		e.stopPropagation();
		if (record && record.ratingFormId) {
			if (type === 'Delete') {
				setModalProps({
					open: true,
					message: t('deleteConfirmationForAlignment', {
						type: 'assessment rating form',
					}),
					details: record,
				});
			} else if (type === 'Clone') {
				setAssessmentFormType({ type: type, details: record });
				handleAddAssessmentFormBtnClick({}, true, type);
			} else if (type === 'Edit') {
				setAssessmentFormType({ type: type, details: record });
				handleAddAssessmentFormBtnClick({}, true, type);
			} else if (type === 'View') {
				// setAssessmentFormType(type);
				// handleAddAssessmentFormBtnClick({}, true, type);
			}
		}
	};

	const callDeleteRatingFormAPI = async () => {
		if (modalProps.details && modalProps.details.ratingFormId) {
			setLoader(true);
			const queryParams = `ratingFormId=${modalProps.details.ratingFormId}`;
			setModalProps({ open: false, message: '', details: '' });
			const response: any = await dispatch(ratingFormDelete(queryParams));
			if (response) {
				if (response && response?.data && response.data.status === 200) {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Rating form deleted successfully', {
						variant: 'success',
						autoHideDuration: 3000,
					});
					getRatingFormListDetails({
						publishType: publishType || 1, // 1 = ALL, 2 = Published, 3 = Unpublished || Draft
						finder: searchAssessmentListTextArray,
						pageIndex: 1,
						sortingText: sortingAssessmentListColumn,
						order: sortAssessmentListOrder,
						pageSize: assessmentListPageSize,
					});
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while deleting assessment form', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				setLoader(false);
			}
		}
	};

	return (
		<Box className='assessment-forms-listing'>
			{reviewFormList && reviewFormList?.records && reviewFormList?.records.length > 0 ? (
				<Box className='okr-list-panel-head'>
					<List component='ul' disablePadding>
						<ListItem className='assessment-col1'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'name')}
								className={
									sortingAssessmentListColumn === 'name' ? `sort-title ${sortAssessmentListOrder}` : 'sort-title asc'
								}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>
										{t('assessmentFormNameLevel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='assessment-col2'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'isPublish')}
								className={
									sortingAssessmentListColumn === 'isPublish'
										? `sort-title ${sortAssessmentListOrder}`
										: 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('statusLabel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='assessment-col3'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'totalSectionsCount')}
								className={
									sortingAssessmentListColumn === 'totalSectionsCount'
										? `sort-title ${sortAssessmentListOrder}`
										: 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('totalSections')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='assessment-col4'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'totalQuestionsCount')}
								className={
									sortingAssessmentListColumn === 'totalQuestionsCount'
										? `sort-title ${sortAssessmentListOrder}`
										: 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('totalQuestions')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='assessment-col5'>
							{/* <Typography variant='body2' className='sort-title' id={'created-on'}>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('createdLevel')}</span>
								</span>
							</Typography> */}
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'createdOn')}
								className={
									sortingAssessmentListColumn === 'createdOn'
										? `sort-title ${sortAssessmentListOrder}`
										: 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('createdLevel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='assessment-col6'>
							{/* <Typography variant='body2' className='sort-title' id={'created-on'}>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('lastUpdatedLevel')}</span>
								</span>
							</Typography> */}
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'updatedOn')}
								className={
									sortingAssessmentListColumn === 'updatedOn'
										? `sort-title ${sortAssessmentListOrder}`
										: 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('lastUpdatedLevel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
					</List>
				</Box>
			) : (
				<></>
			)}
			{reviewFormList && reviewFormList?.records && reviewFormList?.records.length > 0 ? (
				reviewFormList?.records.map((record: any, index: number) => {
					const creatureName = getFullName(record?.createdByDetails);
					const userFullName = getFullName(record?.updatedByDetails);

					return (
						<Box
							className={`assessment-list-content ${
								highlightRecordId === record?.ratingFormId ? 'heighlight-row' : ''
							}`}
						>
							<List component='ul' disablePadding key={`review-form-list-${record?.ratingFormId}`}>
								<ListItem className='assessment-col1'>
									{record?.name && record?.name.length > 35 ? (
										<Tooltip arrow title={record?.name || ''}>
											<Typography>{record?.name.substring(0, 35)}...</Typography>
										</Tooltip>
									) : (
										<Typography>{record?.name || ''}</Typography>
									)}
									<Box className='perform-review-actions'>
										<OKRButton
											handleClick={(event: any) => handleDeleteRecordClick(event, record, 'Clone')}
											icon={<CloneIcon />}
											title={t('clone')}
										/>
										{record?.status === Enums.THREE ? (
											<OKRButton
												handleClick={(event: any) => handleDeleteRecordClick(event, record, 'View')}
												icon={<Visibility />}
												title={'View'}
											/>
										) : (
											<OKRButton
												handleClick={(event: any) => handleDeleteRecordClick(event, record, 'Edit')}
												icon={<EditIcon />}
												title={t('editLabel')}
											/>
										)}
										<OKRButton
											handleClick={(event: any) => handleDeleteRecordClick(event, record, 'Delete')}
											icon={<DeleteIcon />}
											title={t('delete')}
										/>
									</Box>
								</ListItem>
								<ListItem className='assessment-col2'>
									{record?.isPublish ? (
										<Chip className='assessment-chip' label={t('publishedLevel')} />
									) : (
										<Chip className='assessment-chip draft-chip' label={t('draftLevel')} />
									)}
								</ListItem>
								<ListItem className='assessment-col3'>
									<Typography>{record?.totalSectionsCount}</Typography>
								</ListItem>
								<ListItem className='assessment-col4'>
									<Typography>{record?.totalQuestionsCount || 0}</Typography>
								</ListItem>
								<ListItem className='assessment-col5'>
									<Box>
										<Typography>{getMonthDateYear(record?.createdOn)}</Typography>
										{/* <Typography variant='body2'>{getFullName(record?.createdByDetails)}</Typography> */}
										{creatureName && creatureName.length > maxLen ? (
											<Tooltip arrow title={creatureName} aria-label='Email'>
												<Typography variant='body2'>{creatureName.substring(0, maxLen)}...</Typography>
											</Tooltip>
										) : (
											<Typography variant='body2'>{creatureName}</Typography>
										)}
									</Box>
								</ListItem>
								<ListItem className='assessment-col6'>
									<Box className=''>
										<Typography>{getMonthDateYear(record?.updatedOn)}</Typography>
										{userFullName && userFullName.length > maxLen ? (
											<Tooltip arrow title={userFullName} aria-label='Email'>
												<Typography variant='body2'>{userFullName.substring(0, maxLen)}...</Typography>
											</Tooltip>
										) : (
											<Typography variant='body2'>{userFullName}</Typography>
										)}
									</Box>
								</ListItem>
							</List>
						</Box>
					);
				})
			) : reviewFormList ? (
				<NoRecord noRecordMessage={t('noPerformanceFound')} subHead={' '} {...props} />
			) : (
				<></>
			)}

			{modalProps && modalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
					headingText={t('deleteAssessmentForm')}
					subHeading={t('deleteAssessmentFormSubHead')}
				/>
			)}
		</Box>
	);
};
