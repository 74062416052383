import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, FormLabel, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { TeamsIcon } from '../../../../config/svg/formElementIcons';
import {
	AddIconSvg,
	DashIcon,
	DepartmentIcon,
	DesignationIcon,
	SearchIcon,
	StrategicObjIcon,
} from '../../../../config/svg/CommonSvg';
import { OKRButton } from '../../../Common/OKRButton';
import { SODropdown } from './SODropdown';

export const GenerateOkrHead: React.FC<any> = (props) => {
	const { type, advanceSearchData, setAdvanceSearchData, generateOkr, generateDisabled, moreOkrApiPending } = props;
	const { t } = useTranslation();
	const [collapse, setCollapse] = useState<boolean>(true);

	const handleToggle = (e: any) => {
		setCollapse(!collapse);
	};
	const generateOkrClick = (e: any) => {
		setCollapse(false);
		generateOkr();
	};
	const setSoItem = (item: any) => {
		setAdvanceSearchData({
			...advanceSearchData,
			selectedSo: item,
		});
	};
	const updateSearch = (key: string, value: any) => {
		setAdvanceSearchData({
			...advanceSearchData,
			[key]: value,
		});
	};
	const isButtonEnabled = () => {
		if (generateDisabled) {
			return true;
		}
		if (moreOkrApiPending !== '') {
			return true;
		}
		if (
			!advanceSearchData?.industry &&
			!advanceSearchData?.department &&
			!advanceSearchData?.designation &&
			!advanceSearchData?.userInput &&
			!advanceSearchData?.selectedSo?.soName
		) {
			return true;
		}
		return false;
	};
	return (
		<>
			<Box className='generate-okr-src-head'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					<Box className='drawer-form-fields-group'>
						<Box className='drawer-input-field'>
							<Box className='search-action-field'>
								<TextField
									className='src-field'
									id='src-field-okr'
									placeholder={t(
										'Add keywords to enhance your results. E.g. Business Planning, Geographical expansion '
									)}
									fullWidth
									multiline
									maxRows={3}
									value={advanceSearchData?.userInput}
									onChange={(e: any) => {
										e.preventDefault();
										updateSearch('userInput', e.target.value);
									}}
									//onChange={(e) => handleInputThrottled(e.target.value, currentCycleSelection)}
									//value={searchText}
									//onClick={(e) => checkSelection(e, currentCycleSelection)}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start' className='src-icon'>
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Box>
							<Box className='advanced-src'>
								<OKRButton
									className='btn-toggle'
									text={t('Advanced Search')}
									icon={collapse ? <DashIcon /> : <AddIconSvg />}
									handleClick={(e) => handleToggle(e)}
								/>
								<Collapse in={collapse} className='toggle-wrap-field '>
									<Box className='drawer-input-field drawer-input-field-col4'>
										{advanceSearchData?.isSoVisible && (
											<Box className='field-col2 helperText-bottom'>
												<FormLabel id='set-a-duration' component='legend'>
													<StrategicObjIcon />
													{t('strategicObjLabel')}
												</FormLabel>
												<SODropdown
													{...props}
													soDetails={advanceSearchData?.soDetails}
													selectedSo={advanceSearchData.selectedSo}
													setSoItem={setSoItem}
												/>
											</Box>
										)}
										<Tooltip arrow title={advanceSearchData?.designation || ''}>
											<TextField
												id='firstName'
												className='field-col2 helperText-bottom'
												label={
													<>
														<DesignationIcon />
														{t('designationLabel')}
													</>
												}
												placeholder={t('designationLabel')}
												value={advanceSearchData?.designation}
												name={t('designationLabel')}
												//error={''}
												// helperText={
												// 	<>
												// 		<ErrorIcon />
												// 	</>
												// }
												onChange={(e: any) => {
													e.preventDefault();
													updateSearch('designation', e.target.value);
												}}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{ 'aria-label': t('designationLabel'), disableUnderline: true }}
											/>
										</Tooltip>
										<Tooltip arrow title={advanceSearchData?.department || ''}>
											<TextField
												id='firstName'
												className='field-col2 helperText-bottom'
												label={
													<>
														<DepartmentIcon />
														{t('departmentLabel')}
													</>
												}
												placeholder={t('departmentLabel')}
												value={advanceSearchData?.department}
												name={t('departmentLabel')}
												//error={''}
												// helperText={
												// 	<>
												// 		<ErrorIcon />
												// 	</>
												// }
												onChange={(e: any) => {
													e.preventDefault();
													updateSearch('department', e.target.value);
												}}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{ 'aria-label': t('departmentLabel'), disableUnderline: true }}
											/>
										</Tooltip>
										<Tooltip arrow title={advanceSearchData?.industry || ''}>
											<TextField
												id='firstName'
												className='field-col2 helperText-bottom'
												label={
													<>
														<TeamsIcon />
														{t('industryLabel')}
													</>
												}
												placeholder={t('industryLabel')}
												value={advanceSearchData?.industry}
												name={t('industryLabel')}
												//error={''}
												// helperText={
												// 	<>
												// 		<ErrorIcon />
												// 	</>
												// }
												onChange={(e: any) => {
													e.preventDefault();
													updateSearch('industry', e.target.value);
												}}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{ 'aria-label': t('industryLabel'), disableUnderline: true }}
											/>
										</Tooltip>
									</Box>
								</Collapse>
							</Box>
						</Box>

						<Box className='drawer-input-field generate-okr-action'>
							{/* <OKRButton className={'btn-link'} text={t('cancelBtn')} id='cancel-btn' /> */}
							<OKRButton
								className='btn-primary'
								text={type === 'kr' ? t('generateKRs') : t('generateOKRs')}
								id='generate-btn'
								handleClick={generateOkrClick}
								disabled={isButtonEnabled()}
							/>
						</Box>
					</Box>
				</form>
			</Box>
		</>
	);
};
