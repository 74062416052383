import React from 'react';

export const StarBorderIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_7342_251442)'>
			<path
				d='M9.68884 9.95311L2.30456 10.9016C1.47293 11.0084 1.13384 12.0316 1.73652 12.6156L7.43651 18.1389C7.68095 18.3758 7.7885 18.7209 7.72201 19.055L6.12066 27.102C5.95424 27.9383 6.84013 28.5873 7.58504 28.1749L14.4601 24.3683C14.7732 24.1948 15.1551 24.2023 15.4612 24.3879L22.1618 28.4482C22.8979 28.8942 23.8153 28.2627 23.6637 27.4143L22.1685 19.0482C22.1095 18.7176 22.2196 18.3795 22.4618 18.1474L28.2483 12.604C28.8594 12.0186 28.5146 10.9847 27.675 10.8849L19.8252 9.95168C19.4721 9.90969 19.1677 9.68291 19.0259 9.3561L15.8381 2.00711C15.4967 1.22011 14.3903 1.1992 14.0195 1.97276L10.4631 9.39303C10.3164 9.69912 10.025 9.90994 9.68884 9.95311Z'
				fill='white'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M9.29512 8.58766L2.12645 9.50845C0.130534 9.76481 -0.683281 12.2204 0.763154 13.622L6.30627 18.9934L4.7476 26.8259C4.3482 28.833 6.47434 30.3906 8.26211 29.4007L14.9346 25.7062L21.4372 29.6466C23.2038 30.7171 25.4056 29.2014 25.0417 27.1652L23.5844 19.0108L29.2157 13.616C30.6823 12.211 29.8549 9.72972 27.8399 9.49016L20.2183 8.58404L17.122 1.44616C16.3027 -0.442641 13.6472 -0.492813 12.7574 1.36372L9.29512 8.58766ZM15.4612 24.386C15.155 24.2005 14.7732 24.193 14.46 24.3664L7.58495 28.173C6.84005 28.5855 5.95416 27.9365 6.12057 27.1002L7.72192 19.0532C7.78842 18.7191 7.68087 18.374 7.43643 18.1371L1.73644 12.6137C1.13375 12.0297 1.47284 11.0066 2.30448 10.8998L9.68876 9.95127C10.0249 9.9081 10.3164 9.69728 10.4631 9.3912L14.0194 1.97092C14.3902 1.19737 15.4966 1.21827 15.838 2.00527L19.0259 9.35426C19.1676 9.68108 19.472 9.90786 19.8252 9.94984L27.6749 10.8831C28.5145 10.9829 28.8593 12.0168 28.2482 12.6022L22.4617 18.1456C22.2195 18.3776 22.1094 18.7158 22.1684 19.0463L23.6636 27.4124C23.8152 28.2609 22.8978 28.8924 22.1617 28.4464L15.4612 24.386Z'
				fill='#51BAAD'
				fill-opacity='0.5'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7342_251442'>
				<rect width='29.9889' height='30' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
export const StarFilledIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_7342_251445)'>
			<path
				d='M9.14589 9.44154L0.950072 10.4922C0.0460747 10.6081 -0.322523 11.7182 0.332603 12.3518L6.65697 18.4684C6.92269 18.7254 7.03959 19.0998 6.96731 19.4623L5.19059 28.3734C5.00969 29.2807 5.97267 29.9849 6.78239 29.5374L14.4125 25.3208C14.7529 25.1327 15.1679 25.1408 15.5008 25.3421L22.9381 29.8403C23.7383 30.3242 24.7355 29.639 24.5707 28.7185L22.912 19.4549C22.8477 19.0963 22.9674 18.7293 23.2307 18.4776L29.6512 12.3387C30.3154 11.7036 29.9407 10.5819 29.028 10.4736L20.3171 9.43999C19.9331 9.39444 19.6023 9.1484 19.4482 8.79383L15.9104 0.653741C15.5393 -0.200101 14.3366 -0.222774 13.9336 0.616475L9.98756 8.8339C9.8281 9.16598 9.51129 9.3947 9.14589 9.44154Z'
				fill='#39A3FA'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7342_251445'>
				<rect width='29.9883' height='30' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
