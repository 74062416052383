import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Collapse,
	FormControlLabel,
	Switch,
	TablePagination,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { GenerateOKRColorIcon, UpdateIcon } from '../../../../config/svg/CommonSvg';
import { OKRButton } from '../../../Common/OKRButton';
import { RightArrowIcon } from '../../../../config/svg/ArrowSvg';
import { SelectedParameterTooltip } from './SelectedParameterTooltip';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import noRecordFound from '../../../../images/ChatLoader.svg';
import { getUserDetails, getUserName } from '../../../../config/utils';

export const GenerateOkrList: React.FC<any> = (props) => {
	const {
		okrListData,
		getMoreOkrData,
		pageNumbers,
		pageSize,
		handlePageChange,
		moreOkrApiPending,
		addObjective,
		removeObjective,
		addKeyResult,
		removeKeyResult,
		toggleDisabled,
	} = props;
	const { t } = useTranslation();
	const [expandedKr, setExpandedKr] = useState<any[]>([]);
	const [okrToggle, setOkrToggle] = useState<any[]>([]);
	const [krToggle, setKrToggle] = useState<any[]>([]);

	const handleExpandKr = (e: any, item: any) => {
		let list = Object.assign([], expandedKr);
		const elementIndex = list.indexOf(item.objectiveId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(item.objectiveId);
		}
		setExpandedKr(list);
	};

	const handleOkrToggle = (e: any, item: any, noApi = false) => {
		let list = Object.assign([], okrToggle);
		const elementIndex = list.indexOf(item.objectiveId);
		if (elementIndex !== -1) {
			if (!noApi) {
				removeObjective(item);
				list.splice(elementIndex, 1);
			}
		} else {
			if (!noApi) {
				addObjective(item);
			}
			list.push(item.objectiveId);
			if (expandedKr.indexOf(item.objectiveId) === -1) {
				handleExpandKr(e, item);
			}
		}
		setOkrToggle(list);
	};

	const handleKrToggle = (e: any, item: any, objective: any) => {
		let list = Object.assign([], krToggle);
		const elementIndex = list.indexOf(item.krId);
		if (elementIndex !== -1) {
			removeKeyResult(item);
			list.splice(elementIndex, 1);
		} else {
			addKeyResult(item, objective);
			handleOkrToggle(e, objective, true);
			list.push(item.krId);
		}
		setKrToggle(list);
	};
	const loggedInUserDetail = getUserDetails();

	return (
		<>
			{/* section row repeat start*/}
			{okrListData && okrListData.map((section: any, index: any) => {
				const dataArray = section.objectives;
				const totalItems = dataArray.length;
				const totalPages = Math.ceil(totalItems / pageSize);
				const pageNumber = pageNumbers[index];
				const startIndex = (pageNumber - 1) * pageSize;
				const items = dataArray.slice(startIndex, startIndex + pageSize);
				return (
					<Box className='generate-okr-row'>
						<Box className='generate-section-head'>
							<Box className='user-info v-center'>
								<Box className='user-img'>
									{section.isDefaultSection ? (
										<Avatar className='recommended-default'>
											<GenerateOKRColorIcon />
										</Avatar>
									) : (
										<>
											{loggedInUserDetail && loggedInUserDetail.imagePath ? (
												<Avatar
													className='avatar-default'
													alt={`${loggedInUserDetail.firstName} ${loggedInUserDetail.lastName}`}
													src={loggedInUserDetail.imagePath}
												/>
											) : (
												<Avatar className='avatar-default'>
													{getUserName({
														firstName: loggedInUserDetail.firstName,
														lastName: loggedInUserDetail.lastName,
														fullName: loggedInUserDetail.fullname,
													})}
												</Avatar>
											)}
										</>
									)}
								</Box>
								<Box className='user-info-details'>
									<Typography variant='body2'>
										{section.isDefaultSection ? (
											<span>Unlock Recommended</span>
										) : (
											<>
												{section.sectionName ? (
													<span>{section.sectionName}</span>
												) : (
													<>
														<span>Based on searched parameters</span>
													</>
												)}
											</>
										)}
										<SelectedParameterTooltip {...props} okrRecommendationRequest={section.okrRecommendationRequest} />
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box className='generate-section-content'>
							<Box className='gen-more-okr'>
								<OKRButton
									className={'btn-link-type1'}
									text={t('generateMoreOKRs')}
									icon={<UpdateIcon />}
									handleClick={(e) => getMoreOkrData(section.sectionId)}
									disabled={moreOkrApiPending !== ''}
								/>
								<TablePagination
									component='div'
									className='user-list-pagination'
									count={section.objectives.length}
									page={pageNumber - 1}
									onPageChange={(e, newPage: number) => {
										handlePageChange(newPage + 1, index);
									}}
									rowsPerPage={pageSize}
									rowsPerPageOptions={[]}

									//onRowsPerPageChange={handleChangeRowsPerPage}
									// SelectProps={{
									// 	MenuProps: {
									// 		PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
									// 	},
									// }}
								/>
							</Box>

							{moreOkrApiPending !== section.sectionId ? (
								<>
									{items.map((item: any) => (
										<Box
											className={`import-okr-list import-myokr-list ${
												expandedKr.indexOf(item.objectiveId) !== -1 ? 'expanded-okr' : ''
											}`}
										>
											<Box className='import-obj-row'>
												<Box className='import-obj-col'>
													<Box className='obj-title-section'>
														<Box className='import-obj-title'>
															<Typography variant='h4'>{item.objective}</Typography>
														</Box>
														<Box className='import-okr-actions'>
															<OKRButton
																className={`btn-blue-chip ${
																	expandedKr.indexOf(item.objectiveId) !== -1 ? 'expand' : 'collapse'
																}`}
																icon={<RightArrowIcon />}
																text={`${item.keyResults.length} ${t('keyResultTab')}`}
																handleClick={(e) => handleExpandKr(e, item)}
															/>
														</Box>
													</Box>
												</Box>
												<Box className='import-obj-progress-col'>
													<Box className='link-toggle'>
														<Tooltip arrow title={okrToggle.indexOf(item.objectiveId) !== -1 ? 'Remove' : 'Add'}>
															<FormControlLabel
																control={
																	<Switch
																		checked={okrToggle.indexOf(item.objectiveId) !== -1}
																		onChange={(e) => handleOkrToggle(e, item)}
																		disabled={toggleDisabled}
																	/>
																}
																label={''}
																className='toggle-label toggle-no-label'
															/>
														</Tooltip>
													</Box>
												</Box>
											</Box>

											<Collapse
												className='department-kr-collapsible-content'
												in={expandedKr.indexOf(item.objectiveId) !== -1}
												timeout='auto'
												unmountOnExit
											>
												{item.keyResults.map((kr: any, index: any) => (
													<Box className='import-kr-list'>
														<Box className='import-kr-content'>
															<Box className='import-kr-row'>
																<Box className='import-kr-col'>
																	<Box className='kr-title-section'>
																		<Typography>{kr?.keyResult}</Typography>
																	</Box>
																</Box>
																<Box className='import-kr-progress-col'>
																	<Box className='link-toggle'>
																		<Tooltip arrow title={krToggle.indexOf(kr.krId) !== -1 ? 'Remove' : 'Add'}>
																			<FormControlLabel
																				control={
																					<Switch
																						checked={krToggle.indexOf(kr.krId) !== -1}
																						onChange={(e) => handleKrToggle(e, kr, item)}
																						disabled={toggleDisabled}
																					/>
																				}
																				label={''}
																				className='toggle-label toggle-no-label'
																			/>
																		</Tooltip>
																	</Box>
																</Box>
															</Box>
														</Box>
													</Box>
												))}
											</Collapse>
										</Box>
									))}
								</>
							) : (
								<Box className='generate-okr-loader'>
									<AnimatedIcon
										className=''
										width='300'
										type='image/svg+xml'
										alt={t('globalSearchNoRecord')}
										imagePath={noRecordFound}
									/>
								</Box>
							)}
						</Box>
					</Box>
				);
			})}
			{/* section row repeat end*/}
		</>
	);
};
