import * as React from 'react';
import { Fragment } from 'react';
import {
	Box,
	Checkbox,
	Collapse,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@material-ui/core';
import '../../../styles/pages/admin/rolesPage.scss';
import { RoleCard } from './RoleCard';
import { EmojiDoneIcon, RightArrowIcon } from '../../../config/svg/CommonSvgIcon';
import { useTranslation } from 'react-i18next';
import { CheckedIcon, PartialCheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';

export const RoleListing: React.FC<any> = (props) => {
	const {
		permissions,
		rolesList,
		findPermission,
		changePermission,
		handleModulePermissionChange,
		activeRoleId,
		checkModuleSelection,
	} = props;
	const { t } = useTranslation();
	const [open, setOpen] = React.useState({ permissionOpen: false, id: 0 });

	const handleTableRowClick = (e: any, id: any) => {
		e.preventDefault();
		e.stopPropagation();
		setOpen({ permissionOpen: id === open.id ? !open.permissionOpen : true, id: id });
	};
	const findModuleClass = (roles: any, moduleId: any) => {
		const modulePermission =
			roles && roles.permissions && roles.permissions.filter((item: any) => item.moduleId === moduleId);
		const permissionCount = modulePermission && modulePermission.length;

		if (permissionCount > 0) {
			const checked = modulePermission && modulePermission.filter((item: any) => item.isActive);
			return checked.length === permissionCount
				? 'custom-checked'
				: checked.length > 0
				? 'not-checked'
				: 'partially-checked';
		}

		return `not-checked`;
	};
	return (
		<Fragment>
			{permissions.length > 0 && rolesList && rolesList.length > 0 && (
				<TableContainer className='roles-listing'>
					<Table stickyHeader aria-label='collapsible table'>
						<TableHead>
							<TableRow>
								<TableCell align='center' id={`role-cell-0`}>
									<Box className='cell-wrap'>
										<Typography variant='subtitle2'>{t('permissionsText')}</Typography>
										<Tooltip
											open={activeRoleId > 0 && props.clonedRoleId > 0}
											title={
												<Box className='tooltip-text'>
													<EmojiDoneIcon />
													<Typography>
														{t('goodJob')}
														<br />
														{t('managePermissionMessage')}
													</Typography>
												</Box>
											}
											placement='top'
											arrow
											classes={{ popper: 'tooltip-layout-with-icon' }}
										>
											<Typography variant='overline'> </Typography>
										</Tooltip>
									</Box>
								</TableCell>
								{rolesList.map((row: any, index: any) => (
									<TableCell
										align='center'
										key={`roleCell${row.roleId}`}
										id={`role-cell-${row.roleId}`}
										className={`${row.roleId === activeRoleId ? 'activerole' : ''}`}
									>
										<Box className='cell-wrap' id={`role-card-${row.roleId}`}>
											<RoleCard
												{...props}
												roleDetail={row}
												isDefault={row.isDefault}
												updateRoleName={props.updateRoleName}
												rolesList={rolesList}
												setRolesList={props.setRolesList}
												activeRoleId={activeRoleId}
											/>
										</Box>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{permissions.map((permission: any, permissionIndex: any) => (
								<Fragment key={`permission${permission.moduleId}`}>
									<TableRow
										hover
										onClick={(e) => handleTableRowClick(e, permission.moduleId)}
										className={`${open.permissionOpen && open.id === permission.moduleId ? 'expandRow' : ''}`}
									>
										<TableCell id={'module-name'}>
											<Box className='cell-wrap'>
												<Typography variant='body1'>{permission.moduleName}</Typography>
												<Typography variant='subtitle2'>{permission.description}</Typography>
												<Box className={'rotate-arrow'}>
													<RightArrowIcon />
												</Box>
											</Box>
										</TableCell>
										{rolesList.map((roles: any, roleIndex: any) => (
											<TableCell
												key={`module-${permission.moduleId}-${roles.roleId}`}
												id={`module-${permission.moduleId}-${roles.roleId}`}
												className={`${roles.roleId === activeRoleId ? 'activerole' : ''}`}
											>
												<Box
													className='cell-wrap'
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
													}}
												>
													<Checkbox
														checkedIcon={<CheckedIcon />}
														icon={<PartialCheckedIcon />}
														indeterminateIcon={<UnCheckedIcon />}
														aria-label='Permission Checkbox'
														key={permission.moduleId}
														className={findModuleClass(roles, permission.moduleId)}
														// name={`module-${permission.moduleId}-${roles.roleId}`}
														indeterminate={checkModuleSelection(roles, permission.moduleId, permission) === null}
														checked={checkModuleSelection(roles, permission.moduleId, permission)}
														onChange={(e) => handleModulePermissionChange(e, roles.roleId, permission)}
													/>
												</Box>
											</TableCell>
										))}
									</TableRow>
									<TableRow>
										<TableCell colSpan={rolesList.length + 1}>
											<Collapse
												in={open.permissionOpen && open.id === permission.moduleId}
												timeout='auto'
												unmountOnExit
											>
												{permission.permissions.length &&
													permission.permissions.map((subPermission: any, subPermissionIndex: any) => (
														<TableRow key={`subPermission${subPermission.permissionId}`}>
															<TableCell id={'sub-permission-name'}>
																<Box className='cell-wrap'>
																	<Typography variant='body1'>{subPermission.permissionName}</Typography>
																</Box>
															</TableCell>

															{rolesList.map((role: any, index: any) => (
																<TableCell align='center' key={index} id={`sub-permission-id-${index}`}>
																	<Box className='cell-wrap'>
																		<Checkbox
																			checkedIcon={<CheckedIcon />}
																			icon={<PartialCheckedIcon />}
																			checked={findPermission(role, subPermission.permissionId)}
																			name={`${subPermissionIndex.permissionId}-${role.roleId}`}
																			onChange={(e) =>
																				changePermission(
																					e,
																					role,
																					subPermission,
																					index,
																					findPermission(role, subPermission.permissionId)
																				)
																			}
																			className={
																				findPermission(role, subPermission.permissionId)
																					? 'custom-checked'
																					: 'not-checked'
																			}
																			disabled={!subPermission.isEditable}
																		/>
																	</Box>
																</TableCell>
															))}
														</TableRow>
													))}
											</Collapse>
										</TableCell>
									</TableRow>
								</Fragment>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Fragment>
	);
};
