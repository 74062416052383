import React from 'react';
import { Avatar, Box, Chip, Grow, Typography } from '@material-ui/core';
import CircularProgress from '../../OkrAction/CircularProgress';
import { Skeleton } from '@material-ui/lab';

export const SoOkrNotCreated: React.FC<any> = (props: any) => {
	return (
		<Box className='so-okr-card-list'>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box className='so-okr-card so-okr-not-created'>
					<Box className='so-okr-card-top'>
						<Box className='so-okr-card-info'>
							<Box className='so-okr-icon'>
								<Skeleton animation={false} variant='circle' width={22} height={22}>
									<Avatar />
								</Skeleton>
							</Box>
							<Box className='so-okr-title'>
								<Typography variant='h4'>
									<Skeleton variant='text' animation={false} width={200} height={22}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
								<Typography variant='h4'>
									<Skeleton variant='text' animation={false} width={100} height={22}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
							</Box>
						</Box>
						<Box className='so-progress-chart'>
							<Box className='circular-progress-chart'>
								<Skeleton animation={false} variant='circle' width={50} height={50}>
									<CircularProgress percentage={0} />
								</Skeleton>
							</Box>
						</Box>
					</Box>
					<Box className='so-okr-card-bottom'>
						<Box className='so-okr-tags'>
							<Skeleton animation={false} width={80} height={30}>
								<Chip />
							</Skeleton>
							<Skeleton animation={false} width={100} height={30}>
								<Chip />
							</Skeleton>
							<Skeleton animation={false} variant='circle' width={20} height={20}>
								<Chip />
							</Skeleton>
						</Box>
					</Box>
				</Box>
			</Grow>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box className='so-okr-card so-okr-not-created'>
					<Box className='so-okr-card-top'>
						<Box className='so-okr-card-info'>
							<Box className='so-okr-icon'>
								<Skeleton animation={false} variant='circle' width={22} height={22}>
									<Avatar />
								</Skeleton>
							</Box>
							<Box className='so-okr-title'>
								<Typography variant='h4'>
									<Skeleton variant='text' animation={false} width={200} height={22}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
								<Typography variant='h4'>
									<Skeleton variant='text' animation={false} width={100} height={22}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
							</Box>
						</Box>
						<Box className='so-progress-chart'>
							<Box className='circular-progress-chart'>
								<Skeleton animation={false} variant='circle' width={50} height={50}>
									<CircularProgress percentage={0} />
								</Skeleton>
							</Box>
						</Box>
					</Box>
					<Box className='so-okr-card-bottom'>
						<Box className='so-okr-tags'>
							<Skeleton animation={false} width={80} height={30}>
								<Chip />
							</Skeleton>
							<Skeleton animation={false} width={100} height={30}>
								<Chip />
							</Skeleton>
							<Skeleton animation={false} variant='circle' width={20} height={20}>
								<Chip />
							</Skeleton>
						</Box>
					</Box>
				</Box>
			</Grow>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box className='so-okr-card so-okr-not-created'>
					<Box className='so-okr-card-top'>
						<Box className='so-okr-card-info'>
							<Box className='so-okr-icon'>
								<Skeleton animation={false} variant='circle' width={22} height={22}>
									<Avatar />
								</Skeleton>
							</Box>
							<Box className='so-okr-title'>
								<Typography variant='h4'>
									<Skeleton variant='text' animation={false} width={200} height={22}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
								<Typography variant='h4'>
									<Skeleton variant='text' animation={false} width={100} height={22}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
							</Box>
						</Box>
						<Box className='so-progress-chart'>
							<Box className='circular-progress-chart'>
								<Skeleton animation={false} variant='circle' width={50} height={50}>
									<CircularProgress percentage={0} />
								</Skeleton>
							</Box>
						</Box>
					</Box>
					<Box className='so-okr-card-bottom'>
						<Box className='so-okr-tags'>
							<Skeleton animation={false} width={80} height={30}>
								<Chip />
							</Skeleton>
							<Skeleton animation={false} width={100} height={30}>
								<Chip />
							</Skeleton>
							<Skeleton animation={false} variant='circle' width={20} height={20}>
								<Chip />
							</Skeleton>
						</Box>
					</Box>
				</Box>
			</Grow>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box className='so-okr-card so-okr-not-created'>
					<Box className='so-okr-card-top'>
						<Box className='so-okr-card-info'>
							<Box className='so-okr-icon'>
								<Skeleton animation={false} variant='circle' width={22} height={22}>
									<Avatar />
								</Skeleton>
							</Box>
							<Box className='so-okr-title'>
								<Typography variant='h4'>
									<Skeleton variant='text' animation={false} width={200} height={22}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
								<Typography variant='h4'>
									<Skeleton variant='text' animation={false} width={100} height={22}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
							</Box>
						</Box>
						<Box className='so-progress-chart'>
							<Box className='circular-progress-chart'>
								<Skeleton animation={false} variant='circle' width={50} height={50}>
									<CircularProgress percentage={0} />
								</Skeleton>
							</Box>
						</Box>
					</Box>
					<Box className='so-okr-card-bottom'>
						<Box className='so-okr-tags'>
							<Skeleton animation={false} width={80} height={30}>
								<Chip />
							</Skeleton>
							<Skeleton animation={false} width={100} height={30}>
								<Chip />
							</Skeleton>
							<Skeleton animation={false} variant='circle' width={20} height={20}>
								<Chip />
							</Skeleton>
						</Box>
					</Box>
				</Box>
			</Grow>
		</Box>
	);
};
