import React, { Fragment } from 'react';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@material-ui/core';
import { TeamUserIcon, UserReportingToIcon } from '../../config/svg/CommonSvgIcon';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';

export const TeamList: React.FC<any> = (props) => {
	const { userList } = props;
	return (
		<List className='team-chip-hover popper-inner' component='div' disablePadding>
			{userList &&
				userList.length &&
				userList.map((team: any, index: number) => (
					<ListItem component='div' key={index} id={`team-list-${index}`}>
						<ListItemText className='team-details' disableTypography>
							<ListItemAvatar>
								<Avatar
									className='team-avatar'
									style={{
										backgroundColor: getRandomBackgroundColor(team?.backGroundColorCode),
										color: getRandomColor(team?.colorCode),
									}}
									src={team?.imagePath}
								>
									{getUserName({
										firstName: '',
										lastName: '',
										fullName: team?.teamName,
									})}
								</Avatar>
							</ListItemAvatar>
							<Box className='team-info'>
								{team?.teamName.length > 22 ? (
									<Tooltip arrow title={team?.teamName}>
										<Typography variant='h4'>{team?.teamName.substring(0, 22)}...</Typography>
									</Tooltip>
								) : (
									<Typography variant='h4'>{team?.teamName}</Typography>
								)}
								<Box display='flex'>
									<Typography variant='subtitle2'>
										<TeamUserIcon /> {team?.memberCount}
									</Typography>
									<Typography variant='subtitle2'>
										<UserReportingToIcon />
										{team?.leaderName.length > 15 ? (
											<Tooltip arrow title={team?.leaderName}>
												<span>{team?.leaderName.substring(0, 15)}...</span>
											</Tooltip>
										) : (
											<Fragment>{team?.leaderName}</Fragment>
										)}
									</Typography>
								</Box>
							</Box>
						</ListItemText>
					</ListItem>
				))}
		</List>
	);
};
