import React, { useState, useEffect } from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, PastCycleIcon } from '../../config/svg/CommonSvg';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { OKRButton } from '../Common/OKRButton';
import { BottomArrowIcon } from '../../config/svg/CommonSvgIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { getMonthDateYear } from '../../config/utils';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { Enums } from '../../config/enums';

export const TimeFrameDropdown: React.FC<any> = (props: any) => {
	const {
		isCalenderVisible,
		soOkrTimeframeList,
		selectedTimeFrame,
		handleTimeFrameChange,
		handleTimeFrameSelection,
		selectedDateRange,
		setSelectedDateRange,
		soAttributeDetails,
		startDateValue,
		maxDateValue,
		showPastTimeFrameLink,
		handleTimeFrameEditClick,
		handleTimeFrameDeleteClick,
		isTimeFrameEdit = false,
		isTimeFrameDelete = false,
		soPermissionDetails,
		isEditTimeFrameCalenderVisible = false,
		setIsEditTimeFrameCalenderVisible = () => {},
		handleEditTieFrameDateRangeSelection,
		selectedEditTimeFrameDateRange = null,
		setSelectedEditTimeFrameDateRange = () => {},
	} = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);
	const [pastTimeFrame, setPastTimeFrame] = useState<Boolean>(false);

	useEffect(() => {
		if (isEditTimeFrameCalenderVisible) {
			handleClickAway();
			setIsEditTimeFrameCalenderVisible(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditTimeFrameCalenderVisible]);

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const handlePastTimeFrame = () => {
		setPastTimeFrame(!pastTimeFrame);
	};

	return (
		<Box
			className={`okr-team-dropdown ${open ? 'active' : ''} ${
				!isCalenderVisible ? 'dropdown-multilist-item' : 'dropdown-list-item'
			}`}
		>
			<OKRButton
				disabled={isCalenderVisible ? !soPermissionDetails?.isCreatorRights : false}
				className={'dropdown-button'}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				text={
					!isCalenderVisible
						? ''
						: soAttributeDetails?.timeFrameStartDate && soAttributeDetails?.timeFrameEndDate
						? `${getMonthDateYear(soAttributeDetails?.timeFrameStartDate)} - ${getMonthDateYear(
								soAttributeDetails?.timeFrameEndDate
						  )}`
						: 'Select Timeframe'
				}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							{pastTimeFrame && (
								<Box className='dropdown-multi-list-sub-panel'>
									<Box className='back-to-main'>
										<Typography variant='body2' onClick={handlePastTimeFrame}>
											<Link id='back-btn'>
												<BackArrow /> {t('back')}
											</Link>
											{t('pastTimeframesHead')}
										</Typography>
									</Box>
									<List component='div' disablePadding>
										{soOkrTimeframeList && soOkrTimeframeList.length ? (
											soOkrTimeframeList.map(
												(item: any) =>
													item.status === 1 && (
														<ListItem
															component='div'
															key={`key-past-${item.soTimeframeId}`}
															id={`time-frame-past-${item.soTimeframeId}`}
															className={
																(!isCalenderVisible &&
																	selectedTimeFrame &&
																	selectedTimeFrame?.soTimeframeId === item.soTimeframeId) ||
																(isCalenderVisible && soAttributeDetails?.soTimeframeId === item.soTimeframeId)
																	? 'active'
																	: ''
															}
														>
															<ListItemText
																onClick={(event) => {
																	handleTimeFrameChange && handleTimeFrameChange(item, event);
																	!isTimeFrameEdit && handleClickAway();
																}}
															>
																{getMonthDateYear(item.startDate)} - {getMonthDateYear(item.endDate)}
															</ListItemText>
															{isTimeFrameEdit && soPermissionDetails?.isCreatorRights ? (
																// <OKRButton
																// 	title={t('editTimeFrame')}
																// 	icon={<EditIcon />}
																// 	className={'editIcon'}
																// 	handleClick={(event) =>
																// 		handleTimeFrameEditClick && handleTimeFrameEditClick(event, item)
																// 	}
																// />
																<ReactDateRangePicker
																	type={'EditSOTimeFrame'}
																	selectedCycleDetails={{}}
																	startDateValue={item?.startDate || null}
																	endDateValue={item?.endDate || null}
																	minimumDate={startDateValue || new Date()}
																	maximumDate={maxDateValue || new Date()}
																	isDateRangeTextFieldVisible={true}
																	isOnlyDueDateVisible={false}
																	allCycleDetails={[]}
																	isCycleVisible={false}
																	isEndOfMonthVisible={false}
																	numberOfCalendars={Enums.ONE}
																	selectionType={'range'}
																	handleDateSelection={(
																		range: any,
																		start: any,
																		end: any,
																		selectionType: any,
																		isCycleChanged: any
																	) => {
																		handleEditTieFrameDateRangeSelection &&
																			handleEditTieFrameDateRangeSelection(range, start, end, item);
																	}}
																	selectedDateRange={selectedEditTimeFrameDateRange}
																	setSelectedDateRange={setSelectedEditTimeFrameDateRange}
																	placeholderText={t('createTimeframe')}
																	isDatePickerDisable={false}
																	customClassName={'btn-link-type1'}
																/>
															) : (
																<></>
															)}
															{isTimeFrameDelete && soPermissionDetails?.isCreatorRights ? (
																<OKRButton
																	title={t('deleteTimeFrame')}
																	icon={<DeleteIcon />}
																	className={'editIcon'}
																	handleClick={(event) =>
																		handleTimeFrameDeleteClick && handleTimeFrameDeleteClick(event, item)
																	}
																/>
															) : (
																<></>
															)}
														</ListItem>
													)
											)
										) : (
											<></>
										)}
									</List>
								</Box>
							)}

							{!pastTimeFrame ? (
								<>
									<List component='div' disablePadding>
										{soOkrTimeframeList && soOkrTimeframeList.length ? (
											soOkrTimeframeList.map(
												(item: any) =>
													item.status === 2 && (
														<ListItem
															component='div'
															key={`key-current-${item.soTimeframeId}`}
															id={`time-frame-current-${item.soTimeframeId}`}
															className={
																(!isCalenderVisible &&
																	selectedTimeFrame &&
																	selectedTimeFrame?.soTimeframeId === item.soTimeframeId) ||
																(isCalenderVisible && soAttributeDetails?.soTimeframeId === item.soTimeframeId)
																	? 'active'
																	: ''
															}
														>
															<ListItemText
																onClick={(event) => {
																	handleTimeFrameChange && handleTimeFrameChange(item, event);
																	!isTimeFrameEdit && handleClickAway();
																}}
															>
																{getMonthDateYear(item.startDate)} - {getMonthDateYear(item.endDate)}
															</ListItemText>
															{isTimeFrameEdit && soPermissionDetails?.isCreatorRights ? (
																// <OKRButton
																// 	title={t('editTimeFrame')}
																// 	icon={<EditIcon />}
																// 	className={'editIcon'}
																// 	handleClick={(event) =>
																// 		handleTimeFrameEditClick && handleTimeFrameEditClick(event, item)
																// 	}
																// />
																<ReactDateRangePicker
																	type={'EditSOTimeFrame'}
																	selectedCycleDetails={{}}
																	startDateValue={item?.startDate || null}
																	endDateValue={item?.endDate || null}
																	minimumDate={startDateValue || new Date()}
																	maximumDate={maxDateValue || new Date()}
																	isDateRangeTextFieldVisible={true}
																	isOnlyDueDateVisible={false}
																	allCycleDetails={[]}
																	isCycleVisible={false}
																	isEndOfMonthVisible={false}
																	numberOfCalendars={Enums.ONE}
																	selectionType={'range'}
																	handleDateSelection={(
																		range: any,
																		start: any,
																		end: any,
																		selectionType: any,
																		isCycleChanged: any
																	) => {
																		handleEditTieFrameDateRangeSelection &&
																			handleEditTieFrameDateRangeSelection(range, start, end, item);
																	}}
																	selectedDateRange={selectedEditTimeFrameDateRange}
																	setSelectedDateRange={setSelectedEditTimeFrameDateRange}
																	placeholderText={t('createTimeframe')}
																	isDatePickerDisable={false}
																	customClassName={'btn-link-type1'}
																/>
															) : (
																<></>
															)}
															{isTimeFrameDelete && soPermissionDetails?.isCreatorRights ? (
																<OKRButton
																	title={t('deleteTimeFrame')}
																	icon={<DeleteIcon />}
																	className={'editIcon'}
																	handleClick={(event) =>
																		handleTimeFrameDeleteClick && handleTimeFrameDeleteClick(event, item)
																	}
																/>
															) : (
																<></>
															)}
														</ListItem>
													)
											)
										) : (
											<></>
										)}
										{soOkrTimeframeList && soOkrTimeframeList.length ? (
											soOkrTimeframeList.map(
												(item: any) =>
													item.status === 3 && (
														<ListItem
															component='div'
															key={`key-past-${item.soTimeframeId}`}
															id={`time-frame-past-${item.soTimeframeId}`}
															className={
																(!isCalenderVisible &&
																	selectedTimeFrame &&
																	selectedTimeFrame?.soTimeframeId === item.soTimeframeId) ||
																(isCalenderVisible && soAttributeDetails?.soTimeframeId === item.soTimeframeId)
																	? 'active'
																	: ''
															}
														>
															<ListItemText
																onClick={(event) => {
																	handleTimeFrameChange && handleTimeFrameChange(item, event);
																	!isTimeFrameEdit && handleClickAway();
																}}
															>
																{getMonthDateYear(item.startDate)} - {getMonthDateYear(item.endDate)}
															</ListItemText>
															{isTimeFrameEdit && soPermissionDetails?.isCreatorRights ? (
																// <OKRButton
																// 	title={t('editTimeFrame')}
																// 	icon={<EditIcon />}
																// 	className={'editIcon'}
																// 	handleClick={(event) =>
																// 		handleTimeFrameEditClick && handleTimeFrameEditClick(event, item)
																// 	}
																// />
																<ReactDateRangePicker
																	type={'EditSOTimeFrame'}
																	selectedCycleDetails={{}}
																	startDateValue={item?.startDate || null}
																	endDateValue={item?.endDate || null}
																	minimumDate={startDateValue || new Date()}
																	maximumDate={maxDateValue || new Date()}
																	isDateRangeTextFieldVisible={true}
																	isOnlyDueDateVisible={false}
																	allCycleDetails={[]}
																	isCycleVisible={false}
																	isEndOfMonthVisible={false}
																	numberOfCalendars={Enums.ONE}
																	selectionType={'range'}
																	handleDateSelection={(
																		range: any,
																		start: any,
																		end: any,
																		selectionType: any,
																		isCycleChanged: any
																	) => {
																		handleEditTieFrameDateRangeSelection &&
																			handleEditTieFrameDateRangeSelection(range, start, end, item);
																	}}
																	selectedDateRange={selectedEditTimeFrameDateRange}
																	setSelectedDateRange={setSelectedEditTimeFrameDateRange}
																	placeholderText={t('createTimeframe')}
																	isDatePickerDisable={false}
																	customClassName={'btn-link-type1'}
																/>
															) : (
																<></>
															)}
															{isTimeFrameDelete && soPermissionDetails?.isCreatorRights ? (
																<OKRButton
																	title={t('deleteTimeFrame')}
																	icon={<DeleteIcon />}
																	className={'editIcon'}
																	handleClick={(event) =>
																		handleTimeFrameDeleteClick && handleTimeFrameDeleteClick(event, item)
																	}
																/>
															) : (
																<></>
															)}
														</ListItem>
													)
											)
										) : (
											<></>
										)}
									</List>
									<List component='div' disablePadding className='bottom-links'>
										{showPastTimeFrameLink ? (
											<ListItem component='div'>
												<Box className='dropdown-bottom-link'>
													<OKRButton
														id={'create-timeframe-btn'}
														className={'btn-link-type1'}
														icon={<PastCycleIcon />}
														text={t('pastTimeframes')}
														handleClick={handlePastTimeFrame}
													/>
												</Box>
											</ListItem>
										) : (
											<></>
										)}
										{isCalenderVisible ? (
											<ListItem component='div'>
												<Box className='dropdown-bottom-link'>
													{/* <OKRButton
												id={'create-timeframe-btn'}
												className={'btn-link-type1'}
												icon={<CalendarIcon />}
												text={t('createTimeframe')}
												handleClick={handleCalenderOpenClick}
											/> */}
													<ReactDateRangePicker
														type={'SO'}
														selectedCycleDetails={{}}
														startDateValue={
															soAttributeDetails?.soTimeframeId ? null : soAttributeDetails?.timeFrameStartDate || null
														}
														endDateValue={
															soAttributeDetails?.soTimeframeId ? null : soAttributeDetails?.timeFrameEndDate || null
														}
														minimumDate={startDateValue || new Date()}
														maximumDate={maxDateValue || new Date()}
														isDateRangeTextFieldVisible={true}
														isOnlyDueDateVisible={false}
														allCycleDetails={[]}
														isCycleVisible={false}
														isEndOfMonthVisible={false}
														numberOfCalendars={Enums.ONE}
														selectionType={'range'}
														handleDateSelection={(
															range: any,
															start: any,
															end: any,
															selectionType: any,
															isCycleChanged: any
														) => {
															handleTimeFrameSelection(range, start, end);
															!isTimeFrameEdit && handleClickAway();
														}}
														selectedDateRange={selectedDateRange}
														setSelectedDateRange={setSelectedDateRange}
														placeholderText={t('createTimeframe')}
														isDatePickerDisable={false}
														customClassName={'btn-link-type1'}
													/>
												</Box>
											</ListItem>
										) : (
											<></>
										)}
									</List>
								</>
							) : (
								<></>
							)}
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
