/* eslint-disable no-unused-expressions */
import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem, Typography, MenuList, Tooltip, FormLabel, FormControl, Select, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getQuarterStartEnd, formatDate, getDownloadableReportStartDate } from '../../../config/utils';
import {
	goalCycleStatusReport,
	userWiseActivityReport,
	performanceAssessmentQuickStatusReports,
	checkInReports,
	recognitionReports,
	oneOnOneReports,
	userDetailsReport,
	performanceAssessmentDetailedStatusReports,
	performanceImprovementPlanReport,
	directReportsFeedbackReport,
	userWiseOkrProgressReport,
	goalSettingReversalReport,
} from '../../Admin/AdminConstant';
import { getLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import { DownloadReportIcon } from '../../../config/svg/CommonSvg';
import { CSVLink } from 'react-csv';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
	performanceAssessmentDetailedStatusReport,
	performanceAssessmentQuickStatusReport,
	userListReport,
	oneOnOneReport,
	recognitionReport,
	checkInReport,
} from '../../../action/common';
import {
	performanceGoalFormDownloadStatusReport,
	goalCycleList,
	goalCycleRecordList,
	performanceGoalSettingReversalDownloadReport,
	getWorkflowReversalCycleList,
} from '../../../action/adminSettings';
import { fetchDownloadFeedbackReport, getOkrMasterData, fetchDownloadPipReport } from '../../../action/myGoal';
import { getPerfomanceCycleListForDownloadReports } from '../../../action/perform';
import { OKRButton } from '../../Common/OKRButton';
import { CalendarIcon } from '../../../config/svg/CommonSvgIcon';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { CycleDropdownQuickLinks } from '../../Common/CycleDropdownQuickLinks';
import { CycleDropdownDownloadReport } from '../../Common/CycleDropdownDownloadReport';
import { downloadReportsDropdownText, CompunnelDomainName } from '../../../config/constant';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { getEmployeePermission } from '../../../action/roles';
import { checkPermission } from '../../../config/rolePermission';
import {
	SynergitaFeedbackDumpReportUrl,
	SynergitaPIPSummaryReportUrl,
	SynergitaPromotionStatusReportUrl,
	SynergitaContinuousFeedbackReportUrl,
	SynergitaPIPPDFReportUrl,
} from '../../../config/url';

export default function DownloadReports(props) {
	let { currentYear } = getQuarterStartEnd();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const history = useHistory();
	const selectedYear = getLocalStorageItem('year') || currentYear;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const currentCycleId = getLocalStorageItem('cycleId') || 0;
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const { directReports, roleId, isTeamLeader } = userDetail || {};
	let userCycleDetails = JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const progressRef = useRef();
	const quarterRef = useRef();
	const statusRef = useRef();
	const { okrMasterData } = useSelector((state) => state.okrReducer);
	const { getPermissionResult } = useSelector((state) => state.rolesReducer);
	const reportEndDate = new Date();
	const [feedBackReportStartDate, setFeedBackReportStartDate] = useState(getDownloadableReportStartDate(0));
	const [directPipReportStartDate, setDirectPipReportStartDate] = useState(getDownloadableReportStartDate(0));
	const [orgPipReportStartDate, setOrgPipReportStartDate] = useState(getDownloadableReportStartDate(0));
	const [orgFeedBackReportStartDate, setOrgFeedBackReportStartDate] = useState(getDownloadableReportStartDate(0));
	const [orgUserListReportStartDate, setOrgUserListReportStartDate] = useState(getDownloadableReportStartDate(0));
	const [oneOnOneReportStartDate, setOneOnOneReportStartDate] = useState(getDownloadableReportStartDate(0));
	const [recognitionReportStartDate, setRecognitionReportStartDate] = useState(getDownloadableReportStartDate(0));
	const [checkInReportStartDate, setCheckInReportStartDate] = useState(new Date().setMonth(new Date().getMonth() - 1));
	const [goalCycleReportCycle, setGoalCycleReportCycle] = useState({});
	const [goalSettingReversalReportCycle, setGoalSettingReversalReportCycle] = useState({});
	const [goalCycleReportCycleList, setGoalCycleReportCycleList] = useState([]);
	const [goalSettingReversalCycleList, setGoalSettingReversalCycleList] = useState([]);
	const [goalCycleReportList, setGoalCycleReportList] = useState([]);
	const [goalCycleReport, setGoalCycleReport] = useState({});
	const { performanceGoalCycle, goalUserCycle } = useSelector((state) => state?.adminSettingReducer);
	const [progressLoader, setProgressLoader] = useState(false);
	const [feedbackLoader, setFeedbackLoader] = useState(0);
	const [quarterLoader, setQuarterLoader] = useState(false);
	const [statusLoader, setStatusLoader] = useState(false);
	const [goalStatusLoader, setGoalStatusLoader] = useState(false);
	const [goalSettingReversalLoader, setGoalSettingReversalLoader] = useState(false);
	const [quarterReportData, setQuarterReportData] = useState([]);
	const [progressReportData, setProgressReportData] = useState([]);
	const [assessmentLoader, setAssessmentLoader] = useState(0);
	const [isAssessmentReportsShow, setIsAssessmentReportsShow] = useState(false);
	const [userListLoader, setUserListLoader] = useState(false);
	const [oneOnOneLoader, setOneOnOneLoader] = useState(false);
	const [recognitionLoader, setRecognitionLoader] = useState(false);
	const [checkInLoader, setCheckInLoader] = useState(false);
	const [cycleDetails, setCycleDetails] = useState({});
	const [futureCycles, setFutureCycles] = useState([]);
	const [pastCycles, setPastCycles] = useState([]);
	const [performanceCycleList, setPerformanceCycleList] = useState([]);
	const [performanceCycleQuickReportList, setPerformanceCycleQuickReportList] = useState([]);
	const [performanceCycleReportList, setPerformanceCycleReportList] = useState([]);
	const [assessmentQuickReportFilterValue, setAssessmentQuickReportFilterValue] = useState(0);
	const [assessmentReportFilterValue, setAssessmentReportFilterValue] = useState(0);
	const [statusReportCycleSelectionData, setStatusReportCycleSelectionData] = useState({
		type: 0,
		cycleId: cycleId,
		cycleLabel: '',
		cycleYear: currentYear,
	});
	const [quarterReportCycleSelectionData, setQuarterReportCycleSelectionData] = useState({
		type: 0,
		cycleId: cycleId,
		cycleLabel: '',
		cycleYear: currentYear,
	});

	const [performList, setPerformList] = useState();
	const [performNameQuickSelectionData, setPerformNameQuickSelectionData] = useState({
		performanceCycleId: 0,
		empId: 0,
	});
	const [performDetailList, setPerformDetailList] = useState();
	const [performNameDetailSelectionData, setPerformNameDetailSelectionData] = useState({
		performanceCycleId: 0,
		empId: 0,
	});

	const [daysSelectionList, setDaysSelectionList] = useState();
	const [directPersonalFeedbackReportData, setDirectPersonalFeedbackReportData] = useState({
		performanceCycleId: 0,
		name: '',
	});
	const [orgPersonalFeedbackReportData, setOrgPersonalFeedbackReportData] = useState({
		performanceCycleId: 0,
		name: '',
	});
	const [orgUserListReportData, setOrgUserListReportData] = useState({
		performanceCycleId: 0,
		name: '',
	});
	const [oneOnOneReportData, setOneOnOneReportData] = useState({
		performanceCycleId: 0,
		name: '',
	});
	const [recognitionReportData, setRecognitionReportData] = useState({
		performanceCycleId: 0,
		name: '',
	});
	const [checkInReportData, setCheckInReportData] = useState({
		performanceCycleId: 0,
		name: '',
	});
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');

	const [synergitaReportSelectionList, setSynergitaReportSelectionList] = useState([
		{ performanceCycleId: '1', name: 'Feedback Dump' },
		{ performanceCycleId: '2', name: 'PIP Summary' },
		{ performanceCycleId: '3', name: 'Promotion Status' },
		{ performanceCycleId: '4', name: 'Continuous Feedback' },
		{ performanceCycleId: '5', name: 'PIP PDF' },
	]);

	const [synergitaReportData, setSynergitaReportData] = useState({
		performanceCycleId: 1,
		name: 'Feedback Dump',
	});
	const [synergitaLoader, setSynergitaLoader] = useState(false);

	useEffect(() => {
		getGoalCycleRecordList();
		getGoalSettingReversalCycleList();
	}, []);

	const getGoalCycleRecordList = async () => {
		let requestPayload = {
			finder: '',
			pageIndex: 1,
			sortingText: 'createdOn',
			order: 'desc',
			pageSize: 100,
		};
		try {
			const response = await dispatch(goalCycleRecordList(requestPayload));
			if (response) {
				if (response && response.data && response.data.status === 200) {
					const { entityList } = response.data;
					if (entityList) {
						let records = entityList;
						if (records && records.length) {
							//records = records.filter((item) => item.status !== 1 && item.status !== 2);
							let recordsCpy = records.map((item) => {
								return {
									...item,
									performanceCycleId: item.performanceGoalCycleId,
								};
							});
							setGoalCycleReportCycleList(recordsCpy);
							let activeCycle = recordsCpy.filter((item) => item.status === 3);
							if (activeCycle.length > 0) {
								setGoalCycleReportCycle(activeCycle[0]);
							} else {
								let activeCycle = recordsCpy.filter((item) => item.isLastCompletedCycle);
								if (activeCycle.length > 0) {
									setGoalCycleReportCycle(activeCycle[0]);
								}
							}
						} else {
							setGoalCycleReportCycleList([]);
						}
					} else {
						setGoalCycleReportCycleList([]);
					}
				} else {
					setGoalCycleReportCycleList([]);
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	const getGoalSettingReversalCycleList = async () => {
		try {
			const response = await dispatch(getWorkflowReversalCycleList());
			if (response) {
				if (response && response.data && response.data.status === 200) {
					const { entityList } = response.data;
					if (entityList) {
						let records = entityList;
						if (records && records.length) {
							let recordsCpy = records.map((item) => {
								return {
									...item,
									performanceCycleId: item.performanceGoalCycleId,
									name: item.performanceGoalCycleName,
								};
							});
							setGoalSettingReversalCycleList(recordsCpy);
							setGoalSettingReversalReportCycle(recordsCpy[0]);
						} else {
							setGoalSettingReversalCycleList([]);
						}
					} else {
						setGoalSettingReversalCycleList([]);
					}
				} else {
					setGoalSettingReversalCycleList([]);
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (performanceCycleReportList && performanceCycleReportList.length > 0) {
			setPerformDetailList(performanceCycleReportList[0]);
			setPerformNameDetailSelectionData({
				...performNameDetailSelectionData,
				performanceCycleId: performanceCycleReportList[0].performanceCycleId,
			});
		}
	}, [performanceCycleReportList]);

	useEffect(() => {
		daysList();
		checkCycleDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		setCycleDetails(userCycleDetails);
		if (!Boolean(okrMasterData)) {
			dispatch(getOkrMasterData());
		}
	}, []);

	useEffect(() => {
		if (cycleDetails && Object.keys(cycleDetails).length > 0) {
			let dataFound = false;
			let past = [];
			let future = [];
			cycleDetails?.cycleDetails?.forEach((cycleData) => {
				let yearCycleData = { ...cycleData, quarterDetails: [] };
				cycleData.quarterDetails.reverse()?.forEach((cycleOkrData) => {
					if (cycleOkrData.isCurrentQuarter === true) {
						dataFound = true;
						past.push({ ...yearCycleData });
						yearCycleData.quarterDetails = [];
					} else {
						yearCycleData.quarterDetails.push(cycleOkrData);
					}
				});
				if (!dataFound) {
					past.push(yearCycleData);
				} else {
					if (yearCycleData.quarterDetails.length > 0) {
						future.push(yearCycleData);
					}
				}
			});
			setFutureCycles(future);
			setPastCycles(past.reverse());
		}
	}, [cycleDetails]);

	const daysList = async () => {
		var list = [];
		Object.values(downloadReportsDropdownText).map((value, index) => {
			list.push({ performanceCycleId: index, name: value });
		});
		setDirectPersonalFeedbackReportData({
			...directPersonalFeedbackReportData,
			performanceCycleId: list[0].performanceCycleId,
			name: list[0].name,
		});

		setOrgPersonalFeedbackReportData({
			...orgPersonalFeedbackReportData,
			performanceCycleId: list[0].performanceCycleId,
			name: list[0].name,
		});

		setOrgUserListReportData({
			...orgUserListReportData,
			performanceCycleId: list[0].performanceCycleId,
			name: list[0].name,
		});

		setOneOnOneReportData({
			...oneOnOneReportData,
			performanceCycleId: list[0].performanceCycleId,
			name: list[0].name,
		});

		setRecognitionReportData({
			...recognitionReportData,
			performanceCycleId: list[0].performanceCycleId,
			name: list[0].name,
		});

		setCheckInReportData({
			...checkInReportData,
			performanceCycleId: list[0].performanceCycleId,
			name: list[0].name,
		});

		setPipReportData({
			...pipReportData,
			performanceCycleId: list[0].performanceCycleId,
			name: list[0].name,
		});
		setOrgPipReportData({
			...OrgPipReportData,
			performanceCycleId: list[0].performanceCycleId,
			name: list[0].name,
		});
		setDaysSelectionList(list);
	};

	const checkCycleDetails = async () => {
		const performanceCycleDetails = getLocalStorageItem('performanceCycleDetails');
		let list = [];
		let sortedList = [];
		if (performanceCycleDetails) {
			const perList = JSON.parse(performanceCycleDetails);
			list = perList && perList.length ? perList.filter((item) => item.status === 2 || item.status === 3) : [];
		} else {
			const response = await dispatch(getPerfomanceCycleListForDownloadReports());
			if (response && response?.data?.status === 200) {
				const { entityList } = response?.data || {};
				list =
					entityList && entityList.length ? entityList.filter((item) => item.status === 2 || item.status === 3) : [];
				setLocalStorageItem('performanceCycleDetails', JSON.stringify(list));
			} else {
				setIsAssessmentReportsShow(false);
			}
		}
		let list3 = [];
		let list2 = [];
		list.forEach((item) => {
			if (item.status === 3) {
				list3.push(item);
			} else {
				list2.push(item);
			}
		});
		sortedList = [...list3, ...list2];
		if (sortedList.length > 0) {
			setPerformanceCycleList(list);
			setPerformList(sortedList[0]);
			setPerformanceCycleQuickReportList(sortedList);
			setPerformanceCycleReportList(list);

			setAssessmentQuickReportFilterValue(list[0].performanceCycleId);
			setAssessmentReportFilterValue(list[0].performanceCycleId);
			setIsAssessmentReportsShow(true);
			setPerformNameQuickSelectionData({
				...performNameQuickSelectionData,
				performanceCycleId: sortedList[0].performanceCycleId,
			});
		} else {
			setIsAssessmentReportsShow(false);
		}
	};

	/* Status report Start*/
	const handleStatusReportCycleClick = (type, cycleId = 0, cycleLabel) => {
		let cycleYear = statusReportCycleSelectionData.cycleYear;
		if (cycleLabel.split(',')[1]) {
			cycleYear = cycleLabel.split(',')[1].trim();
		}
		setStatusReportCycleSelectionData({
			type,
			cycleId: cycleId === 0 ? currentCycleId : cycleId,
			cycleLabel,
			cycleYear,
		});
	};

	const statusReport = async () => {
		if (!statusLoader) {
			setStatusLoader(true);
			const data = `cycle=${statusReportCycleSelectionData.cycleId}&year=${
				statusReportCycleSelectionData.cycleYear
			}&isOwnTeam=${roleId === 1 ? false : Boolean(directReports)}`;
			await props
				.getStatusReport(data)
				.then((r) => {
					const url = window.URL.createObjectURL(new Blob([r.data]));
					const link = document.createElement('a');
					link.href = url;
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(r.headers['content-disposition']);
					link.setAttribute('download', matches && matches.length > 0 ? matches[1] : 'EngagementReport.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch((e) => console.log(e));
			setStatusLoader(false);
		}
	};
	/* Status report end*/

	/* quarter report Start*/
	const handleQuarterReportCycleClick = (type, cycleId = 0, cycleLabel) => {
		let cycleYear = quarterReportCycleSelectionData.cycleYear;
		if (cycleLabel.split(',')[1]) {
			cycleYear = cycleLabel.split(',')[1].trim();
		}
		setQuarterReportCycleSelectionData({
			type,
			cycleId: cycleId === 0 ? currentCycleId : cycleId,
			cycleLabel,
			cycleYear,
		});
	};

	const quarterReport = async () => {
		if (!quarterLoader) {
			setQuarterLoader(true);
			const data = `cycle=${quarterReportCycleSelectionData.cycleId}&year=${
				quarterReportCycleSelectionData.cycleYear
			}&isOwnTeam=${roleId === 1 ? false : Boolean(directReports)}`;
			await props
				.fetchQuarterReport(data)
				.then((r) => {
					const url = window.URL.createObjectURL(new Blob([r.data], { type: 'application/vnd.ms-excel' }));
					const link = document.createElement('a');
					link.href = url;
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(r.headers['content-disposition']);
					link.setAttribute('download', matches && matches.length > 0 ? matches[1] : 'Org_Report.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch((e) => console.log(e));
			// quarterRef.current.link.click();
			setQuarterLoader(false);
		}
	};

	/* quarter report end*/

	const progressReport = async () => {
		if (!progressLoader) {
			setProgressLoader(true);
			const data = `cycle=${cycleId}&year=${selectedYear}&isOwnTeam=${roleId === 1 ? false : Boolean(directReports)}`;
			await props
				.fetchProgressReport(data)
				.then((r) => setProgressReportData(r.data.entity))
				.catch((e) => console.log(e));
			progressRef.current.link.click();
			setProgressLoader(false);
		}
	};

	/* DirectPersonalFeedback report start*/
	const handleDirectPersonalFeedbackReportClick = async (performanceCycleId, name) => {
		setFeedBackReportStartDate(getDownloadableReportStartDate(performanceCycleId));

		setDirectPersonalFeedbackReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};

	const handleOrgPersonalFeedbackReportClick = async (performanceCycleId, name) => {
		setOrgFeedBackReportStartDate(getDownloadableReportStartDate(performanceCycleId));

		setOrgPersonalFeedbackReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};
	const feedbackReport = async (type) => {
		if (!feedbackLoader) {
			setFeedbackLoader(type);
			const r = await dispatch(
				fetchDownloadFeedbackReport(
					`EmployeeId=` +
						(type === 1 ? userDetail.employeeId : 0) +
						`&StartDate=` +
						(type === 1 ? formatDate(feedBackReportStartDate) : formatDate(orgFeedBackReportStartDate)) +
						`&EndDate=` +
						formatDate(reportEndDate)
				)
			);

			if (r) {
				const url = window.URL.createObjectURL(new Blob([r.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(r.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : 'Feedback.xlsx');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				setFeedbackLoader(0);
			}
		}
	};
	const pipReport = async (type) => {
		if (!feedbackLoader) {
			setFeedbackLoader(type);
			const r = await dispatch(
				fetchDownloadPipReport(
					`EmployeeId=` +
						(type === 3 ? userDetail.employeeId : 0) +
						`&StartDate=` +
						(type === 3 ? formatDate(directPipReportStartDate) : formatDate(orgPipReportStartDate)) +
						`&EndDate=` +
						formatDate(reportEndDate)
				)
			);

			if (r) {
				const url = window.URL.createObjectURL(new Blob([r.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(r.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : 'Feedback.xlsx');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				setFeedbackLoader(0);
			}
		}
	};
	/* DirectPersonalFeedback report end*/

	/* userList report start*/
	const handleOrgUserListReportClick = async (performanceCycleId, name) => {
		setOrgUserListReportStartDate(getDownloadableReportStartDate(performanceCycleId));

		setOrgUserListReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};

	const userListReportDownload = async () => {
		try {
			setUserListLoader(true);
			const response = await dispatch(
				userListReport(`startDate=` + formatDate(orgUserListReportStartDate) + `&endDate=` + formatDate(reportEndDate))
			);
			if (response) {
				const defaultName = 'UserListReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setUserListLoader(false);
		} catch (error) {
			console.error(error);
		}
	};

	/* userList report start*/
	const handleGoalCycleStatusReportClick = async (performanceCycleId, name) => {
		let data = goalCycleReportCycleList.filter((item) => item.performanceCycleId === performanceCycleId);
		if (data.length > 0) {
			setGoalCycleReportCycle({
				...data[0],
				name: name,
			});
		}
	};

	const goalCycleStatusReportClick = async () => {
		try {
			setGoalStatusLoader(true);
			const response = await dispatch(
				performanceGoalFormDownloadStatusReport(`performanceCycleId=` + goalCycleReportCycle.performanceGoalCycleId)
			);
			if (response) {
				const defaultName = 'GoalCycleStatus.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setGoalStatusLoader(false);
		} catch (error) {
			console.error(error);
		}
	};
	/* userList report end*/

	/* goal setting reversal report start*/
	const handleGoalSettingReversalReportClick = async (performanceCycleId, name) => {
		let data = goalSettingReversalCycleList.filter((item) => item.performanceCycleId === performanceCycleId);
		if (data.length > 0) {
			setGoalSettingReversalReportCycle({
				...data[0],
				name: name,
			});
		}
	};

	const goalCycleSettingReversalReportClick = async () => {
		try {
			setGoalSettingReversalLoader(true);
			const response = await dispatch(
				performanceGoalSettingReversalDownloadReport(
					`performanceGoalCycleId=` + goalSettingReversalReportCycle.performanceGoalCycleId
				)
			);
			if (response) {
				const defaultName = 'GoalSettingReversalReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setGoalSettingReversalLoader(false);
		} catch (error) {
			console.error(error);
		}
	};
	/* goal setting reversal report end*/

	/* oneOnOne report start*/
	const handleOneOnOneReportClick = async (performanceCycleId, name) => {
		setOneOnOneReportStartDate(getDownloadableReportStartDate(performanceCycleId));

		setOneOnOneReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};

	const oneOnOneReportDownload = async () => {
		try {
			setOneOnOneLoader(true);
			const requestParam = `employeeId=0&startDate=${formatDate(oneOnOneReportStartDate)}&endDate=${formatDate(
				reportEndDate
			)}`;
			const response = await dispatch(oneOnOneReport(requestParam));
			if (response) {
				const defaultName = 'OneOnOneReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setOneOnOneLoader(false);
		} catch (error) {
			console.error(error);
		}
	};
	/* oneOnOne report end*/

	/* recognition report start*/
	const handleRecognitionReportClick = async (performanceCycleId, name) => {
		setRecognitionReportStartDate(getDownloadableReportStartDate(performanceCycleId));

		setRecognitionReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};

	const recognitionReportDownload = async () => {
		try {
			setRecognitionLoader(true);
			const requestParam = `employeeId=0&startDate=${formatDate(recognitionReportStartDate)}&endDate=${formatDate(
				reportEndDate
			)}`;
			const response = await dispatch(recognitionReport(requestParam));
			if (response) {
				const defaultName = 'RecognitionReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setRecognitionLoader(false);
		} catch (error) {
			console.error(error);
		}
	};
	/* recognition report end*/

	/* checkIn report start*/
	const handleCheckInReportClick = async (performanceCycleId, name) => {
		setCheckInReportStartDate(getDownloadableReportStartDate(performanceCycleId));

		setCheckInReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};

	const checkInReportDownload = async () => {
		try {
			setCheckInLoader(true);
			const requestParam = `employeeId=0&startDate=${formatDate(checkInReportStartDate)}&endDate=${formatDate(
				reportEndDate
			)}`;
			const response = await dispatch(checkInReport(requestParam));
			if (response) {
				const defaultName = 'CheckInReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				enqueueSnackbar(
					'The data size exceeds the compatible limit please try for lesser duration or contact support',
					{ variant: 'error', autoHideDuration: 5000 }
				);
			}
			setCheckInLoader(false);
		} catch (error) {
			console.error(error);
		}
	};
	/* checkIn report end*/

	/* perform quick report Start*/
	const handlePerformReportQuickClick = (cycleId) => {
		setPerformNameQuickSelectionData({
			performanceCycleId: cycleId,
			empId: 0,
		});
	};

	const performanceAssessmentReport = async (type) => {
		try {
			setAssessmentLoader(type);
			const performanceCycleId = 0;
			const requestParam = `empId=0&performanceCycleId=${
				type === 1
					? performNameQuickSelectionData.performanceCycleId
					: type === 2
					? performNameDetailSelectionData.performanceCycleId
					: 0
			}`;
			let response = null;
			if (type === 1) {
				response = await dispatch(performanceAssessmentQuickStatusReport(requestParam));
			} else if (type === 2) {
				response = await dispatch(performanceAssessmentDetailedStatusReport(requestParam));
			}
			if (response) {
				const defaultName =
					type === 1 ? 'PerformanceAssessmentQuickStatusReport.xlsx' : 'PerformanceAssessmentDetailedStatusReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setAssessmentLoader(0);
		} catch (error) {
			console.error(error);
		}
	};

	const handlePerformReportDetailClick = (cycleId) => {
		setPerformNameDetailSelectionData({
			performanceCycleId: cycleId,
			empId: 0,
		});
	};
	/* perform quick report end*/
	const [feedBackPipReportStartDate, setFeedBackPipReportStartDate] = useState(getDownloadableReportStartDate(0));

	const [pipReportData, setPipReportData] = useState({
		performanceCycleId: 0,
		name: '',
	});
	const handleDirectPipReportClick = async (performanceCycleId, name) => {
		setDirectPipReportStartDate(getDownloadableReportStartDate(performanceCycleId));
		setPipReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};
	const [pipReportStartDate, setPipReportStartDate] = useState(getDownloadableReportStartDate(0));

	const [OrgPipReportData, setOrgPipReportData] = useState({
		performanceCycleId: 0,
		name: '',
	});
	const handleOrgPipReportClick = async (performanceCycleId, name) => {
		setOrgPipReportStartDate(getDownloadableReportStartDate(performanceCycleId));

		setOrgPipReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};

	/* Synergita report start*/
	const handleSynergitaReportDownloadClick = async () => {
		setSynergitaLoader(true);
		let fileUrl = SynergitaFeedbackDumpReportUrl;
		let fileName = 'Feedback_Dump_Report.xlsx';
		if (synergitaReportData.performanceCycleId === '1') {
			fileUrl = SynergitaFeedbackDumpReportUrl;
			fileName = 'Feedback_Dump_Report.xlsx';
		} else if (synergitaReportData.performanceCycleId === '2') {
			fileUrl = SynergitaPIPSummaryReportUrl;
			fileName = 'PIP_Summary_Report.xlsx';
		} else if (synergitaReportData.performanceCycleId === '3') {
			fileUrl = SynergitaPromotionStatusReportUrl;
			fileName = 'Promotion_Status_Report.xlsx';
		} else if (synergitaReportData.performanceCycleId === '4') {
			fileUrl = SynergitaContinuousFeedbackReportUrl;
			fileName = 'Continuous_Feedback_Report.xlsx';
		} else if (synergitaReportData.performanceCycleId === '5') {
			fileUrl = SynergitaPIPPDFReportUrl;
			fileName = 'PIP_PDF_Report.zip';
		}

		fetch(fileUrl)
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName); // Specify the file name
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url); // Clean up the URL object
			})
			.catch((error) => console.error('Download error:', error));
		setSynergitaLoader(false);
	};

	const handleSynergitaReportClick = async (performanceCycleId, name) => {
		setSynergitaReportData({
			performanceCycleId: performanceCycleId,
			name: name,
		});
	};

	/* Synergita report end*/

	return (
		<Fragment>
			{/* {roleId !== 1 && !directReports ? (
				<></>
			) : ( */}
			<>
				{/* <Box className='reports-head'>
					<Box className='reports-head-left'>
						<Typography variant='h2'>{t('downloadReport')}</Typography>
					</Box>
				</Box> */}
				<Box className='downloadable-report-content col-full'>
					<MenuList className='download-report-menu'>
						{checkPermission(getPermissionResult?.employeePermissions, userDetailsReport) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('orgUserListReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('userListSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									{/* <Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											className={'cycle-dropdown-dl-report'}
											handleCycleClick={handleOrgUserListReportClick}
											performanceCycleReportList={daysSelectionList}
											dropdownType={'performReport'}
											setPerformList={setOrgUserListReportData}
											performList={orgUserListReportData}
										/>
									</Box> */}
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											userListReportDownload();
										}}
									/>
									{userListLoader ? (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									) : (
										<></>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}
						{checkPermission(getPermissionResult?.employeePermissions, userWiseActivityReport) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('statusReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('engSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											futureCyclesList={futureCycles}
											pastCyclesList={pastCycles}
											className={'cycle-dropdown-dl-report'}
											isCycleDisable={false}
											handleCycleClick={handleStatusReportCycleClick}
											currentCycleSelectionData={statusReportCycleSelectionData}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton text={'Download'} className='btn-primary' handleClick={statusReport} />
									{statusLoader && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}
						{checkPermission(getPermissionResult?.employeePermissions, goalCycleStatusReport) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('goalCycleStatusReportTitle')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('goalCycleStatusReportTitle')}
										{/**
										 * sdgsgsggsg
										 */}
									</Typography>
								</Box>
								<Box className='col col-2'>
									{
										<Box className='helperText-bottom drawer-date-field'>
											<CycleDropdownDownloadReport
												{...props}
												className={'cycle-dropdown-dl-report'}
												handleCycleClick={handleGoalCycleStatusReportClick}
												performanceCycleReportList={goalCycleReportCycleList}
												dropdownType={'performReport'}
												setPerformList={setGoalCycleReportCycle}
												performList={goalCycleReportCycle}
											/>
										</Box>
									}
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											goalCycleStatusReportClick();
										}}
										disabled={goalCycleReportCycleList.length === 0 || null}
									/>
									{goalStatusLoader ? (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									) : (
										<></>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}

						{checkPermission(getPermissionResult?.employeePermissions, goalSettingReversalReport) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('goalSettingReversalReportTitle')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('goalSettingReversalReportTitle')}
										{/**
										 * sdgsgsggsg
										 */}
									</Typography>
								</Box>
								<Box className='col col-2'>
									{
										<Box className='helperText-bottom drawer-date-field'>
											<CycleDropdownDownloadReport
												{...props}
												className={'cycle-dropdown-dl-report'}
												handleCycleClick={handleGoalSettingReversalReportClick}
												performanceCycleReportList={goalSettingReversalCycleList}
												dropdownType={'performReport'}
												setPerformList={setGoalSettingReversalReportCycle}
												performList={goalSettingReversalReportCycle}
											/>
										</Box>
									}
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											goalCycleSettingReversalReportClick();
										}}
										disabled={goalSettingReversalCycleList.length === 0 || null}
									/>
									{goalSettingReversalLoader ? (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									) : (
										<></>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}

						{checkPermission(getPermissionResult?.employeePermissions, userWiseOkrProgressReport) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('quarterReport')}</Typography>
									<CSVLink
										data={quarterReportData}
										headers={[
											{ key: 'employeeId', label: 'Employee ID' },
											{ key: 'firstName', label: 'Employee First Name' },
											{ key: 'lastName', label: 'Employee Last Name' },
											{ key: 'emailId', label: 'Email ID' },
											{ key: 'reportingToFirstName', label: 'Reporting To First Name' },
											{ key: 'reportingToLastName', label: 'Reporting To Last Name' },
											{ key: 'objectiveName', label: 'Objective Name' },
											{ key: 'objectiveScore', label: 'Objective Score' },
											{ key: 'keyDescription', label: 'Key Description' },
											{ key: 'keyStartDate', label: 'Key Start Date' },
											{ key: 'keyDueDate', label: 'Key Due Date' },
											{ key: 'keyScore', label: 'Key Score' },
										]}
										filename='Org_Progress_Report.csv'
										style={{ display: 'none' }}
										ref={quarterRef}
										target='_blank'
									/>
									<Typography variant='h6' className='font-weight-normal'>
										{t('orgSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											futureCyclesList={futureCycles}
											pastCyclesList={pastCycles}
											className={'cycle-dropdown-dl-report'}
											isCycleDisable={false}
											handleCycleClick={handleQuarterReportCycleClick}
											currentCycleSelectionData={quarterReportCycleSelectionData}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton text={'Download'} className='btn-primary' handleClick={quarterReport} />
									{quarterLoader && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}

						{checkPermission(getPermissionResult?.employeePermissions, directReportsFeedbackReport) &&
						okrMasterData?.employeeFeedbackPermission?.isFeedbackVisibleToRM ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('directPersonalFeedbackReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('drSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											className={'cycle-dropdown-dl-report'}
											handleCycleClick={handleDirectPersonalFeedbackReportClick}
											performanceCycleReportList={daysSelectionList}
											dropdownType={'performReport'}
											setPerformList={setDirectPersonalFeedbackReportData}
											performList={directPersonalFeedbackReportData}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											feedbackReport(1);
										}}
									/>
									{feedbackLoader === 1 && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}
						{/* {roleId === 1 ? (
									<MenuItem className='reports-download'>
										<Box className='col col-1'>
											<Typography variant='body1'>{t('orgPersonalFeedbackReport')}</Typography>
											<Typography variant='h6' className='font-weight-normal'>
												{t('feedbackSubhead')}
											</Typography>
										</Box>
										<Box className='col col-2'>
											<Box className='helperText-bottom drawer-date-field'>
												<CycleDropdownDownloadReport
													{...props}
													className={'cycle-dropdown-dl-report'}
													handleCycleClick={handleOrgPersonalFeedbackReportClick}
													performanceCycleReportList={daysSelectionList}
													dropdownType={'performReport'}
													setPerformList={setOrgPersonalFeedbackReportData}
													performList={orgPersonalFeedbackReportData}
												/>
											</Box>
										</Box>
										<Box className='col col-3'>
											<OKRButton
												text={'Download'}
												className='btn-primary'
												handleClick={() => {
													feedbackReport(2);
												}}
											/>
											{feedbackLoader === 2 && (
												<Box className='spinner'>
													<Box className='bounce1'></Box>
													<Box className='bounce2'></Box>
													<Box className='bounce3'></Box>
												</Box>
											)}
										</Box>
									</MenuItem>
								) : (
									<></>
								)} */}
						{/* {directReports && okrMasterData?.performanceImprovementPlanPermission?.isPIPVisibleToRM ? (
									<MenuItem className='reports-download'>
										<Box className='col col-1'>
											<Typography variant='body1'>{t('directPipReport')}</Typography>
											<Typography variant='h6' className='font-weight-normal'>
												{t('pipSubhead')}
											</Typography>
										</Box>
										<Box className='col col-2'>
											<Box className='helperText-bottom drawer-date-field'>
												<CycleDropdownDownloadReport
													{...props}
													className={'cycle-dropdown-dl-report'}
													handleCycleClick={handleDirectPipReportClick}
													performanceCycleReportList={daysSelectionList}
													dropdownType={'performReport'}
													setPerformList={setPipReportData}
													performList={pipReportData}
												/>
											</Box>
										</Box>
										<Box className='col col-3'>
											<OKRButton
												text={'Download'}
												className='btn-primary'
												handleClick={() => {
													pipReport(3);
												}}
											/>
											{feedbackLoader === 3 && (
												<Box className='spinner'>
													<Box className='bounce1'></Box>
													<Box className='bounce2'></Box>
													<Box className='bounce3'></Box>
												</Box>
											)}
										</Box>
									</MenuItem>
								) : (
									<></>
								)} */}
						{checkPermission(getPermissionResult?.employeePermissions, performanceImprovementPlanReport) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('orgPipReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('pipReportSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											className={'cycle-dropdown-dl-report'}
											handleCycleClick={handleOrgPipReportClick}
											performanceCycleReportList={daysSelectionList}
											dropdownType={'performReport'}
											setPerformList={setOrgPipReportData}
											performList={OrgPipReportData}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											pipReport(4);
										}}
									/>
									{feedbackLoader === 4 && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}

						{checkPermission(getPermissionResult?.employeePermissions, performanceAssessmentQuickStatusReports) &&
						isAssessmentReportsShow ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('assessmentQuickReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('performQuickSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											className={'cycle-dropdown-dl-report'}
											handleCycleClick={handlePerformReportQuickClick}
											performanceCycleReportList={performanceCycleQuickReportList}
											dropdownType={'performReport'}
											setPerformList={setPerformList}
											performList={performList}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											performanceAssessmentReport(1);
										}}
									/>
									{assessmentLoader === 1 && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : checkPermission(getPermissionResult?.employeePermissions, performanceAssessmentQuickStatusReports) &&
						  !isAssessmentReportsShow ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Tooltip title={t('assessmentReportMessage')} arrow>
										<span className='disabled-reports'>
											<Typography variant='body1' className='disabled-text'>
												{t('assessmentQuickReport')}
											</Typography>
										</span>
									</Tooltip>
									<Typography variant='h6' className='font-weight-normal disabled-text'>
										{t('performQuickSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<FormControl></FormControl>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										title={t('assessmentReportMessage')}
										className='btn-primary'
										disabled={true}
										buttonType={'disableButtonTooltip'}
									/>
								</Box>
							</MenuItem>
						) : (
							<></>
						)}

						{checkPermission(getPermissionResult?.employeePermissions, performanceAssessmentDetailedStatusReports) &&
						isAssessmentReportsShow ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('assessmentDetailedReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('performDetailSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											className={'cycle-dropdown-dl-report'}
											handleCycleClick={handlePerformReportDetailClick}
											performanceCycleReportList={performanceCycleReportList}
											dropdownType={'performReport'}
											setPerformList={setPerformDetailList}
											performList={performDetailList}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											performanceAssessmentReport(2);
										}}
									/>
									{assessmentLoader === 2 && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : checkPermission(getPermissionResult?.employeePermissions, performanceAssessmentDetailedStatusReports) &&
						  !isAssessmentReportsShow ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Tooltip title={t('assessmentReportMessage')} arrow>
										<span className='disabled-reports'>
											<Typography variant='body1' className='disabled-text'>
												{t('assessmentDetailedReport')}
											</Typography>
										</span>
									</Tooltip>
									<Typography variant='h6' className='font-weight-normal disabled-text'>
										{t('performDetailSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'></Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										title={t('assessmentReportMessage')}
										className='btn-primary'
										disabled={true}
										buttonType={'disableButtonTooltip'}
									/>
								</Box>
							</MenuItem>
						) : (
							<></>
						)}
						{checkPermission(getPermissionResult?.employeePermissions, oneOnOneReports) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('oneOnOneReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('requestSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											className={'cycle-dropdown-dl-report'}
											handleCycleClick={handleOneOnOneReportClick}
											performanceCycleReportList={daysSelectionList}
											dropdownType={'performReport'}
											setPerformList={setOneOnOneReportData}
											performList={oneOnOneReportData}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											oneOnOneReportDownload();
										}}
									/>
									{oneOnOneLoader && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}
						{checkPermission(getPermissionResult?.employeePermissions, recognitionReports) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('recognitionReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('recogSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											className={'cycle-dropdown-dl-report'}
											handleCycleClick={handleRecognitionReportClick}
											performanceCycleReportList={daysSelectionList}
											dropdownType={'performReport'}
											setPerformList={setRecognitionReportData}
											performList={recognitionReportData}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											recognitionReportDownload();
										}}
									/>
									{recognitionLoader && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}
						{checkPermission(getPermissionResult?.employeePermissions, checkInReports) ? (
							<MenuItem className='reports-download'>
								<Box className='col col-1'>
									<Typography variant='body1'>{t('checkInReport')}</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('checkSubhead')}
									</Typography>
								</Box>
								<Box className='col col-2'>
									<Box className='helperText-bottom drawer-date-field'>
										<CycleDropdownDownloadReport
											{...props}
											className={'cycle-dropdown-dl-report'}
											handleCycleClick={handleCheckInReportClick}
											performanceCycleReportList={daysSelectionList}
											dropdownType={'performReport'}
											setPerformList={setCheckInReportData}
											performList={checkInReportData}
										/>
									</Box>
								</Box>
								<Box className='col col-3'>
									<OKRButton
										text={'Download'}
										className='btn-primary'
										handleClick={() => {
											checkInReportDownload();
										}}
									/>
									{checkInLoader && (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									)}
								</Box>
							</MenuItem>
						) : (
							<></>
						)}

						{tenantData && tenantData.domainName === CompunnelDomainName ? (
							<>
								<MenuItem className='reports-download'>
									<Box className='col col-1'>
										<Typography variant='body1'>{t('synergitaBackupReport')}</Typography>
										<Typography variant='h6' className='font-weight-normal'>
											{t('synergitaBackupReport')}
										</Typography>
									</Box>
									<Box className='col col-2'>
										<Box className='helperText-bottom drawer-date-field'>
											<CycleDropdownDownloadReport
												{...props}
												className={'cycle-dropdown-dl-report'}
												handleCycleClick={handleSynergitaReportClick}
												performanceCycleReportList={synergitaReportSelectionList}
												dropdownType={'performReport'}
												setPerformList={setSynergitaReportData}
												performList={synergitaReportData}
											/>
										</Box>
									</Box>
									<Box className='col col-3'>
										<OKRButton
											text={'Download'}
											className='btn-primary'
											handleClick={() => {
												handleSynergitaReportDownloadClick();
											}}
										/>
										{synergitaLoader && (
											<Box className='spinner'>
												<Box className='bounce1'></Box>
												<Box className='bounce2'></Box>
												<Box className='bounce3'></Box>
											</Box>
										)}
									</Box>
								</MenuItem>
							</>
						) : (
							<></>
						)}
					</MenuList>
				</Box>
			</>
			{/* )} */}
		</Fragment>
	);
}
