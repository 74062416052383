import React from 'react';
import Popover from '@material-ui/core/Popover';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { SearchIcon } from '../../config/svg/CommonSvg';
import { CancelIcon } from '../../config/svg/Action';
const okrStyles = makeStyles(() =>
	createStyles({
		root: {
			'&& .MuiPopover-paper': { height: 280 },
		},
	})
);
const adminStyles = makeStyles(() =>
	createStyles({
		root: {
			'&& .MuiPopover-paper': { height: 322 },
		},
	})
);
interface OKRPopoverProps {
	popperClassName?: string;
	popperInnerClass?: string;
	addButtonClassName?: string;
	anchorEl?: any;
	open?: boolean;
	setOpen?: any;
	setAnchorEl?: any;
	handlePopperClose?: (event: any, reason?: any) => void;
	isButtonVisible?: Boolean;
	isCancelButton?: Boolean;
	children?: React.ReactNode;
	useCustomStyle?: Boolean;
	popperAnchorOrigin?: any;
	popperTransformOrigin?: any;
	addUserInRole: any;
	popperHeight: any;
	popperAnchorPosition: any;
}

export const OKRPopover: React.FC<OKRPopoverProps> = (props) => {
	const classes = props.addUserInRole ? adminStyles() : okrStyles();
	const {
		popperClassName,
		addButtonClassName,
		popperInnerClass,
		anchorEl,
		open,
		setOpen,
		setAnchorEl,
		handlePopperClose,
		isButtonVisible,
		isCancelButton,
		children,
		useCustomStyle,
		popperAnchorOrigin,
		popperTransformOrigin,
		popperAnchorPosition,
	} = props;

	const handleClose = (event: any, reason: any) => {
		// setOpen(false);
		// setAnchorEl(null);
		handlePopperClose && handlePopperClose(event, reason);
	};

	return (
		<Popover
			open={open || false}
			anchorEl={anchorEl}
			onClose={handleClose}
			className={popperClassName || ''}
			anchorOrigin={popperAnchorOrigin || { vertical: 'top', horizontal: 'left' }}
			transformOrigin={popperTransformOrigin || { vertical: 'top', horizontal: 'left' }}
			anchorPosition={popperAnchorPosition}
			classes={{ root: useCustomStyle ? classes.root : '' }}
		>
			<Box className={popperInnerClass || ''}>
				{isButtonVisible ? (
					<OKRButton className={addButtonClassName || ''} icon={<SearchIcon />} color='primary' />
				) : (
					<></>
				)}
				{isCancelButton ? <OKRButton className='cancel-btn' icon={<CancelIcon />} /> : <></>}
				{children}
			</Box>
		</Popover>
	);
};
