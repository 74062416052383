import React, { useState, useEffect, Fragment } from 'react';
import { Box, Typography, Chip } from '@material-ui/core';
import { getMonthDateYear } from '../../../config/utils';
import { AlertInfoIcon, CalenderIcon } from '../../../config/svg/CommonSvg';

export const TeamReviewStats = (props: any) => {
	const {
		t,
		teamsReviewHeaderResponse,
		teamReviewUserListResponse,
		handleHeaderFilter,
		performanceGoalReviewWorkFlowTypeId,
		isPublished,
	} = props;

	return (
		<>
			<Box className='goals-review-stats-box-area'>
				{teamsReviewHeaderResponse &&
					teamsReviewHeaderResponse?.reviewWorkFlowResponse &&
					teamsReviewHeaderResponse?.reviewWorkFlowResponse.map((review: any, index: any) => (
						<Box
							className={`team-review-box ${
								review?.performanceGoalReviewWorkFlowTypeId === performanceGoalReviewWorkFlowTypeId ? 'active' : ''
							}`}
							onClick={(e) => {
								handleHeaderFilter(review?.performanceGoalReviewWorkFlowTypeId, false);
							}}
						>
							<Box className='team-review-box-head'>
								<Box className='team-review-status'>
									<Typography variant='h2'>{review?.pendingCount}</Typography>
									<Chip className='lagging-chip' label={'Pending'} />
								</Box>
								<Box className='team-review-status'>
									<Typography variant='h2'>{review?.reconsiderCount}</Typography>
									<Chip className='at-risk-chip' label={'Reconsider'} />
								</Box>
							</Box>
							<Box className={'box-content'}>
								<Typography variant='h5'>
									<span>{review?.workFlowName} </span>
								</Typography>
								<Typography variant='h6' className='font-weight-normal'>
									{review?.workFlowDescription}
								</Typography>
							</Box>
						</Box>
					))}
				<Box
					className={`team-review-box ${isPublished ? 'active' : ''}`}
					onClick={(e) => {
						handleHeaderFilter(0, true);
					}}
				>
					<Box className='team-review-box-head'>
						<Box className='team-review-status'>
							<Typography variant='h2'>
								{teamsReviewHeaderResponse && teamsReviewHeaderResponse?.publishedCount}
							</Typography>
							<Chip className='on-track-chip' label={t('publishedLabel')} />
						</Box>
					</Box>
					<Box className={'box-content'}>
						<Typography variant='h5'>
							<span>{t('publishedLabel')} </span>
						</Typography>
						<Typography variant='h6' className='font-weight-normal'>
							{t('publishedDescriptionText')}
						</Typography>
					</Box>
				</Box>
			</Box>

			{teamReviewUserListResponse && (
				<>
					{teamReviewUserListResponse?.isAlert ? (
						<Box className='goals-review-stats-notes goal-review-error-notes'>
							<AlertInfoIcon />
							<Typography variant='body2' className='review-days-remaining font-weight-normal'>
								<strong>{teamReviewUserListResponse?.daysDifferent + ' days remaining.'}</strong>
							</Typography>
							<Typography variant='body2' className='font-weight-normal'>
								{'Review Period: ' +
									getMonthDateYear(teamReviewUserListResponse?.startDate) +
									' - ' +
									getMonthDateYear(teamReviewUserListResponse?.endDate)}
							</Typography>
						</Box>
					) : (
						<Box className='goals-review-stats-notes'>
							<Typography variant='body2' className='font-weight-normal'>
								{'Review Period: ' +
									getMonthDateYear(teamReviewUserListResponse?.startDate) +
									' - ' +
									getMonthDateYear(teamReviewUserListResponse?.endDate)}
							</Typography>
						</Box>
					)}
				</>
			)}
		</>
	);
};
