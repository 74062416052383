import React, { useState } from 'react';
import { Box, ListItem, ListItemText, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../../Common/OKRButton';
import { BottomArrowIcon } from '../../../../config/svg/CommonSvgIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import { Enums } from '../../../../config/enums';
import { getScaleTypeIcon } from '../../../../config/utils';

export const ScaleTypeDropdown: React.FC<any> = (props: any) => {
	const { customScaleDetails, handleScaleTypeChange, questionTypeMasterData, callingFrom, customScaleType } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const getScaleTypeList = () => {
		const questionType = questionTypeMasterData && questionTypeMasterData?.length ? questionTypeMasterData : [];
		return callingFrom === 'manageScale' ? questionType?.filter((rec: any) => rec?.isScalable) : questionType;
	};

	return (
		<Box className={`okr-team-dropdown scale-type-dropdown  ${open ? 'active' : ''} `}>
			<OKRButton
				className={'dropdown-button'}
				disabled={customScaleType === 'View' || customScaleType === 'Edit' ? true : false}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				text={customScaleDetails?.scaleType || t('selectQuestionType')}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<Box className='dropdown-multi-list-sub-panel'>
								<List component='div' disablePadding>
									{getScaleTypeList()?.map((scaleType: any) => (
										<ListItem
											component='div'
											className={customScaleDetails?.scaleTypeId === scaleType?.questionTypeId ? 'active' : ''}
											onClick={() => {
												handleScaleTypeChange(scaleType?.questionTypeId, scaleType?.name, scaleType);
												handleClickAway();
											}}
										>
											{getScaleTypeIcon(scaleType?.questionTypeId)}
											<ListItemText>{scaleType?.name || ''}</ListItemText>
										</ListItem>
									))}
								</List>
							</Box>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
