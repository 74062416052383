/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Box, Typography, List, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HeatChartTooltip from './HeatChartTooltip';

const HeatChart: React.FC<any> = (props: any) => {
	const { heatData } = props;
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [cellData, setCellData] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const handleOpen = (event: any, item: any) => {
		setAnchorEl(event.currentTarget);
		setCellData(item);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};

	const getProgressClass = (heatProgress: number) => {
		if (heatProgress >= 80) {
			return 'color-1';
		} else if (heatProgress < 80 && heatProgress >= 60) {
			return 'color-2';
		} else if (heatProgress < 60 && heatProgress >= 40) {
			return 'color-3';
		} else if (heatProgress < 40 && heatProgress >= 20) {
			return 'color-4';
		}
		return 'color-5';
	};
	return (
		<Box className='heat-chart-area'>
			<Box className='chart-legend-default'>
				<List component='ul' disablePadding>
					<ListItem>
						<Typography className='legend-cirlce color-5'></Typography>
						<Typography variant='body2'>0% - 20%</Typography>
					</ListItem>
					<ListItem>
						<Typography className='legend-cirlce color-4'></Typography>
						<Typography variant='body2'>21% - 40%</Typography>
					</ListItem>
					<ListItem>
						<Typography className='legend-cirlce color-3'></Typography>
						<Typography variant='body2'>41% - 60%</Typography>
					</ListItem>
					<ListItem>
						<Typography className='legend-cirlce color-2'></Typography>
						<Typography variant='body2'>61% - 80%</Typography>
					</ListItem>
					<ListItem>
						<Typography className='legend-cirlce color-1'></Typography>
						<Typography variant='body2'>81% - 100%</Typography>
					</ListItem>
				</List>
			</Box>
			<List disablePadding>
				{heatData && heatData.length > 0 ? (
					heatData.map((item: any, index: any) => (
						<ListItem
							aria-haspopup='true'
							aria-owns={open ? 'mouse-over-popover' : undefined}
							//onClick={isOnHoverVisible && handleOpen}
							onClick={(e: any) => {
								handleOpen(e, item);
							}}
							id={`team-card-${index}`}
						>
							<Box className={`${getProgressClass(item.teamProgress)} team-report-card`}>
								<Typography variant='h3'>{item.teamProgress}%</Typography>
								<Typography variant='h5'>{item.teamName}</Typography>
							</Box>
						</ListItem>
					))
				) : (
					<></>
				)}
				{open && (
					<Box>
						<HeatChartTooltip
							{...props}
							open={open}
							setOpen={setOpen}
							anchorEl={anchorEl}
							setAnchorEl={setAnchorEl}
							cellData={cellData}
							getProgressClass={getProgressClass}
						/>
					</Box>
				)}
			</List>
		</Box>
	);
};

export default HeatChart;
