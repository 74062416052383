// module.exports = {
// 	getTextForTitle,
// 	getTextForDepartment,
// 	getCursorForNode,
// };

export function getTextForTitle(datum) {
	if (!datum && !datum?.hasChild) {
		return '';
	}
	// if (!datum?.person || !datum?.person?.totalReports) {
	// 	return '';
	// }

	// const {
	// 	person: { totalReports },
	// } = datum;
	// const pluralEnding = totalReports > 1 ? 's' : '';
	const { hasChild, childTeamsCount } = datum;
	return hasChild ? `${childTeamsCount}` : ''; // `${totalReports} supervisee${pluralEnding}`;
}

export function getTextForDepartment(datum) {
	const departmentAbbrMap = {
		Marketing: 'mktg',
		Operations: 'ops',
		Growth: 'gwth',
		Branding: 'brand',
		Assurance: 'fin',
		Data: 'data',
		Design: 'design',
		Communications: 'comms',
		Product: 'prod',
		People: 'people',
		Sales: 'sales',
	};

	if (!datum.person.department) {
		return '';
	}

	const { department } = datum.person;

	if (departmentAbbrMap[department]) {
		return departmentAbbrMap[department].toUpperCase();
	}

	return datum.person.department.substring(0, 3).toUpperCase();
}

export function getCursorForNode(datum) {
	return datum.children || datum._children || datum.hasChild ? 'pointer' : 'default';
}
