import React, { Fragment, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MissionVisionHistoryList } from './MissionVisionHistoryList';

export const MissionVisionHistoryDrawer: React.FC<any> = (props: any) => {
	const { missionVisionHistory } = props;
	const { t } = useTranslation();
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};
	function a11yProps(index: number) {
		return {
			id: `mv-history-tabpanel-${index}`,
			'aria-controls': `mv-history-tabpanel-${index}`,
		};
	}
	return (
		<Fragment>
			<Tabs className='okr-tabs drawer-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab label={t('historyLabel')} value={0} {...a11yProps(0)} />
			</Tabs>
			<Box className='drawer-tabs-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{tabSelected === 0 && (
						<Box className='mv-his-listing-drawer'>
							<MissionVisionHistoryList {...props} missionVisionHistory={missionVisionHistory} />
						</Box>
					)}
				</form>
			</Box>
		</Fragment>
	);
};
