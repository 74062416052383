import React, { useState, useEffect } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@material-ui/core';
// import borderRadius from 'highcharts-border-radius';

// borderRadius(Highcharts);

export const OkrSnapshotProgressChart = (props) => {
	const { chartData } = props;
	const [chartDetails, setChartDetails] = useState(null);
	const columnWithGroupedChartData = {
		credits: {
			enabled: false,
		},
		colors: ['#07a363', '#ff9401', '#ff0000'],
		title: {
			text: '',
			align: 'left',
		},
		xAxis: {
			categories: chartData?.categories || [], //['Q1, 2023', 'Q2, 2023', 'Q3, 2023', 'Q4, 2023'],
			labels: {
				style: {
					color: 'rgba(255, 255, 255, 0.5)',
					fontSize: '12px',
					fontWeight: '700',
					fontFamily: 'Lato',
				},
			},
			gridLineColor: 'rgba(161, 171, 185, 0.3)',
			lineColor: 'rgba(161, 171, 185, 0.3)',
		},
		yAxis: {
			title: {
				text: '',
			},
			labels: {
				useHTML: true,
				format: '{value} %',
				style: {
					color: 'rgba(255, 255, 255, 0.5)',
					fontSize: '12px',
					fontWeight: '700',
					fontFamily: 'Lato',
				},
			},
			gridLineColor: 'rgba(161, 171, 185, 0.3)',
			min: 0,
			max: 100,
			minTickInterval: 10,
			tickInterval: 10,
		},
		tooltip: {
			valueSuffix: ' %',
			borderWidth: 0,
			shadow: false,
			useHTML: true,
			backgroundColor: '#292929',
			borderColor: 'none',
			padding: 0,
			style: {
				color: '#fff',
				fontWeight: 'normal',
				fontFamily: 'Lato',
				fontSize: '12px',
				zIndex: '1',
			},
		},
		plotOptions: {
			series: {
				borderRadius: '0',
				borderWidth: 0,
			},
			column: {
				borderRadiusTopLeft: 6,
				borderRadiusTopRight: 6,
				borderWidth: 0,
			},
		},
		chart: {
			backgroundColor: 'transparent',
			height: '270',
		},
		legend: {
			itemDistance: 13,
			align: 'left',
			padding: 2,
			margin: 0,
			itemMarginTop: 25,
			itemMarginBottom: 0,
			symbolHeight: 8,
			symbolWidth: 8,
			symbolRadius: 6,
			itemStyle: {
				color: '#ffffff',
				fontWeight: 'bold',
				fontFamily: 'Lato',
				fontSize: '12px',
			},
			itemHoverStyle: {
				color: '#ffffff',
			},
		},
		series: [],
	};

	useEffect(() => {
		if (chartData && chartData?.series && chartData?.series?.length) {
			columnWithGroupedChartData.series = chartData?.series || [];
			setChartDetails(columnWithGroupedChartData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartData]);

	return (
		<Box className='weekly-trends-chart-bottom'>
			<Box>
				<HighchartsReact highcharts={Highcharts} options={chartDetails} />
			</Box>
		</Box>
	);
};
