import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
	MenuList,
	MenuItem,
	ListItemIcon,
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	ListItemText,
} from '@material-ui/core';
import {
	PersonalFeedbackIcon,
	ViewFeedback,
	GiveFeedbackIcon,
	AskFeedbackIcon,
	RequestOneOnOneIcon,
} from '../../config/svg/PersonalFeedbackSvg';
import '../../styles/pages/feedback/personalFeedback.scss';
import { getLocalStorageItem } from '../../services/StorageService';
import PersonalFeedbackDrawer from './PersonalFeedbackDrawer';
import AlertDialog from '../Common/Dialog';
import { checkPermission } from '../../config/rolePermission';
import { feedbackModule, OneToOneModule } from '../Admin/AdminConstant';
import { OKRButton } from '../Common/OKRButton';

const PersonalFeedbackMenu = (props) => {
	const { loggedInUser, feedbackRedirection, showPersonalFeedback, resetPFData, setFeedbackData, getPermissionResult } =
		props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	// const transitionFrom = loggedInUser ? 'bottom-end' : 'bottom-start';
	const currentUser = getLocalStorageItem('currentUser') ? JSON.parse(getLocalStorageItem('currentUser')) : null;
	const [anchorEl, setAnchorEl] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const open = Boolean(anchorEl);
	const [drawer, setDrawer] = useState({
		viewPersonalFeedback: false,
		askPersonalFeedback: false,
		givePersonalFeedback: false,
		requestOneOnOne: false,
	});

	useEffect(() => {
		if (showPersonalFeedback === 'view') {
			openDrawer('', 'viewPersonalFeedback');
		} else if (showPersonalFeedback === 'give' || showPersonalFeedback === 'viewListing') {
			openDrawer('', 'givePersonalFeedback');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPersonalFeedback]);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	const openDrawer = (e, type) => {
		handlePopoverClose();

		switch (type) {
			case 'viewPersonalFeedback':
				setDrawer({
					viewPersonalFeedback: true,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: false,
				});
				break;
			case 'askPersonalFeedback':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: true,
					givePersonalFeedback: false,
					requestOneOnOne: false,
				});
				break;
			case 'givePersonalFeedback':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: true,
					requestOneOnOne: false,
				});
				break;
			case 'requestOneOnOne':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: true,
				});
				Boolean(currentUser) && currentUser.employeeId && setModalOpen(true);
				break;
			default:
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: false,
				});
				resetPersonalFeedbackData();
				break;
		}
	};
	/** request 1:1 api calling */
	const handleCloseModal = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		const { userDetail } = props;
		if (type === 1) {
			const data = {
				requestType: 4,
				requestedTo: [Boolean(currentUser) && currentUser.employeeId],
				requestedFrom: userDetail.employeeId,
				onetoOneRemark: t('thankyouFeedback'),
			};
			setModalOpen(false);
			props
				.requestOneToOnePersonalFeedback(data)
				.then((response) => {
					if (Boolean(response) && response.data && response.data.status === 200) {
						enqueueSnackbar(t('requestRaisedSuccessfully'), {
							variant: 'success',
							autoHideDuration: 3000,
						});
					} else {
						const responseAPI = Boolean(response) && response.data && response.data.messageList;
						const keys = Boolean(responseAPI) && Object.keys(responseAPI);
						const messages = Boolean(keys) && keys.length && keys.map((item) => responseAPI[item]);
						enqueueSnackbar(messages, {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				})
				.catch((error) => {
					enqueueSnackbar(t('errorRaisingRequest'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
		}
		setModalOpen(false);
		resetPersonalFeedbackData();
	};

	const resetPersonalFeedbackData = () => {
		Boolean(showPersonalFeedback) && resetPFData();
	};
	return (
		<Fragment>
			{!feedbackRedirection && (
				<Fragment>
					{checkPermission(getPermissionResult.employeePermissions, feedbackModule) ||
					checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
						<OKRButton
							title={t('feedbacksand1and1')}
							icon={<PersonalFeedbackIcon />}
							handleClick={handlePopoverOpen}
							className='btn-circle-white'
							id='feedback-btn'
						/>
					) : (
						<></>
					)}
					<Popper
						className='popper-menu-list personal-feedback-menu'
						open={open}
						anchorEl={anchorEl}
						transition
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						placement='bottom-end'
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper>
									<ClickAwayListener onClickAway={handlePopoverClose}>
										<MenuList openDrawer={openDrawer}>
											{checkPermission(getPermissionResult.employeePermissions, feedbackModule) ? (
												<>
													{loggedInUser && (
														<MenuItem id='view-pf-popper-link' onClick={(e) => openDrawer(e, 'viewPersonalFeedback')}>
															<ListItemIcon>
																<ViewFeedback />
															</ListItemIcon>
															<ListItemText primary={t('viewFeedbackPF')} />
														</MenuItem>
													)}
													<MenuItem id='ask-pf-popper-link' onClick={(e) => openDrawer(e, 'askPersonalFeedback')}>
														<ListItemIcon>
															<AskFeedbackIcon />
														</ListItemIcon>
														<ListItemText primary={t('askFeedbackPF')} />
													</MenuItem>
													<MenuItem id='give-pf-popper-link' onClick={(e) => openDrawer(e, 'givePersonalFeedback')}>
														<ListItemIcon>
															<GiveFeedbackIcon />
														</ListItemIcon>
														<ListItemText primary={t('giveFeedbackPF')} />
													</MenuItem>
												</>
											) : (
												<></>
											)}

											{checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
												<MenuItem id='request-pf-popper-link' onClick={(e) => openDrawer(e, 'requestOneOnOne')}>
													<ListItemIcon>
														<RequestOneOnOneIcon />
													</ListItemIcon>
													<ListItemText primary={t('requestOnetoOne')} />
												</MenuItem>
											) : (
												<></>
											)}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Fragment>
			)}

			<PersonalFeedbackDrawer
				{...props}
				type={'viewPersonalFeedback'}
				openDrawer={openDrawer}
				setDrawer={setDrawer}
				open={drawer.viewPersonalFeedback}
				feedbackData={props.feedbackData}
				showPersonalFeedback={showPersonalFeedback}
				setFeedbackData={setFeedbackData}
			/>

			<PersonalFeedbackDrawer
				{...props}
				type={'askPersonalFeedback'}
				openDrawer={openDrawer}
				setDrawer={setDrawer}
				open={drawer.askPersonalFeedback}
				loggedInUser={loggedInUser}
				currentUser={currentUser}
				showPersonalFeedback={showPersonalFeedback}
				setFeedbackData={setFeedbackData}
			/>

			<PersonalFeedbackDrawer
				{...props}
				type={'givePersonalFeedback'}
				openDrawer={openDrawer}
				setDrawer={setDrawer}
				open={drawer.givePersonalFeedback}
				currentUser={currentUser}
				feedbackData={props.feedbackData}
				showPersonalFeedback={showPersonalFeedback}
				setFeedbackData={setFeedbackData}
			/>

			<PersonalFeedbackDrawer
				{...props}
				type={'requestOneOnOne'}
				openDrawer={openDrawer}
				setDrawer={setDrawer}
				open={drawer.requestOneOnOne}
				currentUser={currentUser}
				showPersonalFeedback={showPersonalFeedback}
				setFeedbackData={setFeedbackData}
			/>

			{Boolean(currentUser) && currentUser.employeeId && drawer.requestOneOnOne && modalOpen && (
				<AlertDialog
					module='oneToOne'
					message={t('requestOnToOneMsg')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};

export default PersonalFeedbackMenu;
