import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGeneratedLetter, getUploadedLetterInformationByParameters, getPreviewLetter, deleteLetterInformation, sendLetterEmail, scheduleLetterEmail, downloadZip, } from '../../action/letterGeneration';
import { Enums } from '../../config/enums';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { DialogComponent } from '../Admin/CommonComponent/DialogComponent';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControlLabel, Checkbox, Box, Typography, List, ListItem, Tooltip, Avatar, Modal, Fade } from '@material-ui/core';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../config/svg/formElementIcons';
import { DownloadIcon, SortingIcon, TickIcon, InviteUserIcon, ModalCloseIcon } from '../../config/svg/CommonSvgIcon';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, PreviewIcon } from '../../config/svg/CommonSvg';
import { OKRButton } from '../Common/OKRButton';
import LinearLoader from '../Common/Loader';
import { useSnackbar } from 'notistack';
import { AnimatedIcon } from '../Common/AnimatedIcon';
//import {NotesImg} from '../../images/no-found-notes.svg';
const NotesImg = require("../../images/no-found.svg") as string;

const LetterTable = (props: any) => {
  const { searchTerm, filterType, sortKey, sortDirection, handleSortChange, shouldUpdateData, onUpdated, filterTrigger, selectedDateRange } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  interface employee {
    id: any;
    name: string;
    empId: number;
    isChecked: boolean;
    UpdatedDate: string;
    typeOfLetter: string;
    refId: string;
    action: JSX.Element;
    emailId: string,
    annualBasicSalary: String,
    revisedDesignation: String,
    revisedCTC: String,
    effectiveFrom: String,
  }
  const [employeesBaseData, setEmployeesBaseData] = useState<employee[]>([]);
  const [employees, setEmployees] = useState<employee[]>([]);
  const [listUpdated, setListUpdated] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [displayedEmployees, setDisplayedEmployees] = useState<employee[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedLetter, setSelectedLetter] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleModalClick = (e: any) => {
    e.stopPropagation();
  }

  useEffect(() => {
    if (shouldUpdateData) {
      toggleListData();
      onUpdated();
    }
  }, [shouldUpdateData, onUpdated]);

  useEffect(() => {
    const iframe = document.getElementById('pdfViewer') as HTMLIFrameElement | null;
    if (iframe) {
      iframe.style.border = 'none';
    }
  }, []);

  React.useEffect(() => {
    let filteredData = [...employees];
    if (filterType.length > 0) {
      filteredData = filteredData.filter(employee => filterType.includes(employee.typeOfLetter));
    }

    if (searchTerm) {
      filteredData = filteredData.filter(employee2 =>
        employee2.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    if (selectedDateRange && selectedDateRange.start && selectedDateRange.end) {
      const { start: startDate, end: endDate } = selectedDateRange;
      filteredData = filteredData.filter(employee => {
        const effectiveFromDate = moment(String(employee.effectiveFrom), 'DD-MMM-YYYY');
        return effectiveFromDate.isBetween(startDate, endDate, 'day', '[]');
      });
    }

    filteredData.sort((a: any, b: any) => {
      let valA = a[sortKey];
      let valB = b[sortKey];
      if (sortKey === 'UpdatedDate') {
        valA = new Date(a[sortKey]);
        valB = new Date(b[sortKey]);
      }
      return (sortDirection === 'asc' ? valA - valB : valB - valA);
    });

    setDisplayedEmployees(filteredData);
  }, [searchTerm, sortKey, sortDirection, employees, filterTrigger, selectedDateRange]);



  const parseDate = (dateStr: any) => {
    const [day, month, year] = dateStr.split('-');
    const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month);
    return new Date(year, monthIndex, day);
  };

  const sortChange = (key: any) => {
    handleSortChange(key);
    const sortedEmployees = [...employees].sort((a: any, b: any) => {
      let valA = a[key];
      let valB = b[key];

      if (key === 'UpdatedDate') {
        valA = parseDate(a[key]);
        valB = parseDate(b[key]);
      }

      if (valA < valB) return sortDirection === 'asc' ? -1 : 1;
      if (valA > valB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
    setEmployees(sortedEmployees);
  };

  const toggleListData = () => {
    (listUpdated) ? setListUpdated(!listUpdated) : setListUpdated(!listUpdated)
  }

  const handleLetterGenerate = async (letterInformationId: any, templateName: any) => {
    try {
      let response: any = {};
      let data = {
        templateId: letterInformationId,
        columnDataMappingId: 0
      };
      response = await dispatch(getGeneratedLetter(data));
      if (response.status === Enums.STATUS_SUCCESS) {
      }
    } catch (error) {
      console.error('Failed to fetch data for template ID:', error);
    }

  };

  const handleSendLetterEmail = async (letterInformationId: any, templateId: any, columnDataMappingId: any, employeeId: any) => {
    try {
      setLoader(true);
      let response: any = {};
      const numericTemplateId = Number(templateId);
      if (isNaN(numericTemplateId)) {
        console.error("Invalid templateId:", templateId);
        setLoader(false);
        return;
      }
      let data = {
        columnDataMappingId: columnDataMappingId,
        letterInformationId: letterInformationId,
        templateId: numericTemplateId,
        employeeId: employeeId
      };

      response = await dispatch(sendLetterEmail(data));
      if (response.status === Enums.STATUS_SUCCESS) {
        const { messageList } = response?.data || {};
        const keys = messageList ? Object.keys(messageList) : [];
        const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
        enqueueSnackbar(messages || t('emailSent'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      setLoader(false);
    } catch (error) {
      console.error('Error delete :', error);
    }
  };

  const handleDeleteLetterInformation = async (columnDataMappingId: any) => {
    try {
      setLoader(true);
      let response: any = {};
      let data = {
        columnDataMappingId: Array.isArray(columnDataMappingId) ? columnDataMappingId : [columnDataMappingId]
      };
      response = await dispatch(deleteLetterInformation(data));
      if (response.status === Enums.STATUS_SUCCESS) {
        toggleListData();
        const { messageList } = response?.data || {};
        const keys = messageList ? Object.keys(messageList) : [];
        const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
        enqueueSnackbar(messages || t('letterDelete'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error delete :', error);
    }
    setLoader(false);
  };

  const handlePreviewLetter = async (filePath: any, templateName: any) => {
    try {
      setLoader(true);
      if (filePath) {
        saveAs(filePath, templateName + ".pdf");
        setLoader(false);
        enqueueSnackbar(t('letterDownload'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        console.error('File URL not found');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const processBulkAction = () => {
    const selectedEmployees = employees.filter(emp => emp.isChecked);
    let filteredData = [...employeesBaseData];
    const newList = filteredData.filter((data: any) =>
      selectedEmployees.some((sel: any) => sel.empId === data.employeeCode)
    );
    const transformedEmployees = newList.map((emp: any) => ({
      columnDataMappingId: emp.columnDataMappingId,
      templateId: emp.templateId,
      letterInformationId: emp.letterInformationId,
      employeeId: emp.employeeId
    }));
    return transformedEmployees;
  };

  const handleBulkSendEmail = async () => {
    try {
      setLoader(true);
      let response: any = {};
      response = await dispatch(scheduleLetterEmail(processBulkAction()));
      if (response.status === Enums.STATUS_SUCCESS) {
        const { messageList } = response?.data || {};
        const keys = messageList ? Object.keys(messageList) : [];
        const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
        enqueueSnackbar(messages || t('emailSentBulk'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setLoader(false);
      } else {
        setLoader(false);
        console.error('Error in Scheduling letter email.');
      }
    } catch (error) {
      setLoader(false);
      console.error('Error in while Scheduling letter email.', error);
    }
  }

  const handleBulkDownload = async () => {
    try {
      setLoader(true);
      let response: any = {};
      const downloadDataList = {
        letterInformationId: [] as number[],
        filePath: [] as string[]
      };
      const data = [...employeesBaseData]
      processBulkAction().forEach((item: any) => {
        const foundEmployee: any = data.find((emp: any) => emp.employeeId === item.employeeId);
        if (foundEmployee) {
          downloadDataList.letterInformationId.push(item.letterInformationId);
          downloadDataList.filePath.push(foundEmployee.filePath);
        }
      });
      response = await dispatch(downloadZip(downloadDataList));

      if (response.status === Enums.STATUS_SUCCESS) {
        let blob = new Blob([response.data], { type: "application/zip" });
        saveAs(blob, "letters.zip");
        const { messageList } = response?.data || {};
        const keys = messageList ? Object.keys(messageList) : [];
        const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
        enqueueSnackbar(messages || t('letterDownloadBulk'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setLoader(false);
      } else {
        setLoader(false);
        console.error('Error in downloading letter.');
      }
    } catch (error) {
      setLoader(false);
      console.error('Error in while Scheduling letter email.', error);
    }
  }

  const handleBulkDelete = async () => {
    setLoader(true);
    const data = processBulkAction().map(item => item.columnDataMappingId);
    handleDeleteLetterInformation(data);
  }

  const handleUserData = async (templateId: number, year: number) => {
    try {
      setLoader(true);
      let response: any = {};
      response = await dispatch(getUploadedLetterInformationByParameters({ templateId, year }));
      if (response.status === Enums.STATUS_SUCCESS) {
        console.log(response.data.entityList);
        const letterList = response.data.entityList.sort((a: any, b: any) => {
          const dateA: any = new Date(a.createdOn);
          const dateB: any = new Date(b.createdOn);
          return dateB - dateA;
        });
        setEmployeesBaseData(letterList);
        const formattedData = letterList.map((emp: any) => {
          const additionalInfo: any = {};

          additionalInfo["annualBasicSalary"] = emp.letterColumnResponses.find(
            (response: any) => response.columnPlaceHolder === "Annual Basic Salary"
          )?.columnValue;
          additionalInfo["revisedDesignation"] = emp.letterColumnResponses.find(
            (response: any) => response.columnPlaceHolder === "Revised Designation"
          )?.columnValue;
          additionalInfo["revisedCTC"] = emp.letterColumnResponses.find(
            (response: any) => response.columnPlaceHolder === "Revised CTC"
          )?.columnValue;
          const effectiveFromStr = emp.letterColumnResponses.find(
            (response: any) => response.columnPlaceHolder === "Effective From"
          )?.columnValue;
          const parsedDate = moment(effectiveFromStr, ['DD-MM-YYYY', 'DD-MMM-YYYY', 'YYYY-MM-DD', moment.ISO_8601], true);
          additionalInfo["effectiveFrom"] = parsedDate ? parsedDate.isValid() ? moment(parsedDate).format('DD-MMM-YYYY') : '' : '';

          return {
            id: `${uuidv4()}`,
            empId: emp.employeeCode,
            name: emp.empName,
            isChecked: false,
            UpdatedDate: moment(emp.createdOn).format('DD-MMM-YYYY'),
            typeOfLetter: emp.templateName,
            refId: emp.referenceNumber,
            emailId: emp.emailId,
            ...additionalInfo,
            action: (
              <Box className="cell-wrap">
                <OKRButton className="icon-btn" icon={<PreviewIcon />} title={t('preview')} handleClick={handleOpenModal} />
                {/* <OKRButton className={`icon-btn ${!emp.isLetterGenerated ? "disabled" : ""}`} icon={<DownloadIcon />} title={t('download')} handleClick={!emp.isLetterGenerated ? undefined : () => handlePreviewLetter(emp.filePath, emp.templateName)} /> */}
                {/* <OKRButton className={`icon-btn ${emp.isLetterGenerated ? "disabled" : ""}`} icon={<LetterIcon />} title={t('letterGen')} handleClick={emp.isLetterGenerated ? undefined : () => handleLetterGenerate(emp.letterInformationId, emp.templateId)} /> */}
                <OKRButton className={`icon-btn ${!emp.isLetterGenerated ? "disabled" : ""}`} icon={<InviteUserIcon />} title={t('sendEmail')} handleClick={() => handleSendLetterEmail(emp.letterInformationId, emp.templateId, emp.columnDataMappingId, emp.employeeId)} />
                <OKRButton className="icon-btn" icon={<DeleteIcon />} title={t('delete')} handleClick={() => handleDeleteLetter([emp.columnDataMappingId])} />
              </Box>
            )
          };
        })

        console.log(formattedData);
        const initialCheckedCount = employees.filter((employee) => employee.isChecked).length;
        setCheckedCount(initialCheckedCount);
        setEmployees(formattedData);
        setLoader(false);
      }
    } catch (error) {
      console.error('Failed to fetch data for template ID:', error);
    }
  };

  const handleDeleteLetter = (letter: any) => {
    setSelectedLetter(letter);
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    handleUserData(2, 2024);
  }, [listUpdated]);

  const [checkedCount, setCheckedCount] = useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, name, checked } = e.target;
    if (name === "allSelect") {
      let tempemployee = employees.map((employee) => {
        return { ...employee, isChecked: checked };
      });
      setEmployees(tempemployee);
      setCheckedCount(tempemployee.filter((employee) => employee.isChecked).length);
    } else {
      let tempemployee = employees.map((employee) =>
        employee.id === id ? { ...employee, isChecked: checked } : employee
      );
      setEmployees(tempemployee);
      setCheckedCount(tempemployee.filter((employee) => employee.isChecked).length);
    }
  };

  const handleDialogCancelClick = () => {
    setOpenDeleteDialog(false);
  };

  return (

    <>

      {loader ? (
        <Box className='loader-wrap'>
          <LinearLoader data={true} />
        </Box>
      ) : displayedEmployees.length > 0 ? (

        <TableContainer className='team-assessment-listing my-team-assessment-listing lg-listing'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => sortChange('name')}>
                  <Box className='cell-wrap'>
                    <FormControlLabel value='end' control={
                      <Checkbox
                        color='primary'
                        name="allSelect"
                        checked={!employees.some((employee) => employee?.isChecked !== true)}
                        onChange={handleChange}
                        icon={<BorderCheckboxIcon />}
                        checkedIcon={<CheckedIcon />}
                        indeterminateIcon={<UnCheckedIcon />}
                      />}
                      label='' labelPlacement='end' />
                    <Typography
                      variant='body2'
                      id={'sort-name'}
                      className='sort-title'
                    >
                      <span className='sortable-label'>
                        <span className='txt-wrap'>{t('nameLabelLG')}</span> <SortingIcon />
                      </span>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell onClick={() => sortChange('id')}>
                  <Box className='cell-wrap'>
                    <Typography
                      variant='body2'
                      id={'sort-name'}
                      className='sort-title'
                    >
                      <span className='sortable-label'>
                        <span className='txt-wrap'>{t('empIdLabel')}/ {t('emailIdLabel')}</span> <SortingIcon />
                      </span>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell onClick={() => sortChange('UpdatedDate')}>
                  <Box className='cell-wrap'>
                    <Typography
                      variant='body2'
                      id={'sort-name'}
                      className='sort-title'
                    >
                      <span className='sortable-label'>
                        <span className='txt-wrap'>{t('updatedLabel')} / {t('refId')}</span> <SortingIcon />
                      </span>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell onClick={() => sortChange('typeOfLetter')}>
                  <Box className='cell-wrap'>
                    <Typography
                      variant='body2'
                      id={'sort-name'}
                      className='sort-title'
                    >
                      <span className='sortable-label'>
                        <span className='txt-wrap'>{t('competences')}</span> <SortingIcon />
                      </span>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell onClick={() => sortChange('refId')}>
                  <Box className='cell-wrap'>
                    <Typography
                      variant='body2'
                      id={'sort-name'}
                      className='sort-title'
                    >
                      <span className='sortable-label'>
                        <span className='txt-wrap'>{t('detailLabel')}</span> <SortingIcon />
                      </span>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell onClick={() => sortChange('refId')}>
                  <Box className='cell-wrap'>
                    <Typography
                      variant='body2'
                      id={'sort-name'}
                      className='sort-title'
                    >
                      <span className='sortable-label'>
                        <span className='txt-wrap'>{t('effectiveFrom')}</span> <SortingIcon />
                      </span>
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedEmployees.map((employee, index) => (
                <TableRow key={employee.id} className='team-assessment-row letter-gen-row'>
                  <TableCell>
                    <Box className='cell-wrap'>
                      <FormControlLabel value='end' control={
                        <Checkbox
                          color='primary'
                          id={employee.id}
                          name={employee.name}
                          checked={employee?.isChecked || false}
                          onChange={handleChange}
                          icon={<BorderCheckboxIcon />}
                          checkedIcon={<CheckedIcon />}
                          indeterminateIcon={<UnCheckedIcon />}
                        />
                      }
                        label=''
                        labelPlacement='end'
                      />
                      <Box className='user-info'>
                        <Box className='user-info-inner'>
                          <Box className='user-img'>
                            <Avatar />
                          </Box>

                          <Box className='user-info-details'>
                            <Typography className='font-weight-normal'>
                              {(employee.name)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className='letter-action'>
                          {employee.action}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className='cell-wrap letter-cell'>
                      <Typography>{employee.empId}</Typography>
                      <Tooltip title={employee.emailId}><Typography className='email-cell'>{employee.emailId}</Typography></Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className='cell-wrap letter-cell'>
                      <Typography>{employee.UpdatedDate}</Typography>
                      <Typography>{employee.refId}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className='cell-wrap'>
                      <Typography className='letter-tag'>{employee.typeOfLetter}</Typography>
                     </Box>
                  </TableCell>
                  <TableCell>
                    <Box className='cell-wrap'>
                      <Box className='salary-info-detail'>
                        <Box>
                          {employee.annualBasicSalary ?  employee.annualBasicSalary : ''}
                        </Box>
                        <Box>
                          {employee.revisedDesignation ?  employee.revisedDesignation : ''}
                        </Box>
                        <Box>
                          {employee.revisedCTC ?  employee.revisedCTC : ''}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className='cell-wrap'>
                      {employee.effectiveFrom ? employee.effectiveFrom : 'NA'}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <Box className='welcome-content'>
            <Box textAlign='center'>
              <Typography variant='h2'>{'No records found'}</Typography>
              <AnimatedIcon
                className='no-task-image'
                width='323px'
                type='image/svg+xml'
                alt={t('noTaskHead')}
                imagePath={NotesImg}
              />
            </Box>
          </Box>
        </>
      )}


      {checkedCount > 0 && (
        <Box className='bulk-user-edit-bar'>
          <Box className='bulk-user-selected'>
            <Typography variant='body1'>
              <TickIcon />
              {checkedCount} {t('selectedLabel')}
            </Typography>
          </Box>
          <Box className='bulk-user-action'>
            <List component='ul' disablePadding>
              <ListItem>
                <OKRButton
                  icon={<InviteUserIcon />}
                  title={t('sendEmail')}
                  className='action-btn'
                  handleClick={handleBulkSendEmail}
                />
              </ListItem>
              <ListItem>
                <OKRButton
                  icon={<DownloadIcon />}
                  title={t('download')}
                  className='action-btn'
                  handleClick={handleBulkDownload}
                />
              </ListItem>
              <ListItem>
                <OKRButton
                  icon={<DeleteIcon />}
                  title={t('delete')}
                  className='action-btn'
                  handleClick={handleBulkDelete}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      )}

      <DialogComponent
        module='confirmation'
        modalOpen={openDeleteDialog}
        handleCloseModal={handleDialogCancelClick}
        matchString={Enums.DELETE_TEXT}
        handleConfirmClick={() => { setOpenDeleteDialog(false); handleDeleteLetterInformation(selectedLetter); }}
        headingText={t('deleteLetter')}
        subHeading={t('deleteLetterSubHead')}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        onClick={handleModalClick}
        closeAfterTransition
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={openModal}>
          <Box className="modal-content lg-feedback-wrapper" style={{ backgroundColor: 'white', padding: '0' }}>
            <h2 className="lg-feedback-heading">Performance Improvement Letter
              <Box className='close-modal' onClick={handleCloseModal}><ModalCloseIcon /></Box>
            </h2>
            <Box className="lg-feedback-inner-wrapper" style={{ backgroundColor: 'white', padding: '0' }}>
              <Box className="iframe-wrapper" style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
                <iframe
                  src="https://unlockokrblobcdndev.azureedge.net/e648628f-f65c-40cc-8a28-c601daf26a89/LetterGeneration/20840b57-2216-400a-b075-05823135bd53.pdf#toolbar=0"
                  style={{ border: 'none', backgroundColor: 'white' }}
                  id='custom-iframe'
                >
                </iframe>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default LetterTable;