import React, { useState } from 'react';
import { getUserDetails } from '../../../config/utils';
import { ImportList } from './ImportList';

export const MyOkrs: React.FC<any> = (props) => {
	const { t, okrKrSelectionData, setOkrKrSelectionData } = props;
	const userDetail: any = getUserDetails();

	const [modalPropsAlert, setModalPropsAlert] = useState({
		open: false,
		data: null,
		message: '',
		type: 0,
		value: '',
		cycleLabel: '',
		cycle: '',
	});
	const fetchMyOkrData = (cycleId: any[], searchString: string, pageSize: number, pageIdx: number) => {
		const payload: any = {
			employeeId: userDetail.employeeId,
			cycleId: cycleId,
			search: searchString,
			pageSize: pageSize,
			pageIndex: pageIdx,
		};
		if (!searchString) {
			setOkrKrSelectionData([]);
		}
		props.postMyOkrImport(payload);
	};

	return (
		<>
			<ImportList
				{...props}
				t={t}
				modalPropsAlert={modalPropsAlert}
				setModalPropsAlert={setModalPropsAlert}
				fetchMyOkrData={fetchMyOkrData}
				okrKrSelectionData={okrKrSelectionData}
				setOkrKrSelectionData={setOkrKrSelectionData} />
		</>
	);
};
