import React, { Fragment, useState, useEffect } from 'react';
import { CssBaseline } from '@material-ui/core';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import KRContributors from '../KRContributors';
import { useTranslation } from 'react-i18next';

const WeeklyKRChart = (props) => {
	const { chartData } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [chartDetails, setChartDetails] = useState([]);
	const [apiDetails, setAPIDetails] = useState([]);
	const [memberDetails, setMemberDetails] = useState(null);

	useEffect(() => {
		getReportDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartData]);

	const getReportDetails = async () => {
		if (chartData.status === 'fulfilled') {
			const mostWeeklyKrReportData = chartData.value;
			if (mostWeeklyKrReportData && mostWeeklyKrReportData.data && mostWeeklyKrReportData.data.entityList) {
				const details = mostWeeklyKrReportData.data.entityList;
				const ChartData =
					details &&
					details.length &&
					details.map((item, index) => ({
						x: index,
						y: item.krUpdate,
						name: item.goalKeyId,
						id: item.goalKeyId,
					}));
				setAPIDetails(details);
				setChartDetails(ChartData);
			}
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const openOkrProgressView = (event, category, yAxis) => {
		const { point } = event;
		const memberList = apiDetails && apiDetails.length ? apiDetails.find((item) => item.goalKeyId === point.id) : [];
		setMemberDetails(memberList);
		setAnchorEl(event);
	};
	const onContributorNameClick = (event, contributorDetails) => {
		event.preventDefault();
		setAnchorEl(null);
	};
	const { t } = useTranslation();
	const basicColumnChartData = {
		chart: {
			type: 'column',
			height: 340,
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		subtitle: {
			text: '',
		},
		xAxis: {
			crosshair: false,
			title: {
				text: `${t('mosttop6KR')}`,
			},
			visible: false,
		},
		yAxis: {
			min: 0,
			max: chartDetails && chartDetails.length > 0 ? null : 25,
			title: {
				text: '',
			},
			lineWidth: 1,
			minTickInterval: 2,
			minorTickInterval: 'auto',
			minorGridLineDashStyle: 'dash',
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				point: {
					events: {
						click: function (event) {
							openOkrProgressView(event, this.category, this.y);
						},
					},
				},
				states: {
					hover: {
						borderColor: 'rgba(57, 163, 250, 0.2)',
						borderWidth: '8px',
						borderRadius: '3px 3px 0px 0px',
					},
				},
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
				borderRadius: '3px 3px 0px 0px',
			},
		},
		series: [
			{
				name: '',
				data: chartDetails,
				color: {
					linearGradient: {
						x1: 0,
						x2: 0,
						y1: 0,
						y2: '100%',
					},
					stops: [
						['0%', '#1D6CB5'],
						['63.54%', '#0664B1'],
						['100%', '#0664B1'],
					],
				},
				states: {
					hover: {
						borderColor: 'rgba(57, 163, 250, 0.2)',
						borderWidth: '8px',
						borderRadius: '3px 3px 0px 0px',
					},
				},
			},
		],
	};

	return (
		<Fragment>
			<CssBaseline />
			<HighchartsReact highcharts={Highcharts} options={basicColumnChartData} />
			{memberDetails && (
				<KRContributors
					contributorList={[
						{
							firstName: memberDetails?.firstName,
							lastName: memberDetails?.lastName,
							krUpdates: memberDetails?.userKrUpdate,
							imagePath: memberDetails?.imagePath,
							score: memberDetails?.score,
							owner: true,
						},
						...memberDetails.keyContributorsResponses,
					]}
					anchorEl={anchorEl}
					onContributorNameClick={onContributorNameClick}
					handleClose={handleClose}
					isProgress={false}
					{...props}
					objectiveName={memberDetails.objectiveName || ''}
					keyDescription={memberDetails.keyDescription || ''}
					customClass={'weekly-kr-card-wrap'}
				/>
			)}
		</Fragment>
	);
};

export default WeeklyKRChart;
