import { useHistory } from 'react-router';
import * as actionTypes from './actionTypes';
import {
	getRolesListApi,
	addRolesAPI,
	editRolesAPI,
	assignRolesAPI,
	changeRoleStatusAPI,
	changeRolePermissionAPI,
	addRolesPermissionAPI,
	getRolesAndPermissionAPI,
	getRolesAPI,
	getAllPermissionAPI,
	getEmployeePermissionAPI,
	updateRoleAPI,
	getRoleByIdAPI,
	updateRoleNameAPI,
	updatePermissionAPI,
	deleteRoleAPI,
	cloneRoleAPI,
	getRolesListAPI,
	assignRoleAPI,
	getEmployeePermissionByIdAPI,
	employeePermissionsAPI,
	getRolePermissionByRoleIdAPI,
} from '../services/RolesService';
import { showLoader } from './common';
import { UNAUTHORIZED } from '../config/app-url';

// const history = useHistory();

const fetchRolesPending = () => ({
	type: actionTypes.FETCH_ROLES_PENDING,
});
const fetchRolesCompleted = (payload) => ({
	type: actionTypes.FETCH_ROLES_SUCCESS,
	payload,
});
const fetchRolesFailed = (error) => ({
	type: actionTypes.FETCH_ROLES_FAILED,
	error,
});

export const getRolesList = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchRolesPending());
		return getRolesListApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchRolesCompleted(response.data));
				} else {
					dispatch(fetchRolesFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchRolesFailed(error));
				dispatch(showLoader(false));
			});
	};
};

const addRolesPending = () => ({
	type: actionTypes.ADD_ROLES_PENDING,
});
const addRolesCompleted = (payload) => ({
	type: actionTypes.ADD_ROLES_SUCCESS,
	payload,
});
const addRolesFailed = (error) => ({
	type: actionTypes.ADD_ROLES_FAILED,
	error,
});

export const addRoles = (data) => {
	return (dispatch) => {
		dispatch(addRolesPending());
		return addRolesAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(addRolesCompleted(response.data));
				} else {
					dispatch(addRolesFailed(response.data));
				}
			})
			.catch(() => {
				dispatch(addRolesFailed('failed'));
			});
	};
};

const editRolesPending = () => ({
	type: actionTypes.EDIT_ROLES_PENDING,
});
const editRolesCompleted = (payload) => ({
	type: actionTypes.EDIT_ROLES_SUCCESS,
	payload,
});
const editRolesFailed = (error) => ({
	type: actionTypes.EDIT_ROLES_FAILED,
	error,
});

export const editRoles = (data) => {
	return (dispatch) => {
		dispatch(editRolesPending());
		return editRolesAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(editRolesCompleted(response.data));
				} else {
					dispatch(editRolesFailed(response.data));
				}
			})
			.catch(() => {
				dispatch(editRolesFailed('failed'));
			});
	};
};

const assignRolesPending = () => ({
	type: actionTypes.ASSIGN_ROLES_PENDING,
});
const assignRolesCompleted = (payload) => ({
	type: actionTypes.ASSIGN_ROLES_SUCCESS,
	payload,
});
const assignRolesFailed = (error) => ({
	type: actionTypes.ASSIGN_ROLES_FAILED,
	error,
});

export const assignRoles = (data) => {
	return (dispatch) => {
		dispatch(assignRolesPending());
		return assignRolesAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(assignRolesCompleted(response.data));
				} else {
					dispatch(assignRolesFailed(response.data));
				}
			})
			.catch(() => {
				dispatch(assignRolesFailed('failed'));
			});
	};
};

export const changeRoleStatus = (data) => {
	return () => {
		return changeRoleStatusAPI(data);
	};
};

export const changeRolePermission = (data) => {
	return () => {
		return changeRolePermissionAPI(data);
	};
};

export const updateStatus = () => ({
	type: actionTypes.UPDATE_ROLE_STATUS,
});

/** action for add role and permission */

export const addRolePermission = (data) => {
	return () => {
		return addRolesPermissionAPI(data);
	};
};

/** action for add role and permission */
const fetchRolePending = () => ({
	type: actionTypes.FETCH_ROLES_PENDING,
});
const fetchRoleCompleted = (payload) => ({
	type: actionTypes.FETCH_ROLES_SUCCESS,
	payload,
});
const fetchRoleFailed = (error) => ({
	type: actionTypes.FETCH_ROLES_FAILED,
	error,
});
export const getRoles = (data) => {
	return (dispatch) => {
		dispatch(fetchRolePending());
		return getRolesAPI(data)
			.then((response) => {
				dispatch(fetchRoleCompleted(response.data));
			})
			.catch((error) => {
				dispatch(fetchRoleFailed(error));
			});
	};
};

/** action for add role and permission */
const getRolesListPending = () => ({
	type: actionTypes.FETCH_ROLES_LIST_PENDING,
});
const getRolesListCompleted = (payload) => ({
	type: actionTypes.FETCH_ROLES_LIST_SUCCESS,
	payload,
});
const getRolesListFailed = (error) => ({
	type: actionTypes.FETCH_ROLES_LIST_FAILED,
	error,
});
export const getActiveRoles = (data) => {
	return (dispatch) => {
		dispatch(getRolesListPending());
		return getRolesListAPI(data)
			.then((response) => {
				dispatch(getRolesListCompleted(response.data));
			})
			.catch((error) => {
				dispatch(getRolesListFailed(error));
			});
	};
};
export const getRolesAndPermission = (data) => {
	return () => {
		return getRolesAndPermissionAPI(data);
	};
};

export const getRoleListing = (data) => {
	return () => {
		return getRolesAPI(data);
	};
};

export const deleteRole = (data) => {
	return () => {
		return deleteRoleAPI(data);
	};
};

/** Get permissions */
const getPermissionsPending = () => ({
	type: actionTypes.GET_PERMISSIONS_PENDING,
});
const getPermissionsCompleted = (payload) => ({
	type: actionTypes.GET_PERMISSIONS_SUCCESS,
	payload,
});
const getPermissionsFailed = (error) => ({
	type: actionTypes.GET_PERMISSIONS_FAILED,
	error,
});

export const getPermissions = (data) => {
	return (dispatch) => {
		dispatch(getPermissionsPending());
		return getAllPermissionAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(getPermissionsCompleted(response.data));
				} else {
					dispatch(getPermissionsFailed(response.data));
				}
			})
			.catch((err) => {
				dispatch(getPermissionsFailed(err));
			});
	};
};

const getPermissionPending = () => ({
	type: actionTypes.GET_PERMISSION_PENDING,
});
const getPermissionCompleted = (payload) => ({
	type: actionTypes.GET_PERMISSION_SUCCESS,
	payload,
});
const getPermissionFailed = (error) => ({
	type: actionTypes.GET_PERMISSION_FAILED,
	error,
});

export const getEmployeePermission = (data, history) => {
	return (dispatch) => {
		dispatch(getPermissionPending());
		return getEmployeePermissionAPI(data)
			.then((response) => {
				if (response?.data?.status === 200) {
					if ((response?.entity === {} || null) && (response?.entity?.employeePermissions === [] || null)) {
						history && history.push(UNAUTHORIZED)
					} else {
						dispatch(getPermissionCompleted(response.data));
					}
				} else {
					history && history.push(UNAUTHORIZED)
				}
			})
			.catch((error) => {
				// Commented below line to execute axios interceptors logic
				// history && history.push(UNAUTHORIZED)
				dispatch(getPermissionFailed(error));
			});
	};
};

/** Get Role by ID */

const getRolePending = () => ({
	type: actionTypes.GET_ROLE_PENDING,
});
const getRoleCompleted = (payload) => ({
	type: actionTypes.GET_ROLE_SUCCESS,
	payload,
});
const getRoleFailed = (error) => ({
	type: actionTypes.GET_ROLE_FAILED,
	error,
});

export const resetRole = () => ({
	type: actionTypes.RESET_CURRENT_ROLE,
});

export const resetAllRoleStatus = (data) => ({
	type: actionTypes.UPDATE_ROLE_STATUS_RESET,
	data,
});

export const getRole = (data) => {
	return (dispatch) => {
		dispatch(getRolePending());
		return getRoleByIdAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(getRoleCompleted(response.data));
				} else {
					dispatch(getRoleFailed(response.data));
				}
			})
			.catch((err) => {
				dispatch(getRoleFailed(err));
			});
	};
};

/** Update role */

const updateRolePending = () => ({
	type: actionTypes.UPDATE_ROLE_PENDING,
});
const updateRoleCompleted = (payload) => ({
	type: actionTypes.UPDATE_ROLE_SUCCESS,
	payload,
});
const updateRoleFailed = (error) => ({
	type: actionTypes.UPDATE_ROLE_FAILED,
	error,
});

export const resetRoleStatus = (data) => ({
	type: actionTypes.UPDATE_ROLE_RESET,
	data,
});

export const updateRole = (data) => {
	return (dispatch) => {
		dispatch(updateRolePending());
		dispatch(updatedRolesList());
		return updateRoleAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(updateRoleCompleted(response.data));
				} else {
					dispatch(updateRoleFailed(response.data));
				}
			})
			.catch((err) => {
				dispatch(updateRoleFailed(err));
			});
	};
};

/** Update Role Name */

const updateRoleNamePending = () => ({
	type: actionTypes.UPDATE_ROLENAME_PENDING,
});
const updateRoleNameCompleted = (payload) => ({
	type: actionTypes.UPDATE_ROLENAME_SUCCESS,
	payload,
});
const updateRoleNameFailed = (error) => ({
	type: actionTypes.UPDATE_ROLENAME_FAILED,
	error,
});

export const updateRoleName = (data) => {
	return (dispatch) => {
		dispatch(updateRoleNamePending());
		dispatch(updatedRolesList());
		return updateRoleNameAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(updateRoleNameCompleted(response.data));
				} else {
					dispatch(updateRoleNameFailed(response.data));
				}
			})
			.catch((err) => {
				dispatch(updateRoleNameFailed(err));
			});
	};
};

export const updatePermission = (data) => {
	return () => {
		return updatePermissionAPI(data);
	};
};

export const updatedRolesList = () => ({
	type: actionTypes.UPDATE_ROLES_LIST,
});
/**
 * Clone role
 */
export const cloneRole = (data) => {
	return () => {
		return cloneRoleAPI(data);
	};
};

/**
 * Assign Role
 */

export const assignRole = (data) => {
	return () => {
		return assignRoleAPI(data);
	};
};

// get employeePermission

const getEmployeePermissionByIdPending = () => ({
	type: actionTypes.GET_USER_PERMISSION_PENDING,
});
const getEmployeePermissionByIdCompleted = (payload) => ({
	type: actionTypes.GET_USER_PERMISSION_SUCCESS,
	payload,
});
const getEmployeePermissionByIdFailed = (error) => ({
	type: actionTypes.GET_USER_PERMISSION_FAILED,
	error,
});

export const getEmployeePermissionsById = (data) => {
	return (dispatch) => {
		dispatch(getEmployeePermissionByIdPending());
		return getEmployeePermissionByIdAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(getEmployeePermissionByIdCompleted(response.data));
				} else {
					dispatch(getEmployeePermissionByIdFailed(response.data));
				}
			})
			.catch((error) => {
				dispatch(getEmployeePermissionByIdFailed(error));
			});
	};
};

/** Save user permissions */

export const saveEmployeePermissions = (data) => {
	return () => {
		return employeePermissionsAPI(data);
	};
};

/**
 * get role permission by Role Id
 */
const getRolePermissionByRoleIdPending = () => ({
	type: actionTypes.GET_ROLES_BY_ROLE_ID_PENDING,
});
const getRolePermissionByRoleIdCompleted = (payload) => ({
	type: actionTypes.GET_ROLES_BY_ROLE_ID_SUCCESS,
	payload,
});
const getRolePermissionByRoleIdFailed = (error) => ({
	type: actionTypes.GET_ROLES_BY_ROLE_ID_FAILED,
	error,
});

export const getRolePermissionByRoleId = (data) => {
	return (dispatch) => {
		dispatch(getEmployeePermissionByIdPending());
		return getRolePermissionByRoleIdAPI(data)
			.then((response) => {
				if (response.data.status === 200) {
					dispatch(getEmployeePermissionByIdCompleted(response.data));
				} else {
					dispatch(getEmployeePermissionByIdFailed(response.data));
				}
			})
			.catch((error) => {
				dispatch(getEmployeePermissionByIdFailed(error));
			});
	};
};
