import React, { useState } from 'react';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/engagement/one-on-one.scss';
import { DeleteIcon, SortingIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import {
	AddLinkIcon,
	EditIcon,
	EditTemplateIcon,
	LinkIcon,
	MeetingLinkIcon,
	MissionVisionIcon,
} from '../../../config/svg/CommonSvg';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { getFullName, getMonthDateYear, getUserName } from '../../../config/utils';
import MeetingNotesDownload from './MeetingNotesDownload';

export const MeetingList = (props: any) => {
	const {
		oneOnOneList,
		sortUserList,
		sortingColumn,
		sortOrder,
		setLoader,
		handleClickRequestOneOnOneEdit,
		handleClickRequestOneOnOneDelete,
		handleClickRequestOneOnOneLaunch,
		handleClickRequestOneOnOneEditLaunch,
		handleClickEditLaunch,
		handleClickStartLaunch,
	} = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);

	const handleClose = () => {
		setAnchorEl(null);
	};
	return oneOnOneList && oneOnOneList.length > 0 ? (
		<Box className='meeting-listing'>
			<Box className='okr-list-panel-head'>
				<List component='ul'>
					<ListItem className='meeting-col1'>
						<Typography
							// onClick={(e) => sortUserList(e, 'name')}
							// className={sortingColumn === 'name' ? `sort-title ${sortOrder}` : 'sort-title asc'}
							className={'sort-title'}
							variant='body2'
						>
							<span className='sortable-label'>
								<span className='txt-wrap' dangerouslySetInnerHTML={{ __html: t('oneOnOneWithHtml') }}></span>
							</span>
						</Typography>
					</ListItem>
					<ListItem className='meeting-col2'>
						<Typography
							// onClick={(e) => sortUserList(e, 'name')}
							// className={sortingColumn === 'name' ? `sort-title ${sortOrder}` : 'sort-title asc'}
							className={'sort-title'}
							variant='body2'
						>
							<span className='sortable-label'>
								<span className='txt-wrap'>{t('titleLabel')}</span>
							</span>
						</Typography>
					</ListItem>
					<ListItem className='meeting-col3'>
						<Typography
							onClick={(e) => sortUserList(e, 'createdOn')}
							className={sortingColumn === 'createdOn' ? `sort-title ${sortOrder}` : 'sort-title asc'}
							variant='body2'
						>
							<span className='sortable-label'>
								<span className='txt-wrap'>{t('requestedOn')}</span> <SortingIcon />
							</span>
						</Typography>
					</ListItem>
					<ListItem className='meeting-col4'>
						<Typography
							onClick={(e) => sortUserList(e, 'updatedOn')}
							className={sortingColumn === 'updatedOn' ? `sort-title ${sortOrder}` : 'sort-title asc'}
							variant='body2'
						>
							<span className='sortable-label'>
								<span className='txt-wrap'>{t('lastModifiedLabel')}</span> <SortingIcon />
							</span>
						</Typography>
					</ListItem>
				</List>
			</Box>
			<Box className='meeting-listing-area'>
				{oneOnOneList?.map((item: any, index: any) => (
					<Box
						className='meeting-listing-content'
						key={`one_on_one_request_item_${item?.oneToOneDetailId || index + 1}`}
					>
						<List component='ul'>
							<ListItem className='meeting-col1'>
								<Box className='user-info'>
									<Box className='user-img'>
										{Boolean(item.imagePath) ? (
											<Avatar alt={`${item?.firstName} ${item?.lastName}`} src={item.imagePath} />
										) : (
											<Avatar>{getUserName(item)}</Avatar>
										)}
										{item?.isReciver ? (
											<Tooltip title={t('requested1on1withYou')} arrow placement='bottom-start'>
												<span className='request-arrow  arrow-left'>
													<BackArrow />
												</span>
											</Tooltip>
										) : (
											<Tooltip title={t('youRequested1on1')} arrow placement='bottom-start'>
												<span className='request-arrow'>
													<BackArrow />
												</span>
											</Tooltip>
										)}
									</Box>
									<Box className='user-info-details'>
										{/* {item?.firstName && getFullName(item) && getFullName(item).length > 14 ? (
											<Tooltip arrow title={getFullName(item) || ''}>
												<Typography variant='h4'>{getFullName(item).substring(0, 14)}...</Typography>
											</Tooltip>
										) : ( */}
										<Box>
											<Tooltip arrow title={getFullName(item) || ''}>
												<Typography variant='h4'>
													{item?.firstName || ''} {item?.lastName || ''}
												</Typography>
											</Tooltip>
										</Box>
										{/* )} */}
										{/* {item?.emailId && item?.emailId.length > 18 ? (
											<Tooltip arrow title={item?.emailId || ''}>
												<Typography variant='subtitle2'>{item?.emailId.substring(0, 18)}...</Typography>
											</Tooltip>
										) : ( */}
										<Box>
											<Tooltip arrow title={item?.emailId || ''}>
												<Typography variant='subtitle2'>{item?.emailId || ''}</Typography>
											</Tooltip>
										</Box>
										{/* )} */}
									</Box>
								</Box>
							</ListItem>
							<ListItem className='meeting-col2 meeting-col2-link'>
								{/* {item?.title && item?.title.length > 25 ? (
									<Tooltip arrow title={item?.title || ''}>
										<Typography>{item?.title.substring(0, 25)}...</Typography>
									</Tooltip>
								) : ( */}
								<Tooltip arrow title={item?.title || ''}>
									<Typography className='title-name'>{item?.title || ''}</Typography>
								</Tooltip>
								{/* )} */}
								{item?.meetingLink ? (
									<OKRButton
										className='meeting-btn'
										icon={<AddLinkIcon />}
										// handleClick={() => {
										// 	window.open(item?.meetingLink, '_blank');
										// }}
										title={item?.meetingLink || ''}
									/>
								) : (
									<></>
								)}
							</ListItem>
							<ListItem className='meeting-col3'>
								<Box className='date-txt'>
									<Typography>{item?.createdOn ? getMonthDateYear(item?.createdOn) : ''}</Typography>
								</Box>
							</ListItem>
							<ListItem className='meeting-col4'>
								<Box className='date-txt'>
									<Typography>{item?.updatedOn ? getMonthDateYear(item?.updatedOn) : ''}</Typography>
								</Box>
								{!item?.isLaunched ? (
									<Box className='user-actions'>
										<OKRButton
											className='btn-link btn-edit-link'
											text={''}
											icon={<MissionVisionIcon />}
											title={t('launchTheMeeting')}
											handleClick={(e: any) => handleClickStartLaunch(e, item)}
										/>
										<MeetingNotesDownload setLoader={setLoader} itemDetail={item} {...props} />
										<PopperMenu
											className={`user-menu-btn ${!item?.isReciver ? '' : 'disabled-button'}`}
											anchorEl={anchorEl}
											handleClose={handleClose}
											includeKebabMenu={true}
											menuClicked={popperMenuClicked}
											setPopperMenuClicked={setPopperMenuClicked}
											btnId={`more-btn-`}
											{...props}
											children={
												<>
													<ListItem onClick={(e: any) => handleClickRequestOneOnOneEdit(e, item)}>
														<ListItemAvatar>
															<EditIcon />
														</ListItemAvatar>
														<ListItemText primary={t('updateRequest')} />
													</ListItem>
													<ListItem onClick={(e: any) => handleClickRequestOneOnOneDelete(e, item)}>
														<ListItemAvatar>
															<DeleteIcon />
														</ListItemAvatar>
														<ListItemText primary={t('delete')} />
													</ListItem>
												</>
											}
										></PopperMenu>
									</Box>
								) : (
									<Box className='user-actions'>
										<OKRButton
											className='btn-link btn-edit-link'
											text={''}
											icon={<EditTemplateIcon />}
											title={t('EditMeeting')}
											handleClick={(e: any) => handleClickEditLaunch(e, item)}
										/>
										<MeetingNotesDownload setLoader={setLoader} itemDetail={item} {...props} />
										<PopperMenu
											className={`user-menu-btn ${!item?.isReciver ? '' : 'disabled-button'}`}
											anchorEl={anchorEl}
											handleClose={handleClose}
											includeKebabMenu={true}
											menuClicked={popperMenuClicked}
											setPopperMenuClicked={setPopperMenuClicked}
											btnId={`more-btn-`}
											{...props}
											children={
												<>
													<ListItem onClick={(e: any) => handleClickRequestOneOnOneDelete(e, item)}>
														<ListItemAvatar>
															<DeleteIcon />
														</ListItemAvatar>
														<ListItemText primary={t('delete')} />
													</ListItem>
												</>
											}
										></PopperMenu>
									</Box>
								)}
							</ListItem>
						</List>
					</Box>
				))}
				{/* <Box className='meeting-listing-content'>
					<List component='ul'>
						<ListItem className='meeting-col1'>
							<Box className='user-info'>
								<Box className='user-img'>
									<Avatar className='avatar-default'>SG</Avatar>
									<Tooltip title={'You Requested 1-on-1'} arrow placement='bottom-start'>
										<span className='request-arrow arrow-left'>
											<BackArrow />
										</span>
									</Tooltip>
								</Box>
								<Box className='user-info-details'>
									<Typography variant='h4'>{'Esther Howard'}</Typography>
									<Typography variant='subtitle2'>{'test@gmail.com'}</Typography>
								</Box>
							</Box>
						</ListItem>
						<ListItem className='meeting-col2'>
							<Typography>{'This growth plan will help you r...'}</Typography>
						</ListItem>
						<ListItem className='meeting-col3'>
							<Box className='date-txt'>
								<Typography>{'Dec 22, 2022'}</Typography>
							</Box>
						</ListItem>
						<ListItem className='meeting-col4'>
							<Box className='date-txt'>
								<Typography>{'Dec 22, 2022'}</Typography>
							</Box>
							<Box className='user-actions'>
								<OKRButton
									className='btn-link'
									text={t('launch')}
									icon={<MissionVisionIcon />}
									//title={t('editUserDetailsText')}
									id={`launch-icon`}
								/>
								<PopperMenu
									className={'user-menu-btn'}
									anchorEl={anchorEl}
									handleClose={handleClose}
									includeKebabMenu={true}
									menuClicked={popperMenuClicked}
									setPopperMenuClicked={setPopperMenuClicked}
									btnId={`more-btn-`}
									{...props}
									children={
										<>
											<ListItem id={`edit-user`}>
												<ListItemAvatar>
													<EditIcon />
												</ListItemAvatar>
												<ListItemText primary={t('updateRequest')} />
											</ListItem>
											<ListItem id={`delete-user`}>
												<ListItemAvatar>
													<DeleteIcon />
												</ListItemAvatar>
												<ListItemText primary={t('delete')} />
											</ListItem>
										</>
									}
								></PopperMenu>
							</Box>
						</ListItem>
					</List>
				</Box>
				<Box className='meeting-listing-content'>
					<List component='ul'>
						<ListItem className='meeting-col1'>
							<Box className='user-info'>
								<Box className='user-img'>
									<Avatar className='avatar-default'>SG</Avatar>
									<Tooltip title={t('requested1on1withyou')} arrow placement='bottom-start'>
										<span className='request-arrow'>
											<BackArrow />
										</span>
									</Tooltip>
								</Box>
								<Box className='user-info-details'>
									<Typography variant='h4'>{'Esther Howard'}</Typography>
									<Typography variant='subtitle2'>{'test@gmail.com'}</Typography>
								</Box>
							</Box>
						</ListItem>
						<ListItem className='meeting-col2'>
							<Typography>{'This growth plan will help you r...'}</Typography>
						</ListItem>
						<ListItem className='meeting-col3'>
							<Box className='date-txt'>
								<Typography>{'Dec 22, 2022'}</Typography>
							</Box>
						</ListItem>
						<ListItem className='meeting-col4'>
							<Box className='date-txt'>
								<Typography>{'Dec 22, 2022'}</Typography>
							</Box>
							<Box className='user-actions'>
								<OKRButton
									className='btn-link'
									text={'Edit'}
									icon={<EditTemplateIcon />}
									//title={t('editUserDetailsText')}
									id={`edit-user-icon-`}
								/>
								<PopperMenu
									className={'user-menu-btn'}
									anchorEl={anchorEl}
									handleClose={handleClose}
									includeKebabMenu={true}
									menuClicked={popperMenuClicked}
									setPopperMenuClicked={setPopperMenuClicked}
									btnId={`more-btn-`}
									{...props}
									children={
										<>
											<ListItem id={`delete-user-`}>
												<ListItemAvatar>
													<DeleteIcon />
												</ListItemAvatar>
												<ListItemText primary={t('delete')} />
											</ListItem>
										</>
									}
								></PopperMenu>
							</Box>
						</ListItem>
					</List>
				</Box> */}
			</Box>
		</Box>
	) : (
		<></>
	);
};
