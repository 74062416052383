import React, { Fragment } from 'react';
import { QuestionList } from './QuestionList';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { RatingFormDetailRequest } from '.';

export const QuestionListWrapper: React.FC<any> = (props: any) => {
	const {
		onDragEnd,
		getItemStyle,
		section,
		sectionIndex,
		questionTypeMasterData,
		handleFormSectionQuestionNameChange,
		handleFormSectionQuestionScaleTypeChange,
		getScaleListByType,
		handleScaleNameChange,
		handleDeleteQuestion,
		handleIsQuestionMandatory,
		handleIsCommentBoxAvailable,
		handleIsCommentBoxMandatory,
		handleManageScale,
	} = props;

	return (
		<>
			{section?.ratingFormDetailRequest && section?.ratingFormDetailRequest?.length ? (
				<Fragment>
					<DragDropContext
						onDragEnd={(result: any) => onDragEnd(result, section?.ratingFormDetailRequest, sectionIndex)}
					>
						<Droppable droppableId='list'>
							{(provided: any) => (
								<div ref={provided.innerRef} className='' {...provided.droppableProps}>
									{section?.ratingFormDetailRequest && section?.ratingFormDetailRequest?.length ? (
										section?.ratingFormDetailRequest.map((question: RatingFormDetailRequest, questionIndex: number) => (
											<Draggable
												key={`draggable_${question.sequence}_index_${questionIndex}_section_${sectionIndex}`}
												draggableId={`draggable_${question.sequence}_questionIndex_${questionIndex}`}
												index={questionIndex}
												isDragDisabled={false}
											>
												{(provided: any, snapshot: any) => (
													<Fragment>
														<QuestionList
															provided={provided}
															snapshot={snapshot}
															getItemStyle={getItemStyle}
															section={section}
															question={question}
															sequence={question.sequence}
															questionIndex={questionIndex}
															sectionIndex={sectionIndex}
															callingFrom={'AssessmentForm'}
															customScaleType={'Add'}
															questionTypeMasterData={questionTypeMasterData}
															handleFormSectionQuestionNameChange={handleFormSectionQuestionNameChange}
															handleFormSectionQuestionScaleTypeChange={handleFormSectionQuestionScaleTypeChange}
															getScaleListByType={getScaleListByType}
															handleScaleNameChange={handleScaleNameChange}
															handleDeleteQuestion={handleDeleteQuestion}
															handleIsQuestionMandatory={handleIsQuestionMandatory}
															handleIsCommentBoxAvailable={handleIsCommentBoxAvailable}
															handleIsCommentBoxMandatory={handleIsCommentBoxMandatory}
															handleManageScale={handleManageScale}
														/>
													</Fragment>
												)}
											</Draggable>
										))
									) : (
										<></>
									)}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</Fragment>
			) : (
				<></>
			)}
		</>
	);
};
