import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import '../../styles/pages/error-pages/errors.scss';
import { getLocalStorageItem } from '../../services/StorageService';
import { useTranslation } from 'react-i18next';

export default function Users(props) {
	const loginUser = JSON.parse(getLocalStorageItem('userDetail'));
	const { t } = useTranslation();
	return (
		<Fragment>
			<div className='not-found-area'>
				<div className='inner-area-wrapper'>
					<div className='inner'>
						<Typography variant='h1'>
							{/* {loginUser.firstName} */}
							{t('forbiddenPrimaryMsg')}
						</Typography>

						<Typography>{t('forbiddenSecondaryMsg')}</Typography>

						{/* <div className='btn-group'>
							<Button onClick={() => (window.location = "http://unlockokr.com/")} className='btn-primary'>Back to Home</Button>

							<Link>Give us Feedback</Link>
						</div> */}
					</div>
				</div>
			</div>
		</Fragment>
	);
}
