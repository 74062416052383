import React, { useEffect, useState } from 'react';
import { Box, Typography, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
	AlertInfoIcon,
	CompletedDateIcon,
	CreatedDateIcon,
	DueDateIcon,
	LinkIcon,
} from '../../../config/svg/CommonSvg';

import { OKRButton } from '../../Common/OKRButton';
import { getMonthDateYearTime, getPriorityClass, getPriorityIcon, getPriorityName } from '../../../config/utils';

export const MomDrawerQuickLinks = (props: any) => {
	const { item } = props;
	const { t } = useTranslation();

	return (
		<Box className='quick-links-btn'>
			<Box className='links-col priority-links'>
				<OKRButton
					className={`blue-btn ${getPriorityClass(item?.priorityId)}`}
					icon={getPriorityIcon(item?.priorityId)}
					title={`${getPriorityName(t, item?.priorityId)}`}
				/>
			</Box>
			<Box className='links-col created-on-links'>
				<OKRButton
					className='created-btn btn-link-type1'
					icon={<CreatedDateIcon />}
					text={item?.createdOn ? getMonthDateYearTime(item?.createdOn) : ''}
					title={t('createOnLabel')}
				/>
			</Box>
			{item?.dueDateText ? (
				<Box className='links-col due-on-links '>
					<OKRButton
						className='created-btn btn-link-type1'
						text={
							<>
								{item?.dueDateText}
								{item?.isOverDue && (
									<Typography className={`alert-due-on`}>
										<AlertInfoIcon />
									</Typography>
								)}
							</>
						}
						icon={<DueDateIcon />}
						title={
							item?.isOverDue ? (
								<>
									Overdue <br /> ({item?.overDueText})
								</>
							) : (
								t('dueOn')
							)
						}
						tooltipClassName={item?.isOverDue ? 'tooltip-alert' : ''}
					/>
				</Box>
			) : (
				<></>
			)}
			{item?.completeDate ? (
				<Box className='links-col created-on-links'>
					<OKRButton
						className='created-btn btn-link-type1'
						icon={<CompletedDateIcon />}
						text={item?.completeDate ? getMonthDateYearTime(item?.completeDate) : ''}
						title={t('completedDate')}
					/>
				</Box>
			) : (
				<></>
			)}

			{item?.goalLinkDetails?.keyDescription ? (
				<Box className='links-col kr-linking'>
					<Tooltip
						arrow
						classes={{ popper: 'tooltip-layout12' }}
						title={
							<Box className='tooltip-text'>
								{item?.goalLinkDetails?.toolTipText || 'Your Key Result'} <br />
								{`"${item?.goalLinkDetails?.keyDescription || ''}"`}
							</Box>
						}
					>
						<Box className={`kr-linking-name `}>
							<LinkIcon />
							<Typography variant='h6'>{item?.goalLinkDetails?.keyDescription || ''}</Typography>
						</Box>
					</Tooltip>
				</Box>
			) : (
				<></>
			)}
		</Box>
	);
};
