import { Checkbox, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import { DrawerTransitionSetting } from '../../../config/utils';
import CloneImg from '../../../images/clone-img.svg';
import '../../../styles/pages/admin/cloneDialog.scss';
import { AddStatusIcon, BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';

interface CloneRoleComponentProps {
	handleCloseModal: (event: any) => void;
	handleCloneSubmit: (event: any, cloneObj: any, modalOpen: any) => void;
	modalOpen: any;
}

const CloneRoleComponent: React.FC<CloneRoleComponentProps> = (props) => {
	const { modalOpen, handleCloseModal, handleCloneSubmit } = props;
	const { t } = useTranslation();
	const [cloneObj, setCloneObj] = React.useState<any>({
		isRoleName: true,
		isRoleDescription: true,
		isPermission: true,
		isActive: true,
	});

	const handleCloneForm = (e: any) => {
		const { checked, name } = e.target;
		setCloneObj({
			...cloneObj,
			[name]: checked,
		});
	};

	/**
	 * Reset form
	 */
	React.useEffect(() => {
		if (modalOpen.open) {
			setCloneObj({
				isRoleName: true,
				isRoleDescription: true,
				isPermission: true,
				isActive: true,
			});
		}
	}, [modalOpen]);

	return (
		<Dialog
			className='clone-role-dialog'
			open={modalOpen.open}
			transitionDuration={DrawerTransitionSetting}
			onClose={handleCloseModal}
		>
			<DialogContent>
				<Typography variant='h2'>{t('whatShouldBeCloned')}</Typography>
				<Box className='clone-dialog-inner'>
					<Box className='clone-checkbox'>
						<FormControlLabel
							control={
								<Checkbox
									name='isRoleName'
									checked={cloneObj.isRoleName}
									onChange={handleCloneForm}
									icon={<BorderCheckboxIcon />}
									checkedIcon={<CheckedIcon />}
									id={'is-role-name'}
								/>
							}
							label={t('roleNameLabel')}
						/>

						<FormControlLabel
							control={
								<Checkbox
									name='isRoleDescription'
									checked={cloneObj.isRoleDescription}
									onChange={handleCloneForm}
									icon={<BorderCheckboxIcon />}
									checkedIcon={<CheckedIcon />}
									id={'is-role-des'}
								/>
							}
							label={t('roleDescriptionLabel')}
						/>
						<FormControlLabel
							control={
								<Checkbox
									name='isPermission'
									checked={cloneObj.isPermission}
									onChange={handleCloneForm}
									icon={<BorderCheckboxIcon />}
									checkedIcon={<CheckedIcon />}
									id={'is-permission'}
								/>
							}
							label={t('permissionsText')}
						/>
					</Box>
					<Box className='clone-image'>
						<img src={CloneImg} alt={t('clone')} />
					</Box>
				</Box>
			</DialogContent>

			<DialogActions>
				<Box className='role-status-field'>
					<FormControlLabel
						control={
							<Switch
								id='roleStatus'
								name='isActive'
								checked={cloneObj.isActive}
								onChange={handleCloneForm}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label={t('statusLabel')}
						labelPlacement='end'
						className={`toggle-label`}
					/>
				</Box>
				<Box className='action-btn'>
					<Button className='btn-link' onClick={handleCloseModal} id='cancel-btn'>
						{t('cancelBtn')}
					</Button>
					<Button id='clone-btn' className='btn-primary' onClick={(e) => handleCloneSubmit(e, cloneObj, modalOpen)}>
						{t('clone')}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default CloneRoleComponent;
