import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Tab,
	Tabs,
	Badge,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PopperMenu } from '../Admin/CommonComponent/PopperMenu';
import { BottomArrowIcon, OrgIcon } from '../../config/svg/CommonSvgIcon';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { OKRButton } from '../Common/OKRButton';

export const AlignmentMapTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`alignment-tabpanel-${index}`}
			aria-labelledby={`alignment-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

AlignmentMapTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `alignment-inner-tab-${index}`,
		'aria-controls': `alignment-inner-tabpanel-${index}`,
	};
}

export const AlignmentMapTabs: React.FC<any> = (props) => {
	const { tabValue, handleTabChange, organisations, loadTeamData } = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(true);
	};
	const handleClose = (teamId = 0) => {
		setAnchorEl(null);
		setOpen(false);
	};
	return (
		<>
			<Tabs className='okr-tabs okr-tabs-space' value={tabValue} onChange={handleTabChange} aria-label='OKR Tabs'>
				<Tab className={`not-filled-icon`} label={t('myOkrsLabel')} {...a11yProps(0)} />
				{/* <Tab
					//icon={<TeamIcon />}
					label={
						<>
							{t('TeamOkrsLabel')}
							<span className='arrowIcon' onClick={handleClick}>
								<BottomArrowIcon />
							</span>
						</>
					}
					{...a11yProps(1)}
				/> */}
				<OKRButton
					handleClick={handleClick}
					className={`tabs-team-btn ${tabValue === 1 ? 'active' : ''}`}
					text={
						<>
							{t('TeamOkrsLabel')}
							<span className='arrowIcon'>
								<BottomArrowIcon />
							</span>
						</>
					}
				/>
			</Tabs>

			<PopperMenu
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				connectorIcon={false}
				popperClassName={'popper-menu popper-menu-type1 popper-team-menu tabs-dropdown-type1'}
				popperPlacement={'bottom-start'}
				{...props}
				children={
					<>
						{/* <ListItem id='all-teams'>
							<ListItemAvatar>
								<Avatar className='all-team team-avatar-small'>
									<OrgIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={'All Teams'} />
						</ListItem> */}
						{organisations.map((item: any) => {
							return (
								<ListItem
									key={''}
									id={`leading-teams-`}
									onClick={() => {
										loadTeamData(item);
										handleTabChange({}, 1);
										handleClose();
									}}
								>
									<ListItemAvatar>
										{item.teamLogo ? (
											<Avatar src={item.teamLogo} className='team-avatar-small' />
										) : (
											<Avatar
												style={{
													backgroundColor: getRandomBackgroundColor(item.backGroundColorCode),
													color: getRandomColor(item.colorCode),
												}}
												className='team-avatar-small'
											>
												{getUserName({
													fullName: item.teamName,
													firstName: '',
													lastName: '',
												})}
											</Avatar>
										)}
									</ListItemAvatar>
									{item.teamName && item.teamName.length > 25 ? (
										<Tooltip arrow title={item?.teamName} aria-label='Teams'>
											<Typography>{item?.teamName.substring(0, 25)}...</Typography>
										</Tooltip>
									) : (
										<Typography>{item?.teamName || ''}</Typography>
									)}
								</ListItem>
							);
						})}
					</>
				}
			></PopperMenu>
		</>
	);
};
