/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Avatar, Typography, ClickAwayListener, Button, Box, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import noRecordFound from '../../images/search-placeholder.svg';
import { ALIGNMENT_MAP, USER_VIEW, TEAM_VIEW } from '../../config/app-url';
import '../../styles/components/globalSearch.scss';
import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { getRandomBackgroundColor, getRandomColor } from '../../config/utils';
import { useTranslation } from 'react-i18next';
import { AnimatedIcon } from './AnimatedIcon';
import _ from 'lodash';

const GlobalSearch = (props) => {
	const {
		module,
		defaultValue,
		getUserById,
		defaultFocus,
		placeHolderText,
		customClass,
		fetchCycleUsers,
		updateValue,
		setUpdateValue,
		customId,
		isOwnerSearch = false,
	} = props;
	const { t } = useTranslation();
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const history = useHistory();
	const ref = React.createRef();
	const [defaultId, setDefaultId] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchTyped, setSearchTyped] = useState(true);
	const [searchClass, setSearchClass] = useState(false);
	const [searchResult, setSearchResult] = useState([]);
	const [initial, setInitial] = useState({
		isLoading: false,
		options: [],
		query: '',
		page: 1,
		selected: [],
		totalCount: 0,
	});

	useEffect(() => {
		if (defaultValue && (!defaultId || updateValue === true)) {
			getUserById(`empId=${defaultValue}`).then((resp) => {
				if (resp && resp.status === 200) {
					const { data } = resp;
					const { entityList } = data;
					setInitial({
						isLoading: false,
						options: [{ ...entityList }],
						query: '',
						page: 1,
						selected: [{ ...entityList }],
						totalCount: 1,
					});
				}
			});
			setUpdateValue && setUpdateValue(false);
		} else if (!defaultValue) {
			// to clear Global saerch component when default value is 0/null/blank
			ref.current.clear();
		}
	}, [defaultValue]);

	useEffect(() => {
		if (defaultFocus) {
			ref.current.focus();
		}
	}, [defaultFocus]);

	useEffect(() => {
		if (ref.current && ref.current.getInput().value !== '') {
			makeAndHandleRequest(ref.current.getInput().value, props.globalSearchPage).then((resp) => {
				const options = initial.options.concat(resp.options);
				setInitial({
					...initial,
					isLoading: false,
					options,
					totalCount: resp.totalCount,
				});
			});
		}
	}, [props.globalSearchPage]);

	const makeAndHandleRequest = async (query, page = 1) => {
		setDefaultId(true);
		setLoading(true);
		setInitial({ ...initial, isLoading: true });
		if (!query) {
			query = ref.current.getInput().value;
		}
		let resp;
		if (fetchCycleUsers === true && (props.module === 'profile' || props.module === 'myGoal')) {
			resp = await props.globalSearchAPIWithTeam(query, page, 99999, 1);
		} else if (fetchCycleUsers === true && props.module !== 'profile') {
			resp = await props.globalSearchAPIWithTeam(query, page, 99999, 0);
		} else {
			resp = await props.searchEmployee(query, page, 99999);
		}
		const { entityList, paggingInfo } = resp?.data || {};
		let list = entityList;
		let loggedInUserDetail = JSON.parse(getLocalStorageItem('userDetail'));
		if (
			props.module === 'feedback' ||
			props.module === 'myGoal' ||
			props.module === 'global' ||
			props.module === 'profile' ||
			props.module === 'peopleSearch'
		) {
			if (!isOwnerSearch) {
				list = entityList && entityList.filter((item) => item.employeeId !== loggedInUserDetail.employeeId);
			}
			//removing selected employees from the list
			if (list && list.length > 0 && props.formData) {
				list = list.filter(
					(item) => !props.formData.selectedUser.find(({ employeeId }) => item.employeeId === employeeId)
				);
			}
		}
		setLoading(false);
		setSearchTyped(false);
		if (list) {
			const options = list;
			setSearchResult(options);
			return { options, totalCount: paggingInfo?.totalRecords || 0 };
		} else {
			setSearchResult([]);
			return { options: [], totalCount: 0 };
		}
	};

	const getUserName = (data) => {
		if (data.searchType === 2) {
			const orgName = data.organisationName.split(' ');
			return `${orgName[0].charAt(0).toUpperCase()}${orgName[1] ? orgName[1].charAt(0).toUpperCase() : ''}`;
		} else {
			const { firstName, lastName } = data;
			if (firstName && lastName) {
				return `${firstName.charAt(0)}${lastName.charAt(0)}`;
			}
			return firstName ? firstName.slice(2) : '';
		}
	};

	const getTeamNameInitials = ({ fullName }) => {
		if (fullName) {
			const name = fullName.trim().replace(/\s\s+/g, ' ').split(' ');
			return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
		}
	};

	const NoRecordFound = ({ text }) => {
		return (
			<div className='search-no-record'>
				<div className='no-record-message'>
					<Typography variant='h2' component='h2'>
						{t('CommonOOPS')}
					</Typography>
					<Typography>{t('globalSearchNoRecord')}</Typography>

					<Typography>{text}</Typography>
					<AnimatedIcon
						className=''
						width='250'
						type='image/svg+xml'
						alt={t('globalSearchNoRecord')}
						imagePath={noRecordFound}
					/>
				</div>
			</div>
		);
	};

	const _handlePagination = (e, shownResults) => {
		e.preventDefault();
		e.stopPropagation();
		if (initial.options.length > shownResults || initial.options.length === initial.totalCount) {
			return;
		} else {
			props.updatePage(props.globalSearchPage + 1);
			const page = initial.page + 1;
			setInitial({ ...initial, isLoading: true, page });
		}
	};

	const _handleSearch = async (query) => {
		setSearchClass(true);
		const resp = await makeAndHandleRequest(query);
		setInitial({
			...initial,
			isLoading: false,
			options: resp.options,
			totalCount: resp.totalCount,
		});
	};

	const _handleInputChange = (input) => {
		setSearchTyped(true);
		if (input === '') {
			ref.current.clear();
			setInitial({
				isLoading: false,
				options: [],
				query: null,
				page: 1,
				selected: [],
			});
			//	setDefaultId(0);
		}
		if (props.globalSearchPage > 1) {
			props.updatePage(1);
		}
	};

	const handleInputThrottled = (value) => {
		setSearchTyped(true);
		setSearchClass(true);
		delayedQuery(value);
		if (props?.addKey?.type === 'map') {
			props.handleGlobalSearchClick(false);
		}
	};
	const delayedQuery = useCallback(
		_.debounce((q) => _handleSearch(q), 500),
		[]
	);

	const _handleOnChange = (selected) => {
		props.updatePage(1);
		if (
			props.module === 'feedback' ||
			props.module === 'myGoal' ||
			props.module === 'profile' ||
			props.module === 'peopleSearch'
		) {
			if (isOwnerSearch || !selected.find((item) => item.employeeId === userDetail.employeeId)) {
				props.onSelectedUser(selected);
				setSearchClass(false);
			}
			setInitial({ ...initial, selected });
			if (props.module === 'myGoal') {
				try {
					ref.current && ref.current.blur();
				} catch (err) {}
				ref.current && ref.current.clear();
				setInitial({
					isLoading: false,
					options: [],
					query: null,
					page: 1,
					selected: [],
				});
			}
		} else if (props.module === 'alignObjective' || props.module === 'organization' || props.module === 'addUser') {
			props.onSelectedUser(selected);
			setInitial({ ...initial, selected });
			setSearchClass(false);
		} else if (props.module === 'global') {
			props.resetOkr();
			props.updatePrevRoute(props.currentRoute);
			if (searchResult[0].searchType === 2) {
				setLocalStorageItem('currentOrganisation', JSON.stringify(searchResult[0]));
				props.history.push(TEAM_VIEW);
				props.updateRoute(TEAM_VIEW);
			} else {
				setLocalStorageItem('currentUser', JSON.stringify(searchResult[0]));
				history.push(USER_VIEW);
				props.updateRoute(USER_VIEW);
			}
			ref.current && ref.current.clear();
		} else {
			try {
				ref.current && ref.current.clear();
				setInitial({
					isLoading: false,
					options: [],
					query: null,
					page: 1,
					selected: [],
				});
			} catch (err) {}
		}
	};

	const handleClickAway = (e) => {
		setSearchClass(false);
		try {
			if (
				(props.module !== 'feedback' && props.module !== 'organization' && props.module !== 'addUser') ||
				props.module === 'myGoal' ||
				initial.selected.length === 0
			) {
				ref.current.blur();
				ref.current.clear();
				setInitial({
					isLoading: false,
					options: [],
					query: null,
					page: 1,
					selected: [],
				});
			}
		} catch (err) {}
	};

	const changeRoute = (e, type, option) => {
		if (module !== 'global') {
			return false;
		}
		e.preventDefault();
		e.stopPropagation();
		setLocalStorageItem('currentUser', JSON.stringify(option));

		const backUrl = getLocalStorageItem('globalBackUrl') || getLocalStorageItem('backUrl');
		if (backUrl) {
			setLocalStorageItem('backUrl', backUrl);
			removeLocalStorageItem('globalBackUrl');
			removeLocalStorageItem('backUrlAlignmentMaps');
		}

		try {
			ref.current.blur();
			setInitial({
				isLoading: false,
				options: [],
				query: null,
				page: 1,
				selected: [],
			});
		} catch (err) {}
		if (type === 1) {
			props.resetAlignment();
			props.resetOkr();
			props.updatePrevRoute(props.currentRoute);
			history.push(ALIGNMENT_MAP);
			props.updateRoute(ALIGNMENT_MAP);
			ref.current.clear();
		} else if (type === 2) {
			props.resetOkr();
			props.updatePrevRoute(props.currentRoute);
			if (option.searchType === 2) {
				setLocalStorageItem('currentOrganisation', JSON.stringify(option));
				props.history.push(TEAM_VIEW);
				props.updateRoute(TEAM_VIEW);
			} else {
				setLocalStorageItem('currentUser', JSON.stringify(option));
				history.push(USER_VIEW);
				props.updateRoute(USER_VIEW);
			}
			ref.current.clear();
		}
	};

	const handleEnterClick = (e) => {
		if (e.keyCode === 13 && searchResult.length === 1) {
			if (module === 'global') {
				// Execute the search on enter click.
				props.resetOkr();
				props.updatePrevRoute(props.currentRoute);
				if (searchResult[0].searchType === 2) {
					setLocalStorageItem('currentOrganisation', JSON.stringify(searchResult[0]));
					props.history.push(TEAM_VIEW);
					props.updateRoute(TEAM_VIEW);
				} else {
					setLocalStorageItem('currentUser', JSON.stringify(searchResult[0]));
					history.push(USER_VIEW);
					props.updateRoute(USER_VIEW);
				}
				ref.current.clear();
			} else if (module === 'myGoal') {
				props.onSelectedUser(searchResult);
				ref.current.clear();
			}
		}
	};

	const getLabelKey = (option) => {
		return option.searchType === 2 ? `${option.organisationName}` : `${option.firstName} ${option.lastName}`;
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Box className={searchClass ? 'search-menu-active' : ''}>
				<AsyncTypeahead
					{...initial}
					className={Boolean(customClass) ? customClass : ''}
					id='async-pagination'
					multiple={props.multiple}
					paginate
					ref={ref}
					delay={100}
					minLength={3}
					maxResults={5}
					autoFocus={module === 'profile' || module === 'peopleSearch' ? true : false}
					isLoading={loading}
					onPaginate={_handlePagination}
					onChange={_handleOnChange}
					onSearch={handleInputThrottled}
					onInputChange={_handleInputChange}
					emptyLabel={!searchTyped ? <NoRecordFound text={''} /> : t('apiLoading')}
					placeholder={placeHolderText ? placeHolderText : t('searchText')}
					selected={initial.selected}
					//labelKey={(option) => `${option.firstName} ${option.lastName}`}
					labelKey={(option) => getLabelKey(option)}
					inputProps={{
						id: customId ? customId : 'src-input-field',
						'aria-label': 'search',
						onClick: () => {
							if (props?.addKey?.type === 'map') {
								props.handleGlobalSearchClick(true);
							}
						},
					}}
					renderMenuItemChildren={(option, props) => {
						if (option.length === 0) {
							return null;
						}
						return (
							<Fragment>
								<Box className='user-info' onClick={(e) => changeRoute(e, 2, option)}>
									<Box className='user-img' id={`src-user-img-${option.employeeId}`}>
										{option.imagePath ? (
											<Avatar alt={`${option.firstName} ${option.lastName}`} src={option.imagePath} />
										) : (
											<Avatar
												style={{
													backgroundColor: getRandomBackgroundColor(option.backGroundColorCode),
													color: getRandomColor(option.colorCode),
												}}
											>
												{getUserName(option)}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Box className='user-info-label'>
											<Typography variant='h4' key={option.employeeId} id={`src-user-name-${option.employeeId}`}>
												<Highlighter search={props.text}>{getLabelKey(option)}</Highlighter>
											</Typography>
											<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
												{option.searchType === 2 ? (
													option.firstName ? (
														<span>
															{t('leaderLabelText')}&nbsp;-&nbsp;
															<span
																className='organization-leader-name'
																// onClick={leaderClicked}
															>
																{option.firstName} {option.lastName}
															</span>
														</span>
													) : (
														''
													)
												) : (
													''
													//`${option.emailId}`
													// `${option.designation}`
												)}
											</Typography>
											{module === 'global' ? (
												<Box className='des-with-team'>
													{option.emailId && option.emailId.length > 35 ? (
														<Tooltip arrow title={option.emailId}>
															<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
																{option.emailId.substring(0, 35)}...
															</Typography>
														</Tooltip>
													) : (
														<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
															{option.emailId}
														</Typography>
													)}
												</Box>
											) : module === 'myGoal' || module === 'feedback' ? (
												<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
													{option.emailId}
												</Typography>
											) : (
												<Box className='des-with-team'>
													{option.emailId && option.emailId.length > 25 ? (
														<Tooltip arrow title={option.emailId}>
															<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
																{option.emailId.substring(0, 25)}...
															</Typography>
														</Tooltip>
													) : (
														<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
															{option.emailId}
														</Typography>
													)}
												</Box>
											)}
											{/* ) : module === 'peopleSearch' ? (
												<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
													{option.emailId}
												</Typography>
											) : (
												<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
													{option.designation}
												</Typography>
											)} */}
										</Box>

										{module === 'global' && (
											<Box className='search-action'>
												<Button
													className='submitBtn btn-secondary'
													color='primary'
													onClick={(e) => changeRoute(e, 2, option)}
													id={`src-action-btn-${option.employeeId}`}
												>
													{t('okrsLabel')}
												</Button>
											</Box>
										)}
									</Box>
								</Box>
							</Fragment>
						);
					}}
					useCache={false}
					onKeyDown={handleEnterClick}
				/>
			</Box>
		</ClickAwayListener>
	);
};

export default GlobalSearch;
