import React, { useState } from 'react';
import {
	Box,
	TextField,
	Typography,
	List,
	ListItem,
	FormControlLabel,
	Switch,
	InputAdornment,
	Tooltip,
	Chip,
	ListItemText,
	ClickAwayListener,
	Popper,
	Grow,
	Paper,
	Avatar,
	Collapse,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../../../styles/pages/admin/settings/default-okr/createDefaultOkrCard.scss';
import { OKRButton } from '../../../../../Common/OKRButton';
import { Enums } from '../../../../../../config/enums';
import { DeleteIcon, SearchIcon } from '../../../../../../config/svg/CommonSvg';
import { CalendarIcon, CrossIcon, PublishedOnIcon, SortingIcon } from '../../../../../../config/svg/CommonSvgIcon';
import { SettingsIcon } from '../../../../../../config/svg/MyGoalSvg';
import { getCurrencyIcon, getCurrencyName, getMonthDate, getUserName } from '../../../../../../config/utils';
import { ErrorIcon } from '../../../../../../config/svg/formElementIcons';
import { RightArrow } from '../../../../../../config/svg/ArrowSvg';
import { useSelector } from 'react-redux';

export const CreateDefaultOkrDetails = (props: any) => {
	const {
		defaultOkrFormDetails,
		handleObjectiveNameChange,
		handleKrNameChange,
		handleKrDrawerOpen,
		handleOkrDeletionClick,
		handleKRDeletionClick,
		selectedCycleDetails,
		handleDraftDefaultOkrClick,
		handleSaveAndPublishLaterDefaultOkrClick,
		handlePublishDefaultOkrClick,
		okrNameError,
		showLoader,
		handleIsWeightSameToggleClick,
		handleIsTargetSameToggleClick,
		handleKrStartTargetValueChange,
		handleKrRoleStartTargetValueChange,
		handleWeightUpdate,
		handleKrRoleSearch,
		handleKrRoleSort,
		handleKrExpandCollapse,
		weightSelectionOpen,
		setWeightSelectionOpen,
	} = props;
	const { t } = useTranslation();
	const { okrMasterData } = useSelector((state: any) => state.okrReducer);

	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	// const [weightSelectionOpen, setWeightSelectionOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [selectedWeightDetails, setSelectedWeightDetails] = useState<any>({
		details: null,
		type: '',
	});
	// const [collapse, setCollapse] = useState<boolean>(false);

	const handleCollapse = (e: any, krItem: any, krIndex: number) => {
		// setCollapse((prev) => !prev);
		handleKrExpandCollapse(krItem, krIndex);
	};
	const handleWeightClick = (e: any, krItem: any, krIndex: number) => {
		setAnchorEl(e.currentTarget);
		setWeightSelectionOpen(true);
		setSelectedWeightDetails({
			details: {
				krItem,
				krIndex,
			},
			type: 'KrWeight',
		});
	};
	const handleWeightClose = () => {
		setWeightSelectionOpen(false);
		setAnchorEl(null);
	};
	const handleRoleWeightClick = (e: any, krItem: any, krIndex: number, role: any, roleIndex: number) => {
		setAnchorEl(e.currentTarget);
		setWeightSelectionOpen(true);
		setSelectedWeightDetails({
			details: {
				krItem,
				krIndex,
				role,
				roleIndex,
			},
			type: 'KrRoleWeight',
		});
	};
	const handleWeightSelection = (e: any, weight: number) => {
		// console.log(weight, selectedWeightDetails);
		handleWeightUpdate(selectedWeightDetails, weight);
		handleWeightClose();
	};
	const getWeightName = (details: any) => {
		const roleName = details?.weight ? details?.weight + 'x' : '1x';

		return roleName || '';
	};

	const handleSearchClicked = (e: any, krItem: any, krIndex: number) => {
		const { value } = e?.target || {};
		handleKrRoleSearch(value, 'insert', krItem, krIndex);
	};
	const searchClicked = (e: any, krItem: any, krIndex: number) => {
		if (e.key === 'Enter') {
			const { value } = e?.target || {};
			if (value && value.trim() !== '') {
				handleKrRoleSearch(value, 'search', krItem, krIndex);
			} else {
				handleKrRoleSearch('', 'search', krItem, krIndex);
			}
		}
	};
	const clearSearch = (e: any, krItem: any, krIndex: number) => {
		handleKrRoleSearch('', 'clear', krItem, krIndex);
	};

	const sortUserList = (e: any, column: any, roleList: any, krItem: any, krIndex: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		if (column === 'targetValue') {
			roleList = roleList?.map((role: any) => {
				role.startTargetDiff = role.targetValue - role.startValue;
				return role;
			});
		}
		let updatedRoleList = sortArrayByKey(roleList, column === 'targetValue' ? 'startTargetDiff' : column, newSortOrder);
		if (column === 'targetValue') {
			updatedRoleList = updatedRoleList?.map((role: any) => {
				delete role.startTargetDiff;
				return role;
			});
		}
		updatedRoleList = dataPerPage(updatedRoleList, 1, Enums.MAX_USER_SIZE);
		setSortingColumn(column);
		// console.log(updatedRoleList);
		handleKrRoleSort(column, updatedRoleList, krItem, krIndex);
		// setLocalRoleList(updatedRoleList);
		// resetCheckState();
	};
	const sortArrayByKey = (arrayList: any, keyName: string, sortingType: string) => {
		arrayList.sort(function (a: any, b: any) {
			const keyA = a[keyName];
			const keyB = b[keyName];
			if (sortingType === 'asc') {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			} else if (sortingType === 'desc') {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			}
			return 0;
		});

		return arrayList;
	};
	const dataPerPage = (arrayList: any, currPage: any, pageSizeValue: any) => {
		const startIndex = currPage - 1;
		const updatedList = arrayList.slice(startIndex * pageSizeValue, pageSizeValue * currPage);

		return updatedList;
	};
	const getQuarterDetails = () => {
		const { symbol, year } = selectedCycleDetails || {};

		return symbol && year ? `(${symbol}, ${year})` : '';
	};
	const checkKrDIsable = () => {
		return !defaultOkrFormDetails?.name || defaultOkrFormDetails?.name?.trim() === '' ? true : false;
	};
	const getFilteredList = (fullRoleList: any, searchText: string) => {
		if (searchText) {
			return fullRoleList?.filter((rr: any) => rr?.name?.toLowerCase().includes(searchText?.toLowerCase()));
		} else {
			return fullRoleList;
		}
	};
	// console.log('CreateDefaultOkrDetails ==> ', defaultOkrFormDetails);

	return (
		<>
			{/* {defaultOkrFormDetails?.assignType === Enums.ZERO ? (
				<> Let's add an objective</>
			) : ( */}
			<Box className='create-default-okr-card'>
				<Box className='create-default-okr-card-inner'>
					<Box className='create-default-obj'>
						<TextField
							className='add-objective helperText-bottom'
							id='create-default-obj-field'
							multiline
							autoFocus={true}
							maxRows={2}
							placeholder={t('AddAnobjective')}
							InputProps={{ 'aria-label': t('addDefaultObj'), disableUnderline: true }}
							name={'name'}
							value={defaultOkrFormDetails?.name || ''}
							onChange={handleObjectiveNameChange}
							error={okrNameError?.error}
							helperText={
								okrNameError?.error && okrNameError?.errorText ? (
									<>
										<ErrorIcon />
										{okrNameError?.errorText}
									</>
								) : (
									''
								)
							}
						/>
						{defaultOkrFormDetails?.name && defaultOkrFormDetails?.name?.trim() !== '' ? (
							<Box className='default-okr-field-actions'>
								<OKRButton
									className='delete-btn'
									id='objective-delete-btn'
									icon={<DeleteIcon />}
									text={t('delete')}
									title={t('delete')}
									handleClick={(e: any) => handleOkrDeletionClick(e, defaultOkrFormDetails)}
								/>
							</Box>
						) : (
							<></>
						)}
					</Box>
					{defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys.length ? (
						defaultOkrFormDetails?.defaultGoalKeys.map((krItem: any, krIndex: number) => {
							return (
								<Box className='create-default-kr'>
									<Box className='create-default-kr-inner'>
										<Box className='create-default-kr-head'>
											{krItem?.name && krItem?.name?.trim() !== '' && krItem?.metricType ? (
												<OKRButton
													className={`kr-expand-collapse-btn ${krItem?.isExpand ? 'active' : ''}`}
													title={`${krItem?.isExpand ? t('collapseLabel') : t('expendLabel')}`}
													icon={<RightArrow />}
													handleClick={(e) => handleCollapse(e, krItem, krIndex)}
												/>
											) : (
												<></>
											)}
											<TextField
												className='add-kr helperText-bottom'
												id={`create_default_kr_field_index_${krIndex}`}
												multiline
												maxRows={2}
												placeholder={t('addKeyRes')}
												InputProps={{ 'aria-label': t('addDefaultKr'), disableUnderline: true }}
												disabled={checkKrDIsable()}
												value={krItem?.name || ''}
												onChange={(event) => handleKrNameChange(event, krItem, krIndex)}
												error={krItem?.isKrNameError}
												helperText={
													krItem?.isKrNameError && krItem?.krNameErrorMsg ? (
														<>
															<ErrorIcon />
															{krItem?.krNameErrorMsg}
														</>
													) : (
														''
													)
												}
											/>
											{krItem?.name && krItem?.name?.trim() !== '' ? (
												<Box className='default-okr-field-actions'>
													{krItem?.publishedOn ? (
														<Box className='set-timeline-area'>
															<Box className='timeline-field'>
																<Box className='calendar-btn'>
																	<OKRButton
																		title={t('publishedOn')}
																		text={
																			krItem?.publishedOn
																				? `${getMonthDate(krItem?.publishedOn)} ${getQuarterDetails()}`
																				: ''
																		}
																		icon={<PublishedOnIcon />}
																		buttonType={'disableButtonTooltip'}
																		disabled={true}
																	/>
																</Box>
															</Box>
														</Box>
													) : (
														<></>
													)}
													<Box className='set-timeline-area'>
														<Box className='timeline-field'>
															<Box className='calendar-btn'>
																<OKRButton
																	title={t('blankSelection')}
																	text={
																		krItem?.endDate ? `${getMonthDate(krItem?.endDate)} ${getQuarterDetails()}` : ''
																	}
																	icon={<CalendarIcon />}
																	disabled={checkKrDIsable()}
																	handleClick={(e) => {
																		handleKrDrawerOpen(e, krItem, krIndex);
																	}}
																/>
															</Box>
														</Box>
													</Box>
													<OKRButton
														className={`settings-btn ${
															krItem?.isMetricTypeError || krItem?.isMetricCurrencyError ? 'settings-btn-error' : ''
														}`}
														id='default-obj-setting-btn'
														icon={<SettingsIcon />}
														disabled={checkKrDIsable()}
														handleClick={(e) => {
															handleKrDrawerOpen(e, krItem, krIndex);
														}}
														text={t('attributesLabel')}
														title={t('okrHoverText')}
														tooltipClassName={`tooltip-layout3 ${
															krItem?.isMetricTypeError || krItem?.isMetricCurrencyError ? 'tooltip-error-new' : ''
														}`}
													/>
													<OKRButton
														className='delete-btn'
														id='objective-delete-btn'
														icon={<DeleteIcon />}
														text={t('delete')}
														title={t('delete')}
														disabled={checkKrDIsable()}
														handleClick={(e) => handleKRDeletionClick(e, krItem, krIndex)}
													/>
												</Box>
											) : (
												<></>
											)}
										</Box>
										<Collapse in={krItem?.isExpand || false}>
											{krItem?.name && krItem?.name?.trim() !== '' && krItem?.metricType ? (
												<Box className='create-default-kr-content'>
													{krItem?.defaultGoalKeyAssignMappings && krItem?.defaultGoalKeyAssignMappings?.length ? (
														<Box className='assign-role-settings'>
															<Box className='assign-role-settings-head'>
																<Typography variant='h6'>
																	{`${
																		defaultOkrFormDetails?.assignType === Enums.ONE
																			? 'Designation'
																			: defaultOkrFormDetails?.assignType === Enums.TWO
																			? 'Job Levels'
																			: defaultOkrFormDetails?.assignType === Enums.THREE
																			? 'Users'
																			: ''
																	}`}
																	({krItem?.defaultGoalKeyAssignMappings?.length})
																</Typography>
																<Box className='user-search'>
																	<TextField
																		className='search-text-field'
																		// id='user-src-field'
																		placeholder={t('searchText')}
																		fullWidth
																		value={krItem?.searchTextValueForKrRole}
																		InputProps={{
																			startAdornment: (
																				<InputAdornment position='start'>
																					<SearchIcon />
																				</InputAdornment>
																			),
																		}}
																		onChange={(e) => handleSearchClicked(e, krItem, krIndex)}
																		onKeyDown={(e) => searchClicked(e, krItem, krIndex)}
																	/>

																	{krItem?.searchTextForKrRole && (
																		<Box className='user-search-chip'>
																			<List>
																				<ListItem>
																					{krItem?.searchTextForKrRole.length > 20 ? (
																						<Tooltip title={krItem?.searchTextForKrRole} arrow>
																							<Chip
																								label={krItem?.searchTextForKrRole.substring(0, 20) + '...'}
																								onDelete={(e) => clearSearch(e, krItem, krIndex)}
																								deleteIcon={
																									<span>
																										<CrossIcon />
																									</span>
																								}
																							/>
																						</Tooltip>
																					) : (
																						<Chip
																							label={krItem?.searchTextForKrRole}
																							onDelete={(e) => clearSearch(e, krItem, krIndex)}
																							deleteIcon={
																								<span>
																									<CrossIcon />
																								</span>
																							}
																						/>
																					)}
																				</ListItem>
																			</List>
																		</Box>
																	)}
																</Box>
															</Box>

															<Box className='assign-role-settings-list'>
																<Box className='okr-list-panel-head'>
																	<List component='ul' disablePadding>
																		<ListItem className='assign-role-col1'>
																			{defaultOkrFormDetails?.assignType === Enums.FOUR ? (
																				<Typography variant='body2'>
																					<span className='sortable-label'>
																						<span className='txt-wrap'>{t('nameLabel')}</span>
																					</span>
																				</Typography>
																			) : (
																				<Typography
																					onClick={(e) =>
																						sortUserList(
																							e,
																							'name',
																							krItem?.defaultGoalKeyAssignMappings,
																							krItem,
																							krIndex
																						)
																					}
																					variant='body2'
																					className={
																						krItem.krSortColumn === 'name'
																							? `sort-title ${sortOrder}`
																							: 'sort-title asc'
																					}
																					// id={'role_name_sort'}
																				>
																					<span className='sortable-label'>
																						<span className='txt-wrap'>
																							{t('nameLabel')} <SortingIcon />
																						</span>
																					</span>
																				</Typography>
																			)}
																		</ListItem>
																		<ListItem className='assign-role-col2'>
																			{defaultOkrFormDetails?.assignType === Enums.FOUR ? (
																				<Typography variant='body2'>
																					<span className='txt-wrap'>
																						{defaultOkrFormDetails?.assignType === Enums.THREE
																							? t('emailLabel')
																							: t('numberUser')}
																					</span>
																				</Typography>
																			) : (
																				<Typography
																					onClick={(e) =>
																						sortUserList(
																							e,
																							defaultOkrFormDetails?.assignType === Enums.THREE
																								? 'emailId'
																								: 'userCount',
																							krItem?.defaultGoalKeyAssignMappings,
																							krItem,
																							krIndex
																						)
																					}
																					variant='body2'
																					className={
																						krItem.krSortColumn === 'userCount'
																							? `sort-title ${sortOrder}`
																							: 'sort-title asc'
																					}
																					// id={'role_users_count_-sort'}
																				>
																					<span className='sortable-label'>
																						<span className='txt-wrap'>
																							{defaultOkrFormDetails?.assignType === Enums.THREE
																								? t('emailLabel')
																								: t('numberUser')}{' '}
																							<SortingIcon />
																						</span>
																					</span>
																				</Typography>
																			)}
																		</ListItem>
																		<ListItem className='assign-role-col3'>
																			{defaultOkrFormDetails?.assignType === Enums.FOUR ? (
																				<Typography variant='body2'>
																					<span className='txt-wrap'>{t('weightLabel')}</span>
																				</Typography>
																			) : (
																				<Typography
																					onClick={(e) =>
																						sortUserList(
																							e,
																							'weight',
																							krItem?.defaultGoalKeyAssignMappings,
																							krItem,
																							krIndex
																						)
																					}
																					variant='body2'
																					className={
																						krItem.krSortColumn === 'weight'
																							? `sort-title ${sortOrder}`
																							: 'sort-title asc'
																					}
																					// id={'role_users_count_sort'}
																				>
																					<span className='sortable-label'>
																						<span className='txt-wrap'>
																							{t('weightLabel')} <SortingIcon />
																						</span>
																					</span>
																				</Typography>
																			)}
																		</ListItem>
																		<ListItem className='assign-role-col4'>
																			{defaultOkrFormDetails?.assignType === Enums.FOUR ? (
																				<Typography variant='body2'>
																					<span className='txt-wrap'>{t('startTargetLabel')}</span>
																				</Typography>
																			) : (
																				<Typography
																					onClick={(e) =>
																						sortUserList(
																							e,
																							'targetValue',
																							krItem?.defaultGoalKeyAssignMappings,
																							krItem,
																							krIndex
																						)
																					}
																					variant='body2'
																					className={
																						krItem.krSortColumn === 'targetValue'
																							? `sort-title ${sortOrder}`
																							: 'sort-title asc'
																					}
																					// id={'role_users_count_sort'}
																				>
																					<span className='sortable-label'>
																						<span className='txt-wrap'>
																							{t('startTargetLabel')} <SortingIcon />
																						</span>
																					</span>
																				</Typography>
																			)}
																		</ListItem>
																	</List>
																</Box>
																<Box className='assign-role-panel-content'>
																	<List component='ul' disablePadding>
																		<ListItem className='assign-role-col1'>
																			<Box>
																				{defaultOkrFormDetails?.assignType === Enums.FOUR ? t('allEmpInOrgLevel') : ''}
																			</Box>
																		</ListItem>
																		<ListItem className='assign-role-col2'>
																			<Box>
																				{defaultOkrFormDetails?.assignType === Enums.FOUR
																					? okrMasterData?.totalUserCount || Enums.ZERO
																					: ''}
																			</Box>
																		</ListItem>
																		<ListItem className='assign-role-col3'>
																			<Box className='weight-box'>
																				<Box className='weight-field'>
																					<OKRButton
																						className='weight-chip'
																						handleClick={(e) => handleWeightClick(e, krItem, krIndex)}
																						text={krItem?.isWeightSame ? getWeightName(krItem) : '--'}
																						disabled={!krItem?.isWeightSame ? true : false}
																					/>
																				</Box>
																				<Box className='switch-button'>
																					{defaultOkrFormDetails?.assignType === Enums.FOUR ? (
																						<></>
																					) : (
																						<FormControlLabel
																							control={
																								<Switch
																									// id='isWeightSameFOr'
																									name='isWeightSame'
																									checked={krItem?.isWeightSame || false}
																									onChange={(e) => handleIsWeightSameToggleClick(e, krItem, krIndex)}
																								/>
																							}
																							label={t('sameForAllLabel')}
																							labelPlacement='end'
																							className='toggle-label'
																						/>
																					)}
																				</Box>
																			</Box>
																		</ListItem>
																		<ListItem className='assign-role-col4'>
																			<Box className='kr-metrics-fileds kr-metrics-full-width'>
																				<Box className='kr-metrics-fileds-inner'>
																					<TextField
																						className='current-value helperText-bottom'
																						// id='current-value'
																						placeholder={'0'}
																						value={krItem?.isTargetSame ? krItem?.startValue : '--'}
																						fullWidth
																						onChange={(e: any) =>
																							handleKrStartTargetValueChange(e, krItem, krIndex, 'startValue')
																						}
																						disabled={!krItem?.isTargetSame ? true : false}
																						InputProps={{
																							startAdornment: (
																								<InputAdornment position='start'>
																									{krItem?.metricType === 1
																										? '%'
																										: krItem?.metricType === 2
																										? getCurrencyIcon(
																												getCurrencyName(
																													krItem?.metricType,
																													krItem?.currencyId,
																													okrMasterData
																												)
																										  )
																										: '#'}
																								</InputAdornment>
																							),
																						}}
																					/>
																					<Box className='matrics-field-seprator' component='span'>
																						{'/'}
																					</Box>
																					<TextField
																						className='target-value helperText-bottom'
																						// id='target-value'
																						placeholder={'100'}
																						value={krItem?.isTargetSame ? krItem?.targetValue : '--'}
																						fullWidth
																						onChange={(e: any) =>
																							handleKrStartTargetValueChange(e, krItem, krIndex, 'targetValue')
																						}
																						disabled={!krItem?.isTargetSame ? true : false}
																						error={krItem?.isTargetValueError}
																						helperText={
																							krItem?.isTargetValueError && krItem?.krTargetValueErrorMsg ? (
																								<>
																									<ErrorIcon />
																									{krItem?.krTargetValueErrorMsg}
																								</>
																							) : (
																								''
																							)
																						}
																						InputProps={{
																							startAdornment: (
																								<InputAdornment position='start'>
																									{krItem?.metricType === 1
																										? '%'
																										: krItem?.metricType === 2
																										? getCurrencyIcon(
																												getCurrencyName(
																													krItem?.metricType,
																													krItem?.currencyId,
																													okrMasterData
																												)
																										  )
																										: '#'}
																								</InputAdornment>
																							),
																						}}
																					/>
																				</Box>
																				<Box className='switch-button'>
																					{defaultOkrFormDetails?.assignType === Enums.FOUR ? (
																						<></>
																					) : (
																						<FormControlLabel
																							control={
																								<Switch
																									// id='isTargetAllow'
																									checked={krItem?.isTargetSame || false}
																									name='isTargetAllow'
																									onChange={(e) => handleIsTargetSameToggleClick(e, krItem, krIndex)}
																								/>
																							}
																							label={t('sameForAllLabel')}
																							labelPlacement='end'
																							className='toggle-label'
																						/>
																					)}
																				</Box>
																			</Box>
																		</ListItem>
																	</List>
																</Box>
																{defaultOkrFormDetails?.assignType === Enums.FOUR ? (
																	<></>
																) : (
																	getFilteredList(
																		krItem?.defaultGoalKeyAssignMappings,
																		krItem?.searchTextForKrRole
																	)?.map((role: any, roleIndex: number) => {
																		return (
																			<Box className='assign-role-panel-content'>
																				<List component='ul' disablePadding>
																					<ListItem
																						className={`assign-role-col1 ${
																							role?.isActive
																								? ''
																								: defaultOkrFormDetails?.assignType === Enums.THREE
																								? 'deleted-user-row'
																								: ''
																						}`}
																					>
																						<Box
																							className={`user-info ${
																								defaultOkrFormDetails?.assignType === Enums.THREE ? 'user-info-tab' : ''
																							}`}
																						>
																							{defaultOkrFormDetails?.assignType === Enums.THREE ? (
																								<Box className='user-img'>
																									<Avatar className='avatar-default' src={role?.imagePath || ''}>
																										{getUserName({
																											firstName: role?.firstName || '',
																											lastName: role?.lastName || '',
																											fullName: role?.name || '',
																										})}
																									</Avatar>
																								</Box>
																							) : (
																								<></>
																							)}
																							<Box className='user-info-details'>
																								<Typography>{role?.name || ''}</Typography>
																								{role?.isActive ? (
																									<></>
																								) : defaultOkrFormDetails?.assignType === Enums.THREE ? (
																									<Box className='deleted-chip-wrap'>
																										<Chip className='delete-chip' label={t('deletedLabel')} />
																									</Box>
																								) : (
																									<></>
																								)}
																							</Box>
																						</Box>
																					</ListItem>
																					<ListItem className='assign-role-col2'>
																						<Typography>
																							{defaultOkrFormDetails?.assignType === Enums.THREE
																								? role?.emailId || ''
																								: role?.userCount || Enums.ZERO}
																						</Typography>
																					</ListItem>
																					<ListItem className='assign-role-col3'>
																						<Box className='weight-box'>
																							<Box className='weight-field'>
																								<OKRButton
																									className='weight-chip'
																									text={getWeightName(role)}
																									handleClick={(e) =>
																										handleRoleWeightClick(e, krItem, krIndex, role, roleIndex)
																									}
																									disabled={krItem?.isWeightSame}
																								/>
																							</Box>
																						</Box>
																					</ListItem>
																					<ListItem className='assign-role-col4'>
																						<Box className='kr-metrics-fileds kr-metrics-full-width'>
																							<Box className='kr-metrics-fileds-inner'>
																								<TextField
																									className='current-value helperText-bottom'
																									// id='current-value'
																									placeholder={'0'}
																									fullWidth
																									value={role?.startValue}
																									disabled={krItem?.isTargetSame}
																									onChange={(e: any) =>
																										handleKrRoleStartTargetValueChange(
																											e,
																											krItem,
																											krIndex,
																											role,
																											roleIndex,
																											'startValue'
																										)
																									}
																									InputProps={{
																										startAdornment: (
																											<InputAdornment position='start'>
																												{krItem?.metricType === 1
																													? '%'
																													: krItem?.metricType === 2
																													? getCurrencyIcon(
																															getCurrencyName(
																																krItem?.metricType,
																																krItem?.currencyId,
																																okrMasterData
																															)
																													  )
																													: '#'}
																											</InputAdornment>
																										),
																									}}
																								/>
																								<Box className='matrics-field-seprator' component='span'>
																									{'/'}
																								</Box>
																								<TextField
																									className='target-value helperText-bottom'
																									// id='target-value'
																									placeholder={'100'}
																									fullWidth
																									value={role?.targetValue}
																									disabled={krItem?.isTargetSame}
																									onChange={(e: any) =>
																										handleKrRoleStartTargetValueChange(
																											e,
																											krItem,
																											krIndex,
																											role,
																											roleIndex,
																											'targetValue'
																										)
																									}
																									error={role?.isRoleTargetValueError}
																									helperText={
																										role?.isRoleTargetValueError && role?.roleTargetValueErrorMsg ? (
																											<>
																												<ErrorIcon />
																												{role?.roleTargetValueErrorMsg}
																											</>
																										) : (
																											''
																										)
																									}
																									InputProps={{
																										startAdornment: (
																											<InputAdornment position='start'>
																												{krItem?.metricType === 1
																													? '%'
																													: krItem?.metricType === 2
																													? getCurrencyIcon(
																															getCurrencyName(
																																krItem?.metricType,
																																krItem?.currencyId,
																																okrMasterData
																															)
																													  )
																													: '#'}
																											</InputAdornment>
																										),
																									}}
																								/>
																							</Box>
																						</Box>
																					</ListItem>
																				</List>
																			</Box>
																		);
																	})
																)}
															</Box>
														</Box>
													) : (
														<></>
													)}
												</Box>
											) : (
												<></>
											)}
										</Collapse>
									</Box>
								</Box>
							);
						})
					) : (
						<></>
					)}
				</Box>
				<Box className='create-deafult-okr-actions'>
					{defaultOkrFormDetails?.status === Enums.TWO ||
					defaultOkrFormDetails?.status === Enums.THREE ||
					defaultOkrFormDetails?.status === Enums.FOUR ? (
						<></>
					) : (
						<OKRButton
							className='btn-link'
							id='save-as-draft'
							text={t('saveAsDraft')}
							disabled={showLoader}
							handleClick={handleDraftDefaultOkrClick}
						/>
					)}
					{/* {defaultOkrFormDetails?.status !== Enums.TWO ? ( */}
					<OKRButton
						className='btn-link'
						id='save-publish-later'
						text={
							defaultOkrFormDetails?.status === Enums.TWO || defaultOkrFormDetails?.status === Enums.FOUR
								? t('saveRePublishLater')
								: t('savePublishLater')
						}
						disabled={showLoader}
						handleClick={handleSaveAndPublishLaterDefaultOkrClick}
					/>
					{/* ) : (
						<></>
					)} */}
					<OKRButton
						className='btn-primary'
						id='default-obj-save-btn'
						text={
							defaultOkrFormDetails?.status === Enums.TWO || defaultOkrFormDetails?.status === Enums.FOUR
								? t('rePublishLabel')
								: t('publishLabel')
						}
						disabled={showLoader}
						handleClick={handlePublishDefaultOkrClick}
					/>
				</Box>
			</Box>
			{/* )} */}

			{weightSelectionOpen && (
				<Popper
					className='popper-menu weightage-dropdown'
					open={weightSelectionOpen}
					anchorEl={anchorEl}
					placement='bottom'
					transition
					modifiers={{
						preventOverflow: {
							enabled: false,
							boundariesElement: window,
						},

						flip: {
							enabled: false, // true by default
						},
					}}
				>
					{({ TransitionProps }) => (
						<Paper>
							<ClickAwayListener onClickAway={handleWeightClose}>
								<List component='div' disablePadding>
									<ListItem
										className='active'
										component='div'
										id={`assign-weightage-1`}
										onClick={(e) => handleWeightSelection(e, 1)}
									>
										<ListItemText>1x</ListItemText>
									</ListItem>
									<ListItem component='div' id={`assign-weightage-2`} onClick={(e) => handleWeightSelection(e, 2)}>
										<ListItemText>2x</ListItemText>
									</ListItem>
									<ListItem component='div' id={`assign-weightage-3`} onClick={(e) => handleWeightSelection(e, 3)}>
										<ListItemText>3x</ListItemText>
									</ListItem>
									<ListItem component='div' id={`assign-weightage-4`} onClick={(e) => handleWeightSelection(e, 4)}>
										<ListItemText>4x</ListItemText>
									</ListItem>
									<ListItem component='div' id={`assign-weightage-5`} onClick={(e) => handleWeightSelection(e, 5)}>
										<ListItemText>5x</ListItemText>
									</ListItem>
								</List>
							</ClickAwayListener>
						</Paper>
					)}
				</Popper>
			)}
		</>
	);
};
