import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../../../styles/pages/admin/settings/default-okr/createDefaultOkr.scss';
import { Box, FormControlLabel, FormLabel, Grid, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import {
	AddIconSvg,
	DeleteIcon,
	DocumentIcon,
	ScaleType,
	WeightageIcon,
} from '../../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../../Common/OKRButton';
import { GoalFormIcon } from '../../../../../../config/svg/MyGoalSvg';
import { ResponseTypeDropdown } from './ResponseTypeDropdown';
import { ErrorIcon } from '../../../../../../config/svg/formElementIcons';
import { NumberFormatComponent } from '../../../../../Common/NumberFormat';

export const CreateGoalSettings: React.FC<any> = (props: any) => {
	const {
		provided,
		getItemStyle,
		snapshot,
		indexVal,
		goalIndex,
		updateGoalDetails,
		goalItem,
		goalLength,
		handleDeleteGoal,
		category,
		goalFormData,
		responseTypeList,
	} = props;

	const { t } = useTranslation();

	return (
		<>
			<Box className='create-goal-row'>
				<Box
					//ref={provided.innerRef}
					//{...provided.draggableProps}
					//{...provided.dragHandleProps}
					//style={getItemStyle(snapshot.isDragging, provided.draggableProps?.style)}
					className='assessment-question-row'
					id={`form_section_item_index_${indexVal}_question_index_${goalIndex}`}
				>
					{/*<Tooltip arrow title={t('dragDropLabel')}>
						<span className='drag-icon'>
							<DragIndicator />
						</span>
	</Tooltip>*/}

					<Box className='goal-info'>
						<Box
							className={`col-1 ${
								goalItem?.isGoalQuestionNameError && goalItem?.sectionQuestionNameErrorMessage
									? 'custom-error-field'
									: ''
							}`}
						>
							<TextField
								className='helperText-bottom'
								label={
									<>
										<GoalFormIcon /> {t('goalLabel')} {goalIndex + 1}
									</>
								}
								placeholder={t('enterGoals')}
								name={t('goalLabel')}
								InputLabelProps={{
									shrink: true,
								}}
								value={goalItem?.name || ''}
								onChange={(e: any) => updateGoalDetails('name', e?.target?.value || '', 'goal', goalIndex)}
								InputProps={{ 'aria-label': t('goalLabel'), disableUnderline: true }}
								error={goalItem?.isGoalQuestionNameError || false}
								helperText={
									goalItem?.isGoalQuestionNameError && goalItem?.sectionQuestionNameErrorMessage ? (
										<>
											<ErrorIcon /> {goalItem?.sectionQuestionNameErrorMessage || ''}
										</>
									) : null
								}
							/>
						</Box>
						<Box className='col-2'>
							{goalFormData.isQuestionWeightage && (
								<Box
									className={`weightage-field ${
										goalItem?.isWeightageError && goalItem?.isWeightageErrorMessage ? 'custom-error-field' : ''
									}`}
								>
									<TextField
										fullWidth
										className='helperText-bottom'
										placeholder={t('enterWeightage')}
										label={
											<>
												<WeightageIcon /> {t('weightageLabel')}
											</>
										}
										name={t('weightageLabel')}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											maxLength: 5,
											inputType: 'numberFieldProgress',
											inputComponent: NumberFormatComponent,
										}}
										InputProps={{
											'aria-label': t('weightageLabel'),
											disableUnderline: true,
											inputComponent: NumberFormatComponent,
										}}
										error={goalItem?.isWeightageError || false}
										helperText={
											goalItem?.isWeightageError && goalItem?.isWeightageErrorMessage ? (
												<>
													<ErrorIcon /> {goalItem?.isWeightageErrorMessage || ''}
												</>
											) : null
										}
										value={goalItem?.weightage || ''}
										onChange={(e: any) => {
											const regexpNumber = /^[0-9\b]+$/;
											let flValue = e?.target?.value;
											//	if (flValue === '' || (regexpNumber.test(flValue) && flValue <= 100)) {
											updateGoalDetails('weightage', flValue || '', 'goal', goalIndex);
											//}
										}}
									/>
								</Box>
							)}
							{goalLength !== 1 && (
								<OKRButton
									icon={<DeleteIcon />}
									className='editIcon'
									title={t('delete')}
									handleClick={(e: any) => handleDeleteGoal(e, goalItem, goalIndex, category, indexVal)}
								/>
							)}
						</Box>
					</Box>
					<Box className='goal-response-type'>
						<FormLabel component='legend'>
							<ScaleType />
							{t('responseTypeLabel')}
						</FormLabel>
						<ResponseTypeDropdown
							selectedVal={goalItem?.questionType}
							handleChange={(item: any) => {
								updateGoalDetails('questionType', item.responseTypeId, 'goal', goalIndex);
							}}
							optionsList={responseTypeList}
						/>
					</Box>
					<Box className='goal-reponse-description'>
						{goalItem?.questionType === 2 ? (
							<TextField
								fullWidth
								className='helperText-bottom'
								placeholder={t('descriptionHelpText')}
								label={
									<>
										<DocumentIcon /> {t('descriptionLabel')}
									</>
								}
								name={t('descriptionLabel')}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									'aria-label': t('descriptionLabel'),
									disableUnderline: true,
								}}
								disabled={true}
							/>
						) : goalItem?.questionType === 3 ? (
							<TextField
								fullWidth
								className='helperText-bottom'
								placeholder={t('numberHelpText')}
								label={
									<>
										<DocumentIcon /> {t('numberLabel')}
									</>
								}
								name={t('numberLabel')}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									'aria-label': t('numberLabel'),
									disableUnderline: true,
								}}
								disabled={true}
							/>
						) : goalItem?.questionType === 4 ? (
							<TextField
								fullWidth
								className='helperText-bottom'
								placeholder={t('dateHelpText')}
								label={
									<>
										<DocumentIcon /> {t('dateLabel')}
									</>
								}
								name={t('dateLabel')}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									'aria-label': t('dateLabel'),
									disableUnderline: true,
								}}
								disabled={true}
							/>
						) : goalItem?.questionType === 5 ? (
							<TextField
								fullWidth
								className='helperText-bottom'
								placeholder={t('numberAndDateHelpText')}
								label={
									<>
										<DocumentIcon /> {t('numberAndDateLabel')}
									</>
								}
								name={t('numberAndDateLabel')}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									'aria-label': t('numberAndDateLabel'),
									disableUnderline: true,
								}}
								disabled={true}
							/>
						) : (
							<></>
						)}
					</Box>
					{goalItem?.questionType !== 1 && (
						<Box className='response-action'>
							<Box className='col'>
								<Grid component='label' container>
									<FormControlLabel
										control={<Switch name='categoryWeightage' checked={goalItem.isMandatory} />}
										label={t('isResponseMandatory')}
										labelPlacement='end'
										className='toggle-label'
										checked={goalItem.isMandatory}
										onChange={(e: any) => {
											updateGoalDetails('isMandatory', e?.target?.checked || false, 'goal', goalIndex);
										}}
									/>
								</Grid>
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};
