import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@material-ui/core';

export const PerformanceChart = (props) => {
	const { chartData } = props;

	const columnWithGroupedChartData = {
		credits: {
			enabled: false,
		},
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: 0,
			plotShadow: false,
			margin: [0, 0, 0, 0], // top, right, bottom, left
			height: '90',
		},
		title: {
			text: chartData?.score,
			align: 'center',
			verticalAlign: 'middle',
			y: 35,
			style: {
				fontWeight: 'bold',
				fontSize: '28px',
				fontFamily: 'Lato',
				color: '#292929',
			},
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
		},
		accessibility: {
			point: {
				valueSuffix: '%',
			},
		},
		plotOptions: {
			pie: {
				// dataLabels: {
				// 	enabled: false,
				// 	distance: -50,
				// 	style: {
				// 		fontWeight: 'bold',
				// 		color: 'white',
				// 	},
				// },
				startAngle: -102,
				endAngle: 102,
				center: ['50%', '100%'],
				size: '230%',
			},
		},
		series: [
			{
				type: 'pie',
				name: 'Performance Score',
				innerSize: '80%',
				enableMouseTracking: false,
				borderRadius: 5,
				data: [
					{
						name: '',
						y: chartData?.score,
						color: '#39A3FA',
						dataLabels: {
							enabled: false,
						},
					},
					{
						name: '',
						y: chartData?.totalValue - parseInt(chartData?.score),
						color: '#D5DAE1',
						dataLabels: {
							enabled: false,
						},
					},
				],
			},
		],
	};

	return (
		<Box className='pie-chart'>
			<HighchartsReact highcharts={Highcharts} options={columnWithGroupedChartData} />
		</Box>
	);
};
