import {
	requestOneToOnePersonalFeedbackAPI,
	askPersonalFeedbackAPI,
	askDetailsPersonalFeedbackAPI,
	giveDetailsPersonalFeedbackAPI,
	giveUpdatePersonalFeedbackAPI,
	getCriteriaMasterAPI,
	requestAgainPersonalFeedbackAPI,
	cancelRequestPersonalFeedbackAPI,
	viewPersonalFeedbackAPI,
	personalFeedbackDetailAPI,
	pendingPersonalFeedbackAPI,
	getAllFeedbackCountAPI,
	searchDefaultCc,
	getPermissionForFeedback,
	getSearchForFeedbackOneToOneApi,
	getFeedbackPermissionSearch,
} from '../services/FeedbackService';

export const requestOneToOnePersonalFeedback = (data) => {
	return () => {
		return requestOneToOnePersonalFeedbackAPI(data);
	};
};
export const addFeedbackPermission = (data) => {
	return () => {
		return getPermissionForFeedback(data);
	};
};
export const getSearchForFeedbackOneToOne = (data) => {
	return () => {
		return getSearchForFeedbackOneToOneApi(data);
	};
};

export const askPersonalFeedback = (data) => {
	return () => {
		return askPersonalFeedbackAPI(data);
	};
};

export const askDetailsPersonalFeedback = (data) => {
	return () => {
		return askDetailsPersonalFeedbackAPI(data);
	};
};

export const giveDetailsPersonalFeedback = (data) => {
	return () => {
		return giveDetailsPersonalFeedbackAPI(data);
	};
};
export const getAllFeedbackCount = (data) => {
	return () => {
		return getAllFeedbackCountAPI(data);
	};
};
export const getSearchFeedbackApi = (data) => {
	return () => {
		return getFeedbackPermissionSearch(data);
	};
};
export const pendingPersonalFeedback = (data) => {
	return () => {
		return pendingPersonalFeedbackAPI(data);
	};
};

export const getSearchDefaultUser = (data) => {
	return () => {
		return searchDefaultCc(data);
	};
};

export const giveUpdatePersonalFeedback = (data) => {
	return () => {
		return giveUpdatePersonalFeedbackAPI(data);
	};
};

export const getCriteriaMaster = (data) => {
	return () => {
		return getCriteriaMasterAPI(data);
	};
};

export const cancelRequestPersonalFeedback = (data) => {
	return () => {
		return cancelRequestPersonalFeedbackAPI(data);
	};
};

export const requestAgainPersonalFeedback = (data) => {
	return () => {
		return requestAgainPersonalFeedbackAPI(data);
	};
};

export const viewPersonalFeedback = (data) => {
	return () => {
		return viewPersonalFeedbackAPI(data);
	};
};

export const personalFeedbackDetail = (data) => {
	return () => {
		return personalFeedbackDetailAPI(data);
	};
};
