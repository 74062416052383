import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@material-ui/core';
import NofoundImg from '../../../../images/no-found-conversation.svg';
import { UserAvatarGroup } from '../../../Common/UserAvatarGroup';
import { ReviewerCommentForm } from './ReviewerCommentForm';
import { PopperMenu } from '../../../Admin/CommonComponent/PopperMenu';
import { DeleteIcon, EditIcon } from '../../../../config/svg/CommonSvg';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import { getFullName, getGlobalTaskListDueDate, getUserName, getTime, getUserDetails } from '../../../../config/utils';
import { Enums } from '../../../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import { deletePipComment, savePipComments } from '../../../../action/performanceImprovementPlan';
import AlertDialog from '../../../Common/Dialog';
import { OKRButton } from '../../../Common/OKRButton';
import CkEditor from '../../../Common/CkEditor/CkEditor';

export const ReviewersComment = (props: any) => {
	const {
		pipDetails,
		reviewDateDetails,
		userDetails,
		showMsg,
		loader,
		setLoader,
		selectedReviewDateData,
		reviewDates,
		refreshCommentList,
		isUserHavePIPPermission,
		isUserHavePIPEditPermission,
		setIsFormEdited,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { pipImprovementData } = useSelector((state: any) => state?.performanceImprovementPlanReducer);

	const reviewFormStaticDataObj = {
		commentDetailsId: Enums.ZERO,
		comments: '',
		moduleDetailsId: Enums.ZERO,
		moduleId: Enums.ZERO,
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [creatorDetailsVal, setCreatorDetailsVal] = useState<any>(null);
	const [reviewerDetailsVal, setReviewerDetailsVal] = useState<any>([]);
	const [reviewFormData, setReviewFormData] = useState<any>(reviewFormStaticDataObj);
	const [showEditor, setShowEditor] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });
	const [commentListDetails, setCommentListDetails] = useState<any>(null);
	// console.log('selectedReviewDateData ==>', selectedReviewDateData);
	useEffect(() => {
		if (reviewDateDetails?.reviewersResponses && reviewDateDetails?.reviewersResponses?.length) {
			let reviewersResponses = [...reviewDateDetails?.reviewersResponses];
			const creatorDetails = reviewDateDetails?.reviewersResponses[0];
			reviewersResponses.shift();
			setCreatorDetailsVal(creatorDetails);
			setReviewerDetailsVal(reviewersResponses);
		}
		if (reviewDateDetails?.commentResponses?.results && reviewDateDetails?.commentResponses?.results?.length) {
			let details = reviewDateDetails?.commentResponses?.results;
			details = details?.map((item: any) => {
				const data = { ...item };
				data.historyComments = data?.comments || '';
				data.isEditMode = false;
				data.commentsError = '';

				return data;
			});
			setCommentListDetails(details);
		} else {
			setCommentListDetails(null);
		}
	}, [reviewDateDetails]);
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangeCommentValue = (value: any) => {
		setIsFormEdited(true);
		setReviewFormData({ ...reviewFormData, comments: value });
	};
	const handleCancelCommentClick = (event: any) => {
		try {
			setIsFormEdited(false);
			setReviewFormData(reviewFormStaticDataObj);
			setShowEditor(false);
		} catch (error) {
			console.error(error);
		}
	};
	const handleSubmitCommentClick = async (event: any, reviewFormDataDetails: any, type = 'Add') => {
		try {
			if (selectedReviewDateData?.performanceImprovementPlanReviewDateId) {
				if (reviewFormDataDetails?.comments) {
					setLoader(true);
					const requestPayload = {
						commentDetailsId: reviewFormDataDetails?.commentDetailsId || Enums.ZERO,
						comments: reviewFormDataDetails?.comments || '',
						moduleDetailsId: selectedReviewDateData?.performanceImprovementPlanReviewDateId || Enums.ZERO,
						moduleId: Enums.THREE,
					};
					const response: any = await dispatch(savePipComments(requestPayload));
					if (response?.data?.status === Enums.STATUS_SUCCESS) {
						refreshCommentList(selectedReviewDateData?.performanceImprovementPlanReviewDateId, reviewDates);
						if (type === 'Add') {
							handleCancelCommentClick(event);
						} else if (type === 'View') {
						}
						setLoader(false);
					} else {
						const { messageList } = response?.data || {};
						const keys = messageList ? Object.keys(messageList) : [];
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						showMsg('error', messages || `Error occurred while adding comment.`);
						setLoader(false);
					}
					setIsFormEdited(false);
				} else {
					if (type === 'Add') {
						setReviewFormData({ ...reviewFormData, commentsError: 'Comment is required to Resolve PIP' });
					} else if (type === 'View') {
						let updatedCommentListDetails =
							commentListDetails && commentListDetails?.length ? [...commentListDetails] : [];
						updatedCommentListDetails = updatedCommentListDetails?.map((item: any) => {
							const data = { ...item };
							if (item?.commentDetailsId === reviewFormDataDetails?.commentDetailsId) {
								data.commentsError = 'Comment is required to Resolve PIP';
							}
							return data;
						});
						setCommentListDetails(updatedCommentListDetails);
					}
				}
			} else {
				showMsg('error', `Some error occurred`);
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			setLoader(false);
		}
	};
	const handleEditCommentClick = (e: any, commentData: any) => {
		try {
			let updatedCommentListDetails = commentListDetails && commentListDetails?.length ? [...commentListDetails] : [];
			updatedCommentListDetails = updatedCommentListDetails?.map((item: any) => {
				const data = { ...item };
				if (item?.commentDetailsId === commentData?.commentDetailsId) {
					data.isEditMode = true;
				} else {
					data.isEditMode = false;
				}
				return data;
			});
			setIsFormEdited(true);
			setCommentListDetails(updatedCommentListDetails);
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			setLoader(false);
		}
	};
	const handleChangeCommentValueOnEdit = (value: any, comment: any, commentIndex: number) => {
		let updatedCommentListDetails = commentListDetails && commentListDetails?.length ? [...commentListDetails] : [];
		if (updatedCommentListDetails?.length > 0) {
			updatedCommentListDetails[commentIndex].comments = value;
			updatedCommentListDetails[commentIndex].commentsError = '';
		}
		setIsFormEdited(true);
		setCommentListDetails(updatedCommentListDetails);
	};
	const handleCancelCommentClickOnEdit = (e: any, commentData: any, commentIndex: number) => {
		let updatedCommentListDetails = commentListDetails && commentListDetails?.length ? [...commentListDetails] : [];
		updatedCommentListDetails = updatedCommentListDetails?.map((item: any) => {
			const data = { ...item };
			if (item?.commentDetailsId === commentData?.commentDetailsId) {
				data.isEditMode = false;
				data.comments = data?.historyComments || '';
			}
			return data;
		});
		setCommentListDetails(updatedCommentListDetails);
		setIsFormEdited(false);
	};
	const handleSubmitCommentClickOnEdit = (e: any, commentData: any, commentIndex: number) => {
		if (commentData?.comments) {
			handleSubmitCommentClick(e, commentData, 'View');
		} else {
			let updatedCommentListDetails = commentListDetails && commentListDetails?.length ? [...commentListDetails] : [];
			updatedCommentListDetails = updatedCommentListDetails?.map((item: any) => {
				const data = { ...item };
				if (item?.commentDetailsId === commentData?.commentDetailsId) {
					data.commentsError = 'Comment is required to Resolve PIP';
				}
				return data;
			});
			setCommentListDetails(updatedCommentListDetails);
		}
	};
	/**  */
	const handleDeleteCommentClick = (e: any, commentData: any) => {
		try {
			setModalProps({
				open: true,
				details: { commentData },
				type: 'DeleteComment',
				message: t('Are you sure you want to delete this comment?'),
			});
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			setLoader(false);
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'DeleteComment') {
				const { commentData } = modalProps?.details || {};
				deleteCommentApiCall(commentData);
			}
		}
		setModalProps({ open: false, details: null, message: '' });
	};
	const deleteCommentApiCall = async (commentData: any) => {
		try {
			if (commentData?.commentDetailsId) {
				setLoader(true);
				const response: any = await dispatch(deletePipComment(`commentDetailsId=${commentData?.commentDetailsId}`));
				if (response?.data?.status === Enums.STATUS_SUCCESS) {
					refreshCommentList(selectedReviewDateData?.performanceImprovementPlanReviewDateId, reviewDates);
					setLoader(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Error occurred while adding comment.`);
					setLoader(false);
				}
				handleClose();
			} else {
				showMsg('error', `Some error occurred`);
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			handleClose();
			setLoader(false);
		}
	};

	return (
		<>
			<Box className='full-rectangular-card reviewers-comment'>
				<Box className='reviewer-info '>
					<Typography variant='h6'>{t('reviewersListLabel')}</Typography>
					<Box className='pip-reviewers-list'>
						{/* <Box className='review-initiator'>
							{creatorDetailsVal ? (
								<>
									{creatorDetailsVal?.imagePath ? (
										<Avatar className='avatar-default' src={creatorDetailsVal.imagePath} />
									) : (
										<Avatar className={'avatar-default'}>
											{getUserName({
												firstName: creatorDetailsVal?.firstName || '',
												lastName: creatorDetailsVal?.lastName || '',
												fullName: creatorDetailsVal?.fullName || '',
											})}
										</Avatar>
									)}
								</>
							) : (
								<></>
							)}
						</Box> */}
						<Box className='review-others'>
							<UserAvatarGroup
								{...props}
								contributorClassName={'contributor-details-popover'}
								isButtonVisible={false}
								handleAddUserClick={() => {}}
								contributorDetailsType={true}
								isOnHoverVisible={true}
								loginUserAvatar={creatorDetailsVal}
								contributorDetails={reviewerDetailsVal && reviewerDetailsVal?.length ? [...reviewerDetailsVal] : []}
								isUserPlaceholder={true}
								subTitle={' '}
								helpText={' '}
								max={5}
								//isUserAvatarClick={type !== 'Add' && editableDetails ? true : false}
								//handleUserAvatarClick={handleUserAvatarClick}
								isDisabled={true}
								viewType='pipReviewers'
							/>
						</Box>
					</Box>
				</Box>
				<Box className='reviewers-post-comment'>
					{pipDetails?.performanceImprovementPlanStatusId === Enums.THREE ||
					pipDetails?.performanceImprovementPlanStatusId === Enums.ONE ||
					pipDetails?.performanceImprovementPlanStatusId === Enums.TWO ? (
						<Box className='reviewers-comment-top'>
							<Box className='user-info'>
								<Box className='user-img'>
									{/* <Avatar className='avatar-default'>DF</Avatar> */}
									{userDetails ? (
										<Tooltip arrow title={`${userDetails?.firstName || ''} ${userDetails?.lastName || ''}`}>
											{userDetails?.imagePath ? (
												<Avatar className='avatar-default' src={userDetails.imagePath} />
											) : (
												<Avatar className={'avatar-default'}>
													{getUserName({
														firstName: userDetails?.firstName || '',
														lastName: userDetails?.lastName || '',
														fullName: userDetails?.fullName || '',
													})}
												</Avatar>
											)}
										</Tooltip>
									) : (
										<></>
									)}
								</Box>
							</Box>
							<ReviewerCommentForm
								loader={loader}
								reviewFormData={reviewFormData}
								handleChangeCommentValue={handleChangeCommentValue}
								handleCancelCommentClick={handleCancelCommentClick}
								handleSubmitCommentClick={(e: any) => handleSubmitCommentClick(e, reviewFormData, 'Add')}
								showEditor={showEditor}
								setShowEditor={setShowEditor}
							/>
						</Box>
					) : (
						<></>
					)}
					<Box className='reviewers-post-comment-list'>
						{reviewDateDetails?.commentCount ? (
							<Typography variant='h6' className='cmt-count'>
								{`${reviewDateDetails?.commentCount} ${t('commentsLabel')}`}
							</Typography>
						) : (
							<></>
						)}
						{commentListDetails && commentListDetails?.length ? (
							commentListDetails?.map((comment: any, commentIndex: number) => {
								return (
									<Box
										className={`reviewers-post-comment-row ${
											pipImprovementData?.commentId == comment.commentDetailsId ? 'highlight-row' : ''
										}`}
									>
										<Box className='user-info'>
											<Box className='user-img'>
												{comment?.imagePath ? (
													<Avatar className='avatar-default' src={comment.imagePath} />
												) : (
													<Avatar className={'avatar-default'}>
														{getUserName({
															firstName: comment?.firstName || '',
															lastName: comment?.lastName || '',
															fullName: comment?.fullName || '',
														})}
													</Avatar>
												)}
											</Box>
										</Box>
										<Box className='reviewers-post-box'>
											<Box className='post-content-head'>
												<Box className='post-content-head-left'>
													<Typography>{getFullName(comment)}</Typography>
													{comment?.createdOn ? (
														<Typography variant='h6' className='font-weight-normal'>
															{`${getTime(comment?.createdOn)}`}
														</Typography>
													) : (
														<></>
													)}
												</Box>
												<Box className='post-content-head-right'>
													{userDetails?.employeeId === comment?.employeeId &&
													(pipDetails?.performanceImprovementPlanStatusId === Enums.THREE ||
														pipDetails?.performanceImprovementPlanStatusId === Enums.ONE ||
														pipDetails?.performanceImprovementPlanStatusId === Enums.TWO) ? (
														<PopperMenu
															className={'user-menu-btn'}
															anchorEl={anchorEl}
															handleClose={handleClose}
															includeKebabMenu={true}
															menuClicked={popperMenuClicked}
															setPopperMenuClicked={setPopperMenuClicked}
															{...props}
															children={
																<>
																	<ListItem
																		onClick={(e: any) => {
																			handleClose();
																			handleEditCommentClick(e, comment);
																		}}
																	>
																		<ListItemAvatar>
																			<EditIcon />
																		</ListItemAvatar>
																		<ListItemText primary={t('editLabel')} />
																	</ListItem>
																	<ListItem
																		onClick={(e: any) => {
																			handleClose();
																			handleDeleteCommentClick(e, comment);
																		}}
																	>
																		<ListItemAvatar>
																			<DeleteIcon />
																		</ListItemAvatar>
																		<ListItemText primary={t('delete')} />
																	</ListItem>
																</>
															}
														/>
													) : (
														<></>
													)}
												</Box>
											</Box>
											<Box className='post-content long-text'>
												{comment?.isEditMode ? (
													<>
														<Box className='reviewer-post-editor-box'>
															<Box className='editor-pane'>
																<Box className='cfr-control-section helperText-bottom' id='rteImage'>
																	<CkEditor
																		placeholder={t('writeYourComment')}
																		showEmoticons={true}
																		value={comment?.comments || ''}
																		handleEditorChange={(value: string) => {
																			if (comment?.comments !== value) {
																				handleChangeCommentValueOnEdit(value, comment, commentIndex);
																			}
																		}}
																	/>
																	{comment?.commentsError ? (
																		<Typography className='error-field'>{comment?.commentsError}</Typography>
																	) : (
																		<></>
																	)}
																</Box>
															</Box>
															<Box className='reviewer-post-action'>
																<OKRButton
																	disabled={Boolean(loader)}
																	className='btn-link'
																	text={t('cancelBtn')}
																	handleClick={(e) => handleCancelCommentClickOnEdit(e, comment, commentIndex)}
																/>
																<OKRButton
																	disabled={Boolean(loader)}
																	className='btn-primary'
																	text={t('postLabel')}
																	handleClick={(e: any) => handleSubmitCommentClickOnEdit(e, comment, commentIndex)}
																/>
															</Box>
														</Box>
													</>
												) : (
													<Box
														dangerouslySetInnerHTML={{
															__html: comment?.comments || '',
														}}
													></Box>
												)}
											</Box>
										</Box>
									</Box>
								);
							})
						) : (
							<></>
						)}
					</Box>
					{/* No Record content */}
					{!reviewDateDetails?.commentCount &&
					!reviewDateDetails?.commentResponses?.results?.length &&
					(pipDetails?.performanceImprovementPlanStatusId === Enums.FOUR ||
						pipDetails?.performanceImprovementPlanStatusId === Enums.FIVE) ? (
						<Box className='user-no-record'>
							<Typography variant='h2'>{t('pipResolvedText1')}</Typography>
							<Typography>{t('pipResolvedText2')}</Typography>
							<AnimatedIcon
								className=''
								width='230'
								type='image/svg+xml'
								alt={t('globalSearchNoRecord')}
								imagePath={NofoundImg}
							/>
						</Box>
					) : !reviewDateDetails?.commentCount && !reviewDateDetails?.commentResponses?.results?.length ? (
						<Box className='user-no-record'>
							<Typography variant='h2'>{t('noCommentsYet')}</Typography>
							<Typography>{t('firstReviewerComment')}</Typography>
							<AnimatedIcon
								className=''
								width='230'
								type='image/svg+xml'
								alt={t('globalSearchNoRecord')}
								imagePath={NofoundImg}
							/>
						</Box>
					) : (
						<></>
					)}
				</Box>
			</Box>
			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
