import React, { useState, useEffect, Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import BubbleChart from './BubbleChart';
import { getOkrProgressDetails } from '../../../config/utils';
import ChartHeader from './ChartHeader';
import { useTranslation } from 'react-i18next';

export const getStatusAxis = (statusData) => {
	const statusName = { lagging: 'lagging', atRisk: 'atRisk', onTrack: 'onTrack', notStarted: 'notStarted' };
	const repeated = { lagging: 0, atRisk: 0, onTrack: 0, notStarted: 0 };

	statusData.forEach((x) => {
		repeated[x] = repeated[x] + 1;
	});

	const counted = Object.assign({}, repeated);

	return statusData.map((status) => {
		let value = 0;
		if (repeated[status] > 0) {
			if (status === statusName.atRisk) {
				value = (1 / (repeated[status] + 1)) * counted[status] - 0.5;
				counted[status] = counted[status] - 1;
			}
			if (status === statusName.lagging) {
				value = 1 + (1 / (repeated[status] + 1)) * counted[status] - 0.5;
				counted[status] = counted[status] - 1;
			}
			if (status === statusName.onTrack) {
				value = 2 + (1 / (repeated[status] + 1)) * counted[status] - 0.5;
				counted[status] = counted[status] - 1;
			}
			if (status === statusName.notStarted) {
				value = (3 / (repeated[status] + 1)) * counted[status] - 0.5;
				counted[status] = counted[status] - 1;
			}
		}
		return value;
	});
};

const OKRChart = (props) => {
	const { getLeastRiskOkrReport, getLeastRiskKrReport, getUserDetails, okrDetail } = props;
	const { t } = useTranslation();
	const [selectedButton, setSelectedButton] = useState(0);
	const [showDetails, setShowDetails] = useState(false);
	const [details, setDetails] = useState([]);
	const [hoverDetail, setHoverDetail] = useState({});
	const [contributorDetail, setContributorDetail] = useState([]);
	const [responseDetail, setResponseDetail] = useState([]);
	const [showActive, setShowActive] = useState(true);

	useEffect(() => {
		getOkrReportDetail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [okrDetail]);

	const getOkrReportDetail = () => {
		setSelectedButton(0);
		setShowDetails(false);
		if (okrDetail && okrDetail.length > 0) {
			setResponseData(okrDetail);
		}
	};

	const getReportDetail = async (type) => {
		const data = getUserDetails('bubble');
		setShowActive(false);
		setShowDetails(false);
		if (type === 'okr') {
			setSelectedButton(0);
			const response = await getLeastRiskOkrReport(data);
			if (response.data && response.data.entity) {
				setResponseData(response.data.entity);
			}
		} else if (type === 'kr') {
			setSelectedButton(1);
			const response = await getLeastRiskKrReport(data);
			if (response.data && response.data.entity) {
				setResponseData(response.data.entity);
			}
		}
	};

	/* api response data */
	const setResponseData = (data) => {
		if (data && data.length > 0) {
			const filterScore = data.filter((i) => i.score && i.score > 0);
			setResponseDetail(filterScore);
			getDetailOnMouseHover({ objectiveDetails: filterScore[0] });
		}
	};

	/* set details on hovering on bubble */
	const getDetailOnMouseHover = (detail) => {
		if (detail.objectiveDetails && (detail.objectiveDetails.goalObjectiveId || detail.objectiveDetails.goalKeyId)) {
			setHoverDetail(detail.objectiveDetails);
		}
		if (detail.isInside) {
			setShowActive(false);
		}
	};

	const storeDataBeforeHover = (contributor) => {
		setContributorDetail(contributor);
	};

	/* chart data */
	const chartData = getStatusAxis(
		responseDetail.map((data) => {
			return getOkrProgressDetails({ dueDate: data.endDate, value: data.score }).okrProgress;
		})
	).map((axis, index) => ({
		axis,
		score: responseDetail[index].score,
		contributorsCount: responseDetail[index].contributors.length,
		objectiveDetails: responseDetail[index],
		endDate: responseDetail[index].endDate,
	}));
	/* detail */
	const getOKRDetails = (OKRContributors, isContributor) => {
		const axis = getStatusAxis(
			OKRContributors.map((data) => {
				return getOkrProgressDetails({ dueDate: new Date(data.endDate), value: data.score }).okrProgress;
			})
		);
		const detailsData = axis.map((axis, index) => {
			const contributor = OKRContributors[index];
			return {
				axis,
				isContributor: isContributor,
				score: contributor.score,
				contributorsCount: contributor.secondLevelContributors ? contributor.secondLevelContributors.length : 0,
				objectiveDetails: contributor,
				contributorsName: `${contributor.firstName} ${contributor.lastName}`,
				endDate: contributor.endDate,
			};
		});

		setDetails(detailsData);
	};

	return (
		<Fragment>
			<Box className='graph-chart-wrap'>
				<ChartHeader
					selectedButton={selectedButton}
					setShowDetails={setShowDetails}
					showDetails={showDetails}
					objectiveDetails={hoverDetail}
					getReportDetail={getReportDetail}
				/>
				<Box className='graph-chart graph-okr-chart'>
					<BubbleChart
						data={showDetails ? details : chartData}
						showDetails={showDetails}
						setShowDetails={setShowDetails}
						getOKRDetails={getOKRDetails}
						getDetailOnMouseHover={getDetailOnMouseHover}
						storeDataBeforeHover={storeDataBeforeHover}
						contributorDetail={contributorDetail}
						showActive={showActive}
						selectedButton={selectedButton}
					/>
					<div className='help-text'>
						<Typography variant='body2'>{t('InsightsChartDragText')}</Typography>
					</div>
					<div className='okr-label-bg'></div>
				</Box>
			</Box>
		</Fragment>
	);
};

export default OKRChart;
