import { combineReducers } from 'redux';
import commonReducer from '../reducers/common';
import searchReducer from '../reducers/search';
import notificationReducer from '../reducers/notification';
import signReducer from '../reducers/signin';
import okrReducer from '../reducers/myGoal';
import feedbackReducer from '../reducers/feedback';
import organizationReducer from '../reducers/organization';
import rolesReducer from '../reducers/roles';
import usersReducer from '../reducers/users';
import alignReducer from '../reducers/alignment';
import forgotPasswordReducer from '../reducers/forgotPassword';
import resetPasswordReducer from '../reducers/resetPassword';
import ssoLoginReducer from '../reducers/ssoLogin';
import onboardingReducer from '../reducers/onboarding';
import reportsReducer from '../reducers/reports';
import cfrReducer from '../reducers/cfr';
import signalRReducer from '../reducers/signalR';
import adminSettingReducer from './adminSettings';
import performReducer from '../reducers/perform';
import recognizeReducer from '../reducers/recognize';
import oneOnOneRequestReducer from './oneOnOneRequest';
import globalTaskReducer from '../reducers/globalTask';
import strategicObjectiveReducer from './strategicObjective';
import adminDefaultOkrReducer from './adminDefaultOkr';
import userPerformanceReducer from './UserPerformance';
import adminPerformanceDevelopmentReducer from './adminPerformanceDevelopment';
import performanceImprovementPlanReducer from './performanceImprovementPlan';

const mainReducer = combineReducers({
	commonReducer,
	searchReducer,
	notificationReducer,
	signReducer,
	forgotPasswordReducer,
	resetPasswordReducer,
	okrReducer,
	feedbackReducer,
	organizationReducer,
	rolesReducer,
	usersReducer,
	alignReducer,
	ssoLoginReducer,
	onboardingReducer,
	reportsReducer,
	cfrReducer,
	signalRReducer,
	adminSettingReducer,
	performReducer,
	recognizeReducer,
	oneOnOneRequestReducer,
	globalTaskReducer,
	strategicObjectiveReducer,
	adminDefaultOkrReducer,
	userPerformanceReducer,
	performanceImprovementPlanReducer,
	adminPerformanceDevelopmentReducer,
});

const rootReducer = (state, action) => {
	return mainReducer(state, action);
};

export default rootReducer;
