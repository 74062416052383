import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	isImpersonateToastShowed: false,
	listUserPending: false,
	listUsersSuccess: false,
	listUsersResult: [],
	listUsersError: '',
	addUsersSuccess: false,
	addUsersPending: false,
	addUsersError: {},
	addUsersStatus: '',
	editUsersError: {},
	editUsersPending: false,
	editUsersSuccess: false,
	editUsersStatus: '',
	licenseDetailsStatus: '',
	licenseDetails: {},
};

export default function usersReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FETCH_USERS_PENDING:
			return {
				...state,
				listUsersPending: true,
				listUsersSuccess: false,
			};
		case actionTypes.FETCH_USERS_SUCCESS:
			return {
				...state,
				listUsersPending: false,
				listUsersSuccess: true,
				listUsersResult: { records: action.payload.entityList, pagingInfo: action.payload.paggingInfo },
			};
		case actionTypes.FETCH_USERS_FAILED:
			return {
				...state,
				listUsersPending: false,
				listUsersSuccess: false,
				listUsersError: action.error,
			};

		case actionTypes.SEARCH_USERS_PENDING:
			return {
				...state,
				listUsersPending: true,
				listUsersSuccess: false,
			};
		case actionTypes.SEARCH_USERS_SUCCESS:
			return {
				...state,
				listUsersPending: false,
				listUsersSuccess: true,
				listUsersResult: action.payload.entity,
			};
		case actionTypes.SEARCH_USERS_FAILED:
			return {
				...state,
				listUsersPending: false,
				listUsersSuccess: false,
				listUsersError: action.error,
			};
		case actionTypes.ADD_USERS_PENDING:
			return {
				...state,
				addUsersError: {},
				addUsersPending: true,
				addUsersSuccess: false,
				addUsersStatus: 'pending',
			};
		case actionTypes.ADD_USERS_SUCCESS:
			return {
				...state,
				addUsersError: {},
				addUsersPending: false,
				addUsersSuccess: true,
				addUsersStatus: 'success',
			};
		case actionTypes.ADD_USERS_FAILED:
			return {
				...state,
				addUsersError: action.error.messageList ? action.error.messageList : {},
				addUsersPending: false,
				addUsersSuccess: false,
				addUsersStatus: 'error',
			};

		case actionTypes.EDIT_USERS_PENDING:
			return {
				...state,
				editUsersError: {},
				editUsersPending: true,
				editUsersSuccess: false,
				editUsersStatus: 'pending',
			};
		case actionTypes.EDIT_USERS_SUCCESS:
			return {
				...state,
				editUsersError: {},
				editUsersPending: false,
				editUsersSuccess: true,
				editUsersStatus: 'success',
			};
		case actionTypes.EDIT_USERS_FAILED:
			return {
				...state,
				editUsersError: action.error.messageList ? action.error.messageList : {},
				editUsersPending: false,
				editUsersSuccess: false,
				editUsersStatus: 'error',
			};

		case actionTypes.UPDATE_USERS_STATUS: {
			return {
				...state,
				listUserPending: false,
				listUsersSuccess: false,
				listUsersResult: [],
				listUsersError: '',
				addUsersSuccess: false,
				addUsersPending: false,
				addUsersError: {},
				addUsersStatus: '',
				editUsersError: {},
				editUsersPending: false,
				editUsersSuccess: false,
				editUsersStatus: '',
			};
		}
		case actionTypes.FETCH_LICENCE_DETAIL_PENDING: {
			return {
				...state,
				licenseDetailsStatus: 'pending',
				licenseDetails: {},
			};
		}
		case actionTypes.FETCH_LICENCE_DETAIL_SUCCESS: {
			return {
				...state,
				licenseDetailsStatus: 'success',
				licenseDetails: action.payload.entity,
			};
		}
		case actionTypes.FETCH_LICENCE_DETAIL_FAILED: {
			return {
				...state,
				licenseDetailsStatus: 'failed',
				licenseDetails: {},
			};
		}
		case actionTypes.REFETCH_LICENCE_DETAIL: {
			return {
				...state,
				licenseDetailsStatus: '',
				licenseDetails: {},
			};
		}
		case actionTypes.REFETCH_USER_DETAIL: {
			return {
				...state,
				listUserPending: false,
				listUsersSuccess: false,
				listUsersResult: [],
				listUsersError: '',
			};
		}
		case actionTypes.IMPERSONATE_TOAST_STATUS: {
			return {
				...state,
				isImpersonateToastShowed: action.payload,
			};
		}
		default:
			return state;
	}
}
