import React, { useState, useEffect } from 'react';
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	Avatar,
	Slide,
	TextField,
	Typography,
	Tooltip,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { DownloadIcon, SendIcon } from '../../config/svg/CommonSvgIcon';
import ChatBotIcon from '../../images/chatbot_blink.svg';
import ChatBotResponseLoader from '../../images/chatbot-response-loader.svg';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import { CancelIcon } from '../../config/svg/Action';
import '../../styles/components/_chatBot.scss';
import unlockAiChatbotLogo from '../../images/AI-chatbot-logo.svg';

import { getChatBot } from '../../action/common';
import { Enums } from '../../config/enums';
import { useDispatch } from 'react-redux';
import { getUserDetails, getUserName } from '../../config/utils';
import { getLocalStorageItem } from '../../services/StorageService';
import { downloadFile } from '../../action/myGoal';
import { ChatBotHistory } from './ChatBotHistory';
import { DefaultOrigin } from '../../config/constant';

export const ChatBot: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const [openChatBot, setOpenChatBot] = useState<boolean>(false);
	const [chatBot, setChatBot] = useState<any>('');
	const [chatId, setChatId] = useState<any>('');
	const dispatch = useDispatch();
	const [chatData, setChatData] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
	const userDetails = getUserDetails();
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const boxArray: any[] = [
		{
			text: 'What are the steps to create OKRs in Unlock?',
			value: 'What are the steps to create Objectives and Key results in Unlock platform?',
		},
		{
			text: 'Holiday list between Aug to Dec for India employees',
			value: 'Give me the list of holidays from August to Dec 2023, only for Employees in India Shift',
		},
		{
			text: 'Create a sales Pitch for a Healthcare Lead',
			value: 'Sales pitch for a Healthcare Lead ',
		},
	];
	const [historyToAdd, setHistoryToAdd] = useState<any>(null);

	useEffect(() => {
		setChatBot('');
		setChatData([]);
		setChatId('');
	}, [openChatBot]);

	const handleOpenChatBot = () => {
		setOpenChatBot(true);
	};
	const handleCloseChatBot = () => {
		setOpenChatBot(false);
	};
	const defaultQuery = (queryString: string) => {
		chatBotResponse(queryString);
	};
	const chatBotResponse = async (queryString = '') => {
		setSubmitDisabled(true);
		setChatBot('');
		scrollTo('chatbot-scroll');
		let chatString = queryString ? queryString : chatBot;
		if (chatString !== '') {
			let chatDataCopy = chatData.map((element: any) => element);
			chatDataCopy.push({
				prompt: chatString,
				response: '',
				filename: [],
			});
			setChatData(chatDataCopy);
			setLoading(true);
			let data = {
				tenantid: '6lnUyLOcUUylp3sxtddFA',
				subdomain: window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin,
				useremailid: userDetails.emailId,
				prompt: chatString,
				chatid: chatId ? chatId : '',
				conversationid: '',
				promptid: '',
			};
			const response: any = await dispatch(getChatBot(data));
			if (response.data.status_code === 200) {
				setSubmitDisabled(false);
				if (chatDataCopy[chatDataCopy.length - 1]) {
					setChatId(response.data.data.chatid);
					response.data.data.chat.forEach((resp: any) => {
						let copyData: any = { ...resp };
						let responseText = resp.response;
						if (responseText.startsWith('\n')) {
							responseText = responseText.replace(/\n/, '');
						}
						copyData.response = responseText.replace(/\n/g, '<br />');
						chatDataCopy[chatDataCopy.length - 1] = copyData;
					});
					addToHistory(response.data.data, data.prompt);
				}
			} else {
				if (chatDataCopy[chatDataCopy.length - 1]) {
					chatDataCopy[chatDataCopy.length - 1].response = t('chatErrorMessage');
				}
				setSubmitDisabled(false);
			}
			setLoading(false);
			setChatData(chatDataCopy);
		}
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 50 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				//window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 300);
	};

	const handleDownloadChatBotFile = async (fileData: any) => {
		try {
			const response: any = await dispatch(downloadFile(fileData.filepath));
			if (response) {
				const defaultName = fileData.filename;
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const initiateNewChat = () => {
		setChatId('');
		setChatData([]);
		setChatBot('');
	};
	const showHistoryPrompt = async (chatHistoryData: any) => {
		if (chatHistoryData && chatHistoryData.chatData.length > 0) {
			let chatDataCopy: any[] = [];
			chatHistoryData.chatData.forEach((cData: any) => {
				let responseText = cData.response;
				if (responseText.startsWith('\n')) {
					responseText = responseText.replace(/\n/, '');
				}
				responseText = responseText.replace(/\n/g, '<br />');
				chatDataCopy.push({
					...cData,
					response: responseText,
				});
			});
			setChatId(chatHistoryData.ChatId);
			setChatData(chatDataCopy);
		}
	};

	const addToHistory = (resp: any, chatString: string) => {
		setHistoryToAdd({
			...resp,
			ChatId: resp.chatid,
			ChatTitle: chatString,
		});
	};
	return (
		<>
			{tenantData?.isChatBotAllowed && (
				<>
					{!openChatBot && (
						<>
							<Box className='chat-bot-btn-box'>
								<span className='chat-bot-btn-layer' onClick={handleOpenChatBot}></span>
								<OKRButton
									className='chat-bot-btn'
									title='Open ChatBot'
									icon={
										<AnimatedIcon
											className='chat-bot-img'
											width='30'
											type='image/svg+xml'
											alt='No user'
											imagePath={ChatBotIcon}
										/>
									}
								/>
								<Box className='chat-bot-help-txt-box'>
									<Typography variant='h6'>
										{t('helpText')}
										<span className='blinking-cursor'></span>
									</Typography>
								</Box>
							</Box>
						</>
					)}
					<Dialog
						className='chat-bot-modal'
						open={openChatBot}
						onClose={handleCloseChatBot}
						aria-labelledby='chatbot-dialog'
						TransitionComponent={Slide}
						transitionDuration={{ enter: 500, exit: 800 }}
					>
						<DialogContent>
							<Box className='chat-bot-modal-content'>
								<Box className='chat-bot-modal-content-left'>
									<Box className='chat-bot-response-area'>
										{chatData.length === 0 ? (
											<Box className='welcome-content'>
												<Box className='welcome-content-top'>
													<AnimatedIcon
														className='chat-bot-img'
														width='170'
														type='image/svg+xml'
														alt='No user'
														imagePath={unlockAiChatbotLogo}
													/>
													<Typography variant='h2' component='h2'>
														{t(
															'Hello ' + userDetails.firstName + ' I am Ubot, the assistant. How can I help you today?'
														)}
													</Typography>
												</Box>
												<Box className='welcome-content-bottom'>
													{boxArray.map((data) => {
														return (
															<Box
																className='hint-box'
																onClick={(e) => {
																	defaultQuery(data.value);
																}}
															>
																<Typography>{data.text}</Typography>
															</Box>
														);
													})}
												</Box>
											</Box>
										) : (
											chatData.map((chat: any) => {
												return (
													<>
														<Box className='asked-query-info'>
															<Box className='asked-query-content'>{chat.prompt}</Box>
															<Box className='asked-user-avatar'>
																{userDetails &&
																	(userDetails.imagePath || userDetails.image ? (
																		<Avatar
																			className='contributor-owner'
																			key={`user-icon-${userDetails.employeeId}`}
																			alt={`${userDetails.firstName} ${userDetails.lastName}`}
																			src={userDetails.imagePath || userDetails.image || ''}
																		/>
																	) : (
																		<Avatar className='contributor-owner' key={`user-icon-${userDetails.employeeId}`}>
																			{getUserName({
																				firstName: userDetails.firstName || '',
																				lastName: userDetails.lastName || '',
																				fullName: userDetails.fullName || '',
																			})}
																		</Avatar>
																	))}
															</Box>
														</Box>
														<Box className='generated-response-box'>
															<AnimatedIcon
																className='chat-bot-img'
																width='21'
																type='image/svg+xml'
																alt='No user'
																imagePath={ChatBotIcon}
															/>
															{chat.response ? (
																<Box className='generated-response-box-inner'>
																	<Box
																		className='generated-response-content'
																		dangerouslySetInnerHTML={{ __html: chat.response }}
																	></Box>
																	{chat.filename && chat.filename.length > 0 && (
																		<Box className='generated-response-reference'>
																			<Typography variant='h6'>References ({chat.filename.length})</Typography>
																			<List component='ul' className='reference-list' disablePadding>
																				{chat.filename.map((fileData: any, index: number) => {
																					return (
																						// <ListItem>
																						// 	<ListItemText>
																						// 		<span className='ref-num'>{index + 1}</span> {fileData.filename}
																						// 	</ListItemText>
																						// 	<ListItemIcon
																						// 		onClick={() => {
																						// 			handleDownloadChatBotFile(fileData);
																						// 		}}
																						// 	>
																						// 		<DownloadIcon />
																						// 	</ListItemIcon>
																						// </ListItem>
																						<ListItem>
																							<Link
																								className='download-reference'
																								id={'download-temp'}
																								target='_blank'
																								rel='noopener noreferrer'
																								href={fileData.filepath}
																							>
																								<ListItemText>
																									<span className='ref-num'>{index + 1}</span>{' '}
																									<Tooltip arrow title={`${fileData.filename}`}>
																										<span className='ref-file-name'>{fileData.filename}</span>
																									</Tooltip>
																								</ListItemText>
																								<ListItemIcon
																								// onClick={() => {
																								// 	handleDownloadChatBotFile(fileData);
																								// }}
																								>
																									<DownloadIcon />
																								</ListItemIcon>
																							</Link>
																						</ListItem>
																					);
																				})}
																			</List>
																		</Box>
																	)}
																</Box>
															) : loading ? (
																<Box className='response-loader-img'>
																	<AnimatedIcon
																		className='chat-bot-response-loader'
																		width='50'
																		type='image/svg+xml'
																		alt='Loading Response'
																		imagePath={ChatBotResponseLoader}
																	/>
																</Box>
															) : (
																''
															)}
														</Box>
													</>
												);
											})
										)}
										<div id='chatbot-scroll'></div>
									</Box>
									<Box className='chat-bot-query-sec'>
										<TextField
											className='user-chat-field'
											id='user-chat-field'
											placeholder={t('tellMeWhatYouWant')}
											value={chatBot}
											fullWidth
											inputProps={{
												'aria-label': 'Chat',
											}}
											onChange={(e) => {
												setChatBot(e.target.value);
											}}
											onKeyDown={(e: any) => {
												if (e.key === 'Enter' && !submitDisabled) {
													chatBotResponse();
												}
											}}
										/>
										<OKRButton
											className={`submit-btn ${submitDisabled ? 'disabled' : ''}`}
											handleClick={() => {
												if (!submitDisabled) {
													chatBotResponse();
												}
											}}
											icon={<SendIcon />}
											title={submitDisabled ? t('chatButtonDisabled') : 'Submit'}
										/>
									</Box>
								</Box>
								<Box className='chat-bot-modal-content-right'>
									{
										<ChatBotHistory
											{...props}
											showHistoryPrompt={showHistoryPrompt}
											historyToAdd={historyToAdd}
											setHistoryToAdd={setHistoryToAdd}
											initiateNewChat={initiateNewChat}
										/>
									}
								</Box>
							</Box>
						</DialogContent>
						<DialogActions>
							<OKRButton handleClick={handleCloseChatBot} title='Close' icon={<CancelIcon />} />
						</DialogActions>
					</Dialog>{' '}
				</>
			)}
		</>
	);
};

export default ChatBot;
