import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { MainDrawer } from '../../../CommonComponent/MainDrawer';
import '../../../../../styles/pages/admin/settings/default-okr/copyDefaultOkrDrawer.scss';
import { ImportAdminOkr } from './ImportAdminOkr';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { uploadDefaultOkrReport } from '../../../../../action/adminDefaultOkr';

export const ImportOkrMainDrawer: React.FC<any> = (props) => {
	const { open, setOpenImportOkr } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const [errorLogMessage, setErrorLogMessage] = useState([]);
	const [uploadFileData, setUploadFileData] = useState<any>({ fileName: '' });

	const [mainTabSelected, setMainTabSelected] = useState<any>(0);
	const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		setMainTabSelected(newValue);
	};

	const uploadFileHandler = async () => {
		if (uploadFileData.filePath) {
			const formData = new FormData();
			formData.append('formFile', uploadFileData.filePath);
			const response: any = await dispatch(uploadDefaultOkrReport(formData));
			if (Boolean(response) && response.data && response.data) {
				setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				return response.data;
			}
		}
	};
	const handleDrawerCloseClick = async (event: any, type: any) => {
		if (type === 'save') {
			if (uploadFileData.fileName && uploadFileData.fileUploaded) {
				try {
					let fileUploadDetails: any = {};
					const formData = new FormData();
					formData.append('formFile', uploadFileData.filePath);
					const response: any = await dispatch(uploadDefaultOkrReport(formData));
					if (Boolean(response) && response.data && response.data) {
						setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
						fileUploadDetails = response.data
					}
					const responseAPI = fileUploadDetails?.messageList;
					const keys = Object.keys(responseAPI);
					const messages = keys.map((item) => responseAPI[item]);
					if (fileUploadDetails.status === 200) {
						setOpenImportOkr(false);
						enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
					} else {
						enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
					}
					if (
						fileUploadDetails.entityList &&
						fileUploadDetails.entityList.length > 0
					) {
						const errorDetail = fileUploadDetails.entityList;
						setErrorLogMessage(errorDetail);
					}
				} catch (err) {
					enqueueSnackbar(t('errorUploadingFile'), { variant: 'error', autoHideDuration: 5000 });
				}
			} else {
				enqueueSnackbar(`${t('UsersBulkUpload')}`, { variant: 'error', autoHideDuration: 5000 });
			}
		} else {
			setOpenImportOkr(false);
		}
	};

	return (
		<Fragment>
			<MainDrawer
				open={open}
				drawerClassName={'main-drawer-panel'}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('defaultOkrs')}
				children={
					<Box className='drawer-inner-content'>
						<ImportAdminOkr
							mainTabSelected={mainTabSelected}
							handleTabChange={handleTabChange}
							uploadFileData={uploadFileData}
							setUploadFileData={setUploadFileData}
							errorLogMessage={errorLogMessage}
							setErrorLogMessage={setErrorLogMessage}
							{...props}
						/>
					</Box>
				}
				saveButtonText={t('importBtnLevel')}
				handleDrawerClose={(event: any) => handleDrawerCloseClick(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerCloseClick(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerCloseClick(event, 'close')}
				isFooterVisible={true}
				isSaveButtonVisible={true}
				isSaveButtonDisabled={uploadFileData.fileName === ''}
			/>
		</Fragment>
	);
};
