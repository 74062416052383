import React, { useState } from 'react';
import { Box, Typography, Collapse } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

import { StarBorderIcon, StarFilledIcon } from '../../../config/svg/RatingSvg';
import { CommentIcon, SelfReviewIcon } from '../../../config/svg/CommonSvg';
import { OKRButton } from '../../Common/OKRButton';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { goalRemovePlugin } from '../../Common/CkEditor/CkEditorEnums';
import { ErrorIcon } from '../../../config/svg/formElementIcons';

const labels: { [index: number]: string } = {
	1: 'Not Meeting Expectations',
	2: 'Successful',
	3: 'Often Exceeding Expectations',
	4: 'Consistently Exceeding Expectations',
};

const labelslist: any[] = [
	{
		id: 1,
		value: 1,
		name: 'Not Meeting Expectations',
	},
	{
		id: 2,
		value: 2,
		name: 'Successful',
	},
	{
		id: 3,
		value: 3,
		name: 'Often Exceeding Expectations',
	},
	{
		id: 4,
		value: 4,
		name: 'Consistently Exceeding Expectations',
	},
];

export const StarRating = (props: any) => {
	const {
		question,
		section,
		handleChangeStarQuestionValue,
		handleChangeStarQuestionTextValue,
		questionIndex,
		sectionIndex,
		handleChangeStarQuestionHoverValue,
		getLabelText,
		t,
		performanceGoalsList,
	} = props;
	const [hover, setHover] = useState<number>(-1);
	const [openComments, setOpenComments] = useState<boolean>(question.openComments || false);

	const getDetailsBasedOnKey = (keyName: string) => {
		return question[keyName];
	};

	const handleCommentsToggle = () => {
		setOpenComments((prev) => !prev);
	};
	return (
		<Box className='goal-self-review-area'>
			<Box className='goal-self-review-title'>
				<SelfReviewIcon />
				<Typography>{t('selfReviewLevel')}</Typography>
			</Box>
			<Box className={`goal-review-rating-box ${question.isRatingError ? 'goal-review-rating-box-error' : ''}`}>
				{Object.entries(labels).map(([value, label]) => (
					<Box key={value} textAlign='center' className='goal-review-star-rating'>
						<Rating
							name={`${
								question?.name ? `${question?.performanceGoalSetDetailId}_${question?.name.replaceAll(' ', '_')}` : ''
							}_${value}_${getDetailsBasedOnKey('performanceGoalSetId')}`}
							//value={question.score && question.score >= Number(value) ? 1 : 0}
							value={(hover !== -1 ? hover : question.score) >= Number(value) ? 1 : 0}
							max={1}
							onChange={(event, newValue) => {
								const newRating = question.score === Number(value) ? 0 : Number(value);
								handleChangeStarQuestionValue(event, newRating, question, questionIndex, section, sectionIndex);
								setOpenComments(true);
								//handleChangeStarQuestionValue(event, Number(value), question, questionIndex, section, sectionIndex);
							}}
							onMouseEnter={() => setHover(Number(value))}
							onMouseLeave={() => setHover(-1)}
							//onChangeActive={(event, newHover) => {
							/*handleChangeStarQuestionHoverValue(
									event,
									newHover,
									question,
									questionIndex,
									section,
									sectionIndex
								);*/
							//}}
							icon={<StarFilledIcon />}
							emptyIcon={<StarBorderIcon />}
							disabled={!performanceGoalsList?.isEditable}
						/>
						<Box className='goal-rating-level'>
							<Typography>{label}</Typography>
						</Box>
					</Box>
				))}
				{question.isRatingError ? (
					<Box className='error-field'>
						<ErrorIcon />
						<Typography variant='body2'>{t('Rating is required')}</Typography>
					</Box>
				) : (
					''
				)}
			</Box>
			<Box className={`goal-review-comment-box ${question.isCommentError ? 'goal-review-comment-box-error' : ''}`}>
				{question.isCommentError && (
					<Box className='error-field'>
						<ErrorIcon />
						<Typography variant='body2'>{t('Comment is required')}</Typography>
					</Box>
				)}
				<OKRButton
					id='comment-btn'
					className='btn-small-text goal-review-comments-btn'
					icon={<CommentIcon />}
					text={
						<>
							{t('Comments')} {question?.isCommentBoxMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
						</>
					}
					handleClick={(e: any) => {
						handleCommentsToggle();
					}}
				/>
				<Collapse in={openComments} className='goal-review-comments-field'>
					<Box className='goal-review-comments-editor-panel editior-pane preview-overlay'>
						{!performanceGoalsList?.isEditable ? (
							<Box
								className='long-text'
								dangerouslySetInnerHTML={{ __html: question.comment ? question.comment : '--' }}
							></Box>
						) : (
							<CkEditor
								placeholder={t('Write your comments')}
								showEmoticons={true}
								value={question.comment}
								handleEditorChange={(value: string) => {
									handleChangeStarQuestionTextValue({}, value, question, questionIndex, section, sectionIndex);
								}}
								enableTagging={false}
								uploadImage={false}
								removedPlugins={goalRemovePlugin}
								removedPluginsList={[...goalRemovePlugin, 'insertTable']}
							/>
						)}
					</Box>
				</Collapse>
			</Box>
		</Box>
	);
};
