import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	entity: {},
	entityList: [],
	ssoLoginStatus: '',
	pending: false,
	messageList: [],
	error: {},
};

export default function ssoLoginReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.SSO_LOGIN_PENDING:
			return {
				...state,
				pending: true,
				messageList: [],
			};
		case actionTypes.SSO_LOGIN_SUCCESS:
			return {
				...state,
				pending: false,
				entity: action.payload.entity,
				ssoLoginStatus: action.payload.status,
				messageList: action.payload.messageList,
			};
		case actionTypes.SSO_LOGIN_FAILED:
			return {
				...state,
				pending: false,
				error: action.error,
			};
		default:
			return state;
	}
}
