import React from 'react';
import { Avatar, Box, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../../config/utils';
import { KrsIcon, ObjIcon, OrgIcon } from '../../../config/svg/CommonSvgIcon';
import { Enums } from '../../../config/enums';
import { IndividualIcon } from '../../../config/svg/CommonSvg';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export const ReportsGaugeChart = (props) => {
	const {
		isOrgReport = false,
		loginUser = {},
		checkedTeam = [],
		overAllProgress = {},
		colors = [],
		labels = [],
		isTeamReport = false,
		teamOkrProgress,
		radialCount = Enums.THREE,
		// =========
		progress,
		memberCount,
		okrCount,
		krCount,
		opacity,
		chartHight,
		paneBackgroundColor,
		isEngagement = false,
	} = props;

	const { myOrgMemberProgress, myTeamMemberProgress, myProgress } = overAllProgress || {};
	const backGroundColorCode = checkedTeam && checkedTeam.length > 0 ? checkedTeam[0]?.backGroundColorCode : '';
	const { t } = useTranslation();
	const paneBackground =
		radialCount === Enums.TWO
			? [
					{
						outerRadius: '76%',
						innerRadius: '60%',
						backgroundColor: paneBackgroundColor,
						borderWidth: 0,
					},
					{
						outerRadius: '56%',
						innerRadius: '40%',
						backgroundColor: paneBackgroundColor,
						borderWidth: 0,
					},
			  ]
			: radialCount === Enums.ONE
			? [
					{
						outerRadius: '96%',
						innerRadius: '70%',
						backgroundColor: paneBackgroundColor,
						opacity: opacity,
						borderWidth: 0,
					},
			  ]
			: [
					{
						outerRadius: '96%',
						innerRadius: '80%',
						backgroundColor: paneBackgroundColor,
						opacity: opacity,
						borderWidth: 0,
					},
					{
						outerRadius: '76%',
						innerRadius: '60%',
						backgroundColor: paneBackgroundColor,
						opacity: opacity,
						borderWidth: 0,
					},
					{
						outerRadius: '56%',
						innerRadius: '40%',
						backgroundColor: paneBackgroundColor,
						opacity: opacity,
						borderWidth: 0,
					},
			  ];
	const chartSeries =
		radialCount === Enums.TWO
			? [
					{
						name: labels[1], // My Team
						custom: 1,
						data: [
							{
								color: !isTeamReport ? backGroundColorCode || colors[1] : colors[1],
								radius: '76%',
								innerRadius: '60%',
								y: myTeamMemberProgress || 0,
								xLabel: labels[1],
							},
						],
					},
					{
						name: labels[0], // Me
						custom: 0,
						data: [
							{
								color: isTeamReport ? backGroundColorCode || colors[0] : colors[0],
								radius: '56%',
								innerRadius: '40%',
								y: myProgress || 0,
								xLabel: labels[0],
							},
						],
					},
			  ]
			: radialCount === Enums.ONE
			? [
					{
						name: labels[0], // Me
						custom: 0,
						data: [
							{
								color: colors[0],
								radius: '96%',
								innerRadius: '70%',
								y: progress || 0,
								xLabel: labels[0],
							},
						],
					},
			  ]
			: [
					{
						name: labels[2], // Org Members
						custom: 2,
						data: [
							{
								color: colors[2],
								radius: '96%',
								innerRadius: '80%',
								y: myOrgMemberProgress || 0,
								xLabel: labels[2],
							},
						],
					},
					{
						name: labels[1], // My Team
						custom: 1,
						data: [
							{
								color: !isTeamReport ? backGroundColorCode || colors[1] : colors[1],
								radius: '76%',
								innerRadius: '60%',
								y: myTeamMemberProgress || 0,
								xLabel: labels[1],
							},
						],
					},
					{
						name: labels[0], // Me
						custom: 0,
						data: [
							{
								color: isTeamReport ? backGroundColorCode || colors[0] : colors[0],
								radius: '56%',
								innerRadius: '40%',
								y: myProgress || 0,
								xLabel: labels[0],
							},
						],
					},
			  ];

	const columnWithGroupedChartData = {
		chart: {
			type: 'solidgauge',
			height: chartHight || '100%',
			backgroundColor: 'transparent',
			spacingTop: '0',
			spacingBottom: '0',
		},
		credits: {
			enabled: false,
		},
		title: null,
		tooltip: {
			borderWidth: 0,
			shadow: false,
			useHTML: true,
			backgroundColor: '#292929',
			borderColor: 'none',
			padding: 0,
			style: {
				color: '#fff',
				fontWeight: 'bold',
				fontFamily: 'Lato',
				fontSize: '12px',
				lineHeight: '15px',
				zIndex: '1',
			},
			// valueSuffix: '%',
			pointFormat: '<span>{point.xLabel}</span>:&nbsp;<span>{point.y} %</span>', //  color: {point.color};
			positioner: function (labelWidth, labelHeight, point) {
				const { name, options } = this?.chart?.hoverPoint?.series || {};
				const { custom } = options || {};
				const { r } = this?.chart?.hoverPoint?.shapeArgs || {};
				// console.log(name, custom, r, options);
				// console.log(this.chart);
				let pos = {
					x: (this.chart.chartWidth - labelWidth) / 2,
					y: this.chart.plotHeight / 2 + 15,
				};

				if (custom === 2) {
					//if (name === 'Org. Members' || name === 'Most Progressed Team') {
					pos = {
						x: r ? Math.round(r + 30) : pos.x,
						y: -30,
					};
				} else if (custom === 1) {
					// (name === 'My Team(s) Members' || name === 'All Teams in Org.') {
					pos = {
						x: r ? Math.round(r + 55) : pos.x,
						y: Math.round(labelWidth / 2 - 80),
					};
				} else if (custom === 0) {
					// (name === 'Me' || name === 'My Team(s)' || name === 'Performance') {
					if (name === 'Performance') {
						pos = {
							x: r ? Math.round(r + 19) : pos.x,
							y: r ? Math.round(r - 100) : pos.y,
						};
					} else if (name === 'My Progress') {
						pos = {
							x: r ? Math.round(r - 19) : pos.x,
							y: r ? Math.round(r - 99) : pos.y,
						};
					} else {
						pos = {
							x: r ? Math.round(r + 80) : pos.x,
							y: r ? Math.round(r - 55) : pos.y,
						};
					}
				}
				// console.log('labelWidth ===> ', pos);
				return pos;
			},
		},
		pane: {
			startAngle: 0,
			endAngle: 360,
			size: '102%',
			background: paneBackground && paneBackground.length ? [...paneBackground] : [],
		},
		yAxis: {
			min: 0,
			max: 100,
			lineWidth: 0,
			tickPositions: [],
		},
		plotOptions: {
			solidgauge: {
				dataLabels: {
					enabled: false,
					inside: false,
				},
				linecap: 'round',
				stickyTracking: false,
				rounded: true,
			},
		},
		legend: {
			enabled: false,
		},
		series: chartSeries && chartSeries.length ? [...chartSeries] : [],
	};

	return (
		<>
			<Box className={`radial-chart radial-high-chart ${isOrgReport ? 'org-report' : ''}`}>
				<Box className='radial-chart-area'>
					{!isOrgReport ? (
						<Box className='radial-chart-ct-image'>
							{!teamOkrProgress ? (
								<Avatar className={'avatar-type1'} src={loginUser.imagePath || ''}>
									{getUserName({
										firstName: '',
										lastName: '',
										fullName: `${loginUser.firstName} ${loginUser.lastName}`,
									})}
								</Avatar>
							) : checkedTeam.length && checkedTeam[0].teamId ? (
								<>
									{checkedTeam[0].teamLogo ? (
										<Avatar className={'avatar-type1'} src={checkedTeam[0].teamLogo}></Avatar>
									) : (
										<Avatar
											className='avatar-type1'
											style={{
												backgroundColor: getRandomBackgroundColor(checkedTeam[0].backGroundColorCode),
												color: getRandomColor(checkedTeam[0].colorCode),
											}}
										>
											{getUserName({ fullName: checkedTeam[0].teamName, firstName: '', lastName: '' })}
										</Avatar>
									)}
								</>
							) : (
								<OrgIcon />
							)}
						</Box>
					) : (
						<Box className='radial-chart-ct-image'>{progress}%</Box>
					)}
					<HighchartsReact highcharts={Highcharts} options={columnWithGroupedChartData} />
				</Box>
				{!isOrgReport ? (
					<Box className={`radial-legend ${teamOkrProgress ? 'radial-legend-team' : ''}`}>
						<List component='ul' disablePadding>
							{isTeamReport ? (
								<>
									<ListItem>
										<Typography
											className='legend-color'
											style={{
												background: backGroundColorCode || colors[0],
											}}
										></Typography>
										<Typography>{overAllProgress.myProgress}%</Typography>
										<Typography variant='body2'>{labels[0]}</Typography>
									</ListItem>
									<ListItem>
										<Typography
											className='legend-color'
											style={{
												background: colors[1],
											}}
										></Typography>
										<Typography>{overAllProgress.myTeamMemberProgress}%</Typography>
										<Typography variant='body2'>{labels[1]}</Typography>
									</ListItem>
									<ListItem>
										<Typography className='legend-color' style={{ background: colors[2] }}></Typography>
										<Typography>{overAllProgress.myOrgMemberProgress}%</Typography>
										<Typography variant='body2'>{labels[2]}</Typography>
									</ListItem>
								</>
							) : (
								<>
									<ListItem>
										<Typography
											className='legend-color'
											style={{
												background: colors[0],
											}}
										></Typography>
										<Typography>{overAllProgress.myProgress}%</Typography>
										<Typography variant='body2'>{labels[0]}</Typography>
									</ListItem>
									<ListItem>
										<Typography
											className='legend-color'
											style={{
												background: !isTeamReport ? backGroundColorCode || colors[1] : colors[1],
											}}
										></Typography>
										<Typography>{overAllProgress.myTeamMemberProgress}%</Typography>
										<Typography variant='body2'>{labels[1]}</Typography>
									</ListItem>
									<ListItem>
										<Typography className='legend-color' style={{ background: colors[2] }}></Typography>
										<Typography>{overAllProgress.myOrgMemberProgress}%</Typography>
										<Typography variant='body2'>{labels[2]}</Typography>
									</ListItem>
								</>
							)}
						</List>
					</Box>
				) : (
					<Box className='org-okr-count'>
						<List className='' disablePadding>
							<ListItem className='okr-count'>
								<ObjIcon />
								<Box className='okr-info'>
									<Typography variant='body2'>
										{okrCount} {t('objectives')}
									</Typography>
								</Box>
							</ListItem>
							<ListItem className='kr-count'>
								<KrsIcon />
								<Box className='okr-info'>
									<Typography variant='body2'>
										{krCount} {t('keyResults')}
									</Typography>
								</Box>
							</ListItem>
							<ListItem className='member-count'>
								<IndividualIcon />
								<Box className='okr-info'>
									<Typography variant='body2'>{memberCount}</Typography>
								</Box>
							</ListItem>
						</List>
					</Box>
				)}
			</Box>
		</>
	);
};
