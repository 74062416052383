import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ClickAwayListener, FormLabel, List, ListItem, TextField, Typography } from '@material-ui/core';
import {
	BottomArrowIcon,
	CalendarIcon,
	DocumentIcon,
	NameRenameIcon,
	ReasonIcon,
} from '../../../../config/svg/CommonSvgIcon';
import { UploadDocuments } from '../../Common/UploadDocuments';
import { MultiSelect } from '../../../Admin/CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../../Admin/CommonComponent/NoSearchRecord';
import { useDispatch, useSelector } from 'react-redux';
import { checkPipNomineeExist, getPIPNomineeSearch } from '../../../../action/performanceImprovementPlan';
import { Enums } from '../../../../config/enums';
import { getRemainingCharacter, getUserDetails } from '../../../../config/utils';
import ReactDateRangePicker from '../../../Common/ReactDateRangePicker';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';
import { LinkToFeedbackDropdown } from '../../Common/LinkToFeedbackDropdown';
import { ReviewDates } from './ReviewDates';
import { OKRButton } from '../../../Common/OKRButton';
import AlertDialog from '../../../Common/Dialog';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { getOkrMasterData } from '../../../../action/myGoal';
import { DueDateIcon } from '../../../../config/svg/CommonSvg';
import { getSearchDefaultUser } from '../../../../action/personalFeedback';

export const PipDetails = (props: any) => {
	const {
		pipFormDetails,
		setPIPFormDetails,
		updatePipFormDetails,
		pipFormType,
		selectedDateRange,
		setSelectedDateRange,
		isExtendTimeFrameClicked,
		scrollTo,
		loader,
		setLoader,
		timeFrameChangeReasonTypes,
		checkPipNomineeExistApiCall,
		getNomineeFeedbackList,
		selectedNomineeFeedbackList,
		showMsg,
		setIsFormEdited,
		isFormCleared,
		setIsFormCleared,
		checkEmployeePIPCreatedOrNot,
	} = props;

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userDetail = getUserDetails();
	const moment = extendMoment(Moment);

	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
	});
	const [openReasonDropdown, setOpenReasonDropdown] = useState<Boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', details: null, message: '' });
	const [modalFeedbackProps, setModalFeedbackProps] = useState<any>({
		open: false,
		details: null,
		type: '',
		message: '',
		isCancel: false,
	});
	const [allReviewersList, setAllReviewersList] = useState<any[]>([]);
	const [feedbackReviewersList, setFeedbackReviewersList] = useState<any>([]);

	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const minDateValue = pipFormDetails?.timeframeStartDate ? new Date(pipFormDetails?.timeframeStartDate) : new Date(); //set minDateValue based on admin condition
	minDateValue.setMonth(
		minDateValue.getMonth() -
			(okrMasterData && okrMasterData?.performanceImprovementPlanPermission?.isPastDateAllowedForPIP ? 120 : 0)
	);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 120);

	const isPIPTitlePrefilledDisabled =
		okrMasterData &&
		okrMasterData?.performanceImprovementPlanPermission?.isPIPTitlePrefilled &&
		!okrMasterData?.performanceImprovementPlanPermission?.isPIPTitlePrefilledEditable;

	useEffect(() => {
		dispatch(getOkrMasterData());
		//prefilled title
		const pipTitlePrefilledText =
			okrMasterData && okrMasterData?.performanceImprovementPlanPermission?.isPIPTitlePrefilled
				? okrMasterData?.performanceImprovementPlanPermission?.pipTitlePrefilledText
				: '';

		setPIPFormDetails({
			...pipFormDetails,
			title: pipFormDetails?.title || pipTitlePrefilledText,
		});
	}, []);

	useEffect(() => {
		if (pipFormType === 'Edit' && pipFormDetails?.performanceImprovementPlanStatusId === Enums.ONE) {
			checkEmployeePIPCreatedOrNot(pipFormDetails?.nomineeId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipFormType]);

	useEffect(() => {
		if (isExtendTimeFrameClicked) {
			scrollTo('pip_title_container');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isExtendTimeFrameClicked]);

	useEffect(() => {
		if (
			pipFormDetails?.performanceImprovementPlanReviewDates &&
			pipFormDetails?.performanceImprovementPlanReviewDates?.length
		) {
			let allIds: any = [];
			pipFormDetails?.performanceImprovementPlanReviewDates?.forEach((item: any) => {
				if (item?.reviewerIds?.length > 0) {
					allIds = new Set([...allIds, ...item?.reviewerIds]);
				}
			});
			setAllReviewersList([...allIds]);
		}
	}, [pipFormDetails]);

	const handleReasonDropdown = () => {
		setOpenReasonDropdown(true);
	};
	const handleReasonClickAway = () => {
		setOpenReasonDropdown(false);
	};
	const getSelectedReasonLabelName = () => {
		const selectedItem =
			pipFormDetails?.extendTimeframes && pipFormDetails?.extendTimeframes?.length
				? pipFormDetails?.extendTimeframes.find(
						(item: any) => item?.performanceImprovementPlanTimeframeId === Enums.ZERO
				  )
				: null;
		return selectedItem?.performanceImprovementPlanReasonId || Enums.ONE;
	};
	const handleReasonSelect = (reasonType: any) => {
		const extendedTimeFrameData = {
			isTimeFrameChanged: true,
			extendTimeframes:
				pipFormDetails?.extendTimeframes && pipFormDetails?.extendTimeframes?.length
					? pipFormDetails?.extendTimeframes?.map((rec: any) => {
							const item = { ...rec };
							if (item?.performanceImprovementPlanTimeframeId === Enums.ZERO) {
								item.performanceImprovementPlanReasonId = reasonType?.performanceImprovementPlanReasonId;
							}
							return item;
					  })
					: [],
		};
		handleReasonClickAway();
		updatePipFormDetails('timeFrameDates', {
			...extendedTimeFrameData,
			timeframeStartDate: pipFormDetails?.timeframeStartDate,
			timeframeEndDate: pipFormDetails?.timeframeEndDate,
		});
	};
	const handleTimeFrameUndoClick = () => {
		const historyTimeframeStartDate = pipFormDetails?.historyTimeframeStartDate || null;
		const historyTimeframeEndDate = pipFormDetails?.historyTimeframeEndDate || null;
		const extendedTimeFrameData = {
			isTimeFrameChanged: false,
			extendTimeframes:
				pipFormDetails?.historyExtendTimeframe && pipFormDetails?.historyExtendTimeframe?.length
					? pipFormDetails?.historyExtendTimeframe
					: [],
		};
		if (historyTimeframeStartDate && historyTimeframeEndDate) {
			const range = moment().range(new Date(historyTimeframeStartDate), new Date(historyTimeframeEndDate));
			setSelectedDateRange(range);
		}
		updatePipFormDetails('timeFrameDates', {
			...extendedTimeFrameData,
			timeframeStartDate: historyTimeframeStartDate,
			timeframeEndDate: historyTimeframeEndDate,
		});
	};
	const searchReportingTo = async (
		value: string,
		// finder: any,
		// pageIndex: any,
		// pageSize: any,
		allReviewersListValue: any,
		allowBlankSearch = false
	) => {
		if (value.length >= 3 || allowBlankSearch) {
			const requestParam = `finder=${value}&pageIndex=1&pageSize=${Enums.MAX_USER_SIZE}`;
			const resp: any = await dispatch(getPIPNomineeSearch(requestParam));
			if (resp && resp?.data && resp.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = resp?.data || {};
				// console.log(allReviewersListValue);
				let list = entityList && entityList?.length ? [...entityList] : [];
				list =
					list &&
					list.filter(
						(item: any) => item.employeeId !== userDetail.employeeId && !allReviewersListValue.includes(item.employeeId)
					);
				if (list && list.length > 0) {
					setReportingToOptions(
						list.map((userData: any) => {
							return {
								...userData,
								label: userData.firstName + ' ' + userData.lastName,
								value: userData.employeeId,
								secondaryText: userData.designation,
							};
						})
					);
				} else {
					setReportingToOptions([]);
					setEmptyRecords({ ...emptyRecords, reportingTo: true });
				}
			} else {
				setEmptyRecords({ ...emptyRecords, reportingTo: true });
			}
		}
	};
	const handleDateOverlapCheck = async (range: any, start: any, end: any) => {
		if (range && start && end) {
			setSelectedDateRange(range);
			let extendedTimeFrameData = {};
			if (pipFormType !== 'Add' && pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE) {
				const newTimeFrameChange = {
					performanceImprovementPlanTimeframeId: Enums.ZERO,
					extendTimeframeStartDate: start,
					extendTimeframeEndDate: end,
					performanceImprovementPlanReasonId: Enums.ONE,
				};
				extendedTimeFrameData = {
					isTimeFrameChanged: true,
					extendTimeframes:
						pipFormDetails?.historyExtendTimeframe && pipFormDetails?.historyExtendTimeframe?.length
							? [...pipFormDetails?.historyExtendTimeframe, { ...newTimeFrameChange }]
							: [{ ...newTimeFrameChange }],
				};
			}
			updatePipFormDetails('timeFrameDates', {
				...extendedTimeFrameData,
				timeframeStartDate: start,
				timeframeEndDate: end,
			});
		}
	};
	const handleCloseFeedbackModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			// if (modalFeedbackProps?.type === 'NomineeFeedbackList') {
			// 	const { empId } = modalFeedbackProps?.details || {};
			// 	const employeeId = empId;
			// 	setModalFeedbackProps({ open: false, type: '', message: '', details: null, isCancel: false });
			// 	await getNomineeFeedbackList(employeeId);
			// 	updatePipFormDetails('feedbackIds', []);
			// }
		} else if (type === 2) {
			if (modalFeedbackProps?.type === 'NomineeFeedbackList') {
				const { empId } = modalFeedbackProps?.details || {};
				const employeeId = empId;
				setModalFeedbackProps({ open: false, type: '', message: '', details: null, isCancel: false });
				await getNomineeFeedbackList(employeeId);
				updatePipFormDetails('feedbackIds', []);
			}
		}
		// setModalFeedbackProps({ open: false, type: '', message: '', details: null, isCancel: false });
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'NomineeSearch') {
				const value = modalProps?.details || {};
				const empId = value && value?.length ? value[0]?.employeeId : Enums.ZERO;
				if (pipFormDetails?.feedbackIds?.length) {
					setModalProps({ open: false, type: '', message: '', details: null });
					addDefaultReviewers(value);
					// setTimeout(() => {
					setModalFeedbackProps({
						open: true,
						type: 'NomineeFeedbackList',
						message: 'The Feedbacks linked to the old nominee have been removed.',
						details: { empId },
						isCancel: true,
					});
					// }, 1500);
				} else {
					setModalProps({ open: false, type: '', message: '', details: null });
					await getNomineeFeedbackList(empId);
					addDefaultReviewers(modalProps?.details);
				}
			} else if (modalProps?.type === 'NomineeSearchDirect') {
				setModalProps({ open: false, type: '', message: '', details: null });
			}
		} else {
			if (modalProps?.type === 'NomineeSearch') {
				setModalProps({ open: false, type: '', message: '', details: null });
				updatePipFormDetails('nomineeId', null);
				setFeedbackReviewersList([]);
			} else if (modalProps?.type === 'NomineeSearchDirect') {
				setModalProps({ open: false, type: '', message: '', details: null });
				updatePipFormDetails('nomineeId', null);
				setFeedbackReviewersList([]);
			}
		}
		// setModalProps({ open: false, type: '', message: '', details: null });
	};
	const handleUserSelectionClick = async (value: any) => {
		try {
			if (value && value.length > Enums.ZERO) {
				const empId = value && value?.length ? value[0]?.employeeId : Enums.ZERO;
				const { isExist, message } = await checkPipNomineeExistApiCall(empId);
				setLoader(false);
				if (isExist) {
					if (pipFormDetails?.feedbackIds?.length) {
						await getNomineeFeedbackList(empId);
						addDefaultReviewers(value);
						setModalFeedbackProps({
							open: true,
							type: 'NomineeFeedbackList',
							message: 'The Feedbacks linked to the old nominee have been removed.',
							details: null,
							isCancel: true,
						});
					} else {
						await getNomineeFeedbackList(empId);
						addDefaultReviewers(value);
					}
				} else {
					setTimeout(() => {
						setModalProps({
							open: true,
							type: 'NomineeSearch',
							message: message,
							details: value,
						});
					}, 1000);
				}
			} else {
				updatePipFormDetails('nomineeId', null);
				setFeedbackReviewersList([]);
			}
			setReportingToOptions([]);
			setEmptyRecords({ ...emptyRecords, reportingTo: false });
		} catch (error) {
			console.error(error);
		}
	};
	const handleFeedbackSelection = (selectedFeedbackData: any) => {
		updatePipFormDetails('feedbackIds', selectedFeedbackData);
	};

	const addDefaultReviewers = async (employeeData: any[]) => {
		const requestParam = `employeeId=${employeeData[0].employeeId}&emailModuleId=1`;
		const resp: any = await dispatch(getSearchDefaultUser(requestParam));
		if (resp && resp?.data && resp.data?.status === Enums.STATUS_SUCCESS) {
			let entity: any = resp.data.entity;
			let reviewers: any = [];
			if (entity.isBusinessUnitHead && entity.businessUnitHeadResponse) {
				reviewers.push({
					...entity.businessUnitHeadResponse,
					isDeleteDisabled: true,
					id: entity.businessUnitHeadResponse.businessUnitHeadId,
				});
			}
			if (entity.isHRBP && entity.hrbpResponse) {
				reviewers.push({ ...entity.hrbpResponse, isDeleteDisabled: true, id: entity.hrbpResponse.hrbpId });
			}
			if (entity.isSkipLevelManager && entity.skipLevelManagerResponse) {
				reviewers.push({
					...entity.skipLevelManagerResponse,
					isDeleteDisabled: true,
					id: entity.skipLevelManagerResponse.skipLevelManagerId,
				});
			}
			const nomineeInfo = {
				nomineeId: employeeData ? employeeData[0]?.employeeId : Enums.ZERO,
				nomineeInfo: employeeData || null,
				nomineeError: '',
			};
			setIsFormEdited(true);
			setPIPFormDetails({ ...pipFormDetails, reviewers: reviewers, ...nomineeInfo });
			setFeedbackReviewersList(reviewers);
		}
	};
	return (
		<>
			<Box className='pip-details-area'>
				<Box className='pip-details-left'>
					{pipFormType !== 'Add' && pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE ? (
						<></>
					) : (
						<Box className='pip-input-field helperText-bottom' id='pip_nominee_container'>
							<MultiSelect
								id='pip_nominee_dropdown'
								selectedOptions={pipFormDetails?.nomineeInfo || null}
								optionsList={reportingToOptions}
								onSelectOption={(value: any) => handleUserSelectionClick(value)}
								disabled={pipFormType !== 'Add' && pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE}
								isMulti={true}
								customFilter={() => {}}
								placeHolder={t('searchColleagueToNominate')}
								noOptionsMessage={<NoSearchRecord />}
								showNoOptions={emptyRecords.reportingTo === true}
								fetchAsyncData={true}
								isSingleSelection={true}
								performSearch={searchReportingTo}
								closeMenuOnSelect={true}
								labelTemplate={'avatarLabel'}
								selectClassName={'select-search-dropdown select-search-icon pip-search-field '}
								selectClassNamePrefix={'react-select'}
								error={Boolean(pipFormDetails?.nomineeError)}
								onInputFocus={() => {
									searchReportingTo('', allReviewersList, true);
								}}
								helperText={
									Boolean(pipFormDetails?.nomineeError) && (
										<Fragment>
											<ErrorIcon />
											{pipFormDetails?.nomineeError || ''}
										</Fragment>
									)
								}
								// hideLoggedIn={true}
								// loggedInUserIds={allReviewersList}
								allReviewersList={allReviewersList}
								blurInputOnSelect={true}
							/>
						</Box>
					)}
					<Box className='pip-input-field' id='pip_title_container'>
						<TextField
							id='pip_title'
							label={
								<Fragment>
									<NameRenameIcon />
									{t('titleLabel')}
									<i style={{ color: 'red' }}>*</i>
								</Fragment>
							}
							className='helperText-bottom'
							maxRows={3}
							multiline
							placeholder={t('enterTitleText')}
							inputProps={{
								maxLength: 50,
								readOnly: isPIPTitlePrefilledDisabled,
							}}
							disabled={isPIPTitlePrefilledDisabled}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								'aria-label': t('titleLabel'),
								disableUnderline: true,
							}}
							name={'title'}
							value={pipFormDetails?.title}
							onChange={(event: any) => {
								const { value, name } = event?.target || {};
								updatePipFormDetails(name, value || '');
							}}
							//error={Boolean(pipFormDetails?.titleError)}
							helperText={
								Boolean(pipFormDetails?.titleError) && (
									<Fragment>
										<ErrorIcon />
										{pipFormDetails?.titleError || ''}
									</Fragment>
								)
							}
						/>
						<Typography variant='h6' className='input-label-pad text-lowercase'>
							{getRemainingCharacter(pipFormDetails?.title || '', 50)} {t('charactersRemaining')}
						</Typography>
					</Box>
					<Box className='pip-input-field-two-col'>
						<Box className='pip-input-field ' id='pip_time_frame_container'>
							<Box className='helperText-bottom'>
								<FormLabel id='pip-timeframe' component='legend'>
									<CalendarIcon />
									{t('timeFrameText')}
									<i style={{ color: 'red' }}>*</i>
								</FormLabel>
								<ReactDateRangePicker
									type={'ContributorList'}
									selectedCycleDetails={{}}
									startDateValue={pipFormDetails?.timeframeStartDate || null}
									endDateValue={pipFormDetails?.timeframeEndDate || null}
									minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
									maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
									handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
										range && handleDateOverlapCheck(range, start, end);
									}}
									isDateRangeTextFieldVisible={true}
									isOnlyDueDateVisible={false}
									allCycleDetails={[]}
									isCycleVisible={false}
									isEndOfMonthVisible={false}
									numberOfCalendars={Enums.TWO}
									selectionType={'range'}
									selectedDateRange={selectedDateRange}
									setSelectedDateRange={setSelectedDateRange}
									placeholderText={t('selectedDateRange')}
									isDatePickerDisable={false}
									// {
									// 	type === 'View' || (type === 'Edit' && pipFormDetails.performanceImprovementPlanStatusId === Enums.TWO)
									// 		? true
									// 		: false
									// }
								/>
								{pipFormDetails?.timeFrameError ? (
									<Typography className='error-field'>
										<ErrorIcon /> {pipFormDetails?.timeFrameError}
									</Typography>
								) : (
									<></>
								)}
							</Box>
							{pipFormType !== 'Add' &&
							pipFormDetails?.isTimeFrameChanged &&
							pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE ? (
								<Box className='input-label-pad '>
									<OKRButton className='btn-link-type1' text={t('undoLabel')} handleClick={handleTimeFrameUndoClick} />
								</Box>
							) : (
								<></>
							)}
						</Box>
						{pipFormType !== 'Add' &&
						pipFormDetails?.isTimeFrameChanged &&
						pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE ? (
							<Box className='pip-input-field '>
								<Box className='helperText-bottom'>
									<FormLabel id='pip-reason' component='legend'>
										<ReasonIcon />
										{t('reasonLabel')}
										<i style={{ color: 'red' }}>*</i>
									</FormLabel>
									<Box className={`ques-type-selection custom-type-dropdown-top ${openReasonDropdown ? 'active' : ''}`}>
										<OKRButton
											className={'dropdown-button button-placeholder'}
											text={
												pipFormDetails?.extendTimeframes?.length
													? getSelectedReasonLabelName() === Enums.ONE
														? t('timeframeExtension')
														: getSelectedReasonLabelName() === Enums.TWO
														? t('changeOfPlan')
														: '- Select -'
													: '- Select -'
											}
											icon={<BottomArrowIcon />}
											handleClick={() => handleReasonDropdown()}
										></OKRButton>

										{openReasonDropdown && (
											<ClickAwayListener onClickAway={handleReasonClickAway}>
												<Box className='ques-type-dropdown'>
													<List component='div' disablePadding>
														{timeFrameChangeReasonTypes && timeFrameChangeReasonTypes?.length ? (
															timeFrameChangeReasonTypes?.map((item: any, reasonIndex: number) => {
																return (
																	<ListItem
																		component='div'
																		className={
																			getSelectedReasonLabelName() === item?.performanceImprovementPlanReasonId
																				? 'active'
																				: ''
																		}
																		onClick={() => handleReasonSelect(item)}
																	>
																		<Typography>{item?.name || ''}</Typography>
																	</ListItem>
																);
															})
														) : (
															<></>
														)}
													</List>
												</Box>
											</ClickAwayListener>
										)}
									</Box>
								</Box>
								<Typography variant='h6' className='input-label-pad'>
									{t('reasonNote')}
								</Typography>
							</Box>
						) : (
							<></>
						)}
					</Box>

					<Box className='pip-input-field review-dates-field' id='pip_review_dates_field_container'>
						<FormLabel id='pip-review-dates' component='legend'>
							<DueDateIcon />
							{t('reviewDates')}
							<i style={{ color: 'red' }}>*</i>
						</FormLabel>
						{/* {pipFormDetails?.reviewDatesError ? (
							<Typography className='error-field'>
								<ErrorIcon /> {pipFormDetails?.reviewDatesError}
							</Typography>
						) : (
							<></>
						)} */}

						{/* Review Dates  */}
						<ReviewDates
							t={t}
							pipFormType={pipFormType}
							pipFormDetails={pipFormDetails}
							updatePipFormDetails={updatePipFormDetails}
							minDateValue={minDateValue}
							maxDateValue={maxDateValue}
							showMsg={showMsg}
							setIsFormEdited={setIsFormEdited}
							isFormCleared={isFormCleared}
							setIsFormCleared={setIsFormCleared}
							feedbackReviewersList={feedbackReviewersList}
						/>

						<Box className='pip-reviewers-notes'>
							<Typography variant='h6'>{t('reviewersNoteText')}</Typography>
						</Box>
					</Box>
				</Box>
				<Box className='pip-details-right'>
					<Box className='form-details-right'>
						<LinkToFeedbackDropdown
							selectedNomineeFeedbackList={selectedNomineeFeedbackList}
							pipFormType={pipFormType}
							pipFormDetails={pipFormDetails}
							handleFeedbackSelection={handleFeedbackSelection}
						/>
						<UploadDocuments
							loader={loader}
							setLoader={setLoader}
							updatePipFormDetails={updatePipFormDetails}
							pipFormDetails={pipFormDetails}
							isFormCleared={isFormCleared}
							setIsFormCleared={setIsFormCleared}
							pipFormType={pipFormType}
							uploadDocumentType='pipFeedbackAttachments'
						/>
					</Box>
				</Box>
			</Box>
			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
			{modalFeedbackProps?.open && (
				<AlertDialog
					message={modalFeedbackProps?.message || ''}
					handleCloseModal={handleCloseFeedbackModal}
					modalOpen={modalFeedbackProps.open}
					isCancel={modalFeedbackProps.isCancel}
				/>
			)}
		</>
	);
};
