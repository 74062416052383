import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { MissionIcon, VisionIcon } from '../../../../config/svg/MyGoalSvg';
import '../../../../styles/pages/admin/settings/strategicObjective.scss';
import CkEditor from '../../../Common/CkEditor/CkEditor';
import { OKRButton } from '../../../Common/OKRButton';
import { PastCycleIcon } from '../../../../config/svg/CommonSvg';
import { useDispatch } from 'react-redux';
import {
	CreateUpdateAdminSOMissionVision,
	getAdminSOMissionVisionDetails,
	getAdminSOMissionVisionHistory,
	getAllSoPermission,
} from '../../../../action/strategicObjective';
import { Enums } from '../../../../config/enums';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { MissionVisionHistoryDrawer } from './MissionVisionHistoryDrawer';
import AlertDialog from '../../../Common/Dialog';

interface MissionVisionInfo {
	type: string | '';
	soMissionVisionId: number | 0;
	missionDescription: string | '';
	visionDescription: string | '';
}
export const MissionVision: React.FC<any> = (props: any) => {
	const { t, loader, setLoader } = props;
	// const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const missionVisionStaticInfo = {
		type: 'add',
		soMissionVisionId: 0,
		missionDescription: '',
		visionDescription: '',
	};
	const [missionVisionInfo, setMissionVisionInfo] = useState<MissionVisionInfo>(missionVisionStaticInfo);
	const [missionVisionEditInfo, setMissionVisionEditInfo] = useState<MissionVisionInfo>(missionVisionStaticInfo);
	const [missionVisionHistory, setMissionVisionHistory] = useState<any>(null);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [openUserDrawer, setOpenUserDrawer] = useState<any>(null);
	const [initialRender, setInitialRender] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '', type: '' });

	useEffect(() => {
		renderMissionVisionInitial();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderMissionVisionInitial = () => {
		getMissionVisionDetails();
		getMissionVisionHistory();
	};
	const getMissionVisionDetails = async () => {
		try {
			setLoader(true);
			const response: any = await dispatch(getAdminSOMissionVisionDetails());
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response?.data || {};
				const detailsInfo = {
					missionDescription: entity?.missionDescription || '',
					visionDescription: entity?.visionDescription || '',
					type: 'edit',
				};
				if (detailsInfo.missionDescription && detailsInfo.visionDescription) {
					setInitialRender(true);
				} else if (!detailsInfo.missionDescription && !detailsInfo.visionDescription) {
					setInitialRender(true);
				}
				setMissionVisionInfo({ ...missionVisionInfo, ...detailsInfo });
				setMissionVisionEditInfo({ ...missionVisionEditInfo, ...detailsInfo });
			} else {
				setMissionVisionInfo({ ...missionVisionInfo, type: 'edit' });
				setMissionVisionEditInfo({ ...missionVisionEditInfo, type: 'edit' });
				// const { messageList } = response.data;
				// const keys = Object.keys(messageList);
				// const messages = keys.map((item) => messageList[item]);
				// enqueueSnackbar(messages || `Some error occurred`, {
				// 	variant: 'error',
				// 	autoHideDuration: 3000,
				// });
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const getMissionVisionHistory = async () => {
		try {
			setLoader(true);
			const requestPayload = {
				pageIndex: 0,
				pageSize: 99999,
				sortingText: '',
				order: '',
			};
			const response: any = await dispatch(getAdminSOMissionVisionHistory(requestPayload));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response?.data || {};
				const { records } = entity || {};
				setMissionVisionHistory(records && records.length ? records : []);
			} else {
				// const { messageList } = response.data;
				// const keys = Object.keys(messageList);
				// const messages = keys.map((item) => messageList[item]);
				// enqueueSnackbar(messages || `Some error occurred`, {
				// 	variant: 'error',
				// 	autoHideDuration: 3000,
				// });
				setMissionVisionHistory([]);
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleMissionChange = (value: any) => {
		if (value && value !== missionVisionInfo?.missionDescription) {
			setIsFormEdited(true);
			setInitialRender(false);
		} else if (!value) {
			setInitialRender(false);
		}
		setMissionVisionInfo({ ...missionVisionInfo, missionDescription: value });
	};
	const handleVisionChange = (value: any) => {
		if (value && value !== missionVisionInfo?.visionDescription) {
			setIsFormEdited(true);
			setInitialRender(false);
		} else if (!value) {
			setInitialRender(false);
		}
		setMissionVisionInfo({ ...missionVisionInfo, visionDescription: value });
	};
	const handleResetMissionVisionData = (event: any) => {
		setModalProps({
			open: true,
			message: 'Changes made will be discarded. Do you wish to proceed?',
			details: '',
			type: 'resetMissionVision',
		});
	};
	const checkValidation = () => {
		if (!missionVisionInfo?.missionDescription && !missionVisionInfo?.visionDescription) {
			initialRender && setInitialRender(false);
			return true;
		} else if (missionVisionInfo?.missionDescription && !missionVisionInfo?.visionDescription) {
			return false;
		} else if (!missionVisionInfo?.missionDescription && missionVisionInfo?.visionDescription) {
			return false;
		} else if (missionVisionInfo?.missionDescription && missionVisionInfo?.visionDescription) {
			return true;
		}
	};
	const handleSaveMissionVisionData = async (event: any) => {
		try {
			if (checkValidation()) {
				setLoader(true);
				const requestPayload = {
					soMissionVisionId: missionVisionInfo?.soMissionVisionId || 0,
					missionDescription: missionVisionInfo?.missionDescription || '',
					visionDescription: missionVisionInfo?.visionDescription || '',
				};
				const response: any = await dispatch(CreateUpdateAdminSOMissionVision(requestPayload));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					const defaultMessage = `Strategic Objectives Mission and Vision ${
						missionVisionInfo?.soMissionVisionId ? 'updated' : 'created'
					} successfully`;
					enqueueSnackbar(messages || defaultMessage, {
						variant: 'success',
						autoHideDuration: 3000,
					});

					renderMissionVisionInitial();
					dispatch(getAllSoPermission());
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					const defaultMessage = `Error occurred while ${
						missionVisionInfo?.soMissionVisionId ? 'updating' : 'creating'
					} mission and vision`;
					enqueueSnackbar(messages || defaultMessage, {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
				// setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleOpenHistoryDrawer = (event: any) => {
		setOpenUserDrawer({ open: true });
	};
	const handleDrawerClose = (event: any, type: string) => {
		setOpenUserDrawer({ open: false });
	};
	const handleCloseModal = async (e: any, type: any) => {
		try {
			e.preventDefault();
			if (type === 1) {
				if (modalProps?.type === 'resetMissionVision') {
					setMissionVisionInfo({ ...missionVisionEditInfo, type: 'edit' });
					setIsFormEdited(false);
					setInitialRender(true);
					setModalProps({ open: false, message: '', details: '', type: '' });
				} else {
					setModalProps({ open: false, message: '', details: '' });
				}
			} else {
				setModalProps({ open: false, message: '', details: '' });
			}
		} catch (error) {
			console.error(error);
			setModalProps({ open: false, message: '', details: '' });
		}
	};

	return (
		<>
			<Box className='so-mv-setting-card full-rectangular-card'>
				<Box className='so-setting-card-top so-setting-card-row'>
					<Box className='so-setting-card-col'>
						<Box className='so-mv-tile-head'>
							<MissionIcon />
							<Typography>{t('missionLabel')}</Typography>
						</Box>
						<Box className='so-mv-tile-content long-text'>
							<CkEditor
								{...props}
								className={'inactive' || ''}
								value={missionVisionInfo?.missionDescription}
								skipBlankCheck={true}
								handleEditorChange={(value: string) => {
									handleMissionChange(value);
								}}
								editorId={`requestOneOnOneAgenda`}
								extraConfig={{ toolbar: ['bold', 'italic'] }}
								uploadImage={false}
								removedPlugins={[]}
								placeHolderText={t('missionPlaceholder')}
								showEmoticons={true}
							/>
						</Box>
					</Box>
					<Box className='so-setting-card-col'>
						<Box className='so-mv-tile-head'>
							<VisionIcon />
							<Typography>{t('visionLabel')}</Typography>
						</Box>
						<Box className='so-mv-tile-content'>
							<CkEditor
								{...props}
								className={'inactive' || ''}
								value={missionVisionInfo?.visionDescription}
								skipBlankCheck={true}
								handleEditorChange={(value: string) => {
									handleVisionChange(value);
								}}
								editorId={`requestOneOnOneAgenda`}
								extraConfig={{ toolbar: ['bold', 'italic'] }}
								uploadImage={false}
								removedPlugins={[]}
								placeHolderText={t('visionPlaceholder')}
								showEmoticons={true}
							/>
						</Box>
					</Box>
				</Box>
				<Box className='so-setting-card-actions'>
					<Box className='so-setting-card-actions-left'>
						<OKRButton
							handleClick={handleOpenHistoryDrawer}
							className='btn-link'
							text={t('historyLabel')}
							icon={<PastCycleIcon />}
						/>
					</Box>
					<Box className='so-setting-card-actions-right'>
						<OKRButton
							disabled={loader || initialRender || !checkValidation()}
							handleClick={handleResetMissionVisionData}
							className='btn-link'
							text={t('cancelBtn')}
						/>
						<OKRButton
							disabled={loader || initialRender || !checkValidation()}
							handleClick={handleSaveMissionVisionData}
							className='btn-primary'
							text={t('saveBtn')}
						/>
					</Box>
				</Box>
				{openUserDrawer && openUserDrawer?.open && (
					<MainDrawer
						open={openUserDrawer?.open || false}
						transitionDuration={{ enter: 500, exit: 1000 }}
						headerTitle={t('missionVissionLabel')}
						children={
							<Box className='drawer-inner-content'>
								<MissionVisionHistoryDrawer {...props} missionVisionHistory={missionVisionHistory} />
							</Box>
						}
						saveButtonText={t('saveBtn')}
						rightCloseBtn={false}
						drawerClassName={'main-drawer-panel '}
						isSaveButtonVisible={false}
						handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
						handleSaveClick={(event: any) => handleDrawerClose(event, 'exclude')}
						handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
					/>
				)}
			</Box>
			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
