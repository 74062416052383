import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	teamAssessmentResponse: {},
	multipleAssessmentResponse: [],
	employeeAssessmentResponse: null,
	performanceAlert: null,
	performanceCycle: null,
	finalRatingMastreData: [],
	employeeFinalRatingData: [],
	isDataLoading: false,
	teamAssessmentResponseStatus: false,
	employeeAssessmentResponseStatus: false,
	employeeAssessmentCycleListStatus: '',
	threeSixtyAssessmentList: null,
	threeSixtyAssessmentListPending: false,
	threeSixtyAssessmentListError: null,
	takeAssessmentData: null,
	conversationList: {
		entityList: [],
		pagingInfo: {},
	},
	getConversationStatus: '',
	teamAssessmentResponseDone: false,
};

export default function performReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.GET_ACCESS_TEAM_LIST_PENDING:
			return {
				...state,
				teamAssessmentResponseStatus: false,
				teamAssessmentResponseDone: false,
			};
		case actionTypes.GET_ACCESS_TEAM_LIST_SUCCESS:
			return {
				...state,
				teamAssessmentResponse: action.payload.entity,
				teamAssessmentResponseStatus: true,
				teamAssessmentResponseDone: true,
			};
		case actionTypes.GET_ACCESS_TEAM_LIST_FAILED:
			return {
				...state,
				teamAssessmentResponse: {},
				teamAssessmentResponseStatus: false,
				teamAssessmentResponseDone: true,
			};
		case actionTypes.GET_MULTIPLE_ASSESSMENT_PENDING:
			return {
				...state,
				multipleAssessmentResponse: [],
			};
		case actionTypes.GET_MULTIPLE_ASSESSMENT_SUCCESS:
			return {
				...state,
				multipleAssessmentResponse: action.payload.entity,
			};
		case actionTypes.GET_MULTIPLE_ASSESSMENT_FAILED:
			return {
				...state,
				multipleAssessmentResponse: [],
			};
		case actionTypes.RESET_TEAM_ASSESSMENT:
			return {
				...state,
				teamAssessmentResponse: {},
			};
		case actionTypes.GET_EMPLOYEE_ASSESSMENT_PENDING:
			return {
				...state,
				employeeAssessmentResponse: null,
				employeeAssessmentResponseStatus: false,
			};
		case actionTypes.GET_EMPLOYEE_ASSESSMENT_SUCCESS:
			return {
				...state,
				employeeAssessmentResponse: action.payload.entity,
				employeeAssessmentResponseStatus: true,
			};
		case actionTypes.RESET_EMPLOYEE_ASSESSMENT:
			return {
				...state,
				employeeAssessmentResponse: null,
				employeeAssessmentResponseStatus: false,
			};
		case actionTypes.GET_EMPLOYEE_ASSESSMENT_FAILED:
			return {
				...state,
				employeeAssessmentResponse: null,
				employeeAssessmentResponseStatus: false,
			};
		case actionTypes.GET_PERFORMANCE_CYCLE_PENDING:
			return {
				...state,
				employeeAssessmentCycleList: [],
				employeeAssessmentCycleListStatus: 'pending',
			};
		case actionTypes.GET_PERFORMANCE_CYCLE_SUCCESS:
			return {
				...state,
				employeeAssessmentCycleList: action.payload.entityList,
				employeeAssessmentCycleListStatus: 'success',
			};
		case actionTypes.GET_PERFORMANCE_CYCLE_FAILED:
			return {
				...state,
				employeeAssessmentCycleList: [],
				employeeAssessmentCycleListStatus: 'failed',
			};
		case actionTypes.GET_PERFORMANCE_ALERT_PENDING:
			return {
				...state,
				performanceAlert: null,
			};
		case actionTypes.GET_PERFORMANCE_ALERT_SUCCESS:
			return {
				...state,
				performanceAlert: action.payload.entity,
			};
		case actionTypes.GET_PERFORMANCE_ALERT_FAILED:
			return {
				...state,
				performanceAlert: null,
			};
		case actionTypes.SET_PERFORMANCE_CYCLE:
			return {
				...state,
				performanceCycle: action.payload,
			};
		case actionTypes.GET_FINAL_RATING_MASTER_PENDING:
			return {
				...state,
				finalRatingMastreData: null,
			};
		case actionTypes.GET_FINAL_RATING_MASTER_SUCCESS:
			return {
				...state,
				finalRatingMastreData: action.payload.entityList,
			};
		case actionTypes.GET_FINAL_RATING_MASTER_FAILED:
			return {
				...state,
				finalRatingMastreData: null,
			};
		case actionTypes.GET_EMPLOYEE_FINAL_RATING_PENDING:
			return {
				...state,
				employeeFinalRatingData: null,
			};
		case actionTypes.GET_EMPLOYEE_FINAL_RATING_SUCCESS:
			return {
				...state,
				employeeFinalRatingData: action.payload.entityList,
			};
		case actionTypes.GET_EMPLOYEE_FINAL_RATING_FAILED:
			return {
				...state,
				employeeFinalRatingData: null,
			};
		case actionTypes.IS_DATA_LOADING:
			return {
				...state,
				isDataLoading: action.data,
			};
		case actionTypes.GET_THREE_SIXTY_ASSESSMENT_API_PENDING:
			return {
				...state,
				threeSixtyAssessmentList: null,
				threeSixtyAssessmentListPending: true,
				threeSixtyAssessmentListError: null,
			};
		case actionTypes.GET_THREE_SIXTY_ASSESSMENT_API_SUCCESS:
			return {
				...state,
				threeSixtyAssessmentList: action.payload.entity,
				threeSixtyAssessmentListPending: false,
				threeSixtyAssessmentListError: null,
			};
		case actionTypes.GET_THREE_SIXTY_ASSESSMENT_API_FAILED:
			return {
				...state,
				threeSixtyAssessmentList: null,
				threeSixtyAssessmentListPending: false,
				threeSixtyAssessmentListError: action.payload,
			};
		case actionTypes.RESET_THREE_SIXTY_ASSESSMENT_DETAILS:
			return {
				...state,
				threeSixtyAssessmentList: null,
				threeSixtyAssessmentListPending: false,
				threeSixtyAssessmentListError: null,
			};
		case actionTypes.GET_TEAM_USER_DATA_SUCCESS:
			return {
				...state,
				takeAssessmentData: action.payload,
			};
		case actionTypes.UPDATE_ASSESSMENT_ALERT:
			return {
				...state,
				performanceAlert: { ...state.performanceAlert, isSelfAssessmentAlert: action.payload },
			};
		case actionTypes.FETCH_CONVERSATION_GOAL_PENDING:
			return {
				...state,
				conversationList: {
					entityList: [],
					pagingInfo: {},
				},
				getConversationStatus: 'pending',
			};
		case actionTypes.FETCH_CONVERSATION_GOAL_SUCCESS:
			return {
				...state,
				conversationList: {
					entityList: action.payload.entity.commentDetailResponses,
					pagingInfo: action.payload.pagingInfo,
				},
				getConversationStatus: 'success',
			};
		case actionTypes.FETCH_CONVERSATION_GOAL_FAILED:
			return {
				...state,
				conversationList: action.error,
				getConversationStatus: 'failed',
			};
		case actionTypes.RESET_CONVERSATION:
			return {
				...state,
				conversationList: {
					entityList: [],
					pagingInfo: {},
				},
				getConversationStatus: '',
			};

		default:
			return state;
	}
}
