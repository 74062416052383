import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from '../../../../CommonComponent/MultiSelect';
import { OKRButton } from '../../../../../Common/OKRButton';
import { ListRole } from './ListRole';
import { CancelIcon, TickIcon } from '../../../../../../config/svg/Action';
import { Enums } from '../../../../../../config/enums';

export const DesignationRole: React.FC<any> = (props: any) => {
	const {
		defaultOkrDesignationDetails,
		setDefaultOkrFormEdited,
		selectedDesignationOptions,
		setSelectedDesignationOptions,
		// handleUpdateAssignIds,
		defaultOkrFormDetails,
		allEmployeeChecked,
		handleSelectedRoleDeletion,
	} = props;
	const { t } = useTranslation();
	const [selectedUser, setSelectedUser] = useState([]);

	const updateValue = () => {
		const defaultOkrDesignationData =
			defaultOkrDesignationDetails && defaultOkrDesignationDetails?.length ? [...defaultOkrDesignationDetails] : [];
		const uniqueItem: any = [];
		defaultOkrDesignationData &&
			defaultOkrDesignationData?.length &&
			defaultOkrDesignationData.forEach((item: any) => {
				const isExist = selectedDesignationOptions?.find(
					(rec: any) => rec?.value === item?.value || rec?.value === item?.designationId
				);
				if (!isExist) {
					uniqueItem.push({ ...item });
				}
			});
		const updateDetails = [...uniqueItem].map((item: any) => {
			return {
				label: item?.designationName || '',
				value: item?.designationId || 0,
				userCount: item?.userCount || 0,
				id: item?.designationId || 0,
			};
		});

		return updateDetails;
	};
	const onSelectEmployee = (selectedUserValue: any, actionMeta: any) => {
		setSelectedUser(selectedUserValue);
		setDefaultOkrFormEdited(true);
	};
	const customFilter = (option: any, searchText: any) => {
		if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
			return true;
		}
	};
	const handleDesignationSave = () => {
		if (selectedDesignationOptions && selectedDesignationOptions?.length) {
			const uniqueItem: any = [...selectedDesignationOptions];
			selectedUser &&
				selectedUser?.length &&
				selectedUser.forEach((item: any) => {
					const isExist = selectedDesignationOptions?.find((rec: any) => rec?.value === item?.value);
					if (!isExist) {
						uniqueItem.push({ ...item });
					}
				});

			setSelectedDesignationOptions(uniqueItem);
		} else {
			setSelectedDesignationOptions(selectedUser);
		}
		setSelectedUser([]);
	};
	const handleDesignationCancel = () => {
		setSelectedUser([]);
	};

	return (
		<>
			<Box className={`select-role-fields ${selectedUser && selectedUser?.length ? 'selected' : ''}`}>
				<FormControl variant='outlined'>
					<MultiSelect
						key={'employeeSelect'}
						{...props}
						isMulti={true}
						selectedOptions={selectedUser}
						optionsList={updateValue()}
						onSelectOption={onSelectEmployee}
						customFilter={customFilter}
						placeHolder={t('designationPlaceholder')}
						noOptionsMessage={t('designationNotFound')}
						showNoOptions={true}
						performSearch={() => {}}
						labelTemplate={'numberUserLabel'}
						id={'employee-select'}
						selectClassName={`select-search-dropdown select-search-icon`}
						selectClassNamePrefix={'react-select'}
						disableSearch={allEmployeeChecked}
						disabled={allEmployeeChecked}
					/>
					{selectedUser && selectedUser?.length ? (
						<Box className='textfield-action-btn'>
							<OKRButton
								className='cancel-btn'
								id={'cancel-btn'}
								icon={<CancelIcon />}
								handleClick={handleDesignationCancel}
							/>
							<OKRButton className='save-btn' id={'save-btn'} icon={<TickIcon />} handleClick={handleDesignationSave} />
						</Box>
					) : (
						<></>
					)}
				</FormControl>
				{/* <Box className='action-btn'>
					<Typography variant='subtitle2' className='action-btn-count'>
						{!selectedUserList.length && employeeList.length > 0 && `(${employeeList.length} ${t('membersLabel')})`}
					</Typography>
					<OKRButton
						disabled={!employeeList.length}
						className='btn-secondary'
						id='action-add-btn'
						text={t('addBtn')}
						handleClick={handleAddUserNextClick}
					/>
				</Box> */}
			</Box>
			<Box className='drawer-blue-bg-full'>
				<ListRole
					titleHead={t('designations')}
					listHeadCol2={t('numberUser')}
					selectedItemList={selectedDesignationOptions || []}
					defaultOkrFormDetails={defaultOkrFormDetails}
					assignType={Enums.ONE}
					allEmployeeChecked={allEmployeeChecked}
					handleSelectedRoleDeletion={handleSelectedRoleDeletion}
				/>
			</Box>
		</>
	);
};
