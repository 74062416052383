import React, { Fragment } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ManageScaleForm } from './ManageScaleForm';
import { CustomScaleForm } from './CustomScaleForm';

export const ManageScaleTab: React.FC<any> = (props: any) => {
	const { manageScaleTabSelected, resetCustomScaleTabData, scaleDetailsFromAssessmentForm } = props;
	const { t } = useTranslation();
	const handleTabChange = (event: any, newValue: Number) => {
		resetCustomScaleTabData(newValue, 2);
	};
	function a11yProps(index: number) {
		return {
			id: `scale-tabpanel-${index}`,
			'aria-controls': `scale-tabpanel-${index}`,
		};
	}

	return (
		<Fragment>
			<>
				<Tabs
					className='okr-tabs drawer-tabs'
					value={manageScaleTabSelected}
					onChange={handleTabChange}
					aria-label='Manage Scale Tabs'
				>
					<Tab
						label={t('manageScale')}
						value={0}
						{...a11yProps(0)}
						disabled={
							scaleDetailsFromAssessmentForm && scaleDetailsFromAssessmentForm?.callingFrom === 'AssessmentForm'
								? true
								: false
						}
					/>
					<Tab label={t('customScale')} value={1} {...a11yProps(1)} />
				</Tabs>
				<Box className='drawer-tabs-panel'>
					<form className='drawer-form-fields' noValidate autoComplete='off'>
						{manageScaleTabSelected === 0 && (
							<>
								<ManageScaleForm {...props} handleTabChange={handleTabChange} />
							</>
						)}
						{manageScaleTabSelected === 1 && (
							<>
								<CustomScaleForm {...props} />
							</>
						)}
					</form>
				</Box>
			</>
		</Fragment>
	);
};
