import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	sigInAction,
	signInActionDB,
	updateStatus,
	azureSignInAction,
	tenantAction,
	tenantActionOKR,
	orgCurrentCycleAction,
	currentCycleStatus,
	impersonateUser,
	azureSignInActionImpersonate,
	userByTokenAction,
} from '../../../action/signin';
import SignIn from '../../../components/Guest/SignIn';
import { updateRoute, showLoader, updatePage, getCycleDetails } from '../../../action/common';
import { getOnboarding, updateOnboardingCount } from '../../../action/onboarding';
import { getEmployeePermission } from '../../../action/roles';

const mapStateToProps = (state) => {
	return {
		signInStatus: state.signReducer.signInStatus,
		pending: state.signReducer.pending,
		messageList: state.signReducer.messageList,
		entity: state.signReducer.entity,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			azureSignInAction,
			orgCurrentCycleAction,
			tenantAction,
			tenantActionOKR,
			sigInAction,
			signInActionDB,
			updateRoute,
			showLoader,
			updatePage,
			updateStatus,
			getCycleDetails,
			getOnboarding,
			updateOnboardingCount,
			getEmployeePermission,
			currentCycleStatus,
			impersonateUser,
			azureSignInActionImpersonate,
			userByTokenAction,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
