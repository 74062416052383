import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReportFilter from '../Filters';
import { getLocalStorageItem } from '../../../services/StorageService';
import { getUserDetails } from '../../../config/utils';
import { HeaderTooltip } from '../HeaderTooltip';
import _ from 'lodash';
import moment from 'moment';
import { ReportsGaugeChart } from '../HighchartReports/ReportsGaugeChart';
import { ReportsLineChart } from '../HighchartReports/ReportsLineChart';
import { CHART_COLOR } from '../../../config/constant';

const MyTeamsProgress: React.FC<any> = (props: any) => {
	const {
		getOkrProgressDetails,
		okrNatures,
		getMyLeadingTeamsDetails,
		myLeadingTeams,
		setIsReportDataAvailable,
		leadingTeams,
		getLeadingTeamsDetails,
	} = props;
	const { t } = useTranslation();
	const [nature, setNature] = React.useState<any>([]);
	const [teams, setTeams] = React.useState<any>([]);
	const [isFilterChanged, setIsFilterChanged] = React.useState<boolean>(false);

	const [teamsPreviousState, setTeamsPreviousState] = React.useState<any>([]);
	const [checkedTeam, setCheckedTeam] = React.useState<any>([]);
	const [naturePreviousState, setNaturePreviousState] = React.useState<any>([]);
	const [filterSelectionError, setFilterSelectionError] = React.useState<boolean>(false);
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const [anchorEl, setAnchorEl] = React.useState<boolean>(false);
	const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
	const [overAllProgress, setOverAllProgress] = React.useState<any>({});
	const [reportsData, setReportsData] = React.useState<any>({});
	const [maxValueRecord, setMaxValueRecord] = React.useState<any>({});
	const [resetClicked, setResetClicked] = React.useState<boolean>(false);
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const cycleEndDate = getLocalStorageItem('cycleEndDate') || '';
	const cycleStartDate = getLocalStorageItem('cycleStartDate') || '';
	const loginUser = getUserDetails();

	React.useEffect(() => {
		if (resetClicked) {
			getTeamProgress();
		}
	}, [resetClicked]);

	const handleFilterBtnClick = () => {
		setAnchorEl((prev) => !prev);
		setNature(naturePreviousState);
		setTeams(teamsPreviousState);
	};

	const handleClose = (event: any) => {
		setAnchorEl(false);
		setNature(naturePreviousState);
		setTeams(teamsPreviousState);
	};

	React.useEffect(() => {
		let data = `cycleId=${cycleId}`;
		// if (!leadingTeams.length) {
		getLeadingTeamsDetails(data);
		// }
	}, []);

	React.useEffect(() => {
		//Call Team data
		if (anchorEl) {
			let data = `cycleId=${currentCycleId}`;
			if (!myLeadingTeams.length) {
				getMyLeadingTeamsDetails(data);
			}
			if (!okrNatures?.okrNature) {
				props.getOkrMasterData();
			}
		}
	}, [anchorEl]);

	React.useEffect(() => {
		if (cycleStartDate && cycleEndDate) {
			getTeamProgress();
		}
	}, []);

	React.useEffect(() => {
		if (myLeadingTeams.length) {
			let checkItem = myLeadingTeams.map((item: any) => {
				return {
					...item,
					isChecked: true,
				};
			});
			setTeams(checkItem);
			setTeamsPreviousState(checkItem);
		}
	}, [myLeadingTeams]);

	React.useEffect(() => {
		if (okrNatures?.okrNature && okrNatures?.okrNature.length) {
			let checkItem = okrNatures.okrNature.map((item: any) => {
				return {
					...item,
					isChecked: true,
				};
			});
			setNature(checkItem);
			setNaturePreviousState(checkItem);
		}
	}, [okrNatures]);

	const prepareChartData = (chartData: any) => {
		let data = chartData.map((org: any) => {
			if (moment(org.date) > moment(new Date())) {
				return {
					...org,
					x: new Date(org.date),
				};
			} else {
				return {
					...org,
					x: new Date(org.date),
					y: org.progress,
				};
			}
		});
		return data;
	};

	const getTeamProgress = async () => {
		let data = {};
		let teamList = [];
		if (anchorEl) {
			let selectedTeams = [...teams];
			let selectedNature = [...nature];
			let getFilterList = _.filter(selectedTeams, 'isChecked');
			teamList = getFilterList.length === 1 ? getFilterList : [];
			let getFilteredNature = _.filter(selectedNature, 'isChecked');
			data = {
				cycleId: Number(cycleId) || 0,
				cycleStartDate: cycleStartDate.split('T')[0],
				cycleEndDate: cycleEndDate.split('T')[0],
				teamIds: _.map(getFilterList, 'teamId'),
				okrNatureIds: _.map(getFilteredNature, 'okrNatureId'),
			};
		} else {
			data = {
				cycleId: Number(cycleId) || 0,
				cycleStartDate: cycleStartDate.split('T')[0],
				cycleEndDate: cycleEndDate.split('T')[0],
				teamIds: [],
				okrNatureIds: [],
			};
		}
		setResetClicked(false);
		const apiRes = await getOkrProgressDetails(data);
		if (apiRes && apiRes.data.status === 200) {
			setOverAllProgress(apiRes.data.entity.myProgressOverall);
			setReportsData(apiRes.data.entity);
			if (teamList.length && apiRes.data.entity.myProgressOverall) {
				setCheckedTeam(teamList);
			} else {
				setCheckedTeam([]);
			}
			let allRecords = [
				...apiRes.data?.entity.myOrgMemberProgressDetails,
				...apiRes.data?.entity?.myProgressDetails,
				...apiRes.data?.entity?.myTeamMemberProgressDetails,
			];
			let maxVal = _.maxBy(allRecords, 'progress');
			setMaxValueRecord(maxVal || {});
			setReportsData({
				...reportsData,
				myOrgMemberProgressDetails: prepareChartData(apiRes.data?.entity.myOrgMemberProgressDetails),
				myProgressDetails: prepareChartData(apiRes.data?.entity?.myProgressDetails),
				myTeamMemberProgressDetails: prepareChartData(apiRes.data?.entity?.myTeamMemberProgressDetails),
			});
			setDataLoaded(true);
			setIsReportDataAvailable(true);
		} else if (apiRes && apiRes.data.status === 204) {
			setIsReportDataAvailable(false);
		}
	};

	const handleFilterOption = (e: any, filterType: string, filterId?: number) => {
		// e.preventDefault();
		const { checked } = e.target;
		let natures = [...nature];
		let allTeams = [...teams];
		if (filterType === 'natureOfOkr') {
			let updateNature = natures.map((item: any) => {
				if (item.okrNatureId === filterId) {
					return {
						...item,
						isChecked: checked,
					};
				}
				return item;
			});
			setNature(updateNature);
		} else if (filterType === 'selectAllNature') {
			let updateNature = natures.map((item: any) => {
				return {
					...item,
					isChecked: checked,
				};
			});
			setNature(updateNature);
		} else if (filterType === 'teams') {
			let updateTeams = allTeams.map((item: any) => {
				if (item.teamId === filterId) {
					return {
						...item,
						isChecked: checked,
					};
				}
				return item;
			});
			setTeams(updateTeams);
		} else if (filterType === 'selectAllTeam') {
			let updateTeams = allTeams.map((item: any) => {
				return {
					...item,
					isChecked: checked,
				};
			});
			setTeams(updateTeams);
		}
	};

	React.useEffect(() => {
		let allTeams;
		let allNatures;
		if (teams.length && nature.length) {
			validateFilter();
			allTeams = teams.filter((item: any) => item.isChecked).length === teams.length;
			allNatures = nature.filter((item: any) => item.isChecked).length === nature.length;
			if (!allTeams || !allNatures) {
				setIsFilterChanged(true);
			} else {
				setIsFilterChanged(false);
			}
		}
	}, [teams, nature]);

	const validateFilter = () => {
		let findFilteredTeams = teams.filter((item: any) => item.isChecked);
		let findFilteredNatures = nature.filter((item: any) => item.isChecked);
		if (!findFilteredTeams.length || !findFilteredNatures.length) {
			setFilterSelectionError(true);
		} else {
			setFilterSelectionError(false);
		}
	};

	const handleApplyFilter = () => {
		setNaturePreviousState(nature);
		setTeamsPreviousState(teams);
		handleFilterBtnClick();
		getTeamProgress();
	};

	const handleResetFilter = () => {
		let natures = [...nature];
		let updateNature = natures.map((item: any) => {
			return {
				...item,
				isChecked: true,
			};
		});
		let updateTeams = teams.map((item: any) => {
			return {
				...item,
				isChecked: true,
			};
		});
		setNature(updateNature);
		setTeams(updateTeams);
		setNaturePreviousState(updateNature);
		setTeamsPreviousState(updateTeams);
		setAnchorEl((prev) => !prev);
		setResetClicked(true);
	};

	return (
		<>
			<Box className='reports-head'>
				<Box className='reports-head-left'>
					<Typography variant='h2'>{t('myOkrProgressReportLabel')}</Typography>
					<HeaderTooltip tooltipText={t('okrProgressReportTooltipText')} btnId={'my-team-prg-tooltip'} />
				</Box>
				<Box className='reports-head-right'>
					<ReportFilter
						{...props}
						toolTipText={t('filterTeamTooltip')}
						secondFiltersOption={nature}
						isSencondFilters={true}
						teams={teams}
						handleFilterOption={handleFilterOption}
						handleApplyFilter={handleApplyFilter}
						handleResetFilter={handleResetFilter}
						handleFilterBtnClick={handleFilterBtnClick}
						handleClose={handleClose}
						filterSelectionError={filterSelectionError}
						anchorEl={anchorEl}
						isFilterChanged={isFilterChanged}
						filterId={'my-team'}
					/>
				</Box>
			</Box>
			<Box className='reports-card col-full'>
				<Box className='report-col col-one-third'>
					<Typography variant='h4'>
						{t('overallPerformanceLabel')}
						<span>
							{t('overAllCurrentDate', { currentDate: moment(overAllProgress?.progressDate).format('MMMM D, YYYY') })}
						</span>
					</Typography>
					<ReportsGaugeChart
						isOrgReport={false}
						teams={teams}
						loginUser={loginUser}
						checkedTeam={checkedTeam}
						overAllProgress={overAllProgress}
						colors={CHART_COLOR}
						labels={[t('meLabel'), t('myTeamsMemberLabel'), t('orgMembersLabel')]}
						chartHight={'244'}
						paneBackgroundColor={'rgba(244, 241, 248, 0.5)'}
					/>
				</Box>
				<Box className='report-col col-two-thirds'>
					<Typography variant='h4' className='title-center'>
						{t('fourWeekReportLabel')}
					</Typography>
					<ReportsLineChart
						t={t}
						dataLoaded={dataLoaded}
						overAllProgress={overAllProgress}
						reportsData={reportsData}
						showLegend={true}
						maxValueRecord={maxValueRecord}
						colors={CHART_COLOR}
						labels={[t('meLabel'), t('myTeamsMemberLabel'), t('orgMembersLabel')]}
						checkedTeam={checkedTeam}
						toolTipPostfix={'%'}
						xAxisText={'Weeks'}
						yAxisText={'Engagement'}
					/>
				</Box>
			</Box>
		</>
	);
};

export default MyTeamsProgress;
