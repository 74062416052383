import * as actionTypes from './actionTypes';

export const showNotification = (show) => ({
	type: actionTypes.HIGHLIGHT_NOTIFICATION,
	payload: show,
});

export const updateDashboardProgress = (data) => ({
	type: actionTypes.SIGNALR_UPDATE_PROGRESS,
	payload: data,
});

export const okrUpdated = (data) => ({
	type: actionTypes.SIGNALR_OKR_UPDATED,
	payload: data,
});

export const isAnyDrawerOpened = (data) => ({
	type: actionTypes.IS_DRAWER_DIALOG_OPENED,
	payload: data,
});

export const showRefreshSnackBar = (data) => ({
	type: actionTypes.SHOW_REFRESH_SNACKBAR,
	payload: data,
});

export const handleCalendarOpen = (data) => ({
	type: actionTypes.CALENDAR_OPEN_STATE,
	payload: data,
});

export const isNonDashboardUpdated = (data) => ({
	type: actionTypes.IS_NON_DASHBOARD_UPDATED,
	payload: data,
});

export const isOneOnOneRefresh = (data) => ({
	type: actionTypes.IS_ONE_ON_ONE_REFRESH,
	payload: data,
});

export const isDashboardRefresh = (data) => ({
	type: actionTypes.IS_DASHBOARD_REFRESH,
	payload: data,
});

export const isRecognizeRefresh = (data) => ({
	type: actionTypes.IS_RECOGNIZE_REFRESH,
	payload: data,
});
