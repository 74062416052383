/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useRef, useEffect } from 'react';
import {
	Drawer,
	IconButton,
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	MenuList,
	MenuItem,
	Tooltip,
} from '@material-ui/core';
import { KebabIcon } from '../../config/svg/CommonSvg';
import AskFeedback from '../Feedback/AskFeedback';
import ViewFeedback from '../Feedback/ViewFeedback';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import AlertDialog from '../Common/Dialog';
import { useTranslation } from 'react-i18next';
import { ALIGNMENT_MAP } from '../../config/app-url';
import { DrawerTransitionSetting, getUserDetails } from '../../config/utils';
import '../../styles/pages/feedback/feedback.scss';
import { useSnackbar } from 'notistack';

const FeedbackMenu = (props) => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleToggle = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setOpen((prev) => !prev);
	};

	const handleClose = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const { currentUser, userDetail, type, objId, objective, isLocked, alignment, isAnyFeedback, isNewDashboard } = props;
	const { t } = useTranslation();
	return (
		<Fragment>
			{alignment ? (
				<Tooltip title={t('moreOptions')} arrow>
					<IconButton
						className='kebab-icon'
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup='true'
						onClick={handleToggle}
					>
						<KebabIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title={t('moreOptions')} arrow>
					<IconButton
						className='kebab-icon'
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup='true'
						onClick={handleToggle}
					>
						<KebabIcon />
					</IconButton>
				</Tooltip>
			)}
			<Popper className='popper-menu-list' open={open} anchorEl={anchorRef.current} placement='bottom-end' transition>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList autoFocusItem={open} id='menu-list-grow'>
									{!isNewDashboard && !currentUser && type === 'okr' && !isLocked && (
										<MenuItem
											key={`menu_1}`}
											onClick={(e) => props.openAddKeyResult(e, true, objective, 'editOkr', type, objId)}
										>
											{t('editObjective')}
										</MenuItem>
									)}
									{!isNewDashboard && !currentUser && type === 'kr' && !isLocked && (
										<MenuItem onClick={(e) => props.openAddKeyResult(e, true, objective, 'editKey', type, objId)}>
											{t('editKeyResult')}
										</MenuItem>
									)}
									{!isNewDashboard && !currentUser && !isLocked && !objective.isPrivate && type === 'kr' ? (
										<MenuItem onClick={(e) => props.openAddKeyResult(e, true, objective, 'assignKr', type, objId)}>
											{t('assignTo')}
										</MenuItem>
									) : (
										!isNewDashboard &&
										!currentUser &&
										!isLocked &&
										!objective.isPrivate && (
											<MenuItem onClick={(e) => props.openAddKeyResult(e, true, objective, 'assignOkr', type, objId)}>
												{t('assignTo')}
											</MenuItem>
										)
									)}
									{!isNewDashboard && !currentUser && type === 'okr' && !isLocked && (
										<MenuItem
											key={`menu_2}`}
											onClick={(e) => props.openAddKeyResult(e, true, objective, 'addKey', type, objId)}
										>
											{t('addKeyResult')}
										</MenuItem>
									)}

									{!currentUser && (
										<MenuItem onClick={(e) => props.handleAskFeedback(e)}>{t('requestFeedback')}</MenuItem>
									)}
									{currentUser && userDetail.employeeId !== currentUser.employeeId && (
										<MenuItem onClick={props.handleGiveFeedback}>{t('giveFeedback')}</MenuItem>
									)}
									{!currentUser && Boolean(isAnyFeedback) && (
										<MenuItem onClick={props.handleViewFeedback}>{t('menuItemReadFeedback')}</MenuItem>
									)}
									{!currentUser ? (
										<MenuItem onClick={props.handleRequestOneOnOne}>{t('requestOnetoOne')}</MenuItem>
									) : (
										currentUser &&
										userDetail.employeeId !== currentUser.employeeId && (
											<MenuItem
												onClick={(e) => props.handlePeopleRequestOneOnOne(e, type, objId, objective, currentUser)}
											>
												{t('requestOnetoOne')}
											</MenuItem>
										)
									)}
									<MenuItem onClick={(e) => props.handleAlignmentMap(e, objective)}>{t('viewGoalsmap')}</MenuItem>

									{!currentUser && !isLocked && (
										<MenuItem onClick={() => props.handleDeleteObjective(objId, type, objective)}>
											{t('delete')}
										</MenuItem>
									)}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Fragment>
	);
};

export default function Feedback(props) {
	const { enqueueSnackbar } = useSnackbar();
	const [openAskFeedback, setOpenAskFeedback] = useState(false);
	const [openGiveFeedback, setOpenGiveFeedback] = useState(false);
	const [openRequestOneOnOne, setOpenRequestOneOnOne] = useState(false);
	const [openViewFeedback, setOpenViewFeedback] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalWaringOpen, setWarningModalOpen] = useState(false);
	const [formDataUpdated, setFormDataUpdated] = useState(false);

	const [feedbackDetail, setFeedbackDetail] = useState({ id: 0, employeeId: 0 });
	const {
		setFeedback,
		openAddKeyResult,
		resetFeedback,
		askFeedbackStatus,
		giveFeedbackStatus,
		handleDeleteObjective,
		objective,
		isLocked,
		feedbackRedirection,
		showGiveFeedback,
		notificationBy,
		resetNotificationData,
		alignment,
		openFeedbackType,
		feedbackOld,
	} = props;
	const { t } = useTranslation();
	const userDetail = getUserDetails();

	useEffect(() => {
		if (giveFeedbackStatus === 'success' || askFeedbackStatus === 'success') {
			handleFeedbackClose();
		}
	}, [giveFeedbackStatus, askFeedbackStatus]);

	useEffect(() => {
		if (showGiveFeedback && parseInt(showGiveFeedback) === 1) {
			handleGiveFeedback();
		} else if (showGiveFeedback && parseInt(showGiveFeedback) === 2) {
			handleViewFeedback();
		}
	}, [showGiveFeedback]);

	useEffect(() => {
		if (openFeedbackType === 1) {
			handleAskFeedback();
		} else if (openFeedbackType === 2) {
			handleViewFeedback();
		} else if (openFeedbackType === 3) {
			handleRequestOneOnOne();
		} else if (openFeedbackType === 4) {
			handlePeopleRequestOneOnOne(
				'',
				props.type,
				props.type === 'kr' ? props.krId || props.objId : props.objId,
				props.objective,
				props.currentUser || props.sourceDetails
			);
		} else if (openFeedbackType === 5) {
			handleGiveFeedback();
		}
	}, [openFeedbackType]);

	const handleClose = () => {
		//	resetNotificationData();
		//===
	};

	const handleAlignmentMap = (e) => {
		props.updateRoute(ALIGNMENT_MAP);
		props.history.push(ALIGNMENT_MAP, { objective });
	};

	const handleAskFeedback = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		resetFeedback();
		handleClose();
		setOpenAskFeedback(true);
		setOpenGiveFeedback(false);
		setOpenViewFeedback(false);
		setOpenRequestOneOnOne(false);
	};
	const handleGiveFeedback = (e) => {
		//	e.preventDefault();
		//	e.stopPropagation();
		resetFeedback();
		handleClose();
		setOpenGiveFeedback(true);
		setOpenAskFeedback(false);
		setOpenViewFeedback(false);
		setOpenRequestOneOnOne(false);
	};
	const handleViewFeedback = (e) => {
		//	e.preventDefault();
		//	e.stopPropagation();
		resetFeedback();
		handleClose();
		setOpenViewFeedback(true);
		setOpenGiveFeedback(false);
		setOpenAskFeedback(false);
		setOpenRequestOneOnOne(false);
	};

	const handleRequestOneOnOne = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		resetFeedback();
		handleClose();
		// setOpenViewFeedback(false);
		setOpenGiveFeedback(false);
		setOpenAskFeedback(false);
		setOpenRequestOneOnOne(true);
	};
	// eslint-disable-next-line no-restricted-globals
	const handleFeedbackClose = (e = event) => {
		handleClose();
		setOpenAskFeedback(false);
		setOpenGiveFeedback(false);
		setOpenViewFeedback(false);
		setOpenRequestOneOnOne(false);
		setFeedback({ type: 0, open: false });
		if (showGiveFeedback) {
			try {
				resetNotificationData();
			} catch (err) { }
		}
		//=== on close right section
		//=== reset feedback data
	};

	const handlePeopleRequestOneOnOne = (e, type, id, objective, currentUser) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		const typeId = type === 'okr' ? 1 : 2;
		const { employeeId } = currentUser;
		if (!id) {
			id = objective.goalObjectiveId;
		}

		setFeedbackDetail({ typeId, id, employeeId, type: 1 });
		setOpenAskFeedback(false);
		setOpenGiveFeedback(false);
		setModalOpen(true);
	};

	/** ========= */
	const handleRequestOnToOne = (e, typeId, id, employeeId, message, type) => {
		e.preventDefault();
		e.stopPropagation();
		setFeedbackDetail({ typeId, id, employeeId, message, type });
		setOpenAskFeedback(false);
		setOpenGiveFeedback(false);
		setModalOpen(true);
	};

	/** request 1:1 api calling */
	const handleCloseModal = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		let requestedTo = [];
		if (Array.isArray(feedbackDetail.employeeId)) {
			requestedTo = [...feedbackDetail.employeeId];
		} else {
			requestedTo = [feedbackDetail.employeeId];
		}

		if (type === 1) {
			const data = {
				requestId: feedbackDetail.id,
				requestType: type === 2 ? type : feedbackDetail.typeId,
				requestedTo,
				requestedFrom: userDetail.employeeId,
				onetoOneRemark: feedbackDetail.message ? feedbackDetail.message : t('thankyouFeedback'),
			};
			setModalOpen(false);
			props
				.requestOneToOneFeedback(data)
				.then((response) => {
					if (openFeedbackType === 4) {
						props?.resetDecline && props.resetDecline();
					}
					if (feedbackDetail.type !== 1) {
						handleFeedbackClose();
					}
					if (response.status === 200) {
						enqueueSnackbar(t('requestRaisedSuccessfully'), {
							variant: 'success',
							autoHideDuration: 3000,
						});
					} else {
						enqueueSnackbar(t('errorRaisingRequest'), {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				})
				.catch((error) => {
					enqueueSnackbar(t('errorRaisingRequest'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
		} else {
			props?.resetDecline && props.resetDecline();
		}
		setModalOpen(false);
	};

	/** open popup for warning */
	const handleDrawerConfirmation = (event) => {
		if (formDataUpdated) {
			setWarningModalOpen(true);
		} else {
			handleWaringCloseModal(event, 1);
		}

		// action for prevent reload on alignmentMap
		if (props.setAlignmentMapAction) {
			let obj = {
				objectiveId: props.objective.objectiveId,
				krId: props.objective.objectiveId === props.objId ? 0 : props.objId,
			};

			if (props.feedbackOnData && props.feedbackOnData.feedbackItem) {
				obj.objectiveId = props.feedbackOnData.feedbackItem.okrId || 0;
				obj.krId = props.feedbackOnData.feedbackItem.krId || 0;
			}
			const alignmentMapAction = {
				actionType: 'VIEW_FEEDBACK',
				status: 'PENDING',
				value: [obj],
			};
			props.setAlignmentMapAction(alignmentMapAction);
		}

		// if (props.alignmentMap) {
		// 	if (props.tabValue === 0) {
		// 		props.getAlignmentData();
		// 	} else if (props.tabValue === 1) {
		// 		const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
		// 		props.fetchTeamsAlignmentMap(data);
		// 	}
		// }
	};

	/** open warning popup */
	const handleWaringCloseModal = (e, type) => {
		e.preventDefault();
		if (type === 1) {
			handleFeedbackClose(e);
			try {
				resetNotificationData();
			} catch (err) { }
		}
		setWarningModalOpen(false);
	};

	return (
		<Fragment>
			{!feedbackRedirection && !feedbackOld && (
				<FeedbackMenu
					handleAskFeedback={handleAskFeedback}
					handleGiveFeedback={handleGiveFeedback}
					handleViewFeedback={handleViewFeedback}
					handleRequestOnToOne={handleRequestOnToOne}
					handleRequestOneOnOne={handleRequestOneOnOne}
					handleDeleteObjective={handleDeleteObjective}
					openAddKeyResult={openAddKeyResult}
					handlePeopleRequestOneOnOne={handlePeopleRequestOneOnOne}
					handleAlignmentMap={handleAlignmentMap}
					objective={objective}
					isLocked={isLocked}
					alignment={alignment}
					{...props}
				/>
			)}
			{/* {openAskFeedback && ( */}
			<Drawer
				className='main-drawer-panel drawer-no-footer'
				anchor='right'
				open={openAskFeedback}
				onEscapeKeyDown={handleDrawerConfirmation}
				onBackdropClick={handleDrawerConfirmation}
				transitionDuration={DrawerTransitionSetting}
			>
				<IconButton className='drawer-close-btn' id='drawer-close-btn' onClick={handleDrawerConfirmation}>
					<RightArrowIcon />
				</IconButton>
				<AskFeedback
					isAskFeedback={true}
					requestOneOnOne={false}
					{...props}
					handleFeedbackClose={handleFeedbackClose}
					handleDrawerConfirmation={handleDrawerConfirmation}
					feedbackRequestId={props.feedbackRequestId}
					setFormDataUpdated={setFormDataUpdated}
					setOpenAskFeedback={setOpenAskFeedback}
				/>
			</Drawer>
			{/* )} */}
			{/* {openGiveFeedback && ( */}
			<Drawer
				className='main-drawer-panel drawer-no-footer'
				anchor='right'
				open={openGiveFeedback}
				onEscapeKeyDown={handleDrawerConfirmation}
				onBackdropClick={handleDrawerConfirmation}
				transitionDuration={DrawerTransitionSetting}
			>
				<IconButton className='drawer-close-btn' id='drawer-close-btn' onClick={handleDrawerConfirmation}>
					<RightArrowIcon />
				</IconButton>
				<AskFeedback
					isAskFeedback={false}
					requestOneOnOne={false}
					{...props}
					handleFeedbackClose={handleFeedbackClose}
					handleDrawerConfirmation={handleDrawerConfirmation}
					notificationBy={notificationBy}
					currentUser={props.currentUser}
					feedbackRequestId={props.feedbackRequestId}
					setFormDataUpdated={setFormDataUpdated}
					alignmentMap={props.alignmentMap}
					setOpenGiveFeedback={setOpenGiveFeedback}
				/>
			</Drawer>
			{/* )} */}
			{/* {openRequestOneOnOne && ( */}
			<Drawer
				className='main-drawer-panel drawer-no-footer'
				anchor='right'
				open={openRequestOneOnOne}
				onEscapeKeyDown={handleDrawerConfirmation}
				onBackdropClick={handleDrawerConfirmation}
				transitionDuration={DrawerTransitionSetting}
			>
				<IconButton className='drawer-close-btn' id='drawer-close-btn' onClick={handleDrawerConfirmation}>
					<RightArrowIcon />
				</IconButton>
				<AskFeedback
					isAskFeedback={false}
					requestOneOnOne={true}
					{...props}
					handleFeedbackClose={handleFeedbackClose}
					handleRequestOnToOne={handleRequestOnToOne}
					handleDrawerConfirmation={handleDrawerConfirmation}
					feedbackRequestId={props.feedbackRequestId}
					setFormDataUpdated={setFormDataUpdated}
				/>
			</Drawer>
			{/* )} */}
			{/* {openViewFeedback && ( */}
			<Drawer
				className='main-drawer-panel drawer-no-footer'
				anchor='right'
				open={openViewFeedback}
				onEscapeKeyDown={handleDrawerConfirmation}
				onBackdropClick={handleDrawerConfirmation}
				transitionDuration={DrawerTransitionSetting}
			>
				<IconButton className='drawer-close-btn' id='drawer-close-btn' onClick={handleDrawerConfirmation}>
					<RightArrowIcon />
				</IconButton>
				<ViewFeedback
					{...props}
					handleFeedbackClose={handleFeedbackClose}
					handleRequestOnToOne={handleRequestOnToOne}
					handleDrawerConfirmation={handleDrawerConfirmation}
					feedbackDetailId={props.feedbackDetailId}
					setFormDataUpdated={setFormDataUpdated}
				/>
			</Drawer>
			{/* )} */}

			{modalOpen && (
				<AlertDialog
					module='oneToOne'
					message={t('requestOnToOneMsg')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
					objective={objective}
				/>
			)}
			{modalWaringOpen && (
				<AlertDialog
					module='mygoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleWaringCloseModal}
					modalOpen={modalWaringOpen}
				/>
			)}
		</Fragment>
	);
}
