import { Checkbox, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import { DrawerTransitionSetting } from '../../../../../config/utils';
import CloneImg from '../../../../../images/clone-img-okr.svg';
import '../../../../../styles/pages/admin/cloneDialog.scss';
import { AddStatusIcon, BorderCheckboxIcon, CheckedIcon } from '../../../../../config/svg/formElementIcons';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { cloneObjective, refreshAdminDefaultOkrList } from '../../../../../action/adminDefaultOkr';
import { useSnackbar } from 'notistack';

interface CloneRoleComponentProps {
	handleCloseModal: (event: any, openId: number) => void;
	handleCloneSubmit: (event: any, cloneObj: any, modalOpen: any) => void;
	modalOpen: any;
}

const CloneDefaultOkrDialog: React.FC<CloneRoleComponentProps> = (props) => {
	const { modalOpen, handleCloseModal, handleCloneSubmit } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
	const [cloneObj, setCloneObj] = React.useState<any>({
		isAssigned: false,
		isWeight: false,
		isTarget: false,
	});
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {setCloneObj({
		isAssigned: false,
		isWeight: false,
		isTarget: false,
	}) }, [modalOpen.open]);
	const handleCloneForm = (e: any) => {
		const { checked, name } = e.target;
		setCloneObj({
			...cloneObj,
			[name]: checked,
		});
	};
	const clonedObjective = async () => {
		setSaveInProgress(true);
		let requestPayload: any= {...cloneObj, defaultGoalObjectiveId: modalOpen.openId};
		const response: any = await dispatch(cloneObjective(requestPayload));
		if (response.status === 200) {
			setSaveInProgress(false);
			handleCloseModal({}, 0);
			dispatch(refreshAdminDefaultOkrList(true));
			enqueueSnackbar(response?.data?.messageList.Result, {
				variant: 'success',
				autoHideDuration: 3000,
			});

		} else {
			setSaveInProgress(false);
		}
	};

	return (
		<Dialog
			className='clone-role-dialog clone-okr-dialog'
			open={modalOpen.open}
			transitionDuration={DrawerTransitionSetting}
			onClose={(e: any) => { handleCloseModal(e, 0) }}
		>
			<DialogContent>
				<Typography variant='h2'>{t('whatShouldBeCloned')}</Typography>
				<Box className='clone-dialog-inner'>
					<Box className='clone-checkbox'>
						<FormControlLabel
							control={
								<Checkbox
									name='isAssigned'
									checked={cloneObj.isAssigned}
									onChange={handleCloneForm}
									icon={<BorderCheckboxIcon />}
									checkedIcon={<CheckedIcon />}
									id={'is-kr-weightage'}
								/>
							}
							label={t('krDesignation')}
						/>
						<FormControlLabel
							control={
								<Checkbox
									name='isWeight'
									checked={cloneObj.isWeight}
									onChange={handleCloneForm}
									icon={<BorderCheckboxIcon />}
									checkedIcon={<CheckedIcon />}
									id={'is-kr-weightage'}
								/>
							}
							label={t('krWeightage')}
						/>

						<FormControlLabel
							control={
								<Checkbox
									name='isTarget'
									checked={cloneObj.isTarget}
									onChange={handleCloneForm}
									icon={<BorderCheckboxIcon />}
									checkedIcon={<CheckedIcon />}
									id={'is-kr-target'}
								/>
							}
							label={t('krTarget')}
						/>
					</Box>
					<Box className='clone-image'>
						<img src={CloneImg} alt={t('clone')} />
					</Box>
				</Box>
			</DialogContent>

			<DialogActions>
				<Box className='action-btn'>
					<Button className='btn-link' onClick={(e:any) => {handleCloseModal(e, 0)}} id='cancel-btn'>
						{t('cancelBtn')}
					</Button>
					<Button id='clone-btn' disabled={saveInProgress} className='btn-primary' onClick={clonedObjective}>
						{t('clone')}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default CloneDefaultOkrDialog;


