import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import { OKRButton } from '../../../../Common/OKRButton';
import { Enums } from '../../../../../config/enums';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';

export const PipRightsSettings: React.FC<any> = (props: any) => {
	const {
		t,
		loader,
		performancePlanSettingDetails,
		setPerformancePlanSettingDetails,
		updatePerformancePlanSettingAPICall,
		resetPerformanceDevelopmentSetting,
		isPerformancePlanStatusUpdated,
		setIsPerformancePlanStatusUpdated,
		isPipTitlePrefilledTextError,
		setIsPipTitlePrefilledTextError,
	} = props;

	const handleAllowReviewersToEditChange = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			updatePerformancePlanSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};
	const handleIsPIPVisibleToRMChange = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			updatePerformancePlanSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};
	const handleIsPIPTitlePrefilledChange = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			setIsPipTitlePrefilledTextError(!checked);
			updatePerformancePlanSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};
	const handlePipTitlePrefilledTextChange = (event: any) => {
		try {
			const { name, value } = event?.target || {};
			setIsPipTitlePrefilledTextError(value === '');
			updatePerformancePlanSetting(name, value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleIsPIPTitlePrefilledEditableChange = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			updatePerformancePlanSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};
	const handleIsPastDateAllowedForPIPChange = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			updatePerformancePlanSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};

	const updatePerformancePlanSetting = (key: string, value: boolean) => {
		setIsPerformancePlanStatusUpdated(true);
		setPerformancePlanSettingDetails({ ...performancePlanSettingDetails, [key]: value });
	};

	return (
		<Box className='performance-development-card full-rectangular-card pip-rights-settings'>
			<Box className='pd-card-header'>
				<Typography variant='h4' className='font-weight-normal'>
					{t('otherSettingsLabel')}
				</Typography>
			</Box>
			<Box className='pd-card-switch'>
				<FormControlLabel
					control={
						<Switch
							id='allowReviewersToEditPIP'
							name='allowReviewersToEditPIP'
							checked={performancePlanSettingDetails?.allowReviewersToEditPIP || false}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleAllowReviewersToEditChange}
						/>
					}
					label={t('allowReviewersToGetRightsPip')}
				/>
			</Box>
			<Box className='pd-card-switch'>
				<FormControlLabel
					control={
						<Switch
							id='isPIPVisibleToRM'
							name='isPIPVisibleToRM'
							checked={performancePlanSettingDetails?.isPIPVisibleToRM || false}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleIsPIPVisibleToRMChange}
						/>
					}
					label={t('allowNomineeReportingManagerPip')}
				/>
			</Box>
			<Box className='pd-card-switch'>
				<FormControlLabel
					control={
						<Switch
							id='isPIPTitlePrefilled'
							name='isPIPTitlePrefilled'
							checked={performancePlanSettingDetails?.isPIPTitlePrefilled || false}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleIsPIPTitlePrefilledChange}
						/>
					}
					label={t('pipTitlePrefilled')}
				/>
			</Box>
			{performancePlanSettingDetails?.isPIPTitlePrefilled && (
				<>
					<Box className='pd-card-textfield'>
						<TextField
							autoFocus
							id='pipTitlePrefilledText'
							name='pipTitlePrefilledText'
							inputProps={{ maxLength: 50 }}
							className='resolution-text-field helperText-bottom'
							value={performancePlanSettingDetails?.pipTitlePrefilledText || ''}
							placeholder={t('addDefaultPIPTitleWatermarkText')}
							onChange={handlePipTitlePrefilledTextChange}
							error={isPipTitlePrefilledTextError || ''}
							helperText={
								isPipTitlePrefilledTextError && (
									<>
										<ErrorIcon /> {t('defaultTitleRequiredMsg')}
									</>
								)
							}
						/>
					</Box>
					<Box className='pd-card-switch'>
						<FormControlLabel
							control={
								<Switch
									id='isPIPTitlePrefilledEditable'
									name='isPIPTitlePrefilledEditable'
									checked={performancePlanSettingDetails?.isPIPTitlePrefilledEditable || false}
									inputProps={{ 'aria-label': 'controlled' }}
									onChange={handleIsPIPTitlePrefilledEditableChange}
								/>
							}
							label={t('allowEditOfPrefilledValue')}
						/>
					</Box>
				</>
			)}
			<Box className='pd-card-switch'>
				<FormControlLabel
					control={
						<Switch
							id='isPastDateAllowedForPIP'
							name='isPastDateAllowedForPIP'
							checked={performancePlanSettingDetails?.isPastDateAllowedForPIP || false}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleIsPastDateAllowedForPIPChange}
						/>
					}
					label={t('allowPastDatesForPip')}
				/>
			</Box>
			<Box className='pd-setting-card-actions'>
				<Box className='pd-setting-card-actions-left'></Box>
				<Box className='pd-setting-card-actions-right'>
					<OKRButton
						className='btn-link'
						text={t('cancelBtn')}
						disabled={loader || !isPerformancePlanStatusUpdated}
						handleClick={resetPerformanceDevelopmentSetting}
					/>
					<OKRButton
						className='btn-primary'
						text={t('saveBtn')}
						disabled={loader || !isPerformancePlanStatusUpdated}
						handleClick={updatePerformancePlanSettingAPICall}
					/>
				</Box>
			</Box>
		</Box>
	);
};
