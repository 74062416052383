import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button, FormControl, TextField, Typography, Box } from '@material-ui/core';
import AssignContributor from '../OkrAction/AssignContributor';
import { formatDate, getQuarterStartEnd } from '../../config/utils';
import { getLocalStorageItem } from '../../services/StorageService';
import { ErrorIcon } from '../../config/svg/formElementIcons';

const RequestOneOnOne = (props) => {
	const {
		userDetail,
		handleDrawerConfirmation,
		setFormDataUpdated,
		requestOneToOnePersonalFeedback,
		setLoader,
		loader,
	} = props;
	const { t } = useTranslation();
	const { currentYear } = getQuarterStartEnd();
	const selectedYear = getLocalStorageItem('year') || currentYear;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const cycleEndDate = getLocalStorageItem('cycleEndDate') || 0;
	const staticData = {
		year: selectedYear,
		objectiveId: 0,
		goalObjectiveId: 0,
		employeeId: userDetail ? userDetail.employeeId : '',
		isPrivate: false,
		objectiveName: '',
		objectiveDescription: '',
		objectiveCycleId: cycleId,
		startDate: formatDate(new Date()),
		endDate: formatDate(cycleEndDate),
		importedType: 0,
		importedId: 0,
		contributors: [],
		myGoalsDetails: [],
		selectedUser: [],
	};
	const { enqueueSnackbar } = useSnackbar();
	const [formData, setFormData] = useState({
		message: t('requestOneOnOneForDefaultMessage'),
		submit: false,
	});
	const [goal, setGoal] = useState(staticData);

	const setMessageValue = (event) => {
		setFormDataUpdated(true);
		event.preventDefault();
		event.stopPropagation();
		setFormData({ ...formData, message: event.target.value, submit: true });
	};
	const submitRequest = (event, type) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 2) {
			handleDrawerConfirmation(event);
		} else {
			setFormData({ ...formData, submit: true });

			if (formData.message.trim() !== '') {
				feedbackRequest(event);
			}
		}
	};
	const feedbackRequest = (event) => {
		try {
			if (Boolean(goal) && goal.contributors && goal.contributors.length) {
				setLoader(true);
				const requestData = {
					requestType: 4,
					requestedTo: Boolean(goal) && goal.contributors && goal.contributors.length ? goal.contributors : [],
					requestedFrom: userDetail ? userDetail.employeeId : '',
					onetoOneRemark: formData.message,
				};
				requestOneToOnePersonalFeedback(requestData)
					.then((response) => {
						if (response && response.data && response.data.status === 200) {
							setGoal(staticData);
							setFormData({
								message: t('requestOneOnOneForDefaultMessage'),
								submit: false,
							});
							enqueueSnackbar(`${t('requestOneOnOneForSuccess')}`, {
								variant: 'success',
								autoHideDuration: 5000,
							});
						} else {
							enqueueSnackbar(`${t('requestOneOnOneForFail')}`, {
								variant: 'error',
								autoHideDuration: 5000,
							});
						}
						setLoader(false);
						setFormDataUpdated(false);
					})
					.catch((error) => {
						setLoader(false);
						console.error(error);
					});
			} else {
				//
			}
		} catch (e) {
			setLoader(false);
			console.error(e);
		}
	};
	const callbackRef = useCallback((inputElement) => {
		setTimeout(() => {
			if (inputElement) {
				//inputElement.focus();
			}
		}, 2000);
	}, []);
	const onSelectedUser = async (selected) => {
		const isExist = goal.selectedUser && goal.selectedUser.find((item) => item.employeeId === selected[0].employeeId);
		if (selected.length > 0 && !isExist) {
			const allUser = [...goal.selectedUser, { ...goal.selectedUser, ...selected[0] }];
			const allUserIds = allUser.map((item) => item.employeeId);

			setGoal(() => ({
				...goal,
				selectedUser: allUser,
				contributors: allUserIds,
				myGoalsDetails: goal.myGoalsDetails.map((item) => {
					item.contributors = [...new Set([...item.contributors, ...allUserIds])];
					return item;
				}),
			}));
			setFormDataUpdated(true);

			/* 
			// removed api call to check is already aligned - Not Required
			const goalId = goal.goalObjectiveId ? goal.goalObjectiveId : 0;
			const sourceId = goal.source && goal.source > 0 ? goal.source : goalId;
			const apiData = `sourceType=1&sourceId=${sourceId}&employeeId=${selected[0].employeeId}`;
			const resp = await props.checkAlignStatus(apiData);
			const { entity } = resp.data;

			if (entity) {
				const allUser = [...goal.selectedUser, { ...goal.selectedUser, ...selected[0] }];
				const allUserIds = allUser.map((item) => item.employeeId);

				setGoal(() => ({
					...goal,
					selectedUser: allUser,
					contributors: allUserIds,
					myGoalsDetails: goal.myGoalsDetails.map((item) => {
						item.contributors = [...new Set([...item.contributors, ...allUserIds])];
						return item;
					}),
				}));
				setFormDataUpdated(true);
			} else {
				enqueueSnackbar(`${getFullName(selected[0])} ${t('alreadyAlign')}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			} */
		}
		// setIsUpdate(true);
	};
	const removeUser = async ({ employeeId }) => {
		// setIsUpdate(true);
		const user = goal.selectedUser.filter((item) => item.employeeId !== employeeId);
		const userIds = goal.selectedUser.filter((item) => item.employeeId !== employeeId);
		const goalDetails = goal.myGoalsDetails.map((item) => {
			item.contributors = item.contributors.filter((item) => item !== employeeId);
			return item;
		});
		setGoal({
			...goal,
			selectedUser: user,
			contributors: [...userIds.map((item) => item.employeeId)],
			myGoalsDetails: goalDetails,
		});
		if (goal.goalObjectiveId) {
			const data = `goalType=1&goalId=${goal.goalObjectiveId}&employeeId=${employeeId}`;
			const resp = await props.deleteContributor(data);
			if (resp.status === 200) {
				// setCheckContributorRemove(true);
				enqueueSnackbar(t('contributorDeletedSuccess'), { variant: 'success', autoHideDuration: 3000 });
			} else {
				enqueueSnackbar(t('contributorDeletedFail'), { variant: 'error', autoHideDuration: 3000 });
			}
		}
	};

	return (
		<form className='okr-form-fields drawer-search-field' noValidate autoComplete='off'>
			<FormControl
				className={`drawer-field-bottom helperText-bottom ${
					Boolean(goal) && goal.contributors && goal.contributors.length === 0 && formData.submit ? 'error-search' : ''
				}`}
				variant='outlined'
				style={{ width: '100%' }}
			>
				<AssignContributor
					{...props}
					goal={goal}
					onSelectedUser={onSelectedUser}
					removeUser={removeUser}
					addKey={''}
					fieldLabel={t('requestOneOnOneForLabelPF')}
				/>
				{Boolean(goal) && goal.contributors && goal.contributors.length === 0 && formData.submit ? (
					<Typography variant='caption' className='error-field'>
						<ErrorIcon />
						{t('selectEmployee')}
					</Typography>
				) : (
					''
				)}
			</FormControl>

			<TextField
				style={{ width: '100%' }}
				className='feedback-message helperText-bottom'
				id='req-one-onone-msg'
				label={t('requestOneOnOneForTextPF')}
				multiline
				rows={4}
				placeholder={t('typeMessageHere')}
				value={formData.message}
				inputRef={callbackRef}
				onChange={setMessageValue}
				error={formData.submit && formData.message.trim() === ''}
				helperText={
					formData.submit && formData.message.trim() === '' ? (
						<>
							<ErrorIcon />
							{t('enterValidMessage')}
						</>
					) : (
						''
					)
				}
			/>
			<Box className='okr-form-action feedback-action'>
				<Button
					id='req-one-cancel-btn'
					className='cancel-btn btn-link'
					onClick={(event) => submitRequest(event, 2)}
					color='primary'
				>
					{t('cancelBtn')}
				</Button>
				<Button
					className='submitBtn btn-primary'
					id='req-one-send-btn'
					onClick={(event) => submitRequest(event, 1)}
					color='primary'
					disabled={formData.message.trim() === '' || goal.contributors.length === 0 || loader}
				>
					{t('send')}
				</Button>
			</Box>
		</form>
	);
};

export default RequestOneOnOne;
