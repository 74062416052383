import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Checkbox,
	Chip,
	ClickAwayListener,
	FormLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { BottomArrowIcon, CalendarIcon } from '../../../config/svg/CommonSvgIcon';
import { GiveFeedbackIcon } from '../../../config/svg/PersonalFeedbackSvg';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import { getMonthDateYearTime } from '../../../config/utils';
import { Enums } from '../../../config/enums';

export const LinkToFeedbackDropdown = (props: any) => {
	const { selectedNomineeFeedbackList, pipFormType, pipFormDetails, handleFeedbackSelection } = props;
	const { t } = useTranslation();
	const [openFeedbackDropdown, setOpenFeedbackDropdown] = React.useState<Boolean>(false);

	const handleFeedbackDropdown = () => {
		setOpenFeedbackDropdown((prev: Boolean) => !prev);
	};
	const handleFeedbackClickAway = () => {
		setOpenFeedbackDropdown(false);
	};
	const handleFeedbackSelectionClick = (e: any, feedback: any) => {
		const { checked } = e?.target || {};
		let updatedSelectedFeedbackData = [...pipFormDetails?.feedbackIds];
		if (checked) {
			if (feedback?.feedbackId) {
				updatedSelectedFeedbackData.push(feedback.feedbackId);
			}
		} else {
			updatedSelectedFeedbackData = updatedSelectedFeedbackData?.filter((rec: any) => rec !== feedback?.feedbackId);
		}
		handleFeedbackSelection(updatedSelectedFeedbackData);
	};

	return (
		<Box className='link-feedback-dropdown'>
			<FormLabel id='link-feedback' component='legend' className='form-label'>
				<GiveFeedbackIcon />
				{t('linkToFeedback')}
			</FormLabel>
			<Box className={`ques-type-selection custom-type-dropdown-top ${openFeedbackDropdown ? 'active' : ''}`}>
				<Box
					className={`dropdown-button button-placeholder ${openFeedbackDropdown ? 'active' : ''} ${
						selectedNomineeFeedbackList && selectedNomineeFeedbackList?.length === Enums.ZERO
							? 'disabled-feedback-dropdown'
							: ''
					}`}
					onClick={() => handleFeedbackDropdown()}
				>
					<span className='dropdown-icon'>
						<BottomArrowIcon />
					</span>
					{pipFormDetails?.feedbackIds?.length ? (
						<Box className='selected-chip'>
							{[...pipFormDetails?.feedbackIds]?.map((ids: any, index: number) => {
								const selectedFeedback =
									selectedNomineeFeedbackList && selectedNomineeFeedbackList?.length
										? selectedNomineeFeedbackList?.find((rec: any) => rec?.feedbackId === ids)
										: null;
								return index === 0 || index === 1 ? (
									<Chip className='default-gray-chip default-red-chip' label={selectedFeedback?.title || ''} />
								) : (
									<></>
								);
							})}
							{pipFormDetails?.feedbackIds?.length > 2 ? (
								<span className='btn-count'>+{pipFormDetails?.feedbackIds?.length - 2}</span>
							) : (
								<></>
							)}
						</Box>
					) : (
						<Typography className='placeholder-txt'>{t('selectLabel')}</Typography>
					)}
				</Box>
				{selectedNomineeFeedbackList && selectedNomineeFeedbackList?.length === Enums.ZERO ? (
					<Box className='linked-feedback-des'>
						<Typography variant='h6'>{t('noFeedbackGivenOrReviewed')}</Typography>
					</Box>
				) : (
					<></>
				)}
				{openFeedbackDropdown && (
					<ClickAwayListener onClickAway={handleFeedbackClickAway}>
						<Box className='ques-type-dropdown'>
							<List component='div' disablePadding>
								{selectedNomineeFeedbackList && selectedNomineeFeedbackList?.length ? (
									selectedNomineeFeedbackList?.map((feedback: any, feedbackIndex: number) => {
										const {
											feedbackId,
											// feedbackTypeId,
											feedbackType,
											timeframeStartDate,
											timeframeEndDate,
											title,
											// feedbackStatusId,
											// feedbackStatus,
											// summary,
											// feedbackCategory,
											// feedbackCategoryId,
										} = feedback;
										const feedbackIds =
											pipFormDetails?.feedbackIds && pipFormDetails?.feedbackIds?.length
												? pipFormDetails?.feedbackIds
												: [];
										return (
											<>
												<ListItem
													component='div'
													key={`feedback_item_${feedbackId}_${feedbackIndex}`}
													id={`feedback_item_${feedbackId}_${feedbackIndex}`}
												>
													<ListItemIcon>
														<Checkbox
															icon={<BorderCheckboxIcon />}
															checkedIcon={<CheckedIcon />}
															id={`feedback_checkbox_${feedbackId}_${feedbackIndex}`}
															checked={feedbackIds.includes(feedbackId)}
															onChange={(e) => handleFeedbackSelectionClick(e, feedback)}
														/>
													</ListItemIcon>
													<ListItemText className='feedback-details' disableTypography>
														<Box className='team-info'>
															<Chip className='default-gray-chip default-blue-chip' label={feedbackType || ''} />
															<Typography variant='h4'>{title || ''}</Typography>
															{timeframeStartDate && timeframeEndDate ? (
																<Typography variant='h6' className='font-weight-normal'>
																	<CalendarIcon />{' '}
																	{timeframeStartDate && timeframeEndDate ? (
																		`${getMonthDateYearTime(timeframeStartDate)} - ${getMonthDateYearTime(
																			timeframeEndDate
																		)}`
																	) : (
																		<></>
																	)}
																</Typography>
															) : (
																<></>
															)}
														</Box>
													</ListItemText>
												</ListItem>
											</>
										);
									})
								) : (
									<></>
								)}
							</List>
						</Box>
					</ClickAwayListener>
				)}
			</Box>
		</Box>
	);
};
