import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	Typography,
	List,
	ListItem,
	Avatar,
	FormControlLabel,
	Checkbox,
	Chip,
	Tooltip,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../../../Common/OKRButton';
import { DeleteIcon, LikeThumbIcon, SearchIcon } from '../../../../../config/svg/CommonSvg';
import { CrossIcon, SortingIcon, TickIcon } from '../../../../../config/svg/CommonSvgIcon';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../../../config/svg/formElementIcons';
import { EnumPerformanceGoalCycleStatus, Enums } from '../../../../../config/enums';
import { useSelector } from 'react-redux';
import { AnimatedIcon } from '../../../CommonComponent/AnimatedIcon';
import { getFullName, getMonthDateYear, getUserName } from '../../../../../config/utils';
import NouserImg from '../../../../../images/search-no-colleagues.svg';
import AlertDialog from '../../../../Common/Dialog';
import { NoRecord } from '../../../CommonComponent/NoRecord';

export const GoalCycleAssignUsersList: React.FC<any> = (props: any) => {
	const {
		selectedUsersOptions,
		setSelectedUsersOptions,
		setIsGoalCycleFormEdited,
		goalCycleData,
		setGoalCycleData,
		loader,
		currentUsersAddedCount,
		setCurrentUsersAddedCount,
		currentUsersRemovedCount,
		setCurrentUsersRemovedCount,
		tooltipText,
		handleDelete,
	} = props;
	const { t } = useTranslation();

	const [localUserList, setLocalUserList] = useState<any>([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	const [checkedUser, setCheckedUser] = useState<any>([]);
	const [checkedAll, setCheckedAll] = useState(false);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [pageIndex, setPageIndex] = useState<number>(1);
	const [visibleCount, setVisibleCount] = useState<number>(10);
	const [modalProps, setModalProps] = useState<any>({
		open: false,
		message: '',
		details: '',
		type: '',
	});

	useEffect(() => {
		if (currentUsersAddedCount > 0 || currentUsersRemovedCount > 0) {
			setTimeout(() => {
				setCurrentUsersAddedCount(0);
				setCurrentUsersRemovedCount(0);
			}, 10000);
		}
	}, [currentUsersAddedCount, currentUsersRemovedCount]);

	useEffect(() => {
		setLocalUserList(selectedUsersOptions && selectedUsersOptions?.length ? selectedUsersOptions : []);
		resetCheckState();
	}, [selectedUsersOptions]);

	// ===================================================

	const fetchLoadMoreList = async (pageIndex: any) => {
		setPageIndex(pageIndex + 1);
		setVisibleCount((x) => x + 10); // add 10 more data
	};

	const resetCheckState = () => {
		setCheckedAll(false);
		setCheckedUser([]);
	};

	const clearSearch = () => {
		setLocalUserList(selectedUsersOptions && selectedUsersOptions?.length ? selectedUsersOptions : []);
		setSearchTextArray('');
		resetCheckState();
	};

	const handleSearchClicked = (e: any) => {
		setSearchValue(e.target.value);
	};

	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			const { value } = e?.target || {};
			if (value && value.trim() !== '') {
				setSearchTextArray(value);
				let updatedUserList = selectedUsersOptions && selectedUsersOptions?.length ? selectedUsersOptions : [];
				updatedUserList = updatedUserList?.filter(
					(rec: any) => rec?.firstName && rec?.firstName?.toLowerCase().includes(value.toLowerCase())
				);
				setLocalUserList(updatedUserList);
				resetCheckState();
			}
			setSearchValue('');
		}
	};
	// =====================================================

	const handleAllChecked = (e: any) => {
		if (e.target.checked) {
			setCheckedAll(true);
			//======= filter Already selected userId
			const newUserList = localUserList.filter((user: any) => {
				if (typeof checkedUser.find((item: any) => item.employeeId === user.employeeId) === 'undefined') {
					return true;
				} else {
					return false;
				}
			});
			//====== get all userId in the page
			let newList = newUserList.map(
				(user: any) =>
					typeof checkedUser.find((item: any) => item.employeeId === user.employeeId) === 'undefined' && {
						...user,
					}
			);
			setCheckedUser(newList.concat(checkedUser) || []);
		} else {
			setCheckedAll(false);

			const filterUnselected = checkedUser.filter((chkUser: any) => {
				if (typeof localUserList.find((item: any) => item.employeeId === chkUser.employeeId) === 'undefined') {
					return true;
				} else {
					return false;
				}
			});
			setCheckedUser(filterUnselected);
		}
	};

	const getSelectedUserLength = () => {
		const selectedUserCount = localUserList.filter((chkUser: any) => {
			if (typeof checkedUser.find((item: any) => item.value === chkUser.value) === 'undefined') {
				return false;
			} else {
				return true;
			}
		});
		return selectedUserCount.length;
	};

	const onUserSelect = (e: any, rec: any, index: number) => {
		const { checked } = e.target;
		if (checked) {
			const isExist = checkedUser.find((chkUser: any) => rec?.employeeId === chkUser?.employeeId);
			if (!isExist) {
				setCheckedUser([...checkedUser, rec]);
			}
		} else {
			const filteredList = checkedUser.filter((chkUser: any) => rec?.employeeId !== chkUser?.employeeId);
			setCheckedUser([...filteredList]);
		}
	};

	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		let updatedEmpList = sortArrayByKey(localUserList, column, newSortOrder);
		updatedEmpList = dataPerPage(updatedEmpList, 1, Enums.MAX_USER_SIZE);
		setSortingColumn(column);
		setLocalUserList(updatedEmpList);
		resetCheckState();
	};

	const sortArrayByKey = (arrayList: any, keyName: string, sortingType: string) => {
		arrayList.sort(function (a: any, b: any) {
			const keyA = a[keyName];
			const keyB = b[keyName];
			if (sortingType === 'asc') {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			} else if (sortingType === 'desc') {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			}
			return 0;
		});
		return arrayList;
	};

	const dataPerPage = (arrayList: any, currPage: any, pageSizeValue: any) => {
		const startIndex = currPage - 1;
		const updatedList = arrayList.slice(startIndex * pageSizeValue, pageSizeValue * currPage);

		return updatedList;
	};

	const handleSelectedUserDeletion = (
		event: any, // button event
		item: any, // array for single or bulk
		indexVal: number, // item index for single item
		deleteType: string // single bulk
	) => {
		setModalProps({
			open: true,
			message: t('selectedRecordsDelete'),
			module: 'confirmation',
			details: {
				headingText: t('selectedRecordsDelete'),
				subHeading: ' ',
				item,
				indexVal,
			},
		});
	};

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			const { item } = modalProps?.details || {};
			let updatedList = [...selectedUsersOptions];
			updatedList = updatedList.filter((rec: any) => !item?.find((role: any) => role?.employeeId === rec.employeeId));
			setSelectedUsersOptions(updatedList);
			setGoalCycleData({ ...goalCycleData, assignedUsersHistory: updatedList });
			setIsGoalCycleFormEdited(true);
			setModalProps({ open: false, message: '', details: '', type: '' });
			handleDelete && handleDelete(updatedList);
		} else {
			setModalProps({ open: false, message: '', details: '', type: '' });
		}
	};

	return (
		<>
			{loader ? (
				<Box className='loading-center-global'>
					<Typography>Loading...</Typography>
				</Box>
			) : (
				<>
					<Box className='assigned-users-list-area' id='container-assigned-users-list-area'>
						{searchTextArray || (selectedUsersOptions && selectedUsersOptions?.length) ? (
							<Box className='title-head'>
								<Box>
									<Box className='assigned-user-title'>
										{currentUsersAddedCount && currentUsersAddedCount > 0 ? (
											<Tooltip
												placement='right'
												open={true}
												arrow
												classes={{ popper: 'assign-user-error-tooltip' }}
												title={
													<Box className='tooltip-text'>
														<LikeThumbIcon />
														<Typography
															dangerouslySetInnerHTML={{
																__html: tooltipText
																	? 'Hey! These are all users mapped to the selected Goal setting cycle'
																	: t('assignedUsersAddedText', { userCount: currentUsersAddedCount }),
															}}
														></Typography>
													</Box>
												}
											>
												<Box>
													<Typography variant='h6' className='text-upper-case'>
														{t('addedUsers')} (
														{selectedUsersOptions && selectedUsersOptions?.length ? selectedUsersOptions?.length : 0})
													</Typography>
												</Box>
											</Tooltip>
										) : (
											<></>
										)}

										{currentUsersRemovedCount && currentUsersRemovedCount > 0 ? (
											<Tooltip
												placement='bottom-start'
												open={true}
												arrow
												classes={{ popper: 'assign-user-error-tooltip' }}
												title={
													<Box className='tooltip-text'>
														<LikeThumbIcon />
														<Typography
															dangerouslySetInnerHTML={{
																__html: t('assignedUsersRemovedText', { userCount: currentUsersRemovedCount }),
															}}
														></Typography>
													</Box>
												}
											>
												<Box>
													<Typography variant='h6' className='text-upper-case'>
														{t('addedUsers')} (
														{selectedUsersOptions && selectedUsersOptions?.length ? selectedUsersOptions?.length : 0})
													</Typography>
												</Box>
											</Tooltip>
										) : (
											<></>
										)}
										{currentUsersAddedCount === 0 && currentUsersRemovedCount === 0 && (
											<Box>
												<Typography variant='h6' className='text-upper-case'>
													{t('addedUsers')} (
													{selectedUsersOptions && selectedUsersOptions?.length ? selectedUsersOptions?.length : 0})
												</Typography>
											</Box>
										)}
									</Box>
									<Typography variant='h6'>{t('addedUsersNotes')}</Typography>
								</Box>
								<Box className='user-search'>
									<TextField
										className='search-text-field'
										id='user-src-field'
										placeholder={t('searchText')}
										fullWidth
										value={searchValue}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<SearchIcon />
												</InputAdornment>
											),
										}}
										onChange={(e) => handleSearchClicked(e)}
										onKeyDown={(e) => searchClicked(e)}
									/>

									{searchTextArray && (
										<Box className='user-search-chip'>
											<List>
												<ListItem>
													{searchTextArray.length > 20 ? (
														<Tooltip title={searchTextArray} arrow>
															<Chip
																label={searchTextArray.substring(0, 20) + '...'}
																onDelete={clearSearch}
																deleteIcon={
																	<span>
																		<CrossIcon />
																	</span>
																}
															/>
														</Tooltip>
													) : (
														<Chip
															label={searchTextArray}
															onDelete={clearSearch}
															deleteIcon={
																<span>
																	<CrossIcon />
																</span>
															}
														/>
													)}
												</ListItem>
											</List>
										</Box>
									)}
								</Box>
							</Box>
						) : (
							<></>
						)}
						{localUserList && localUserList?.length ? (
							<>
								<Box className='assigned-users-listing'>
									<Box className='okr-list-panel-head'>
										<List component='ul' disablePadding>
											<ListItem className='assigned-user-col1'>
												<FormControlLabel
													control={
														<Checkbox
															checked={checkedAll}
															onChange={(e) => handleAllChecked(e)}
															icon={<BorderCheckboxIcon />}
															checkedIcon={<CheckedIcon />}
															indeterminateIcon={<UnCheckedIcon />}
															indeterminate={
																checkedUser &&
																checkedUser.length > 0 &&
																getSelectedUserLength() > 0 &&
																localUserList.length > getSelectedUserLength()
															}
															disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
														/>
													}
													label=''
												/>
												<Typography
													onClick={(e) => sortUserList(e, 'firstName')}
													variant='body2'
													className={sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
													id='sort-name'
												>
													<span className='sortable-label'>
														<span className='txt-wrap'>
															{t('nameLabel')} <SortingIcon />
														</span>
													</span>
												</Typography>
											</ListItem>
											<ListItem className='assigned-user-col2'>
												<Typography
													onClick={(e) => sortUserList(e, 'emailId')}
													className={sortingColumn === 'emailId' ? `sort-title ${sortOrder}` : 'sort-title asc'}
													variant='body2'
												>
													<span className={`sortable-label `}>
														<span className='txt-wrap'>
															{t('emailLabel')} <SortingIcon />
														</span>
													</span>
												</Typography>
											</ListItem>
											<ListItem className='assigned-user-col3'>
												<Typography
													onClick={(e) => sortUserList(e, 'performanceGoalForm')}
													className={
														sortingColumn === 'performanceGoalForm' ? `sort-title ${sortOrder}` : 'sort-title asc'
													}
													variant='body2'
												>
													<span className={`sortable-label `}>
														<span className='txt-wrap'>
															{t('goalForm')} <SortingIcon />
														</span>
													</span>
												</Typography>
											</ListItem>
											<ListItem className='assigned-user-col4'>
												<Typography
													onClick={(e) => sortUserList(e, 'joiningDate')}
													className={sortingColumn === 'joiningDate' ? `sort-title ${sortOrder}` : 'sort-title asc'}
													variant='body2'
												>
													<span className={`sortable-label `}>
														<span className='txt-wrap'>
															{t('doj')} <SortingIcon />
														</span>
													</span>
												</Typography>
											</ListItem>
										</List>
									</Box>
									<Box className='assigned-users-list-content'>
										{localUserList &&
											localUserList.slice(0, visibleCount)?.map((item: any, indexVal: number) => {
												return (
													<Box
														className={`users-list-row ${item?.isActive ? '' : 'deleted-user-row'}`}
														key={`selected_user_role_designation_index_${indexVal}`}
														id={`selected_user_role_designation_index_${indexVal}`}
													>
														<List component='ul' disablePadding>
															<ListItem className='assigned-user-col1'>
																<FormControlLabel
																	control={
																		<Checkbox
																			checked={
																				typeof checkedUser.find((user: any) => item.employeeId === user?.employeeId) !==
																				'undefined'
																			}
																			onChange={(e) => onUserSelect(e, item, indexVal)}
																			icon={<BorderCheckboxIcon />}
																			checkedIcon={<CheckedIcon />}
																			indeterminateIcon={<UnCheckedIcon />}
																			disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
																		/>
																	}
																	label=''
																/>
																<Box className='user-info v-center'>
																	<Box className='user-img'>
																		<Avatar className='avatar-default' src={item?.imagePath || ''}>
																			{getUserName({
																				firstName: item?.firstName || '',
																				lastName: item?.lastName || '',
																				fullName: item?.label || '',
																			})}
																		</Avatar>
																	</Box>

																	<Box className='user-info-details'>
																		<Typography variant='body1'>{getFullName(item)}</Typography>
																	</Box>
																</Box>
															</ListItem>
															<ListItem className='assigned-user-col2'>
																<Typography variant='body1'> {item?.emailId || ''}</Typography>
															</ListItem>
															<ListItem className='assigned-user-col3'>
																<Typography>{item?.performanceGoalForm || ''}</Typography>
															</ListItem>
															<ListItem className='assigned-user-col4'>
																<Typography>{item?.joiningDate ? getMonthDateYear(item?.joiningDate) : ''}</Typography>
																<OKRButton
																	icon={<DeleteIcon />}
																	className='editIcon'
																	handleClick={(event) =>
																		handleSelectedUserDeletion(event, [{ ...item }], indexVal, 'single')
																	}
																	disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
																/>
															</ListItem>
														</List>
													</Box>
												);
											})}
									</Box>
								</Box>
								{visibleCount < localUserList?.length ? (
									<Box className='load-more-area'>
										<OKRButton
											className='load-more-btn btn-small-text'
											handleClick={() => {
												fetchLoadMoreList(pageIndex || 1);
											}}
											text={
												loader ? (
													<Box className='spinner'>
														<Box className='bounce1'></Box>
														<Box className='bounce2'></Box>
														<Box className='bounce3'></Box>
													</Box>
												) : (
													t('loadMore')
												)
											}
										/>
									</Box>
								) : (
									<></>
								)}
							</>
						) : (
							<NoRecord
								noRecordMessage={searchTextArray ? t('noRecordPip') : t('goalCycleAssignUserMainNoRecord')}
								subHead={searchTextArray ? t('noRecordPipText') : t('subNoRecord')}
								noImg={NouserImg}
								{...props}
							/>
						)}
						{checkedUser && checkedUser?.length > 1 ? (
							<Box className='bulk-user-edit-bar'>
								<Box className='bulk-user-selected'>
									<Typography variant='body1'>
										<TickIcon />
										{checkedUser.length} {t('selectedLabel')}
									</Typography>
								</Box>
								<Box className='bulk-user-action'>
									<List component='ul' disablePadding>
										<ListItem>
											<OKRButton
												icon={<DeleteIcon />}
												title={t('delete')}
												handleClick={(event) => handleSelectedUserDeletion(event, checkedUser, 0, 'bulk')}
												className='action-btn'
											></OKRButton>
										</ListItem>
									</List>
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
					{modalProps && modalProps.open && (
						<AlertDialog
							module='PerformanceReviewList'
							isCancel={false}
							message={modalProps?.message || ''}
							handleCloseModal={handleCloseModal}
							modalOpen={modalProps.open}
						/>
					)}
				</>
			)}
		</>
	);
};
