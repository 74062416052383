import React, { useState, useEffect } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@material-ui/core';
import { createUUID } from '../../../config/utils';
// import borderRadius from 'highcharts-border-radius';

// borderRadius(Highcharts);

export const StatusChart = (props) => {
	const { chartData, statusChartKey } = props;
	const [chartDetails, setChartDetails] = useState(null);

	const columnWithGroupedChartData = {
		credits: {
			enabled: false,
		},
		colors: ['#d5dae1', '#ff0000', '#ff9401', '#07a363'],
		// colors: ['#07a363', '#ff9401', '#ff0000', '#d5dae1'],
		chart: {
			type: 'column',
			// inverted: true,
			// polar: true,
			//margin: [0, 0, 0, 0], // top, right, bottom, left

			marginBottom: 100,
			backgroundColor: 'transparent',
			//spacingTop: '0',
			spacingBottom: '0',
			height: '260',
		},
		title: {
			text: '',
			align: 'left',
		},
		tooltip: {
			// outside: true,
			valueSuffix: '',
			borderWidth: 0,
			shadow: false,
			useHTML: true,
			backgroundColor: '#292929',
			borderColor: 'none',
			padding: 0,
			style: {
				color: '#fff',
				fontWeight: 'normal',
				fontFamily: 'Lato',
				fontSize: '12px',
				zIndex: '1',
			},
		},
		// pane: {
		// 	size: '87%',
		// 	innerSize: '20%',
		// 	endAngle: 270,
		// },
		xAxis: {
			title: {
				text: '',
			},
			gridLineColor: 'rgba(161, 171, 185, 0.3)',
			tickInterval: 1,
			labels: {
				style: {
					color: '#416390',
					fontSize: '12px',
					fontWeight: '700',
					fontFamily: 'Lato',
				},
			},

			categories: chartData?.categories,
		},
		yAxis: {
			title: {
				text: '',
			},
			gridLineColor: 'rgba(161, 171, 185, 0.3)',
			min: 0,
			minTickInterval: 1,
			tickInterval: 1,
			labels: {
				style: {
					color: '#416390',
					fontSize: '12px',
					fontWeight: '700',
					fontFamily: 'Lato',
				},
			},
		},
		plotOptions: {
			series: {
				pointWidth: 30,
				//borderRadiusTopLeft: 6,
				//borderRadiusTopRight: 6,
			},
			column: {
				stacking: 'normal',
				//borderRadiusTopLeft: 6,
				//borderRadiusTopRight: 6,
			},
		},
		legend: {
			align: 'left',
			itemWidth: 100,
			padding: 0,
			margin: 0,
			itemMarginTop: 0,
			itemMarginBottom: 8,
			symbolHeight: 8,
			symbolWidth: 8,
			symbolRadius: 6,
			//layout: 'horizontal',
			floating: true,
			width: 200,
			itemDistance: 0,
			verticalAlign: 'bottom',
			x: -10,
			itemStyle: {
				color: '#292929',
				fontWeight: 'bold',
				fontFamily: 'Lato',
				fontSize: '12px',
			},
		},
		series: [],
	};

	useEffect(() => {
		if (chartData && chartData?.series && chartData?.series?.length) {
			columnWithGroupedChartData.series =
				[...chartData?.series].map((item) => {
					const data = { ...item };
					return data;
				}) || [];
			setChartDetails(columnWithGroupedChartData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartData]);

	return (
		<Box className='status-react-chart-wrap' key={createUUID() || statusChartKey}>
			<HighchartsReact highcharts={Highcharts} options={chartDetails} />
		</Box>
	);
};
