import * as React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getOkrProgressDetails } from '../../config/utils';

export interface CircularProgressProps {
	percentage: number;
	dueDate?: String;
	score?: String;
	colorCode?: String;
}

const CircularProgress: React.SFC<CircularProgressProps> = (props) => {
	const { color } = getOkrProgressDetails({
		value: props.score,
		dueDate: props.dueDate,
	});
	const [showProgress, setShowProgress] = React.useState(false);
	setTimeout(() => {
		setShowProgress(true);
	}, 50);
	return (
		<>
			{showProgress ? (
				<CircularProgressbar
					styles={buildStyles({
						// Rotation of path and trail, in number of turns (0-1)
						textSize: '20px',
						trailColor: '#E3E6EA',
						pathColor: props.colorCode ? `#${props.colorCode}` : color,
						textColor: '#292929',
					})}
					strokeWidth={12}
					value={props.percentage}
					text={`${props.percentage}%`}
				></CircularProgressbar>
			) : (
				<></>
			)}
		</>
	);
};

export default CircularProgress;
