import React, { Fragment, useState, useEffect } from 'react';
import {
	Avatar,
	Box,
	Collapse,
	FormControlLabel,
	FormLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/engagement/one-on-one.scss';
import { ErrorIcon, TitleIcon } from '../../../config/svg/formElementIcons';
import { AddIconSvg, DashIcon } from '../../../config/svg/CommonSvgIcon';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { roleRemovePlugin } from '../../Common/CkEditor/CkEditorEnums';
import {
	checkIsInitialListCall,
	createUUID,
	formatOneOnOneRequestDate,
	getGlobalTaskListDueDate,
	getMonthDateYearTime,
	getPriorityName,
	getUserName,
	urlPatternValidation,
} from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';
import { OneOnOneTaskList } from './OneOnOneTaskList';
import { Enums } from '../../../config/enums';
import AlertDialog from '../../Common/Dialog';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import noRecordImg from '../../../images/no-found-notes.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { AddLinkIcon, ContentCopyIcon } from '../../../config/svg/CommonSvg';
import { CreateTask } from '../../GlobalTask/CreateTask';
import { useDispatch, useSelector } from 'react-redux';
import {
	completedGlobalTask,
	deleteGlobalTask,
	fetchGlobalTaskPending,
	getAllGlobalTask,
	getRecentAssignee,
} from '../../../action/globalTask';
import { getKrListing, updateTask } from '../../../action/myGoal';
import { RightArrow, RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { TaskList } from '../../GlobalTask/TaskList';
import NotesImg from '../../../images/no-found-notes.svg';
import { MultiSelect } from '../../Admin/CommonComponent/MultiSelect';
import { NatureMeetingOptions } from '../../../config/constant';
import { NoSearchRecord } from '../../Admin/CommonComponent/NoSearchRecord';
import { getOkrMasterData } from '../../../action/myGoal';

export const LaunchTemplate = (props: any) => {
	const {
		openLaunchForm,
		setOpenLaunchForm,
		oneToOneTitleOnLaunchError,
		setOneToOneTitleLaunchError,
		userDetail,
		currentUser,
		handleBackButtonOnClick,
		handleSaveLaunchEditRequestAPICall,
		isLaunchFormEdited,
		setIsLaunchFormEdited,
		addUpdateTask,
		handleCompleteTask,
		// handleConfirmDeleteTaskClick,
		loader,
		setLoader,
		copiedTaskList,
		setIsCreateLinkPopupOpen,
		select1on1Tab,
		setSelect1on1Tab,
		allTaskList,
		setAllTaskList,
		OtherAllTaskList,
		setOtherAllTaskList,
		setCopiedTaskList,
		updateTaskList,
		resetHighlightOneOnOneTask,
		oneToOneSettings,
		oneToOneAgendaError,
		setOneToOneAgendaError,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const staticErrorObj = {
		error: false,
		helperText: '',
		type: '',
	};
	const defaultFormDetails = {
		taskName: '',
		priority: Enums.ONE,
		taskDueDate: null,
		assignedTo: Enums.ZERO,
		userDetails: null,
		goalTypeId: Enums.ZERO,
		goalId: Enums.ZERO,
		linkedKRDetails: null,
		isCheckedInLinked: false,
	};
	const [taskPagingInfo, setTaskPagingInfo] = useState<any>(null);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	const [formDetails, setFormDetails] = useState<any>({
		taskName: '',
		taskDueDate: null, // new Date(),
	});
	const [formDetailsTask, setFormDetailsTask] = useState(defaultFormDetails);
	const [isMeetingLinkValid, setIsMeetingLinkValid] = useState<boolean>(false);
	const [isMeetingLinkCopied, setIsMeetingLinkCopied] = useState<{ copied: boolean }>({ copied: false });
	const [completeByDate, setCompleteBy] = useState<any>(null);
	const [taskDueDateError, setTaskDueDateError] = useState<any>(staticErrorObj);
	const [oneToOneMeetingLinkError, setOneToOneMeetingLinkError] = useState<any>(staticErrorObj);
	const [taskNameError, setTaskNameError] = useState<any>(staticErrorObj);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, details: null });
	const [openTabChangeDialog, setOpenTabChangeDialog] = useState<any>({ open: false, details: null });
	const [collapse, setCollapse] = useState<boolean>(false);
	const [collapsePrivate, setCollapsePrivate] = useState<boolean>(false);
	const [taskListHeight, setTaskListHeight] = useState<any>({ myHeight: null, otherHeight: null });
	const [otherTaskListHeight, setOtherTaskListHeight] = useState<any>({ myHeight: null, otherHeight: null });
	const [isUserAssigned, setIsUserAssigned] = useState<boolean>(false);
	const [assignedEmployeeId, setAssignedEmployeeId] = useState<Number>(userDetail?.employeeId);
	const [dueDateError, setDueDateError] = useState<any>(null);
	const [natureOfMeetingSelected, setNatureOfMeetingSelected] = useState<any>([{ ...NatureMeetingOptions[0] }]);

	useEffect(() => {
		dispatch(getOkrMasterData());

		const { myTasks, otherTasks } = formDetails?.tasksDetails || {};
		if (otherTasks && otherTasks.length > 5) {
			const element = document.querySelectorAll('.task-list-wrap-other .accomplish-task');
			//setTimeout(() => {
			const details = handleTaskListingHeight(element, 'other');
			setOtherTaskListHeight(details);
			//}, 100);
		}

		if (myTasks && myTasks.length > 5) {
			const element = document.querySelectorAll('.task-list-self .accomplish-task');
			//setTimeout(() => {
			const details = handleTaskListingHeight(element, 'my');
			setTaskListHeight(details);
			//}, 100);
		}
		if (formDetails?.oneToOnePrivateNotes) {
			setCollapsePrivate(true);
		}
	}, [formDetails]);

	useEffect(() => {
		if (openLaunchForm && openLaunchForm?.open && openLaunchForm?.details) {
			const { notesDetails, tasksDetails, isTaskAddUpdated } = openLaunchForm?.details || {};
			const { myNotes, otherNotes } = notesDetails || {};
			const oneToOnePublicNotesData =
				myNotes && myNotes.length > 0 ? myNotes.find((item: any) => !item.isPrivate) : null;
			const oneToOnePrivateNotesData =
				myNotes && myNotes.length > 0 ? myNotes.find((item: any) => item.isPrivate) : null;
			const notesData = isTaskAddUpdated
				? { ...formDetails, tasksDetails: tasksDetails ? { ...tasksDetails } : null }
				: {
						publicNoteId: oneToOnePublicNotesData?.noteId || 0,
						oneToOnePublicNotes: oneToOnePublicNotesData?.description || '',
						privateNoteId: oneToOnePrivateNotesData?.noteId || 0,
						oneToOnePrivateNotes: oneToOnePrivateNotesData?.description || '',
				  };
			if (openLaunchForm.details.isReciver && okrMasterData?.oneToOneSettingResponse?.meetingNotesTakenBy === 1) {
				setSelect1on1Tab(2);
			}
			if (
				openLaunchForm?.details &&
				openLaunchForm?.details.natureOfMeeting !== null &&
				openLaunchForm?.details.natureOfMeeting !== undefined
			) {
				setNatureOfMeetingSelected(
					NatureMeetingOptions.filter((item: any) => item.id === openLaunchForm?.details.natureOfMeeting)
				);
			}
			setFormDetails({
				...openLaunchForm?.details,
				...notesData,
				natureOfMeeting: openLaunchForm?.details.natureOfMeeting,
				otherNotes: otherNotes && otherNotes.length > 0 ? otherNotes.filter((item: any) => !item?.isPrivate) : [],
			});
			const isTrueVal = openLaunchForm?.details?.meetingLink
				? urlPatternValidation(openLaunchForm?.details?.meetingLink)
				: false;
			setIsMeetingLinkValid(isTrueVal);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openLaunchForm]);
	useEffect(() => {
		if (copiedTaskList && copiedTaskList.length) {
			const record = openLaunchForm?.details || formDetails;
			// let updatedTasksDetails = null;
			let updatedRecord = { ...record };
			const allFinalTask: any = [];
			copiedTaskList?.forEach((copyTask: any) => {
				let requestPayload1: any = {
					taskName: copyTask?.taskName ? copyTask?.taskName.trim() : '',
					dueDate: null,
					priorityId: copyTask?.priorityId || Enums.ONE,
					priority: copyTask?.priority || 'Low',
					assignedTo: copyTask?.assignedBy || Enums.ZERO,
					goalTypeId: copyTask?.goalTypeId || Enums.ZERO,
					goalId: copyTask?.goalId || Enums.ZERO,
					taskType: Enums.THREE,
					isCompleted: false,
					oneToOneDetailId: copyTask?.oneToOneDetailId,
					linkedKRDetails: copyTask?.goalLinkDetails
						? { ...copyTask?.goalLinkDetails, isLinkGreyedOut: false }
						: copyTask?.linkedKRDetails
						? { ...copyTask?.linkedKRDetails, isLinkGreyedOut: false }
						: null,
					delegateTo: null,
					isError: true,
					copiedTaskId: copyTask?.taskId || null,
					isCheckedInLinked: copyTask?.isCheckedInLinked || false,
				};
				requestPayload1 = handleSaveCallBack(requestPayload1, 'direct');
				allFinalTask.push({ ...requestPayload1 });
			});
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			const myTasksData = tasksCopy?.filter((it: any) => !it?.copiedTaskId);

			setAllTaskList([...myTasksData, ...allFinalTask]);
			// const { tasksDetails } = record || {};
			// if (tasksDetails) {
			// 	const { myTasks, otherTasks } = tasksDetails || {};
			// 	const myTasksData = myTasks?.filter((it: any) => !it?.copiedTaskId);
			// 	updatedTasksDetails = { myTasks: [...myTasksData, ...allFinalTask], otherTasks };
			// } else {
			// 	updatedTasksDetails = { myTasks: [...allFinalTask], otherTasks: [] };
			// }
			// updatedRecord = { ...updatedRecord, tasksDetails: updatedTasksDetails };
			const finalDetails = {
				...openLaunchForm,
				details: { ...updatedRecord, isTaskAddUpdated: true },
			};
			setOpenLaunchForm(finalDetails);
		} else {
			const record = openLaunchForm?.details || formDetails;
			let updatedRecord = record ? { ...record } : {};
			const allFinalTask: any = [];
			const updatedAllTaskList = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			if (updatedAllTaskList?.length) {
				const myTasksData = updatedAllTaskList?.filter((it: any) => !it?.copiedTaskId);
				setAllTaskList([...myTasksData]);
			} else {
				setAllTaskList([...allFinalTask]);
			}
			const finalDetails = {
				...openLaunchForm,
				details: { ...updatedRecord, isTaskAddUpdated: true },
			};
			setOpenLaunchForm(finalDetails);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [copiedTaskList]);

	const handleTitleChange = (e: any) => {
		e.preventDefault();
		const { name, value } = e?.target || {};
		setIsLaunchFormEdited(true);
		setFormDetails({ ...formDetails, [name]: value });
	};
	const handleAgendaChange = (value: any, fieldName: string) => {
		if (value && value !== formDetails[fieldName]) {
			setIsLaunchFormEdited(true);
		}
		setFormDetails({ ...formDetails, [fieldName]: value });
	};
	const handleSaveLaunchEditRequestClick = (event: any) => {
		let remarkText = formDetails?.oneToOneRemark.trim();
		let isValid: boolean = true;
		if (
			!openLaunchForm?.details?.isReciver &&
			oneToOneSettings?.isOneToOneAgendaRequired &&
			(!remarkText ||
				remarkText.trim() === '' ||
				remarkText.trim() === '<p></p>' ||
				remarkText.trim() === '<p><br></p>')
		) {
			setOneToOneAgendaError({
				error: true,
				helperText: 'Please enter Agenda',
				type: 'oneToOneAgenda',
			});
			setCollapse(true);
			window.scrollTo(0, 0);
			isValid = false;
		}
		if (!(!formDetails?.meetingLink || (formDetails?.meetingLink && urlPatternValidation(formDetails?.meetingLink)))) {
			setOneToOneMeetingLinkError({
				error: true,
				helperText: 'Please enter valid meeting link',
				type: 'oneToOneMeetingLink',
			});
			isValid = false;
		}
		if (isValid) {
			handleSaveLaunchEditRequestAPICall(event, formDetails);
		}
	};
	const handleTaskOnEnterCreate = (e: any) => {
		// e.keyCode === 27 for Esc
		if (e.keyCode === 13 && formDetails?.taskName && formDetails?.taskName.trim() !== '') {
			handleAddNewTask(e);
		} else if (e.keyCode === 27) {
			handleCancelTask(e);
		}
	};
	const handleTaskOnChange = (e: any) => {
		e.preventDefault();
		const { name, value } = e?.target || {};
		setIsLaunchFormEdited(true);
		setTaskNameError(staticErrorObj);
		setFormDetails({ ...formDetails, [name]: value });
	};
	const handleAddTaskDueDateChange = (value: any) => {
		setIsLaunchFormEdited(true);
		setTaskDueDateError(staticErrorObj);
		setFormDetails({ ...formDetails, taskDueDate: value });
	};
	const handleAssignUserOnChange = (isUserAssigned: any, employeeId: any) => {
		setIsLaunchFormEdited(true);
		setIsUserAssigned(isUserAssigned);
		setAssignedEmployeeId(employeeId);
		setFormDetails({ ...formDetails, isUserAssigned: isUserAssigned });
	};

	const validateCreateTask = (taskName: any, taskDueDate: any, isEdit: boolean) => {
		if (!isEdit) {
			if (!taskName) {
				setTaskNameError({
					error: true,
					type: 'taskName',
					helperText: 'Please enter task name',
				});
				return false;
			} else if (taskName && !taskName.trim()) {
				setTaskNameError({
					error: true,
					type: 'taskName',
					helperText: 'Please enter task name',
				});
				return false;
			}
			if (!taskDueDate) {
				setTaskDueDateError({
					type: 'taskDueDate',
					helperText: 'Please select a due date',
				});
				return false;
			} else {
				return true;
			}
		} else {
			if (!taskName) {
				enqueueSnackbar('Please enter task name', {
					variant: 'error',
					autoHideDuration: 3000,
				});
				return false;
			} else if (taskName && !taskName.trim()) {
				enqueueSnackbar('Please enter task name', {
					variant: 'error',
					autoHideDuration: 3000,
				});
				return false;
			}
			if (!taskDueDate) {
				enqueueSnackbar('Please select a due date', {
					variant: 'error',
					autoHideDuration: 3000,
				});
				return false;
			} else {
				return true;
			}
		}
	};
	const handleCancelTask = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setCompleteBy(null);
		setIsUserAssigned(false);
		setAssignedEmployeeId(userDetail?.employeeId);
		setFormDetails({ ...formDetails, taskName: '', taskDueDate: null });
	};
	const handleAddNewTask = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (validateCreateTask(formDetails?.taskName, formDetails?.taskDueDate, false)) {
			setTaskDueDateError(staticErrorObj);
			setTaskNameError(staticErrorObj);
			const newTask = {
				goalId: formDetails?.oneToOneDetailId || Enums.ZERO,
				taskName: formDetails?.taskName ? formDetails?.taskName.trim() : '',
				dueDate: formatOneOnOneRequestDate(formDetails?.taskDueDate || new Date()),
				goalTypeId: Enums.THREE,
				taskType: Enums.THREE,
				isCompleted: false,
				assignedTo: assignedEmployeeId || 0,
				assignedBy: userDetail?.employeeId,
				assignedByEmailId: isUserAssigned ? openLaunchForm?.details?.emailId : userDetail?.emailId,
				assignedByFirstName: isUserAssigned ? openLaunchForm?.details?.firstName : userDetail?.firstName,
				assignedByLastName: isUserAssigned ? openLaunchForm?.details?.lastName : userDetail?.lastName,
				assignedByImagePath: isUserAssigned ? openLaunchForm?.details?.imagePath : userDetail?.imagePath,
			};
			addUpdateTask(newTask, false, openLaunchForm?.details || formDetails);
			setFormDetails({ ...formDetails, taskName: '', taskDueDate: null });
			setCompleteBy(null);
			setIsUserAssigned(false);
			setAssignedEmployeeId(userDetail?.employeeId);
		} else {
			console.log('handleAddNewTask ===> ', taskDueDateError, taskNameError);
		}
	};

	// const handleTaskUpdate = (e: any, taskDetail: any, taskIndex: number) => {
	// 	if (validateCreateTask(taskDetail?.taskName, taskDetail?.dueDate, true)) {
	// 		const requestParams = {
	// 			taskId: taskDetail?.taskId || 0,
	// 			taskName: taskDetail?.taskName.trim(),
	// 			goalId: taskDetail?.goalId || formDetails?.oneToOneDetailId || Enums.ZERO,
	// 			taskType: taskDetail?.taskType || Enums.THREE,
	// 			dueDate: formatOneOnOneRequestDate(taskDetail?.dueDate || new Date()),
	// 		};
	// 		addUpdateTask(requestParams, true, openLaunchForm?.details || formDetails);
	// 		setFormDetails({ ...formDetails, taskName: '', taskDueDate: null });
	// 		setCompleteBy(null);
	// 		setIsUserAssigned(false);
	// 		setAssignedEmployeeId(userDetail?.employeeId);
	// 	} else {
	// 		console.log('handleTaskUpdate ===> ', taskDetail);
	// 	}
	// };

	// const handleDeleteTask = (e: any, task: any, oneOnOneDetails: any) => {
	// 	if (task?.taskId && task?.isTaskAddedNew) {
	// 		handleConfirmDeleteTaskClick(e, task, oneOnOneDetails, true);
	// 	} else {
	// 		setOpenDeleteDialog({ open: true, details: { task, oneOnOneDetails } });
	// 	}
	// };
	// const handleDialogCancelClick = (e: any, type: any) => {
	// 	e.preventDefault();
	// 	if (type === 2) {
	// 		const { task, oneOnOneDetails } = openDeleteDialog?.details || {};
	// 		if (task?.taskId) {
	// 			setOpenDeleteDialog({ open: false, details: null });
	// 			handleConfirmDeleteTaskClick(e, task, oneOnOneDetails, false);
	// 		}
	// 	} else {
	// 		setOpenDeleteDialog({ open: false, details: null });
	// 	}
	// };

	// Not in Use
	const handleEditMode = (e: any, index: number, allTasks: any) => {
		const tasksCopy = [...allTasks];
		if (!tasksCopy[index].isCompleted) {
			tasksCopy[index].openEditMode = true;
			setFormDetails({ ...formDetails, tasksDetails: { ...formDetails?.tasksDetails, myTasks: tasksCopy } });
		}
	};
	const handleBlurTask = (e: any, taskIndex: any, allTasks: any) => {
		const tasksCopy = [...allTasks];
		if (tasksCopy[taskIndex].openEditMode && !tasksCopy[taskIndex].isEditMode) {
			tasksCopy[taskIndex].openEditMode = false;
			setFormDetails({ ...formDetails, tasksDetails: { ...formDetails?.tasksDetails, myTasks: tasksCopy } });
		}
	};
	const handleCancelUpdate = (e: any, index: number, allTasks: any) => {
		const { myTasks } = openLaunchForm?.details?.tasksDetails || [];
		const tasksCopy = [...allTasks];
		tasksCopy[index].taskName = myTasks[index].history ? myTasks[index].history : tasksCopy[index].taskName;

		tasksCopy[index].dueDate = myTasks[index].historyDate ? myTasks[index].historyDate : null;
		tasksCopy[index].momentDueDate = myTasks[index].historyDate ? moment(new Date(myTasks[index].historyDate)) : null;
		tasksCopy[index].isError = myTasks[index].historyDate ? false : true;
		tasksCopy[index].isEditMode = false;
		tasksCopy[index].openEditMode = false;
		tasksCopy.map((task: any) => {
			task.disabled = false;
		});
		setFormDetails({ ...formDetails, tasksDetails: { ...formDetails?.tasksDetails, myTasks: tasksCopy } });
	};
	// const handleTaskOnEnterEdit = (e: any, task: any, index: number, allTasks: any) => {
	// 	if (e.keyCode === 13 && task && task.taskName.trim() !== '') {
	// 		e.preventDefault();
	// 		e.stopPropagation();
	// 		handleTaskUpdate(e, task, index);
	// 	} else if (e.keyCode === 27) {
	// 		handleCancelUpdate(e, index, allTasks);
	// 	}
	// };
	// const handleTaskChange = (e: any, index: number, allTasks: any) => {
	// 	const tasksCopy = [...allTasks];
	// 	const { value } = e.target;
	// 	tasksCopy[index].taskName = value;
	// 	tasksCopy[index].isEditMode = true;
	// 	tasksCopy.map((task: any) => {
	// 		if (!task.isEditMode) {
	// 			task.disabled = true;
	// 		}
	// 	});
	// 	setIsLaunchFormEdited(true);
	// 	setFormDetails({ ...formDetails, tasksDetails: { ...formDetails?.tasksDetails, myTasks: tasksCopy } });
	// };
	const handleAssignUserListChange = (index: number, allTasks: any, isUserAssigned: boolean) => {
		const tasksCopy = [...allTasks];
		tasksCopy[index].assignedTo = isUserAssigned
			? openLaunchForm?.details?.isReciver
				? openLaunchForm?.details?.requestedFrom
				: openLaunchForm?.details?.requestedTo
			: userDetail?.employeeId;
		tasksCopy[index].assignedBy =
			isUserAssigned || tasksCopy[index].isTaskAddedNew ? userDetail?.employeeId : tasksCopy[index].historyAssignedBy;
		tasksCopy[index].assignedByEmailId = isUserAssigned ? openLaunchForm?.details?.emailId : userDetail?.emailId;
		tasksCopy[index].assignedByImagePath = isUserAssigned ? openLaunchForm?.details?.imagePath : userDetail?.imagePath;
		tasksCopy[index].assignedByFirstName = isUserAssigned ? openLaunchForm?.details?.firstName : userDetail?.firstName;
		tasksCopy[index].assignedByLastName = isUserAssigned ? openLaunchForm?.details?.lastName : userDetail?.lastName;
		setFormDetails({ ...formDetails, tasksDetails: { ...formDetails?.tasksDetails, myTasks: tasksCopy } });
	};
	const handleDueDateChangeInList = (value: any, index: number, dateRange: any, allTasks: any) => {
		const tasksCopy = [...allTasks];
		tasksCopy[index].dueDate = value;
		tasksCopy[index].momentDueDate = dateRange || moment(new Date(value));
		tasksCopy[index].isEditMode = true;
		tasksCopy[index].isError = false;
		if (tasksCopy[index].copiedTaskId) {
			delete tasksCopy[index].copiedTaskId;
		}
		tasksCopy.map((task: any) => {
			if (!task.isEditMode) {
				task.disabled = true;
			}
		});
		setIsLaunchFormEdited(true);
		setFormDetails({ ...formDetails, tasksDetails: { ...formDetails?.tasksDetails, myTasks: tasksCopy } });
	};

	const handleToggle = (toggleType: any) => {
		if (toggleType === 'PrivateNoteToggle') {
			setCollapsePrivate(!collapsePrivate);
		} else {
			setCollapse(!collapse);
		}
	};

	const handleTaskListingHeight = (element: any, taskType: string) => {
		let newHeight = 0;
		let details: any = { myHeight: null, otherHeight: null };
		element &&
			element.length > 0 &&
			element.forEach((item: any, index: any) => {
				if (index < 5) {
					newHeight = newHeight + item.clientHeight;
				}
				if (index === 5) {
					if (taskType === 'my') {
						details.myHeight = newHeight + 10;
					}
					if (taskType === 'other') {
						details.otherHeight = newHeight + 10;
					}
				} else if (index < 5) {
					setTaskListHeight({ myHeight: null, otherHeight: null });
					details = { myHeight: null, otherHeight: null };
				}
			});

		return details;
	};

	const handleMeetingLinkChange = (e: any) => {
		const { name, value } = e?.target || {};
		const isTrueVal = value && urlPatternValidation(value);
		setIsLaunchFormEdited(true);
		setIsMeetingLinkValid(isTrueVal);
		setFormDetails({ ...formDetails, [name]: value });
	};
	const handleCopyMeetingLinkClick = () => {
		setIsMeetingLinkCopied({ copied: true });
		navigator.clipboard.writeText(formDetails?.meetingLink || '');
		setTimeout(() => {
			setIsMeetingLinkCopied({ copied: false });
		}, 10000);
	};

	const handleJoinMeetingLink = (e: any) => {
		if (formDetails?.meetingLink) {
			const isLinkValid = urlPatternValidation(formDetails?.meetingLink);
			isLinkValid && window.open(formDetails?.meetingLink, '_blank');
		}
	};

	// ======================================================================
	// ======================================================================
	// ======================================================================
	// ======================================================================
	const dispatch = useDispatch();
	const { krList } = useSelector((state: any) => state.okrReducer);
	const { globalTaskListResponse, globalTaskListStatus } = useSelector((state: any) => state.globalTaskReducer);
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 60);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 120);
	const defaultTaskListRequest = {
		isMyTask: true,
		isCompleted: false,
		pageSize: Enums.MAX_USER_SIZE,
		pageIndex: Enums.ONE,
		order: '',
		sortingText: '',
		finder: [],
		taskFilter: {
			dueDate: [],
			taskType: [],
			priority: [],
		},
		startDate: null,
		endDate: null,
		taskGroupById: Enums.ZERO,
		taskGroupByType: Enums.ZERO,
		moduleId: Enums.THREE,
		employeeId: Enums.ZERO,
		moduleItemKeyId: Enums.ZERO,
	};
	const staticFilterObj = {
		isCompleted: false,
		taskGroupByType: Enums.ZERO,
		sortingText: '',
		order: '',
	};
	const [pageIndexVal, setPageIndex] = useState<number>(1);
	const [filterDetails, setFilterDetails] = useState<any>(staticFilterObj);
	const [globalTaskRequest, setGlobalTaskRequest] = useState<any>(defaultTaskListRequest);
	const [defaultRecentUserList, setDefaultRecentUserList] = useState<any>([]);
	const [completedTaskCount, setCompletedTaskCount] = useState<number>(0);
	const [toDoTaskCount, setToDoTaskCount] = useState<number>(0);
	const [taskGroupByList, setTaskGroupByList] = useState<any>(null);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	const [selectedFilterDateRange, setSelectedFilterDateRange] = useState<any>(null);
	const [openGroupByTask, setOpenGroupByTask] = useState<any>({ taskExpand: false, taskGroupById: 0 });
	const [isDateRangeChanged, setIsDateRangeChanged] = useState<boolean>(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(fetchGlobalTaskPending());
		fetchGlobalTask(Enums.ZERO);
		if (userDetail && userDetail?.employeeId) {
			let data = `empId=${userDetail.employeeId}`;
			dispatch(getKrListing(data));
		}
		getRecentSearchesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (globalTaskListStatus) {
			renderTaskListDetails(globalTaskListResponse, null);
			setLoader(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalTaskListStatus, globalTaskListResponse]);

	const handleTabChange = (event: any, newValue: number) => {
		const isTaskAddedNew = allTaskList?.find((rec: any) => rec?.isTaskAddedNew);
		if (isTaskAddedNew) {
			setOpenTabChangeDialog({ open: true, details: newValue });
		} else {
			switchTab(newValue);
		}
	};
	const handleTabChangeDialogClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			const newValue = openTabChangeDialog?.details || null;
			if (newValue) {
				setOpenTabChangeDialog({ open: false, details: null });
				setAllTaskList(null);
				switchTab(newValue);
			}
		} else {
			setOpenTabChangeDialog({ open: false, details: null });
		}
	};
	const switchTab = (newValue: number) => {
		setSelect1on1Tab(newValue);
		setCollapsePrivate(false);

		if (newValue === Enums.ONE) {
			setAllTaskList(null);
		}
		if (newValue === Enums.TARGET) {
			setOtherAllTaskList(null);
		}

		setLoader(true);
		setPageIndex(1);
		const data = {
			...globalTaskRequest,
			moduleItemKeyId: openLaunchForm?.details?.oneToOneDetailId || formDetails?.oneToOneDetailId || Enums.ZERO,
			employeeId: getEmployeeId(newValue),
			pageIndex: 1,
			isMyTask: getMyTaskDetails(),
			isCompleted: false,
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	function a11yProps(index: number) {
		return {
			id: `user-tab-${index}`,
			'aria-controls': `user-tab-tabpanel-${index}`,
		};
	}
	const getEmployeeId = (type: number) => {
		return type === Enums.ONE
			? userDetail?.employeeId || Enums.ZERO
			: type === Enums.TWO
			? openLaunchForm?.details?.requestedTo !== userDetail?.employeeId
				? openLaunchForm?.details?.requestedTo
				: openLaunchForm?.details?.requestedFrom || Enums.ZERO
			: Enums.ZERO;
	};
	const getRecentSearchesList = () => {
		try {
			getRecentAssigneeList(`goalKeyId=${Enums.ZERO}`);
		} catch (error) {
			console.error(error);
		}
	};
	const renderTaskListDetails = (taskListResp: any, newTask: any) => {
		if (taskListResp?.status === Enums.STATUS_SUCCESS) {
			const { entity, pagingInfo } = taskListResp || {};
			// const { totalPages, pageIndex = 0 } = pagingInfo || {};
			const { completedCount, toDoCount, taskListings, taskGroupByResponses } = entity || {};
			setCompletedTaskCount(completedCount || Enums.ZERO);
			setToDoTaskCount(toDoCount || Enums.ZERO);
			setTaskPagingInfo(pagingInfo);

			if (taskGroupByResponses && taskGroupByResponses.length) {
				setTaskGroupByList(taskGroupByResponses);
			} else {
				setTaskGroupByList([]);
			}
			const updatedTaskListingsData =
				taskListings && taskListings.length
					? taskListings?.filter((rec: any) => rec.taskId !== newTask?.taskId)
					: taskListings;
			const newTaskList = newTask ? [newTask, ...updatedTaskListingsData] : updatedTaskListingsData || [];

			const updatedTaskListings =
				newTaskList && newTaskList.length
					? newTaskList.map((rec: any) => ({
							...rec,
							momentDueDate: rec?.dueDate ? moment(new Date(rec?.dueDate)) : null,
							historyDate: rec?.dueDate,
							history: rec.taskName,
					  }))
					: [];
			updateTaskList(updatedTaskListings, select1on1Tab);
		}
	};
	const getMyTaskDetails = () => {
		if (select1on1Tab === Enums.ONE) {
			return true;
		} else if (select1on1Tab === Enums.TWO) {
			return false;
		}
	};
	const fetchGlobalTask = async (pageIndex: number) => {
		setLoader(true);
		setPageIndex(pageIndex + 1);
		let dateData = {};
		if (selectedFilterDateRange) {
			const startDate = selectedFilterDateRange?.start?._d || new Date();
			const endDate = selectedFilterDateRange?.end?._d || new Date();
			dateData = {
				startDate: formatOneOnOneRequestDate(startDate),
				endDate: formatOneOnOneRequestDate(endDate),
			};
		}
		const data = {
			...globalTaskRequest,
			moduleItemKeyId: openLaunchForm?.details?.oneToOneDetailId || formDetails?.oneToOneDetailId || Enums.ZERO,
			employeeId: getEmployeeId(Enums.ONE),
			pageIndex: pageIndex + 1,
			isMyTask: getMyTaskDetails(),
			...dateData,
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	const getRecentAssigneeList = async (requestParam: any) => {
		try {
			const response: any = await dispatch(getRecentAssignee(requestParam));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				setDefaultRecentUserList(entityList && entityList.length ? entityList : []);
			} else {
				setDefaultRecentUserList([]);
			}
		} catch (error) {
			console.error(error);
			setDefaultRecentUserList([]);
		}
	};
	const resetTabDetails = (resetType: boolean) => {
		setFormDetailsTask(defaultFormDetails);
		// setIsLaunchFormEdited(false);
		if (resetType) {
			setFilterDetails(staticFilterObj);
			setPageIndex(1);
			const data = { ...defaultTaskListRequest, isMyTask: select1on1Tab === Enums.ONE ? true : false };
			setGlobalTaskRequest(data);
		}
	};
	const getAllTask = (data: any) => {
		let requestPayload = { ...data };
		setGlobalTaskRequest(requestPayload);
		dispatch(getAllGlobalTask(requestPayload, false));
	};
	const handleFilterCompletedOnly = (e: any) => {
		const { checked } = e?.target;
		setLoader(true);
		setPageIndex(1);
		// setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			isCompleted: checked,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		// dispatch(getAllGlobalTask(data, false));
		setFilterDetails({
			...filterDetails,
			isCompleted: checked,
			// taskGroupByType: Enums.ZERO,
			// sortingText: '',
			// order: '',
		});
	};
	const handleGroupByFilterClick = (taskGroupByType: number) => {
		setLoader(true);
		setPageIndex(1);
		// setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			taskGroupByType,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		setFilterDetails({ ...filterDetails, taskGroupByType });
	};
	const handleSortingFilterClick = (sortingText: string, order: string) => {
		setLoader(true);
		setPageIndex(1);
		// setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			sortingText,
			order,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		setFilterDetails({ ...filterDetails, sortingText, order });
	};
	const handleTaskNameClick = (e: any, task: any, index: number) => {
		// const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		let tasksCopy: any[] = [];
		if (select1on1Tab === Enums.ONE) {
			tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		} else if (select1on1Tab === Enums.TWO) {
			tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
		}
		if (!task.isCompleted) {
			tasksCopy.forEach((taskVal: any) => {
				if (taskVal.taskId === task.taskId) {
					taskVal.openEditMode = true;
					taskVal.isEditMode = true;
				} else {
					taskVal.openEditMode = false;
					taskVal.isEditMode = false;
				}
			});

			updateTaskList(tasksCopy, select1on1Tab);
		}
	};
	const handleTaskChange = (e: any, index: any, allTasks: any) => {
		// const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		let tasksCopy: any[] = [];
		if (select1on1Tab === Enums.ONE) {
			tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		} else if (select1on1Tab === Enums.TWO) {
			tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
		}

		const { value } = e.target;
		tasksCopy[index].taskName = value;
		tasksCopy[index].isEditMode = true;
		tasksCopy.forEach((task: any) => {
			if (!task.isEditMode) {
				task.disabled = true;
			}
		});

		updateTaskList(tasksCopy, select1on1Tab);
	};

	const handleTaskOnEnterEdit = (e: any, index: number, task: any) => {
		if (e.keyCode === 13 && task && task.taskName.trim() !== '') {
			handleTaskUpdate(task, false, index);
		} else if (e.keyCode === 27) {
			handleEditTaskClose(e, index, task);
		}
	};
	const handleEditTaskClose = (event: any, index: any, task: any) => {
		// const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		let tasksCopy: any[] = [];
		if (select1on1Tab === Enums.ONE) {
			tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		} else if (select1on1Tab === Enums.TWO) {
			tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
		}
		tasksCopy[index].taskName = task?.history || '';
		tasksCopy[index].isEditMode = false;
		tasksCopy[index].openEditMode = false;
		tasksCopy.forEach((task: any) => {
			task.disabled = false;
		});

		updateTaskList(tasksCopy, select1on1Tab);
	};
	const callUpdateOthersTaskDetailsAPI = async (task: any, key1 = '', key2 = '', key3 = '', key4 = '', key5 = '') => {
		if (task?.taskId) {
			let tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
			tasksCopy = tasksCopy.map((taskVal: any) => {
				if (taskVal.taskId === task.taskId) {
					// taskVal = data;
					taskVal.isEditMode = false;
					if (key1) {
						if (key1 === 'assignedTo' && !task[key1]) {
							taskVal[key1] = task?.assignedBy || null;
						} else {
							taskVal[key1] = task[key1];
						}
					}
					if (key2) {
						if (key2 === 'isAnimated') {
							taskVal[key2] = task?.isCompleted;
						} else {
							taskVal[key2] = task[key2];
						}
					}
					if (key3) {
						taskVal[key3] = task[key3];
					}
					if (key4) {
						taskVal[key4] = task[key4];
					}
					if (key5) {
						taskVal[key5] = task[key5];
					}
				}
				return taskVal;
			});

			updateTaskList(tasksCopy, select1on1Tab);
		}
		getRecentSearchesList();
	};
	const callUpdateTaskDetailsAPI = async (task: any, key1 = '', key2 = '', key3 = '', key4 = '', key5 = '') => {
		if (task?.taskId) {
			let tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			tasksCopy = tasksCopy.map((taskVal: any) => {
				if (taskVal.taskId === task.taskId) {
					// taskVal = data;
					taskVal.isEditMode = false;
					if (key1) {
						if (key1 === 'assignedTo' && !task[key1]) {
							taskVal[key1] = task?.assignedBy || null;
						} else {
							taskVal[key1] = task[key1];
						}
					}
					if (key2) {
						if (key2 === 'isAnimated') {
							taskVal[key2] = task?.isCompleted;
						} else {
							taskVal[key2] = task[key2];
						}
					}
					if (key3) {
						taskVal[key3] = task[key3];
					}
					if (key4) {
						taskVal[key4] = task[key4];
					}
					if (key5) {
						taskVal[key5] = task[key5];
					}
				}
				return taskVal;
			});

			updateTaskList(tasksCopy, select1on1Tab);
		}
		getRecentSearchesList();
	};
	const handleTaskUpdate = async (taskDetail: any, isTaskDueDateChange: boolean, index: number) => {
		if (taskDetail?.taskName && taskDetail?.taskName.trim() !== '') {
			let tasksCopy: any[] = [];
			if (select1on1Tab === Enums.ONE) {
				tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			} else if (select1on1Tab === Enums.TWO) {
				tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
			}
			tasksCopy[index].isEditMode = false;
			tasksCopy[index].openEditMode = false;
			tasksCopy.forEach((task: any) => {
				task.disabled = false;
			});

			updateTaskList(tasksCopy, select1on1Tab);
		} else {
			console.log('handleTaskUpdate ===> ', taskDetail);
		}
	};
	const handleTaskLinkChange = (links: any[], task: any) => {
		try {
			let formTaskLink: any[] = [];
			links.forEach((linkArray: any) => {
				formTaskLink.push(linkArray.link);
			});
			const requestPayload = {
				...task,
				link: formTaskLink,
			};
			let index = allTaskList.findIndex((item: any) => item.taskId === task.taskId);
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			tasksCopy[index].link = links;
			setAllTaskList([...tasksCopy]);
			callUpdateTaskDetailsAPI(requestPayload);
		} catch (error) {
			console.error(error);
		}
	};
	const resetCalendarDate = (isDirect: boolean) => {
		if (isDirect) {
			setSelectedFilterDateRange(null);
			setLoader(true);
			setPageIndex(1);
			const data = {
				...globalTaskRequest,
				pageIndex: 1,
				isMyTask: getMyTaskDetails(),
				startDate: null,
				endDate: null,
			};
			setGlobalTaskRequest(data);
			getAllTask(data);
		} else {
			setSelectedFilterDateRange(null);
		}
	};
	/**
	 * Task Name Change action end here
	 */
	const handleCheckInSelectionOnEdit = (e: any, isSelected: any, task: any) => {
		try {
			if (task?.dueDate) {
				const requestPayload = {
					...task,
					isCheckedInLinked: isSelected,
				};
				callUpdateTaskDetailsAPI(requestPayload, 'isCheckedInLinked');
				setDueDateError(null);
			} else {
				setDueDateError({ isError: true, itemIndex: task?.taskId });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleRemoveCheckInSelectionOnEdit = (e: any, isSelected: any, task: any) => {
		try {
			const requestPayload = {
				...task,
				isCheckedInLinked: isSelected,
			};
			callUpdateTaskDetailsAPI(requestPayload, 'isCheckedInLinked');
			setDueDateError(null);
		} catch (error) {
			console.error(error);
		}
	};
	const handlePriorityChangeOnEdit = (e: any, taskType: number, task: any, type: number) => {
		try {
			const requestPayload = {
				...task,
				priority: getPriorityName(t, taskType || Enums.ONE),
				priorityId: taskType || Enums.ONE,
			};
			// updatedSelectedTaskItem(requestPayload, 'priority', 'priorityId');
			if (type === Enums.ONE) {
				callUpdateTaskDetailsAPI(requestPayload, 'priority', 'priorityId');
			} else {
				callUpdateOthersTaskDetailsAPI(requestPayload, 'priority', 'priorityId');
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleAddTaskDueDateChangeOnEdit = (value: any, range: any, task: any, type: number) => {
		try {
			const requestPayload = {
				...task,
				dueDate: value,
				dueDateText: value ? getGlobalTaskListDueDate(new Date(value)) : '',
				overDueText: '',
				isOverDue: false,
			};
			// updatedSelectedTaskItem(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
			if (type === Enums.ONE) {
				callUpdateTaskDetailsAPI(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
			} else {
				callUpdateOthersTaskDetailsAPI(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleResetButtonClickOnEdit = (task: any, type: number) => {
		const requestPayload = {
			...task,
			dueDate: '',
			dueDateText: '',
			overDueText: '',
			isOverDue: false,
			isCheckedInLinked: false,
		};
		// updatedSelectedTaskItem(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
		if (type === Enums.ONE) {
			callUpdateTaskDetailsAPI(
				requestPayload,
				'dueDate',
				'dueDateText',
				'overDueText',
				'isOverDue',
				'isCheckedInLinked'
			);
		} else {
			callUpdateOthersTaskDetailsAPI(
				requestPayload,
				'dueDate',
				'dueDateText',
				'overDueText',
				'isOverDue',
				'isCheckedInLinked'
			);
		}
	};
	const handleKRLinkOnEdit = (e: any, selectedKR: any, task: any, type: number) => {
		if (selectedKR && selectedKR?.goalKeyId) {
			const requestPayload = {
				...task,
				goalTypeId: selectedKR?.goalKeyId ? Enums.TWO : Enums.ZERO,
				goalId: selectedKR?.goalKeyId || Enums.ZERO,
				goalLinkDetails: selectedKR,
			};
			// updatedSelectedTaskItem(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
			if (type === Enums.ONE) {
				callUpdateTaskDetailsAPI(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
			} else {
				callUpdateOthersTaskDetailsAPI(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
			}
		}
	};
	const handleRemoveKROnEdit = (e: any, task: any, type: number) => {
		const requestPayload = {
			...task,
			goalTypeId: Enums.ZERO,
			goalId: Enums.ZERO,
			goalLinkDetails: null,
		};
		// updatedSelectedTaskItem(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
		if (type === Enums.ONE) {
			callUpdateTaskDetailsAPI(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
		} else {
			callUpdateOthersTaskDetailsAPI(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
		}
	};
	const onSelectedUserOnEdit = (selectedUser: any, task: any, type: number) => {
		if (selectedUser && selectedUser?.employeeId) {
			const requestPayload = { ...task, assignedTo: selectedUser };
			// updatedSelectedTaskItem(requestPayload, 'assignedTo');
			if (type === Enums.ONE) {
				callUpdateTaskDetailsAPI(requestPayload, 'assignedTo');
			} else {
				callUpdateOthersTaskDetailsAPI(requestPayload, 'assignedTo');
			}
		}
	};
	// const handleRemoveAssigneeOnEdit = (e: any, task: any) => {
	// 	const requestPayload = { ...task, assignedTo: null };
	const handleRemoveAssigneeOnEdit = (e: any, task: any, isAssigneeSelfRemoved = false, type: number) => {
		const requestPayload = { ...task, assignedTo: isAssigneeSelfRemoved ? task?.assignedBy || null : null };
		// updatedSelectedTaskItem(requestPayload, 'assignedTo');
		if (type === Enums.ONE) {
			callUpdateTaskDetailsAPI(requestPayload, 'assignedTo');
		} else {
			callUpdateOthersTaskDetailsAPI(requestPayload, 'assignedTo');
		}
	};
	const handleCompleteTaskOnEdit = async (e: any, task: any, type: number) => {
		try {
			const { checked } = e.target;
			const updatedTaskDetails = { ...task, isCompleted: checked };
			// updatedSelectedTaskItem(updatedTaskDetails, 'isCompleted');

			if (task?.taskId && task?.isTaskAddedNew) {
				callUpdateTaskDetailsAPI(updatedTaskDetails, 'isCompleted', 'isAnimated');
			} else {
				setLoader(true);
				if (type === Enums.ONE) {
					callUpdateTaskDetailsAPI(updatedTaskDetails, 'isCompleted', 'isAnimated');
				} else {
					callUpdateOthersTaskDetailsAPI(updatedTaskDetails, 'isCompleted', 'isAnimated');
				}
				const requestPayload = { taskId: task.taskId, isCompleted: checked };
				const taskCompleteResponse: any = await dispatch(completedGlobalTask(requestPayload));
				if (Boolean(taskCompleteResponse) && taskCompleteResponse.data.status === 200) {
					// const data = { ...globalTaskRequest };
					// getAllTask(data);
					setLoader(false);
				} else {
					// const responseAPI = taskCompleteResponse?.data?.messageList || {};
					// const keys = Object.keys(responseAPI);
					// const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
					// console.error('handleCompleteTaskOnEdit ===> Error ', messages);
					// showApiMsgs(`${messages} `, 'error');
					setLoader(false);
				}
			}
		} catch (error) {
			// console.error('handleCompleteTaskOnEdit ===> Error ', error);
			// showApiMsgs(t('someErrorOccurred'), 'error');
		}
	};
	const handleDeleteTaskOnEdit = (e: any, task: any) => {
		if (task?.taskId && task?.isTaskAddedNew) {
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			const myTasksData = tasksCopy?.filter((it: any) => it?.taskId !== task?.taskId);

			setAllTaskList([...myTasksData]);
			let updatedCopiedTaskList = copiedTaskList && copiedTaskList?.length ? [...copiedTaskList] : [];
			updatedCopiedTaskList = updatedCopiedTaskList?.filter((rec: any) => rec.taskId !== task?.copiedTaskId);
			setCopiedTaskList([...updatedCopiedTaskList]);
			setTaskPagingInfo({ ...taskPagingInfo, totalRecords: myTasksData.length });
		} else {
			// setOpenDeleteDialog({ open: true, details: { task, oneOnOneDetails } });
			setOpenDeleteDialog({ open: true, details: { task } });
		}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			const { task } = openDeleteDialog?.details || {};
			if (task?.taskId) {
				setOpenDeleteDialog({ open: false, details: null });
				handleConfirmDeleteTaskClick(e, task);
			}
		} else {
			setOpenDeleteDialog({ open: false, details: null });
		}
	};
	const handleConfirmDeleteTaskClick = async (e: any, taskDetails: any) => {
		try {
			setLoader(true);
			const requestParams = `taskId=${taskDetails?.taskId}`;
			const deleteTaskResponse: any = await dispatch(deleteGlobalTask(requestParams));
			if (Boolean(deleteTaskResponse) && deleteTaskResponse?.data?.status === 200) {
				const data = { ...globalTaskRequest };
				getAllTask(data);

				setLoader(false);
			} else {
				const responseAPI = deleteTaskResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				// showApiMsgs(`${messages} `, 'error');
				setLoader(false);
			}
		} catch (error) {
			// showApiMsgs(t('someErrorOccurred'), 'error');
			setLoader(false);
		}
	};
	const handleCollapseTask = (e: any, taskGroupById: any) => {
		setOpenGroupByTask({
			taskExpand: taskGroupById === openGroupByTask?.taskGroupById ? !openGroupByTask?.taskExpand : true,
			taskGroupById: taskGroupById,
		});
		if (taskGroupById !== openGroupByTask?.taskGroupById) {
			setAllTaskList(null);
			setLoader(true);
			setPageIndex(1);
			const data = { ...globalTaskRequest, pageIndex: 1, taskGroupById: taskGroupById, isMyTask: getMyTaskDetails() };
			setGlobalTaskRequest(data);
			getAllTask(data);
		}
	};
	const handleSearchText = (searchText = '') => {
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			pageIndex: 1,
			isMyTask: getMyTaskDetails(),
			finder: searchText && searchText?.trim() !== '' ? [searchText] : [],
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	// ======================================================================
	// ======================================================================
	// ======================================================================
	// ======================================================================
	const handleSaveCallBack = (createRequest: any, type = '') => {
		let requestPayload = { ...createRequest };
		requestPayload = { ...requestPayload };
		const newTask = {
			oneToOneDetailId: requestPayload?.oneToOneDetailId || formDetails?.oneToOneDetailId || Enums.ZERO,
			taskName: requestPayload?.taskName ? requestPayload?.taskName.trim() : '',
			history: requestPayload?.taskName ? requestPayload?.taskName.trim() : '',
			goalId: requestPayload?.goalId || Enums.ZERO,
			goalTypeId: requestPayload?.goalId ? Enums.TWO : Enums.ZERO,
			taskType: 'Request1To1',
			taskTypeId: Enums.THREE,
			isCompleted: false,
			taskId: createUUID(),
			isTaskAddedNew: true,
			isEdited: false,
			disabled: false,
			dueDate: requestPayload?.dueDate ? formatOneOnOneRequestDate(new Date(requestPayload?.dueDate)) : null,
			momentDueDate: requestPayload?.dueDate ? moment(new Date(requestPayload?.dueDate)) : null,
			historyDate: requestPayload?.dueDate ? formatOneOnOneRequestDate(new Date(requestPayload?.dueDate)) : null,
			dueDateText: requestPayload?.dueDate ? getMonthDateYearTime(new Date(requestPayload?.dueDate)) : '',
			isOverDue: false,
			overDueText: '',
			createdOn: new Date(),
			isEditEnable: true,
			completeDate: null,
			priorityId: requestPayload?.priorityId,
			priority: getPriorityName(t, requestPayload?.priorityId),
			isImported: false,
			// sourceMappingId: 202,
			assignedTo: {
				employeeId: requestPayload?.delegateTo?.employeeId || userDetail?.employeeId || Enums.ZERO,
				firstName: requestPayload?.delegateTo?.firstName || userDetail?.firstName || '',
				lastName: requestPayload?.delegateTo?.lastName || userDetail?.lastName || '',
				fullName:
					requestPayload?.delegateTo?.employeeId === userDetail?.employeeId
						? `${userDetail?.firstName || ''} ${userDetail?.lastName || ''}`
						: requestPayload?.delegateTo?.fullName || '',
				imagePath:
					requestPayload?.delegateTo?.employeeId === userDetail?.employeeId
						? userDetail?.imagePath || null
						: requestPayload?.delegateTo?.imagePath || null,
				emailId: requestPayload?.delegateTo?.emailId || userDetail?.emailId || '',
				isActive: true,
			},
			assignedBy: {
				employeeId: userDetail?.employeeId || Enums.ZERO,
				firstName: userDetail?.firstName || '',
				lastName: userDetail?.lastName || '',
				fullName: `${userDetail?.firstName || ''} ${userDetail?.lastName || ''}`,
				imagePath: userDetail?.imagePath || null,
				emailId: userDetail?.emailId || '',
				isActive: true,
			},
			goalLinkDetails: requestPayload?.linkedKRDetails,
			checkInLinkDetails: null,
			requestLinkDetails: {
				senderName: formDetails?.isReciver ? formDetails?.firstName || '' : 'You', // userDetail?.firstName || ''
				reciverName: formDetails?.isReciver ? 'You' : formDetails?.firstName || '',
				oneToOneTitel: formDetails?.title || '',
				isLinkGreyedOut: false,
				toolTipText: '',
			},
			isError: requestPayload?.isError || false,
			copiedTaskId: requestPayload?.copiedTaskId || null,
			link: requestPayload.link,
			isRecentlyAdded: requestPayload?.isRecentlyAdded || false,
			isCheckedInLinked: requestPayload?.isCheckedInLinked || false,
		};
		if (type === 'direct') {
			return newTask;
		} else {
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			const totalRecords = taskPagingInfo?.totalRecords ? taskPagingInfo.totalRecords + 1 : 1;
			setAllTaskList([newTask, ...tasksCopy]);
			setTaskPagingInfo({ ...taskPagingInfo, totalRecords });
		}
	};

	return (
		<>
			<Box className='launch-template'>
				<Box className='launch-template-row first-row'>
					<TextField
						fullWidth
						className={`helperText-bottom ${openLaunchForm?.details?.isReciver ? 'disabled-title' : ''}`}
						label={
							<Fragment>
								<TitleIcon /> {t('titleLabel')}
								<i style={{ color: 'red' }}>*</i>
							</Fragment>
						}
						placeholder={t('addTitle')}
						name='title'
						value={formDetails?.title || ''}
						onChange={handleTitleChange}
						error={oneToOneTitleOnLaunchError?.error || false}
						helperText={
							(oneToOneTitleOnLaunchError?.error && (
								<>
									<ErrorIcon />
									{oneToOneTitleOnLaunchError?.helperText || ''}
								</>
							)) ||
							''
						}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							'aria-label': t('titleLabel'),
							disableUnderline: true,
							readOnly: openLaunchForm?.details?.isReciver,
						}}
					/>
				</Box>

				<Box className='launch-template-row first-row'>
					<Box className='meeting-link-row helperText-bottom'>
						<FormLabel>{t('natureOfMeeting')}</FormLabel>
						<RadioGroup
							row
							aria-label='cycleDuration'
							name='cycleDuration'
							onChange={(event: any) => {
								const selectedvalue = parseInt(event.target.value);
								let selectedRadio = [...NatureMeetingOptions].filter((item: any) => item?.id === selectedvalue);
								if (selectedRadio.length > 0) {
									setNatureOfMeetingSelected(selectedRadio);
									setNatureOfMeetingSelected(selectedRadio);
									setFormDetails({ ...formDetails, natureOfMeeting: selectedRadio[0].id });
								}
							}}
						>
							{NatureMeetingOptions.length > 0 &&
								NatureMeetingOptions.map((meetingOption: any) => (
									<FormControlLabel
										value={meetingOption?.id}
										control={<Radio />}
										label={meetingOption.label}
										checked={
											natureOfMeetingSelected.length > 0 ? natureOfMeetingSelected[0].id === meetingOption?.id : false
										}
									/>
								))}
						</RadioGroup>
						{/* 	<MultiSelect
							key={'employeeSelect'}
							id='employee-select'
							selectedOptions={natureOfMeetingSelected}
							optionsList={NatureMeetingOptions}
							onSelectOption={(value: any) => {
								if (value.length > 0) {
									setNatureOfMeetingSelected(value);
									setFormDetails({ ...formDetails, natureOfMeeting: value[0].id, meetingLink: '' });
								}
							}}
							customFilter={(option: any, searchText: any) => {
								if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
									return true;
								}
							}}
							placeHolder={t('timeFramePlaceholdar')}
							noOptionsMessage={<NoSearchRecord />}
							fetchAsyncData={false}
							isSingleSelection={false}
							performSearch={() => {}}
							closeMenuOnSelect={true}
							labelTemplate={'onlyLabel'}
							isMulti={false}
							selectClassName={`dropdown-default-wrapper checkin-visibility-dropdown`}
							selectClassNamePrefix={'dropdown-default'}
							disableSearch={true}
						/> */}
					</Box>
				</Box>
				{natureOfMeetingSelected && natureOfMeetingSelected.length > 0 && natureOfMeetingSelected[0].id === 1 && (
					<Box className='launch-template-row first-row'>
						<Box className='meeting-link-row helperText-bottom'>
							<TextField
								className='launch-meeting-link'
								fullWidth
								label={
									<Fragment>
										<AddLinkIcon />
										{t('meetingLink')}
									</Fragment>
								}
								placeholder={t('addMeetingLink')}
								value={formDetails?.meetingLink || ''}
								onChange={handleMeetingLinkChange}
								name={'meetingLink'}
								InputLabelProps={{
									shrink: true,
								}}
								error={oneToOneMeetingLinkError?.error || false}
								helperText={
									(oneToOneMeetingLinkError?.error && (
										<>
											<ErrorIcon />
											{oneToOneMeetingLinkError?.helperText || ''}
										</>
									)) ||
									''
								}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											{isMeetingLinkValid ? (
												<>
													<Box className='action-btn'>
														<OKRButton
															icon={<ContentCopyIcon />}
															className={'editIcon copy-btn'}
															title={t('copyLink')}
															handleClick={handleCopyMeetingLinkClick}
															disabled={!isMeetingLinkValid}
														/>
														<OKRButton
															text={'Join'}
															className={'btn-primary join-btn'}
															handleClick={handleJoinMeetingLink}
															disabled={!isMeetingLinkValid}
														/>
													</Box>
												</>
											) : (
												<></>
											)}
										</InputAdornment>
									),
								}}
							/>
							{isMeetingLinkCopied && isMeetingLinkCopied.copied ? (
								<Typography variant='body2' className='copied-text'>
									{t('copiedText')}
								</Typography>
							) : (
								<></>
							)}
						</Box>
					</Box>
				)}
				<Box className='launch-template-row '>
					{openLaunchForm?.details?.isReciver ? (
						<>
							<OKRButton
								className={`btn-toggle ${collapse ? 'btn-collapsed' : ''}`}
								text={t('agendaLabel')}
								icon={<RightArrowIcon />}
								handleClick={() => handleToggle('AgendaToggle')}
							/>
							<Collapse in={collapse} className='toggle-wrap-field'>
								<Box className='launch-agenda-content'>
									<Box className='long-text contributor-notes'>
										<Box
											dangerouslySetInnerHTML={{ __html: formDetails?.oneToOneRemark || 'No Agenda Mentioned' }}
										></Box>
									</Box>
								</Box>
							</Collapse>
						</>
					) : (
						<Box className='helperText-bottom'>
							<OKRButton
								className={`btn-toggle ${collapse ? 'btn-collapsed' : ''}`}
								text={
									<>
										{t('agendaLabel')}
										{oneToOneSettings?.isOneToOneAgendaRequired && <i style={{ color: 'red' }}>*</i>}
									</>
								}
								icon={<RightArrowIcon />}
								handleClick={() => handleToggle('AgendaToggle')}
							/>

							<Collapse in={collapse} className='toggle-wrap-field editor-five-line'>
								<CkEditor
									{...props}
									className={'inactive' || ''}
									value={formDetails?.oneToOneRemark || ''}
									skipBlankCheck={true}
									handleEditorChange={(value: string) => {
										handleAgendaChange(value, 'oneToOneRemark');
										setOneToOneAgendaError({
											error: '',
											helperText: '',
											type: '',
										});
									}}
									editorId={`requestOneOnOneAgendaOnLaunch`}
									removedPlugins={roleRemovePlugin}
									placeHolderText={t('writeAgendaLabel')}
									showEmoticons={true}
								/>
								{(oneToOneAgendaError?.error && (
									<Typography className='error-field'>
										<ErrorIcon />
										{oneToOneAgendaError?.helperText || ''}
									</Typography>
								)) ||
									''}
							</Collapse>
						</Box>
					)}
				</Box>
				<Box className='launch-template-row'>
					{okrMasterData?.oneToOneSettingResponse?.meetingNotesTakenBy === 2 && (
						<Box className='user-head'>
							<Tabs
								className='okr-tab-button okr-tab-white-button'
								value={select1on1Tab}
								onChange={handleTabChange}
								aria-label='User Tabs'
							>
								<Tab
									label={
										<>
											<Box className='user-info v-center'>
												<Box className='user-img'>
													{Boolean(userDetail?.imagePath) ? (
														<Avatar
															className='avatar-default'
															alt={`${userDetail?.firstName} ${userDetail?.lastName}`}
															src={userDetail?.imagePath}
														/>
													) : (
														<Avatar>{getUserName(userDetail)}</Avatar>
													)}
												</Box>
												<Box className='user-info-details'>
													<Typography variant='h6'>
														{userDetail?.firstName || ''} {userDetail?.lastName || ''}
													</Typography>
												</Box>
												<Typography className='owner-chip'>{t('mySelfTab')}</Typography>
											</Box>
										</>
									}
									value={Enums.ONE}
									{...a11yProps(Enums.ONE)}
								/>
								<Tab
									label={
										<>
											<Box className='user-info v-center'>
												<Box className='user-img'>
													{Boolean(openLaunchForm?.details?.imagePath) ? (
														<Avatar
															className='avatar-default'
															alt={`${openLaunchForm?.details?.firstName} ${openLaunchForm?.details?.lastName}`}
															src={openLaunchForm?.details.imagePath}
														/>
													) : (
														<Avatar>{getUserName(openLaunchForm?.details)}</Avatar>
													)}
												</Box>
												<Box className='user-info-details'>
													<Typography variant='h6'>
														{openLaunchForm?.details?.firstName || ''} {openLaunchForm?.details?.lastName || ''}
													</Typography>
												</Box>
											</Box>
										</>
									}
									value={Enums.TWO}
									{...a11yProps(Enums.TWO)}
								/>
							</Tabs>
						</Box>
					)}
					<Box className='user-tab-wrapper user-content'>
						{select1on1Tab === Enums.ONE && (
							<Box className='user-tab-content '>
								<Box className={`user-content-row one-on-one-task task-left-col`} id={'my_task_list'}>
									<Typography variant='h4'>{t('tasksLabel')}</Typography>
									<CreateTask
										loader={loader}
										setLoader={setLoader}
										isLaunchFormEdited={isLaunchFormEdited}
										setIsLaunchFormEdited={setIsLaunchFormEdited}
										allTaskList={allTaskList}
										setAllTaskList={setAllTaskList}
										defaultFormDetails={defaultFormDetails}
										formDetails={formDetailsTask}
										setFormDetails={setFormDetailsTask}
										resetTabDetails={resetTabDetails}
										krList={krList}
										defaultRecentUserList={defaultRecentUserList}
										minDateValue={minDateValue}
										maxDateValue={maxDateValue}
										setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
										callingFrom={'OneOnOneTask'}
										globalTaskRequest={globalTaskRequest}
										defaultTaskListRequest={{ ...defaultTaskListRequest, isMyTask: true }}
										renderTaskListDetails={renderTaskListDetails}
										getRecentSearchesList={getRecentSearchesList}
										handleSaveCallBack={handleSaveCallBack}
										resetHighlightOneOnOneTask={resetHighlightOneOnOneTask}
										taskPagingInfo={taskPagingInfo}
										setTaskPagingInfo={setTaskPagingInfo}
										dueDateError={dueDateError}
										setDueDateError={setDueDateError}
									/>
									{allTaskList &&
									allTaskList.length === Enums.ZERO &&
									!completedTaskCount &&
									!toDoTaskCount &&
									checkIsInitialListCall(defaultTaskListRequest, globalTaskRequest) ? (
										<Box className='welcome-content no-task-create'>
											<Box textAlign='center'>
												<Typography variant='h6'>{t('noTasksCreated')}</Typography>
												{/* <Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
												<Typography>{t('noTaskFoundSubTitle')}</Typography>
												<AnimatedIcon
													className='no-task-image'
													width='323px'
													type='image/svg+xml'
													alt={t('noTaskHead')}
													imagePath={NotesImg}
												/> */}
											</Box>
										</Box>
									) : (
										<TaskList
											loader={loader}
											setLoader={setLoader}
											tabSelected={select1on1Tab}
											globalTaskRequest={globalTaskRequest}
											setGlobalTaskRequest={setGlobalTaskRequest}
											setPageIndex={setPageIndex}
											setTabSelected={setSelect1on1Tab}
											filterDetails={filterDetails}
											taskPagingInfo={taskPagingInfo}
											allTaskList={allTaskList}
											completedTaskCount={completedTaskCount}
											toDoTaskCount={toDoTaskCount}
											handleFilterCompletedOnly={handleFilterCompletedOnly}
											handleGroupByFilterClick={handleGroupByFilterClick}
											handleSortingFilterClick={handleSortingFilterClick}
											showLoadMore={showLoadMore}
											pageIndexVal={pageIndexVal}
											fetchGlobalTask={fetchGlobalTask}
											handleTaskNameClick={handleTaskNameClick}
											handleTaskChange={handleTaskChange}
											handleTaskOnEnterEdit={handleTaskOnEnterEdit}
											handleEditTaskClose={handleEditTaskClose}
											handleTaskUpdate={handleTaskUpdate}
											setIsLaunchFormEdited={setIsLaunchFormEdited}
											handlePriorityChangeOnEdit={(e: any, taskType: number, task: any) =>
												handlePriorityChangeOnEdit(e, taskType, task, 1)
											}
											handleAddTaskDueDateChangeOnEdit={(value: any, range: any, task: any) =>
												handleAddTaskDueDateChangeOnEdit(value, range, task, 1)
											}
											handleResetButtonClickOnEdit={(task: any) => handleResetButtonClickOnEdit(task, 1)}
											onSelectedUserOnEdit={(selectedUser: any, task: any) =>
												onSelectedUserOnEdit(selectedUser, task, 1)
											}
											handleRemoveAssigneeOnEdit={(e: any, task: any, isAssigneeSelfRemoved = false) =>
												handleRemoveAssigneeOnEdit(e, task, isAssigneeSelfRemoved, 1)
											}
											handleKRLinkOnEdit={(e: any, selectedKR: any, task: any) =>
												handleKRLinkOnEdit(e, selectedKR, task, 1)
											}
											handleRemoveKROnEdit={(e: any, task: any) => handleRemoveKROnEdit(e, task, 1)}
											handleCompleteTaskOnEdit={(e: any, task: any) => handleCompleteTaskOnEdit(e, task, 1)}
											handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
											taskGroupByList={taskGroupByList}
											handleCollapseTask={handleCollapseTask}
											openGroupByTask={openGroupByTask}
											setOpenGroupByTask={setOpenGroupByTask}
											defaultRecentUserList={defaultRecentUserList}
											searchTextArray={searchTextArray}
											setSearchTextArray={setSearchTextArray}
											minDateValue={minDateValue}
											maxDateValue={maxDateValue}
											setIsDateRangeChanged={setIsDateRangeChanged}
											selectedFilterDateRange={selectedFilterDateRange}
											setSelectedFilterDateRange={setSelectedFilterDateRange}
											resetCalendarDate={resetCalendarDate}
											setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
											callingFrom={'OneOnOneTask'}
											handleSearchText={handleSearchText}
											searchedInEmpId={'Current'}
											handleTaskLinkChange={handleTaskLinkChange}
											dueDateError={dueDateError}
											setDueDateError={setDueDateError}
											handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
											handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
										/>
									)}
									{/* <OneOnOneTaskList
										openLaunchForm={openLaunchForm}
										formDetails={formDetails}
										handleTaskOnChange={handleTaskOnChange}
										handleTaskOnEnterCreate={handleTaskOnEnterCreate}
										handleCancelTask={handleCancelTask}
										handleAddNewTask={handleAddNewTask}
										handleAddTaskDueDateChange={handleAddTaskDueDateChange}
										completeByDate={completeByDate}
										setCompleteBy={setCompleteBy}
										taskDueDateError={taskDueDateError}
										taskNameError={taskNameError}
										handleCompleteTask={handleCompleteTask}
										handleDeleteTask={handleDeleteTask}
										handleEditMode={handleEditMode}
										handleBlurTask={handleBlurTask}
										handleTaskChange={handleTaskChange}
										handleTaskOnEnterEdit={handleTaskOnEnterEdit}
										handleCancelUpdate={handleCancelUpdate}
										handleTaskUpdate={handleTaskUpdate}
										handleDueDateChangeInList={handleDueDateChangeInList}
										taskListHeight={taskListHeight}
										userDetail={userDetail}
										handleAssignUserOnChange={handleAssignUserOnChange}
										isUserAssigned={isUserAssigned}
										handleAssignUserListChange={handleAssignUserListChange}
									/> */}
								</Box>
								<Box className='user-content-row '>
									<Box className='editor-fifteen-line'>
										<Typography variant='h4'>{t('meetingNotes')}</Typography>
										<CkEditor
											{...props}
											className={'inactive' || ''}
											value={formDetails?.oneToOnePublicNotes || ''}
											skipBlankCheck={true}
											handleEditorChange={(value: string) => {
												handleAgendaChange(value, 'oneToOnePublicNotes');
											}}
											editorId={`oneToOnePublicNotes`}
											removedPlugins={roleRemovePlugin}
											placeHolderText={t('writeNotes')}
											showEmoticons={true}
										/>
									</Box>
									<Box className={`collapsed-private-notes ${collapsePrivate ? 'open-private-notes' : ''}`}>
										<OKRButton
											className={`btn-toggle ${collapsePrivate ? 'btn-collapsed' : ''}`}
											text={t('privateNotes')}
											icon={<RightArrowIcon />}
											handleClick={() => handleToggle('PrivateNoteToggle')}
										/>
										<Collapse in={collapsePrivate} className='toggle-wrap-field editor-fifteen-line'>
											<CkEditor
												{...props}
												className={'inactive' || ''}
												value={formDetails?.oneToOnePrivateNotes || ''}
												skipBlankCheck={true}
												handleEditorChange={(value: string) => {
													handleAgendaChange(value, 'oneToOnePrivateNotes');
												}}
												editorId={`oneToOnePrivateNotes`}
												removedPlugins={roleRemovePlugin}
												placeHolderText={t('writeNotes')}
												showEmoticons={true}
											/>
										</Collapse>
									</Box>
								</Box>
							</Box>
						)}
						{select1on1Tab === Enums.TWO && (
							<Box className='user-tab-content other-col'>
								<Box className='user-content-row one-on-one-task '>
									{/* <OneOnOneTaskList
										openLaunchForm={openLaunchForm}
										formDetails={formDetails}
										isOther={true}
										taskListHeight={otherTaskListHeight}
									/> */}
									{OtherAllTaskList &&
									OtherAllTaskList.length === Enums.ZERO &&
									!completedTaskCount &&
									!toDoTaskCount &&
									checkIsInitialListCall(defaultTaskListRequest, globalTaskRequest) ? (
										// <Box className='welcome-content'>
										// 	<Box textAlign='center'>
										// 		<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
										// 		<Typography>{t('noTaskFoundSubTitle')}</Typography>
										// 		<AnimatedIcon
										// 			className='no-task-image'
										// 			width='323px'
										// 			type='image/svg+xml'
										// 			alt={t('noTaskHead')}
										// 			imagePath={NotesImg}
										// 		/>
										// 	</Box>

										// </Box>
										<Box className='not-found-screen'>
											<Typography variant='h4'>
												{t('noOtherUserTaskText', {
													userName: `${openLaunchForm?.details?.firstName || ''} ${
														openLaunchForm?.details?.lastName || ''
													}`,
												})}
											</Typography>
											<Typography variant='h5'>{t('noOtherUserTaskSubHeadText')}</Typography>
											<Box className='blank-bg'></Box>
										</Box>
									) : (
										<>
											<Typography variant='h4'>{t('tasksLabel')}</Typography>
											<TaskList
												loader={loader}
												setLoader={setLoader}
												tabSelected={select1on1Tab}
												globalTaskRequest={globalTaskRequest}
												setGlobalTaskRequest={setGlobalTaskRequest}
												setPageIndex={setPageIndex}
												setTabSelected={setSelect1on1Tab}
												filterDetails={filterDetails}
												allTaskList={OtherAllTaskList}
												completedTaskCount={completedTaskCount}
												toDoTaskCount={toDoTaskCount}
												handleFilterCompletedOnly={handleFilterCompletedOnly}
												handleGroupByFilterClick={handleGroupByFilterClick}
												handleSortingFilterClick={handleSortingFilterClick}
												showLoadMore={showLoadMore}
												pageIndexVal={pageIndexVal}
												taskPagingInfo={taskPagingInfo}
												fetchGlobalTask={fetchGlobalTask}
												handleTaskNameClick={handleTaskNameClick}
												handleTaskChange={handleTaskChange}
												handleTaskOnEnterEdit={handleTaskOnEnterEdit}
												handleEditTaskClose={handleEditTaskClose}
												handleTaskUpdate={handleTaskUpdate}
												setIsLaunchFormEdited={setIsLaunchFormEdited}
												handlePriorityChangeOnEdit={(e: any, taskType: number, task: any) =>
													handlePriorityChangeOnEdit(e, taskType, task, 2)
												}
												handleAddTaskDueDateChangeOnEdit={(value: any, range: any, task: any) =>
													handleAddTaskDueDateChangeOnEdit(value, range, task, 2)
												}
												handleResetButtonClickOnEdit={(task: any) => handleResetButtonClickOnEdit(task, 2)}
												onSelectedUserOnEdit={(selectedUser: any, task: any) =>
													onSelectedUserOnEdit(selectedUser, task, 2)
												}
												handleRemoveAssigneeOnEdit={(e: any, task: any, isAssigneeSelfRemoved = false) =>
													handleRemoveAssigneeOnEdit(e, task, isAssigneeSelfRemoved, 2)
												}
												handleKRLinkOnEdit={(e: any, selectedKR: any, task: any) =>
													handleKRLinkOnEdit(e, selectedKR, task, 2)
												}
												handleRemoveKROnEdit={(e: any, task: any) => handleRemoveKROnEdit(e, task, 2)}
												handleCompleteTaskOnEdit={(e: any, task: any) => handleCompleteTaskOnEdit(e, task, 2)}
												handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
												taskGroupByList={taskGroupByList}
												handleCollapseTask={handleCollapseTask}
												openGroupByTask={openGroupByTask}
												setOpenGroupByTask={setOpenGroupByTask}
												defaultRecentUserList={defaultRecentUserList}
												searchTextArray={searchTextArray}
												setSearchTextArray={setSearchTextArray}
												minDateValue={minDateValue}
												maxDateValue={maxDateValue}
												setIsDateRangeChanged={setIsDateRangeChanged}
												selectedFilterDateRange={selectedFilterDateRange}
												setSelectedFilterDateRange={setSelectedFilterDateRange}
												resetCalendarDate={resetCalendarDate}
												setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
												callingFrom={'OneOnOneTask'}
												handleSearchText={handleSearchText}
												callForOther={false}
												searchedInEmpId={'Other'}
												handleTaskLinkChange={handleTaskLinkChange}
												dueDateError={dueDateError}
												setDueDateError={setDueDateError}
												handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
												handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
											/>
										</>
									)}
								</Box>
								{formDetails && formDetails?.otherNotes && formDetails?.otherNotes?.length > 0 ? (
									<Box className='user-content-row'>
										<Typography variant='h4'>{t('meetingNotes')}</Typography>
										<Box className='contributor-notes'>
											{formDetails?.otherNotes?.map((item: any, index: number) => (
												<Box className='long-text' key={`other_user_notes_${item?.noteId}`}>
													<Box
														//variant='h4'
														dangerouslySetInnerHTML={{ __html: item?.description || '' }}
													></Box>
												</Box>
											))}
										</Box>
									</Box>
								) : (
									<Box className='user-content-row one-on-one-task'>
										<Box className='not-found-screen'>
											<Typography variant='h4'>
												{t('noOtherUserNotesText', {
													userName: `${openLaunchForm?.details?.firstName || ''} ${
														openLaunchForm?.details?.lastName || ''
													}`,
												})}
											</Typography>
											<Typography variant='h5'>{t('noOtherUserNotesSubHeadText')}</Typography>
											<Box className='blank-bg'></Box>
										</Box>
									</Box>
								)}
							</Box>
						)}
					</Box>
				</Box>
				<Box className='launch-template-row launch-template-btn-action'>
					<Box>
						<Typography variant='h6' className='font-weight-normal'>
							{t('noteLaunchHead')}
						</Typography>
						<Typography variant='h6' className='font-weight-normal'>
							{t('noteLaunchSubHead')}
						</Typography>
					</Box>
					<Box>
						<OKRButton handleClick={handleBackButtonOnClick} className={'btn-link'} text={t('cancelBtn')} />
						<OKRButton
							handleClick={handleSaveLaunchEditRequestClick}
							className={'btn-primary'}
							text={openLaunchForm?.type !== 'LaunchEdit' ? t('saveBtn') : t('update')}
							disabled={loader}
						/>
					</Box>
				</Box>
			</Box>
			{openDeleteDialog && openDeleteDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('taskDeleteMessage')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog?.open}
				/>
			)}
			{openTabChangeDialog && openTabChangeDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleTabChangeDialogClick}
					modalOpen={openTabChangeDialog?.open}
				/>
			)}
		</>
	);
};
