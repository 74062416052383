import {
	PERFORMANCE_REVIEW_CORE_VALUE_LIST_FAILED,
	PERFORMANCE_REVIEW_CORE_VALUE_LIST_SUCCESS,
	PERFORMANCE_REVIEW_ROLE_LIST_FAILED,
	PERFORMANCE_REVIEW_ROLE_LIST_SUCCESS,
	UPDATE_COMPETENCY_RATING_TAB_VALUE,
	CURRENT_TAB_SELECTION,
	QUESTION_TYPE_MASTER_FAILED,
	QUESTION_TYPE_MASTER_SUCCESS,
	MANAGE_SCALE_LIST_FAILED,
	MANAGE_SCALE_LIST_SUCCESS,
	HIGHLIGHT_GOAL_FORM_ID,
	RELOAD_GOAL_FORM_LIST,
	ALIGN_GOAL_PAGINATION_DATA,
	HIGHLIGHT_GOAL_CYCLE_ID,
	ALIGN_GOAL_CYCLE_PAGINATION_DATA,
	ALIGN_GOAL_CYCLE_WORKFLOW_PAGINATION_DATA,
	GOAL_REVIEW_PAGINATION_DATA,
	SET_GOAL_CYCLE_FORM,
	MANAGE_GOAL_TAB_COUNT_SUCCESS,
	MANAGE_GOAL_TAB_COUNT_FAILED,
	GET_PERFORMANCE_GOAL_CYCLE_SUCCESS,
	GET_PERFORMANCE_GOAL_CYCLE_FAILED,
	GET_PERFORMANCE_GOAL_CYCLE_DATA,
	SET_PERFORMANCE_GOAL_ATTACHMENT_COUNT,
	GOAL_CONVERSATION_ALERT_SUCCESS,
	GOAL_CONVERSATION_ALERT_FAILED,
	REFRESH_GOAL_REVIEW_LIST,
	HIGHLIGHT_GOAL_REVIEW_CYCLE_ID,
	SET_PERFORMANCE_REVIEW_CYCLE,
	GET_PERFORMANCE_REVIEW_PENDING,
	GET_PERFORMANCE_REVIEW_SUCCESS,
	GET_PERFORMANCE_REVIEW_FAILED,
} from './../action/actionTypes';

const INITIAL_STATE = {
	performanceReviewRoleList: null,
	performanceReviewRoleError: null,
	performanceReviewCoreValueList: null,
	performanceReviewCoreValueError: null,
	competencyRatingSettingTabValue: 0,
	selectionValue: '',
	questionTypeMasterData: null,
	questionTypeMasterError: null,
	manageScaleListResponse: null,
	manageScaleListError: null,
	highlightGoalFormId: null,
	reloadGoalFormListData: false,
	paginationData: null,
	highlightGoalCycleId: null,
	goalCycleFormCount: 0,
	goalTabCount: {},
	goalUserCycle: [],
	performanceGoalCycle: null,
	goalAttachmentCont: 0,
	goalConversationAlert: false,
	refreshGoalReviewReviewListing: false,
	goalReviewCycleIdHighlight: 0,
	goalReviewCycle: [],
	goalReviewCycleFailed: false,
	currentGoalReviewCycle: {},
};

export default function adminSettingReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_PERFORMANCE_GOAL_CYCLE_DATA:
			return {
				...state,
				performanceGoalCycle: action.payload,
			};
		case PERFORMANCE_REVIEW_ROLE_LIST_SUCCESS:
			return {
				...state,
				performanceReviewRoleList: action.payload || {},
				performanceReviewRoleError: null,
			};
		case PERFORMANCE_REVIEW_ROLE_LIST_FAILED:
			return {
				...state,
				performanceReviewRoleList: null,
				performanceReviewRoleError: action.payload,
			};
		case PERFORMANCE_REVIEW_CORE_VALUE_LIST_SUCCESS:
			return {
				...state,
				performanceReviewCoreValueList: action.payload || {},
				performanceReviewRoleError: null,
			};
		case PERFORMANCE_REVIEW_CORE_VALUE_LIST_FAILED:
			return {
				...state,
				performanceReviewCoreValueList: null,
				performanceReviewCoreValueError: action.payload,
			};
		case UPDATE_COMPETENCY_RATING_TAB_VALUE:
			return {
				...state,
				competencyRatingSettingTabValue: action.payload,
			};
		case CURRENT_TAB_SELECTION:
			return {
				...state,
				selectionValue: action.payload,
			};
		case QUESTION_TYPE_MASTER_SUCCESS:
			return {
				...state,
				questionTypeMasterData: action.payload || {},
				questionTypeMasterError: null,
			};
		case QUESTION_TYPE_MASTER_FAILED:
			return {
				...state,
				questionTypeMasterData: null,
				questionTypeMasterError: action.payload,
			};
		case MANAGE_SCALE_LIST_SUCCESS:
			return {
				...state,
				manageScaleListResponse: action.payload || [],
				manageScaleListError: null,
			};
		case MANAGE_SCALE_LIST_FAILED:
			return {
				...state,
				manageScaleListResponse: [],
				manageScaleListError: action.payload,
			};
		case HIGHLIGHT_GOAL_FORM_ID:
			return {
				...state,
				highlightGoalFormId: action.payload || null,
			};
		case RELOAD_GOAL_FORM_LIST:
			return {
				...state,
				reloadGoalFormListData: action.payload || false,
			};
		case ALIGN_GOAL_PAGINATION_DATA:
			return {
				...state,
				paginationData: action.payload || {},
			};
		case HIGHLIGHT_GOAL_CYCLE_ID:
			return {
				...state,
				highlightGoalCycleId: action.payload || null,
			};
		case ALIGN_GOAL_CYCLE_PAGINATION_DATA:
			return {
				...state,
				paginationData: action.payload || {},
			};
		case ALIGN_GOAL_CYCLE_WORKFLOW_PAGINATION_DATA:
			return {
				...state,
				paginationData: action.payload || {},
			};
		case GOAL_REVIEW_PAGINATION_DATA:
			return {
				...state,
				paginationData: action.payload || {},
			};
		case SET_GOAL_CYCLE_FORM:
			return {
				...state,
				goalCycleFormCount: action.payload || 0,
			};
		case MANAGE_GOAL_TAB_COUNT_SUCCESS:
			return {
				...state,
				goalTabCount: action.payload || {},
			};
		case MANAGE_GOAL_TAB_COUNT_FAILED:
			return {
				...state,
				goalTabCount: {},
			};
		case GOAL_CONVERSATION_ALERT_SUCCESS:
			return {
				...state,
				goalConversationAlert: action.payload || {},
			};
		case GOAL_CONVERSATION_ALERT_FAILED:
			return {
				...state,
				goalConversationAlert: {},
			};
		case GET_PERFORMANCE_GOAL_CYCLE_SUCCESS:
			return {
				...state,
				goalUserCycle: action.payload || [],
			};
		case GET_PERFORMANCE_GOAL_CYCLE_FAILED:
			return {
				...state,
				goalUserCycle: [],
			};

		case SET_PERFORMANCE_GOAL_ATTACHMENT_COUNT:
			return {
				...state,
				goalAttachmentCont: action.payload || 0,
			};
		case HIGHLIGHT_GOAL_REVIEW_CYCLE_ID:
			return {
				...state,
				goalReviewCycleIdHighlight: action.payload,
			};
		case REFRESH_GOAL_REVIEW_LIST:
			return {
				...state,
				refreshGoalReviewReviewListing: action.payload,
			};
		case SET_PERFORMANCE_REVIEW_CYCLE:
			return {
				...state,
				currentGoalReviewCycle: action.payload,
			};
		case GET_PERFORMANCE_REVIEW_SUCCESS:
			return {
				...state,
				goalReviewCycle: action.payload.entityList,
				goalReviewCycleFailed: false,
			};
		case GET_PERFORMANCE_REVIEW_FAILED:
			return {
				...state,
				goalReviewCycle: [],
				goalReviewCycleFailed: true,
			};
		default:
			return state;
	}
}
