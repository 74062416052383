import React, { Fragment } from 'react';
import {
	Box,
	Button,
	ClickAwayListener,
	ListItemText,
	MenuItem,
	MenuList,
	Popper,
	Typography,
} from '@material-ui/core';
import { CancelIcon } from '../../config/svg/Action';

export const UserSearchListPopover: React.FC<any> = (props) => {
	const { open, anchorEl, setOpen, setAnchorEl, searchTextArray, clearSearch } = props;
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleListKeyDown = (event: any) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	return (
		<Popper className='user-search-details-popover' open={open} anchorEl={anchorEl} transition placement='bottom-end'>
			<ClickAwayListener onClickAway={handleClose}>
				<Box className='' onKeyDown={handleListKeyDown}>
					<MenuList className='search-details-list'>
						{searchTextArray.map((user: any, idx: any) => {
							if (idx >= 3) {
								return (
									<MenuItem key={`filter${idx}`}>
										<ListItemText>{user}</ListItemText>
										<Button
											className='cancel-btn'
											onClick={() => {
												handleClose();
												clearSearch(user);
											}}
										>
											<CancelIcon />
										</Button>
									</MenuItem>
								);
							}
						})}
					</MenuList>
				</Box>
			</ClickAwayListener>
		</Popper>
	);
};
