import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	getAvgOkrReport,
	getMostWeeklyKrReport,
	getMostFeedbackReport,
	getWeeklyKrGrowthReport,
	getLeastRiskOkrReport,
	getLeastRiskKrReport,
	getTeamPerformance,
	getTeamProgressDetails,
	getLeadingTeamsDetails,
	getOrgReportsDetails,
	getOkrProgressDetails,
} from '../../action/report';
import Insights from '../../components/Insights';
import { updatePage, updateRoute, showLoader, updatePrevRoute } from '../../action/common';
import { resetAlignment } from '../../action/alignment';
import { resetFeedback, requestOneToOneFeedback } from '../../action/feedback';
import { getOkrMasterData } from '../../action/myGoal';

const mapStateToProps = (state) => {
	return {
		feedbackOneOnOneStatus: state.feedbackReducer.feedback1To1Status,
		currentRoute: state.commonReducer.currentRoute,
		okrNatures: state.okrReducer.okrMasterData,
		leadingTeams: state.reportsReducer.leadingTeam,
		orgReport: state.reportsReducer.orgReport,
		orgReportStatus: state.reportsReducer.orgReportStatus,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAvgOkrReport,
			getMostWeeklyKrReport,
			getMostFeedbackReport,
			getWeeklyKrGrowthReport,
			getLeastRiskOkrReport,
			getLeastRiskKrReport,
			// resetOkr,
			resetAlignment,
			resetFeedback,
			updatePage,
			updateRoute,
			requestOneToOneFeedback,
			showLoader,
			updatePrevRoute,
			getTeamPerformance,
			getTeamProgressDetails,
			getOkrMasterData,
			getLeadingTeamsDetails,
			getOrgReportsDetails,
			getOkrProgressDetails,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Insights));
