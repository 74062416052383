import { deleteRequest, getRequest, postRequest, putRequest } from '../config/auth';
import {
	GET_ALL_RECOGNIZATION,
	SAVE_RECOGNIZATION,
	GET_ALL_BADGES,
	DELETE_RECOGNIZE_POST,
	SAVE_COMMENTS,
	GET_ALL_COMMENTS,
	DELETE_COMMENT_POST,
	UPDATE_RECOGNIZE_POST,
	GET_ALL_FAME_BADGES,
	GET_LIKE_RECOGNIZE,
	GET_TEAMS_BY_EMP,
	GET_TEAMS_BY_RECOG,
} from '../config/api-url';

export const allRecognizeListApi = (data) => postRequest(`${GET_ALL_RECOGNIZATION}`, data);
export const fameBadgesApi = (data) => postRequest(`${GET_ALL_FAME_BADGES}`, data);
export const saveRecognizeApi = (data) => postRequest(`${SAVE_RECOGNIZATION}`, data);
export const getAllBadges = (data) => getRequest(`${GET_ALL_BADGES}?${data}`, data);
export const deleteRecognizePostApi = (data) => deleteRequest(`${DELETE_RECOGNIZE_POST}?${data}`, data);
export const allCommentsListApi = (data) => getRequest(`${GET_ALL_COMMENTS}?${data}`, data);
export const saveCommentsApi = (data) => postRequest(`${SAVE_COMMENTS}`, data);
export const deleteCommentsPostApi = (data) => deleteRequest(`${DELETE_COMMENT_POST}?${data}`, data);
export const updateRecognizationPostAPI = (data) => putRequest(`${UPDATE_RECOGNIZE_POST}`, data);
export const allLikeRecognitionApi = (data) => getRequest(`${GET_LIKE_RECOGNIZE}?${data}`, data);
export const getAllRecogTeamsApi = (data) => postRequest(`${GET_TEAMS_BY_EMP}`, data);
export const getAllTeamDataApi = (data) => postRequest(`${GET_TEAMS_BY_RECOG}`, data);
