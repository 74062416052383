import {
	deleteFeedbackStatusAPI,
	deletePerformancePlanStatusAPI,
	getFeedbackSettingAPI,
	getFeedbackStatusAPI,
	getPerformancePlanEmailConfAPI,
	getPerformancePlanSettingAPI,
	getPerformancePlanStatusAPI,
	insertFeedbackStatusAPI,
	insertPerformancePlanStatusAPI,
	updateFeedbackSettingAPI,
	updateFeedbackStatusAPI,
	updateFeedbackStatusEnableAPI,
	updatePerformancePlanSettingAPI,
	updatePerformancePlanStatusAPI,
	updatePerformancePlanStatusEnableAPI,
	updatedPerformancePlanEmailConfAPI,
	getFeedbackTypeAPI,
	insertFeedbackTypeAPI,
	updateFeedbackTypeAPI,
	deleteFeedbackTypeAPI,
	updateFeedbackTypeEnableAPI,
	getFeedbackCategoryAPI,
	insertFeedbackCategoryAPI,
	updateFeedbackCategoryAPI,
	deleteFeedbackCategoryAPI,
	updateFeedbackCategoryEnableAPI,
	saveFeedbackOneToOneSettingAPI,
	getFeedbackOneToOneSettingAPI,
	getEmailRecipientAPI,
	updateEmailRecipientAPI,
} from '../services/adminPerformanceDevelopment';
import { Enums } from '../config/enums';
import {
	RELOAD_FEEDBACK_CATEGORY_LIST,
	FEEDBACK_ONE_TO_ONE_SETTINGS_SUCCESS,
	FEEDBACK_ONE_TO_ONE_SETTINGS_FAILED,
} from '../action/actionTypes';
// ===================================================
export const getEmailRecipient = (data) => {
	return () => {
		return getEmailRecipientAPI(data);
	};
};
export const updateEmailRecipient = (data) => {
	return () => {
		return updateEmailRecipientAPI(data);
	};
};
// ===================================================
export const getPerformancePlanSetting = (data) => {
	return () => {
		return getPerformancePlanSettingAPI(data);
	};
};
export const updatePerformancePlanSetting = (data) => {
	return () => {
		return updatePerformancePlanSettingAPI(data);
	};
};
// ===================================================
export const getPerformancePlanStatus = (data) => {
	return () => {
		return getPerformancePlanStatusAPI(data);
	};
};
export const insertPerformancePlanStatus = (data) => {
	return () => {
		return insertPerformancePlanStatusAPI(data);
	};
};
export const updatePerformancePlanStatus = (data) => {
	return () => {
		return updatePerformancePlanStatusAPI(data);
	};
};
export const deletePerformancePlanStatus = (data) => {
	return () => {
		return deletePerformancePlanStatusAPI(data);
	};
};
// ===================================================
export const updatePerformancePlanStatusEnable = (data) => {
	return () => {
		return updatePerformancePlanStatusEnableAPI(data);
	};
};
// ===================================================
export const getPerformancePlanEmailConf = (data) => {
	return () => {
		return getPerformancePlanEmailConfAPI(data);
	};
};
export const updatedPerformancePlanEmailConf = (data) => {
	return () => {
		return updatedPerformancePlanEmailConfAPI(data);
	};
};
// ===================================================
// ===================================================
export const getFeedbackSetting = (data) => {
	return () => {
		return getFeedbackSettingAPI(data);
	};
};
export const updateFeedbackSetting = (data) => {
	return () => {
		return updateFeedbackSettingAPI(data);
	};
};
// ===================================================
export const getFeedbackStatus = (data) => {
	return () => {
		return getFeedbackStatusAPI(data);
	};
};
export const insertFeedbackStatus = (data) => {
	return () => {
		return insertFeedbackStatusAPI(data);
	};
};
export const updateFeedbackStatus = (data) => {
	return () => {
		return updateFeedbackStatusAPI(data);
	};
};
export const deleteFeedbackStatus = (data) => {
	return () => {
		return deleteFeedbackStatusAPI(data);
	};
};
// ===================================================
export const updateFeedbackStatusEnable = (data) => {
	return () => {
		return updateFeedbackStatusEnableAPI(data);
	};
};
// ===================================================

// ===================================================
export const getFeedbackType = (data) => {
	return () => {
		return getFeedbackTypeAPI(data);
	};
};
export const insertFeedbackType = (data) => {
	return () => {
		return insertFeedbackTypeAPI(data);
	};
};
export const updateFeedbackType = (data) => {
	return () => {
		return updateFeedbackTypeAPI(data);
	};
};
export const deleteFeedbackType = (data) => {
	return () => {
		return deleteFeedbackTypeAPI(data);
	};
};
// ===================================================
export const updateFeedbackTypeEnable = (data) => {
	return () => {
		return updateFeedbackTypeEnableAPI(data);
	};
};
// ===================================================
export const getFeedbackCategoryData = (data) => {
	return () => {
		return getFeedbackCategoryAPI(data);
	};
};
export const insertFeedbackCategory = (data) => {
	return () => {
		return insertFeedbackCategoryAPI(data);
	};
};
export const updateFeedbackCategory = (data) => {
	return () => {
		return updateFeedbackCategoryAPI(data);
	};
};
export const deleteFeedbackCategory = (data) => {
	return () => {
		return deleteFeedbackCategoryAPI(data);
	};
};
// ===================================================
export const updateFeedbackCategoryEnable = (data) => {
	return () => {
		return updateFeedbackCategoryEnableAPI(data);
	};
};

export const reloadFeebackCategoryList = (reload = 0) => {
	return {
		type: RELOAD_FEEDBACK_CATEGORY_LIST,
		payload: reload,
	};
};

const getFeedbackOneToOneSettingSuccess = (payload) => ({
	type: FEEDBACK_ONE_TO_ONE_SETTINGS_SUCCESS,
	payload,
});
const getFeedbackOneToOneSettingFailed = (error) => ({
	type: FEEDBACK_ONE_TO_ONE_SETTINGS_FAILED,
	payload: error,
});
export const getFeedbackOneToOneSetting = (data) => {
	return (dispatch) => {
		return getFeedbackOneToOneSettingAPI(data)
			.then((response) => {
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data;
					dispatch(getFeedbackOneToOneSettingSuccess(entity));
				} else {
					dispatch(getFeedbackOneToOneSettingFailed(null));
				}
			})
			.catch((error) => {
				dispatch(getFeedbackOneToOneSettingFailed(null));
			});
	};
};
export const saveFeedbackOneToOneSetting = (data) => {
	return () => {
		return saveFeedbackOneToOneSettingAPI(data);
	};
};
