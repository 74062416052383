import React, { Fragment, useEffect, useState } from 'react';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { CategorySettingsDrawer } from './CategorySettingsDrawer';
import { getMonthDateYearTime } from '../../../config/utils';
import { CalendarIcon } from '../../../config/svg/CommonSvgIcon';
import { useDispatch } from 'react-redux';
import { fetchConversationGoalCompleted } from '../../../action/perform';

export const CategorySettings: React.FC<any> = (props) => {
	const { t, categorySettingsOpen, handleSettingsDrawerClose, selectTab, performanceGoalsList } = props;
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const [loader, setLoader] = useState<boolean>(false);

	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};
	const dispatch = useDispatch();

	useEffect(() => {
		if (selectTab === 'attachments') {
			setTabSelected(0);
		} else if (selectTab === 'conversation') {
			dispatch(
				fetchConversationGoalCompleted({
					entity: {
						commentDetailResponses: [],
					},
					pagingInfo: {},
				})
			);
			setTabSelected(1);
		}
	}, [selectTab]);
	return (
		<Fragment>
			<OKRDrawer
				open={categorySettingsOpen.open}
				drawerClassName='main-drawer-panel main-drawer-no-footer category-settings-drawer'
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={
					<>
						{t('categoryLevel')}
						{performanceGoalsList?.settingDurationStartDate && performanceGoalsList?.settingDurationEndDate && (
							<Tooltip title={t('goalSettingDuration')} arrow>
								<span>
									<CalendarIcon />
									{`${getMonthDateYearTime(performanceGoalsList?.settingDurationStartDate)} - ${getMonthDateYearTime(
										performanceGoalsList?.settingDurationEndDate
									)}`}
								</span>
							</Tooltip>
						)}
					</>
				}
				children={
					<Box className='drawer-inner-content'>
						<CategorySettingsDrawer
							kr={{
								...categorySettingsOpen.data,
								goalSourceId: categorySettingsOpen.data.performanceGoalLinkedFormCategoryId,
							}}
							t={t}
							handleTabChange={handleTabChange}
							tabSelected={tabSelected}
							{...props}
							loader={loader}
							setLoader={setLoader}
						/>
					</Box>
				}
				moreButton={<></>}
				handleDrawerClose={(event) => handleSettingsDrawerClose(event)}
				handleSaveClick={(event) => handleSettingsDrawerClose(event)}
				handleCancelClick={(event) => handleSettingsDrawerClose(event)}
				hideDefaultButton={false}
				loader={loader}
				isFooterVisible={false}
				isSaveButtonDisabled={false}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
		</Fragment>
	);
};
