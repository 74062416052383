import React, { Fragment, useState } from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../../styles/pages/admin/settings/addPerformReview.scss';
import { VisibilitySettingDropdown } from './VisibilitySettingDropdown';
import { EnumPerformanceFormVisibilitySettings, Enums } from '../../../../../config/enums';

export const AssessmentFormVisibility: React.FC<any> = (props: any) => {
	const {
		finalRatingDuration,
		setScoreVisibilityDuration,
		setIsPerformanceFormEdited,
		scoreVisibilityDuration,
		performanceFormVisibility,
		setPerformanceFormVisibility,
		performanceFormData,
		setVisibilityError,
		visibilityError,
		staticErrorObj,
		editableDetails,
		setPerformanceFormData,
	} = props;
	const { t } = useTranslation();

	const handleDropdownChange = (name: string, id: any, type: number) => {
		setIsPerformanceFormEdited(true);
		let currentIndex = -1;
		let performanceVisibilityCopy = performanceFormVisibility.map((item: any, index: number) => {
			if (item.assessmentType === type) {
				currentIndex = index;
			}
			return item;
		});
		if (currentIndex === -1) {
		} else {
			performanceVisibilityCopy[currentIndex][name] = id;
		}
		setPerformanceFormVisibility(performanceVisibilityCopy);
	};
	const isSelfRatingOn = () => {
		return performanceFormData.isSelfRatingPercentage;
	};
	const isThreeSixtyOn = () => {
		return performanceFormData.isThreeSixtyEnabled;
	};
	const getValue = (name: string, type: number) => {
		let currentIndex = 0;
		let performanceVisibilityCopy = performanceFormVisibility.map((item: any, index: number) => {
			if (item.assessmentType === type) {
				currentIndex = index;
			}
			return item;
		});
		return performanceVisibilityCopy[currentIndex][name];
	};
	const getNextMonthDate = (date: any) => {
		try {
			const maxDateValue = new Date(date || new Date());
			maxDateValue.setDate(maxDateValue.getDate() + 30);

			return maxDateValue;
		} catch (error) {
			console.error();
		}
	};
	return (
		<>
			<Box className='assessment-form-role-settings'>
				<Box className='okr-list-panel-head'>
					<List component='ul'>
						<ListItem className='assessment-form-role-col1'>
							<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-name'}></Typography>
						</ListItem>
						{
							<ListItem className='assessment-form-role-col2'>
								<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-self'}>
									<span className='txt-wrap'>{`${t('visibleTo')} ${t('assessee')}`}</span>
								</Typography>
							</ListItem>
						}
						<ListItem className='assessment-form-role-col3'>
							<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-manager'}>
								<span className='txt-wrap'>{`${t('visibleTo')} ${t('managerLabel')}`}</span>
							</Typography>
						</ListItem>
						{
							<ListItem className='assessment-form-role-col4'>
								<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-360'}>
									<span
										className='txt-wrap'
										dangerouslySetInnerHTML={{ __html: t('visibleTo') + ' ' + t('assessee360degree') }}
									></span>
								</Typography>
							</ListItem>
						}
					</List>
				</Box>
				<Box className='assessment-form-role-listing'>
					<Box className='assessment-form-role-item'>
						<List disablePadding>
							<ListItem className='assessment-form-role-col1'>
								<Box>
									<Typography>{t('selfRatingForm')}</Typography>
								</Box>
							</ListItem>
							<ListItem className='assessment-form-role-col2'>
								<Box>
									<Typography>Show</Typography>
								</Box>
							</ListItem>
							<ListItem className='assessment-form-role-col3'>
								{isSelfRatingOn() ? (
									<VisibilitySettingDropdown
										{...props}
										handleDropdownChange={(name: string, item: any) => {
											handleDropdownChange(name, item.id, 2);
										}}
										selectedValue={getValue('selfFormVisibilty', 2)}
										itemData={[
											{ id: EnumPerformanceFormVisibilitySettings.Hide, label: t('hide') },
											{ id: EnumPerformanceFormVisibilitySettings.Show, label: t('Show') },
										]}
										isDisabled={editableDetails?.status === Enums.THREE}
										name={'selfFormVisibilty'}
									/>
								) : (
									t('Input stream off')
								)}
							</ListItem>
							{isThreeSixtyOn() ? (
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							) : (
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							)}
						</List>
					</Box>

					<Box className='assessment-form-role-item'>
						<List disablePadding>
							<ListItem className='assessment-form-role-col1'>
								<Box>
									<Typography>{t('managerRatingForm')}</Typography>
								</Box>
							</ListItem>
							<ListItem className='assessment-form-role-col2'>
								<VisibilitySettingDropdown
									{...props}
									handleDropdownChange={(name: string, item: any) => {
										handleDropdownChange(name, item.id, 1);
									}}
									selectedValue={getValue('managerFormVisibilty', 1)}
									itemData={[
										{ id: EnumPerformanceFormVisibilitySettings.Hide, label: t('hide') },
										{ id: EnumPerformanceFormVisibilitySettings.Show, label: t('Show') },
									]}
									isDisabled={editableDetails?.status === Enums.THREE}
									name={'managerFormVisibilty'}
								/>
							</ListItem>
							<ListItem className='assessment-form-role-col3'>
								<Box>
									<Typography>Show</Typography>
								</Box>
							</ListItem>
							{isThreeSixtyOn() ? (
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							) : (
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							)}
						</List>
					</Box>

					<Box className='assessment-form-role-item'>
						<List disablePadding>
							<ListItem className='assessment-form-role-col1'>
								<Box>
									<Typography dangerouslySetInnerHTML={{ __html: t('threeSixtyRatingForm') }}></Typography>
								</Box>
							</ListItem>
							<ListItem className='assessment-form-role-col2'>
								{isThreeSixtyOn() ? (
									<VisibilitySettingDropdown
										{...props}
										handleDropdownChange={(name: string, item: any) => {
											handleDropdownChange(name, item.id, 1);
										}}
										selectedValue={getValue('threeSixtyFormVisibilty', 1)}
										itemData={[
											{ id: EnumPerformanceFormVisibilitySettings.Hide, label: t('hide') },
											{ id: EnumPerformanceFormVisibilitySettings.Show, label: t('Show') },
										]}
										isDisabled={editableDetails?.status === Enums.THREE}
										name={'threeSixtyFormVisibilty'}
									/>
								) : (
									t('Input stream off')
								)}
							</ListItem>
							<ListItem className='assessment-form-role-col3'>
								{isThreeSixtyOn() ? (
									<VisibilitySettingDropdown
										{...props}
										handleDropdownChange={(name: string, item: any) => {
											handleDropdownChange(name, item.id, 2);
										}}
										selectedValue={getValue('threeSixtyFormVisibilty', 2)}
										itemData={[
											{ id: EnumPerformanceFormVisibilitySettings.Hide, label: t('hide') },
											{ id: EnumPerformanceFormVisibilitySettings.Show, label: t('Show') },
										]}
										isDisabled={editableDetails?.status === Enums.THREE}
										name={'threeSixtyFormVisibilty'}
									/>
								) : (
									t('Input stream off')
								)}
							</ListItem>
							<ListItem className='assessment-form-role-col4'>
								<Box>
									<Typography>Show</Typography>
								</Box>
							</ListItem>
						</List>
					</Box>
				</Box>
			</Box>
		</>
	);
};
