import { GET_SO_PERMISSION_SUCCESS, GET_SO_PERMISSION_FAILED, OPEN_SO_CREATE_DRAWER } from '../action/actionTypes';

const INITIAL_STATE = {
	soPermissionDetails: {},
	isSoPermissionPending: false,
	isSoDrawerOpen: false,
};

export default function strategicObjectiveReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_SO_PERMISSION_SUCCESS:
			return {
				...state,
				soPermissionDetails: action.payload || {},
				isSoPermissionPending: false,
			};
		case GET_SO_PERMISSION_FAILED:
			return {
				...state,
				soPermissionDetails: {},
				isSoPermissionPending: true,
			};
		case OPEN_SO_CREATE_DRAWER:
			return {
				...state,
				isSoDrawerOpen: action.payload || false,
			};
		default:
			return state;
	}
}
