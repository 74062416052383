import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Collapse, Slider, TextField, Typography } from '@material-ui/core';
import { Enums } from '../../../config/enums';
import { SettingsIcon } from '../../../config/svg/MyGoalSvg';
import { CreateKeyResult } from '../../Goals/KeyResult/CreateKeyResult';
import { OKRButton } from '../../Common/OKRButton';
import { GAevent, getMonthDate, getMonthDateYear, isTrialVersion } from '../../../config/utils';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { getDueDays, getDueDaysText, getUserDetails } from '../../../config/utils';
import { AddIconSvg, DeleteIcon, GenerateOKRColorIcon } from '../../../config/svg/CommonSvg';
import { CallOutTooltip } from '../CallOutTooltip';
import { getLocalStorageItem } from '../../../services/StorageService';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import AlertDialog from '../../Common/Dialog';
import { calloutsDataForOKR, defaultCalloutProps } from '../../../config/constant';
import { CalendarIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRListProgressBar } from '../../Common/OKRListProgressBar';

export const CreateObjectiveDetail: React.FC<any> = (props) => {
	const {
		t,
		addNewKR,
		deleteAddedKR,
		updateFormDetail,
		handleSaveObjective,
		showAddGoal,
		durationCycleDetail,
		handleDrawerOpen,
		handleCloseCreateGoal,
		objectiveDetail,
		handleObjectiveNameChange,
		handleDateChange,
		currentCycleEndDate,
		resetKeyAttributeById,
		formSubmit,
		goalFormError,
		setShowKr,
		autoSaveData,
		isAutoSaving,
		isDataSaved,
		setIsDataSaved,
		activeOnboardingPanel,
		finalSubmitted,
		setFinalSubmitted,
		editModee,
		setEditModee,
		currentCycleId,
		handleOpenGenerateKrDrawer,
	} = props;

	const loginUserAvatar = getUserDetails();
	const loggedInUserDetail = getUserDetails();
	const [modalPropsOKR, setModalPropsOKR] = useState<any>({ open: false, type: '', message: '', module: '', data: {} });
	const [modalPropsOKRCalendar, setModalPropsOKRCalendar] = useState<any>({
		open: false,
		type: '',
		message: '',
		module: '',
		data: {},
	});
	const [userCycleChanged, setUserCycleChanged] = useState<boolean>(false);

	const handleDateCloseModalCalendar = (event: any, type: Number, startDate?: any) => {
		if (type === 1) {
			if (modalPropsOKRCalendar.type === 'updateDateForOKR') {
				setModalPropsOKRCalendar({ open: false, type: '', message: '', module: '', data: {} });
				setSelectedDateRange(modalPropsOKRCalendar.dateRange);
				handleDateChange(
					modalPropsOKRCalendar.data.date,
					'',
					modalPropsOKRCalendar.data.startDate,
					modalPropsOKRCalendar.selectedQuarterData || selectedCycleDetails
				);
				setUserCycleChanged(true);
			}
		}
		setModalPropsOKRCalendar({ open: false, type: '', message: '', module: '' });
	};

	const handleDateCloseModal = (event: any, type: Number, startDate?: any) => {
		if (type === 1) {
			if (modalPropsOKR.type === 'updateDateForOKR') {
				setModalPropsOKR({ open: false, type: '', message: '', module: '', data: {} });
				handleDateChange(
					modalPropsOKR.data.date,
					'',
					modalPropsOKR.data.startDate,
					modalPropsOKR.selectedQuarterData || selectedCycleDetails
				);
				setSelectedDateRange(modalPropsOKR.dateRange);
			}
		}
		setModalPropsOKR({ open: false, type: '', message: '', module: '' });
	};
	const hasContributor = (objectiveDetail: any) => {
		const krWithContributor = objectiveDetail?.myGoalsDetails.filter(
			(item: any) => item.contributors && item.contributors?.length > 0
		);
		if (krWithContributor?.length > 0) {
			return true;
		}
		return false;
	};

	const isDisabled = () => {
		return Boolean(objectiveDetail) &&
			objectiveDetail?.objectiveName?.length >= Enums.DEFAULT_TYPE_CHAR &&
			Boolean(objectiveDetail.myGoalsDetails) &&
			objectiveDetail.myGoalsDetails?.length &&
			objectiveDetail.myGoalsDetails.find((item: any) => item.keyDescription?.length >= Enums.DEFAULT_TYPE_CHAR)
			? false
			: true;
	};

	const checkMandatoryFieldforObjective = () => {
		return (
			Boolean(objectiveDetail) &&
			objectiveDetail?.objectiveName?.length >= Enums.DEFAULT_TYPE_CHAR &&
			objectiveDetail?.goalNatureId &&
			(objectiveDetail?.goalTypeId || !loggedInUserDetail.isTeamLeader)
		);
	};
	const checkMandatoryFieldforKR = () => {
		let isValid = Boolean(objectiveDetail) && Boolean(objectiveDetail.myGoalsDetails);
		if (isValid && objectiveDetail?.myGoalsDetails?.length) {
			let validKrs = objectiveDetail.myGoalsDetails.find((item: any) => {
				if (item?.metricId === Enums.ONE || item?.metricId === Enums.TWO || item?.metricId === Enums.THREE) {
					item.startValue = !item.startValue ? 0 : item.startValue;
					item.targetValue = !item.targetValue ? 0 : item.targetValue;
					if (item.startValue === item.targetValue) {
						return true;
					}
				}
				return (
					item.keyDescription?.length >= Enums.DEFAULT_TYPE_CHAR && (item.metricId === 0 || item.metricId === undefined)
				);
			});
			isValid = !Boolean(validKrs);
		}
		return isValid;
	};

	const validateOkrData = (e: any, type: number) => {
		setFinalSubmitted(true);
		if (checkMandatoryFieldforObjective() && checkMandatoryFieldforKR()) {
			isTrialVersion() && GAevent('SubmitOKR', 'Clicked Submit OKR', 'Submit OKR');
			setFinalSubmitted(false);
			handleSaveObjective(e, type);
		} else {
			setShowTooltip(true);
		}
	};

	//callout popup
	const [showCallouts, setShowCallouts] = useState<boolean>(false);
	const [allCycleDetails, setAllCycleDetails] = useState<any>([]);
	const [selectedDateRange, setSelectedDateRange] = useState<any>([]);
	const [OKRDateRangeDetail, setOKRDateRangeDetail] = useState<any>({
		start: objectiveDetail.startDate,
		end: objectiveDetail.endDate,
	});

	useEffect(() => {
		setOKRDateRangeDetail({
			...OKRDateRangeDetail,
			start: objectiveDetail.startDate,
			end: objectiveDetail.endDate,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveDetail]);
	useEffect(() => {
		if (getLocalStorageItem('showCallouts')) {
			setShowCallouts(true);
		}

		let getCycleDetails = JSON.parse(getLocalStorageItem('cycleDetail') || '');
		setAllCycleDetails(getCycleDetails);
	}, []);

	const initialCallout = defaultCalloutProps;
	const calloutsData = calloutsDataForOKR;
	const [callOut, setCallOut] = useState(initialCallout);
	const [anchorElCallouts, setAnchorElCallouts] = useState(null);
	const [showKrCallouts, setShowKrCallouts] = useState(false);
	const [showKrSettingCallouts, setShowKrSettingCallouts] = useState(false);
	const inputRefOkrTitle: any = useRef();
	const inputRefOkrSetting: any = useRef();
	const nextCallout = (currentCalloutIndex: Number) => {
		setShowKrCallouts(false);
		setShowKrSettingCallouts(false);

		if (currentCalloutIndex === 0) {
			setAnchorElCallouts(inputRefOkrSetting.current);
			setCallOut(calloutsData[1]);
		} else if (currentCalloutIndex === 1) {
			setShowKrCallouts(true);
		} else if (currentCalloutIndex === 2) {
			setShowKrSettingCallouts(true);
			setCallOut(calloutsData[3]);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	const prevCallout = (currentCalloutIndex: Number) => {
		setShowKrCallouts(false);
		setShowKrSettingCallouts(false);

		if (currentCalloutIndex === 3) {
			setShowKrCallouts(true);
		} else if (currentCalloutIndex === 2) {
			setAnchorElCallouts(inputRefOkrSetting.current);
			setCallOut(calloutsData[1]);
		} else if (currentCalloutIndex === 1) {
			inputRefOkrTitle.current.focus();
			//Callout for OKR Title Field
			setAnchorElCallouts(inputRefOkrTitle.current);
			setCallOut(calloutsData[0]);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	const [handIconShowed, setHandIconShowed] = useState(false);
	//black tooltip for seeting icon
	useEffect(() => {
		//Callout for Setting OKR Icon
		if (
			(!getLocalStorageItem('showCallouts') || anchorElCallouts === null) &&
			Boolean(objectiveDetail) &&
			objectiveDetail.objectiveName?.length >= 1
		) {
			if (!handIconShowed) {
				setHandIconShowed(true);
				setShowCallouts(true);
				setAnchorElCallouts(inputRefOkrSetting.current);
				setCallOut(calloutsData[4]);
				setTimeout(() => {
					setAnchorElCallouts(null);
					setCallOut(initialCallout);
				}, 5000);
			}
		}
		if (Boolean(objectiveDetail) && objectiveDetail?.objectiveName?.length <= 0) {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveDetail.objectiveName]);

	// useEffect(() => {
	// 	console.log(objectiveDetail);
	// }, [objectiveDetail]);

	useEffect(() => {
		if (
			showAddGoal &&
			activeOnboardingPanel === 'createOkr' &&
			getLocalStorageItem('createOkrcalloutDone') === null &&
			getLocalStorageItem('showCallouts') === 'true'
		) {
			setShowCallouts(true);
			const timeout = setTimeout(() => {
				inputRefOkrTitle.current.focus();
				//Callout for OKR Title Field
				setAnchorElCallouts(inputRefOkrTitle.current);
				setCallOut(calloutsData[0]);
			}, 500);
			return () => {
				clearTimeout(timeout);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOnboardingPanel]);

	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(props.userSelectedCycle);

	useEffect(() => {
		objectiveDetail?.cycleDetailData && setSelectedCycleDetails(objectiveDetail?.cycleDetailData);
	}, [objectiveDetail]);

	const [showTooltip, setShowTooltip] = useState(false);
	useEffect(() => {
		if (showTooltip) {
			setTimeout(() => {
				setShowTooltip(false);
			}, 3000);
		}
	}, [showTooltip]);

	return (
		<Collapse in={showAddGoal} className='create-okr-card-wrap'>
			<Box className='create-okr-card' id='create-okr-card'>
				{/* <OKRButton
					className='cancel-btn'
					title={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('saveAndClose') : ''}
					icon={<CancelIcon />}
					handleClick={(e) => handleCloseCreateGoal(e, 'cancel')}
				/> */}
				<Box className='create-obj'>
					<OKRButton
						icon={<GenerateOKRColorIcon />}
						title={t('generateOKRs')}
						className={'generate-okr-btn'}
						handleClick={() => {
							props.handleOpenImportDrawer({}, true);
						}}
					/>
					<Box className='create-obj-col'>
						<Box className='okr-col1'>
							<TextField
								className='add-objective'
								id='create-objective-field'
								value={objectiveDetail?.objectiveName}
								onChange={handleObjectiveNameChange}
								multiline
								autoFocus={true}
								error={(goalFormError.type === 'objectiveName' && goalFormError?.error) || ''}
								helperText={(goalFormError.type === 'objectiveName' && goalFormError?.helperText) || ''}
								maxRows={2}
								placeholder={t('AddAnobjective')} //listOkrResult?.isFirstTimeUser ? t('AddFirstObjective')
								InputProps={{ 'aria-label': 'Add Objective', disableUnderline: true }}
								inputRef={inputRefOkrTitle}
							/>
							{Boolean(objectiveDetail) && objectiveDetail?.objectiveName?.length >= Enums.DEFAULT_TYPE_CHAR ? (
								<Typography component='div' className='okr-field-actions' ref={inputRefOkrSetting}>
									<Box className='set-timeline-area'>
										<Box className='timeline-field'>
											<Box className='calendar-btn'>
												<OKRButton
													title={
														t('blankSelection')
														// props.isCreationFlow
														// 	? t('blankSelection')
														// 	: objectiveDetail.endDate
														// 	? `${getMonthDateYear(objectiveDetail.endDate)}`
														// 	: ''
													}
													text={
														objectiveDetail.endDate
															? `${getMonthDate(objectiveDetail.endDate)} (${selectedCycleDetails?.symbol}, ${
																	selectedCycleDetails?.year
															  })`
															: ''
													}
													icon={<CalendarIcon />}
													handleClick={(e) => {
														props.updateCalloutPanel('');
														setTimeout(() => {
															props.updateCalloutPanel('okrDrawer');
														}, 500);
														setCallOut(initialCallout);
														handleDrawerOpen(e);
													}}
												/>
											</Box>
										</Box>
									</Box>
									<OKRButton
										className={
											!checkMandatoryFieldforObjective() && finalSubmitted
												? 'settings-btn settings-btn-error'
												: 'settings-btn'
										}
										id='objective-setting-btn'
										icon={<SettingsIcon />}
										handleClick={(e) => {
											props.updateCalloutPanel('');
											setTimeout(() => {
												props.updateCalloutPanel('okrDrawer');
											}, 500);
											setCallOut(initialCallout);
											handleDrawerOpen(e);
										}}
										handleOver={(e) => {
											if (!getLocalStorageItem('showCallouts')) {
												props.updateCalloutPanel('');
												setCallOut(initialCallout);
											}
										}}
										text={t('attributesLabel')}
										title={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('okrHoverText') : ''}
										tooltipClassName={`${
											!checkMandatoryFieldforObjective() && finalSubmitted
												? 'tooltip-layout3 tooltip-error-new'
												: 'tooltip-layout3'
										}`}
										openTooltip={!checkMandatoryFieldforObjective() && finalSubmitted ? true : false}
									/>
									<OKRButton
										className='delete-btn'
										id='objective-delete-btn'
										icon={<DeleteIcon />}
										handleClick={(e) => handleCloseCreateGoal(e, 'delete')}
										handleOver={(e) => {}}
										text={t('delete')}
										title={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('delete') : ''}
									/>
									{objectiveDetail?.isTyped && isAutoSaving ? (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									) : (
										objectiveDetail?.isTyped &&
										isAutoSaving === false && <Typography variant='body2'>{t('saved')}</Typography>
									)}
								</Typography>
							) : getLocalStorageItem('showCallouts') ? (
								//This is non clickable button to show callouts
								<Typography component='div' className='okr-field-actions' ref={inputRefOkrSetting}>
									<OKRButton
										className='settings-btn settings-btn-disable'
										icon={<SettingsIcon />}
										handleClick={(e) => {}}
										text={t('attributesLabel')}
										id='objective-setting-btn'
									/>
								</Typography>
							) : (
								<></>
							)}
						</Box>
						{Boolean(objectiveDetail) && objectiveDetail?.objectiveName?.length >= Enums.DEFAULT_TYPE_CHAR ? (
							<Fragment>
								<Box className='okr-col2'>
									<Box className='okr-list-progressbar'>
										<Box className='okr-list-progressbar-inner'>
											<Box className='okr-list-progress-head'>
												<Box className='contributor-avatar-section'>
													<Box className='contributors'>
														<Box className='contributors-avatar-group'>
															<UserAvatarGroup
																{...props}
																contributorDetails={objectiveDetail?.contributors}
																avatarClassName=''
																isButtonVisible={false}
																max={3}
																handleAddUserClick={() => {}}
																loginUserAvatar={loginUserAvatar}
																currentUser={false}
																contributorDetailsType={true}
																showTarget={false}
																fullEmailId={true}
																viewType='okrList'
																addButtonClassName='add-btn grey-out-section'
															/>
														</Box>
													</Box>
												</Box>
												<Box className='progress-bar-section'>
													<Box className='progress-slider'>
														<Box className='obj-progress'>
															<Box className='obj-progress-slider'>
																<Slider
																	aria-label={t('MyGoalProgressSlider')}
																	valueLabelDisplay='off'
																	marks={false}
																	disabled
																/>
																<Box className='progress-timestamp-info'>
																	<Typography variant='h2'>
																		0<sup>%</sup>
																	</Typography>
																</Box>
															</Box>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Fragment>
						) : (
							<></>
						)}
					</Box>
				</Box>
				{Boolean(objectiveDetail?.myGoalsDetails) && objectiveDetail?.myGoalsDetails?.length ? (
					objectiveDetail.myGoalsDetails.map((item: Number, index: Number) => (
						<Fragment key='index'>
							<CreateKeyResult
								placeHolderTxt={t('addKeyRes')} //getPlaceHolderText(index)
								currentCycleEndDate={currentCycleEndDate}
								objectiveEndDate={objectiveDetail.endDate || new Date()}
								objectiveStartDate={objectiveDetail.startDate || new Date()}
								objectiveDetail={objectiveDetail}
								durationCycleDetail={durationCycleDetail}
								defaultTypeChar={Enums.DEFAULT_TYPE_CHAR}
								t={t}
								addNewKR={addNewKR}
								updateFormDetail={updateFormDetail}
								resetKeyAttributeById={resetKeyAttributeById}
								dateCycle={selectedCycleDetails}
								keyData={item}
								deleteAddedKR={deleteAddedKR}
								formData={objectiveDetail.myGoalsDetails.find((krData: any) => krData.index === item)}
								goalFormError={goalFormError}
								setShowKr={setShowKr}
								autoSaveData={autoSaveData}
								isAutoSaving={isAutoSaving}
								setIsDataSaved={setIsDataSaved}
								setCallOut={setCallOut}
								anchorElCallouts={anchorElCallouts}
								setAnchorElCallouts={setAnchorElCallouts}
								initialCallout={initialCallout}
								showKrCallouts={showKrCallouts}
								showKrSettingCallouts={showKrSettingCallouts}
								setShowKrSettingCallouts={setShowKrSettingCallouts}
								setShowKrCallouts={setShowKrCallouts}
								index={index}
								calloutsData={calloutsData}
								finalSubmitted={finalSubmitted}
								krFocus={props.krFocus}
								editModee={editModee}
								setEditModee={setEditModee}
								handleOpenGenerateKrDrawer={handleOpenGenerateKrDrawer}
								{...props}
							/>
						</Fragment>
					))
				) : (
					<></>
				)}

				<Box display='flex' justifyContent='flex-end'>
					<Box
						className={`create-okr-actions ${
							Boolean(objectiveDetail) && objectiveDetail?.objectiveName?.length >= Enums.DEFAULT_TYPE_CHAR
								? ''
								: 'hidden-actions'
						}`}
					>
						{/* <OKRButton
							className='button-blue-text create-add-another'
							icon={<AddIconSvg />}
							text={t('createAddAnother')}
							disabled={isDisabled() || isAutoSaving || formSubmit || objectiveDetail?.objectiveName?.trim() === ''}
							handleClick={(event: any) => validateOkrData(event, 1)} //handleSaveObjective(event, 1)
							title={
								!getLocalStorageItem('showCallouts') || anchorElCallouts === null
									? isDisabled()
										? t('okrButtonDisableTooltip')
										: t('okrSubmitAnotherButtonTooltip')
									: ''
							}
							tooltipClassName='tooltip-layout1 tooltip-layout-button'
							buttonType='createOkr'
						/> */}
						<OKRButton
							className='btn-link'
							id='save-as-draft'
							text={t('saveAsDraft')}
							handleClick={(e: any) => {
								handleSaveObjective(e, 3);
							}}
							disabled={isAutoSaving}
						/>
						<Typography>{t('orLabel')}</Typography>
						<OKRButton
							className='btn-primary'
							id='create-obj-btn'
							text={t('createObjective')}
							disabled={
								isDisabled() ||
								isDataSaved ||
								isAutoSaving ||
								formSubmit ||
								objectiveDetail?.objectiveName?.trim() === ''
							}
							handleClick={(event: any) => validateOkrData(event, 2)} //handleSaveObjective(event, 2)
							title={
								!getLocalStorageItem('showCallouts') || anchorElCallouts === null
									? isDisabled()
										? t('okrButtonDisableTooltip')
										: t('okrSubmitButtonTooltip')
									: ''
							}
							tooltipClassName='tooltip-layout3'
							buttonType='createOkr'
						/>
					</Box>
				</Box>
				{showCallouts && (
					<CallOutTooltip
						open={callOut.open}
						anchorEl={anchorElCallouts}
						setAnchorEl={setAnchorElCallouts}
						setCallOut={setCallOut}
						text={t(callOut.text)}
						placement={callOut.placement}
						classname={callOut.classname}
						handlePrev={() => prevCallout(callOut.currentCalloutIndex)}
						handleNext={() => nextCallout(callOut.currentCalloutIndex)}
						showNext={callOut.next}
						showPrevious={callOut.prev}
						showDone={callOut.done}
						initialCallout={initialCallout}
						tooltipIcon={callOut.tooltipIcon}
						activeOnboardingPanel={activeOnboardingPanel}
					/>
				)}
				{modalPropsOKR.open && (
					<AlertDialog
						module={modalPropsOKR.module ? modalPropsOKR.module : 'user'}
						message={modalPropsOKR.message}
						handleCloseModal={handleDateCloseModal}
						modalOpen={modalPropsOKR.open}
						isCancel={modalPropsOKR.module === 'validateForm' ? true : false}
					/>
				)}
				{modalPropsOKRCalendar.open && (
					<AlertDialog
						module={modalPropsOKRCalendar.module ? modalPropsOKRCalendar.module : 'user'}
						message={modalPropsOKRCalendar.message}
						handleCloseModal={handleDateCloseModalCalendar}
						modalOpen={modalPropsOKRCalendar.open}
						isCancel={modalPropsOKRCalendar.module === 'validateForm' ? true : false}
					/>
				)}
			</Box>
		</Collapse>
	);
};
