import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { GenerateOkrHead } from './GenerateOkrHead';
import { GenerateKrList } from './GenerateKrList';
import noRecordFound from '../../../../images/ChatLoader.svg';
import { CreateOKRIcon } from '../../../../config/svg/MyGoalSvg';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import { useDispatch } from 'react-redux';
import {
	aiAddKeyResult,
	aiListKeyResult,
	generateMoreKrData,
	getAiFilter,
	removeAiEditKr,
	removeAiKr,
	updateAiEditKr,
	updateAiKr,
} from '../../../../action/OkrAi';
import { Enums } from '../../../../config/enums';
import noFound from '../../../../images/search-placeholder.svg';
import { getUserDetails } from '../../../../config/utils';

export const GenerateKr: React.FC<any> = (props) => {
	const [advanceSearchData, setAdvanceSearchData] = React.useState<any>({
		department: '',
		designation: '',
		industry: '',
		soDetails: [],
		selectedSo: {},
		userInput: '',
	});
	const { aiObjective } = props;
	const { t } = useTranslation();
	const [mainTabSelected, setMainTabSelected] = useState<Number>(0);
	const handleMainTabChange = (event: any, newValue: Number) => {
		setMainTabSelected(newValue);
	};
	const dispatch = useDispatch();
	const [okrListData, setOkrListData] = useState<any[]>([]);
	const [generateDisabled, setGenerateDisabled] = useState<boolean>(false);
	const [isListApiPending, setIsListApiPending] = useState<boolean>(true);
	const [moreOkrApiPending, setMoreOkrApiPending] = useState<string>('');
	const [pageNumbers, setPageNumbers] = useState<any[]>([]);
	const [krIdMapping, setKrIdMappingMapping] = useState<any>({});
	const [recommendationId, setRecommendationId] = useState<number>(0);

	const pageSize = 5;
	useEffect(() => {
		loadSearchCriteria();
	}, []);

	const loadSearchCriteria = async () => {
		let response: any = await dispatch(getAiFilter(''));
		if (response.data.status === Enums.STATUS_SUCCESS) {
			let selectedSo: any[] = response.data.entity.soDetails.filter((item: any) => item.isSelected);
			setAdvanceSearchData({
				...response.data.entity,
				selectedSo: selectedSo.length > 0 ? selectedSo[0] : {},
			});
			getKrAi(response.data.entity);
		}
	};
	const getKrAi = async (apiData: any) => {
		setIsListApiPending(true);
		let data: any = {
			sectionName: apiData.userInput ? apiData.userInput : '',
			userInput: apiData.userInput ? apiData.userInput : '',
			designation: apiData.designation,
			department: apiData.department,
			industry: apiData.industry,
			so: apiData.so ? apiData.so : '',
			objective: aiObjective.objectiveName,
			objectiveId: aiObjective.goalObjectiveId,
			recommendationType: 2,
			isUserGeneration: apiData.isUserGeneration ? true : false,
			recommendationId: recommendationId,
		};
		const response: any = await dispatch(aiListKeyResult(data));
		if (Boolean(response) && response.data.status === 200) {
			setOkrListData(response.data.entity.sectionDatas);
			setPageNumbers(response.data.entity.sectionDatas.map(() => 1));
			setGenerateDisabled(false);
			setIsListApiPending(false);
			setRecommendationId(response.data.entity.recommendationId);
		} else {
			setIsListApiPending(false);
		}
	};

	const getMoreOkrData = async (sectionId: any) => {
		setMoreOkrApiPending(sectionId);
		let requestPayload: any = {
			sectionId: sectionId,
			objectiveId: aiObjective.goalObjectiveId,
			recommendationId: recommendationId,
		};
		const response: any = await dispatch(generateMoreKrData(requestPayload));
		if (Boolean(response) && response.data.status === 200) {
			let list: any[] = Object.assign([], okrListData);
			const index = list.findIndex((ele: any) => ele.sectionId === sectionId);
			list[index] = response.data.entity;
			setOkrListData(list);
			setMoreOkrApiPending('');
		} else {
		}
	};

	const handlePageChange = (newPageNumber: number, index: number) => {
		setPageNumbers(pageNumbers.map((pageNumber, i) => (i === index ? newPageNumber : pageNumber)));
	};
	const generateKr = async () => {
		setGenerateDisabled(true);
		let generateOkrData = { ...advanceSearchData, isUserGeneration: true };
		if (advanceSearchData?.selectedSo?.soDetailsId) {
			generateOkrData.so = advanceSearchData.selectedSo.soName;
		}
		getKrAi(generateOkrData);
	};
	function a11yProps(index: number) {
		return {
			id: `gen-okr-tabpanel-${index}`,
			'aria-controls': `gen-okr-tabpanel-${index}`,
		};
	}

	const addKeyResult = async (apiData: any) => {
		let data: any = {
			objectiveName: aiObjective.objectiveName,
			krName: apiData.keyResult,
			startDate: props.userSelectedCycle.startDate,
			dueDate: props.userSelectedCycle.endDate,
			objectiveId: aiObjective.goalObjectiveId,
			cycleId: props.userSelectedCycle.organisationCycleId,
			year: props.userSelectedCycle.year,
		};
		if (!aiObjective.goalObjectiveId) {
			dispatch(updateAiKr(data));
		} else {
			dispatch(updateAiEditKr({ ...data, goalObjectiveId: aiObjective.goalObjectiveId }));
			/*const response: any = await dispatch(aiAddKeyResult(data));
			if (Boolean(response) && response.data.status === 200) {
				setKrIdMappingMapping({
					...krIdMapping,
					[apiData.krId]: response.data.entity.goalKeyId,
				});
			} else {
				setIsListApiPending(false);
			}*/
		}
	};

	const removeKeyResult = async (apiData: any) => {
		if (!aiObjective.goalObjectiveId) {
			dispatch(removeAiKr(apiData));
		} else {
			dispatch(removeAiEditKr({ ...apiData, objectiveId: aiObjective.goalObjectiveId }));
			/*const userDetail: any = getUserDetails();
			const { employeeId } = userDetail;
			const data = `employeeId=${employeeId}&goalKeyId=${deleteDataId}&isDeleteFromAI=true`;
			const response = await props.deleteContributor(data);
			if (Boolean(response) && response.data.status === 200) {
				let aiObjList: any = { ...krIdMapping };
				delete aiObjList[apiData.krId];
				setKrIdMappingMapping(aiObjList);
			} else {
				setIsListApiPending(false);
			}*/
		}
	};
	return (
		<>
			<Tabs
				className='okr-tabs drawer-tabs'
				value={mainTabSelected}
				onChange={handleMainTabChange}
				aria-label='Copy Tabs'
			>
				<Tab label={t('keyResultTab')} value={0} {...a11yProps(0)} />
			</Tabs>
			<Box className='drawer-tabs-panel generate-okr-tabpanel'>
				<Box className='import-okr-tabpanel'>
					<Box className='generate-okr-title'>
						<Box className='okr-title-name'>
							<Tooltip title={t('objectiveName')} arrow>
								<span>
									<CreateOKRIcon />
								</span>
							</Tooltip>
							<Tooltip title={'Full Text'} arrow>
								<Typography variant='h5' className='font-weight-normal'>
									{aiObjective.objectiveName}
								</Typography>
							</Tooltip>
						</Box>
					</Box>
					<GenerateOkrHead
						type={'kr'}
						{...props}
						advanceSearchData={advanceSearchData}
						setAdvanceSearchData={setAdvanceSearchData}
						generateOkr={generateKr}
						generateDisabled={generateDisabled}
						moreOkrApiPending={moreOkrApiPending}
					/>
					<Box className='generate-okr-list generate-kr-list'>
						{!isListApiPending ? (
							okrListData?.length === 0 ? (
								<Box className='search-no-record-global'>
									<Box className='no-record-message'>
										<Typography variant='h2' component='h2'>
											{t('aiNoResponse')}
										</Typography>
										<AnimatedIcon
											className=''
											width='230'
											type='image/svg+xml'
											alt={t('globalSearchNoRecord')}
											imagePath={noFound}
										/>
									</Box>
								</Box>
							) : (
								<GenerateKrList
									{...props}
									okrListData={okrListData}
									getMoreOkrData={getMoreOkrData}
									pageNumbers={pageNumbers}
									pageSize={pageSize}
									handlePageChange={handlePageChange}
									moreOkrApiPending={moreOkrApiPending}
									addKeyResult={addKeyResult}
									removeKeyResult={removeKeyResult}
								/>
							)
						) : (
							<Box className='generate-okr-loader'>
								<AnimatedIcon
									className=''
									width='300'
									type='image/svg+xml'
									alt={t('globalSearchNoRecord')}
									imagePath={noRecordFound}
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
