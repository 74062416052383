import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Enums } from '../../../config/enums';

export const ProgressTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};
ProgressTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any,
	value: PropTypes.any,
};
function a11yProps(index: number) {
	return {
		id: `reflection-tabpanel-${index}`,
		'aria-controls': `reflection-tabpanel-${index}`,
	};
}

export const ReflectionsTabs: React.FC<any> = (props) => {
	const { tabSelected, setTabSelected, tabDetails } = props;
	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};

	return (
		<>
			<Box className='tab-scroller'>
				<Tabs
					value={tabSelected}
					onChange={handleTabChange}
					indicatorColor='primary'
					textColor='primary'
					aria-label='Reflections tabs '
				>
					{tabDetails && tabDetails?.length ? (
						tabDetails.map((tab: any, indexVal: number) => (
							<Tab
								label={
									<>
										<span
											className={`week-status ${
												tab.checkInStatus === Enums.CHECKED_IN_MISSED
													? 'checked-in-missed'
													: tab.checkInStatus === Enums.NOT_YET_CHECKED_IN
													? 'not-yet-checked'
													: tab.checkInStatus === Enums.STATUS_CHECKED_ID
													? 'checked-in'
													: tab.checkInStatus === Enums.CHECKED_IN_NO_DATA
													? 'checked-no-data'
													: ''
											}`}
										></span>
										<span>{tab.displayDate}</span>
									</>
								}
								className={'accomplished-tab'}
								{...a11yProps(indexVal)}
							/>
						))
					) : (
						<></>
					)}
				</Tabs>
			</Box>
		</>
	);
};
