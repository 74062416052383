import React, { Fragment, useState } from 'react';
import {
	Box,
	Chip,
	ClickAwayListener,
	Grow,
	ListItem,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { SettingsIcon, StrategicGoalIcon } from '../../../config/svg/MyGoalSvg';
import CircularProgress from '../../OkrAction/CircularProgress';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { DeleteIcon } from '../../../config/svg/CommonSvg';
import { useDispatch } from 'react-redux';
import { SOProgress } from '../SOProgress';
import { Enums } from '../../../config/enums';
import { updateSoProgress } from '../../../action/strategicObjective';
import { useSnackbar } from 'notistack';

export const SoListing: React.FC<any> = (props: any) => {
	const { soList, clickSOAttributeClick, soPermission, deleteSOAttributeClick, getSoOkrList } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [openProgressDetail, setOpenProgressDetail] = useState<any>(null);
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [isAPIPending, setIsAPIPending] = useState<boolean>(false);
	const [openPerspectiveMenu, setOpenPerspectiveMenu] = useState<boolean>(false);
	const [anchorElPerspective, setAnchorElPerspective] = useState<HTMLElement | null>(null);
	const [hoverIndex, setHoverIndex] = useState<any>(null);

	const handleOpenProgress = (e: any, selectedItem: any) => {
		if (openProgressDetail && openProgressDetail?.soDetailsId === selectedItem?.soDetailsId) {
			setOpenProgressDetail(
				openProgressDetail ? openProgressDetail : { ...selectedItem, updatedScore: selectedItem?.score }
			);
		} else {
			setOpenProgressDetail({ ...selectedItem, updatedScore: selectedItem?.score });
		}
		setAnchorEl({ [selectedItem?.soDetailsId]: e.currentTarget });
	};

	const handleSaveProgress = async (e: any, details: any) => {
		try {
			setIsAPIPending(true);
			const requestParam = `soDetailsId=${details?.soDetailsId}&score=${details?.updatedScore}`;
			const requestPayload = null; // { soDetailsId: details?.soDetailsId, score: details?.updatedScore };
			const response: any = await dispatch(updateSoProgress(requestPayload, requestParam));
			if (response && response?.data && response?.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys?.map((item: any) => messageList[item]) : '';
				enqueueSnackbar(messages || 'Progress updated successfully', {
					variant: 'success',
					autoHideDuration: 3000,
				});

				setAnchorEl(null);
				setOpenProgressDetail(null);
				getSoOkrList(0, 'direct');
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys?.map((item: any) => messageList[item]) : '';
				enqueueSnackbar(messages || 'Error occurred while updating progress', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setIsAPIPending(false);
		} catch (error) {
			console.error(error);
			enqueueSnackbar('Error occurred while updating progress', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setIsAPIPending(false);
		}
	};

	const handleCloseProgressBar = (event: any, type: string) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		setAnchorEl(null);
		setOpenProgressDetail(null);
	};

	const handleFieldChange = (value: any) => {
		const regexp = /^[0-9\\.b]+$/;
		if (value === '' || regexp.test(value)) {
			let valueVal = !Boolean(value) ? '' : value;
			updateSOProgress(valueVal);
		}
	};
	const handleSliderChange = (event: any, newValue: any) => {
		updateSOProgress(newValue);
	};
	const updateSOProgress = (value: any) => {
		const updatedSoDetail = { ...openProgressDetail };
		const score = value ? parseFloat(value) : 0;
		updatedSoDetail.updatedScore = score;
		setOpenProgressDetail(updatedSoDetail);
	};
	const calculateScorePercent = (score: number) => {
		return score && score >= 100 ? 100 : score || 0;
	};
	const handleOpenPerspective = (event: any, index: any) => {
		setAnchorElPerspective(event.currentTarget);
		setOpenPerspectiveMenu(true);
		setHoverIndex(index);
	};
	const handleClosePerspective = (event: any) => {
		setAnchorElPerspective(null);
		setOpenPerspectiveMenu(false);
		setHoverIndex(null);
	};

	return (
		<Box className='so-okr-card-list'>
			{soList &&
				soList.length > 0 &&
				soList.map((item: any, index: any) => (
					<Grow
						key={`SO_Listing_Index_${item?.soDetailsId}`}
						in={true}
						style={{ transformOrigin: '0 0 0' }}
						{...(true ? { timeout: 1500 } : {})}
					>
						<Box className='so-okr-card'>
							<Box className='so-okr-card-top'>
								<Box className='okr-status-info'>
									{item?.status === Enums.GOAL_STATUS_DRAFT ? (
										<Chip className='draft-chip' label={t('draft')} />
									) : (
										<></>
									)}
								</Box>
								<Box className='so-okr-card-top-inner'>
									<Box className='so-okr-card-info'>
										<Box className='so-okr-icon'>
											<StrategicGoalIcon />
										</Box>
										<Box className='so-okr-title'>
											{item?.soName.length > 70 ? (
												<Tooltip title={item?.soName} arrow>
													<Typography variant='h4'>{item?.soName.substring(0, 70) + '...'}</Typography>
												</Tooltip>
											) : (
												<Typography variant='h4'>{item?.soName}</Typography>
											)}
										</Box>
									</Box>
									{soPermission?.isSoProgressVisibilityRights && item?.status === Enums.GOAL_STATUS_DRAFT ? (
										<Box className='so-progress-chart'>
											<Box className='circular-progress-chart'>
												<CircularProgress percentage={0} colorCode={item?.progressCode} score={'0'} />
											</Box>
										</Box>
									) : soPermission?.isSoProgressVisibilityRights &&
									  (soPermission?.calculationTechnique !== Enums.TWO ||
											(!soPermission?.isCreatorRights && soPermission?.calculationTechnique === Enums.TWO)) ? (
										<Box className='so-progress-chart'>
											<Box className='circular-progress-chart'>
												<CircularProgress percentage={item?.score} colorCode={item?.progressCode} score={item?.score} />
											</Box>
										</Box>
									) : soPermission?.isSoProgressVisibilityRights &&
									  soPermission?.isCreatorRights &&
									  soPermission?.calculationTechnique === Enums.TWO ? (
										<Box className='so-progress-chart'>
											<Box
												className='circular-progress-chart cursor-pointer'
												onClick={(e) => handleOpenProgress(e, item)}
											>
												<CircularProgress
													percentage={calculateScorePercent(item?.score)}
													colorCode={item?.progressCode}
													score={calculateScorePercent(item?.score)?.toString()}
												/>
												{openProgressDetail && anchorEl && anchorEl[item.soDetailsId] ? (
													<SOProgress
														openProgressDetail={openProgressDetail}
														setOpenProgressDetail={setOpenProgressDetail}
														anchorEl={anchorEl[item.soDetailsId]}
														setAnchorEl={setAnchorEl}
														isAPIPending={isAPIPending}
														handleSaveProgress={handleSaveProgress}
														handleCloseProgressBar={handleCloseProgressBar}
														handleSliderChange={handleSliderChange}
														handleFieldChange={handleFieldChange}
														calculateScorePercent={calculateScorePercent}
													/>
												) : null}
											</Box>
										</Box>
									) : (
										<></>
									)}
								</Box>
								{soPermission?.isCreatorRights ? (
									<Box className='so-okr-card-actions'>
										<PopperMenu
											popperClassName={'popper-menu manage-form-popper-menu'}
											includeKebabMenu={true}
											children={
												<>
													<ListItem onClick={(event) => deleteSOAttributeClick(event, item)}>
														<ListItemAvatar>
															<DeleteIcon />
														</ListItemAvatar>
														<ListItemText primary={t('delete')} />
													</ListItem>
												</>
											}
										/>
									</Box>
								) : (
									<></>
								)}
							</Box>
							{/* {soPermission?.isCreatorRights ? ( */}
							<Box className='so-okr-card-mid'>
								<OKRButton
									className='settings-btn'
									icon={<SettingsIcon />}
									title={t('strategicObjAttributesLabel')}
									handleClick={(event) => clickSOAttributeClick(event, item)}
								/>
							</Box>
							{/* ) : (
								<></>
							)} */}
							<Box className='so-okr-card-bottom'>
								<Box className='so-okr-tags'>
									{item.perspectiveTags &&
										item.perspectiveTags.length > 0 &&
										item.perspectiveTags.slice(0, 3).map((tag: any, index: any) => {
											return (
												<>
													{tag?.perspectiveNameTag.length > 12 ? (
														<Tooltip title={tag?.perspectiveNameTag} arrow>
															<Chip label={tag?.perspectiveNameTag.substring(0, 12) + '...'} />
														</Tooltip>
													) : (
														<Chip label={tag?.perspectiveNameTag} />
													)}
												</>
											);
										})}
									{item.perspectiveTags.length > 3 && (
										<Box
											className='no-of-tags'
											aria-owns={openPerspectiveMenu ? 'mouse-over-popover' : undefined}
											aria-haspopup='true'
											onMouseOver={(e: any) => {
												handleOpenPerspective(e, index);
											}}
											onMouseLeave={handleClosePerspective}
										>
											<OKRButton className={'btn-count'} text={`+${item.perspectiveTags.length - 3}`} />

											{openPerspectiveMenu && index === hoverIndex && (
												<Popper
													className='popper-menu-list team-filter-list-menu perspectiveTags-list-menu'
													transition
													open={openPerspectiveMenu}
													anchorEl={anchorElPerspective}
													placement='bottom-start'
												>
													<Paper>
														<ClickAwayListener onClickAway={handleClosePerspective}>
															<MenuList id='direct-report-team-list'>
																{item.perspectiveTags.length > 0 &&
																	item.perspectiveTags.map((tag: any, index: number) => (
																		<>
																			{index >= 3 && (
																				<MenuItem
																					key={tag?.perspectiveNameTag}
																					id={`team-name-${tag?.perspectiveNameTag}`}
																				>
																					<ListItemText
																						primary={
																							<Typography variant='h5' className='team-filter-txt'>
																								{tag?.perspectiveNameTag && tag?.perspectiveNameTag.length > 32 ? (
																									<Tooltip arrow title={tag?.perspectiveNameTag} aria-label='Teams'>
																										<Typography>
																											{tag?.perspectiveNameTag.substring(0, 32)}...
																										</Typography>
																									</Tooltip>
																								) : (
																									<Typography>{tag?.perspectiveNameTag}</Typography>
																								)}
																							</Typography>
																						}
																					/>
																				</MenuItem>
																			)}
																		</>
																	))}
															</MenuList>
														</ClickAwayListener>
													</Paper>
												</Popper>
											)}
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Grow>
				))}
			{/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box className='so-okr-card'>
					<Box className='so-okr-card-top'>
						<Box className='so-okr-card-info'>
							<Box className='so-okr-icon'>
								<StrategicGoalIcon />
							</Box>
							<Box className='so-okr-title'>
								<Typography variant='h4'>Establish a Wonderful Employee Wellness Program</Typography>
							</Box>
						</Box>
						<Box className='so-progress-chart'>
							<Box className='circular-progress-chart'>
								<CircularProgress percentage={50} colorCode={'#ff0000'} score={'50%'} />
							</Box>
						</Box>
					</Box>
					<Box className='so-okr-card-bottom'>
						<Box className='so-okr-tags'>
							<Chip label='Customer' />
							<Chip label='Internal Process' />
						</Box>
					</Box>
				</Box>
			</Grow>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box className='so-okr-card'>
					<Box className='so-okr-card-top'>
						<Box className='so-okr-card-info'>
							<Box className='so-okr-icon'>
								<StrategicGoalIcon />
							</Box>
							<Box className='so-okr-title'>
								<Typography variant='h4'>Implement Continuous Performance Management</Typography>
							</Box>
						</Box>
						<Box className='so-progress-chart'>
							<Box className='circular-progress-chart'>
								<CircularProgress percentage={20} colorCode={'#ff0000'} score={'20%'} />
							</Box>
						</Box>
					</Box>
					<Box className='so-okr-card-bottom'>
						<Box className='so-okr-tags'>
							<Chip label='Finance' />
							<Chip label='Customer' />
							<Chip label='Internal Process' />
							<Box className='no-of-tags'>2</Box>
						</Box>
					</Box>
				</Box>
			</Grow>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box className='so-okr-card'>
					<Box className='so-okr-card-top'>
						<Box className='so-okr-card-info'>
							<Box className='so-okr-icon'>
								<StrategicGoalIcon />
							</Box>
							<Box className='so-okr-title'>
								<Typography variant='h4'>Design Irresistible Employee Benefits Program</Typography>
							</Box>
						</Box>
						<Box className='so-progress-chart'>
							<Box className='circular-progress-chart'>
								<CircularProgress percentage={62} colorCode={'#ff0000'} score={'62%'} />
							</Box>
						</Box>
					</Box>
					<Box className='so-okr-card-bottom'>
						<Box className='so-okr-tags'>
							<Chip label='Finance' />
							<Chip label='Internal Process' />
						</Box>
					</Box>
				</Box>
			</Grow>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box className='so-okr-card'>
					<Box className='so-okr-card-top'>
						<Box className='so-okr-card-info'>
							<Box className='so-okr-icon'>
								<StrategicGoalIcon />
							</Box>
							<Box className='so-okr-title'>
								<Typography variant='h4'>Make our Product Management team even better</Typography>
							</Box>
						</Box>
						<Box className='so-progress-chart'>
							<Box className='circular-progress-chart'>
								<CircularProgress percentage={58} colorCode={'#ff0000'} score={'58%'} />
							</Box>
						</Box>
					</Box>
					<Box className='so-okr-card-bottom'>
						<Box className='so-okr-tags'>
							<Chip label='Internal Process' />
							<Chip label='Learning & Growth' />
						</Box>
					</Box>
				</Box>
			</Grow> */}
		</Box>
	);
};
