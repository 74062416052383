import React, { Fragment, useEffect, useState } from 'react';
import { OKRDrawer } from '../../../../Common/OKRDrawer';
import { addCopyOkr, openAdminCopyOkr, refreshAdminDefaultOkrList } from '../../../../../action/adminDefaultOkr';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CopyAdminOkr } from './CopyAdminOkr';
import { Box } from '@material-ui/core';
import { MainDrawer } from '../../../CommonComponent/MainDrawer';
import '../../../../../styles/pages/admin/settings/default-okr/copyDefaultOkrDrawer.scss';

export const CopyOkrMainDrawer: React.FC<any> = (props) => {
	const { open } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {}, []);

	const [mainTabSelected, setMainTabSelected] = useState<any>(0);
	const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
	const [krToggle, setKrToggle] = useState<any[]>([]);
	const { alignSelectedCycle } = useSelector((state: any) => state.adminDefaultOkrReducer);

	const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		setMainTabSelected(newValue);
	};
	const handleDrawerCloseClick = (event: any, type: any) => {
		if (type === 'save') {
			copyOkr();
		} else {
			dispatch(openAdminCopyOkr(false));
		}
	};
	const copyOkr = async () => {
		setSaveInProgress(true);
		let requestPayload: any[] = [];
		Object.keys(krToggle).forEach((okrKey: any) => {
			let importKrData: any = {
				...krToggle[okrKey],
				teamCycleDetailId: alignSelectedCycle.organisationCycleId,
				defaultCopyKeyResults: [],
			};
			Object.keys(krToggle[okrKey].krList).forEach((krKey: any) => {
				importKrData.defaultCopyKeyResults.push({
					name: krToggle[okrKey].krList[krKey].name,
				});
			});
			delete importKrData.krList;
			requestPayload.push(importKrData);
		});
		const response: any = await dispatch(addCopyOkr(requestPayload));
		if (response.status === 200) {
			setSaveInProgress(false);
			dispatch(openAdminCopyOkr(false));
			dispatch(refreshAdminDefaultOkrList(true));
		} else {
			setSaveInProgress(false);
		}
	};

	return (
		<Fragment>
			{/* <OKRDrawer
				open={open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={'Default OKRs'}
				children={<CopyAdminOkr />}
				moreButton={<></>}
				handleDrawerClose={(event: any) => handleDrawerCloseClick(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerCloseClick(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerCloseClick(event, 'close')}
				hideDefaultButton={false}
				//loader={loader}
				isSaveButtonDisabled={false}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/> */}
			<MainDrawer
				open={open}
				drawerClassName={'main-drawer-panel'}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('defaultOkrs')}
				//loader={loader}
				children={
					<Box className='drawer-inner-content'>
						<CopyAdminOkr
							mainTabSelected={mainTabSelected}
							handleTabChange={handleTabChange}
							{...props}
							krToggle={krToggle}
							setKrToggle={setKrToggle}
						/>
					</Box>
				}
				saveButtonText={t('copyBtnLevel')}
				handleDrawerClose={(event: any) => handleDrawerCloseClick(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerCloseClick(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerCloseClick(event, 'close')}
				isFooterVisible={true}
				isSaveButtonVisible={true}
				isSaveButtonDisabled={Object.keys(krToggle).length === 0 || saveInProgress}
			/>
		</Fragment>
	);
};
