import React from 'react';
import {
	Avatar,
	Box,
	ClickAwayListener,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from './OKRButton';
import { BottomArrowIcon, TeamUserIcon } from '../../config/svg/CommonSvgIcon';
import { EditTreeIcon } from '../../config/svg/CommonSvg';
import { CancelIcon } from '../../config/svg/Action';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';

export const OkrTeamDropdown: React.FC<any> = (props) => {
	const { treeNodesData, selectedTeams, handleSelectionChange, disabledBtn } = props;
	const { t } = useTranslation();
	const [open, setOpen] = React.useState<Boolean>(false);
	const handleClick = () => {
		if (!disabledBtn) {
			setOpen((prev: Boolean) => !prev);
		}
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const nodeClicked = (args: any) => {
		handleClickAway();
		if (args.value === 0) {
			handleSelectionChange([]);
			return;
		}
		let nodeDetails: any = Object.assign({}, args);
		let selectedNode = [];
		selectedNode.push(nodeDetails);
		handleSelectionChange(selectedNode);
	};
	return (
		<>
			{
				(!selectedTeams || selectedTeams.length === 0 || open) ?
					<Box className={`okr-team-dropdown ${open ? 'active' : ''}`}>
						<OKRButton
							className={'dropdown-button'}
							text={'Select Your Team'}
							icon={<BottomArrowIcon />}
							handleClick={handleClick}
						></OKRButton>
						{open && (
							<ClickAwayListener onClickAway={handleClickAway}>
								<Box className='team-dropdown'>
									<Box className='dropdown-head'>
										<Typography>{t('myTeamLabel')}</Typography>
									</Box>
									<List component='div' disablePadding>
										{
											treeNodesData?.map((item: any) =>
												<ListItem component='div' key={'key'} id={`team-list-`} onClick={() => {
													nodeClicked(item);
												}}>
													<ListItemText className='team-details' disableTypography>
														{!item.noAvatar && <ListItemAvatar>
															{item.imgagePath ? (
																<Avatar
																	className='team-avatar' alt={`${item.teamName}`}
																	src={item.imgagePath}
																/>
															) : (
																<Avatar
																	className='team-avatar'
																	style={{
																		backgroundColor: getRandomBackgroundColor(
																			item.backGroundColorCode
																		),
																		color: getRandomColor(item.colorCode),
																	}}
																>
																	{getUserName({
																		firstName: '',
																		lastName: '',
																		fullName: item.teamName,
																	})}
																</Avatar>
															)}
														</ListItemAvatar>
														}
														<Box className='team-info'>
															<Typography variant='h4'>{item.teamName}</Typography>
															<Box display='flex'>
																<Typography variant='subtitle2'>
																	<TeamUserIcon /> {item.teamMembersCount} {t('membersLabel')}
																</Typography>
															</Box>
														</Box>
													</ListItemText>
												</ListItem>
											)
										}
									</List>
								</Box>
							</ClickAwayListener>
						)}
					</Box> :
					<Box className='user-team-wrap'>
						<Box className='user-info user-team-info'>
							<Box id={`team-avatar-`} className='user-img'>
								{selectedTeams[0].imgagePath ? (
									<Avatar
										className='team-avatar' alt={`${selectedTeams[0].teamName}`}
										src={selectedTeams[0].imgagePath}
									/>
								) : (
									<Avatar
										className='team-avatar'
										style={{
											backgroundColor: getRandomBackgroundColor(
												selectedTeams[0].backGroundColorCode
											),
											color: getRandomColor(selectedTeams[0].colorCode),
										}}
									>
										{getUserName({
											firstName: '',
											lastName: '',
											fullName: selectedTeams[0].teamName,
										})}
									</Avatar>
								)}
							</Box>
							<Box className='user-info-details'>
								<Typography id={`team-fullname-`} variant='h4'>
									{selectedTeams[0].teamName}
								</Typography>
								<Typography id={`team-count-`} variant='subtitle2'>
									{
										selectedTeams[0].teamMembersCount > 0 ?
											<span className='secondary-text-span'>{selectedTeams[0].teamMembersCount} {t('membersLabel')}</span>
											: <span className='secondary-text-span no-member'>{t('noMemberLabel')}</span>
									}
								</Typography>
							</Box>
						</Box>
						{
							!disabledBtn ?
								<>
									<OKRButton
										id={'edit-btn'}
										className={'action-btn'}
										icon={<EditTreeIcon />}
										title={t('editLabel')}
										handleClick={() => {
											handleClick()
										}}
									/>
									<OKRButton
										id={'delete-btn'}
										className={'action-btn'}
										icon={<CancelIcon />}
										title={t('delete')}
										handleClick={() => {
											nodeClicked({
												value: 0
											})
										}}
									/>
								</> :
								''
						}
					</Box>
			}
		</>
	);
};
