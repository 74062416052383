import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Box, List, ListItem, TablePagination, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import { SearchIcon } from '../../../../../config/svg/CommonSvg';
import { CrossIcon, SortingIcon } from '../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../Common/OKRButton';
import { NoRecord } from '../../../CommonComponent/NoRecord';
import AssignContributor from '../../../../OkrAction/AssignContributor';
import { useDispatch } from 'react-redux';
import { globalSearchAPIWithTeam, searchEmployee } from '../../../../../action/search';
import { getUserById, updatePage } from '../../../../../action/common';
import { getFullName, getUserName } from '../../../../../config/utils';
import { Enums } from '../../../../../config/enums';
import AlertDialog from '../../../../Common/Dialog';
import { performanceCycleIncludeEmployee } from '../../../../../action/adminSettings';
import { useSnackbar } from 'notistack';

export const ExcludeManageUser: React.FC<any> = (props: any) => {
	const { excludedUseList, drawerType, type, editableDetails, localArrList, setLocalArrList, setExcludedUseList } =
		props;

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [totalUser, setTotalUser] = useState(0);
	const [pageIndex, setPageIndex] = useState<any>(0);
	const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');

	useEffect(() => {
		if (excludedUseList && excludedUseList.selectedUser && excludedUseList.selectedUser.length) {
			const updatedEmpList = dataPerPage(excludedUseList?.selectedUser, pageIndex + 1, pageSize);
			setTotalUser(excludedUseList.selectedUser.length);
			setLocalArrList({
				...localArrList,
				selectedUser: [...updatedEmpList],
				raisedForId: [...updatedEmpList].map((item: any) => item.employeeId),
			});
		} else {
			if (localArrList?.selectedUser && localArrList?.selectedUser?.length) {
				// localArrList.selectedUser.forEach((record: any) => {
				// 	removeSelectedUser(record);
				// });
				setLocalArrList({
					selectedUser: [],
					raisedForId: [],
				});
			} else {
				setLocalArrList({
					selectedUser: [],
					raisedForId: [],
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [excludedUseList]);

	const onSelectedUser = async (selected: any) => {
		const isExist =
			localArrList.selectedUser &&
			localArrList.selectedUser.find((item: any) => item.employeeId === selected[0].employeeId);
		if (selected.length > 0 && !isExist) {
			const allUser = [...localArrList.selectedUser, { ...selected[0], newRecord: true }];
			const allUserIds = allUser.map((item) => item.employeeId);

			setLocalArrList({
				...localArrList,
				selectedUser: allUser,
				raisedForId: allUserIds,
			});
		}
	};
	const removeUser = async (e: any, record: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (type === 'Edit' && editableDetails && editableDetails?.status === Enums.TWO && !record?.newRecord) {
			setModalProps({
				open: true,
				message: t('deleteConfirmationForAlignment', {
					type: 'user',
				}),
				details: record,
			});
		} else {
			removeSelectedUser(record);
		}
	};
	const removeSelectedUser = (record: any) => {
		const { employeeId } = record || {};
		const user = excludedUseList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);
		const userIds = excludedUseList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);

		setExcludedUseList({
			selectedUser: user,
			raisedForId: [...userIds.map((item: any) => item.employeeId)],
		});
		setPageIndex(0);
		// setLocalArrList({
		// 	...localArrList,
		// 	selectedUser: user,
		// 	raisedForId: [...userIds.map((item: any) => item.employeeId)],
		// });
	};

	const searchCustomEmployee = (searchStr: any, page: any, pageSize: any) => {
		return dispatch(searchEmployee(searchStr, page, pageSize));
	};
	const updateCustomPage = (page: any) => {
		return dispatch(updatePage(page));
	};
	const globalSearchWithTeam = (searchStr: any, page: any, pageSize: any, type: any) => {
		return dispatch(globalSearchAPIWithTeam(searchStr, page, pageSize, type));
	};
	const getCustomUserById = (data: any) => {
		return dispatch(getUserById(data));
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps && modalProps?.details) {
				const { employeeId } = modalProps?.details;
				const requestPayload = {
					performanceCycleId: editableDetails?.performanceCycleId || 0,
					employeeIds: [employeeId],
				};
				const response: any = await dispatch(performanceCycleIncludeEmployee(requestPayload));
				if (response) {
					if (response?.data && response?.data.status === 200) {
						const { messageList } = response.data;
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						enqueueSnackbar(messages || 'Employee included in current cycle successfully', {
							variant: 'success',
							autoHideDuration: 3000,
						});
						// Remove User from local state
						removeSelectedUser(modalProps.details);
					} else {
						const { messageList } = response.data;
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						enqueueSnackbar(messages || 'Error occurred while including employee in current cycle', {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				}
				setModalProps({ open: false, message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const handleChangePage = (event: any, newPage: number) => {
		newPage = newPage + 1;
		setPageIndex(newPage);
		const updatedEmpList = dataPerPage(excludedUseList.selectedUser, newPage, pageSize);
		setLocalArrList({
			selectedUser: [...updatedEmpList],
			raisedForId: [...updatedEmpList].map((item: any) => item.employeeId),
		});
	};
	const handleChangeRowsPerPage = async (event: any) => {
		const pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);
		const updatedEmpList = dataPerPage(excludedUseList?.selectedUser, 1, pSize);
		setLocalArrList({
			selectedUser: [...updatedEmpList],
			raisedForId: [...updatedEmpList].map((item: any) => item.employeeId),
		});
	};
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		let updatedEmpList = sortArrayByKey(excludedUseList?.selectedUser, column, newSortOrder);
		updatedEmpList = dataPerPage(updatedEmpList, 1, pageSize);
		setSortingColumn(column);
		setLocalArrList({
			selectedUser: [...updatedEmpList],
			raisedForId: [...updatedEmpList].map((item: any) => item.employeeId),
		});
	};
	const sortArrayByKey = (arrayList: any, keyName: string, sortingType: string) => {
		arrayList.sort(function (a: any, b: any) {
			const keyA = a[keyName];
			const keyB = b[keyName];
			if (sortingType === 'asc') {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			} else if (sortingType === 'desc') {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			}
			return 0;
		});

		return arrayList;
	};
	const dataPerPage = (arrayList: any, currPage: any, pageSizeValue: any) => {
		const startIndex = currPage - 1;
		const updatedList = arrayList.slice(startIndex * pageSizeValue, pageSizeValue * currPage);

		return updatedList;
	};

	// console.log('excludedUseList ===> ', excludedUseList);
	// console.log('localArrList ===> ', localArrList);

	return (
		<Box className='manage-user-drawer'>
			<Fragment>
				{drawerType === 'AddEdit' ? (
					<Box className='drawer-input-field drawer-user-search-field'>
						{/* <TextField
						className='user-search-field'
						id='user-src-field'
						placeholder={t('searchPeopleText')}
						fullWidth
						//onChange={}
						//value={}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/> */}
						<AssignContributor
							{...props}
							// goal={localArrList}
							goal={{ selectedUser: [], raisedForId: [] }}
							onSelectedUser={onSelectedUser}
							removeUser={removeUser}
							addKey={''}
							fieldLabel={''}
							fetchCycleUsers={true}
							customClass={'user-quick-search-type2'}
							customId={'give-pf-src'}
							multiple={false}
							defaultValue={null}
							defaultFocus={null}
							placeHolderText={null}
							searchEmployee={searchCustomEmployee}
							updatePage={updateCustomPage}
							globalSearchAPIWithTeam={globalSearchWithTeam}
							getUserById={getCustomUserById}
							isChipsHide={true}
							isOwnerSearch={true}
						/>
					</Box>
				) : (
					<></>
				)}
				<Box className='users-listing'>
					{(localArrList && localArrList?.selectedUser && localArrList?.selectedUser.length === 0) || !localArrList ? (
						<></>
					) : (
						<Box className='userCount'>
							{localArrList &&
							localArrList?.selectedUser &&
							localArrList?.selectedUser.filter((item: any) => item?.newRecord).length ? (
								<Typography variant='subtitle2'>{`(${
									localArrList && localArrList?.selectedUser
										? localArrList?.selectedUser.filter((item: any) => item?.newRecord).length
										: 0
								} ${t('membersLabel')})`}</Typography>
							) : (
								<Typography variant='subtitle2' style={{ visibility: 'hidden' }}>
									0 {t('membersLabel')}
								</Typography>
							)}
							<>
								{totalUser ? (
									<TablePagination
										component='div'
										className='user-list-pagination'
										count={totalUser}
										page={pageIndex > 1 ? pageIndex - 1 : 0}
										onPageChange={handleChangePage}
										rowsPerPage={pageSize}
										onRowsPerPageChange={handleChangeRowsPerPage}
										SelectProps={{
											MenuProps: {
												PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
											},
										}}
									/>
								) : (
									<></>
								)}
							</>
						</Box>
					)}
					<Fragment>
						{localArrList && localArrList?.selectedUser && localArrList?.selectedUser.length > 0 ? (
							<Box className='okr-list-panel-head'>
								<List component='ul' disablePadding>
									<ListItem className='user-col1'>
										<Typography
											onClick={(e) => sortUserList(e, 'firstName')}
											variant='body2'
											className={sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											id='sort-name'
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>
													{t('nameLabel')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='user-col2'>
										<Typography
											onClick={(e) => sortUserList(e, 'emailId')}
											variant='body2'
											className={sortingColumn === 'emailId' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											id='sort-email'
										>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>
													{t('emailLabel')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
								</List>
							</Box>
						) : (
							<></>
						)}
						<Box className='users-list-content'>
							<Fragment>
								{localArrList && localArrList?.selectedUser && localArrList?.selectedUser.length > 0 ? (
									localArrList?.selectedUser.map((record: any) => (
										<Box className='users-list-row' key={record.employeeId}>
											<List component='ul' disablePadding>
												<ListItem className='user-col1'>
													<Box className='user-info'>
														<Box className='user-img'>
															{record.imagePath ? (
																<Avatar className='avatar-default' src={record.imagePath} />
															) : (
																<Avatar className='avatar-default'>{getUserName(record)}</Avatar>
															)}
														</Box>
														<Box className='user-info-details'>
															<Typography variant='h4'>{getFullName(record)}</Typography>
															<Typography variant='subtitle2'>{record?.designation || ''}</Typography>
														</Box>
													</Box>
												</ListItem>
												<ListItem className='user-col2'>
													{record?.emailId && record?.emailId.length > 35 ? (
														<Tooltip arrow title={record?.emailId} aria-label='Email'>
															<Typography variant='h4'>{record?.emailId.substring(0, 35)}...</Typography>
														</Tooltip>
													) : (
														<Typography variant='h4'>{record?.emailId || ''}</Typography>
													)}
													{type !== 'View' ? (
														<OKRButton
															icon={<CrossIcon />}
															handleClick={(e: any) => removeUser(e, record)}
															className={'btn-cross'}
														/>
													) : (
														<></>
													)}
												</ListItem>
											</List>
										</Box>
									))
								) : (
									<></>
								)}
							</Fragment>
							{/* <Box className='load-more-box'>
								<OKRButton id='load-more' className='btn-link' text={`${t('loadMore')}...`}></OKRButton>
							</Box> */}
						</Box>
					</Fragment>
				</Box>
				{(localArrList && localArrList?.selectedUser && localArrList?.selectedUser.length === 0) || !localArrList ? (
					<NoRecord noRecordMessage={t('noExcludeMainHead')} subHead={t('noExcludeSubHead')} {...props} />
				) : (
					<></>
				)}
			</Fragment>
			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Box>
	);
};
