import React from 'react';
import { Box, Chip, List, ListItem, Typography, Tooltip } from '@material-ui/core';
import { OKRButton } from '../../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, EditIcon } from '../../../../config/svg/CommonSvg';
import { CloneIcon, SortingIcon } from '../../../../config/svg/CommonSvgIcon';
import '../../../../styles/pages/admin/settings/perfomanceReviewListing.scss';
import { Enums } from '../../../../config/enums';
import { getFullName, getMonthDateYear } from '../../../../config/utils';
import { NoRecord } from '../../CommonComponent/NoRecord';
import { Visibility } from '../../../../config/svg/ProfileSvg';

export const PerformanceCyclesList: React.FC<any> = (props: any) => {
	const {
		performanceReviewList,
		handlePerformanceReviewRecordAction,
		sortUserList,
		sortingColumn,
		sortOrder,
		maxLen,
		listLoading,
	} = props;
	const { t } = useTranslation();
	return (
		<>
			{listLoading ? (
				<Box className='loading-center-global'>
					<Typography>Loading...</Typography>
				</Box>
			) : (
				<>
					{performanceReviewList && performanceReviewList.records && performanceReviewList.records.length > 0 ? (
						<Box className='okr-list-panel-head'>
							<List component='ul'>
								<ListItem className='perform-col1'>
									<Typography
										onClick={(e) => sortUserList(e, 'name')}
										variant='body2'
										className={sortingColumn === 'name' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										id={'performance-review-name'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('performanceCycleName')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='perform-col2'>
									<Typography
										onClick={(e) => sortUserList(e, 'status')}
										variant='body2'
										className={sortingColumn === 'status' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										id={'status'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('statusLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='perform-col3'>
									<Typography
										onClick={(e) => sortUserList(e, 'startDate')}
										variant='body2'
										className={sortingColumn === 'startDate' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										id={'duration'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('setaDuration')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='perform-col4'>
									<Typography
										onClick={(e) => sortUserList(e, 'assessesCount')}
										variant='body2'
										className={sortingColumn === 'assessesCount' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										id={'assesses'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('performancePeriod')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								{/* <ListItem className='perform-col5'>
							<Typography variant='body2' className='sort-title' id={'published-on'}>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('publishedLevel')}</span>
									<BottomArrowIcon />
								</span>
							</Typography>
						</ListItem> */}
								<ListItem className='perform-col6'>
									<Typography variant='body2' className='sort-title' id={'created-on'}>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('createdLevel')}</span>
											{/* <BottomArrowIcon /> */}
										</span>
									</Typography>
								</ListItem>
								<ListItem className='perform-col7'>
									<Typography variant='body2' className='sort-title' id={'created-on'}>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('lastUpdatedLevel')}</span>
											{/* <BottomArrowIcon /> */}
										</span>
									</Typography>
								</ListItem>
							</List>
						</Box>
					) : (
						<></>
					)}

					{performanceReviewList && performanceReviewList.records && performanceReviewList.records.length > 0 ? (
						<Box className='performance-listing-area'>
							{performanceReviewList.records.map((record: any, index: number) => {
								const creatureName = getFullName(record?.launchedByDetails);
								const userFullName = getFullName(record?.updatedByDetails);
								const userFullNameCreate = getFullName(record?.createdByDetails);

								return (
									<Box className='performance-listing-content' key={`performance_cycle_${index + 1}`}>
										<List component='ul'>
											<ListItem className='perform-col1'>
												<Box className='perform-review-name'>
													{record?.name && record?.name.length > 35 ? (
														<Tooltip arrow title={record?.name || ''}>
															<Typography>{record?.name.substring(0, 35)}...</Typography>
														</Tooltip>
													) : (
														<Typography>{record?.name || ''}</Typography>
													)}
												</Box>
												<Box className='perform-review-actions'>
													<OKRButton
														handleClick={(event: any) => handlePerformanceReviewRecordAction(event, record, 'Clone')}
														icon={<CloneIcon />}
														title={t('clone')}
													/>
													{record?.status === Enums.THREE ? (
														<OKRButton
															handleClick={(event: any) => handlePerformanceReviewRecordAction(event, record, 'View')}
															icon={<Visibility />}
															title={'View'}
														/>
													) : (
														<OKRButton
															handleClick={(event: any) => handlePerformanceReviewRecordAction(event, record, 'Edit')}
															icon={<EditIcon />}
															title={t('editLabel')}
														/>
													)}
													<OKRButton
														handleClick={(event: any) => handlePerformanceReviewRecordAction(event, record, 'Delete')}
														icon={<DeleteIcon />}
														title={t('delete')}
													/>
												</Box>
											</ListItem>
											<ListItem className='perform-col2'>
												{record?.status === Enums.TWO ? (
													<Box className='performance-review-status'>
														{/* <Tooltip arrow title={'Active'} aria-label='Status'>
													<span className='status'></span>
												</Tooltip> */}
														<Chip label={'Ongoing'} />
													</Box>
												) : record?.status === Enums.THREE ? (
													<Box className='performance-review-status'>
														{/* <Tooltip arrow title={t('completedLevel')} aria-label='Status'>
													<span className='status published'></span>
												</Tooltip> */}
														<Chip label={t('completedLevel')} />
													</Box>
												) : record?.status === Enums.FOUR ? (
													<Box className='performance-review-status'>
														{/* <Tooltip arrow title={t('completedLevel')} aria-label='Status'>
													<span className='status published'></span>
												</Tooltip> */}
														<Chip label={'Submitted'} />
													</Box>
												) : (
													<Box className='performance-review-status'>
														{/* <Tooltip arrow title={'Draft'} aria-label='Status'>
													<span className='status no-status'></span>
												</Tooltip> */}
														<Chip className='draft-chip' label={t('draftLevel')} />
													</Box>
												)}
											</ListItem>
											<ListItem className='perform-col3'>
												<Typography>{`${getMonthDateYear(record?.startDate)} - ${getMonthDateYear(
													record?.endDate
												)}`}</Typography>
											</ListItem>
											<ListItem className='perform-col4'>
												<Typography>{`${getMonthDateYear(record?.performancePeriodStartDate)} - ${getMonthDateYear(
													record?.performancePeriodEndDate
												)}`}</Typography>
											</ListItem>
											{/* <ListItem className='perform-col5'>
										{record?.status === Enums.TWO || record?.status === Enums.THREE ? (
											<Box className='published'>
												<Typography variant='h4'>{getMonthDateYear(record?.launchedOn)}</Typography>
												<Typography variant='body2'>{getFullName(record?.launchedByDetails)}</Typography>
												{creatureName && creatureName.length > maxLen ? (
													<Tooltip arrow title={creatureName} aria-label='Email'>
														<Typography variant='body2'>{creatureName.substring(0, maxLen)}...</Typography>
													</Tooltip>
												) : (
													<Typography variant='body2'>{creatureName}</Typography>
												)}
											</Box>
										) : (
											<></>
										)}
									</ListItem> */}
											<ListItem className='perform-col6'>
												<Box className='created'>
													<Typography>{getMonthDateYear(record?.createdOn)}</Typography>
													{/* <Typography variant='body2'>{getFullName(record?.updatedByDetails)}</Typography> */}
													{userFullNameCreate && userFullNameCreate.length > maxLen ? (
														<Tooltip arrow title={userFullNameCreate} aria-label='Email'>
															<Typography variant='body2'>{userFullNameCreate.substring(0, maxLen)}...</Typography>
														</Tooltip>
													) : (
														<Typography variant='body2'>{userFullNameCreate}</Typography>
													)}
												</Box>
											</ListItem>
											<ListItem className='perform-col7'>
												<Box className='created'>
													<Typography>{getMonthDateYear(record?.updatedOn)}</Typography>
													{/* <Typography variant='body2'>{getFullName(record?.updatedByDetails)}</Typography> */}
													{userFullName && userFullName.length > maxLen ? (
														<Tooltip arrow title={userFullName} aria-label='Email'>
															<Typography variant='body2'>{userFullName.substring(0, maxLen)}...</Typography>
														</Tooltip>
													) : (
														<Typography variant='body2'>{userFullName}</Typography>
													)}
												</Box>
											</ListItem>
										</List>
									</Box>
								);
							})}
						</Box>
					) : performanceReviewList ? (
						<NoRecord noRecordMessage={t('noPerformanceFound')} subHead={' '} {...props} />
					) : (
						<></>
					)}
				</>
			)}
		</>
	);
};
