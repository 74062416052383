export const oidcConfigData = () => {
	if (window.location.origin.indexOf('localhost') >= 0) {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_LOCALHOST,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_LOCALHOST,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
		};
	} else if (window.location.host.split('.')[0] === 'dev') {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_DEV,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_DEV,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
		};
	} else if (window.location.host.split('.')[0] === 'qa') {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_QA,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_QA,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
		};
	} else if (window.location.host.split('.')[0] === 'uat') {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_UAT,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_UAT,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
		};
	} else if (window.location.host.split('.')[0] === 'googletest') {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_GOOGLETEST,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_GOOGLETEST, // https://accounts.google.com/o/oauth2/auth,
			client_secret: process.env.REACT_APP_SECRET_KEY_GOOGLETEST,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
		};
	} else if (window.location.host.split('.')[0] === 'infoprolearning') {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_INFOPROLEARNING,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_INFOPROLEARNING,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
		};
	} else if (window.location.host.split('.')[0] === 'innovant') {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_INNOVANT,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_INNOVANT, // https://accounts.google.com,
			client_secret: process.env.REACT_APP_SECRET_KEY_INNOVANT,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
		};
	} else if (window.location.host.split('.')[0] === 'compunnel') {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_COMPUNNEL,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_COMPUNNEL,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
			client_assertion: process.env.REACT_APP_SECRET_KEY_COMPUNNEL,
		};
	} else {
		return {
			client_id: process.env.REACT_APP_CLIENT_ID_INFOPROLEARNING,
			redirect_uri: `${window.location.origin}/secretlogin`,
			silent_redirect_uri: `${window.location.origin}/secretlogin`,
			scope: process.env.REACT_APP_SSO_SCOPE,
			authority: process.env.REACT_APP_AUTHORITY_INFOPROLEARNING,
			post_logout_redirect_uri: `${window.location.origin}/logout`,
		};
	}
};
