import React, { Fragment, useEffect, useState } from 'react';
import { Box, ClickAwayListener, Grid, Popper, Switch, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormSelectPopper } from './FormSelectPopper';
import { NoRecord } from '../../../CommonComponent/NoRecord';
import { PointIcon, ReportsTooltipIcon, WeightageIcon } from '../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../Common/OKRButton';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { MainDrawer } from '../../../CommonComponent/MainDrawer';
import { AssessmentFormsSettingDrawer } from './AssessmentFormsSettingDrawer';
import { set } from 'lodash';
import AlertDialog from '../../../../Common/Dialog';
import { abbreviateNumber } from '../../../../../config/utils';

export const AssessmentFormsSettingMainDrawer: React.FC<any> = (props: any) => {
	const {
		openAssessmentSettingForm,
		setOpenAssessmentSettingForm,
		assessmentSettingTabSelected,
		setAssessmentSettingTabSelected,
		loader,
		assessmentFormSetting,
		setAssessmentFormSetting,
		setIsPerformanceFormEdited,
	} = props;
	const { t } = useTranslation();
	const [formObjectData, setFormObjectData] = useState<any>({
		ratingFormId: 0,
		ratingFormName: '',
		ratingFormSectionResponse: [],
	});
	const [nonRatingQuestion, setNonRatingQuestion] = useState<any[]>([]);
	const [toggleClick, setToggleClick] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<boolean>(false);

	const [modalCustomScaleProps, setModalCustomScaleProps] = useState<any>({
		open: false,
		message: '',
		details: '',
		alertType: '',
	});
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

	useEffect(() => {
		if (
			assessmentFormSetting &&
			assessmentFormSetting[openAssessmentSettingForm.data.performanceRoleId] &&
			assessmentFormSetting[openAssessmentSettingForm.data.performanceRoleId][openAssessmentSettingForm.type]
		) {
			let copyObj = Object.assign(
				{},
				assessmentFormSetting[openAssessmentSettingForm.data.performanceRoleId][openAssessmentSettingForm.type]
			);
			let finalObject = { ...formObjectData, ...copyObj };
			let formObjectDataCopy: any[] = [];
			let nonRaitingQuestion: any[] = [];
			finalObject?.ratingFormSectionResponse.forEach((element: any) => {
				if (element['isRatingQuestion'] !== undefined && !element.isRatingQuestion) {
					nonRaitingQuestion.push(element);
				} else {
					formObjectDataCopy.push(element);
				}
			});
			setNonRatingQuestion(nonRaitingQuestion);
			finalObject.ratingFormSectionResponse = formObjectDataCopy;
			setFormObjectData(finalObject);
			setToggleClick(copyObj.isScoreCalculatedWithWeightage);
		}
	}, []);

	const handleDrawerAssessmentClose = (event: any, type: string) => {
		if (type === 'save') {
			let selectionType = openAssessmentSettingForm.type;
			let dataToSet: any = {};
			let assessmentFormSettingCopy = Object.assign({}, assessmentFormSetting);
			if (assessmentFormSettingCopy[openAssessmentSettingForm.data.performanceRoleId]) {
				dataToSet = Object.assign({}, assessmentFormSettingCopy[openAssessmentSettingForm.data.performanceRoleId]);
			}
			if (!formObjectData.ratingFormId) {
				delete dataToSet[selectionType];
				assessmentFormSettingCopy[openAssessmentSettingForm.data.performanceRoleId] = dataToSet;
				setAssessmentFormSetting(assessmentFormSettingCopy);
				setOpenAssessmentSettingForm({ open: false });
				return;
			}
			setIsPerformanceFormEdited(true);
			let weightage = 0;
			formObjectData.ratingFormSectionResponse.forEach((element: any) => {
				weightage = weightage + parseFloat(element.weightagePercentage);
			});
			weightage = parseInt(abbreviateNumber(weightage, 0));
			if (toggleClick && weightage !== 100) {
				setErrorMsg(true);
				return false;
			}
			let weightValue = abbreviateNumber(100 / (formObjectData.ratingFormSectionResponse.length || 1), 2);
			let roleId = openAssessmentSettingForm.data.performanceRoleId;
			let roleName = openAssessmentSettingForm.data.performanceRoleName;
			dataToSet[selectionType] = {
				performanceRoleLinkedFormId: 0,
				performanceRoleId: openAssessmentSettingForm.data.performanceRoleId,
				ratingFormId: formObjectData.ratingFormId,
				assessmentType: selectionType,
				ratingFormName: formObjectData.ratingFormName,
				totalSectionsCount: formObjectData.totalSectionsCount,
				totalQuestionsCount: formObjectData.totalQuestionsCount,
				isScoreCalculatedWithWeightage: toggleClick,
				ratingFormSectionResponse: formObjectData.ratingFormSectionResponse.map((item: any) => {
					return {
						ratingFormSectionId: item.ratingFormSectionId,
						weightagePercentage: !toggleClick ? weightValue : item.weightagePercentage,
						name: item.name,
					};
				}),
			};
			if (nonRatingQuestion) {
				nonRatingQuestion.forEach((nonRatingQues:any) => {
					dataToSet[selectionType].ratingFormSectionResponse.push({ ...nonRatingQues, weightagePercentage: 0 });
				});
			}
			assessmentFormSettingCopy[openAssessmentSettingForm.data.performanceRoleId] = dataToSet;
			setAssessmentFormSetting(assessmentFormSettingCopy);
			setOpenAssessmentSettingForm({ open: false });
		} else if (isFormEdited) {
			setModalCustomScaleProps({
				open: true,
				message: t('taskUnsavedChanges'),
				details: type,
				alertType: 'unSaveCustomScale',
			});
		} else {
			setOpenAssessmentSettingForm({ open: false });
		}
	};

	const handleCustomScaleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			setOpenAssessmentSettingForm({ open: false });
			setIsFormEdited(false);
			setModalCustomScaleProps({ open: false, message: '', details: '', alertType: '' });
		} else {
			setModalCustomScaleProps({ open: false, message: '', details: '', alertType: '' });
		}
	};
	return (
		<>
			<MainDrawer
				open={openAssessmentSettingForm?.open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('assessmentFormLevel')}
				//loader={ratingFormLoader}
				children={
					<Box className='drawer-inner-content'>
						<AssessmentFormsSettingDrawer
							{...props}
							assessmentSettingTabSelected={assessmentSettingTabSelected}
							setAssessmentSettingTabSelected={setAssessmentSettingTabSelected}
							openAssessmentSettingForm={openAssessmentSettingForm}
							formObjectData={formObjectData}
							setFormObjectData={setFormObjectData}
							toggleClick={toggleClick}
							setToggleClick={setToggleClick}
							errorMsg={errorMsg}
							setErrorMsg={setErrorMsg}
							setIsFormEdited={setIsFormEdited}
							nonRatingQuestion={nonRatingQuestion}
							setNonRatingQuestion={setNonRatingQuestion}
						/>
					</Box>
				}
				saveButtonText={t('Done')}
				rightCloseBtn={false}
				isSaveDraft={false}
				isSaveButtonDisabled={loader}
				isDraftButtonDisabled={loader}
				handleDrawerClose={(event: any) => handleDrawerAssessmentClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerAssessmentClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerAssessmentClose(event, 'cancel')}
			/>

			{modalCustomScaleProps && modalCustomScaleProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalCustomScaleProps?.message || ''}
					handleCloseModal={handleCustomScaleCloseModal}
					modalOpen={modalCustomScaleProps.open}
				/>
			)}
		</>
	);
};
