import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import { CreateTask } from '../../../GlobalTask/CreateTask';
import {
	checkIsInitialListCall,
	createUUID,
	formatOneOnOneRequestDate,
	getGlobalTaskListDueDate,
	getMonthDateYearTime,
	getPriorityName,
	getUserDetails,
} from '../../../../config/utils';
import { TaskList } from '../../../GlobalTask/TaskList';
import { Enums } from '../../../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
	completedGlobalTask,
	createGlobalTask,
	deleteGlobalTask,
	fetchGlobalTaskPending,
	getAllGlobalTask,
	getRecentAssignee,
	updateGlobalTask,
} from '../../../../action/globalTask';
import { getKrListing } from '../../../../action/myGoal';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import NotesImg from '../../../../images/no-found-notes.svg';
import AlertDialog from '../../../Common/Dialog';

export const PipTaskDetails = (props: any) => {
	const { t, pipFormType, loader, setLoader, pipFormDetails, allTaskList, setAllTaskList, showMsg, setIsFormEdited } =
		props;
	// console.log('PipTaskDetails ===> ', pipFormType, pipFormDetails?.performanceImprovementPlanId);
	const userDetail = getUserDetails();
	const dispatch = useDispatch();
	const { globalTaskListResponse, globalTaskListStatus } = useSelector((state: any) => state.globalTaskReducer);
	const { krList } = useSelector((state: any) => state.okrReducer);

	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 60);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 120);
	const defaultTaskListRequest = {
		isMyTask: true,
		isCompleted: false,
		pageSize: Enums.MAX_USER_SIZE,
		pageIndex: Enums.ONE,
		order: '',
		sortingText: '',
		finder: [],
		taskFilter: {
			dueDate: [],
			taskType: [],
			priority: [],
		},
		startDate: null,
		endDate: null,
		taskGroupById: Enums.ZERO,
		taskGroupByType: Enums.ZERO,
		moduleId: Enums.FIVE,
		employeeId: Enums.ZERO,
		moduleItemKeyId: Enums.ZERO,
	};
	const defaultFormDetails = {
		taskName: '',
		priority: Enums.ONE,
		taskDueDate: null,
		assignedTo: Enums.ZERO,
		userDetails: null,
		goalTypeId: Enums.ZERO,
		goalId: Enums.ZERO,
		linkedKRDetails: null,
		isCheckedInLinked: false,
	};
	const staticFilterObj = {
		isCompleted: false,
		taskGroupByType: Enums.ZERO,
		sortingText: '',
		order: '',
	};

	const [isCreateLinkPopupOpen, setIsCreateLinkPopupOpen] = useState<boolean>(false);

	const [isLaunchFormEdited, setIsLaunchFormEdited] = useState<any>(false);
	const [select1on1Tab, setSelect1on1Tab] = useState<any>(Enums.ONE);
	const [formDetailsTask, setFormDetailsTask] = useState(defaultFormDetails);
	const [filterDetails, setFilterDetails] = useState<any>(staticFilterObj);
	const [pageIndexVal, setPageIndex] = useState<number>(1);
	const [globalTaskRequest, setGlobalTaskRequest] = useState<any>(defaultTaskListRequest);
	const [defaultRecentUserList, setDefaultRecentUserList] = useState<any>([]);
	const [completedTaskCount, setCompletedTaskCount] = useState<number>(0);
	const [toDoTaskCount, setToDoTaskCount] = useState<number>(0);
	const [taskPagingInfo, setTaskPagingInfo] = useState<any>(null);
	const [taskGroupByList, setTaskGroupByList] = useState<any>(null);
	const [formDetails, setFormDetails] = useState<any>({
		taskName: '',
		taskDueDate: null, // new Date(),
	});
	const [dueDateError, setDueDateError] = useState<any>(null);
	const [selectedFilterDateRange, setSelectedFilterDateRange] = useState<any>(null);
	const [copiedTaskList, setCopiedTaskList] = useState<any>([]);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, details: null });
	const [openGroupByTask, setOpenGroupByTask] = useState<any>({ taskExpand: false, taskGroupById: 0 });
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	const [isDateRangeChanged, setIsDateRangeChanged] = useState<boolean>(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (pipFormType !== 'Add' && pipFormDetails?.performanceImprovementPlanId) {
			dispatch(fetchGlobalTaskPending());
			fetchGlobalTask(Enums.ZERO);
			getRecentSearchesList();
		}
		if (userDetail && userDetail?.employeeId) {
			let data = `empId=${userDetail.employeeId}`;
			dispatch(getKrListing(data));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (pipFormType !== 'Add') {
			if (globalTaskListStatus) {
				renderTaskListDetails(globalTaskListResponse, null);
				setLoader(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalTaskListStatus, globalTaskListResponse]);
	useEffect(() => {
		if (isLaunchFormEdited) {
			setIsFormEdited(isLaunchFormEdited);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLaunchFormEdited]);

	const fetchGlobalTask = async (pageIndex: number) => {
		setLoader(true);
		setPageIndex(pageIndex + 1);
		let dateData = {};
		if (selectedFilterDateRange) {
			const startDate = selectedFilterDateRange?.start?._d || new Date();
			const endDate = selectedFilterDateRange?.end?._d || new Date();
			dateData = {
				startDate: formatOneOnOneRequestDate(startDate),
				endDate: formatOneOnOneRequestDate(endDate),
			};
		}
		// console.log('PIP-TASK-DETAILS === pipFormDetails', pipFormDetails);
		const data = {
			...globalTaskRequest,
			moduleItemKeyId: pipFormDetails?.performanceImprovementPlanId || formDetails?.oneToOneDetailId || Enums.ZERO,
			employeeId: getEmployeeId(Enums.ONE),
			// employeeId: pipFormDetails?.nomineeDetails?.employeeId || pipFormDetails?.nomineeId || getEmployeeId(Enums.ONE),
			pageIndex: pageIndex + 1,
			isMyTask: getMyTaskDetails(),
			...dateData,
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	const getEmployeeId = (type: number) => {
		return type === Enums.ONE ? userDetail?.employeeId || Enums.ZERO : Enums.ZERO;
	};
	const resetTabDetails = (resetType: boolean) => {
		setFormDetailsTask(defaultFormDetails);
		// setIsLaunchFormEdited(false);
		if (pipFormType === 'View') {
			setIsLaunchFormEdited(false);
			setIsFormEdited(false);
		}
		if (resetType) {
			setFilterDetails(staticFilterObj);
			setPageIndex(1);
			const data = { ...defaultTaskListRequest, isMyTask: select1on1Tab === Enums.ONE ? true : false };
			setGlobalTaskRequest(data);
		}
	};

	const renderTaskListDetails = (taskListResp: any, newTask: any) => {
		if (taskListResp?.status === Enums.STATUS_SUCCESS) {
			const { entity, pagingInfo } = taskListResp || {};
			// const { totalPages, pageIndex = 0 } = pagingInfo || {};
			const { completedCount, toDoCount, taskListings, taskGroupByResponses } = entity || {};
			setCompletedTaskCount(completedCount || Enums.ZERO);
			setToDoTaskCount(toDoCount || Enums.ZERO);
			setTaskPagingInfo(pagingInfo);

			if (taskGroupByResponses && taskGroupByResponses.length) {
				setTaskGroupByList(taskGroupByResponses);
			} else {
				setTaskGroupByList([]);
			}
			const updatedTaskListingsData =
				taskListings && taskListings.length
					? taskListings?.filter((rec: any) => rec.taskId !== newTask?.taskId)
					: taskListings;
			const newTaskList = newTask ? [newTask, ...updatedTaskListingsData] : updatedTaskListingsData || [];

			const updatedTaskListings =
				newTaskList && newTaskList.length
					? newTaskList.map((rec: any) => ({
							...rec,
							momentDueDate: rec?.dueDate ? moment(new Date(rec?.dueDate)) : null,
							historyDate: rec?.dueDate,
							history: rec.taskName,
					  }))
					: [];
			updateTaskList(updatedTaskListings, select1on1Tab);
		}
	};
	const updateTaskList = (updatedTaskListings: any[], tabType: number) => {
		if (tabType === Enums.ONE) {
			setAllTaskList([...updatedTaskListings]);
		}
		// if (tabType === Enums.TWO) {
		// 	setOtherAllTaskList([...updatedTaskListings]);
		// }
	};

	const getRecentSearchesList = () => {
		try {
			// getRecentAssigneeList(`goalKeyId=${Enums.ZERO}`);
		} catch (error) {
			console.error(error);
		}
	};
	const getRecentAssigneeList = async (requestParam: any) => {
		try {
			const response: any = await dispatch(getRecentAssignee(requestParam));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				setDefaultRecentUserList(entityList && entityList.length ? entityList : []);
			} else {
				setDefaultRecentUserList([]);
			}
		} catch (error) {
			console.error(error);
			setDefaultRecentUserList([]);
		}
	};

	const directCallSaveTaskAPI = async (taskDetails: any, isEdit = false) => {
		try {
			setLoader(true);
			let formTaskLink: any[] = [];
			if (taskDetails?.link?.length && Object.keys(taskDetails?.link[0]).includes('link')) {
				taskDetails?.link?.forEach((linkArray: any) => {
					formTaskLink.push(linkArray.link || '');
				});
			} else {
				formTaskLink = taskDetails?.link;
			}
			// console.log('formTaskLink ===>', formTaskLink, taskDetails?.link);
			const requestPayload = {
				taskId: taskDetails?.isTaskAddedNew ? Enums.ZERO : taskDetails?.taskId || Enums.ZERO,
				goalTypeId: taskDetails?.goalTypeId || Enums.ZERO,
				goalId: taskDetails?.goalId || Enums.ZERO,
				taskName: taskDetails?.taskName,
				isCompleted: taskDetails?.isCompleted,
				taskType: taskDetails?.taskTypeId || Enums.ZERO,
				assignedTo: taskDetails?.assignedTo?.employeeId || Enums.ZERO,
				dueDate: taskDetails?.dueDate ? formatOneOnOneRequestDate(new Date(taskDetails?.dueDate)) : null,
				priority: taskDetails?.priorityId,
				link: formTaskLink,
				isCheckedInLinked: taskDetails?.isCheckedInLinked,
				status: pipFormDetails?.performanceImprovementPlanStatusId || Enums.ZERO, // PIP status
				sourceMappingId: pipFormDetails?.performanceImprovementPlanId || Enums.ZERO, // PIP Id
			};
			let response: any = null;
			if (isEdit) {
				response = await dispatch(updateGlobalTask(requestPayload));
			} else {
				response = await dispatch(createGlobalTask(requestPayload));
			}
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				fetchGlobalTask(Enums.ZERO);
			} else {
				const responseAPI = response?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				showMsg('error', messages);
				setLoader(false);
			}
			setIsFormEdited(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const handleSaveCallBack = (createRequest: any, type = '') => {
		let requestPayload = { ...createRequest };
		requestPayload = { ...requestPayload };
		const newTask = {
			oneToOneDetailId: requestPayload?.oneToOneDetailId || formDetails?.oneToOneDetailId || Enums.ZERO,
			taskName: requestPayload?.taskName ? requestPayload?.taskName.trim() : '',
			history: requestPayload?.taskName ? requestPayload?.taskName.trim() : '',
			goalId: requestPayload?.goalId || Enums.ZERO,
			goalTypeId: requestPayload?.goalId ? Enums.TWO : Enums.ZERO,
			taskType: 'PIP',
			taskTypeId: Enums.FIVE,
			isCompleted: false,
			taskId: createUUID(),
			isTaskAddedNew: true,
			isEdited: false,
			disabled: false,
			dueDate: requestPayload?.dueDate ? formatOneOnOneRequestDate(new Date(requestPayload?.dueDate)) : null,
			momentDueDate: requestPayload?.dueDate ? moment(new Date(requestPayload?.dueDate)) : null,
			historyDate: requestPayload?.dueDate ? formatOneOnOneRequestDate(new Date(requestPayload?.dueDate)) : null,
			dueDateText: requestPayload?.dueDate ? getMonthDateYearTime(new Date(requestPayload?.dueDate)) : '',
			isOverDue: false,
			overDueText: '',
			createdOn: new Date(),
			isEditEnable: true,
			completeDate: null,
			priorityId: requestPayload?.priorityId,
			priority: getPriorityName(t, requestPayload?.priorityId),
			isImported: false,
			// sourceMappingId: 202,
			assignedTo: {
				employeeId: requestPayload?.delegateTo?.employeeId || userDetail?.employeeId || Enums.ZERO,
				firstName: requestPayload?.delegateTo?.firstName || userDetail?.firstName || '',
				lastName: requestPayload?.delegateTo?.lastName || userDetail?.lastName || '',
				fullName:
					requestPayload?.delegateTo?.employeeId === userDetail?.employeeId
						? `${userDetail?.firstName || ''} ${userDetail?.lastName || ''}`
						: requestPayload?.delegateTo?.fullName || '',
				imagePath:
					requestPayload?.delegateTo?.employeeId === userDetail?.employeeId
						? userDetail?.imagePath || null
						: requestPayload?.delegateTo?.imagePath || null,
				emailId: requestPayload?.delegateTo?.emailId || userDetail?.emailId || '',
				isActive: true,
			},
			assignedBy: {
				employeeId: userDetail?.employeeId || Enums.ZERO,
				firstName: userDetail?.firstName || '',
				lastName: userDetail?.lastName || '',
				fullName: `${userDetail?.firstName || ''} ${userDetail?.lastName || ''}`,
				imagePath: userDetail?.imagePath || null,
				emailId: userDetail?.emailId || '',
				isActive: true,
			},
			goalLinkDetails: requestPayload?.linkedKRDetails,
			checkInLinkDetails: null,
			requestLinkDetails: {
				senderName: formDetails?.isReciver ? formDetails?.firstName || '' : 'You', // userDetail?.firstName || ''
				reciverName: formDetails?.isReciver ? 'You' : formDetails?.firstName || '',
				oneToOneTitel: formDetails?.title || '',
				isLinkGreyedOut: false,
				toolTipText: '',
			},
			isError: requestPayload?.isError || false,
			copiedTaskId: requestPayload?.copiedTaskId || null,
			link: requestPayload.link,
			isRecentlyAdded: requestPayload?.isRecentlyAdded || false,
			isCheckedInLinked: requestPayload?.isCheckedInLinked || false,
		};
		if (pipFormType === 'View') {
			directCallSaveTaskAPI(newTask, false);
		} else {
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			const totalRecords = taskPagingInfo?.totalRecords ? taskPagingInfo.totalRecords + 1 : 1;
			setAllTaskList([newTask, ...tasksCopy]);
			setTaskPagingInfo({ ...taskPagingInfo, totalRecords });
			setIsFormEdited(true);
		}
	};
	const resetHighlightOneOnOneTask = () => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		tasksCopy?.forEach((taskVal: any) => {
			taskVal.isRecentlyAdded = false;
		});

		setAllTaskList([...tasksCopy]);
	};

	// ======================================================================
	// ======================================================================
	// ======================================================================
	// ======================================================================

	const getAllTask = (data: any) => {
		let requestPayload = { ...data };
		setGlobalTaskRequest(requestPayload);
		dispatch(getAllGlobalTask(requestPayload, false));
	};
	const getMyTaskDetails = () => {
		if (select1on1Tab === Enums.ONE) {
			return true;
		} else if (select1on1Tab === Enums.TWO) {
			return false;
		}
	};
	// ======================================================================
	const handleFilterCompletedOnly = (e: any) => {
		const { checked } = e?.target;
		setLoader(true);
		setPageIndex(1);
		// setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			isCompleted: checked,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		// dispatch(getAllGlobalTask(data, false));
		setFilterDetails({
			...filterDetails,
			isCompleted: checked,
			// taskGroupByType: Enums.ZERO,
			// sortingText: '',
			// order: '',
		});
	};
	const handleGroupByFilterClick = (taskGroupByType: number) => {
		setLoader(true);
		setPageIndex(1);
		// setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			taskGroupByType,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		setFilterDetails({ ...filterDetails, taskGroupByType });
	};
	const handleSortingFilterClick = (sortingText: string, order: string) => {
		setLoader(true);
		setPageIndex(1);
		// setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			sortingText,
			order,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		setFilterDetails({ ...filterDetails, sortingText, order });
	};
	const handleTaskNameClick = (e: any, task: any, index: number) => {
		// const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		let tasksCopy: any[] = [];
		if (select1on1Tab === Enums.ONE) {
			tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		}
		// else if (select1on1Tab === Enums.TWO) {
		// 	tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
		// }
		if (!task.isCompleted) {
			tasksCopy.forEach((taskVal: any) => {
				if (taskVal.taskId === task.taskId) {
					taskVal.openEditMode = true;
					taskVal.isEditMode = true;
				} else {
					taskVal.openEditMode = false;
					taskVal.isEditMode = false;
				}
			});

			updateTaskList(tasksCopy, select1on1Tab);
		}
	};
	const handleTaskChange = (e: any, index: any, allTasks: any) => {
		// const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		let tasksCopy: any[] = [];
		if (select1on1Tab === Enums.ONE) {
			tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		}
		// else if (select1on1Tab === Enums.TWO) {
		// 	tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
		// }

		const { value } = e.target;
		tasksCopy[index].taskName = value;
		tasksCopy[index].isEditMode = true;
		tasksCopy.forEach((task: any) => {
			if (!task.isEditMode) {
				task.disabled = true;
			}
		});

		updateTaskList(tasksCopy, select1on1Tab);
	};

	const handleTaskOnEnterEdit = (e: any, index: number, task: any) => {
		if (e.keyCode === 13 && task && task.taskName.trim() !== '') {
			handleTaskUpdate(task, false, index);
		} else if (e.keyCode === 27) {
			handleEditTaskClose(e, index, task);
		}
	};
	const handleEditTaskClose = (event: any, index: any, task: any) => {
		// const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		let tasksCopy: any[] = [];
		if (select1on1Tab === Enums.ONE) {
			tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		}
		// else if (select1on1Tab === Enums.TWO) {
		// 	tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
		// }
		tasksCopy[index].taskName = task?.history || '';
		tasksCopy[index].isEditMode = false;
		tasksCopy[index].openEditMode = false;
		tasksCopy.forEach((task: any) => {
			task.disabled = false;
		});

		updateTaskList(tasksCopy, select1on1Tab);
	};
	// const callUpdateOthersTaskDetailsAPI = async (task: any, key1 = '', key2 = '', key3 = '', key4 = '', key5 = '') => {
	// 	if (task?.taskId) {
	// 		let tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
	// 		tasksCopy = tasksCopy.map((taskVal: any) => {
	// 			if (taskVal.taskId === task.taskId) {
	// 				// taskVal = data;
	// 				taskVal.isEditMode = false;
	// 				if (key1) {
	// 					if (key1 === 'assignedTo' && !task[key1]) {
	// 						taskVal[key1] = task?.assignedBy || null;
	// 					} else {
	// 						taskVal[key1] = task[key1];
	// 					}
	// 				}
	// 				if (key2) {
	// 					if (key2 === 'isAnimated') {
	// 						taskVal[key2] = task?.isCompleted;
	// 					} else {
	// 						taskVal[key2] = task[key2];
	// 					}
	// 				}
	// 				if (key3) {
	// 					taskVal[key3] = task[key3];
	// 				}
	// 				if (key4) {
	// 					taskVal[key4] = task[key4];
	// 				}
	// 				if (key5) {
	// 					taskVal[key5] = task[key5];
	// 				}
	// 			}
	// 			return taskVal;
	// 		});

	// 		updateTaskList(tasksCopy, select1on1Tab);
	// 	}
	// 	getRecentSearchesList();
	// };
	const callUpdateTaskDetailsAPI = async (task: any, key1 = '', key2 = '', key3 = '', key4 = '', key5 = '') => {
		if (pipFormType === 'View') {
			directCallSaveTaskAPI(task, true);
		} else {
			if (task?.taskId) {
				let tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
				tasksCopy = tasksCopy.map((taskVal: any) => {
					if (taskVal.taskId === task.taskId) {
						// taskVal = data;
						taskVal.isEditMode = false;
						if (key1) {
							if (key1 === 'assignedTo' && !task[key1]) {
								taskVal[key1] = task?.assignedBy || null;
							} else {
								taskVal[key1] = task[key1];
							}
						}
						if (key2) {
							if (key2 === 'isAnimated') {
								taskVal[key2] = task?.isCompleted;
							} else {
								taskVal[key2] = task[key2];
							}
						}
						if (key3) {
							taskVal[key3] = task[key3];
						}
						if (key4) {
							taskVal[key4] = task[key4];
						}
						if (key5) {
							taskVal[key5] = task[key5];
						}
					}
					return taskVal;
				});

				updateTaskList(tasksCopy, select1on1Tab);
				setIsFormEdited(true);
			}
		}
		getRecentSearchesList();
	};
	const handleTaskUpdate = async (taskDetail: any, isTaskDueDateChange: boolean, index: number) => {
		if (taskDetail?.taskName && taskDetail?.taskName.trim() !== '') {
			if (pipFormType === 'View') {
				directCallSaveTaskAPI(taskDetail, true);
			} else {
				let tasksCopy: any[] = [];
				if (select1on1Tab === Enums.ONE) {
					tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
				}
				// else if (select1on1Tab === Enums.TWO) {
				// 	tasksCopy = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
				// }
				tasksCopy[index].isEditMode = false;
				tasksCopy[index].openEditMode = false;
				tasksCopy.forEach((task: any) => {
					task.disabled = false;
				});

				updateTaskList(tasksCopy, select1on1Tab);
				setIsFormEdited(true);
			}
		} else {
			console.log('handleTaskUpdate ===> ', taskDetail);
		}
	};
	const handleTaskLinkChange = (links: any[], task: any) => {
		try {
			let formTaskLink: any[] = [];
			links.forEach((linkArray: any) => {
				formTaskLink.push(linkArray.link);
			});
			const requestPayload = {
				...task,
				link: formTaskLink,
			};
			let index = allTaskList.findIndex((item: any) => item.taskId === task.taskId);
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			tasksCopy[index].link = links;
			setAllTaskList([...tasksCopy]);
			callUpdateTaskDetailsAPI(requestPayload);
		} catch (error) {
			console.error(error);
		}
	};
	const resetCalendarDate = (isDirect: boolean) => {
		if (isDirect) {
			setSelectedFilterDateRange(null);
			setLoader(true);
			setPageIndex(1);
			const data = {
				...globalTaskRequest,
				pageIndex: 1,
				isMyTask: getMyTaskDetails(),
				startDate: null,
				endDate: null,
			};
			setGlobalTaskRequest(data);
			getAllTask(data);
		} else {
			setSelectedFilterDateRange(null);
		}
	};
	/**
	 * Task Name Change action end here
	 */
	const handleCheckInSelectionOnEdit = (e: any, isSelected: any, task: any) => {
		try {
			if (task?.dueDate) {
				const requestPayload = {
					...task,
					isCheckedInLinked: isSelected,
				};
				callUpdateTaskDetailsAPI(requestPayload, 'isCheckedInLinked');
				setDueDateError(null);
			} else {
				setDueDateError({ isError: true, itemIndex: task?.taskId });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleRemoveCheckInSelectionOnEdit = (e: any, isSelected: any, task: any) => {
		try {
			const requestPayload = {
				...task,
				isCheckedInLinked: isSelected,
			};
			callUpdateTaskDetailsAPI(requestPayload, 'isCheckedInLinked');
			setDueDateError(null);
		} catch (error) {
			console.error(error);
		}
	};
	const handlePriorityChangeOnEdit = (e: any, taskType: number, task: any, type: number) => {
		try {
			const requestPayload = {
				...task,
				priority: getPriorityName(t, taskType || Enums.ONE),
				priorityId: taskType || Enums.ONE,
			};
			// updatedSelectedTaskItem(requestPayload, 'priority', 'priorityId');
			if (type === Enums.ONE) {
				callUpdateTaskDetailsAPI(requestPayload, 'priority', 'priorityId');
			} else {
				// callUpdateOthersTaskDetailsAPI(requestPayload, 'priority', 'priorityId');
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleAddTaskDueDateChangeOnEdit = (value: any, range: any, task: any, type: number) => {
		try {
			const requestPayload = {
				...task,
				dueDate: value,
				dueDateText: value ? getGlobalTaskListDueDate(new Date(value)) : '',
				overDueText: '',
				isOverDue: false,
			};
			// updatedSelectedTaskItem(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
			if (type === Enums.ONE) {
				callUpdateTaskDetailsAPI(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
			} else {
				// callUpdateOthersTaskDetailsAPI(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleResetButtonClickOnEdit = (task: any, type: number) => {
		const requestPayload = {
			...task,
			dueDate: '',
			dueDateText: '',
			overDueText: '',
			isOverDue: false,
			isCheckedInLinked: false,
		};
		// updatedSelectedTaskItem(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
		if (type === Enums.ONE) {
			callUpdateTaskDetailsAPI(
				requestPayload,
				'dueDate',
				'dueDateText',
				'overDueText',
				'isOverDue',
				'isCheckedInLinked'
			);
		} else {
			// callUpdateOthersTaskDetailsAPI(
			// 	requestPayload,
			// 	'dueDate',
			// 	'dueDateText',
			// 	'overDueText',
			// 	'isOverDue',
			// 	'isCheckedInLinked'
			// );
		}
	};
	const handleKRLinkOnEdit = (e: any, selectedKR: any, task: any, type: number) => {
		if (selectedKR && selectedKR?.goalKeyId) {
			const requestPayload = {
				...task,
				goalTypeId: selectedKR?.goalKeyId ? Enums.TWO : Enums.ZERO,
				goalId: selectedKR?.goalKeyId || Enums.ZERO,
				goalLinkDetails: selectedKR,
			};
			// updatedSelectedTaskItem(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
			if (type === Enums.ONE) {
				callUpdateTaskDetailsAPI(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
			} else {
				// callUpdateOthersTaskDetailsAPI(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
			}
		}
	};
	const handleRemoveKROnEdit = (e: any, task: any, type: number) => {
		const requestPayload = {
			...task,
			goalTypeId: Enums.ZERO,
			goalId: Enums.ZERO,
			goalLinkDetails: null,
		};
		// updatedSelectedTaskItem(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
		if (type === Enums.ONE) {
			callUpdateTaskDetailsAPI(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
		} else {
			// callUpdateOthersTaskDetailsAPI(requestPayload, 'goalTypeId', 'goalId', 'goalLinkDetails');
		}
	};
	const onSelectedUserOnEdit = (selectedUser: any, task: any, type: number) => {
		if (selectedUser && selectedUser?.employeeId) {
			const requestPayload = { ...task, assignedTo: selectedUser };
			// updatedSelectedTaskItem(requestPayload, 'assignedTo');
			if (type === Enums.ONE) {
				callUpdateTaskDetailsAPI(requestPayload, 'assignedTo');
			} else {
				// callUpdateOthersTaskDetailsAPI(requestPayload, 'assignedTo');
			}
		}
	};
	// const handleRemoveAssigneeOnEdit = (e: any, task: any) => {
	// 	const requestPayload = { ...task, assignedTo: null };
	const handleRemoveAssigneeOnEdit = (e: any, task: any, isAssigneeSelfRemoved = false, type: number) => {
		const requestPayload = { ...task, assignedTo: isAssigneeSelfRemoved ? task?.assignedBy || null : null };
		// updatedSelectedTaskItem(requestPayload, 'assignedTo');
		if (type === Enums.ONE) {
			callUpdateTaskDetailsAPI(requestPayload, 'assignedTo');
		} else {
			// callUpdateOthersTaskDetailsAPI(requestPayload, 'assignedTo');
		}
	};
	const handleTaskCompleteDataUpdate = (task: any, key1: any, key2: any) => {
		if (task?.taskId) {
			let tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			tasksCopy = tasksCopy.map((taskVal: any) => {
				if (taskVal.taskId === task.taskId) {
					// taskVal = data;
					taskVal.isEditMode = false;
					if (key1) {
						if (key1 === 'assignedTo' && !task[key1]) {
							taskVal[key1] = task?.assignedBy || null;
						} else {
							taskVal[key1] = task[key1];
						}
					}
					if (key2) {
						if (key2 === 'isAnimated') {
							taskVal[key2] = task?.isCompleted;
						} else {
							taskVal[key2] = task[key2];
						}
					}
				}
				return taskVal;
			});

			updateTaskList(tasksCopy, select1on1Tab);
		}
	};
	const handleCompleteTaskOnEdit = async (e: any, task: any, type: number) => {
		try {
			const { checked } = e.target;
			const updatedTaskDetails = { ...task, isCompleted: checked };
			// updatedSelectedTaskItem(updatedTaskDetails, 'isCompleted');

			if (task?.taskId && task?.isTaskAddedNew) {
				handleTaskCompleteDataUpdate(updatedTaskDetails, 'isCompleted', 'isAnimated');
			} else {
				setLoader(true);
				if (type === Enums.ONE) {
					handleTaskCompleteDataUpdate(updatedTaskDetails, 'isCompleted', 'isAnimated');
				} else {
					// callUpdateOthersTaskDetailsAPI(updatedTaskDetails, 'isCompleted', 'isAnimated');
				}
				const requestPayload = { taskId: task.taskId, isCompleted: checked };
				const taskCompleteResponse: any = await dispatch(completedGlobalTask(requestPayload));
				if (Boolean(taskCompleteResponse) && taskCompleteResponse.data.status === 200) {
					// const data = { ...globalTaskRequest };
					// getAllTask(data);
					fetchGlobalTask(Enums.ZERO);
					setLoader(false);
				} else {
					const responseAPI = taskCompleteResponse?.data?.messageList || {};
					const keys = Object.keys(responseAPI);
					const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
					// console.error('handleCompleteTaskOnEdit ===> Error ', messages);
					// showApiMsgs(`${messages} `, 'error');
					showMsg('error', messages);
					setLoader(false);
				}
			}
		} catch (error) {
			// console.error('handleCompleteTaskOnEdit ===> Error ', error);
			showMsg('error', t('someErrorOccurred'));
		}
	};
	const handleDeleteTaskOnEdit = (e: any, task: any) => {
		if (task?.taskId && task?.isTaskAddedNew) {
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			const myTasksData = tasksCopy?.filter((it: any) => it?.taskId !== task?.taskId);

			setAllTaskList([...myTasksData]);
			let updatedCopiedTaskList = copiedTaskList && copiedTaskList?.length ? [...copiedTaskList] : [];
			updatedCopiedTaskList = updatedCopiedTaskList?.filter((rec: any) => rec.taskId !== task?.copiedTaskId);
			setCopiedTaskList([...updatedCopiedTaskList]);
			setTaskPagingInfo({ ...taskPagingInfo, totalRecords: myTasksData.length });
		} else {
			// setOpenDeleteDialog({ open: true, details: { task, oneOnOneDetails } });
			setOpenDeleteDialog({ open: true, details: { task } });
		}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			const { task } = openDeleteDialog?.details || {};
			if (task?.taskId) {
				setOpenDeleteDialog({ open: false, details: null });
				handleConfirmDeleteTaskClick(e, task);
			}
		} else {
			setOpenDeleteDialog({ open: false, details: null });
		}
	};
	const handleConfirmDeleteTaskClick = async (e: any, taskDetails: any) => {
		try {
			setLoader(true);
			const requestParams = `taskId=${taskDetails?.taskId}`;
			const deleteTaskResponse: any = await dispatch(deleteGlobalTask(requestParams));
			if (Boolean(deleteTaskResponse) && deleteTaskResponse?.data?.status === 200) {
				const data = { ...globalTaskRequest };
				getAllTask(data);

				setLoader(false);
			} else {
				const responseAPI = deleteTaskResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				// showApiMsgs(`${messages} `, 'error');
				setLoader(false);
			}
		} catch (error) {
			// showApiMsgs(t('someErrorOccurred'), 'error');
			setLoader(false);
		}
	};
	const handleCollapseTask = (e: any, taskGroupById: any) => {
		setOpenGroupByTask({
			taskExpand: taskGroupById === openGroupByTask?.taskGroupById ? !openGroupByTask?.taskExpand : true,
			taskGroupById: taskGroupById,
		});
		if (taskGroupById !== openGroupByTask?.taskGroupById) {
			setAllTaskList(null);
			setLoader(true);
			setPageIndex(1);
			const data = { ...globalTaskRequest, pageIndex: 1, taskGroupById: taskGroupById, isMyTask: getMyTaskDetails() };
			setGlobalTaskRequest(data);
			getAllTask(data);
		}
	};
	const handleSearchText = (searchText = '') => {
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			pageIndex: 1,
			isMyTask: getMyTaskDetails(),
			finder: searchText && searchText?.trim() !== '' ? [searchText] : [],
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};

	return (
		<>
			<Box className={`user-content-row task-left-col`} id={'my_task_list'}>
				<CreateTask
					loader={loader}
					setLoader={setLoader}
					isLaunchFormEdited={isLaunchFormEdited}
					setIsLaunchFormEdited={setIsLaunchFormEdited}
					allTaskList={allTaskList}
					setAllTaskList={setAllTaskList}
					defaultFormDetails={defaultFormDetails}
					formDetails={formDetailsTask}
					setFormDetails={setFormDetailsTask}
					resetTabDetails={resetTabDetails}
					krList={krList}
					defaultRecentUserList={defaultRecentUserList}
					minDateValue={minDateValue}
					maxDateValue={maxDateValue}
					setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
					callingFrom={'OneOnOneTask'}
					globalTaskRequest={globalTaskRequest}
					defaultTaskListRequest={{ ...defaultTaskListRequest, isMyTask: true }}
					renderTaskListDetails={renderTaskListDetails}
					getRecentSearchesList={getRecentSearchesList}
					handleSaveCallBack={handleSaveCallBack}
					resetHighlightOneOnOneTask={resetHighlightOneOnOneTask}
					taskPagingInfo={taskPagingInfo}
					setTaskPagingInfo={setTaskPagingInfo}
					dueDateError={dueDateError}
					setDueDateError={setDueDateError}
				/>
				{allTaskList &&
				allTaskList.length === Enums.ZERO &&
				!completedTaskCount &&
				!toDoTaskCount &&
				checkIsInitialListCall(defaultTaskListRequest, globalTaskRequest) ? (
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography variant='h2'>{t('noTaskHead')}</Typography>
							<Typography>{t('noPipTaskMessage')}</Typography>
							<AnimatedIcon
								className='no-task-image'
								width='323px'
								type='image/svg+xml'
								alt={t('noTaskHead')}
								imagePath={NotesImg}
							/>
						</Box>
					</Box>
				) : (
					<TaskList
						loader={loader}
						setLoader={setLoader}
						tabSelected={select1on1Tab}
						globalTaskRequest={globalTaskRequest}
						setGlobalTaskRequest={setGlobalTaskRequest}
						setPageIndex={setPageIndex}
						setTabSelected={setSelect1on1Tab}
						filterDetails={filterDetails}
						taskPagingInfo={taskPagingInfo}
						allTaskList={allTaskList}
						completedTaskCount={completedTaskCount}
						toDoTaskCount={toDoTaskCount}
						handleFilterCompletedOnly={handleFilterCompletedOnly}
						handleGroupByFilterClick={handleGroupByFilterClick}
						handleSortingFilterClick={handleSortingFilterClick}
						showLoadMore={showLoadMore}
						pageIndexVal={pageIndexVal}
						fetchGlobalTask={fetchGlobalTask}
						handleTaskNameClick={handleTaskNameClick}
						handleTaskChange={handleTaskChange}
						handleTaskOnEnterEdit={handleTaskOnEnterEdit}
						handleEditTaskClose={handleEditTaskClose}
						handleTaskUpdate={handleTaskUpdate}
						setIsLaunchFormEdited={setIsLaunchFormEdited}
						handlePriorityChangeOnEdit={(e: any, taskType: number, task: any) =>
							handlePriorityChangeOnEdit(e, taskType, task, 1)
						}
						handleAddTaskDueDateChangeOnEdit={(value: any, range: any, task: any) =>
							handleAddTaskDueDateChangeOnEdit(value, range, task, 1)
						}
						handleResetButtonClickOnEdit={(task: any) => handleResetButtonClickOnEdit(task, 1)}
						onSelectedUserOnEdit={(selectedUser: any, task: any) => onSelectedUserOnEdit(selectedUser, task, 1)}
						handleRemoveAssigneeOnEdit={(e: any, task: any, isAssigneeSelfRemoved = false) =>
							handleRemoveAssigneeOnEdit(e, task, isAssigneeSelfRemoved, 1)
						}
						handleKRLinkOnEdit={(e: any, selectedKR: any, task: any) => handleKRLinkOnEdit(e, selectedKR, task, 1)}
						handleRemoveKROnEdit={(e: any, task: any) => handleRemoveKROnEdit(e, task, 1)}
						handleCompleteTaskOnEdit={(e: any, task: any) => handleCompleteTaskOnEdit(e, task, 1)}
						handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
						taskGroupByList={taskGroupByList}
						handleCollapseTask={handleCollapseTask}
						openGroupByTask={openGroupByTask}
						setOpenGroupByTask={setOpenGroupByTask}
						defaultRecentUserList={defaultRecentUserList}
						searchTextArray={searchTextArray}
						setSearchTextArray={setSearchTextArray}
						minDateValue={minDateValue}
						maxDateValue={maxDateValue}
						setIsDateRangeChanged={setIsDateRangeChanged}
						selectedFilterDateRange={selectedFilterDateRange}
						setSelectedFilterDateRange={setSelectedFilterDateRange}
						resetCalendarDate={resetCalendarDate}
						setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
						callingFrom={'OneOnOneTask'}
						handleSearchText={handleSearchText}
						searchedInEmpId={'Current'}
						handleTaskLinkChange={handleTaskLinkChange}
						dueDateError={dueDateError}
						setDueDateError={setDueDateError}
						handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
						handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
					/>
				)}
			</Box>

			{openDeleteDialog && openDeleteDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('taskDeleteMessage')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog?.open}
				/>
			)}
		</>
	);
};
