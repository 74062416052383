import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormLabel, ButtonGroup } from '@material-ui/core';
import { TeamSelection } from './TeamSelection';
import { AssignContributors } from './AssignContributors';
import { getUserDetails } from '../../../config/utils';
import { checkPermission } from '../../../config/rolePermission';
import { assignOkr } from '../../Admin/AdminConstant';
import { OkrTeamDropdown } from '../OkrTeamDropdown';
import { Enums } from '../../../config/enums';

export const AssignmentSelection: React.FC<any> = (props) => {
	const {
		keyData,
		kr,
		okr,
		okrMasterData,
		durationCycleDetail,
		updateData,
		krAttrDateError,
		getPermissionResult,
		t,
		ownerData,
	} = props;
	const [currentGoalId, setCurrentGoalId] = useState<number>(2);
	const [selectedTeams, setSelectedTeams] = React.useState<any>([]);
	const [selectedMetric, setSelectedMetric] = React.useState<any>({});
	const [resetTeamsListingData, setResetTeamsListingData] = React.useState<boolean>(false);
	const loggedInUserDetail = getUserDetails();
	const [treeNodesData, setTreeNodesData] = React.useState<any[] | null>([]);
	const [contributors, setContributors] = useState<any[]>([ownerData]);
	const [isGroupTargetChanged, setIsGroupTargetChanged] = useState<boolean>(false);

	useEffect(() => {
		const { startValue, targetValue } = keyData;
		if (
			contributors.length > 0 &&
			contributors[0].isKrOwner &&
			contributors[0].checkEdited &&
			(contributors[0].startValue != startValue || contributors[0].targetValue != targetValue)
		) {
			let ContributorData: any = Object.assign([], contributors);
			ContributorData[0].startValue = startValue;
			ContributorData[0].targetValue = targetValue;
			setContributors(ContributorData);
		}
	}, [keyData]);
	useEffect(() => {
		const { metricId, currencyId } = keyData;
		if (metricId) {
			const { metricMasters } = okrMasterData;
			const selectedMetricId = metricId ? metricId : keyData?.metricId === 0 ? 5 : keyData?.metricId;
			const selectedMetricData =
				metricMasters && metricMasters.length
					? metricMasters.find((item: any) => item.metricId === selectedMetricId)
					: null;
			let selectedCurrencyDetail: any = {};
			if (selectedMetricData && selectedMetricData?.name === 'Currency') {
				const { metricDataMaster } = selectedMetricData;
				const selectedCurrency =
					metricDataMaster && metricDataMaster.length
						? metricDataMaster.find((item: any) => item.dataId === currencyId)
						: null;
				selectedCurrencyDetail = selectedCurrency;
			}
			setSelectedMetric({ selectedMetric: selectedMetricData, selectedCurrencyDetail });
		}
		if (loggedInUserDetail.isTeamLeader === false) {
			setCurrentGoalId(2);
		} else {
			getUserTeams();
		}
	}, []);

	useEffect(() => {
		if (krAttrDateError && krAttrDateError.type === 'assignment_error') {
			setTimeout(() => {
				const selectedEl = document.getElementById('error-txt-validation-message-label');
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}
			}, 15);
		}
	}, [krAttrDateError]);

	useEffect(() => {
		let contributorData = contributors ? contributors : [];
		let teamId = 0;
		if (selectedTeams && selectedTeams.length > 0) {
			teamId = selectedTeams[0].id;
		}
		if (keyData.teamId !== teamId) {
			contributorData = contributorData.filter((contri: any) => contri.employeeId === loggedInUserDetail.employeeId);
			if (contributorData.length > 0 && contributorData[0].isKrOwner) {
				contributorData[0].teamId = teamId;
			}
			setContributors(contributorData);
			setResetTeamsListingData(true);
		} else {
			if (contributorData.length > 0 && contributorData[0].isKrOwner) {
				contributorData[0].teamId = teamId;
			}
			setContributors(contributorData);
			setResetTeamsListingData(false);
		}

		updateData({
			currentGoalId,
			contributors: contributorData,
			selectedTeams,
			isGroupTarget: isGroupTargetChanged,
		});
	}, [currentGoalId, contributors, selectedTeams, isGroupTargetChanged]);

	const getUserTeams = async () => {
		if (!treeNodesData || treeNodesData.length === 0) {
			const apiRes = await props.getUserTeamsData('?goalType=1&empId=' + loggedInUserDetail?.employeeId);
			setTreeNodesData(null);
			if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
				const filteredDataset = apiRes?.data?.entityList;
				prepareTeamData(filteredDataset || []);
			}
		}
	};
	const updateGroupTargetValue = (checked: boolean) => {
		setIsGroupTargetChanged(checked);
	};
	const prepareTeamNodeData = (orgData: any, secondaryText?: string) => {
		return {
			label: orgData.organisationName,
			value: orgData.organisationId,
			imagePath: orgData.imagePath,
			secondaryText: t('membersLabel'),
			count: orgData.membersCount,
			backGroundColorCode: orgData.backGroundColorCode,
			colorCode: orgData.colorCode,
			teamLogo: orgData.imagePath,
			id: orgData.organisationId,
			teamId: orgData.organisationId,
			teamName: orgData.organisationName,
			teamMembersCount: orgData.membersCount,
			children: [],
			isParent: true,
			parentTeamBackGroundColorCode: orgData.parentTeamBackGroundColorCode,
			parentTeamColorCode: orgData.parentTeamColorCode,
			organisationHead: orgData.organisationHead,
			isMyTeam: orgData.isMyTeam,
			leaderDesignation: orgData.leaderDesignation,
			leaderFirstName: orgData.leaderFirstName,
			leaderImagePath: orgData.leaderImagePath,
			leaderLastName: orgData.leaderLastName,
		};
	};
	const prepareTeamData = (data: any) => {
		let completeOrgData: any = [];
		for (let ii = 0; ii < data?.length; ii++) {
			let orgData = data[ii];
			let teamObj = prepareTeamNodeData(orgData, orgData.parentName);
			completeOrgData.push(teamObj);
		}
		let selVal: string[] = [];
		selectedTeams.forEach((selectedT: any) => {
			completeOrgData.forEach((orgData: any) => {
				if (orgData.id === selectedT.id) {
					orgData.isChecked = true;
					orgData.selected = true;
				}
			});
		});
		setTreeNodesData(completeOrgData);
	};
	const handleSelectionChange = (item: any) => {
		let newGoalTypeId = item.length > 0 ? 1 : 2;
		setCurrentGoalId(newGoalTypeId);
		setSelectedTeams(item);
	};
	const checkTargetValue = () => {
		return true;
	};
	return (
		<Box className='okr-assign-btn-grp'>
			{loggedInUserDetail.isTeamLeader === true && (
				<Box className='kr-field-row assigned-btn helperText-bottom'>
					<Box className='drawer-input-field'>
						<Box>
							<FormLabel component='legend' id='team-selection'>
								{t('okrTeamLabel')}
							</FormLabel>
							<OkrTeamDropdown
								selectedTeams={selectedTeams}
								treeNodesData={treeNodesData}
								handleSelectionChange={handleSelectionChange}
							/>
						</Box>
					</Box>
					{krAttrDateError.type === 'assignment_error' && krAttrDateError?.error && (
						<span className='error-txt-validation-message error-field' id='error-txt-validation-message-label'>
							{(krAttrDateError.type === 'assignment_error' && krAttrDateError?.error) || ''}
						</span>
					)}
				</Box>
			)}
			{checkPermission(getPermissionResult?.employeePermissions, assignOkr) &&
			(currentGoalId === 2 || (currentGoalId === 1 && selectedTeams.length > 0)) ? (
				<AssignContributors
					isBecomeContributor={false}
					updatePage={props.updatePage}
					getOwnerTeamsData={props.getOwnerTeamsData}
					globalSearchAPIWithTeam={props.globalSearchAPIWithTeam}
					checkAlignStatus={props.checkAlignStatus}
					keyData={keyData}
					kr={kr}
					okr={okr}
					okrMasterData={okrMasterData}
					durationCycleDetail={durationCycleDetail}
					currentUser={props.currentUser}
					keyAttribute={{ ...keyData, teamId: selectedTeams.length > 0 ? selectedTeams[0].id : 0 }}
					objectiveDetail={okr}
					year={props.year}
					parentGoalTypeId={okr.goalTypeId}
					goalTypeId={currentGoalId}
					isContributorOnly={false}
					actionType={'editKR'}
					selectedMetricDetail={selectedMetric}
					objectiveStartDate={okr?.startDate}
					objectiveEndDate={okr?.endDate}
					updateKeyAttrDetail={() => {}}
					updateMetricDetails={() => {}} // addtarget
					setIsContributorUpdated={() => {}}
					deleteContributor={props.deleteContributor}
					isNoUnitOrBoolean={selectedMetric && (selectedMetric.metricId === 4 || selectedMetric.metricId === 5)}
					isBoolean={selectedMetric && selectedMetric.metricId === 4}
					isStartValueUpdated={() => {}}
					isTargetValueUpdated={() => {}}
					showAttributeDrawer={true}
					contributorDetails={contributors}
					setContributorDetails={setContributors}
					setCallOut={() => {}}
					setAnchorElCallouts={() => {}}
					updateCalloutPanel={() => {}}
					getPermissionResult={getPermissionResult}
					resetTeamListData={resetTeamsListingData}
					userSelectedCycle={props.userSelectedCycle}
					underAssign={props.underAssign}
					checkTargetValue={checkTargetValue}
					updateGroupTargetValue={updateGroupTargetValue}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};
