import React, { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { OKRButton } from './OKRButton';
import { LinkDeleteIcon } from '../../config/svg/CommonSvg';
import { WhatsNewPopupIcon } from '../../config/svg/GlobalSvg';
import { useDispatch, useSelector } from 'react-redux';
import { getUserVersion, updateGetUserVersion, updateUserForceLogout } from '../../action/common';
import { useLocation } from 'react-router-dom';
import { clearLocalStorage, getLocalStorageItem } from '../../services/StorageService';
import { useAuth } from 'react-oidc-context';
import { LOGOUT_INITIATOR_URL } from '../../config/app-url';
import { getAllSoPermission } from '../../action/strategicObjective';

export const WhatsNewAlert: React.FC<any> = (props) => {
	const auth = useAuth();
	const location = useLocation();
	const { userVersion } = useSelector((state: any) => state?.commonReducer);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [showWhatsNew, setShowWhatsNew] = useState<boolean>(false);
	useEffect(() => {
		if (handleWhatIsNewPopupCondition()) {
			dispatch(getUserVersion());
			dispatch(getAllSoPermission());
		}
		// userVersion?.isVersionVisible &&
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	/** Force logout */
	useEffect(() => {
		if (userVersion?.isForceLogout) {
			//update force logout flag
			const tenantData: any = JSON.parse(getLocalStorageItem('TenantData') || '{}');
			const userData: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');
			if (!userData.isImpersonatedUser) {
				// logout only for logged in user not for impersonate user
				const requestParam = {
					tenantId: tenantData.tenantId,
					emailId: userData.emailId,
					isForceLogout: false,
				};
				dispatch(updateUserForceLogout(requestParam));

				//Clear local storage and logout
				setTimeout(function () {
					logoutUser();
				}, 3000);
			}
		}
	}, [userVersion]);

	useEffect(() => {
		if (userVersion?.isVersionVisible) {
			setShowWhatsNew(true);
		}
	}, [userVersion]);

	const logoutUser = () => {
		const isSSO = getLocalStorageItem('isSSO');
		const idpId = getLocalStorageItem('idpId');
		if (isSSO === 'false') {
			window.location.href = window.location.origin + LOGOUT_INITIATOR_URL;
		} else if (idpId === '3') {
			//Google Login
			clearLocalStorage();
			sessionStorage.clear();
			window.open('https://accounts.google.com/logout', '', 'width=100,height=100');
			window.location.href = window.location.origin + LOGOUT_INITIATOR_URL;
		} else {
			auth.removeUser();
			auth.signoutRedirect();
		}
		clearLocalStorage();
		sessionStorage.clear();
	};

	const handleWhatIsNewPopupCondition = () => {
		if (
			!window?.location?.href?.includes('/logout') &&
			!window?.location?.href?.includes('/500') &&
			!window?.location?.href?.includes('/unauthorized') &&
			!window?.location?.href?.includes('/under-maintenance') &&
			!window?.location?.href?.includes('/free-trial') &&
			!window?.location?.href?.includes('/secretlogin') &&
			!window?.location?.href?.includes('/custom-login') &&
			!window?.location?.href?.includes('/privacy-policy') &&
			!window?.location?.href?.includes('/reset-password')
		) {
			return true;
		}
		return false;
	};

	const handleUpdateVersionUpdate = async (showTooltip: boolean) => {
		if (!showTooltip) {
			window.open(process.env.REACT_APP_WHATS_NEW, '_blank');
		}
		setShowWhatsNew(false);
		const requestParam = `versionId=${userVersion.versionId}&isRead=${showTooltip}`;
		dispatch(updateGetUserVersion(requestParam, showTooltip));
	};

	return (
		<Modal
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			open={showWhatsNew}
			className='whats-new-popup'
		>
			<Box className={'whats-new-popup-wrap'}>
				<Box className='popup-inner'>
					<IconButton className='close' onClick={() => handleUpdateVersionUpdate(true)}>
						<LinkDeleteIcon />
					</IconButton>
					<Box className='popup-img'>
						<WhatsNewPopupIcon />
					</Box>
					<Box className='popup-des'>
						<Typography variant='body1'>{t('whatsNewText')}</Typography>
						<OKRButton
							className='button-white-bg'
							text={t('checkItOut')}
							handleClick={() => handleUpdateVersionUpdate(false)}
						/>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};
