import React, { useState } from 'react';
import { Box, ListItem, ListItemText, List, ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../../Common/OKRButton';
import { BottomArrowIcon } from '../../../../config/svg/CommonSvgIcon';

export const FeedbackTypeDropdown: React.FC<any> = (props: any) => {
	const { feedbackTypesOptions, setSelectedValue, selectedValue, disabled = false } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const handleSelection = (selectedValue: any) => {
		handleClickAway();
		setSelectedValue(selectedValue);
	};

	return (
		<Box className={`okr-team-dropdown feedback-type-dropdown  ${open ? 'active' : ''} `}>
			<OKRButton
				className={'dropdown-button'}
				disabled={disabled}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				text={selectedValue && selectedValue?.name ? selectedValue?.name : t('selectLabel')}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<Box className='dropdown-multi-list-sub-panel'>
								{feedbackTypesOptions.length > 0 ? (
									<List component='div' disablePadding>
										{feedbackTypesOptions?.map((item: any) => (
											<ListItem
												component='div'
												className={selectedValue?.name === item?.name ? 'active' : ''}
												onClick={() => {
													handleSelection(item);
												}}
											>
												<ListItemText>{item.name}</ListItemText>
											</ListItem>
										))}
									</List>
								) : (
									<></>
								)}
							</Box>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
