import { Box, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { minTwoDigits } from '../../config/utils';
export interface ProgressLabelProps {
	percentage?: any;
}

const ProgressLabel: React.SFC<ProgressLabelProps> = (props) => {
	const { percentage } = props;
	return (
		<Fragment>
			{percentage && !isNaN(percentage.teamScore) && (
				<Box display='flex' style={{ background: `#${percentage.progressCode}` }} className={'percentage-label'}>
					<Typography>{minTwoDigits(percentage.teamScore)}%</Typography>
				</Box>
			)}
		</Fragment>
	);
};

export default ProgressLabel;
