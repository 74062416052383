import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	reloadPIP: false,
	reloadFeeback: false,
	reloadDetails: false,
	statusFilterPending: false,
	statusFilterList: null,
	pipFiltersList: [],
	pipAlert: null,
	feedbackAlert: null,
	pipImprovementData: {},
	feedbackImprovementData: {},
	highlightedFeedbackId: 0,
};

export default function performanceImprovementPlanReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.RELOAD_PIP_LIST:
			return {
				...state,
				reloadPIP: action.payload,
			};
		case actionTypes.RELOAD_DETAILS_PAGE:
			return {
				...state,
				reloadDetails: action.payload,
			};
		case actionTypes.RELOAD_FEEDBACK_LIST:
			return {
				...state,
				reloadFeeback: action.payload,
			};
		case actionTypes.HIGHLIGHT_FEEDBACK_ID:
			return {
				...state,
				highlightedFeedbackId: action.payload,
			};
		case actionTypes.SET_PIP_FILTERS_LIST:
			return {
				...state,
				pipFiltersList: action.payload,
			};
		case actionTypes.GET_PIP_LISTING_FILTER_PENDING:
			return {
				...state,
				statusFilterPending: true,
				statusFilterList: null,
			};
		case actionTypes.GET_PIP_LISTING_FILTER_SUCCESS:
			return {
				...state,
				statusFilterPending: false,
				statusFilterList: action.payload.entityList.filter((x) => x?.isResolvedStatus && x?.isEnabled),
			};
		case actionTypes.GET_PIP_LISTING_FILTER_FAILED:
			return {
				...state,
				statusFilterPending: false,
				statusFilterList: null,
				//listOkrError: action.error,
			};
		case actionTypes.GET_PIP_ALERT_PENDING:
			return {
				...state,
				pipAlert: null,
			};
		case actionTypes.GET_PIP_ALERT_SUCCESS:
			return {
				...state,
				pipAlert: action.payload.entity,
			};
		case actionTypes.GET_PIP_ALERT_FAILED:
			return {
				...state,
				pipAlert: null,
			};

		case actionTypes.GET_FEEDBACK_ALERT_PENDING:
			return {
				...state,
				feedbackAlert: null,
			};
		case actionTypes.GET_FEEDBACK_ALERT_SUCCESS:
			return {
				...state,
				feedbackAlert: action.payload.entity,
			};
		case actionTypes.GET_FEEDBACK_ALERT_FAILED:
			return {
				...state,
				feedbackAlert: null,
			};
		case actionTypes.SET_PIP_IMPROVEMENT_ID:
			return {
				...state,
				pipImprovementData: action.payload,
			};
		case actionTypes.SET_FEEDBACK_IMPROVEMENT_ID:
			return {
				...state,
				feedbackImprovementData: action.payload,
			};
		default:
			return state;
	}
}
