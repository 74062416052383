import React, { Fragment, useEffect, useState } from 'react';
import { Box, Chip, Collapse, List, ListItem, Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../../../Common/OKRButton';
import {
	AlertInfoIcon,
	CollapseAllIcon,
	CopyOkrsIcon,
	DownloadIcon,
	ExpandAllIcon,
} from '../../../../../config/svg/CommonSvg';
import { AddIconBlue, DeleteIcon, EditIcon, PublishedOnIcon } from '../../../../../config/svg/CommonSvgIcon';
import '../../../../../styles/pages/admin/settings/default-okr/default-okr-listing.scss';
import { AssignChip } from './AssignChip';
import { RightArrowIcon } from '../../../../../config/svg/ArrowSvg';
import { CalendarIcon, CloneIcon } from '../../../../../config/svg/CommonSvgIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
	defaultOkrDelete,
	exportDefaultOkrReport,
	getAdminDefaultOkrList,
	openAdminCopyOkr,
	publishObjective,
	refreshAdminDefaultOkrList,
	RePublishObjective,
} from '../../../../../action/adminDefaultOkr';
import { getMonthDate, getUserDetails } from '../../../../../config/utils';
import { Enums } from '../../../../../config/enums';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';
import { AnimatedIcon } from '../../../../Common/AnimatedIcon';
import AddUserImg from '../../../../../images/copy-import-previous-goal.svg';
import DefaultOkrImg from '../../../../../images/my-goal-bg.svg';
import AlertDialog from '../../../../Common/Dialog';

export const DefaultOkrList: React.FC<any> = (props: any) => {
	const {
		handleCloneOkr,
		setListLoading,
		listLoading,
		handleEditDefaultOkrClick,
		showApiMsg,
		selectedCycleDetails,
		handleClickDefaultOkr,
		setHighlightDefaultOkr,
		highlightDefaultOkr,
		setShowLoader,
	} = props;
	const { t } = useTranslation();
	const userDetail: any = getUserDetails();
	const dispatch = useDispatch();
	const { refreshDefaultOkr } = useSelector((state: any) => state.adminDefaultOkrReducer);

	const [listingData, setListingData] = useState<any>([]);
	const [alertMessage, setAlertMessage] = useState<any>('');
	const [expandedKrList, setExpandedKrList] = useState<number[]>([]);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [modalOpen, setModalOpen] = useState(false);
	const [publishDisabled, setPublishDisabled] = useState(false);
	const [publishButton, setPublishButton] = useState<number>(0);
	const [republishButton, setReublishButton] = useState<number>(0);
	const [modalPerformanceProps, setModalPerformanceProps] = useState<any>({ open: false, message: '', details: '' });

	useEffect(() => {
		defaultOkrAPICall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCycleDetails]);
	useEffect(() => {
		if (refreshDefaultOkr) {
			dispatch(refreshAdminDefaultOkrList(false));
			defaultOkrAPICall();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshDefaultOkr]);

	const defaultOkrAPICall = (showLoading = true) => {
		if (selectedCycleDetails) {
			showLoading && setListLoading(true);
			getPerformanceReviewList({
				cycleId: selectedCycleDetails?.organisationCycleId,
				searchText: '',
				status: [],
				designationAssignId: [],
				jobLevelAssignId: [],
			});
		}
	};
	const handlePublishOkr = async () => {
		setPublishDisabled(true);
		const response: any = await dispatch(
			publishObjective({
				teamCycleDetailId: selectedCycleDetails?.organisationCycleId,
				objectiveId: 0,
				loginUserId: 0,
			})
		);
		if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
			dispatch(refreshAdminDefaultOkrList(true));
			setPublishDisabled(false);
		} else {
		}
	};
	const handleRePublishOkr = async () => {
		setPublishDisabled(true);
		const response: any = await dispatch(
			RePublishObjective({
				teamCycleDetailId: selectedCycleDetails?.organisationCycleId,
			})
		);
		if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
			dispatch(refreshAdminDefaultOkrList(true));
			setPublishDisabled(false);
		} else {
		}
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();

		if (type === 1) {
			handlePublishOkr();
			setModalOpen(false);
		} else {
			setModalOpen(false);
		}
	};
	const getPerformanceReviewList = async (requestPayload: any) => {
		const response: any = await dispatch(getAdminDefaultOkrList(requestPayload));
		if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
			setListingData(response.data.entity.defaultGoalObjectiveResponse);
			let hasRepublish = 0;
			let hasPublish = 0;
			response.data.entity.defaultGoalObjectiveResponse.forEach((okrAssignType: any) => {
				if (okrAssignType.status === 4) {
					hasRepublish = 4;
				} else if (okrAssignType.status === 3) {
					hasPublish = 3;
				}
			});
			setReublishButton(hasRepublish);
			setPublishButton(hasPublish);
			setAlertMessage(response.data.entity.unpublishedMessage);
			setTimeout(() => {
				setHighlightDefaultOkr(0);
			}, 5000);
			if (highlightDefaultOkr) {
				scrollTo(`default_okr_list_item_index_${highlightDefaultOkr}`);
			}
		} else {
			const { messageList } = response?.data || {};
			const keys = messageList ? Object.keys(messageList) : null;
			const messages = keys && keys?.length ? keys.map((item) => messageList[item]) : [];
			showApiMsg('error', messages || 'Error occurred while fetching default okr details');
		}
		setListLoading(false);
		setShowLoader(false);
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 100 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 800);
	};

	const handleExpandKeyResult = (e: any, okr: any) => {
		let list = Object.assign([], expandedKrList);
		const elementIndex = list.indexOf(okr.defaultGoalObjectiveId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(okr.defaultGoalObjectiveId);
		}
		setExpandedKrList(list);
	};
	const handleExpandAll = () => {
		let defaultListing: any[] = [];
		listingData.forEach((okrKey: any) => {
			defaultListing.push(okrKey.defaultGoalObjectiveId);
		});
		setExpandedKrList(defaultListing);
	};
	const handleCollapseAll = () => {
		setExpandedKrList([]);
	};
	const deleteDefaultOkr = async (okrDetails: any) => {
		try {
			if (okrDetails?.defaultGoalObjectiveId) {
				setShowLoader(true);
				const isGoalObjective = true;
				const requestParams = `id=${okrDetails?.defaultGoalObjectiveId}&isGoalObjective=${isGoalObjective}`;
				const response: any = await dispatch(defaultOkrDelete(requestParams));
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					defaultOkrAPICall(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : null;
					const messages = keys && keys?.length ? keys.map((item) => messageList[item]) : [];
					showApiMsg('error', messages || 'Error occurred while fetching default okr details');
					setShowLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleDeleteDefaultOkrClick = (event: any, okrDetails: any) => {
		try {
			// console.log('handleDeleteDefaultOkrClick ===> ', okrDetails);
			if (okrDetails?.status === Enums.ONE) {
				let msg = '';
				if (okrDetails?.defaultGoalKeyResponse?.length === Enums.ZERO) {
					msg = t('deleteOkrAlertMessage');
				} else if (okrDetails?.defaultGoalKeyResponse?.length) {
					msg = t('deleteOkrAlertMessageNew');
				}
				setModalPerformanceProps({
					open: true,
					message: msg,
					details: { okrDetails: okrDetails, deleteType: 'DefaultOKR' },
					event: event,
				});
			} else {
				setModalProps({
					open: true,
					message: '',
					details: {
						okrDetails: okrDetails,
						deleteType: 'DefaultOKR',
						headingText: t('deleteOkrAlertMessageNew'),
						subHeading: '',
						matchString: Enums.DELETE_TEXT,
					},
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handlePublishButton = () => {
		if (republishButton === 4) {
			handleRePublishOkr();
		} else {
			setModalOpen(true);
		}
	};

	const handlePerformanceCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			const { details } = modalPerformanceProps || {};
			if (details && details?.deleteType === 'DefaultOKR') {
				const okrDetails = details?.okrDetails || null;
				if (okrDetails) {
					deleteDefaultOkr(okrDetails);
				}
			}

			setModalPerformanceProps({ open: false, message: '', details: '' });
		} else {
			setModalPerformanceProps({ open: false, message: '', details: '' });
		}
	};
	const handleConfirmationCloseModal = async (e: any, type: any) => {
		try {
			if (type === 1) {
				const { details } = modalProps || {};
				if (details && details?.deleteType === 'DefaultOKR') {
					const okrDetails = details?.okrDetails || null;
					if (okrDetails) {
						deleteDefaultOkr(okrDetails);
					}
				}
				setModalProps({ open: false, message: '', details: '' });
			} else {
				setModalProps({ open: false, message: '', details: '' });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleExportMyOkrReport = async () => {
		try {
			const cycleId =
				selectedCycleDetails && selectedCycleDetails?.organisationCycleId
					? selectedCycleDetails?.organisationCycleId
					: 0;
			if (cycleId && userDetail?.employeeId) {
				// setListLoading(true);
				const isDesignation = false;
				const isJobLevel = false;
				const isUser = false;
				const employeeId = userDetail?.employeeId || 0;

				const requestParam = `?employeeId=${employeeId}&cycleId=${cycleId}&isDesignation=${isDesignation}&isJobLevel=${isJobLevel}&isUser=${isUser}`;
				const response: any = await dispatch(exportDefaultOkrReport(requestParam));
				if (response) {
					const defaultName = 'defaultOkrReport.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(response.headers['content-disposition']);
					link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
				// setListLoading(false);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			{!listLoading && listingData.length > 0 && (
				<Box className='default-okr-list-head'>
					<Box className='default-okr-list-head-left'>
						<Box className='expand-collapse-all'>
							{expandedKrList.length === listingData.length ? (
								<OKRButton
									className='expand-collapse-btn'
									title={
										<Typography variant='body2' className='expand-txt'>
											{t('collapseAllLabel')}
										</Typography>
									}
									text={<CollapseAllIcon />}
									handleClick={(e) => handleCollapseAll()}
								/>
							) : (
								<OKRButton
									className='expand-collapse-btn'
									title={
										<Typography variant='body2' className='expand-txt'>
											{t('expendAllLabel')}
										</Typography>
									}
									text={<ExpandAllIcon />}
									handleClick={(e) => handleExpandAll()}
								/>
							)}
						</Box>
						<Typography variant='h6'>Objective({listingData.length})</Typography>
						{alertMessage && (
							<Box className='default-okrs-info-mssg'>
								<AlertInfoIcon />
								<Typography variant='body2'>{alertMessage}</Typography>
							</Box>
						)}
					</Box>
					<Box className='default-okr-list-head-right'>
						<OKRButton
							className='btn-circle-white download-btn'
							icon={<DownloadIcon />}
							title={t('downloadDefaultOKRs')}
							handleClick={handleExportMyOkrReport}
						></OKRButton>
						<OKRButton
							className='btn-primary'
							title={republishButton === 4 ? 'Republish' : t('publishLabel')}
							text={republishButton === 4 ? 'Republish' : t('publishLabel')}
							handleClick={(e) => handlePublishButton()}
							disabled={publishDisabled || (publishButton === 0 && republishButton === 0)}
						/>
					</Box>
				</Box>
			)}

			{listLoading ? (
				<Box className='loading-center'>
					<Typography>{t('loading')}</Typography>
				</Box>
			) : listingData.length === 0 ? (
				selectedCycleDetails?.cycleStatus !== 1 ? (
					<Box className='no-records-area'>
						<Box className='no-record-message'>
							<Typography variant='h2' component='h2'>
								{t('createCopyDefaultOkrsTitle')}
							</Typography>
							<Typography>{t('createCopyDefaultOkrsSubTitle')}</Typography>
							<Box className='get-started-card'>
								<AnimatedIcon
									className='add-user-img'
									width='157'
									type='image/svg+xml'
									alt='No user'
									imagePath={AddUserImg}
								/>

								<Box>
									<Box className='get-started-card-content'>
										<Typography>{t('createCopyDefaultOkrsCard')}</Typography>
									</Box>
									<Box className='get-started-card-link'>
										<Link onClick={handleClickDefaultOkr}>
											<AddIconBlue />
											{t('letsCreatetxt')}
										</Link>
										<Link
											onClick={() => {
												dispatch(openAdminCopyOkr(true));
											}}
										>
											<CopyOkrsIcon />
											{t('copyOKRs')}
										</Link>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				) : (
					<Box className='no-records-area'>
						<Box className='no-record-message'>
							<Typography variant='h2' component='h2'>
								{t('defaultNoRecord')}
							</Typography>
							<AnimatedIcon className='' width='516' type='image/svg+xml' alt='No user' imagePath={DefaultOkrImg} />
						</Box>
					</Box>
				)
			) : (
				listingData.map((objData: any) => (
					<Box
						className={`default-okr-listing ${
							highlightDefaultOkr === objData?.defaultGoalObjectiveId ? 'highlight-default-row' : ''
						}`}
						id={`default_okr_list_item_index_${objData?.defaultGoalObjectiveId}`}
					>
						<Box className='default-okr-list-panel'>
							<Box className='default-okr-list-panel-inner'>
								<Box className='default-obj-list-panel'>
									<List disablePadding>
										<ListItem className='default-okr-col1'>
											<Box className='default-okr-status-info'>
												{objData.status === 2 && <Chip className='published-chip' label={objData.statusName} />}
												{objData.status === 1 && <Chip className='published-updated-chip' label={objData.statusName} />}
												{objData.status === 3 && <Chip className='unpublished-chip' label={objData.statusName} />}
												{objData.status === 4 && <Chip className='published-updated-chip' label={objData.statusName} />}
											</Box>
											<Box className='default-okr-name'>
												<Typography>{objData.name}</Typography>
											</Box>
											<Box className='default-okr-actions'>
												<OKRButton
													className={`btn-blue-chip ${
														expandedKrList.indexOf(objData.defaultGoalObjectiveId) !== -1 ? 'expand' : 'collapse'
													}`}
													icon={<RightArrowIcon />}
													text={objData.defaultGoalKeyResponse.length + ' Key Results'}
													id={`kr-toggle-btn`}
													handleClick={(e) => handleExpandKeyResult(e, objData)}
												/>
												<Box className='set-timeline-area'>
													<Box className='calender-date'>
														<Box className='timeline-field'>
															<Box className='calendar-btn'>
																<OKRButton
																	disabled
																	icon={<CalendarIcon />}
																	text={`${getMonthDate(objData.endDate)} (${
																		selectedCycleDetails
																			? `${selectedCycleDetails?.symbol}, ${selectedCycleDetails?.year}`
																			: ''
																	})`}
																/>
															</Box>
														</Box>
													</Box>
												</Box>
												{selectedCycleDetails?.cycleStatus !== 1 && (
													<Box className='default-okr-field-actions'>
														<OKRButton
															className='clone-btn'
															id='obj-clone-btn'
															icon={<CloneIcon />}
															title={t('clone')}
															handleClick={(e: any) => handleCloneOkr(e, objData.defaultGoalObjectiveId)}
														/>
														<OKRButton
															className='edit-btn'
															id='obj-edit-btn'
															icon={<EditIcon />}
															title={t('editLabel')}
															handleClick={(e: any) => handleEditDefaultOkrClick(e, objData)}
														/>
														<OKRButton
															className='delete-btn'
															id='obj-delete-btn'
															icon={<DeleteIcon />}
															title={t('delete')}
															handleClick={(e: any) => handleDeleteDefaultOkrClick(e, objData)}
														/>
													</Box>
												)}
											</Box>
										</ListItem>
										<ListItem className='default-okr-col2'>
											<AssignChip
												defaultOkrFormDetails={{
													assignIds: objData.defaultGoalObjectiveAssignDetails,
													assignType: objData.assignType,
												}}
												hideLabelButton={true}
											/>
										</ListItem>
									</List>
								</Box>
								<Collapse
									className='default-kr-list-panel'
									in={expandedKrList.indexOf(objData.defaultGoalObjectiveId) !== -1}
									timeout='auto'
									unmountOnExit
								>
									{objData.defaultGoalKeyResponse.map((keyData: any) => (
										<Box className='default-kr-list-inner'>
											<Box className='default-okr-status-info'>
												{keyData.status === 2 && <Chip className='published-chip' label={keyData.statusName} />}
												{keyData.status === 1 && (
													<Chip
														className='published-updated-chip'
														/* label={t('publishedUpdated')} */ label={keyData.statusName}
													/>
												)}
												{keyData.status === 3 && <Chip className='unpublished-chip' label={keyData.statusName} />}
												{keyData.status === 4 && (
													<Chip
														className='published-updated-chip'
														/* label={t('publishedUpdated')} */ label={keyData.statusName}
													/>
												)}
											</Box>
											<Box className='default-kr-name'>
												<Typography>{keyData.name}</Typography>
											</Box>
											<Box className='default-okr-actions'>
												<Box className='set-timeline-area'>
													<Box className='timeline-field'>
														{/* {keyData?.publishedOn ? (
															<Box className='set-timeline-area'>
																<Box className='timeline-field'>
																	<Box className='calendar-btn'>
																		<OKRButton
																			title={t('Published on')}
																			text={
																				keyData?.publishedOn
																					? `${getMonthDate(keyData?.publishedOn)} (${
																							selectedCycleDetails
																								? `${selectedCycleDetails?.symbol}, ${selectedCycleDetails?.year}`
																								: ''
																					  })}`
																					: ''
																			}
																			icon={<PublishedOnIcon />}
																			disabled={true}
																		/>
																	</Box>
																</Box>
															</Box>
														) : (
															<></>
														)} */}
														<Box className='calendar-btn'>
															<OKRButton
																disabled
																icon={<CalendarIcon />}
																text={`${getMonthDate(keyData.endDate)} (${
																	selectedCycleDetails
																		? `${selectedCycleDetails?.symbol}, ${selectedCycleDetails?.year}`
																		: ''
																})`}
															/>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									))}{' '}
								</Collapse>
							</Box>
						</Box>
					</Box>
				))
			)}
			{modalProps && modalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleConfirmationCloseModal(e, 2)}
					matchString={modalProps?.details?.matchString || Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleConfirmationCloseModal(e, 1)}
					headingText={modalProps?.details?.headingText || ''}
					subHeading={modalProps?.details?.subHeading || ''}
				/>
			)}
			{modalOpen && (
				<DialogComponent
					module='information'
					message={t('publishText')}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen}
				/>
			)}
			{modalPerformanceProps && modalPerformanceProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalPerformanceProps?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={modalPerformanceProps.open}
				/>
			)}
		</>
	);
};
