import React, { useEffect, useState } from 'react';
import { Avatar, Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { TimeIcon } from '../../config/svg/CommonSvgIcon';
import { BaseUnlockUToken, UnlockUServer } from '../../config/url';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { getLearningUUrl, getServerUrl, getUserDetails, openPost, unlockEncryptString } from '../../config/utils';
import moment from 'moment';
import { LEARNINGU, TRENDING_COURSES_UNLOCKU_TOCKEN, UNLOCKAPPNAME, UNLOCKUTOKEN } from '../../config/constant';
import { getLocalStorageItem } from '../../services/StorageService';
import { DashboardToDoCoursesSkeleton } from './DashboardToDoCoursesSkeleton';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import noCourseImg from '../../images/no-course.svg';

export const DashboardToDoCourses: React.FC<any> = (props: any) => {
	const { setTrendingCoursesLoaded } = props;
	const { t } = useTranslation();
	const [trendingCourses, setTrendingCourses] = useState([]);
	const loginUser = getUserDetails();
	let timeFormat = (n: any) => `0${(n / 60) ^ 0}`.slice(-2) + ':' + ('0' + (n % 60)).slice(-2);
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const userDetail = JSON.parse(getLocalStorageItem('userDetail') || '{}');

	useEffect(() => {
		getTrendingCourses();
	}, []);

	const getTrendingCourses = () => {
		let data: any = new FormData();
		data.append('wstoken', BaseUnlockUToken);
		data.append('wsfunction', TRENDING_COURSES_UNLOCKU_TOCKEN);
		data.append('wsrestformat', 'json');
		data.append('username', loginUser.emailId);
		//used fetch because of cors error
		const finalUrl = UnlockUServer ? UnlockUServer : getServerUrl() || '';
		fetch(finalUrl, {
			body: data,
			method: 'post',
		})
			.then((res: any) => res.json())
			.then((coursesResponse: any) => {
				if (coursesResponse?.length) {
					setTrendingCoursesLoaded(true);
					setTrendingCourses(coursesResponse);
				} else {
					setTrendingCourses([]);
				}
			});
	};

	const handleViewCourse = (e: any, courseLink: any) => {
		let username = unlockEncryptString(tenantData.userEmail);
		if (userDetail?.isImpersonatedUser && userDetail?.emailId) {
			username = unlockEncryptString(userDetail?.emailId);
		}
		const company_identifier = unlockEncryptString(tenantData.tenantId);
		const static_token = unlockEncryptString(UNLOCKUTOKEN);
		const app_name = unlockEncryptString(UNLOCKAPPNAME);
		const course_accesslink = unlockEncryptString(encodeURI(courseLink));
		const payloadToSend = `?username=${username}&company_identifier=${company_identifier}&token=${static_token}&app_name=${app_name}&course_link=${course_accesslink}${
			'&okrCurrentState=' + unlockEncryptString(window.location.href)
		}`;
		const params: any = [];
		const finalUrl = LEARNINGU ? LEARNINGU : getLearningUUrl();
		params['requestParams'] = payloadToSend;
		openPost(finalUrl, params, true);
	};

	const formatInDate = (timeStamp: any) => {
		return moment.unix(timeStamp).format('MMMM D, YYYY');
	};

	return (
		<Box className='course-list'>
			{trendingCourses && trendingCourses.length ? (
				<List disablePadding>
					{trendingCourses &&
						trendingCourses.length > 0 &&
						trendingCourses.map((course: any, index: number) => (
							<ListItem key={index}>
								<Box className='courses-img'>
									<img src={course?.image_path} alt='course-img' />
								</Box>
								<Box className='courses-content'>
									{course?.fullname && course?.fullname?.length > 27 ? (
										<Tooltip arrow title={course.fullname} aria-label='Course Name'>
											<Typography variant='h4'>{course?.fullname.substring(0, 27)}...</Typography>
										</Tooltip>
									) : (
										<Typography variant='h4'>{course?.fullname}</Typography>
									)}

									<Box className='content-bottom'>
										<Box className='courses-status'>
											{course?.batch_startdate ? (
												<Typography variant='h6'>
													<TimeIcon /> {formatInDate(course?.batch_startdate)} /{' '}
													{course?.content_duration ? `${timeFormat(course?.content_duration)} mins` : ''}
												</Typography>
											) : (
												<>
													{course?.content_duration > 0 && (
														<Typography variant='h6'>
															<TimeIcon />{' '}
															{course?.content_duration ? `${timeFormat(course?.content_duration)} mins` : ''}
														</Typography>
													)}
												</>
											)}
											<OKRButton
												handleClick={(e) => handleViewCourse(e, course?.redirect_link)}
												className='btn-link view-course-btn'
												icon={<BackArrow />}
												text={t('viewCourse')}
											/>
											{/* <Typography variant='h6' className='in-lagging-text'>
													<Box onClick={(e) => handleViewCourse(e, course.redirect_link)}>View Course</Box>
												</Typography> */}
										</Box>
									</Box>
								</Box>
							</ListItem>
						))}
				</List>
			) : (
				<Box className='user-no-record' textAlign='center'>
					<Typography variant='h4'>{t('noCoursesMainHead')}</Typography>
					<Typography variant='h5' className='font-weight-normal'>
						{t('noCoursesSubHead')}
					</Typography>
					<AnimatedIcon className='' width='200' type='image/svg+xml' alt='No Academies ' imagePath={noCourseImg} />
				</Box>
				// <DashboardToDoCoursesSkeleton />
			)}

			{/* <OKRButton className='btn-primary view-all-courses-btn' text={t('viewAllCourses')} /> */}
		</Box>
	);
};
