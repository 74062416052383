import React, { Fragment, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Chip,
	ClickAwayListener,
	Grow,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { AddIconSvg, AlertInfoIcon } from '../../../config/svg/CommonSvg';
import { CategoryColoredIcon, GoalsCycleColoredIcon } from '../../../config/svg/MyGoalSvg';
import { getGoalCycleSelectorApi, getPerformanceGoalTeamDetails } from '../../../action/adminSettings';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../config/enums';
import {
	getMonthDateYearTime,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { PerformanceGoals } from '..';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import WelcomeImage from '../../../images/my-goal-bg.svg';
import { hidePerformanceCycle, setPerformanceGoalForRedirection } from '../../../action/common';
import { CalendarColoredIcon } from '../../../config/svg/CommonSvgIcon';
import { CommonFilters } from '../../PerformanceDevelopment/Common/CommonFilters';
import { GoalDrFilters } from '../CommonGoal/GoalDrFilters';

export const PerformanceTeamGoals: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userDetail = getUserDetails();
	const [loader, setLoader] = useState<boolean>(false);
	const [performanceGoalTeamDetails, setPerformanceGoalTeamDetails] = useState<any>({});
	const [openOtherTeams, setOpenOtherTeams] = useState<boolean>(false);
	const [drUserData, setDrUserData] = useState<any>({});
	const [anchorElTeam, setAnchorElTeam] = useState<HTMLElement | null>(null);
	const { performanceGoalCycle } = useSelector((state: any) => state?.adminSettingReducer);
	const [currentUserTeams, setCurrentUserTeams] = useState<any>(0);
	const { redirectedPerformanceGoalDetails } = useSelector((state: any) => state.commonReducer);
	const [searchValue, setSearchValue] = useState('');
	const [searchStringValue, setSearchStringValue] = useState('');

	let defaultFinalSettings: any[] = [];
	const [finalFilterCondition, setFinalFilterCondition] = useState<any>(defaultFinalSettings);
	const [noRecords, setNoRecords] = useState<boolean>(false);
	const [selectedFilterDateRange, setSelectedFilterDateRange] = useState<any>(null);
	const { hidePerformaceCycle } = useSelector((state: any) => state.commonReducer);

	const [feedbackFilters, setFeedbackFilters] = useState<any>({});
	useEffect(() => {
		if (redirectedPerformanceGoalDetails) {
			setTimeout(() => {
				dispatch(setPerformanceGoalForRedirection(null));
			}, 10000);
		}
		getUserPerformanceGoalTeamDetails();
	}, []);

	useEffect(() => {
		//notification redirection for team details
		if (redirectedPerformanceGoalDetails && performanceGoalTeamDetails) {
			const { tabId, cycleId, employeeId, categoryId, conversationId, conversationsReplyId } =
				redirectedPerformanceGoalDetails || {};
			if (employeeId) {
				const directReportsDetails = performanceGoalTeamDetails?.directReporteeDetails?.find(
					(item: any) => item.employeeId === employeeId
				);

				if (directReportsDetails) {
					navigateToUserForm(directReportsDetails);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectedPerformanceGoalDetails, performanceGoalTeamDetails]);

	useEffect(() => {
		if (performanceGoalCycle && performanceGoalCycle.performanceGoalCycleId) {
			let appliesFilter: any[] = [];
			if (finalFilterCondition.length > 0) {
				finalFilterCondition.forEach((item: any) => {
					appliesFilter.push(item.id);
				});
			}
			getUserPerformanceGoalTeamDetails(appliesFilter, searchStringValue);
		}
	}, [performanceGoalCycle, finalFilterCondition, searchStringValue]);

	const getUserPerformanceGoalTeamDetails = async (appliesFilter: any = [], searchString: any = '') => {
		try {
			setLoader(true);
			let requestParam: any = {
				search: [searchString],
				statusId: appliesFilter,
			};
			const response: any = await dispatch(getPerformanceGoalTeamDetails(requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setPerformanceGoalTeamDetails(response?.data.entity);
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {}
	};

	const handleOpenOtherTeams = (event: any, allUsers: any) => {
		setAnchorElTeam(event.currentTarget);
		setOpenOtherTeams(true);
		setCurrentUserTeams(allUsers);
	};
	const handleCloseOtherTeams = () => {
		setAnchorElTeam(null);
		setOpenOtherTeams(false);
		setCurrentUserTeams(0);
	};

	const scrollIntoView = () => {
		setTimeout(() => {
			const selectedEl = document.getElementById(`user-goal-section-head-id`);
			if (selectedEl) {
				selectedEl.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'start',
				});
			}
		}, 500);
	};
	const navigateToUserForm = (userData: any) => {
		setDrUserData(userData);
		dispatch(hidePerformanceCycle(false));
		scrollIntoView();
		let employeeData = `empId=${userData.employeeId ? userData.employeeId : 0}`;
		dispatch(getGoalCycleSelectorApi(employeeData));
	};

	const backClicked = () => {
		setDrUserData({});
		dispatch(setPerformanceGoalForRedirection(null));
		dispatch(hidePerformanceCycle(true));
		scrollIntoView();
		getUserPerformanceGoalTeamDetails();
	};
	const handleSearchText = (searchText: any) => {
		setSearchStringValue(searchText);
	};
	const applyFilter = (filterData: any) => {
		setFinalFilterCondition(filterData);
	};

	const applyDateFilter = (range: any) => {
		setSelectedFilterDateRange(range);
	};
	return (
		<Box className='performance-team-goals'>
			{!drUserData.employeeId ? (
				<Box className='user-goal-section-head'>
					<Box className='user-goal-section-head-left'>
						<Typography variant='h2'>{t('directReportsGoals')}</Typography>
					</Box>
					{hidePerformaceCycle && performanceGoalCycle && performanceGoalCycle.performanceGoalCycleId && (
						<Box className='user-goal-section-head-right'>
							<GoalDrFilters
								{...props}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								defaultFinalSettings={defaultFinalSettings}
								finalFilterCondition={finalFilterCondition}
								setFinalFilterCondition={applyFilter}
								handleSearchText={handleSearchText}
								setSelectedFilterDateRange={applyDateFilter}
								selectedFilterDateRange={selectedFilterDateRange}
								minDateValue={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
								maxDateValue={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
								defaultLocalSettings={defaultFinalSettings}
								type={'feedback'}
							/>
						</Box>
					)}
				</Box>
			) : (
				<></>
			)}

			{!drUserData.employeeId ? (
				<Box className='user-goals-section-content'>
					{performanceGoalTeamDetails?.directReporteeDetails &&
					performanceGoalTeamDetails?.directReporteeDetails.length ? (
						<Box className='team-goals-card-area'>
							{performanceGoalTeamDetails?.directReporteeDetails &&
							performanceGoalTeamDetails?.directReporteeDetails.length ? (
								performanceGoalTeamDetails?.directReporteeDetails?.map((directReporteeDetail: any) => (
									<>
										<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
											<Box
												className={`team-goals-card ${directReporteeDetail?.status === -1 ? 'cursor-default' : ''}`}
												onClick={() => {
													directReporteeDetail?.status !== -1 && navigateToUserForm(directReporteeDetail);
												}}
											>
												<Box className='team-goals-card-top'>
													<Box className='user-info v-center'>
														<Box className='user-img'>
															{directReporteeDetail?.imagePath ? (
																<Avatar
																	className='avatar-default'
																	alt={`${directReporteeDetail?.firstName} ${directReporteeDetail?.lastName}`}
																	src={directReporteeDetail?.imagePath}
																/>
															) : (
																<Avatar className='avatar-default'>
																	{getUserName({
																		firstName: directReporteeDetail?.firstName,
																		lastName: directReporteeDetail?.lastName,
																		fullName: directReporteeDetail?.fullname,
																	})}
																</Avatar>
															)}
														</Box>
														<Box className='user-info-details'>
															<Box className='user-info-label'>
																<Typography
																	variant='h4'
																	className='font-weight-normal'
																>{`${directReporteeDetail?.fullName}`}</Typography>
															</Box>
														</Box>
													</Box>

													<Box className='team-goals-status'>
														{directReporteeDetail.isAlertMessageVisible ? (
															<Tooltip
																placement='top'
																arrow
																classes={{ popper: 'tooltip-alert' }}
																title={directReporteeDetail.alterMessage}
															>
																<span>
																	<AlertInfoIcon />
																</span>
															</Tooltip>
														) : (
															<></>
														)}
														{directReporteeDetail.isExclamationMarkVisible ? (
															<Tooltip
																placement='top'
																arrow
																classes={{ popper: 'tooltip-alert' }}
																title={t('deadlineMissedTitle')}
															>
																<span>
																	<AlertInfoIcon />
																</span>
															</Tooltip>
														) : (
															<></>
														)}
														{directReporteeDetail?.statusName && (
															<Chip
																className={
																	directReporteeDetail?.status === Enums.ZERO
																		? 'default-grey-chip'
																		: directReporteeDetail?.status === Enums.ONE
																		? 'draft-chip'
																		: directReporteeDetail?.status === Enums.TWO
																		? 'lagging-chip'
																		: directReporteeDetail?.status === Enums.THREE
																		? 'default-grey-chip'
																		: directReporteeDetail?.status === Enums.FOUR
																		? 'on-track-chip'
																		: ''
																}
																label={directReporteeDetail?.statusName}
															/>
														)}
													</Box>
												</Box>
												<Box
													className={`team-goals-card-mid ${
														directReporteeDetail?.status === -1 ? 'team-goal-card-mid-eql' : ''
													}`}
												>
													{directReporteeDetail?.status === -1 ? (
														<>
															<Box className='category-duration'>
																<CalendarColoredIcon />
																<Typography variant='h6'>--</Typography>
															</Box>
															<Box className='category-count'>
																<CategoryColoredIcon />
																<Typography variant='h6'>--</Typography>
															</Box>
															<Box className='goals-count'>
																<GoalsCycleColoredIcon />
																<Typography variant='h6'>--</Typography>
															</Box>
														</>
													) : (
														<>
															<Box className='category-duration'>
																<CalendarColoredIcon />
																<Typography variant='h6'>{`${getMonthDateYearTime(
																	directReporteeDetail?.startDate
																)} - ${getMonthDateYearTime(directReporteeDetail?.endDate)}`}</Typography>
															</Box>
															{directReporteeDetail?.categorieCount && (
																<Box className='category-count'>
																	<CategoryColoredIcon />
																	<Typography variant='h6'>
																		{`${directReporteeDetail?.categorieCount} ${t('categoriesLabel')}`}
																	</Typography>
																</Box>
															)}
															{directReporteeDetail?.goalCount && (
																<Box className='goals-count'>
																	<GoalsCycleColoredIcon />
																	<Typography variant='h6'>
																		{`${directReporteeDetail?.goalCount} ${t('goalsLabel')}`}
																	</Typography>
																</Box>
															)}
														</>
													)}
												</Box>
											</Box>
										</Grow>
									</>
								))
							) : (
								<></>
							)}
						</Box>
					) : loader ? (
						<Box className='loading-center-global'>
							<Typography>Loading...</Typography>
						</Box>
					) : (
						<Box className='welcome-content performance-goals-welcome' id='performance-goals-welcome'>
							<Box textAlign='center' className='pad-container'>
								<Typography variant='h2'>
									{searchStringValue !== '' || finalFilterCondition.length > 0
										? t('noRecordPip')
										: t('teamDirectReportNoData1')}
								</Typography>
								<Typography>
									{searchStringValue !== '' || finalFilterCondition.length > 0
										? t('noRecordPipText')
										: t('teamDirectReportNoData2')}
								</Typography>
								<AnimatedIcon
									className=''
									width='516'
									type='image/svg+xml'
									alt='WelcomeImage'
									imagePath={WelcomeImage}
								/>
							</Box>
						</Box>
					)}
				</Box>
			) : (
				<>
					<PerformanceGoals {...props} fromTeamDetails={true} drUserData={drUserData} backClicked={backClicked} />
					{drUserData.status === 4 && <></>}
				</>
			)}
		</Box>
	);
};
