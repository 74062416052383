import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ClickAwayListener, Grow, List, ListItem, Paper, Popper, Tab, Tabs } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { AlignSettingIcon } from '../../../../config/svg/CommonSvg';
import LinearLoader from '../../../Common/Loader';
import { CreateOKRIcon } from '../../../../config/svg/MyGoalSvg';
import { SettingsTabPanel } from '..';
import { Align } from './Align';
import { DefaultOkr } from './DefaultOkr';
import { CreateDefaultOkr } from './DefaultOkr/CreateDefaultOkr';
import { CycleDropdown } from '../../../Common/CycleDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultOkrName } from '../../../../action/common';
import { getLocalStorageItem } from '../../../../services/StorageService';
import { getCycleDefaultSelection } from '../../../../config/utils';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Enums } from '../../../../config/enums';
import {
	getDefaultOkrCycleDetails,
	getDefaultOkrDesignation,
	getDefaultOkrPerformanceRole,
	pastCycleSelected,
} from '../../../../action/adminDefaultOkr';
import { CopyOkrMainDrawer } from './AdminCopyOkr/CopyOkrMainDrawer';
import { CycleDropdownData } from '../../../Common/CycleDropdownData';
import { BottomArrowIcon } from '../../../../config/svg/CommonSvgIcon';

export const AlignSettingsTab: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const {
		a11yProps,
		tabSelected,
		handleTabChange,
		parentOrganisation,
		selectedCycleDetails,
		cycleDetailsForDefaultOkr,
		setSelectedCycle,
		setSelectedCycleDetails,
		isCurrentCycle,
		selectedCycle,
	} = props;
	const dispatch = useDispatch();
	const cycleId = getLocalStorageItem('cycleId') ? parseInt(getLocalStorageItem('cycleId') || '0') : 0;
	const currentCycleYear = getLocalStorageItem('year') || null;
	const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	const [currentCycleId, setCurrentCycleId] = useState(cycleId);
	const cycleDuration = getLocalStorageItem('cycleDuration') || null;

	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<any>({ show: false, type: null });

	const handleTabClick = (event: any, newValue: Number) => {
		event.preventDefault();
		event.stopPropagation();
		handleTabChange(event, newValue);
	};

	const handleClick = (event: any, tabId: any) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
		setOpen({ show: true, type: tabId });
	};

	const handleCycleChange = (id: any, quarterData: any, year: any) => {
		setOpen(false);
		setSelectedCycle({
			organisationCycleId: quarterData?.organisationCycleId || currentCycleId,
			currentCycleYear: year || currentSelectedCycleYear,
			cycleDuration: quarterData?.cycleDuration || cycleDuration,
		});
		setSelectedCycleDetails({
			...quarterData,
			year: year || currentSelectedCycleYear,
		});
		handleTabChange({}, 1);
		if (quarterData.cycleStatus === 1) {
			dispatch(pastCycleSelected(true));
		} else {
			dispatch(pastCycleSelected(false));
		}
	};

	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpen({ show: false, type: null });
	};
	return (
		<>
			<Tabs className='okr-tab-button align-setting-tab-button ' value={tabSelected} aria-label='Align Tabs'>
				<Tab
					icon={<AlignSettingIcon />}
					label={`${t('alignSettingsLabel')}`}
					onClick={(e) => handleTabClick(e, 0)}
					value={0}
					{...a11yProps(0)}
				/>
				{parentOrganisation?.defaultOkrTagName && (
					<Tab
						className='arrow-tab'
						icon={<CreateOKRIcon />}
						label={
							<Box>
								{parentOrganisation?.defaultOkrTagName || ''}
								{`${' '}${t('cycle')} : ${
									selectedCycleDetails
										? `${selectedCycleDetails?.symbol}, ${selectedCycleDetails?.year || currentSelectedCycleYear}`
										: ''
								}`}
								<span className='arrowIcon'>
									<BottomArrowIcon />
								</span>
							</Box>
						}
						onClick={(e) => handleClick(e, 1)}
						value={1}
						{...a11yProps(1)}
					/>
				)}
			</Tabs>
			{/* <CycleDropdown
				allCycleDetails={cycleDetailsForDefaultOkr}
				currentCycleId={currentCycleId}
				currentSelectedCycleYear={currentSelectedCycleYear}
				cycleDuration={cycleDuration}
				handleCycleChange={handleCycleChange}
				selectedCycleDetails={selectedCycleDetails}
				currentCycleYear={currentCycleYear}
				isCurrentCycle={isCurrentCycle}
				isCycleDisable={false}
				editModee={false}
				setEditModee={() => {}}
				resToUseInCalendar={() => {}}
			/> */}
			{open.show && open.type === 1 ? (
				<Popper
					className={'popper-menu tabs-dropdown-menu default-okr-tabs-dropdown'}
					open={open.show ? true : false}
					anchorEl={anchorEl}
					placement={'bottom-end'}
					modifiers={{
						preventOverflow: {
							enabled: false,
							boundariesElement: window,
						},

						flip: {
							enabled: false, // true by default
						},
					}}
					transition
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<Paper>
								<ClickAwayListener
									onClickAway={(e) => {
										handleClose(e);
									}}
								>
									<Box className='cycle-dropdown'>
										<Box className='tenure-list' id='cycle-dropdown-wrapper'>
											<CycleDropdownData
												allCycleDetails={cycleDetailsForDefaultOkr}
												currentCycleId={currentCycleId}
												handleChange={() => {}}
												redirectTo={(id: any, quarterData: any, year: any) => {
													handleCycleChange(id, quarterData, year);
												}}
												selectedCycle={selectedCycle}
												callingFrom={'DefaultOkr'}
												isSoObj={false}
											/>
										</Box>
									</Box>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			) : null}
		</>
	);
};
