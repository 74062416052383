import { Box } from '@material-ui/core';
import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MainDrawer } from '../CommonComponent/MainDrawer';
import { ManageUserPermissionForm } from './ManageUserPermissionForm';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { DialogComponent } from '../CommonComponent/DialogComponent';

export const ManageUserPermission: React.FC<any> = (props) => {
	const {
		openManageUserPermDrawer,
		setOpenManageUserPermDrawer,
		selectedUser,
		getActiveRoles,
		rolesDataResult,
		getUserPermissionsStatus,
		userPermissions,
	} = props;
	const { t } = useTranslation();
	const [loader, setLoader] = useState<boolean>(false);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
	const [permissionsTree, setPermissionsTree] = useState<any>([]);
	const [selectedRole, setSelectedRole] = useState<any>([]);
	const [permissionsCopy, setPermissionsCopy] = useState<any>([]);
	const [rolesOptions, setRolesOptions] = useState<any>([]);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (getUserPermissionsStatus !== 'success') {
			setLoader(true);
		} else {
			setLoader(false);
		}
	}, [getUserPermissionsStatus]);

	useEffect(() => {
		if (!rolesDataResult.length) {
			getActiveRoles();
		}
		props.getEmployeePermissionsById(selectedUser.employeeId);
	}, []);

	//Set roles dropdown
	useEffect(() => {
		if (rolesDataResult.length && selectedUser) {
			let rolesOptionsList = rolesDataResult.map((rolesData: any) => {
				return {
					...rolesData,
					label: rolesData.roleName,
					isIndividualPermission: selectedUser.isIndividualPermission,
					value: rolesData.roleId,
				};
			});
			let filterSelected = rolesOptionsList.filter((item: any) => item.roleId === selectedUser.roleId);
			setSelectedRole(filterSelected);
			setRolesOptions(rolesOptionsList);
		}
	}, [rolesDataResult]);

	useEffect(() => {
		if (userPermissions && userPermissions.length) {
			setPermissionsCopy(JSON.parse(JSON.stringify(userPermissions)));
			let permissionTree = maintainPermissionTree(userPermissions);
			setPermissionsTree(permissionTree);
		}
	}, [userPermissions]);

	// useEffect(() => {
	//     if (rolesDataResult.length && selectedUser.roleId) {
	//         setSelectedRole(selectedUser);
	//     }
	// }, [rolesDataResult]);

	const maintainPermissionTree = (userPermissions: any) => {
		let permissions = userPermissions.map((items: any) => {
			let findLength = items.permissions.filter((permissions: any) => permissions.isActive).length;
			let findCustomPermissions = items.permissions.filter((permissions: any) => permissions.isIndividual).length;
			if (findLength === items.permissions.length) {
				return {
					...items,
					isActive: true,
					isIndividual: findCustomPermissions > 0 ? true : false,
				};
			} else if (findLength < 1) {
				return {
					...items,
					isActive: false,
					isIndividual: findCustomPermissions > 0 ? true : false,
				};
			} else {
				return {
					...items,
					isActive: null,
					isIndividual: findCustomPermissions > 0 ? true : false,
				};
			}
		});
		return permissions;
	};

	const getAllPermissionsStatus = () => {
		let permissionsData = [...permissionsTree];
		let newPermission = permissionsData.map((item: any) => {
			let allPer = item.permissions.map((per: any) => {
				return {
					permissionId: per.permissionId,
					isActive: per.isActive,
				};
			});
			return allPer;
		});

		let newPermissionObj = _.flatMap(newPermission);
		return newPermissionObj;
	};

	const handleSavePermission = async () => {
		let savePermissionObj = {
			employeeId: selectedUser.employeeId,
			roleId: selectedRole[0].roleId,
			permissionRequest: getAllPermissionsStatus(),
		};
		setLoader(true);
		const response = await props.saveEmployeePermissions(savePermissionObj);
		if (response && response.data && response.data.status === 200) {
			enqueueSnackbar(t('userPermissionSuccessMsg'), { variant: 'success', autoHideDuration: 3000 });
			setOpenManageUserPermDrawer(!openManageUserPermDrawer);
			props.setUserRoleUpdated(true);
			setLoader(false);
		} else {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 3000 });
			setLoader(false);
		}
	};

	const handleChangePermissions = (event: any, moduleIndex: number, permissionIndex: number) => {
		const { checked } = event?.target;
		let permissionItems = [...permissionsTree];
		permissionItems[moduleIndex].permissions[permissionIndex].isActive = checked;

		let updatedTree = maintainPermissionTree(permissionItems);
		setPermissionsTree(updatedTree);
		setIsFormEdited(true);
	};

	const handleChangeModule = (e: any, moduleIndex: number) => {
		const { checked } = e.target;
		let permissionItems = [...permissionsTree];
		permissionItems[moduleIndex].permissions.forEach((permissions: any) => {
			if (permissions.isEditable) {
				permissions.isActive = checked;
			}
		});
		let updatedTree = maintainPermissionTree(permissionItems);
		setPermissionsTree(updatedTree);
		setIsFormEdited(true);
	};

	/** Handle single module */
	const handleModuleReset = (e: any, moduleId?: Number) => {
		let userPermissionsclone = JSON.parse(JSON.stringify(permissionsCopy));
		let permissionItems = [...permissionsTree];
		let findIndex = 0;
		let updateModule = userPermissionsclone.map((item: any, index: number) => {
			if (moduleId) {
				if (item.moduleId === moduleId) {
					item.permissions.map((permission: any) => {
						if (permission.isIndividual) {
							permission.isActive = permission.individualPermissionType === 0 ? true : false;
							permission.isIndividual = false;
						} else {
							permission.isIndividual = false;
							permission.isActive = permission.isActive;
						}
					});
					findIndex = index;
				}
				return item;
			} else {
				item.permissions.map((permission: any) => {
					if (permission.isIndividual) {
						permission.isActive = permission.individualPermissionType === 0 ? true : false;
					} else {
						permission.isActive = permission.isActive;
					}
				});
				return item;
			}
		});
		setIsFormEdited(true);
		if (moduleId) {
			permissionItems[findIndex] = updateModule[findIndex];
			let updatedData = maintainPermissionTree(permissionItems);
			setPermissionsTree(updatedData);
		} else {
			let updatedData = maintainPermissionTree(updateModule);
			setPermissionsTree(updatedData);
		}
	};

	const handleDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if ((type === 'cancel' || type === 'close') && !isFormEdited) {
			setOpenManageUserPermDrawer(!openManageUserPermDrawer);
			return;
		} else if (isFormEdited && type !== 'save') {
			setShowAlertDialog((prev) => !prev);
			return;
		} else if (!isFormEdited && type === 'save') {
			setOpenManageUserPermDrawer(!openManageUserPermDrawer);
			return;
		}

		if (type === 'save') {
			handleSavePermission();
		}
	};

	/**
	 * Handle role drop down menu change
	 * @param value
	 */
	const handleRoleChangeDropDown = (value: any) => {
		setSelectedRole(value);
		setIsFormEdited(true);
		setLoader(true);
		props.getRolePermissionByRoleId(value[0].roleId);
	};

	const handleDialogCancelClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			setOpenManageUserPermDrawer(!openManageUserPermDrawer);
		} else {
			setShowAlertDialog((prev) => !prev);
		}
	};

	return (
		<Fragment>
			<MainDrawer
				open={openManageUserPermDrawer}
				headerTitle={t('managePermissionsLabel')}
				loader={loader}
				transitionDuration={{ enter: 500, exit: 1000 }}
				children={
					<Box className='drawer-inner-content'>
						<ManageUserPermissionForm
							{...props}
							selectedRole={selectedRole}
							setSelectedRole={setSelectedRole}
							handleChangePermissions={handleChangePermissions}
							handleChangeModule={handleChangeModule}
							permissionsTree={permissionsTree}
							handleModuleReset={handleModuleReset}
							rolesOptions={rolesOptions}
							handleRoleChangeDropDown={handleRoleChangeDropDown}
						/>
					</Box>
				}
				saveButtonText={t('saveBtn')}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
			/>

			<DialogComponent
				module='information'
				message={t('unSavedItemAlert')}
				modalOpen={showAlertDialog}
				handleCloseModal={handleDialogCancelClick}
			/>
		</Fragment>
	);
};
