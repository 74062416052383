/** users section props */

import { USER_ROLE_ID } from '../../../config/users';
import { checkForSpace, formatFeedbackDate, isValidEmail } from '../../../config/utils';

export const userDataFields = {
	firstName: 'firstName',
	lastName: 'lastName',
	emailId: 'emailId',
	employeeCode: 'employeeCode',
	designation: 'designation',
	reportingTo: 'reportingTo',
	roleId: 'roleId',
	teamId: 'teamId',
	isEmailSend: 'isEmailSend',
	performanceRoleName: 'performanceRoleName',
	isExcludeFromCurrentPerformanceCycle: 'isExcludeFromCurrentPerformanceCycle',
	department: 'department',
	performanceRoleId: 'performanceRoleId',
	skipLevelManagerId: 'skipLevelManagerId',
	businessUnitHeadId: 'businessUnitHeadId',
	hrBuddyId: 'hrBuddyId',
	hrbpId: 'hrbpId',
	joiningDate: 'joiningDate',
	isProbationPeriod: 'isProbationPeriod',
	performanceGoalFormId: 'performanceGoalFormId',
	piFilePath: 'piFilePath',
	piUploadFileName: 'piUploadFileName',
	piStorageFileName: 'piStorageFileName',
};

export interface UserDataProps {
	firstName: string;
	lastName: string;
	emailId: string;
	employeeCode: string;
	designation: string;
	reportingTo: number;
	roleId: number;
	teamId: number[];
	employeeId?: number;
	isEmailSend?: any;
	performanceRoleName?: '';
	isExcludeFromCurrentPerformanceCycle?: false;
	department: string;
	performanceRoleId: number;
	skipLevelManagerId: number;
	businessUnitHeadId: number;
	hrBuddyId: number;
	hrbpId: number;
	joiningDate: string | null;
	isProbationPeriod: boolean;
	performanceGoalFormId: number;
	piFilePath: string;
	piUploadFileName: string;
	piStorageFileName: string;
}

/** users section default values */
export const defaultUserData: UserDataProps = {
	firstName: '',
	lastName: '',
	emailId: '',
	employeeCode: '',
	designation: '',
	reportingTo: 0,
	roleId: 0,
	teamId: [],
	isEmailSend: false,
	performanceRoleName: '',
	isExcludeFromCurrentPerformanceCycle: false,
	department: '',
	performanceRoleId: 0,
	skipLevelManagerId: 0,
	businessUnitHeadId: 0,
	hrBuddyId: 0,
	hrbpId: 0,
	joiningDate: null,
	isProbationPeriod: false,
	performanceGoalFormId: 0,
	piFilePath: '',
	piUploadFileName: '',
	piStorageFileName: '',
};
export const freeTrialsRequiredFields = ['firstName', 'lastName', 'emailId'];
export const userDataRequiredFields = ['firstName', 'lastName', 'emailId', 'roleId', 'teamId', 'performanceRoleName'];
export const userDataRequiredFieldsErrorMsg: any = {
	firstName: 'firstNameRequired',
	firstNameInvalid: 'firstNameInvalid',
	lastName: 'lastNameRequired',
	lastNameInvalid: 'lastNameInvalid',
	emailIdInvalid: 'emailInvalid',
	emailId: 'emailRequired',
	designation: 'designationRequired',
	teamId: 'organizationRequired',
	performanceRoleName: 'performanceRoleName',
};

/**
 * To check if a field is valid
 * @param field field to be checked
 * @param fieldValue field value
 * @param t translation function to fetch translation string
 * @returns blank string in case of no error else return error text
 */
export const isFieldValid = (field: string, fieldValue: any, t: any): string => {
	switch (field) {
		case userDataFields.firstName:
		case userDataFields.lastName:
			if (fieldValue.trim() === '') {
				return t(userDataRequiredFieldsErrorMsg[field]);
			}
			// else if (checkForSpace(fieldValue)) {
			// 	return t(userDataRequiredFieldsErrorMsg[field + 'Invalid']);
			// }
			return '';
		case userDataFields.designation:
			return fieldValue.trim() === '' ? t(userDataRequiredFieldsErrorMsg[field]) : '';
		case userDataFields.emailId:
			if (fieldValue.trim() === '') {
				return t(userDataRequiredFieldsErrorMsg[field]);
			} else if (!isValidEmail(fieldValue)) {
				return t(userDataRequiredFieldsErrorMsg[field + 'Invalid']);
			}
			return '';
		case userDataFields.teamId:
		case userDataFields.roleId:
			return fieldValue === 0 || fieldValue.length === 0 ? t(userDataRequiredFieldsErrorMsg[field]) : '';
		case userDataFields.performanceRoleName:
			return fieldValue.trim() === '' ? t(userDataRequiredFieldsErrorMsg[field]) : '';
		default:
			return '';
	}
};

export const prepareApiPayload = (payload: any): UserDataProps => {
	let createUserPayload: UserDataProps = Object.assign({}, defaultUserData);
	createUserPayload.firstName = payload.firstName;
	createUserPayload.lastName = payload.lastName;
	createUserPayload.emailId = payload.emailId;
	createUserPayload.employeeCode = payload.employeeCode;
	createUserPayload.designation = payload.designation;
	createUserPayload.roleId = payload.roleId;
	createUserPayload.isEmailSend = payload.isEmailSend;
	createUserPayload.performanceRoleName = payload.performanceRoleName || '';
	createUserPayload.performanceRoleId = payload.performanceRoleId || '';
	createUserPayload.isExcludeFromCurrentPerformanceCycle = payload.isExcludeFromCurrentPerformanceCycle || false;
	createUserPayload.department = payload.department;
	createUserPayload.skipLevelManagerId = 0;
	createUserPayload.businessUnitHeadId = 0;
	createUserPayload.hrBuddyId = 0;
	createUserPayload.hrbpId = 0;
	createUserPayload.joiningDate = payload.joiningDate ? formatFeedbackDate(payload.joiningDate) : null;
	createUserPayload.isProbationPeriod = payload.isProbationPeriod;
	createUserPayload.performanceGoalFormId = payload.performanceGoalFormId || 0;
	createUserPayload.piFilePath = payload.piFilePath || '';
	createUserPayload.piUploadFileName = payload.piUploadFileName || '';
	createUserPayload.piStorageFileName = payload.piStorageFileName || '';

	if (payload.performanceGoalFormId && payload.performanceGoalFormId.length > 0) {
		createUserPayload.performanceGoalFormId = payload.performanceGoalFormId[0].value || 0;
	}
	if (payload.roleId && payload.roleId.length > 0) {
		createUserPayload.roleId = payload.roleId[0].value;
	}
	if (payload.reportingTo && payload.reportingTo.length > 0) {
		createUserPayload.reportingTo = payload.reportingTo[0].value;
	}
	if (payload.skipLevelManagerId && payload.skipLevelManagerId.length > 0) {
		createUserPayload.skipLevelManagerId = payload.skipLevelManagerId[0].value;
	}
	if (payload.businessUnitHeadId && payload.businessUnitHeadId.length > 0) {
		createUserPayload.businessUnitHeadId = payload.businessUnitHeadId[0].value;
	}
	if (payload.hrBuddyId && payload.hrBuddyId.length > 0) {
		createUserPayload.hrBuddyId = payload.hrBuddyId[0].value;
	}
	if (payload.hrbpId && payload.hrbpId.length > 0) {
		createUserPayload.hrbpId = payload.hrbpId[0].value;
	}
	if (payload.teamId && payload.teamId.length > 0) {
		createUserPayload.teamId = payload.teamId.map((teamData: any) => {
			return teamData.value;
		});
	}
	return createUserPayload;
};

export const prepareAddAnotherFormData = (formData: any) => {
	let formDefaultData = Object.assign({}, defaultUserData);
	formDefaultData.designation = formData.designation;
	formDefaultData.teamId = formData.teamId;
	formDefaultData.roleId = formData.roleId;
	return formDefaultData;
};

export const prepareEditUserFormData = (userData: any) => {
	let formDefaultData: any = {};
	formDefaultData.firstName = userData.firstName;
	formDefaultData.lastName = userData.lastName;
	formDefaultData.emailId = userData.emailId;
	formDefaultData.employeeCode = userData.employeeCode;
	formDefaultData.designation = userData.designation;
	formDefaultData.performanceRoleId = userData.performanceRoleId || '';
	formDefaultData.performanceRoleName = userData.performanceRoleName || '';
	formDefaultData.isExcludeFromCurrentPerformanceCycle = userData.isExcludeFromCurrentPerformanceCycle || false;
	formDefaultData.department = userData.department;
	formDefaultData.isProbationPeriod = userData.isProbationPeriod;
	formDefaultData.joiningDate = userData.joiningDate || null;
	formDefaultData.performanceGoalFormId = userData.performanceGoalFormId || 0;
	formDefaultData.piFilePath = userData. piFilePath|| '';
	formDefaultData.piUploadFileName = userData. piUploadFileName|| '';
	formDefaultData.piStorageFileName = userData. piStorageFileName|| '';
	if (userData.reportingManagerResponse.reportingManagerId) {
		formDefaultData.reportingTo = [
			{
				label: userData.reportingManagerResponse.firstName + ' ' + userData.reportingManagerResponse.lastName,
				value: userData.reportingManagerResponse.reportingManagerId,
				imagePath: userData.reportingManagerResponse.imagePath,
				secondaryText: userData.reportingManagerResponse.designation,
			},
		];
	} else {
		formDefaultData.reportingTo = 0;
	}
	if (userData.businessUnitHeadResponse.businessUnitHeadId) {
		formDefaultData.businessUnitHeadId = [
			{
				label: userData.businessUnitHeadResponse.firstName + ' ' + userData.businessUnitHeadResponse.lastName,
				value: userData.businessUnitHeadResponse.businessUnitHeadId,
				imagePath: userData.businessUnitHeadResponse.imagePath,
				secondaryText: userData.businessUnitHeadResponse.designation,
			},
		];
	} else {
		formDefaultData.businessUnitHeadId = 0;
	}
	if (userData.skipLevelManagerResponse?.skipLevelManagerId) {
		formDefaultData.skipLevelManagerId = [
			{
				label: userData.skipLevelManagerResponse.firstName + ' ' + userData.skipLevelManagerResponse.lastName,
				value: userData.skipLevelManagerResponse.skipLevelManagerId,
				imagePath: userData.skipLevelManagerResponse.imagePath,
				secondaryText: userData.skipLevelManagerResponse.designation,
			},
		];
	} else {
		formDefaultData.skipLevelManagerId = 0;
	}
	if (userData.hrBuddyResponse?.hrBuddyId) {
		formDefaultData.hrBuddyId = [
			{
				label: userData.hrBuddyResponse.firstName + ' ' + userData.hrBuddyResponse.lastName,
				value: userData.hrBuddyResponse.hrBuddyId,
				imagePath: userData.hrBuddyResponse.imagePath,
				secondaryText: userData.hrBuddyResponse.designation,
			},
		];
	} else {
		formDefaultData.hrBuddyId = 0;
	}
	if (userData.hrbpResponse?.hrbpId) {
		formDefaultData.hrbpId = [
			{
				label: userData.hrbpResponse.firstName + ' ' + userData.hrbpResponse.lastName,
				value: userData.hrbpResponse.hrbpId,
				imagePath: userData.hrbpResponse.imagePath,
				secondaryText: userData.hrbpResponse.designation,
			},
		];
	} else {
		formDefaultData.hrbpId = 0;
	}
	formDefaultData.teamId = userData.employeeTeamResponses.map((employeeTeam: any) => {
		return {
			label: employeeTeam.teamName,
			value: employeeTeam.teamId,
			isNotDeletable: employeeTeam.isTeamLeader,
			imagePath: employeeTeam.imagePath,
			secondaryText: employeeTeam.organisationHead,
			count: employeeTeam.employeeCount,
			colorCode: employeeTeam.colorCode,
			backGroundColorCode: employeeTeam.backGroundColorCode,
		};
	});
	formDefaultData.roleId = [
		{
			label: userData.roleName,
			value: userData.roleId,
		},
	];
	formDefaultData.performanceGoalFormId = [
		{
			label: userData.performanceGoalFormName || 'Search or select goal form',
			value: userData.performanceGoalFormId || 0,
		},
	];

	return formDefaultData;
};
