import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTemplateList, getSampleTemplateByTemplateId, uploadTemplateData, validateLetter } from '../../action/letterGeneration';
import { DefaultOrigin } from '../../config/constant';
import { Enums } from '../../config/enums';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import { Box, Drawer, Button, FormControl, Select, MenuItem, Typography, FormLabel, Link } from '@material-ui/core';

import { AddIconSvg, DeleteIcon, DownloadIcon } from '../../config/svg/CommonSvg';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import UploadIcon from '../../images/upload-img-new.svg';
import { DialogComponent } from '../Admin/CommonComponent/DialogComponent';

import { useTranslation } from 'react-i18next';
import LinearLoader from '../Common/Loader';
import { useSnackbar } from 'notistack';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import exportFromJSON from 'export-from-json';

type Anchor = 'right';

export default function LetterDrawer(props: any) {
    const { onRefreshData, templates, setTemplates } = props;
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [selectTemplate, setSelectTemplate] = useState<string>('0');
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [errorLogMessage, setErrorLogMessage] = useState([]);
    const [fileIsValidated, setFileIsValidated] = useState<boolean>(false);
    const [formError, setFormError] = useState<any>({});
    const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [inputKey, setInputKey] = useState(Date.now());

    const inputFileRef = useRef<HTMLInputElement>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    const [uploadFileData, setUploadFileData] = useState({
        filePath: '',
        fileName: '',
        fileSize: 0,
        fileType: '',
        fileUploaded: false,
    });

    const showApiMsgs = (msg: string, variant: any, duration: any) => {
        enqueueSnackbar(msg, {
            variant: variant,
            autoHideDuration: duration,
        });
    };

    const removeFile = () => {
        setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
    };

    const handleCloseModal = (e: any, type: any) => {
        e.preventDefault();
        if (type === 1) {
            if (modalProps.type === 'deleteFile') {
                removeFile();
            }
        }
        setModalProps({ open: false, type: '', message: '', module: '' });
    };

    useEffect(() => {
        const addTemplatesInListDownload = async () => {
            let response: any = {};
            try {
                let data = {
                    subdomain: window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin,
                };
                response = await dispatch(getTemplateList(data));
                if (response.status === Enums.STATUS_SUCCESS) {
                    setTemplates(response.data.entityList.map((template: any) => ({
                        id: template.templateId,
                        name: template.templateName
                    })));
                }
            } catch (error) {
                console.error('Failed to fetch templates:', error);
            }
        };

        addTemplatesInListDownload();
    }, [dispatch]);

    const { t } = useTranslation();

    const [openFilter, setOpenFilter] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: any) => {
        setOpenFilter(openFilter ? null : event.currentTarget);
    };

    const selectionChangeHandler = (event: any) => {
        setSelectTemplate(event.target.value);
    };

    const handlePopperClose = () => {
        setOpenFilter(null);
    };

    const open = Boolean(openFilter);
    const id = open ? 'simple-popper' : undefined;

    const resetFileInput = () => {
        setInputKey(Date.now());
    };

    const handleFileChange = (event: any) => {
        setErrorLogMessage([]);

        setLoader(true);
        const file = event.target.files[0];
        event.target.value = '';
        const fSize = file.size;
        const size = Math.round(fSize / 1024);
        const fileType = file.type;
        const format = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv',
        ];
        if (size >= 1024) {
            enqueueSnackbar(t('fileSize1MB'), { variant: 'error', autoHideDuration: 5000 });
        } else if (!format.includes(fileType.toLowerCase())) {
            enqueueSnackbar(t('fileType4BulkUpload'), { variant: 'error', autoHideDuration: 5000 });
        } else {
            let fileObj = { filePath: file, fileName: file.name, fileUploaded: true };
            setFileIsValidated(true);
            handleFileVerification(fileObj);
        }
        setTimeout(() => {
            const selectedEl = document.getElementById('uploaded-file-details');
            if (selectedEl) {
                selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }, 15);

        setErrorLogMessage([]);
    };

    const handleTemplateSelection = async () => {
        if (selectTemplate === '0') return;
        const matchingTemplate = templates.find((template: any) => template.id === selectTemplate);
        setSelectedTemplateId(selectTemplate);
        setOpenFilter(null);
        const templateName = matchingTemplate ? matchingTemplate.name : undefined;
        try {
            let response: any = {};
            response = await dispatch(getSampleTemplateByTemplateId({ templateId: selectTemplate }));
            if (response.status === Enums.STATUS_SUCCESS) {
                const templateHeaders = response.data.entityList.reduce((acc: any, template: any) => {
                    acc[template.templateColumnName] = '';
                    return acc;
                }, {});

                // const sampleData = {
                //     "Effective From(DD-MM-YYYY)*": "15-04-2020",
                //     "EmailId*": "sample.email@domain.com",
                //     "Employee ID*": "12345",
                //     "First Name*": "John",
                //     "Last Name*": "Doe",
                //     "Letter Date(DD-MM-YYYY)*": "15-04-2020",
                //     "Reference Number*": "REF1234567",
                //     "Revised Designation*": "New Title"
                // };
                const worksheetData = [templateHeaders];
                const worksheet = XLSX.utils.json_to_sheet(worksheetData);
                const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

                const data = new Blob([csvOutput], { type: 'text/csv' });
                saveAs(data, (templateName + '.csv'));
            } else {
                console.error("Failed to fetch the template");
            }
        } catch (error) {
            console.error('Error fetching template:', error);
        }
    };

    const handleFileVerification = async (file: any) => {
        if (!file) {
            alert("Please select a file before uploading.");
            return;
        }

        setLoader(true);
        const formData = new FormData();
        formData.append('formFile', file.filePath);
        let data = {
            formData: formData,
            templateId: parseInt(selectTemplate)
        };
        try {
            let response: any = {};
            response = await dispatch(validateLetter(data));
            if (response.data && response.data.status === 200) {

                setLoader(false);
                resetFileInput();
                setUploadFileData({ ...uploadFileData, filePath: file.filePath, fileName: file.fileName, fileUploaded: true });
                setFileIsValidated(true);
                setSelectedFile(file);
                showApiMsgs(t('fileVerifySuccess'), 'success', 3000);
                return response.data;
            } else if (response.data.status === 400) {
                if (response.data.entity && response.data.entity.bulkErrors.length > 0) {
                    const errorDetail = response.data.entity.bulkErrors;
                    setErrorLogMessage(errorDetail);
                }
                const responseAPI = response.data.messageList;
                const keys = Object.keys(responseAPI);
                const messages = keys.map((item) => responseAPI[item]);
                showApiMsgs(`${messages} `, 'error', 4000);
                setLoader(false);
            } else {
                console.error("Failed to upload File.");
            }
        } catch (error) {
            console.error('Failed to upload handle.', error);
        }
    };


    const handleFileUpload = async (anchor: Anchor) => {
        if (!uploadFileData) {
            alert("Please select a file before uploading.");
            return;
        }
        setFileIsValidated(false);
        setLoader(true);
        const formData = new FormData();
        formData.append('formFile', uploadFileData.filePath);
        let data = {
            formData: formData,
            templateId: parseInt(selectTemplate)
        };
        try {
            let response: any = {};
            response = await dispatch(uploadTemplateData(data));
            if (response.data && response.data.status === 200) {
                setSelectedFile(null);
                resetFileInput();
                closeDrawer(anchor, false);
                onRefreshData();
                removeFile();
                setIsDrawerOpen(false);
                setLoader(false);
                showApiMsgs(t('fileUploadSuccess'), 'success', 4000);
            } else if (response.data.status === 400) {
                if (response.data.entity && response.data.entity.bulkErrors.length > 0) {
                    const errorDetail = response.data.entity.bulkErrors;
                    setErrorLogMessage(errorDetail);
                }
                const responseAPI = response.data.messageList;
                const keys = Object.keys(responseAPI);
                const messages = keys.map((item) => responseAPI[item]);
                showApiMsgs(`${messages} `, 'error', 4000);
                setLoader(false);
            } else {
                console.error("Failed to upload File.");
            }
        } catch (error) {
            console.error('Failed to upload handle.', error);
        }
    };

    const exportToExcel = () => {
        const updatedErrorLogMessage = errorLogMessage.map((item: any) => {
            return {
                'Record Row & Name': item.key,
                'Error Description': item.value,
            };
        });
        exportFromJSON({ data: updatedErrorLogMessage, fileName: 'error_report', exportType: 'csv' });
    };

    const closeDrawer = (anchor: Anchor, open: boolean) => {
        setState({ ...state, [anchor]: open });
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setIsDrawerOpen(open);
            setState({ ...state, [anchor]: open });
        };

    useEffect(() => {
        if (isDrawerOpen) {
            setSelectedTemplateId(null);
            setSelectTemplate('0');
            setSelectedFile(null);
            removeFile();
        }
    }, [isDrawerOpen]);


    useEffect(() => {
        const handleBodyClick = (event: any) => {
            if (openFilter && !openFilter.contains(event.target as Node)) {
                handlePopperClose();
            }
        };

        document.body.addEventListener('click', handleBodyClick);

        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [openFilter]);


    return (
        <>
            {loader && (
                <Box className='loader-wrap'>
                    <LinearLoader data={true} />
                </Box>
            )}
            <Box>
                {(['right'] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button
                            className={open ? 'add-btn active' : 'add-btn'}
                            color='primary'
                            onClick={toggleDrawer(anchor, true)}
                            id={'quick-link-add'}
                        >
                            <AddIconSvg />
                        </Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            className='main-drawer-panel main-drawer-titlepanel letter-drawer'
                        >
                            <Button className='drawer-close-btn' id='drawer-close-btn' onClick={toggleDrawer(anchor, false)}>
                                <RightArrowIcon />
                            </Button>
                            <Box className='drawer-area'>
                                <Box className='drawer-head'>
                                    <Box className='drawer-head-left'>
                                        <Typography variant='h3'>{t('letterGenTitle')}</Typography>
                                    </Box>
                                </Box>
                                <Box className='drawer-content'>
                                    <Box className='drawer-inner-content'>
                                        <Box className='okr-drawer-form-area drawer-content-panel'>

                                            <Box className='bulk-user-content'>
                                                <Box className='upload-head select-template-box'>
                                                    <FormControl>
                                                        <FormLabel> Select Template
                                                            <i style={{ color: 'red' }}>*</i></FormLabel>
                                                        <Select value={selectTemplate} onChange={selectionChangeHandler}>
                                                            <MenuItem disabled value="0">Select Template</MenuItem>
                                                            {templates.map((template: any) => (
                                                                <MenuItem key={template.id} value={template.id} >{template.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    {selectTemplate !== "0" && (
                                                        <Button onMouseDown={() => handleTemplateSelection()}> <DownloadIcon /> </Button>
                                                    )}
                                                </Box>

                                                {/* <Box className={`upload-file ${selectTemplate !== '0' ? '' : 'disabled-upload-file'}`}>
                                                    <Box className='upload-file-inner'>
                                                        <img src={UploadIcon} alt='Upload File' className='upload-img' />
                                                        <Typography variant='h4'>
                                                            <label htmlFor='fileInput' className='file-input-label txt-wrap'>
                                                                {t('UsersBulkUploadBrowseFile')}
                                                                <input
                                                                    ref={inputFileRef}
                                                                    key={inputKey}
                                                                    type='file'
                                                                    id='fileInput'
                                                                    accept='.csv, .xlsx, .xls'
                                                                    onChange={handleFileChange}
                                                                    style={{ display: 'none' }}
                                                                />
                                                            </label>
                                                        </Typography>
                                                    </Box>
                                                </Box> */}


<label htmlFor='fileInput' className={`upload-file ${selectTemplate !== '0' ? '' : 'disabled-upload-file'}`}>
    <Box className='upload-file-inner'>
        <img src={UploadIcon} alt='Upload File' className='upload-img' />
        <Typography variant='h4' component='span'>
            {t('UsersBulkUploadBrowseFile')}
        </Typography>
        <input
            ref={inputFileRef}
            key={inputKey}
            type='file'
            id='fileInput'
            accept='.csv, .xlsx, .xls'
            onChange={handleFileChange}
            style={{ display: 'none' }}
        />
    </Box>
</label>



                                                <Box id='uploaded-file-details' className={`note-text ${selectTemplate !== '0' ? '' : 'disabled-upload-file'}`}>
                                                    <Typography variant='body1' className='short-des-text'>
                                                        <strong>{t('RoleEditNote')}:</strong> <br />
                                                        {t('bulkUpdateEmailNotify')}
                                                    </Typography>
                                                </Box>

                                                {uploadFileData.fileName !== '' ? (
                                                    <Box className='uploading-content'>
                                                        <Box className='uploading-bar'>
                                                            <Box className='bar'>
                                                                <Box className='success'></Box>
                                                                <Box className='inner-bar'>
                                                                    <Typography>
                                                                        {t('UsersBulkUploadFileName')} : {uploadFileData.fileName ? uploadFileData.fileName : ''}
                                                                    </Typography>
                                                                    <Button
                                                                        className='delete-icon'
                                                                        onClick={() =>
                                                                            setModalProps({
                                                                                open: true,
                                                                                module: 'delete',
                                                                                type: 'deleteFile',
                                                                                message: t('UsersBulkUploadDeleteFile'),
                                                                            })
                                                                        }
                                                                    >
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <></>
                                                )}
                                                {errorLogMessage && errorLogMessage.length > 0 && (
                                                    <Box className='upload-result'>
                                                        <Box className='no-error-mssg'>
                                                            <ErrorIcon />
                                                            <Typography variant='h5'>
                                                                {formError && formError.bulkUserError ? formError.bulkUserError : t('noRecordsAdded')}
                                                            </Typography>
                                                        </Box>
                                                        <Box className='download-error-link' onClick={exportToExcel}>
                                                            <DownloadIcon />
                                                            <Link>{t('downloadErrorReportsText')}</Link>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className='drawer-footer-actions'>
                                    <Box>
                                        <Button
                                            className='cancel-btn btn-link'
                                            id='profile-cancel-btn'
                                            color='primary'
                                            onClick={toggleDrawer(anchor, false)}
                                        >
                                            {t('cancelBtn')}
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            className='submitBtn btn-primary'
                                            id='profile-submit-btn'
                                            color='primary'
                                            onClick={(e) => handleFileUpload(anchor)}
                                            disabled={selectTemplate === '0' || !selectedFile || !fileIsValidated}
                                        >
                                            {t('Add')}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Drawer>
                    </React.Fragment>
                ))
                }
            </Box >
            {modalProps.open && (
                <DialogComponent
                    module='information'
                    message={modalProps.message}
                    handleCloseModal={handleCloseModal}
                    modalOpen={modalProps.open}
                />
            )}
        </>
    );
}