import React, { Fragment, useEffect, useState } from 'react';
import { Box, Chip, Tooltip, Typography } from '@material-ui/core';
import { CategoryColoredIcon, DirectReportColorIcon, GoalsCycleColoredIcon } from '../../config/svg/MyGoalSvg';
import { useTranslation } from 'react-i18next';
import { getMonthDateYearTime } from '../../config/utils';
import { AlertInfoIcon } from '../../config/svg/CommonSvg';
import { Enums } from '../../config/enums';

export const DashboardMyGoals: React.FC<any> = (props: any) => {
	const { performanceGoalsData, handleCardClick } = props;
	const { t } = useTranslation();
	return (
		<>
			{performanceGoalsData?.myGoalsView?.isMyGoalViewVisible && (
				<Box
					className='goals-widget-box box-rectangle-info'
					onClick={(e) => {
						handleCardClick(e, 'myGoals');
					}}
				>
					<Box className='box-head'>
						{performanceGoalsData?.myGoalsView?.totalCategoryCount && (
							<Box className='box-head-title cat-title'>
								<CategoryColoredIcon />
								<Typography variant='h2'>{performanceGoalsData?.myGoalsView?.totalCategoryCount}</Typography>
							</Box>
						)}
						{performanceGoalsData?.myGoalsView?.totalGoalCount && (
							<Box className='box-head-title goals-title'>
								<GoalsCycleColoredIcon />
								<Typography variant='h2'>{performanceGoalsData?.myGoalsView?.totalGoalCount}</Typography>
							</Box>
						)}
					</Box>
					<Box className='box-content'>
						<Box className='performance-goals-details'>
							<Typography variant='h5'>{t('myGoalsLabel')}</Typography>
							<Box className='performance-goals-status'>
								{performanceGoalsData?.myGoalsView?.isAlertMessageVisible ? (
									<Tooltip
										placement='top'
										arrow
										classes={{ popper: 'tooltip-alert' }}
										title={performanceGoalsData?.myGoalsView?.alertMessage}
									>
										<span className='status-alert'>
											<AlertInfoIcon />
										</span>
									</Tooltip>
								) : (
									<></>
								)}
								{performanceGoalsData?.myGoalsView?.statusName && (
									<Chip
										className={
											performanceGoalsData?.myGoalsView?.status === Enums.ZERO
												? 'default-grey-chip'
												: performanceGoalsData?.myGoalsView?.status === Enums.ONE
												? 'draft-chip'
												: performanceGoalsData?.myGoalsView?.status === Enums.TWO
												? 'lagging-chip'
												: performanceGoalsData?.myGoalsView?.status === Enums.THREE
												? 'default-grey-chip'
												: performanceGoalsData?.myGoalsView?.status === Enums.FOUR
												? 'on-track-chip'
												: ''
										}
										label={performanceGoalsData?.myGoalsView?.statusName}
									/>
								)}
							</Box>
						</Box>
						<Typography variant='h6' className='font-weight-normal'>
							{`${getMonthDateYearTime(
								performanceGoalsData?.myGoalsView?.settingDurationStartDate
							)} - ${getMonthDateYearTime(performanceGoalsData?.myGoalsView?.settingDurationEndDate)}`}
						</Typography>
					</Box>
				</Box>
			)}
			{performanceGoalsData?.reportingManagerView?.isReportingManagerView && (
				<Box
					className='goals-widget-box box-rectangle-info'
					onClick={(e) => {
						handleCardClick(e, 'directReportsGoals');
					}}
				>
					<Box className='box-head'>
						<Box className='box-head-title cat-title'>
							<DirectReportColorIcon />
							<Typography variant='h2'>{performanceGoalsData?.reportingManagerView?.actionPending}</Typography>
						</Box>
					</Box>
					<Box className='box-content'>
						<Typography variant='h5'>{t('myDirectReportsGoals')}</Typography>
						<Typography variant='h6' className='font-weight-normal'>
							{t('countOfPendingApprovals')}
						</Typography>
					</Box>
				</Box>
			)}
		</>
	);
};
