import React, { useEffect, useState } from 'react';
import { ClickAwayListener, TextField, Box, Typography } from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { CrossIcon, EditIcon, TickThinIcon } from '../../../config/svg/CommonSvgIcon';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '../../../config/svg/formElementIcons';

export const TextInlineEdit: React.FC<any> = (props) => {
	const { inlineEdit, setInlineEdit, errorText, parentName, updateValue, error } = props;
	const [textValue, setTextValue] = useState<String>('');
	const [saveClicked, setSaveClicked] = React.useState(false);
	const inputRef = React.useRef<any>();

	const { t } = useTranslation();
	const enterFocus = (e: any) => {
		e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
	};
	useEffect(() => {
		if (parentName) {
			setTextValue(parentName);
		}
	}, [parentName]);

	const handleClickAway = () => {
		if (textValue !== '') {
			setInlineEdit(false);
			updateValue(textValue);
			setSaveClicked(false);
		} else {
			setSaveClicked(true);
		}
	};
	const handleClick = () => {
		setInlineEdit(true);
	};

	const handleChange = (e: any) => {
		e.preventDefault();
		const { target } = e;
		if (target.value.trim().length > 64) {
			return false;
		} else {
			setTextValue(target.value);
			setSaveClicked(true);
		}
	};

	return (
		<>
			<Box className='title-inline-field'>
				{!inlineEdit ? (
					<Box className='title-field title-field-datails' id='title-field'>
						<Typography variant='h4'>
							{parentName ? parentName : 'Please enter Parent Name'}
							<OKRButton
								handleClick={(e) => handleClick()}
								className='editIcon'
								icon={<EditIcon />}
								title={t('editTitleLabel')}
								tooltipPlacement={'top'}
							/>
						</Typography>
						{error && (
							<Typography className='search-error team-name-error'>
								<ErrorIcon />
								{error}
							</Typography>
						)}
					</Box>
				) : (
					<ClickAwayListener onClickAway={(e) => handleClickAway()}>
						<Box className='edit-text-field'>
							<TextField
								id='team-name-inline'
								value={textValue}
								autoFocus
								fullWidth
								inputRef={inputRef}
								onFocus={(e) => enterFocus(e)}
								placeholder={t('teamNameLabel')}
								onChange={(e) => {
									handleChange(e);
								}}
								//error={''}
								helperText={
									saveClicked && textValue?.trim() === '' ? (
										<>
											<ErrorIcon />
											{errorText}
										</>
									) : error ? (
										<>
											<ErrorIcon />
											{error}
										</>
									) : (
										''
									)
								}
							/>
							<Box className='edit-actions'>
								<OKRButton
									className='edit-cancel'
									id='edit-cancel'
									icon={<CrossIcon />}
									handleClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										if (textValue !== '' && parentName !== '') {
											setInlineEdit(false);
											setTextValue(parentName);
										} else {
											setSaveClicked(true);
										}
									}}
								/>
								<OKRButton
									className='edit-save'
									id='edit-save'
									icon={<TickThinIcon />}
									handleClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										if (textValue.trim() !== '') {
											updateValue(textValue);
											handleClickAway();
											setSaveClicked(true);
										}
									}}
								/>
							</Box>
						</Box>
					</ClickAwayListener>
				)}
			</Box>
		</>
	);
};
