import React, { Fragment, useState, useEffect } from 'react';
import { Page, Text, View, Image, Document, Font } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import OKR_LOGO from '../../../images/unlocku-new-logo.png';
import OKR_Profile_Pic from '../../../images/pdf-user.png';
import RobotoRegular from '../../../fonts/Roboto-Regular.ttf';
import RobotoMedium from '../../../fonts/Roboto-Medium.ttf';
import RobotoLight from '../../../fonts/Roboto-Light.ttf';
import RobotoBold from '../../../fonts/Roboto-Bold.ttf';
import {
	getMonthDateYearTime,
	getPriorityIcon,
	getPriorityName,
	getUserDetails,
	getUserName,
	formatNumberWithCommas,
} from '../../../config/utils';

import { styles } from './AssessmentDetailPdfStyle';
import Html from 'react-pdf-html';
import { Enums } from '../../../config/enums';

// Register Roboto font variants
Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: RobotoRegular,
			fontWeight: 'normal',
		},
		{
			src: RobotoMedium,
			fontWeight: 'medium',
		},
		{
			src: RobotoLight,
			fontStyle: 'italic',
		},
		{
			src: RobotoBold,
			fontWeight: 'bold',
		},
	],
});

// Register font
// Font.register({
// 	family: 'Roboto',
// 	fonts: [
// 		{
// 			src: RobotoRegular,
// 			fontWeight: 'normal',
// 		},
// 		{
// 			src: RobotoMedium,
// 			fontWeight: 'medium',
// 		},
// 		{
// 			src: RobotoLight,
// 			fontWeight: 'light',
// 		},
// 		{
// 			src: RobotoItalic,
// 			fontStyle: 'italic',
// 		},
// 		{
// 			src: RobotoBold,
// 			fontWeight: 'bold',
// 		},
// 	],
// });

const getSource = (imagePath, type) =>
	type === 1
		? {
				uri: imagePath,
				method: 'GET',
		  }
		: imagePath;
// const html = `
// <p>vdsdsdsffsdf</p><p>&nbsp;</p><p><strong>sasas</strong></p><p><i>asasasa</i></p><p><a target="_blank" rel="noopener noreferrer" href="http://SDSADSD">http://SDSADSD</a></p><ul style="list-style-type:circle;"><li>FDF</li><li>JKJHK</li></ul><p>&nbsp;</p><ol style="list-style-type:lower-latin;"><li>DFSD</li><li>LK;</li><li>&nbsp;</li></ol>
// 		`;
export function AssessmentDetailPdf(props) {
	const { data } = props;
	const { t } = useTranslation();
	const rowCount = data?.selfAssessment?.length;
	const rowOtherCount = data?.managerAssessment?.length;
	const rowOkrCount = data?.performanceOkr?.length;

	return (
		<Fragment>
			<Document>
				<Page size='A4' style={styles.pageFirst}>
					<View style={styles.pageFirst_wrap}>
						<View fixed style={styles.header_top}>
							<Image style={styles.unlockULogo} source={getSource(OKR_LOGO, 0)} />
							<View style={styles.performancePeriodDetail}>
								<Text style={[styles.performancePeriodTitle, styles.fontMedium]}>
									{data.performanceDetailsDb?.periodName}
								</Text>
								<View style={styles.performancePeriod}>
									<Text style={[styles.performancePeriodHead, styles.fontMedium]}>{'Performance Period: '}</Text>
									<Text style={styles.performancePeriodTime}>
										{getMonthDateYearTime(data.performanceDetailsDb?.startDate)} -{' '}
										{getMonthDateYearTime(data.performanceDetailsDb?.endDate)}
									</Text>
								</View>
							</View>
							<View style={styles.employeeRow}>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Employee Name'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{`${data.selfAssessment?.employeeDetailsResponse?.firstName} ${data.selfAssessment?.employeeDetailsResponse?.lastName}`}
									</Text>
								</View>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Email ID'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data.selfAssessment?.employeeDetailsResponse?.emailId
											? data.selfAssessment?.employeeDetailsResponse?.emailId
											: '--'}
									</Text>
								</View>
							</View>
							<View style={styles.employeeRow}>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Employee ID'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data.selfAssessment?.employeeDetailsResponse?.employeeCode
											? data.selfAssessment?.employeeDetailsResponse?.employeeCode
											: '--'}
									</Text>
								</View>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Joining Date'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data.selfAssessment?.employeeDetailsResponse?.joiningDate
											? getMonthDateYearTime(data.selfAssessment?.employeeDetailsResponse?.joiningDate)
											: '--'}
									</Text>
								</View>
							</View>
							<View style={styles.employeeRow}>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Designation'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data.selfAssessment?.employeeDetailsResponse?.designation
											? data.selfAssessment?.employeeDetailsResponse?.designation
											: '--'}
									</Text>
								</View>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Reporting Manager'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data.selfAssessment?.employeeDetailsResponse?.reportingManagerName
											? data.selfAssessment?.employeeDetailsResponse?.reportingManagerName
											: '--'}
									</Text>
								</View>
							</View>
							<View style={styles.employeeRow}>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Department'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data.selfAssessment?.employeeDetailsResponse?.department
											? data.selfAssessment?.employeeDetailsResponse?.department
											: '--'}
									</Text>
								</View>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Grade'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data.selfAssessment?.employeeDetailsResponse?.performanceRoleName
											? data.selfAssessment?.employeeDetailsResponse?.performanceRoleName
											: '--'}
									</Text>
								</View>
							</View>
						</View>
					</View>
					{/* Footer */}
					{/* <View style={styles.footer}>
						<View style={styles.footerLogo}>
							<Image style={styles.unlockUfooterLogo} source={getSource(OKR_LOGO, 0)} />
						</View>
						<View style={styles.footerText}>
							<Text style={styles.infoProLearn_text}>&copy; 2024 Infopro Learning</Text>
						</View>
					</View> */}
				</Page>
				<Page size='A4' style={styles.page}>
					<View style={styles.page_wrap}>
						<View style={styles.assessmentSection}>
							<View style={styles.assessmentSectionTitle}>
								<Text style={[styles.assessmentSectionTitleText, styles.fontMedium]}>Self Assessment</Text>
							</View>
							{data.selfAssessment?.performanceRoleLinkedFormSection?.length > 0 &&
							data.selfAssessment?.status !== 0 ? (
								<View style={styles.assessmentBlock}>
									{data.selfAssessment?.performanceRoleLinkedFormSection?.map((assessment, index) => (
										<>
											<View style={styles.assessmentBlockHead}>
												<Text style={styles.assessmentBlockHeadTitle}>{assessment?.name}</Text>
												<Text style={styles.assessmentBlockHeadDesc}>{assessment?.description}</Text>
											</View>
											{assessment?.performanceRoleLinkedFormDetails?.map((item, index) => (
												<View style={styles.assessmentQuesAnsBlock}>
													<View style={styles.assessmentQues}>
														<Text style={styles.question}>{item.questionsName}</Text>
													</View>
													<View style={styles.assessmentAns}>
														{item.questionsType !== 4 ? (
															<>
																<Text style={styles.assessmentAnsTitle}>Rating:</Text>
																<View style={styles.assessmentScaleSection} breakInside='avoid'>
																	{item?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails?.map(
																		(scaleItem) => (
																			<View style={styles.scaleDetails} breakInside='avoid'>
																				{scaleItem.isSelected ? (
																					<Text style={[styles.scaleTextSelected, styles.fontNormal]}>
																						{scaleItem.name}
																					</Text>
																				) : (
																					<Text style={[styles.scaleText, styles.fontNormal]}>{scaleItem.name}</Text>
																				)}
																				<Text style={styles.scaleTextSeparator}>|</Text>
																			</View>
																		)
																	)}
																</View>
															</>
														) : (
															<View style={styles.shortDescAnswer}>
																<Html stylesheet={[styles]}>{item.ratingValue.replace(/figure/g, 'div')}</Html>
															</View>
														)}
													</View>
													<View style={styles.assessmentCommentSection}>
														<Text style={styles.comments_title}>{'Comments'}</Text>
														{item.questionCommentBox ? (
															<Html stylesheet={styles}>{item.questionCommentBox.replace(/figure/g, 'div')}</Html>
														) : (
															<Text>--</Text>
														)}
													</View>
												</View>
											))}
										</>
									))}
								</View>
							) : (
								<View style={styles.assessmentBlock}>
									<View style={styles.noContentBlock}>
										<Text style={styles.noContentText}>Not yet submmited</Text>
									</View>
								</View>
							)}
						</View>
						<View style={styles.assessmentSection} break>
							<View style={styles.assessmentSectionTitle}>
								<Text style={[styles.assessmentSectionTitleText, styles.fontMedium]}>Manager Assessment</Text>
							</View>
							{data.managerAssessment?.performanceRoleLinkedFormSection?.length > 0 &&
							data.managerAssessment?.status !== 0 ? (
								<View style={styles.assessmentBlock}>
									{data.managerAssessment?.performanceRoleLinkedFormSection?.map((assessment, index) => (
										<>
											<View style={styles.assessmentBlockHead}>
												<Text style={styles.assessmentBlockHeadTitle}>{assessment?.name}</Text>
												<Text style={styles.assessmentBlockHeadDesc}>{assessment?.description}</Text>
											</View>
											{assessment?.performanceRoleLinkedFormDetails?.map((item, index) => (
												<View style={styles.assessmentQuesAnsBlock}>
													<View style={styles.assessmentQues}>
														<Text style={styles.question}>{item.questionsName}</Text>
													</View>
													<View style={styles.assessmentAns}>
														{item.questionsType !== 4 ? (
															<>
																<Text style={styles.assessmentAnsTitle}>Rating:</Text>
																<View style={styles.assessmentScaleSection} breakInside='avoid'>
																	{item?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails?.map(
																		(scaleItem) => (
																			<View style={styles.scaleDetails} breakInside='avoid'>
																				{scaleItem.isSelected ? (
																					<Text style={[styles.scaleTextSelected, styles.fontNormal]}>
																						{scaleItem.name}
																					</Text>
																				) : (
																					<Text style={[styles.scaleText, styles.fontNormal]}>{scaleItem.name}</Text>
																				)}
																				<Text style={styles.scaleTextSeparator}>|</Text>
																			</View>
																		)
																	)}
																</View>
															</>
														) : (
															<View style={styles.shortDescAnswer}>
																<Html stylesheet={[styles]}>{item.ratingValue.replace(/figure/g, 'div')}</Html>
															</View>
														)}
													</View>
													<View style={styles.assessmentCommentSection}>
														<Text style={styles.comments_title}>{'Comments'}</Text>
														{item.questionCommentBox ? (
															<Html stylesheet={styles}>{item.questionCommentBox.replace(/figure/g, 'div')}</Html>
														) : (
															<Text>--</Text>
														)}
													</View>
												</View>
											))}
										</>
									))}
								</View>
							) : (
								<View style={styles.assessmentBlock}>
									<View style={styles.noContentBlock}>
										<Text style={styles.noContentText}>Not yet submmited</Text>
									</View>
								</View>
							)}
						</View>
						<View style={styles.assessmentSection} break>
							<View style={styles.assessmentSectionTitle}>
								<Text style={[styles.assessmentSectionTitleText, styles.fontMedium]}>Final Assessment</Text>
							</View>
							{data.finalRatingScoreResponse && data.finalRatingScoreResponse?.isPerformanceCycleDone ? (
								<View style={styles.assessmentBlock}>
									<View style={styles.assessmentQuesAnsBlock}>
										{data.finalRatingScoreResponse?.finalScoreName ? (
											<View style={styles.assessmentQues}>
												<Text style={styles.final_rating_value}>{data.finalRatingScoreResponse.questionsName}</Text>
											</View>
										) : (
											<></>
										)}
										<View style={styles.assessmentAns}>
											{data.finalRatingScoreResponse?.finalScoreComment ? (
												<Html stylesheet={styles}>
													{data.finalRatingScoreResponse?.finalScoreComment.replace(/figure/g, 'div')}
												</Html>
											) : (
												<Text>--</Text>
											)}
										</View>
									</View>
								</View>
							) : (
								<View style={styles.assessmentBlock}>
									<View style={styles.noContentBlock}>
										<Text style={styles.noContentText}>Not yet submmited</Text>
									</View>
								</View>
							)}
						</View>
						<View style={styles.assessmentSection} break>
							<View style={styles.assessmentSectionTitle}>
								<Text style={[styles.assessmentSectionTitleText, styles.fontMedium]}>{'Objectives & Key Results'}</Text>
							</View>
							{data.cycleDetails?.length > 0 ? (
								data.cycleDetails?.map((cycleData, index) => (
									<>
										<View style={styles.quarterDetails}>
											<Text style={[styles.quarterTitle]}>{cycleData.cycle}</Text>
										</View>
										{cycleData.performanceOkrs?.length > 0 ? (
											cycleData.performanceOkrs?.map((item, index) => (
												<View style={styles.okrPanel}>
													<View style={styles.ObjectiveDetails}>
														<View style={styles.ObjectiveHead}>
															<Text style={[styles.ObjectiveTitle, styles.fontMedium]}>{item?.objectiveName}</Text>
															<View style={styles.ObjectiveProgress}>
																<Text style={[styles.ObjectivePercentage, styles.fontMedium]}>{item?.score}%</Text>
																<Text
																	style={{
																		fontFamily: 'Roboto',
																		fontWeight: 'medium',
																		fontSize: 8,
																		textTransform: 'uppercase',
																		color: item?.progressColurCode,
																	}}
																>
																	{item?.progress}
																</Text>
															</View>
														</View>
													</View>
													<View style={styles.table}>
														<View style={styles.tableRow} breakInside='avoid'>
															<View style={[styles.tableCol, styles.tableColfull]}></View>
															<View style={styles.tableCol}>
																<Text style={styles.tableHeader}>Start/Target</Text>
															</View>
															<View style={styles.tableCol}>
																<Text style={styles.tableHeader}>Actual</Text>
															</View>
														</View>
														{item.performanceKrs?.map((kr, index) => (
															<View style={styles.tableRow} breakInside='avoid'>
																<View style={[styles.tableCol, styles.tableColfull]}>
																	<Text style={styles.tableCell}>{kr.keyResult}</Text>
																	<View style={styles.tableKrDetails}>
																		<Text
																			style={{
																				fontFamily: 'Roboto',
																				fontWeight: 'medium',
																				fontSize: 8,
																				textTransform: 'uppercase',
																				color: kr?.progressColurCode,
																			}}
																		>
																			{kr?.progress}
																		</Text>
																		<Text style={styles.tableInnerCellSeparator}>|</Text>
																		<Text style={[styles.tableInnerCell, styles.tableInnerCellBold]}>Assigned By:</Text>
																		<Text style={styles.tableInnerCell}>{kr.assignedBy ? kr.assignedBy : '--'}</Text>
																	</View>
																</View>
																<View style={styles.tableCol}>
																	<Text style={[styles.tableCell, styles.tableCellCurrencyText]}>
																		{formatNumberWithCommas(kr.startValue)}
																	</Text>
																	<Text style={[styles.tableCell, styles.tableCellCurrencyText]}>
																		{formatNumberWithCommas(kr.target)}
																	</Text>
																</View>
																<View style={styles.tableCol}>
																	<Text
																		style={[
																			styles.tableCell,
																			styles.tableCellCurrencyText,
																			styles.ObjectivePercentage,
																			styles.fontMedium,
																		]}
																	>
																		{formatNumberWithCommas(kr.score)}
																	</Text>
																	<Text style={[styles.tableCell, styles.tableCellCurrencyText]}>
																		{formatNumberWithCommas(kr.actualValue)}
																	</Text>
																</View>
															</View>
														))}
													</View>
												</View>
											))
										) : (
											<></>
										)}
									</>
								))
							) : (
								<>
									<View style={styles.assessmentBlock}>
										<View style={styles.noContentBlock}>
											<Text style={styles.noContentText}>No OKRs created for the Assessment Period</Text>
										</View>
									</View>
								</>
							)}
						</View>
					</View>
					{/* Footer */}
					{/* <View style={styles.footer}>
						<View style={styles.footerInner}>
							<View style={styles.footerLogo}>
								<Image style={styles.unlockUfooterLogo} source={getSource(OKR_LOGO, 0)} />
							</View>
							<View style={styles.footerText}>
								<Text style={styles.infoProLearn_text}>&copy; 2024 Infopro Learning</Text>
							</View>
						</View>
					</View> */}
				</Page>
			</Document>
		</Fragment>
	);
}
