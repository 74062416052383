import { Box, List, ListItem, Typography, Avatar, Tooltip, Chip } from '@material-ui/core';
import * as React from 'react';
import { OKRButton } from './OKRButton';
import _ from 'lodash';
import { getMonthDate, getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { SortIcon } from '../../config/svg/CommonSvg';
import OKRFlag from '../../images/okrFlag.svg';
import '../../styles/pages/admin/users.scss';

export const VirtualAlignment: React.FC<any> = (props) => {
	const { virtualAlignmentList, t, okr, sortVirtualAlignmentList, sortingColumn, sortOrder } = props;

	return (
		<Box className='drawer-inner-content'>
			<Box className='drawer-content-panel'>
				<Box className='drawer-field-row'>
					<Box className='objectiveName'>
						<Box className='objectiveDetails'>
							<img src={OKRFlag} alt='Okr flag' />
							<Typography>{virtualAlignmentList.objectiveName}</Typography>
						</Box>
						<Box className='tags'>
							<OKRButton className='btn-gray-chip' text={virtualAlignmentList.dueCycle} />
							<OKRButton className='btn-gray-chip' text={virtualAlignmentList?.teamName} />
						</Box>
					</Box>

					<Box className='list-panel-wrap'>
						<Box className='okr-list-panel-head'>
							<List component='ul'>
								<ListItem className='user-col1'>
									<Typography
										variant='body2'
										className={sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										onClick={(e) => sortVirtualAlignmentList(e, 'firstName')}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('nameLabel')}</span> <SortIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='user-col2'>
									<Typography
										variant='body2'
										className={sortingColumn === 'objectiveName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										onClick={(e) => sortVirtualAlignmentList(e, 'objectiveName')}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('Objective')}</span> <SortIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='user-col3'>
									<Typography
										variant='body2'
										className={sortingColumn === 'dueDate' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										onClick={(e) => sortVirtualAlignmentList(e, 'dueDate')}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('timeLine')}</span> <SortIcon />
										</span>
									</Typography>
								</ListItem>
							</List>
						</Box>
						<Box className=''>
							{virtualAlignmentList?.virtualDetails?.length &&
								virtualAlignmentList.virtualDetails.map((item: any, index: number) => (
									<Box className='list-panel-content' id={`virtual-list ${index}`}>
										<List component='ul'>
											<ListItem className='user-col1'>
												<Box className='user-info'>
													<Box className='user-img'>
														{item.imagePath ? (
															<Avatar src={item.imagePath} />
														) : (
															<Avatar className='avatar-default'>
																{getUserName({
																	firstName: item.firstName || '',
																	lastName: item.lastName || '',
																	fullName: item.fullName || '',
																})}
															</Avatar>
														)}
													</Box>
													<Box className='user-info-details'>
														<Typography variant='h4'>
															{item.firstName} {item.lastName}
														</Typography>
														<Typography variant='subtitle2'>{item.designation}</Typography>

														<Box className='teams-chips'>
															<Tooltip title={item.teamName} arrow>
																<Chip
																	style={{
																		backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																		color: getRandomColor(item?.colorCode),
																	}}
																	label={item.teamName}
																/>
															</Tooltip>
														</Box>
													</Box>
												</Box>
											</ListItem>
											<ListItem className='user-col2'>{item.objectiveName}</ListItem>
											<ListItem className='user-col3'>
												<Box>
													<Box className='calender-date'>{getMonthDate(item.dueDate)}</Box>
													<Typography variant='h6'>{item.dueCycle ? `Cycle: ${item.dueCycle}` : ''}</Typography>
												</Box>
											</ListItem>
										</List>
									</Box>
								))}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
