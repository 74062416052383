import { deleteRequest, getRequest, postRequest, putRequest } from '../config/auth';
import {
	GET_ALL_MOM_REQUEST_LIST,
	GET_NOTES,
	GET_ONE_ON_ONE_REQUEST_LIST,
	GET_USER_NOTES,
	GET_USER_TASKS,
	ONE_ON_ONE_LAUNCH_DATE_REQUEST,
	ONE_ON_ONE_LAUNCH_REQUEST,
	ONE_ON_ONE_REQUEST,
	ONE_ON_ONE_TASKS,
	ONE_ON_ONE_TEAM_DETAILS,
	TASK_COMPLETED,
	ONE_ON_ONE_MEETING_NOTES,
} from '../config/api-url';

export const getOneOnOneListAPI = (data) => postRequest(`${GET_ONE_ON_ONE_REQUEST_LIST}`, data);
export const createOneOneRequestAPI = (data) => postRequest(`${ONE_ON_ONE_REQUEST}`, data);
export const updateOneOneRequestAPI = (data) => putRequest(`${ONE_ON_ONE_REQUEST}`, data);
export const deleteOneOneRequestAPI = (data) => deleteRequest(`${ONE_ON_ONE_REQUEST}?${data}`, data);
export const getOneOnOneTeamDetailsAPI = (data) => getRequest(`${ONE_ON_ONE_TEAM_DETAILS}`, data); // ?${data}
export const launchOneOnOneRequestAPI = (data) => postRequest(`${ONE_ON_ONE_LAUNCH_REQUEST}`, data);
export const launchOneOnOneDataAPI = (data) => getRequest(`${ONE_ON_ONE_LAUNCH_REQUEST}?${data}`, data);
export const launchDateOneOnOneRequestAPI = (data) => putRequest(`${ONE_ON_ONE_LAUNCH_DATE_REQUEST}?${data}`, data);

export const getNotesListAPI = (data) => getRequest(`${GET_USER_NOTES}?${data}`, data);
export const createNotesAPI = (data) => postRequest(`${GET_NOTES}`, data);
export const updateNotesAPI = (data) => putRequest(`${GET_NOTES}`, data);

export const getTasksListAPI = (data) => getRequest(`${GET_USER_TASKS}?${data}`, data);
export const createTaskAPI = (data) => postRequest(`${ONE_ON_ONE_TASKS}`, data);
export const updateTaskAPI = (data) => putRequest(`${ONE_ON_ONE_TASKS}`, data);
export const deleteTaskAPI = (data) => deleteRequest(`${ONE_ON_ONE_TASKS}?${data}`, data);
export const completedOneOneOneTaskAPI = (data) => putRequest(`${TASK_COMPLETED}`, data);
export const getAllMomDetailsAPI = (data) => postRequest(GET_ALL_MOM_REQUEST_LIST, data);
export const getDownloadMeetingNotesAPI = (data) => getRequest(`${ONE_ON_ONE_MEETING_NOTES}?${data}`, data);
