import React, { useState, useEffect } from 'react';
import { Enums } from '../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, MobileStepper, Typography } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { DownArrowIcon, LeftArrow, RightArrow } from '../../config/svg/ArrowSvg';
import { getMonthDateYear } from '../../config/utils';
import {
	getPerformanceGoalReviewCycle,
	getGoalAlertConversation,
	setGoalReviewCurrentCycle,
} from '../../action/adminSettings';

export const CycleTab = (props: any) => {
	const { fromReview } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { goalReviewCycle } = useSelector((state: any) => state.adminSettingReducer);
	const [activeStep, setActiveStep] = useState(
		goalReviewCycle && goalReviewCycle.length > 0 ? goalReviewCycle.findIndex((item: any) => item.status === 3) : {}
	);
	const [cycleData, setCycleData] = useState<any[]>([]);
	const [maxSteps, setMaxSteps] = useState<number>(0);

	useEffect(() => {
		if (cycleData && cycleData[activeStep]) {
			dispatch(setGoalReviewCurrentCycle(cycleData[activeStep]));
		}
	}, [activeStep]);

	useEffect(() => {
		dispatch(setGoalReviewCurrentCycle(null));
		setActiveStep(null);
		dispatch(getPerformanceGoalReviewCycle());
	}, []);

	useEffect(() => {
		if (goalReviewCycle && goalReviewCycle.length > 0) {
			let data: any[] = goalReviewCycle.filter((item: any) => item.status === 2 || item.status === 3);
			let currentCycle: number = data.findIndex((item: any) => item.status === 3);
			if (currentCycle === -1) {
				currentCycle = data.findIndex((item: any) => item.isLastCompletedCycle);
			}
			dispatch(setGoalReviewCurrentCycle(data[currentCycle]));
			setActiveStep(currentCycle);
			setCycleData(data);
			setMaxSteps(data.length);
		} else {
			dispatch(setGoalReviewCurrentCycle(null));
			setActiveStep(null);
			setCycleData([]);
			setMaxSteps(0);
		}
	}, [goalReviewCycle]);

	const handleNext = () => {
		setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
		dispatch(getGoalAlertConversation());
	};

	return (
		<>
			{cycleData && cycleData.length > 0 && (
				<Box className='page-subheader-right-inner'>
					<Box className='tenure-navigation'>
						<Box className={`tenure-stepper`}>
							<OKRButton
								title={`${
									props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
										? t('futureCycle')
										: props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID
										? t('pastCycle')
										: ''
								}`}
								text={
									cycleData[activeStep]
										? getMonthDateYear(cycleData[activeStep].startDate) +
										  ' - ' +
										  getMonthDateYear(cycleData[activeStep].endDate)
										: ''
								}
								className='tenure-stepper-text'
								handleClick={() => {}}
								id='tenure-btn'
							/>

							<MobileStepper
								steps={maxSteps}
								position='static'
								variant='progress'
								activeStep={activeStep}
								nextButton={
									<OKRButton
										title={`${t('nextCycle')}`}
										className='tenure-next'
										id='tenure-btn-next'
										handleClick={handleNext}
										disabled={activeStep === maxSteps - 1}
										icon={<RightArrow />}
									/>
								}
								backButton={
									<OKRButton
										title={`${t('previousCycle')}`}
										className='tenure-previous'
										id='tenure-btn-previous'
										handleClick={handleBack}
										disabled={activeStep === 0}
										icon={<LeftArrow />}
									/>
								}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};
