import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelectForm } from '../../../CommonComponent/MultiSelectForm';
import { getParentTeam } from '../../../../../action/organization';
import { useDispatch } from 'react-redux';
import { getPreviewFormData, goalCycleList, ratingFormList } from '../../../../../action/adminSettings';
import { AssessmentPopup } from '../../../../Common/AssessmentPopup';
import { MultiSelectGoalReview } from '../../../CommonComponent/MultiSelectGoalReview';

export const MultiSelectGoalReviewPopper: React.FC<any> = (props: any) => {
	const {
		setLoader,
		setFormObjectData,
		formObjectData,
		setToggleClick,
		setIsFormEdited,
		nonRatingQuestion,
		setNonRatingQuestion,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);
	const [reviewFormList, setReviewFormList] = useState<any>(null);
	const [totalUser, setTotalUser] = useState(0);
	const [pageIndex, setPageIndex] = useState<any>(0);
	const [selectedValue, setSelectedValue] = useState<any>([]);
	const [performanceRatings, setPerformanceRatings] = useState<any>([]);
	const [assessmentPreview, setAssessmentPreview] = useState<any>({ open: false, details: null });

	useEffect(() => {
		if (formObjectData?.performanceGoalCycleId) {
			setRatingFormData(formObjectData);
		}
	}, []);
	const getParentTeamCall = async (data: any) => {
		//setLoader(true);
		const response: any = await dispatch(
			goalCycleList({
				publishType: 1,
				finder: data.finder,
				pageIndex: data.pageIndex,
				sortingText: 'settingDurationEndDate',
				order: 'desc',
				pageSize: data.pageSize,
				/*finder: data.finder,
				pageIndex: 1,
				sortingText: 'createdOn',
				order: 'desc',
				pageSize: 100,*/
			})
		);

		return response;
		//setLoader(false);
	};
	const setRatingFormData = async (ratingForm: any) => {
		let selectedFormData = [
			{
				...ratingForm,
				id: ratingForm.performanceGoalCycleId,
				name: ratingForm.ratingFormName,
			},
		];
		setTeamFieldData('', selectedFormData, true);
		/*const response = await getParentTeamCall({ finder: '', pageIndex: 1, pageSize: 200 });
		if (response.data.status === 200) {
			let ratingFormData = response.data.entity.records.filter(
				(item: any) => item.ratingFormId === ratingForm.ratingFormId
			);
			if (ratingFormData.length > 0) {
				ratingFormData[0].ratingFormSectionResponse.map((item: any) => {
					const indexVal = ratingForm.ratingFormSectionResponse.findIndex(
						(rec: any) => rec?.ratingFormSectionId === item.ratingFormSectionId
					);
					if (indexVal > -1) {
						item.weightagePercentage =
							ratingForm.ratingFormSectionResponse[indexVal].weightagePercentage;
					}
					return item;
				});
				ratingFormData[0].id = ratingFormData[0].ratingFormId;
				setTeamFieldData('', ratingFormData, true);
			}
		}*/
	};
	const setTeamFieldData = (field: string, values: any, noToggle = false) => {
		if (values && values.length > 0) {
			let value = values[0];
			let formObjectDataCopy: any = {
				performanceGoalCycleId: 0,
				ratingFormName: '',
				ratingFormSectionResponse: [],
				performanceCycleId: formObjectData.performanceCycleId,
			};
			let nonRaitingQuestion: any[] = [];
			if (value.ratingFormSectionResponse) {
				value.ratingFormSectionResponse.forEach((element: any) => {
					if (element['isRatingQuestion'] !== undefined && !element.isRatingQuestion) {
						nonRaitingQuestion.push(element);
					} else {
						formObjectDataCopy.ratingFormSectionResponse.push({
							...element,
							weightagePercentage: element.weightagePercentage || 0,
						});
					}
				});
			} else if (value.formQuestionListRequest) {
				value.formQuestionListRequest.forEach((element: any) => {
					if (element['isRatingQuestion'] !== undefined && !element.isRatingQuestion) {
						nonRaitingQuestion.push(element);
					} else {
						formObjectDataCopy.ratingFormSectionResponse.push({
							...element,
							weightagePercentage: element.weightagePercentage || 0,
						});
					}
				});
			}
			if (!noToggle) {
				setNonRatingQuestion(nonRaitingQuestion);
			}
			formObjectDataCopy.performanceGoalCycleId = value.performanceGoalCycleId;
			formObjectDataCopy.ratingFormName = value.name;
			formObjectDataCopy.totalSectionsCount = value.totalSectionsCount;
			formObjectDataCopy.totalQuestionsCount = value.totalQuestionsCount;
			formObjectDataCopy.assigedUsersCount = value.assigedUsersCount;
			setFormObjectData(formObjectDataCopy);
			setSelectedValue(values);
		} else {
			setSelectedValue([]);
			setFormObjectData({ performanceGoalCycleId: 0, ratingFormName: '', ratingFormSectionResponse: [] });
		}
		if (!noToggle) {
			setToggleClick(false);
			setIsFormEdited(true);
		}
	};

	const handleCloseDialog = () => {
		setAssessmentPreview({ open: false, details: null });
	};

	const handlOpenDialog = async (details: any) => {
		const response: any = await dispatch(
			getPreviewFormData(`formId=${details.performanceGoalCycleId}&performanceCycleId=${0}`)
		);
		if (response.status === 200) {
			setPerformanceRatings(response.data.entity);
		}
		setAssessmentPreview({
			open: true,
			details: { ...details, formQuestionListRequest: details.ratingFormSectionResponse } || null,
		});
	};
	return (
		<>
			<MultiSelectGoalReview
				key={'parentId'}
				id='team-org-dropdown'
				selectedOptions={selectedValue}
				onSelectOption={(value: any) => {
					setTeamFieldData('parentId', value);
				}}
				fieldMap={{
					label: 'teamName',
					value: 'teamId',
					imagePath: 'logoImagePath',
					secondaryText: 'teamHeadName',
					count: 'employeeCount',
				}}
				placeHolder={t('selectGoalCycleLevel')}
				isMulti={true}
				isSingleSelection={true}
				getOptions={getParentTeamCall}
				closeMenuOnSelect={true}
				selectClassName={'select-search-dropdown select-search-icon goal-cycle-select-dropdown'}
				selectClassNamePrefix={'react-select'}
				error={''}
				isMenuOpen={setIsMenuOpen}
				handleIconClick={handlOpenDialog}
				hideLoadMore={true}
			/>
			{assessmentPreview && assessmentPreview?.open ? (
				<AssessmentPopup
					setAssessmentPopup={setAssessmentPreview}
					assessmentPopup={assessmentPreview}
					handleCloseDialog={handleCloseDialog}
					performanceCycleId={0}
					performanceCycle={{}}
					setLoader={() => {}}
					formAssessmentData={performanceRatings}
					selectedUserDetails={null}
					callingFrom={'AdminAssessmentView'}
				/>
			) : (
				<></>
			)}
		</>
	);
};
