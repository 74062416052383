import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Popper, Tooltip } from '@material-ui/core';

export const DescriptionTooltip: React.FC<any> = (props) => {
	const { name, description } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = useState(false);
	const divToolRef = useRef<any>(null);
	const [allowTooltip, setAllowTooltip] = useState(false);
	useEffect(() => {
		if (description) {
			const className = 'description-arrow 2';
			const elem = document.createElement('span');
			elem.className = className;
			elem.innerHTML = 'arrow';
			elem.onclick = function (event: any) {
				setAnchorEl(anchorEl ? null : event.currentTarget);
			};
			if (divToolRef?.current?.childElementCount === 0) {
				divToolRef?.current?.appendChild(elem);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [description, name]);
	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};
	return (
		<>
			{description && (
				<>
					<Tooltip title={'Description'} arrow>
						<span className='description-arrow 1' onClick={handleClick}></span>
					</Tooltip>

					<Popper
						className='role-description-popup'
						open={open}
						anchorEl={anchorEl}
						placement='bottom-end'
						modifiers={{
							preventOverflow: {
								enabled: false,
								boundariesElement: window,
							},
							applyStyle: {
								enabled: true,
								gpuAcceleration: false,
							},
						}}
					>
						<ClickAwayListener onClickAway={handleClose}>
							<Box className='textarea-expand' dangerouslySetInnerHTML={{ __html: description }}></Box>
						</ClickAwayListener>
					</Popper>
				</>
			)}
		</>
	);
};
