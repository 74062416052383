import React, { useState } from 'react';
import { Box, Checkbox, ClickAwayListener, List, ListItem, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { DeleteIcon, LinkIcon } from '../../../config/svg/CommonSvg';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import { CancelIcon, TickIcon } from '../../../config/svg/Action';
import { CalendarIcon, SortingIcon } from '../../../config/svg/CommonSvgIcon';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { getGlobalTaskSourceDetails, getMonthDateYear } from '../../../config/utils';
import NotesImg from '../../../images/no-found-notes.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { completedGlobalTask, deleteGlobalTask, getAllGlobalTask } from '../../../action/globalTask';
import { useDispatch } from 'react-redux';
import AlertDialog from '../../Common/Dialog';
import { useSnackbar } from 'notistack';
import { Enums } from '../../../config/enums';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';

export const GlobalTasksList: React.FC<any> = (props: any) => {
	const {
		isGroupBySourceSelected,
		globalTaskRequest,
		handleTaskUpdate,
		handleEditTaskOpen,
		handleEditTaskClose,
		handleTaskChange,
		handleTaskOnEnterEdit,
		formDetails,
		showLoadMore,
		fetchGlobalTask,
		pageIndex,
		currentSort,
		sortOrder,
		handleSortData,
		curCycleData,
		startDateValue,
		maxDateValue,
		handleDueDateChangeInList,
		handleResetButtonClick,
		loader,
		setLoader,
	} = props;

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, details: null });

	const handleDeleteTask = (e: any, task: any) => {
		setOpenDeleteDialog({ open: true, details: { task } });
	};

	const handleDialogCancelClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			const { task } = openDeleteDialog?.details || {};
			if (task?.taskId) {
				setOpenDeleteDialog({ open: false, details: null });
				handleConfirmDeleteTaskClick(e, task);
			}
		} else {
			setOpenDeleteDialog({ open: false, details: null });
		}
	};

	const handleConfirmDeleteTaskClick = async (e: any, taskDetails: any) => {
		try {
			setLoader(true);
			const requestParams = `taskId=${taskDetails?.taskId}`;
			const deleteTaskResponse: any = await dispatch(deleteGlobalTask(requestParams));
			if (Boolean(deleteTaskResponse) && deleteTaskResponse?.data?.status === 200) {
				dispatch(getAllGlobalTask(globalTaskRequest, false));
				// enqueueSnackbar(t('taskDeleted'), {
				// 	variant: 'success',
				// 	autoHideDuration: 3000,
				// });
				setLoader(false);
			} else {
				const responseAPI = deleteTaskResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				enqueueSnackbar(messages, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setLoader(false);
			}
			return true;
		} catch (error) {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setLoader(false);
			return true;
		}
	};

	const handleCompleteTask = async (e: any, task: any) => {
		try {
			const { checked } = e.target;
			setLoader(true);
			const postData = { taskId: task.taskId, isCompleted: checked };
			const taskCompleteResponse: any = await dispatch(completedGlobalTask(postData));
			if (Boolean(taskCompleteResponse) && taskCompleteResponse.data.status === 200) {
				dispatch(getAllGlobalTask(globalTaskRequest, false));
				// enqueueSnackbar(t('taskUpdate'), {
				// 	variant: 'success',
				// 	autoHideDuration: 3000,
				// });
				setLoader(false);
			} else {
				const responseAPI = taskCompleteResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				enqueueSnackbar(messages, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setLoader(false);
			}
		} catch (error) {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};

	return (
		<>
			{loader ? (
				<Box className={'space-loading'} display={'flex'} justifyContent={'center'}>
					{t('loading')}
				</Box>
			) : (
				<Box className={`global-tasks-list ${isGroupBySourceSelected ? 'global-tasks-by-sources' : ''}`}>
					{formDetails?.tasksDetails && formDetails?.tasksDetails.length ? (
						<>
							<Box className='okr-list-panel-head'>
								<List component='ul' disablePadding>
									<ListItem className='task-col1'>
										<Typography variant='body2' id='sort-tasks' className={`sort-title`}>
											<span className='sortable-label'>
												<span className='txt-wrap'>{t('tasksLabel')}</span>
											</span>
										</Typography>
									</ListItem>
									{!isGroupBySourceSelected && (
										<ListItem className='task-col2'>
											<Typography variant='body2' id='sort-source' className={`sort-title`}>
												<span className='sortable-label'>
													<span className='txt-wrap'>{t('sourceLable')}</span>
												</span>
											</Typography>
										</ListItem>
									)}
									<ListItem className='task-col21'>
										<Typography variant='body2' id='sort-source' className={`sort-title`}>
											<span className='sortable-label'>
												<span className='txt-wrap'>{t('createdDate')}</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='task-col3'>
										<Typography
											variant='body2'
											id='sort-duedate'
											className={currentSort === 'dueDate' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											onClick={() => handleSortData('dueDate')}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>
													{t('dueDateText')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
								</List>
							</Box>
							{formDetails?.tasksDetails.map((item: any, index: number) => (
								<Box className='global-tasks-list-content'>
									<List component='ul' disablePadding>
										<ListItem className='task-col1'>
											<Box className='task-name-box'>
												<Checkbox
													className='task-checkbox'
													icon={<BorderCheckboxIcon />}
													checkedIcon={<CheckedIcon />}
													aria-label='Task Checkbox'
													checked={item.isCompleted}
													onChange={(e: any) => handleCompleteTask(e, item)}
												/>
												<Box
													className={`task-name ${item.isEditMode ? 'edit-mode-on' : ''}`}
													onClick={(event) => {
														handleEditTaskOpen(event, index, formDetails?.tasksDetails);
													}}
												>
													{item.isEditMode ? (
														<Box className='edit-task-field'>
															<TextField
																ref={(ref) => ref && ref.focus()}
																rows={2}
																multiline
																disabled={item.isCompleted}
																value={item.taskName}
																autoFocus
																fullWidth
																onChange={(e) => handleTaskChange(e, index, formDetails?.tasksDetails)}
																onKeyDown={(e) => handleTaskOnEnterEdit(e, index, item, formDetails?.tasksDetails)}
																onFocus={(e) => {
																	e.currentTarget.setSelectionRange(
																		e.currentTarget.value.length,
																		e.currentTarget.value.length
																	);
																	e.currentTarget.scrollTop = e.currentTarget.scrollHeight;
																}}
															/>
															<Box className='edit-task-actions'>
																<OKRButton
																	className='edit-task-cancel'
																	icon={<CancelIcon />}
																	handleClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		handleEditTaskClose(e, index, item.taskId, formDetails?.tasksDetails);
																	}}
																/>
																<OKRButton
																	className='edit-task-save'
																	icon={<TickIcon />}
																	disabled={item.taskName.trim() ? false : true}
																	handleClick={(e: any) => {
																		e.preventDefault();
																		e.stopPropagation();
																		handleTaskUpdate(item, false, formDetails?.tasksDetails);
																	}}
																/>
															</Box>
														</Box>
													) : (
														<Box className={`task-after-edit ${item.isEditMode ? 'task-hover-active' : ''}`}>
															<Typography>
																<Tooltip arrow title={item.taskName}>
																	<span>{item.taskName}</span>
																</Tooltip>
															</Typography>
														</Box>
													)}
												</Box>
											</Box>
											<Box className='task-link-box'>
												{item.taskType === 1 ? (
													item.goalId > 0 && (
														<Box className='task-link'>
															<Box className='task-link-name'>
																<LinkIcon />
																{item.goalLinkDetails.goalName.length > 40 ? (
																	<Tooltip arrow title={item.goalLinkDetails.goalName}>
																		<span>{item.goalLinkDetails.goalName.substring(0, 40)}...</span>
																	</Tooltip>
																) : (
																	item.goalLinkDetails.goalName
																)}
															</Box>
														</Box>
													)
												) : item.taskType === 2 ? (
													item.goalId > 0 && (
														<Box className='task-link'>
															<Box className='task-link-name'>
																<LinkIcon />
																{item.checkInLinkDetails.goalName.length > 40 ? (
																	<Tooltip arrow title={item.checkInLinkDetails.goalName}>
																		<span>{item.checkInLinkDetails.goalName.substring(0, 40)}...</span>
																	</Tooltip>
																) : (
																	item.checkInLinkDetails.goalName
																)}
															</Box>
														</Box>
													)
												) : item.taskType === 3 ? (
													<Box className='task-link'>
														<Box className='task-1-on-1-list'>
															{item.requestLinkDetails.senderName.length > 10 ? (
																<Tooltip arrow title={item.requestLinkDetails.senderName}>
																	<span className='requested-by'>
																		{item.requestLinkDetails.senderName.substring(0, 10)}...
																	</span>
																</Tooltip>
															) : (
																<span className='requested-by'>{item.requestLinkDetails.senderName}</span>
															)}

															<span className='request-arrow arrow-left'>
																<BackArrow />
															</span>
															{item.requestLinkDetails.reciverName.length > 10 ? (
																<Tooltip arrow title={item.requestLinkDetails.reciverName}>
																	<span className='requested-to'>
																		{item.requestLinkDetails.reciverName.substring(0, 10)}...
																	</span>
																</Tooltip>
															) : (
																<span className='requested-to'>{item.requestLinkDetails.reciverName}</span>
															)}
															{item.requestLinkDetails.oneToOneTitel.length > 20 ? (
																<Tooltip arrow title={item.requestLinkDetails.oneToOneTitel}>
																	<span>{item.requestLinkDetails.oneToOneTitel.substring(0, 20)}...</span>
																</Tooltip>
															) : (
																<span>{item.requestLinkDetails.oneToOneTitel}</span>
															)}
														</Box>
														{item?.assignedTo !== item?.assignedBy && (
															<Box className='assigned-by-box'>
																<Typography variant='body2'>
																	<>
																		{t('assignedBy')}: {item?.assignedByFirstName} {item?.assignedByLastName}
																	</>
																</Typography>
															</Box>
														)}
													</Box>
												) : (
													<></>
												)}
											</Box>
										</ListItem>
										{!isGroupBySourceSelected && (
											<ListItem className='task-col2'>
												<Box className='task-source'>
													<Typography variant='h6'>
														<span className='source-name'>{getGlobalTaskSourceDetails(item.taskType)}</span>
													</Typography>
												</Box>
											</ListItem>
										)}
										<ListItem className='task-col21'>
											<Typography variant='h6' className='btn-link-type1'>
												{item.taskStartedDate ? getMonthDateYear(item.taskStartedDate) : ' --'}
											</Typography>
										</ListItem>
										<ListItem className='task-col3'>
											<Box className='set-timeline-area'>
												<Box className='calender-date'>
													<Box className='timeline-field'>
														<Box className='calendar-btn'>
															{!item.isCompleted && (item.taskType === Enums.FOUR || item.taskType === Enums.THREE) ? (
																<ReactDateRangePicker
																	type={'GlobalTask'}
																	calendarTitle={t('dueDateCalenderTitle')}
																	startDateValue={item?.dueDate || null}
																	selectedCycleDetails={curCycleData || {}}
																	minimumDate={startDateValue ? new Date(startDateValue) : new Date()}
																	maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
																	handleDateSelection={(range: any, start: any) => {
																		start && handleDueDateChangeInList(start, index, range, formDetails?.tasksDetails);
																	}}
																	isDateRangeTextFieldVisible={true}
																	isOnlyDueDateVisible={false}
																	allCycleDetails={[]}
																	isCycleVisible={false}
																	isEndOfMonthVisible={false}
																	numberOfCalendars={Enums.ONE}
																	selectionType={'single'}
																	selectedDateRange={item?.momentDueDate || null}
																	setSelectedDateRange={(range: any) => {
																		// console.log('setSelectedDateRange ===>', range);
																	}}
																	placeholderText={t('selectedDate')}
																	isDatePickerDisable={false}
																	isResetButtonVisible={true}
																	handleResetButtonClick={() => {
																		handleResetButtonClick(index, formDetails?.tasksDetails);
																	}}
																/>
															) : (
																<OKRButton
																	tooltipClassName='tooltip-layout9'
																	text={item.dueDate ? getMonthDateYear(item.dueDate) : ' --'}
																	className={'no-edit-date'}
																	title={
																		item.isCompleted && item.completeDate
																			? `${t('completedDate')}: ${getMonthDateYear(item.completeDate)}`
																			: ''
																	}
																/>
															)}
														</Box>
													</Box>
												</Box>
											</Box>
										</ListItem>
									</List>
									<OKRButton
										handleClick={(e: any) => {
											handleDeleteTask(e, item);
										}}
										className='delete-icon'
										icon={<DeleteIcon />}
									/>
								</Box>
							))}
						</>
					) : (
						<Box className='welcome-content'>
							<Box textAlign='center'>
								<AnimatedIcon
									className=''
									width='230'
									type='image/svg+xml'
									alt={t('noTaskHead')}
									imagePath={NotesImg}
								/>
								<Typography variant='h2'>{t('noTaskHead')}</Typography>
							</Box>
						</Box>
					)}

					{formDetails?.tasksDetails && formDetails?.tasksDetails.length && showLoadMore ? (
						<Box className='load-more-link'>
							<Typography
								onClick={(e) => {
									fetchGlobalTask(pageIndex || 0, 0);
								}}
								id='load-more'
								component={'span'}
							>
								{loader ? (
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								) : (
									t('Load More')
								)}
							</Typography>
						</Box>
					) : (
						<></>
					)}
				</Box>
			)}
			{openDeleteDialog && openDeleteDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('taskDeleteMessage')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog?.open}
				/>
			)}
		</>
	);
};
