import {
	getAssessmentTeamAPI,
	getEmployeeAssessmentAPI,
	GetMultipleAssessmentAPI,
	getPerformCycleAPI,
	saveRatingFeedbackAPI,
	getPerformAlertAPI,
	getFinalRatingMasterDataAPI,
	getEmployeesFinalRatingAPI,
	updateEmployeesFinalRatingAPI,
	multipleEmployeesManagerRatingAPI,
	getSnapshotDataAPI,
	saveSelfAssessmentAPI,
	isUserExludedAPI,
	getThreeSixtyAssessmentListAPI,
	getNomineeList,
	saveNomineeApi,
	deleteNomineeApi,
	validateNomineeApi,
	ManagerNomineeApi,
	validateIsNomineeDeletedAPI,
	getAssessmentVisibility,
	saveLikeGoal,
	saveCommentGoal,
	getCommentGoal
} from '../services/OkrService';
import {
	GET_ACCESS_TEAM_LIST_FAILED,
	GET_ACCESS_TEAM_LIST_PENDING,
	GET_ACCESS_TEAM_LIST_SUCCESS,
	GET_MULTIPLE_ASSESSMENT_FAILED,
	GET_MULTIPLE_ASSESSMENT_PENDING,
	GET_MULTIPLE_ASSESSMENT_SUCCESS,
	GET_EMPLOYEE_ASSESSMENT_PENDING,
	GET_EMPLOYEE_ASSESSMENT_SUCCESS,
	RESET_EMPLOYEE_ASSESSMENT,
	GET_EMPLOYEE_ASSESSMENT_FAILED,
	GET_PERFORMANCE_CYCLE_PENDING,
	GET_PERFORMANCE_CYCLE_SUCCESS,
	GET_PERFORMANCE_CYCLE_FAILED,
	GET_PERFORMANCE_ALERT_PENDING,
	GET_PERFORMANCE_ALERT_SUCCESS,
	GET_PERFORMANCE_ALERT_FAILED,
	RESET_TEAM_ASSESSMENT,
	SET_PERFORMANCE_CYCLE,
	GET_FINAL_RATING_MASTER_PENDING,
	GET_FINAL_RATING_MASTER_FAILED,
	GET_FINAL_RATING_MASTER_SUCCESS,
	GET_EMPLOYEES_FINAL_RATING,
	GET_EMPLOYEE_FINAL_RATING_FAILED,
	GET_EMPLOYEE_FINAL_RATING_SUCCESS,
	GET_EMPLOYEE_FINAL_RATING_PENDING,
	IS_DATA_LOADING,
	RESET_THREE_SIXTY_ASSESSMENT_DETAILS,
	GET_TEAM_USER_DATA_SUCCESS,
	UPDATE_ASSESSMENT_ALERT,
	FETCH_CONVERSATION_GOAL_FAILED,
	FETCH_CONVERSATION_GOAL_SUCCESS,
	FETCH_CONVERSATION_GOAL_PENDING,
	RESET_CONVERSATION_GOAL

} from './actionTypes';
import { showLoader } from './common';

const getAssessTeamListPending = () => ({
	type: GET_ACCESS_TEAM_LIST_PENDING,
});
const getAssessTeamListCompleted = (payload) => ({
	type: GET_ACCESS_TEAM_LIST_SUCCESS,
	payload,
});
const getAssessTeamListFailed = (error) => ({
	type: GET_ACCESS_TEAM_LIST_FAILED,
	payload: error,
});

export const getEmployeeAssesmentPending = () => ({
	type: GET_EMPLOYEE_ASSESSMENT_PENDING,
});

export const resetEmployeeAssesment = () => ({
	type: RESET_EMPLOYEE_ASSESSMENT,
});

const getEmployeeAssesmentCompleted = (payload) => ({
	type: GET_EMPLOYEE_ASSESSMENT_SUCCESS,
	payload,
});
const getEmployeeAssesmentFailed = (error) => ({
	type: GET_EMPLOYEE_ASSESSMENT_FAILED,
	payload: error,
});

const getPerfomanceCyclePending = () => ({
	type: GET_PERFORMANCE_CYCLE_PENDING,
});
const getPerfomanceCycleCompleted = (payload) => ({
	type: GET_PERFORMANCE_CYCLE_SUCCESS,
	payload,
});
const getPerfomanceCycleFailed = (error) => ({
	type: GET_PERFORMANCE_CYCLE_FAILED,
	payload: error,
});

const getPerfomanceAlertPending = () => ({
	type: GET_PERFORMANCE_ALERT_PENDING,
});
const getPerfomanceAlertCompleted = (payload) => ({
	type: GET_PERFORMANCE_ALERT_SUCCESS,
	payload,
});
const getPerfomanceAlertFailed = (error) => ({
	type: GET_PERFORMANCE_ALERT_FAILED,
	payload: error,
});

export const getTeamAssessmentList = (data) => {
	return (dispatch) => {
		dispatch(getAssessTeamListPending());
		return getAssessmentTeamAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(getAssessTeamListCompleted(response.data));
				} else {
					dispatch(getAssessTeamListFailed('error'));
				}
				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(getAssessTeamListFailed(error));
			});
	};
};

export const getEmployeeAssessment = (data) => {
	return () => {
		return getEmployeeAssessmentAPI(data);
	};
};

const GetMultipleAssessmentPending = () => ({
	type: GET_MULTIPLE_ASSESSMENT_PENDING,
});
const GetMultipleAssessmentCompleted = (payload) => ({
	type: GET_MULTIPLE_ASSESSMENT_SUCCESS,
	payload,
});
const GetMultipleAssessmentFailed = (error) => ({
	type: GET_MULTIPLE_ASSESSMENT_FAILED,
	payload: error,
});

export const getMultipleAssessment = (data) => {
	return (dispatch) => {
		dispatch(GetMultipleAssessmentPending());
		return GetMultipleAssessmentAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(GetMultipleAssessmentCompleted(response.data));
				} else {
					dispatch(GetMultipleAssessmentFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(GetMultipleAssessmentFailed(error));
			});
	};
};
export const getEmployeeAssessmentList = (data) => {
	return (dispatch) => {
		dispatch(getEmployeeAssesmentPending());
		return getEmployeeAssessmentAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(getEmployeeAssesmentCompleted(response.data));
				} else {
					dispatch(getEmployeeAssesmentFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getEmployeeAssesmentFailed(error));
			});
	};
};

export const getPerfomanceCycleList = () => {
	return (dispatch) => {
		dispatch(getPerfomanceCyclePending());
		return getPerformCycleAPI()
			.then((response) => {
				if (response.status === 200) {
					response.data.entityList = response.data.entityList.map((data) => {
						var d1 = new Date();
						d1.setHours(0, 0, 0, 0);
						var d2 = new Date(data.reviewDate);
						d2.setHours(23, 59, 59, 999);
						if (d2.getTime() < d1.getTime()) {
							data.isCycleCompleted = true;
						}
						return data;
					});
					dispatch(getPerfomanceCycleCompleted(response.data));
				} else {
					dispatch(getPerfomanceCycleFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getPerfomanceCycleFailed(error));
			});
	};
};

export const saveRatingFeedback = (data) => {
	return () => {
		return saveRatingFeedbackAPI(data);
	};
};

export const resetTeamAssessmentList = () => {
	return {
		type: RESET_TEAM_ASSESSMENT,
	};
};

export const getPerfomanceAlert = () => {
	return (dispatch) => {
		dispatch(getPerfomanceAlertPending());
		return getPerformAlertAPI()
			.then((response) => {
				if (response.status === 200) {
					dispatch(getPerfomanceAlertCompleted(response.data));
				} else {
					dispatch(getPerfomanceAlertFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getPerfomanceAlertFailed(error));
			});
	};
};

export const setPerformaceCurrentCycle = (payload) => ({
	type: SET_PERFORMANCE_CYCLE,
	payload,
});


const getFinalRatingMasterDataPending = () => ({
	type: GET_FINAL_RATING_MASTER_PENDING,
});
const getFinalRatingMasterDataCompleted = (payload) => ({
	type: GET_FINAL_RATING_MASTER_SUCCESS,
	payload,
});
const getFinalRatingMasterDataFailed = (error) => ({
	type: GET_FINAL_RATING_MASTER_FAILED,
	payload: error,
});

export const getFinalRatingMasterData = () => {
	return (dispatch) => {
		dispatch(getFinalRatingMasterDataPending());
		return getFinalRatingMasterDataAPI()
			.then((response) => {
				if (response.status === 200) {
					dispatch(getFinalRatingMasterDataCompleted(response.data));
				} else {
					dispatch(getFinalRatingMasterDataFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getFinalRatingMasterDataFailed(error));
			});
	};
};

const getEmployeeFinalRatingPending = () => ({
	type: GET_EMPLOYEE_FINAL_RATING_PENDING,
});
const getEmployeeFinalRatingCompleted = (payload) => ({
	type: GET_EMPLOYEE_FINAL_RATING_SUCCESS,
	payload,
});
const getEmployeeFinalRatingFailed = (error) => ({
	type: GET_EMPLOYEE_FINAL_RATING_FAILED,
	payload: error,
});

export const setTeamUserDataCompleted = (payload) => ({
	type: GET_TEAM_USER_DATA_SUCCESS,
	payload,
});
export const getEmployeesFinalRating = (data) => {
	return (dispatch) => {
		dispatch(getEmployeeFinalRatingPending());
		return getEmployeesFinalRatingAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(getEmployeeFinalRatingCompleted(response.data));
				} else {
					dispatch(getEmployeeFinalRatingFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getEmployeeFinalRatingFailed(error));
			});
	};
};

export const saveEmployeesFinalRating = (data) => {
	return () => {
		return updateEmployeesFinalRatingAPI(data);
	};
};

export const saveNomineeData = (data) => {
	return () => {
		return saveNomineeApi(data);
	};
};

export const saveCommentGoalData = (data) => {
	return () => {
		return saveCommentGoal(data);
	};
};
export const saveLikeGoalData = (data) => {
	return () => {
		return saveLikeGoal(data);
	};
};

export const getRepliesData = (data) => {
	return () => {
		return getCommentGoal(data);
	};
};
export const getGoalDataApi = (data) => {
	return (dispatch) => {
		dispatch(fetchConversationGoalPending());
		return getCommentGoal(data)
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					dispatch(fetchConversationGoalCompleted(response.data));
				} else {
					dispatch(fetchConversationGoalFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchConversationGoalFailed(error));
			});
	};
};
const fetchConversationGoalPending = () => ({
	type: FETCH_CONVERSATION_GOAL_PENDING,
});
export const fetchConversationGoalCompleted = (payload) => ({
	type: FETCH_CONVERSATION_GOAL_SUCCESS,
	payload,
});
const fetchConversationGoalFailed = (error) => ({
	type: FETCH_CONVERSATION_GOAL_FAILED,
	error,
});
export const resetConversationCount = (error) => ({
	type: FETCH_CONVERSATION_GOAL_PENDING,
	error,
});
export const deleteNomineeData = (data) => {
	return () => {
		return deleteNomineeApi(data);
	};
};

export const validateNominee = (data) => {
	return () => {
		return validateNomineeApi(data);
	};
};

export const ManagerNominee = (data) => {
	return () => {
		return ManagerNomineeApi(data);
	};
};

export const saveSelfAssessment = (data) => {
	return () => {
		return saveSelfAssessmentAPI(data);
	};
};

export const saveMultipleEmployeesRating = (data) => {
	return () => {
		return multipleEmployeesManagerRatingAPI(data);
	};
};

export const getSnapShotData = (data) => {
	return () => {
		return getSnapshotDataAPI(data);
	};
};

export const getIsUserExluded = (data) => {
	return () => {
		return isUserExludedAPI(data);
	};
};
export const getNomineeData = (data) => {
	return () => {
		return getNomineeList(data);
	};
};

export const isDataInProgress = (data) => ({
	type: IS_DATA_LOADING,
	data,
});

export const getPerfomanceCycleListForDownloadReports = () => {
	return () => {
		return getPerformCycleAPI();
	};
};

/**
 * Three Sixty Assessment Actions
 */
export const resetThreeSixtyAssessmentDetails = () => ({
	type: RESET_THREE_SIXTY_ASSESSMENT_DETAILS,
	payload: null,
});
export const getThreeSixtyAssessmentList = (payload) => {
	return () => {
		return getThreeSixtyAssessmentListAPI(payload);
	};
};
export const getMultipleAssessmentForThreeSixty = (data) => {
	return () => {
		return GetMultipleAssessmentAPI(data);
	};
};
export const validateIsNomineeDeleted = (data) => {
	return () => {
		return validateIsNomineeDeletedAPI(data);
	};
};

export const getAssessmentVisibilityApi = (payload) => {
	return () => {
		return getAssessmentVisibility(payload);
	};
};
export const updateAssessmentAlert = (payload) => ({
	type: UPDATE_ASSESSMENT_ALERT,
	payload,
});