import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FreeTrial } from '../../../components/FreeTrial';
import {
	freeTrialApiDetails,
	freeTrialApiEmailIdDetails,
	freeTrialApiDomainDetails,
	freeTrialApiPasswordDetails,
} from '../../../action/myGoal';

const mapStateToProps = (state) => {
	return {
		freeTrialData: state.okrReducer.freeTrialData,
		freeTrialStatus: state.okrReducer.freeTrialStatus,
		freeTrialEmailIdData: state.okrReducer.freeTrialEmailIdData,
		freeTrialEmailIdStatus: state.okrReducer.freeTrialEmailIdStatus,
		freeTrialDomainData: state.okrReducer.freeTrialDomainData,
		freeTrialDomainStatus: state.okrReducer.freeTrialDomainStatus,
		freeTrialPasswordData: state.okrReducer.freeTrialPasswordData,
		freeTrialPasswordStatus: state.okrReducer.freeTrialPasswordStatus,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			freeTrialApiDetails,
			freeTrialApiEmailIdDetails,
			freeTrialApiDomainDetails,
			freeTrialApiPasswordDetails,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FreeTrial));
