import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { HappyEmoji, NotSetEmoji, NuetralEmoji, SadEmoji } from '../../config/svg/CommonSvg';
// import '../../styles/components/_weeklyTrends.scss';

const WeeklyTrend = (props) => {
	const { categories, chartData } = props; // chartColors
	// const { t } = useTranslation();

	const columnWithGroupedChartData = {
		chart: {
			// type: 'spline',
			zoomType: 'x',
			panning: true,
			panKey: 'shift',
			scrollablePlotArea: {
				minWidth: 400,
			},
		},
		title: {
			text: '',
		},
		subtitle: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		xAxis: {
			title: {
				text: null,
			},
			tickWidth: 0,
			gridLineWidth: 0,
			labels: {
				useHTML: true,
				align: 'center',
				formatter: (detail) => {
					return categories[detail?.value];
				},
				style: {
					color: '#416390',
					fontSize: '10px',
					fontWeight: '700',
					textTransform: 'uppercase',
				},
			},
		},
		yAxis: [
			{
				title: {
					text: 'Progress',
					style: {
						color: '#416390',
						fontSize: '10px',
						fontWeight: '700',
						textTransform: 'uppercase',
					},
				},
				labels: {
					useHTML: true,
					format: '{value} %',
					style: {
						color: '#416390',
						fontSize: '10px',
						fontWeight: '700',
						textTransform: 'uppercase',
					},
				},
				min: 0,
				max: 100,
				minTickInterval: 20,
				tickInterval: 20,
			},
			{
				opposite: true,
				title: {
					text: null,
				},
				labels: {
					useHTML: true,
					formatter: (detail) => {
						return;
					},
				},
				min: 0,
				max: 100,
				minTickInterval: 20,
				tickInterval: 20,
			},
		],
		tooltip: {
			useHTML: true,
			backgroundColor: 'rgba(41,41,41,1)',
			borderColor: 'none',
			boxShadow: 'none',
			padding: 0,
			style: {
				color: '#fff',
				fontWeight: 'bold',
				fontFamily: 'Lato',
				fontSize: '12px',
				zIndex: '1',
			},
		},
		colors: [],
		series: chartData,
		plotOptions: {
			series: {
				marker: {
					enabled: true,
				},
				stickyTracking: false,
			},
		},
		legend: {
			itemStyle: {
				color: '#292929',
				fontWeight: 'normal',
				textOverflow: 'undefined',
				width: '120px',
				fontFamily: 'Lato',
				fontSize: '14px',
			},
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 400,
					},
					chartOptions: {
						plotOptions: {
							series: {
								marker: {
									radius: 2.5,
								},
							},
							pointStart: 0,
						},
						legend: {
							layout: 'vertical',
							align: 'center',
							verticalAlign: 'bottom',
						},
					},
				},
			],
		},
	};

	return (
		<Box className='weekly-trends-chart-bottom'>
			<Box>
				<HighchartsReact highcharts={Highcharts} options={columnWithGroupedChartData} />
			</Box>
			<Box className='confidence-chart-emoticons'>
				<HappyEmoji />
				<NuetralEmoji />
				<SadEmoji />
				<NotSetEmoji />
			</Box>
		</Box>
	);
};

export default WeeklyTrend;
