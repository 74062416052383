import {
	aiAuthorLoginAPI,
	aiBotOKRDataReaderAPI,

} from '../services/aiBot';

export const aiBotOKRDataReader = (payload) => {
	return () => {
		return aiBotOKRDataReaderAPI(payload);
	};
};

export const aiAuthorLogin = (payload) => {
	return () => {
		return aiAuthorLoginAPI(payload);
	};
};

