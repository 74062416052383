import React, { Fragment, useState } from 'react';
import { Box, Chip, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import {
	DragIndicator,
	InfoIcon,
	LinkIcon,
	ContributorIcon,
	AlignedAsContributor,
} from '../../../config/svg/CommonSvg';
import { OKRButton } from '../../Common/OKRButton';
import { OkrFieldActions } from '../OkrFieldAction';
import {
	formatDate,
	getMinDate,
	getMonthDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { OkrProgressBar } from '../../OkrAction/OkrProgressBar';
import { Enums } from '../../../config/enums';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import { OkrPopperMenu } from '../../OkrAction/OkrPopperMenu';
import { OkrTextEdit } from './OkrTextEdit';
import { ListTooltip } from '../../OkrAction/ListTooltip';
import { getLocalStorageItem } from '../../../services/StorageService';
import DeltaKrPopper from '../../Common/DeltaKrPopper';
import DatePicker from '../../Common/DatePicker';
import AlertDialog from '../../Common/Dialog';
import { useSnackbar } from 'notistack';

export const StandaloneKrList: React.FC<any> = (props) => {
	const {
		provided,
		showActionButton,
		getItemStyle,
		snapshot,
		okr,
		setOpenDrawer,
		handleListAction,
		showGoalAndKrAttribute,
		showEditLoader,
		showAddToObjective,
		handleDeleteOkr,
		updateTextValue,
		handleClickInputAway,
		updateName,
		isLocked,
		t,
		updateProgress,
		handleAddUserClick,
		currentUser,
		handleBecomeAContributorClick,
		okrMasterData,
		highlightGoalId,
		currentCycleEndDate,
	} = props;

	const userDetail: any = getUserDetails();
	const { enqueueSnackbar } = useSnackbar();
	const [modalPropsOKR, setModalPropsOKR] = useState<any>({ open: false, type: '', message: '' });
	const handleAcceptDeclineClick = (open: Boolean, okr: any, kr: any, type: String, KRType: String) => {
		setOpenDrawer({ open, okr, kr, type, KRType });
	};

	const handleCloseModal = async (event: any, type: Number) => {
		if (type === 1) {
			if (modalPropsOKR.type === 'updateDateForOKR') {
				setModalPropsOKR({ open: false, type: '', message: '', module: '', data: {} });
				updateDashboardDueDate(modalPropsOKR.data.date, modalPropsOKR.data.okr);
			}
		}
		setModalPropsOKR({ open: false, type: '', message: '', module: '' });
	};

	const updateDashboardDueDate = async (dueDate: any, okr: any) => {
		const data = {
			goalType: 2,
			goalId: okr.goalKeyId,
			endDate: formatDate(dueDate),
			startDate: formatDate(okr.startDate),
		};
		const response = await props.updateObjetciveDueDate(data);
		if (response && response.data && response.data.status === 200) {
			//fetch OKR
			props.fetchOkr();
		} else if (response && response.data && response.data.status === 400) {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
		}
	};

	const getClass = (goalKeyId: any) => {
		if (getLocalStorageItem('goalType') && getLocalStorageItem('goalType') === '2' && goalKeyId === highlightGoalId) {
			return 'my-obj-listing newly-highlighted-kr';
		} else {
			return 'my-obj-listing';
		}
	};

	return (
		<Box
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
			className={getClass(okr.goalKeyId)}
			key={`okr${okr.goalKeyId}`}
			id={`myGoal${okr.goalKeyId}`}
			onClick={(e) => showActionButton(e, okr)}
			style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
		>
			<Box className='my-obj-listing-content kr-listing-content'>
				<Box className='my-obj-listing-inner'>
					<DragIndicator />
					<List component='ul'>
						<ListItem className='okr-col1 okr-drag-wrap'>
							<Box
								className='okr-objective'
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								{!okr.edit ? (
									<Box className='okr-after-edit'>
										<ListTooltip objName={okr?.keyDescription || ''} description={okr?.keyNotes || ''} />
									</Box>
								) : (
									<Fragment>
										<OkrTextEdit
											updateName={updateName}
											updateTextValue={updateTextValue}
											handleClickInputAway={handleClickInputAway}
											okr={okr}
											goalType={3}
											showEditLoader={showEditLoader}
										/>
									</Fragment>
								)}
							</Box>
							<Box className='okr-keys card-toggle'>
								<OKRButton className='btn-gray-chip' icon={<LinkIcon />} text={'Key Result'} />
								{(okr.teamName && okr.teamName.length > 0) ||
								(okr.parentTeamDetail && okr.parentTeamDetail.teamName) ? (
									<Box className='teams-chips'>
										{okr.teamName && okr.teamName.length > 0 ? (
											<Tooltip title={okr.teamName} arrow>
												<Chip
													style={{
														backgroundColor: getRandomBackgroundColor(okr?.backGroundColorCode),
														color: getRandomColor(okr?.colorCode),
													}}
													//label={okr.teamName}
													label={getUserName({
														firstName: '',
														lastName: '',
														fullName: okr.teamName || '',
													})}
												/>
											</Tooltip>
										) : (
											<></>
										)}
										{okr.parentTeamDetail && okr.parentTeamDetail.teamName ? (
											<Tooltip title={okr.parentTeamDetail.teamName} arrow>
												<Chip
													style={{
														backgroundColor: getRandomBackgroundColor(okr.parentTeamDetail.backGroundColorCode),
														color: getRandomColor(okr.parentTeamDetail.colorCode),
													}}
													icon={
														<svg
															width='11'
															height='11'
															viewBox='0 0 11 11'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<path
																d='M6.71854 6.18852L4.52411 3.9941C4.35506 3.82488 4.08076 3.82488 3.91168 3.9941C3.74249 4.16315 3.74249 4.43745 3.91168 4.60652L6.10611 6.80094C6.19072 6.88555 6.30144 6.92784 6.41233 6.92784C6.52321 6.92784 6.63393 6.88555 6.71854 6.80094C6.88773 6.63187 6.88773 6.35757 6.71854 6.18852Z'
																fill={getRandomColor(okr.parentTeamDetail.colorCode)}
																stroke={getRandomColor(okr.parentTeamDetail.colorCode)}
																strokeWidth='0.5'
															/>
															<path
																d='M9.30956 5.10748L8.50042 4.2982C8.33967 4.13758 8.07932 4.13758 7.91856 4.2982C7.75779 4.45895 7.75779 4.71958 7.91856 4.8802L8.72769 5.68948C9.58252 6.54444 9.5817 7.94559 8.72769 8.81111C7.873 9.6658 6.47184 9.66512 5.60605 8.81111L4.79676 8.00198C4.63614 7.84123 4.37552 7.84123 4.21475 8.00198C4.05398 8.16275 4.05398 8.42321 4.21475 8.58385L5.02595 9.39504C5.61947 9.9806 6.39611 10.2736 7.1711 10.2736C7.94773 10.2736 8.72301 9.97936 9.31158 9.39106C10.4841 8.20251 10.4833 6.28108 9.30956 5.10748Z'
																fill={getRandomColor(okr.parentTeamDetail.colorCode)}
																stroke={getRandomColor(okr.parentTeamDetail.colorCode)}
																strokeWidth='0.5'
															/>
															<path
																d='M6.41524 2.2132L5.60405 1.40185C4.41579 0.229494 2.49423 0.230447 1.31857 1.40583C0.146083 2.59424 0.147035 4.51566 1.32063 5.68925L2.12992 6.49854C2.21017 6.57892 2.3155 6.6191 2.42085 6.6191C2.52605 6.6191 2.6314 6.57892 2.71178 6.4984C2.87253 6.33779 2.87253 6.07717 2.71178 5.91654L1.9025 5.10725C1.04768 4.25243 1.04849 2.85128 1.9025 1.98576C2.75745 1.13107 4.1586 1.13191 5.02413 1.98576L5.83341 2.79504C5.99418 2.95581 6.25451 2.95581 6.41528 2.79504C6.57601 2.63443 6.57601 2.37381 6.41524 2.2132Z'
																fill={getRandomColor(okr.parentTeamDetail.colorCode)}
																stroke={getRandomColor(okr.parentTeamDetail.colorCode)}
																strokeWidth='0.5'
															/>
														</svg>
													}
													//label={okr.parentTeamDetail.teamName}
													//title={okr.parentTeamDetail.teamName}
													label={getUserName({
														firstName: '',
														lastName: '',
														fullName: okr.parentTeamDetail.teamName || '',
													})}
												/>
											</Tooltip>
										) : (
											<></>
										)}
									</Box>
								) : (
									<></>
								)}
								{currentUser && !okr.isContributor && !isLocked ? (
									<Fragment>
										<OKRButton
											className='alignment-btn'
											icon={<ContributorIcon />}
											title={t('becomeAContributor')}
											//text={t('becomeAContributor')}
											handleClick={(event: any) => handleBecomeAContributorClick(event, null, okr)}
										/>
									</Fragment>
								) : currentUser && okr.isContributor ? (
									<Fragment>
										<OKRButton
											className='alignment-btn'
											icon={<AlignedAsContributor />}
											title={t('alignedAsContributor')}
											//text={t('alignedAsContributor')}
										/>
									</Fragment>
								) : (
									''
								)}
								{!isLocked &&
									!currentUser &&
									okr?.goalStatusId !== Enums.THREE &&
									okr.teamId === Enums.ZERO &&
									okr?.employeeId === userDetail?.employeeId && (
										<OkrFieldActions
											handleListAction={handleListAction}
											showGoalAndKrAttribute={showGoalAndKrAttribute}
											showEditLoader={showEditLoader}
											okr={okr}
											type={3}
											showAddToObjective={showAddToObjective}
											{...props}
										/>
									)}
							</Box>
						</ListItem>
						<ListItem className='okr-col2'>
							<UserAvatarGroup
								{...props}
								contributorDetails={
									okr.contributors && okr.contributors.length
										? okr.contributors.filter((item: any) => !item.isSource)
										: []
								}
								addButtonClassName='add-btn'
								avatarClassName=''
								isButtonVisible={!isLocked}
								handleAddUserClick={(event: any) => handleAddUserClick(event, null, okr)}
								max={3}
								loginUserAvatar={
									okr.contributors && okr.contributors.length
										? okr.contributors.find((item: any) => item.isSource)
											? okr.contributors.find((item: any) => item.isSource)
											: okr
										: okr
								}
								currentUser={currentUser}
								contributorDetailsType={true}
							/>
						</ListItem>
						<ListItem className='okr-col3'>
							<Box className='date-settings'>
								<Box className='calender-date'>
									{!isLocked &&
									!currentUser &&
									okr?.goalStatusId !== Enums.THREE &&
									okr.teamId === Enums.ZERO &&
									okr?.employeeId === userDetail?.employeeId ? (
										<DatePicker
											currentCycleEndDate={currentCycleEndDate}
											durationCycleDetail={props.durationCycleDetail}
											value={okr.dueDate}
											handleDateChange={(date: any) => {
												if (okr?.contributors?.length > 1) {
													setModalPropsOKR({
														open: true,
														type: 'updateDateForOKR',
														message: t('updateKRAttribute'),
														module: 'user',
														data: { date: date, okr: okr },
													});
												}
											}}
											name={''}
											showLabel={false}
											minDate={getMinDate(
												okr?.importedId > 0 && okr.parentStartDate ? okr.parentStartDate : okr?.startDate
											)}
											maxDate={
												okr?.importedId > 0 && okr.parentDueDate ? okr.parentDueDate : new Date(currentCycleEndDate)
											}
											error={''}
											helperText={''}
											format='MMM d'
											handleKeyboardButtonClick={() => {}}
											disableToolbar={false}
										/>
									) : (
										getMonthDate(okr.dueDate)
									)}
								</Box>
								<Typography variant='h6'>{okr.dueCycle ? `${t('cycle')}: ${okr.dueCycle}` : ''}</Typography>
							</Box>
						</ListItem>
						<ListItem className='okr-col4'>
							{okr.hasRecent === true &&
								!isLocked &&
								!currentUser &&
								okr.contributors.length > 0 &&
								(okr?.owner === userDetail?.employeeId ||
									(okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT &&
										okr.teamId !== Enums.ZERO &&
										okr?.createdBy === userDetail?.employeeId) ||
									(okr.goalTypeId !== 1 && okr.myGoalsDetails)) && (
									<DeltaKrPopper {...props} recentContribution={okr.recentContributorData} kr={okr} />
								)}
							<OkrProgressBar
								parentOkr={[]}
								okr={okr}
								type={'kr'}
								t={t}
								updateProgress={updateProgress}
								showEditLoader={showEditLoader}
								currentUser={currentUser}
								okrMasterData={okrMasterData}
								isScoreLocked={props.listOkrResult.isScoreLocked}
								isAnyDrawerOpened={props.isAnyDrawerOpened}
								appDrawerInfo={props.appDrawerInfo}
							/>
							{!isLocked && (
								<OkrPopperMenu
									okr={okr}
									type={3}
									t={t}
									handleDeleteOkr={handleDeleteOkr}
									KRType={'Standalone'}
									handleAcceptDeclineClick={handleAcceptDeclineClick}
									{...props}
									currentUser={currentUser}
								/>
							)}
						</ListItem>
					</List>
				</Box>
			</Box>
			{modalPropsOKR.open && (
				<AlertDialog
					module={modalPropsOKR.module ? modalPropsOKR.module : 'user'}
					message={modalPropsOKR.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalPropsOKR.open}
				/>
			)}
		</Box>
	);
};
