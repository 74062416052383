import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddText } from '../AddText';

export const EditNotes: React.FC<any> = (props) => {
	const { description, createNote, setEditedNote, editedNote, setIsTaskEdited, setUnsavedCfrProps } = props;
	const [showEditEditor, setShowEditEditor] = useState(true);
	const [editedNotesContent, setEditedNotesContent] = React.useState<string>('');
	const [editedImageList, setEditedImageList] = useState<any[]>([]);
	const [disableEditBtn, setDisableEditBtn] = useState<boolean>(false);
	const { t } = useTranslation();

	React.useEffect(() => {
		setEditedNotesContent(description);
		setShowEditEditor(true);
		setEditedImageList(editedNote.noteFiles);
	}, [description]);

	React.useEffect(() => {
		if (showEditEditor) {
			setIsTaskEdited(true);
		} else {
			setIsTaskEdited(false);
		}
	}, [showEditEditor]);
	return (
		<>
			<AddText
				{...props}
				setId={'notes-tab-edit'}
				tabSelected={3}
				showEditor={showEditEditor}
				setShowEditor={(val: any) => {
					setIsTaskEdited(val);
					setShowEditEditor(val);
				}}
				showAvatar={false}
				placeholderText={t('notesPlaceholdarText')}
				setEditorText={setEditedNotesContent}
				editorText={editedNotesContent}
				handleSave={(e: any) => {
					createNote(editedNotesContent, editedImageList);
				}}
				handleCancel={() => {
					setEditedNotesContent('');
					setEditedNote({});
				}}
				errorMessage={t('notesRequired')}
				showHelpText={true}
				setImageList={setEditedImageList}
				imageList={editedImageList}
				isEditMode={true}
				disableBtn={disableEditBtn}
				setDisableBtn={setDisableEditBtn}
				setUnsavedCfrProps={setUnsavedCfrProps}
			/>
		</>
	);
};
