import React, { Fragment, useState, useEffect } from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import '../../styles/pages/error-pages/errors.scss';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { getUserDetails } from '../../config/utils';
import { useHistory } from 'react-router-dom';
import { DASHBOARD, HOME, ORGANIZATIONS } from '../../config/app-url';

// interface Window {
// 	ATL_JQ_PAGE_PROPS?: any;
// }
declare global {
	interface Window {
		ATL_JQ_PAGE_PROPS?: any;
	}
}

export const InternalServerError: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const userDetail: any = getUserDetails();
	const reloadPage = () => {
		removeLocalStorageItem('currentUser');
		if (getLocalStorageItem('LoginAs') === 'user') {
			// history.push(HOME);
			history.push(DASHBOARD);
		} else {
			history.push(ORGANIZATIONS);
		}
	};
	const openJiraIssueCollector = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		handleToggle(2);

		(document.getElementById('atlwdg-trigger') as any).click();
	};

	useEffect(() => {
		const script = document.createElement('script');
		const script2 = document.createElement('script');

		script.type = 'text/javascript';
		script.src =
			'https://servicedesk.compunnel.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN/8bkccv/814001/1b2186679a631144916156ab6aec18b5/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=7dfe95aa';
		script.async = true;
		script2.type = 'text/javascript';
		script2.src =
			'https://servicedesk.compunnel.com/s/ba53967f66ec0096b89bb4fd64ec1120-T/8bkccv/814001/1b2186679a631144916156ab6aec18b5/4.0.3/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=7dfe95aa';
		script2.async = true;

		document.head.append(script);
		document.head.append(script2);

		window.ATL_JQ_PAGE_PROPS = {
			triggerFunction: function (showCollectorDialog: any) {
				showCollectorDialog();
			},
			fieldValues: {
				fullname: (userDetail && userDetail.firstName) + ' ' + (userDetail && userDetail.lastName),
				email: userDetail && userDetail.emailId,
				customfield_11308: window.location.hostname,
			},
		};
	}, []);

	const handleToggle = (flag: any) => {
		if (flag === 1) {
			setOpen((prevOpen) => !prevOpen);
		} else {
			setOpen(false);
		}
	};
	return (
		<Fragment>
			<Box className='internal-server-error-area'>
				<Box className='inner-area-wrapper'>
					<Box className='inner'>
						<Typography variant='h1'>{t('InternalServerErrorMsg')}</Typography>
						<Typography>{t('internalServer500')}</Typography>
						<Box className='btn-group'>
							<Link onClick={(e: any) => openJiraIssueCollector(e)}>Give us Feedback</Link>
							<OKRButton className='btn-primary' text={t('ReloadBtn')} handleClick={reloadPage} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Fragment>
	);
};
