import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Switch, Tab, Tabs } from '@material-ui/core';
import { JobLevelIcon, NameIcon } from '../../../../../../config/svg/formElementIcons';
import { DesignationIcon } from '../../../../../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';

export const DefaultObjTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`default-obj-${index}`}
			aria-labelledby={`default-obj-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
};

DefaultObjTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `default-obj-tabpanel-${index}`,
		'aria-controls': `default-obj-tabpanel-${index}`,
	};
}

export const DefaultObjTab: React.FC<any> = (props) => {
	const { handleRoleTabChange = {}, selectedRoleTab = 0, allEmployeeChecked, handleAllEmployeeSelection } = props;
	const { t } = useTranslation();
	return (
		<>
			<Tabs
				className='okr-tab-button okr-tab-white-button'
				value={selectedRoleTab}
				onChange={handleRoleTabChange}
				aria-label='Default OKR Tabs'
			>
				<Tab
					icon={<DesignationIcon />}
					label={t('designationLabel')}
					value={0}
					{...a11yProps(0)}
					disabled={allEmployeeChecked}
				/>
				<Tab icon={<JobLevelIcon />} label={t('jobLevels')} value={1} {...a11yProps(1)} disabled={allEmployeeChecked} />
				<Tab icon={<NameIcon />} label={t('RoleListUsers')} value={2} {...a11yProps(2)} disabled={allEmployeeChecked} />
			</Tabs>
			<Box className='so-setting-switch switch-button'>
				<FormControlLabel
					control={<Switch name='isAllEmployees' checked={allEmployeeChecked} onChange={handleAllEmployeeSelection} />}
					label={t('assignAllEmployees')}
					labelPlacement='end'
					className='toggle-label'
				/>
			</Box>
		</>
	);
};
