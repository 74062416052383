import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import noRecordFound from '../../images/no-reports-img.svg';
import { useHistory } from 'react-router-dom';
const NoReports = (props: any) => {
	const { t } = props;
	const history = useHistory();

	useEffect(() => {
		document.body.classList.add('admin-body-class');
		document.body.classList.remove('user-body-class');
		return () => {
			document.body.classList.remove('admin-body-class');
		};
	}, [history.location]);
	return (
		<Box className='no-records-area'>
			<Box className='no-record-message'>
				<Typography variant='h3' component='h3'>
					{t('noReportHead')}
				</Typography>
				<Typography variant='h4'>{t('noReportMsg')}</Typography>
				<AnimatedIcon
					className='no-record-img'
					width='530'
					type='image/svg+xml'
					alt={'No Reports Available'}
					imagePath={noRecordFound}
				/>
			</Box>
		</Box>
	);
};
export default NoReports;
