import React, { Fragment, useState, useEffect } from 'react';
import {
	Popper,
	Grow,
	Typography,
	List,
	ListItem,
	Checkbox,
	Paper,
	ClickAwayListener,
	FormControlLabel,
	Button,
	Link,
	Box,
	Switch,
	Tooltip,
	RadioGroup,
	Radio,
} from '@material-ui/core';
import './scss/AlignmentFilter.scss';
import { CancelIcon } from '../../config/svg/Action';
import { FilterIcon } from '../../config/svg/CommonSvg';
import { BorderCheckboxIcon, CheckedIcon } from '../../config/svg/formElementIcons';
import { OKRButton } from './OKRButton';
import { BottomArrowIcon, NewFilterIcon } from '../../config/svg/CommonSvgIcon';
import { DownArrowIcon, TopArrowIcon, UpArrowIcon } from '../../config/svg/ArrowSvg';

export const OkrStatusFilter: React.FC<any> = (props) => {
	const {
		t,
		listOkrStatusResult,
		addAlignmentFilter,
		alignmentFilterData,
		viewName,
		okrMasterData,
		viewPage,
		addDirectReportSortBy,
		directReportResultStatus,
		dashboardListing,
		tabValue,
	} = props;
	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectArrayId, setSelectArrayId] = useState<any>([]);
	const [filter, setFilter] = useState<boolean>(false);
	const [filterApplied, setfilterApplied] = useState<boolean>(false);
	const [filterObjs, setFilterObjs] = useState<any>([]);
	const [byTypes, setByTypes] = useState<any>([]);
	const [scroll, setScroll] = useState<any>(false);
	const [sortValue, setSortValue] = useState('mostprogress1');
	// this is static filter, object schema is same for fitler
	const [privateOkr, setPrivateOkr] = useState<any>({
		id: 100,
		statusName: 'Private',
		description: 'Private Okr Filter',
		code: 'Private',
		color: '',
		isActive: true,
		isChecked: false,
		type: 'private',
	});

	//this is for radio group
	const handleSortChange = (event: any) => {
		setSortValue(event.target.value);
		addDirectReportSortBy(event.target.value);
	};

	const handleToggleFilter = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpenFilter((prev) => !prev);
	};

	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpenFilter(false);
	};

	const handleFilterChanged = (e: any, obj: any, index: number, type: string) => {
		const { name, value, id, checked } = e.target;
		let newItem = [...selectArrayId];
		let item = newItem.indexOf(obj.id);
		if (newItem.includes(obj.id)) {
			newItem.splice(item, 1);
			setSelectArrayId(newItem);
		} else {
			newItem.push(obj.id);
			setSelectArrayId(newItem);
		}

		// const combined = [...filterObjs, ...byTypes];
		const privateCopy = privateOkr.isChecked ? [privateOkr] : [];
		const combined = [...filterObjs, ...byTypes, ...privateCopy];

		if (type === 'progress') {
			let filterByProgress = [...filterObjs];
			filterByProgress[index].isChecked = checked;
			setFilterObjs(filterByProgress);
			setFilter(true);
			let filtersItems = filterObjs.filter((item: any) => item.isChecked);
			if (filtersItems.length > 0) {
				setfilterApplied(true);
			}
		} else if (type === 'type') {
			let filters = [...byTypes];
			filters[index].isChecked = checked;
			setByTypes(filters);
			setFilter(true);
			let typesItems = byTypes.filter((item: any) => item.isChecked);
			if (typesItems.length > 0) {
				setfilterApplied(true);
			}
		}
		const count = combined.filter((item: any) => item.isChecked).length;
		if (!count) {
			setfilterApplied(false);
			addAlignmentFilter([]);
		}
	};

	const clearFilter = () => {
		let filters = [...filterObjs];
		let byTypesFilters = [...byTypes];
		filters.map((item: any) => (item.isChecked = false));
		byTypesFilters.map((item: any) => (item.isChecked = false));
		setPrivateOkr({ ...privateOkr, isChecked: false });

		setSortValue('');
		if (addDirectReportSortBy) {
			addDirectReportSortBy('');
		}

		setByTypes(byTypesFilters);
		setFilterObjs(filters);
		setfilterApplied(false);
		if (alignmentFilterData.length) {
			addAlignmentFilter([]);
		}
	};

	const handleCloseChip = (filter: any) => {
		if (filter.type === 'progress') {
			let copy = [...filterObjs];
			let removedChip = copy.map((item: any) => {
				if (item.code === filter.code) {
					item.isChecked = false;
				}
				return item;
			});
			setFilterObjs(removedChip);
		} else if (filter.type === 'type') {
			let copy = [...byTypes];
			let removedChip = copy.map((item: any) => {
				if (item.code === filter.code) {
					item.isChecked = false;
				}
				return item;
			});
			setByTypes(removedChip);
		} else if (filter.type === 'private') {
			setPrivateOkr({ ...privateOkr, isChecked: false });
		}

		if (alignmentFilterData.length === 1) {
			setfilterApplied(false);
			addAlignmentFilter([]);
		}
	};

	useEffect(() => {
		const copy = filterObjs
			.filter((item: any) => item.isChecked)
			.map((item: any) => {
				return { ...item, type: 'progress' };
			});
		const copyTypes = byTypes
			.filter((item: any) => item.isChecked)
			.map((item: any) => {
				return { ...item, type: 'type' };
			});
		const privateCopy = privateOkr.isChecked ? [privateOkr] : [];
		const combined = [...copy, ...copyTypes, ...privateCopy];
		if (filterApplied) {
			addAlignmentFilter(combined);
		}
	}, [filterObjs, byTypes, privateOkr]);

	useEffect(() => {
		let items = JSON.parse(JSON.stringify(listOkrStatusResult));
		if (items && items.okrStatusDetails) {
			setFilterObjs(items.okrStatusDetails);
		}
	}, [listOkrStatusResult]);

	useEffect(() => {
		if (okrMasterData && okrMasterData.okrTypes) {
			let items = JSON.parse(JSON.stringify(okrMasterData));
			if (items && items.okrTypes && items.okrTypes.length) {
				let typeFilters: any[] = [...items.okrTypes];
				typeFilters.push({
					"id": 'default',
					"statusName": items.defaultOkrTagName,
					"description": items.defaultOkrTagName,
					"code": 'default',
					"color": "",
					"isActive": true
				});
				setByTypes(typeFilters);
			}
		}
	}, [okrMasterData]);

	useEffect(() => {
		if (!alignmentFilterData.length && filterApplied) {
			clearFilter();
		}
	}, [alignmentFilterData]);

	/**
	 * Set Filter scroll
	 */
	const winHeight = () => {
		const { innerHeight } = window;
		if (innerHeight <= 700) {
			setScroll(true);
		} else {
			setScroll(false);
		}
	};

	useEffect(() => {
		//set Scroll if window height less than 700
		winHeight();
		window.addEventListener('resize', winHeight, false);
	}, []);

	const onChangePrivateOkr = (e: any) => {
		e.preventDefault();
		setPrivateOkr({ ...privateOkr, isChecked: !privateOkr.isChecked });
		setfilterApplied(true);
		setFilter(true);
	};

	return (
		<Fragment>
			{dashboardListing && tabValue !== 2 ? (
				<OKRButton
					className={`list-filter-btn ${openFilter ? 'active' : ''}`}
					icon={<NewFilterIcon />}
					text={
						<span className='filter-txt'>
							{t('filterLabel')}
							{openFilter ? <UpArrowIcon /> : <DownArrowIcon />}
						</span>
					}
					handleClick={handleToggleFilter}
					id='dashboard-filter-btn'
				/>
			) : dashboardListing && tabValue === 2 ? (
				<>
					<OKRButton
						icon={<FilterIcon />}
						title={t('filterLabel')}
						className='btn-circle-white'
						handleClick={handleToggleFilter}
						text={
							filterApplied && (
								<>
									{alignmentFilterData.length > 0 && (
										<span className='more-filter-count'>+{alignmentFilterData.length}</span>
									)}
								</>
							)
						}
						id='map-filter-btn'
					/>
				</>
			) : (
				<>
					<OKRButton
						icon={<FilterIcon />}
						title={t('filterLabel')}
						className='btn-circle-white'
						handleClick={handleToggleFilter}
						id='map-filter-btn'
					/>
				</>
			)}
			<Box className={`filter-chip ${dashboardListing ? 'dashboard-filter-chip' : ''}`}>
				{dashboardListing && tabValue === 2 ? (
					<List>
						{filterApplied && (
							<>
								<ListItem className='clear-all'>
									<Link onClick={clearFilter}>{t('clearAll')}</Link>
								</ListItem>
							</>
						)}
					</List>
				) : (
					<List>
						{filter &&
							alignmentFilterData &&
							alignmentFilterData.length > 0 &&
							alignmentFilterData.slice(0, 1).map((item: any, index: any) => (
								<Fragment>
									{item.isChecked && (
										<ListItem key={index}>
											<Tooltip arrow title={item.statusName}>
												<Typography variant='body2'>{item.statusName}</Typography>
											</Tooltip>
											<Button className='cancel-btn' onClick={() => handleCloseChip(item)}>
												<CancelIcon />
											</Button>
										</ListItem>
									)}
								</Fragment>
							))}

						{filterApplied && (
							<>
								{alignmentFilterData.length > 1 && (
									<ListItem className='more-filter-count'>
										<Typography variant='body2'>+{alignmentFilterData.length - 1}</Typography>
									</ListItem>
								)}
								<ListItem className='clear-all'>
									<Link onClick={clearFilter}>{t('clearAll')}</Link>
								</ListItem>
							</>
						)}
					</List>
				)}
			</Box>
			<Popper
				className={`okr-filter alignmentmap-filter-list ${dashboardListing ? 'dashboard-filter-list sdsdsd' : ''}`}
				open={openFilter}
				anchorEl={anchorEl}
				id='filtersPopper'
				placement={dashboardListing ? 'bottom-end' : 'bottom-start'}
				modifiers={{
					preventOverflow: {
						enabled: false,

						boundariesElement: window,
					},

					flip: {
						enabled: false, // true by default
					},
				}}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<ClickAwayListener onClickAway={handleClose}>
							<Paper>
								<Box
									className={
										viewPage && scroll && viewPage === 'okrDashboard'
											? 'okrDashboard'
											: viewName === 'directReport'
											? 'alignmentFilterDirectWrap'
											: 'alignmentFilterWrap'
									}
								>
									{/*fShow these option for direct report*/}
									{viewName === 'directReport' ? (
										<Box className='okr-sortby-filter'>
											<Typography variant='body2'>{t('sortBy')}:</Typography>
											<RadioGroup aria-label='progress' name='progress' value={sortValue} onChange={handleSortChange}>
												<List>
													<ListItem key={`Chk`} id={`direct-report-list0`}>
														<FormControlLabel
															value='mostprogress'
															control={<Radio disabled={directReportResultStatus === 'pending'} />}
															label={t('mostProgress')}
														/>
													</ListItem>
													<ListItem key={`Chk1`} id={`direct-report-list1`}>
														<FormControlLabel
															value='leastprogress'
															control={<Radio disabled={directReportResultStatus === 'pending'} />}
															label={t('leastProgress')}
														/>
														{/* {viewPage !== 'okrDashboard' && (
															<Box className='since-days-radio'>
																<Typography variant='body2'>{t('sinceDays')}</Typography>
																<RadioGroup
																	aria-label='sincedays'
																	name='sincedays'
																	value={sortValue}
																	onChange={handleSortChange}
																>
																	<List>
																		<ListItem key={`Chk2`}>
																			<FormControlLabel
																				value='leastprogress7days'
																				control={<Radio disabled={directReportResultStatus === 'pending'} />}
																				label='07'
																			/>
																		</ListItem>
																		<ListItem key={`Chk3`}>
																			<FormControlLabel
																				value='leastprogress15days'
																				control={<Radio disabled={directReportResultStatus === 'pending'} />}
																				label='15'
																			/>
																		</ListItem>
																		<ListItem key={`Chk4`}>
																			<FormControlLabel
																				value='leastprogress30days'
																				control={<Radio disabled={directReportResultStatus === 'pending'} />}
																				label='30'
																			/>
																		</ListItem>
																	</List>
																</RadioGroup>
															</Box>
														)} */}
													</ListItem>
													<ListItem key={`Chk5`} id={`direct-report-list2`}>
														<FormControlLabel
															value='mostcontribution'
															control={<Radio disabled={directReportResultStatus === 'pending'} />}
															label={t('mostContribution')}
														/>
													</ListItem>
													<ListItem key={`Chk6`} id={`direct-report-list3`}>
														<FormControlLabel
															value='leastcontribution'
															control={<Radio disabled={directReportResultStatus === 'pending'} />}
															label={t('leastContribution')}
														/>
													</ListItem>
												</List>
											</RadioGroup>
										</Box>
									) : (
										''
									)}
									{/* {Show these option only for okr view} */}
									{viewName && viewName === 'okrView' && (
										<>
											<Box className='okr-type-filter'>
												<Typography variant='body2'>{t('byTypeLabel')}:</Typography>
												<List>
													{byTypes &&
														byTypes.length > 0 &&
														byTypes.map((item: any, index: number) => (
															<ListItem key={index} id={`type-filter-list-${index}`}>
																<FormControlLabel
																	control={
																		<Checkbox
																			icon={<BorderCheckboxIcon />}
																			checkedIcon={<CheckedIcon />}
																			checked={item.isChecked}
																			value={item.id}
																			onChange={(e) => handleFilterChanged(e, item, index, 'type')}
																			name={item.code}
																			id={item.id}
																			color='primary'
																			disabled={props.alignStatus === 'pending'}
																		/>
																	}
																	label={item.statusName}
																/>
																<Typography className='color-circle not-started-circle'></Typography>
															</ListItem>
														))}
												</List>
											</Box>
										</>
									)}
									{/* <Box className='okr-type-filter'> */}
									<Box className='okr-progress-filter'>
										<Typography variant='body2'>{t('byProgressLabel')}:</Typography>
										<List>
											{openFilter &&
												filterObjs &&
												filterObjs.length > 0 &&
												filterObjs.map((item: any, index: number) => (
													<ListItem key={index} id={`prog-filter-list-${index}`}>
														<FormControlLabel
															control={
																<Checkbox
																	icon={<BorderCheckboxIcon />}
																	checkedIcon={<CheckedIcon />}
																	checked={item.isChecked}
																	disabled={directReportResultStatus === 'pending' || props.alignStatus === 'pending'}
																	value={item.id}
																	onChange={(e) => handleFilterChanged(e, item, index, 'progress')}
																	name={item.code}
																	id={item.id}
																	color='primary'
																/>
															}
															label={item.statusName}
														/>
														<Typography
															className='color-circle'
															style={{ backgroundColor: `#${item.color}` }}
														></Typography>
													</ListItem>
												))}
										</List>
									</Box>
									{/* {Show this option only for okr view} */}
									{/* {viewName && viewName === 'okrView' && (
										<Box className='private-public-filter'>
											<List>
												<ListItem>
													<Typography variant='body2'>{t('viewPrivateOkrs')}</Typography>
													<Switch
														name='checkedB'
														checked={privateOkr.isChecked}
														onChange={(e) => onChangePrivateOkr(e)}
													/>
												</ListItem>
											</List>
										</Box>
									)} */}
								</Box>
							</Paper>
						</ClickAwayListener>
					</Grow>
				)}
			</Popper>
		</Fragment>
	);
};
