import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	Typography,
	FormControl,
	TextField,
	InputAdornment,
	List,
	ListItem,
	Avatar,
	FormControlLabel,
	Checkbox,
	Tooltip,
	Chip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from '../../../../CommonComponent/MultiSelect';
import { OKRButton } from '../../../../../Common/OKRButton';
import { AnimatedIcon } from '../../../../../Common/AnimatedIcon';
import NouserImg from '../../../../../../images/search-no-colleagues.svg';
import { DeleteIcon, SearchIcon } from '../../../../../../config/svg/CommonSvg';
import { CrossIcon, SortingIcon, TickIcon } from '../../../../../../config/svg/CommonSvgIcon';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../../../../config/svg/formElementIcons';
import { Enums } from '../../../../../../config/enums';
import { getFullName, getUserName } from '../../../../../../config/utils';
import { useSelector } from 'react-redux';
import { NoRecord } from '../../../../CommonComponent/NoRecord';

export const ListRole: React.FC<any> = (props: any) => {
	const {
		titleHead,
		listHeadCol2,
		selectedItemList,
		// defaultOkrFormDetails,
		assignType,
		allEmployeeChecked,
		handleSelectedRoleDeletion,
	} = props;
	const { t } = useTranslation();
	const { okrMasterData } = useSelector((state: any) => state.okrReducer);

	const [localRoleList, setLocalRoleList] = useState<any>([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	const [checkedUser, setCheckedUser] = useState<any>([]);
	const [checkedAll, setCheckedAll] = useState(false);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');

	useEffect(() => {
		setLocalRoleList(selectedItemList && selectedItemList?.length ? selectedItemList : []);
		resetCheckState();
	}, [selectedItemList]);

	// ===================================================
	const resetCheckState = () => {
		setCheckedAll(false);
		setCheckedUser([]);
	};
	const clearSearch = () => {
		setSearchTextArray('');
		setLocalRoleList(selectedItemList && selectedItemList?.length ? selectedItemList : []);
		resetCheckState();
	};
	const handleSearchClicked = (e: any) => {
		setSearchValue(e.target.value);
	};
	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			const { value } = e?.target || {};
			if (value && value.trim() !== '') {
				setSearchTextArray(value);
				let updatedRoleList = selectedItemList && selectedItemList?.length ? selectedItemList : [];
				updatedRoleList = updatedRoleList?.filter(
					(rec: any) => rec?.label && rec?.label?.toLowerCase().includes(value.toLowerCase())
				);
				setLocalRoleList(updatedRoleList);
				resetCheckState();
			}
			setSearchValue('');
		}
	};
	// =====================================================

	const handleAllChecked = (e: any) => {
		if (e.target.checked) {
			setCheckedAll(true);
			//======= filter Already selected userId
			const newUserList = localRoleList.filter((user: any) => {
				if (typeof checkedUser.find((item: any) => item.value === user.value) === 'undefined') {
					return true;
				} else {
					return false;
				}
			});
			//====== get all userId in the page
			let newList = newUserList.map(
				(user: any) =>
					typeof checkedUser.find((item: any) => item.value === user.value) === 'undefined' && {
						...user,
					}
			);
			setCheckedUser(newList.concat(checkedUser) || []);
		} else {
			setCheckedAll(false);

			const filterUnselected = checkedUser.filter((chkUser: any) => {
				if (typeof localRoleList.find((item: any) => item.value === chkUser.value) === 'undefined') {
					return true;
				} else {
					return false;
				}
			});
			setCheckedUser(filterUnselected);
		}
	};
	const getSelectedUserLength = () => {
		const selectedUserCount = localRoleList.filter((chkUser: any) => {
			if (typeof checkedUser.find((item: any) => item.value === chkUser.value) === 'undefined') {
				return false;
			} else {
				return true;
			}
		});
		return selectedUserCount.length;
	};
	const onUserSelect = (e: any, rec: any, index: number) => {
		const { checked } = e.target;
		if (checked) {
			const isExist = checkedUser.find((chkUser: any) => rec?.value === chkUser?.value);
			if (!isExist) {
				setCheckedUser([...checkedUser, rec]);
			}
		} else {
			const filteredList = checkedUser.filter((chkUser: any) => rec?.value !== chkUser?.value);
			setCheckedUser([...filteredList]);
		}
	};

	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		let updatedEmpList = sortArrayByKey(localRoleList, assignType === Enums.THREE ? 'emailId' : column, newSortOrder);
		updatedEmpList = dataPerPage(updatedEmpList, 1, Enums.MAX_USER_SIZE);
		setSortingColumn(column);
		setLocalRoleList(updatedEmpList);
		resetCheckState();
	};
	const sortArrayByKey = (arrayList: any, keyName: string, sortingType: string) => {
		arrayList.sort(function (a: any, b: any) {
			const keyA = a[keyName];
			const keyB = b[keyName];
			if (sortingType === 'asc') {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			} else if (sortingType === 'desc') {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			}
			return 0;
		});

		return arrayList;
	};
	const dataPerPage = (arrayList: any, currPage: any, pageSizeValue: any) => {
		const startIndex = currPage - 1;
		const updatedList = arrayList.slice(startIndex * pageSizeValue, pageSizeValue * currPage);

		return updatedList;
	};

	// console.log('ListRole == localRoleList ===>', localRoleList, checkedUser);

	return (
		<>
			{allEmployeeChecked ? (
				<Box className='default-role-list all-emp-list'>
					<Box className='default-role-list-inner'>
						<Box className='users-listing'>
							<Box className='okr-list-panel-head'>
								<List component='ul' disablePadding>
									<ListItem className='user-col1'>
										<Typography variant='body2' className={`sort-title desc `} id='sort-name'>
											<span className='sortable-label'>
												<span className='txt-wrap'>{t('nameLabel')}</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='user-col2'>
										<Typography variant='body2' className={'sort-title'} id='sort-email'>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>{t('numberUser')}</span>
											</span>
										</Typography>
									</ListItem>
								</List>
							</Box>
							<Box className='users-list-content'>
								<Box className='users-list-row' key={`selectedUser`} id={`selected-user-`}>
									<List component='ul' disablePadding>
										<ListItem className='user-col1'>
											<Box className='user-info'>
												<Box className='user-info-details'>
													<Typography variant='body1'>{t('allEmpInOrgLevel')}</Typography>
												</Box>
											</Box>
										</ListItem>
										<ListItem className='user-col2'>
											<Typography variant='body1'>{okrMasterData?.totalUserCount || Enums.ZERO}</Typography>
										</ListItem>
									</List>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			) : (
				<Box className='default-role-list'>
					<Box className='default-role-list-inner'>
						{searchTextArray || (localRoleList && localRoleList?.length) ? (
							<Box className='title-head'>
								<Typography variant='h6'>
									{titleHead || t('RoleListUsers')} (
									{localRoleList && localRoleList?.length ? localRoleList?.length : 0})
								</Typography>
								<Box className='user-search'>
									<TextField
										className='search-text-field'
										id='user-src-field'
										placeholder={t('searchText')}
										fullWidth
										value={searchValue}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<SearchIcon />
												</InputAdornment>
											),
										}}
										onChange={(e) => handleSearchClicked(e)}
										onKeyDown={(e) => searchClicked(e)}
									/>

									{searchTextArray && (
										<Box className='user-search-chip'>
											<List>
												<ListItem>
													{searchTextArray.length > 20 ? (
														<Tooltip title={searchTextArray} arrow>
															<Chip
																label={searchTextArray.substring(0, 20) + '...'}
																onDelete={clearSearch}
																deleteIcon={
																	<span>
																		<CrossIcon />
																	</span>
																}
															/>
														</Tooltip>
													) : (
														<Chip
															label={searchTextArray}
															onDelete={clearSearch}
															deleteIcon={
																<span>
																	<CrossIcon />
																</span>
															}
														/>
													)}
												</ListItem>
											</List>
										</Box>
									)}
								</Box>
							</Box>
						) : (
							<></>
						)}
						{localRoleList && localRoleList?.length ? (
							<Box className='users-listing'>
								<Box className='okr-list-panel-head'>
									<List component='ul' disablePadding>
										<ListItem className='user-col1'>
											<FormControlLabel
												control={
													<Checkbox
														checked={checkedAll}
														onChange={(e) => handleAllChecked(e)}
														icon={<BorderCheckboxIcon />}
														checkedIcon={<CheckedIcon />}
														indeterminateIcon={<UnCheckedIcon />}
														indeterminate={
															checkedUser &&
															checkedUser.length > 0 &&
															getSelectedUserLength() > 0 &&
															localRoleList.length > getSelectedUserLength()
														}
													/>
												}
												label=''
											/>
											<Typography
												onClick={(e) => sortUserList(e, 'label')}
												variant='body2'
												className={sortingColumn === 'label' ? `sort-title ${sortOrder}` : 'sort-title asc'}
												id='sort-name'
											>
												<span className='sortable-label'>
													<span className='txt-wrap'>
														{t('nameLabel')} <SortingIcon />
													</span>
												</span>
											</Typography>
										</ListItem>
										<ListItem className='user-col2'>
											<Typography
												onClick={(e) => sortUserList(e, 'userCount')}
												variant='body2'
												className={sortingColumn === 'userCount' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											>
												<span className={`sortable-label `}>
													<span className='txt-wrap'>
														{listHeadCol2 || t('emailLabel')} <SortingIcon />
													</span>
												</span>
											</Typography>
										</ListItem>
										<ListItem className='user-col3'></ListItem>
									</List>
								</Box>
								<Box className='users-list-content'>
									{localRoleList?.map((item: any, indexVal: number) => {
										return (
											<Box
												className={`users-list-row ${
													item?.isActive ? '' : assignType === Enums.THREE ? 'deleted-user-row' : ''
												}`}
												key={`selected_user_role_designation_index_${indexVal}`}
												id={`selected_user_role_designation_index_${indexVal}`}
											>
												<List component='ul' disablePadding>
													<ListItem className='user-col1'>
														<FormControlLabel
															control={
																<Checkbox
																	checked={
																		typeof checkedUser.find((user: any) => item.value === user?.value) !== 'undefined'
																	}
																	onChange={(e) => onUserSelect(e, item, indexVal)}
																	icon={<BorderCheckboxIcon />}
																	checkedIcon={<CheckedIcon />}
																	indeterminateIcon={<UnCheckedIcon />}
																/>
															}
															label=''
														/>
														<Box className='user-info'>
															{assignType === Enums.THREE ? (
																<Box className='user-img'>
																	<Avatar className='avatar-default' src={item?.imagePath || ''}>
																		{getUserName({
																			firstName: item?.firstName || '',
																			lastName: item?.lastName || '',
																			fullName: item?.label || '',
																		})}
																	</Avatar>
																</Box>
															) : (
																<></>
															)}
															<Box className='user-info-details'>
																<Typography variant='body1'>
																	{assignType === Enums.ONE || assignType === Enums.TWO
																		? item?.label || ''
																		: assignType === Enums.THREE
																		? item?.label || getFullName(item)
																		: ''}
																</Typography>
																{item?.isActive ? (
																	<></>
																) : assignType === Enums.THREE ? (
																	<Box className='deleted-chip-wrap'>
																		<Chip className='delete-chip' label={t('deletedLabel')} />
																	</Box>
																) : (
																	<></>
																)}
															</Box>
														</Box>
													</ListItem>
													<ListItem className='user-col2'>
														<Typography variant='body1'>
															{assignType === Enums.ONE || assignType === Enums.TWO
																? item?.userCount
																: assignType === Enums.THREE
																? item?.emailId || ''
																: ''}
														</Typography>
													</ListItem>
													<ListItem className='user-col3'>
														<OKRButton
															icon={<DeleteIcon />}
															className='editIcon'
															handleClick={(event) =>
																handleSelectedRoleDeletion(event, [{ ...item }], indexVal, 'single', assignType)
															}
														/>
													</ListItem>
												</List>
											</Box>
										);
									})}
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
					{localRoleList && localRoleList?.length === 0 ? (
						<NoRecord noRecordMessage={t('mainNoRecord')} subHead={t('subNoRecord')} noImg={NouserImg} {...props} />
					) : (
						<></>
					)}
					{checkedUser && checkedUser?.length > 1 ? (
						<Box className='default-okr-bulk'>
							<Box className='bulk-user-edit-bar bulk-user-edit-bar-type1'>
								<Box className='bulk-user-selected'>
									<Typography variant='body1'>
										<TickIcon />
										{checkedUser.length} {t('selectedLabel')}
									</Typography>
								</Box>
								<Box className='bulk-user-action'>
									<List component='ul' disablePadding>
										<ListItem>
											<OKRButton
												icon={<DeleteIcon />}
												title={t('delete')}
												className='action-btn'
												handleClick={(event) => handleSelectedRoleDeletion(event, checkedUser, 0, 'bulk', assignType)}
											></OKRButton>
										</ListItem>
									</List>
								</Box>
							</Box>
						</Box>
					) : (
						<></>
					)}
				</Box>
			)}
		</>
	);
};
