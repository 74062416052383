import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import { Logo } from '../../config/svg/GlobalSvg';
import '../../styles/pages/static-pages/staticPage.scss';
import { Link } from 'react-router-dom';

export default function TermsOfUse() {
	const { t } = useTranslation();
	return (
		<Box className='static-page-wrapper wrapper'>
			<header className='app-header-secondary'>
				<Box className='header-left'>
					<Box className='app-logo' id='logo'>
						<Logo />
					</Box>
				</Box>
			</header>
			<Box className='main-wrapper'>
				<Box className='inner-wrapper'>
					<Typography variant='h2'>Terms Of Use</Typography>
					<Typography variant='h3'>Agreement to Terms</Typography>
					<Typography>
						These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf
						of an entity (“you”) and Infopro Learning, Inc (“we,” “us” or “our”), concerning your access to and use of
						the{' '}
						<a target='_blank' rel='noopener noreferrer' href='https://www.unlocku.com/'>
							www.unlocku.com
						</a>{' '}
						website (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and
						agree to be bound by all of these Terms of Use. If you do not agree with all of these Terms of Use, then you
						are expressly prohibited from using the Site and you must discontinue use immediately.
					</Typography>
					<Typography>
						Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby
						expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
						modifications to these Terms of Use at any time and for any reason. We will alert you about any changes, and
						you waive any right to receive specific notice of each such change. It is your responsibility to
						periodically review these Terms of Use to stay informed of updates. You will be subject to and will be
						deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your
						continued use of the Site after the date such revised Terms of Use are posted.
					</Typography>
					<Typography>
						The information provided on the Site is not intended for distribution to or use by any person or entity in
						any jurisdiction or country where such distribution or use would be contrary to law or regulation or which
						would subject us to any registration requirement within such jurisdiction or country. Accordingly, those
						persons who choose to access the Site from other locations do so on their own initiative and are solely
						responsible for compliance with local laws, if and to the extent local laws are applicable.
					</Typography>
					<Typography>
						The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted
						to use or register for the Site.
					</Typography>
					<Typography variant='h3'>Intellectual Property Rights</Typography>
					<Typography>
						Unless otherwise indicated, the Site is our proprietary property and all source code, databases,
						functionality, software, website designs, audio, video, text, photographs, and graphics on the Site
						(collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”)
						are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and
						various other intellectual property rights and unfair competition laws of the United States, foreign
						jurisdictions, and international conventions. The Content and the Marks are provided on the Site “AS IS” for
						your information and personal use only. Except as expressly provided in these Terms of Use, no part of the
						Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly
						displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any
						commercial purpose whatsoever, without our express prior written permission.
					</Typography>
					<Typography>
						Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site
						and to download or print a copy of any portion of the Content to which you have properly gained access
						solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to
						the Site, the Content and the Marks.
					</Typography>
					<Typography variant='h3'>User Representations</Typography>
					<Typography>By using the Site, you represent and warrant that:</Typography>
					<List>
						<ListItem>
							<Typography variant='h4'>
								All registration information you submit will be true, accurate, current, and complete;
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>
								You will maintain the accuracy of such information and promptly update such registration information as
								necessary;{' '}
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>
								you have the legal capacity and you agree to comply with these Terms of Use;
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>you are not a minor in the jurisdiction in which you reside;</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>
								you will not access the Site through automated or non-human means, whether through a bot, script or
								otherwise;
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>
								you will not use the Site for any illegal or unauthorized purpose; and
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>
								your use of the Site will not violate any applicable law or regulation.
							</Typography>
						</ListItem>
					</List>
					<Typography>
						If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
						suspend or terminate your account and refuse any and all current or future use of the Site (or any portion
						thereof).
					</Typography>
					<Typography variant='h3'>User Registration</Typography>
					<Typography>
						You may be required to register with the Site. You agree to keep your password confidential and will be
						responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a
						username you select if we determine, in our sole discretion, that such username is inappropriate, obscene,
						or otherwise objectionable.
					</Typography>
					<Typography variant='h3'>Fees And Payment</Typography>
					<Typography>We accept the following forms of payment:</Typography>
					<List>
						<ListItem>
							<Typography variant='h4'>Visa</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>Mastercard</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>American Express</Typography>
						</ListItem>
					</List>
					<Typography>
						You may be required to purchase or pay a fee to access some of our services. You agree to provide current,
						complete, and accurate purchase and account information for all purchases made via the Site. You further
						agree to promptly update account and payment information, including email address, payment method, and
						payment card expiration date, so that we can complete your transactions and contact you as needed. We bill
						you through an online billing account for purchases made via the Site. Sales tax will be added to the price
						of purchases as deemed required by us. We may change prices at any time. All payments shall be in U.S.
						dollars.
					</Typography>
					<Typography>
						You agree to pay all charges or fees at the prices then in effect for your purchases, and you authorize us
						to charge your chosen payment provider for any such amounts upon making your purchase. If your purchase is
						subject to recurring charges, then you consent to our charging your payment method on a recurring basis
						without requiring your prior approval for each recurring charge, until you notify us of your cancellation.
						We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or
						received payment. We also reserve the right to refuse any order placed through the Site.
					</Typography>
					<Typography variant='h3'>Cancellation</Typography>
					<Typography>
						You can cancel your subscription at any time by logging into your account or contacting us using the contact
						information provided below. Your cancellation will take effect at the end of the current payment term. All
						website and policy info will be deleted upon the end of the subscription.
					</Typography>
					<Typography variant='h3'>Prohibited Activities</Typography>
					<Typography>
						You may not access or use the Site for any purpose other than that for which we make the Site available. The
						Site may not be used in connection with any commercial endeavours except those that are specifically
						endorsed or approved by us.
					</Typography>
					<Typography>As a user of the Site, you agree not to:</Typography>
					<List>
						<ListItem>
							<Typography>
								Systematically retrieve data or other content from the Site to create or compile, directly or
								indirectly, a collection, compilation, database, or directory without written permission from us.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by
								electronic or other means for the purpose of sending unsolicited email, or creating user accounts by
								automated means or under false pretences.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>Use a buying agent or purchasing agent to make purchases on the Site.</Typography>
						</ListItem>
						<ListItem>
							<Typography>Use the Site to advertise or offer to sell goods and services.</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Circumvent, disable, or otherwise interfere with security-related features of the Site, including
								features that prevent or restrict the use or copying of any Content or enforce limitations on the use of
								the Site and/or the Content contained therein.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>Engage in unauthorized framing of or linking to the Site</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
								information such as user passwords.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Make improper use of our support services or submit false reports of abuse or misconduct.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Engage in any automated use of the system, such as using scripts to send comments or messages, or using
								any data mining, robots, or similar data gathering and extraction tools. Interfere with, disrupt, or
								create an undue burden on the Site or the networks or services connected to the Site.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Attempt to impersonate another user or person or use the username of another user.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>Sell or otherwise transfer your profile.</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Use any information obtained from the Site in order to harass, abuse, or harm another person.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Decipher,
								decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a
								part of the Site.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any
								portion of the Site.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion
								of the Site to you.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>Delete the copyright or other proprietary rights notice from any Content.</Typography>
						</ListItem>
						<ListItem>
							<Typography>Use the Site in a manner inconsistent with any applicable laws or regulations.</Typography>
						</ListItem>
					</List>
					<Typography variant='h3'>User Generated Contributions</Typography>
					<Typography>
						The Site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and
						other functionality, and may provide you with the opportunity to create, submit, post, display, transmit,
						perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not
						limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal
						information or other material (collectively, “Contributions”). Contributions may be viewable by other users
						of the Site and through third-party websites. As such, any Contributions you transmit may be treated as
						non-confidential and non-proprietary. When you create or make available any Contributions, you thereby
						represent and warrant that:
					</Typography>
					<List>
						<ListItem>
							<Typography>
								The creation, distribution, transmission, public display, or performance, and the accessing,
								downloading, or copying of your Contributions do not and will not infringe the proprietary rights,
								including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any
								third party.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								You are the creator and owner of or have the necessary licenses, rights, consents, releases, and
								permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions
								in any manner contemplated by the Site and these Terms of Use.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>
								You have the written consent, release, and/or permission of each and every identifiable individual
								person in your Contributions to use the name or likeness of each and every such identifiable individual
								person to enable inclusion and use of your Contributions in any manner contemplated by the Site and
								these Terms of Use.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography>Your Contributions do not violate any applicable law, regulation, or rule.</Typography>
						</ListItem>
					</List>
					<Typography>
						Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other
						things, termination or suspension of your rights to use the Site.
					</Typography>
					<Typography variant='h3'>Contribution License</Typography>
					<Typography>
						By posting your Contributions to any part of the Site, you automatically grant, and you represent and
						warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual,
						non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy,
						reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform,
						publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such
						Contributions (including, without limitation, your image and voice) for any purpose, commercial,
						advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such
						Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in
						any media formats and through any media channels.
					</Typography>
					<Typography>
						This license will apply to any form, media, or technology now known or hereafter developed, and includes our
						use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks,
						trade names, logos, and personal and commercial images you provide. You waive all moral rights in your
						Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.
					</Typography>
					<Typography>
						We do not assert any ownership over your Contributions. You retain full ownership of all of your
						Contributions and any intellectual property rights or other proprietary rights associated with your
						Contributions. We are not liable for any statements or representations in your Contributions provided by you
						in any area on the Site. You are solely responsible for your Contributions to the Site and you expressly
						agree to exonerate us from any and all responsibility and to refrain from any legal action against us
						regarding your Contributions.
					</Typography>
					<Typography>We have the right, in our sole and absolute discretion,</Typography>
					<List>
						<ListItem>To edit, redact, or otherwise change any Contributions;</ListItem>
						<ListItem>
							To re-categorize any Contributions to place them in more appropriate locations on the Site; and
						</ListItem>
						<ListItem>
							To pre-screen or delete any Contributions at any time and for any reason, without notice. We have no
							obligation to monitor your Contributions.
						</ListItem>
					</List>
					<Typography variant='h3'>Submissions</Typography>
					<Typography>
						You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information
						regarding the Site (“Submissions”) provided by you to us are non-confidential and shall become our sole
						property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled
						to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or
						otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such
						Submissions, and you hereby warrant that any such Submissions are original with you or that you have the
						right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual
						infringement or misappropriation of any proprietary right in your Submissions.
					</Typography>
					<Typography variant='h3'>Third-party Websites And Content</Typography>
					<Typography>
						The Site may contain (or you may be sent via the Site) links to other websites (“Third-Party Websites”) as
						well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information,
						applications, software, and other content or items belonging to or originating from third parties
						(“Third-Party Content”). Such Third-Party Websites and Third-Party Content are not investigated, monitored,
						or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any
						Third-Party Websites accessed through the Site or any Third-Party Content posted on, available through, or
						installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy
						practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.
						Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any
						Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Site
						and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own
						risk, and you should be aware these Terms of Use no longer govern. You should review the applicable terms
						and policies, including privacy and data gathering practices, of any website to which you navigate from the
						Site or relating to any applications you use or install from the Site. Any purchases you make through
						Third-Party Websites will be through other websites and from other companies, and we take no responsibility
						whatsoever in relation to such purchases which are exclusively between you and the applicable third party.
						You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites
						and you shall hold us harmless from any harm caused by your purchase of such products or services.
						Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to
						or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
					</Typography>
					<Typography variant='h3'>Site Management</Typography>
					<Typography>We reserve the right, but not the obligation, to:</Typography>
					<List>
						<ListItem>Monitor the Site for violations of these Terms of Use;</ListItem>
						<ListItem>
							Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms
							of Use, including without limitation, reporting such user to law enforcement authorities;
						</ListItem>
						<ListItem>
							In our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or
							disable (to the extent technologically feasible) any of your Contributions or any portion thereof;{' '}
						</ListItem>
						<ListItem>
							in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise
							disable all files and content that are excessive in size or are in any way burdensome to our systems; and
						</ListItem>
						<ListItem>
							otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the
							proper functioning of the Site.
						</ListItem>
					</List>
					<Typography variant='h3'>Privacy Policy</Typography>
					<Typography>
						We care about data privacy and security. Please review our Privacy Policy:
						<Link target='_blank' to='/privacy-policy'>
							{t('privacyPolicy')}
						</Link>
						. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of
						Use.
					</Typography>
					<Typography variant='h3'>Term and Termination</Typography>
					<Typography>
						These Terms of Use shall remain in full force and effect while you use the Site. Without Limiting Any Other
						Provision Of These Terms Of Use, We Reserve The Right To, In Our Sole Discretion And Without Notice Or
						Liability, Deny Access To And Use Of The Site (Including Blocking Certain Ip Addresses), To Any Person For
						Any Reason Or For No Reason, Including Without Limitation For Breach Of Any Representation, Warranty, Or
						Covenant Contained In These Terms Of Use Or Of Any Applicable Law Or Regulation. We May Terminate Your Use
						Or Participation In The Site Or Delete Your Account And Any Content Or Information That You Posted At Any
						Time, Without Warning, In Our Sole Discretion.
					</Typography>
					<Typography>
						If we terminate or suspend your account for any reason, you are prohibited from registering and creating a
						new account under your name, a fake or borrowed name, or the name of any third party, even if you may be
						acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the
						right to take appropriate legal action, including without limitation pursuing civil, criminal, and
						injunctive redress.
					</Typography>
					<Typography variant='h3'>Modifications and Interruptions</Typography>
					<Typography>
						We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at
						our sole discretion without notice. However, we have no obligation to update any information on our Site. We
						also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will
						not be liable to you or any third party for any modification, price change, suspension, or discontinuance of
						the Site.
					</Typography>
					<Typography>
						We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other
						problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors.
						We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any
						time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss,
						damage, or inconvenience caused by your inability to access or use the Site during any downtime or
						discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and
						support the Site or to supply any corrections, updates, or releases in connection therewith.
					</Typography>
					<Typography variant='h3'>Governing Law</Typography>
					<Typography>
						These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of the
						State of New York applicable to agreements made and to be entirely performed within the State of New York,
						without regard to its conflict of law principles.
					</Typography>
					<Typography variant='h3'>Corrections</Typography>
					<Typography>
						There may be information on the Site that contains typographical errors, inaccuracies, or omissions,
						including descriptions, pricing, availability, and various other information. We reserve the right to
						correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any
						time, without prior notice.
					</Typography>
					<Typography variant='h3'>Disclaimer</Typography>
					<Typography>
						The site is provided on an as-is and as-available basis. You agree that your use of the site and our
						services will be at your sole risk. To the fullest extent permitted by law, we disclaim all warranties,
						express or implied, in connection with the site and your use thereof, including, without limitation, the
						implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no
						warranties or representations about the accuracy or completeness of the site’s content or the content of any
						websites linked to the site and we will assume no liability or responsibility for any (1) errors, mistakes,
						or inaccuracies of content and materials, (2) personal injury or property damage, of any nature whatsoever,
						resulting from your access to and use of the site, (3) any unauthorized access to or use of our secure
						servers and/or any and all personal information and/or financial information stored therein, (4) any
						interruption or cessation of transmission to or from the site, (5) any bugs, viruses, trojan horses, or the
						like which may be transmitted to or through the site by any third party, and/or (6) any errors or omissions
						in any content and materials or for any loss or damage of any kind incurred as a result of the use of any
						content posted, transmitted, or otherwise made available via the site. We do not warrant, endorse,
						guarantee, or assume responsibility for any product or service advertised or offered by a third party
						through the site, any hyperlinked website, or any website or mobile application featured in any banner or
						other advertising, and we will not be a party to or in any way be responsible for monitoring any transaction
						between you and any third-party providers of products or services. As with the purchase of a product or
						service through any medium or in any environment, you should use your best judgment and exercise caution
						where appropriate.
					</Typography>
					<Typography variant='h3'>Limitations of Liability</Typography>
					<Typography>
						In No Event Will We Or Our Directors, Employees, Or Agents Be Liable To You Or Any Third Party For Any
						Direct, Indirect, Consequential, Exemplary, Incidental, Special, Or Punitive Damages, Including Lost Profit,
						Lost Revenue, Loss Of Data, Or Other Damages Arising From Your Use Of The Site, Even If We Have Been Advised
						Of The Possibility Of Such Damages. Notwithstanding Anything To The Contrary Contained Herein, Our Liability
						To You For Any Cause Whatsoever And Regardless Of The Form Of The Action, Will At All Times Be Limited To
						The Amount Paid, If Any, By You To Us During The Six (6) Month Period Prior To Any Cause Of Action Arising.
						Certain State Laws Do Not Allow Limitations On Implied Warranties Or The Exclusion Or Limitation Of Certain
						Damages. If These Laws Apply To You, Some Or All Of The Above Disclaimers Or Limitations May Not Apply To
						You, And You May Have Additional Rights.
					</Typography>
					<Typography variant='h3'>Indemnification</Typography>
					<Typography>
						You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
						respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim,
						or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out
						of: (1) your Contributions; (2) use of the Site; (3) breach of these Terms of Use; (4) any breach of your
						representations and warranties set forth in these Terms of Use; (5) your violation of the rights of a third
						party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any
						other user of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the
						right, at your expense, to assume the exclusive defence and control of any matter for which you are required
						to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use
						reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this
						indemnification upon becoming aware of it.
					</Typography>
					<Typography variant='h3'>User Data</Typography>
					<Typography>
						We will maintain certain data that you transmit to the Site for the purpose of managing the performance of
						the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of
						data, you are solely responsible for all data that you transmit or that relates to any activity you have
						undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of
						any such data, and you hereby waive any right of action against us arising from any such loss or corruption
						of such data.
					</Typography>
					<Typography variant='h3'>Electronic Communications, Transactions, and Signatures</Typography>
					<Typography>
						Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You
						consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and
						other communications we provide to you electronically, via email and on the Site, satisfy any legal
						requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
						CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
						TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under
						any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original
						signature or delivery or retention of non-electronic records, or to payments or the granting of credits by
						any means other than electronic means.
					</Typography>
					<Typography variant='h3'>Miscellaneous</Typography>
					<Typography>
						These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site
						constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any
						right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These
						Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and
						obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or
						failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of
						these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the
						provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of
						any remaining provisions. There is no joint venture, partnership, employment or agency relationship created
						between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use
						will not be construed against us by virtue of having drafted them. You hereby waive any and all defences you
						may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to
						execute these Terms of Use.
					</Typography>
					<Typography variant='h3'>Contact Us</Typography>
					<Typography>
						In order to resolve a complaint regarding the Site or to receive further information regarding use of the
						Site, please contact us at:
					</Typography>
					<Typography>
						By mail: 103 Morgan Lane, Suite # 102, Plainsboro, NJ 08536
						<br /> By phone: 609-606-9020
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
