import React, { useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	ClickAwayListener,
	FormControlLabel,
	List,
	ListItem,
	Switch,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { BottomArrowIcon, TeamUserIcon } from '../../../config/svg/CommonSvgIcon';
import NoTeamMemberImg from '../../../images/no-direct-reportees.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { useDispatch } from 'react-redux';
import { getAllTeamData, getTeamsByEmployeeList } from '../../../action/recognize';
import { useSnackbar } from 'notistack';
import {
	formatFeedbackDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../../config/utils';

export const TeamLeaderBoard: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const [isToggle, setIsToggle] = useState<any>(false);
	const [open, setOpen] = React.useState<Boolean>(false);
	const handleClickAway = () => {
		setOpen(false);
	};
	const { enqueueSnackbar } = useSnackbar();
	const [teamDropdownData, setTeamDropdownData] = useState<any[]>([]);
	const [selectedTeamRecog, setSelectedTeamRecog] = useState<any>({});
	const [recognitionTeamData, setRecognitionTeamData] = useState<any[]>([]);

	const dispatch = useDispatch();
	let userDetail = getUserDetails();

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};

	useEffect(() => {
		if (props.selectedUser) {
			getTeamDetailsRecognition();
		}
	}, [props.selectedUser, userDetail.employeeId]);

	useEffect(() => {
		if (selectedTeamRecog && selectedTeamRecog?.teamId) {
			getTeamSelectionData(selectedTeamRecog.teamId);
		}
	}, [selectedTeamRecog]);

	const handleDropdownSelection = (dropdownData: any) => {
		setSelectedTeamRecog(dropdownData);
		handleClickAway();
	};

	const getTeamDetailsRecognition = async () => {
		setIsToggle(false);
		let data: any = {
			id: props.selectedUserDetail?.teamId
				? props.selectedUserDetail.teamId
				: props.selectedUserDetail.employeeId
				? props.selectedUserDetail?.employeeId
				: userDetail.employeeId,
			searchType: props.selectedUserDetail?.teamId ? 2 : 1,
		};
		const response: any = await dispatch(getTeamsByEmployeeList(data));
		if (Boolean(response) && response.data.status === 200) {
			setTeamDropdownData(response.data.entityList);
			if (response.data.entityList.length > 0) {
				setSelectedTeamRecog(response.data.entityList[0]);
			}
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const getTeamSelectionData = async (teamId: number) => {
		let data: any = {
			id: teamId
				? teamId
				: props.selectedUserDetail?.teamId
				? props.selectedUserDetail.teamId
				: props.selectedUserDetail.employeeId
				? props.selectedUserDetail?.employeeId
				: userDetail.employeeId,
			searchType: teamId || props.selectedUserDetail?.teamId ? 2 : props.selectedUserDetail.employeeId ? 1 : 0,
		};
		const response: any = await dispatch(getAllTeamData(data));
		if (Boolean(response) && response.data.status === 200) {
			setRecognitionTeamData(response.data.entityList[0].recognitionEmployees);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const toggleMyPost = (event: any) => {
		if (event.target.checked) {
			setIsToggle(true);
		} else {
			setIsToggle(false);
		}
	};
	return (
		<Box className='team-leaderboard-box'>
			<Box className='team-leaderboard-head'>
				<Typography variant='h6'>{t('teamLeaderBoard')}</Typography>
				{!props.selectedUserDetail.teamId && (
					<Box className='memberwise-by-switch'>
						<FormControlLabel
							control={<Switch checked={isToggle} name='memberViewFilter' onChange={toggleMyPost} />}
							label={t('memberWiseView')}
							labelPlacement='end'
							className='toggle-label'
						/>
					</Box>
				)}
			</Box>
			{isToggle ? (
				<>
					<Box className='team-list-head'>
						<Typography variant='h5'>{t('selectTeamLabel')}:</Typography>
						<Box className={`custom-dropdown custom-type-dropdown-top ${open ? 'active' : ''}`}>
							<OKRButton
								className={'dropdown-button button-placeholder'}
								text={selectedTeamRecog && selectedTeamRecog?.teamName ? selectedTeamRecog?.teamName : 'Select Team'}
								icon={<BottomArrowIcon />}
								handleClick={() => handleClick()}
							></OKRButton>
							{open && (
								<ClickAwayListener onClickAway={handleClickAway}>
									<Box className='ques-type-dropdown'>
										<List component='div' disablePadding>
											{teamDropdownData.length > 0 &&
												teamDropdownData.map((dropdownData: any) => {
													return (
														<ListItem
															component='div'
															onClick={() => {
																handleDropdownSelection(dropdownData);
															}}
															className={`${
																selectedTeamRecog && selectedTeamRecog?.teamId === dropdownData?.teamId ? 'active' : ''
															}`}
														>
															<Typography>{dropdownData.teamName}</Typography>
														</ListItem>
													);
												})}
										</List>
									</Box>
								</ClickAwayListener>
							)}
						</Box>
					</Box>
					<Box className='team-member-list'>
						{recognitionTeamData.length > 0 ? (
							<>
								<Box className='team-list-head'>
									<List component='ul' disablePadding>
										<ListItem className='team-col1'>
											<Typography variant='h6'>{t('nameLabel')}</Typography>
										</ListItem>
										<ListItem className='team-col2'>
											<Typography variant='h6'>{t('recogReceived')}</Typography>
										</ListItem>
										<ListItem className='team-col3'>
											<Typography variant='h6'>{t('recogGiven')}</Typography>
										</ListItem>
										<ListItem className='team-col4'>
											<Typography variant='h6'>{t('badgesReceived')}</Typography>
										</ListItem>{' '}
									</List>
								</Box>
								<Box className='team-list-area'>
									{recognitionTeamData.length > 0 &&
										recognitionTeamData.map((dataTeamRecog: any) => {
											return (
												<Box className='team-list-row'>
													<List component='ul' disablePadding>
														<ListItem className='team-col1'>
															<Box className='user-info'>
																<Box className='user-img'>
																	{dataTeamRecog?.imagePath ? (
																		<Avatar src={dataTeamRecog?.imagePath} />
																	) : (
																		<Avatar>
																			{getUserName({
																				fullName: '',
																				firstName: dataTeamRecog?.firstName,
																				lastName: dataTeamRecog?.lastName,
																			})}
																		</Avatar>
																	)}
																</Box>
																<Box className='user-info-details'>
																	{(dataTeamRecog?.firstName + dataTeamRecog?.lastName).length > 15 ? (
																		<Tooltip title={dataTeamRecog?.firstName + ' ' + dataTeamRecog?.lastName} arrow>
																			<Typography variant='h4'>
																				{(dataTeamRecog?.firstName + ' ' + dataTeamRecog?.lastName).substring(0, 15)}
																				...
																			</Typography>
																		</Tooltip>
																	) : (
																		<Typography variant='h4'>
																			{dataTeamRecog?.firstName} {dataTeamRecog?.lastName}
																		</Typography>
																	)}
																	{dataTeamRecog?.emailId && dataTeamRecog?.emailId.length > 20 ? (
																		<Tooltip arrow title={dataTeamRecog?.emailId || ''}>
																			<Typography variant='subtitle2'>
																				{dataTeamRecog?.emailId.substring(0, 20)}...
																			</Typography>
																		</Tooltip>
																	) : (
																		<Typography variant='subtitle2'>{dataTeamRecog?.emailId || ''}</Typography>
																	)}
																</Box>
															</Box>
														</ListItem>
														<ListItem className='team-col2'>
															<Box className='recognize-badge-count'>{dataTeamRecog?.totalRecognitionsReceived}</Box>
														</ListItem>
														<ListItem className='team-col3'>
															<Box className='recognize-badge-count'>{dataTeamRecog?.totalRecognitionsGiven}</Box>
														</ListItem>
														<ListItem className='team-col4'>
															<Box className='recognize-badge-count'>{dataTeamRecog?.totalBadgesReceived}</Box>
														</ListItem>
													</List>
												</Box>
											);
										})}
								</Box>
							</>
						) : (
							<Box className='no-record'>
								<Typography variant='h2'>{t('noMemberFound')}</Typography>

								<AnimatedIcon
									className='no-record-img'
									width={'300'}
									type='image/svg+xml'
									alt={'No Conversation Available'}
									imagePath={NoTeamMemberImg}
								/>
							</Box>
						)}
					</Box>
				</>
			) : (
				<Box className='team-member-list team-wise-veiw'>
					<Box className='team-list-head'>
						<List component='ul' disablePadding>
							<ListItem className='team-col1'>
								<Typography variant='h6'>{t('teamNameLabel')}</Typography>
							</ListItem>
							<ListItem className='team-col2'>
								<Typography variant='h6'>{t('recogReceived')}</Typography>
							</ListItem>
							<ListItem className='team-col3'>
								<Typography variant='h6'>{t('badgesReceived')}</Typography>
							</ListItem>{' '}
						</List>
					</Box>
					<Box className='team-list-area'>
						{teamDropdownData.length > 0 &&
							teamDropdownData.map((dropdownData: any) => {
								return (
									<Box className='team-list-row'>
										<List component='ul' disablePadding>
											<ListItem className='team-col1'>
												<Box className='team-details'>
													{dropdownData?.imagePath ? (
														<Avatar className='team-avatar' src={dropdownData?.imagePath} />
													) : (
														<Avatar
															className='team-avatar'
															style={{
																backgroundColor: getRandomBackgroundColor(dropdownData.backGroundColorCode),
																color: getRandomColor(dropdownData.colorCode),
															}}
														>
															{getUserName({
																fullName: dropdownData?.teamName,
																firstName: '',
																lastName: '',
															})}
														</Avatar>
													)}

													<Box className='team-info'>
														{(dropdownData?.teamName).length > 15 ? (
															<Tooltip title={dropdownData?.teamName} arrow>
																<Typography variant='h4'>
																	{(dropdownData?.teamName).substring(0, 15)}
																	...
																</Typography>
															</Tooltip>
														) : (
															<Typography variant='h4'>{dropdownData?.teamName}</Typography>
														)}
														<Box display='flex'>
															<Typography variant='subtitle2'>
																<TeamUserIcon /> {dropdownData?.teamMemberCount} {t('membersLabel')}
															</Typography>
														</Box>
													</Box>
												</Box>
											</ListItem>
											<ListItem className='team-col2'>
												<Box className='recognize-badge-count'>{dropdownData?.recognitionsReceived?.total}</Box>
											</ListItem>
											<ListItem className='team-col3'>
												<Box className='recognize-badge-count'>{dropdownData?.badgesReceived?.total}</Box>
											</ListItem>
										</List>
									</Box>
								);
							})}
					</Box>
				</Box>
			)}
		</Box>
	);
};
