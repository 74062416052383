export const symbolIcons = [
	{ title: 'Grinning Face', character: '😀' },
	{ title: 'Grinning Face with Big Eyes', character: '😃' },
	{ title: 'Grinning Face with Smiling Eyes', character: '😄' },
	{ title: 'Beaming Face with Smiling Eyes', character: '😁' },
	{ title: 'Grinning Squinting Face', character: '😆' },
	{ title: 'Grinning Face with Sweat', character: '😅' },
	{ title: 'Rolling on the Floor Laughing', character: '🤣' },
	{ title: 'Face with Tears of Joy', character: '😂' },
	{ title: 'Slightly Smiling Face', character: '🙂' },
	{ title: 'Upside-Down Face', character: '🙃' },
	{ title: 'Winking Face', character: '😉' },
	{ title: 'Smiling Face with Smiling Eyes', character: '😊' },
	{ title: 'Smiling Face with Halo', character: '😇' },
	{ title: 'Smiling Face with Hearts', character: '🥰' },
	{ title: 'Smiling Face with Heart-Eyes', character: '😍' },
	{ title: 'Star-Struck', character: '🤩' },
	{ title: 'Face Blowing a Kiss', character: '😘' },
	{ title: 'Kissing Face', character: '😗' },
	{ title: 'Smiling Face', character: '☺️' },
	{ title: 'Kissing Face with Closed Eyes', character: '😚' },
	{ title: 'Kissing Face with Smiling Eyes', character: '😙' },
	{ title: 'Smiling Face with Tear', character: '🥲' },
	{ title: 'Face Savoring Food', character: '😋' },
	{ title: 'Face with Tongue', character: '😛' },
	{ title: 'Winking Face with Tongue', character: '😜' },
	{ title: 'Zany Face', character: '🤪' },
	{ title: 'Squinting Face with Tongue', character: '😝' },
	{ title: 'Money-Mouth Face', character: '🤑' },
	{ title: 'Hugging Face', character: '🤗' },
	{ title: 'Face with Hand Over Mouth', character: '🤭' },
	{ title: 'Shushing Face', character: '🤫' },
	{ title: 'Thinking Face', character: '🤔' },
	{ title: 'Zipper-Mouth Face', character: '🤐' },
	{ title: 'Face with Raised Eyebrow', character: '🤨' },
	{ title: 'Neutral Face', character: '😐' },
	{ title: 'Expressionless Face', character: '😑' },
	{ title: 'Face Without Mouth', character: '😶' },
	{ title: 'Smirking Face', character: '😏' },
	{ title: 'Unamused Face', character: '😒' },
	{ title: 'Face with Rolling Eyes', character: '🙄' },
	{ title: 'Grimacing Face', character: '😬' },
	{ title: 'Lying Face', character: '🤥' },
	{ title: 'Relieved Face', character: '😌' },
	{ title: 'Pensive Face', character: '😔' },
	{ title: 'Sleepy Face', character: '😪' },
	{ title: 'Drooling Face', character: '🤤' },
	{ title: 'Sleeping Face', character: '😴' },
	{ title: 'Face with Medical Mask', character: '😷' },
	{ title: 'Face with Thermometer', character: '🤒' },
	{ title: 'Face with Head-Bandage', character: '🤕' },
	{ title: 'Nauseated Face', character: '🤢' },
	{ title: 'Face Vomiting', character: '🤮' },
	{ title: 'Sneezing Face', character: '🤧' },
	{ title: 'Hot Face', character: '🥵' },
	{ title: 'Cold Face', character: '🥶' },
	{ title: 'Woozy Face', character: '🥴' },
	{ title: 'Dizzy Face', character: '😵' },
	{ title: 'Exploding Head', character: '🤯' },
	{ title: 'Cowboy Hat Face', character: '🤠' },
	{ title: 'Partying Face', character: '🥳' },
	{ title: 'Disguised Face', character: '🥸' },
	{ title: 'Smiling Face with Sunglasses', character: '😎' },
	{ title: 'Nerd Face', character: '🤓' },
	{ title: 'Face with Monocle', character: '🧐' },
	{ title: 'Confused Face', character: '😕' },
	{ title: 'Worried Face', character: '😟' },
	{ title: 'Slightly Frowning Face', character: '🙁' },
	{ title: 'Frowning Face', character: '☹️' },
	{ title: 'Face with Open Mouth', character: '😮' },
	{ title: 'Hushed Face', character: '😯' },
	{ title: 'Astonished Face', character: '😲' },
	{ title: 'Flushed Face', character: '😳' },
	{ title: 'Pleading Face', character: '🥺' },
	{ title: 'Frowning Face with Open Mouth', character: '😦' },
	{ title: 'Anguished Face', character: '😧' },
	{ title: 'Fearful Face', character: '😨' },
	{ title: 'Anxious Face with Sweat', character: '😰' },
	{ title: 'Sad but Relieved Face', character: '😥' },
	{ title: 'Crying Face', character: '😢' },
	{ title: 'Loudly Crying Face', character: '😭' },
	{ title: 'Face Screaming in Fear', character: '😱' },
	{ title: 'Confounded Face', character: '😖' },
	{ title: 'Persevering Face', character: '😣' },
	{ title: 'Disappointed Face', character: '😞' },
	{ title: 'Downcast Face with Sweat', character: '😓' },
	{ title: 'Weary Face', character: '😩' },
	{ title: 'Tired Face', character: '😫' },
	{ title: 'Yawning Face', character: '🥱' },
	{ title: 'Face with Steam From Nose', character: '😤' },
	{ title: 'Pouting Face', character: '😡' },
	{ title: 'Angry Face', character: '😠' },
	{ title: 'Face with Symbols on Mouth', character: '🤬' },
	{ title: 'Smiling Face with Horns', character: '😈' },
	{ title: 'Angry Face with Horns', character: '👿' },
	{ title: 'Skull', character: '💀' },
	{ title: 'Skull and Crossbones', character: '☠️' },
	{ title: 'Pile of Poo', character: '💩' },
	{ title: 'Clown Face', character: '🤡' },
	{ title: 'Ogre', character: '👹' },
	{ title: 'Goblin', character: '👺' },
	{ title: 'Ghost', character: '👻' },
	{ title: 'Alien', character: '👽' },
	{ title: 'Alien Monster', character: '👾' },
	{ title: 'Robot', character: '🤖' },
	{ title: 'Grinning Cat', character: '😺' },
	{ title: 'Grinning Cat with Smiling Eyes', character: '😸' },
	{ title: 'Cat with Tears of Joy', character: '😹' },
	{ title: 'Smiling Cat with Heart-Eyes', character: '😻' },
	{ title: 'Cat with Wry Smile', character: '😼' },
	{ title: 'Kissing Cat', character: '😽' },
	{ title: 'Weary Cat', character: '🙀' },
	{ title: 'Crying Cat', character: '😿' },
	{ title: 'Pouting Cat', character: '😾' },
	{ title: 'Kiss Mark', character: '💋' },
	{ title: 'Waving Hand', character: '👋' },
	{ title: 'Raised Back of Hand', character: '🤚' },
	{ title: 'Hand with Fingers Splayed', character: '🖐️' },
	{ title: 'Raised Hand', character: '✋' },
	{ title: 'Vulcan Salute', character: '🖖' },
	{ title: 'OK Hand', character: '👌' },
	{ title: 'Pinched Fingers', character: '🤌' },
	{ title: 'Pinching Hand', character: '🤏' },
	{ title: 'Victory Hand', character: '✌️' },
	{ title: 'Crossed Fingers', character: '🤞' },
	{ title: 'Love-You Gesture', character: '🤟' },
	{ title: 'Sign of the Horns', character: '🤘' },
	{ title: 'Call Me Hand', character: '🤙' },
	{ title: 'Backhand Index Pointing Left', character: '👈' },
	{ title: 'Backhand Index Pointing Right', character: '👉' },
	{ title: 'Backhand Index Pointing Up', character: '👆' },
	{ title: 'Middle Finger', character: '🖕' },
	{ title: 'Backhand Index Pointing Down', character: '👇' },
	{ title: 'Index Pointing Up', character: '☝️' },
	{ title: 'Thumbs Up', character: '👍' },
	{ title: 'Thumbs Down', character: '👎' },
	{ title: 'Raised Fist', character: '✊' },
	{ title: 'Oncoming Fist', character: '👊' },
	{ title: 'Left-Facing Fist', character: '🤛' },
	{ title: 'Right-Facing Fist', character: '🤜' },
	{ title: 'Clapping Hands', character: '👏' },
	{ title: 'Raising Hands', character: '🙌' },
	{ title: 'Open Hands', character: '👐' },
	{ title: 'Palms Up Together', character: '🤲' },
	{ title: 'Handshake', character: '🤝' },
	{ title: 'Folded Hands', character: '🙏' },
	{ title: 'Writing Hand', character: '✍️' },
	{ title: 'Nail Polish', character: '💅' },
	{ title: 'Selfie', character: '🤳' },
	{ title: 'Flexed Biceps', character: '💪' },
	{ title: 'Mechanical Arm', character: '🦾' },
	{ title: 'Mechanical Leg', character: '🦿' },
	{ title: 'Leg', character: '🦵' },
	{ title: 'Foot', character: '🦶' },
	{ title: 'Ear', character: '👂' },
	{ title: 'Ear with Hearing Aid', character: '🦻' },
	{ title: 'Nose', character: '👃' },
	{ title: 'Brain', character: '🧠' },
	{ title: 'Anatomical Heart', character: '🫀' },
	{ title: 'Lungs', character: '🫁' },
	{ title: 'Tooth', character: '🦷' },
	{ title: 'Bone', character: '🦴' },
	{ title: 'Eyes', character: '👀' },
	{ title: 'Eye', character: '👁️' },
	{ title: 'Tongue', character: '👅' },
	{ title: 'Mouth', character: '👄' },
	{ title: 'Baby', character: '👶' },
	{ title: 'Child', character: '🧒' },
	{ title: 'Boy', character: '👦' },
	{ title: 'Girl', character: '👧' },
	{ title: 'Person', character: '🧑' },
	{ title: 'Person: Blond Hair', character: '👱' },
	{ title: 'Man', character: '👨' },
	{ title: 'Person: Beard', character: '🧔' },
	{ title: 'Man: Red Hair', character: '👨‍🦰' },
	{ title: 'Man: Curly Hair', character: '👨‍🦱' },
	{ title: 'Man: White Hair', character: '👨‍🦳' },
	{ title: 'Man: Bald', character: '👨‍🦲' },
	{ title: 'Woman', character: '👩' },
	{ title: 'Woman: Red Hair', character: '👩‍🦰' },
	{ title: 'Person: Red Hair', character: '🧑‍🦰' },
	{ title: 'Woman: Curly Hair', character: '👩‍🦱' },
	{ title: 'Person: Curly Hair', character: '🧑‍🦱' },
	{ title: 'Woman: White Hair', character: '👩‍🦳' },
	{ title: 'Person: White Hair', character: '🧑‍🦳' },
	{ title: 'Woman: Bald', character: '👩‍🦲' },
	{ title: 'Person: Bald', character: '🧑‍🦲' },
	{ title: 'Woman: Blond Hair', character: '👱‍♀️' },
	{ title: 'Man: Blond Hair', character: '👱‍♂️' },
	{ title: 'Older Person', character: '🧓' },
	{ title: 'Old Man', character: '👴' },
	{ title: 'Old Woman', character: '👵' },
	{ title: 'Person Frowning', character: '🙍' },
	{ title: 'Man Frowning', character: '🙍‍♂️' },
	{ title: 'Woman Frowning', character: '🙍‍♀️' },
	{ title: 'Person Pouting', character: '🙎' },
	{ title: 'Man Pouting', character: '🙎‍♂️' },
	{ title: 'Woman Pouting', character: '🙎‍♀️' },
	{ title: 'Person Gesturing No', character: '🙅' },
	{ title: 'Man Gesturing No', character: '🙅‍♂️' },
	{ title: 'Woman Gesturing No', character: '🙅‍♀️' },
	{ title: 'Person Gesturing OK', character: '🙆' },
	{ title: 'Man Gesturing OK', character: '🙆‍♂️' },
	{ title: 'Woman Gesturing OK', character: '🙆‍♀️' },
	{ title: 'Person Tipping Hand', character: '💁' },
	{ title: 'Man Tipping Hand', character: '💁‍♂️' },
	{ title: 'Woman Tipping Hand', character: '💁‍♀️' },
	{ title: 'Person Raising Hand', character: '🙋' },
	{ title: 'Man Raising Hand', character: '🙋‍♂️' },
	{ title: 'Woman Raising Hand', character: '🙋‍♀️' },
	{ title: 'Deaf Person', character: '🧏' },
	{ title: 'Deaf Man', character: '🧏‍♂️' },
	{ title: 'Deaf Woman', character: '🧏‍♀️' },
	{ title: 'Person Bowing', character: '🙇' },
	{ title: 'Man Bowing', character: '🙇‍♂️' },
	{ title: 'Woman Bowing', character: '🙇‍♀️' },
	{ title: 'Person Facepalming', character: '🤦' },
	{ title: 'Man Facepalming', character: '🤦‍♂️' },
	{ title: 'Woman Facepalming', character: '🤦‍♀️' },
	{ title: 'Person Shrugging', character: '🤷' },
	{ title: 'Man Shrugging', character: '🤷‍♂️' },
	{ title: 'Woman Shrugging', character: '🤷‍♀️' },
	{ title: 'Health Worker', character: '🧑‍⚕️' },
	{ title: 'Man Health Worker', character: '👨‍⚕️' },
	{ title: 'Woman Health Worker', character: '👩‍⚕️' },
	{ title: 'Student', character: '🧑‍🎓' },
	{ title: 'Man Student', character: '👨‍🎓' },
	{ title: 'Woman Student', character: '👩‍🎓' },
	{ title: 'Teacher', character: '🧑‍🏫' },
	{ title: 'Man Teacher', character: '👨‍🏫' },
	{ title: 'Woman Teacher', character: '👩‍🏫' },
	{ title: 'Judge', character: '🧑‍⚖️' },
	{ title: 'Man Judge', character: '👨‍⚖️' },
	{ title: 'Woman Judge', character: '👩‍⚖️' },
	{ title: 'Farmer', character: '🧑‍🌾' },
	{ title: 'Man Farmer', character: '👨‍🌾' },
	{ title: 'Woman Farmer', character: '👩‍🌾' },
	{ title: 'Cook', character: '🧑‍🍳' },
	{ title: 'Man Cook', character: '👨‍🍳' },
	{ title: 'Woman Cook', character: '👩‍🍳' },
	{ title: 'Mechanic', character: '🧑‍🔧' },
	{ title: 'Man Mechanic', character: '👨‍🔧' },
	{ title: 'Woman Mechanic', character: '👩‍🔧' },
	{ title: 'Factory Worker', character: '🧑‍🏭' },
	{ title: 'Man Factory Worker', character: '👨‍🏭' },
	{ title: 'Woman Factory Worker', character: '👩‍🏭' },
	{ title: 'Office Worker', character: '🧑‍💼' },
	{ title: 'Man Office Worker', character: '👨‍💼' },
	{ title: 'Woman Office Worker', character: '👩‍💼' },
	{ title: 'Scientist', character: '🧑‍🔬' },
	{ title: 'Man Scientist', character: '👨‍🔬' },
	{ title: 'Woman Scientist', character: '👩‍🔬' },
	{ title: 'Technologist', character: '🧑‍💻' },
	{ title: 'Man Technologist', character: '👨‍💻' },
	{ title: 'Woman Technologist', character: '👩‍💻' },
	{ title: 'Singer', character: '🧑‍🎤' },
	{ title: 'Man Singer', character: '👨‍🎤' },
	{ title: 'Woman Singer', character: '👩‍🎤' },
	{ title: 'Artist', character: '🧑‍🎨' },
	{ title: 'Man Artist', character: '👨‍🎨' },
	{ title: 'Woman Artist', character: '👩‍🎨' },
	{ title: 'Pilot', character: '🧑‍✈️' },
	{ title: 'Man Pilot', character: '👨‍✈️' },
	{ title: 'Woman Pilot', character: '👩‍✈️' },
	{ title: 'Astronaut', character: '🧑‍🚀' },
	{ title: 'Man Astronaut', character: '👨‍🚀' },
	{ title: 'Woman Astronaut', character: '👩‍🚀' },
	{ title: 'Firefighter', character: '🧑‍🚒' },
	{ title: 'Man Firefighter', character: '👨‍🚒' },
	{ title: 'Woman Firefighter', character: '👩‍🚒' },
	{ title: 'Police Officer', character: '👮' },
	{ title: 'Man Police Officer', character: '👮‍♂️' },
	{ title: 'Woman Police Officer', character: '👮‍♀️' },
	{ title: 'Detective', character: '🕵️' },
	{ title: 'Man Detective', character: '🕵️‍♂️' },
	{ title: 'Woman Detective', character: '🕵️‍♀️' },
	{ title: 'Guard', character: '💂' },
	{ title: 'Man Guard', character: '💂‍♂️' },
	{ title: 'Woman Guard', character: '💂‍♀️' },
	{ title: 'Ninja', character: '🥷' },
	{ title: 'Construction Worker', character: '👷' },
	{ title: 'Man Construction Worker', character: '👷‍♂️' },
	{ title: 'Woman Construction Worker', character: '👷‍♀️' },
	{ title: 'Prince', character: '🤴' },
	{ title: 'Princess', character: '👸' },
	{ title: 'Person Wearing Turban', character: '👳' },
	{ title: 'Man Wearing Turban', character: '👳‍♂️' },
	{ title: 'Woman Wearing Turban', character: '👳‍♀️' },
	{ title: 'Person With Skullcap', character: '👲' },
	{ title: 'Woman with Headscarf', character: '🧕' },
	{ title: 'Person in Tuxedo', character: '🤵' },
	{ title: 'Man in Tuxedo', character: '🤵‍♂️' },
	{ title: 'Woman in Tuxedo', character: '🤵‍♀️' },
	{ title: 'Person With Veil', character: '👰' },
	{ title: 'Man with Veil', character: '👰‍♂️' },
	{ title: 'Woman with Veil', character: '👰‍♀️' },
	{ title: 'Pregnant Woman', character: '🤰' },
	{ title: 'Breast-Feeding', character: '🤱' },
	{ title: 'Woman Feeding Baby', character: '👩‍🍼' },
	{ title: 'Man Feeding Baby', character: '👨‍🍼' },
	{ title: 'Person Feeding Baby', character: '🧑‍🍼' },
	{ title: 'Baby Angel', character: '👼' },
	{ title: 'Santa Claus', character: '🎅' },
	{ title: 'Mrs. Claus', character: '🤶' },
	{ title: 'Mx Claus', character: '🧑‍🎄' },
	{ title: 'Superhero', character: '🦸' },
	{ title: 'Man Superhero', character: '🦸‍♂️' },
	{ title: 'Woman Superhero', character: '🦸‍♀️' },
	{ title: 'Supervillain', character: '🦹' },
	{ title: 'Man Supervillain', character: '🦹‍♂️' },
	{ title: 'Woman Supervillain', character: '🦹‍♀️' },
	{ title: 'Mage', character: '🧙' },
	{ title: 'Man Mage', character: '🧙‍♂️' },
	{ title: 'Woman Mage', character: '🧙‍♀️' },
	{ title: 'Fairy', character: '🧚' },
	{ title: 'Man Fairy', character: '🧚‍♂️' },
	{ title: 'Woman Fairy', character: '🧚‍♀️' },
	{ title: 'Vampire', character: '🧛' },
	{ title: 'Man Vampire', character: '🧛‍♂️' },
	{ title: 'Woman Vampire', character: '🧛‍♀️' },
	{ title: 'Merperson', character: '🧜' },
	{ title: 'Merman', character: '🧜‍♂️' },
	{ title: 'Mermaid', character: '🧜‍♀️' },
	{ title: 'Elf', character: '🧝' },
	{ title: 'Man Elf', character: '🧝‍♂️' },
	{ title: 'Woman Elf', character: '🧝‍♀️' },
	{ title: 'Genie', character: '🧞' },
	{ title: 'Man Genie', character: '🧞‍♂️' },
	{ title: 'Woman Genie', character: '🧞‍♀️' },
	{ title: 'Zombie', character: '🧟' },
	{ title: 'Man Zombie', character: '🧟‍♂️' },
	{ title: 'Woman Zombie', character: '🧟‍♀️' },
	{ title: 'Person Getting Massage', character: '💆' },
	{ title: 'Man Getting Massage', character: '💆‍♂️' },
	{ title: 'Woman Getting Massage', character: '💆‍♀️' },
	{ title: 'Person Getting Haircut', character: '💇' },
	{ title: 'Man Getting Haircut', character: '💇‍♂️' },
	{ title: 'Woman Getting Haircut', character: '💇‍♀️' },
	{ title: 'Person Walking', character: '🚶' },
	{ title: 'Man Walking', character: '🚶‍♂️' },
	{ title: 'Woman Walking', character: '🚶‍♀️' },
	{ title: 'Person Standing', character: '🧍' },
	{ title: 'Man Standing', character: '🧍‍♂️' },
	{ title: 'Woman Standing', character: '🧍‍♀️' },
	{ title: 'Person Kneeling', character: '🧎' },
	{ title: 'Man Kneeling', character: '🧎‍♂️' },
	{ title: 'Woman Kneeling', character: '🧎‍♀️' },
	{ title: 'Person with White Cane', character: '🧑‍🦯' },
	{ title: 'Man with White Cane', character: '👨‍🦯' },
	{ title: 'Woman with White Cane', character: '👩‍🦯' },
	{ title: 'Person in Motorized Wheelchair', character: '🧑‍🦼' },
	{ title: 'Man in Motorized Wheelchair', character: '👨‍🦼' },
	{ title: 'Woman in Motorized Wheelchair', character: '👩‍🦼' },
	{ title: 'Person in Manual Wheelchair', character: '🧑‍🦽' },
	{ title: 'Man in Manual Wheelchair', character: '👨‍🦽' },
	{ title: 'Woman in Manual Wheelchair', character: '👩‍🦽' },
	{ title: 'Person Running', character: '🏃' },
	{ title: 'Man Running', character: '🏃‍♂️' },
	{ title: 'Woman Running', character: '🏃‍♀️' },
	{ title: 'Woman Dancing', character: '💃' },
	{ title: 'Man Dancing', character: '🕺' },
	{ title: 'Person in Suit Levitating', character: '🕴️' },
	{ title: 'People with Bunny Ears', character: '👯' },
	{ title: 'Men with Bunny Ears', character: '👯‍♂️' },
	{ title: 'Women with Bunny Ears', character: '👯‍♀️' },
	{ title: 'Person in Steamy Room', character: '🧖' },
	{ title: 'Man in Steamy Room', character: '🧖‍♂️' },
	{ title: 'Woman in Steamy Room', character: '🧖‍♀️' },
	{ title: 'Person in Lotus Position', character: '🧘' },
	{ title: 'People Holding Hands', character: '🧑‍🤝‍🧑' },
	{ title: 'Women Holding Hands', character: '👭' },
	{ title: 'Woman and Man Holding Hands', character: '👫' },
	{ title: 'Men Holding Hands', character: '👬' },
	{ title: 'Kiss', character: '💏' },
	{ title: 'Kiss: Woman, Man', character: '👩‍❤️‍💋‍👨' },
	{ title: 'Kiss: Man, Man', character: '👨‍❤️‍💋‍👨' },
	{ title: 'Kiss: Woman, Woman', character: '👩‍❤️‍💋‍👩' },
	{ title: 'Couple with Heart', character: '💑' },
	{ title: 'Couple with Heart: Woman, Man', character: '👩‍❤️‍👨' },
	{ title: 'Couple with Heart: Man, Man', character: '👨‍❤️‍👨' },
	{ title: 'Couple with Heart: Woman, Woman', character: '👩‍❤️‍👩' },
	{ title: 'Family', character: '👪' },
	{ title: 'Family: Man, Woman, Boy', character: '👨‍👩‍👦' },
	{ title: 'Family: Man, Woman, Girl', character: '👨‍👩‍👧' },
	{ title: 'Family: Man, Woman, Girl, Boy', character: '👨‍👩‍👧‍👦' },
	{ title: 'Family: Man, Woman, Boy, Boy', character: '👨‍👩‍👦‍👦' },
	{ title: 'Family: Man, Woman, Girl, Girl', character: '👨‍👩‍👧‍👧' },
	{ title: 'Family: Man, Man, Boy', character: '👨‍👨‍👦' },
	{ title: 'Family: Man, Man, Girl', character: '👨‍👨‍👧' },
	{ title: 'Family: Man, Man, Girl, Boy', character: '👨‍👨‍👧‍👦' },
	{ title: 'Family: Man, Man, Boy, Boy', character: '👨‍👨‍👦‍👦' },
	{ title: 'Family: Man, Man, Girl, Girl', character: '👨‍👨‍👧‍👧' },
	{ title: 'Family: Woman, Woman, Boy', character: '👩‍👩‍👦' },
	{ title: 'Family: Woman, Woman, Girl', character: '👩‍👩‍👧' },
	{ title: 'Family: Woman, Woman, Girl, Boy', character: '👩‍👩‍👧‍👦' },
	{ title: 'Family: Woman, Woman, Boy, Boy', character: '👩‍👩‍👦‍👦' },
	{ title: 'Family: Woman, Woman, Girl, Girl', character: '👩‍👩‍👧‍👧' },
	{ title: 'Family: Man, Boy', character: '👨‍👦' },
	{ title: 'Family: Man, Boy, Boy', character: '👨‍👦‍👦' },
	{ title: 'Family: Man, Girl', character: '👨‍👧' },
	{ title: 'Family: Man, Girl, Boy', character: '👨‍👧‍👦' },
	{ title: 'Family: Man, Girl, Girl', character: '👨‍👧‍👧' },
	{ title: 'Family: Woman, Boy', character: '👩‍👦' },
	{ title: 'Family: Woman, Boy, Boy', character: '👩‍👦‍👦' },
	{ title: 'Family: Woman, Girl', character: '👩‍👧' },
	{ title: 'Family: Woman, Girl, Boy', character: '👩‍👧‍👦' },
	{ title: 'Family: Woman, Girl, Girl', character: '👩‍👧‍👧' },
	{ title: 'Speaking Head', character: '🗣️' },
	{ title: 'Bust in Silhouette', character: '👤' },
	{ title: 'Busts in Silhouette', character: '👥' },
	{ title: 'People Hugging', character: '🫂' },
	{ title: 'Footprints', character: '👣' },
	{ title: 'Luggage', character: '🧳' },
	{ title: 'Closed Umbrella', character: '🌂' },
	{ title: 'Umbrella', character: '☂️' },
	{ title: 'Jack-O-Lantern', character: '🎃' },
	{ title: 'Thread', character: '🧵' },
	{ title: 'Yarn', character: '🧶' },
	{ title: 'Glasses', character: '👓' },
	{ title: 'Sunglasses', character: '🕶️' },
	{ title: 'Goggles', character: '🥽' },
	{ title: 'Lab Coat', character: '🥼' },
	{ title: 'Safety Vest', character: '🦺' },
	{ title: 'Necktie', character: '👔' },
	{ title: 'T-Shirt', character: '👕' },
	{ title: 'Jeans', character: '👖' },
	{ title: 'Scarf', character: '🧣' },
	{ title: 'Gloves', character: '🧤' },
	{ title: 'Coat', character: '🧥' },
	{ title: 'Socks', character: '🧦' },
	{ title: 'Dress', character: '👗' },
	{ title: 'Kimono', character: '👘' },
	{ title: 'Sari', character: '🥻' },
	{ title: 'One-Piece Swimsuit', character: '🩱' },
	{ title: 'Briefs', character: '🩲' },
	{ title: 'Shorts', character: '🩳' },
	{ title: 'Bikini', character: '👙' },
	{ title: 'Woman’s Clothes', character: '👚' },
	{ title: 'Purse', character: '👛' },
	{ title: 'Handbag', character: '👜' },
	{ title: 'Clutch Bag', character: '👝' },
	{ title: 'Backpack', character: '🎒' },
	{ title: 'Thong Sandal', character: '🩴' },
	{ title: 'Man’s Shoe', character: '👞' },
	{ title: 'Running Shoe', character: '👟' },
	{ title: 'Hiking Boot', character: '🥾' },
	{ title: 'Flat Shoe', character: '🥿' },
	{ title: 'High-Heeled Shoe', character: '👠' },
	{ title: 'Woman’s Sandal', character: '👡' },
	{ title: 'Ballet Shoes', character: '🩰' },
	{ title: 'Woman’s Boot', character: '👢' },
	{ title: 'Crown', character: '👑' },
	{ title: 'Woman’s Hat', character: '👒' },
	{ title: 'Top Hat', character: '🎩' },
	{ title: 'Graduation Cap', character: '🎓' },
	{ title: 'Billed Cap', character: '🧢' },
	{ title: 'Military Helmet', character: '🪖' },
	{ title: 'Rescue Worker’s Helmet', character: '⛑️' },
	{ title: 'Lipstick', character: '💄' },
	{ title: 'Ring', character: '💍' },
	{ title: 'Briefcase', character: '💼' },
	{ title: 'Drop of Blood', character: '🩸' },
	{ title: 'Heart with Arrow', character: '💘' },
	{ title: 'Heart with Ribbon', character: '💝' },
	{ title: 'Sparkling Heart', character: '💖' },
	{ title: 'Growing Heart', character: '💗' },
	{ title: 'Beating Heart', character: '💓' },
	{ title: 'Revolving Hearts', character: '💞' },
	{ title: 'Two Hearts', character: '💕' },
	{ title: 'Heart Decoration', character: '💟' },
	{ title: 'Heart Exclamation', character: '❣️' },
	{ title: 'Broken Heart', character: '💔' },
	{ title: 'Red Heart', character: '❤️' },
	{ title: 'Orange Heart', character: '🧡' },
	{ title: 'Yellow Heart', character: '💛' },
	{ title: 'Green Heart', character: '💚' },
	{ title: 'Blue Heart', character: '💙' },
	{ title: 'Purple Heart', character: '💜' },
	{ title: 'Brown Heart', character: '🤎' },
	{ title: 'Black Heart', character: '🖤' },
	{ title: 'White Heart', character: '🤍' },
	{ title: 'Hundred Points', character: '💯' },
	{ title: 'Anger Symbol', character: '💢' },
	{ title: 'Speech Balloon', character: '💬' },
	{ title: 'Eye in Speech Bubble', character: '👁️‍🗨️' },
	{ title: 'Left Speech Bubble', character: '🗨️' },
	{ title: 'Right Anger Bubble', character: '🗯️' },
	{ title: 'Thought Balloon', character: '💭' },
	{ title: 'Zzz', character: '💤' },
	{ title: 'White Flower', character: '💮' },
	{ title: 'Hot Springs', character: '♨️' },
	{ title: 'Barber Pole', character: '💈' },
	{ title: 'Stop Sign', character: '🛑' },
	{ title: 'Twelve O’Clock', character: '🕛' },
	{ title: 'Twelve-Thirty', character: '🕧' },
	{ title: 'One O’Clock', character: '🕐' },
	{ title: 'One-Thirty', character: '🕜' },
	{ title: 'Two O’Clock', character: '🕑' },
	{ title: 'Two-Thirty', character: '🕝' },
	{ title: 'Three O’Clock', character: '🕒' },
	{ title: 'Three-Thirty', character: '🕞' },
	{ title: 'Four O’Clock', character: '🕓' },
	{ title: 'Four-Thirty', character: '🕟' },
	{ title: 'Five O’Clock', character: '🕔' },
	{ title: 'Five-Thirty', character: '🕠' },
	{ title: 'Six O’Clock', character: '🕕' },
	{ title: 'Six-Thirty', character: '🕡' },
	{ title: 'Seven O’Clock', character: '🕖' },
	{ title: 'Seven-Thirty', character: '🕢' },
	{ title: 'Eight O’Clock', character: '🕗' },
	{ title: 'Eight-Thirty', character: '🕣' },
	{ title: 'Nine O’Clock', character: '🕘' },
	{ title: 'Nine-Thirty', character: '🕤' },
	{ title: 'Ten O’Clock', character: '🕙' },
	{ title: 'Ten-Thirty', character: '🕥' },
	{ title: 'Eleven O’Clock', character: '🕚' },
	{ title: 'Eleven-Thirty', character: '🕦' },
	{ title: 'Cyclone', character: '🌀' },
	{ title: 'Spade Suit', character: '♠️' },
	{ title: 'Heart Suit', character: '♥️' },
	{ title: 'Diamond Suit', character: '♦️' },
	{ title: 'Club Suit', character: '♣️' },
	{ title: 'Joker', character: '🃏' },
	{ title: 'Mahjong Red Dragon', character: '🀄' },
	{ title: 'Flower Playing Cards', character: '🎴' },
	{ title: 'Muted Speaker', character: '🔇' },
	{ title: 'Speaker Low Volume', character: '🔈' },
	{ title: 'Speaker Medium Volume', character: '🔉' },
	{ title: 'Speaker High Volume', character: '🔊' },
	{ title: 'Loudspeaker', character: '📢' },
	{ title: 'Megaphone', character: '📣' },
	{ title: 'Postal Horn', character: '📯' },
	{ title: 'Bell', character: '🔔' },
	{ title: 'Bell with Slash', character: '🔕' },
	{ title: 'Musical Note', character: '🎵' },
	{ title: 'Musical Notes', character: '🎶' },
	{ title: 'Chart Increasing with Yen', character: '💹' },
	{ title: 'Sparkle', character: '❇️' },
	{ title: 'Copyright', character: '©️' },
	{ title: 'Registered', character: '®️' },
	{ title: 'Trade Mark', character: '™️' },
	{ title: 'Person Rowing Boat', character: '🚣' },
	{ title: 'Map of Japan', character: '🗾' },
	{ title: 'Snow-Capped Mountain', character: '🏔️' },
	{ title: 'Mountain', character: '⛰️' },
	{ title: 'Volcano', character: '🌋' },
	{ title: 'Mount Fuji', character: '🗻' },
	{ title: 'Camping', character: '🏕️' },
	{ title: 'Beach with Umbrella', character: '🏖️' },
	{ title: 'Desert', character: '🏜️' },
	{ title: 'Desert Island', character: '🏝️' },
	{ title: 'National Park', character: '🏞️' },
	{ title: 'Stadium', character: '🏟️' },
	{ title: 'Classical Building', character: '🏛️' },
	{ title: 'Building Construction', character: '🏗️' },
	{ title: 'Hut', character: '🛖' },
	{ title: 'Houses', character: '🏘️' },
	{ title: 'Derelict House', character: '🏚️' },
	{ title: 'House', character: '🏠' },
	{ title: 'House with Garden', character: '🏡' },
	{ title: 'Office Building', character: '🏢' },
	{ title: 'Japanese Post Office', character: '🏣' },
	{ title: 'Post Office', character: '🏤' },
	{ title: 'Hospital', character: '🏥' },
	{ title: 'Bank', character: '🏦' },
	{ title: 'Hotel', character: '🏨' },
	{ title: 'Love Hotel', character: '🏩' },
	{ title: 'Convenience Store', character: '🏪' },
	{ title: 'School', character: '🏫' },
	{ title: 'Department Store', character: '🏬' },
	{ title: 'Factory', character: '🏭' },
	{ title: 'Japanese Castle', character: '🏯' },
	{ title: 'Castle', character: '🏰' },
	{ title: 'Wedding', character: '💒' },
	{ title: 'Tokyo Tower', character: '🗼' },
	{ title: 'Statue of Liberty', character: '🗽' },
	{ title: 'Church', character: '⛪' },
	{ title: 'Mosque', character: '🕌' },
	{ title: 'Hindu Temple', character: '🛕' },
	{ title: 'Synagogue', character: '🕍' },
	{ title: 'Shinto Shrine', character: '⛩️' },
	{ title: 'Kaaba', character: '🕋' },
	{ title: 'Fountain', character: '⛲' },
	{ title: 'Tent', character: '⛺' },
	{ title: 'Foggy', character: '🌁' },
	{ title: 'Night with Stars', character: '🌃' },
	{ title: 'Cityscape', character: '🏙️' },
	{ title: 'Sunrise Over Mountains', character: '🌄' },
	{ title: 'Sunrise', character: '🌅' },
	{ title: 'Cityscape at Dusk', character: '🌆' },
	{ title: 'Sunset', character: '🌇' },
	{ title: 'Bridge at Night', character: '🌉' },
	{ title: 'Carousel Horse', character: '🎠' },
	{ title: 'Ferris Wheel', character: '🎡' },
	{ title: 'Roller Coaster', character: '🎢' },
	{ title: 'Locomotive', character: '🚂' },
	{ title: 'Railway Car', character: '🚃' },
	{ title: 'High-Speed Train', character: '🚄' },
	{ title: 'Bullet Train', character: '🚅' },
	{ title: 'Train', character: '🚆' },
	{ title: 'Metro', character: '🚇' },
	{ title: 'Light Rail', character: '🚈' },
	{ title: 'Station', character: '🚉' },
	{ title: 'Tram', character: '🚊' },
	{ title: 'Monorail', character: '🚝' },
	{ title: 'Mountain Railway', character: '🚞' },
	{ title: 'Tram Car', character: '🚋' },
	{ title: 'Bus', character: '🚌' },
	{ title: 'Oncoming Bus', character: '🚍' },
	{ title: 'Trolleybus', character: '🚎' },
	{ title: 'Minibus', character: '🚐' },
	{ title: 'Ambulance', character: '🚑' },
	{ title: 'Fire Engine', character: '🚒' },
	{ title: 'Police Car', character: '🚓' },
	{ title: 'Oncoming Police Car', character: '🚔' },
	{ title: 'Taxi', character: '🚕' },
	{ title: 'Oncoming Taxi', character: '🚖' },
	{ title: 'Automobile', character: '🚗' },
	{ title: 'Oncoming Automobile', character: '🚘' },
	{ title: 'Sport Utility Vehicle', character: '🚙' },
	{ title: 'Pickup Truck', character: '🛻' },
	{ title: 'Delivery Truck', character: '🚚' },
	{ title: 'Articulated Lorry', character: '🚛' },
	{ title: 'Tractor', character: '🚜' },
	{ title: 'Racing Car', character: '🏎️' },
	{ title: 'Motorcycle', character: '🏍️' },
	{ title: 'Motor Scooter', character: '🛵' },
	{ title: 'Auto Rickshaw', character: '🛺' },
	{ title: 'Bicycle', character: '🚲' },
	{ title: 'Kick Scooter', character: '🛴' },
	{ title: 'Bus Stop', character: '🚏' },
	{ title: 'Motorway', character: '🛣️' },
	{ title: 'Railway Track', character: '🛤️' },
	{ title: 'Fuel Pump', character: '⛽' },
	{ title: 'Police Car Light', character: '🚨' },
	{ title: 'Horizontal Traffic Light', character: '🚥' },
	{ title: 'Vertical Traffic Light', character: '🚦' },
	{ title: 'Construction', character: '🚧' },
	{ title: 'Anchor', character: '⚓' },
	{ title: 'Sailboat', character: '⛵' },
	{ title: 'Speedboat', character: '🚤' },
	{ title: 'Passenger Ship', character: '🛳️' },
	{ title: 'Ferry', character: '⛴️' },
	{ title: 'Motor Boat', character: '🛥️' },
	{ title: 'Ship', character: '🚢' },
	{ title: 'Airplane', character: '✈️' },
	{ title: 'Small Airplane', character: '🛩️' },
	{ title: 'Airplane Departure', character: '🛫' },
	{ title: 'Airplane Arrival', character: '🛬' },
	{ title: 'Parachute', character: '🪂' },
	{ title: 'Seat', character: '💺' },
	{ title: 'Helicopter', character: '🚁' },
	{ title: 'Suspension Railway', character: '🚟' },
	{ title: 'Mountain Cableway', character: '🚠' },
	{ title: 'Aerial Tramway', character: '🚡' },
	{ title: 'Satellite', character: '🛰️' },
	{ title: 'Rocket', character: '🚀' },
	{ title: 'Flying Saucer', character: '🛸' },
	{ title: 'Ringed Planet', character: '🪐' },
	{ title: 'Shooting Star', character: '🌠' },
	{ title: 'Milky Way', character: '🌌' },
	{ title: 'Umbrella on Ground', character: '⛱️' },
	{ title: 'Fireworks', character: '🎆' },
	{ title: 'Sparkler', character: '🎇' },
	{ title: 'Moon Viewing Ceremony', character: '🎑' },
	{ title: 'Yen Banknote', character: '💴' },
	{ title: 'Dollar Banknote', character: '💵' },
	{ title: 'Euro Banknote', character: '💶' },
	{ title: 'Pound Banknote', character: '💷' },
	{ title: 'Moai', character: '🗿' },
	{ title: 'Passport Control', character: '🛂' },
	{ title: 'Customs', character: '🛃' },
	{ title: 'Baggage Claim', character: '🛄' },
	{ title: 'Left Luggage', character: '🛅' },
	{ title: 'See-No-Evil Monkey', character: '🙈' },
	{ title: 'Hear-No-Evil Monkey', character: '🙉' },
	{ title: 'Speak-No-Evil Monkey', character: '🙊' },
	{ title: 'Collision', character: '💥' },
	{ title: 'Dizzy', character: '💫' },
	{ title: 'Sweat Droplets', character: '💦' },
	{ title: 'Dashing Away', character: '💨' },
	{ title: 'Monkey Face', character: '🐵' },
	{ title: 'Monkey', character: '🐒' },
	{ title: 'Gorilla', character: '🦍' },
	{ title: 'Orangutan', character: '🦧' },
	{ title: 'Dog Face', character: '🐶' },
	{ title: 'Dog', character: '🐕' },
	{ title: 'Guide Dog', character: '🦮' },
	{ title: 'Service Dog', character: '🐕‍🦺' },
	{ title: 'Poodle', character: '🐩' },
	{ title: 'Wolf', character: '🐺' },
	{ title: 'Fox', character: '🦊' },
	{ title: 'Raccoon', character: '🦝' },
	{ title: 'Cat Face', character: '🐱' },
	{ title: 'Cat', character: '🐈' },
	{ title: 'Black Cat', character: '🐈‍⬛' },
	{ title: 'Lion', character: '🦁' },
	{ title: 'Tiger Face', character: '🐯' },
	{ title: 'Tiger', character: '🐅' },
	{ title: 'Leopard', character: '🐆' },
	{ title: 'Horse Face', character: '🐴' },
	{ title: 'Horse', character: '🐎' },
	{ title: 'Unicorn', character: '🦄' },
	{ title: 'Zebra', character: '🦓' },
	{ title: 'Deer', character: '🦌' },
	{ title: 'Bison', character: '🦬' },
	{ title: 'Cow Face', character: '🐮' },
	{ title: 'Ox', character: '🐂' },
	{ title: 'Water Buffalo', character: '🐃' },
	{ title: 'Cow', character: '🐄' },
	{ title: 'Pig Face', character: '🐷' },
	{ title: 'Pig', character: '🐖' },
	{ title: 'Boar', character: '🐗' },
	{ title: 'Pig Nose', character: '🐽' },
	{ title: 'Ram', character: '🐏' },
	{ title: 'Ewe', character: '🐑' },
	{ title: 'Goat', character: '🐐' },
	{ title: 'Camel', character: '🐪' },
	{ title: 'Two-Hump Camel', character: '🐫' },
	{ title: 'Llama', character: '🦙' },
	{ title: 'Giraffe', character: '🦒' },
	{ title: 'Elephant', character: '🐘' },
	{ title: 'Mammoth', character: '🦣' },
	{ title: 'Rhinoceros', character: '🦏' },
	{ title: 'Hippopotamus', character: '🦛' },
	{ title: 'Mouse Face', character: '🐭' },
	{ title: 'Mouse', character: '🐁' },
	{ title: 'Rat', character: '🐀' },
	{ title: 'Hamster', character: '🐹' },
	{ title: 'Rabbit Face', character: '🐰' },
	{ title: 'Rabbit', character: '🐇' },
	{ title: 'Chipmunk', character: '🐿️' },
	{ title: 'Beaver', character: '🦫' },
	{ title: 'Hedgehog', character: '🦔' },
	{ title: 'Bat', character: '🦇' },
	{ title: 'Bear', character: '🐻' },
	{ title: 'Polar Bear', character: '🐻‍❄️' },
	{ title: 'Koala', character: '🐨' },
	{ title: 'Panda', character: '🐼' },
	{ title: 'Sloth', character: '🦥' },
	{ title: 'Otter', character: '🦦' },
	{ title: 'Skunk', character: '🦨' },
	{ title: 'Kangaroo', character: '🦘' },
	{ title: 'Badger', character: '🦡' },
	{ title: 'Paw Prints', character: '🐾' },
	{ title: 'Turkey', character: '🦃' },
	{ title: 'Chicken', character: '🐔' },
	{ title: 'Rooster', character: '🐓' },
	{ title: 'Hatching Chick', character: '🐣' },
	{ title: 'Baby Chick', character: '🐤' },
	{ title: 'Front-Facing Baby Chick', character: '🐥' },
	{ title: 'Bird', character: '🐦' },
	{ title: 'Penguin', character: '🐧' },
	{ title: 'Dove', character: '🕊️' },
	{ title: 'Eagle', character: '🦅' },
	{ title: 'Duck', character: '🦆' },
	{ title: 'Swan', character: '🦢' },
	{ title: 'Owl', character: '🦉' },
	{ title: 'Dodo', character: '🦤' },
	{ title: 'Feather', character: '🪶' },
	{ title: 'Flamingo', character: '🦩' },
	{ title: 'Peacock', character: '🦚' },
	{ title: 'Parrot', character: '🦜' },
	{ title: 'Frog', character: '🐸' },
	{ title: 'Crocodile', character: '🐊' },
	{ title: 'Turtle', character: '🐢' },
	{ title: 'Lizard', character: '🦎' },
	{ title: 'Snake', character: '🐍' },
	{ title: 'Dragon Face', character: '🐲' },
	{ title: 'Dragon', character: '🐉' },
	{ title: 'Sauropod', character: '🦕' },
	{ title: 'T-Rex', character: '🦖' },
	{ title: 'Spouting Whale', character: '🐳' },
	{ title: 'Whale', character: '🐋' },
	{ title: 'Dolphin', character: '🐬' },
	{ title: 'Seal', character: '🦭' },
	{ title: 'Fish', character: '🐟' },
	{ title: 'Tropical Fish', character: '🐠' },
	{ title: 'Blowfish', character: '🐡' },
	{ title: 'Shark', character: '🦈' },
	{ title: 'Octopus', character: '🐙' },
	{ title: 'Spiral Shell', character: '🐚' },
	{ title: 'Snail', character: '🐌' },
	{ title: 'Butterfly', character: '🦋' },
	{ title: 'Bug', character: '🐛' },
	{ title: 'Ant', character: '🐜' },
	{ title: 'Honeybee', character: '🐝' },
	{ title: 'Beetle', character: '🪲' },
	{ title: 'Lady Beetle', character: '🐞' },
	{ title: 'Cricket', character: '🦗' },
	{ title: 'Cockroach', character: '🪳' },
	{ title: 'Spider', character: '🕷️' },
	{ title: 'Spider Web', character: '🕸️' },
	{ title: 'Scorpion', character: '🦂' },
	{ title: 'Mosquito', character: '🦟' },
	{ title: 'Fly', character: '🪰' },
	{ title: 'Worm', character: '🪱' },
	{ title: 'Microbe', character: '🦠' },
	{ title: 'Bouquet', character: '💐' },
	{ title: 'Cherry Blossom', character: '🌸' },
	{ title: 'White Flower', character: '💮' },
	{ title: 'Rosette', character: '🏵️' },
	{ title: 'Rose', character: '🌹' },
	{ title: 'Wilted Flower', character: '🥀' },
	{ title: 'Hibiscus', character: '🌺' },
	{ title: 'Sunflower', character: '🌻' },
	{ title: 'Blossom', character: '🌼' },
	{ title: 'Tulip', character: '🌷' },
	{ title: 'Seedling', character: '🌱' },
	{ title: 'Potted Plant', character: '🪴' },
	{ title: 'Evergreen Tree', character: '🌲' },
	{ title: 'Deciduous Tree', character: '🌳' },
	{ title: 'Palm Tree', character: '🌴' },
	{ title: 'Cactus', character: '🌵' },
	{ title: 'Sheaf of Rice', character: '🌾' },
	{ title: 'Herb', character: '🌿' },
	{ title: 'Shamrock', character: '☘️' },
	{ title: 'Four Leaf Clover', character: '🍀' },
	{ title: 'Maple Leaf', character: '🍁' },
	{ title: 'Fallen Leaf', character: '🍂' },
	{ title: 'Leaf Fluttering in Wind', character: '🍃' },
	{ title: 'Mushroom', character: '🍄' },
	{ title: 'Chestnut', character: '🌰' },
	{ title: 'Crab', character: '🦀' },
	{ title: 'Lobster', character: '🦞' },
	{ title: 'Shrimp', character: '🦐' },
	{ title: 'Squid', character: '🦑' },
	{ title: 'Globe Showing Europe-Africa', character: '🌍' },
	{ title: 'Globe Showing Americas', character: '🌎' },
	{ title: 'Globe Showing Asia-Australia', character: '🌏' },
	{ title: 'Globe with Meridians', character: '🌐' },
	{ title: 'Rock', character: '🪨' },
	{ title: 'New Moon', character: '🌑' },
	{ title: 'Waxing Crescent Moon', character: '🌒' },
	{ title: 'Waxing Gibbous Moon', character: '🌔' },
	{ title: 'Full Moon', character: '🌕' },
	{ title: 'Waning Gibbous Moon', character: '🌖' },
	{ title: 'Last Quarter Moon', character: '🌗' },
	{ title: 'Waning Crescent Moon', character: '🌘' },
	{ title: 'Crescent Moon', character: '🌙' },
	{ title: 'New Moon Face', character: '🌚' },
	{ title: 'First Quarter Moon Face', character: '🌛' },
	{ title: 'Last Quarter Moon Face', character: '🌜' },
	{ title: 'Sun', character: '☀️' },
	{ title: 'Full Moon Face', character: '🌝' },
	{ title: 'Sun with Face', character: '🌞' },
	{ title: 'Star', character: '⭐' },
	{ title: 'Glowing Star', character: '🌟' },
	{ title: 'Shooting Star', character: '🌠' },
	{ title: 'Cloud', character: '☁️' },
	{ title: 'Sun Behind Cloud', character: '⛅' },
	{ title: 'Cloud with Lightning and Rain', character: '⛈️' },
	{ title: 'Sun Behind Small Cloud', character: '🌤️' },
	{ title: 'Sun Behind Large Cloud', character: '🌥️' },
	{ title: 'Sun Behind Rain Cloud', character: '🌦️' },
	{ title: 'Cloud with Rain', character: '🌧️' },
	{ title: 'Cloud with Snow', character: '🌨️' },
	{ title: 'Cloud with Lightning', character: '🌩️' },
	{ title: 'Tornado', character: '🌪️' },
	{ title: 'Fog', character: '🌫️' },
	{ title: 'Wind Face', character: '🌬️' },
	{ title: 'Rainbow', character: '🌈' },
	{ title: 'Umbrella', character: '☂️' },
	{ title: 'Umbrella with Rain Drops', character: '☔' },
	{ title: 'High Voltage', character: '⚡' },
	{ title: 'Snowflake', character: '❄️' },
	{ title: 'Snowman', character: '☃️' },
	{ title: 'Snowman Without Snow', character: '⛄' },
	{ title: 'Comet', character: '☄️' },
	{ title: 'Fire', character: '🔥' },
	{ title: 'Droplet', character: '💧' },
	{ title: 'Water Wave', character: '🌊' },
	{ title: 'Christmas Tree', character: '🎄' },
	{ title: 'Sparkles', character: '✨' },
	{ title: 'Tanabata Tree', character: '🎋' },
	{ title: 'Pine Decoration', character: '🎍' },
	{ title: 'Grapes', character: '🍇' },
	{ title: 'Melon', character: '🍈' },
	{ title: 'Watermelon', character: '🍉' },
	{ title: 'Tangerine', character: '🍊' },
	{ title: 'Lemon', character: '🍋' },
	{ title: 'Banana', character: '🍌' },
	{ title: 'Pineapple', character: '🍍' },
	{ title: 'Mango', character: '🥭' },
	{ title: 'Red Apple', character: '🍎' },
	{ title: 'Green Apple', character: '🍏' },
	{ title: 'Pear', character: '🍐' },
	{ title: 'Peach', character: '🍑' },
	{ title: 'Cherries', character: '🍒' },
	{ title: 'Strawberry', character: '🍓' },
	{ title: 'Blueberries', character: '🫐' },
	{ title: 'Kiwi Fruit', character: '🥝' },
	{ title: 'Tomato', character: '🍅' },
	{ title: 'Olive', character: '🫒' },
	{ title: 'Coconut', character: '🥥' },
	{ title: 'Avocado', character: '🥑' },
	{ title: 'Eggplant', character: '🍆' },
	{ title: 'Potato', character: '🥔' },
	{ title: 'Carrot', character: '🥕' },
	{ title: 'Ear of Corn', character: '🌽' },
	{ title: 'Hot Pepper', character: '🌶️' },
	{ title: 'Bell Pepper', character: '🫑' },
	{ title: 'Cucumber', character: '🥒' },
	{ title: 'Leafy Green', character: '🥬' },
	{ title: 'Broccoli', character: '🥦' },
	{ title: 'Garlic', character: '🧄' },
	{ title: 'Onion', character: '🧅' },
	{ title: 'Mushroom', character: '🍄' },
	{ title: 'Peanuts', character: '🥜' },
	{ title: 'Chestnut', character: '🌰' },
	{ title: 'Bread', character: '🍞' },
	{ title: 'Croissant', character: '🥐' },
	{ title: 'Baguette Bread', character: '🥖' },
	{ title: 'Flatbread', character: '🫓' },
	{ title: 'Pretzel', character: '🥨' },
	{ title: 'Bagel', character: '🥯' },
	{ title: 'Pancakes', character: '🥞' },
	{ title: 'Waffle', character: '🧇' },
	{ title: 'Cheese Wedge', character: '🧀' },
	{ title: 'Meat on Bone', character: '🍖' },
	{ title: 'Poultry Leg', character: '🍗' },
	{ title: 'Cut of Meat', character: '🥩' },
	{ title: 'Bacon', character: '🥓' },
	{ title: 'Hamburger', character: '🍔' },
	{ title: 'French Fries', character: '🍟' },
	{ title: 'Pizza', character: '🍕' },
	{ title: 'Hot Dog', character: '🌭' },
	{ title: 'Sandwich', character: '🥪' },
	{ title: 'Taco', character: '🌮' },
	{ title: 'Burrito', character: '🌯' },
	{ title: 'Tamale', character: '🫔' },
	{ title: 'Stuffed Flatbread', character: '🥙' },
	{ title: 'Falafel', character: '🧆' },
	{ title: 'Egg', character: '🥚' },
	{ title: 'Cooking', character: '🍳' },
	{ title: 'Shallow Pan of Food', character: '🥘' },
	{ title: 'Pot of Food', character: '🍲' },
	{ title: 'Fondue', character: '🫕' },
	{ title: 'Bowl with Spoon', character: '🥣' },
	{ title: 'Green Salad', character: '🥗' },
	{ title: 'Popcorn', character: '🍿' },
	{ title: 'Butter', character: '🧈' },
	{ title: 'Salt', character: '🧂' },
	{ title: 'Canned Food', character: '🥫' },
	{ title: 'Bento Box', character: '🍱' },
	{ title: 'Rice Cracker', character: '🍘' },
	{ title: 'Rice Ball', character: '🍙' },
	{ title: 'Cooked Rice', character: '🍚' },
	{ title: 'Curry Rice', character: '🍛' },
	{ title: 'Steaming Bowl', character: '🍜' },
	{ title: 'Spaghetti', character: '🍝' },
	{ title: 'Roasted Sweet Potato', character: '🍠' },
	{ title: 'Oden', character: '🍢' },
	{ title: 'Sushi', character: '🍣' },
	{ title: 'Fried Shrimp', character: '🍤' },
	{ title: 'Fish Cake with Swirl', character: '🍥' },
	{ title: 'Moon Cake', character: '🥮' },
	{ title: 'Dango', character: '🍡' },
	{ title: 'Dumpling', character: '🥟' },
	{ title: 'Fortune Cookie', character: '🥠' },
	{ title: 'Takeout Box', character: '🥡' },
	{ title: 'Oyster', character: '🦪' },
	{ title: 'Soft Ice Cream', character: '🍦' },
	{ title: 'Shaved Ice', character: '🍧' },
	{ title: 'Ice Cream', character: '🍨' },
	{ title: 'Doughnut', character: '🍩' },
	{ title: 'Cookie', character: '🍪' },
	{ title: 'Birthday Cake', character: '🎂' },
	{ title: 'Shortcake', character: '🍰' },
	{ title: 'Cupcake', character: '🧁' },
	{ title: 'Pie', character: '🥧' },
	{ title: 'Chocolate Bar', character: '🍫' },
	{ title: 'Candy', character: '🍬' },
	{ title: 'Lollipop', character: '🍭' },
	{ title: 'Custard', character: '🍮' },
	{ title: 'Honey Pot', character: '🍯' },
	{ title: 'Baby Bottle', character: '🍼' },
	{ title: 'Glass of Milk', character: '🥛' },
	{ title: 'Hot Beverage', character: '☕' },
	{ title: 'Teapot', character: '🫖' },
	{ title: 'Teacup Without Handle', character: '🍵' },
	{ title: 'Sake', character: '🍶' },
	{ title: 'Bottle with Popping Cork', character: '🍾' },
	{ title: 'Wine Glass', character: '🍷' },
	{ title: 'Cocktail Glass', character: '🍸' },
	{ title: 'Tropical Drink', character: '🍹' },
	{ title: 'Beer Mug', character: '🍺' },
	{ title: 'Clinking Beer Mugs', character: '🍻' },
	{ title: 'Clinking Glasses', character: '🥂' },
	{ title: 'Tumbler Glass', character: '🥃' },
	{ title: 'Cup with Straw', character: '🥤' },
	{ title: 'Bubble Tea', character: '🧋' },
	{ title: 'Beverage Box', character: '🧃' },
	{ title: 'Mate', character: '🧉' },
	{ title: 'Ice', character: '🧊' },
	{ title: 'Chopsticks', character: '🥢' },
	{ title: 'Fork and Knife with Plate', character: '🍽️' },
	{ title: 'Fork and Knife', character: '🍴' },
	{ title: 'Spoon', character: '🥄' }
];
