/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { DefaultIcon, HayHandIcon } from './svg/CommonSvg';
import { getLocalStorageItem } from '../services/StorageService';

export const CHART_COLOR = ['#6C5BFA', '#8FC3F6', '#FA989D'];
export const TEAMS_CHART_COLOR = ['#8FC3F6', '#FA989D', '#45E7BA'];
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
export const EMAIL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
export const URL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

export const APP_ID = 3;
export const REQUEST_TYPE_OKR = 1;
export const REQUEST_TYPE_KR = 2;
export const REQUEST_TYPE_FEEDBACK = 3;
export const REQUEST_TYPE_TEAMS = 4;
export const RAISED_TYPE_ID = 2;
export const GoalModel = {
	objectiveId: 0,
	goalObjectiveId: 0,
	employeeId: 0,
	isPrivate: false,
	objectiveName: '',
	objectiveDescription: '',
	objectiveCycleId: 17,
	startDate: '',
	endDate: '',
	importedType: 0,
	importedId: 0,
	contributors: [],
	myGoalsDetails: [],
};
export const MaxLength = 120;
export const Styles = {
	twoLine: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
	},
};
export const IS_VISIBLE = false;
export const IS_CLIENT = false;
export const marks = [
	{
		value: 0,
		label: '0',
	},
	{
		value: 1,
		label: '1',
	},
	{
		value: 2,
		label: '2',
	},
	{
		value: 3,
		label: '3',
	},
	{
		value: 4,
		label: '4',
	},
	{
		value: 5,
		label: '5',
	},
];
export const labels = {
	1: 'Needs Improvement',
	2: 'Average',
	3: 'Good',
	4: 'Exceptional',
	5: 'Extraordinary',
};

export const ratingLabels = {
	1: 'Beginner',
	2: 'Confident',
	3: 'Adept',
	4: 'Expert',
	5: 'Master',
};

export const AdUserDomain = '@msdnincsysinc.onmicrosoft.com';

//**** For MS Login Regular Use****************************************** */
// export const DefaultOrigin = 'https://dev.unlocku.com';
// export const WithoutHttpDefaultOrigin = 'dev.unlocku.com';

//For Checking Custom Created Instance for DB Login ********************
export const DefaultOrigin = 'https://dev.unlocku.com';
export const WithoutHttpDefaultOrigin = 'dev.unlocku.com';
//********** END ******************************************************** */

export const AZURE_LOGOUTURI =
	'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
	window.location.origin +
	'/logout';
export const AZURE_Created_domains = ['msdnincsysinc.onmicrosoft.com', 'unlockokr.online'];

/**
 * Callouts constant
 */
export const defaultCalloutProps = {
	open: false,
	text: '',
	placement: '',
	classname: '',
	currentCallout: '',
	currentCalloutIndex: 0,
	prev: false,
	next: false,
	done: false,
	tooltipIcon: <DefaultIcon />,
};

export const calloutsDataForOKR = [
	{
		open: true,
		text: `${'createokrTitleGuidedText'}`,
		placement: '',
		classname: '',
		currentCallout: 'okrTitle',
		currentCalloutIndex: 0,
		prev: false,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'settingGuidedText'}`,
		placement: '',
		classname: '',
		currentCallout: 'okrSetting',
		currentCalloutIndex: 1,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: '',
		placement: '',
		classname: 'create-title-guidedtext',
		currentCallout: 'krTitle',
		currentCalloutIndex: 2,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'settingKrGuidedText'}`,
		placement: '',
		classname: 'setting-kr-guidedtext',
		currentCallout: 'krSetting',
		currentCalloutIndex: 3,
		prev: true,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'okrSettingTooltipText'}`,
		placement: '',
		classname: 'okr-tooltip',
		currentCallout: 'okrSettingTooltip',
		currentCalloutIndex: 4,
		prev: false,
		next: false,
		done: false,
		tooltipIcon: <HayHandIcon />,
	},
	{
		open: true,
		text: `${'okrSettingTooltipText'}`,
		placement: '',
		classname: 'okr-tooltip kr-tooltip',
		currentCallout: 'okrSettingTooltip',
		currentCalloutIndex: 5,
		prev: false,
		next: false,
		done: false,
		tooltipIcon: <HayHandIcon />,
	},
];
export const calloutsDataForKeyResult = [
	{
		open: true,
		text: `${'krTeamGuidedText'}`,
		placement: 'left-start',
		classname: 'krTeamGuidedText',
		currentCallout: 'krTeamText',
		currentCalloutIndex: 0,
		prev: false,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'krTitleGuidedText'}`,
		placement: 'left-start',
		classname: '',
		currentCallout: 'KrTitleText',
		currentCalloutIndex: 1,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'krDuedateGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'KrDueDate',
		currentCalloutIndex: 2,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'measurementGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md',
		currentCallout: 'Krmeasurement',
		currentCalloutIndex: 3,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'krcontributorsGuidedText'}`,
		placement: 'left-start',
		classname: '',
		currentCallout: 'Krcontributors',
		currentCalloutIndex: 4,
		prev: true,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'letsAssignContributorGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10 assignContributorTooltip',
		currentCallout: '',
		currentCalloutIndex: 5,
		prev: false,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'assignTargetGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'assignTargetTitle',
		currentCalloutIndex: 6,
		prev: false,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'assignKrTargetGuidedText'}`,
		placement: 'top-start',
		classname: 'assignkr-target-guidedtext',
		currentCallout: 'assignKrTarget',
		currentCalloutIndex: 7,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'assignKrDueDateGuidedText'}`,
		placement: 'top-start',
		classname: 'assignkr-date-guidedtext',
		currentCallout: 'assignKrCommonDueDate',
		currentCalloutIndex: 8,
		prev: true,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'showContributors'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'contributerList',
		currentCalloutIndex: 9,
		prev: false,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'indvContributors'}`,
		placement: 'left-start',
		classname: '',
		currentCallout: 'indvcontributerList',
		currentCalloutIndex: 10,
		prev: false,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'letsAssignIndContributorGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10 assignContributorTooltip',
		currentCallout: '',
		currentCalloutIndex: 11,
		prev: false,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
];

export const calloutsDataForObjective = [
	{
		open: true,
		text: `${'okrTitleGuidedText'}`,
		placement: 'left-start',
		classname: '',
		currentCallout: 'okrTitle',
		currentCalloutIndex: 0,
		prev: false,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'desGuidedText'}`,
		placement: 'left-start',
		classname: 'desGuided',
		currentCallout: 'okrDesc',
		currentCalloutIndex: 1,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: '',
		placement: 'left-start',
		classname: 'middle-md-5',
		currentCallout: 'OkrTeamORIndividual',
		currentCalloutIndex: 2,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'ownerGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md',
		currentCallout: 'okrOwner',
		currentCalloutIndex: 3,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'dateGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'okrDate',
		currentCalloutIndex: 4,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'virtuaLinkGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'virtualLink',
		currentCalloutIndex: 5,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
];

/**
 * This method adds placeholder based on its index
 * @param key Number
 */
export const getPlaceHolderText = (key, isFirstTimeUser) => {
	//Checking if first time user
	if (isFirstTimeUser) {
		switch (key) {
			case 0:
				return 'letsAddFirstKR';
			case 1:
				return 'letsAddAnotherKR';
			case 2:
				return 'wantAddAnother';
			case 3:
				return 'addFourthKey';
			case 4:
				return 'addFifthKey';
			case 5:
				return 'specialObjectiveKey';
			default:
				return 'specialObjectiveKey';
		}
	} else {
		switch (key) {
			case 0:
				return 'addKeyRes';
			case 1:
				return 'AddAnotherKeyRes';
			case 2:
				return 'wantAddAnother';
			case 3:
				return 'addFourthKey';
			case 4:
				return 'addFifthKey';
			case 5:
				return 'specialObjectiveKey';
			default:
				return 'specialObjectiveKey';
		}
	}
};

export const FreeTrialCredentials = {
	userName: 'FreeTrialUser',
	password: 'Admin@123',
};
export const LEARNING = 'https://academy.unlockokr.com/login/login.php';

export const unlockLearnChangeUrlForDomains = {
	1: 'infoprolearning',
};
// Constant for UnlockU redirection ************
const tenantData = JSON.parse(getLocalStorageItem('TenantData')) || {};
export const LEARNINGU = Object.values(unlockLearnChangeUrlForDomains).includes(window.location.host.split('.')[0])
	? process.env.REACT_APP_LEARNINGU_INFO
	: tenantData?.learnUrl
	? `${tenantData?.learnUrl}/${process.env.REACT_APP_LEARNINGU}`
	: ''; //'https://unlock-u.unlocklearn.com/sso/login.php';
export const UNLOCKKEY = 'UnlockWork';
export const UNLOCKAPPNAME = 'Unlock:Work';
export const UNLOCKACADEMYACTION = 'academy';
export const UNLOCKUTOKEN = '8c06e56a98f5f531e8de75f0e368995c';
export const FREE_COURSES_UNLOCKU_TOCKEN = 'core_user_get_academy_list'; // 'core_user_get_free_or_featured_courses';
export const TRENDING_COURSES_UNLOCKU_TOCKEN = 'get_free_courses_of_okr_academy'; // 'core_user_get_trending_courses';

export const globalTaskPriorityLabels = {
	1: 'Low',
	2: 'Medium',
	3: 'High',
};

export const globalTaskSourceLabels = {
	1: 'OKRs',
	2: 'Check-in',
	3: '1-on-1',
	4: 'Other',
};

export const globalTaskDaysFilterLabels = {
	1: 'Today',
	2: 'Tomorrow',
	3: 'This Week',
	4: 'Overdue',
};

export const openIdConnectType = {
	1: 'DB',
	2: 'MS',
	3: 'Google',
	4: 'Okta',
};

export const downloadReportsDropdownText = {
	0: 'Last 30 Days',
	1: 'Last 60 Days',
	2: 'Last 90 Days',
	3: 'Last 120 Days',
	4: 'Last 150 Days',
	5: 'Last 6 Months',
	6: 'Last 9 Months',
	7: 'Last 1 Year',
	8: 'From Inception',
};

export const globalTaskDaysNewFilterLabels = {
	1: 'No Due',
	2: 'Overdue',
	3: 'Today',
	4: 'Tomorrow',
	5: 'This Week',
	6: 'Delayed',
};

export const pipFiltersLabels = [
	{
		label: 'Type',
		performanceImprovementPlanStatusId: 'start',
		values: [
			{ performanceImprovementPlanStatusId: 1, name: 'My' },
			{ performanceImprovementPlanStatusId: 2, name: 'All nominations' },
		],
		order: 0,
	},
	{
		label: 'By Status',
		performanceImprovementPlanStatusId: 'status',
		values: [
			{ performanceImprovementPlanStatusId: 3, name: 'Draft' },
			{ performanceImprovementPlanStatusId: 1, name: 'Active' },
			{ performanceImprovementPlanStatusId: 0, name: 'Not Started' },
			{ performanceImprovementPlanStatusId: 2, name: 'Resolved' },
		],
		order: 1,
	},
	{
		label: 'By Resolved Status',
		performanceImprovementPlanStatusId: 'resolvedStatus',
		values: [],
		order: 3,
	},
	{
		label: 'Timeframe changed?',
		performanceImprovementPlanStatusId: 'timeframe',
		values: [
			{ performanceImprovementPlanStatusId: 1, name: 'Yes' },
			{ performanceImprovementPlanStatusId: 0, name: 'No' },
		],
		order: 3,
	},
];

export const feedbackFiltersLabels = [
	{
		label: 'Type',
		performanceImprovementPlanStatusId: 'start',
		values: [
			{ performanceImprovementPlanStatusId: 1, name: 'My' },
			{ performanceImprovementPlanStatusId: 2, name: 'All nominations' },
		],
		order: 0,
	},
	{
		label: 'By Status',
		performanceImprovementPlanStatusId: 'status',
		values: [
			{ performanceImprovementPlanStatusId: 1, name: 'Active' },
			{ performanceImprovementPlanStatusId: 0, name: 'Not Started' },
			{ performanceImprovementPlanStatusId: 2, name: 'Resolved' },
			{ performanceImprovementPlanStatusId: 3, name: 'No Timeframe' },
		],
		order: 1,
	},
	{
		label: 'By Category',
		performanceImprovementPlanStatusId: 'categoryFilters',
		values: [],
		order: 3,
	},
	{
		label: 'By Resolved Status',
		performanceImprovementPlanStatusId: 'resolvedStatus',
		values: [],
		order: 3,
	},
];

export const FeedbackTimeFrame = [
	{ id: 1, name: 'Mandatory' },
	{ id: 2, name: 'Non Mandatory' },
	{ id: 3, name: 'Not Required' },
];

export const FeedbackTimeFrameType = [
	{ id: 1, name: 'Manual' },
	{ id: 2, name: 'Select Duration' },
];
export const FeedbackEditTimeFrame = [
	{ id: 1, name: 'Allow editing start or end dates after creation' },
	{ id: 2, name: 'Do not allow editing dates' },
	{ id: 3, name: 'Allow editing end date only' },
];
export const FeedbackEditEndDateType = [
	{ id: 1, name: 'Allow manual edit to any value' },
	{ id: 2, name: 'Allow manual extension only' },
	{ id: 3, name: 'Auto extend based on days set' },
];
export const NatureMeetingOptions = [
	{ id: 1, label: 'Online' },
	{ id: 2, label: 'In-person' },
];

export const TeamsFilterOptions = [
	{ id: 0, label: 'Not Set' },
	{ id: 1, label: 'Draft' },
	{ id: 2, label: 'Approval Pending' },
	{ id: 3, label: 'Reconsider' },
	{ id: 4, label: 'Approved' },
];
export const InfoprolearningDomainName = 'infoprolearning.unlocku.com';
export const CompunnelDomainName = 'compunnel.unlocku.com';
export const PIFileFormat = [
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'text/csv',
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/vnd.ms-powerpoint',
	'application/msword',
	'text/plain',
];
export const PIFileExtension = ['msg', 'zip', 'tar', 'rar', 'vtt', 'mp4'];
