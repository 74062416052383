import React, { useState, useEffect } from 'react';
import { Enums } from '../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, MobileStepper, Typography } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { getPerfomanceCycleList, setPerformaceCurrentCycle } from '../../action/perform';
import { DownArrowIcon, LeftArrow, RightArrow } from '../../config/svg/ArrowSvg';
import { getMonthDateYear } from '../../config/utils';
import {
	getGoalAlertConversation,
	getGoalCycleSelectorApi,
	getPerformanceGoalReviewCycle,
} from '../../action/adminSettings';

export const CycleTab = (props: any) => {
	const { fromReview } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { employeeAssessmentCycleList } = useSelector((state: any) => state.performReducer);
	const [activeStep, setActiveStep] = useState(
		employeeAssessmentCycleList && employeeAssessmentCycleList.length > 0
			? employeeAssessmentCycleList.findIndex((item: any) => item.status === 2)
			: {}
	);
	const [cycleData, setCycleData] = useState<any[]>([]);
	const [maxSteps, setMaxSteps] = useState<number>(0);

	useEffect(() => {
		if (cycleData && cycleData[activeStep]) {
			dispatch(setPerformaceCurrentCycle(cycleData[activeStep]));
		}
	}, [activeStep]);

	useEffect(() => {
		dispatch(setPerformaceCurrentCycle(null));
		setActiveStep(null);
		if (fromReview) {
			dispatch(getPerformanceGoalReviewCycle());
		} else {
			dispatch(getPerfomanceCycleList());
			dispatch(getGoalCycleSelectorApi());
		}
		dispatch(getPerfomanceCycleList());
		dispatch(getGoalCycleSelectorApi());
	}, []);

	useEffect(() => {
		if (employeeAssessmentCycleList && employeeAssessmentCycleList.length > 0) {
			let data: any[] = employeeAssessmentCycleList.filter((item: any) => item.status === 2 || item.status === 3);
			let currentCycle: number = data.findIndex((item: any) => item.status === 2);
			if (currentCycle === -1) {
				currentCycle = data.findIndex((item: any) => item.isLastCompletedCycle);
			}
			setActiveStep(currentCycle);
			setCycleData(data);
			setMaxSteps(data.length);
		} else {
			dispatch(setPerformaceCurrentCycle(null));
			setActiveStep(null);
		}
	}, [employeeAssessmentCycleList]);

	const handleNext = () => {
		setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
		dispatch(getGoalAlertConversation());
	};

	return (
		<>
			{cycleData && cycleData.length > 0 && (
				<Box className='page-subheader-right-inner'>
					<Box className='tenure-navigation'>
						<Box className={`tenure-stepper`}>
							<OKRButton
								title={`${
									props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
										? t('futureCycle')
										: props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID
										? t('pastCycle')
										: ''
								}`}
								text={
									cycleData[activeStep]
										? getMonthDateYear(cycleData[activeStep].performancePeriodStartDate) +
										  ' - ' +
										  getMonthDateYear(cycleData[activeStep].performancePeriodEndDate)
										: ''
								}
								className='tenure-stepper-text'
								handleClick={() => {}}
								id='tenure-btn'
							/>

							<MobileStepper
								steps={maxSteps}
								position='static'
								variant='progress'
								activeStep={activeStep}
								nextButton={
									<OKRButton
										title={`${t('nextCycle')}`}
										className='tenure-next'
										id='tenure-btn-next'
										handleClick={handleNext}
										disabled={activeStep === maxSteps - 1}
										icon={<RightArrow />}
									/>
								}
								backButton={
									<OKRButton
										title={`${t('previousCycle')}`}
										className='tenure-previous'
										id='tenure-btn-previous'
										handleClick={handleBack}
										disabled={activeStep === 0}
										icon={<LeftArrow />}
									/>
								}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};
