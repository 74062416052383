import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	employeeAssessmentResponse: null,
	employeeAssessmentResponseStatus: false,
	selfAssessmentResponse: null,
	selfAssessmentResponseStatus: false,
};

export default function userPerformanceReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.GET_EMPLOYEE_ASSESSMENT_PENDING:
			return {
				...state,
				employeeAssessmentResponse: null,
				employeeAssessmentResponseStatus: false,
			};
		case actionTypes.GET_EMPLOYEE_ASSESSMENT_FAILED:
			return {
				...state,
				employeeAssessmentResponse: null,
				employeeAssessmentResponseStatus: false,
			};
		case actionTypes.GET_EMPLOYEE_ASSESSMENT_SUCCESS:
			return {
				...state,
				employeeAssessmentResponse: action.payload.entity,
				employeeAssessmentResponseStatus: true,
			};
		case actionTypes.GET_SELF_ASSESSMENT_PENDING:
			return {
				...state,
				selfAssessmentResponse: null,
				selfAssessmentResponseStatus: false,
			};
		case actionTypes.GET_SELF_ASSESSMENT_FAILED:
			return {
				...state,
				selfAssessmentResponse: null,
				selfAssessmentResponseStatus: false,
			};
		case actionTypes.GET_SELF_ASSESSMENT_SUCCESS:
			return {
				...state,
				selfAssessmentResponse: action.payload.entity,
				selfAssessmentResponseStatus: true,
			};
		default:
			return state;
	}
}
