import {
	Box,
	Avatar,
	FormControlLabel,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	Typography,
	Collapse,
	Tooltip,
	Chip,
	Badge,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteIcon, EditIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { getRandomBackgroundColor, getRandomColor, getTime, getUserDetails, getUserName } from '../../../config/utils';
import {
	deleteRecognizePost,
	getCommentsList,
	getLikesList,
	likeRecognization,
	praiseRecognizeId,
	saveRecognizePost,
} from '../../../action/recognize';
import { showLoader } from '../../../action/common';
import { Enums } from '../../../config/enums';
import { searchEmployee } from '../../../action/search';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { CommentIcon, LikeFilledIcon, LikeIcon, LinkTeamIcon } from '../../../config/svg/CommonSvg';
import { OKRPopper } from '../../Common/OKRPopper';
import AlertDialog from '../../Common/Dialog';
import { useSnackbar } from 'notistack';
import { CreateComment } from './CreateComment';
import { CreatePost } from '../RecognizePost/createPost';
import noRecognitionPost from '../../../images/no-recognition-post.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import LikeImg from '../../../images/Like.svg';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import teamFilledIcon from '../../../images/team-filled-icon.svg';
import { LearningIcon } from '../../../config/svg/DrawerSvg';

export const RecognizeList = (props: any) => {
	const { setLoader, commentRecognizationId, setCommentRecognizationId } = props;
	const [selectedUser, setSelectedUser] = React.useState<any>([]);
	const [postText, setPostText] = React.useState<any>('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const dispatch = useDispatch();
	let userDetail = getUserDetails();
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const [modalProps, setModalProps] = React.useState<any>({
		open: false,
		data: {},
	});
	const { enqueueSnackbar } = useSnackbar();

	const [checked, setChecked] = useState(false);

	const [editRecognizationId, setEditRecognizationId] = useState<number>(0);
	const [recognizeListData, setRecognizeListData] = useState<any>(null);
	const [likeData, setLikeData] = useState<any[]>([]);
	const [likedId, setLikedId] = useState<number>(0);
	const [likeAnimation, setLikeAnimation] = useState<number>(0);
	const [savedId, setSavedId] = useState(0);
	const [openOthersList, setOpenOthersList] = useState<boolean>(false);
	const [headLineListId, setHeadLineListId] = useState<number>(0);
	const [highlightComment, setHighlightComment] = useState(false);
	const {
		recognizePostResponse,
		recognizeBadgesMapping,
		recognizePagingInfo,
		recognizePostResponseStatus,
		recognizeId,
	} = useSelector((state: any) => state.recognizeReducer);

	useEffect(() => {
		if (props.selectedUser || recognizeId) {
			setCommentRecognizationId(recognizeId ? recognizeId : 0);
		}
	}, [recognizeId, props.selectedUser]);

	useEffect(() => {
		if (recognizePostResponseStatus === 'pending') {
			setLoader(true);
		} else {
			setLoader(false);
		}
	}, [recognizePostResponseStatus]);

	useEffect(() => {
		if (recognizePagingInfo?.pageIndex === 1) {
			setRecognizeListData(recognizePostResponse);

			//notification highlight
			let recognitionHighlight: any = getLocalStorageItem('recognitionHighlight');
			if (Boolean(recognitionHighlight)) {
				recognitionHighlight = JSON.parse(recognitionHighlight);
				if (recognitionHighlight.recognitionId > 0) {
					setLocalStorageItem('isAllLinkShow', true);

					//comment highlight
					let recognitionCommentHighlight: any = getLocalStorageItem('recognitionCommentHighlight');
					if (Boolean(recognitionCommentHighlight)) {
						recognitionCommentHighlight = JSON.parse(recognitionCommentHighlight);
						if (recognitionCommentHighlight.commentDetailsId > 0) {
							setCommentRecognizationId(recognitionHighlight.recognitionId);
							setHighlightComment(true);
						}
					}
				}
			}

			if (commentRecognizationId) {
				scrollTo('recog-post-comments-section-' + commentRecognizationId);
				setCommentRecognizationId(0);
			}

			setTimeout(() => {
				setSavedId(0);
				removeLocalStorageItem('recognitionHighlight');
				removeLocalStorageItem('recognitionCommentHighlight');
				dispatch(praiseRecognizeId(null));
			}, 7000);
		} else {
			if (recognizePostResponse) {
				const listData =
					recognizeListData && recognizeListData.length
						? [...recognizeListData, ...recognizePostResponse]
						: [...recognizePostResponse];
				setRecognizeListData(listData);
			} else if (recognizePostResponse === null) {
				setRecognizeListData([]);
			}
		}
	}, [recognizePostResponse]);

	const handleOpen = async (event: any, recognizeData: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
		setLikedId(recognizeData.recognitionId);
		let data = `moduleDetailsId=${recognizeData.recognitionId}&moduleId=2`;
		const response: any = await dispatch(getLikesList(data));
		if (Boolean(response) && response.data.status === 200) {
			setLikeData(response.data.entity.recognitionLikeResponses);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
		setLikeData([]);
		setLikedId(0);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	const handleChange = () => {
		setChecked((prev) => !prev);
	};

	const toggleMyPost = (event: any) => {
		props.getAllOrgRecognize(1, 10, event.target.checked);
	};
	const handleIsAllLinkShow = () => {
		setSavedId(0);
		setCommentRecognizationId(0);
		removeLocalStorageItem('isAllLinkShow');
		removeLocalStorageItem('recognitionHighlight');
		props.getAllOrgRecognize(1, 10);
	};

	const handleConfirmClick = async () => {
		let data = `recognitionId=${modalProps.data.recognitionId}`;
		const response: any = await dispatch(deleteRecognizePost(data));

		if (Boolean(response) && response.data.status === 200) {
			// enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
			// 	variant: 'success',
			// 	autoHideDuration: 5000,
			// });
			props.getAllOrgRecognize(1, 10, props.isMyPost);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const likeRecognizationList = async (item: any, like: any) => {
		setLikeAnimation(item.recognitionId);
		const response: any = await dispatch(
			likeRecognization({
				moduleDetailsId: item.recognitionId,
				moduleId: 2,
				employeeId: userDetail.employeeId,
				isActive: like,
			})
		);

		if (Boolean(response) && response.data.status === 200) {
			let counter = like ? 1 : -1;
			let recognizeListDataCopy = recognizeListData.map((listData: any) => listData);
			const index = recognizeListDataCopy.findIndex((listData: any) => item.recognitionId === listData.recognitionId);
			if (index > -1) {
				recognizeListDataCopy[index].isLiked = like;
				recognizeListDataCopy[index].totalLikeCount += counter;
			}
			setRecognizeListData(recognizeListDataCopy);
			//props.getAllOrgRecognize(1, 10, props.isMyPost);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			handleConfirmClick();
		}
		setModalProps({
			open: false,
			data: {},
		});
	};
	const handleDeleteRecognize = (handleDeleteRecognize: any) => {
		setModalProps({
			open: true,
			data: handleDeleteRecognize,
		});
		handleClose();
	};
	const handleEditRecognize = (editRecognize: any) => {
		setEditRecognizationId(editRecognize.recognitionId);
	};

	const getBadgeIcon = (item: any) => {
		if (item.attachmentImagePath) {
			return (
				<>
					<Box className='recognize-badge-box'>
						<Box className='recognize-badge-icon'>
							<Badge
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								className={'badge-anchor-bottom'}
							>
								<img src={item.attachmentImagePath} alt={item.AttachmentName} />
							</Badge>
						</Box>
						<Typography variant='h6'>{item.attachmentName}</Typography>
					</Box>
				</>
			);
		}
		return <></>;
	};

	const editSubmit = () => {
		props.getAllOrgRecognize(1, 10, props.isMyPost);
		setEditRecognizationId(0);
	};

	const getComments = async (data: any) => {
		if (commentRecognizationId !== 0 && commentRecognizationId === data.recognitionId) {
			setCommentRecognizationId(0);
		} else {
			setCommentRecognizationId(data.recognitionId);
		}
	};

	const handleOthersListOpen = (event: any, recognizeData: any) => {
		setAnchorEl(event.currentTarget);
		setOpenOthersList(true);
		setHeadLineListId(recognizeData.recognitionId);
	};
	const handleOthersListClose = () => {
		setOpenOthersList(false);
		setAnchorEl(null);
		setHeadLineListId(0);
	};

	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 50 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 300);
	};

	const updateRecogCommentCount = (recognitionId: any, add: boolean) => {
		let counter = add ? 1 : -1;
		let recognizeListDataCopy = recognizeListData.map((listData: any) => listData);
		const index = recognizeListDataCopy.findIndex((listData: any) => recognitionId === listData.recognitionId);
		if (index > -1) {
			recognizeListDataCopy[index].totalCommentCount += counter;
		}
		setRecognizeListData(recognizeListDataCopy);
	};
	return (
		<>
			{(!getLocalStorageItem('isAllLinkShow') || getLocalStorageItem('isAllLinkShow') === 'false') && props.canEdit && (
				<Box className='my-post-filter-actions'>
					<Box className='my-post-switch'>
						<FormControlLabel
							control={<Switch name='isMyPostFilter' onChange={toggleMyPost} />}
							label={t('myPostsLevel')}
							labelPlacement='end'
							className='toggle-label'
						/>
					</Box>
				</Box>
			)}
			{getLocalStorageItem('isAllLinkShow') === 'true' && (
				<Box className='back-to-main'>
					<OKRButton
						id='back-btn'
						icon={<BackArrow />}
						text={t('backToAllPosts')}
						handleClick={() => {
							handleIsAllLinkShow();
						}}
					/>
				</Box>
			)}

			<Box className='recog-posts-list-area'>
				{recognizeListData && recognizeListData.length > 0 ? (
					recognizeListData.map((recognizeData: any) =>
						editRecognizationId === recognizeData.recognitionId ? (
							<CreatePost
								getAllOrgRecognize={editSubmit}
								recognizeData={recognizeData}
								setEditRecognizationId={setEditRecognizationId}
								isMyPost={props.isMyPost}
							/>
						) : (
							<Box className='recog-post-list'>
								<Box className='recog-user-info'>
									<Box className='user-img'>
										{recognizeData?.receiverDetails?.length === 1 ? (
											recognizeData?.receiverDetails[0].receiverImagePath ? (
												<Avatar src={recognizeData?.receiverDetails[0].receiverImagePath} />
											) : (
												<Avatar>
													{getUserName({
														fullName: recognizeData?.receiverDetails[0].receiverName,
														firstName: null,
														lastName: null,
													})}
												</Avatar>
											)
										) : (
											<Avatar className='common-avatar' src={teamFilledIcon} />
										)}
									</Box>
								</Box>
								<Box className='recog-post-box'>
									<Box className='recog-post-box-inner'>
										<Box className='recog-post-head'>
											<Box className='recog-post-head-left'>
												<Box className='recog-post-head-left-top'>
													{/* {recognizeData.receiverDetails.map((recieverData: any, index: number) => (
														<Typography>
															{index === 1 && ', '}&nbsp;
															{index <= 1 && recieverData.receiverName}
															{index === 1 && recognizeData.receiverDetails.length > 2 && ', '}
														</Typography>
													))} */}
													<List className='recog-post-users' disablePadding>
														{recognizeData.receiverDetails.map((recieverData: any, index: number) => (
															<>
																{index <= 1 && <ListItem> {recieverData.receiverName}</ListItem>}
																{recognizeData.receiverDetails.length > 2 && ''}
															</>
														))}
													</List>
													{recognizeData.receiverDetails.length > 2 && (
														<Box
															className='others-lists'
															aria-owns={openOthersList ? 'mouse-over-popover' : undefined}
															aria-haspopup='true'
															onMouseOver={(e: any) => {
																handleOthersListOpen(e, recognizeData);
															}}
															//onMouseLeave={handleOthersListClose}
														>
															<Typography>
																{'+ '}
																{recognizeData.receiverDetails.length - 2} others
															</Typography>
														</Box>
													)}
													{openOthersList && recognizeData.recognitionId === headLineListId && (
														<Box onMouseLeave={handleOthersListClose}>
															<PopperMenu
																{...props}
																children={
																	<>
																		<Typography variant='h6' className='font-weight-normal'>
																			{t('recognizedTo')}:
																		</Typography>
																		{recognizeData.receiverDetails.map((headList: any, index: number) => {
																			return (
																				index > 1 && (
																					<ListItem>
																						<Chip
																							className={`${
																								headList.searchType === 2 ? 'user-team-chip' : 'user-chip'
																							}`}
																							style={{
																								backgroundColor: getRandomBackgroundColor(
																									headList?.backGroundColorCode
																								),
																								color: getRandomColor(headList?.colorCode),
																							}}
																							avatar={
																								headList?.receiverImagePath ? (
																									<Avatar
																										className='avatar-default'
																										alt={headList?.receiverName}
																										src={headList?.receiverImagePath}
																									/>
																								) : (
																									<Avatar
																										style={{
																											backgroundColor: getRandomBackgroundColor(
																												headList?.backGroundColorCode
																											),
																											color: getRandomColor(headList?.colorCode),
																										}}
																										src={headList?.receiverImagePath}
																									>
																										{getUserName({
																											firstName: '',
																											lastName: '',
																											fullName: headList?.receiverName,
																										})}
																									</Avatar>
																								)
																							}
																							label={headList?.receiverName}
																						/>
																					</ListItem>
																				)
																			);
																		})}
																	</>
																}
																popperClassName={'others-lists-popup'}
																anchorEl={anchorEl}
																handleClose={handleOthersListClose}
																open={openOthersList}
																popperPlacement={'bottom-start'}
															/>
														</Box>
													)}
												</Box>
												<Box className='recog-post-head-left-bottom'>
													<Typography variant='h6' className='font-weight-normal'>
														{t('recognizedBy')}: &nbsp; {recognizeData.senderFullName}
													</Typography>
													<Typography variant='h6' className='font-weight-normal'>
														{recognizeData.updatedOn
															? getTime(recognizeData.updatedOn)
															: getTime(recognizeData.createdOn)}{' '}
														{recognizeData.isEdited ? `(${t('editedLabel')})` : ''}
													</Typography>
												</Box>
											</Box>
											<Box className='recog-post-head-right'>
												{recognizeData.senderEmployeeId === userDetail.employeeId && (
													<PopperMenu
														popperClassName={'popper-menu recog-post-popper-menu'}
														anchorEl={anchorEl}
														handleClose={handleCloseMenu}
														includeKebabMenu={true}
														menuClicked={popperMenuClicked}
														setPopperMenuClicked={setPopperMenuClicked}
														{...props}
														children={
															<>
																<ListItem
																	onClick={() => {
																		handleEditRecognize(recognizeData);
																	}}
																>
																	<ListItemAvatar>
																		<EditIcon />
																	</ListItemAvatar>
																	<ListItemText primary={t('editLabel')} />
																</ListItem>

																<ListItem
																	onClick={() => {
																		handleDeleteRecognize(recognizeData);
																	}}
																>
																	<ListItemAvatar>
																		<DeleteIcon />
																	</ListItemAvatar>
																	<ListItemText primary={t('delete')} />
																</ListItem>
															</>
														}
													/>
												)}
											</Box>
										</Box>
										<Box className='recog-post-content long-text'>
											{getBadgeIcon(recognizeData)}
											<Typography
												variant='h5'
												className='font-weight-normal'
												dangerouslySetInnerHTML={{ __html: recognizeData.message }}
											></Typography>
										</Box>
										<Box className='recog-post-footer'>
											<Box className='recog-post-actions-left'>
												{!recognizeData.isLiked ? (
													<OKRButton
														className='like-btn'
														icon={<LikeIcon />}
														handleClick={() => {
															likeRecognizationList(recognizeData, true);
														}}
													/>
												) : (
													<Box className='like-filled-btn'>
														<OKRButton
															className={`like-btn ${likeAnimation === recognizeData.recognitionId ? 'btn-anim' : ''}`}
															icon={
																<>
																	{likeAnimation === recognizeData.recognitionId && (
																		<AnimatedIcon
																			className='like-animation-img'
																			width='60'
																			type='image/svg+xml'
																			alt='No user'
																			imagePath={LikeImg}
																		/>
																	)}
																	<LikeFilledIcon />
																</>
															}
															handleClick={() => {
																likeRecognizationList(recognizeData, false);
																setLikeAnimation(0);
															}}
														/>
													</Box>
												)}
												<OKRButton
													className='comment-btn'
													icon={<CommentIcon />}
													text={t('commentLabel')}
													handleClick={() => {
														getComments(recognizeData);
													}}
												/>
											</Box>
											<Box className='recog-post-actions-right'>
												<List disablePadding>
													{
														<ListItem
															aria-owns={open ? 'mouse-over-popover' : undefined}
															aria-haspopup='true'
															onMouseEnter={(e: any) => {
																recognizeData.totalLikeCount > 0 && handleOpen(e, recognizeData);
															}}
															onMouseLeave={handleClose}
														>
															<ListItemText
																className={recognizeData.totalLikeCount > 0 ? 'like-popup' : ''}
																primary={`${recognizeData.totalLikeCount} ${
																	recognizeData.totalLikeCount > 1 ? t('likedLevel') : t('likeLevel')
																}`}
															/>
															{open && recognizeData.recognitionId === likedId && (
																<Box>
																	<PopperMenu
																		{...props}
																		children={
																			<>
																				{likeData.length > 0 &&
																					likeData.map((likeList: any) => {
																						return (
																							<ListItem>
																								<Box className='user-info'>
																									<Box className='user-img'>
																										{likeList?.imagePath ? (
																											<Avatar src={likeList?.imagePath} />
																										) : (
																											<Avatar>
																												{getUserName({
																													fullName: '',
																													firstName: likeList?.firstName,
																													lastName: likeList?.lastName,
																												})}
																											</Avatar>
																										)}
																									</Box>
																									<Box className='user-info-details'>
																										{likeList.fullName.length > 30 ? (
																											<Tooltip title={likeList.fullName} arrow>
																												<Typography variant='subtitle2'>
																													{likeList.fullName.substring(0, 30)}
																													...
																												</Typography>
																											</Tooltip>
																										) : (
																											<Typography>{likeList.fullName}</Typography>
																										)}

																										{likeList.emailId.length > 28 ? (
																											<Tooltip title={likeList.emailId} arrow>
																												<Typography variant='subtitle2'>
																													{likeList.emailId.substring(0, 28)}
																													...
																												</Typography>
																											</Tooltip>
																										) : (
																											<Typography variant='subtitle2'>{likeList.emailId}</Typography>
																										)}
																									</Box>
																								</Box>
																							</ListItem>
																						);
																					})}
																			</>
																		}
																		popperClassName={'recog-liked-by-dropdown'}
																		anchorEl={anchorEl}
																		handleClose={handleClose}
																		popperPlacement={'bottom'}
																		open={open}
																	/>
																</Box>
															)}
														</ListItem>
													}

													<ListItem>
														<OKRButton
															text={`${recognizeData.totalCommentCount} ${t('commentsLabel')}`}
															handleClick={() => {
																getComments(recognizeData);
															}}
														/>
													</ListItem>
												</List>
											</Box>
										</Box>
									</Box>
									{recognizeData.totalLikeCount === 0 &&
										recognizeData.totalCommentCount === 0 &&
										recognizeData.recognitionId !== commentRecognizationId && (
											<Box className='recog-post-note'>
												<Typography variant='h6' className='font-weight-normal'>
													{t('beTheFirstToReact')}
												</Typography>
											</Box>
										)}
									<Collapse
										className='recog-post-comments-trail'
										in={recognizeData.recognitionId === commentRecognizationId}
									>
										<Box id={`recog-post-comments-section-${recognizeData.recognitionId}`}>
											{recognizeData.recognitionId === commentRecognizationId && (
												<CreateComment
													recognitionId={recognizeData.recognitionId}
													getAllOrgRecognize={props.getAllOrgRecognize}
													isMyPost={props.isMyPost}
													savedId={savedId}
													setHighlightComment={setHighlightComment}
													highlightComment={highlightComment}
													updateRecogCommentCount={updateRecogCommentCount}
												/>
											)}
										</Box>
									</Collapse>
								</Box>
							</Box>
						)
					)
				) : props.isMyPost ? (
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography variant='h2'>{t('noDataForSelfView')}</Typography>
							<Typography>{t('noDataForSelfViewSubText')}</Typography>
							<img width='352' alt={t('noDataForSelfView')} src={noRecognitionPost} />
						</Box>
					</Box>
				) : props.selectedUser && props.selectedUser?.employeeId ? (
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography variant='h2'>{t('noDataForPeopleView')}</Typography>
							<img width='352' alt={t('noDataForPeopleView')} src={noRecognitionPost} />
						</Box>
					</Box>
				) : recognizeListData && recognizeListData.length === 0 && recognizePostResponseStatus !== 'pending' ? (
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography variant='h2'>{t('noDataForSelfView')}</Typography>
							<Typography>{t('noDataForSelfViewSubText')}</Typography>
							<img width='352' alt={t('noDataForSelfView')} src={noRecognitionPost} />
						</Box>
					</Box>
				) : (
					<></>
				)}
			</Box>

			{recognizePagingInfo?.totalPages > 1 && recognizePagingInfo?.totalPages !== props.pageIndex && (
				<Box className='load-more-action'>
					<OKRButton
						handleClick={(e) => {
							props.getAllOrgRecognize(props.pageIndex + 1 || 1, 10, props.isMyPost);
						}}
						className={`load-more-btn`}
						text={t('loadMore')}
					/>
				</Box>
			)}

			{modalProps.open && (
				<AlertDialog
					module='information'
					message={t('deletePost')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
