import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, FormLabel, Typography, TextField, ButtonGroup, Avatar, Tooltip } from '@material-ui/core';
import {
	AspirationalIcon,
	IndividualIcon,
	CommittedIcon,
	TeamIcon,
	VirtualDeleteIcon,
	VirtualLinkContributorIcon,
} from '../../../config/svg/CommonSvg';
import { Enums } from '../../../config/enums';
import {
	getCurrentCycleListWithQuarters,
	getDueDays,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
import { LinkObjectivePopover } from '../LinkObjectivePopover';
import { TeamsOkr } from '../TeamsOkr/TeamsOkr';
import { CallOutTooltip } from '../../OkrAction/CallOutTooltip';
import { calloutsDataForObjective, defaultCalloutProps } from '../../../config/constant';
import { ErrorIcon } from '../../../config/svg/formElementIcons';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { OkrTeamDropdown } from '../../Common/OkrTeamDropdown';
import { InformationMessage } from '../../Admin/CommonComponent/InformationMessage';

export const GoalAttributeDetail: React.FC<any> = (props) => {
	const {
		t,
		okrMasterData,
		durationCycleDetail,
		currentCycleEndDate,
		objectiveDetail,
		goalAttrDateError,
		setIsSaveDisabled,
		goalsAttribute,
		setGoalsAttribute,
		goalDetail,
		updateGoalDueDate,
		updateGoalStartDate,
		updateStartDate,
		updateDueDate,
		activeOnboardingPanel,
		showPrevForSubmitCallouts,
		setShowPrevForSubmitCallouts,
		isNewOkr,
		setGaolAttrDateError,
		resToUseInCalendar,
		editModee,
		setEditModee,
		updateGoalDateRangeCycleChangeUpdate,
		currentCycleId,
		isLocked,
	} = props;

	const [currentGoalId, setCurrentGoalId] = useState<number>(0);
	const [selectedTeams, setSelectedTeams] = React.useState<any>([]);
	const [treeNodesData, setTreeNodesData] = React.useState<any[] | null>(null);
	const loggedInUserDetail = getUserDetails();
	const [isReset, setIsReset] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>(null);

	const [linkObjectiveOpen, setLinkObjectiveOpen] = useState<boolean>(false);
	const [linkedObjectiveData, setLinkedObjectiveData] = useState<any[]>([]);
	const [linkObjectiveLoading, setlinkObjectiveLoading] = useState<boolean>(false);
	const [linkObjectiveUserAnchorEl, setLinkObjectiveUserAnchorEl] = useState<HTMLElement | null>(null);
	const [linkObjectiveUserOpen, setLinkObjectiveUserOpen] = useState<boolean>(false);
	const [isLinkObjectiveInitiated, setIsLinkObjectiveInitiated] = useState<boolean>(false);
	const [nature, setNature] = useState<number>(0);
	//callout popup
	const [showCallouts, setShowCallouts] = useState<boolean>(false);

	// Added below code for Date Range Picker ==> Start
	const cycleId = getLocalStorageItem('cycleId') && parseInt(getLocalStorageItem('cycleId') || '0');
	const cycleDetail = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(null);
	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	const [allCycleDetails, setAllCycleDetails] = useState<any>(cycleDetail);
	const [allCycleList, setAllCycleList] = useState<any>(cycleDetail);
	const [selectionType, setSelectionType] = useState<any>('range');
	const initialCallout = defaultCalloutProps;
	const calloutsData = calloutsDataForObjective;
	const [callOut, setCallOut] = useState(initialCallout);
	const [anchorElCallouts, setAnchorElCallouts] = useState(null);
	const inputRefOkrTitle: any = useRef();
	const inputRefOkrDescription: any = useRef();
	const inputRefOkrGoalType: any = useRef();
	const inputRefOkrOwner: any = useRef();
	const inputRefOkrDate: any = useRef();
	const virtualLinkRef: any = useRef();

	useEffect(() => {
		if (allCycleDetails && allCycleDetails.cycleDetails && allCycleDetails.cycleDetails.length) {
			allCycleDetails.cycleDetails.forEach((cycleDetails: any) => {
				const currentQuarterDetails = cycleDetails.quarterDetails.find(
					(item: any) => item.organisationCycleId === cycleId
				);
				if (currentQuarterDetails) {
					setSelectedCycleDetails(
						objectiveDetail?.cycleDetailData || { ...currentQuarterDetails, year: cycleDetails?.year }
					);
					setIsCurrentCycle({ ...currentQuarterDetails, year: cycleDetails?.year });
				}
			});
			setAllCycleList(getCurrentCycleListWithQuarters(allCycleDetails));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allCycleDetails]);
	useEffect(() => {
		if (selectionType === 'range') {
			const startDateValue = goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate;
			const endDateValue = goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate;
			updateDueDays(startDateValue, endDateValue, selectionType);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getUserTeams();
	}, []);

	useEffect(() => {
		if (getLocalStorageItem('showCallouts')) {
			setShowCallouts(true);
		}
	}, [props.onboardingCount]);

	useEffect(() => {
		if (
			activeOnboardingPanel === 'okrDrawer' &&
			getLocalStorageItem('okrDrawercalloutDone') === null &&
			getLocalStorageItem('showCallouts') === 'true'
		) {
			setShowCallouts(true);
			const timeout = setTimeout(() => {
				inputRefOkrTitle.current.focus();
				//Callout for OKR Title Field
				setAnchorElCallouts(inputRefOkrTitle.current);
				setCallOut(calloutsData[0]);
			}, 1000);
			return () => {
				clearTimeout(timeout);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOnboardingPanel]);

	useEffect(() => {
		if (showPrevForSubmitCallouts) {
			setAnchorElCallouts(virtualLinkRef.current);
			setCallOut(calloutsData[5]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPrevForSubmitCallouts]);

	const nextCallout = (currentCalloutIndex: Number) => {
		if (currentCalloutIndex === 0) {
			setTimeout(() => {
				//Callout for OKR Title Field
				setAnchorElCallouts(inputRefOkrDescription.current);
				setCallOut(calloutsData[1]);
			}, 100);
		} else if (currentCalloutIndex === 1) {
			if (loggedInUserDetail.isTeamLeader && nature !== 0) {
				if (inputRefOkrGoalType.current) {
					if (loggedInUserDetail.isTeamLeader) {
						setAnchorElCallouts(inputRefOkrGoalType.current);
						setCallOut({
							...calloutsData[2],
							text: `${t('teamAlignGuidedText')}`,
							currentCallout: 'teamAlign',
						});
					} else {
						setAnchorElCallouts(inputRefOkrGoalType.current);
						setCallOut({
							...calloutsData[2],
							text: `${t('individualAlignGuidedText')}`,
							currentCallout: 'individualAlign',
						});
					}
				}
			} else {
				const element = document.getElementById('inputRefAVirtualLink');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				setAnchorElCallouts(inputRefOkrDate.current);
				setCallOut(calloutsData[4]);
			}
		} else if (currentCalloutIndex === 2) {
			const element = document.getElementById('inputRefAVirtualLink');
			if (element && getLocalStorageItem('showCallouts')) {
				element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}
			if (selectedTeams.length > 0 && selectedTeams[0].organisationHead !== loggedInUserDetail.employeeId) {
				setAnchorElCallouts(inputRefOkrOwner.current);
				setCallOut(calloutsData[3]);
			} else {
				setAnchorElCallouts(inputRefOkrDate.current);
				setCallOut(calloutsData[4]);
			}
		} else if (currentCalloutIndex === 3) {
			if (selectedTeams.length > 0 && selectedTeams[0].organisationHead !== loggedInUserDetail.employeeId) {
				const element = document.getElementById('inputRefAVirtualLink');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				setAnchorElCallouts(inputRefOkrDate.current);
				setCallOut(calloutsData[4]);
			}
		} else if (currentCalloutIndex === 4) {
			setAnchorElCallouts(virtualLinkRef.current);
			setCallOut(calloutsData[5]);
		} else if (currentCalloutIndex === 5) {
			props.setShowSubmitCallouts(true);
			setShowPrevForSubmitCallouts(false);
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	const prevCallout = (currentCalloutIndex: Number) => {
		if (currentCalloutIndex === 0) {
		} else if (currentCalloutIndex === 1) {
			setAnchorElCallouts(inputRefOkrTitle.current);
			setCallOut(calloutsData[0]);
		} else if (currentCalloutIndex === 2) {
			setAnchorElCallouts(inputRefOkrDescription.current);
			setCallOut(calloutsData[1]);
		} else if (currentCalloutIndex === 3) {
			if (loggedInUserDetail.isTeamLeader && nature !== 0) {
				const element = document.getElementById('obj-name');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				if (loggedInUserDetail.isTeamLeader) {
					setAnchorElCallouts(inputRefOkrGoalType.current);
					setCallOut({
						...calloutsData[2],
						text: `${t('teamAlignGuidedText')}`,
						currentCallout: 'teamAlign',
					});
				} else {
					setAnchorElCallouts(inputRefOkrGoalType.current);
					setCallOut({
						...calloutsData[2],
						text: `${t('individualAlignGuidedText')}`,
						currentCallout: 'individualAlign',
					});
				}
			} else {
				setAnchorElCallouts(inputRefOkrDescription.current);
				setCallOut(calloutsData[1]);
			}
		} else if (currentCalloutIndex === 4) {
			if (selectedTeams.length > 0 && selectedTeams[0].organisationHead !== loggedInUserDetail.employeeId) {
				if (inputRefOkrGoalType.current) {
					setAnchorElCallouts(inputRefOkrOwner.current);
					setCallOut(calloutsData[3]);
				}
			} else {
				const element = document.getElementById('obj-name');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				if (loggedInUserDetail.isTeamLeader && nature !== 0) {
					if (loggedInUserDetail.isTeamLeader) {
						setAnchorElCallouts(inputRefOkrGoalType.current);
						setCallOut({
							...calloutsData[2],
							text: `${t('teamAlignGuidedText')}`,
							currentCallout: 'teamAlign',
						});
					} else {
						setAnchorElCallouts(inputRefOkrGoalType.current);
						setCallOut({
							...calloutsData[2],
							text: `${t('individualAlignGuidedText')}`,
							currentCallout: 'individualAlign',
						});
					}
				} else {
					setAnchorElCallouts(inputRefOkrDescription.current);
					setCallOut(calloutsData[1]);
				}
			}
		} else if (currentCalloutIndex === 5) {
			setAnchorElCallouts(inputRefOkrDate.current);
			setCallOut(calloutsData[4]);
		} else if (currentCalloutIndex === 6) {
			setAnchorElCallouts(virtualLinkRef.current);
			setCallOut(calloutsData[5]);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	useEffect(() => {
		if (objectiveDetail.teamOkrRequests?.length > 0) {
			goalsAttribute.goalTypeId = 1;
		}
		if (
			objectiveDetail.linkedObjective &&
			objectiveDetail.linkedObjective.objectiveId &&
			!goalsAttribute.linkedObjective
		) {
			goalsAttribute.linkedObjective = objectiveDetail.linkedObjective;
			goalsAttribute.linkedObjectiveId = objectiveDetail.linkedObjectiveId;
		}
		if (goalsAttribute && goalsAttribute.goalTypeId) {
			setCurrentGoalId(goalsAttribute.goalTypeId);
			setGaolAttrDateError({});
			let canSetTeam = true;
			if (goalDetail.actionType === 'editGoal' && goalsAttribute.isMyTeamOkr === false) {
				canSetTeam = false;
			}
			if (goalsAttribute.goalTypeId === 1) {
				if (
					objectiveDetail.teamOkrRequests.length > 0 &&
					(!goalsAttribute.teamOkrRequests || goalsAttribute.teamOkrRequests.length === 0)
				) {
					let selected: any = [];
					objectiveDetail.teamOkrRequests.forEach((element: any) => {
						selected.push({
							...element,
							teamName: objectiveDetail.teamName,
							teamLogo: objectiveDetail.teamLogo,
							teamMembersCount: objectiveDetail.teamMembersCount,
						});
					});
					if (canSetTeam && (selectedTeams.length === 0 || selectedTeams[0].id !== selected[0].id)) {
						setSelectedTeams(selected);
					}
				} else if (
					goalsAttribute.teamOkrRequests &&
					goalsAttribute.teamOkrRequests.length !== 0 &&
					objectiveDetail.teamOkrRequests.length !== 0
				) {
					if (goalsAttribute.teamOkrRequests.length > 0) {
						let selected: any = [];
						goalsAttribute.teamOkrRequests.forEach((element: any) => {
							selected.push({
								...element,
								teamName: goalsAttribute.teamName,
								teamLogo: goalsAttribute.teamLogo,
								teamMembersCount: goalsAttribute.teamMembersCount,
								colorCode: goalsAttribute.colorCode,
								backGroundColorCode: goalsAttribute.backGroundColorCode,
							});
						});
						if (canSetTeam && (selectedTeams.length === 0 || selectedTeams[0].id !== selected[0].id)) {
							setSelectedTeams(selected);
						}
					}
				} else if (
					goalsAttribute.teamOkrRequests &&
					goalsAttribute.teamOkrRequests.length !== 0 &&
					objectiveDetail.teamOkrRequests.length === 0
				) {
					if (goalsAttribute.teamOkrRequests.length > 0) {
						let selected: any = [];
						goalsAttribute.teamOkrRequests.forEach((element: any) => {
							selected.push({
								...element,
								teamName: goalsAttribute.teamName,
								teamLogo: goalsAttribute.teamLogo,
								teamMembersCount: goalsAttribute.teamMembersCount,
								colorCode: goalsAttribute.colorCode,
								backGroundColorCode: goalsAttribute.backGroundColorCode,
							});
						});
						if (canSetTeam && (selectedTeams.length === 0 || selectedTeams[0].id !== selected[0].id)) {
							setSelectedTeams(selected);
						}
					}
				}
			}
		} else if (objectiveDetail.goalTypeId !== goalsAttribute.goalTypeId) {
			setCurrentGoalId(objectiveDetail.goalTypeId);
			setGoalsAttribute({
				...goalsAttribute,
				goalTypeId: objectiveDetail.goalTypeId,
			});
		}

		if (objectiveDetail?.goalNatureId && !goalsAttribute?.goalNatureId) {
			setNature(objectiveDetail.goalNatureId);
			setGoalsAttribute({
				...goalsAttribute,
				goalNatureId: objectiveDetail.goalNatureId,
			});
		} else if (goalsAttribute?.goalNatureId) {
			setNature(goalsAttribute.goalNatureId);
		}

		if (!loggedInUserDetail.isTeamLeader && !goalsAttribute.goalTypeId) {
			setGoalsAttribute({
				...goalsAttribute,
				goalTypeId: 2,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalsAttribute]);

	useEffect(() => {
		if (currentGoalId === 2) {
			setSelectedTeams([]);
		}
	}, [currentGoalId]);

	const resetObjectiveContributor = () => {
		let callReset: boolean = false;
		const dataToCheck = goalDetail.actionType === 'editGoal' ? goalsAttribute : objectiveDetail;
		if (dataToCheck && dataToCheck.myGoalsDetails) {
			dataToCheck.myGoalsDetails.forEach((kr: any) => {
				if (
					kr.contributors &&
					kr.contributors.filter((item: any) => parseInt(item.employeeId) !== loggedInUserDetail.employeeId).length > 0
				) {
					callReset = true;
				}
			});
		}
		return callReset;
	};

	const assignmentSetter = (item: any, teamData: any) => {
		setCurrentGoalId(item.goalTypeId);
		if (item?.goalTypeId && parseInt(item.goalTypeId) === 1) {
			setGoalsAttribute({
				...goalsAttribute,
				goalTypeId: 1,
				isMyTeamOkr: true,
				teamOkrRequests: teamData,
				backGroundColorCode: teamData[0].backGroundColorCode,
				colorCode: teamData[0].colorCode,
				teamId: teamData[0].id,
				teamName: teamData[0].teamName,
				teamLogo: teamData[0].imagePath,
				teamMembersCount: teamData[0].membersCount ? teamData[0].membersCount : teamData[0].teamMembersCount,
				selectedTeamBackgroundColor: teamData[0].backGroundColorCode,
				selectedTeamTextColor: teamData[0].colorCode,
				isCoach: !teamData[0].isMyTeam ? true : false,
				owner: teamData[0].organisationHead,
				ownerFirstName: teamData[0].leaderFirstName,
				ownerLastName: teamData[0].leaderLastName,
				ownerImagePath: teamData[0].leaderImagePath,
			});
		} else {
			objectiveDetail.teamOkrRequests = [];
			objectiveDetail.teamId = 0;
			objectiveDetail.teamName = '';
			setGoalsAttribute({
				...goalsAttribute,
				isMyTeamOkr: true,
				goalTypeId: 2,
				teamId: 0,
				teamName: '',
				teamOkrRequests: [],
			});
		}
	};
	const resetCon = async () => {
		await props.resetContributor();
	};

	const getUserTeams = async () => {
		const apiRes = await props.getUserTeamsData('?goalType=1&empId=' + loggedInUserDetail?.employeeId);
		setTreeNodesData(null);
		if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
			const filteredDataset = apiRes?.data?.entityList;
			const otherTeams =
				filteredDataset && filteredDataset.length ? filteredDataset.filter((item: any) => !item.isMyTeam) : [];
			const myTeams =
				filteredDataset && filteredDataset.length ? filteredDataset.filter((item: any) => item.isMyTeam) : [];
			prepareTeamData(filteredDataset || []);
		}
	};
	const prepareTeamNodeData = (orgData: any, secondaryText?: string) => {
		return {
			label: orgData.organisationName,
			value: orgData.organisationId,
			imagePath: orgData.imagePath,
			secondaryText: t('membersLabel'),
			count: orgData.membersCount,
			backGroundColorCode: orgData.backGroundColorCode,
			colorCode: orgData.colorCode,
			teamLogo: orgData.imagePath,
			id: orgData.organisationId,
			teamId: orgData.organisationId,
			teamName: orgData.organisationName,
			teamMembersCount: orgData.membersCount,

			children: [],
			isParent: true,
			parentTeamBackGroundColorCode: orgData.parentTeamBackGroundColorCode,
			parentTeamColorCode: orgData.parentTeamColorCode,
			organisationHead: orgData.organisationHead,
			isMyTeam: orgData.isMyTeam,
			leaderDesignation: orgData.leaderDesignation,
			leaderFirstName: orgData.leaderFirstName,
			leaderImagePath: orgData.leaderImagePath,
			leaderLastName: orgData.leaderLastName,
		};
	};
	const prepareTeamData = (data: any) => {
		let completeOrgData: any = [];
		for (let ii = 0; ii < data?.length; ii++) {
			let orgData = data[ii];
			let teamObj = prepareTeamNodeData(orgData, orgData.parentName);
			completeOrgData.push(teamObj);
		}
		let selVal: string[] = [];
		selectedTeams.forEach((selectedT: any) => {
			completeOrgData.forEach((orgData: any) => {
				if (orgData.id === selectedT.id) {
					orgData.isChecked = true;
					orgData.selected = true;
				}
			});
		});
		setTreeNodesData(completeOrgData);
	};

	const handleCloseModal = (e: any, action: any) => {
		if (action === 1) {
			if (modalProps.type === 'updateDateForKR') {
				const { dateDetails } = modalProps.data;
				if (dateDetails) {
					updateStartDate(dateDetails?.startDate.date || modalProps.data.date);
					updateDueDate(
						dateDetails?.endDate.date || modalProps.data.date,
						dateDetails?.startDate.date || modalProps.data.date
					);
					dateDetails?.range && setSelectedDateRange(dateDetails?.range);
					updateDueDays(dateDetails?.startDate?.date, dateDetails?.endDate?.date, selectionType);
				}
				// if (modalProps.data.type === 'startDate') {
				// 	updateStartDate(modalProps.data.date);
				// } else if (modalProps.data.type === 'dueDate') {
				// 	updateDueDate(modalProps.data.date);
				// }
			} else {
				setModalProps({ ...modalProps, open: false });
				resetObjectiveId({ ...modalProps, open: false });
			}
		} else {
			if (modalProps.type === 'private') {
				objectiveDetail.isPrivate = !modalProps.data;
				setGoalsAttribute({ ...goalsAttribute, isPrivate: !modalProps.data });
			}
			if (modalProps.type === 'updateDateForKR') {
				selectedDateRange && setSelectedDateRange(selectedDateRange);
			}
			setIsReset(false);
		}
		setModalProps({ open: false, data: '', type: '' });
	};
	const resetObjectiveId = async (modalProps: any) => {
		if (goalsAttribute.goalObjectiveId) {
			const apiRes = await props.resetObjective(
				'employeeId=' +
					goalsAttribute.employeeId +
					'&goalObjectiveId=' +
					goalsAttribute.goalObjectiveId +
					'&goalType=' +
					modalProps.data.goalTypeId
			);
		}
		assignmentSetter({ goalTypeId: modalProps.data.teamData.length > 0 ? 1 : 2 }, modalProps.data.teamData);
		if (modalProps.callReset === true) {
			await resetCon();
		}
		setModalProps({ open: false, data: '', type: '' });
	};

	const onHandleLinkObjectiveClick = async (event: any) => {
		if (
			isLocked ||
			goalsAttribute.isDefaultGoalObjective ||
			(goalsAttribute.goalObjectiveId &&
				goalsAttribute?.goalObjectiveId !== 0 &&
				goalsAttribute.createdBy !== loggedInUserDetail.employeeId &&
				goalsAttribute.owner !== loggedInUserDetail.employeeId)
		) {
		} else {
			setLinkObjectiveUserAnchorEl(linkObjectiveUserAnchorEl ? null : event.currentTarget);
			setLinkObjectiveUserOpen(true);
			scrollTo();
			setLinkObjectiveOpen(true);
		}
	};
	const fetchUserObjectiveList = async (userData: any) => {
		setlinkObjectiveLoading(true);
		const apiRes = await props.getLinkObjectiveData(
			'?searchEmployeeId=' + userData.employeeId + '&searchEmployeeCycleId=' + userData.cycleId
		);
		if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
			setLinkedObjectiveData(apiRes.data.entityList);
		}
		setlinkObjectiveLoading(false);
		setLinkObjectiveOpen(true);
		scrollTo();
	};
	const linkSelected = (selectedLink: any) => {
		setGoalsAttribute({
			...goalsAttribute,
			linkedObjective: selectedLink,
			linkedObjectiveId: selectedLink ? selectedLink.objectiveId : 0,
		});
	};

	const scrollTo = () => {
		setTimeout(function () {
			let element = document.getElementById('objective-link');
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 200);
	};

	const hasContributor = (goalsAttribute: any) => {
		const krWithContributor =
			goalsAttribute &&
			goalsAttribute.myGoalsDetails &&
			goalsAttribute.myGoalsDetails.filter((item: any) => item.contributors && item.contributors.length > 0);
		if (krWithContributor && krWithContributor.length > 0) {
			return true;
		}
		return false;
	};

	const handleNatureOfOkr = (value: any) => {
		setNature(value);
		setGaolAttrDateError({});
		setGoalsAttribute({
			...goalsAttribute,
			goalNatureId: value,
		});
	};

	const shouldDateChangePopup = (okrData: any, date: any) => {
		return (
			new Date(date).toDateString() !== new Date(okrData.endDate).toDateString() &&
			okrData?.myGoalsDetails?.filter((item: any) => item.keyDescription.length > 0).length > 0
		);
	};
	// Added below code for Date Range Picker ==> Start
	const dateChangeHandler = (
		range: any,
		start: any,
		end: any,
		selectionType: any,
		isCycleChanged: Boolean,
		selectedQuarterData: any
	) => {
		if (isCycleChanged) {
			updateGoalStartDate(start, false);
			updateGoalDueDate(end, false, start);
			range && setSelectedDateRange(range);
			updateDueDays(start, end, selectionType);
			updateGoalDateRangeCycleChangeUpdate(selectedQuarterData);
		} else {
			const dateDetails = { startDate: { date: start }, endDate: { date: end }, range };
			if (start) {
				if (goalDetail && goalDetail.showAttributeDrawer && goalDetail.actionType === 'editGoal') {
					// setModalProps({
					// 	open: true,
					// 	type: 'updateDateForKR',
					// 	message:
					// 		goalDetail?.contributors?.length > 1 ? t('updateObjectiveAttribute') : t('updateObjAttrNoContributor'),
					// 	module: 'user',
					// 	event: null,
					// 	data: { metricId: '', date: start, type: 'startDate', dateDetails },
					// });
				} else if (!Boolean(goalsAttribute?.goalObjectiveId)) {
					// setModalProps({
					// 	open: true,
					// 	type: 'updateDateForKR',
					// 	message: hasContributor(objectiveDetail) ? t('updateObjectiveAttribute') : t('updateObjAttrNoContributor'),
					// 	module: 'user',
					// 	event: null,
					// 	data: { metricId: '', date: start, type: 'startDate', dateDetails },
					// });
				} else {
					updateGoalStartDate(start, false);
					range && setSelectedDateRange(range);
					updateDueDays(start, end, selectionType);
				}
			}
			if (start && end) {
				setIsSaveDisabled(false);
				if (goalsAttribute?.myGoalsDetails && goalsAttribute?.myGoalsDetails.length > 0) {
					if (
						goalDetail &&
						goalDetail.showAttributeDrawer &&
						goalDetail.actionType === 'editGoal' &&
						shouldDateChangePopup(goalsAttribute, end)
					) {
						//updateGoalDueDate(date, true, start);
						setModalProps({
							open: true,
							type: 'updateDateForKR',
							message:
								goalDetail?.contributors?.length > 1 ? t('updateObjectiveAttribute') : t('updateObjAttrNoContributor'),
							module: 'user',
							event: null,
							data: { metricId: '', date: end, type: 'dueDate', dateDetails },
						});
					} else if (!Boolean(goalsAttribute?.goalObjectiveId) && shouldDateChangePopup(objectiveDetail, end)) {
						setModalProps({
							open: true,
							type: 'updateDateForKR',
							message: hasContributor(objectiveDetail)
								? t('updateObjectiveAttribute')
								: t('updateObjAttrNoContributor'),
							module: 'user',
							event: null,
							data: { metricId: '', date: end, type: 'dueDate', dateDetails },
						});
					} else {
						updateGoalStartDate(start, false);
						updateGoalDueDate(end, false, start);
						range && setSelectedDateRange(range);
						updateDueDays(start, end, selectionType);
					}
				} else {
					updateGoalStartDate(start, false);
					updateGoalDueDate(end, false, start);
					range && setSelectedDateRange(range);
					updateDueDays(start, end, selectionType);
				}
			}
		}
	};

	const updateDueDays = (startDt: any, endDt: any, rangeSelectionType: String) => {
		if (rangeSelectionType === 'range') {
			const diffInDays = getDueDays(
				startDt,
				endDt,
				selectedCycleDetails ? selectedCycleDetails : props.userSelectedCycle
			);
		}
	};
	// Added below code for Date Range Picker ==> End
	const handleSelectionChange = (item: any) => {
		let newGoalTypeId = item.length > 0 ? 1 : 2;
		let currentGoalId = goalsAttribute.goalTypeId;
		let dataToCheck = goalDetail.actionType === 'editGoal' ? goalsAttribute : objectiveDetail;
		let isResetRequired = false;
		if (currentGoalId !== 0 && currentGoalId !== newGoalTypeId) {
			isResetRequired = true;
		} else {
			if (dataToCheck?.teamOkrRequests?.length > 0 && dataToCheck.teamOkrRequests[0]['id'] !== item[0]['id']) {
				isResetRequired = true;
			}
		}
		if (goalDetail.actionType === 'editGoal' && goalsAttribute.isMyTeamOkr === false) {
			isResetRequired = false;
		}
		if (goalDetail.actionType !== 'editGoal') {
			isResetRequired = isResetRequired && resetObjectiveContributor();
		}
		if (isReset === false && isResetRequired) {
			if (goalDetail.actionType === 'editGoal') {
				setIsReset(true);
				if (goalsAttribute?.goalStatusId === Enums.GOAL_STATUS_DRAFT) {
					const data = { open: false, message: '', data: { goalTypeId: newGoalTypeId, teamData: item }, type: '' };
					setModalProps(data);
					resetObjectiveId(data);
				} else {
					setModalProps({
						open: true,
						message: t('resetTeamsOkrAlertMessage'),
						data: { goalTypeId: newGoalTypeId, teamData: item },
						type: '',
					});
				}
			} else {
				let callReset = resetObjectiveContributor();
				if (callReset === false) {
					assignmentSetter({ goalTypeId: newGoalTypeId }, item);
				} else {
					setIsReset(true);
					setModalProps({
						open: true,
						message: t('resetTeamsOkrAlertMessage'),
						data: { goalTypeId: newGoalTypeId, teamData: item },
						type: '',
						callReset: callReset,
					});
				}
			}
		} else {
			setCurrentGoalId(newGoalTypeId);
			assignmentSetter({ goalTypeId: newGoalTypeId }, item);
		}
	};
	return (
		<Fragment>
			<Box className='drawer-inner-content'>
				<Box className='drawer-content-panel'>
					<form className='drawer-form-fields' noValidate autoComplete='off'>
						{goalsAttribute.isDefaultGoalObjective && (
							<>
								<InformationMessage
									messageText={t('defaultAttributeText')}
									icon={true}
									className={'information-message-user information-message-user-type1'}
								/>
							</>
						)}
						<Box className='drawer-input-field helperText-bottom' id='obj-name'>
							<TextField
								id='objective-field'
								label={t('objectiveName')}
								placeholder={t('giveNameGoalAttributeText')}
								InputProps={{
									'aria-label': t('objective'),
									disableUnderline: true,
									autoFocus: true,
									disabled: isLocked || goalsAttribute.isDefaultGoalObjective,
								}}
								fullWidth
								multiline
								rows={2}
								InputLabelProps={{
									shrink: true,
								}}
								error={(goalAttrDateError.type === 'objectiveName' && goalAttrDateError?.error) || ''}
								helperText={
									(goalAttrDateError.type === 'objectiveName' && goalAttrDateError?.helperText && (
										<>
											<ErrorIcon />
											{goalAttrDateError?.helperText}
										</>
									)) ||
									''
								}
								value={goalsAttribute.objectiveName}
								onChange={(event: any) => {
									setIsSaveDisabled(false);
									setGoalsAttribute({ ...goalsAttribute, objectiveName: event.target.value });
								}}
								inputRef={inputRefOkrTitle}
							/>
						</Box>

						{okrMasterData && okrMasterData.okrNature && okrMasterData.okrNature.length > 0 && (
							<Box className='drawer-input-field'>
								<Box className='okr-assign-btn-grp helperText-bottom'>
									<Box className='assigned-btn'>
										<FormLabel id='nature-of-okr' component='legend'>
											{t('natureOfOkr')}
										</FormLabel>
									</Box>
									<ButtonGroup className='kr-btn-group' disableElevation variant='contained' color='primary'>
										{okrMasterData.okrNature.map((item: any, index: any) => (
											<OKRButton
												icon={item.okrNatureId === 1 ? <AspirationalIcon /> : <CommittedIcon />}
												text={item.name}
												title={item.description}
												handleClick={() => handleNatureOfOkr(item.okrNatureId)}
												className={`${item.okrNatureId === nature ? 'active' : ''}`}
												id={item.okrNatureId === 1 ? 'aspirational-btn' : 'committed-btn'}
												tooltipClassName='tooltip-layout4'
												disabled={isLocked || goalsAttribute.isDefaultGoalObjective}
											/>
										))}
									</ButtonGroup>
									{goalAttrDateError.type === 'natureError' && (
										<span className='error-field'>
											<ErrorIcon />
											{goalAttrDateError.error}
										</span>
									)}
								</Box>
							</Box>
						)}
						{loggedInUserDetail.isTeamLeader ? (
							<Box className={`drawer-input-field ${isLocked || goalsAttribute.isDefaultGoalObjective ? 'disabled-virtual' : ''}`}>
								<Box>
									<FormLabel component='legend' id='team-selection'>
										{t('okrTeamLabel')}
									</FormLabel>
									<OkrTeamDropdown
										selectedTeams={selectedTeams}
										treeNodesData={treeNodesData}
										goalsAttribute={goalsAttribute}
										handleSelectionChange={handleSelectionChange}
										disabledBtn={
											isLocked || goalsAttribute.isDefaultGoalObjective /*!(
											!goalsAttribute?.goalObjectiveId ||
											(goalsAttribute.createdBy === loggedInUserDetail.employeeId &&
												!goalsAttribute.importedId)
											)*/
										}
									/>
								</Box>
							</Box>
						) : (
							<></>
						)}

						<Box>
							{0 &&
							selectedTeams.length > 0 &&
							selectedTeams[0].organisationHead !== loggedInUserDetail.employeeId &&
							!goalsAttribute.importedId ? (
								<Box className='drawer-input-field owner-box'>
									{
										/*!objectiveDetail.isPrivate && (*/
										<Box className='okr-assign-btn-grp'>
											<Box className='assigned-btn'>
												<FormLabel component='legend' ref={inputRefOkrOwner}>
													{!selectedTeams[0].organisationHead ? t('withoutOwnerText') : t('behalfOwner')}
												</FormLabel>
												<Box className='owner-main'>
													<Box className='owner-details'>
														<Fragment>
															<Box
																className={`teams-listing ${
																	goalsAttribute.goalTypeId === 1 && selectedTeams.length > 0 ? 'dropdown-active' : ''
																}`}
															>
																<Box className='user-info'>
																	<Box className='user-img'>
																		{selectedTeams[0].leaderImagePath ? (
																			<Avatar
																				className='avatar-default'
																				alt={`${selectedTeams[0].leaderFirstName} ${selectedTeams[0].leaderLastName}`}
																				src={selectedTeams[0].leaderImagePath}
																			/>
																		) : (
																			<Avatar
																				style={{
																					backgroundColor: getRandomBackgroundColor(
																						selectedTeams[0].backGroundColorCode
																					),
																					color: getRandomColor(selectedTeams[0].colorCode),
																				}}
																			>
																				{getUserName({
																					firstName: selectedTeams[0].leaderFirstName,
																					lastName: selectedTeams[0].leaderLastName,
																					fullName: '',
																				})}
																			</Avatar>
																		)}
																	</Box>
																	<Box className='user-info-details'>
																		<Typography variant='h4'>
																			{selectedTeams[0].leaderFirstName} {selectedTeams[0].leaderLastName}
																		</Typography>
																		<Typography variant='subtitle2'>{selectedTeams[0].leaderDesignation}</Typography>
																	</Box>
																</Box>
															</Box>
														</Fragment>
													</Box>
												</Box>
											</Box>
										</Box>
									}
								</Box>
							) : (
								''
							)}
						</Box>
						<Box
							className={`drawer-input-field drawer-date-field helperText-bottom ${
								isLocked || goalsAttribute.isDefaultGoalObjective ? 'disabled-virtual' : ''
							}`}
						>
							<FormLabel id='obj-set-timeframe' component='legend'>
								{t('setATimeframe')}
							</FormLabel>
							<ReactDateRangePicker
								type={'GoalAttribute'}
								currentCycleEndDate={currentCycleEndDate}
								durationCycleDetail={durationCycleDetail}
								startDateValue={goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate}
								endDateValue={goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate}
								minimumDate={selectedCycleDetails?.startDate}
								maximumDate={selectedCycleDetails?.endDate}
								handleDateSelection={(
									range: any,
									start: any,
									end: any,
									selectionType: String,
									isCycleChanged: Boolean,
									selectedQuarterData: any
								) => {
									dateChangeHandler(range, start, end, selectionType, isCycleChanged, selectedQuarterData);
								}}
								bottomChildComponent={<></>}
								allCycleDetails={allCycleList}
								selectedCycleDetails={selectedCycleDetails}
								setSelectedCycleDetails={setSelectedCycleDetails}
								isCurrentCycle={isCurrentCycle}
								isCycleVisible={true}
								numberOfCalendars={Enums.TWO}
								selectionType={selectionType}
								selectedDateRange={selectedDateRange}
								setSelectedDateRange={setSelectedDateRange}
								isDateRangeTextFieldVisible={true}
								isOnlyDueDateVisible={false}
								isDatePickerDisable={isLocked || goalsAttribute.isDefaultGoalObjective}
								isCycleDisable={goalsAttribute?.importedId > 0 ? true : false}
								resToUseInCalendar={resToUseInCalendar}
								editModee={editModee}
								setEditModee={setEditModee}
								showBottomText={true}
							/>
						</Box>
						{!goalsAttribute.isPrivate && (
							<Box
								className={`drawer-input-field link-objective pos-rel ${
									isLocked || goalsAttribute.isDefaultGoalObjective ? 'disabled-virtual' : ''
								}`}
							>
								<Box
									component='div'
									className='okr-assign-btn-grp linking-objective'
									id='inputRefAVirtualLink'
									{...({ ref: virtualLinkRef } as any)}
								>
									<Box className={`kr-field-row assigned-btn`}>
										{goalsAttribute.linkedObjective && goalsAttribute.linkedObjective.objectiveId ? (
											<ul className='objective-ul assigned-link'>
												<li className={`objective-li `}>
													<Box className='left-content' onClick={onHandleLinkObjectiveClick}>
														<Box className='link-objective-label'>
															<VirtualLinkContributorIcon />
															{t('linkObjeativeTitle')}
														</Box>
														<Typography className='objective-label'>
															{goalsAttribute.linkedObjective.objectiveName}
														</Typography>
														<Typography className='objective-chips'>
															{goalsAttribute.linkedObjective.dueCycle !== '' && (
																<span
																	className='objective-chip quarter-chip'
																	// title={goalsAttribute.linkedObjective.dueCycle}
																>
																	{' '}
																	{goalsAttribute.linkedObjective.dueCycle}
																</span>
															)}
															{goalsAttribute.linkedObjective.teamName !== '' && (
																<Tooltip arrow title={goalsAttribute.linkedObjective.teamName}>
																	<span
																		style={{
																			backgroundColor: getRandomBackgroundColor(
																				goalsAttribute.linkedObjective?.backGroundColorCode
																			),
																			color: getRandomColor(goalsAttribute.linkedObjective?.colorCode),
																		}}
																		className='objective-chip performance-chip'
																	>
																		{' '}
																		{goalsAttribute.linkedObjective.teamName}
																	</span>
																</Tooltip>
															)}
															{/*<span className='objective-chip management-chip'>	{goalsAttribute.linkedObjective.performance}</span>*/}
														</Typography>
													</Box>
													<Box className='right-content'>
														{(goalDetail.actionType !== 'editGoal' ||
															(goalDetail.actionType === 'editGoal' &&
																(goalsAttribute.createdBy === loggedInUserDetail.employeeId ||
																	goalsAttribute.owner === loggedInUserDetail.employeeId))) && (
															<span
																className='virtualdeleteicon'
																id='virtual-delete-icon'
																onClick={(e) => {
																	linkSelected({ objectiveId: 0 });
																}}
															>
																<VirtualDeleteIcon />
															</span>
														)}
													</Box>
												</li>
											</ul>
										) : (
											(goalDetail.actionType !== 'editGoal' ||
												(goalDetail.actionType === 'editGoal' &&
													(goalsAttribute.createdBy === loggedInUserDetail.employeeId ||
														goalsAttribute.owner === loggedInUserDetail.employeeId))) && (
												<Box className='left-content' onClick={onHandleLinkObjectiveClick}>
													<Box className='link-objective-label'>
														<VirtualLinkContributorIcon />
														{t('linkObjeativeTitle')}
													</Box>
													<FormLabel component='legend' className='link_objective_desc'>
														{t('linkObjeativeDescription')}
													</FormLabel>
												</Box>
											)
										)}
									</Box>
								</Box>
								<LinkObjectivePopover
									{...props}
									popperAddClassName={'link-objective-popover'}
									linkObjectiveOpen={linkObjectiveOpen}
									linkObjectiveData={linkedObjectiveData}
									setLinkObjectiveOpen={setLinkObjectiveOpen}
									linkSelected={linkSelected}
									selectedLink={goalsAttribute.linkedObjective ? goalsAttribute.linkedObjective.objectiveId : ''}
									t={t}
									linkObjectiveLoading={linkObjectiveLoading}
									linkObjectiveUserOpen={linkObjectiveUserOpen}
									setLinkObjectiveUserOpen={setLinkObjectiveUserOpen}
									linkObjectiveUserAnchorEl={linkObjectiveUserAnchorEl}
									setLinkObjectiveUserAnchorEl={setLinkObjectiveUserAnchorEl}
									fetchUserObjectiveList={fetchUserObjectiveList}
									scrollTo={scrollTo}
									loggedInUserDetail={loggedInUserDetail}
									isLinkObjectiveInitiated={isLinkObjectiveInitiated}
									setIsLinkObjectiveInitiated={setIsLinkObjectiveInitiated}
									selectedCycleDetails={selectedCycleDetails}
								></LinkObjectivePopover>
							</Box>
						)}
					</form>
					{modalProps && modalProps.open && (
						<AlertDialog
							module={modalProps.type === 'contibutor' ? 'organization' : 'goalAttribute'}
							message={modalProps.message}
							handleCloseModal={handleCloseModal}
							modalOpen={modalProps.open}
						/>
					)}
				</Box>
			</Box>
			{showCallouts && (
				<CallOutTooltip
					open={callOut.open}
					anchorEl={anchorElCallouts}
					setAnchorEl={setAnchorElCallouts}
					setCallOut={setCallOut}
					text={t(callOut.text)}
					placement={callOut.placement}
					classname={callOut.classname}
					handlePrev={() => prevCallout(callOut.currentCalloutIndex)}
					handleNext={() => nextCallout(callOut.currentCalloutIndex)}
					showNext={callOut.next}
					showPrevious={callOut.prev}
				/>
			)}
		</Fragment>
	);
};
