import React, { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	FormControl,
	InputLabel,
	Avatar,
	TextField,
	Tabs,
	Tab,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GlobalSearch from '../../Common/GlobalSearch';
// import { getLocalStorageItem } from '../../../services/StorageService';
import { useDispatch, useSelector } from 'react-redux';
import { globalSearchAPIWithTeam, searchEmployee } from '../../../action/search';
import { getUserById, updatePage } from '../../../action/common';
import { getUserName, urlPatternValidation } from '../../../config/utils';
import { DocumentIcon } from '../../../config/svg/CommonSvgIcon';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { roleRemovePlugin } from '../../Common/CkEditor/CkEditorEnums';
import { ErrorIcon, ReportingToIcon, TitleIcon } from '../../../config/svg/formElementIcons';
import { RequestFeedbackAgain } from '../../../config/svg/PersonalFeedbackSvg';
import { MultiSelect } from '../../Admin/CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../Admin/CommonComponent/NoSearchRecord';
import { AddLinkIcon } from '../../../config/svg/CommonSvg';
import { NatureMeetingOptions } from '../../../config/constant';
import { getSearchForFeedbackOneToOne } from '../../../action/personalFeedback';
import { getFeedbackOneToOneSetting } from '../../../action/adminPerformanceDevelopment';

export const RequestOneOnOneForm = (props: any) => {
	const {
		formData,
		setFormData,
		setIsFormEdited,
		isEdit,
		editableDetails,
		oneToOneTitleError,
		oneToOneUserError,
		isDirect,
		directEmpDetails,
		setOneToOneTitleError,
		setOneToOneUserError,
		staticTitleText,
		userDetail,
		oneToOneMeetingLinkError,
		setOneToOneMeetingLinkError,
		oneToOneSettings,
		oneToOneAgendaError,
		setOneToOneAgendaError,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [natureOfMeetingSelected, setNatureOfMeetingSelected] = useState<any>([{ ...NatureMeetingOptions[0] }]);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
	});
	useEffect(() => {
		if (!oneToOneSettings) {
			dispatch(getFeedbackOneToOneSetting());
		}
	}, []);

	useEffect(() => {
		if (isEdit && formData && formData.natureOfMeeting !== null && formData.natureOfMeeting !== undefined) {
			setNatureOfMeetingSelected(NatureMeetingOptions.filter((item: any) => item.id === formData.natureOfMeeting));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);
	// const currentUser = getLocalStorageItem('currentUser') ? JSON.parse(getLocalStorageItem('currentUser') || '') : null;
	// console.log('Detail===>', formData, isEdit, editableDetails);

	useEffect(() => {
		if (isEdit && editableDetails) {
			setFormData({
				requestId: editableDetails?.oneToOneDetailId || 0,
				oneToOneTitle: editableDetails?.title || '',
				onetoOneRemark: editableDetails?.oneToOneRemark || null,
				meetingLink: editableDetails?.meetingLink || '',
				natureOfMeeting: editableDetails.natureOfMeeting,
				// selectedUser: [editableDetails],
				// requestedTo: editableDetails?.employeeId || 0,
			});
		} else if (isDirect && directEmpDetails) {
			setFormData({
				requestId: 0,
				oneToOneTitle: staticTitleText,
				onetoOneRemark: null,
				selectedUser: [directEmpDetails],
				requestedTo: directEmpDetails?.employeeId || 0,
				natureOfMeeting: directEmpDetails?.natureOfMeeting,
				meetingLink: '',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEdit, editableDetails, isDirect, directEmpDetails]);

	const onSelectedUser = async (selected: any) => {
		const isExist =
			formData.selectedUser &&
			formData.selectedUser.find(
				(item: any) => item.employeeId === Boolean(selected) && selected.length && selected[0].employeeId
			);
		if (selected.length > 0 && !isExist) {
			const allUser = [...formData.selectedUser, { ...formData.selectedUser, ...selected[0] }];
			const allUserIds = Boolean(allUser) && allUser.length && allUser[0].employeeId ? allUser[0].employeeId : 0;
			setFormData(() => ({
				...formData,
				selectedUser: allUser,
				requestedTo: allUserIds,
				isError: false,
			}));
			setIsFormEdited(true);
			setOneToOneUserError({
				error: '',
				helperText: '',
				type: '',
			});
		} else {
			setFormData(() => ({
				...formData,
				selectedUser: [],
				raisedForId: 0,
			}));
			// setFormDataUpdated(false);
		}
	};
	const searchCustomEmployee = (searchStr: any, page: any, pageSize: any) => {
		return dispatch(searchEmployee(searchStr, page, pageSize));
	};
	const updateCustomPage = (page: any) => {
		return dispatch(updatePage(page));
	};
	const globalSearchWithTeam = (searchStr: any, page: any, pageSize: any, type: any) => {
		return dispatch(globalSearchAPIWithTeam(searchStr, page, pageSize, type));
	};
	const getCustomUserById = (data: any) => {
		return dispatch(getUserById(data));
	};
	const handleTitleChange = (e: any) => {
		e.preventDefault();
		const { name, value } = e?.target || {};
		setIsFormEdited(true);
		setFormData({ ...formData, [name]: value });
		setOneToOneTitleError({
			error: '',
			helperText: '',
			type: '',
		});
	};
	const handleAgendaChange = (value: any) => {
		if (
			(value && isEdit && value !== editableDetails?.oneToOneRemark) ||
			(value && value !== formData?.onetoOneRemark)
		) {
			setIsFormEdited(true);
		}
		setFormData({ ...formData, onetoOneRemark: value });
	};
	const handleMeetingLinkChange = (event: any) => {
		const { name, value } = event.target;
		const isTrueVal = !value || urlPatternValidation(value);
		setIsFormEdited(true);
		setFormData({ ...formData, [name]: value });
		isTrueVal &&
			setOneToOneMeetingLinkError({
				error: '',
				helperText: '',
				type: '',
			});
	};
	const [select1on1Tab, setSelect1on1Tab] = useState<any>(0);
	const handleTabChange = (event: any, newValue: Number) => {
		setSelect1on1Tab(newValue);
	};
	function a11yProps(index: number) {
		return {
			id: `1-on-1-tab-${index}`,
			'aria-controls': `1-on-1-tabpanel-${index}`,
		};
	}

	const searchReportingTo = async (value: string) => {
		if (value.length >= 3) {
			setOneToOneUserError({
				error: '',
				helperText: '',
				type: '',
			});
			// const resp: any = await dispatch(searchEmployee(value, 1, 6));
			let queryString = `finder=${value}&pageIndex=${1}&pageSize=${10}`;
			const resp: any = await dispatch(getSearchForFeedbackOneToOne(queryString));
			let list = resp.data.entityList;
			list = list && list.filter((item: any) => item.employeeId !== userDetail.employeeId);
			if (list && list.length > 0) {
				setReportingToOptions(
					list.map((userData: any) => {
						return {
							...userData,
							label: userData.firstName + ' ' + userData.lastName,
							value: userData.employeeId,
							secondaryText: userData.designation,
						};
					})
				);
			} else {
				setEmptyRecords({ ...emptyRecords, reportingTo: true });
				setReportingToOptions([]);
			}
		}
	};
	const getUserFieldData = (field: string) => {
		return formData[field];
	};
	const setUserFieldData = (field: string, value: any) => {
		const empId = Boolean(value) && value.length && value[0].employeeId ? value[0].employeeId : 0;
		setFormData({ ...formData, [field]: value, requestedTo: empId || 0 });
		setIsFormEdited(true);
		setOneToOneUserError({
			error: '',
			helperText: '',
			type: '',
		});
	};

	return (
		<>
			<Tabs className='okr-tabs drawer-tabs' value={select1on1Tab} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab label={t('Details')} value={0} {...a11yProps(0)} />
			</Tabs>
			<Box className='drawer-tabs-panel drawer-request-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{!isEdit && !isDirect ? (
						<>
							{/* <Box
								className={`drawer-input-field helperText-bottom ${
									oneToOneUserError && oneToOneUserError?.error ? 'error-search' : ''
								} `}
							>
								<FormControl variant='outlined' style={{ width: '100%' }}>
									<InputLabel htmlFor='ask-feedback-from'>
										<RequestFeedbackAgain /> {'1-on-1 with'}
										<sup>*</sup>
									</InputLabel>
									<GlobalSearch
										{...props}
										module={'feedback'}
										onSelectedUser={onSelectedUser}
										fetchCycleUsers={true}
										customClass={'user-quick-search-type2'}
										customId={'give-pf-src'}
										multiple={false}
										defaultValue={null}
										defaultFocus={null}
										placeHolderText={null}
										searchEmployee={searchCustomEmployee}
										updatePage={updateCustomPage}
										globalSearchAPIWithTeam={globalSearchWithTeam}
										getUserById={getCustomUserById}
									/>
									{oneToOneUserError && oneToOneUserError.error ? (
										<Typography className='error-field' variant='caption' color='error'>
											{oneToOneUserError?.helperText || t('selectEmployee')}
										</Typography>
									) : (
										''
									)}
								</FormControl>
							</Box> */}
							{/*  */}
							<Box
								// className={`field-col2 ${
								// 	oneToOneUserError && oneToOneUserError.error ? 'select-error helperText-bottom ' : ''
								// }`}
								className={`drawer-input-field`}
							>
								<Box
									className={`helperText-bottom one-on-one-field ${
										oneToOneUserError && oneToOneUserError?.error ? 'select-error' : ''
									} `}
								>
									<FormLabel>
										<RequestFeedbackAgain /> {t('oneOnOneWith')} <i style={{ color: 'red' }}>*</i>
									</FormLabel>
									<MultiSelect
										key={'selectedUser'}
										id='team-id-dropdown'
										selectedOptions={getUserFieldData('selectedUser') || 0}
										optionsList={reportingToOptions}
										onSelectOption={(value: any) => {
											setUserFieldData('selectedUser', value);
											setReportingToOptions([]);
											setEmptyRecords({ ...emptyRecords, reportingTo: false });
										}}
										isMulti={true}
										customFilter={() => {}}
										placeHolder={'Search'}
										noOptionsMessage={
											<NoSearchRecord/>
										}
										showNoOptions={emptyRecords.reportingTo === true}
										fetchAsyncData={true}
										isSingleSelection={true}
										performSearch={searchReportingTo}
										closeMenuOnSelect={true}
										labelTemplate={'avatarLabel'}
										selectClassName={'select-search-dropdown select-search-icon'}
										selectClassNamePrefix={'react-select'}
										error={(oneToOneUserError && oneToOneUserError.error) || ''}
										helperText={
											oneToOneUserError?.helperText && (
												<>
													<ErrorIcon />
													{oneToOneUserError?.helperText || ''}
												</>
											)
										}
									/>
								</Box>
							</Box>
						</>
					) : isEdit && editableDetails ? (
						<Box className='drawer-input-field user-info '>
							<Box className='user-img'>
								{Boolean(editableDetails?.imagePath) ? (
									<Avatar
										alt={`${editableDetails?.firstName} ${editableDetails?.lastName}`}
										src={editableDetails?.imagePath}
									/>
								) : (
									<Avatar>{getUserName(editableDetails)}</Avatar>
								)}
							</Box>
							<Box className='user-details'>
								<Typography variant='h4'>
									{editableDetails?.firstName || ''} {editableDetails?.lastName || ''}
								</Typography>
								<Typography variant='subtitle2'>{editableDetails?.emailId || ''}</Typography>
							</Box>
						</Box>
					) : isDirect && directEmpDetails ? (
						<Box className='drawer-input-field user-info '>
							<Box className='user-img'>
								{Boolean(directEmpDetails?.imagePath) ? (
									<Avatar
										alt={`${directEmpDetails?.firstName} ${directEmpDetails?.lastName}`}
										src={directEmpDetails?.imagePath}
									/>
								) : (
									<Avatar>{getUserName(directEmpDetails)}</Avatar>
								)}
							</Box>
							<Box className='user-details'>
								<Typography variant='h4'>
									{directEmpDetails?.firstName || ''} {directEmpDetails?.lastName || ''}
								</Typography>
								<Typography variant='subtitle2'>{directEmpDetails?.emailId || ''}</Typography>
							</Box>
						</Box>
					) : (
						<></>
					)}
					<Box className='drawer-blue-bg-full'>
						<Box className='drawer-input-field'>
							<TextField
								fullWidth
								className='helperText-bottom'
								label={
									<>
										<TitleIcon />
										{t('titleLabel')}
									</>
								}
								placeholder={t('addTitle')}
								value={formData?.oneToOneTitle || ''}
								onChange={handleTitleChange}
								name={`oneToOneTitle`}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('addTitle'), disableUnderline: true }}
								error={oneToOneTitleError?.error || false}
								helperText={
									(oneToOneTitleError?.error && (
										<>
											<ErrorIcon />
											{oneToOneTitleError?.helperText || ''}
										</>
									)) ||
									''
								}
							/>
						</Box>
						<Box className='drawer-input-field'>
							<FormLabel>{t('natureOfMeeting')}</FormLabel>
							<RadioGroup
								row
								aria-label='cycleDuration'
								name='cycleDuration'
								onChange={(event: any) => {
									const selectedvalue = parseInt(event.target.value);
									let selectedRadio = [...NatureMeetingOptions].filter((item: any) => item?.id === selectedvalue);
									if (selectedRadio.length > 0) {
										setNatureOfMeetingSelected(selectedRadio);
										setFormData({
											...formData,
											natureOfMeeting: selectedRadio[0].id,
										});
										setIsFormEdited(true);
									}
								}}
							>
								{NatureMeetingOptions.length > 0 &&
									NatureMeetingOptions.map((meetingOption: any) => (
										<FormControlLabel
											value={meetingOption?.id}
											control={<Radio />}
											label={meetingOption.label}
											checked={
												natureOfMeetingSelected.length > 0 ? natureOfMeetingSelected[0].id === meetingOption?.id : false
											}
										/>
									))}
							</RadioGroup>
							{/* <MultiSelect
								key={'employeeSelect'}
								id='employee-select'
								selectedOptions={natureOfMeetingSelected}
								optionsList={NatureMeetingOptions}
								onSelectOption={(value: any) => {
									if (value.length > 0) {
										setNatureOfMeetingSelected(value);
										setFormData({ ...formData, natureOfMeeting: value[0].id, meetingLink: '' });
										setIsFormEdited(true);
									}
								}}
								customFilter={(option: any, searchText: any) => {
									if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
										return true;
									}
								}}
								placeHolder={t('timeFramePlaceholdar')}
								noOptionsMessage={<NoSearchRecord />}
								fetchAsyncData={false}
								isSingleSelection={false}
								performSearch={() => {}}
								closeMenuOnSelect={true}
								labelTemplate={'onlyLabel'}
								isMulti={false}
								selectClassName={`dropdown-default-wrapper checkin-visibility-dropdown`}
								selectClassNamePrefix={'dropdown-default'}
								disableSearch={true}
							/> */}
						</Box>
						{natureOfMeetingSelected && natureOfMeetingSelected.length > 0 && natureOfMeetingSelected[0].id === 1 && (
							<Box className='drawer-input-field'>
								<TextField
									fullWidth
									className='helperText-bottom'
									label={
										<>
											<AddLinkIcon />
											{t('meetingLink')}
										</>
									}
									placeholder={t('addMeetingLink')}
									value={formData?.meetingLink || ''}
									onChange={handleMeetingLinkChange}
									name={'meetingLink'}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ 'aria-label': t('addMeetingLink'), disableUnderline: true }}
									error={oneToOneMeetingLinkError?.error || false}
									helperText={
										(oneToOneMeetingLinkError?.error && (
											<>
												<ErrorIcon />
												{oneToOneMeetingLinkError?.helperText || ''}
											</>
										)) ||
										''
									}
								/>
							</Box>
						)}
						<Box className='drawer-input-field helperText-bottom'>
							<FormLabel>
								<DocumentIcon /> {t('agendaLabel')}
								{oneToOneSettings?.isOneToOneAgendaRequired ? <i style={{ color: 'red' }}>*</i> : ''}
							</FormLabel>
							<CkEditor
								{...props}
								className={'inactive' || ''}
								value={formData?.onetoOneRemark || ''}
								skipBlankCheck={true}
								handleEditorChange={(value: string) => {
									handleAgendaChange(value);
									setOneToOneAgendaError({
										error: '',
										helperText: '',
										type: '',
									});
								}}
								editorId={`requestOneOnOneAgenda`}
								removedPlugins={roleRemovePlugin}
								placeHolderText={t('writeAgendaLabel')}
								showEmoticons={true}
							/>
							{(oneToOneAgendaError?.error && (
								<Typography className='error-field'>
									<ErrorIcon />
									{oneToOneAgendaError?.helperText || ''}
								</Typography>
							)) ||
								''}
						</Box>
					</Box>
				</form>
			</Box>
		</>
	);
};
