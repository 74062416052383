import React, { Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Slider, IconButton, Typography, Icon } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import AlertDialog from '../../../Common/Dialog';
// import { createUUID } from '../../../config/utils';
import { DeleteIcon } from '../../../../config/svg/CommonSvgIcon';
import '../../../../styles/pages/admin/imageEditor.scss';

export const ImageEditor: React.FC<any> = (props) => {
	const {
		handleFileChange,
		// showImageEditor,
		removeLogo,
		// updateImageDataImagePath,
		image,
		setImage,
		avatarEditorRef,
		handleDeleteLogo,
	} = props;

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [modalOpen, setModalOpen] = useState(false);
	// const [imageName, setImageName] = useState('');
	const [sliderValue, setSliderValue] = React.useState(1);
	const [straightenValue, setStraightenValue] = useState(0);
	// const [uploadingPic, setUploadingPic] = useState(false);
	// const avatarEditorRef = useRef<AvatarEditor>(null);

	const uploadImage = (event: any) => {
		const file = event.target.files[0];
		const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];
		try {
			if (file) {
				const extension = file.name.split('.').slice(-1).pop();
				const fSize = file.size;
				const fileSize = Math.round(fSize / 1024);
				if (fileSize > 1024) {
					enqueueSnackbar(t('OrgLogo'), { variant: 'error', autoHideDuration: 5000 });
					event.target.value = '';
				} else if (!allowedExtensions.includes(extension ? extension.toLowerCase() : '')) {
					enqueueSnackbar(t('OrgLogoType'), {
						variant: 'error',
						autoHideDuration: 5000,
					});
					event.target.value = '';
				} else {
					handleFileChange(event, file);
					// setImageName(file.name);
					setImage(file);
					event.target.value = '';
				}
			} else {
				event.target.value = '';
			}
		} catch (err) {
			console.log(err);
		}
	};
	const handleSliderChange = (event: any, newValue: any) => {
		setSliderValue(newValue);
	};
	const handleStraightenSliderChange = (event: any, newValue: any) => {
		setStraightenValue(newValue);
	};
	const onRefreshIconClick = () => {
		setSliderValue(1);
		setStraightenValue(0);
	};

	const onDeleteIconClick = (e: any) => {
		setImage(null);
		removeLogo(e);
		enqueueSnackbar(t('OrgLogoDelete'), {
			variant: 'success',
			autoHideDuration: 5000,
		});
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			onDeleteIconClick(e);
			handleDeleteLogo();
		}
		setModalOpen(false);
	};

	return (
		<Fragment>
			<Box className='drawer-tabs-panel drawer-upload-image-panel'>
				<Box className='image-edit-area'>
					<Box className='upload-action'>
						<div className='upload-button' id='upload-btn'>
							<label htmlFor='file-input-for-editor' className='upload-image-label'>
								{t('uploadPhoto')}
							</label>
							<input
								className='upload-image-file-input'
								accept='image/x-png,image/jpeg,image/jpg,image/svg+xml'
								type='file'
								id='file-input-for-editor'
								onChange={uploadImage}
							/>
						</div>
						{/* <Box>
							{Boolean(image) ? (
								<IconButton size='small' className='deleteIcon' onClick={() => setModalOpen(true)}>
									<DeleteIcon />
								</IconButton>
							) : image ? (
								<IconButton
									size='small'
									className='deleteIcon'
									onClick={() => {
										if (image) {
											setImage(null);
											enqueueSnackbar(t('BadgeLogoDelete'), {
												variant: 'success',
												autoHideDuration: 5000,
											});
										}
									}}
								>
									<DeleteIcon />
								</IconButton>
							) : (
								<Icon className='deleteIcon'>
									<DeleteIcon />
								</Icon>
							)}
						</Box> */}
					</Box>
					<Box className='profile-avatar-wrap'>
						<Box className='profile-avatar'>
							<AvatarEditor
								ref={avatarEditorRef}
								image={image}
								width={320}
								height={320}
								border={0}
								borderRadius={250}
								scale={sliderValue}
								rotate={straightenValue}
								disableBoundaryChecks={false}
								// @ts-expect-error
								disableHiDPIScaling={false}
								// crossOrigin={'anonymous'}
								className={image ? 'image-section-white-bg' : 'image-section'}
							/>
						</Box>
					</Box>
					<Box className='image-setting'>
						<Box className='zoom-box' p={2}>
							<Typography>Zoom</Typography>
							<Slider
								step={0.1}
								min={-2}
								max={5}
								value={typeof sliderValue === 'number' ? sliderValue : 1}
								onChange={handleSliderChange}
								aria-labelledby='input-slider'
							/>
						</Box>
						<Box className='straighten-box' p={2}>
							<Typography>Straighten</Typography>
							<Slider
								min={0}
								max={360}
								value={typeof straightenValue === 'number' ? straightenValue : 0}
								onChange={handleStraightenSliderChange}
								aria-labelledby='input-slider'
							/>
						</Box>
						<Box>
							<IconButton className='reset-btn' size='small' onClick={onRefreshIconClick} style={{ marginTop: 10 }}>
								<RefreshIcon />
							</IconButton>
						</Box>
					</Box>
				</Box>
			</Box>
			{modalOpen && (
				<AlertDialog
					module='delete'
					message={t('OrgLogoRemoveAlert')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};

export default ImageEditor;
