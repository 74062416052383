import {
	GLOBAL_TASK_PENDING,
	GLOBAL_TASK_SUCCESS,
	GLOBAL_TASK_FAILED,
	GLOBAL_DASHBOARD_TASK_SUCCESS,
	GLOBAL_TASK_SOURCE_PENDING,
	GLOBAL_TASK_SOURCE_SUCCESS,
	GLOBAL_TASK_SOURCE_FAILED,
	GLOBAL_TASK_NOTIFICATION_REDIRECTION,
	GLOBAL_TASK_CREATE_LINK_UN_SAVE_CHECK,
	GLOBAL_TASK_CREATE_POP_UP_DETAILS,
} from './actionTypes';
import {
	getAllGlobalTaskAPI,
	getGlobalTaskSourceAPI,
	completedGlobalTaskAPI,
	createGlobalTaskAPI,
	updateGlobalTaskAPI,
	deleteGlobalTaskAPI,
	getRecentAssigneeAPI,
} from '../services/GlobalTaskService';

// Get global task

export const fetchGlobalTaskPending = () => ({
	type: GLOBAL_TASK_PENDING,
});
const fetchGlobalTaskCompleted = (payload) => ({
	type: GLOBAL_TASK_SUCCESS,
	payload,
});
const fetchGlobalTaskFailed = (error) => ({
	type: GLOBAL_TASK_FAILED,
	error,
});
const fetchGlobalDashboardTaskCompleted = (payload) => ({
	type: GLOBAL_DASHBOARD_TASK_SUCCESS,
	payload,
});

export const getAllGlobalTask = (data, isDashboardTask) => {
	return (dispatch) => {
		dispatch(fetchGlobalTaskPending());
		return getAllGlobalTaskAPI(data)
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					if (isDashboardTask) {
						dispatch(fetchGlobalDashboardTaskCompleted(response.data));
					} else {
						dispatch(fetchGlobalTaskCompleted(response.data));
					}
				} else {
					dispatch(fetchGlobalTaskFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchGlobalTaskFailed(error));
			});
	};
};
export const getAllGlobalTaskDirect = (data) => {
	return () => {
		return getAllGlobalTaskAPI(data);
	};
};
// Get global task source

const fetchGlobalTaskSourcePending = () => ({
	type: GLOBAL_TASK_SOURCE_PENDING,
});
const fetchGlobalTaskSourceCompleted = (payload) => ({
	type: GLOBAL_TASK_SOURCE_SUCCESS,
	payload,
});
const fetchGlobalTaskSourceFailed = (error) => ({
	type: GLOBAL_TASK_SOURCE_FAILED,
	error,
});

export const getGlobalTaskSource = (data, isDashboardTask) => {
	return (dispatch) => {
		dispatch(fetchGlobalTaskSourcePending());
		return getGlobalTaskSourceAPI(data)
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					dispatch(fetchGlobalTaskSourceCompleted(response.data));
				} else {
					dispatch(fetchGlobalTaskSourceFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchGlobalTaskSourceFailed(error));
			});
	};
};

// complete global task source
export const completedGlobalTask = (data) => {
	return () => {
		return completedGlobalTaskAPI(data);
	};
};

// Add global task source
export const createGlobalTask = (data) => {
	return () => {
		return createGlobalTaskAPI(data);
	};
};

// Update global task source
export const updateGlobalTask = (data) => {
	return () => {
		return updateGlobalTaskAPI(data);
	};
};

// Delete global task source
export const deleteGlobalTask = (data) => {
	return () => {
		return deleteGlobalTaskAPI(data);
	};
};

/**
 * New Global Task Action Start Here
 */
export const getRecentAssignee = (payload) => {
	return () => {
		return getRecentAssigneeAPI(payload);
	};
};

export const globalTaskNotificationRedirection = (payload) => ({
	type: GLOBAL_TASK_NOTIFICATION_REDIRECTION,
	payload,
});

export const globalTaskCreateLinkUnSaveCheck = (payload) => ({
	type: GLOBAL_TASK_CREATE_LINK_UN_SAVE_CHECK,
	payload,
});
export const globalTaskCreatePopupDetails = (payload) => ({
	type: GLOBAL_TASK_CREATE_POP_UP_DETAILS,
	payload,
});
