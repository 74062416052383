import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { OKRButton } from '../../../../Common/OKRButton';
import { EmailRecipientSetting } from '../Common/EmailRecipientSetting';
import { EnumEmailModule } from '../../../../../config/enums';
import { FeedbackRightSettingDropdown } from './FeedbackRightsSettingsDropdown';

export const FeedbackRightsSettings: React.FC<any> = (props: any) => {
	const {
		t,
		loader,
		feedbackSettingDetails,
		setFeedbackSettingDetails,
		updateFeedbackSettingAPICall,
		resetFeedbackSetting,
		isFeedbackStatusUpdated,
		setIsFeedbackStatusUpdated,
	} = props;

	const handleAllowReviewersToEditChange = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			updateFeedbackSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};
	const handleIsFeedbackVisibleToRMChange = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			updateFeedbackSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};
	const handleAllowPastDateToEditChange = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			updateFeedbackSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};
	const handleAllowuserCreateFeedback = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			updateFeedbackSetting(name, checked);
		} catch (error) {
			console.error(error);
		}
	};
	const updateFeedbackSetting = (key: string, value: boolean) => {
		setIsFeedbackStatusUpdated(true);
		setFeedbackSettingDetails({ ...feedbackSettingDetails, [key]: value });
	};

	return (
		<Box className='performance-development-card full-rectangular-card feedback-rights-settings'>
			<Box className='pd-card-header'>
				<Typography variant='h4' className='font-weight-normal'>
					{t('otherSettingsLabel')}
				</Typography>
			</Box>
			<Box className='pd-card-switch'>
				<FormControlLabel
					control={
						<Switch
							id='allowReviewersToEditFeedback'
							name='allowReviewersToEditFeedback'
							checked={feedbackSettingDetails?.allowReviewersToEditFeedback || false}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleAllowReviewersToEditChange}
						/>
					}
					label={t('allowReviewersToGetRightsFeedback')}
				/>
			</Box>
			<Box className='pd-card-switch'>
				<FormControlLabel
					control={
						<Switch
							id='isFeedbackVisibleToRM'
							name='isFeedbackVisibleToRM'
							checked={feedbackSettingDetails?.isFeedbackVisibleToRM || false}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleIsFeedbackVisibleToRMChange}
						/>
					}
					label={t('allowNomineeReportingManagerFeedback')}
				/>
			</Box>
			<Box className='pd-card-switch'>
				<FormControlLabel
					control={
						<Switch
							id='isPastDateFeedbackCreationAllow'
							name='isPastDateFeedbackCreationAllow'
							checked={feedbackSettingDetails?.isPastDateFeedbackCreationAllow || false}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleAllowPastDateToEditChange}
						/>
					}
					label={t('allowPastDateFeedbackRights')}
				/>
			</Box>
			<Box className='pd-card-switch'>
				<FormControlLabel
					control={
						<Switch
							id='allowAllUsersCreateFeedback'
							name='allowAllUsersCreateFeedback'
							checked={feedbackSettingDetails?.allowAllUsersCreateFeedback || false}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleAllowuserCreateFeedback}
						/>
					}
					label={t('allowAllToCreateFeedback')}
				/>
			</Box>
			{!feedbackSettingDetails?.allowAllUsersCreateFeedback ? (
				<Box className='pd-card-switch'>
					<FeedbackRightSettingDropdown
						t={t}
						loader={loader}
						messageNote={t('sendUpdateEmailsToNoteFeedback')}
						emailModuleId={EnumEmailModule.Feedback}
						feedbackSettingDetails={feedbackSettingDetails}
						setFeedbackSettingDetails={setFeedbackSettingDetails}
						setIsFeedbackStatusUpdated={setIsFeedbackStatusUpdated}
						//setLoader={setLoader}
					/>
				</Box>
			) : (
				<></>
			)}
			<Box className='pd-setting-card-actions'>
				<Box className='pd-setting-card-actions-left'></Box>
				<Box className='pd-setting-card-actions-right'>
					<OKRButton
						className='btn-link'
						text={t('cancelBtn')}
						disabled={loader || !isFeedbackStatusUpdated}
						handleClick={resetFeedbackSetting}
					/>
					<OKRButton
						className='btn-primary'
						text={t('saveBtn')}
						disabled={loader || !isFeedbackStatusUpdated}
						handleClick={updateFeedbackSettingAPICall}
					/>
				</Box>
			</Box>
		</Box>
	);
};
