import React, { Fragment, useState, useEffect } from 'react';
import {
	Box,
	Chip,
	List,
	ListItem,
	Typography,
	Tooltip,
	Avatar,
	TextField,
	TablePagination,
	InputAdornment,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CloneIcon, CrossIcon, EditIcon, SortingIcon, UserAvtarIcon } from '../../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../../Common/OKRButton';
import { DeleteIcon, SearchIcon } from '../../../../../../config/svg/CommonSvg';
import { AvatarGroup } from '@material-ui/lab';
import { getFullName, getMonthDateYear } from '../../../../../../config/utils';
import { NoRecord } from '../../../../CommonComponent/NoRecord';
import { useDispatch, useSelector } from 'react-redux';
import {
	goalCycleList,
	deleteGoalCycle,
	setHighlightGoalCycleId,
	setGoalFormCount,
	setGoalCyclePaginationData,
	getPerformanceGoalTabCount,
} from '../../../../../../action/adminSettings';
import NoDataImg from '../../../../../../images/my-goal-bg.svg';
import { DialogComponent } from '../../../../CommonComponent/DialogComponent';
import { EnumPerformanceGoalCycleStatus, Enums } from '../../../../../../config/enums';
import { useSnackbar } from 'notistack';

export const GoalCyclesList: React.FC<any> = (props: any) => {
	const {
		goalCycleSearchTextArray,
		pageIndex,
		pageSize,
		setPageIndex,
		setLoader,
		setPaginationData,
		handleGoalCycleAction,
		loader,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [goalCycleListData, setGoalCycleListData] = useState<any>(null);
	const [sortingData, setSortingData] = useState<any>({ column: 'createdOn', order: 'desc' });
	const { highlightGoalCycleId } = useSelector((state: any) => state?.adminSettingReducer);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });

	useEffect(() => {
		getGoalCycleList({
			finder: goalCycleSearchTextArray,
			pageIndex: pageIndex,
			sortingText: sortingData.column,
			order: sortingData.order,
			pageSize: pageSize,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalCycleSearchTextArray, pageIndex, pageSize]);

	useEffect(() => {
		if (goalCycleListData && goalCycleListData?.records && goalCycleListData?.records?.length && highlightGoalCycleId) {
			setTimeout(() => {
				dispatch(setHighlightGoalCycleId(null));
			}, 10000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalCycleListData, highlightGoalCycleId]);


	
	const getGoalCycleList = async (requestPayload: any) => {
		try {
			setLoader(true);
			const response: any = await dispatch(goalCycleList(requestPayload));
			if (response) {
				!goalCycleListData && setGoalCycleListData({ records: [], paggingInfo: {} });
				if (response && response.data && response.data.status === 200) {
					const { entity } = response.data;
					dispatch(setGoalCyclePaginationData(entity));
					if (entity) {
						const { records } = entity || {};
						if (records && records.length) {
							const updatedData = { records: records };
							setGoalCycleListData(updatedData);

							let responseData = { ...entity };
							delete responseData.records;
							dispatch(setPaginationData(responseData));
							dispatch(setGoalFormCount(records.length));
						} else {
							setGoalCycleListData({ records: [] });
							dispatch(setGoalFormCount(0));
						}
					} else {
						setGoalCycleListData({ records: [] });
					}
					setLoader(false);
				} else {
					setLoader(false);
					setGoalCycleListData({ records: [] });
				}
			}
		} catch (e) {
			setLoader(false);
			console.error(e);
		}
	};

	const sortCycleList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingData.column) {
			newSortOrder = sortingData.order === 'asc' ? 'desc' : 'asc';
		}
		setSortingData({ column: column, order: newSortOrder });
		getGoalCycleList({
			finder: goalCycleSearchTextArray,
			pageIndex: 1,
			sortingText: column,
			order: newSortOrder,
			pageSize: pageSize,
		});
	};

	const handleRecordClick = (e: any, record: any, type: string) => {
		e.preventDefault();
		e.stopPropagation();
		if (record && record.performanceGoalCycleId) {
			if (type === 'Delete') {
				setModalProps({
					open: true,
					message:
						record.status === EnumPerformanceGoalCycleStatus.Draft ||
						record.status === EnumPerformanceGoalCycleStatus.Submitted
							? t('deleteDraftGoalCycleText')
							: record.status === EnumPerformanceGoalCycleStatus.Published
							? t('deleteOngoingGoalCycleText')
							: t('deleteCompleteGoalCycleText'),
					details: record,
				});
			} else if (type === 'Clone') {
				//handleGoalCycleAction({ ...record, name: 'Cloned ' + record?.name }, true, type);
			} else if (type === 'Edit') {
				handleGoalCycleAction(record, true, type);
			}
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		//e.preventDefault();
		if (type === 1) {
			handleDeletePerformanceCycle();
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const handleDeletePerformanceCycle = async () => {
		if (modalProps.details && modalProps.details.performanceGoalCycleId) {
			setLoader(true);
			const queryParams = `performanceGoalCycleId=${modalProps.details.performanceGoalCycleId}`;
			setModalProps({ open: false, message: '', details: '' });
			const response: any = await dispatch(deleteGoalCycle(queryParams));
			if (response) {
				if (response && response?.data && response.data.status === 200) {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'cycle deleted successfully', {
						variant: 'success',
						autoHideDuration: 3000,
					});
					getGoalCycleList({
						finder: goalCycleSearchTextArray,
						pageIndex: pageIndex,
						sortingText: sortingData.column,
						order: sortingData.order,
						pageSize: pageSize,
					});
					dispatch(getPerformanceGoalTabCount());
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while deleting assessment form', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				setLoader(false);
			}
		}
	};

	return (
		<>
			{loader ? (
				<Box className='loading-center-global'>
					<Typography>Loading...</Typography>
				</Box>
			) : (
				<>
					<Box className='goal-cycles-listing'>
						{goalCycleListData && goalCycleListData.records && goalCycleListData.records.length > 0 ? (
							<Box className='okr-list-panel-head'>
								<List component='ul' disablePadding>
									<ListItem className='goal-cycles-col1'>
										<Typography
											onClick={(e) => sortCycleList(e, 'name')}
											variant='body2'
											id={'cycle-name'}
											className={sortingData.column === 'name' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>
													{t('nameLabel')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='goal-cycles-col2'>
										<Typography
											onClick={(e) => sortCycleList(e, 'startDate')}
											variant='body2'
											id={'start-Date'}
											className={
												sortingData.column === 'startDate' ? `sort-title ${sortingData.order}` : 'sort-title asc'
											}
										>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>
													{t('timeFrameText')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='goal-cycles-col3'>
										<Typography
											onClick={(e) => sortCycleList(e, 'settingDurationStartDate')}
											variant='body2'
											id={'setting-duration-Date'}
											className={
												sortingData.column === 'settingDurationStartDate'
													? `sort-title ${sortingData.order}`
													: 'sort-title asc'
											}
										>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>
													{t('settingDurationLabel')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='goal-cycles-col4'>
										<Typography
											onClick={(e) => sortCycleList(e, 'assigedUsersCount')}
											variant='body2'
											id={'assiged-users-count'}
											className={
												sortingData.column === 'assigedUsersCount'
													? `sort-title ${sortingData.order}`
													: 'sort-title asc'
											}
										>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>
													{t('numberUser')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='goal-cycles-col5'>
										<Typography
											onClick={(e) => sortCycleList(e, 'status')}
											variant='body2'
											id={'status-name'}
											className={sortingData.column === 'status' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
										>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>
													{t('statusLabel')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
								</List>
							</Box>
						) : (
							<></>
						)}
						{goalCycleListData && goalCycleListData?.records && goalCycleListData?.records.length > 0 ? (
							goalCycleListData?.records.map((record: any, index: number) => {
								return (
									<>
										<Box
											className={`goal-cycles-list-content ${
												highlightGoalCycleId === record?.performanceGoalCycleId ? 'heighlight-row' : ''
											}`}
										>
											<List component='ul' disablePadding key={`review-form-list-${record?.performanceGoalCycleId}`}>
												<ListItem className='goal-cycles-col1'>
													{record?.name && record?.name.length > 35 ? (
														<Tooltip arrow title={record?.name || ''}>
															<Typography variant='h4' className='font-weight-normal'>
																{record?.name.substring(0, 35)}...
															</Typography>
														</Tooltip>
													) : (
														<Typography variant='h4' className='font-weight-normal'>
															{record?.name || ''}
														</Typography>
													)}
													<Box className='goal-cycles-list-actions'>
														{/* <OKRButton
												//handleClick={(event: any) => handleRecordClick(event, record, 'Clone')}
												icon={<CloneIcon />}
												title={t('clone')}
											/> */}
														<OKRButton
															handleClick={(event: any) => handleRecordClick(event, record, 'Edit')}
															icon={<EditIcon />}
															title={t('editLabel')}
														/>
														<OKRButton
															handleClick={(event: any) => handleRecordClick(event, record, 'Delete')}
															icon={<DeleteIcon />}
															title={t('delete')}
														/>
													</Box>
												</ListItem>
												<ListItem className='goal-cycles-col2'>
													<Typography variant='h4' className='font-weight-normal'>
														{record?.startDate
															? getMonthDateYear(record.startDate) + ' - ' + getMonthDateYear(record.endDate)
															: ''}
													</Typography>
												</ListItem>
												<ListItem className='goal-cycles-col3'>
													<Typography variant='h4' className='font-weight-normal'>
														{record?.settingDurationStartDate
															? getMonthDateYear(record?.settingDurationStartDate) +
															  ' - ' +
															  getMonthDateYear(record?.settingDurationEndDate)
															: ''}
													</Typography>
												</ListItem>
												<ListItem className='goal-cycles-col4'>
													<Box className='user-avatar-count'>
														<Typography variant='h4' className='font-weight-normal'>
															{record?.assigedUsersCount || 0}
														</Typography>
														{/* <AvatarGroup className='avatar-count-group' max={1}>
															<Avatar className='avatar-default-grey' alt='User Avtar'>
																<UserAvtarIcon />
															</Avatar>
															<Avatar alt='User Avtar Count' className='count-more-then-two'>
																{record?.assigedUsersCount || 0}
															</Avatar>
														</AvatarGroup> */}
													</Box>
												</ListItem>
												<ListItem className='goal-cycles-col5'>
													<Chip className='goal-cycles-chip' label={record?.statusName} />
												</ListItem>
											</List>
										</Box>
									</>
								);
							})
						) : (
							<NoRecord
								noRecordMessage={goalCycleSearchTextArray ? t('noRecordPip') : t('noGoalCyclesCreatedYet')}
								subHead={goalCycleSearchTextArray ? t('noRecordPipText') : t('clickPlusIconToAddCycles')}
								noDataImgWidth={'516'}
								noImg={NoDataImg}
								{...props}
							/>
						)}

						{modalProps && modalProps.open && (
							<DialogComponent
								module='confirmation'
								modalOpen={modalProps.open}
								handleCloseModal={(e: any) => handleCloseModal(e, 2)}
								matchString={Enums.DELETE_TEXT}
								handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
								headingText={t('deletePerformanceCycleHeadingText')}
								subHeading={modalProps.message}
							/>
						)}
					</Box>
				</>
			)}
		</>
	);
};
