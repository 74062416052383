import React, { Fragment, useState } from 'react';
import { Box, FormControlLabel, FormLabel, Switch, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../../styles/pages/admin/settings/addPerformReview.scss';
import { CalendarIcon, EmailIcon, ErrorIcon, NameIcon } from '../../../../../config/svg/formElementIcons';
import { UserAvatarGroup } from '../../../CommonComponent/UserAvatarGroup';
import { RequestOneOnOneIcon } from '../../../../../config/svg/PersonalFeedbackSvg';
import ReactDateRangePicker from '../../../../Common/ReactDateRangePicker';
import { Enums } from '../../../../../config/enums';
// import { formatDate, formatFeedbackDate } from '../../../../../config/utils';
// import { getLocalStorageItem } from '../../../../../services/StorageService';
// import { addPerformanceCycle } from '../../../../../action/adminSettings';
import { MainDrawer } from '../../../CommonComponent/MainDrawer';
import { ExcludeUserDrawer } from './ExcludeUserDrawer';

export const GeneralSettings: React.FC<any> = (props: any) => {
	const {
		performanceFormData,
		setPerformanceFormData,
		performanceReviewError,
		handlePerformanceReviewValueChange,
		selectedDateRange,
		setSelectedDateRange,
		completeByDate,
		setCompleteBy,
		// currentCycleDetails,
		curCycleData,
		cycleDurationError,
		completeByError,
		setCompleteByError,
		handleDateOverlapCheck,
		handlePerformancePeriodDateOverlapCheck,
		type,
		editableDetails,
		minDateValue,
		maxDateValue,
		completeByMinDateValue,
		setIsPerformanceFormEdited,
		openUserDrawer,
		setOpenUserDrawer,
		excludedUseList,
		setExcludedUseList,
		handleCompleteByDateChange,
		performancePeriodByError,
		selectedPerformacePeriodDateRange,
		setSelectedPerformacePeriodDateRange,
		setPerformancePeriodByError,
	} = props;
	const { t } = useTranslation();
	const [localArrList, setLocalArrList] = useState<any>({ selectedUser: [], raisedForId: [] });

	const handleDrawerClose = (event: any, type: string) => {
		if (type === 'exclude') {
			let updatedData = {
				raisedForId: [...excludedUseList?.raisedForId, ...localArrList?.raisedForId],
				selectedUser: [...excludedUseList?.selectedUser, ...localArrList?.selectedUser],
			};
			const uniqueValue =
				updatedData?.raisedForId && updatedData?.raisedForId.length
					? Array.from(new Set([...updatedData.raisedForId]))
					: [];
			const finalData: any = [];
			uniqueValue &&
				uniqueValue.length &&
				uniqueValue.forEach((item: any) => {
					const data =
						updatedData.selectedUser && updatedData.selectedUser.length
							? updatedData.selectedUser.find((rec: any) => rec?.employeeId === item)
							: null;
					if (data) {
						finalData.push({ ...data });
					}
				});
			updatedData = {
				raisedForId: [...uniqueValue],
				selectedUser: [...finalData],
			};
			setIsPerformanceFormEdited(true);
			setExcludedUseList(updatedData);
			setPerformanceFormData({
				...performanceFormData,
				excludedEmployeeId: updatedData?.raisedForId,
				excludedUsersList: updatedData?.selectedUser,
			});
			/*if (excludedUseList?.selectedUser > localArrList?.selectedUser) {
				setIsPerformanceFormEdited(true);
				setExcludedUseList(excludedUseList);
				setPerformanceFormData({
					...performanceFormData,
					excludedEmployeeId: excludedUseList?.raisedForId,
					excludedUsersList: excludedUseList?.selectedUser,
				});
			} else {
				setExcludedUseList(localArrList);
				setPerformanceFormData({
					...performanceFormData,
					excludedEmployeeId: localArrList?.raisedForId,
					excludedUsersList: localArrList?.selectedUser,
				});
			}*/
		} else if (type === 'cancel' || type === 'close') {
			setExcludedUseList(excludedUseList);
		}
		setOpenUserDrawer({ open: false, type: '' });
	};
	const handleExcludeUserClick = (event: any) => {
		setOpenUserDrawer({ open: true, type: 'AddEdit' });
	};

	const handleUserAvatarClick = (event: any) => {
		setOpenUserDrawer({ open: true, type: 'View' });
	};

	return (
		<Box className='col-full '>
			<Box className='col-half '>
				<Box className='perform-txt-field helperText-bottom'>
					<TextField
						id='perform-review-name'
						fullWidth
						className=''
						disabled={type === 'View' ? true : false}
						required
						label={
							<Fragment>
								<EmailIcon />
								{t('performanceReviewName')}
							</Fragment>
						}
						placeholder={t('reviewName')}
						value={performanceFormData.performanceReviewName || ''}
						name='performanceReviewName'
						onChange={handlePerformanceReviewValueChange}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{ 'aria-label': t('performanceReviewName'), disableUnderline: true }}
						error={
							performanceReviewError?.type === 'performanceReviewName' && performanceReviewError?.helperText
								? true
								: false
						}
						helperText={
							performanceReviewError?.type === 'performanceReviewName' &&
							performanceReviewError?.helperText && (
								<Fragment>
									<ErrorIcon />
									{performanceReviewError?.helperText}
								</Fragment>
							)
						}
					/>
					{/* {performanceReviewError?.type === 'performanceReviewName' && performanceReviewError?.helperText ? (
						<Typography className='error-field'>
							<ErrorIcon /> {performanceReviewError?.helperText}
						</Typography>
					) : (
						<></>
					)} */}
				</Box>

				<Box
					className={`perform-txt-field drawer-date-field ${
						type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? 'disabled-field' : ''
					}`}
				>
					<Box className='field-col-2 helperText-bottom'>
						<Box
							className={`helperText-bottom ${
								cycleDurationError?.type === 'startDate' && cycleDurationError?.helperText ? 'custom-error-field' : ''
							}`}
						>
							<FormLabel id='set-a-duration' component='legend' className='form-label-required'>
								<CalendarIcon />
								{t('setaDuration')}
								<sup>*</sup>
							</FormLabel>
							<ReactDateRangePicker
								type={'ContributorList'}
								selectedCycleDetails={curCycleData || {}}
								startDateValue={type !== 'Add' && editableDetails ? editableDetails?.startDate : null}
								endDateValue={type !== 'Add' && editableDetails ? editableDetails?.endDate : null}
								minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
								maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
								handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
									range && handleDateOverlapCheck(range, start, end);
								}}
								isDateRangeTextFieldVisible={true}
								isOnlyDueDateVisible={false}
								allCycleDetails={[]}
								isCycleVisible={false}
								isEndOfMonthVisible={false}
								numberOfCalendars={Enums.TWO}
								selectionType={'range'}
								selectedDateRange={selectedDateRange}
								setSelectedDateRange={setSelectedDateRange}
								placeholderText={t('selectedDateRange')}
								isDatePickerDisable={
									type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? true : false
								}
							/>

							{cycleDurationError?.type === 'startDate' && cycleDurationError?.helperText ? (
								<Typography className='error-field'>
									<ErrorIcon /> {cycleDurationError?.helperText}
								</Typography>
							) : (
								<></>
							)}
						</Box>
						<Typography variant='h6'>{t('assessmentPeriodLabel')}</Typography>
					</Box>
					<Box className='field-col-2 '>
						{/* key={`complete_by_date_${new Date().getTime()}`} */}
						<Box
							className={`helperText-bottom ${
								completeByError?.type === 'reviewDate' && completeByError?.helperText ? 'custom-error-field' : ''
							}`}
						>
							<FormLabel id='set-a-duration' component='legend' className='form-label-required'>
								<CalendarIcon />
								{t('completeBy')}
								<sup>*</sup>
							</FormLabel>
							<ReactDateRangePicker
								type={'ContributorList'}
								startDateValue={type !== 'Add' && editableDetails ? editableDetails?.reviewDate : null}
								selectedCycleDetails={curCycleData || {}}
								minimumDate={completeByMinDateValue || new Date()}
								maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
								handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
									if (range) {
										setCompleteByError({
											error: '',
											helperText: '',
											type: '',
										});
										// setCompleteBy(range);
										// setIsPerformanceFormEdited(true);
										handleCompleteByDateChange(range, start);
									}
								}}
								isDateRangeTextFieldVisible={true}
								isOnlyDueDateVisible={false}
								allCycleDetails={[]}
								isCycleVisible={false}
								isEndOfMonthVisible={false}
								numberOfCalendars={Enums.ONE}
								selectionType={'single'}
								selectedDateRange={completeByDate}
								setSelectedDateRange={setCompleteBy}
								placeholderText={t('selectedDate')}
								isDatePickerDisable={type === 'View' ? true : false}
							/>

							{completeByError?.type === 'reviewDate' && completeByError?.helperText ? (
								<Typography className='error-field'>
									<ErrorIcon /> {completeByError?.helperText}
								</Typography>
							) : (
								<></>
							)}
						</Box>
						<Typography variant='h6'>{t('completeByNote')}</Typography>
					</Box>
				</Box>
				<Box
					className={`perform-txt-field drawer-date-field ${
						type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? 'disabled-field' : ''
					}`}
				>
					<Box className='full-width-field'>
						<Box
							className={`helperText-bottom ${
								performancePeriodByError?.type === 'performancePeriod' && performancePeriodByError?.helperText
									? 'custom-error-field'
									: ''
							}`}
						>
							<FormLabel id='set-a-duration' component='legend' className='form-label-required'>
								<CalendarIcon />
								{t('performancePeriod')}
								<sup>*</sup>
							</FormLabel>
							<ReactDateRangePicker
								type={'ContributorList'}
								selectedCycleDetails={curCycleData || {}}
								startDateValue={type !== 'Add' && editableDetails ? editableDetails?.performancePeriodStartDate : null}
								endDateValue={type !== 'Add' && editableDetails ? editableDetails?.performancePeriodEndDate : null}
								minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
								maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
								handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
									if (range) {
										handlePerformancePeriodDateOverlapCheck(range, start, end);
										/*setPerformancePeriodByError({
											error: '',
											helperText: '',
											type: '',
										});
										// setCompleteBy(range);
										// setIsPerformanceFormEdited(true);
										setSelectedPerformacePeriodDateRange(range);*/
									}
								}}
								isDateRangeTextFieldVisible={true}
								isOnlyDueDateVisible={false}
								allCycleDetails={[]}
								isCycleVisible={false}
								isEndOfMonthVisible={false}
								numberOfCalendars={Enums.TWO}
								selectionType={'range'}
								selectedDateRange={selectedPerformacePeriodDateRange}
								setSelectedDateRange={setSelectedPerformacePeriodDateRange}
								placeholderText={t('selectedDateRange')}
								isDatePickerDisable={
									type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? true : false
								}
							/>
							{performancePeriodByError?.type === 'performancePeriod' && performancePeriodByError?.helperText ? (
								<Typography className='error-field'>
									<ErrorIcon /> {performancePeriodByError?.helperText}
								</Typography>
							) : (
								<></>
							)}
						</Box>
						<Typography variant='h6'>{t('performancePeriodLabel')}</Typography>
					</Box>
				</Box>
			</Box>

			<Box className='setting-blue-box'>
				<Box className='setting-blue-box-head'>
					<Box className='head-left'>
						<NameIcon />
						<Typography>{t('excludeUsers')}</Typography>
					</Box>
				</Box>
				<UserAvatarGroup
					{...props}
					subTitle={' '}
					helpText={' '}
					handleAddUserClick={handleExcludeUserClick}
					isOnHoverVisible={false}
					assignedUserDetails={
						// type !== 'Add' && editableDetails
						// 	?
						excludedUseList && excludedUseList?.selectedUser && excludedUseList?.selectedUser.length > 0
							? excludedUseList?.selectedUser.map((item: any) => item)
							: []
						// : performanceFormData?.excludedUsersList &&
						//   performanceFormData?.excludedUsersList.length > 0 &&
						//   performanceFormData?.excludedUsersList.map((item: any) => item)
					}
					max={6}
					isUserAvatarClick={type !== 'Add' && editableDetails ? true : false}
					handleUserAvatarClick={handleUserAvatarClick}
					isDisabled={type === 'View' ? true : false}
				/>
				<Typography variant='h6'>{t('excludeUsersNote')}</Typography>
			</Box>
			<Box className='setting-blue-box'>
				<Box className='setting-blue-box-head'>
					<Box className='head-left'>
						<RequestOneOnOneIcon />
						<Typography>{t('requestOnetoOne')}</Typography>
					</Box>
				</Box>
				<Box className='setting-user-action'>
					<FormControlLabel
						control={
							<Switch
								name='isOneToOneReview'
								disabled={type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? true : false}
								checked={Boolean(performanceFormData.isOneToOneReview)}
								onChange={handlePerformanceReviewValueChange}
							/>
						}
						label={t('settingRequest1on1')}
						labelPlacement='end'
						className='toggle-label'
					/>
					<Typography variant='h6'>{t('requestDes')}</Typography>
				</Box>
			</Box>
			{openUserDrawer && openUserDrawer?.open && (
				<MainDrawer
					open={openUserDrawer?.open || false}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={t('excludeUsers')}
					// loader={loader}
					children={
						<Box className='drawer-inner-content'>
							<ExcludeUserDrawer
								{...props}
								setExcludedUseList={setExcludedUseList}
								excludedUseList={excludedUseList}
								localArrList={localArrList}
								setLocalArrList={setLocalArrList}
								drawerType={openUserDrawer?.type || 'AddEdit'}
							/>
						</Box>
					}
					saveButtonText={t('excludeLabel')}
					rightCloseBtn={false}
					drawerClassName={'main-drawer-panel '}
					isSaveButtonVisible={type !== 'View' ? true : false}
					handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
					handleSaveClick={(event: any) => handleDrawerClose(event, 'exclude')}
					handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
				/>
			)}
		</Box>
	);
};
