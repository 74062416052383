import React, { useState } from 'react';
import { HOME, TEAM_VIEW } from '../../../config/app-url';
import { setLocalStorageItem } from '../../../services/StorageService';
import { OrgChartWrapper } from '../../Admin/d3OrgChart/OrgChartWrapper';
// import Organizations from '../../Admin/Organizations';

const OrganisationChart: React.FC<any> = (props) => {
	const [modelProps, setModelProps] = useState<any>({});

	const handleViewOrganization = (e: any, currentOrg: any) => {
		const selectedOrg = currentOrg ? { ...currentOrg } : {};

		selectedOrg?.parent && delete selectedOrg['parent'];
		selectedOrg?.children && delete selectedOrg['children'];
		selectedOrg?._children && delete selectedOrg['_children'];

		setModelProps({ ...modelProps, anchorEl: null, open: false });

		//Set current organisation
		setLocalStorageItem('currentOrganisation', JSON.stringify(selectedOrg));
		// setting redirect url for dashboard teamsOkr tab
		setLocalStorageItem(
			'backUrl',
			JSON.stringify({
				url: HOME,
				tabToOpen: 'orgChart',
			})
		);
		props.resetOkr();
		props.history.push(TEAM_VIEW);
		props.updateRoute(TEAM_VIEW);
	};

	return (
		<OrgChartWrapper {...props} tabSelected={2} isOrgChart={true} handleViewOrganization={handleViewOrganization} />
	);
};

export default OrganisationChart;
