import { globalSearchAPIWithTeamAPI } from '../services/GlobalSearchService';
import {
	getDefaultOkrCycleDetailsAPI,
	getDefaultOkrDesignationDetailsAPI,
	getDefaultOkrPerformanceRoleDetailsAPI,
	ListDefaultOkrAPI,
	createDefaultOkrAPI,
	editDefaultOkrAPI,
	getDefaultOkrDetailsForEditCallAPI,
	defaultOkrDeleteAPI,
	copyDefaultOkrAPI,
	copyOkrApi,
	cloneOkrApi,
	publishFromListingApi,
	exportDefaultOkrReportAPI,
	RePublishFromListingApi,
	validateDefaultOkrReportAPI,
	uploadDefaultOkrReportAPI,
} from '../services/adminDefaultOkr';
import {
	SET_CYCLE_DETAILS_DEFAULT_OKR,
	FAIL_CYCLE_DETAILS_DEFAULT_OKR,
	PENDING_CYCLE_DETAILS_DEFAULT_OKR,
	SET_DESIGNATION_DETAILS_DEFAULT_OKR,
	FAIL_DESIGNATION_DETAILS_DEFAULT_OKR,
	PENDING_DESIGNATION_DETAILS_DEFAULT_OKR,
	SET_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	FAIL_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	PENDING_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	OPEN_ADMIN_COPY_OKR,
	SET_TOTAL_EMPLOYEE_COUNT,
	REFRESH_ADMIN_DEFAULT_OKR,
	PAST_SELECTED_CYCLE,
	ALIGN_SELECTED_CYCLE,
} from './actionTypes';

export const setCycleDetailsForDefaultOkr = (payload) => ({
	type: SET_CYCLE_DETAILS_DEFAULT_OKR,
	payload,
});
export const failCycleDetailsForDefaultOkr = (payload) => ({
	type: FAIL_CYCLE_DETAILS_DEFAULT_OKR,
	payload,
});
export const pendingCycleDetailsForDefaultOkr = (payload) => ({
	type: PENDING_CYCLE_DETAILS_DEFAULT_OKR,
	payload,
});
export const getDefaultOkrCycleDetails = (data) => {
	return (dispatch) => {
		dispatch(pendingCycleDetailsForDefaultOkr(null));
		return getDefaultOkrCycleDetailsAPI(data)
			.then((response) => {
				if (response.status === 200) {
					const { entity } = response?.data || {};
					dispatch(setCycleDetailsForDefaultOkr(entity || null));
				} else {
					dispatch(failCycleDetailsForDefaultOkr(null));
				}

				// dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(failCycleDetailsForDefaultOkr(null));
			});
	};
};

export const setDesignationForDefaultOkr = (payload) => ({
	type: SET_DESIGNATION_DETAILS_DEFAULT_OKR,
	payload,
});
export const failDesignationForDefaultOkr = (payload) => ({
	type: FAIL_DESIGNATION_DETAILS_DEFAULT_OKR,
	payload,
});
export const pendingDesignationForDefaultOkr = (payload) => ({
	type: PENDING_DESIGNATION_DETAILS_DEFAULT_OKR,
	payload,
});
export const getDefaultOkrDesignation = (data) => {
	return (dispatch) => {
		dispatch(pendingDesignationForDefaultOkr(null));
		return getDefaultOkrDesignationDetailsAPI(data)
			.then((response) => {
				if (response.status === 200) {
					const { entityList } = response?.data || {};
					dispatch(setDesignationForDefaultOkr(entityList || []));
				} else {
					dispatch(failDesignationForDefaultOkr(null));
				}
			})
			.catch((error) => {
				dispatch(failDesignationForDefaultOkr(null));
			});
	};
};

export const setPerformanceRoleForDefaultOkr = (payload) => ({
	type: SET_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	payload,
});
export const failPerformanceRoleForDefaultOkr = (payload) => ({
	type: FAIL_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	payload,
});
export const pendingPerformanceRoleForDefaultOkr = (payload) => ({
	type: PENDING_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	payload,
});
export const getDefaultOkrPerformanceRole = (data) => {
	return (dispatch) => {
		dispatch(pendingPerformanceRoleForDefaultOkr(null));
		return getDefaultOkrPerformanceRoleDetailsAPI(data)
			.then((response) => {
				if (response.status === 200) {
					const { entityList } = response?.data || {};
					dispatch(setPerformanceRoleForDefaultOkr(entityList || []));
				} else {
					dispatch(failPerformanceRoleForDefaultOkr(null));
				}
			})
			.catch((error) => {
				dispatch(failPerformanceRoleForDefaultOkr(null));
			});
	};
};

export const getUserListUsingSearchForDefaultOkr = (data, page, pageSize, type) => {
	return () => {
		return globalSearchAPIWithTeamAPI(data, page, pageSize, type);
	};
};

export const openAdminCopyOkr = (payload) => ({
	type: OPEN_ADMIN_COPY_OKR,
	payload,
});
export const pastCycleSelected = (payload) => ({
	type: PAST_SELECTED_CYCLE,
	payload,
});
export const refreshAdminDefaultOkrList = (payload) => ({
	type: REFRESH_ADMIN_DEFAULT_OKR,
	payload,
});
export const getAdminDefaultOkrList = (data) => {
	return () => {
		return ListDefaultOkrAPI(data);
	};
};
export const getCopyDefaultOkrList = (data) => {
	return () => {
		return copyDefaultOkrAPI(data);
	};
};
export const addCopyOkr = (data) => {
	return () => {
		return copyOkrApi(data);
	};
};
export const cloneObjective = (data) => {
	return () => {
		return cloneOkrApi(data);
	};
};
export const publishObjective = (data) => {
	return () => {
		return publishFromListingApi(data);
	};
};
export const RePublishObjective = (data) => {
	return () => {
		return RePublishFromListingApi(data);
	};
};

export const setTotalEmployeeCount = (payload) => ({
	type: SET_TOTAL_EMPLOYEE_COUNT,
	payload,
});

export const createDefaultOkrCall = (payload) => {
	return () => {
		return createDefaultOkrAPI(payload);
	};
};

export const editDefaultOkrCall = (payload) => {
	return () => {
		return editDefaultOkrAPI(payload);
	};
};

export const getDefaultOkrDetailsForEditCall = (payload) => {
	return () => {
		return getDefaultOkrDetailsForEditCallAPI(payload);
	};
};

export const defaultOkrDelete = (payload) => {
	return () => {
		return defaultOkrDeleteAPI(payload);
	};
};

export const exportDefaultOkrReport = (data) => {
	return () => {
		return exportDefaultOkrReportAPI(data);
	};
};

export const validateDefaultOkrReport = (data) => {
	return () => {
		return validateDefaultOkrReportAPI(data);
	};
};

export const uploadDefaultOkrReport = (data) => {
	return () => {
		return uploadDefaultOkrReportAPI(data);
	};
};

export const alignSelectedCycle = (payload) => ({
	type: ALIGN_SELECTED_CYCLE,
	payload,
});