import { Box } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MainDrawer } from '../../Admin/CommonComponent/MainDrawer';
import { PeopleProgressLists } from './PeopleProgressLists';
import { useDispatch, useSelector } from 'react-redux';
import { getOtherTeamDetails } from '../../../action/myGoal';
import { Enums } from '../../../config/enums';
import _ from 'lodash';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';

export const TeamProgressDrawer = (props: any) => {
	const {
		openPeopleProgress,
		handlePeopleProgressClose,
		cycleId,
		userDetail,
		setTeamToFilter,
		selectedUsers,
		setSelectedUsers,
	} = props;
	const { t } = useTranslation();
	const [loader, setLoader] = useState(false);
	const [pageIndex, setPageIndex] = useState<any>(1);
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchText, setSearchText] = useState<string>('');
	const [sorting, setSorting] = useState<any>({
		sortingText: '',
		order: '',
	});
	const dispatch = useDispatch();
	const [drOtherData, setDrOtherData] = useState<any>([]);
	const [paggingInfo, setPaggingInfo] = useState<any>({});
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [checkAll, setCheckAll] = useState(false);
	const [modalOpen, setModalOpen] = useState<any>({ open: false, item: null });
	const { peopleProgressFilters } = useSelector((state: any) => state?.okrReducer);

	useEffect(() => {
		setDrOtherData([]);
		setShowLoadMore(false);
		setPaggingInfo({});
		setCheckAll(false);
	}, []);
	useEffect(() => {
		getDrOtherDetails();
	}, [pageIndex, searchText, sorting]);
	const getDrOtherDetails = async () => {
		//setLoader(true);
		let requestParam: any = {
			cycleId: cycleId,
			employeeId: userDetail.employeeId,
			pageIndex: pageIndex,
			pageSize: 99999,
			sortingText: sorting.sortingText,
			order: sorting.order,
			finder: searchText ? [searchText] : [],
			teamToFilter: [],
		};
		const response: any = await dispatch(getOtherTeamDetails(requestParam));
		if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
			if (pageIndex !== 1) {
				let currentRecords = [...drOtherData, ...response?.data.entity.teamDetails];
				setDrOtherData(currentRecords);
			} else {
				setDrOtherData(response?.data.entity.teamDetails);
			}
			setPaggingInfo({
				pageIndex: response?.data?.entity.pageIndex,
				pageSize: response?.data?.entity.pageSize,
				totalPages: response?.data?.entity.totalPages,
				totalRecords: response?.data?.entity.totalRecords,
			});
			if (pageIndex < response?.data?.entity.totalPages) {
				setShowLoadMore(true);
			} else {
				setShowLoadMore(false);
			}
			//setLoader(false);
		} else {
			//setLoader(false);
		}
	};

	const loadMore = (pageIndex: any) => {
		setPageIndex(pageIndex);
		setCheckAll(false);
	};

	const handleDrawerClose = (event: any, type: string) => {
		if (type === 'save' && Object.keys(selectedUsers).length !== 0) {
			setTeamToFilter(Object.keys(selectedUsers));
			handlePeopleProgressClose(event, type);
		} else {
			let hasChange = false;
			Object.keys(selectedUsers).forEach((element: any) => {
				if (peopleProgressFilters.teamToFilter.indexOf(element) === -1) {
					hasChange = true;
				}
			});
			if (Object.keys(selectedUsers).length > 0 && hasChange) {
				setModalOpen({ open: true, item: null });
			} else {
				setTeamToFilter(Object.keys(selectedUsers));
				if (Object.keys(selectedUsers).length > 0) {
					handlePeopleProgressClose(event, 'save');
				} else {
					handlePeopleProgressClose(event, 'close');
				}
			}
		}
	};

	const delayedQuery = useCallback(
		_.debounce((q) => _handleSearch(q), 500),
		[]
	);
	const handleInputThrottled = (value: any) => {
		setSearchValue(value);
		delayedQuery(value);
	};
	const _handleSearch = (value: any) => {
		setSearchText(value);
		setPageIndex(1);
	};
	const sortList = (e: any, type: string, order: string) => {
		setSorting({
			sortingText: type,
			order: order,
		});
		setPageIndex(1);
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (type === 1) {
			handlePeopleProgressClose(e, 'close');
		}
		setModalOpen({ open: false, item: null });
	};
	return (
		<>
			<MainDrawer
				open={openPeopleProgress}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('teamsProgress')}
				loader={loader}
				children={
					<Box className='drawer-inner-content'>
						<PeopleProgressLists
							{...props}
							drOtherData={drOtherData}
							setDrOtherData={setDrOtherData}
							selectedUsers={selectedUsers}
							setSelectedUsers={setSelectedUsers}
							showLoadMore={showLoadMore}
							loadMore={loadMore}
							pageIndex={paggingInfo.pageIndex || 1}
							checkAll={checkAll}
							setCheckAll={setCheckAll}
							totalPages={paggingInfo.totalPages}
							handleInputThrottled={handleInputThrottled}
							searchValue={searchValue}
							totalRecords={paggingInfo.totalRecords}
							sortList={sortList}
							sorting={sorting}
						/>
					</Box>
				}
				saveButtonText={t('applyLabel')}
				//isSaveButtonDisabled={selectedUsers && Object.keys(selectedUsers).length === 0 ? true : false}
				isFooterVisible={true}
				rightCloseBtn={true}
				drawerClassName={'main-drawer-panel other-teams-drawer-panel'}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
			/>
			{modalOpen.open && (
				<DialogComponent
					module='information'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen.open}
				/>
			)}
		</>
	);
};
