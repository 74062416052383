import React, { useState, useEffect, Fragment } from 'react';
import { Box, Button } from '@material-ui/core';
import '../style.scss';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { AddContributorDetail } from '../KeyResult/AddContributorDetail';
import moment from 'moment';
import { Enums } from '../../../config/enums';
import AlertDialog from '../../Common/Dialog';

export const AddEditContributorDetail: React.FC<any> = (props) => {
	const {
		keyData,
		onHandleCancelClick,
		onSaveContributorDetailClick,
		selectedContributorDetails,
		getCurrencyIcon,
		keyAttribute,
		t,
		handleBack,
		handleCancel,
		durationCycleDetail,
		objectiveStartDate,
		objectiveEndDate,
		hideBackButton,
		goalTypeId,
		setCallOut,
		setAnchorElCallouts,
		initialCallout,
		calloutsData,
		hideKrConfidence,
	} = props;
	const [selectedContributorDetailsD, setSelectedContributorDetails] = useState<any>(selectedContributorDetails);
	const [krContributorDetailDashboard, setKRContributorDetailDashboard] = useState<any>(selectedContributorDetailsD);
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);
	const [subModalProps, setSubModalProps] = useState<any>(null);
	const [defaultAssignData, setDefaultAssignData] = useState<any>({});

	useEffect(() => {
		setDefaultAssignData(krContributorDetailDashboard);
	}, []);

	// const onHandleSaveClicked = (e: any) => {
	// 	onSaveContributorDetailClick(e, krContributorDetailDashboard);
	// };

	const validateAddContributorForm = (formData: any) => {
		if (formData.assignmentTypeId === Enums.TWO) {
			if (!formData.objectiveName) {
				setKRAttrDateError({
					error: t('objectiveNameError'),
					helperText: t('objectiveNameError'),
					type: 'objectiveName',
				});
				return false;
			} else if (formData.objectiveName.trim() === '') {
				setKRAttrDateError({
					error: t('objectiveNameError'),
					helperText: t('objectiveNameError'),
					type: 'objectiveName',
				});
				return false;
			}
		}
		if (!formData.keyResult) {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			return false;
		} else if (formData.keyResult.trim() === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			return false;
		}
		if (formData.metricId > 0 && formData.metricId !== Enums.FOUR && formData.metricId !== Enums.FIVE) {
			formData.startValue = !formData.startValue ? 0 : formData.startValue;
			formData.targetValue = !formData.targetValue ? 0 : formData.targetValue;
			if (formData.startValue === formData.targetValue) {
				setKRAttrDateError({
					error: t('startCurrentSameError'),
					helperText: t('startCurrentSameError'),
					type: 'targetValue',
				});
				return false;
			}
		}
		return true;
	};
	const checkAssignUnsavedData = (e: any) => {
		if (
			defaultAssignData.objectiveName !== krContributorDetailDashboard?.objectiveName ||
			defaultAssignData.keyResult !== krContributorDetailDashboard?.keyResult ||
			defaultAssignData.krAssignerMessage !== krContributorDetailDashboard?.krAssignerMessage ||
			defaultAssignData.startDate !== krContributorDetailDashboard?.startDate ||
			defaultAssignData.dueDate !== krContributorDetailDashboard?.dueDate ||
			defaultAssignData.startValue !== krContributorDetailDashboard['startValue'] ||
			defaultAssignData.targetValue !== krContributorDetailDashboard['targetValue']
		) {
			setSubModalProps(true);
		} else {
			handleCancel(e);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		}
	};
	const handleCloseSubModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
			handleCancel(event);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		}
		setSubModalProps(false);
	};

	return (
		<Fragment>
			<OKRDrawer
				open={true}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('AssignTeamsKr')}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-subpanel'>
							<AddContributorDetail
								{...props}
								t={props.t}
								type={2}
								keyData={keyData}
								durationCycleDetail={durationCycleDetail}
								objectiveStartDate={objectiveStartDate}
								objectiveEndDate={objectiveEndDate}
								defaultKRDashboardDateError={krAttrDateError}
								krContributorDetailDashboard={krContributorDetailDashboard}
								setKRContributorDetailDashboard={setKRContributorDetailDashboard}
								selectedContributorDetails={selectedContributorDetailsD}
								onHandleCancelClick={onHandleCancelClick}
								onSaveContributorDetailClick={() => {}}
								getCurrencyIcon={getCurrencyIcon}
								goalTypeId={goalTypeId}
								keyAttribute={keyAttribute}
								setCallOut={setCallOut}
								setAnchorElCallouts={setAnchorElCallouts}
								initialCallout={initialCallout}
								calloutsData={calloutsData}
								hideKrConfidence={hideKrConfidence}
							/>
						</Box>
					</Box>
				}
				moreButton={false}
				handleDrawerClose={(e: any) => {
					checkAssignUnsavedData(e);
				}}
				handleSaveClick={(event: any) => {
					const datesDifferenceInDays = moment(new Date(krContributorDetailDashboard.startDate || new Date())).isAfter(
						new Date(krContributorDetailDashboard.dueDate || new Date()),
						'days'
					);
					if (Boolean(datesDifferenceInDays)) {
						setKRAttrDateError({
							error: t('startDateDueDateError'),
							helperText: t('startDateDueDateError'),
							type: 'Date',
						});
					} else {
						if (validateAddContributorForm(krContributorDetailDashboard)) {
							setKRAttrDateError(defaultKRDateError);
							onSaveContributorDetailClick && onSaveContributorDetailClick(event, krContributorDetailDashboard);
						}
					}
					props.updateCalloutPanel && props.updateCalloutPanel('');
					setTimeout(() => {
						props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
					}, 500);
				}}
				handleCancelClick={(e: any) => {
					checkAssignUnsavedData(e);
				}}
				hideDefaultButton={false}
				isSaveButtonDisabled={false}
				saveButtonText={'Assign'}
				showBackButton={!hideBackButton}
				backButton={
					<Button
						className='btn-link'
						onClick={() => {
							handleBack(0);
						}}
					>
						{<KeyboardBackspaceIcon />}
						{t('back')}
					</Button>
				}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				// hideCloseIcon={true}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
			{subModalProps && (
				<AlertDialog
					module='myGoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseSubModal}
					modalOpen={subModalProps}
				/>
			)}
		</Fragment>
	);
};
