import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Avatar, Box, ClickAwayListener, Link, Tooltip, Typography } from '@material-ui/core';
import Select from 'react-select';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import '../../../styles/pages/weekly-checkins/weeklyCheckins.scss';
import GlobalSearch from '../../Common/GlobalSearch';
import { MembersList } from './MembersList';
import { QuesAns } from './QuesAns';
import {
	getUserName,
	objDiff,
	GAevent,
	isTrialVersion,
	getCurrentUserDetails,
	getUserDetails,
	formatFeedbackDate,
	formatOneOnOneRequestDate,
	checkUnSavePopUpVisible,
} from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { WeekRangeTabPanel, WeekRangeTabs } from './WeekRangeTabsScrollar';
import { useCallback } from 'react';
import AlertDialog from '../../Common/Dialog';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { OKRButton } from '../../Common/OKRButton';
import { LeftArrow, RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { CrossIcon, SettingIcon } from '../../../config/svg/CommonSvgIcon';
import { getKrListing } from '../../../action/myGoal';
import { getLocalStorageItem } from '../../../services/StorageService';
import { AlertInfoIcon, TeamIcon } from '../../../config/svg/CommonSvg';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { CheckInVisibilitySettings } from './CheckInVisibilitySettings';
import { CheckInVisibilitySettingsDrawer } from './CheckInVisibilitySettingsDrawer';
import { fetchGlobalTaskPending, getAllGlobalTask, getRecentAssignee } from '../../../action/globalTask';
import { Visibility } from '../../../config/svg/ProfileSvg';

export const WeeklyCheckIns: React.FC<any> = (props) => {
	const {
		// userDetail,
		checkInResult,
		// showApiMsgs,
		checkinMasterData,
		getCheckinMasterData,
		showLoader,
		getUserDirectEmployees,
		getCheckInData,
		getCheckInWeeklyDates,
		updateCheckInData,
		isCheckinSubmitted,
		updateCheckInVisibilityData,
	} = props;

	const currentUser: any = getCurrentUserDetails();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const userDetail: any = getUserDetails();

	const [tabSelected, setTabSelected] = React.useState<Number>(0);
	const [checkInDates, setCheckInDates] = React.useState<any>([]);
	const [checkInFutureDates, setCheckInFutureDates] = React.useState<any>([]);
	const [defaultCheckInData, setDefaultCheckInData] = React.useState<any>([]);
	const [checkInData, setCheckInData] = useState<any>([]);
	const [checkInDataCopy, setCheckInDataCopy] = useState<any>([]);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [isCheckinPermission, setIsCheckinPermission] = useState<boolean>(false);
	const [isOldVersion, setIsOldVersion] = useState<boolean>(false);
	// const [createTaskValue, setCreateTaskValue] = useState<string>('');
	// const [linkedKr, setLinkedKr] = useState<any>({});
	const [allTasks, setAllTasks] = useState<any>([]);
	const [open, setOpen] = React.useState(false);
	const [activeClass, setActiveClass] = React.useState(false);
	const [openSettingDrawer, setOpenSettingDrawer] = useState<any>(false);
	const [checkinNewChip, setCheckinNewChip] = React.useState<any>(null);

	const showApiMsgs = (msg: any, variant: any) => {
		// enqueueSnackbar(msg, {
		// 	variant: variant,
		// 	autoHideDuration: 2000,
		// });
	};

	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	useEffect(() => {
		if (!open && activeClass) {
			setTimeout(() => {
				setActiveClass(false);
			}, 500);
		}
	}, [activeClass]);
	const handleClickAway = () => {
		setOpen(false);
	};

	const handleTabChange = (event: any, newValue: Number) => {
		if (newValue !== tabSelected) {
			setCheckInData([]);
		}
		setTabSelected(newValue);
		setIsEdit(false);
		isCheckinSubmitted();
	};

	const [selectedUser, setSelectedUser] = useState<any>({});
	const [currentDate, setCurrentDate] = useState<any>({});
	const [selectedDate, setSelectedDate] = useState<any>({});
	const [directReportees, setDirectReportees] = useState<any>({ directReports: [], otherReports: [] });
	const [directReporteesLoaded, setDirectReporteesLoaded] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [viewToShow, setViewToShow] = useState<string>('');
	const [isDataSaving, setIsDataSaving] = useState<boolean>(false);
	const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
	const [checkinVisibility, setCheckinVisibility] = useState<any>(0);
	const [checkinVisibilityList, setCheckinVisibilityList] = useState<any>([]);
	const [totalWeeksCount, setTotalWeeksCount] = useState<number>(0);
	const [upcomingDateDetails, setUpcomingDateDetails] = useState<any>(null);
	//const [openKrList, setOpenKrList] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { krList, krListDataStatus } = useSelector((state: any) => state.okrReducer);

	useEffect(() => {
		setSelectedUser(userDetail);
		if (!checkinMasterData.length) {
			getCheckinMasterData();
		}
		isCheckinSubmitted();
	}, []);

	useEffect(() => {
		if (checkinMasterData.length && checkInResult) {
			let checkinData = JSON.parse(JSON.stringify(checkinMasterData));
			let data = checkinData.map((item: any) => {
				return {
					...item,
					value: item.checkInVisibilityId,
					label: item.checkInVisibilityName,
				};
			});
			let selectedCheckin = data.filter((item: any) => item.checkInVisibilityId === checkInResult?.checkInVisibilty);
			setCheckinVisibilityList(data);
			setCheckinVisibility(selectedCheckin);
		}
	}, [checkinMasterData, checkInResult]);

	useEffect(() => {
		if (selectedUser.employeeId) {
			getCheckInDates();
			if (!directReportees.directReports.length && !directReportees.otherReports.length) {
				fetchDirectReportees();
			}
		}
	}, [selectedUser]);

	useEffect(() => {
		if (selectedUser && selectedUser?.employeeId) {
			let data = `empId=${selectedUser.employeeId}`;
			dispatch(getKrListing(data));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.dashboardCurrentTab, selectedUser]);

	useEffect(() => {
		if (selectedDate?.startDate) {
			if (!isCheckinPermission) {
				setCheckInData([]);
				setTimeout(() => {
					if (krListDataStatus === 'success') {
						if (!modalOpen) {
							setModalOpen(true);
						}
					}
					showLoader(false);
				}, 1500);
				// showLoader(false);
			} else if (selectedDate.checkInStatus === Enums.CHECKED_IN_MISSED && checkIfNotLoggedInUser()) {
				setViewToShow('missed');
				showLoader(false);
			} else if (
				selectedDate.checkInStatus === Enums.NOT_YET_CHECKED_IN &&
				checkIfNotLoggedInUser() &&
				selectedDate.checkInWeekType === 0
			) {
				setViewToShow('noYetChecked');
				showLoader(false);
			} else if (
				selectedDate.checkInStatus === Enums.NOT_YET_CHECKED_IN &&
				checkIfNotLoggedInUser() &&
				selectedDate.checkInWeekType === 1
			) {
				setViewToShow('noYetCheckedPlanning');
				showLoader(false);
			} else if (selectedDate.checkInStatus === Enums.CHECKED_NO_PERMISSION && checkIfNotLoggedInUser()) {
				setViewToShow('noPermission');
				showLoader(false);
			} else {
				if (krListDataStatus === 'success' || krListDataStatus === 'failed') {
					showLoader(true);
					setViewToShow('');
					fetchCheckInData();
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, krListDataStatus, krList]);

	const handleSelectedReportee = (reporteeData: any) => {
		let directReporteesData = reporteeData.directReports.map((item: any) => {
			if (item.employeeId === selectedUser.employeeId && selectedUser.employeeId !== userDetail.employeeId) {
				item.isSelected = true;
			} else {
				item.isSelected = false;
			}
			return item;
		});
		return { directReports: directReporteesData, otherReports: reporteeData.otherReports };
	};

	const fetchDirectReportees = async () => {
		setIsDataLoaded(false);
		setCheckInData([]);
		let directEmployeeRes = await getUserDirectEmployees(userDetail.employeeId);
		if (directEmployeeRes.data.status === Enums.STATUS_SUCCESS) {
			setIsDataLoaded(() => true);
			setDirectReporteesLoaded(true);
			if (directEmployeeRes.data.entity.directReports.length || directEmployeeRes.data.entity.otherReports.length) {
				setDirectReportees(handleSelectedReportee(directEmployeeRes.data.entity));
			}
		}
	};

	const fetchCheckInData = async () => {
		let data = `empId=${selectedUser.employeeId}&startDate=${selectedDate.startDate}&endDate=${selectedDate.endDate}`;
		let checkInDataResponse = await getCheckInData(data);
		setIsDataLoaded(() => false);
		setCheckInData([]);

		if (checkInDataResponse.data.status === Enums.STATUS_SUCCESS) {
			setIsOldVersion(checkInDataResponse.data.entity.isOldVersion);
			let data = checkInDataResponse.data.entity.checkInDetailsResponse;
			let updateResponse = data.map((item: any) => {
				return {
					...item,
					historyText: item.checkInDetails,
					employeeId: selectedUser.employeeId,
				};
			});
			setDefaultCheckInData(data);
			setCheckInData(updateResponse);
			setCheckInDataCopy(JSON.parse(JSON.stringify(updateResponse)));

			setIsDataLoaded(true);
			let addHistoryKey =
				checkInDataResponse.data.entity.taskResponse.length > 0
					? checkInDataResponse.data.entity.taskResponse.map((item: any) => {
							return {
								...item,
								history: item.taskName,
							};
					  })
					: [];
			setAllTasks(linkTaskWithKr(krList, addHistoryKey));
			if (selectedDate.checkInStatus !== Enums.STATUS_CHECKED_ID) {
				setIsDataSaving(false);
			}

			getAllTaskForSelectedCheckIn(selectedUser.employeeId, selectedDate.startDate, selectedDate.endDate);
			showLoader(false);
		}
	};

	const fetchCheckInTaskData = async () => {
		let data = `empId=${selectedUser.employeeId}&startDate=${selectedDate.startDate}&endDate=${selectedDate.endDate}`;
		let checkInDataResponse = await getCheckInData(data);
		if (checkInDataResponse.data.status === Enums.STATUS_SUCCESS) {
			let addHistoryKey =
				checkInDataResponse.data.entity.taskResponse.length > 0
					? checkInDataResponse.data.entity.taskResponse.map((item: any) => {
							return {
								...item,
								history: item.taskName,
							};
					  })
					: [];
			setAllTasks(linkTaskWithKr(krList, addHistoryKey));
		}
	};

	const linkTaskWithKr = (krListing: any, allTasksList: any) => {
		if (allTasksList.length && krListing.length) {
			let linkTaskWithKrList = allTasksList.map((item: any) => {
				let task: any = item;
				krListing.map((kr: any) => {
					if (item.goalId !== 0 && item.goalId === kr.goalKeyId) {
						task.krLinked = kr;
					}
					return item;
				});
				return task;
			});
			return linkTaskWithKrList;
		} else {
			return allTasksList;
		}
	};

	const getCheckInDates = async () => {
		let empId = `empId=${selectedUser.employeeId}`;
		showLoader(true);
		let response = await getCheckInWeeklyDates(empId);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			const { entity } = response?.data || {};
			const { checkInWeeklyDatesResponse, upcomingWeeklyDatesResponse } = entity || {};
			if (checkInWeeklyDatesResponse && checkInWeeklyDatesResponse.length) {
				let reverseDates: any = checkInWeeklyDatesResponse.reverse();
				setIsCheckinPermission(response.data.entity.isCheckinDataVisible);
				let getLastDate;
				setTotalWeeksCount(checkInWeeklyDatesResponse.length);
				if (isFirstLoad) {
					getLastDate = canCheckInEdit() ? reverseDates.slice(-2)[0] : reverseDates.slice(-3)[0];
					setTabSelected(canCheckInEdit() ? reverseDates.length - 2 : reverseDates.length - 3);
					setIsFirstLoad(false);
					setSelectedDate(getLastDate);
					setCurrentDate(getLastDate);
				} else {
					getLastDate = reverseDates.slice(tabSelected)[0];
					setSelectedDate(getLastDate);
					setCurrentDate(getLastDate);
				}
				showLoader(false);
				setCheckInDates(reverseDates);
			}
			if (upcomingWeeklyDatesResponse && upcomingWeeklyDatesResponse?.length) {
				setCheckInFutureDates(upcomingWeeklyDatesResponse);
			} else {
				setCheckInFutureDates([]);
			}
		}
	};

	const getCheckInDatesFromTask = async () => {
		let empId = `empId=${selectedUser.employeeId}`;
		showLoader(true);
		let response = await getCheckInWeeklyDates(empId);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			const { entity } = response?.data || {};
			const { checkInWeeklyDatesResponse, upcomingWeeklyDatesResponse } = entity || {};
			if (checkInWeeklyDatesResponse && checkInWeeklyDatesResponse.length) {
				let reverseDates: any = checkInWeeklyDatesResponse.reverse();
				showLoader(false);
				setCheckInDates(reverseDates);
			}

			if (upcomingWeeklyDatesResponse && upcomingWeeklyDatesResponse?.length) {
				setCheckInFutureDates(upcomingWeeklyDatesResponse);
			} else {
				setCheckInFutureDates([]);
			}
		}
	};

	const handleSelectDate = (e: React.ChangeEvent<HTMLInputElement>, checkInDate: any) => {
		e.preventDefault();
		setSelectedDate(checkInDate);
		setCheckinNewChip(null);
	};

	const handleQuestionChange = (e: any, checkInQuestion: any, index: number) => {
		let checkInDataClone = [...checkInData];
		checkInDataClone[index].checkInDetails = e;
		// checkInDataClone[index].isEdited = true;
		setCheckInData(checkInDataClone);
	};

	const isArrayEqual = (x: any, y: any) => {
		const isSameSize = _.size(x) === _.size(y);
		return isSameSize && _(x).xorWith(y, _.isEqual).isEmpty();
	};

	const handleCheckInUpdate = async (checkinRow?: any) => {
		setIsDataSaving(true);
		if (checkinRow) {
			showLoader(true);
			let submitCheckIn = await updateCheckInData([{ ...checkinRow, checkInWeek: selectedDate.displayDate }]);
			showLoader(false);
			if (submitCheckIn.data.status === Enums.STATUS_SUCCESS) {
				setIsDataSaving(false);
				handleAPImessages(submitCheckIn.data.messageList, 'success');
				isCheckinSubmitted();
				getCheckInDates();
			}
			isTrialVersion() && GAevent('SubmitCheck-ins', 'Clicked Submit in Check-ins', 'Submit');
			// let submitCheckIn = await props.updateCheckInData(setCheckInDataCopy);
		} else {
			let setCheckInDataCopy = [...checkInData];
			let findBlankField = setCheckInDataCopy.filter(
				(item: any) =>
					!item.checkInDetails ||
					item.checkInDetails?.trim() === '' ||
					item.checkInDetails?.trim() === '<p></p>' ||
					item.checkInDetails?.trim() === '<p><br></p>'
			).length;

			setCheckInDataCopy = setCheckInDataCopy.map((item: any) => {
				return {
					...item,
					checkInDetails: item.checkInDetails === null ? '' : item.checkInDetails,
					checkInDate: selectedDate.startDate,
					checkInWeek: selectedDate.displayDate,
				};
			});

			if (findBlankField === checkInData.length) {
				// showApiMsgs(t('leastFieldError'), 'error');
				setIsDataSaving(false);
			} else {
				showLoader(true);
				let submitCheckIn = await updateCheckInData(setCheckInDataCopy);
				showLoader(false);
				if (submitCheckIn.data.status === Enums.STATUS_SUCCESS) {
					setIsDataSaving(false);
					handleAPImessages(submitCheckIn.data.messageList, 'success');
					isCheckinSubmitted();
					getCheckInDates();
				}
			}
		}
	};

	const handleAPImessages = (messageList: any, variant: any) => {
		const responseAPI = messageList;
		const keys = Object.keys(responseAPI);
		const messages = keys.map((item) => responseAPI[item]);
		showApiMsgs(`${messages} `, variant);
	};

	const checkIfNotLoggedInUser = () => {
		if (selectedUser.employeeId !== userDetail.employeeId) {
			return true;
		}
		return false;
	};

	const onSelectedUser = (user: any, isDirectReportee?: boolean) => {
		setCheckinNewChip(null);
		if (isDirectReportee) {
			setSelectedUser(user);
			window.scroll({ top: 0, behavior: 'smooth' });
			setIsFirstLoad(true);
		} else {
			setSelectedUser(user[0]);
			setIsFirstLoad(true);
		}
		setIsEdit(false);
		setOpen(false);
		setActiveClass(true);
	};

	const handleGoBack = () => {
		setIsDataLoaded(false);
		setSelectedUser(userDetail);
		setIsFirstLoad(true);

		setUpcomingDateDetails(null);
		setCheckinNewChip(null);
	};

	const canCheckInEdit = () => {
		if (selectedUser.employeeId === userDetail.employeeId) {
			return true;
		}
		return false;
	};

	const showQuestionHeading = () => {
		if (currentDate.startDate === selectedDate.startDate) {
			return true;
		}
		return false;
	};

	const editCheckIn = useCallback(() => {
		setIsDataSaving(false);
		setIsEdit((prev) => !prev);
	}, [isEdit]);

	const handleCheckInChange = async (selected: any) => {
		setCheckinVisibility(selected);
		let data = `checkInVisibilty=${selected.value}`;
		let resp = await updateCheckInVisibilityData(data);
		if (resp.data.status === Enums.STATUS_SUCCESS) {
			showApiMsgs(t('settingChangedSuccessfully'), 'success');
		} else {
			handleAPImessages(resp.data.messageList, 'error');
		}
	};

	const handleCloseModal = () => {
		setIsCheckinPermission(true);
		handleGoBack();
		setModalOpen(false);
	};

	// const handleTaskChange = (e: any) => {
	// 	const { value } = e.target;
	// 	setCreateTaskValue(value);
	// 	if (value.includes(' /')) {
	// 		setOpenKrList(true);
	// 	}
	// };

	// const handleKrListClickAway = () => {
	// 	setOpenKrList(false);
	// };

	// const handleKRLink = (e: any, kr: any) => {
	// 	setLinkedKr(kr);
	// 	let taskText = createTaskValue.split('/')[0];
	// 	setOpenKrList(false);
	// 	setCreateTaskValue(taskText);
	// };

	// useEffect(() => {
	// 	if (createTaskValue && openKrList && Boolean(linkedKr)) {
	// 		let searchKrVal = createTaskValue.split('/')[1];
	// 		let filterList = krList.filter((kr: any) => {
	// 			if (kr.krTitle.toLowerCase().includes(searchKrVal.toLowerCase())) {
	// 				return kr;
	// 			}
	// 		});
	// 		setKrList(filterList);
	// 	}
	// }, [createTaskValue]);

	const handleOpenSettingDrawer = (event: any) => {
		setOpenSettingDrawer(true);
	};

	const handleSettingDrawerAction = (event: any, type: string) => {
		setOpenSettingDrawer(false);
	};
	const getCheckInWeekDate = async (dateRange: any, start: any) => {
		setTabSelected(-1);
		const upcomingDateValue = formatFeedbackDate(new Date(start));
		let data = `empId=${selectedUser.employeeId}&upcomingDate=${upcomingDateValue}`;
		let checkInDataResponse = await getCheckInData(data);
		if (checkInDataResponse.data.status === Enums.STATUS_SUCCESS) {
			let upcomingDate: any = checkInDataResponse.data.entity.upcomingWeeklyDatesResponse;
			upcomingDate.endDate = formatFeedbackDate(new Date(upcomingDate.endDate));
			upcomingDate.startDate = formatFeedbackDate(new Date(upcomingDate.startDate));
			const displayDateIndex = -1;
			// checkInDates && checkInDates?.length
			// 	? checkInDates?.findIndex((rec: any) => rec?.displayDate === upcomingDate?.displayDate)
			// 	: -1;
			// if (upcomingDate?.checkInWeekType === Enums.ONE && displayDateIndex === -1) {
			if (displayDateIndex === -1) {
				setCheckinNewChip({ ...upcomingDate });
				setSelectedDate({ ...upcomingDate });
				setUpcomingDateDetails(start);
			} else {
				setCheckinNewChip(null);
				setSelectedDate({ ...upcomingDate });
				setUpcomingDateDetails(start);
				// const displayDateIndex =
				// 	checkInDates && checkInDates?.length
				// 		? checkInDates?.findIndex((rec: any) => rec?.displayDate === upcomingDate?.displayDate)
				// 		: -1;
				handleTabChange({}, displayDateIndex);
			}
		} else {
		}
	};
	// ********************************************************************
	// ********************************************************************
	const defaultTaskListRequest = {
		isMyTask: true,
		isCompleted: false,
		pageSize: Enums.MAX_USER_SIZE,
		pageIndex: Enums.ONE,
		order: '',
		sortingText: '',
		finder: [],
		taskFilter: {
			dueDate: [],
			taskType: [],
			priority: [],
		},
		startDate: null,
		endDate: null,
		taskGroupById: Enums.ZERO,
		taskGroupByType: Enums.ZERO,
		moduleId: Enums.TWO,
		employeeId: Enums.ZERO,
		moduleItemKeyId: Enums.ZERO,
		krParentId: Enums.ZERO,
	};
	const myRef = useRef<any>(null);
	const { isCreateLinkPopupEnable, createLinkPopupDetail } = useSelector((state: any) => state.globalTaskReducer);
	const [globalTaskRequest, setGlobalTaskRequest] = useState<any>(defaultTaskListRequest);
	const [defaultRecentUserList, setDefaultRecentUserList] = useState<any>([]);
	const [isLaunchFormEdited, setIsLaunchFormEdited] = useState<any>(false);
	const [isCreateLinkPopupOpen, setIsCreateLinkPopupOpen] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	const [showFutureWeekMessage, setShowFutureWeekMessage] = useState<string>('');

	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// 	// getAllTask(defaultTaskListRequest);
	// 	// if (userDetail && userDetail?.employeeId) {
	// 	// 	const data = `empId=${userDetail.employeeId}`;
	// 	// 	dispatch(getKrListing(data));
	// 	// }
	// 	// getRecentSearchesList();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	useEffect(() => {
		document.addEventListener('click', handleCheckUnSaveData, true);
		return () => {
			document.removeEventListener('click', handleCheckUnSaveData, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLaunchFormEdited, isCreateLinkPopupOpen]);

	const handleCheckUnSaveData = (event: any) => {
		// if (ref3?.current?.contains(event?.target)) {
		// 	//
		// } else {
		const id = createLinkPopupDetail ? document.getElementById(createLinkPopupDetail) : null;
		const taskLinkTextPopup = document.getElementById('taskLinkTextPopup');
		if (
			myRef.current &&
			!myRef.current?.contains(event.target) &&
			checkUnSavePopUpVisible(event) &&
			isLaunchFormEdited &&
			!id?.contains(event.target) &&
			!taskLinkTextPopup?.contains(event.target)
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({
					open: true,
					type: 'unSaveChange',
					message: t('unSavedItemAlert'),
					details: null,
					event: event,
				});
			}
		} else {
		}
		// }
	};
	const handleTaskUnSaveCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChange') {
				await setIsLaunchFormEdited(false);
				const { target } = modalProps?.event || {};
				if (target) {
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
				// resetTabDetails(false);
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};

	const getAllTaskForSelectedCheckIn = (empId: any, startDate: any, endDate: any) => {
		try {
			const data = {
				...defaultTaskListRequest,
				employeeId: empId || Enums.ZERO,
				startDate: startDate ? startDate : null,
				endDate: endDate ? endDate : null,
			};
			dispatch(fetchGlobalTaskPending());
			setGlobalTaskRequest(data);
			getAllTask(data);
			getRecentSearchesList();
		} catch (error) {
			console.error(error);
		}
	};

	const getAllTask = (data: any) => {
		let requestPayload = { ...data };
		// requestPayload = {
		// 	...requestPayload,
		// 	employeeId: userDetail?.employeeId || Enums.ZERO,
		// 	startDate: null,
		// 	endDate: null,
		// };
		setGlobalTaskRequest(requestPayload);
		dispatch(getAllGlobalTask(requestPayload, false));
	};

	const getRecentSearchesList = () => {
		try {
			const data = `goalKeyId=${Enums.ZERO}`;
			getRecentAssigneeList(data);
		} catch (error) {
			console.error(error);
		}
	};
	const getRecentAssigneeList = async (requestParam: any) => {
		try {
			const response: any = await dispatch(getRecentAssignee(requestParam));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				setDefaultRecentUserList(entityList && entityList.length ? entityList : []);
			} else {
				setDefaultRecentUserList([]);
			}
		} catch (error) {
			console.error(error);
			setDefaultRecentUserList([]);
		}
	};
	const handleSaveCallBack = (message: string) => {
		getCheckInDatesFromTask();
		isCheckinSubmitted();
		message && setShowFutureWeekMessage(message);
		upcomingDateDetails && getCheckInWeekDate({}, upcomingDateDetails);
		setTimeout(() => {
			setUpcomingDateDetails(null);
		}, 2000);
	};
	// ********************************************************************
	// ********************************************************************

	return (
		<>
			<Box className='weekly-check-ins-wrap'>
				<Box className='check-ins-head'>
					<Box className='check-ins-head-left'>
						<Typography variant='h2'>{t('weeklyCheckInsHead')}</Typography>
						<Box className={`check-ins-info ${checkInResult?.isAlert ? 'alert-active' : ''}`}>
							{checkInResult?.isAlert ? <AlertInfoIcon /> : ''}
							<Typography variant='h6' className={checkInResult?.isAlert ? 'red' : ''}>
								{checkInResult.remainingDays}
							</Typography>
						</Box>
					</Box>
					{checkInResult?.isChangeCheckInVisibilty && checkinVisibility ? (
						<Box className='check-ins-head-right'>
							<OKRButton
								icon={<Visibility />}
								title={t('checkInsVisibilitySettings')}
								className={'btn-link-type3'}
								handleClick={handleOpenSettingDrawer}
								text={t('visibilitySettings')}
							/>
						</Box>
					) : (
						''
					)}
				</Box>
				{/* <Box>
					<TextField value={createTaskValue} onChange={handleTaskChange} />
					{linkedKr && linkedKr?.krTitle}
					{openKrList && (
						<ClickAwayListener onClickAway={handleKrListClickAway}>
							<List>
								{krList.map((item: any, index: number) => (
									<ListItem key={index} onClick={(e) => handleKRLink(e, item)}>
										{item.krTitle}
									</ListItem>
								))}
							</List>
						</ClickAwayListener>
					)}
				</Box> */}
				<Box className='check-ins-content'>
					<Box className='col-one'>
						<Box className='check-ins-content-head'>
							{checkIfNotLoggedInUser() && (
								<Box className='back-to-main'>
									<Typography variant='body2'>
										<Link onClick={handleGoBack} className='back-to-main-link'>
											<BackArrow /> {t('back')}
										</Link>
										{t('watchingCheckins', { name: `${selectedUser.firstName} ${selectedUser.lastName}` })}
									</Typography>
								</Box>
							)}
							<Box className='user-info'>
								<Box className='user-img'>
									{selectedUser && selectedUser.imagePath && selectedUser.imagePath.trim() !== '' ? (
										<Avatar className='user-avatar' key={`user-avtar`} src={selectedUser.imagePath} />
									) : selectedUser && selectedUser.firstName ? (
										<Avatar className='user-avatar' key={`user-avtar`}>
											{getUserName(selectedUser)}
										</Avatar>
									) : (
										<Avatar className='user-avatar' key={`user-avtar`}></Avatar>
									)}
								</Box>
								<Box className='user-info-details'>
									{(selectedUser?.firstName + selectedUser?.lastName).length > 20 ? (
										<Tooltip title={selectedUser?.firstName + ' ' + selectedUser?.lastName} arrow>
											<Typography variant='h5'>
												{(selectedUser?.firstName + ' ' + selectedUser?.lastName).substring(0, 20) + '...'}
												...
											</Typography>
										</Tooltip>
									) : (
										<Typography variant='h5'>
											{selectedUser.firstName} {selectedUser.lastName}
										</Typography>
									)}
									<Typography variant='subtitle2'>{selectedUser.designation}</Typography>
								</Box>
							</Box>
							{checkInDates && checkInDates.length > 0 && (
								<WeekRangeTabs
									{...props}
									tabSelected={tabSelected}
									handleTabChange={handleTabChange}
									checkInDates={checkInDates}
									handleSelectDate={handleSelectDate}
									selectedUser={selectedUser}
									showApiMsgs={showApiMsgs}
									userDetail={userDetail}
									totalWeeksCount={totalWeeksCount}
									getCheckInWeekDate={getCheckInWeekDate}
									checkinNewChip={checkinNewChip}
									setCheckinNewChip={setCheckinNewChip}
									showFutureWeekMessage={showFutureWeekMessage || ''}
									checkInFutureDates={checkInFutureDates}
								/>
							)}
						</Box>
						<WeekRangeTabPanel>
							{isDataLoaded ? (
								<QuesAns
									checkInData={checkInData}
									handleQuestionChange={handleQuestionChange}
									handleCheckInUpdate={handleCheckInUpdate}
									currentDate={currentDate}
									viewToShow={viewToShow}
									canCheckInEdit={canCheckInEdit}
									showQuestionHeading={showQuestionHeading}
									editCheckIn={editCheckIn}
									isEdit={isEdit}
									selectedUser={selectedUser}
									isDataSaving={isDataSaving}
									selectedDate={selectedDate}
									setIsDataSaving={setIsDataSaving}
									defaultCheckInData={defaultCheckInData}
									allTasks={allTasks}
									setAllTasks={setAllTasks}
									fetchCheckInData={fetchCheckInData}
									handleAPImessages={handleAPImessages}
									checkInDataCopy={checkInDataCopy}
									showApiMsgs={showApiMsgs}
									userDetail={userDetail}
									isOldVersion={isOldVersion}
									setCheckInDataCopy={setCheckInDataCopy}
									getCheckInDates={getCheckInDates}
									fetchCheckInTaskData={fetchCheckInTaskData}
									getCheckInDatesFromTask={getCheckInDatesFromTask}
									{...props}
									defaultTaskListRequest={defaultTaskListRequest}
									globalTaskRequest={globalTaskRequest}
									setGlobalTaskRequest={setGlobalTaskRequest}
									defaultRecentUserList={defaultRecentUserList}
									setDefaultRecentUserList={setDefaultRecentUserList}
									getAllTask={getAllTask}
									getRecentSearchesList={getRecentSearchesList}
									krList={krList}
									setLoader={showLoader}
									isLaunchFormEdited={isLaunchFormEdited}
									setIsLaunchFormEdited={setIsLaunchFormEdited}
									setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
									myRef={myRef}
									handleSaveCallBack={handleSaveCallBack}
									checkIfNotLoggedInUser={checkIfNotLoggedInUser}
								/>
							) : (
								<Box className='loading-center'>
									<Typography>{t('loading')}</Typography>
								</Box>
							)}
						</WeekRangeTabPanel>
					</Box>
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box className={`check-ins-right-sidebar`}>
							{!open && (
								<OKRButton
									icon={open ? <CrossIcon /> : <TeamIcon />}
									className={`action-btn  ${open ? 'cancel-icon' : 'right-icon'} `}
									handleClick={handleClick}
									title={t('checkInsArrow')}
									tooltipPlacement={'right'}
									//tooltipOpen={`${open ? 'false' : ''}`}
									//tooltipClassName={`${open ? '' : 'tooltip-layout2'}`}
								/>
							)}
							{open && <OKRButton icon={<RightArrowIcon />} className={`drawer-close-btn`} handleClick={handleClick} />}
							<Box
								className={`check-ins-direct-reportees ${open ? 'active' : ''} ${activeClass ? 'redirect-active' : ''}`}
							>
								<Typography variant='h4' className='checkins-head'>
									{t('checkInsArrow')}
								</Typography>
								<Box className='member-list-wrap'>
									<GlobalSearch
										{...props}
										module={'myGoal'}
										fetchCycleUsers={true}
										customClass={'user-quick-search-type2'}
										placeHolderText={t('searchPeopleText')}
										onSelectedUser={onSelectedUser}
										customId={'weekly-checkins-src'}
										showApiMsgs={showApiMsgs}
										userDetail={userDetail}
									/>
									<MembersList
										{...props}
										directReporteesLoaded={directReporteesLoaded}
										directReportees={directReportees}
										getUserName={getUserName}
										handleUserClick={onSelectedUser}
										showApiMsgs={showApiMsgs}
										userDetail={userDetail}
									/>
								</Box>
							</Box>
						</Box>
					</ClickAwayListener>
				</Box>
			</Box>

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleTaskUnSaveCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
			{modalOpen && (
				<AlertDialog
					isCancel={true}
					message={t('checkinRMOnly')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
			{openSettingDrawer && (
				<CheckInVisibilitySettingsDrawer
					{...props}
					openSettingDrawer={openSettingDrawer}
					setOpenSettingDrawer={setOpenSettingDrawer}
				/>
			)}
		</>
	);
};
