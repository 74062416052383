import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';
import assessmentNodata from '../../images/assessment-no-data-img.svg';
import { getUserDetails } from '../../config/utils';

export const NoRecordPerformance: React.FC<any> = (props: any) => {
	const { message, handleOneOnOneClick = () => {} } = props;
	const { t } = useTranslation();
	const user = getUserDetails();

	return (
		<>
			<Box className='assessment-no-record'>
				<Box className='no-record-message'>
					<Typography variant='h2'>{t('assessmentNoData1')}</Typography>
					<Typography>{t('assessmentNoData2')}</Typography>
					<Box className='request1on1-messg'>
						<OKRButton
							icon={<RequestOneOnOneIcon2 />}
							text={t('requestOnetoOneText')}
							handleClick={(e) => {
								handleOneOnOneClick(e, 'MyAssessmentList', user?.reportingTo || 0);
							}}
						/>
						<Typography>{t('assessmentNoDataRequest1on1')}</Typography>
					</Box>

					<img width='485' alt={t('assessmentNoData1')} src={assessmentNodata} />
				</Box>
			</Box>
		
		</>
	);
};
