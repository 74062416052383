import React from 'react';
import { Box, ClickAwayListener, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { DownArrowIcon, UpArrowIcon } from '../../../config/svg/ArrowSvg';
// import { SortingButtonIcon } from '../../../config/svg/CommonSvgIcon';
import { CreateOKRIcon, KeyResultBlackIcon } from '../../../config/svg/MyGoalSvg';
import { Enums } from '../../../config/enums';

export const SnapshotDropdown: React.FC<any> = (props: any) => {
	const { handleStatusTypeSelection, selectedStatusType = Enums.ONE } = props;
	const { t } = useTranslation();
	const [openDropdown, setOpenDropdown] = React.useState<Boolean>(false);

	const handleClick = () => {
		setOpenDropdown((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpenDropdown(false);
	};

	return (
		<Box className={`okr-team-dropdown  dropdown-multilist-item ${openDropdown ? 'active' : ''} `}>
			<OKRButton
				className={`dropdown-button ${openDropdown ? 'active' : ''}`}
				text={
					<span className='filter-txt'>
						{selectedStatusType === Enums.ONE ? <CreateOKRIcon /> : <KeyResultBlackIcon />}
						{openDropdown ? <UpArrowIcon /> : <DownArrowIcon />}
					</span>
				}
				handleClick={handleClick}
				id='task-sorting-btn'
			/>
			{openDropdown && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<List component='div' disablePadding>
								<ListItem
									component='div'
									className={selectedStatusType === Enums.ONE ? 'active' : ''}
									onClick={(e) => {
										handleClickAway();
										handleStatusTypeSelection(e, Enums.ONE);
									}}
								>
									<ListItemIcon>
										<CreateOKRIcon />
									</ListItemIcon>
									<ListItemText primary={t('objectives')} />
								</ListItem>
								<ListItem
									component='div'
									className={selectedStatusType === Enums.TWO ? 'active' : ''}
									onClick={(e) => {
										handleClickAway();
										handleStatusTypeSelection(e, Enums.TWO);
									}}
								>
									<ListItemIcon>
										<KeyResultBlackIcon />
									</ListItemIcon>
									<ListItemText primary={t('keyResults')} />
								</ListItem>
							</List>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
