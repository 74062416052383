import {
	signInAPI,
	signInDbAPI,
	logoutAPI,
	azureSignInAPI,
	tenantAPI,
	orgCurrentCycleAPI,
	tenantAPIOKR,
	impersonateUserAPI,
	createPasswordAPI,
	forgetPasswordAPI,
} from '../services/SigninService';
import * as actionTypes from './actionTypes';
import { showLoader } from './common';
import { useSnackbar } from 'notistack';

const signInPending = () => ({
	type: actionTypes.SIGIN_PENDING,
});

const signInCompleted = (payload) => ({
	type: actionTypes.SIGIN_SUCCESS,
	payload,
});

const signInFailed = (error) => ({
	type: actionTypes.SIGIN_FAILED,
	error,
});
const logoutSuccess = () => ({
	type: actionTypes.LOGOUT,
});

export const logoutAction = () => {
	return (dispatch) => {
		return logoutAPI()
			.then(() => {
				dispatch(logoutSuccess());
			})
			.catch(() => {
				dispatch(logoutSuccess());
			});
	};
};

export const sigInAction = (data) => {
	return (dispatch) => {
		dispatch(signInPending());
		return signInAPI(data)
			.then((response) => {
				dispatch(signInCompleted(response.data));
			})
			.catch((error) => {
				dispatch(signInFailed(error));
			});
	};
};

export const azureSignInAction = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		return (
			azureSignInAPI(data)
				//return signInAPI(data)
				.then((response) => {
					dispatch(signInCompleted(response.data));
					dispatch(showLoader(false));
				})
				.catch((error) => {
					dispatch(signInFailed(error));
					dispatch(showLoader(false));
				})
		);
	};
};

export const userByTokenAction = (data) => {
	return () => {
		return azureSignInAPI(data);
	};
};

export const orgCurrentCycleAction = () => {
	return () => {
		return orgCurrentCycleAPI();
	};
};

export const tenantAction = (data) => {
	return () => {
		return tenantAPI(data);
	};
};

export const tenantActionOKR = (data) => {
	return () => {
		return tenantAPIOKR(data);
	};
};

export const signInActionDB = (data) => {
	return () => {
		return signInDbAPI(data);
	};
};

export const createPassword = (data) => {
	return () => {
		return createPasswordAPI(data);
	};
};

export const forgetPassword = (data) => {
	return () => {
		return forgetPasswordAPI(data);
	};
};

export const updateStatus = () => ({
	type: actionTypes.UPDATE_STATUS,
});

export const currentCycleStatus = (payload) => ({
	type: actionTypes.CURRENT_CYCLE_DATA,
	payload,
});

export const impersonateUser = (data) => {
	return () => {
		return impersonateUserAPI(data);
	};
};

export const azureSignInActionImpersonate = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		return (
			impersonateUserAPI(data)
				//return signInAPI(data)
				.then((response) => {
					dispatch(signInCompleted(response.data));
					dispatch(showLoader(false));
				})
				.catch((error) => {
					dispatch(signInFailed(error));
					dispatch(showLoader(false));
				})
		);
	};
};
