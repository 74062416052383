import React, { useEffect, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
	Box,
	Chip,
	FormControlLabel,
	List,
	ListItem,
	Switch,
	Typography,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import { SortingIcon, TickIcon } from '../../../../config/svg/CommonSvgIcon';
import { Enums } from '../../../../config/enums';
import {
	CreateAdminSOPerspective,
	deleteAdminSOPerspective,
	getAllPerspective,
	isPerspectiveVisible,
} from '../../../../action/strategicObjective';
import { OKRButton } from '../../../Common/OKRButton';
import { AddIconSvg, DeleteIcon, EditIcon } from '../../../../config/svg/CommonSvg';
import { CancelIcon } from '../../../../config/svg/Action';
import AlertDialog from '../../../Common/Dialog';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';

export const PerspectiveList: FC<any> = (props: any) => {
	const { t, loader, setLoader } = props;
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [perspectiveList, setPerspectiveList] = useState<any>(null);
	const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(false);
	// const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [showDialog, setShowDialog] = useState<any>({
		open: false,
		message: '',
		isCancel: true,
		tagData: null,
		type: '',
	});
	const [deleteModalProps, setDeleteModalProps] = useState<any>({ open: false, message: '', tagData: '', type: '' });

	useEffect(() => {
		const requestParam = {
			pageIndex: Enums.ZERO,
			pageSize: Enums.MAX_USER_SIZE,
			finder: [],
			sortingText: sortingColumn,
			order: sortOrder,
		};
		getAllPerspectiveList(requestParam);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAllPerspectiveList = async (requestParam: any) => {
		try {
			setLoader(true);

			const response: any = await dispatch(getAllPerspective(requestParam));

			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response.data;
				const { records } = entity || {};
				let list = records && records.length ? [...records] : [];

				setPerspectiveList(list);
			} else {
				setPerspectiveList([]);
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
		}
	};
	const handleStatusChangeClick = async (event: any, item: any) => {
		try {
			if (item && item?.perspectiveTagId) {
				const { checked } = event?.target || {};
				setLoader(true);
				const requestPayload = {
					perspectiveTagId: item.perspectiveTagId,
					isActive: checked || false,
				};
				const response: any = await dispatch(isPerspectiveVisible(requestPayload));

				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || `Dimension Tag status updated successfully.`, {
						variant: 'success',
						autoHideDuration: 3000,
					});

					const requestParam = {
						pageIndex: Enums.ZERO,
						pageSize: Enums.MAX_USER_SIZE,
						finder: [],
						sortingText: sortingColumn,
						order: sortOrder,
					};
					getAllPerspectiveList(requestParam);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					enqueueSnackbar(messages || `Error occurred while updating dimension.`, {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		const requestParam = {
			pageIndex: Enums.ZERO,
			pageSize: Enums.MAX_USER_SIZE,
			finder: [],
			sortingText: column,
			order: newSortOrder,
		};
		getAllPerspectiveList(requestParam);
		setSortingColumn(column);
	};
	const editSelectedTag = (e: any, item: any) => {
		e.preventDefault();
		let list = perspectiveList && perspectiveList.length ? [...perspectiveList] : [];
		list = [...list]?.map((rec: any) => {
			const data = { ...rec };
			if (data?.perspectiveTagId === item?.perspectiveTagId) {
				data.isEdit = true;
			} else {
				data.isEdit = false;
			}

			return data;
		});
		setPerspectiveList([...list]);
		setIsCreateDisabled(true);
	};
	const handleCloseModal = (e: any, type: number) => {
		if (type === 1) {
			if (showDialog && showDialog?.type === 'Delete') {
				callDeletePerspectiveTagAPI();
			} else if (showDialog && showDialog?.type === 'NotDelete') {
				callDeletePerspectiveTagAPI();
			}
		} else {
			if (showDialog && showDialog?.type === 'NotDelete') {
				// callDeletePerspectiveTagAPI();
				setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
			} else {
				setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
			}
		}
	};
	const handleCloseDeleteModal = (e: any, type: any) => {
		if (type === 1) {
			if (deleteModalProps?.type === 'PerspectiveDelete') {
				callDeletePerspectiveTagAPI();
				// setDeleteModalProps({ open: false, message: '', tagData: '', type: '' });
			}
		} else {
			setDeleteModalProps({ open: false, message: '', tagData: '', type: '' });
		}
	};
	const deleteSelectedTag = async (e: any, item: any) => {
		try {
			e.preventDefault();
			if (item && item?.isSoLinked) {
				setShowDialog({
					open: true,
					type: 'NotDelete',
					message:
						'This Dimension will be visible on strategic objectives where this was already tagged. You can un-tag from the strategic objectives directly if needed. However, for any new Strategic Objective creation, this will not be visible.',
					isCancel: true,
					tagData: item,
				});
			} else {
				setDeleteModalProps({
					open: true,
					message: {
						msg1: 'Trying to delete the dimension?',
						msg2: '',
					},
					tagData: item,
					type: 'PerspectiveDelete',
				});
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const callDeletePerspectiveTagAPI = async () => {
		try {
			const tagData = deleteModalProps?.tagData || showDialog?.tagData;
			if (tagData && tagData?.perspectiveTagId) {
				setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
				setLoader(true);
				const requestParam = `perspectiveTagId=${tagData?.perspectiveTagId || 0}`;
				const response: any = await dispatch(deleteAdminSOPerspective(requestParam));
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					enqueueSnackbar(messages || `Dimension Tag deleted successfully.`, {
						variant: 'success',
						autoHideDuration: 3000,
					});

					const requestParam = {
						pageIndex: Enums.ZERO,
						pageSize: Enums.MAX_USER_SIZE,
						finder: [],
						sortingText: sortingColumn,
						order: sortOrder,
					};
					getAllPerspectiveList(requestParam);
					setIsCreateDisabled(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					enqueueSnackbar(messages || `Error occurred while deleting dimension tag.`, {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
				setLoader(false);
				setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
				setDeleteModalProps({ open: false, message: '', tagData: '', type: '' });
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(`Error occurred while deleting dimension tag.`, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setLoader(false);
			setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
		}
	};
	const handleTagNameChange = (e: any, item: any) => {
		e.preventDefault();
		const { value } = e?.target || {};
		let list = perspectiveList && perspectiveList.length ? [...perspectiveList] : [];
		list = [...list]?.map((rec: any) => {
			const data = { ...rec };
			if (data?.perspectiveTagId === item?.perspectiveTagId) {
				data.perspectiveNameTag = value;
				data.errorMessage = '';
			}

			return data;
		});
		setPerspectiveList([...list]);
	};
	const createNewTag = (e: any) => {
		let list = perspectiveList && perspectiveList.length ? [...perspectiveList] : [];
		const newRecord = {
			perspectiveTagId: 0,
			perspectiveNameTag: '',
			isActive: true,
			isDefault: false,
			isEdit: true,
			isNewRecord: true,
		};
		list.unshift(newRecord);
		setPerspectiveList([...list]);
		setIsCreateDisabled(true);
	};
	const handleCancelNewRecClick = (e: any, item: any) => {
		e.preventDefault();
		const requestParam = {
			pageIndex: Enums.ZERO,
			pageSize: Enums.MAX_USER_SIZE,
			finder: [],
			sortingText: sortingColumn,
			order: sortOrder,
		};
		getAllPerspectiveList(requestParam);
		setIsCreateDisabled(false);
	};
	const handleSaveNewRecClick = async (e: any, item: any, callType: string) => {
		try {
			setLoader(true);
			e.preventDefault();
			if (item?.perspectiveNameTag && item?.perspectiveNameTag.trim()) {
				let perspectiveTagId = 0;
				if (callType === 'direct') {
					perspectiveTagId = 0;
				} else {
					perspectiveTagId = item?.perspectiveTagId || 0;
				}
				const requestPayload = {
					perspectiveTagId: perspectiveTagId,
					perspectiveNameTag: item?.perspectiveNameTag || '',
					isActive: true,
				};
				const response: any = await dispatch(CreateAdminSOPerspective(requestPayload));

				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					enqueueSnackbar(messages || `Dimension Tag created successfully.`, {
						variant: 'success',
						autoHideDuration: 3000,
					});

					const requestParam = {
						pageIndex: Enums.ZERO,
						pageSize: Enums.MAX_USER_SIZE,
						finder: [],
						sortingText: sortingColumn,
						order: sortOrder,
					};
					getAllPerspectiveList(requestParam);
					setIsCreateDisabled(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					enqueueSnackbar(messages || `Error occurred while updating dimension.`, {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
				setLoader(false);
			} else {
				setLoader(false);
				let list = perspectiveList && perspectiveList.length ? [...perspectiveList] : [];
				list = [...list]?.map((rec: any) => {
					const data = { ...rec };
					if (data?.perspectiveTagId === item?.perspectiveTagId) {
						data.errorMessage = 'Please enter dimension tag name.';
					}

					return data;
				});
				setPerspectiveList([...list]);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
			setIsCreateDisabled(false);
		}
	};
	const cancelEditTagClick = (e: any, record: any) => {
		e.preventDefault();
		const requestParam = {
			pageIndex: Enums.ZERO,
			pageSize: Enums.MAX_USER_SIZE,
			finder: [],
			sortingText: sortingColumn,
			order: sortOrder,
		};
		getAllPerspectiveList(requestParam);
		setIsCreateDisabled(false);
	};
	const updateEditTagClick = (e: any, record: any) => {
		handleSaveNewRecClick(e, record, 'editFlow');
	};

	return (
		<>
			<Box className='so-setting-card full-rectangular-card'>
				<Box className='so-setting-head'>
					<Box>
						<Typography variant='h4' className='font-weight-normal'>
							{t('perspectiveSO')}
						</Typography>
						<Typography variant='subtitle2' className='subtitle3'>
							{t('perspectiveSubHead')}
						</Typography>
					</Box>
					<OKRButton
						className='btn-link add-perspective-btn'
						handleClick={(e) => createNewTag(e)}
						icon={<AddIconSvg />}
						text={t('addPerspective')}
						title={t('addPerspective')}
						disabled={isCreateDisabled}
					/>
				</Box>
				<Box className='perspective-listing-area'>
					{perspectiveList && perspectiveList.length ? (
						<Box className='okr-list-panel-head'>
							<List component='ul'>
								<ListItem className='perspective-col1'>
									<Typography
										variant='body2'
										onClick={(e) => sortUserList(e, 'perspectiveNameTag')}
										className={sortingColumn === 'perspectiveNameTag' ? `sort-title ${sortOrder}` : 'sort-title asc'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('perspectiveNameLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='perspective-col2'>
									<Typography
										variant='body2'
										onClick={(e) => sortUserList(e, 'isDefault')}
										className={sortingColumn === 'isDefault' ? `sort-title ${sortOrder}` : 'sort-title asc'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('categoryLevel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='perspective-col3'>
									<Typography
										variant='body2'
										onClick={(e) => sortUserList(e, 'isActive')}
										className={sortingColumn === 'isActive' ? `sort-title ${sortOrder}` : 'sort-title asc'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('statusLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
							</List>
						</Box>
					) : (
						<></>
					)}

					{perspectiveList && perspectiveList.length ? (
						perspectiveList?.map((record: any, index: number) => (
							<>
								<Box
									className='perspective-listing-content'
									key={`perspective_${record?.perspectiveTagId}_item_${index}`}
								>
									<List component='ul'>
										<ListItem
											className={`perspective-col1 ${
												record?.isEdit && !record?.isNewRecord ? 'perspective-edit-col' : ''
											}`}
										>
											{record?.isEdit ? (
												<>
													<TextField
														className='perspective-text-field'
														placeholder={t('perspectiveNameLabel')}
														fullWidth
														value={record?.perspectiveNameTag || ''}
														onChange={(e) => handleTagNameChange(e, record)}
													/>
													{record?.errorMessage ? (
														<Typography className='error-field'>
															<ErrorIcon /> {record?.errorMessage}
														</Typography>
													) : (
														<></>
													)}
													{!record?.isNewRecord ? (
														<Box className='edit-perspective-actions'>
															<OKRButton
																className='perspective-cancel-btn'
																icon={<CancelIcon />}
																handleClick={(e) => cancelEditTagClick(e, record)}
															/>
															<OKRButton
																className={`perspective-save-btn`}
																icon={<TickIcon />}
																handleClick={(e) => updateEditTagClick(e, record)}
																disabled={record?.perspectiveNameTag ? false : true}
															/>
														</Box>
													) : (
														<></>
													)}
												</>
											) : (
												<Typography>{record?.perspectiveNameTag || ''}</Typography>
											)}
											{!record?.isDefault && !record?.isEdit && !isCreateDisabled ? (
												<Box className='perspective-listing-actions'>
													<OKRButton
														handleClick={(e) => editSelectedTag(e, record)}
														icon={<EditIcon />}
														title={t('editLabel')}
													/>
													<OKRButton
														handleClick={(e) => deleteSelectedTag(e, record)}
														icon={<DeleteIcon />}
														title={t('delete')}
													/>
												</Box>
											) : (
												<></>
											)}
										</ListItem>
										<ListItem className='perspective-col2'>
											{record?.isDefault ? (
												<Box className='perspective-cat-chip'>
													<Chip label={t('defaultLabel')} />
												</Box>
											) : (
												<Box className='perspective-cat-chip'>
													<Chip label={t('addedByAdmin')} />
												</Box>
											)}
										</ListItem>
										<ListItem className='perspective-col3'>
											<Box className='status-switch'>
												<FormControlLabel
													label={''}
													control={
														<Switch
															disabled={record?.isEdit || isCreateDisabled}
															checked={record?.isActive}
															onChange={(event: any) => handleStatusChangeClick(event, record)}
														/>
													}
													className='toggle-label toggle-no-label'
												/>
											</Box>
										</ListItem>
									</List>
									{record?.isNewRecord ? (
										<Box className='so-setting-card-actions'>
											<Box className='so-setting-card-actions-left'></Box>
											<Box className='so-setting-card-actions-right'>
												<OKRButton
													handleClick={(e) => handleCancelNewRecClick(e, record)}
													className='btn-link'
													text={t('cancelBtn')}
													disabled={loader}
												/>
												<OKRButton
													handleClick={(e) => handleSaveNewRecClick(e, record, 'direct')}
													className='btn-primary'
													text={t('addBtn')}
													disabled={record?.perspectiveNameTag ? (loader ? true : false) : true}
												/>
											</Box>
										</Box>
									) : (
										<></>
									)}
								</Box>
							</>
						))
					) : (
						<></>
					)}
					{/* <Box className='perspective-listing-content'>
						<List component='ul'>
							<ListItem className='perspective-col1'>
								<Typography>Customer</Typography>
							</ListItem>
							<ListItem className='perspective-col2'>
								<Box className='perspective-cat-chip'>
									<Chip label={t('addedByAdmin')} />
								</Box>
							</ListItem>
							<ListItem className='perspective-col3'>
								<Box className='status-switch'>
									<FormControlLabel control={<Switch name='Perspective Status' />} label={''} />
								</Box>
							</ListItem>
						</List>
					</Box> */}
				</Box>
			</Box>
			{showDialog && showDialog?.open ? (
				<AlertDialog
					message={showDialog?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={showDialog?.open}
					isCancel={showDialog?.isCancel}
					module={'soPerspective'}
				/>
			) : (
				<></>
			)}
			{deleteModalProps && deleteModalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={deleteModalProps.open}
					handleCloseModal={(e: any) => handleCloseDeleteModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleCloseDeleteModal(e, 1)}
					headingText={deleteModalProps?.message?.msg1 || ''}
					subHeading={deleteModalProps?.message?.msg2 || ''}
				/>
			)}
		</>
	);
};
