import React, { FC } from 'react';
import { Avatar, Badge, Box, Checkbox, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AlertInfoIcon, DeleteIcon, NoUserIcon } from '../../../config/svg/CommonSvg';
import { OKRButton } from '../../Common/OKRButton';
import {
	BorderCheckboxIcon,
	CheckedIcon,
	CircleCheckboxIcon,
	FilledCircleCheckboxIcon,
} from '../../../config/svg/formElementIcons';
import { AvatarGroup } from '@material-ui/lab';
import { TickIcon, UserAvtarIcon } from '../../../config/svg/CommonSvgIcon';
import { Enums } from '../../../config/enums';
import {
	convertTaskDetailsForQuickLinks,
	formatOneOnOneRequestDate,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { AlignNewArrow } from '../../../config/svg/ArrowSvg';
import { CancelIcon } from '../../../config/svg/Action';
import { TaskQuickLinks } from '../TaskQuickLinks';
import moment from 'moment';
import { getLocalStorageItem } from '../../../services/StorageService';
import { useSelector } from 'react-redux';
import { TaskDelegatePopper } from '../TaskDelegatePopper';
import animatedCircleCheckboxIcon from '../../../images/animated-circle-checkbox-icon.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';

export const TaskListItem: FC<any> = (props) => {
	const {
		handleTaskNameClick,
		handleTaskChange,
		handleTaskOnEnterEdit,
		handleEditTaskClose,
		handleTaskUpdate,
		handlePriorityChangeOnEdit,
		handleAddTaskDueDateChangeOnEdit,
		handleTaskLinkChange,
		handleResetButtonClickOnEdit,
		onSelectedUserOnEdit,
		handleRemoveAssigneeOnEdit,
		handleKRLinkOnEdit,
		handleRemoveKROnEdit,
		handleCompleteTaskOnEdit,
		handleDeleteTaskOnEdit,
		defaultRecentUserList,
		setIsCreateLinkPopupOpen,
		callingFrom,
		highlightRedirectedTask = false,
		callForOther = false,
		searchedInEmpId,
		index,
		task,
		checkInUserEmpId,
		dueDateError = null,
		setDueDateError = () => {},
		handleCheckInSelectionOnEdit = () => {},
		handleRemoveCheckInSelectionOnEdit = () => {},
	} = props;
	const { t } = useTranslation();
	const userDetail: any = getUserDetails();
	const { krList } = useSelector((state: any) => state.okrReducer);
	const currentCycleDetails = getLocalStorageItem('currentCycle');
	const curCycleData = currentCycleDetails ? JSON.parse(currentCycleDetails) : {};
	const startDateValue = new Date();
	startDateValue.setMonth(startDateValue.getMonth() - 60);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 120);

	const validateUserEmpId = () => {
		const empId = callingFrom === 'CheckInTask' && checkInUserEmpId ? checkInUserEmpId : userDetail?.employeeId;
		return empId;
	};

	return (
		<Box
			className={`task-list-panel ${highlightRedirectedTask === task?.taskId ? 'highlight-task' : ''} ${
				callingFrom === 'CheckInTask' && task?.isImported ? 'task-list-panel-grey-out' : ' '
			} ${Boolean(task?.isRecentlyAdded) ? 'highlight-task' : ''}`}
			key={`global_task_list_item_index_${index + 1}`}
			id={`global_task_list_item_index_${task?.taskId}`}
		>
			<Box className='task-list-panel-left'>
				<Box className='task-name-box textfield-wrap'>
					<Box className='task-checkbox-animation'>
						{task?.isAnimated ? (
							<AnimatedIcon
								className=''
								width='48'
								type='image/svg+xml'
								alt={t('globalSearchNoRecord')}
								imagePath={animatedCircleCheckboxIcon}
							/>
						) : (
							''
						)}
						<Tooltip arrow title={`${task?.isCompleted ? t('markIncomplete') : t('markComplete')}`}>
							<Checkbox
								className='task-checkbox'
								icon={<CircleCheckboxIcon />}
								checkedIcon={<FilledCircleCheckboxIcon />}
								checked={task?.isCompleted || false}
								disabled={
									callingFrom === 'CheckInTask' && task?.isImported ? true : callForOther ? true : !task?.isEditEnable
								}
								aria-label='Task Checkbox'
								onChange={(e: any) => {
									handleEditTaskClose(e, index, task);
									handleCompleteTaskOnEdit(e, task);
								}}
							/>
						</Tooltip>
					</Box>
					<Box
						className={`task-name ${
							callingFrom === 'CheckInTask' && task?.isImported
								? ''
								: !task.isCompleted && task?.assignedBy?.employeeId === validateUserEmpId()
								? 'task-hover-active'
								: ''
						} ${task?.isEditEnable && task?.isEditMode ? 'edit-mode-on' : ''}`}
						onClick={(event) => {
							if (task?.assignedBy?.employeeId === validateUserEmpId()) {
								if (callingFrom === 'CheckInTask' && task?.isImported) {
									// No Edit call
								} else {
									handleTaskNameClick(event, task, index);
								}
							}
						}}
					>
						{task?.isEditEnable && task?.isEditMode ? (
							<Box className='edit-task-field'>
								<TextField
									ref={(ref) => ref && ref.focus()}
									maxRows={3}
									multiline
									disabled={callingFrom === 'CheckInTask' && task?.isImported ? true : callForOther ? true : false}
									value={task.taskName}
									autoFocus
									fullWidth
									onChange={(e) => handleTaskChange(e, index, task)}
									onKeyDown={(e) => handleTaskOnEnterEdit(e, index, task)}
									onFocus={(e) => {
										e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
										e.currentTarget.scrollTop = e.currentTarget.scrollHeight;
									}}
								/>
							</Box>
						) : (
							<Box className='task-after-edit'>
								<Typography>{task?.taskName || ''}</Typography>
							</Box>
						)}
					</Box>

					{task?.taskName ? (
						<Box className='action-btn-wrap'>
							<TaskQuickLinks
								{...props}
								krList={krList}
								formDetails={convertTaskDetailsForQuickLinks(task, true)}
								setFormDetails={() => {}}
								handlePriorityChange={(e: any, taskType: number) => handlePriorityChangeOnEdit(e, taskType, task)}
								handleAddTaskDueDateChange={(value: any, range: any) =>
									handleAddTaskDueDateChangeOnEdit(value, range, task)
								}
								handleTaskLinkChange={(links: any[]) => handleTaskLinkChange(links, task)}
								curCycleData={curCycleData}
								startDateValue={startDateValue}
								maxDateValue={maxDateValue}
								completeByDate={task?.dueDate ? moment(new Date(task?.dueDate)) : null}
								setCompleteBy={() => {}}
								handleResetButtonClick={() => handleResetButtonClickOnEdit(task)}
								onSelectedUser={(user: any) => onSelectedUserOnEdit(user, task)}
								handleRemoveAssignee={(e: any) => handleRemoveAssigneeOnEdit(e, task)}
								handleKRLink={(e: any, selectedKR: any) => handleKRLinkOnEdit(e, selectedKR, task)}
								handleRemoveKR={(e: any) => handleRemoveKROnEdit(e, task)}
								isCreateTask={false}
								isActionable={
									callingFrom === 'CheckInTask' && task?.isImported
										? false
										: callForOther
										? false
										: task?.isCompleted
										? false
										: task?.assignedBy?.employeeId === validateUserEmpId()
										? true
										: false
								} // task?.isEditEnable
								setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
								callingFrom={callingFrom}
								isKRandDueDateRemove={
									callingFrom === 'CheckInTask'
										? true
										: callingFrom === 'GlobalTask'
										? task?.taskTypeId === Enums.TWO
											? true
											: task?.assignedBy?.employeeId === validateUserEmpId()
											? false
											: true
										: task?.assignedBy?.employeeId === validateUserEmpId()
										? false
										: true
								}
								dueDateErrorId={`due_date_Copy_Error_${task?.taskId}_${index}`}
								isDateError={task?.isError || false}
								handleCheckInSelection={(e: any, isSelected: boolean) =>
									handleCheckInSelectionOnEdit(e, isSelected, task)
								}
								handleRemoveCheckInSelection={(e: any, isSelected: boolean) =>
									handleRemoveCheckInSelectionOnEdit(e, isSelected, task)
								}
								dueDateError={
									dueDateError && dueDateError?.isError && dueDateError?.itemIndex === task?.taskId
										? { isError: true }
										: { isError: false }
								}
								taskData={task}
							/>
							{!callForOther && task?.isEditEnable && task?.isEditMode ? (
								<Box className='textfield-action-btn'>
									<OKRButton
										className='cancel-btn'
										icon={<CancelIcon />}
										handleClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											handleEditTaskClose(e, index, task);
										}}
									/>
									<OKRButton
										className='save-btn'
										icon={<TickIcon />}
										disabled={task.taskName.trim() ? false : true}
										handleClick={(e: any) => {
											e.preventDefault();
											e.stopPropagation();
											handleTaskUpdate(task, false, index);
										}}
									/>
								</Box>
							) : (
								<></>
							)}
						</Box>
					) : (
						<></>
					)}
				</Box>
			</Box>
			<Box className='task-list-panel-right'>
				<Box className='user-avatar-group'>
					{task?.assignedBy?.employeeId && task?.assignedTo?.employeeId ? (
						<Box className='task-aligned-users'>
							{task?.assignedBy?.employeeId !== task?.assignedTo?.employeeId ? (
								<Box className='task-assigned-by'>
									{task?.assignedBy?.isActive ? (
										<></>
									) : (
										<Tooltip placement='top' arrow classes={{ popper: 'tooltip-alert' }} title={t('userDeleted')}>
											<Badge
												className={'badge-icon delegated-badge-icon'}
												overlap='rectangular'
												badgeContent={<AlertInfoIcon />}
											></Badge>
										</Tooltip>
									)}
									<Tooltip
										arrow
										title={`${task?.assignedBy?.firstName} ${task?.assignedBy?.lastName} delegated the task to ${task?.assignedTo?.firstName} ${task?.assignedTo?.lastName}`}
									>
										{task?.assignedBy?.imagePath && task?.assignedBy?.imagePath.trim() !== '' ? (
											<Avatar
												src={task?.assignedBy?.imagePath}
												className={task?.assignedBy?.isActive ? '' : 'disable-user'}
											/>
										) : task?.assignedBy?.firstName ? (
											<Avatar className={task?.assignedBy?.isActive ? '' : 'disable-user'}>
												{getUserName(task?.assignedBy)}
											</Avatar>
										) : (
											<></>
										)}
									</Tooltip>

									<Box className='align-arrow'>
										<AlignNewArrow />
									</Box>
								</Box>
							) : (
								<Box className='task-assigned-by'>
									{task?.assignedBy?.isActive ? (
										<></>
									) : (
										<Tooltip placement='top' arrow classes={{ popper: 'tooltip-alert' }} title={t('userDeleted')}>
											<Badge
												className={'badge-icon delegated-badge-icon'}
												overlap='rectangular'
												badgeContent={<AlertInfoIcon />}
											></Badge>
										</Tooltip>
									)}
									<Tooltip
										arrow
										title={
											searchedInEmpId === 'Current'
												? task?.assignedBy?.employeeId === validateUserEmpId()
													? `Delegated to me`
													: `Delegated to ${task?.assignedBy?.firstName} ${task?.assignedBy?.lastName}`
												: task?.assignedBy?.employeeId === validateUserEmpId()
												? `Delegated to me`
												: `Delegated to ${task?.assignedBy?.firstName} ${task?.assignedBy?.lastName}`
										}
									>
										{task?.assignedBy?.imagePath && task?.assignedBy?.imagePath.trim() !== '' ? (
											<Avatar
												src={task?.assignedBy?.imagePath}
												className={task?.assignedBy?.isActive ? '' : 'disable-user'}
											/>
										) : task?.assignedBy?.firstName ? (
											<Avatar className={task?.assignedBy?.isActive ? '' : 'disable-user'}>
												{getUserName(task?.assignedBy)}
											</Avatar>
										) : (
											<></>
										)}
									</Tooltip>
								</Box>
							)}
							{task?.assignedTo?.employeeId && task?.assignedBy?.employeeId !== task?.assignedTo?.employeeId ? (
								<>
									<Box className='task-assigned-to'>
										<TaskDelegatePopper
											formDetails={convertTaskDetailsForQuickLinks(task, true)}
											onSelectedUser={(user: any) => onSelectedUserOnEdit(user, task)}
											handleRemoveAssignee={(e: any) => handleRemoveAssigneeOnEdit(e, task, true)}
											defaultRecentUserList={defaultRecentUserList}
											popperPosition={'bottom-end'}
											isActionable={
												callingFrom === 'CheckInTask' && task?.isImported
													? false
													: callForOther
													? false
													: task?.isCompleted
													? false
													: task?.isEditEnable &&
													  Boolean(task?.assignedBy?.employeeId === validateUserEmpId()) &&
													  task?.assignedBy?.isActive
											}
											isCreateTask={false}
											setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
											isRemovable={
												callingFrom === 'CheckInTask' && task?.isImported
													? false
													: callForOther
													? false
													: task?.isCompleted
													? false
													: task?.isEditEnable && task?.assignedTo?.isActive
													? true
													: false
											}
											isUserDeleted={task?.assignedTo?.isActive ? false : true}
										/>
									</Box>
								</>
							) : !callForOther && task?.isEditEnable ? (
								<>
									<Box className='task-assigned-to'>
										<TaskDelegatePopper
											formDetails={convertTaskDetailsForQuickLinks(task, false)}
											onSelectedUser={(user: any) => onSelectedUserOnEdit(user, task)}
											handleRemoveAssignee={(e: any) => handleRemoveAssigneeOnEdit(e, task)}
											defaultRecentUserList={defaultRecentUserList}
											popperPosition={'bottom-end'}
											isActionable={
												callingFrom === 'CheckInTask' && task?.isImported
													? false
													: task?.isCompleted
													? false
													: task?.isEditEnable &&
													  Boolean(task?.assignedBy?.employeeId === validateUserEmpId()) &&
													  task?.assignedBy?.isActive
											}
											isCreateTask={false}
											setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
											isUserDeleted={task?.assignedTo?.isActive ? false : true}
										/>
									</Box>
								</>
							) : callingFrom === 'OneOnOneTask' ? (
								<Box className='task-assigned-to'>
									<OKRButton className='blue-btn disabled' disabled icon={<NoUserIcon />} title={t('delegateTo')} />
								</Box>
							) : (
								<></>
							)}
						</Box>
					) : (
						<></>
					)}
				</Box>
				{!callForOther && task?.isEditEnable && Boolean(task?.assignedBy?.employeeId === validateUserEmpId()) ? (
					callingFrom === 'CheckInTask' && task?.isImported ? (
						<></>
					) : (
						<Box className='task-list-actions'>
							<OKRButton
								handleClick={(e: any) => {
									handleDeleteTaskOnEdit(e, task);
								}}
								className='delete-btn'
								icon={<DeleteIcon />}
								title={t('delete')}
							/>
						</Box>
					)
				) : (
					<></>
				)}
			</Box>
		</Box>
	);
};
