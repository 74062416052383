import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { CreateTask } from './CreateTask';
import { TaskList } from './TaskList';
import Header from '../../containers/Header';
import { TaskHeaderTabs, TaskTabPanel } from './TaskHeaderTabs';
import {
	checkIsInitialListCall,
	checkUnSavePopUpVisible,
	formatOneOnOneRequestDate,
	getGlobalTaskListDueDate,
	getPriorityName,
	getUserDetails,
} from '../../config/utils';
import AlertDialog from '../Common/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import LinearLoader from '../Common/Loader';
import {
	completedGlobalTask,
	deleteGlobalTask,
	fetchGlobalTaskPending,
	getAllGlobalTask,
	getRecentAssignee,
	globalTaskNotificationRedirection,
	updateGlobalTask,
} from '../../action/globalTask';
import { Enums } from '../../config/enums';
import moment from 'moment';
import { getKrListing } from '../../action/myGoal';
import { useSnackbar } from 'notistack';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import NotesImg from '../../images/no-found-notes.svg';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';

export const GlobalTask: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const userDetail: any = getUserDetails();
	const taskRedirected = getLocalStorageItem('taskRedirected') || false;
	const {
		globalTaskListResponse,
		globalTaskListStatus,
		isRedirectedFromNotification,
		isCreateLinkPopupEnable,
		createLinkPopupDetail,
	} = useSelector((state: any) => state.globalTaskReducer);
	const { krList } = useSelector((state: any) => state.okrReducer);
	const ref = useRef<any>(null);
	// const ref3 = useRef<any>(null);
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 60);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 120);
	const defaultTaskListRequest = {
		isMyTask: true,
		isTagged: false,
		isCompleted: false,
		pageSize: Enums.TEN, // THIS FOR TESTING, THIS will be 10 Enums.TEN
		pageIndex: Enums.ONE,
		order: '',
		sortingText: '',
		finder: [],
		taskFilter: {
			dueDate: [],
			taskType: [],
			priority: [],
		},
		startDate: null,
		endDate: null,
		taskGroupById: Enums.ZERO,
		taskGroupByType: Enums.ZERO,
		moduleId: Enums.FOUR,
	};
	const defaultFormDetails = {
		taskName: '',
		priority: Enums.ONE,
		taskDueDate: null,
		assignedTo: Enums.ZERO,
		userDetails: null,
		goalTypeId: Enums.ZERO,
		goalId: Enums.ZERO,
		linkedKRDetails: null,
		isCheckedInLinked: false,
	};
	const staticFilterObj = {
		isCompleted: false,
		taskGroupByType: Enums.ZERO,
		sortingText: '',
		order: '',
	};

	const [formDetails, setFormDetails] = useState<any>(defaultFormDetails);
	const [tabSelected, setTabSelected] = useState(1);
	const [isLaunchFormEdited, setIsLaunchFormEdited] = useState<any>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	const [loader, setLoader] = useState(false);
	const [allTaskList, setAllTaskList] = useState<any>(null);
	const [taskPagingInfo, setTaskPagingInfo] = useState<any>(null);
	const [taskGroupByList, setTaskGroupByList] = useState<any>(null);
	const [pageIndexVal, setPageIndex] = useState<number>(1);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [completedTaskCount, setCompletedTaskCount] = useState<number>(0);
	const [toDoTaskCount, setToDoTaskCount] = useState<number>(0);
	const [globalTaskRequest, setGlobalTaskRequest] = useState<any>(defaultTaskListRequest);
	const [filterDetails, setFilterDetails] = useState<any>(staticFilterObj);
	const [defaultRecentUserList, setDefaultRecentUserList] = useState<any>([]);
	const [openGroupByTask, setOpenGroupByTask] = useState<any>({ taskExpand: false, taskGroupById: 0 });
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, details: null });
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	const [isDateRangeChanged, setIsDateRangeChanged] = useState<boolean>(false);
	const [selectedFilterDateRange, setSelectedFilterDateRange] = useState<any>(null);
	const [isCreateLinkPopupOpen, setIsCreateLinkPopupOpen] = useState<boolean>(false);
	const [highlightRedirectedTask, setHighlightRedirectedTask] = useState<any>(0);
	const [dueDateError, setDueDateError] = useState<any>(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(fetchGlobalTaskPending());
		if (!taskRedirected) {
			fetchGlobalTask(Enums.ZERO);
		}
		if (userDetail && userDetail?.employeeId) {
			let data = `empId=${userDetail.employeeId}`;
			dispatch(getKrListing(data));
		}
		getRecentSearchesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (selectedFilterDateRange) {
			fetchGlobalTask(Enums.ZERO);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFilterDateRange]);
	useEffect(() => {
		if (globalTaskListStatus) {
			renderTaskListDetails(globalTaskListResponse, null);
			setLoader(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalTaskListStatus, globalTaskListResponse]);
	useEffect(() => {
		const { isRedirected, taskName, taskId, tabId, isCompleted } = isRedirectedFromNotification;
		if (isRedirected) {
			setSearchTextArray(taskName);
			setHighlightRedirectedTask(taskId ? taskId : Enums.ZERO);
			setFilterDetails({
				...filterDetails,
				isCompleted: isCompleted || false,
				isTagged: tabId === Enums.THREE ? true : false,
			});
			handleTabChange({}, tabId ? tabId : Enums.ONE, taskId ? taskName : '', isCompleted || false);
			removeLocalStorageItem('taskRedirected');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRedirectedFromNotification]);
	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});
	useEffect(() => {
		document.addEventListener('click', handleCheckUnSaveData, true);
		return () => {
			document.removeEventListener('click', handleCheckUnSaveData, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLaunchFormEdited, isCreateLinkPopupOpen]);

	const handleCheckUnSaveData = (event: any) => {
		// if (ref3?.current?.contains(event?.target)) {
		// 	//
		// } else {
		const id = createLinkPopupDetail ? document.getElementById(createLinkPopupDetail) : null;
		const id2 = document.getElementById('taskLinkTextPopup');
		const class1 = document.getElementsByClassName('ck-body-wrapper');
		if (
			ref.current &&
			!ref.current?.contains(event.target) &&
			checkUnSavePopUpVisible(event) &&
			isLaunchFormEdited &&
			!id?.contains(event.target) &&
			!id2?.contains(event.target) &&
			!(class1.length > 0 && class1[0].contains(event.target))
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({
					open: true,
					type: 'unSaveChange',
					message: t('unSavedItemAlert'),
					details: null,
					event: event,
				});
			}
		} else {
		}
		// }
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChange') {
				await setIsLaunchFormEdited(false);
				const { target } = modalProps?.event || {};
				if (target) {
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
				resetTabDetails(false);
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	const getRecentSearchesList = () => {
		try {
			getRecentAssigneeList(`goalKeyId=${Enums.ZERO}`);
		} catch (error) {
			console.error(error);
		}
	};
	const handleSearchText = (searchText = '') => {
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			pageIndex: 1,
			isMyTask: getMyTaskDetails(),
			finder: searchText && searchText?.trim() !== '' ? [searchText] : [],
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	const renderTaskListDetails = (taskListResp: any, newTask: any) => {
		if (taskListResp?.status === Enums.STATUS_SUCCESS) {
			const { pagingInfo, entity } = taskListResp || {};
			const { totalPages, pageIndex = 0 } = pagingInfo || {};
			setTaskPagingInfo(pagingInfo);
			const { completedCount, toDoCount, taskListings, taskGroupByResponses } = entity || {};
			setCompletedTaskCount(completedCount || Enums.ZERO);
			setToDoTaskCount(toDoCount || Enums.ZERO);
			if (taskGroupByResponses && taskGroupByResponses.length) {
				setTaskGroupByList(taskGroupByResponses);
			} else {
				setTaskGroupByList([]);
			}
			const updatedTaskListingsData =
				taskListings && taskListings.length
					? taskListings?.filter((rec: any) => rec.taskId !== newTask?.taskId)
					: taskListings;
			const newTaskList = newTask ? [newTask, ...updatedTaskListingsData] : updatedTaskListingsData || [];
			// const newTaskList = newTask ? [newTask, ...(taskListings || [])] : taskListings || [];

			const updatedTaskListings =
				newTaskList && newTaskList.length
					? newTaskList.map((rec: any) => ({
							...rec,
							momentDueDate: rec?.dueDate ? moment(new Date(rec?.dueDate)) : null,
							historyDate: rec?.dueDate,
							history: rec.taskName,
					  }))
					: [];

			if (totalPages > Enums.ONE && totalPages > pageIndex) {
				setShowLoadMore(true);
			} else {
				setShowLoadMore(false);
			}

			if (pageIndex === Enums.ZERO || pageIndex === Enums.ONE) {
				setAllTaskList(updatedTaskListings || []);
			} else {
				if (allTaskList && allTaskList.length) {
					const fullList = [...allTaskList, ...updatedTaskListings];
					let filterData: any[] = [];
					fullList?.forEach((rec: any) => {
						const sf = filterData?.find((it: any) => it?.taskId === rec?.taskId);
						if (!sf) {
							filterData.push({ ...rec });
						}
					});
					setAllTaskList(filterData);
				} else {
					setAllTaskList([...updatedTaskListings]);
				}
			}
			if (highlightRedirectedTask) {
				const selectedTaskId = `global_task_list_item_index_${highlightRedirectedTask}`;
				moveScrollToBox(selectedTaskId);
				setTimeout(() => {
					removeLocalStorageItem('taskRedirected');
					dispatch(
						globalTaskNotificationRedirection({
							isRedirected: false,
							taskName: '',
							taskId: 0,
							tabId: 0,
							isCompleted: false,
						})
					);
					setHighlightRedirectedTask(0);
				}, 10000);
			}
		} else {
			setCompletedTaskCount(Enums.ZERO);
			setToDoTaskCount(Enums.ZERO);
			setShowLoadMore(false);
			setTaskGroupByList([]);
			setAllTaskList([]);
			setTaskPagingInfo({});
		}
	};

	const moveScrollToBox = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element?.scrollIntoView({ behavior: 'smooth' });
		}
	};
	const resetCalendarDate = (isDirect: boolean) => {
		if (isDirect) {
			setSelectedFilterDateRange(null);
			setLoader(true);
			setPageIndex(1);
			const data = {
				...globalTaskRequest,
				pageIndex: 1,
				isMyTask: getMyTaskDetails(),
				startDate: null,
				endDate: null,
			};
			setGlobalTaskRequest(data);
			dispatch(getAllGlobalTask(data, false));
		} else {
			setSelectedFilterDateRange(null);
		}
	};
	const handleTabChange = (event: any, newValue: any, type = '', isCompleted = false) => {
		if (newValue === Enums.ONE || newValue === Enums.TWO || newValue === Enums.THREE) {
			window.scrollTo(0, 0);
			setAllTaskList(null);
			setTaskPagingInfo({});
			setLoader(true);
			setPageIndex(1);
			let data: any = {
				...defaultTaskListRequest,
				isMyTask: newValue === Enums.ONE ? true : false,
				isTagged: newValue === Enums.THREE ? true : false,
				isCompleted,
			};
			if (type) {
				data = {
					...defaultTaskListRequest,
					isMyTask: newValue === Enums.ONE ? true : false,
					isTagged: newValue === Enums.THREE ? true : false,
					isCompleted,
					finder: type && type?.trim() !== '' ? [type] : [],
				};
			} else {
				setSearchTextArray('');
			}
			setGlobalTaskRequest(data);
			setFilterDetails({ ...staticFilterObj, isCompleted: isCompleted || false });
			dispatch(getAllGlobalTask(data, false));
			if (userDetail && userDetail?.employeeId) {
				let data = `empId=${userDetail.employeeId}`;
				dispatch(getKrListing(data));
			}
			getRecentSearchesList();
		}
		resetTabDetails(false);
		setTabSelected(newValue);
		resetCalendarDate(false);
	};
	const scrollPageHeader = (e: any) => {
		const objElement = document.getElementById('page-task-subheader');
		if (window.pageYOffset >= 88) {
			objElement && objElement.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement && objElement.classList.remove('sticky-header');
		}
		/**
		 * Load data on scroll
		 */
		// const windowHeight = window.innerHeight + document.documentElement.scrollTop; // window.pageYOffset;
		// const documentHeight = document.documentElement.offsetHeight;
		// if (documentHeight === windowHeight && showLoadMore) {
		// 	fetchGlobalTask(pageIndexVal);
		// }
	};
	const getMyTaskDetails = () => {
		if (tabSelected === Enums.ONE) {
			return true;
		} else if (tabSelected === Enums.TWO) {
			return false;
		}
	};

	const getRecentAssigneeList = async (requestParam: any) => {
		try {
			const response: any = await dispatch(getRecentAssignee(requestParam));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				setDefaultRecentUserList(entityList && entityList.length ? entityList : []);
			} else {
				setDefaultRecentUserList([]);
			}
		} catch (error) {
			console.error(error);
			setDefaultRecentUserList([]);
		}
	};
	const fetchGlobalTask = async (pageIndex: number) => {
		setLoader(true);
		setPageIndex(pageIndex + 1);
		let dateData = {};
		if (selectedFilterDateRange) {
			const startDate = selectedFilterDateRange?.start?._d || new Date();
			const endDate = selectedFilterDateRange?.end?._d || new Date();
			dateData = {
				startDate: formatOneOnOneRequestDate(startDate),
				endDate: formatOneOnOneRequestDate(endDate),
			};
		}
		const data = { ...globalTaskRequest, pageIndex: pageIndex + 1, isMyTask: getMyTaskDetails(), ...dateData };
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	const resetTabDetails = (resetType: boolean) => {
		setFormDetails(defaultFormDetails);
		setIsLaunchFormEdited(false);
		if (resetType) {
			setFilterDetails(staticFilterObj);
			setPageIndex(1);
			const data = { ...defaultTaskListRequest, isMyTask: tabSelected === Enums.ONE ? true : false };
			setGlobalTaskRequest(data);
		}
	};
	const handleFilterCompletedOnly = (e: any) => {
		const { checked } = e?.target;
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			isCompleted: checked,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
		setFilterDetails({
			...filterDetails,
			isCompleted: checked,
			// taskGroupByType: Enums.ZERO,
			// sortingText: '',
			// order: '',
		});
	};
	const handleGroupByFilterClick = (taskGroupByType: number) => {
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			taskGroupByType,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
		setFilterDetails({ ...filterDetails, taskGroupByType });
	};
	const handleSortingFilterClick = (sortingText: string, order: string) => {
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			sortingText,
			order,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
		setFilterDetails({ ...filterDetails, sortingText, order });
	};
	const handleCollapseTask = (e: any, taskGroupById: any) => {
		setOpenGroupByTask({
			taskExpand: taskGroupById === openGroupByTask?.taskGroupById ? !openGroupByTask?.taskExpand : true,
			taskGroupById: taskGroupById,
		});
		if (taskGroupById !== openGroupByTask?.taskGroupById) {
			setAllTaskList(null);
			setTaskPagingInfo({});
			setLoader(true);
			setPageIndex(1);
			const data = { ...globalTaskRequest, pageIndex: 1, taskGroupById: taskGroupById, isMyTask: getMyTaskDetails() };
			setGlobalTaskRequest(data);
			dispatch(getAllGlobalTask(data, false));
		}
	};
	// filterDetails?.taskGroupByType === Enums.ZERO

	/**
	 * Task Name Change action start here
	 */
	const callUpdateTaskDetailsAPI = async (taskDetail: any, updateType = '') => {
		try {
			if (taskDetail && taskDetail?.taskId) {
				setLoader(true);
				setDueDateError(null);
				const requestPayload: any = {
					goalId: taskDetail?.goalId || Enums.ZERO,
					goalTypeId: taskDetail?.goalId ? Enums.TWO : Enums.ZERO,
					taskId: taskDetail?.taskId || 0,
					taskName: taskDetail?.taskName.trim(),
					taskType: taskDetail?.taskTypeId || Enums.FOUR,
					dueDate: taskDetail?.dueDate ? formatOneOnOneRequestDate(taskDetail?.dueDate) : null,
					priority: taskDetail?.priorityId || Enums.ONE,
					assignedTo: taskDetail?.assignedTo?.employeeId || Enums.ZERO,
					isCompleted: taskDetail?.isCompleted || false,
					isCheckedInLinked: taskDetail?.isCheckedInLinked || false,
				};
				if (taskDetail.link) {
					let formTaskLink: any[] = [];
					taskDetail.link.forEach((linkArray: any) => {
						formTaskLink.push(linkArray.link);
					});
					requestPayload.link = formTaskLink;
				}
				const response: any = await dispatch(updateGlobalTask(requestPayload));
				setLoader(false);
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data || {};
					if (entity) {
						let msg = null;
						if (updateType === 'DueDateChanged' || updateType === 'CheckInUnlink' || updateType === 'RemoveKR') {
							msg = t('taskUpdatedToastMessage');
						}
						if (msg) {
							enqueueSnackbar(msg, {
								variant: 'success',
								autoHideDuration: 3000,
							});
						}
						// const data = { ...globalTaskRequest };
						//dispatch(getAllGlobalTask(data, false));
						const updatedRecord = {
							...entity,
							momentDueDate: entity?.dueDate ? moment(new Date(entity?.dueDate)) : null,
							historyDate: entity?.dueDate || null,
							history: entity.taskName || '',
						};
						let tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
						tasksCopy = tasksCopy.map((taskVal: any) => {
							if (taskVal.taskId === updatedRecord.taskId) {
								taskVal = updatedRecord;
							}
							return taskVal;
						});

						setAllTaskList([...tasksCopy]);
					}
					getRecentSearchesList();
				} else {
					const responseAPI = response?.data?.messageList || {};
					const keys = Object.keys(responseAPI);
					const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
					enqueueSnackbar(messages, {
						variant: 'error',
						autoHideDuration: 3000,
					});
					return false;
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const updatedSelectedTaskItem = (
		task: any,
		keyName: string,
		keyName2?: string,
		keyName3?: string,
		keyName4?: string
	) => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		tasksCopy.forEach((taskVal: any) => {
			if (taskVal.taskId === task.taskId) {
				if (keyName) {
					if (keyName === 'assignedTo' && !task[keyName]) {
						taskVal[keyName] = task?.assignedBy || null;
					} else {
						taskVal[keyName] = task[keyName];
					}
				}
				if (keyName2) {
					if (keyName2 === 'isAnimated') {
						taskVal[keyName2] = task?.isCompleted;
					} else {
						taskVal[keyName2] = task[keyName2];
					}
					// taskVal[keyName2] = task[keyName2];
				}
				if (keyName3) {
					taskVal[keyName3] = task[keyName3];
				}
				if (keyName4) {
					taskVal[keyName4] = task[keyName4];
				}
			}
		});

		setAllTaskList([...tasksCopy]);
	};
	const handleTaskNameClick = (e: any, task: any, index: number) => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		if (!task.isCompleted) {
			tasksCopy.forEach((taskVal: any) => {
				if (taskVal.taskId === task.taskId) {
					taskVal.openEditMode = true;
					taskVal.isEditMode = true;
				} else {
					taskVal.taskName = taskVal?.history || '';
					taskVal.openEditMode = false;
					taskVal.isEditMode = false;
				}
			});

			setAllTaskList([...tasksCopy]);
		}
	};
	const handleTaskChange = (e: any, index: any, allTasks: any) => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];

		const { value } = e.target;
		tasksCopy[index].taskName = value;
		tasksCopy[index].isEditMode = true;
		tasksCopy.forEach((task: any) => {
			if (!task.isEditMode) {
				task.disabled = true;
			}
		});

		setAllTaskList([...tasksCopy]);
	};

	const handleTaskOnEnterEdit = (e: any, index: number, task: any) => {
		if (e.keyCode === 13 && task && task.taskName.trim() !== '') {
			handleTaskUpdate(task, false, index);
		} else if (e.keyCode === 27) {
			handleEditTaskClose(e, index, task);
		}
	};
	const handleEditTaskClose = (event: any, index: any, task: any) => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		tasksCopy[index].taskName = task?.history || '';
		tasksCopy[index].isEditMode = false;
		tasksCopy[index].openEditMode = false;
		tasksCopy.forEach((task: any) => {
			task.disabled = false;
		});
		setAllTaskList([...tasksCopy]);
	};
	const handleTaskUpdate = async (taskDetail: any, isTaskDueDateChange: boolean, index: number) => {
		if (taskDetail?.taskName && taskDetail?.taskName.trim() !== '') {
			setLoader(true);
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			tasksCopy[index].isEditMode = false;
			tasksCopy[index].openEditMode = false;
			tasksCopy.forEach((task: any) => {
				task.disabled = false;
			});
			setAllTaskList([...tasksCopy]);

			callUpdateTaskDetailsAPI(taskDetail);
		} else {
			console.log('handleTaskUpdate ===> ', taskDetail);
		}
	};
	/**
	 * Task Name Change action end here
	 */
	const handleCheckInSelectionOnEdit = (e: any, isSelected: any, task: any) => {
		try {
			if (task?.dueDate) {
				const requestPayload = {
					...task,
					isCheckedInLinked: isSelected,
				};
				callUpdateTaskDetailsAPI(requestPayload);
				setDueDateError(null);
			} else {
				setDueDateError({ isError: true, itemIndex: task?.taskId });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleRemoveCheckInSelectionOnEdit = (e: any, isSelected: any, task: any) => {
		try {
			const requestPayload = {
				...task,
				isCheckedInLinked: isSelected,
			};
			callUpdateTaskDetailsAPI(requestPayload, 'CheckInUnlink');
			setDueDateError(null);
		} catch (error) {
			console.error(error);
		}
	};
	const handlePriorityChangeOnEdit = (e: any, taskType: number, task: any) => {
		try {
			const requestPayload = {
				...task,
				priority: getPriorityName(t, taskType || Enums.ONE),
				priorityId: taskType || Enums.ONE,
			};
			// updatedSelectedTaskItem(requestPayload, 'priority', 'priorityId');
			callUpdateTaskDetailsAPI(requestPayload);
		} catch (error) {
			console.error(error);
		}
	};
	const handleAddTaskDueDateChangeOnEdit = (value: any, range: any, task: any) => {
		try {
			const requestPayload = {
				...task,
				dueDate: value,
				dueDateText: value ? getGlobalTaskListDueDate(new Date(value)) : '',
				overDueText: '',
				isOverDue: false,
			};
			// updatedSelectedTaskItem(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
			callUpdateTaskDetailsAPI(requestPayload, 'DueDateChanged');
		} catch (error) {
			console.error(error);
		}
	};
	const handleTaskLinkChange = (links: any[], task: any) => {
		try {
			const requestPayload = {
				...task,
				link: links,
			};
			callUpdateTaskDetailsAPI(requestPayload);
		} catch (error) {
			console.error(error);
		}
	};
	const handleResetButtonClickOnEdit = (task: any) => {
		const requestPayload = {
			...task,
			dueDate: '',
			dueDateText: '',
			overDueText: '',
			isOverDue: false,
			isCheckedInLinked: false,
		};
		// updatedSelectedTaskItem(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
		callUpdateTaskDetailsAPI(requestPayload, 'DueDateChanged');
	};
	const handleKRLinkOnEdit = (e: any, selectedKR: any, task: any) => {
		if (selectedKR && selectedKR?.goalKeyId) {
			const requestPayload = {
				...task,
				goalTypeId: selectedKR?.goalKeyId ? Enums.TWO : Enums.ZERO,
				goalId: selectedKR?.goalKeyId || Enums.ZERO,
				linkedKRDetails: selectedKR,
			};
			updatedSelectedTaskItem(requestPayload, 'goalTypeId', 'goalId', 'linkedKRDetails');
			callUpdateTaskDetailsAPI(requestPayload);
		}
	};
	const handleRemoveKROnEdit = (e: any, task: any) => {
		const requestPayload = {
			...task,
			goalTypeId: Enums.ZERO,
			goalId: Enums.ZERO,
			linkedKRDetails: null,
		};
		updatedSelectedTaskItem(requestPayload, 'goalTypeId', 'goalId', 'linkedKRDetails');
		callUpdateTaskDetailsAPI(requestPayload, 'RemoveKR');
	};
	const onSelectedUserOnEdit = (selectedUser: any, task: any) => {
		if (selectedUser && selectedUser?.employeeId) {
			const requestPayload = { ...task, assignedTo: selectedUser };
			// console.log('onSelectedUserOnEdit ===> ', requestPayload);
			updatedSelectedTaskItem(requestPayload, 'assignedTo');
			callUpdateTaskDetailsAPI(requestPayload);
		}
	};
	const handleRemoveAssigneeOnEdit = (e: any, task: any, isAssigneeSelfRemoved = false) => {
		const requestPayload = { ...task, assignedTo: isAssigneeSelfRemoved ? task?.assignedBy || null : null };
		updatedSelectedTaskItem(requestPayload, 'assignedTo');
		callUpdateTaskDetailsAPI(requestPayload);
	};
	const handleCompleteTaskOnEdit = async (e: any, task: any) => {
		try {
			const { checked } = e.target;
			setLoader(true);
			const updatedTaskDetails = { ...task, isCompleted: checked };
			updatedSelectedTaskItem(updatedTaskDetails, 'isCompleted', 'isAnimated');
			const requestPayload = { taskId: task.taskId, isCompleted: checked };
			const taskCompleteResponse: any = await dispatch(completedGlobalTask(requestPayload));
			if (Boolean(taskCompleteResponse) && taskCompleteResponse.data.status === 200) {
				// const data = { ...globalTaskRequest };
				// dispatch(getAllGlobalTask(data, false));
				setLoader(false);
			} else {
				const responseAPI = taskCompleteResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				enqueueSnackbar(messages, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setLoader(false);
			}
		} catch (error) {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};
	const handleDeleteTaskOnEdit = (e: any, task: any) => {
		setOpenDeleteDialog({ open: true, details: { task } });
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			const { task } = openDeleteDialog?.details || {};
			if (task?.taskId) {
				setOpenDeleteDialog({ open: false, details: null });
				handleConfirmDeleteTaskClick(e, task);
			}
		} else {
			setOpenDeleteDialog({ open: false, details: null });
		}
	};
	const handleConfirmDeleteTaskClick = async (e: any, taskDetails: any) => {
		try {
			setLoader(true);
			const requestParams = `taskId=${taskDetails?.taskId}`;
			const deleteTaskResponse: any = await dispatch(deleteGlobalTask(requestParams));
			if (Boolean(deleteTaskResponse) && deleteTaskResponse?.data?.status === 200) {
				const updatedAllTaskList = allTaskList && allTaskList?.length ? [...allTaskList] : [];
				setAllTaskList(updatedAllTaskList?.filter((rec: any) => rec.taskId !== taskDetails?.taskId));
				const data = { ...globalTaskRequest };
				dispatch(getAllGlobalTask(data, false));

				setLoader(false);
			} else {
				const responseAPI = deleteTaskResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				enqueueSnackbar(messages, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setLoader(false);
			}
		} catch (error) {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setLoader(false);
		}
	};

	return (
		<Fragment>
			{loader && <LinearLoader />}
			<Box className='page-subheader page-subheader-notification' id='page-task-subheader'>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'>
						<Box className='page-subheader-title'>
							<Typography variant='h2'>{t('tasksLabel')}</Typography>
						</Box>
						<TaskHeaderTabs tabSelected={tabSelected} handleTabChange={handleTabChange} {...props} />
					</Box>
					<Box className='page-subheader-right'>
						<Box className='page-subheader-right-inner'>
							<Header {...props} fromTab={true} isTask={true} />
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className='pad-container global-task-page'>
				<TaskTabPanel value={tabSelected} index={1}>
					<>
						<div ref={ref}>
							<CreateTask
								loader={loader}
								setLoader={setLoader}
								isLaunchFormEdited={isLaunchFormEdited}
								setIsLaunchFormEdited={setIsLaunchFormEdited}
								allTaskList={allTaskList}
								setAllTaskList={setAllTaskList}
								defaultFormDetails={defaultFormDetails}
								formDetails={formDetails}
								setFormDetails={setFormDetails}
								resetTabDetails={resetTabDetails}
								krList={krList}
								defaultRecentUserList={defaultRecentUserList}
								minDateValue={minDateValue}
								maxDateValue={maxDateValue}
								setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
								callingFrom={'GlobalTask'}
								globalTaskRequest={globalTaskRequest}
								defaultTaskListRequest={{ ...defaultTaskListRequest, isMyTask: true }}
								renderTaskListDetails={renderTaskListDetails}
								getRecentSearchesList={getRecentSearchesList}
								taskPagingInfo={taskPagingInfo}
								setTaskPagingInfo={setTaskPagingInfo}
								dueDateError={dueDateError}
								setDueDateError={setDueDateError}
							/>
						</div>
						{allTaskList &&
						allTaskList.length === Enums.ZERO &&
						!completedTaskCount &&
						!toDoTaskCount &&
						checkIsInitialListCall(defaultTaskListRequest, globalTaskRequest) ? (
							<Box className='welcome-content'>
								<Box textAlign='center'>
									<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
									<Typography>{t('noTaskFoundSubTitle')}</Typography>
									<AnimatedIcon
										className='no-task-image'
										width='323px'
										type='image/svg+xml'
										alt={t('noTaskHead')}
										imagePath={NotesImg}
									/>
								</Box>
							</Box>
						) : (
							<TaskList
								loader={loader}
								setLoader={setLoader}
								tabSelected={tabSelected}
								globalTaskRequest={globalTaskRequest}
								setGlobalTaskRequest={setGlobalTaskRequest}
								setPageIndex={setPageIndex}
								setTabSelected={setTabSelected}
								filterDetails={filterDetails}
								allTaskList={allTaskList}
								taskPagingInfo={taskPagingInfo}
								completedTaskCount={completedTaskCount}
								toDoTaskCount={toDoTaskCount}
								handleFilterCompletedOnly={handleFilterCompletedOnly}
								handleGroupByFilterClick={handleGroupByFilterClick}
								handleSortingFilterClick={handleSortingFilterClick}
								showLoadMore={showLoadMore}
								pageIndexVal={pageIndexVal}
								fetchGlobalTask={fetchGlobalTask}
								handleTaskNameClick={handleTaskNameClick}
								handleTaskChange={handleTaskChange}
								handleTaskOnEnterEdit={handleTaskOnEnterEdit}
								handleEditTaskClose={handleEditTaskClose}
								handleTaskUpdate={handleTaskUpdate}
								setIsLaunchFormEdited={setIsLaunchFormEdited}
								handlePriorityChangeOnEdit={handlePriorityChangeOnEdit}
								handleAddTaskDueDateChangeOnEdit={handleAddTaskDueDateChangeOnEdit}
								handleTaskLinkChange={handleTaskLinkChange}
								handleResetButtonClickOnEdit={handleResetButtonClickOnEdit}
								onSelectedUserOnEdit={onSelectedUserOnEdit}
								handleRemoveAssigneeOnEdit={handleRemoveAssigneeOnEdit}
								handleKRLinkOnEdit={handleKRLinkOnEdit}
								handleRemoveKROnEdit={handleRemoveKROnEdit}
								handleCompleteTaskOnEdit={handleCompleteTaskOnEdit}
								handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
								taskGroupByList={taskGroupByList}
								handleCollapseTask={handleCollapseTask}
								openGroupByTask={openGroupByTask}
								setOpenGroupByTask={setOpenGroupByTask}
								defaultRecentUserList={defaultRecentUserList}
								searchTextArray={searchTextArray}
								setSearchTextArray={setSearchTextArray}
								handleSearchText={handleSearchText}
								minDateValue={minDateValue}
								maxDateValue={maxDateValue}
								setIsDateRangeChanged={setIsDateRangeChanged}
								selectedFilterDateRange={selectedFilterDateRange}
								setSelectedFilterDateRange={setSelectedFilterDateRange}
								resetCalendarDate={resetCalendarDate}
								setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
								callingFrom={'GlobalTask'}
								highlightRedirectedTask={highlightRedirectedTask}
								dueDateError={dueDateError}
								setDueDateError={setDueDateError}
								handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
								handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
							/>
						)}
					</>
				</TaskTabPanel>
				<TaskTabPanel value={tabSelected} index={2}>
					<>
						<div ref={ref}>
							<CreateTask
								loader={loader}
								setLoader={setLoader}
								isLaunchFormEdited={isLaunchFormEdited}
								setIsLaunchFormEdited={setIsLaunchFormEdited}
								allTaskList={allTaskList}
								setAllTaskList={setAllTaskList}
								defaultFormDetails={defaultFormDetails}
								formDetails={formDetails}
								setFormDetails={setFormDetails}
								resetTabDetails={resetTabDetails}
								krList={krList}
								defaultRecentUserList={defaultRecentUserList}
								minDateValue={minDateValue}
								maxDateValue={maxDateValue}
								setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
								callingFrom={'GlobalTask'}
								globalTaskRequest={globalTaskRequest}
								defaultTaskListRequest={{ ...defaultTaskListRequest, isMyTask: false }}
								renderTaskListDetails={renderTaskListDetails}
								getRecentSearchesList={getRecentSearchesList}
								taskPagingInfo={taskPagingInfo}
								setTaskPagingInfo={setTaskPagingInfo}
								dueDateError={dueDateError}
								setDueDateError={setDueDateError}
							/>
						</div>
						{allTaskList &&
						allTaskList.length === Enums.ZERO &&
						!completedTaskCount &&
						!toDoTaskCount &&
						checkIsInitialListCall(defaultTaskListRequest, globalTaskRequest) ? (
							<Box className='welcome-content'>
								<Box textAlign='center'>
									<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
									<Typography>{t('noTaskFoundSubTitle')}</Typography>
									<AnimatedIcon
										className=''
										width='230'
										type='image/svg+xml'
										alt={t('noTaskHead')}
										imagePath={NotesImg}
									/>
								</Box>
							</Box>
						) : (
							<TaskList
								loader={loader}
								setLoader={setLoader}
								tabSelected={tabSelected}
								globalTaskRequest={globalTaskRequest}
								setGlobalTaskRequest={setGlobalTaskRequest}
								setPageIndex={setPageIndex}
								setTabSelected={setTabSelected}
								filterDetails={filterDetails}
								allTaskList={allTaskList}
								taskPagingInfo={taskPagingInfo}
								completedTaskCount={completedTaskCount}
								toDoTaskCount={toDoTaskCount}
								handleFilterCompletedOnly={handleFilterCompletedOnly}
								handleGroupByFilterClick={handleGroupByFilterClick}
								handleSortingFilterClick={handleSortingFilterClick}
								showLoadMore={showLoadMore}
								pageIndexVal={pageIndexVal}
								fetchGlobalTask={fetchGlobalTask}
								handleTaskNameClick={handleTaskNameClick}
								handleTaskChange={handleTaskChange}
								handleTaskOnEnterEdit={handleTaskOnEnterEdit}
								handleEditTaskClose={handleEditTaskClose}
								handleTaskUpdate={handleTaskUpdate}
								setIsLaunchFormEdited={setIsLaunchFormEdited}
								handlePriorityChangeOnEdit={handlePriorityChangeOnEdit}
								handleAddTaskDueDateChangeOnEdit={handleAddTaskDueDateChangeOnEdit}
								handleTaskLinkChange={handleTaskLinkChange}
								handleResetButtonClickOnEdit={handleResetButtonClickOnEdit}
								onSelectedUserOnEdit={onSelectedUserOnEdit}
								handleRemoveAssigneeOnEdit={handleRemoveAssigneeOnEdit}
								handleKRLinkOnEdit={handleKRLinkOnEdit}
								handleRemoveKROnEdit={handleRemoveKROnEdit}
								handleCompleteTaskOnEdit={handleCompleteTaskOnEdit}
								handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
								taskGroupByList={taskGroupByList}
								handleCollapseTask={handleCollapseTask}
								openGroupByTask={openGroupByTask}
								setOpenGroupByTask={setOpenGroupByTask}
								defaultRecentUserList={defaultRecentUserList}
								searchTextArray={searchTextArray}
								setSearchTextArray={setSearchTextArray}
								handleSearchText={handleSearchText}
								minDateValue={minDateValue}
								maxDateValue={maxDateValue}
								setIsDateRangeChanged={setIsDateRangeChanged}
								selectedFilterDateRange={selectedFilterDateRange}
								setSelectedFilterDateRange={setSelectedFilterDateRange}
								resetCalendarDate={resetCalendarDate}
								setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
								callingFrom={'GlobalTask'}
								highlightRedirectedTask={highlightRedirectedTask}
								dueDateError={dueDateError}
								setDueDateError={setDueDateError}
								handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
								handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
							/>
						)}
					</>
				</TaskTabPanel>
				<TaskTabPanel value={tabSelected} index={3}>
					<>
						{allTaskList &&
						allTaskList.length === Enums.ZERO &&
						!completedTaskCount &&
						!toDoTaskCount &&
						checkIsInitialListCall(defaultTaskListRequest, globalTaskRequest) ? (
							<Box className='welcome-content'>
								<Box textAlign='center'>
									<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
									<Typography>{t('noTaskFoundSubTitle')}</Typography>
									<AnimatedIcon
										className=''
										width='230'
										type='image/svg+xml'
										alt={t('noTaskHead')}
										imagePath={NotesImg}
									/>
								</Box>
							</Box>
						) : (
							<TaskList
								loader={loader}
								setLoader={setLoader}
								tabSelected={tabSelected}
								globalTaskRequest={globalTaskRequest}
								setGlobalTaskRequest={setGlobalTaskRequest}
								setPageIndex={setPageIndex}
								setTabSelected={setTabSelected}
								filterDetails={filterDetails}
								allTaskList={allTaskList}
								taskPagingInfo={taskPagingInfo}
								completedTaskCount={completedTaskCount}
								toDoTaskCount={toDoTaskCount}
								handleFilterCompletedOnly={handleFilterCompletedOnly}
								handleGroupByFilterClick={handleGroupByFilterClick}
								handleSortingFilterClick={handleSortingFilterClick}
								showLoadMore={showLoadMore}
								pageIndexVal={pageIndexVal}
								fetchGlobalTask={fetchGlobalTask}
								handleTaskNameClick={handleTaskNameClick}
								handleTaskChange={handleTaskChange}
								handleTaskOnEnterEdit={handleTaskOnEnterEdit}
								handleEditTaskClose={handleEditTaskClose}
								handleTaskUpdate={handleTaskUpdate}
								setIsLaunchFormEdited={setIsLaunchFormEdited}
								handlePriorityChangeOnEdit={handlePriorityChangeOnEdit}
								handleAddTaskDueDateChangeOnEdit={handleAddTaskDueDateChangeOnEdit}
								handleTaskLinkChange={handleTaskLinkChange}
								handleResetButtonClickOnEdit={handleResetButtonClickOnEdit}
								onSelectedUserOnEdit={onSelectedUserOnEdit}
								handleRemoveAssigneeOnEdit={handleRemoveAssigneeOnEdit}
								handleKRLinkOnEdit={handleKRLinkOnEdit}
								handleRemoveKROnEdit={handleRemoveKROnEdit}
								handleCompleteTaskOnEdit={handleCompleteTaskOnEdit}
								handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
								taskGroupByList={taskGroupByList}
								handleCollapseTask={handleCollapseTask}
								openGroupByTask={openGroupByTask}
								setOpenGroupByTask={setOpenGroupByTask}
								defaultRecentUserList={defaultRecentUserList}
								searchTextArray={searchTextArray}
								setSearchTextArray={setSearchTextArray}
								handleSearchText={handleSearchText}
								minDateValue={minDateValue}
								maxDateValue={maxDateValue}
								setIsDateRangeChanged={setIsDateRangeChanged}
								selectedFilterDateRange={selectedFilterDateRange}
								setSelectedFilterDateRange={setSelectedFilterDateRange}
								resetCalendarDate={resetCalendarDate}
								setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
								callingFrom={'GlobalTask'}
								highlightRedirectedTask={highlightRedirectedTask}
								dueDateError={dueDateError}
								setDueDateError={setDueDateError}
								handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
								handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
							/>
						)}
					</>
				</TaskTabPanel>
			</Box>

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
			{openDeleteDialog && openDeleteDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('taskDeleteMessage')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog?.open}
				/>
			)}
		</Fragment>
	);
};
