/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import {
	Typography,
	FormControl,
	TextField,
	List,
	ListItem,
	ListItemText,
	Collapse,
	Button,
	ListItemAvatar,
	Avatar,
	Box,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import LinearLoader from '../Common/Loader';
import { getTime, getUserDetails, getUserName } from '../../config/utils';
import { REQUEST_TYPE_FEEDBACK } from '../../config/constant';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '../../config/svg/formElementIcons';

export default function ViewFeedback(props) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const userDetail = getUserDetails();
	const { objective, type, objId } = props;
	const { objectiveName, myGoalsDetails, keyDetails, okrViewKeyResults } = objective || {
		objectiveName: '',
		myGoalsDetails: [],
	};
	const [checked, setChecked] = useState(false);
	const [loader, setLoader] = useState(true);
	const [showCommentForm, setShowCommentForm] = useState(false);
	const [feedbackData, setFeedbackData] = useState([]);
	const [feedbackDetailId, setFeedbackDetailId] = useState(
		props.feedbackDetailId > 0 ? parseInt(props.feedbackDetailId) : 0
	);
	const [formData, setFormData] = useState({
		message: '',
		submit: false,
	});

	const feedbackOnTypeId = keyDetails
		? objective.feedbackOnTypeId
		: type === 'okr'
		? 1
		: type === 'okr' && objective.objectiveType && objective.objectiveType === 1
		? 1
		: 2;
	// if (type === 'okr' && objective.objectiveType && objective.objectiveType === 2) {
	// 	feedbackOnTypeId = REQUEST_TYPE_KR;
	// }
	const typeOnId = keyDetails ? objective.feedbackOnId : objId;

	const keysDetail = keyDetails || myGoalsDetails || okrViewKeyResults || [];

	const setMessageValue = (event) => {
		props.setFormDataUpdated(true);
		event.stopPropagation();
		setFormData({ ...formData, message: event.target.value });
	};
	const showForm = (e) => {
		e.stopPropagation();
		setShowCommentForm(true);
	};

	const submitComment = (e, type) => {
		e.preventDefault();
		setFormData({ ...formData, submit: true });
		if (type === 2) {
			setShowCommentForm(false);
			resetFormData();
			props.setFormDataUpdated(false);
		} else {
			if (formData.message.trim() !== '') {
				submitFeedbackComment();
			}
		}
	};

	const submitFeedbackComment = () => {
		const commentData = {
			comments: formData.message,
			feedbackDetailId: feedbackDetailId,
			parentCommentId: 0,
		};
		props
			.commentFeedback(commentData)
			.then((response) => {
				if (response && response.status === 200) {
					setShowCommentForm(false);
					resetFormData();
					//=== fetch latest comment
					let data = `${feedbackOnTypeId}/${typeOnId}`;
					fetchFeedback(data);
					enqueueSnackbar(t('commentAddedSuccess'), {
						variant: 'success',
						autoHideDuration: 3000,
					});
					props.setFormDataUpdated(false);
				}
			})
			.catch((err) => {
				enqueueSnackbar(t('errorAddingComment'), {
					variant: 'error',
					autoHideDuration: 3000,
				});
			});
	};

	const handleChange = (e) => {
		e.stopPropagation();
		setChecked((prev) => !prev);
	};

	useEffect(() => {
		setLoader(true);
		let data = `${feedbackOnTypeId}/${typeOnId}`;
		fetchFeedback(data);
	}, []);

	const fetchFeedback = (data) => {
		props
			.fetchFeedback(data)
			.then((response) => {
				if (response.status === 200 && response.data.entity) {
					setLoader(false);
					setFeedbackData(response.data.entity);
					const data = `EmployeeId=${userDetail.employeeId}`;
					props.userUnreadNotification(data);
				}
			})
			.catch((err) => {
				setLoader(false);
				console.error(err);
			});
	};

	const handleAnimation = (e, fdId) => {
		e.stopPropagation();
		if (feedbackDetailId !== fdId || feedbackDetailId === 0) {
			setFeedbackDetailId(fdId);
			resetFormData();
		}
	};

	const resetFormData = () => {
		setFormData({
			...formData,
			message: '',
			submit: false,
		});
	};

	const handleEvent = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const givenByDetail = (feedback) => {
		return (
			<ListItem alignItems='flex-start'>
				<ListItemAvatar>
					{feedback.imagePath ? <Avatar src={feedback.imagePath} /> : <Avatar>{getUserName(feedback)}</Avatar>}
				</ListItemAvatar>
				<ListItemText
					primary={`${feedback.firstName} ${feedback.lastName}`}
					secondary={
						<Fragment>
							<Typography component='small' variant='body2'>
								{getTime(feedback.createdOn)}
							</Typography>
							<Typography component='span'>{feedback.feedbackDetail}</Typography>
						</Fragment>
					}
				/>
			</ListItem>
		);
	};

	return (
		<Box className='drawer-area' onClick={handleEvent}>
			<Box className='drawer-head'>
				<Typography variant='h3'>{t('receivedReadFeedback')}</Typography>
			</Box>

			{loader && <LinearLoader data={props.commentFeedbackStatus === 'success'} />}
			<Box className='drawer-content' id='custom-scroll'>
				<Box className='drawer-inner-content'>
					<Box className='drawer-content-panel okr-drawer-form-area'>
						<Box className='okr-objective'>
							<Typography variant='h4' className={type === 'okr' ? 'active-obj' : ''}>
								{/* This is for Dashboard Listing */}
								{objective.assignmentTypeId && !objective.teamId && objective.assignmentTypeId !== 1
									? objectiveName
									: objective.keyDescription}

								{/* This is for teams okr card */}
								{objective.teamId > 0 && objectiveName}

								{/* This is for Alignment map */}
								{objective.objectiveType ? objective.name : ''}
							</Typography>
						</Box>
						{objective.teamId < 1 && (
							<Box className='feedback-keys okr-keys'>
								{(type === 'okr' || feedbackOnTypeId === 1) && objective.assignmentTypeId !== 1 ? (
									<Fragment>
										<Button
											variant='contained'
											className={checked ? 'btn-blue-chip expand' : 'btn-blue-chip'}
											onClick={handleChange}
										>
											{`${keysDetail.length} ${t('keyResults')}`} <RightArrowIcon />
										</Button>
										<Collapse in={checked}>
											<List>
												{keysDetail.map((item) => (
													<ListItem key={`krDesc${item.goalKeyId || item.krId}`}>
														<ListItemText primary={item.keyDescription || item.krName} />
													</ListItem>
												))}
											</List>
										</Collapse>
									</Fragment>
								) : (
									<List>
										<ListItem>
											<ListItemText
												className='active-key'
												primary={
													keysDetail.find((item) => item.goalKeyId === typeOnId)?.keyDescription ||
													keysDetail.find((item) => item.krId === typeOnId)?.krName
												}
											/>
										</ListItem>
									</List>
								)}
							</Box>
						)}
						<Box className='feedback-comments-area view-feedback-area '>
							{feedbackData.feedbackResponses && feedbackData.feedbackResponses.length > 0 ? (
								feedbackData.feedbackResponses.map((feedback) => (
									<Box
										className={
											feedbackDetailId === feedback.feedbackDetailId ? 'feedback-comments active' : 'feedback-comments'
										}
										key={`feedback${feedback.feedbackDetailId}`}
										onClick={(e) => handleAnimation(e, feedback.feedbackDetailId)}
									>
										<Box
											className={
												feedbackDetailId === feedback.feedbackDetailId
													? 'comments-zoom-out animate animate-fadeOut'
													: 'comments-zoom-out animate animate-fadeIn'
											}
										>
											<Box display='flex' justifyContent='space-between'>
												<Typography variant='body2'>
													Given By: {feedback.firstName} {feedback.lastName}
												</Typography>
												<Typography variant='body2'>{getTime(feedback.createdOn)}</Typography>
											</Box>
											<Typography>{feedback.feedbackDetail}</Typography>
										</Box>
										<Box
											className={
												feedbackDetailId === feedback.feedbackDetailId
													? 'comments-zoom-in animate animate-fadeIn'
													: 'comments-zoom-in animate animate-fadeOut'
											}
										>
											<List className='comments-list'>
												{feedback.askByRequestRemark && (
													<ListItem alignItems='flex-start'>
														<ListItemAvatar>
															{feedback.askByImagePath ? (
																<Avatar src={feedback.askByImagePath} />
															) : (
																<Avatar>
																	{getUserName({
																		firstName: feedback.askByFirstName,
																		lastName: feedback.askByLastName,
																	})}
																</Avatar>
															)}
														</ListItemAvatar>
														<ListItemText
															primary={`Requested By: ${feedback.askByFirstName} ${feedback.askByLastName}`}
															secondary={
																<Fragment>
																	<Typography component='small' variant='body2'>
																		{getTime(feedback.askByCreatedOn)}
																	</Typography>
																	<Typography component='span'>{feedback.askByRequestRemark}</Typography>
																</Fragment>
															}
														/>
													</ListItem>
												)}

												{givenByDetail(feedback)}

												<List className='comments-sub-list'>
													{feedback.feedbackComments.map((comment) => (
														<ListItem alignItems='flex-start' key={`comment${comment.commentId}`}>
															<ListItemAvatar>
																{comment.imagePath ? (
																	<Avatar src={comment.imagePath} />
																) : (
																	<Avatar>{getUserName(comment)}</Avatar>
																)}
															</ListItemAvatar>
															<ListItemText
																primary={`${comment.firstName} ${comment.lastName}`}
																secondary={
																	<Fragment>
																		<Typography component='small' variant='body2'>
																			{getTime(comment.createdOn)}
																		</Typography>
																		<Typography component='span'>{comment.comment}</Typography>
																	</Fragment>
																}
															/>
														</ListItem>
													))}
													{showCommentForm ? (
														<form
															className='okr-form-fields'
															onSubmit={(e) => submitComment(e, 1)}
															noValidate
															autoComplete='off'
														>
															<ListItem alignItems='flex-start'>
																<ListItemAvatar>
																	{userDetail.imagePath ? (
																		<Avatar src={userDetail.imagePath} />
																	) : (
																		<Avatar>{getUserName(userDetail)}</Avatar>
																	)}
																</ListItemAvatar>
																<ListItemText
																	primary={`${userDetail.firstName} ${userDetail.lastName}`}
																	secondary={
																		<Fragment>
																			<FormControl
																				className='feedback-message helperText-bottom'
																				variant='outlined'
																				style={{ width: '100%' }}
																			>
																				<TextField
																					placeholder={t('typeMessageHere')}
																					inputProps={{ 'aria-label': t('FeedbackViewAriaLabel') }}
																					onChange={setMessageValue}
																					error={formData.submit && formData.message.trim() === ''}
																					helperText={
																						formData.submit && formData.message.trim() === '' ? (
																							<>
																								<ErrorIcon />
																								{t('enterValidMessage')}
																							</>
																						) : (
																							''
																						)
																					}
																				/>
																			</FormControl>
																		</Fragment>
																	}
																/>
															</ListItem>
															<Box className='okr-form-action'>
																<Button
																	className='cancel-btn btn-link'
																	onClick={(e) => submitComment(e, 2)}
																	color='primary'
																>
																	{t('cancelBtn')}
																</Button>
																<Button className='submitBtn btn-primary' type='submit' color='primary'>
																	{t('send')}
																</Button>
															</Box>
														</form>
													) : (
														<Box className='okr-form-action'>
															<Button
																className='request-btn'
																color='primary'
																onClick={(e) =>
																	props.handleRequestOnToOne(
																		e,
																		REQUEST_TYPE_FEEDBACK,
																		feedback.feedbackDetailId,
																		feedback.employeeId,
																		'',
																		1
																	)
																}
															>
																{t('requestOnetoOne')}
															</Button>

															<Button className='submitBtn btn-primary' onClick={(e) => showForm(e)}>
																{t('reply')}
															</Button>
														</Box>
													)}
												</List>
											</List>
										</Box>
									</Box>
								))
							) : !loader ? (
								<Typography>{t('noFeedbackAvailable')}</Typography>
							) : (
								<Typography></Typography>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
