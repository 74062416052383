import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../containers/Header';
import { ReportTabs } from './HeaderTabs';
import { useTranslation } from 'react-i18next';

export const ReportHeader: React.FC<any> = (props) => {
	const { tabSelected, setTabSelectedTeam } = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	// const [open, setOpen] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		if (tabSelected) {
			setAnchorEl(null);
			// setOpen(false);
		}
	}, [tabSelected]);
	useEffect(() => {
		document.body.classList.add('admin-body-class');
		document.body.classList.remove('user-body-class');
		return () => {
			document.body.classList.remove('admin-body-class');
		};
	}, [history.location]);
	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});
	const scrollPageHeader = (e: any) => {
		const objElement = document.getElementById('page-report-subheader');
		if (window.pageYOffset >= 88) {
			objElement?.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement?.classList.remove('sticky-header');
		}
	};
	return (
		<Box className='page-subheader' id='page-report-subheader'>
			<Box className='page-subheader-inner'>
				<Box className='page-subheader-left'>
					<Box className='page-subheader-title'>
						<Typography variant='h2'>{t('reportsLabel')}</Typography>
					</Box>
					<ReportTabs
						tabSelected={props.tabSelected}
						handleTabChange={props.handleTabChange}
						setTabSelectedTeam={setTabSelectedTeam}
						{...props}
					/>
				</Box>
				<Box className='page-subheader-right'>
					<Header {...props} fromTab={true} />
				</Box>
			</Box>
		</Box>
	);
};
