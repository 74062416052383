import React from 'react';
import { Box, Button, ClickAwayListener, ListItemText, MenuItem, MenuList, Popper } from '@material-ui/core';
import { CrossIcon } from '../../../config/svg/CommonSvgIcon';

export const UserSearchListPopover: React.FC<any> = (props) => {
	const { open, anchorEl, setOpen, setAnchorEl, searchTextArray, clearSearch } = props;
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleListKeyDown = (event: any) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	return (
		<Popper className='user-search-list-popover' open={open} anchorEl={anchorEl} transition placement='bottom-end'>
			<ClickAwayListener onClickAway={handleClose}>
				<Box className='' onKeyDown={handleListKeyDown}>
					{searchTextArray &&
						searchTextArray.map((user: any, idx: any) => {
							if (idx >= 3) {
								return (
									<MenuList className='search-details-list'>
										<MenuItem key={`filter`}>
											<ListItemText>{user}</ListItemText>
											<Button
												onClick={() => {
													handleClose();
													clearSearch(user);
												}}
											>
												<CrossIcon />
											</Button>
										</MenuItem>
									</MenuList>
								);
							}
						})}
				</Box>
			</ClickAwayListener>
		</Popper>
	);
};
