import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { FeedbackRightsSettings } from './FeedbackRightsSettings';
import { FeedbackResolutionSettings } from './FeedbackResolutionSettings';
import { FeedbackTypesSettings } from './FeedbackTypesSettings';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { EmailRecipientSetting } from '../Common/EmailRecipientSetting';
import { EnumEmailModule, EnumEmailRecipients, Enums } from '../../../../../config/enums';
import {
	deleteFeedbackStatus,
	getFeedbackSetting,
	insertFeedbackStatus,
	updateFeedbackSetting,
	updateFeedbackStatus,
	updateFeedbackStatusEnable,
	getFeedbackStatus,
	getEmailRecipient,
	updateEmailRecipient,
} from '../../../../../action/adminPerformanceDevelopment';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';
import AlertDialog from '../../../../Common/Dialog';
import { getOkrMasterData } from '../../../../../action/myGoal';

export const AdminPerformanceFeedback: React.FC<any> = (props: any) => {
	const { loader, setLoader } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	const [feedbackSettingDetails, setFeedbackSettingDetails] = useState<any>({
		allowAllUsersCreateFeedback: true,
	});
	const [feedbackStatusDetails, setFeedbackStatusDetails] = useState<any>(null);
	// const [performancePlanEmailConfDetails, setPerformancePlanEmailConfDetails] = useState<any>(null);
	const [isFeedbackStatusUpdated, setIsFeedbackStatusUpdated] = useState<boolean>(false);
	const [twoFactorModalProps, setTwoFactorModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });
	const [modalFeedbackProps, setModalFeedbackProps] = useState<any>({
		open: false,
		details: null,
		type: '',
		message: '',
		isCancel: false,
	});

	useEffect(() => {
		dispatch(getOkrMasterData());
		renderDefaultAdminFeedbackData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const showMsg = (variant: any, messages: any) => {
		enqueueSnackbar(messages || `Error occurred while fetching comments`, {
			variant: variant || 'success',
			autoHideDuration: 3000,
		});
	};

	const renderDefaultAdminFeedbackData = async () => {
		try {
			setLoader(true);
			const emailRecipientRequestParams = `emailModuleId=${EnumEmailModule.Feedback}`;
			Promise.allSettled([dispatch(getFeedbackSetting()), dispatch(getFeedbackStatus())])
				.then((response: any) => {
					if (response) {
						const performancePlanSetting =
							Boolean(response) && response.length && response[0] && response[0].value ? response[0].value : null;
						const performancePlanStatus =
							Boolean(response) && response.length && response[1] && response[1].value ? response[1].value : null;

						if (Boolean(performancePlanSetting) && performancePlanSetting?.data?.status === Enums.STATUS_SUCCESS) {
							const { entity } = performancePlanSetting?.data || {};
							const settingDetails = entity
								? {
										...entity,
										allowReviewersToEditFeedbackHistory: entity?.allowReviewersToEditFeedback || false,
										isFeedbackVisibleToRMHistory: entity?.isFeedbackVisibleToRM || false,
										isPastDateFeedbackCreationAllow: entity?.isPastDateFeedbackCreationAllow || false,
										allowAllUsersCreateFeedback: entity?.allowAllUsersCreateFeedback || false,
								  }
								: null;
							setFeedbackSettingDetails(settingDetails);
						}
						if (Boolean(performancePlanStatus) && performancePlanStatus?.data?.status === Enums.STATUS_SUCCESS) {
							const { entityList } = performancePlanStatus?.data || {};
							let updatedFeedbackStatusDetails: any[] = entityList && entityList?.length ? [...entityList] : [];
							updatedFeedbackStatusDetails = updatedFeedbackStatusDetails
								.filter((item: any) => item?.isResolvedStatus)
								.map((item: any, index: any) => {
									const data = { ...item };
									data.historyName = data.name;
									return data;
								});
							setFeedbackStatusDetails(updatedFeedbackStatusDetails || []);
						}
						setLoader(false);
					}
				})
				.catch((error) => {
					console.error(error);
					setFeedbackSettingDetails(null);
					setFeedbackStatusDetails([]);
					setLoader(false);
				});
		} catch (error) {
			console.error(error);
			setFeedbackSettingDetails(null);
			setFeedbackStatusDetails([]);
			setLoader(false);
		}
	};

	const updateFeedbackSettingAPICall = async () => {
		try {
			const requestParams = feedbackSettingDetails ? { ...feedbackSettingDetails } : null;
			if (requestParams) {
				setLoader(true);
				delete requestParams.allowReviewersToEditFeedbackHistory;
				delete requestParams.isFeedbackVisibleToRMHistory;
				const response: any = await dispatch(updateFeedbackSetting(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('success', messages || `Feedback rights settings updated successfully.`);
					const settingDetails = requestParams
						? {
								...requestParams,
								allowReviewersToEditFeedbackHistory: requestParams?.allowReviewersToEditFeedback || false,
								isFeedbackVisibleToRMHistory: requestParams?.isFeedbackVisibleToRM || false,
								isPastDateFeedbackCreationAllow: requestParams?.isPastDateFeedbackCreationAllow || false,
								allowAllUsersCreateFeedback: requestParams?.allowAllUsersCreateFeedback || false,
						  }
						: null;
					setFeedbackSettingDetails(settingDetails);
					setIsFeedbackStatusUpdated(false);
					setLoader(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Some error occurred`);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const resetFeedbackSetting = () => {
		try {
			const settingDetails = feedbackSettingDetails
				? {
						...feedbackSettingDetails,
						allowReviewersToEditFeedback: feedbackSettingDetails?.allowReviewersToEditFeedbackHistory || false,
						isFeedbackVisibleToRM: feedbackSettingDetails?.isFeedbackVisibleToRMHistory || false,
						isPastDateFeedbackCreationAllow: feedbackSettingDetails?.isPastDateFeedbackCreationAllow || false,
						allowAllUsersCreateFeedback: feedbackSettingDetails?.allowAllUsersCreateFeedback || false,
				  }
				: null;
			setFeedbackSettingDetails(settingDetails);
			setIsFeedbackStatusUpdated(false);
		} catch (error) {
			console.error(error);
		}
	};
	// ================================================
	// ================================================// ================================================
	// ================================================
	const handleStatusNameChangeClick = (e: any, statusItem: any, statusItemIndex: any) => {
		const { value } = e?.target || {};
		const updatedFeedbackStatusDetails: any[] =
			feedbackStatusDetails && feedbackStatusDetails?.length ? [...feedbackStatusDetails] : [];
		updatedFeedbackStatusDetails[statusItemIndex].name = value;
		setFeedbackStatusDetails(updatedFeedbackStatusDetails);
	};
	const createNewResolution = (e: any) => {
		// setIsCreateResolution(true);
		const updatedFeedbackStatusDetails: any[] =
			feedbackStatusDetails && feedbackStatusDetails?.length ? [...feedbackStatusDetails] : [];
		const newStatusInsert = {
			feedbackStatusId: Enums.ZERO,
			name: '',
			historyName: '',
			isDefault: false,
			isEnabled: true,
			isEditResolution: false,
			isCreateResolution: true,
		};
		updatedFeedbackStatusDetails.unshift(newStatusInsert);
		setFeedbackStatusDetails(updatedFeedbackStatusDetails);
	};
	const insertEditResolution = async (e: any, statusItem: any, statusItemIndex: any) => {
		try {
			if (statusItem?.name && statusItem.name.trim() !== '') {
				setLoader(true);
				const requestParams = {
					feedbackStatusId: statusItem?.feedbackStatusId || Enums.ZERO,
					name: statusItem?.name || '',
					description: statusItem?.name || '',
				};
				const response: any = await dispatch(insertFeedbackStatus(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('success', messages || `Resolution settings status created successfully.`);

					renderDefaultAdminFeedbackData();
					// setLoader(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Some error occurred`);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const cancelCreateResolution = (e: any, statusItem: any, statusItemIndex: number) => {
		e.preventDefault();
		// setIsCreateResolution(false);
		let updatedFeedbackStatusDetails: any[] =
			feedbackStatusDetails && feedbackStatusDetails?.length ? [...feedbackStatusDetails] : [];
		updatedFeedbackStatusDetails = updatedFeedbackStatusDetails.filter(
			(item: any, index: number) => index !== statusItemIndex
		);
		// updatedFeedbackStatusDetails[statusItemIndex].isCreateResolution = false;
		setFeedbackStatusDetails(updatedFeedbackStatusDetails);
	};
	const editResolution = (e: any, statusItem: any, statusItemIndex: any) => {
		const updatedFeedbackStatusDetails: any[] =
			feedbackStatusDetails && feedbackStatusDetails?.length ? [...feedbackStatusDetails] : [];
		updatedFeedbackStatusDetails[statusItemIndex].isEditResolution = true;
		setFeedbackStatusDetails(updatedFeedbackStatusDetails);
	};
	const handleStatusChange = async (e: any, statusItem: any, statusItemIndex: any, fullList: any) => {
		try {
			if (statusItem?.feedbackStatusId) {
				const { checked } = e?.target || {};
				let isAPICall: boolean = false;
				if (!checked) {
					const updatedFullList = [...fullList].map((rec: any) => {
						const data = { ...rec };
						return data;
					});
					updatedFullList[statusItemIndex].isEnabled = false;
					const activeStatusList = updatedFullList?.filter((rec: any) => rec?.isEnabled);
					if (!activeStatusList?.length) {
						setModalFeedbackProps({
							open: true,
							type: 'resolutionSettings',
							message: 'At least 1 Resolve status needs to be active.',
							isCancel: true,
						});
					} else {
						isAPICall = true;
					}
				} else {
					isAPICall = true;
				}
				if (isAPICall) {
					setLoader(true);
					const requestParams = {
						feedbackStatusId: statusItem?.feedbackStatusId || Enums.ZERO,
						isEnabled: checked || false,
					};
					const response: any = await dispatch(updateFeedbackStatusEnable(requestParams));
					if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
						const { messageList } = response?.data || {};
						const keys = messageList ? Object.keys(messageList) : [];
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						showMsg('success', messages || `Resolution settings status updated successfully.`);

						renderDefaultAdminFeedbackData();
						// setLoader(false);
					} else {
						const { messageList } = response?.data || {};
						const keys = messageList ? Object.keys(messageList) : [];
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						showMsg('error', messages || `Some error occurred`);
						setLoader(false);
					}
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const updateEditResolution = async (e: any, statusItem: any, statusItemIndex: any) => {
		try {
			if (statusItem?.feedbackStatusId) {
				setLoader(true);
				const requestParams = {
					feedbackStatusId: statusItem?.feedbackStatusId || Enums.ZERO,
					name: statusItem?.name || '',
					description: statusItem?.name || '',
				};
				const response: any = await dispatch(updateFeedbackStatus(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('success', messages || `Resolution settings status updated successfully.`);

					renderDefaultAdminFeedbackData();
					// setLoader(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Some error occurred`);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const cancelEditResolution = (e: any, statusItem: any, statusItemIndex: any) => {
		e.preventDefault();
		const updatedFeedbackStatusDetails: any[] =
			feedbackStatusDetails && feedbackStatusDetails?.length ? [...feedbackStatusDetails] : [];
		updatedFeedbackStatusDetails[statusItemIndex].name = updatedFeedbackStatusDetails[statusItemIndex].historyName;
		updatedFeedbackStatusDetails[statusItemIndex].isEditResolution = false;
		// updatedFeedbackStatusDetails.map((item: any, index: any) => {
		// 	const data = { ...item };
		// 	data.isEditResolution = false;
		// 	return data;
		// });
		setFeedbackStatusDetails(updatedFeedbackStatusDetails);
	};
	const deletePerformancePlanStatusApiCall = async (statusItem: any, statusItemIndex: number) => {
		try {
			if (statusItem?.feedbackStatusId) {
				setLoader(true);
				const requestParams = `feedbackStatusId=${statusItem?.feedbackStatusId}`;
				const response: any = await dispatch(deleteFeedbackStatus(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('success', messages || `Resolution settings status deleted successfully.`);

					renderDefaultAdminFeedbackData();
					// setLoader(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Some error occurred`);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleDeleteResolution = (e: any, statusItem: any, statusItemIndex: any) => {
		if (statusItem?.feedbackStatusId) {
			// setModalProps({
			// 	open: true,
			// 	event: null,
			// 	details: { statusItem, statusItemIndex },
			// 	type: 'DeleteResolution',
			// 	message: t('Are you sure you want to delete this performance improvement plan resolution status?'),
			// });
			setTwoFactorModalProps({
				open: true,
				message: {
					headingText: '',
					subHeading: t('pipFeedbackDeletionText'),
				},
				details: { statusItem, statusItemIndex },
			});
		} else {
			let updatedFeedbackStatusDetails =
				feedbackStatusDetails && feedbackStatusDetails?.length ? [...feedbackStatusDetails] : [];
			updatedFeedbackStatusDetails?.filter((item: any, index: number) => index !== statusItemIndex);
			setFeedbackStatusDetails([...updatedFeedbackStatusDetails]);
		}
	};
	const handleTwoFactorCloseModal = async (e: any, type: any) => {
		if (type === 1) {
			const { statusItem, statusItemIndex } = twoFactorModalProps?.details || {};
			setTwoFactorModalProps({ open: false, message: '', details: '' });
			deletePerformancePlanStatusApiCall(statusItem, statusItemIndex);
		} else {
			setTwoFactorModalProps({ open: false, message: '', details: '' });
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'DeleteResolution') {
				const { statusItem, statusItemIndex } = modalProps?.details || {};
				deletePerformancePlanStatusApiCall(statusItem, statusItemIndex);
			}
		}
		setModalProps({ open: false, event: null, message: '' });
	};
	const handleCloseFeedbackModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
		} else if (type === 2) {
			if (modalFeedbackProps?.type === 'resolutionSettings') {
				// renderDefaultAdminFeedbackData();
				setModalFeedbackProps({ open: false, type: '', message: '', details: null, isCancel: false });
			}
		}
	};
	//HERE
	return (
		<>
			<Box className='performance-development-settings'>
				<EmailRecipientSetting
					t={t}
					loader={loader}
					messageNote={t('sendUpdateEmailsToNoteFeedback')}
					emailModuleId={EnumEmailModule.Feedback}
					setLoader={setLoader}
				/>
				<FeedbackTypesSettings {...props} t={t} />
				<FeedbackResolutionSettings
					t={t}
					loader={loader}
					feedbackStatusDetails={feedbackStatusDetails}
					createNewResolution={createNewResolution}
					insertEditResolution={insertEditResolution}
					cancelCreateResolution={cancelCreateResolution}
					updateEditResolution={updateEditResolution}
					editResolution={editResolution}
					cancelEditResolution={cancelEditResolution}
					handleDeleteResolution={handleDeleteResolution}
					handleStatusNameChangeClick={handleStatusNameChangeClick}
					handleStatusChange={handleStatusChange}
				/>
				<FeedbackRightsSettings
					t={t}
					loader={loader}
					feedbackSettingDetails={feedbackSettingDetails}
					setFeedbackSettingDetails={setFeedbackSettingDetails}
					updateFeedbackSettingAPICall={updateFeedbackSettingAPICall}
					resetFeedbackSetting={resetFeedbackSetting}
					isFeedbackStatusUpdated={isFeedbackStatusUpdated}
					setIsFeedbackStatusUpdated={setIsFeedbackStatusUpdated}
				/>
			</Box>

			{twoFactorModalProps && twoFactorModalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={twoFactorModalProps.open}
					handleCloseModal={(e: any) => handleTwoFactorCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleTwoFactorCloseModal(e, 1)}
					headingText={twoFactorModalProps?.message?.headingText}
					subHeading={twoFactorModalProps?.message?.subHeading}
				/>
			)}
			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
			{modalFeedbackProps?.open && (
				<AlertDialog
					message={modalFeedbackProps?.message || ''}
					handleCloseModal={handleCloseFeedbackModal}
					modalOpen={modalFeedbackProps.open}
					isCancel={modalFeedbackProps.isCancel}
				/>
			)}
		</>
	);
};
