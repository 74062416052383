import React from 'react';
import { NoSearchRecord } from '../../CommonComponent/NoSearchRecord';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import { UserIcon } from '../../../../config/svg/CommonSvgIcon';
import { ErrorIcon, RoleIcon } from '../../../../config/svg/formElementIcons';
import { OKRButton } from '../../../Common/OKRButton';
import '../../../../styles/pages/admin/bulkUserEdit.scss';

export const ChangeBulkRole: React.FC<any> = (props) => {
	const { setOpenChangeRole, checkedUser, t, selectedRole, setSelectedRole, rolesOptions, confirmUpdate } = props;
	return (
		<Box className='bulk-user-popover'>
			<Box className='connnector-icon'></Box>
			<ClickAwayListener onClickAway={() => setOpenChangeRole(false)}>
				<Box className='change-role-popup'>
					<Box className='bulk-user-popup-head'>
						<Typography variant='h3'>{t('changeRoleLabel')}</Typography>
					</Box>
					<Box className='bulk-user-popup-content'>
						<Box className='selected-user-message'>
							<ErrorIcon />
							<Typography variant='body2'>{t('selectedUsersMessage')}</Typography>
						</Box>
						<Box className='user-selected-info'>
							<UserIcon />
							<Typography>
								{checkedUser && checkedUser.length} {`${t('RoleListUsers')} ${t('selectedLabel')}`}
							</Typography>
						</Box>
						<Box className='user-role-dropdown'>
							<Box className='select-user-role'>
								<RoleIcon />
								<Typography>{t('newRoleLabel')}</Typography>
							</Box>
							<MultiSelect
								key={'rolesIdDropdown'}
								id={'change-role-dropdown'}
								selectedOptions={selectedRole}
								onSelectOption={(value: any) => {
									setSelectedRole(value);
								}}
								optionsList={rolesOptions}
								customFilter={(option: any, searchText: any) => {
									if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
										return true;
									}
								}}
								placeHolder={t('assignRoleLabel')}
								noOptionsMessage={<NoSearchRecord />}
								fetchAsyncData={false}
								isSingleSelection={false}
								performSearch={() => {}}
								closeMenuOnSelect={true}
								labelTemplate={'onlyLabel'}
								isMulti={false}
								selectClassName={'user-role-dropdown'}
								selectClassNamePrefix={'user-role'}
								disableSearch={true}
							/>
						</Box>
					</Box>
					<Box className='bulk-user-popup-actions'>
						<OKRButton className='btn-link' text={t('cancelBtn')} handleClick={() => setOpenChangeRole(false)} />
						<OKRButton
							disabled={selectedRole[0].roleId <= 0}
							className='btn-primary'
							text={t('update')}
							handleClick={() => (selectedRole ? confirmUpdate(selectedRole) : {})}
						/>
					</Box>
				</Box>
			</ClickAwayListener>
		</Box>
	);
};
