import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Chip, Link, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import '../../../../styles/pages/performance-development/pipList.scss';
import { AttachmentIcon, CalendarIcon, LoadMoreIcon } from '../../../../config/svg/CommonSvgIcon';
import { UserAvatarGroup } from '../../../Common/UserAvatarGroup';
import { OKRButton } from '../../../Common/OKRButton';
import { AlertInfoIcon, CommentIcon } from '../../../../config/svg/CommonSvg';
import { getDateMonthTime, getMonthDateYear, getMonthDateYearTime, getUserName } from '../../../../config/utils';
import { FeedbackSkeleton } from './FeedbackSkeleton';
import { Enums } from '../../../../config/enums';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import animatedTag from '../../../../images/tag_animation.svg';
import { useDispatch, useSelector } from 'react-redux';
import { highlightFeedbackId } from '../../../../action/performanceImprovementPlan';

export const FeedbackBlock = (props: any) => {
	const { performanceData, handlePIPItemClick, showLoadMore, loadMore } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { highlightedFeedbackId } = useSelector((state: any) => state?.performanceImprovementPlanReducer);
	const getReviewers = (reviewersList: any[]) => {
		let list = reviewersList && reviewersList.length > 0 ? reviewersList.slice(1) : [];
		return list;
	};
	useEffect(() => {
		if (highlightedFeedbackId) {
			setTimeout(() => {
				dispatch(highlightFeedbackId(0));
			}, 5000);
		}
	}, [highlightedFeedbackId]);
	return (
		<Box className='pip-list-area'>
			<Box className='pip-card-panel-list'>
				{performanceData && performanceData?.length ? (
					<>
						{performanceData?.map((item: any) => (
							<>
								<Box
									className={`pip-card-panel  ${
										item?.feedbackId === highlightedFeedbackId ? 'highlighted-pip-item' : ''
									}`}
								>
									<Box className='pip-card-header' onClick={(event: any) => handlePIPItemClick(event, item)}>
										<Box
											className={`user-info v-center ${!item.nomineeDetails.isActive ? 'terminated-user-info' : ''}`}
										>
											<Box className='user-img'>
												<Avatar
													className='avatar-default'
													key={`user-icon-${item.employeeId}`}
													src={item.nomineeDetails.imagePath || ''}
												>
													{getUserName({
														firstName: item.nomineeDetails.firstName || '',
														lastName: item.nomineeDetails.lastName || '',
														fullName: item.nomineeDetails.fullName || '',
													})}
												</Avatar>
											</Box>
											<Box className='user-info-details'>
												<Typography variant='h4' className='font-weight-normal'>
													{item.nomineeDetails.fullName}
												</Typography>
											</Box>
										</Box>
										{item?.feedbackTypeId !== 1 && item?.timeframeStartDate && item?.timeframeEndDate ? (
											<Box className='pip-status'>
												{/* Draft = 1, Submitted = 2, Active = 3, SuccessfullyCleared = 4, Terminate = 5 */}
												{item?.feedbackStatusId === Enums.ONE ? (
													<Chip className='draft-chip' label={item?.feedbackStatusName} />
												) : item?.feedbackStatusId === Enums.TWO ? (
													<Chip className='lagging-chip' label={item?.feedbackStatusName} />
												) : item?.feedbackStatusId === Enums.THREE ? (
													<Chip className='lagging-chip' label={item?.feedbackStatusName} />
												) : item?.feedbackStatusId === Enums.FOUR ? (
													<Box className='timeframe-animation'>
														<AnimatedIcon
															className=''
															width='480'
															type='image/svg+xml'
															alt={t('globalSearchNoRecord')}
															imagePath={animatedTag}
														/>
														<Chip className='on-track-chip' label={item?.feedbackStatusName} />
													</Box>
												) : item?.feedbackStatusId === Enums.FIVE ? (
													<Chip className='at-risk-chip' label={item?.feedbackStatusName} />
												) : (
													<Chip className='grey-chip' label={item?.feedbackStatusName} />
												)}
											</Box>
										) : (
											<></>
										)}
									</Box>
									<Box className='pip-card-content' onClick={(event: any) => handlePIPItemClick(event, item)}>
										<Box className='pip-feedback-list'>
											<List disablePadding>
												<ListItem>
													<Box className='pip-status feedback-type-chip'>
														<Chip className='review-chip' label={item.feedbackTypeName} />
													</Box>
													{item.title ? (
														<Box className='performance-dev-block-title'>
															<Tooltip
																title={item.title}
																arrow
																placement='bottom-start'
																classes={{ popper: 'tooltip-pipfeedbacktitle' }}
															>
																<Typography variant='h4'>{item.title}</Typography>
															</Tooltip>
														</Box>
													) : (
														<Typography className='no-title' variant='h4'>
															{t('pipNoTitleAdded')}
														</Typography>
													)}
													{item?.timeframeStartDate && item?.timeframeEndDate ? (
														<Box className='feedback-date-range'>
															{/* {item?.timeframeStartDate && item?.timeframeEndDate ? ( */}
															<Tooltip
																title={item?.isTimeFrameAlert ? t('timeFramePassed') : t('timeFrameText')}
																arrow
																classes={item?.isTimeFrameAlert ? { popper: 'tooltip-alert' } : {}}
															>
																<Typography variant='h6' className={item.isTimeFrameAlert ? 'alert-field' : ''}>
																	{item.isTimeFrameAlert && (
																		<span className='alert-icon'>
																			<AlertInfoIcon />
																		</span>
																	)}
																	<CalendarIcon />
																	{getMonthDateYear(item?.timeframeStartDate)} -{' '}
																	{getMonthDateYear(item?.timeframeEndDate)}
																</Typography>
															</Tooltip>
															{/* ) : (
																<></>
															)} */}

															{item.timeFrameChangedCount > 0 && (
																<Tooltip
																	classes={{ popper: 'timeframe-tooltip' }}
																	title={
																		<>
																			{t('timeFrameChangedOn')}
																			<List>
																				{item?.feedbackTimeframeResponse.map((extendedDate: any) => (
																					<>
																						<ListItem>
																							<span>
																								{extendedDate?.extendTimeframeChangeDate
																									? getDateMonthTime(extendedDate?.extendTimeframeChangeDate)
																									: ''}
																							</span>
																							<span>
																								{extendedDate?.extendTimeframeStartDate &&
																								extendedDate?.extendTimeframeEndDate ? (
																									`${getMonthDateYearTime(
																										extendedDate?.extendTimeframeStartDate
																									)} - ${getMonthDateYearTime(extendedDate?.extendTimeframeEndDate)}`
																								) : (
																									<></>
																								)}
																							</span>
																							<span>{extendedDate?.feedbackReasonName}</span>
																						</ListItem>
																					</>
																				))}
																			</List>
																		</>
																	}
																	arrow
																>
																	<Chip
																		className='grey-chip'
																		label={
																			item.timeFrameChangedCount === 1
																				? t('timeframeChanged')
																				: item.timeFrameChangedCount > 1
																				? t('Changed: ') + getMonthDateYearTime(item.extendTimeframeEndDate)
																				: ''
																		}
																	/>
																</Tooltip>
															)}
														</Box>
													) : item?.createdOn ? (
														<Box className='feedback-date-range'>
															<Tooltip title={t('createOnLabelNew')} arrow>
																<Typography variant='h6'>
																	<CalendarIcon />
																	{getMonthDateYear(item?.createdOn)}
																</Typography>
															</Tooltip>
															{/* {item.feedbackTypeId === 2 ? (
																<></>
															) : (
																<Typography variant='h6'>{t('pipNoTimeframeSet')}</Typography>
															)} */}
														</Box>
													) : (
														<></>
													)}
												</ListItem>
											</List>
										</Box>
									</Box>
									<Box className='pip-card-footer'>
										<Typography
											variant='body2'
											className='font-weight-normal'
											onClick={(event: any) => handlePIPItemClick(event, item)}
										>
											{t('reviewersLabel')}
										</Typography>
										<Box className='pip-card-footer-content'>
											<Box className='pip-card-footer-left'>
												<Box className='pip-reviewers-list'>
													<Box className='review-others'>
														<UserAvatarGroup
															{...props}
															contributorClassName={'contributor-details-popover'}
															isButtonVisible={false}
															handleAddUserClick={() => {}}
															contributorDetailsType={true}
															isOnHoverVisible={true}
															loginUserAvatar={
																item.reviewerDetails && item.reviewerDetails.length > 0 ? item.reviewerDetails[0] : {}
															}
															contributorDetails={getReviewers(item.reviewerDetails)}
															isUserPlaceholder={true}
															subTitle={' '}
															helpText={' '}
															max={5}
															//isUserAvatarClick={type !== 'Add' && editableDetails ? true : false}
															//handleUserAvatarClick={handleUserAvatarClick}
															isDisabled={true}
															viewType='pipReviewers'
															t={t}
														/>
													</Box>
												</Box>
											</Box>
											<Box className='pip-card-footer-right' onClick={(event: any) => handlePIPItemClick(event, item)}>
												{item.isCommentAlert ? (
													<Box className='comment-badge'>
														<OKRButton
															icon={<CommentIcon />}
															text={item.commentCount || '0'}
															title={t('totalComments')}
															buttonType={'hasBadge'}
														/>
													</Box>
												) : (
													<OKRButton
														icon={<CommentIcon />}
														text={item.commentCount || '0'}
														title={t('totalComments')}
													/>
												)}
												<OKRButton
													icon={<AttachmentIcon />}
													text={item.attachmentCount || '0'}
													title={t('attachedFiles')}
												/>
											</Box>
										</Box>
									</Box>
								</Box>
							</>
						))}
						{performanceData?.length < 3 && <FeedbackSkeleton />}
						{performanceData?.length < 2 && <FeedbackSkeleton />}
					</>
				) : (
					<></>
				)}
			</Box>
			{showLoadMore && (
				<Box className='load-more-box'>
					<OKRButton className='load-more-btn' icon={<LoadMoreIcon />} text={t('loadMore')} handleClick={loadMore} />
				</Box>
			)}
		</Box>
	);
};
