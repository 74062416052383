import React, { Fragment, useState } from 'react';
import { Route, useHistory } from 'react-router';
import { Switch } from 'react-router-dom';
import SignIn from '../containers/Guest/SignIn';
import ForgotPassword from '../containers/Guest/ForgotPassword';
import MyGoal from '../containers/MyGoal';
import TeamView from '../containers/TeamView';
import Header from '../containers/Header';
import AdminHeader from '../containers/AdminHeader';
import Dashboard from '../containers/Dashboard';
import Admin from '../containers/Admin';
import ChatBot from '../containers/ChatBot';
import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
	clearLocalStorage,
} from '../services/StorageService';
import {
	ADMIN,
	HOME,
	USER_ROUTES,
	ADMIN_ROUTES,
	MY_GOAL,
	USER_VIEW,
	PRIVACY_POLICY,
	TERMS_OF_USE,
	SECRET_LOGIN,
	INSIGHT,
	VALID_ROUTES,
	REPORTS,
	TEAM_VIEW,
	FEEDBACK,
	ENGAGE,
	DASHBOARD,
	PERFORM,
	RECOGNIZE,
	LETTER_GEN,
	GLOBAL_Task,
	PERFORM_DEVELOPMENT,
	GOAL_REVIEW,
} from './app-url';
import {
	updateRoute,
	changeCycleId,
	currentDashboardTab,
	setNomineeDetailsForRedirection,
	setPerformanceGoalForRedirection,
	currentDashboardTabForSO,
	setEmployeeDetailsForRedirection,
} from '../action/common';
import store from '../store/configureStore';
import Reports from '../containers/Reports';
import Insight from '../containers/Insight';
import ResetPassword from '../containers/Guest/ResetPassword';
import CustomLogin from '../containers/Guest/CustomDomain';
import UnauthorizedAccess from '../containers/Guest/UnauthorizedAccess';
import SsoLogin from '../containers/SsoLogin';
import Forbidden from '../containers/Forbidden';
import { InternalServerError } from '../components/InternalServerError';
import { UnderMaintainence } from '../components/UnderMaintainence';
import { toLower } from 'lodash';
import { getParameterByName, GAevent, GApageView, isTrialVersion } from './utils';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsOfUse from '../components/TermsOfUse';
import Logout from '../components/Logout';
import { resetOkr } from '../action/myGoal';
import { useLocation } from 'react-router-dom';
import FreeTrial from '../containers/Guest/FreeTrial';
import Feedback from '../components/Engagement/Feedback';
import { Engagement } from '../components/Engagement';
import Home from '../components/HomePage';
import { Perform } from '../components/Perform';
import { Recognize } from '../components/Recognize';
import LetterGen from '../components/LetterGen/LetterGen';
import { GlobalTask } from '../components/GlobalTask';
import { UserPerformance } from '../components/UserPerformance';
import { PerformanceDevelopment } from '../components/PerformanceDevelopment';
import { setPipImprovementData, setFeedbackImprovementData } from '../action/performanceImprovementPlan';
import { GoalsReview } from '../components/GoalsReview';
// import { GApageView } from './utils';

export default function AppRoutes(props) {
	const history = useHistory();
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const [loginAs, setLoginAs] = useState(
		userDetail && userDetail.loggedInAs && userDetail.loggedInAs === 'Admin' ? 'admin' : 'user'
	);

	// useEffect(() => {
	// process.env.REACT_APP_ENV === 'trial' && GApageView(window.location.pathname + window.location.search);
	// }, [location.pathname]);
	// useEffect(() => {
	// GApageView(window.location.pathname + window.location.search);
	// }, [location.pathname]);
	const switchUser = (e, type, url) => {
		e.preventDefault();
		removeLocalStorageItem('currentUser');
		store.dispatch(resetOkr());

		if (type === 'admin') {
			setLocalStorageItem('LoginAs', 'admin');
			setLoginAs('admin');
			if (url === REPORTS) {
				isTrialVersion() && GAevent('Reports', 'Clicked Reports Section', 'Reports');
			}
			history.push(url);
			store.dispatch(updateRoute(url));
		} else if (type === 'user') {
			setLocalStorageItem('LoginAs', 'user');
			setLoginAs('user');
			if (url === REPORTS) {
				isTrialVersion() && GAevent('Reports', 'Clicked Reports Section', 'Reports');
			}
			store.dispatch(updateRoute(url));
			history.push(url);
		}
	};

	const setRedirectDetails = (url, empId, cycleId) => {
		removeLocalStorageItem('currentUser');
		//==== if user is accessing page
		//==== user is not autorized then
		const urlItem = url.split('/');
		const urlType = Boolean(urlItem[0]) ? toLower(urlItem[0]) : '';
		if (urlType === 'feedback') {
			const type = Boolean(urlItem[1]) ? urlItem[1] : 0;
			const id = Boolean(urlItem[2]) ? urlItem[2] : 0;
			const notificationsBy = Boolean(urlItem[3]) ? urlItem[3] : 0;
			if (type && type === '3') {
				const employeeId = Boolean(urlItem[4]) ? urlItem[4] : 0;
				if (employeeId === empId) {
					//if request one to one Done on other's OKR
					setLocalStorageItem(
						'redirectUrl',
						JSON.stringify({
							url: `RequestOneOnOne/${id}`,
						})
					);
				} else {
					//if request one to one Done on self OKR
					setLocalStorageItem(
						'redirectUrl',
						JSON.stringify({
							url: USER_VIEW,
							objectiveId: id,
							goalType: notificationsBy,
							employeeId: employeeId,
							cycleId: cycleId,
						})
					);
				}
			} else {
				setLocalStorageItem(
					'redirectUrl',
					JSON.stringify({
						url: `Feedback/${type}/${id}`,
						notificationsBy: notificationsBy,
					})
				);
			}
			setLocalStorageItem('empId', empId);
		} else if (urlType === 'contributor') {
			const goalId = Boolean(urlItem[1]) ? urlItem[1] : 0;
			setLocalStorageItem(
				'redirectUrl',
				JSON.stringify({
					url: `Contributors/${goalId}`,
				})
			);
			setLocalStorageItem('empId', empId);
		} else if (urlType === 'organization') {
			const parentId = Boolean(urlItem[1]) ? urlItem[1] : 0;
			const orgId = Boolean(urlItem[2]) ? urlItem[2] : 0;
			setLocalStorageItem(
				'redirectUrl',
				JSON.stringify({
					url: `Organization/${parentId}/${orgId}`,
				})
			);
		} else if (urlType === 'comments') {
			const type = Boolean(urlItem[1]) ? urlItem[1] : 0;
			const id = Boolean(urlItem[2]) ? urlItem[2] : 0;
			const feedbackDetailId = Boolean(urlItem[3]) ? urlItem[3] : 0;
			setLocalStorageItem(
				'redirectUrl',
				JSON.stringify({
					url: `Comments/${type}/${id}/${feedbackDetailId}`,
				})
			);
			setLocalStorageItem('empId', empId);
		} else if (urlType === 'personalfeedback') {
			const type = Boolean(urlItem[1]) ? parseInt(urlItem[1]) : 0;
			const id = Boolean(urlItem[2]) ? parseInt(urlItem[2]) : 0;
			if (type && (type === 1 || type === 2 || type === 4)) {
				setLocalStorageItem(
					'redirectUrl',
					JSON.stringify({
						url: `PersonalFeedback/${type}/${id}`,
					})
				);
				setLocalStorageItem('empId', empId);
			}
		} else if (urlType === 'krassignment') {
			const krId = urlItem[2] ? parseInt(urlItem[2]) : 0;
			const okrId = urlItem[3] ? parseInt(urlItem[3]) : 0;
			const acceptDecline = urlItem[4] ? parseInt(urlItem[4]) : 0;
			const status = acceptDecline === 1 ? 'accept' : acceptDecline === 2 ? 'decline' : '';
			if (krId > 0) {
				removeLocalStorageItem('currentUser');
				setLocalStorageItem(
					'redirectUrl',
					JSON.stringify({
						url: `krAssignmentOpenDrawer/${krId}/${okrId}/${status}`,
					})
				);
			}
			setLocalStorageItem('empId', empId);
		} else if (urlType === 'kracceptdecline') {
			const type = Boolean(urlItem[1]) ? parseInt(urlItem[1]) : 0;
			const krId = Boolean(urlItem[2]) ? parseInt(urlItem[2]) : 0;
			const objId = Boolean(urlItem[3]) ? parseInt(urlItem[3]) : 0;
			setLocalStorageItem(
				'redirectUrl',
				JSON.stringify({
					url: `KRAcceptDecline/${type}/${krId}/${objId}`,
				})
			);
			setLocalStorageItem('empId', empId);
		} else if (urlType === 'unlockaccount') {
			//case when user is locked
			setLocalStorageItem(
				'redirectUrl',
				JSON.stringify({
					url: 'unlockaccount/unlockaccount',
					lockedUser: true,
				})
			);
		} else if (urlType === 'notes') {
			const noteId = urlItem[1] ? parseInt(urlItem[1]) : 0;
			const goalType = urlItem[2] ? parseInt(urlItem[2]) : 0;
			const goalId = urlItem[3] ? parseInt(urlItem[3]) : 0;
			const employeeId = urlItem[4] ? parseInt(urlItem[4]) : 0;
			if (noteId > 0 && employeeId > 0) {
				// removeLocalStorageItem('currentUser');
				setLocalStorageItem(
					'redirectUrl',
					JSON.stringify({
						url: url,
						noteId: noteId,
						goalType: goalType,
						goalId: goalId,
						employeeId: employeeId,
					})
				);
			}
			setLocalStorageItem('empId', empId);
		} else if (urlType === 'conversations') {
			const conversationId = urlItem[1] ? parseInt(urlItem[1]) : 0;
			const goalType = urlItem[2] ? parseInt(urlItem[2]) : 0;
			const goalId = urlItem[3] ? parseInt(urlItem[3]) : 0;
			const employeeId = urlItem[4] ? parseInt(urlItem[4]) : 0;
			if (conversationId > 0 && employeeId > 0) {
				setLocalStorageItem(
					'redirectUrl',
					JSON.stringify({
						url: url,
						conversationId: conversationId,
						goalType: goalType,
						goalId: goalId,
						employeeId: employeeId,
						cycleId: cycleId,
					})
				);
			}
			// setLocalStorageItem('empId', empId);
		} else if (urlType === 'performance-goal') {
			setLocalStorageItem('redirectUrl', JSON.stringify({ url: url }));
			setLocalStorageItem('empId', empId);
			if (urlItem.length > 0) {
				const urlValues = url && url.includes('/') ? url.split('/') : [];
				const tabId = urlValues[1] ? parseInt(urlValues[1]) : 0;
				const cycleId = urlValues[2] ? parseInt(urlValues[2]) : 0;
				const employeeId = urlValues[3] ? parseInt(urlValues[3]) : 0;
				const categoryId = urlValues[4] ? parseInt(urlValues[4]) : 0;
				const conversationId = urlValues[5] ? parseInt(urlValues[5]) : 0;
				const conversationsReplyId = urlValues[6] ? parseInt(urlValues[6]) : 0;
				//handle conversation redirection
				if (conversationId > 0 || conversationsReplyId > 0) {
					setLocalStorageItem(
						'conversationHighlight',
						JSON.stringify({
							conversationId: conversationId,
							employeeId: employeeId,
							conversationsReplyId: conversationsReplyId,
						})
					);
				}

				const payload = {
					tabId, // 1 for my goal, 2 for team goal
					cycleId,
					employeeId,
					categoryId,
					conversationId,
					conversationsReplyId,
				};
				store.dispatch(setPerformanceGoalForRedirection(payload));
				store.dispatch(currentDashboardTabForSO(false));
				store.dispatch(currentDashboardTab(tabId === 1 ? 6 : 7));
				store.dispatch(updateRoute(HOME));
				history.push(HOME);
			}
		} else if (urlType === 'perform') {
			setLocalStorageItem('redirectUrl', JSON.stringify({ url: url }));
			setLocalStorageItem('empId', empId);
		} else if (urlType === 'userdeleted') {
			store.dispatch(currentDashboardTabForSO(false));
			store.dispatch(currentDashboardTab(0));
			store.dispatch(updateRoute(HOME));
			setLocalStorageItem('redirectUrl', JSON.stringify({ url: url }));
			setLocalStorageItem('empId', empId);
			history.push(HOME);
		} else if (urlType === 'perform-development' || urlType === 'perform-feedback') {
			setLocalStorageItem('redirectUrl', JSON.stringify({ url: url }));
			setLocalStorageItem('empId', empId);
		} else if (urlType === 'recognize') {
			let recognitionId = 0;
			let commentDetailsId = 0;
			let replyDetailsId = 0;
			if (urlItem.length > 0) {
				recognitionId = urlItem[1] ? parseInt(urlItem[1]) : 0;
				setLocalStorageItem(
					'recognitionHighlight',
					JSON.stringify({
						recognitionId: recognitionId,
					})
				);

				if (urlItem.length > 2) {
					let commentDetailsId = urlItem[2] ? parseInt(urlItem[2]) : 0;
					let replyDetailsId = urlItem[3] ? parseInt(urlItem[3]) : 0;
					setLocalStorageItem(
						'recognitionCommentHighlight',
						JSON.stringify({
							recognitionId: recognitionId,
							commentDetailsId: commentDetailsId,
							replyDetailsId: replyDetailsId,
						})
					);
				} else if (urlItem.length > 1) {
					commentDetailsId = urlItem[2] ? parseInt(urlItem[2]) : 0;
					setLocalStorageItem(
						'recognitionCommentHighlight',
						JSON.stringify({
							recognitionId: recognitionId,
							commentDetailsId: commentDetailsId,
						})
					);
				}
			}
			setLocalStorageItem(
				'redirectUrl',
				JSON.stringify({
					url: commentDetailsId > 0 ? `recognize/${recognitionId}/${commentDetailsId}}` : `recognize/${recognitionId}}`,
				})
			);
			setLocalStorageItem('empId', empId);
		} else {
			//==== if user is accessing page and url is exist
			if (USER_ROUTES.findIndex((item) => item === url) > -1 || ADMIN_ROUTES.findIndex((item) => item === url) > -1) {
				setLocalStorageItem(
					'redirectUrl',
					JSON.stringify({
						url: url,
					})
				);
				setLocalStorageItem('empId', empId);
			}
		}
	};

	/** render route with header */
	const RenderRoute = (props) => {
		let url = props.location.pathname;
		let isValidUrl = true;
		if (props.location.search) {
			const windowUrl = window.location.href;
			let redirectUrl = getParameterByName('redirectUrl', windowUrl);
			const empId = getParameterByName('empId', windowUrl);
			const cycleId = getParameterByName('cycleId', windowUrl);
			const year = getParameterByName('year', windowUrl);

			const urlItem = redirectUrl.split('/');
			const urlType = Boolean(urlItem[0]) ? toLower(urlItem[0]) : '';
			if (
				urlType === 'organization' &&
				Boolean(userDetail) &&
				userDetail.loggedInAs &&
				userDetail.loggedInAs === 'Admin'
			) {
				//=== in case of admin redirection to organization
				setRedirectDetails(redirectUrl, empId, cycleId);
			} else if (
				// if user already logged in
				Boolean(redirectUrl) &&
				Boolean(empId) &&
				Boolean(userDetail) &&
				parseInt(userDetail.employeeId) === parseInt(empId)
			) {
				if (
					(urlType === 'conversations' || urlType === 'notes') &&
					cycleId &&
					year &&
					(getLocalStorageItem('cycleId') !== cycleId || getLocalStorageItem('year') !== year)
				) {
					//in case other cycleId or year
					//check if cycle id or year is different than current
					let cycleDetail = JSON.parse(getLocalStorageItem('cycleDetail'));
					let saveCycleObject = { index: 0, quarter: {} };
					let currentCycle = cycleDetail.cycleDetails.filter((item) =>
						item.quarterDetails.reverse().some((quarter, index) => {
							if (quarter.organisationCycleId === parseInt(cycleId)) {
								saveCycleObject = {
									quarter: quarter,
									index: `${index + 1}${year}`,
								};
							}
						})
					);
					let cycleObj = {
						cycleId: parseInt(cycleId),
						quarterData: saveCycleObject.quarter,
						id: saveCycleObject.index,
						year: year,
						currentCycle: currentCycle,
						cycleChangedStatus: false,
					};
					// store.dispatch(updateCurrentCycleId(saveCycleObject.quarter));
					store.dispatch(changeCycleId(cycleObj));
					setLocalStorageItem('isPeopleViewRedirectionInProgress', true);
					setLocalStorageItem('otherCycleObjective', JSON.stringify({ cycleId: cycleId, year: year }));
				} else if (urlType === 'notes' || urlType === 'conversations') {
					history.push(USER_VIEW);
					setLocalStorageItem('isPeopleViewRedirectionInProgress', true);
					setLocalStorageItem('otherCycleObjective', JSON.stringify({ cycleId: cycleId, year: year }));
				} else if (urlType === 'performance-goal') {
					const tabId = urlItem[1] ? parseInt(urlItem[1]) : 0;
					const cycleId = urlItem[2] ? parseInt(urlItem[2]) : 0;
					const employeeId = urlItem[3] ? parseInt(urlItem[3]) : 0;
					const categoryId = urlItem[4] ? parseInt(urlItem[4]) : 0;
					const conversationId = urlItem[5] ? parseInt(urlItem[5]) : 0;
					const conversationsReplyId = urlItem[6] ? parseInt(urlItem[6]) : 0;

					//handle conversation redirection
					if (conversationId > 0 || conversationsReplyId > 0) {
						setLocalStorageItem(
							'conversationHighlight',
							JSON.stringify({
								conversationId: conversationId,
								employeeId: employeeId,
								conversationsReplyId: conversationsReplyId,
							})
						);
					}
					setTimeout(() => {
						const payload = {
							tabId, // 1 for my goal, 2 for team goal
							cycleId,
							employeeId,
							categoryId,
							conversationId,
							conversationsReplyId,
						};
						store.dispatch(setPerformanceGoalForRedirection(payload));
						store.dispatch(currentDashboardTabForSO(false));
						store.dispatch(currentDashboardTab(tabId === 1 ? 6 : 7));
						store.dispatch(updateRoute(HOME));
					}, 3000);
					history.push(HOME);
				} else if (urlType === 'userdeleted') {
					setTimeout(() => {
						store.dispatch(currentDashboardTabForSO(false));
						store.dispatch(currentDashboardTab(0));
						store.dispatch(updateRoute(HOME));
					}, 3000);
					history.push(HOME);
				} else if (urlType === 'perform') {
					const performanceCycleId = urlItem[1] ? parseInt(urlItem[1]) : 0;
					const threeSixtyStatus = urlItem[2] ? parseInt(urlItem[2]) : 0;
					const nomineeForEmpId = urlItem[3] ? parseInt(urlItem[3]) : 0;
					const payload = {
						cycleId: performanceCycleId,
						employeeId: empId,
						threeSixtyStatus,
						nomineeForEmpId,
					};
					setTimeout(() => {
						store.dispatch(currentDashboardTabForSO(false));
						threeSixtyStatus === 3 && store.dispatch(setNomineeDetailsForRedirection(payload));
						store.dispatch(currentDashboardTab(threeSixtyStatus === 3 ? 2 : 0));
						store.dispatch(updateRoute(PERFORM));
					}, 3000);
					history.push(PERFORM);
					
				} else if (urlType === 'goal-review') {
					setTimeout(() => {
						store.dispatch(currentDashboardTabForSO(false));
						//store.dispatch(currentDashboardTab(threeSixtyStatus === 3 ? 2 : 0));
						store.dispatch(updateRoute(GOAL_REVIEW));
					}, 3000);
					history.push(GOAL_REVIEW);
				} else if (urlType === 'perform-development') {
					const pipId = urlItem[1] ? parseInt(urlItem[1]) : 0;
					let pipData = {
						pipId: pipId,
						reviewerId: urlItem[2] || 0,
						commentId: urlItem[3] || 0,
					};

					setTimeout(() => {
						history.push(PERFORM_DEVELOPMENT);
					}, 1000);
					setTimeout(() => {
						if (pipId) {
							store.dispatch(setPipImprovementData(pipData));
							store.dispatch(currentDashboardTab(1));
						}
						store.dispatch(updateRoute(PERFORM_DEVELOPMENT));
					}, 2000);
				} else if (urlType === 'perform-feedback') {
					setTimeout(() => {
						if (urlItem[1]) {
							store.dispatch(
								setFeedbackImprovementData({
									feedbackId: urlItem[1] ? parseInt(urlItem[1]) : 0,
									commentId: urlItem[2] || 0,
								})
							);
							store.dispatch(currentDashboardTab(0));
						}
						//store.dispatch(currentDashboardTab(0));
						store.dispatch(updateRoute(PERFORM_DEVELOPMENT));
					}, 3000);
					history.push(PERFORM_DEVELOPMENT);
				} else if (urlType === 'recognize') {
					setTimeout(() => {
						store.dispatch(currentDashboardTabForSO(false));
						store.dispatch(currentDashboardTab(0));
						store.dispatch(updateRoute(RECOGNIZE));
					}, 3000);
					history.push(RECOGNIZE);
				}
				setRedirectDetails(redirectUrl, empId, cycleId);
			} else if (Boolean(redirectUrl) && !Boolean(userDetail)) {
				setRedirectDetails(redirectUrl, empId, cycleId);
				if (Boolean(cycleId) && Boolean(year)) {
					//setLocalStorageItem('otherCycleObjective', JSON.stringify({ cycleId: cycleId, year: year }));
				}
			} else if (
				Boolean(redirectUrl) &&
				Boolean(userDetail) &&
				Boolean(empId) &&
				parseInt(userDetail.employeeId) !== parseInt(empId)
			) {
				// clearLocalStorage();
				// history.push('forbidden');
				isValidUrl = false;
				removeLocalStorageItem('redirectUrl');
				history.push('/forbidden');
			} else if (Boolean(redirectUrl) && Boolean(userDetail) && !Boolean(empId) && urlType === 'recognize') {
				setTimeout(() => {
					store.dispatch(currentDashboardTabForSO(false));
					store.dispatch(currentDashboardTab(0));
					store.dispatch(updateRoute(RECOGNIZE));
				}, 3000);
				history.push(RECOGNIZE);
				setRedirectDetails(redirectUrl, empId, cycleId);
			} else {
				clearLocalStorage();
			}
		} else {
			let redirectUrl = getLocalStorageItem('redirectUrl');
			if (redirectUrl) {
				redirectUrl = JSON.parse(redirectUrl);
				if (redirectUrl) {
					const urlItem = redirectUrl.url.split('/');
					const selectedTab = urlItem[3] ? parseInt(urlItem[3]) : 0;
					const selectedTile = urlItem[4] ? parseInt(urlItem[4]) : 0;
					const selectedEmployee = urlItem[2] ? parseInt(urlItem[2]) : 0;
					setTimeout(() => {
						removeLocalStorageItem('redirectUrl');
						store.dispatch(currentDashboardTabForSO(false));
						store.dispatch(currentDashboardTab(selectedTab));
						store.dispatch(setEmployeeDetailsForRedirection({ employeeId: selectedEmployee, selectedTile }));
						store.dispatch(updateRoute(PERFORM));
					}, 3000);
					history.push(PERFORM);
				}
			}
		}
		if (!isValidUrl) {
			url = url.replace('/', 'forbidden');
		} else {
			url = url.replace('/', '');
		}
		// store.dispatch(updateRoute('/' + url));
		checkForAuthorization(url);

		const currentRoute = getLocalStorageItem('currentRoute');

		return (
			<Fragment>
				{currentRoute === ADMIN ? (
					<div className={`admin-wrapper wrapper`}>
						{/* <AdminHeader {...props} switchUser={switchUser} loginAs={loginAs} /> */}
						<Header {...props} switchUser={switchUser} loginAs={loginAs} />
						<main role='main' className={'page-container main-wrapper'}>
							{props.children}
						</main>
					</div>
				) : (
					<div className={`${url}-wrapper user-wrapper wrapper`}>
						<Header {...props} switchUser={switchUser} loginAs={loginAs} />
						<main
							role='main'
							className={`page-container ${url === 'people-view' ? 'people-main-wrapper' : 'main-wrapper'}`}
						>
							{props.children}
							<ChatBot />
						</main>
					</div>
				)}
			</Fragment>
		);
	};

	const checkForAuthorization = (url) => {
		let redirectUrl = getLocalStorageItem('redirectUrl');
		if (Boolean(redirectUrl)) {
			//=== do not check
		} else if (getLocalStorageItem('userDetail') && (url === '' || url === SECRET_LOGIN || url === '/')) {
			// history.push(HOME);
			history.push(DASHBOARD);
		} else {
			if (VALID_ROUTES.findIndex((item) => item === url) === -1) {
				clearLocalStorage();
				history.push('/');
			}
		}
	};

	/** render router only when user is loggedIn */
	const PrivateRoute = ({ component: Component, ...rest }) => (
		<Route
			{...rest}
			render={(props) => (getLocalStorageItem('userDetail') ? <Component switchUser={switchUser} {...props} /> : '')}
		/>
	);

	const SignInComponent = () => <SignIn {...props} />;
	const ForgotPasswordComponent = () => <ForgotPassword {...props} />;
	const ResetPasswordComponent = () => <ResetPassword {...props} />;
	const CustomDomainComponent = () => <CustomLogin {...props} />;
	const UnauthorizedAccessComponent = () => <UnauthorizedAccess {...props} />;
	const PrivacyPolicyComponent = () => <PrivacyPolicy {...props} />;
	const TermsOfUseComponent = () => <TermsOfUse {...props} />;
	const LogoutComponent = () => <Logout {...props} />;
	const InternalServerErrorComponent = () => <InternalServerError {...props} />;
	const UnderMaintainenceComponent = () => <UnderMaintainence {...props} />;
	const FreeTrialComponent = () => <FreeTrial {...props} />;

	return (
		<Switch>
			<Route path='/secretlogin' exact component={SignInComponent} />
			<Route path='/sso-login' exact component={SsoLogin} />
			<Route path='/forgot-password' exact component={ForgotPasswordComponent} />
			<Route path='/reset-password' exact component={ResetPasswordComponent} />
			<Route path='/custom-login' exact component={CustomDomainComponent} />
			<Route path='/unauthorized' exact component={UnauthorizedAccessComponent} />
			<Route path={PRIVACY_POLICY} exact component={PrivacyPolicyComponent} />
			<Route path={TERMS_OF_USE} exact component={TermsOfUseComponent} />
			<Route path={'/logout'} exact component={LogoutComponent} />
			<Route path={'/500'} exact component={InternalServerErrorComponent} />
			<Route path={'/under-maintenance'} exact component={UnderMaintainenceComponent} />
			<Route path={'/free-trial'} exact component={FreeTrialComponent} />

			<RenderRoute>
				<PrivateRoute path={ADMIN} exact component={Admin} />
				<Route path='/' exact component={Home} />
				<PrivateRoute path={DASHBOARD} exact component={Home} />
				<PrivateRoute path='/forbidden' exact component={Forbidden} />
				<PrivateRoute path={HOME} exact component={Dashboard} />
				<PrivateRoute path={MY_GOAL} exact component={MyGoal} />
				<PrivateRoute path={USER_VIEW} exact component={MyGoal} />
				{/* <PrivateRoute path={INSIGHT} exact component={Insights} /> */}
				<PrivateRoute path={REPORTS} exact component={Reports} />
				<PrivateRoute path={INSIGHT} exact component={Insight} />
				<PrivateRoute path={TEAM_VIEW} exact component={TeamView} />
				<PrivateRoute path={ENGAGE} exact component={Engagement} />
				<PrivateRoute path={PERFORM} exact component={UserPerformance} />
				<PrivateRoute path={RECOGNIZE} exact component={Recognize} />
				<PrivateRoute path={LETTER_GEN} exact component={LetterGen} />
				<PrivateRoute path={GLOBAL_Task} exact component={GlobalTask} />
				<PrivateRoute path={PERFORM_DEVELOPMENT} exact component={PerformanceDevelopment} />
				<PrivateRoute path={GOAL_REVIEW} exact component={GoalsReview} />
			</RenderRoute>
		</Switch>
	);
}
