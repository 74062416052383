import React, { useState, useEffect } from 'react';
import { SOObjectiveList } from './SOObjectiveList';
import { getCycleDefaultSelection } from '../../../config/utils';

export const SearchSOMyOkr: React.FC<any> = (props) => {
	const {
		objectiveListMyTab,
		setObjectiveListMyTab,
		cycleId,
		cycleDetail,
		currentCycleYear,
		cycleDuration,
		getObjectiveListBasedOnCycleChange,
		okrMasterData,
		getLinkedKeyResultCount,
		setIsDetailsEdited,
		soPermissionDetails,
	} = props;
	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(null);
	const [currentCycleId, setCurrentCycleId] = useState(cycleId);
	const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	const [searchText, setSearchText] = useState('');
	const [currentCycleSelection, setCurrentCycleSelection] = useState<any[]>([]);
	const [myGoalList, setMyGoalList] = useState<any>(null);

	useEffect(() => {
		if (cycleDetail && cycleDetail.cycleDetails && cycleDetail.cycleDetails.length) {
			let currentQuarterDetails: any = getCycleDefaultSelection(cycleDetail.cycleDetails, cycleId);
			if (currentQuarterDetails) {
				setSelectedCycleDetails({ ...currentQuarterDetails });
				setIsCurrentCycle({ ...currentQuarterDetails });
				setCurrentSelectedCycleYear(currentQuarterDetails?.year || currentCycleYear);
				setCurrentCycleId(currentQuarterDetails?.organisationCycleId || currentCycleId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (objectiveListMyTab && objectiveListMyTab.length) {
			const filterData =
				objectiveListMyTab && objectiveListMyTab.length
					? objectiveListMyTab.filter(
							(item: any) => item?.customDetails?.cycleId === selectedCycleDetails?.organisationCycleId
					  )
					: [];
			setMyGoalList(filterData);
		} else {
			setMyGoalList([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveListMyTab, selectedCycleDetails]);

	const handleCycleChange = (id: any, quarterData: any, year: any) => {
		updateCycleDetails(quarterData, year);
	};
	const updateCycleDetails = (quarterData: any, year: any) => {
		quarterData?.organisationCycleId && setCurrentCycleId(quarterData?.organisationCycleId);
		year && setCurrentSelectedCycleYear(year);
		setSelectedCycleDetails({ ...quarterData, year });
		const selectedCycleDetail = {
			...quarterData,
			year,
		};
		getObjectiveListBasedOnCycleChange(selectedCycleDetail, 'MyTab');
	};

	const handleObjectiveKRLinkingChange = (event: any, linkingType: string, okr: any, kr: any) => {
		try {
			const { checked } = event?.target || {};
			let updatedMainList = objectiveListMyTab && objectiveListMyTab.length ? [...objectiveListMyTab] : [];
			// console.log('handleObjectiveKRLinkingChange', myGoalList, selectedCycleDetails, okr, kr);
			if (linkingType === 'OKR') {
				updatedMainList = [...updatedMainList].map((obj: any) => {
					const data = { ...obj };
					if (obj.goalObjectiveId === okr.goalObjectiveId) {
						data.linkedKrResponses =
							data.linkedKrResponses && data.linkedKrResponses.length
								? data.linkedKrResponses.map((krData: any) => {
										const krObj = { ...krData };
										krObj.isSelected = checked || false;
										krObj.isLinked = checked || false;

										return krObj;
								  })
								: [];

						data.isSelected = checked || false;
						data.isLinked = checked || false;
					}

					return { ...data };
				});
			} else if (linkingType === 'KR') {
				updatedMainList = [...updatedMainList].map((obj: any) => {
					const data = { ...obj };
					if (obj.goalObjectiveId === okr.goalObjectiveId) {
						data.linkedKrResponses =
							data.linkedKrResponses && data.linkedKrResponses.length
								? data.linkedKrResponses.map((krData: any) => {
										const krObj = { ...krData };
										if (krObj?.goalkeyId === kr?.goalkeyId) {
											krObj.isSelected = checked || false;
											krObj.isLinked = checked || false;
										}

										return krObj;
								  })
								: [];

						data.isSelected = false; //checked ? validateAllKRCheck(data) : false;
					}

					return { ...data };
				});
			} else {
				updatedMainList =
					objectiveListMyTab && objectiveListMyTab.length ? [...objectiveListMyTab] : objectiveListMyTab;
			}
			setObjectiveListMyTab(updatedMainList);
			setIsDetailsEdited(true);
		} catch (error) {
			console.error(error);
		}
	};
	const validateAllKRCheck = (okr: any) => {
		const krList = okr.linkedKrResponses && okr.linkedKrResponses.length ? okr.linkedKrResponses : [];
		const selectedKR = krList.filter((item: any) => item?.isSelected);

		return krList.length === 0 ? false : selectedKR.length === krList.length ? true : false;
	};

	return (
		<>
			<SOObjectiveList
				cycleId={cycleId}
				cycleDetail={cycleDetail}
				currentCycleYear={currentCycleYear}
				cycleDuration={cycleDuration}
				getObjectiveListBasedOnCycleChange={getObjectiveListBasedOnCycleChange}
				okrMasterData={okrMasterData}
				selectedCycleDetails={selectedCycleDetails}
				currentCycleSelection={currentCycleSelection}
				currentCycleId={currentCycleId}
				currentSelectedCycleYear={currentSelectedCycleYear}
				handleCycleChange={handleCycleChange}
				isCurrentCycle={isCurrentCycle}
				searchText={searchText}
				setSearchText={setSearchText}
				myGoalList={myGoalList}
				setMyGoalList={setMyGoalList}
				handleObjectiveKRLinkingChange={handleObjectiveKRLinkingChange}
				getLinkedKeyResultCount={getLinkedKeyResultCount}
				soPermissionDetails={soPermissionDetails}
			/>
		</>
	);
};
