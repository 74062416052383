import { getRequest, postRequest, deleteRequest, putRequest } from '../config/auth';
import {
	GET_NOTES,
	GET_CONVERSATION,
	LIKE_CONVERSATION,
	WALL_OF_FAME_DASHBOARD,
	GET_CONVERSATION_REPLY,
	GET_CONVERSATION_TASK_REPLY,
} from '../config/api-url';

//CFR
export const getNotesAPI = (data) => getRequest(`${GET_NOTES}?${data}`, data);
export const deleteNotesAPI = (data) => deleteRequest(`${GET_NOTES}?${data}`, data);
export const UpdateNotesAPI = (data) => putRequest(`${GET_NOTES}`, data);
export const addNotesAPI = (data) => postRequest(`${GET_NOTES}`, data);
//export const taskCompletedAPI = (data) => putRequest(`${TASK_COMPLETED}`, data);

//Conversation
export const getConversationAPI = (data) => getRequest(`${GET_CONVERSATION}?${data}`, data);
export const deleteConversationAPI = (data) => deleteRequest(`${GET_CONVERSATION}?${data}`, data);
export const UpdateConversationAPI = (data) => putRequest(`${GET_CONVERSATION}`, data);
export const addConversationAPI = (data) => postRequest(`${GET_CONVERSATION}`, data);
export const updateConversationTaskReplyAPI = (data) => putRequest(`${GET_CONVERSATION_TASK_REPLY}`, data);
export const addConversationTaskReplyAPI = (data) => postRequest(`${GET_CONVERSATION_TASK_REPLY}`, data);
export const getConversationTaskReplyAPI = (data) => getRequest(`${GET_CONVERSATION_TASK_REPLY}?${data}`, data);
export const likeConversationAPI = (data) => postRequest(`${LIKE_CONVERSATION}`, data);
export const getMywalloffamedashboardAPI = (data) => getRequest(`${WALL_OF_FAME_DASHBOARD}`);
export const getConversationReplyAPI = (data) => getRequest(`${GET_CONVERSATION_REPLY}?${data}`, data);
