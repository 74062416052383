import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import { CreateTask } from '../../GlobalTask/CreateTask';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../config/enums';
import {
	checkIsInitialListCall,
	formatOneOnOneRequestDate,
	getGlobalTaskListDueDate,
	getPriorityName,
	getUserDetails,
	validateFilterSelection,
} from '../../../config/utils';
import { completedGlobalTask, deleteGlobalTask, getAllGlobalTaskDirect } from '../../../action/globalTask';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import NotesImg from '../../../images/no-found-notes.svg';
import { TaskList } from '../../GlobalTask/TaskList';
import { updateTask } from '../../../action/myGoal';
import AlertDialog from '../../Common/Dialog';
import moment from 'moment';
import { useSnackbar } from 'notistack';

export const WeeklyTaskDetails: React.FC<any> = (props) => {
	const {
		// setIsCfrUpdated,
		// getTasks,
		// type,
		// okr,
		// kr,
		tabSelected,
		// taskStatus,
		// tasks,
		// isDataSaving,
		// setIsDataSaving,
		showApiMsgs,
		// isTaskEdited,
		// setShowDialog,
		// isPastCycle,
		// setAllTasks,
		// allTasks,
		// canCheckInEdit,
		setIsNewTaskEdited,
		// setIsTaskEdited,
		// fetchCheckInTaskData,
		// getCheckInDatesFromTask,
		setLoader,
		// ==================================
		defaultTaskListRequest,
		globalTaskRequest,
		setGlobalTaskRequest,
		defaultRecentUserList,
		// setDefaultRecentUserList,
		getAllTask,
		getRecentSearchesList,
		krList,
		isLaunchFormEdited,
		setIsLaunchFormEdited,
		setIsCreateLinkPopupOpen,
		selectedDate,
		handleSaveCallBack,
		selectedUser,
	} = props;
	// console.log('WeeklyTaskDetails ===> ', props);

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const userDetail: any = getUserDetails();
	const dispatch = useDispatch();
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 6);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);
	const { globalTaskListResponse, globalTaskListStatus } = useSelector((state: any) => state.globalTaskReducer);
	const { loaderStatus } = useSelector((state: any) => state.commonReducer);
	const [taskPagingInfo, setTaskPagingInfo] = useState<any>(null);

	const defaultFormDetails = {
		taskName: '',
		priority: Enums.ONE,
		taskDueDate: null,
		assignedTo: Enums.ZERO,
		userDetails: null,
		goalTypeId: Enums.ZERO,
		goalId: Enums.ZERO,
		linkedKRDetails: null,
		isCheckedInLinked: false,
	};
	const staticFilterObj = {
		isCompleted: false,
		taskGroupByType: Enums.ZERO,
		sortingText: '',
		order: '',
	};
	const [formDetails, setFormDetails] = useState<any>(defaultFormDetails);
	// const [loader, setLoader] = useState(false);
	// const [isLaunchFormEdited, setIsLaunchFormEdited] = useState<any>(false);
	const [allTaskList, setAllTaskList] = useState<any>(null);
	// const [defaultRecentUserList, setDefaultRecentUserList] = useState<any>([]);
	// const [isCreateLinkPopupOpen, setIsCreateLinkPopupOpen] = useState<boolean>(false);
	const [completedTaskCount, setCompletedTaskCount] = useState<number>(0);
	const [toDoTaskCount, setToDoTaskCount] = useState<number>(0);
	// const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	// List Related States
	// const [globalTaskRequest, setGlobalTaskRequest] = useState<any>(defaultTaskListRequest);
	const [filterDetails, setFilterDetails] = useState<any>(staticFilterObj);
	const [pageIndexVal, setPageIndex] = useState<number>(1);
	const [tabSelectedVal, setTabSelectedVal] = useState(1);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isDateRangeChanged, setIsDateRangeChanged] = useState<boolean>(false);
	const [selectedFilterDateRange, setSelectedFilterDateRange] = useState<any>(null);
	const [openGroupByTask, setOpenGroupByTask] = useState<any>({ taskExpand: false, taskGroupById: 0 });
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, details: null });
	const [taskGroupByList, setTaskGroupByList] = useState<any>(null);
	const [dueDateError, setDueDateError] = useState<any>(null);

	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// 	getAllTask(defaultTaskListRequest);
	// 	if (userDetail && userDetail?.employeeId) {
	// 		let data = `empId=${userDetail.employeeId}`;
	// 		dispatch(getKrListing(data));
	// 	}
	// 	getRecentSearchesList();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const getAllTask = (data: any) => {
	// 	let requestPayload = { ...data };
	// 	requestPayload = {
	// 		...requestPayload,
	// 		employeeId: userDetail?.employeeId || Enums.ZERO,
	// 		startDate: null,
	// 		endDate: null,
	// 	};
	// 	setGlobalTaskRequest(requestPayload);
	// 	dispatch(getAllGlobalTask(requestPayload, false));
	// };

	// const getRecentAssigneeList = async (requestParam: any) => {
	// 	try {
	// 		const response: any = await dispatch(getRecentAssignee(requestParam));
	// 		if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
	// 			const { entityList } = response?.data || {};
	// 			setDefaultRecentUserList(entityList && entityList.length ? entityList : []);
	// 		} else {
	// 			setDefaultRecentUserList([]);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 		setDefaultRecentUserList([]);
	// 	}
	// };

	useEffect(() => {
		if (globalTaskListStatus) {
			renderTaskListDetails(globalTaskListResponse, null);
			setLoader(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalTaskListStatus, globalTaskListResponse]);

	const renderTaskListDetails = (taskListResp: any, newTask: any) => {
		if (taskListResp?.status === Enums.STATUS_SUCCESS) {
			const { pagingInfo, entity } = taskListResp || {};
			const { totalPages = 1, pageIndex = 0 } = pagingInfo || {};
			const { completedCount, toDoCount, taskListings, taskGroupByResponses } = entity || {};
			setCompletedTaskCount(completedCount || Enums.ZERO);
			setToDoTaskCount(toDoCount || Enums.ZERO);
			setTaskPagingInfo(pagingInfo);

			if (taskGroupByResponses && taskGroupByResponses.length) {
				setTaskGroupByList(taskGroupByResponses);
			} else {
				setTaskGroupByList([]);
			}
			const updatedTaskListingsData =
				taskListings && taskListings.length
					? taskListings?.filter((rec: any) => rec.taskId !== newTask?.taskId)
					: taskListings;
			const newTaskList = newTask ? [newTask, ...updatedTaskListingsData] : updatedTaskListingsData || [];
			// const newTaskList = newTask ? [newTask, ...(taskListings || [])] : taskListings || [];
			const updatedTaskListings =
				newTaskList && newTaskList.length
					? newTaskList.map((rec: any) => ({
							...rec,
							momentDueDate: rec?.dueDate ? moment(new Date(rec?.dueDate)) : null,
							historyDate: rec?.dueDate,
							history: rec.taskName,
					  }))
					: [];

			if (totalPages > Enums.ONE && totalPages > pageIndex) {
				setShowLoadMore(true);
			} else {
				setShowLoadMore(false);
			}

			if (pageIndex === Enums.ZERO || pageIndex === Enums.ONE) {
				setAllTaskList(updatedTaskListings || []);
			} else {
				if (allTaskList && allTaskList.length) {
					const fullList = [...allTaskList, ...updatedTaskListings];
					let filterData: any[] = [];
					fullList?.forEach((rec: any) => {
						const sf = filterData?.find((it: any) => it?.taskId === rec?.taskId);
						if (!sf) {
							filterData.push({ ...rec });
						}
					});
					setAllTaskList(filterData);
				} else {
					setAllTaskList([...updatedTaskListings]);
				}
			}
		} else {
			setCompletedTaskCount(Enums.ZERO);
			setToDoTaskCount(Enums.ZERO);
			setShowLoadMore(false);
			setTaskGroupByList(null);
			setAllTaskList(null);
		}
	};
	const resetTabDetails = (resetType: boolean) => {
		setFormDetails(defaultFormDetails);
		setIsLaunchFormEdited(false);
		if (resetType) {
			setFilterDetails({ staticFilterObj, taskGroupByType: Enums.ZERO });
			// setPageIndex(1);
			// const data = {
			// 	...defaultTaskListRequest,
			// 	isMyTask: true,
			// 	taskGroupByType: Enums.ZERO,
			// 	employeeId: selectedUser.employeeId || Enums.ZERO,
			// 	endDate: selectedDate?.endDate ? formatOneOnOneRequestDate(selectedDate?.endDate) : null,
			// 	startDate: selectedDate?.startDate ? formatOneOnOneRequestDate(selectedDate?.startDate) : null,
			// };
			// setGlobalTaskRequest(data);
			// getAllTask(data || null);
		}
	};
	const getAllTaskCallBack = async (entity: any) => {
		setPageIndex(1);
		// const data = {
		// 	...defaultTaskListRequest,
		// 	isMyTask: true,
		// 	taskGroupByType: Enums.ZERO,
		// 	employeeId: selectedUser.employeeId || Enums.ZERO,
		// 	endDate: selectedDate?.endDate ? formatOneOnOneRequestDate(selectedDate?.endDate) : null,
		// 	startDate: selectedDate?.startDate ? formatOneOnOneRequestDate(selectedDate?.startDate) : null,
		// };
		const data = {
			...globalTaskRequest,
			taskGroupByType: Enums.ZERO,
		};
		setGlobalTaskRequest(data);
		let taskListResp: any = await dispatch(getAllGlobalTaskDirect(data));
		if (taskListResp && taskListResp?.data) {
			renderTaskListDetails(taskListResp?.data, entity || null);
		}
		// let taskListResp: any = await dispatch(getAllGlobalTaskDirect(data));
		// if (taskListResp && taskListResp?.data) {
		// 	renderTaskListDetails(taskListResp?.data, entity || null);
		// } else {
		// resetTabDetails(false);
		// if (allTaskList && allTaskList.length) {
		// 	entity ? setAllTaskList([entity, ...allTaskList]) : setAllTaskList([...allTaskList]);
		// } else {
		// 	entity ? setAllTaskList([entity]) : setAllTaskList([]);
		// }
		// }
	};
	// const getRecentSearchesList = () => {
	// 	try {
	// 		if (kr && kr?.goalKeyId) {
	// 			let data = `goalKeyId=${kr?.goalKeyId || Enums.ZERO}`;
	// 			getRecentAssigneeList(data);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	/**
	 * Task List Related
	 */
	const callUpdateTaskDetailsAPI = async (task: any, updateType = '') => {
		setDueDateError(null);
		let data: any = {
			taskId: task.taskId,
			taskName: task?.taskName.trim(),
			taskType: Enums.TWO,
			goalId: task?.goalId || Enums.ZERO,
			goalTypeId: task?.goalTypeId || Enums.ZERO,
			dueDate: task?.dueDate ? formatOneOnOneRequestDate(task?.dueDate) : null,
			priority: task?.priorityId || Enums.ONE,
			isCompleted: task?.isCompleted || false,
			assignedTo: task?.assignedTo?.employeeId || Enums.ZERO,
			isCheckedInLinked: task?.isCheckedInLinked || false,
		};
		if (task.link) {
			let formTaskLink: any[] = [];
			task.link.forEach((linkArray: any) => {
				formTaskLink.push(linkArray.link);
			});
			data.link = formTaskLink;
		}
		setLoader(true);
		let updateTaskResponse: any = await dispatch(updateTask(data));
		if (Boolean(updateTaskResponse) && updateTaskResponse?.data?.status === 200) {
			const { entity, messageList } = updateTaskResponse?.data || {};
			const keys = messageList ? Object.keys(messageList) : [];
			const messages = keys && keys?.length ? keys.map((item) => messageList[item]) : '';
			if (entity) {
				let msg = null;
				if (updateType === 'CheckInUnlink' || updateType === 'DueDateChanged' || updateType === 'RemoveKR') {
					msg = t('taskUpdatedToastMessage');
				}
				if (msg) {
					enqueueSnackbar(msg, {
						variant: 'success',
						autoHideDuration: 3000,
					});
				}
				if (updateType === 'CheckInUnlink') {
					const data = { ...globalTaskRequest };
					getAllTask(data, true);
					setTimeout(() => {
						handleSaveCallBack('');
					}, 1000);
				} else {
					// if (isDateChanged) {
					// 	if (
					// 		task?.dueDate &&
					// 		selectedDate.endDate &&
					// 		moment(new Date(task?.dueDate)).isAfter(moment(new Date(selectedDate.endDate)))
					// 	) {
					// 		const messages = 'Good Job! Your task will be saved in the future week';
					// 		showApiMsgs(`${messages} `, 'success');
					// 	}
					// }
					// const data = { ...globalTaskRequest };
					const updatedRecord = {
						...entity,
						momentDueDate: entity?.dueDate ? moment(new Date(entity?.dueDate)) : null,
						historyDate: entity?.dueDate || null,
						history: entity.taskName || '',
					};
					let tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
					tasksCopy = tasksCopy.map((taskVal: any) => {
						if (taskVal.taskId === updatedRecord.taskId) {
							taskVal = updatedRecord;
							taskVal.isEditMode = false;
						}
						return taskVal;
					});

					setAllTaskList([...tasksCopy]);
				}
			}
			getRecentSearchesList();
			setLoader(false);
		} else {
			const responseAPI = updateTaskResponse.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			showApiMsgs(`${messages} `, 'error');
			setLoader(false);
		}
	};
	const getMyTaskDetails = () => {
		return true;
	};
	const handleFilterCompletedOnly = (e: any) => {
		const { checked } = e?.target;
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			isCompleted: checked,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		setFilterDetails({
			...filterDetails,
			isCompleted: checked,
			// taskGroupByType: Enums.ZERO,
			// sortingText: '',
			// order: '',
		});
	};
	const handleGroupByFilterClick = (taskGroupByType: number) => {
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			taskGroupByType,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		setFilterDetails({ ...filterDetails, taskGroupByType });
	};
	const handleSortingFilterClick = (sortingText: string, order: string) => {
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			sortingText,
			order,
			isMyTask: getMyTaskDetails(),
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
		setFilterDetails({ ...filterDetails, sortingText, order });
	};
	const fetchGlobalTask = async (pageIndex: number) => {
		setLoader(true);
		setPageIndex(pageIndex + 1);
		let dateData = {};
		// if (selectedFilterDateRange) {
		// 	const startDate = selectedFilterDateRange?.start?._d || new Date();
		// 	const endDate = selectedFilterDateRange?.end?._d || new Date();
		// 	dateData = {
		// 		startDate: formatOneOnOneRequestDate(startDate),
		// 		endDate: formatOneOnOneRequestDate(endDate),
		// 	};
		// }
		const data = { ...globalTaskRequest, pageIndex: pageIndex + 1, isMyTask: getMyTaskDetails(), ...dateData };
		setGlobalTaskRequest(data);
		getAllTask(data);
	};
	const handleTaskNameClick = (e: any, task: any, index: number) => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		if (!task.isCompleted) {
			tasksCopy.forEach((taskVal: any) => {
				if (taskVal.taskId === task.taskId) {
					taskVal.openEditMode = true;
					taskVal.isEditMode = true;
				} else {
					taskVal.taskName = taskVal?.history || '';
					taskVal.openEditMode = false;
					taskVal.isEditMode = false;
				}
			});

			setAllTaskList([...tasksCopy]);
		}
	};
	const handleTaskChange = (e: any, index: any, allTasks: any) => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];

		const { value } = e.target;
		tasksCopy[index].taskName = value;
		tasksCopy[index].isEditMode = true;
		tasksCopy.forEach((task: any) => {
			if (!task.isEditMode) {
				task.disabled = true;
			}
		});

		setAllTaskList([...tasksCopy]);
	};

	const handleTaskOnEnterEdit = (e: any, index: number, task: any) => {
		if (e.keyCode === 13 && task && task.taskName.trim() !== '') {
			handleTaskUpdate(task, false, index);
		} else if (e.keyCode === 27) {
			handleEditTaskClose(e, index, task);
		}
	};
	const handleEditTaskClose = (event: any, index: any, task: any) => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		tasksCopy[index].taskName = task?.history || '';
		tasksCopy[index].isEditMode = false;
		tasksCopy[index].openEditMode = false;
		tasksCopy.forEach((task: any) => {
			task.disabled = false;
		});
		setAllTaskList([...tasksCopy]);
	};
	const handleTaskUpdate = async (taskDetail: any, isTaskDueDateChange: boolean, index: number) => {
		if (taskDetail?.taskName && taskDetail?.taskName.trim() !== '') {
			setLoader(true);
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			tasksCopy[index].isEditMode = false;
			tasksCopy[index].openEditMode = false;
			tasksCopy.forEach((task: any) => {
				task.disabled = false;
			});
			setAllTaskList([...tasksCopy]);

			callUpdateTaskDetailsAPI(taskDetail);
		} else {
			// console.log('handleTaskUpdate ===> ', taskDetail);
		}
	};
	const handleTaskLinkChange = (links: any[], task: any) => {
		try {
			const requestPayload = {
				...task,
				link: links,
			};
			let index = allTaskList.findIndex((item: any) => item.taskId === task.taskId);
			const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
			tasksCopy[index].link = links;
			setAllTaskList([...tasksCopy]);
			callUpdateTaskDetailsAPI(requestPayload);
		} catch (error) {
			console.error(error);
		}
	};
	const resetCalendarDate = (isDirect: boolean) => {
		if (isDirect) {
			setSelectedFilterDateRange(null);
			setLoader(true);
			setPageIndex(1);
			const data = {
				...globalTaskRequest,
				pageIndex: 1,
				isMyTask: getMyTaskDetails(),
				startDate: null,
				endDate: null,
			};
			setGlobalTaskRequest(data);
			getAllTask(data);
		} else {
			setSelectedFilterDateRange(null);
		}
	};
	/**
	 * Task Name Change action end here
	 */
	const updatedSelectedTaskItem = (task: any, keyName: string, keyName2?: string, keyName3?: string) => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		tasksCopy.forEach((taskVal: any) => {
			if (taskVal.taskId === task.taskId) {
				if (keyName) {
					taskVal[keyName] = task[keyName];
				}
				if (keyName2) {
					if (keyName2 === 'isAnimated') {
						taskVal[keyName2] = task?.isCompleted;
					} else {
						taskVal[keyName2] = task[keyName2];
					}
					// taskVal[keyName2] = task[keyName2];
				}
				if (keyName3) {
					taskVal[keyName3] = task[keyName3];
				}
			}
		});

		setAllTaskList([...tasksCopy]);
	};
	const handleCheckInSelectionOnEdit = (e: any, isSelected: any, task: any) => {
		try {
			if (task?.dueDate) {
				const requestPayload = {
					...task,
					isCheckedInLinked: isSelected,
				};
				callUpdateTaskDetailsAPI(requestPayload);
				setDueDateError(null);
			} else {
				setDueDateError({ isError: true, itemIndex: task?.taskId });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleRemoveCheckInSelectionOnEdit = (e: any, isSelected: any, task: any) => {
		try {
			const requestPayload = {
				...task,
				isCheckedInLinked: isSelected,
			};
			callUpdateTaskDetailsAPI(requestPayload, 'CheckInUnlink');
			setDueDateError(null);
		} catch (error) {
			console.error(error);
		}
	};
	const handlePriorityChangeOnEdit = (e: any, taskType: number, task: any) => {
		try {
			const requestPayload = {
				...task,
				priority: getPriorityName(t, taskType || Enums.ONE),
				priorityId: taskType || Enums.ONE,
			};
			// updatedSelectedTaskItem(requestPayload, 'priority', 'priorityId');
			callUpdateTaskDetailsAPI(requestPayload);
		} catch (error) {
			console.error(error);
		}
	};
	const handleAddTaskDueDateChangeOnEdit = (value: any, range: any, task: any) => {
		try {
			const requestPayload = {
				...task,
				dueDate: value,
				dueDateText: value ? getGlobalTaskListDueDate(new Date(value)) : '',
				overDueText: '',
				isOverDue: false,
			};
			// updatedSelectedTaskItem(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
			callUpdateTaskDetailsAPI(requestPayload, 'DueDateChanged');
		} catch (error) {
			console.error(error);
		}
	};
	const handleResetButtonClickOnEdit = (task: any) => {
		const value = selectedDate?.endDate || null;
		const requestPayload = {
			...task,
			dueDate: value,
			dueDateText: value ? getGlobalTaskListDueDate(new Date(value)) : '',
			overDueText: '',
			isOverDue: false,
		};
		// updatedSelectedTaskItem(requestPayload, 'dueDate', 'dueDateText', 'overDueText', 'isOverDue');
		callUpdateTaskDetailsAPI(requestPayload, 'DueDateChanged');
	};
	const handleKRLinkOnEdit = (e: any, selectedKR: any, task: any) => {
		if (selectedKR && selectedKR?.goalKeyId) {
			const requestPayload = {
				...task,
				goalTypeId: selectedKR?.goalKeyId ? Enums.TWO : Enums.ZERO,
				goalId: selectedKR?.goalKeyId || Enums.ZERO,
				linkedKRDetails: selectedKR,
			};
			updatedSelectedTaskItem(requestPayload, 'goalTypeId', 'goalId', 'linkedKRDetails');
			callUpdateTaskDetailsAPI(requestPayload);
		}
	};
	const handleRemoveKROnEdit = (e: any, task: any) => {
		const requestPayload = {
			...task,
			goalTypeId: Enums.ZERO,
			goalId: Enums.ZERO,
			linkedKRDetails: null,
		};
		updatedSelectedTaskItem(requestPayload, 'goalTypeId', 'goalId', 'linkedKRDetails');
		callUpdateTaskDetailsAPI(requestPayload, 'RemoveKR');
	};
	const onSelectedUserOnEdit = (selectedUser: any, task: any) => {
		if (selectedUser && selectedUser?.employeeId) {
			const requestPayload = { ...task, assignedTo: selectedUser };
			// console.log('onSelectedUserOnEdit ===> ', requestPayload);
			updatedSelectedTaskItem(requestPayload, 'assignedTo');
			callUpdateTaskDetailsAPI(requestPayload);
		}
	};
	const handleRemoveAssigneeOnEdit = (e: any, task: any, isAssigneeSelfRemoved = false) => {
		const requestPayload = { ...task, assignedTo: isAssigneeSelfRemoved ? task?.assignedBy || null : null };
		updatedSelectedTaskItem(requestPayload, 'assignedTo');
		callUpdateTaskDetailsAPI(requestPayload);
	};
	const handleCompleteTaskOnEdit = async (e: any, task: any) => {
		try {
			const { checked } = e.target;
			setLoader(true);
			const updatedTaskDetails = { ...task, isCompleted: checked };
			updatedSelectedTaskItem(updatedTaskDetails, 'isCompleted', 'isAnimated');
			const requestPayload = { taskId: task.taskId, isCompleted: checked };
			const taskCompleteResponse: any = await dispatch(completedGlobalTask(requestPayload));
			if (Boolean(taskCompleteResponse) && taskCompleteResponse.data.status === 200) {
				// const data = { ...globalTaskRequest };
				setLoader(false);
			} else {
				const responseAPI = taskCompleteResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');

				showApiMsgs(`${messages} `, 'error');
				setLoader(false);
			}
		} catch (error) {
			showApiMsgs(t('someErrorOccurred'), 'error');
		}
	};
	const handleDeleteTaskOnEdit = (e: any, task: any) => {
		setOpenDeleteDialog({ open: true, details: { task } });
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			const { task } = openDeleteDialog?.details || {};
			if (task?.taskId) {
				setOpenDeleteDialog({ open: false, details: null });
				handleConfirmDeleteTaskClick(e, task);
			}
		} else {
			setOpenDeleteDialog({ open: false, details: null });
		}
	};
	const handleConfirmDeleteTaskClick = async (e: any, taskDetails: any) => {
		try {
			setLoader(true);
			const requestParams = `taskId=${taskDetails?.taskId}`;
			const deleteTaskResponse: any = await dispatch(deleteGlobalTask(requestParams));
			if (Boolean(deleteTaskResponse) && deleteTaskResponse?.data?.status === 200) {
				const updatedAllTaskList = allTaskList && allTaskList?.length ? [...allTaskList] : [];
				setAllTaskList(updatedAllTaskList?.filter((rec: any) => rec.taskId !== taskDetails?.taskId));
				const data = { ...globalTaskRequest };
				getAllTask(data, true);
				setTimeout(() => {
					handleSaveCallBack('');
				}, 1000);

				setLoader(false);
			} else {
				const responseAPI = deleteTaskResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				showApiMsgs(`${messages} `, 'error');
				setLoader(false);
			}
		} catch (error) {
			showApiMsgs(t('someErrorOccurred'), 'error');
			setLoader(false);
		}
	};
	const handleCollapseTask = (e: any, taskGroupById: any) => {
		setOpenGroupByTask({
			taskExpand: taskGroupById === openGroupByTask?.taskGroupById ? !openGroupByTask?.taskExpand : true,
			taskGroupById: taskGroupById,
		});
		if (taskGroupById !== openGroupByTask?.taskGroupById) {
			setAllTaskList(null);
			setLoader(true);
			setPageIndex(1);
			const data = { ...globalTaskRequest, pageIndex: 1, taskGroupById: taskGroupById, isMyTask: getMyTaskDetails() };
			setGlobalTaskRequest(data);
			getAllTask(data);
		}
	};
	const handleSearchText = (searchText = '') => {
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			pageIndex: 1,
			isMyTask: true,
			finder: searchText && searchText?.trim() !== '' ? [searchText] : [],
		};
		setGlobalTaskRequest(data);
		getAllTask(data);
	};

	return (
		<>
			<Box className={`checkins-task-area`} id={'checkins-task-area'}>
				{selectedUser.employeeId === userDetail.employeeId ? (
					<div>
						<CreateTask
							callingFrom={'CheckInTask'}
							loader={loaderStatus}
							setLoader={setLoader}
							isLaunchFormEdited={isLaunchFormEdited}
							setIsLaunchFormEdited={setIsLaunchFormEdited}
							allTaskList={allTaskList}
							setAllTaskList={setAllTaskList}
							defaultFormDetails={defaultFormDetails}
							formDetails={formDetails}
							setFormDetails={setFormDetails}
							resetTabDetails={resetTabDetails}
							krList={krList}
							defaultRecentUserList={defaultRecentUserList}
							minDateValue={minDateValue}
							maxDateValue={maxDateValue}
							setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
							getAllTaskCallBack={getAllTaskCallBack}
							globalTaskRequest={globalTaskRequest}
							defaultTaskListRequest={{ ...defaultTaskListRequest, isMyTask: true }}
							getRecentSearchesList={getRecentSearchesList}
							dueDate={selectedDate?.endDate || null}
							startDate={selectedDate?.startDate || null}
							handleSaveCallBack={(message: string) => {
								setIsNewTaskEdited(false);
								handleSaveCallBack(message);
							}}
							dueDateError={dueDateError}
							setDueDateError={setDueDateError}
						/>
					</div>
				) : (
					<></>
				)}
				{allTaskList &&
				allTaskList.length === Enums.ZERO &&
				!completedTaskCount &&
				!toDoTaskCount &&
				validateFilterSelection(globalTaskRequest, defaultTaskListRequest) ? (
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
							<Typography>{t('noTaskFoundSubTitle')}</Typography>
							<AnimatedIcon
								className='no-task-image'
								width='323px'
								type='image/svg+xml'
								alt={t('noTaskHead')}
								imagePath={NotesImg}
							/>
						</Box>
					</Box>
				) : (
					<TaskList
						callingFrom={'CheckInTask'}
						loader={loaderStatus}
						setLoader={setLoader}
						tabSelected={tabSelectedVal}
						globalTaskRequest={globalTaskRequest}
						setGlobalTaskRequest={setGlobalTaskRequest}
						setPageIndex={setPageIndex}
						taskPagingInfo={taskPagingInfo}
						setTabSelected={setTabSelectedVal}
						filterDetails={filterDetails}
						allTaskList={allTaskList}
						completedTaskCount={completedTaskCount}
						toDoTaskCount={toDoTaskCount}
						showLoadMore={showLoadMore}
						pageIndexVal={pageIndexVal}
						setIsLaunchFormEdited={setIsLaunchFormEdited}
						taskGroupByList={taskGroupByList}
						openGroupByTask={openGroupByTask}
						setOpenGroupByTask={setOpenGroupByTask}
						defaultRecentUserList={defaultRecentUserList}
						searchTextArray={searchTextArray}
						setSearchTextArray={setSearchTextArray}
						minDateValue={minDateValue}
						maxDateValue={maxDateValue}
						setIsDateRangeChanged={setIsDateRangeChanged}
						selectedFilterDateRange={selectedFilterDateRange}
						setSelectedFilterDateRange={setSelectedFilterDateRange}
						setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
						handleSearchText={handleSearchText}
						handleFilterCompletedOnly={handleFilterCompletedOnly}
						handleGroupByFilterClick={handleGroupByFilterClick}
						handleSortingFilterClick={handleSortingFilterClick}
						fetchGlobalTask={fetchGlobalTask}
						handleTaskNameClick={handleTaskNameClick}
						handleTaskChange={handleTaskChange}
						handleTaskOnEnterEdit={handleTaskOnEnterEdit}
						handleEditTaskClose={handleEditTaskClose}
						handleTaskUpdate={handleTaskUpdate}
						handlePriorityChangeOnEdit={handlePriorityChangeOnEdit}
						handleAddTaskDueDateChangeOnEdit={handleAddTaskDueDateChangeOnEdit}
						handleResetButtonClickOnEdit={handleResetButtonClickOnEdit}
						onSelectedUserOnEdit={onSelectedUserOnEdit}
						handleRemoveAssigneeOnEdit={handleRemoveAssigneeOnEdit}
						handleKRLinkOnEdit={handleKRLinkOnEdit}
						handleRemoveKROnEdit={handleRemoveKROnEdit}
						handleCompleteTaskOnEdit={handleCompleteTaskOnEdit}
						handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
						handleCollapseTask={handleCollapseTask}
						resetCalendarDate={resetCalendarDate}
						handleTaskLinkChange={handleTaskLinkChange}
						checkInUserEmpId={0}
						// searchedInEmpId={'Current'}
						dueDateError={dueDateError}
						setDueDateError={setDueDateError}
						handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
						handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
					/>
				)}
			</Box>

			{openDeleteDialog && openDeleteDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('taskDeleteMessage')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog?.open}
				/>
			)}
		</>
	);
};
