import React, { useState } from 'react';
import { Box, ClickAwayListener, Popper, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HappyEmoji, NuetralEmoji, SadEmoji, NotSetEmoji, AlertInfoIcon } from '../../config/svg/CommonSvg';
import { KrConfidence } from './KrConfidence';
import { confidenceIcon, confidenceIconId } from '../../config/utils';
import { Enums } from '../../config/enums';
import { useSnackbar } from 'notistack';
import { setLocalStorageItem } from '../../services/StorageService';

export const KrConfidencePopper: React.FC<any> = (props) => {
	const {
		kr,
		okr,
		type,
		currentUser,
		krIndex,
		handleOpenWeeklyTrend,
		confidenceValue = 0,
		canUpdateConfidence,
		updateKrData,
		showWeekelyTrend = true,
		hidePopover,
		isNew,
	} = props;
	const { t } = useTranslation();
	const keyData = type === 1 ? okr : kr;
	const id = type === 1 ? okr.goalObjectiveId : keyData.goalKeyId;
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [isApiCalled, setIsApiCalled] = useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const handleOpen = (event: any) => {
		if (!isNew) {
			setAnchorEl(event.currentTarget);
			setOpen(true);
		}
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleConfidenceUpdate = async (value: number) => {
		if (!isApiCalled) {
			setIsApiCalled(true);
			props.showLoader(true);
			const responseConfidence = await props.updateConfidenceData({
				goalKeyId: id,
				confidenceId: value,
			});
			const messages = responseConfidence?.data?.messageList?.result;
			if (responseConfidence.data.status === Enums.STATUS_SUCCESS) {
				await props.showLoader(false);
				enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
				props.updateConfidence && props.updateConfidence(okr, krIndex, value);
				setLocalStorageItem('confidenceOkr', okr.goalObjectiveId);
				setIsApiCalled(false);
			} else {
				enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
				setIsApiCalled(false);
			}
		}
	};
	return (
		<Box
			className={`confi-action-btn ${
				props.currentUser || props.isTeamView || !canUpdateConfidence ? 'people-confi-btn' : ''
			}`}
		>
			{/* {keyData?.isConfidenceAlert && (
				<Tooltip
					arrow
					title={`${type === 1 ? t('updateConfidence') : t('confidenceAlert')}`}
					classes={{ popper: `tooltip-alert  ${type === 1 ? '' : 'tooltip-layout2'}` }}
				>
					<Typography className={`confidence-alert ${type === 1 ? 'alert-okr' : ''}`}>
						<AlertInfoIcon />
					</Typography>
				</Tooltip>
			)} */}
			{type === 2 && (
				<Box
					aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup='true'
					onMouseOver={handleOpen}
					onMouseLeave={handleClose}
				>
					{/* Not Set not-set class  {`${t('notSetTooltip')}`}*/}
					<Tooltip arrow title={''} classes={{ popper: 'tooltip-layout6' }}>
						<Typography component='h6' variant='h6' id={`list-confi-${id}`} className='confi-label'>
							{confidenceIcon(confidenceValue)}
							<Typography
								component='span'
								variant='h6'
								className={`confi-text ${
									(props.currentUser || props.isTeamView || !canUpdateConfidence) && !confidenceValue
										? 'disabled-text'
										: ''
								}`}
							>
								{props.okrMasterData.confidenceMapping && props.okrMasterData.confidenceMapping[confidenceValue].name}
							</Typography>
						</Typography>
					</Tooltip>

					{/* need to add class in popper based on confidence label */}
					<Popper
						open={open && !hidePopover && !isApiCalled}
						anchorEl={anchorEl}
						transition
						container={anchorEl}
						className={`confidence-list-popper ${confidenceIconId(confidenceValue)} ${
							!showWeekelyTrend ? 'confidence-no-trend' : ''
						} ${props.currentUser || props.isTeamView || !canUpdateConfidence ? 'confidence-people-popper' : ''}`}
						placement='top-start'
					>
						<ClickAwayListener onClickAway={handleClose}>
							<KrConfidence
								{...props}
								confidenceLabel={false}
								handleOpenWeeklyTrend={handleOpenWeeklyTrend}
								handleConfidenceClick={(confidenceValue: number) => {
									if (canUpdateConfidence) {
										handleClose();
										handleConfidenceUpdate(confidenceValue);
									} else {
										updateKrData && updateKrData(confidenceValue);
										handleClose();
									}
								}}
								showWeekelyTrend={showWeekelyTrend}
								showOnlySelected={props.currentUser || props.isTeamView || !props.canUpdateConfidence}
							/>
						</ClickAwayListener>
					</Popper>
				</Box>
			)}

			{type === 3 && (
				<Box>
					<Tooltip arrow title={`${t('notSetTooltip')}`} classes={{ popper: 'tooltip-layout6' }}>
						<Typography component='h6' variant='h6' id={`list-confi-${id}`} className='confi-label'>
							{confidenceIcon(0)}
							<Typography component='span' variant='h6' className={`confi-text disabled-text`}>
								{t('notSetLabel')}
							</Typography>
						</Typography>
					</Tooltip>
				</Box>
			)}
		</Box>
	);
};
