import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';
import assessmentNodata from '../../images/assessment-no-data-img.svg';
import { getUserDetails } from '../../config/utils';

export const NoPerformanceRecord: React.FC<any> = (props: any) => {
	const { heading, subHeading, imgAlt } = props;
	const { t } = useTranslation();
	const user = getUserDetails();

	return (
		<>
			<Box className='assessment-no-record'>
				<Box className='no-record-message'>
					<Typography variant='h2'>
						<span
							dangerouslySetInnerHTML={{
								__html: heading,
							}}
						></span>
					</Typography>
					<Typography>
						<span
							dangerouslySetInnerHTML={{
								__html: subHeading,
							}}
						></span>
					</Typography>
					<img width='485' alt={imgAlt} src={assessmentNodata} />
				</Box>
			</Box>
		</>
	);
};
