import React, { useState, useRef, useEffect } from 'react';
import { Box, InputAdornment, TextField } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { AddIconSvg } from '../../../config/svg/CommonSvg';
import { CancelIcon, TickIcon } from '../../../config/svg/Action';
import { OKRButton } from '../../Common/OKRButton';
import { TaskQuickLinks } from '../TaskQuickLinks';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../config/enums';
import { getLocalStorageItem } from '../../../services/StorageService';
import { checkIsInitialListCall, formatOneOnOneRequestDate } from '../../../config/utils';
import { createGlobalTask, getAllGlobalTaskDirect, updateGlobalTask } from '../../../action/globalTask';
import { useSnackbar } from 'notistack';
import moment from 'moment';

export const CreateTask: React.FC<any> = (props: any) => {
	const {
		isLaunchFormEdited,
		setIsLaunchFormEdited,
		loader,
		setLoader,
		allTaskList,
		setAllTaskList,
		formDetails,
		setFormDetails,
		resetTabDetails,
		minDateValue,
		maxDateValue,
		setIsCreateLinkPopupOpen,
		callingFrom,
		globalTaskRequest,
		defaultTaskListRequest,
		renderTaskListDetails,
		getRecentSearchesList,
		handleSaveCallBack,
		resetHighlightOneOnOneTask = null,
		taskPagingInfo,
		setTaskPagingInfo,
		dueDateError,
		setDueDateError,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const currentCycleDetails = getLocalStorageItem('currentCycle');
	const curCycleData = currentCycleDetails ? JSON.parse(currentCycleDetails) : {};
	const inputRef: any = useRef(null);

	// const globalTaskReducer = useSelector((state: any) => state.globalTaskReducer);

	const [completeByDate, setCompleteBy] = useState<any>(null);
	const [dueDateErrorMessage, setDueDateErrorMessage] = useState<any>({ isError: false, errMsg: '' });
	const { globalTaskAutoFocus } = useSelector((state: any) => state.commonReducer);

	useEffect(() => {
		if (globalTaskAutoFocus) {
			inputRef.current.focus();
		}
	}, [globalTaskAutoFocus]);

	useEffect(() => {
		const updatedTaskList = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		if (updatedTaskList && updatedTaskList?.length && updatedTaskList.find((rec: any) => rec?.isRecentlyAdded)) {
			if (callingFrom === 'OneOnOneTask') {
				setTimeout(() => {
					resetHighlightOneOnOneTask && resetHighlightOneOnOneTask();
				}, 1500);
			} else {
				setTimeout(() => {
					resetHighlightTask(updatedTaskList);
				}, 2000);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allTaskList]);

	const handleTaskNameChange = (e: any) => {
		try {
			const { value } = e?.target || {};
			setFormDetails({ ...formDetails, taskName: value && value.trim() ? value : '' });

			setIsLaunchFormEdited(Boolean(value && value.trim() !== ''));
		} catch (error) {
			console.error(error);
			setFormDetails({ ...formDetails, taskName: '' });
			setIsLaunchFormEdited(false);
		}
	};
	const handleTaskOnEnterEdit = (e: any) => {
		if (e.keyCode === 13) {
			handleSaveCreateTaskClick(e);
		} else if (e.keyCode === 27) {
			handleCancelCreateTaskClick(e);
		}
	};

	const handleTaskLinkChange = (links: any[]) => {
		try {
			let formTaskLink: any[] = [];
			links.forEach((linkArray: any) => {
				formTaskLink.push(linkArray.link);
			});
			setFormDetails({ ...formDetails, link: links });
			//setIsLaunchFormEdited(true);
		} catch (error) {
			console.error(error);
			setFormDetails({ ...formDetails, taskLinks: '' });
			setIsLaunchFormEdited(false);
		}
	};
	const handleCancelCreateTaskClick = (e: any) => {
		resetTabDetails(false);
	};
	const handleSaveCreateTaskClick = async (e: any) => {
		try {
			// if (callingFrom === 'OneOnOneTask' && !formDetails?.taskDueDate) {
			// 	// enqueueSnackbar('Please select due date', {
			// 	// 	variant: 'error',
			// 	// 	autoHideDuration: 3000,
			// 	// });
			// 	setDueDateErrorMessage({ isError: true, errMsg: 'Please select a due date' });
			// } else {
			setDueDateError(null);
			setLoader(true);
			const isEdit = false;
			setDueDateErrorMessage({ isError: false, errMsg: '' });
			let formTaskLink: any[] = [];
			formDetails?.link?.forEach((linkArray: any) => {
				formTaskLink.push(linkArray.link);
			});
			let requestPayload: any = {
				taskName: formDetails?.taskName ? formDetails?.taskName.trim() : '',
				dueDate: formDetails?.taskDueDate
					? formatOneOnOneRequestDate(formDetails?.taskDueDate)
					: callingFrom === 'KrTask' && formDetails?.taskDueDate
					? formatOneOnOneRequestDate(formDetails?.taskDueDate)
					: callingFrom === 'CheckInTask' && props?.dueDate
					? formatOneOnOneRequestDate(props?.dueDate)
					: null,
				priority: formDetails?.priority || Enums.ONE,
				assignedTo: formDetails?.assignedTo || Enums.ZERO,
				goalTypeId: callingFrom === 'KrTask' ? props?.goalTypeId || Enums.ZERO : formDetails?.goalTypeId || Enums.ZERO,
				goalId: callingFrom === 'KrTask' ? props?.goalId || 0 : formDetails?.goalId || Enums.ZERO,
				taskType: callingFrom === 'CheckInTask' ? Enums.TWO : callingFrom === 'KrTask' ? Enums.ONE : Enums.FOUR,
				isCompleted: false,
				link: formTaskLink,
				isCheckedInLinked:
					callingFrom === 'CheckInTask' && props?.dueDate ? true : formDetails?.isCheckedInLinked || false,
			};

			if (callingFrom === 'CheckInTask') {
				requestPayload.startDate = props?.startDate || null;
			}
			// if (callingFrom === 'GlobalTask') {
			// 	requestPayload = { ...requestPayload, assignedTo: formDetails?.assignedTo || Enums.ZERO };
			// }
			if (callingFrom === 'OneOnOneTask') {
				requestPayload = {
					...requestPayload,
					oneToOneDetailId: formDetails?.oneToOneDetailId,
					linkedKRDetails: formDetails?.linkedKRDetails || null,
					priorityId: formDetails?.priority || Enums.ONE,
					isRecentlyAdded: true,
					delegateTo: formDetails?.userDetails
						? {
								employeeId: formDetails?.userDetails?.employeeId || Enums.ZERO,
								firstName: formDetails?.userDetails?.firstName || '',
								lastName: formDetails?.userDetails?.lastName || '',
								fullName: formDetails?.userDetails?.fullName || '',
								imagePath: formDetails?.userDetails?.imagePath || '',
								emailId: formDetails?.userDetails?.emailId || '',
						  }
						: null,
					link: formDetails?.link,
				};

				handleSaveCallBack && handleSaveCallBack(requestPayload);
				resetTabDetails(false);
				setLoader(false);
			} else {
				let response: any = null;
				if (isEdit) {
					response = await dispatch(updateGlobalTask(requestPayload));
				} else {
					response = await dispatch(createGlobalTask(requestPayload));
				}
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity, messageList } = response?.data || {};

					if (callingFrom === 'KrTask' || callingFrom === 'CheckInTask') {
						setLoader(false);
						resetTabDetails(true);

						const updatedRecord = {
							...entity,
							momentDueDate: entity?.dueDate ? moment(new Date(entity?.dueDate)) : null,
							historyDate: entity?.dueDate || null,
							history: entity.taskName || '',
							isRecentlyAdded: true,
						};
						props.getAllTaskCallBack(updatedRecord || null);

						if (callingFrom === 'CheckInTask') {
							const responseAPI = messageList || {};
							const keys = Object.keys(responseAPI);
							const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : [''];
							// if (messages && messages.join('')) {
							// 	enqueueSnackbar(messages, {
							// 		variant: 'success',
							// 		autoHideDuration: 3000,
							// 	});
							// }
							handleSaveCallBack && handleSaveCallBack(messages && messages.join('') ? messages.join('') : '');
						}
					} else {
						if (entity) {
							let taskListResp: any = null;
							if (!checkIsInitialListCall(defaultTaskListRequest, globalTaskRequest)) {
								resetTabDetails(true);
								taskListResp = await dispatch(getAllGlobalTaskDirect(defaultTaskListRequest));
							}
							const updatedRecord = {
								...entity,
								momentDueDate: entity?.dueDate ? moment(new Date(entity?.dueDate)) : null,
								historyDate: entity?.dueDate || null,
								history: entity.taskName || '',
								isRecentlyAdded: true,
							};
							if (taskListResp && taskListResp?.data) {
								renderTaskListDetails(taskListResp?.data, updatedRecord);
							} else {
								resetTabDetails(false);
								if (allTaskList && allTaskList.length) {
									let newList: any[] = [];
									newList.push(updatedRecord);
									let copiedList = allTaskList.map((item: any) => item);
									newList = [...newList, ...copiedList];
									setAllTaskList(newList);
								} else {
									setAllTaskList([updatedRecord]);
								}
								if (!isEdit) {
									if (taskPagingInfo) {
										const totalRecords = taskPagingInfo?.totalRecords ? taskPagingInfo.totalRecords + 1 : 1;
										setTaskPagingInfo({ ...taskPagingInfo, totalRecords: totalRecords });
									}
								}
							}
						}
						setLoader(false);
					}
					getRecentSearchesList && getRecentSearchesList();
				} else {
					const responseAPI = response?.data?.messageList || {};
					const keys = Object.keys(responseAPI);
					const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
					enqueueSnackbar(messages, {
						variant: 'error',
						autoHideDuration: 3000,
					});
					setLoader(false);
					return false;
				}
			}
			// }
		} catch (error) {
			console.error(error);
			setLoader(false);
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};
	const resetHighlightTask = (updatedTaskList: any) => {
		const tasksCopy = updatedTaskList && updatedTaskList?.length ? [...updatedTaskList] : [];
		tasksCopy.forEach((taskVal: any) => {
			taskVal.isRecentlyAdded = false;
		});

		setAllTaskList([...tasksCopy]);
	};

	const handleRemoveCheckInSelection = (e: any, isSelected: boolean) => {
		try {
			setFormDetails({ ...formDetails, isCheckedInLinked: isSelected });
		} catch (error) {
			console.error(error);
		}
	};
	const handleCheckInSelection = (e: any, isSelected: boolean) => {
		try {
			if (formDetails?.taskDueDate) {
				setFormDetails({ ...formDetails, isCheckedInLinked: isSelected });
				setDueDateError(null);
			} else {
				setDueDateError({ isError: true, itemIndex: Enums.ZERO });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handlePriorityChange = (e: any, taskType: number) => {
		try {
			setFormDetails({ ...formDetails, priority: taskType || Enums.ONE });
			setIsLaunchFormEdited(true);
		} catch (error) {
			console.error(error);
			setFormDetails({ ...formDetails, priority: Enums.ONE });
		}
	};
	const handleAddTaskDueDateChange = (value: any, range: any) => {
		try {
			range && setCompleteBy(range);
			setFormDetails({ ...formDetails, taskDueDate: value });
			setIsLaunchFormEdited(true);
		} catch (error) {
			console.error(error);
			setFormDetails({ ...formDetails, taskDueDate: null });
		}
	};
	const handleResetButtonClick = () => {
		setFormDetails({ ...formDetails, taskDueDate: null });
		setCompleteBy(null);
	};
	const onSelectedUser = (selectedUser: any) => {
		if (selectedUser && selectedUser?.employeeId) {
			setFormDetails({ ...formDetails, assignedTo: selectedUser?.employeeId || Enums.ZERO, userDetails: selectedUser });
			setIsLaunchFormEdited(true);
		}
	};
	const handleRemoveAssignee = (e: any) => {
		setFormDetails({ ...formDetails, assignedTo: Enums.ZERO, userDetails: null });
	};
	const handleKRLink = (e: any, selectedKR: any) => {
		if (selectedKR && selectedKR?.goalKeyId) {
			setFormDetails({
				...formDetails,
				goalTypeId: selectedKR?.goalKeyId ? Enums.TWO : Enums.ZERO,
				goalId: selectedKR?.goalKeyId || Enums.ZERO,
				linkedKRDetails: selectedKR,
			});
		}
	};
	const handleRemoveKR = (e: any) => {
		setFormDetails({ ...formDetails, goalTypeId: Enums.ZERO, goalId: Enums.ZERO, linkedKRDetails: null });
	};

	return (
		<Box className='create-task-block'>
			<Box className={`textfield-wrap ${formDetails?.taskName ? 'textfield-edit-mode' : ''}`}>
				<TextField
					className='add-text-field'
					inputRef={inputRef}
					name={'taskName'}
					value={formDetails?.taskName || ''}
					onChange={handleTaskNameChange}
					onKeyDown={handleTaskOnEnterEdit}
					onFocus={(e) => {
						e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
						e.currentTarget.scrollTop = e.currentTarget.scrollHeight;
					}}
					fullWidth
					multiline={false}
					maxRows={3}
					placeholder={'Add task'}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<AddIconSvg />
							</InputAdornment>
						),
					}}
				/>
				<Box className='action-btn-wrap'>
					{formDetails?.taskName ? (
						<TaskQuickLinks
							{...props}
							formDetails={formDetails}
							setFormDetails={setFormDetails}
							handlePriorityChange={handlePriorityChange}
							handleAddTaskDueDateChange={handleAddTaskDueDateChange}
							curCycleData={curCycleData}
							startDateValue={minDateValue}
							maxDateValue={maxDateValue}
							completeByDate={completeByDate}
							setCompleteBy={setCompleteBy}
							handleResetButtonClick={handleResetButtonClick}
							onSelectedUser={onSelectedUser}
							handleRemoveAssignee={handleRemoveAssignee}
							handleKRLink={handleKRLink}
							handleRemoveKR={handleRemoveKR}
							isCreateTask={true}
							isActionable={true}
							setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
							callingFrom={callingFrom}
							isKRandDueDateRemove={false}
							dueDateErrorMessage={dueDateErrorMessage}
							setDueDateErrorMessage={setDueDateErrorMessage}
							handleTaskLinkChange={handleTaskLinkChange}
							handleCheckInSelection={handleCheckInSelection}
							handleRemoveCheckInSelection={handleRemoveCheckInSelection}
							dueDateError={dueDateError && dueDateError?.itemIndex === 0 ? { isError: true } : { isError: false }}
						/>
					) : null}
					{formDetails?.taskName ? (
						<Box className='textfield-action-btn'>
							<OKRButton
								className='cancel-btn'
								id={'cancel-btn'}
								icon={<CancelIcon />}
								handleClick={handleCancelCreateTaskClick}
							/>
							<OKRButton
								className='save-btn'
								id={'save-btn'}
								icon={<TickIcon />}
								disabled={loader}
								handleClick={handleSaveCreateTaskClick}
							/>
						</Box>
					) : null}
				</Box>
			</Box>
		</Box>
	);
};
