import {
	getEmployeeAssessmentDetailsAPI,
	getSelfAssessmentAPI,
	saveAssessmentFormAPI,
	saveMultipleAssessmentFormAPI,
	getAllAssessmentData,
} from '../services/UserPerformance';
import {
	GET_EMPLOYEE_ASSESSMENT_FAILED,
	GET_EMPLOYEE_ASSESSMENT_PENDING,
	GET_EMPLOYEE_ASSESSMENT_SUCCESS,
	GET_SELF_ASSESSMENT_FAILED,
	GET_SELF_ASSESSMENT_PENDING,
	GET_SELF_ASSESSMENT_SUCCESS,
} from './actionTypes';

export const saveAssessmentForm = (data) => {
	return () => {
		return saveAssessmentFormAPI(data);
	};
};
export const getSelfAssessment = (data) => {
	return () => {
		return getSelfAssessmentAPI(data);
	};
};
export const getDownloadAssessmentData = (data) => {
	return () => {
		return getAllAssessmentData(data);
	};
};
const getSelfAssessmentFailed = (error) => ({
	type: GET_SELF_ASSESSMENT_FAILED,
	payload: error,
});
export const getSelfAssessmentPending = () => ({
	type: GET_SELF_ASSESSMENT_PENDING,
});
const getSelfAssessmentCompleted = (payload) => ({
	type: GET_SELF_ASSESSMENT_SUCCESS,
	payload,
});
export const getSelfAssessmentList = (data) => {
	return (dispatch) => {
		dispatch(getSelfAssessmentPending());
		return getSelfAssessmentAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(getSelfAssessmentCompleted(response.data));
				} else {
					dispatch(getSelfAssessmentFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getSelfAssessmentFailed(error));
			});
	};
};

// ============================================================================
export const getEmployeeAssessment = (data) => {
	return () => {
		return getEmployeeAssessmentDetailsAPI(data);
	};
};
const getEmployeeAssessmentFailed = (error) => ({
	type: GET_EMPLOYEE_ASSESSMENT_FAILED,
	payload: error,
});
export const getEmployeeAssessmentPending = () => ({
	type: GET_EMPLOYEE_ASSESSMENT_PENDING,
});
const getEmployeeAssessmentCompleted = (payload) => ({
	type: GET_EMPLOYEE_ASSESSMENT_SUCCESS,
	payload,
});
export const getEmployeeAssessmentList = (data) => {
	return (dispatch) => {
		dispatch(getEmployeeAssessmentPending());
		return getEmployeeAssessmentDetailsAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(getEmployeeAssessmentCompleted(response.data));
				} else {
					dispatch(getEmployeeAssessmentFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getEmployeeAssessmentFailed(error));
			});
	};
};

export const saveMultipleAssessmentForm = (data) => {
	return () => {
		return saveMultipleAssessmentFormAPI(data);
	};
};
