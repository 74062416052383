import React, { useState } from 'react';
import { Box, FormControlLabel, Switch, TextField, Typography, InputAdornment, FormLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../../styles/pages/admin/settings/addPerformReview.scss';
import { CalendarIcon, CommittedIcon, FinalRatingIcon, Perform360Icon } from '../../../../../config/svg/CommonSvgIcon';
import { Enums } from '../../../../../config/enums';
import { NumberFormatComponent } from '../../../../Common/NumberFormat';
import { supportModuleAlignOkr } from '../../../AdminConstant';
import { checkSupportPermissionType, formatDate } from '../../../../../config/utils';
import ReactDateRangePicker from '../../../../Common/ReactDateRangePicker';
import { SelfReviewIcon, TeamReviewIcon, WeightIcon } from '../../../../../config/svg/CommonSvg';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import AlertDialog from '../../../../Common/Dialog';
import moment from 'moment';

export const PerformanceInputStream: React.FC<any> = (props: any) => {
	const {
		performanceFormData,
		handlePerformanceReviewValueChange,
		type,
		editableDetails,
		minDateValueForAssessmentInputs,
		setIsPerformanceFormEdited,
		maxDateValue,
		selectedDateRange,
		completeByDate,
		selfRatingDuration,
		managerRatingDuration,
		threeSixtyRatingDuration,
		finalRatingDuration,
		setSelfRatingDuration,
		setManagerRatingDuration,
		setThreeSixtyRatingDuration,
		setFinalRatingDuration,
		selfRatingDurationError,
		managerRatingDurationError,
		threeSixtyRatingDurationError,
		finalRatingDurationError,
		setSelfRatingDurationError,
		setManagerRatingDurationError,
		setThreeSixtyRatingDurationError,
		setFinalRatingDurationError,
		finalRatingTotalError,
		handleFinalPublishDateTo,
		scoreVisibilityDuration,
		performanceVisibility,
		setPerformanceVisibility,
	} = props;
	const { t } = useTranslation();
	const [modalDateChanged, setModalDateChanged] = useState<any>({
		open: false,
		message: '',
		range: '',
		key: '',
		isShown: false,
	});
	const staticErrorObj = {
		error: '',
		helperText: '',
		type: '',
	};
	const isDateRangeDisable = (cardValue: boolean) => {
		const isDisable = !cardValue || type === 'View'; // || (type === 'Edit' && editableDetails.status === Enums.TWO) ? true : false;
		const isDateSelected = selectedDateRange && completeByDate ? true : false;
		return isDateSelected ? isDisable : true; // && !selectedDateRange && !completeByDate ? true : false;
	};

	const handleDateChange = (range: any, key: string, skip = false) => {
		if (0 && type === 'Edit' && !skip && !modalDateChanged.isShown) {
			setModalDateChanged({
				open: true,
				message: 'Please note that adjusting the dates will automatically update the grace period as well.',
				key,
				range,
			});
		} else if (key === 'SelfRatingDuration') {
			setSelfRatingDurationError(staticErrorObj);
			setSelfRatingDuration(range);
			setIsPerformanceFormEdited(true);
		} else if (key === 'ThreeSixtyRatingDuration') {
			setThreeSixtyRatingDurationError(staticErrorObj);
			setThreeSixtyRatingDuration(range);
			setIsPerformanceFormEdited(true);
		} else if (key === 'ManagerRatingDuration') {
			setManagerRatingDurationError(staticErrorObj);
			setManagerRatingDuration(range);
			setIsPerformanceFormEdited(true);
		} else if (key === 'FinalRatingDuration') {
			setFinalRatingDurationError(staticErrorObj);
			if (scoreVisibilityDuration && scoreVisibilityDuration !== null) {
				const diff = moment(new Date(formatDate(scoreVisibilityDuration))).diff(new Date(formatDate(range.start)));
				if (diff < 0) {
					handleFinalPublishDateTo(null);
				}
			}
			setFinalRatingDuration(range);
			let currentIndex = -1;
			let performanceVisibilityCopy = performanceVisibility.map((item: any, index: number) => {
				if (item.assessmentType === 1) {
					currentIndex = index;
				}
				return item;
			});
			if (
				performanceVisibilityCopy &&
				performanceVisibilityCopy[currentIndex] &&
				performanceVisibilityCopy[currentIndex]['finalScoreVisibleFrom']
			) {
				const diff = moment(
					new Date(formatDate(performanceVisibilityCopy[currentIndex]['finalScoreVisibleFrom']))
				).diff(new Date(formatDate(range.end)));
				if (diff < 0) {
					if (currentIndex === -1) {
					} else {
						performanceVisibilityCopy[currentIndex]['finalScoreVisibleFrom'] = null;
					}
					setPerformanceVisibility(performanceVisibilityCopy);
				}
			}

			setIsPerformanceFormEdited(true);
		}
	};

	const handlePerformanceCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			handleDateChange(modalDateChanged.range, modalDateChanged.key, true);
			setModalDateChanged({ open: false, message: '', key: '', range: '', isShown: true });
		} else {
			setModalDateChanged({ open: false, message: '', key: '', range: '', isShown: false });
		}
	};
	return (
		<Box className='col-full '>
			{checkSupportPermissionType(supportModuleAlignOkr) ? (
				<Box className='setting-blue-box committed-okr-box'>
					<Box className='setting-blue-box-head'>
						<Box className='head-left'>
							<CommittedIcon />
							<Typography>{t('committedOKRsPrg')}</Typography>
						</Box>
						<FormControlLabel
							control={
								<Switch
									name='isOkrProgressPercentage'
									disabled={type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? true : false}
									checked={Boolean(performanceFormData?.isOkrProgressPercentage)}
									onChange={handlePerformanceReviewValueChange}
								/>
							}
							label={''}
							className='toggle-label toggle-no-label'
						/>
					</Box>

					<Box className='setting-user-action'>
						<Box className='setting-des perform-txt-field'>
							{/* <Typography variant='h6'>{t('scoreFinalAssessment')}</Typography> */}
							<TextField
								fullWidth
								className='helperText-bottom'
								placeholder={t('percentScore')}
								label={
									<>
										<WeightIcon /> {t('weightageLabel')}
									</>
								}
								disabled={
									!performanceFormData?.isOkrProgressPercentage ||
									type === 'View' ||
									(type === 'Edit' && editableDetails.status === Enums.TWO)
										? true
										: false
								}
								value={performanceFormData?.okrProgressPercentage || '0'}
								onChange={handlePerformanceReviewValueChange}
								name={'okrProgressPercentage'}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									maxLength: Enums.FIVE,
									inputType: 'numberFieldProgress',
								}}
								InputProps={{
									inputComponent: NumberFormatComponent,
									'aria-label': t('percentScore'),
									disableUnderline: true,
									endAdornment: (
										<InputAdornment position='end'>
											<>%</>
										</InputAdornment>
									),
								}}
							/>
						</Box>
					</Box>
					<Box className='committed-okr-des-box'>
						<Typography variant='h6'>{t('performCommittedOkr')}</Typography>
					</Box>
				</Box>
			) : (
				<></>
			)}
			<Box className='setting-blue-box'>
				<Box className='setting-blue-box-head'>
					<Box className='head-left'>
						<SelfReviewIcon />
						<Typography>{t('selfRating')}</Typography>
					</Box>
					<FormControlLabel
						control={
							<Switch
								name='isSelfRatingPercentage'
								disabled={type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? true : false}
								onChange={handlePerformanceReviewValueChange}
								checked={Boolean(performanceFormData.isSelfRatingPercentage)}
							/>
						}
						label={''}
						className='toggle-label toggle-no-label'
					/>
				</Box>
				<Box className='setting-user-action'>
					<Box className='setting-des perform-txt-field'>
						{/* <Typography variant='h6'>{t('scoreFinalAssessment')}</Typography> */}
						<TextField
							fullWidth
							className='helperText-bottom'
							placeholder={t('percentScore')}
							label={
								<>
									<WeightIcon /> {t('weightageLabel')}
								</>
							}
							disabled={
								!performanceFormData?.isSelfRatingPercentage ||
								type === 'View' ||
								(type === 'Edit' && editableDetails.status === Enums.TWO)
									? true
									: false
							}
							value={performanceFormData?.selfRatingPercentage || '0'}
							onChange={handlePerformanceReviewValueChange}
							name={'selfRatingPercentage'}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								maxLength: Enums.FIVE,
								inputType: 'numberFieldProgress',
							}}
							InputProps={{
								inputComponent: NumberFormatComponent,
								'aria-label': t('percentScore'),
								disableUnderline: true,
								endAdornment: (
									<InputAdornment position='end'>
										<>%</>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</Box>
				<Box
					className={`helperText-bottom perform-txt-field drawer-date-field ${
						selfRatingDurationError?.type === 'selfAssessmentDate' && selfRatingDurationError?.helperText
							? 'custom-error-field'
							: ''
					}`}
				>
					<FormLabel
						id='set-a-duration'
						component='legend'
						className={`${isDateRangeDisable(performanceFormData?.isSelfRatingPercentage) ? 'disable-label' : ''}`}
					>
						<CalendarIcon />
						{t('durationLevel')}
						<sup>*</sup>
					</FormLabel>
					<ReactDateRangePicker
						type={'ContributorList'}
						selectedCycleDetails={{}}
						startDateValue={type !== 'Add' && editableDetails ? editableDetails?.selfRatingStartDate : null}
						endDateValue={type !== 'Add' && editableDetails ? editableDetails?.selfRatingEndDate : null}
						minimumDate={minDateValueForAssessmentInputs || new Date()}
						maximumDate={completeByDate ? new Date(completeByDate) : maxDateValue ? new Date(maxDateValue) : new Date()}
						handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
							if (range) {
								handleDateChange(range, 'SelfRatingDuration', false);
							}
						}}
						isDateRangeTextFieldVisible={true}
						isOnlyDueDateVisible={false}
						allCycleDetails={[]}
						isCycleVisible={false}
						isEndOfMonthVisible={false}
						numberOfCalendars={2}
						selectionType={'range'}
						selectedDateRange={selfRatingDuration}
						setSelectedDateRange={setSelfRatingDuration}
						placeholderText={t('selectedDateRange')}
						isDatePickerDisable={isDateRangeDisable(performanceFormData?.isSelfRatingPercentage)}
						isTooltip={true}
					/>

					{/* {selfRatingDurationError?.type === 'selfAssessmentDate' && selfRatingDurationError?.helperText ? (
						<Typography className='error-field'>
							<ErrorIcon /> {selfRatingDurationError?.helperText}
						</Typography>
					) : (
						<></>
					)} */}
				</Box>
			</Box>
			<Box className='setting-blue-box'>
				<Box className='setting-blue-box-head'>
					<Box className='head-left'>
						<Perform360Icon />
						<Typography dangerouslySetInnerHTML={{ __html: t('assessment360Rating') }}></Typography>
					</Box>
					<FormControlLabel
						control={
							<Switch
								// name='isPerform360'
								name='isThreeSixtyEnabled'
								disabled={type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? true : false}
								checked={Boolean(performanceFormData?.isThreeSixtyEnabled)}
								onChange={handlePerformanceReviewValueChange}
							/>
						}
						label={''}
						className='toggle-label toggle-no-label'
					/>
				</Box>
				<Box className='setting-user-action'>
					<Box className='setting-des perform-txt-field'>
						{/* <Typography variant='h6'>{t('scoreFinalAssessment')}</Typography> */}
						<TextField
							fullWidth
							className='helperText-bottom'
							placeholder={t('percentScore')}
							label={
								<>
									<WeightIcon /> {t('weightageLabel')}
								</>
							}
							disabled={
								!performanceFormData?.isThreeSixtyEnabled ||
								type === 'View' ||
								(type === 'Edit' && editableDetails.status === Enums.TWO)
									? true
									: false
							}
							value={performanceFormData?.threeSixtyPercentage || '0'}
							onChange={handlePerformanceReviewValueChange}
							name={'threeSixtyPercentage'}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								maxLength: Enums.FIVE,
								inputType: 'numberFieldProgress',
							}}
							InputProps={{
								inputComponent: NumberFormatComponent,
								'aria-label': t('percentScore'),
								disableUnderline: true,
								endAdornment: (
									<InputAdornment position='end'>
										<>%</>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</Box>
				<Box
					className={`helperText-bottom perform-txt-field drawer-date-field ${
						threeSixtyRatingDurationError?.type === 'threeSixtyAssessmentDate' &&
						threeSixtyRatingDurationError?.helperText
							? 'custom-error-field'
							: ''
					}`}
				>
					<FormLabel
						id='set-a-duration'
						component='legend'
						className={`${isDateRangeDisable(performanceFormData?.isThreeSixtyEnabled) ? 'disable-label' : ''}`}
					>
						<CalendarIcon />
						{t('durationLevel')}
						<sup>*</sup>
					</FormLabel>
					<ReactDateRangePicker
						type={'ContributorList'}
						selectedCycleDetails={{}}
						startDateValue={type !== 'Add' && editableDetails ? editableDetails?.threeSixtyStartDate : null}
						endDateValue={type !== 'Add' && editableDetails ? editableDetails?.threeSixtyEndDate : null}
						minimumDate={minDateValueForAssessmentInputs || new Date()}
						maximumDate={completeByDate ? new Date(completeByDate) : maxDateValue ? new Date(maxDateValue) : new Date()}
						handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
							if (range) {
								handleDateChange(range, 'ThreeSixtyRatingDuration', false);
							}
						}}
						isDateRangeTextFieldVisible={true}
						isOnlyDueDateVisible={false}
						allCycleDetails={[]}
						isCycleVisible={false}
						isEndOfMonthVisible={false}
						numberOfCalendars={2}
						selectionType={'range'}
						selectedDateRange={threeSixtyRatingDuration}
						setSelectedDateRange={setThreeSixtyRatingDuration}
						placeholderText={t('selectedDateRange')}
						isDatePickerDisable={isDateRangeDisable(performanceFormData?.isThreeSixtyEnabled)}
						isTooltip={true}
					/>

					{/* {threeSixtyRatingDurationError?.type === 'threeSixtyAssessmentDate' &&
					threeSixtyRatingDurationError?.helperText ? (
						<Typography className='error-field'>
							<ErrorIcon /> {threeSixtyRatingDurationError?.helperText}
						</Typography>
					) : (
						<></>
					)} */}
				</Box>
			</Box>
			<Box className='setting-blue-box'>
				<Box className='setting-blue-box-head'>
					<Box className='head-left'>
						<TeamReviewIcon />
						<Typography>{t('managerRating')}</Typography>
					</Box>
					{/* <FormControlLabel
						control={
							<Switch
								disabled={true}
								name='isManagerRatingPercentage'
								onChange={handlePerformanceReviewValueChange}
								checked={Boolean(performanceFormData.isManagerRatingPercentage)}
							/>
						}
						label={''}
					/> */}
				</Box>
				<Box className='setting-user-action'>
					<Box className='setting-des perform-txt-field'>
						{/* <Typography variant='h6'>{t('scoreFinalAssessment')}</Typography> */}
						<TextField
							fullWidth
							className='helperText-bottom'
							placeholder={t('percentScore')}
							label={
								<>
									<WeightIcon /> {t('weightageLabel')}
								</>
							}
							disabled={
								!performanceFormData?.isManagerRatingPercentage ||
								type === 'View' ||
								(type === 'Edit' && editableDetails.status === Enums.TWO)
									? true
									: false
							}
							value={performanceFormData?.managerRatingPercentage || '0'}
							onChange={handlePerformanceReviewValueChange}
							name={'managerRatingPercentage'}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								maxLength: Enums.FIVE,
								inputType: 'numberFieldProgress',
							}}
							InputProps={{
								inputComponent: NumberFormatComponent,
								'aria-label': t('percentScore'),
								disableUnderline: true,
								endAdornment: (
									<InputAdornment position='end'>
										<>%</>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</Box>
				<Box
					className={`helperText-bottom perform-txt-field drawer-date-field ${
						managerRatingDurationError?.type === 'managerAssessmentDate' && managerRatingDurationError?.helperText
							? 'custom-error-field'
							: ''
					}`}
				>
					<FormLabel
						id='set-a-duration'
						component='legend'
						className={`${isDateRangeDisable(performanceFormData?.isManagerRatingPercentage) ? 'disable-label' : ''}`}
					>
						<CalendarIcon />
						{t('durationLevel')}
						<sup>*</sup>
					</FormLabel>
					<ReactDateRangePicker
						type={'ContributorList'}
						selectedCycleDetails={{}}
						startDateValue={type !== 'Add' && editableDetails ? editableDetails?.managerRatingStartDate : null}
						endDateValue={type !== 'Add' && editableDetails ? editableDetails?.managerRatingEndDate : null}
						minimumDate={minDateValueForAssessmentInputs || new Date()}
						maximumDate={completeByDate ? new Date(completeByDate) : maxDateValue ? new Date(maxDateValue) : new Date()}
						handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
							if (range) {
								handleDateChange(range, 'ManagerRatingDuration', false);
							}
						}}
						isDateRangeTextFieldVisible={true}
						isOnlyDueDateVisible={false}
						allCycleDetails={[]}
						isCycleVisible={false}
						isEndOfMonthVisible={false}
						numberOfCalendars={2}
						selectionType={'range'}
						selectedDateRange={managerRatingDuration}
						setSelectedDateRange={setManagerRatingDuration}
						placeholderText={t('selectedDateRange')}
						isDatePickerDisable={isDateRangeDisable(performanceFormData?.isManagerRatingPercentage)}
						isTooltip={true}
					/>

					{/* {managerRatingDurationError?.type === 'managerAssessmentDate' && managerRatingDurationError?.helperText ? (
						<Typography className='error-field'>
							<ErrorIcon /> {managerRatingDurationError?.helperText}
						</Typography>
					) : (
						<></>
					)} */}
				</Box>
			</Box>

			<Box className='setting-blue-box final-rating-box'>
				<Box className='setting-blue-box-head'>
					<Box className='head-left'>
						<FinalRatingIcon />
						<Typography>{t('finalRatingLabel')}</Typography>
					</Box>
				</Box>
				<Box className='setting-user-action'>
					<Typography className='equal' component={'span'} variant='h2'>
						=
					</Typography>
					{/* <FormControlLabel
						control={<></>}
						label={
							<>
								<WeightIcon /> {t('weightageLabel')}
							</>
						}
					/> */}
					<Box className='setting-des perform-txt-field'>
						{/* <Typography variant='h6'>{t('scoreFinalAssessment')}</Typography> */}
						<TextField
							fullWidth
							className='helperText-bottom'
							placeholder={t('percentScore')}
							label={
								<>
									<WeightIcon /> {t('totalWeightage')}
								</>
							}
							disabled={true}
							value={performanceFormData?.finalRatingPercentage || '100'}
							name={'finalRatingPercentage'}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								maxLength: Enums.FIVE,
								inputType: 'numberFieldProgress',
							}}
							InputProps={{
								inputComponent: NumberFormatComponent,
								'aria-label': t('percentScore'),
								disableUnderline: true,
								endAdornment: (
									<InputAdornment position='end'>
										<>%</>
									</InputAdornment>
								),
							}}
							error={
								finalRatingTotalError?.type === 'finalRatingTotal' && finalRatingTotalError?.helperText ? true : false
							}
						/>
					</Box>
				</Box>
				<Box
					className={`helperText-bottom perform-txt-field drawer-date-field ${
						finalRatingDurationError?.type === 'finalRatingDate' && finalRatingDurationError?.helperText
							? 'custom-error-field'
							: ''
					}`}
				>
					<FormLabel
						id='set-a-duration'
						component='legend'
						className={`${isDateRangeDisable(performanceFormData?.finalRatingPercentage) ? 'disable-label' : ''}`}
					>
						<CalendarIcon />
						{t('durationLevel')}
						<sup>*</sup>
					</FormLabel>
					<ReactDateRangePicker
						type={'ContributorList'}
						selectedCycleDetails={{}}
						startDateValue={type !== 'Add' && editableDetails ? editableDetails?.finalRatingStartDate : null}
						endDateValue={type !== 'Add' && editableDetails ? editableDetails?.finalRatingEndDate : null}
						minimumDate={minDateValueForAssessmentInputs || new Date()}
						maximumDate={completeByDate ? new Date(completeByDate) : maxDateValue ? new Date(maxDateValue) : new Date()}
						handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
							if (range) {
								handleDateChange(range, 'FinalRatingDuration', false);
							}
						}}
						isDateRangeTextFieldVisible={true}
						isOnlyDueDateVisible={false}
						allCycleDetails={[]}
						isCycleVisible={false}
						isEndOfMonthVisible={false}
						numberOfCalendars={2}
						selectionType={'range'}
						selectedDateRange={finalRatingDuration}
						setSelectedDateRange={setFinalRatingDuration}
						placeholderText={t('selectedDateRange')}
						isDatePickerDisable={isDateRangeDisable(performanceFormData?.finalRatingPercentage)}
						isTooltip={true}
						// isDatePickerDisable={
						// 	(!selectedDateRange && !completeByDate) ||
						// 	type === 'View' ||
						// 	(type === 'Edit' && editableDetails.status === Enums.TWO)
						// 		? true
						// 		: false
						// }
					/>

					{/* {finalRatingDurationError?.type === 'finalRatingDate' && finalRatingDurationError?.helperText ? (
						<Typography className='error-field final-error-field'>
							<ErrorIcon /> {finalRatingDurationError?.helperText}
						</Typography>
					) : (
						<></>
					)} */}
				</Box>
			</Box>
			{modalDateChanged && modalDateChanged.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalDateChanged?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={modalDateChanged.open}
				/>
			)}
		</Box>
	);
};
