import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
	Avatar,
	Box,
	Typography,
	ListItem,
	List,
	TextField,
	InputAdornment,
	Button,
	Chip,
	Popover,
	Tooltip,
	Badge,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import {
	getUserName,
	getRandomBackgroundColor,
	getRandomColor,
	formatDate,
	abbreviateNumber,
	getCurrentQuarterAndYear,
} from '../../../config/utils';
import {
	AddIconSvg,
	AlertInfoIcon,
	DeleteIcon,
	EditIcon,
	ExternalContributorIcon,
} from '../../../config/svg/CommonSvg';
import { Enums } from '../../../config/enums';
import { NumberFormatComponent } from '../../Common/NumberFormat';
import { NumbersIcon, PercentageIcon } from '../../../config/svg/MyGoalSvg';
import moment from 'moment';
import { OKRDrawer } from '../../Common/OKRDrawer';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { OKRButton } from '../../Common/OKRButton';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import '../../../styles/pages/drawer/assignTargetPanel.scss';
import { CancelIcon } from '../../../config/svg/Action';
import { ErrorIcon } from '../../../config/svg/formElementIcons';

export const ContributorList: React.FC<any> = (props) => {
	const {
		open,
		t,
		keyAttribute,
		contributorList,
		completeOrgData,
		getCurrencyIcon,
		selectedMetricDetail,
		handleBack,
		handleNext,
		handleCancel,
		targetValue,
		krStartValue = 0,
		setCallOut,
		anchorElCallouts,
		setAnchorElCallouts,
		initialCallout,
		showAssignKrDateCallouts,
		setShowAssignKrDateCallouts,
		showAssignKrTargetCallouts,
		setShowAssignKrTargetCallouts,
		setShowAssignKrTitleCallouts,
		showAssignKrTitleCallouts,
		calloutsData,
		saveButtonText,
		onDeleteContributor,
		updateGroupTargetValue,
		isDeleteInProgress,
		isLocked,
	} = props;
	const userDetail: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	const [selectedUsersData, setSelectedUsersData] = useState<any[]>([]);
	const [openTarget, setOpenTarget] = useState<boolean>(false);
	const [openId, setOpenId] = useState<any>(0);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [prevValue, setPrevValue] = useState<any>({});
	const [hasError, setHasError] = useState<boolean>(false);
	const { year } = getCurrentQuarterAndYear();
	const [subModalProps, setSubModalProps] = useState<any>(null);
	const [defaultSelectedUsersData, setDefaultSelectedUsersData] = useState([]);
	const [saveClicked, setSaveClicked] = useState<boolean>(false);
	const [defaultIsGroupTargetValue, setDefaultIsGroupTargetValue] = useState<boolean>(
		keyAttribute?.isGroupTarget || false
	);
	const [isGroupTarget, setIsGroupTarget] = useState<boolean>(
		keyAttribute?.id?.includes('-') ? keyAttribute?.isGroupTarget : keyAttribute?.isGroupTarget || false
	);
	const [groupTargetChangeProps, setGroupTargetChangeProps] = useState<any>({ open: false, message: '', details: {} });

	useEffect(() => {
		setDefaultIsGroupTargetValue(keyAttribute?.isGroupTarget || false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		// if (selectedMetricDetail.selectedMetric?.metricId !== Enums.ONE) {
		setIsGroupTarget(
			keyAttribute?.id?.includes('-') ? keyAttribute?.isGroupTarget : keyAttribute?.isGroupTarget || false
		);
		// } else {
		// 	setIsGroupTarget(false);
		// }
	}, [selectedMetricDetail, keyAttribute]);
	useEffect(() => {
		if (contributorList && contributorList.length > 0 && completeOrgData.length > 0) {
			const isGroup = keyAttribute?.id?.includes('-')
				? keyAttribute?.isGroupTarget
				: keyAttribute?.isGroupTarget || false;
			const updatedContributorList = contributorList?.map((item: any) => {
				if (isGroup) {
					item.targetValue = targetValue;
					item.startValue = krStartValue;
					item.isGroupTarget = isGroup;
				}
				return item;
			});
			setSelectedUsersData(updatedContributorList);
		}
	}, [completeOrgData, contributorList]);

	const checkNumber = (value: any, oldValue: any) => {
		const regexp = /^[0-9\b]+$/;
		const regexpWithDecimal = /^(\d*\.)?\d+$/;
		if (selectedMetricDetail.selectedMetric.metricId && selectedMetricDetail.selectedMetric.metricId === 2) {
			// this is for decimal
			if (value === '') {
				return '';
			} else if (value !== '' || regexpWithDecimal.test(value)) {
				return parseFloat(!Boolean(value) ? 0 : value);
			} else {
				return oldValue;
			}
		} else {
			// this is for number only
			if (value === '') {
				return '';
			} else if (value !== '' || regexp.test(value)) {
				return parseInt(!Boolean(value) ? 0 : value);
			} else {
				return oldValue;
			}
		}
	};
	const getDateDifference = (date: any, otherDate: any, keyName: string) => {
		const datesDifferenceInDays = moment(new Date(otherDate || new Date())).isAfter(
			new Date(formatDate(date) || new Date()),
			'days'
		);
		const daysDiff = moment(new Date(formatDate(date) || new Date())).diff(
			moment(new Date(otherDate || new Date())),
			'days'
		);
		return keyName === 'dueDate' ? (daysDiff > 0 ? date : '') : datesDifferenceInDays ? date : '';
	};
	const getValidField = (field: string, fieldType: string, value: any, treeData: any) => {
		if (fieldType === 'date') {
			let otherKey = 'dueDate';
			let errorMsg = 'Start date should be greater than due date';
			if (field === 'dueDate') {
				otherKey = 'startDate';
				errorMsg = 'Due date should not be lesser than start date';
			}
			if (treeData[otherKey]) {
				treeData[field] = getDateDifference(value, treeData[otherKey], field);
			} else {
				treeData[field] = value;
			}
			if (treeData[field] === '') {
				if (treeData['invalid']) {
					treeData['invalid'][field] = errorMsg;
				} else {
					treeData['invalid'] = {};
					treeData['invalid'][field] = errorMsg;
				}
			} else if (treeData['invalid']) {
				delete treeData['invalid'][field];
			}
		} else {
			treeData[field] = checkNumber(value, treeData[field]);
			if (treeData['targetValue'] && treeData['startValue'] && treeData['targetValue'] === treeData['startValue']) {
				if (treeData['invalid']) {
					treeData['invalid']['targetValue'] = 'Current and Target value is same';
				} else {
					treeData['invalid'] = { targetValue: 'Current and Target value is same' };
				}
			} else if (treeData['targetValue'] && treeData['targetValue'] !== treeData['startValue'] && treeData['invalid']) {
				delete treeData['invalid']['targetValue'];
			}
		}
		return treeData;
	};
	const valueChanged = (field: any, fieldType: any, value: any, treeData: any) => {
		if (fieldType === 'date') {
			value = formatDate(value);
		}
		let nodeData: any = getValidField(field, fieldType, value, treeData);
		let treeData1 = selectedUsersData.map((item: any) => item);
		treeData1.forEach((element: any) => {
			if (element.id === nodeData.id) {
				element = nodeData;
			}
		});
		setSelectedUsersData(treeData1);
	};
	const changeGroupTargetDetail = (name: any, checked: any) => {
		selectedUsersData.length > 0 &&
			selectedUsersData.forEach((selectedUser: any, index: number) => {
				const startValueD = checked
					? krStartValue
					: selectedUser?.employeeId === userDetail?.employeeId
					? krStartValue
					: 0;
				const targetValueD = checked
					? targetValue
					: selectedUser?.employeeId === userDetail?.employeeId
					? targetValue
					: 0;
				selectedUser[name] = checked;
				valueChanged('startValue', 'text', startValueD, selectedUser);
				setTimeout(() => {
					valueChanged('targetValue', 'text', targetValueD, selectedUser);
				}, 1000);
			});
		setIsGroupTarget(checked || false);
		updateGroupTargetValue && updateGroupTargetValue(checked);
	};
	const handleGroupTargetClick = (e: any) => {
		const { name, checked } = e?.target || {};
		if (keyAttribute?.goalKeyId && keyAttribute?.goalStatusId !== Enums.GOAL_STATUS_DRAFT) {
			setGroupTargetChangeProps({ open: true, message: t('groupTargetChangeMessage'), details: { name, checked } });
		} else {
			changeGroupTargetDetail(name, checked);
		}
	};
	const handleCloseGroupTargetModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			const { name, checked } = groupTargetChangeProps?.details || {};
			changeGroupTargetDetail(name, checked);
			setGroupTargetChangeProps({ open: false, message: '', details: {} });
		} else {
			setGroupTargetChangeProps({ open: false, message: '', details: {} });
		}
	};

	const getCurrencyIconSpan = () => {
		let icon: any = '';
		if (props.selectedMetricDetail.selectedMetric.name === 'Percentage') {
			icon = <PercentageIcon />;
		} else if (props.selectedMetricDetail.selectedMetric.name === 'Numbers') {
			icon = <NumbersIcon />;
		} else {
			icon = props.getCurrencyIcon(
				props.selectedMetricDetail.selectedCurrencyDetail?.name
					? props.selectedMetricDetail.selectedCurrencyDetail.name
					: 'Dollar'
			);
		}
		return <span className='currency-span'>{icon}</span>;
	};
	const handleTarget = (e: any, selectedUser: any) => {
		e.preventDefault();
		e.stopPropagation();
		let data = Object.assign({}, selectedUser);
		setPrevValue(data);
		setAnchorEl(e.currentTarget);
		setOpenTarget(anchorEl ? false : true);
		setOpenId(data.id);
	};
	const handleClosePopup = () => {
		setAnchorEl(null);
		setOpenTarget(false);
		setOpenId(0);
		setPrevValue({});
	};
	const isSaveButtonActive = (checkValid = false) => {
		let isError: boolean = false;
		if (
			selectedMetricDetail &&
			selectedMetricDetail.selectedMetric.metricId !== 5 &&
			selectedMetricDetail.selectedMetric.metricId !== 4
		) {
			const selectedUsersDataList = selectedUsersData.map((item: any) => item);
			let isPositiveFlow = parseInt(keyAttribute.startValue || 0) < parseInt(keyAttribute.targetValue || 0);

			selectedUsersDataList.forEach((item: any) => {
				const start = parseInt(item.startValue === '' ? 0 : item.startValue);
				const target = parseInt(item.targetValue === '' ? 0 : item.targetValue);
				if ((item.isKrOwner && target > 0 && start === target) || (!item.isKrOwner && start === target)) {
					item['invalid'] = {};
					item['invalid']['targetValue'] = t('startTargetSame');
					isError = true;
				}
				if ((isPositiveFlow && start > target) || (!isPositiveFlow && start < target)) {
					item['invalid'] = {};
					if (isPositiveFlow) {
						item['invalid']['targetValue'] = t('startValueCannotBeGreater');
					} else {
						item['invalid']['startValue'] = t('startValueCannotBeLesser');
					}
					isError = true;
				}
			});
			setSelectedUsersData(selectedUsersDataList);
		}
		return isError;
	};
	//callouts
	const assignTarget: any = useRef();
	const inputRefcommonDueDate: any = useRef();
	const inputRefcommonDueDateOther: any = useRef();
	const inputRefstartTarget: any = useRef();
	const inputRefstartTargetOther: any = useRef();
	useEffect(() => {
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel('assignTargetDrawer');
		}, 500);
		let listContributors = JSON.parse(JSON.stringify(contributorList));
		setDefaultSelectedUsersData(listContributors);

		return () => {
			props.updateCalloutPanel && props.updateCalloutPanel('');
		};
	}, []);
	useEffect(() => {
		setShowAssignKrTargetCallouts && setShowAssignKrTargetCallouts(false);
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		if (
			open &&
			props.activeOnboardingPanel === 'assignTargetDrawer' &&
			getLocalStorageItem('showCallouts') &&
			getLocalStorageItem('assignTargetDrawercalloutDone') === null &&
			assignTarget.current
		) {
			setTimeout(() => {
				setAnchorElCallouts && setAnchorElCallouts(assignTarget.current);
				setCallOut && setCallOut(calloutsData[6]);
			}, 700);
		} else {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
		}
	}, [props.activeOnboardingPanel && open]);
	useEffect(() => {
		//Callout for prev Assign Kr Title
		if (showAssignKrTitleCallouts && assignTarget) {
			setAnchorElCallouts && setAnchorElCallouts(assignTarget.current);
			setCallOut && setCallOut(calloutsData[6]);
			setShowAssignKrTargetCallouts(false);
		}
	}, [showAssignKrTitleCallouts]);
	useEffect(() => {
		//Callout for Assign Due Date
		if (showAssignKrDateCallouts && inputRefcommonDueDate && inputRefcommonDueDate.current) {
			setAnchorElCallouts && setAnchorElCallouts(inputRefcommonDueDate.current);
			setCallOut && setCallOut(calloutsData[8]);
			setShowAssignKrTargetCallouts(false);
		}
	}, [showAssignKrDateCallouts]);
	useEffect(() => {
		//Callout for Assign Target
		if (showAssignKrTargetCallouts && inputRefstartTarget && inputRefstartTarget.current) {
			setShowAssignKrDateCallouts(false);
			setShowAssignKrTitleCallouts(false);
			setAnchorElCallouts && setAnchorElCallouts(inputRefstartTarget.current);
			if (selectedMetricDetail.selectedMetric.name === 'NoUnits') {
				setCallOut && setCallOut({ ...calloutsData[7], text: 'No Unit does not require targets' });
			} else if (selectedMetricDetail.selectedMetric.name === 'Boolean') {
				setCallOut && setCallOut({ ...calloutsData[7], text: 'Bullion Unit is simply measured in Y/N' });
			} else {
				setCallOut && setCallOut(calloutsData[7]);
			}
		}
	}, [showAssignKrTargetCallouts]);

	const checkAssignContributorUpdated = (e: any) => {
		let isUpdated: boolean = false;
		if (
			selectedMetricDetail &&
			selectedMetricDetail.selectedMetric.metricId !== 5 &&
			selectedMetricDetail.selectedMetric.metricId !== 4
		) {
			contributorList.forEach((userData: any, index: any) => {
				if (
					userData.dueDate !== defaultSelectedUsersData[index]['dueDate'] ||
					userData.startValue !== defaultSelectedUsersData[index]['startValue'] ||
					userData.targetValue !== defaultSelectedUsersData[index]['targetValue']
				) {
					isUpdated = true;
				}
			});
		} else {
			contributorList.forEach((userData: any, index: any) => {
				if (userData.dueDate !== defaultSelectedUsersData[index]['dueDate']) {
					isUpdated = true;
				}
			});
		}
		if (isUpdated === false) {
			handleCancel(e);

			updateGroupTargetValue && updateGroupTargetValue(defaultIsGroupTargetValue);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		} else {
			setSubModalProps(true);
		}
	};
	const handleCloseSubModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
			handleCancel(event);

			updateGroupTargetValue && updateGroupTargetValue(defaultIsGroupTargetValue);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		}
		setSubModalProps(false);
	};

	const getMetricCurrencyIconString = (value: any) => {
		let component: string = '';
		if (selectedMetricDetail.selectedMetric.name === 'Currency') {
			component =
				(selectedMetricDetail.selectedCurrencyDetail.symbol
					? selectedMetricDetail.selectedCurrencyDetail.symbol
					: 'Dollar') +
				'' +
				value;
		} else if (selectedMetricDetail.selectedMetric.name === 'Percentage') {
			component = value + '%';
		} else if (selectedMetricDetail.selectedMetric.name === 'Numbers') {
			component = '#' + value;
		} else if (
			selectedMetricDetail.selectedMetric.metricId === 4 ||
			selectedMetricDetail.selectedMetric.metricId === 5
		) {
			component = selectedMetricDetail.selectedMetric.description + ' ' + value;
		}
		return component;
	};

	return (
		<Fragment>
			{open ? (
				<Box
					className={
						keyAttribute && !keyAttribute.goalKeyId ? 'createdPopover' : 'contributor-details-popover teams-section-kr'
					}
				>
					<OKRDrawer
						open={open}
						transitionDuration={{ enter: 500, exit: 1000 }}
						headerTitle={t('contributorsText')}
						headerPostFix={
							targetValue === 0 ? '' : { value: targetValue, icon: getCurrencyIconSpan(), krStartValue: krStartValue }
						}
						children={
							<Box className='drawer-inner-content'>
								<Box className='drawer-content-subpanel'>
									<Box className='assign-target-panel' {...({ ref: assignTarget } as any)}>
										{(keyAttribute?.importedId === Enums.ZERO && keyAttribute?.id?.includes('-')) ||
										(keyAttribute?.importedId === Enums.ZERO &&
											!keyAttribute?.id?.includes('-') &&
											keyAttribute?.goalKeyId) ? (
											<Box className='group-target-switch'>
												<FormControlLabel
													control={
														<Switch
															checked={isGroupTarget || false}
															name='isGroupTarget'
															inputProps={{ 'aria-label': 'controlled' }}
															disabled={isLocked}
															onClick={handleGroupTargetClick}
														/>
													}
													label={t('groupTargetLabel')}
													labelPlacement='end'
													className='toggle-label'
												/>
											</Box>
										) : (
											<></>
										)}
										<Box className='target-assignment-msg'>
											{/* <Typography variant='h5' className='under-assigned'>
												{t('underAssigned')}
											</Typography> */}
											{!isGroupTarget && !keyAttribute?.isMetricChanged && keyAttribute?.keyValueDeviation > 0 && (
												<Typography variant='h5' className='over-assigned'>
													{t('overAssigned')} {getMetricCurrencyIconString(keyAttribute?.keyValueDeviation)}
												</Typography>
											)}
										</Box>
										<Box
											className={`kr-contributor-listing ${isGroupTarget ? 'group-contributor-listing' : ''}`}
											onClick={() => {}}
										>
											<Box className='kr-contributor-list-head'>
												<List component='ul'>
													<ListItem className='user-col1'>
														<Typography variant='body2'>{t('nameLabel')}</Typography>
													</ListItem>
													{isGroupTarget ? null : (
														<ListItem className='user-col2'>
															<Typography variant='body2'>{t('startTarget')}</Typography>
														</ListItem>
													)}
													<ListItem className='user-col3'>
														<Typography variant='body2'>{t('dueDate')}</Typography>
													</ListItem>
												</List>
											</Box>

											<Box className='kr-contributor-list-content'>
												{selectedUsersData.length > 0 &&
													selectedUsersData.map((selectedUser: any, index: number) => (
														<Box
															className={`kr-contributor-list-row ${selectedUser.isActive === false ? 'disabled' : ''}`}
															id={`kr-contributors-list-${selectedUser.employeeId}`}
														>
															<Box>
																<List component='ul'>
																	<ListItem className='user-col1' id={`kr-contributor-list-${selectedUser.employeeId}`}>
																		<Box className='user-info'>
																			<Box className='user-img' id={`kr-contributor-avatar-${selectedUser.employeeId}`}>
																				{selectedUser.isActive === false ? (
																					<Tooltip
																						placement='top'
																						arrow
																						classes={{ popper: 'tooltip-alert' }}
																						title={t('pendingTargetDistributionMsg')}
																					>
																						<Badge
																							className={'badge-icon okr-badge-icon'}
																							overlap='rectangular'
																							badgeContent={<AlertInfoIcon />}
																						></Badge>
																					</Tooltip>
																				) : (
																					<></>
																				)}

																				{selectedUser && selectedUser.imagePath ? (
																					<Avatar alt={`${selectedUser.fullname}`} src={selectedUser.imagePath} />
																				) : (
																					<Avatar
																						style={{
																							backgroundColor: getRandomBackgroundColor(
																								selectedUser.backGroundColorCode
																							),
																							color: getRandomColor(selectedUser.colorCode),
																						}}
																					>
																						{getUserName({
																							firstName: '',
																							lastName: '',
																							fullName: selectedUser.fullName,
																						})}
																					</Avatar>
																				)}
																			</Box>

																			<Box className='user-info-details'>
																				{selectedUser?.fullName &&
																				selectedUser?.fullName.length > 15 &&
																				!isGroupTarget ? (
																					<Tooltip title={selectedUser.fullName} arrow>
																						<Typography
																							variant='h4'
																							id={`kr-contributor-name-${selectedUser.employeeId}`}
																						>
																							{selectedUser.fullName.substring(0, 15) + '...'}
																						</Typography>
																					</Tooltip>
																				) : (
																					<Typography
																						variant='h4'
																						id={`kr-contributor-name-${selectedUser.employeeId}`}
																					>
																						{selectedUser.fullName}
																					</Typography>
																				)}
																				{!selectedUser.isKrOwner &&
																					(selectedUser?.emailId &&
																					selectedUser?.emailId.length > 25 &&
																					!isGroupTarget ? (
																						<Tooltip title={selectedUser.emailId} arrow>
																							<Typography variant='subtitle2'>
																								{selectedUser.emailId.substring(0, 25) + '...'}
																							</Typography>
																						</Tooltip>
																					) : (
																						<Typography variant='subtitle2'>{selectedUser.emailId}</Typography>
																					))}
																				{/* {selectedUser.secondaryText ? (
																					selectedUser?.secondaryText && selectedUser?.secondaryText.length > 25 ? (
																						<Tooltip title={selectedUser.secondaryText} arrow>
																							<Typography
																								variant='subtitle2'
																								id={`kr-contributor-designation-${selectedUser.employeeId}`}
																							>
																								<span className='secondary-text-span'>
																									{selectedUser.secondaryText.substring(0, 25) + '...'}
																								</span>
																							</Typography>
																						</Tooltip>
																					) : (
																						<Typography
																							variant='subtitle2'
																							id={`kr-contributor-designation-${selectedUser.employeeId}`}
																						>
																							<span className='secondary-text-span'>{selectedUser.secondaryText}</span>
																						</Typography>
																					)
																				) : (
																					''
																				)} */}
																				{selectedUser.isKrOwner ? (
																					<>
																						{userDetail?.emailId &&
																						userDetail?.emailId.length > 25 &&
																						!isGroupTarget ? (
																							<Tooltip title={userDetail.emailId} arrow>
																								<Typography variant='subtitle2'>
																									{userDetail.emailId.substring(0, 25) + '...'}
																								</Typography>
																							</Tooltip>
																						) : (
																							<Typography variant='subtitle2'>{userDetail.emailId}</Typography>
																						)}
																						<Box className='owner-tag'>
																							<span className='owner-chip'>{t('mySelfTab')}</span>
																						</Box>
																					</>
																				) : (
																					selectedUser?.isExternal && (
																						<Box
																							className='teams-chips'
																							id={`kr-contributor-team-${selectedUser.employeeId}`}
																						>
																							<Tooltip
																								title={
																									!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																										? t('externalContributorTooltip')
																										: ''
																								}
																								arrow
																							>
																								<span className='external-link'>
																									{' '}
																									<ExternalContributorIcon />{' '}
																								</span>
																							</Tooltip>
																						</Box>
																					)
																				)}

																				{/* {selectedUser && selectedUser?.teamName && (
																					<Box
																						className='teams-chips'
																						id={`kr-contributor-team-${selectedUser.employeeId}`}
																					>
																						{selectedUser?.isExternal && (
																							<Tooltip
																								title={
																									!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																										? t('externalContributorTooltip')
																										: ''
																								}
																								arrow
																							>
																								<span className='external-link'>
																									{' '}
																									<ExternalContributorIcon />{' '}
																								</span>
																							</Tooltip>
																						)}
																						<Tooltip
																							title={
																								!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																									? selectedUser?.teamName
																									: ''
																							}
																							arrow
																						>
																							<Chip
																								className='chip'
																								style={{
																									backgroundColor: getRandomBackgroundColor(
																										selectedUser?.teambackGroundColorCode
																									),
																									color: getRandomColor(selectedUser?.teamcolorCode),
																								}}
																								label={selectedUser?.teamName}
																							/>
																						</Tooltip>
																					</Box>
																				)} */}
																			</Box>
																		</Box>
																	</ListItem>
																	{isGroupTarget ? null : (
																		<ListItem
																			className='user-col2'
																			ref={index === 0 ? inputRefstartTarget : inputRefstartTargetOther}
																		>
																			{/* added assign target input field start here*/}
																			<Box className='assign-target-metric-field'>
																				<Box className='kr-metrics-fileds kr-metrics-full-width'>
																					<Box className='kr-metrics-fileds-inner'>
																						<Box display='flex'>
																							<CurrentInputValue
																								keyAttribute={keyAttribute}
																								selectedMetricDetail={selectedMetricDetail}
																								data={selectedUser}
																								valueChanged={valueChanged}
																								getCurrencyIcon={getCurrencyIcon}
																								isGroupTarget={isGroupTarget}
																								isLocked={isLocked}
																							/>
																							<Box className='matrics-field-seprator' component='span'>
																								{'/'}
																							</Box>
																							<TargetInputValue
																								keyAttribute={keyAttribute}
																								selectedMetricDetail={selectedMetricDetail}
																								data={selectedUser}
																								valueChanged={valueChanged}
																								getCurrencyIcon={getCurrencyIcon}
																								isGroupTarget={isGroupTarget}
																								isLocked={isLocked}
																							/>
																						</Box>
																					</Box>
																				</Box>
																			</Box>
																		</ListItem>
																	)}
																	<ListItem
																		className='user-col3'
																		ref={index === 0 ? inputRefcommonDueDate : inputRefcommonDueDateOther}
																	>
																		<Box className='set-timeline-area'>
																			<Box
																				className='calender-date helperText-bottom'
																				id={`due-date-${selectedUser.employeeId}`}
																			>
																				{/*getMonthDate(selectedUser.dueDate)*/}
																				<DueDateInput
																					data={selectedUser}
																					selectedCycleDetails={props.userSelectedCycle}
																					okrDueDate={keyAttribute?.dueDate}
																					durationCycleDetail={props.durationCycleDetail}
																					valueChanged={valueChanged}
																					isGroupTarget={isGroupTarget}
																					isLocked={isLocked}
																				/>
																			</Box>
																		</Box>
																		{!isLocked && !isDeleteInProgress && !selectedUser.isKrOwner && (
																			<OKRButton
																				className='assign-target-delete'
																				icon={<DeleteIcon />}
																				handleClick={(event: any) =>
																					onDeleteContributor(event, selectedUser, 'Delete', isGroupTarget)
																				}
																				title={t('delete')}
																			/>
																		)}
																	</ListItem>
																</List>
															</Box>
														</Box>
													))}
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						}
						moreButton={false}
						handleDrawerClose={(e: any) => {
							checkAssignContributorUpdated(e);
						}}
						handleSaveClick={(event: any) => {
							setSaveClicked(true);
							if (!isSaveButtonActive(true)) {
								handleNext(2);
							}
						}}
						handleCancelClick={(e: any) => {
							checkAssignContributorUpdated(e);
						}}
						hideDefaultButton={false}
						saveButtonText={'Save'}
						showBackButton={true}
						isSaveButtonDisabled={isLocked || isDeleteInProgress}
						backButton={
							!isLocked ? <Button
								className='btn-link'
								onClick={() => {
									handleBack(0);
									setAnchorElCallouts && setAnchorElCallouts(null);
									setCallOut && setCallOut(initialCallout);
								}}
							>
								{<KeyboardBackspaceIcon />}
								{t('backContributor')}
							</Button> : <></>
						}
						isAnyDrawerOpened={props.isAnyDrawerOpened}
						appDrawerInfo={props.appDrawerInfo}
						drawerClassName={'main-drawer-panel main-drawer-subpanel'}
						// hideCloseIcon={true}
					/>
				</Box>
			) : (
				<span></span>
			)}
			{subModalProps && (
				<AlertDialog
					module='myGoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseSubModal}
					modalOpen={subModalProps}
				/>
			)}
			{groupTargetChangeProps && groupTargetChangeProps?.open && (
				<AlertDialog
					module='myGoal'
					message={groupTargetChangeProps?.message || t('groupTargetChangeMessage')}
					handleCloseModal={handleCloseGroupTargetModal}
					modalOpen={groupTargetChangeProps?.open || false}
				/>
			)}
		</Fragment>
	);
};

export const CurrentInputValue = (props: any) => {
	return (
		<TextField
			className='current-value helperText-bottom'
			id='current-value'
			//label={'Start'}
			placeholder={props.selectedMetricDetail.selectedMetric.metricId === 2 ? '0' : '0'}
			value={props.data['startValue'] || ''}
			fullWidth
			onChange={(e) => {
				props.valueChanged('startValue', 'text', e.target.value, props.data);
			}}
			inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
			InputProps={{
				...(props.selectedMetricDetail.selectedMetric.name !== 'Percentage' && {
					inputComponent: NumberFormatComponent,
				}),
				startAdornment: (
					<InputAdornment position='start'>
						{props.selectedMetricDetail.selectedMetric.name === 'Percentage' ? (
							<PercentageIcon />
						) : props.selectedMetricDetail.selectedMetric.name === 'Numbers' ? (
							<NumbersIcon />
						) : (
							props.getCurrencyIcon(
								props.selectedMetricDetail.selectedCurrencyDetail?.name
									? props.selectedMetricDetail.selectedCurrencyDetail.name
									: 'Dollar'
							)
						)}
					</InputAdornment>
				),
			}}
			error={props.data['invalid'] && props.data['invalid']['startValue'] ? true : false}
			helperText={
				props.data['invalid'] && props.data['invalid']['startValue'] ? (
					<>
						<ErrorIcon /> {props.data['invalid']['startValue']}
					</>
				) : (
					''
				)
			}
			disabled={props.isLocked || props?.isGroupTarget ? true : props.data.isActive === false ? true : false}
		/>
	);
};

export const TargetInputValue = (props: any) => {
	return (
		<TextField
			className='target-value helperText-bottom'
			id='target-value'
			//label={'Target'}
			placeholder={props.selectedMetricDetail.selectedMetric.metricId === 2 ? '0' : '0'}
			value={props.data['targetValue'] || ''}
			fullWidth
			onChange={(e: any) => {
				props.valueChanged('targetValue', 'text', e.target.value, props.data);
			}}
			inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
			InputProps={{
				...(props.selectedMetricDetail.selectedMetric.name !== 'Percentage' && {
					inputComponent: NumberFormatComponent,
				}),
				startAdornment: (
					<InputAdornment position='start'>
						{props.selectedMetricDetail.selectedMetric.name === 'Percentage' ? (
							<PercentageIcon />
						) : props.selectedMetricDetail.selectedMetric.name === 'Numbers' ? (
							<NumbersIcon />
						) : (
							props.getCurrencyIcon(
								props.selectedMetricDetail.selectedCurrencyDetail?.name
									? props.selectedMetricDetail.selectedCurrencyDetail.name
									: 'Dollar'
							)
						)}
					</InputAdornment>
				),
			}}
			error={props.data['invalid'] && props.data['invalid']['targetValue'] ? true : false}
			helperText={
				props.data['invalid'] && props.data['invalid']['targetValue'] ? (
					<>
						<ErrorIcon /> {props.data['invalid']['targetValue']}
					</>
				) : (
					''
				)
			}
			disabled={props.isLocked || props?.isGroupTarget ? true : props.data.isActive === false ? true : false}
		/>
	);
};

export const DueDateInput = (props: any) => {
	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	useEffect(() => {
		props.data['okrDueDate'] && setSelectedDateRange(moment(new Date(props.data['okrDueDate'])));
	}, [props]);
	const valueChanged = (value: any, field: any, type: any) => {
		props.valueChanged(field, type, value, props.data);
	};

	return (
		<ReactDateRangePicker
			type={'ContributorList'}
			selectedCycleDetails={props.selectedCycleDetails}
			startDateValue={props.data['dueDate'] || new Date()}
			minimumDate={props.data['startDate'] || new Date()}
			maximumDate={props['okrDueDate'] || props.data['dueDate'] || new Date()}
			handleDateSelection={(range: any, start: any, end: any, selectionType: String, isCycleChanged: Boolean) => {
				range && setSelectedDateRange(range);
				valueChanged(new Date(formatDate(start)), 'dueDate', 'date');
			}}
			isDateRangeTextFieldVisible={false}
			isOnlyDueDateVisible={true}
			allCycleDetails={[]}
			isCycleVisible={false}
			isEndOfMonthVisible={false}
			numberOfCalendars={Enums.ONE}
			selectionType={'single'}
			selectedDateRange={selectedDateRange}
			setSelectedDateRange={setSelectedDateRange}
			isDatePickerDisable={props.isLocked || props?.isGroupTarget ? true : props.data.isActive === false ? true : false}
		/>
	);
};
