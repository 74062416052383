import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { forgotPasswordAction, updateStatus } from '../../../action/forgotPassword';
import ForgotPassword from '../../../components/Guest/ForgotPassword';
import { updateRoute, showLoader, updatePage } from '../../../action/common';

const mapStateToProps = (state) => {
	return {
		forgotPasswordStatus: state.forgotPasswordReducer.forgotPasswordStatus,
		pending: state.forgotPasswordReducer.pending,
		messageList: state.forgotPasswordReducer.messageList,
		entity: state.forgotPasswordReducer.entity,
		error: state.forgotPasswordReducer.error,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			forgotPasswordAction,
			updateRoute,
			showLoader,
			updatePage,
			updateStatus,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));
