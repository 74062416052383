import React, { useCallback, useState } from 'react';
import { Box, InputAdornment, TextField } from '@material-ui/core';
import { LinkDeleteIcon, SearchIcon } from '../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export const SearchField: React.FC<any> = (props) => {
	const {
		performSearch,
		currentCycleSelection,
		checkSelection,
		clearSelection,
		searchText,
		setSearchText,
		placeholderText,
	} = props;
	const { t } = useTranslation();

	const _handleSearch = async (query: any, currentCycleSelection: any) => {
		if (query.trim().length >= 3 || query.trim() === '') {
			setSearchText(query.trim());
			performSearch(query.trim(), currentCycleSelection, false);
		}
	};
	const delayedQuery = useCallback(
		_.debounce((q, currentCycleSelection) => _handleSearch(q, currentCycleSelection), 500),
		[]
	);
	const handleInputThrottled = (value: any, currentCycleSelection: any) => {
		setSearchText(value);
		delayedQuery(value, currentCycleSelection);
	};
	return (
		<Box className='search-action-field'>
			<TextField
				className='src-field'
				id='src-field-okr'
				placeholder={placeholderText || t('searchOkrs')}
				fullWidth
				onChange={(e) => handleInputThrottled(e.target.value, currentCycleSelection)}
				value={searchText}
				onClick={(e) => checkSelection(e, currentCycleSelection)}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start' className='src-icon'>
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: (
						<>
							{Boolean(searchText) && searchText.trim() && (
								<InputAdornment
									position='start'
									onClick={(e) => {
										clearSelection(e, currentCycleSelection);
									}}
									className='delete-icon'
								>
									<LinkDeleteIcon />
								</InputAdornment>
							)}
						</>
					),
				}}
			/>
		</Box>
	);
};
