import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
});

export default function LinearLoader(props) {
	const { data } = props;
	const classes = useStyles();
	const [progress, setProgress] = React.useState(0);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 1;
				let progress = Math.min(oldProgress + diff, 95);
				if (data) {
					progress = Math.min(oldProgress + diff, 100);
				}
				return progress;
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, [data]);

	return (
		<div className={classes.root} id='loader-bar'>
			<LinearProgress value={progress} />
		</div>
	);
}
