import React, { Fragment, useState, useEffect } from 'react';
import { Page, Text, View, Image, Document, Font } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import OKR_LOGO from '../../../images/unlock-talent.png';
import OKR_Profile_Pic from '../../../images/pdf-user.png';

import {
	getMonthDateYearTime,
	getPriorityIcon,
	getPriorityName,
	getUserDetails,
	getUserName,
} from '../../../config/utils';

import { styles } from './MeetingDetailPdfStyle';
import Html from 'react-pdf-html';
import { Enums } from '../../../config/enums';

const getSource = (imagePath, type) =>
	type === 1
		? {
				uri: imagePath,
				method: 'GET',
		  }
		: imagePath;
// const html = `
// <p>vdsdsdsffsdf</p><p>&nbsp;</p><p><strong>sasas</strong></p><p><i>asasasa</i></p><p><a target="_blank" rel="noopener noreferrer" href="http://SDSADSD">http://SDSADSD</a></p><ul style="list-style-type:circle;"><li>FDF</li><li>JKJHK</li></ul><p>&nbsp;</p><ol style="list-style-type:lower-latin;"><li>DFSD</li><li>LK;</li><li>&nbsp;</li></ol>
// 		`;
export function MeetingDetailPdf(props) {
	const { data } = props;
	const { t } = useTranslation();
	const rowCount = data?.loggedInEmployeeTasks.length;
	const rowOtherCount = data?.otherEmployeeTasks.length;
	return (
		<Fragment>
			{console.log(data)}
			<Document>
				<Page size='A4' style={styles.page} wrap>
					<View style={styles.page_wrap}>
						<View fixed style={styles.header_top}>
							<Image style={styles.okr_logo} source={getSource(OKR_LOGO, 0)} />
						</View>
						<View style={styles.header_middle}>
							{data?.launchCompletedDate ? (
								<View style={styles.meeting_date}>
									<Text style={styles.meeting_title_content}>{t('Meeting Date:')}</Text>
									<View style={styles.meeting_title_wrap}>
										<Text style={styles.meeting_title_content}>{getMonthDateYearTime(data?.launchCompletedDate)}</Text>
									</View>
								</View>
							) : (
								<View style={styles.meeting_date}>
									<Text style={styles.meeting_title_content}>{t('Meeting Date:')}</Text>
									<View style={styles.meeting_title_wrap}>
										<Text style={styles.meeting_title_content}>--</Text>
									</View>
								</View>
							)}
							<View style={styles.meeting_title}>
								<Text style={styles.meeting_title_text}>{t('titleLabel')}</Text>
								<View style={styles.meeting_title_wrap}>
									<Text style={styles.meeting_title_content}>{data?.oneToOneTitle}</Text>
								</View>
							</View>
							<View style={styles.nature_of_meeting}>
								<Text style={styles.meeting_title_text}>{t('natureOfMeeting')}</Text>
								<View style={styles.meeting_title_wrap}>
									<Text style={styles.meeting_title_content}>{data?.natureOfMeeting}</Text>
								</View>
							</View>
							<View style={styles.meeting_link}>
								<Text style={styles.meeting_title_text}>{t('meetingLinkLabel')}</Text>
								<View style={styles.meeting_title_wrap}>
									{data?.meetingLink ? (
										<Text style={styles.meeting_link_content}>{data?.meetingLink}</Text>
									) : (
										<Text style={styles.not_link_text}>{t('noLinkMentioned')}</Text>
									)}
								</View>
							</View>
							<View style={styles.meeting_attendees}>
								<Text style={styles.meeting_title_text}>{t('Attendees')}</Text>
								<View style={styles.meeting_title_wrap}>
									<View style={styles.meeting_attendees_inner}>
										<View style={styles.profile_info}>
											<Text style={styles.meeting_title_content}>
												{data?.loggedInEmployee?.employeeName} & {data?.otherEmployee?.employeeName}
											</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={styles.meeting_agenda}>
								<Text style={styles.meeting_title_text}>{t('agendaLabel')}</Text>
								<View style={styles.meeting_agenda_wrap}>
									{data?.oneToOneRemark ? (
										<Html stylesheet={styles}>{data?.oneToOneRemark}</Html>
									) : (
										<Text style={styles.not_agenda}>{t('noAgendaMentioned')}</Text>
									)}
								</View>
							</View>
						</View>
						<View style={styles.meeting_section}>
							{Boolean(!data?.isInitiatorOnly) && (
								<View style={styles.meeting_content_head}>
									<View style={styles.meeting_col2}>
										<View style={styles.profile_details}>
											{Boolean(data?.loggedInEmployee?.imagePath) && data?.loggedInEmployee?.imagePath.trim() !== '' ? (
												<Image style={styles.user_image} source={getSource(data?.loggedInEmployee?.imagePath, 1)} />
											) : (
												<View style={styles.user_initial}>
													<Text style={styles.user_initial_name}>
														{getUserName({
															fullName: data?.loggedInEmployee?.employeeName,
															firstName: '',
															lastName: '',
														})}
													</Text>
												</View>
											)}
											<View style={styles.profile_info}>
												<Text style={styles.user_name}>{data?.loggedInEmployee?.employeeName}</Text>
											</View>
											<View style={styles.profile_owner}>
												<Text style={styles.profile_owner_name}>{t('mySelfTab')}</Text>
											</View>
										</View>
									</View>
								</View>
							)}
							<View style={styles.meeting_content}>
								<View style={styles.meeting_col2}>
									{data?.loggedInEmployeeTasks.length <= 0 &&
									!data?.loggedInEmployeeNotes &&
									!data?.loggedInPrivateEmployeeNotes ? (
										<View style={styles.meeting_notes}>
											{/* <Text style={styles.meeting_title_text}>{t('privateNotes')}</Text> */}
											<View style={styles.meeting_notes_inner}>
												<View style={styles.meeting_notes_inner_wrap}>
													<Text style={styles.not_found}>{t('There are no meeting notes or tasks yet.')}</Text>
												</View>
											</View>
										</View>
									) : (
										<>
											{data?.loggedInEmployeeTasks.length > 0 ? (
												<View style={styles.meeting_notes}>
													<Text style={styles.meeting_title_text}>{t('tasksLabel')}</Text>
													<View style={styles.meeting_notes_inner}>
														<View style={styles.meeting_tasks_inner_wrap}>
															{data?.loggedInEmployeeTasks.map((task, index) => (
																<View
																	key={task.taskId}
																	//style={styles.task_row}
																	style={{
																		...styles.task_row,
																		...(index === 0 ? styles.first_row : {}),
																		...(index === rowCount - 1 ? styles.last_row : {}),
																	}}
																>
																	<Text>{task.taskName}</Text>
																	<View style={styles.task_action}>
																		<View style={styles.task_action_top}>
																			{task?.priority && (
																				<View style={styles.task_action_col}>
																					<Text style={styles.task_action_label}>Priority: </Text>
																					<Text style={styles.task_action_label_txt}>
																						{task?.priority ? task?.priority : ''}
																					</Text>
																				</View>
																			)}
																			{task?.createdOn && (
																				<View style={styles.task_action_col}>
																					<Text style={styles.task_action_label}>Created on: </Text>
																					<Text style={styles.task_action_label_txt}>
																						{task?.createdOn ? getMonthDateYearTime(task?.createdOn) : ''}
																					</Text>
																				</View>
																			)}
																			{task?.dueDate && (
																				<View style={styles.task_action_col}>
																					<Text style={styles.task_action_label}>Due on: </Text>
																					<Text style={styles.task_action_label_txt}>
																						{task?.dueDate ? getMonthDateYearTime(task?.dueDate) : ''}
																					</Text>
																				</View>
																			)}
																			{task?.completeDate && (
																				<View style={styles.task_action_col}>
																					<Text style={styles.task_action_label}>Completed on: </Text>
																					<Text style={styles.task_action_label_txt}>
																						{task?.completeDate ? getMonthDateYearTime(task?.completeDate) : ''}
																					</Text>
																				</View>
																			)}
																		</View>

																		<View style={styles.task_action_top}>
																			{task?.assignedBy?.firstName && (
																				<View style={styles.task_action_col}>
																					<Text style={styles.task_action_label}>Assigned By: </Text>
																					<Text style={styles.task_action_label_txt}>
																						{task?.assignedBy?.firstName
																							? `${task?.assignedBy?.firstName} ${task?.assignedBy?.lastName}`
																							: ''}
																					</Text>
																				</View>
																			)}
																			{task?.assignedTo?.firstName && (
																				<View style={styles.task_action_col}>
																					<Text style={styles.task_action_label}>Assigned To: </Text>
																					<Text style={styles.task_action_label_txt}>
																						{task?.assignedTo?.firstName
																							? `${task?.assignedTo?.firstName} ${task?.assignedTo?.lastName}`
																							: ''}
																					</Text>
																				</View>
																			)}
																		</View>
																	</View>
																</View>
															))}
														</View>
													</View>
												</View>
											) : (
												<></>
											)}
											{data?.loggedInEmployeeNotes ? (
												<View style={styles.meeting_notes}>
													<Text style={styles.meeting_title_text}>{t('meetingNotes')}</Text>
													<View style={styles.meeting_notes_inner}>
														<View style={styles.meeting_notes_inner_wrap}>
															{data?.loggedInEmployeeNotes ? (
																<Html stylesheet={styles}>
																	{data?.loggedInEmployeeNotes?.description.replace(/figure/g, 'div')}
																</Html>
															) : (
																<Text style={styles.not_found}>{t('There are no meeting notes yet.')}</Text>
															)}
														</View>
													</View>
												</View>
											) : (
												<></>
											)}
											{data?.loggedInPrivateEmployeeNotes ? (
												<View style={styles.private_notes}>
													<Text style={styles.meeting_title_text}>{t('privateNotes')}</Text>
													<View style={styles.meeting_notes_inner}>
														<View style={styles.meeting_notes_inner_wrap}>
															{data?.loggedInPrivateEmployeeNotes ? (
																<Html stylesheet={styles}>
																	{data?.loggedInPrivateEmployeeNotes?.description.replace(/figure/g, 'div')}
																</Html>
															) : (
																<Text style={styles.not_found}>{t('There are no private notes yet.')}</Text>
															)}
														</View>
													</View>
												</View>
											) : (
												<></>
											)}
										</>
									)}
								</View>
							</View>
						</View>
						{Boolean(!data?.isInitiatorOnly) && (
							<View style={styles.meeting_section}>
								<View style={styles.meeting_content_head}>
									<View style={styles.meeting_col2}>
										<View style={styles.profile_details}>
											{Boolean(data?.otherEmployee?.imagePath) && data?.otherEmployee?.imagePath.trim() !== '' ? (
												<Image style={styles.user_image} source={getSource(data?.otherEmployee?.imagePath, 1)} />
											) : (
												<View style={styles.user_initial}>
													<Text style={styles.user_initial_name}>
														{getUserName({
															fullName: data?.otherEmployee?.employeeName,
															firstName: '',
															lastName: '',
														})}
													</Text>
												</View>
											)}
											<View style={styles.profile_info}>
												<Text style={styles.user_name}>{data?.otherEmployee?.employeeName}</Text>
											</View>
										</View>
									</View>
								</View>

								<View style={styles.meeting_content}>
									<View style={styles.meeting_col2}>
										{data?.otherEmployeeTasks.length <= 0 && !data?.otherEmployeeNotes ? (
											<View style={styles.meeting_notes}>
												<View style={styles.meeting_notes_inner}>
													<View style={styles.meeting_notes_inner_wrap}>
														<Text style={styles.not_found}>{t('There are no meeting notes or tasks yet.')}</Text>
													</View>
												</View>
											</View>
										) : (
											<>
												{data?.otherEmployeeTasks.length > 0 ? (
													<View style={styles.meeting_notes}>
														<Text style={styles.meeting_title_text}>{t('tasksLabel')}</Text>
														<View style={styles.meeting_notes_inner}>
															<View style={styles.meeting_tasks_inner_wrap}>
																{data?.otherEmployeeTasks.map((task, index) => (
																	<View
																		key={task.taskId}
																		//style={styles.task_row}
																		style={{
																			...styles.task_row,
																			...(index === 0 ? styles.first_row : {}),
																			...(index === rowOtherCount - 1 ? styles.last_row : {}),
																		}}
																	>
																		<Text>{task.taskName}</Text>
																		<View style={styles.task_action}>
																			<View style={styles.task_action_top}>
																				{task?.priority && (
																					<View style={styles.task_action_col}>
																						<Text style={styles.task_action_label}>Priority: </Text>
																						<Text style={styles.task_action_label_txt}>
																							{task?.priority ? task?.priority : ''}
																						</Text>
																					</View>
																				)}
																				{task?.createdOn && (
																					<View style={styles.task_action_col}>
																						<Text style={styles.task_action_label}>Created on: </Text>
																						<Text style={styles.task_action_label_txt}>
																							{task?.createdOn ? getMonthDateYearTime(task?.createdOn) : ''}
																						</Text>
																					</View>
																				)}
																				{task?.dueDate && (
																					<View style={styles.task_action_col}>
																						<Text style={styles.task_action_label}>Due on: </Text>
																						<Text style={styles.task_action_label_txt}>
																							{task?.dueDate ? getMonthDateYearTime(task?.dueDate) : ''}
																						</Text>
																					</View>
																				)}
																				{task?.completeDate && (
																					<View style={styles.task_action_col}>
																						<Text style={styles.task_action_label}>Completed on: </Text>
																						<Text style={styles.task_action_label_txt}>
																							{task?.completeDate ? getMonthDateYearTime(task?.completeDate) : ''}
																						</Text>
																					</View>
																				)}
																			</View>

																			<View style={styles.task_action_top}>
																				{task?.assignedBy?.firstName && (
																					<View style={styles.task_action_col}>
																						<Text style={styles.task_action_label}>Assigned By: </Text>
																						<Text style={styles.task_action_label_txt}>
																							{task?.assignedBy?.firstName
																								? `${task?.assignedBy?.firstName} ${task?.assignedBy?.lastName}`
																								: ''}
																						</Text>
																					</View>
																				)}
																				{task?.assignedTo?.firstName && (
																					<View style={styles.task_action_col}>
																						<Text style={styles.task_action_label}>Assigned To: </Text>
																						<Text style={styles.task_action_label_txt}>
																							{task?.assignedTo?.firstName
																								? `${task?.assignedTo?.firstName} ${task?.assignedTo?.lastName}`
																								: ''}
																						</Text>
																					</View>
																				)}
																			</View>
																		</View>
																	</View>
																))}
															</View>
														</View>
													</View>
												) : (
													<></>
												)}
												{data?.otherEmployeeNotes ? (
													<View style={styles.other_meeting_notes}>
														<Text style={styles.meeting_title_text}>{t('meetingNotes')}</Text>
														<View style={styles.meeting_notes_inner}>
															<View style={styles.meeting_notes_inner_wrap}>
																{data?.otherEmployeeNotes ? (
																	<Html stylesheet={styles}>
																		{data?.otherEmployeeNotes?.description.replace(/figure/g, 'div')}
																	</Html>
																) : (
																	<Text style={styles.not_found}>{t('There are no meeting notes yet.')}</Text>
																)}
															</View>
														</View>
													</View>
												) : (
													<></>
												)}
											</>
										)}
									</View>
								</View>
							</View>
						)}
					</View>

					<Text
						style={styles.pageNumber}
						render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
						fixed
					/>
				</Page>
			</Document>
		</Fragment>
	);
}
