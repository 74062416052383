import {
	giveFeedbackAPI,
	askFeedbackAPI,
	fetchFeedbackAPI,
	feedback1To1API,
	commentFeedbackAPI,
	redirectFeedbackAPI,
} from '../services/FeedbackService';
import * as actionTypes from './actionTypes';

export const fetchFeedback = (data) => {
	return () => {
		return fetchFeedbackAPI(data);
	};
};

const feedbackPending = () => ({
	type: actionTypes.GIVE_FEEDBACK_PENDING,
});

const feedbackCompleted = (payload) => ({
	type: actionTypes.GIVE_FEEDBACK_SUCCESS,
	payload,
});

const feedbackFailed = (error) => ({
	type: actionTypes.GIVE_FEEDBACK_FAILED,
	error,
});

export const giveFeedback = (data) => {
	return (dispatch) => {
		dispatch(feedbackPending());
		return giveFeedbackAPI(data)
			.then((response) => {
				dispatch(feedbackCompleted(response.data));
			})
			.catch((error) => {
				dispatch(feedbackFailed(error));
			});
	};
};

const askFeedbackPending = () => ({
	type: actionTypes.ASK_FEEDBACK_PENDING,
});

const askFeedbackCompleted = (payload) => ({
	type: actionTypes.ASK_FEEDBACK_SUCCESS,
	payload,
});

const askFeedbackFailed = (error) => ({
	type: actionTypes.ASK_FEEDBACK_FAILED,
	error,
});

export const askFeedback = (data) => {
	return (dispatch) => {
		dispatch(askFeedbackPending());
		return askFeedbackAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(askFeedbackCompleted(response.data));
				} else {
					dispatch(askFeedbackFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(askFeedbackFailed(error));
			});
	};
};

export const requestOneToOneFeedback = (data) => {
	return () => {
		return feedback1To1API(data);
	};
};

export const commentFeedback = (data) => {
	return () => {
		return commentFeedbackAPI(data);
	};
};

export const resetFeedback = () => ({
	type: actionTypes.RESET_FEEDBACK,
});

export const redirectToFeedback = (data) => {
	return () => {
		return redirectFeedbackAPI(data);
	};
};
