import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Box, List, ListItem, TablePagination, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AssignContributor from '../../../OkrAction/AssignContributor';
import { CrossIcon, SortingIcon } from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
import { NoRecord } from '../../CommonComponent/NoRecord';
import { useDispatch } from 'react-redux';
import { globalSearchAPIWithTeam, searchEmployee } from '../../../../action/search';
import { getUserById, updatePage } from '../../../../action/common';
import AlertDialog from '../../../Common/Dialog';
import { useSnackbar } from 'notistack';
import { deleteSoProgressVisibilityUser } from '../../../../action/strategicObjective';
import { Enums } from '../../../../config/enums';
import { getFullName, getUserName } from '../../../../config/utils';

export const SoProgressManageUser: React.FC<any> = (props: any) => {
	const {
		selectedUserDetails,
		includedUseList,
		setIncludedUseList,
		type,
		localArrList,
		setLocalArrList,
		setLoader,
		isEdited,
		setIsEdited,
		isSoVisibilitySpecificUserSelected,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	// const [localArrList, setLocalArrList] = useState<any>({ selectedUser: [], raisedForId: [] });
	const [totalUser, setTotalUser] = useState(0);
	const [pageIndex, setPageIndex] = useState<any>(0);
	const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');

	useEffect(() => {
		if (includedUseList && includedUseList.selectedUser && includedUseList.selectedUser.length) {
			const updatedEmpList = dataPerPage(includedUseList?.selectedUser, pageIndex + 1, pageSize);
			setTotalUser(includedUseList.selectedUser.length);
			setLocalArrList({
				...localArrList,
				selectedUser: [...updatedEmpList],
				raisedForId: [...updatedEmpList].map((item: any) => item.employeeId),
			});
		} else {
			if (localArrList?.selectedUser && localArrList?.selectedUser?.length) {
				// localArrList.selectedUser.forEach((record: any) => {
				// 	removeSelectedUser(record);
				// });
				setLocalArrList({
					selectedUser: [],
					raisedForId: [],
				});
			} else {
				setLocalArrList({
					selectedUser: [],
					raisedForId: [],
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [includedUseList]);

	const dataPerPage = (arrayList: any, currPage: any, pageSizeValue: any) => {
		const startIndex = currPage - 1;
		const updatedList = arrayList.slice(startIndex * pageSizeValue, pageSizeValue * currPage);

		return updatedList;
	};
	const onSelectedUser = async (selected: any) => {
		const isExist =
			localArrList.selectedUser &&
			localArrList.selectedUser.find((item: any) => item.employeeId === selected[0].employeeId);
		if (selected.length > 0 && !isExist) {
			const allUser = [...localArrList.selectedUser, { ...selected[0], newRecord: true, soUserSettingId: 0 }];
			const allUserIds = allUser.map((item) => item.employeeId);

			setLocalArrList({
				...localArrList,
				selectedUser: allUser,
				raisedForId: allUserIds,
			});
			setIsEdited(true);
		}
	};
	const removeUser = async (e: any, record: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (!record?.newRecord) {
			setModalProps({
				open: true,
				message: t('deleteConfirmationForAlignment', {
					type: 'user',
				}),
				details: record,
			});
		} else {
			removeSelectedUser(record);
		}
	};
	const removeSelectedUser = (record: any) => {
		const { employeeId, newRecord } = record || {};
		if (newRecord) {
			const user1 = includedUseList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);
			const userIds1 = includedUseList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);

			setIncludedUseList({
				selectedUser: user1,
				raisedForId: [...userIds1.map((item: any) => item.employeeId)],
			});

			const user = localArrList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);
			const userIds = localArrList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);

			setLocalArrList({
				selectedUser: user,
				raisedForId: [...userIds.map((item: any) => item.employeeId)],
			});
		} else {
			const user = includedUseList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);
			const userIds = includedUseList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);

			setIncludedUseList({
				selectedUser: user,
				raisedForId: [...userIds.map((item: any) => item.employeeId)],
			});
			const user1 = localArrList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);
			const userIds1 = localArrList?.selectedUser.filter((item: any) => item.employeeId !== employeeId);

			setLocalArrList({
				selectedUser: user1,
				raisedForId: [...userIds1.map((item: any) => item.employeeId)],
			});
		}
		setPageIndex(0);
	};

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps && modalProps?.details) {
				const { soUserSettingId } = modalProps?.details;
				if (soUserSettingId) {
					setModalProps({ open: false, message: '', details: '' });
					setLoader(true);
					const requestPayload = [soUserSettingId];
					const response: any = await dispatch(deleteSoProgressVisibilityUser(requestPayload));

					if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
						const { messageList } = response.data;
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						enqueueSnackbar(messages || 'Employee deleted from strategic objective visibility setting', {
							variant: 'success',
							autoHideDuration: 3000,
						});
						// Remove User from local state
						removeSelectedUser(modalProps.details);
					} else {
						const { messageList } = response?.data || {};
						const keys = messageList ? Object.keys(messageList) : [];
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						enqueueSnackbar(
							messages || 'Error occurred while deleting included employee from strategic objective visibility setting',
							{
								variant: 'error',
								autoHideDuration: 3000,
							}
						);
					}
					setLoader(false);
				} else {
					removeSelectedUser(modalProps.details);
					setModalProps({ open: false, message: '', details: '' });
				}
			}
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const searchCustomEmployee = (searchStr: any, page: any, pageSize: any) => {
		return dispatch(searchEmployee(searchStr, page, pageSize));
	};
	const updateCustomPage = (page: any) => {
		return dispatch(updatePage(page));
	};
	const globalSearchWithTeam = (searchStr: any, page: any, pageSize: any, type: any) => {
		return dispatch(globalSearchAPIWithTeam(searchStr, page, pageSize, type));
	};
	const getCustomUserById = (data: any) => {
		return dispatch(getUserById(data));
	};
	const handleChangePage = (event: any, newPage: number) => {
		newPage = newPage + 1;
		setPageIndex(newPage);
		const updatedEmpList = dataPerPage(includedUseList.selectedUser, newPage, pageSize);
		setLocalArrList({
			selectedUser: [...updatedEmpList],
			raisedForId: [...updatedEmpList].map((item: any) => item.employeeId),
		});
	};
	const handleChangeRowsPerPage = async (event: any) => {
		const pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);
		const updatedEmpList = dataPerPage(includedUseList?.selectedUser, 1, pSize);
		setLocalArrList({
			selectedUser: [...updatedEmpList],
			raisedForId: [...updatedEmpList].map((item: any) => item.employeeId),
		});
	};
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		let updatedEmpList = sortArrayByKey(includedUseList?.selectedUser, column, newSortOrder);
		updatedEmpList = dataPerPage(updatedEmpList, 1, pageSize);
		setSortingColumn(column);
		setLocalArrList({
			selectedUser: [...updatedEmpList],
			raisedForId: [...updatedEmpList].map((item: any) => item.employeeId),
		});
	};
	const sortArrayByKey = (arrayList: any, keyName: string, sortingType: string) => {
		arrayList.sort(function (a: any, b: any) {
			const keyA = a[keyName];
			const keyB = b[keyName];
			if (sortingType === 'asc') {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			} else if (sortingType === 'desc') {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			}
			return 0;
		});

		return arrayList;
	};

	return (
		<>
			<Box className='manage-user-drawer'>
				<Fragment>
					{type !== 'viewProgressVisibilitySpecificUsers' && isSoVisibilitySpecificUserSelected ? (
						<Box className='drawer-input-field drawer-user-search-field'>
							<AssignContributor
								{...props}
								goal={{ selectedUser: [], raisedForId: [] }}
								addKey={''}
								fieldLabel={''}
								fetchCycleUsers={true}
								customClass={'user-quick-search-type2'}
								customId={'give-pf-src'}
								multiple={false}
								defaultValue={null}
								defaultFocus={null}
								placeHolderText={null}
								onSelectedUser={onSelectedUser}
								removeUser={removeUser}
								searchEmployee={searchCustomEmployee}
								updatePage={updateCustomPage}
								globalSearchAPIWithTeam={globalSearchWithTeam}
								getUserById={getCustomUserById}
								isChipsHide={true}
							/>
						</Box>
					) : null}
					<Box className='users-listing'>
						{(localArrList && localArrList?.selectedUser && localArrList?.selectedUser.length === 0) ||
						!localArrList ? (
							<></>
						) : (
							<Box className='userCount'>
								{localArrList &&
								localArrList?.selectedUser &&
								localArrList?.selectedUser.filter((item: any) => item?.newRecord).length ? (
									<Typography variant='subtitle2'>{`(${
										localArrList && localArrList?.selectedUser
											? localArrList?.selectedUser.filter((item: any) => item?.newRecord).length
											: 0
									} ${t('membersLabel')})`}</Typography>
								) : (
									<Typography variant='subtitle2' style={{ visibility: 'hidden' }}>
										0 {t('membersLabel')}
									</Typography>
								)}
								<>
									{totalUser ? (
										<TablePagination
											component='div'
											className='user-list-pagination'
											count={totalUser}
											page={pageIndex > 1 ? pageIndex - 1 : 0}
											onPageChange={handleChangePage}
											rowsPerPage={pageSize}
											onRowsPerPageChange={handleChangeRowsPerPage}
											SelectProps={{
												MenuProps: {
													PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
												},
											}}
										/>
									) : (
										<></>
									)}
								</>
							</Box>
						)}
						<Fragment>
							{localArrList && localArrList?.selectedUser && localArrList?.selectedUser.length > 0 ? (
								<Box className='okr-list-panel-head'>
									<List component='ul' disablePadding>
										<ListItem className='user-col1'>
											<Typography
												onClick={(e) => sortUserList(e, 'firstName')}
												variant='body2'
												className={sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
												id='sort-name'
											>
												<span className='sortable-label'>
													<span className='txt-wrap'>
														{t('nameLabel')} <SortingIcon />
													</span>
												</span>
											</Typography>
										</ListItem>
										<ListItem className='user-col2'>
											<Typography
												onClick={(e) => sortUserList(e, 'emailId')}
												variant='body2'
												className={sortingColumn === 'emailId' ? `sort-title ${sortOrder}` : 'sort-title asc'}
												id='sort-email'
											>
												<span className={`sortable-label `}>
													<span className='txt-wrap'>
														{t('emailLabel')} <SortingIcon />
													</span>
												</span>
											</Typography>
										</ListItem>
									</List>
								</Box>
							) : (
								<></>
							)}
							<Box className='users-list-content'>
								<Fragment>
									{localArrList && localArrList?.selectedUser && localArrList?.selectedUser.length > 0 ? (
										localArrList?.selectedUser.map((record: any) => (
											<Box className='users-list-row' key={record.employeeId}>
												<List component='ul' disablePadding>
													<ListItem className='user-col1'>
														<Box className='user-info'>
															<Box className='user-img'>
																{record.imagePath ? (
																	<Avatar className='avatar-default' src={record.imagePath} />
																) : (
																	<Avatar className='avatar-default'>{getUserName(record)}</Avatar>
																)}
															</Box>
															<Box className='user-info-details'>
																<Typography variant='h4'>{getFullName(record)}</Typography>
																<Typography variant='subtitle2'>{record?.designation || ''}</Typography>
															</Box>
														</Box>
													</ListItem>
													<ListItem className='user-col2'>
														{record?.emailId && record?.emailId.length > 35 ? (
															<Tooltip arrow title={record?.emailId} aria-label='Email'>
																<Typography variant='h4'>{record?.emailId.substring(0, 35)}...</Typography>
															</Tooltip>
														) : (
															<Typography variant='h4'>{record?.emailId || ''}</Typography>
														)}
														{type !== 'viewProgressVisibilitySpecificUsers' && isSoVisibilitySpecificUserSelected ? (
															<OKRButton
																icon={<CrossIcon />}
																handleClick={(e: any) => removeUser(e, record)}
																className={'btn-cross'}
															/>
														) : (
															<></>
														)}
													</ListItem>
												</List>
											</Box>
										))
									) : (
										<></>
									)}
								</Fragment>
							</Box>
							{(localArrList && localArrList?.selectedUser && localArrList?.selectedUser.length === 0) ||
							!localArrList ? (
								<NoRecord noRecordMessage={t('noExcludeMainHead')} subHead={t('visibiltySoUserMessg')} {...props} />
							) : (
								<></>
							)}
						</Fragment>
					</Box>
				</Fragment>
			</Box>

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
