import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import LinearLoader from '../Common/Loader';
import Header from '../../containers/Header';
import { UserPerformTabPanel, UserPerformTabs } from './UserPerformTabs';
import { UserSelfAssessment } from './UserSelfAssessment';
import '../../styles/pages/user-performance/userAssessmentHeaderBox.scss';
import '../../styles/pages/user-performance/userAssessmentQuesList.scss';
import { TeamsAssessment } from './TeamAssessment';
import { CycleTab } from '../Perform/CycleTab';
import { useDispatch, useSelector } from 'react-redux';
import {
	getEmployeeAssessmentList,
	getSelfAssessmentList,
	getSelfAssessmentPending,
} from '../../action/UserPerformance';
import { UserThreeSixtyList } from './UserThreeSixtyAssessment';
import { CreateOneOnOne } from '../Engagement/Request1on1/CreateOneOnOne';
import { globalSearchAPIWithTeam } from '../../action/search';
import { getUserDetails } from '../../config/utils';
import { currentDashboardTab } from '../../action/common';
import { FinalRatingAssessment } from './FinalRatingAssessment';

export const UserPerformance = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userDetails = getUserDetails();
	const { performanceCycle } = useSelector((state: any) => state.performReducer);
	const { userSelectedCycle, currentEngageTab, dashboardCurrentTab } = useSelector((state: any) => state.commonReducer);

	const [tabSelected, setTabSelected] = useState(0);
	const [loader, setLoader] = useState<boolean>(false);
	const [reloadRequired, setReloadRequired] = useState<boolean>(false);
	const [openNominateDrawer, setOpenNominateDrawer] = useState<boolean>(false);
	const [requestOneOnOneDrawer, setRequestOneOnOneDrawer] = useState<any>({ open: false, type: '' });

	useEffect(() => {
		if (dashboardCurrentTab !== tabSelected) {
			setTabSelected(dashboardCurrentTab);
		}
	}, [dashboardCurrentTab]);

	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});
	const getSelfAssessmentDetails = async (empId: any, performanceCycleId: any, ratingAssessmentType: number) => {
		try {
			const requestParams = `empId=${empId}&performanceCycleId=${performanceCycleId}&ratingAssessmentType=${ratingAssessmentType}`;
			// const response = await dispatch(getSelfAssessment(requestParams)); // use to get direct API response
			dispatch(getSelfAssessmentList(requestParams)); // selfAssessmentResponse, selfAssessmentResponseStatus
		} catch (error) {
			console.error(error);
		}
	};
	const getEmployeeAssessmentDetails = async (empId: any, performanceCycleId: any, ratingAssessmentType: number) => {
		try {
			const requestParams = `empId=${empId}&performanceCycleId=${performanceCycleId}&ratingAssessmentType=${ratingAssessmentType}`;
			// const response = await dispatch(getEmployeeAssessment(requestParams)); // use to get direct API response
			dispatch(getEmployeeAssessmentList(requestParams)); // employeeAssessmentResponse, employeeAssessmentResponseStatus
		} catch (error) {
			console.error(error);
		}
	};

	const handleTabChange = (event: any, newValue: any) => {
		dispatch(getSelfAssessmentPending());
		setTabSelected(newValue);
		dispatch(currentDashboardTab(newValue));
	};
	const handleNominateDrawerOpen = (event: React.ChangeEvent<HTMLInputElement>, user: any, isManaged: boolean) => {
		setOpenNominateDrawer(true);
	};
	const showThreeSixtyForUser = (user: any) => {};

	const handleOneOnOneClick = (e: any, clickType: string, empId: any) => {
		// clickType ===  MyAssessmentList || MyAssessMentTab
		const { reportingTo } = userDetails || {};
		let employeeId = reportingTo || 0;
		if (clickType === 'MyAssessmentList') {
			employeeId = empId || 0;
		}
		if (clickType && employeeId) {
			const redirectInfo = { employeeId: employeeId, employeeInfo: null, redirectFrom: 'Assessment' };

			getRedirectedEmployeeData(redirectInfo);

			// setLocalStorageItem('requestOneOnOneRedirect', JSON.stringify(redirectInfo));
			// dispatch(currentEngageTabs(3));
			// dispatch(updateRoute(ENGAGE));
			// props.switchUser(e, 'user', ENGAGE);
		}
	};

	const getRedirectedEmployeeData = async (details: any) => {
		setLoader(true);
		const { employeeId } = details || {};
		const resp: any = await dispatch(globalSearchAPIWithTeam(employeeId, 1, 999999, 1));
		setLoader(false);
		if (resp && resp?.data && resp?.data?.status === 200) {
			let list = resp?.data?.entityList;
			list = list && list.filter((item: any) => item.employeeId !== userDetails.employeeId);
			const employeeInfo = list && list.length ? list.find((rec: any) => rec?.employeeId === employeeId) : null;
			if (employeeInfo) {
				handleRequestOneOnOneCall({}, employeeInfo);
			} else {
				handleClickRequestOneOnOneCreate({});
			}
		} else {
			handleClickRequestOneOnOneCreate({});
		}
	};
	const handleRequestOneOnOneCall = (event: any, empRecord: any) => {
		setRequestOneOnOneDrawer({ open: true, type: 'Direct', details: '', empInfo: empRecord });
	};
	const handleClickRequestOneOnOneCreate = (event: any) => {
		setRequestOneOnOneDrawer({ open: true, type: 'Add' });
	};

	// console.log('performanceCycle ==>', performanceCycle);
	const scrollPageHeader = (e: any) => {
		const objElement = document.getElementById('page-perform-subheader');
		if (window.pageYOffset >= 88) {
			objElement && objElement.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement && objElement.classList.remove('sticky-header');
		}
	};
	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}

			<Box className='page-subheader' id='page-perform-subheader'>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'>
						<Box className='page-subheader-title'>
							<Typography variant='h2'>{t('performanceAssessment')}</Typography>
						</Box>
						<UserPerformTabs
							tabSelected={tabSelected}
							handleTabChange={handleTabChange}
							{...props}
							userDetails={userDetails}
						/>
					</Box>
					<Box className='page-subheader-right'>
						<CycleTab {...props} />
						<Header {...props} fromTab={true} isPerform={true} />
					</Box>
				</Box>
			</Box>
			<Box className='pad-container'>
				{/*<FinalRatingAssessment />*/}
				<UserPerformTabPanel value={tabSelected} index={0}>
					<UserSelfAssessment
						performanceCycle={performanceCycle}
						getSelfAssessmentDetails={getSelfAssessmentDetails}
						getEmployeeAssessmentDetails={getEmployeeAssessmentDetails}
						loader={loader}
						setLoader={setLoader}
						handleOneOnOneClick={handleOneOnOneClick}
					/>
				</UserPerformTabPanel>
				<UserPerformTabPanel value={tabSelected} index={1}>
					<TeamsAssessment
						{...props}
						key={performanceCycle?.performanceCycleId}
						performanceCycle={performanceCycle}
						tabSelected={tabSelected}
						handleOneOnOneClick={handleOneOnOneClick}
						loader={loader}
						setLoader={setLoader}
						reloadRequired={reloadRequired}
						setReloadRequired={setReloadRequired}
						setOpenNominateDrawer={handleNominateDrawerOpen}
						showThreeSixtyForUser={showThreeSixtyForUser}
						getEmployeeAssessmentDetails={getEmployeeAssessmentDetails}
						getSelfAssessmentDetails={getSelfAssessmentDetails}
					/>
				</UserPerformTabPanel>
				<UserPerformTabPanel value={tabSelected} index={2}>
					<UserThreeSixtyList
						{...props}
						cycleData={performanceCycle}
						tabSelected={tabSelected}
						handleOneOnOneClick={handleOneOnOneClick}
						setLoader={setLoader}
						loader={loader}
						getEmployeeAssessmentDetails={getEmployeeAssessmentDetails}
					/>
				</UserPerformTabPanel>
			</Box>

			{requestOneOnOneDrawer && requestOneOnOneDrawer?.open ? (
				<CreateOneOnOne
					requestOneOnOneDrawer={requestOneOnOneDrawer}
					setRequestOneOnOneDrawer={setRequestOneOnOneDrawer}
				/>
			) : (
				<></>
			)}
		</>
	);
};
