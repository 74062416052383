import React, { useState } from 'react';
import { Avatar, Badge, Box, ClickAwayListener, Paper, Popper, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AlertInfoIcon, NoUserIcon, SearchIcon } from '../../config/svg/CommonSvg';
import { OKRButton } from '../Common/OKRButton';
import { CancelIcon } from '../../config/svg/Action';
import { SearchUsers } from '../Common/SearchUsers';
import { globalSearchAPIWithTeam, searchEmployeeInRole } from '../../action/search';
import { getUserById, updatePage } from '../../action/common';
import { useDispatch } from 'react-redux';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { Highlighter } from 'react-bootstrap-typeahead';
import { globalTaskCreateLinkUnSaveCheck, globalTaskCreatePopupDetails } from '../../action/globalTask';

export const TaskDelegatePopper: React.FC<any> = (props: any) => {
	const {
		formDetails,
		onSelectedUser,
		handleRemoveAssignee,
		defaultRecentUserList,
		popperPosition,
		isActionable,
		isCreateTask,
		setIsCreateLinkPopupOpen,
		callingFrom,
		isRemovable = false,
		isUserDeleted = false,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = useState({ active: false, type: '' });
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const handleClick = (event: any, typePopper: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		if (isCreateTask || callingFrom === 'OneOnOneTask') {
			setIsCreateLinkPopupOpen(true);
			dispatch(globalTaskCreateLinkUnSaveCheck(true));
			dispatch(globalTaskCreatePopupDetails('krDelegatePopup'));
		}
		setOpen({ active: true, type: typePopper });
	};
	const handleClose = () => {
		setAnchorEl(null);
		if (isCreateTask || callingFrom === 'OneOnOneTask') {
			setIsCreateLinkPopupOpen(false);
			dispatch(globalTaskCreateLinkUnSaveCheck(false));
			dispatch(globalTaskCreatePopupDetails(null));
		}
		setOpen({ active: false, type: '' });
		setCustomStyle(false);
	};
	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	const searchEmployeeInRoleCheck = (searchStr: any, page: any, pageSize: any) => {
		return dispatch(searchEmployeeInRole(searchStr, page, pageSize));
	};
	const globalSearchWithTeam = (searchStr: any, page: any, pageSize: any, type: any) => {
		return dispatch(globalSearchAPIWithTeam(searchStr, page, pageSize, type));
	};
	const getCustomUserById = (data: any) => {
		return dispatch(getUserById(data));
	};
	const updateCustomPage = (page: any) => {
		return dispatch(updatePage(page));
	};
	const getLabelKey = (option: any) => {
		return option.searchType === 2 ? `${option.organisationName}` : `${option.firstName} ${option.lastName}`;
	};
	const handlePopperScroll = (event: any, elementId: any) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
			}
		}, 100);
	};

	return (
		<>
			{formDetails?.assignedTo && formDetails?.userDetails ? (
				<Box className={`selected-item selected-delegate ${open.active ? 'active-popup' : ''}`}>
					{!isUserDeleted ? (
						<></>
					) : (
						<Tooltip placement='top' arrow classes={{ popper: 'tooltip-alert' }} title={t('userDeleted')}>
							<Badge
								className={'badge-icon delegated-badge-icon'}
								overlap='rectangular'
								badgeContent={<AlertInfoIcon />}
							></Badge>
						</Tooltip>
					)}
					<Tooltip arrow title={`${formDetails?.userDetails?.firstName} ${formDetails?.userDetails?.lastName}`}>
						{formDetails?.userDetails?.imagePath && formDetails?.userDetails?.imagePath.trim() !== '' ? (
							<Avatar
								onClick={(e) => {
									if (isActionable) {
										handleClick(e, 'type2');
										handlePopperScroll(e, 'krDelegatePopup');
									}
								}}
								src={formDetails?.userDetails.imagePath}
								className={isCreateTask ? '' : formDetails?.userDetails?.isActive === false ? 'disable-user' : ''}
							/>
						) : formDetails?.userDetails.firstName ? (
							<Avatar
								onClick={(e) => {
									if (isActionable) {
										handleClick(e, 'type2');
										handlePopperScroll(e, 'krDelegatePopup');
									}
								}}
								className={isCreateTask ? '' : formDetails?.userDetails?.isActive === false ? 'disable-user' : ''}
							>
								{getUserName(formDetails?.userDetails)}
							</Avatar>
						) : (
							<></>
						)}
					</Tooltip>
					{isActionable || isRemovable ? (
						<OKRButton
							className='cancel-btn'
							title={isRemovable ? 'Remove the task from your list' : null}
							icon={<CancelIcon />}
							handleClick={handleRemoveAssignee}
						/>
					) : (
						<></>
					)}
				</Box>
			) : (
				<>
					{isActionable ? (
						<OKRButton
							className='blue-btn'
							icon={<NoUserIcon />}
							title={t('delegateTo')}
							handleClick={(e) => {
								handleClick(e, 'type1');
								handlePopperScroll(e, 'krDelegatePopup');
							}}
						/>
					) : (
						<></>
					)}
				</>
			)}
			<Popper
				className={`user-quick-search-popover task-delegate-popper ${
					open.type === 'type1' ? ' task-delegate-popper-type1' : ''
				} ${popperPosition === 'bottom-end' ? 'task-delegate-popper-end' : ''}`}
				open={open.active}
				anchorEl={anchorEl}
				placement={popperPosition || 'bottom-start'}
				modifiers={{
					flip: {
						enabled: false,
					},
					offset: {
						enabled: popperPosition ? true : false,
						offset: open.type === 'type1' && popperPosition === 'bottom-end' ? '69,0' : '65,0',
					},
				}}
				id={'krDelegatePopup'}
				container={callingFrom === 'GlobalTask' ? document.body : document.getElementById('kr-task-drawer')}
			>
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<Box className='search-user-inner'>
							<OKRButton className={''} icon={<SearchIcon />} color='primary' />
							{/* <OKRButton className='cancel-btn' icon={<CancelIcon />} /> */}
							<SearchUsers
								module={'myGoal'}
								onSelectedUser={(user: any) => {
									if (user && user.length) {
										onSelectedUser(user[0]);
										handleClose();
									}
								}}
								defaultFocus={true}
								onInputChange={handleSearchUserInputChange}
								placeHolderText={t('searchText')}
								isTeamSearch={false}
								globalSearchAPIWithTeam={globalSearchWithTeam}
								searchEmployeeInRole={searchEmployeeInRoleCheck}
								getUserById={getCustomUserById}
								hideEmailId={true}
								updatePage={updateCustomPage}
								notFoundTextType1={true}
								defaultFocusSetTime={true}
							/>
							{customStyle ? (
								<></>
							) : defaultRecentUserList && defaultRecentUserList.length ? (
								<Box className='rbt-menu dropdown-menu' id='async-pagination'>
									{defaultRecentUserList?.map((option: any) => (
										<Box className='dropdown-item'>
											<Box
												className={`user-info`}
												onClick={() => {
													onSelectedUser(option);
													handleClose();
												}}
											>
												<Box className='user-img' id={`src-user-img-${option.employeeId}`}>
													{option.imagePath ? (
														<Avatar alt={`${option?.firstName} ${option?.lastName}`} src={option.imagePath} />
													) : (
														<Avatar
															style={{
																backgroundColor: getRandomBackgroundColor(option?.backGroundColorCode),
																color: getRandomColor(option?.colorCode),
															}}
														>
															{getUserName(option)}
														</Avatar>
													)}
												</Box>
												<Box className='user-info-details'>
													<Box className='user-info-label'>
														<Typography variant='h4' id={`src-user-name-${option.employeeId}`}>
															<Highlighter search={''}>{getLabelKey(option)}</Highlighter>
														</Typography>
													</Box>
												</Box>
											</Box>
										</Box>
									))}
								</Box>
							) : (
								<></>
							)}
						</Box>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	);
};
