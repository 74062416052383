import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, FormLabel, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CalendarIcon, NameRenameIcon, NotificationIcon } from '../../../../../../config/svg/CommonSvgIcon';
import { ErrorIcon } from '../../../../../../config/svg/formElementIcons';
import { FeedbackCategoryDropdown } from './FeedbackCategoryDropdown';
import { NumberFormatComponent } from '../../../../../Common/NumberFormat';
import {
	FeedbackEditEndDateType,
	FeedbackEditTimeFrame,
	FeedbackTimeFrame,
	FeedbackTimeFrameType,
} from '../../../../../../config/constant';

export const CreateAdminFeedbackDrawer: React.FC<any> = (props: any) => {
	const { createEditData, updateFieldData, errorObjectData, setErrorObjectData } = props;
	const { t } = useTranslation();
	return (
		<>
			<Box className='drawer-content-panel create-feedback-drawer'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					<Box className='drawer-form-fields'>
						<Box className='drawer-input-field' id='feedback-category-title'>
							<TextField
								fullWidth
								label={
									<Fragment>
										<NameRenameIcon />
										{t('categoryName')}
										<i style={{ color: 'red' }}>*</i>
									</Fragment>
								}
								className='helperText-bottom'
								maxRows={3}
								multiline
								placeholder={t('enterTitleText')}
								inputProps={{ maxLength: 25 }}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									'aria-label': t('categoryName'),
									disableUnderline: true,
								}}
								name={'title'}
								helperText={
									errorObjectData &&
									errorObjectData.name && (
										<Fragment>
											<ErrorIcon />
											{t('categoryNameValidation')}
										</Fragment>
									)
								}
								defaultValue={createEditData.name}
								onChange={(e: any) => {
									updateFieldData('name', e.target.value);
									setErrorObjectData({ ...errorObjectData, name: false });
								}}
							/>
						</Box>
						<Box className='drawer-blue-bg-full'>
							<Box className='drawer-form-fields-group feedback-cat-fields-group'>
								<Box className='drawer-input-field drawer-input-field-col2'>
									<Box className='field-col2 helperText-bottom' id='feedback-category-timeframe'>
										<FormLabel id='scale-type' component='legend'>
											<CalendarIcon />
											{t('timeFrameText')}
											<i style={{ color: 'red' }}>*</i>
										</FormLabel>
										<FeedbackCategoryDropdown
											feedbackTypesOptions={FeedbackTimeFrame}
											setSelectedValue={(e: any) => {
												updateFieldData('timeframe', e);
											}}
											selectedValue={createEditData.timeframe}
										/>
										{errorObjectData && errorObjectData.timeframe && (
											<Typography className='error-field'>
												<ErrorIcon /> {t('timeframeIsRequired')}
											</Typography>
										)}
									</Box>
									{createEditData?.timeframe !== 3 && (
										<Box className='field-col2 helperText-bottom' id='feedback-category-timeframe-type'>
											<FormLabel id='scale-type' component='legend'>
												<CalendarIcon />
												{t('timeframeType')}
												<i style={{ color: 'red' }}>*</i>
											</FormLabel>
											<FeedbackCategoryDropdown
												feedbackTypesOptions={FeedbackTimeFrameType}
												setSelectedValue={(e: any) => {
													updateFieldData('timeframeType', e, {
														timeframeDuration: 0,
														reminderDuration: 0,
														editTimeFrameType: 0,
														editEndDateType: 0,
														noOfExtensionsAllows: 0,
														autoExtensionsDays: 0,
													});
												}}
												selectedValue={createEditData.timeframeType}
											/>
											{errorObjectData && errorObjectData.timeframeType && (
												<Typography className='error-field'>
													<ErrorIcon /> {t('timeframeTypeIsRequired')}
												</Typography>
											)}
										</Box>
									)}
								</Box>
								{createEditData?.timeframe !== 3 && createEditData?.timeframeType === 1 && (
									<>
										<Box className='drawer-input-field drawer-input-field-col2'>
											<Box className='field-col2 helperText-bottom' id='feedback-edit-timeframe'>
												<FormLabel id='edit-timeframe' component='legend'>
													<CalendarIcon />
													{t('editTimeFrame')}
													<i style={{ color: 'red' }}>*</i>
												</FormLabel>
												<FeedbackCategoryDropdown
													feedbackTypesOptions={FeedbackEditTimeFrame}
													setSelectedValue={(e: any) => {
														updateFieldData('editTimeFrameType', e, {
															editEndDateType: 0,
															autoExtensionsDays: 0,
															noOfExtensionsAllows: 0,
														});
														setErrorObjectData({ ...errorObjectData, editTimeFrameType: false });
													}}
													selectedValue={createEditData.editTimeFrameType}
												/>
												{errorObjectData && errorObjectData.editTimeFrameType && (
													<Typography className='error-field'>
														<ErrorIcon /> {t('timeframeTypeIsRequired')}
													</Typography>
												)}
											</Box>
											{createEditData.editTimeFrameType === 3 && (
												<Box className='field-col2 helperText-bottom' id='feedback-edit-end-date-type'>
													<FormLabel id='edit-end-date-type' component='legend'>
														<CalendarIcon />
														{t('editEndDateType')}
														<i style={{ color: 'red' }}>*</i>
													</FormLabel>
													<FeedbackCategoryDropdown
														feedbackTypesOptions={FeedbackEditEndDateType}
														setSelectedValue={(e: any) => {
															updateFieldData('editEndDateType', e);
															setErrorObjectData({ ...errorObjectData, editEndDateType: false });
														}}
														selectedValue={createEditData.editEndDateType}
													/>
													{errorObjectData && errorObjectData.editEndDateType && (
														<Typography className='error-field'>
															<ErrorIcon /> {t('timeframeTypeIsRequired')}
														</Typography>
													)}
												</Box>
											)}
										</Box>
										{createEditData.editEndDateType === 3 && (
											<Box className='drawer-input-field drawer-input-field-col2'>
												<Box className='field-col2' id='feedback-auto-extend-by-days'>
													<TextField
														fullWidth
														label={
															<Fragment>
																<NameRenameIcon />
																{t('autoExtendByDays')}
																<i style={{ color: 'red' }}>*</i>
															</Fragment>
														}
														className='helperText-bottom'
														placeholder={t('enterDuration')}
														inputProps={{ maxLength: 50 }}
														InputLabelProps={{
															shrink: true,
														}}
														InputProps={{
															'aria-label': t('autoExtendByDays'),
															disableUnderline: true,
															inputComponent: NumberFormatComponent,
														}}
														name={'title'}
														onChange={(e: any) => {
															updateFieldData('autoExtensionsDays', e.target.value);
															setErrorObjectData({ ...errorObjectData, autoExtensionsDays: false });
														}}
														defaultValue={createEditData.autoExtensionsDays}
														helperText={
															errorObjectData &&
															errorObjectData.autoExtensionsDays && (
																<Fragment>
																	<ErrorIcon />
																	{t('durationIsRequired')}
																</Fragment>
															)
														}
													/>
												</Box>
												<Box className='field-col2' id='feedback-no-of-extensions-allowed'>
													<TextField
														fullWidth
														label={
															<Fragment>
																<NameRenameIcon />
																{t('noOfExtensionsAllowed')}
																<i style={{ color: 'red' }}>*</i>
															</Fragment>
														}
														className='helperText-bottom'
														placeholder={t('enterDuration')}
														inputProps={{ maxLength: 50 }}
														InputLabelProps={{
															shrink: true,
														}}
														InputProps={{
															'aria-label': t('noOfExtensionsAllowed'),
															disableUnderline: true,
															inputComponent: NumberFormatComponent,
														}}
														name={'title'}
														onChange={(e: any) => {
															updateFieldData('noOfExtensionsAllows', e.target.value);
															setErrorObjectData({ ...errorObjectData, noOfExtensionsAllows: false });
														}}
														defaultValue={createEditData.noOfExtensionsAllows}
														helperText={
															errorObjectData &&
															errorObjectData.noOfExtensionsAllows && (
																<Fragment>
																	<ErrorIcon />
																	{t('durationIsRequired')}
																</Fragment>
															)
														}
													/>
												</Box>
											</Box>
										)}
									</>
								)}
								<Box className='drawer-input-field drawer-input-field-col2'>
									{createEditData?.timeframe !== 3 && createEditData?.timeframeType === 2 && (
										<Box className='field-col2' id='feedback-category-duration'>
											<TextField
												fullWidth
												label={
													<Fragment>
														<NameRenameIcon />
														{t('durationInDays')}

														{createEditData.timeframe === 1 && <i style={{ color: 'red' }}>*</i>}
													</Fragment>
												}
												className='helperText-bottom'
												placeholder={t('enterDuration')}
												inputProps={{ maxLength: 50 }}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													'aria-label': t('durationLevel'),
													disableUnderline: true,
													inputComponent: NumberFormatComponent,
												}}
												name={'title'}
												onChange={(e: any) => {
													updateFieldData('timeframeDuration', e.target.value);
													setErrorObjectData({ ...errorObjectData, timeframeDuration: false });
												}}
												defaultValue={createEditData.timeframeDuration}
												helperText={
													errorObjectData &&
													errorObjectData.timeframeDuration && (
														<Fragment>
															<ErrorIcon />
															Duration is required
														</Fragment>
													)
												}
											/>
										</Box>
									)}
									{createEditData?.timeframe !== 3 && createEditData?.timeframeType === 2 && (
										<Box className='field-col2' id='feedback-category-noti-duration'>
											<TextField
												fullWidth
												label={
													<Fragment>
														<NotificationIcon />
														{t('notificationDurationEveryXDays')}
														{createEditData.timeframe === 1 && <i style={{ color: 'red' }}>*</i>}
													</Fragment>
												}
												className='helperText-bottom'
												placeholder={t('enterNotificationDuration')}
												inputProps={{ maxLength: 50 }}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													'aria-label': t('notificationDuration'),
													disableUnderline: true,
													inputComponent: NumberFormatComponent,
												}}
												name={'title'}
												onChange={(e: any) => {
													updateFieldData('reminderDuration', e.target.value);
													setErrorObjectData({ ...errorObjectData, reminderDuration: false });
												}}
												defaultValue={createEditData.reminderDuration}
												helperText={
													errorObjectData &&
													errorObjectData.reminderDuration && (
														<Fragment>
															<ErrorIcon />
															{t('notificationDurationIsRequired')}
														</Fragment>
													)
												}
											/>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</form>
			</Box>
		</>
	);
};
