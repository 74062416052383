import React, { Fragment, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MainDrawer } from '../../../CommonComponent/MainDrawer';
import { ManageScaleTab } from './ManageScaleTab';

export const ManageScale: React.FC<any> = (props: any) => {
	const {
		openManageScaleDrawer,
		// setOpenManageScaleDrawer,
		manageScaleTabSelected,
		// setManageScaleTabSelected,
		resetCustomScaleTabData,
		handleSaveCustomScaleForm,
		loader,
		customScaleType,
		setScaleDetailsFromAssessmentForm,
	} = props;
	const { t } = useTranslation();
	const handleDrawerClose = (event: any, type: string) => {
		if (type === 'close' || type === 'cancel') {
			resetCustomScaleTabData(0, 1);
			setScaleDetailsFromAssessmentForm(null);
		} else if (type === 'save' || type === 'draft') {
			handleSaveCustomScaleForm(event);
		}
	};
	return (
		<MainDrawer
			open={openManageScaleDrawer}
			drawerClassName={`main-drawer-panel ${manageScaleTabSelected === 0 ? 'main-drawer-no-footer' : ''}`}
			transitionDuration={{ enter: 500, exit: 1000 }}
			headerTitle={t('manageScale')}
			loader={loader}
			children={
				<Box className='drawer-inner-content'>
					<ManageScaleTab {...props} />
				</Box>
			}
			cancelBtnText={customScaleType === 'View' ? t('back') : t('cancelBtn')}
			saveButtonText={customScaleType === 'Edit' ? t('update') : t('saveBtn')}
			rightCloseBtn={false}
			isSaveDraft={false}
			isFooterVisible={manageScaleTabSelected === 0 ? false : true}
			isSaveButtonVisible={customScaleType === 'View' ? false : true}
			isSaveButtonDisabled={customScaleType === 'View' || loader ? true : false}
			//isDraftButtonDisabled={loader}
			handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
			handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
			handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
			handleDraftButtonClick={(event: any) => handleDrawerClose(event, 'draft')}
		/>
	);
};
