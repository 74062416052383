import React, { useRef, useState } from 'react';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UploadIcon from '../../../../images/upload-img-new.svg';
import { DeleteIcon, DownloadIcon } from '../../../../config/svg/CommonSvgIcon';
import '../../../../styles/pages/admin/bulkUserUpload.scss';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import exportFromJSON from 'export-from-json';
import { BulkTeamCsvTemplateUrl } from '../../../../config/url';

export const AddBulkTeamForm: React.FC<any> = (props) => {
	const {
		uploadFileData,
		setUploadFileData,
		statusLogMessage,
		setStatusLogMessage,
		setBulkTeamEdited,
		downloadButtonClass,
	} = props;
	const { t } = useTranslation();
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	const preventDefault = (event: any) => event.preventDefault();

	/**
	 * handle upload browse File
	 */
	const uploadFile = (e: any) => {
		if (inputFileRef) {
			inputFileRef?.current?.click();
		}
	};

	/**
	 * handle uploaded browse file
	 */
	const handleFileChange = async (e: any) => {
		const file = e.target.files[0];
		e.target.value = '';
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		setUploadFileData({
			...uploadFileData,
			filePath: file,
			fileName: file.name,
			fileSize: size,
			fileType: fileType,
			fileUploaded: false,
		});
		setBulkTeamEdited(true);
		setStatusLogMessage([]);
		setTimeout(() => {
			const selectedEl = document.getElementById('bottom-scroll');
			if (selectedEl) {
				selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
			}
		}, 15);
	};

	/**
	 * handle export file
	 */
	const exportToExcel = () => {
		const updatedErrorLogMessage = statusLogMessage.map((item: any) => {
			return {
				'Record Row & Name': item.key, // Replace 'key' with 'Record Row & Name'
				'Error Description': item.value, // Replace 'value' with 'Error Description'
			};
		});
		exportFromJSON({ data: updatedErrorLogMessage, fileName: 'status_report', exportType: 'csv' });
	};

	/**
	 * handle delete file
	 */
	const removeFile = () => {
		setStatusLogMessage([]);
		setUploadFileData({
			...uploadFileData,
			filePath: '',
			fileName: '',
			fileUploaded: false,
			fileSize: 0,
			fileType: '',
		});
	};

	/**
	 * handle close alert box
	 */
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'deleteFile') {
				removeFile();
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	return (
		<>
			<input ref={inputFileRef} type='file' style={{ display: 'none' }} onChange={handleFileChange} />
			<Box className='bulk-user-content'>
				<Box className='upload-head'>
					<Typography variant='h5'>
						<Link id={'download-temp'} target='_blank' rel='noopener noreferrer' href={BulkTeamCsvTemplateUrl}>
							{t('downloadTemplate')}
						</Link>{' '}
						<span dangerouslySetInnerHTML={{ __html: t('bulkTeamUploadHeading') }}></span>
					</Typography>
				</Box>
				<Box
					className='upload-file'
					onClick={(event: any) => {
						uploadFile(event);
					}}
				>
					<Box className='upload-file-inner'>
						<img src={UploadIcon} alt='Upload File' className='upload-img' />
						<Typography variant='h4'>
							<Link id={'browse-btn'} href='#' onClick={preventDefault}>
								{t('UsersBulkUploadBrowseFile')}
							</Link>
						</Typography>
					</Box>
				</Box>

				{uploadFileData.fileName !== '' ? (
					<Box className='uploading-content'>
						<Box className='uploading-bar'>
							<Box className='bar'>
								<Box className='success'></Box>
								<Box className='inner-bar'>
									<Typography>
										{t('UsersBulkUploadFileName')} : {uploadFileData.fileName ? uploadFileData.fileName : ''}
									</Typography>
									<Button
										className='delete-icon'
										onClick={() =>
											setModalProps({
												open: true,
												module: 'delete',
												type: 'deleteFile',
												message: t('UsersBulkUploadDeleteFile'),
											})
										}
									>
										<DeleteIcon />
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				) : (
					<></>
				)}
				{uploadFileData.fileUploaded ? (
					<Box className='upload-result'>
						<Box className={`download-status-link ${downloadButtonClass ? 'error' : ''}`} onClick={exportToExcel}>
							<DownloadIcon />
							<Link>{t('downloadStatusReportsText')}</Link>
						</Box>
					</Box>
				) : (
					<></>
				)}
				<Box className='note-text' id='uploaded-file-details'>
					<Typography variant='body1' className='short-des-text'>
						<strong>{t('RoleEditNote')}:</strong> {t('bulkTeamUploadNote')}
					</Typography>
				</Box>
				<Box id='bottom-scroll'></Box>
			</Box>

			{modalProps.open && (
				<DialogComponent
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
