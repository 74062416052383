import React, { useEffect, useState } from 'react';
import {
	Box,
	ClickAwayListener,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Link,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { useTranslation } from 'react-i18next';
import { DownFilledArrow, RightArrowIcon } from '../../config/svg/ArrowSvg';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { CycleIcon } from '../../config/svg/CommonSvg';

export const CycleDropdownQuickLinks: React.FC<any> = (props) => {
	const { handleCycleClick, futureCyclesList, pastCyclesList, currentCycleSelectionData } = props;
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState<Boolean>(false);
	const [open, setOpen] = React.useState<Boolean>(false);
	const [pastCycles, setPastCycles] = useState(false);
	const [futureCycles, setFutureCycles] = useState(false);
	const [cycleList, setCycleList] = useState<any[]>([]);

	useEffect(() => {
		if (currentCycleSelectionData?.type === 3) {
			setPastCycles(true);
			setCycleList(pastCyclesList);
		} else if (currentCycleSelectionData?.type === 4) {
			setFutureCycles(true);
			setCycleList(futureCyclesList);
		} else {
			setPastCycles(false);
			setFutureCycles(false);
		}
	}, [currentCycleSelectionData, open]);

	const handleChange = (panel: any) => (event: any, isExpanded: any) => {
		setExpanded(isExpanded ? panel : false);
	};
	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
		setPastCycles(false);
		setFutureCycles(false);
	};
	const handlePastCycles = () => {
		setCycleList(pastCyclesList);
		setPastCycles(!pastCycles);
	};
	const handleFutureCycles = () => {
		setFutureCycles(!futureCycles);
		setCycleList(futureCyclesList);
	};
	return (
		<Box className='cycle-dropdown'>
			<OKRButton
				text={`${currentCycleSelectionData?.cycleLabel ? currentCycleSelectionData?.cycleLabel : t('lastTwoCycles')} `}
				className={`cycle-dropdown-btn  ${open ? 'open' : ''}`}
				id='cycle-dropdown-btn'
				icon={<DownFilledArrow />}
				handleClick={handleClick}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='tenure-list' id='cycle-dropdown-wrapper'>
						{!pastCycles && !futureCycles && (
							<Box className='cycle-quick-links'>
								<List>
									<ListItem
										id='last-two-cycle'
										className={`${currentCycleSelectionData?.type === 1 ? 'active' : ''}`}
										onClick={() => {
											handleClickAway();
											handleCycleClick(1, 0, t('lastTwoCycles'));
										}}
									>
										<ListItemText primary={t('lastTwoCycles')} />
									</ListItem>
									<ListItem
										id='current-cycle-link'
										className={`${currentCycleSelectionData?.type === 2 ? 'active' : ''}`}
										onClick={() => {
											handleClickAway();
											handleCycleClick(2, 0, t('currentCycle'));
										}}
									>
										<ListItemText primary={t('currentCycle')} />
									</ListItem>
									<ListItem id='past-cycle-link' onClick={handlePastCycles}>
										<ListItemText primary={t('pastCycle')} />
									</ListItem>
									<ListItem id='future-cycle-link' onClick={handleFutureCycles}>
										<ListItemText primary={t('futureCycles')} />
									</ListItem>
								</List>
							</Box>
						)}
						{(pastCycles || futureCycles) && (
							<Box className='cycle-sub-panel'>
								<Box className='back-to-main'>
									<Typography variant='body2'>
										<Link id='back-btn' onClick={pastCycles ? handlePastCycles : handleFutureCycles}>
											<BackArrow /> {t('back')}
										</Link>
										{pastCycles ? t('yourPastOKRsCycles') : t('yourFutureOKRsCycles')}
									</Typography>
								</Box>
								{cycleList.map((cycleYears: any, idx: any) => (
									<ExpansionPanel
										key={`cycle-panel-`}
										expanded={
											currentCycleSelectionData?.cycleId.length > 0 &&
											cycleYears.quarterDetails.find(
												(item: any) => item.organisationCycleId === currentCycleSelectionData?.cycleId
											)
												? true
												: expanded === cycleYears
										}
										onChange={handleChange(cycleYears)}
										id={`cycle-panel-`}
									>
										<ExpansionPanelSummary
											expandIcon={<RightArrowIcon />}
											aria-controls='panel1a-content'
											id='cycle-panel-header'
										>
											<Typography>
												Year {cycleYears.year} {cycleYears.isAnyNonDefaultOkr && <CycleIcon />}
											</Typography>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											<List component='nav' aria-label={t('HeaderTenureNavigation')}>
												{cycleYears.quarterDetails.map((QuaterDetails: any) => (
													<ListItem
														button
														key={`cycle-list-item`}
														className={`${
															currentCycleSelectionData?.cycleId.length > 0 &&
															currentCycleSelectionData?.cycleId[0] === QuaterDetails.organisationCycleId
																? 'active'
																: ''
														}`}
														id={`cycle-list-item`}
														onClick={() => {
															handleClickAway();
															handleCycleClick(
																pastCycles ? 3 : 4,
																QuaterDetails.organisationCycleId,
																`${QuaterDetails.symbol}, ${cycleYears.year}`
															);
														}}
													>
														<ListItemText
															className={`${
																currentCycleSelectionData?.cycleId.length > 0 &&
																currentCycleSelectionData?.cycleId[0] === QuaterDetails.organisationCycleId
																	? 'active'
																	: ''
															}`}
															primary={`${QuaterDetails.symbol}, ${cycleYears.year}`}
															secondary={QuaterDetails.isAnyNonDefaultOkr && <CycleIcon />}
														/>
													</ListItem>
												))}
											</List>
										</ExpansionPanelDetails>
									</ExpansionPanel>
								))}
							</Box>
						)}
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
