import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Collapse, FormControlLabel, Switch, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { NoSearchRecord } from '../../../CommonComponent/NoSearchRecord';
import { SearchField } from '../../../../Common/SearchField';
import { CycleDropdown } from '../../../../Common/CycleDropdown';
import { getLocalStorageItem } from '../../../../../services/StorageService';
import { OKRButton } from '../../../../Common/OKRButton';
import { RightArrowIcon } from '../../../../../config/svg/ArrowSvg';
import { useDispatch, useSelector } from 'react-redux';
import { getCycleDefaultSelection } from '../../../../../config/utils';
import { getCopyDefaultOkrList } from '../../../../../action/adminDefaultOkr';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../../../config/svg/formElementIcons';
import { Enums } from '../../../../../config/enums';

export const CopyAdminOkr: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { setModalProps, modalProps, mainTabSelected, handleMainTabChange, krToggle, setKrToggle } = props;
	const allCycleDetails = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const cycleId = getLocalStorageItem('cycleId') ? parseInt(getLocalStorageItem('cycleId') || '0') : 0;
	const cycleDuration = getLocalStorageItem('cycleDuration') || null;
	const currentCycleYear = getLocalStorageItem('year') || null;
	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(null);
	// const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	const [currentCycleId, setCurrentCycleId] = useState(cycleId);

	const [expandedKr, setExpandedKr] = useState<any[]>([]);

	const { cycleDetailsForDefaultOkr } = useSelector((state: any) => state.adminDefaultOkrReducer);
	useEffect(() => {
		if (
			cycleDetailsForDefaultOkr &&
			cycleDetailsForDefaultOkr.cycleDetails &&
			cycleDetailsForDefaultOkr.cycleDetails.length
		) {
			let currentQuarterDetails: any = getCycleDefaultSelection(cycleDetailsForDefaultOkr.cycleDetails, cycleId);
			if (currentQuarterDetails) {
				setSelectedCycleDetails({ ...currentQuarterDetails });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cycleDetailsForDefaultOkr]);

	const [objectiveData, setObjectiveData] = useState<any>([]);

	const dispatch = useDispatch();
	const [currentCycleSelection, setCurrentCycleSelection] = useState<any[]>([]);

	const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	const [listLoader, setListLoader] = useState<boolean>(false);

	useEffect(() => {
		if (selectedCycleDetails) {
			getPerformanceReviewList({
				cycleId: [selectedCycleDetails?.organisationCycleId],
				search: '',
				pageSize: 1,
				pageIndex: 10,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCycleDetails]);
	const getPerformanceReviewList = async (requestPayload: any) => {
		setListLoader(true);
		const response: any = await dispatch(getCopyDefaultOkrList(requestPayload));
		if (response.status === 200) {
			setListLoader(false);
			setObjectiveData(
				response.data.entity.records.length > 0 ? response.data.entity.records[0].defaultCopyGoalObjectiveResponses : []
			);
		} else {
			setListLoader(false);
		}
	};
	const handleCycleChange = (id: any, quarterData: any, year: any) => {
		setSelectedCycleDetails({
			...quarterData,
			year: year || currentSelectedCycleYear,
		});
	};
	const [searchText, setSearchText] = useState('');

	const handleExpandKr = (e: any, item: any) => {
		let list = Object.assign([], expandedKr);
		const elementIndex = list.indexOf(item.defaultGoalObjectiveId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(item.defaultGoalObjectiveId);
		}
		setExpandedKr(list);
	};

	const handleOkrToggle = (item: any, e: any) => {
		const okrKey = item.defaultGoalObjectiveId;
		let isChecked: boolean = e.target.checked;
		let okrKrSelectionL: any[] = Object.assign({}, krToggle);
		if (isChecked === true) {
			okrKrSelectionL[okrKey] = {
				name: item.name,
				krList: {},
				goalNatureId: item.natureTypeId,
				teamCycleDetailId: currentCycleId,
			};
			item.defaultCopyGoalKeyResponses.forEach((child: any) => {
				okrKrSelectionL[okrKey].krList[child.defaultGoalKeyId] = {
					name: child.name,
				};
			});
		} else {
			if (okrKrSelectionL[okrKey] !== undefined) {
				delete okrKrSelectionL[okrKey];
			}
		}
		setKrToggle(okrKrSelectionL);
	};
	const handleKrToggle = (krData: any, okrData: any, e: any) => {
		const krKey = krData.defaultGoalKeyId;
		const okrKey = okrData.defaultGoalObjectiveId;
		let isChecked: boolean = e.target.checked;
		let okrKrSelectionL: any[] = Object.assign({}, krToggle);
		if (isChecked === true) {
			if (okrKrSelectionL[okrKey] === undefined) {
				okrKrSelectionL[okrKey] = {
					name: okrData.name,
					krList: {},
					goalNatureId: okrData.natureTypeId,
					teamCycleDetailId: okrData.teamCycleDetailId,
				};
			}
			okrKrSelectionL[okrKey].krList[krKey] = {
				name: krData.name,
			};
		} else {
			if (okrKrSelectionL[okrKey] && okrKrSelectionL[okrKey].krList[krKey]) {
				delete okrKrSelectionL[okrKey].krList[krKey];
				if (Object.keys(okrKrSelectionL[okrKey].krList).length === 0) {
					delete okrKrSelectionL[okrKey];
				}
			}
		}
		setKrToggle(okrKrSelectionL);
	};
	function a11yMainProps(index: number) {
		return {
			id: `gen-okr-tabpanel-${index}`,
			'aria-controls': `gen-okr-tabpanel-${index}`,
		};
	}
	const performSearch = (searchString: string, currentCycleS: any, clearSearch: boolean) => {
		if (!searchText || !searchString) {
			//setListPending(true);
		}
		setSearchText(searchString);
		getPerformanceReviewList({
			cycleId: [currentCycleS?.organisationCycleId],
			search: searchString,
			pageSize: 1,
			pageIndex: 10,
		});
	};
	return (
		<>
			<Box className='drawer-input-field'>
				<Tabs
					className='okr-tabs drawer-tabs copy-okr-tab'
					value={mainTabSelected}
					onChange={handleMainTabChange}
					aria-label='Copy Tabs'
				>
					<Tab label={t('copyOKRs')} value={0} {...a11yMainProps(0)} />
				</Tabs>
			</Box>
			<Box className='drawer-tabs-panel default-copy-obj-drawer'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					<Box className='drawer-form-fields'>
						<Box className='drawer-input-field'>
							<Box className='src-okr-head'>
								<SearchField
									{...props}
									checkSelection={() => {}}
									clearSelection={(e: any, currentCycleSelection: any) => {
										performSearch('', currentCycleSelection, true);
									}}
									currentCycleSelection={selectedCycleDetails}
									performSearch={performSearch}
									searchText={searchText}
									setSearchText={setSearchText}
									placeholderText={t('srcObj')}
								/>
								<CycleDropdown
									allCycleDetails={cycleDetailsForDefaultOkr}
									currentCycleId={currentCycleId}
									currentSelectedCycleYear={currentSelectedCycleYear}
									cycleDuration={cycleDuration}
									handleCycleChange={handleCycleChange}
									selectedCycleDetails={selectedCycleDetails}
									currentCycleYear={currentCycleYear}
									isCurrentCycle={isCurrentCycle}
									isCycleDisable={false}
									editModee={false}
									callingFrom={'DefaultOkr'}
									setEditModee={() => {}}
									resToUseInCalendar={() => {}}
								/>
							</Box>
						</Box>
						<Box className='drawer-blue-bg-full'>
							{listLoader ? (
								<Box className='loading-center'>
									<Typography>{t('loading')}</Typography>
								</Box>
							) : objectiveData.length > 0 ? (
								objectiveData?.map((item: any) => (
									<Box
										className={`import-okr-list import-myokr-list ${
											expandedKr.indexOf(item.defaultGoalObjectiveId) !== -1 ? 'expanded-okr' : ''
										}`}
									>
										<Box className='import-obj-row'>
											<Box className='import-obj-col'>
												<Box className='obj-title-section'>
													<Box className='import-obj-title'>
														<Checkbox
															checkedIcon={<CheckedIcon />}
															aria-label='OKR Checkbox'
															indeterminateIcon={<UnCheckedIcon />}
															icon={<BorderCheckboxIcon />}
															onChange={(e) => {
																handleOkrToggle(item, e);
															}}
															checked={krToggle[item.defaultGoalObjectiveId] !== undefined ? true : false}
															indeterminate={
																krToggle &&
																krToggle[item.defaultGoalObjectiveId] &&
																krToggle[item.defaultGoalObjectiveId].krList?.length !== 0 &&
																Object.keys(krToggle[item.defaultGoalObjectiveId].krList).length !==
																	item.defaultCopyGoalKeyResponses.length
																	? true
																	: false
															}
														/>
														<Typography variant='h4'>{item.name}</Typography>
													</Box>
													<Box className='import-okr-actions'>
														<OKRButton
															className={`btn-blue-chip ${
																expandedKr.indexOf(item.defaultGoalObjectiveId) !== -1 ? 'expand' : 'collapse'
															}`}
															icon={<RightArrowIcon />}
															text={`${item.defaultCopyGoalKeyResponses.length} ${t('keyResultTab')}`}
															handleClick={(e) => handleExpandKr(e, item)}
														/>
													</Box>
												</Box>
											</Box>
											<Box className='import-obj-progress-col'>
												<Box className='user-count'>
													<Typography variant='h6'>
														# of{' '}
														{item.assignType === Enums.ONE
															? t('designations')
															: item.assignType === Enums.TWO
															? t('jobLevel')
															: item.assignType === Enums.THREE
															? t('RoleListUsers')
															: item.assignType === Enums.FOUR
															? t('allEmployees')
															: ''}
														: {item.assignCount}
													</Typography>
												</Box>
												{/* <Box className='link-toggle'></Box> */}
											</Box>
										</Box>

										<Collapse
											className='department-kr-collapsible-content'
											in={expandedKr.indexOf(item.defaultGoalObjectiveId) !== -1}
											timeout='auto'
											unmountOnExit
										>
											{item.defaultCopyGoalKeyResponses.map((kr: any, index: any) => (
												<Box className='import-kr-list'>
													<Checkbox
														checkedIcon={<CheckedIcon />}
														aria-label='KR Checkbox'
														icon={<BorderCheckboxIcon />}
														onChange={(e) => {
															handleKrToggle(kr, item, e);
														}}
														checked={
															krToggle[item.defaultGoalObjectiveId] &&
															krToggle[item.defaultGoalObjectiveId].krList &&
															krToggle[item.defaultGoalObjectiveId].krList[kr.defaultGoalKeyId] !== undefined
																? true
																: false
														}
													/>
													<Box className='import-kr-content'>
														<Box className='import-kr-row'>
															<Box className='import-kr-col'>
																<Box className='kr-title-section'>
																	<Typography>{kr?.name}</Typography>
																</Box>
															</Box>
															{/* <Box className='import-kr-progress-col'>
																<Box className='link-toggle'>
																	<Tooltip arrow title={krToggle.indexOf(kr.defaultGoalKeyId) !== -1 ? 'Remove' : 'Add'}>
																			<FormControlLabel
																				control={
																					<Switch
																						checked={krToggle.indexOf(kr.defaultGoalKeyId) !== -1}
																						onChange={(e) => handleKrToggle(kr, item, e)}
																					// disabled={toggleDisabled}
																					/>
																				}
																				label={''}
																			/>
																		</Tooltip>
																</Box>
															</Box> */}
														</Box>
													</Box>
												</Box>
											))}
										</Collapse>
									</Box>
								))
							) : (
								<Box>
									<NoSearchRecord />
								</Box>
							)}
						</Box>
					</Box>
				</form>
			</Box>
		</>
	);
};
