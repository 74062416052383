import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
	Badge,
	Box,
	ClickAwayListener,
	Fade,
	InputAdornment,
	Paper,
	Popper,
	Slider,
	TextField,
	Typography,
	Tooltip,
	ListItem,
	List,
	ListItemText,
	IconButton,
	Chip,
} from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { AlignNewArrow, LeftArrow } from '../../config/svg/ArrowSvg';
import { useTranslation } from 'react-i18next';
import { ClapIcon, ConversationIcon, KebabIcon } from '../../config/svg/CommonSvg';
import { CancelIcon, TickIcon } from '../../config/svg/Action';
import { CreateOKRIcon } from '../../config/svg/MyGoalSvg';
import clapAnim from '../../images/clapAnim.svg';
import { AnimatedIcon } from './AnimatedIcon';
import {
	confidenceIcon,
	getCurrencyIcon,
	getCurrencyName,
	getFullName,
	getPercentageOfKr,
	getPercentageUpperValue,
	getTargetValueFormated,
	getUserDetails,
	getValueFromPercentage,
} from '../../config/utils';
import { OkrProgressBar } from '../OkrAction/OkrProgressBar';
import { Enums } from '../../config/enums';
import { AvatarUser } from './User/AvatarUser';
import { NumberFormatComponent } from './NumberFormat';
import { getLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { USER_VIEW } from '../../config/app-url';
import { useHistory } from 'react-router-dom';
import { dragDisabled } from '../../action/myGoal';
import { useDispatch } from 'react-redux';
import { OKRProgressGroupTarget } from './OKRProgressGroupTarget';
import { KrConfidencePopper } from './KrConfidencePopper';

export const OKRListProgressBar: React.FC<any> = (props) => {
	const {
		loginUserAvatar,
		contributorDetails,
		okr,
		kr,
		updateProgress,
		showEditLoader,
		currentUser,
		okrMasterData,
		showArchiveList,
		listOkrResult,
		enqueueSnackbar,
		handlePraise,
		krObj,
		setOpenKrId,
		isScoreLocked,
		handleCfrOpen,
		parentOkr,
		type,
		newKr = false,
		isTeamView,
		isEditable = true,
		classNames = '',
		setErrorOpen,
		errorOpen,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [openProgressDetail, setOpenProgressDetail] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const history = useHistory();
	const [openPopperMenu, setOpenPopperMenu] = useState(false);
	const [clickedUser, setClickedUser] = useState<any>(null);
	const [anchorElPopperMenu, setAnchorElPopperMenu] = useState(null);
	const [progressValuePercentage, setProgressValuePercentage] = useState<any>(kr?.myScore);
	const [popperError, setPopperError] = useState(false);

	useEffect(() => {
		dispatch(dragDisabled(openProgressDetail ? (kr.id ? kr.id : kr.goalKeyId) : 0));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openProgressDetail]);
	const handleToggle = (e: any) => {
		if (!errorOpen) {
			setAnchorEl(anchorEl ? null : e.currentTarget);
			setOpenProgressDetail((previousOpen: any) => !previousOpen);
		}
	};
	const handleClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (!isProgressUpdated) {
			setAnchorEl(null);
			setOpenProgressDetail(false);
			setPopperError(false);
			setIsProgressUpdated(false);
		} else {
			setPopperError(true);
		}
	};

	const handleMenuClick = (e: any, clickedContributor: any) => {
		setAnchorElPopperMenu(anchorElPopperMenu ? null : e.currentTarget);
		setOpenPopperMenu((previousOpen: any) => !previousOpen);
		setClickedUser(clickedContributor);
	};
	const handleMenuClose = (event: any = {}) => {
		setAnchorElPopperMenu(null);
		setOpenPopperMenu(false);
	};
	const userDetail = getUserDetails();
	const [progressPercentageValue, setProgressPercentageValue] = useState<number>();
	const [progressValue, setProgressValue] = useState<any>(kr?.myScore);
	const [isProgressUpdated, setIsProgressUpdated] = useState<boolean>(false);
	const [isReset, setIsReset] = useState<boolean>(false);
	const [scoreInNumber, setScoreInNumber] = useState<any>(0);
	let currentYear = getLocalStorageItem('currentYear');
	useEffect(() => {
		if (anchorEl !== null) {
			if (kr?.isScored) {
				setScoreInNumber(kr?.currentValue);
			} else {
				setScoreInNumber('');
			}

			let percentage: any;
			if (kr?.ownStartValue === 0 && kr?.ownTargetValue === 0) {
				percentage = getPercentageOfKr(kr?.startValue, kr?.currentValue, kr?.targetValue);
			} else {
				percentage = getPercentageOfKr(kr?.ownStartValue, kr?.currentValue, kr?.ownTargetValue);
			}
			if (loginUserAvatar.employeeId === userDetail.employeeId) {
				setProgressValuePercentage(loginUserAvatar.realScore);
				setProgressValue(loginUserAvatar.realScore);
			} else {
				let curContributor =
					contributorDetails && contributorDetails.length > 0
						? contributorDetails.filter((item: any) => item.employeeId === userDetail.employeeId)
						: [];
				if (curContributor.length > 0) {
					setProgressValuePercentage(curContributor[0].realScore);
					setProgressValue(curContributor[0].realScore);
				}
			}
			setOpenKrId(kr.goalKeyId);
			setIsProgressUpdated(false);
			setErrorOpen(false);
			setPopperError(false);
		} else {
			setOpenKrId(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anchorEl]);

	const resetProgress = () => {
		setIsReset(true);
		if (kr?.isScored) {
			setScoreInNumber(kr?.currentValue);
		} else {
			setScoreInNumber('');
		}

		let percentage: any;
		if (kr?.ownStartValue === 0 && kr?.ownTargetValue === 0) {
			percentage = getPercentageOfKr(kr?.startValue, kr?.currentValue, kr?.targetValue);
		} else {
			percentage = getPercentageOfKr(kr?.ownStartValue, kr?.currentValue, kr?.ownTargetValue);
		}
		setProgressValuePercentage(Math.floor(Number(percentage)));
		setProgressValue(Number(percentage) < 0 ? 0 : Number(percentage));
		setIsProgressUpdated(false);
		setErrorOpen(false);
	};

	const handleFieldChange = (newValue: any) => {
		let assignmentTypeId = kr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		const regexp = /^[0-9\\.b]+$/;
		if (newValue === '' || regexp.test(newValue)) {
			newValue = !Boolean(newValue) ? '' : newValue;
			// setProgressValue(newValue);
			setProgressPercentageValue(newValue);
			if (!isReset) {
				setIsProgressUpdated(true);
				setErrorOpen(true);
			} else {
				setIsReset(false);
			}
			setScoreInNumber(newValue);
			let score: any = 0;
			let percentage: any = 0;
			if (kr.ownTargetValue || kr.ownStartValue) {
				if (kr?.ownStartValue === 0 && kr?.ownTargetValue === 0) {
					score = getPercentageOfKr(kr?.startValue, newValue, kr?.targetValue);
					percentage = getPercentageOfKr(kr?.startValue, newValue, kr?.targetValue);
				} else {
					score = getPercentageOfKr(kr.ownStartValue, newValue, kr.ownTargetValue);
					percentage = getPercentageOfKr(kr.ownStartValue, newValue, kr.ownTargetValue);
				}
				setProgressValuePercentage(Math.floor(Number(percentage)));
				setProgressValue(score < 0 ? 0 : score);
				setPopperError(false);
			}
		}
	};

	const handleSliderChange = (event: any, newValue: number | number[]) => {
		let assignmentTypeId = kr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		let score: any = getValueFromPercentage(kr.ownTargetValue, newValue, kr.ownStartValue, kr.currentValue);
		let percentage: any = getPercentageOfKr(kr.ownStartValue, score, kr.ownTargetValue);
		if (Number(score) === 0) {
			setScoreInNumber(0);
		} else {
			setScoreInNumber(score);
		}
		setProgressPercentageValue(percentage);
		setProgressValuePercentage(Math.floor(Number(percentage)));
		setIsProgressUpdated(true);
		setErrorOpen(true);
	};

	const saveProgress = () => {
		let assignmentTypeId = kr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		if (parseFloat(kr.currentValue) !== parseFloat(scoreInNumber) || !kr?.isScored) {
			setAnchorEl(null);
			setOpenProgressDetail(false);
			setIsProgressUpdated(false);
			setErrorOpen(false);
			updateProgress(kr.goalKeyId || kr.krId, assignmentTypeId, scoreInNumber || 0, currentYear, parentOkr);
		}
	};
	const handleProgressCfr = async (e: any) => {
		await handleMenuClose();
		handleCfrOpen(e, 2, kr, parentOkr, 'conversation', clickedUser);
	};
	const handleViewOkr = async () => {
		await handleMenuClose();
		setLocalStorageItem('currentUser', JSON.stringify(clickedUser));
		history.push(USER_VIEW);
		props.updateRoute(USER_VIEW);
		let krId = clickedUser.goalId;
		setLocalStorageItem('highlightGoal', krId);
		setLocalStorageItem('highlightGoalKey', krId);
		setLocalStorageItem('goalType', krId);
	};
	const editAllowed = () => {
		return !isScoreLocked && !currentUser && !props.isTeamView && isEditable; // && !isTeamView;
	};
	const handlePopperScroll = (event: any, elementId: any) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
			}
		}, 100);
	};

	return (
		<Box className={`okr-list-progressbar ${classNames ? classNames : ''}`} style={{ width: '100%' }}>
			<div
				className={`okr-list-progressbar-inner ${openProgressDetail ? 'progressbar-active' : ''}`}
				onClick={(e: any) => {
					handleToggle(e);
					handlePopperScroll(e, `kr-popper-progress-detail-${kr.goalKeyId || kr.krId}`);
				}}
				id={`okr-progress-${kr.goalKeyId || kr.krId}`}
			>
				<Box
					className={`okr-list-progress-head ${
						contributorDetails && contributorDetails.length > 0 ? '' : 'no-contributor'
					}`}
				>
					<Box className='contributor-avatar-section'>
						<Badge
							color='primary'
							badgeContent={
								contributorDetails && contributorDetails.length > 0 ? (
									<Typography component='span'>+{contributorDetails && contributorDetails.length}</Typography>
								) : (
									<></>
								)
							}
							invisible={contributorDetails && contributorDetails.length > 0 ? false : true}
						>
							<AvatarUser userDetail={loginUserAvatar} className='contributor-owner' />
						</Badge>
					</Box>
					<Box className='progress-bar-section'>
						<Box className='progress-slider'>
							<OkrProgressBar
								{...props}
								parentOkr={okr}
								okr={{ ...kr, score: kr.overallScorePercentage }}
								type={'kr'}
								t={t}
								updateProgress={updateProgress}
								showEditLoader={showEditLoader}
								currentUser={currentUser}
								okrMasterData={okrMasterData}
								teamsOkr={props.teamsOkr}
								showArchiveList={showArchiveList}
								isScoreLocked={isScoreLocked}
								enqueueSnackbar={enqueueSnackbar}
								isAnyDrawerOpened={props.isAnyDrawerOpened}
								appDrawerInfo={props.appDrawerInfo}
								drawerOpen={openProgressDetail}
								newKr={newKr}
							/>
						</Box>
						{/* <Box className='kr-progress-bottom'>
							{openProgressDetail ? <></> : <Typography variant='h6'>3 days ago</Typography>}
							{drawerOpen ? <Typography variant='h6'>$ 5,000,000</Typography> : <></>}
						</Box> */}
					</Box>
					<Box className='progress-accordion-arrow'>
						<OKRButton className='accordion-icon' icon={<LeftArrow />} />
					</Box>
				</Box>
			</div>
			{openProgressDetail && (
				<Popper
					open={openProgressDetail}
					anchorEl={anchorEl}
					placement='bottom'
					transition
					className={`popper-okr-progress-bar ${
						parentOkr.goalStatusId === Enums.GOAL_STATUS_DRAFT || showArchiveList || props.newKr
							? 'popper-progress-new-kr'
							: ''
					}`}
					//className={`popper-okr-progress-bar popper-progress-bar popper-progress-bar-bottom`}
					modifiers={{
						preventOverflow: {
							enabled: false,
							boundariesElement: window,
						},

						flip: {
							enabled: false, // true by default
						},
					}}
					id={`kr-popper-progress-detail-${kr.goalKeyId || kr.krId}`}
					container={anchorEl}
				>
					{({ TransitionProps }) => (
						<Fade {...TransitionProps}>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<Box>
										{!kr.isGroupTarget ? (
											<Box className={`progress-contributor-list`}>
												{currentUser || (!props.isTeamView && loginUserAvatar.employeeId !== userDetail.employeeId) ? (
													<Box className='assign-align-user-info'>
														<Chip
															avatar={<AvatarUser userDetail={loginUserAvatar} className='contributor-owner' />}
															label={
																<>
																	<Tooltip title={getFullName(loginUserAvatar)} arrow>
																		<span>{getFullName(loginUserAvatar)}</span>
																	</Tooltip>
																</>
															}
															className='chip-avatar'
														/>
														<Typography className='parent-chip'>{t('parentLabel')}</Typography>
													</Box>
												) : !currentUser || props.isTeamView || loginUserAvatar.employeeId === userDetail.employeeId ? (
													<Box
														className={`progress-contributor-row ${
															!currentUser && !props.isTeamView && isEditable
																? 'progress-parent-row'
																: 'contributor-list-info contributor-list-info-first'
														} ${loginUserAvatar.employeeId !== userDetail.employeeId ? 'parent-row-space' : ''}`}
													>
														<Box className='progress-contributor-left'>
															{/* add deleted-user class for deleted user */}
															<Box
																className={`${
																	loginUserAvatar.isActive === false
																		? 'progress-contributor-info deleted-user'
																		: 'progress-contributor-info'
																}`}
															>
																<Box className='user-info'>
																	<Box className='user-img'>
																		<AvatarUser userDetail={loginUserAvatar} className='contributor-owner' />
																		<Tooltip
																			classes={{
																				popper: `${loginUserAvatar.confidenceId &&
																					props.okrMasterData.confidenceMapping[loginUserAvatar.confidenceId].name ===
																						'Low' && 'tooltip-layout4'
																				}`,
																			}}
																			title={
																				<>
																					<Typography>
																						{props.okrMasterData.confidenceMapping && loginUserAvatar.confidenceId ?
																							props.okrMasterData.confidenceMapping[loginUserAvatar.confidenceId].name : 'Not Set'}
																					</Typography>
																					{
																						(loginUserAvatar.confidenceId !== 0 && loginUserAvatar.confidenceId !== 4) && loginUserAvatar.confidenceId && <em>
																							{props.okrMasterData.confidenceMapping &&
																								props.okrMasterData.confidenceMapping[loginUserAvatar.confidenceId]
																									.descrip}
																						</em>
																					}
																				</>
																			}
																			arrow
																		>
																			<Box className='confidence-icon'>
																				{confidenceIcon(loginUserAvatar.confidenceId)}
																			</Box>
																		</Tooltip>
																	</Box>
																	<Box className='user-info-details'>
																		<Tooltip title={getFullName(loginUserAvatar)} arrow>
																			<Typography variant='body1' className='user-name'>
																				{getFullName(loginUserAvatar)}
																			</Typography>
																		</Tooltip>
																		<Typography className='parent-chip'>{t('parentLabel')}</Typography>
																		{loginUserAvatar.isActive === false && (
																			<Chip className='delete-chip' label={t('Deleted')} />
																		)}
																	</Box>
																</Box>
															</Box>
															{/* add deleted-user class for deleted user */}
															<Box
																className={`${
																	loginUserAvatar.isActive === false
																		? 'progress-contributor-progress deleted-user'
																		: 'progress-contributor-progress'
																}`}
															>
																<Box className='progress-contributor-progress-inner'>
																	<Box className='popup-progress-box'>
																		<Box className='popup-progress-track'>
																			{!(!loginUserAvatar?.ownTargetValue && !loginUserAvatar?.ownStartValue) ? (
																				<Box className='popup-progress-value'>
																					<Typography variant='h6'>
																						{getTargetValueFormated(
																							loginUserAvatar?.ownStartValue,
																							kr?.metricId,
																							kr?.currencyId,
																							props.okrMasterData
																						)}
																					</Typography>
																					<Typography variant='h6'>
																						{getTargetValueFormated(
																							loginUserAvatar?.ownTargetValue,
																							kr?.metricId,
																							kr?.currencyId,
																							props.okrMasterData
																						)}
																					</Typography>
																				</Box>
																			) : (
																				<Box className='popup-progress-value'>
																					<Typography variant='h6'>Target not set</Typography>
																				</Box>
																			)}
																			<Box className='popup-progress-rail'>
																				<Slider
																					aria-label={t('MyGoalProgressSlider')}
																					//style={{ color }}
																					value={!currentUser ? progressValue : loginUserAvatar.realScore}
																					disabled={kr?.ownTargetValue === 0}
																					min={0}
																					max={100}
																					className={'show-blue-slider'}
																					onChange={(e, val) => {
																						if (editAllowed()) {
																							setProgressValue(val);
																							handleSliderChange(e, val);
																						}
																					}}
																				/>
																			</Box>
																		</Box>
																		{!(!loginUserAvatar?.ownTargetValue && !loginUserAvatar?.ownStartValue) ? (
																			<Typography variant='h6'>
																				{!isEditable
																					? getPercentageUpperValue(loginUserAvatar.realScore)
																					: editAllowed()
																					? getPercentageUpperValue(progressValuePercentage)
																					: getPercentageUpperValue(loginUserAvatar.realScore)}
																				{'%'}
																			</Typography>
																		) : (
																			<Typography variant='h6'>--</Typography>
																		)}
																	</Box>
																	<Box className='progress-input-area'>
																		{!loginUserAvatar?.ownTargetValue && !loginUserAvatar?.ownStartValue ? (
																			<Box className='popup-progress-value-bottom'>
																				<Typography variant='h6'></Typography>
																			</Box>
																		) : editAllowed() ? (
																			<Box
																				className={`progress-input-box ${isProgressUpdated ? 'action-btn-show' : ''}`}
																			>
																				<TextField
																					placeholder={kr.metricId === 2 ? '100' : '100'}
																					className={`${popperError ? 'progress-input-unsaved' : ''}`}
																					value={scoreInNumber}
																					inputProps={{
																						maxLength: Enums.MAX_LENGTH_CURRENT_TARGET,
																						inputType: 'numberFieldProgress',
																					}}
																					onChange={(e: any) => {
																						handleFieldChange(e.target.value);
																					}}
																					name='percentage'
																					InputProps={{
																						inputComponent: NumberFormatComponent,
																						startAdornment: (
																							<InputAdornment position='start'>
																								{kr.metricId === 1
																									? '%'
																									: kr.metricId === 2
																									? getCurrencyIcon(
																											getCurrencyName(kr?.metricId, kr?.currencyId, props.okrMasterData)
																									  )
																									: '#'}
																							</InputAdornment>
																						),
																					}}
																				/>
																				{isProgressUpdated && (
																					<Box className='textfield-action-btn'>
																						<OKRButton
																							className='cancel-btn'
																							id={'cancel-btn'}
																							icon={<CancelIcon />}
																							handleClick={() => resetProgress()}
																						/>
																						<OKRButton
																							className='save-btn'
																							id={'save-btn'}
																							icon={<TickIcon />}
																							disabled={!isProgressUpdated}
																							handleClick={(e: any) => {
																								saveProgress();
																							}}
																						/>
																					</Box>
																				)}
																			</Box>
																		) : (
																			<Box className='popup-progress-value-bottom'>
																				{isEditable && (
																					<Typography variant='h6'>
																						{kr?.metricId !== Enums.ONE &&
																							getTargetValueFormated(
																								loginUserAvatar?.overallScore,
																								kr?.metricId,
																								kr?.currencyId,
																								props.okrMasterData
																							)}
																					</Typography>
																				)}
																			</Box>
																		)}
																		{isEditable &&
																			!(!loginUserAvatar?.ownTargetValue && !loginUserAvatar?.ownStartValue) && (
																				<Box className='progress-link-icon'></Box>
																			)}
																	</Box>
																</Box>

																{/* Target not set */}

																<Box className='progress-kebab-icon'></Box>
															</Box>
														</Box>
														<Box className='progress-contributor-right'>
															<Typography variant='h5'>
																{0 && !loginUserAvatar?.ownTargetValue && !loginUserAvatar?.ownStartValue
																	? '--'
																	: kr?.metricId === Enums.ONE
																	? '--'
																	: loginUserAvatar.actualContribution
																	? getPercentageUpperValue(loginUserAvatar.actualContribution) + '%'
																	: 0 + '%'}
															</Typography>
														</Box>
													</Box>
												) : (
													<></>
												)}
												{contributorDetails && contributorDetails.length > 0 ? (
													contributorDetails.map(
														(item: any) =>
															item && (
																<Box
																	className={`progress-contributor-row ${
																		isEditable && (currentUser || !props.isTeamView) && item.employeeId === userDetail.employeeId
																			? 'progress-parent-row'
																			: 'contributor-list-info'
																	} ${loginUserAvatar.employeeId !== userDetail.employeeId ? 'parent-row-space' : ''}`}
																>
																	{' '}
																	<Box className='progress-contributor-left'>
																		{/* add deleted-user class for deleted user */}
																		<Box
																			className={`${
																				item.isActive === false
																					? 'progress-contributor-info deleted-user'
																					: 'progress-contributor-info'
																			}`}
																		>
																			<Box className='user-info'>
																				<Box className='user-img'>
																					{(item.isAssigned || !isEditable) && (
																						<Tooltip title={t('givenByYou')} arrow>
																							<Box className='arrow-icon assign-arrow-icon'>
																								<AlignNewArrow />
																							</Box>
																						</Tooltip>
																					)}
																					{item.isAligned && (
																						<Tooltip title={t('selfAlignedLabel')} arrow>
																							<Box className='arrow-icon'>
																								<AlignNewArrow />
																							</Box>
																						</Tooltip>
																					)}
																					<AvatarUser userDetail={item} className='' />
																					<Tooltip
																						classes={{
																							popper: `${item.confidenceId &&
																								props.okrMasterData.confidenceMapping[item.confidenceId].name ===
																									'Low' && 'tooltip-layout4'
																							}`,
																						}}
																						title={
																							<>
																								<Typography>
																									{props.okrMasterData.confidenceMapping && item.confidenceId ?
																										props.okrMasterData.confidenceMapping[item.confidenceId]?.name : 'Not Set'}
																								</Typography>
																								{
																									(item.confidenceId !== 0 && item.confidenceId !== 4) && item.confidenceId && <em>
																										{props.okrMasterData.confidenceMapping &&
																											props.okrMasterData.confidenceMapping[item.confidenceId].descrip}
																									</em>
																								}
																							</>
																						}
																						arrow
																					>
																						<Box className='confidence-icon'>{confidenceIcon(item.confidenceId)}</Box>
																					</Tooltip>
																				</Box>
																				<Box className='user-info-details'>
																					<Tooltip title={getFullName(item)} arrow>
																						<Typography variant='body1' className='user-name'>
																							{getFullName(item)}
																						</Typography>
																					</Tooltip>
																					{item.employeeId === loginUserAvatar.employeeId && (
																						<Typography className='parent-chip'>{t('parentLabel')}</Typography>
																					)}
																					{item.isActive === false && (
																						<Chip className='delete-chip' label={t('Deleted')} />
																					)}

																					<Box className='kr-clap-icon'>
																						{userDetail.employeeId !== item.employeeId &&
																							item?.praiseType !== Enums.PRAISE_HIDE && (
																								<>
																									{item?.praiseType === Enums.PRAISE_SEND ? (
																										<Tooltip
																											arrow
																											title={
																												<>
																													{t('sendPraiseTooltip')} <br />{' '}
																													{`"${item?.firstName} ${item?.lastName}"`}
																												</>
																											}
																										>
																											<IconButton onClick={() => handlePraise(item, krObj)}>
																												<ClapIcon />
																											</IconButton>
																										</Tooltip>
																									) : item?.praiseType === Enums.PRAISE_SENT ? (
																										<Tooltip
																											arrow
																											title={
																												<>
																													{t('praiseSentTooltip')} <br />{' '}
																													{`"${item?.firstName} ${item?.lastName}"`}
																												</>
																											}
																										>
																											<IconButton>
																												<AnimatedIcon
																													className=''
																													width='26'
																													type='image/svg+xml'
																													alt={t('globalSearchNoRecord')}
																													imagePath={clapAnim}
																												/>
																												{/* <ClapAnimationIcon /> */}
																											</IconButton>
																										</Tooltip>
																									) : (
																										<></>
																									)}
																								</>
																							)}
																					</Box>
																				</Box>
																			</Box>
																		</Box>
																		{/* add deleted-user class for deleted user */}
																		<Box
																			className={`${
																				item.isActive === false
																					? 'progress-contributor-progress deleted-user'
																					: 'progress-contributor-progress'
																			}`}
																		>
																			<Box className='progress-contributor-progress-inner'>
																				<Box className='popup-progress-box'>
																					<Box className='popup-progress-track'>
																						{!(!item?.startValue && !item?.targetValue) ? (
																							<Box className='popup-progress-value'>
																								<Typography variant='h6'>
																									{getTargetValueFormated(
																										item?.startValue,
																										kr?.metricId,
																										kr?.currencyId,
																										props.okrMasterData
																									)}
																								</Typography>
																								<Typography variant='h6'>
																									{getTargetValueFormated(
																										item?.targetValue,
																										kr?.metricId,
																										kr?.currencyId,
																										props.okrMasterData
																									)}
																								</Typography>
																							</Box>
																						) : (
																							<Box className='popup-progress-value'>
																								<Typography variant='h6'>Target not set</Typography>
																							</Box>
																						)}
																						<Box className='popup-progress-rail'>
																							<Slider
																								aria-label={t('MyGoalProgressSlider')}
																								//style={{ color }}
																								value={
																									item.employeeId === userDetail.employeeId && editAllowed()
																										? progressValue
																										: item.realScore
																								}
																								disabled={item?.ownTargetValue === 0}
																								min={0}
																								max={100}
																								className={'show-blue-slider'}
																								onChange={(e, val) => {
																									if (item.employeeId === userDetail.employeeId && editAllowed()) {
																										setProgressValue(val);
																										handleSliderChange(e, val);
																									}
																								}}
																							/>
																						</Box>
																					</Box>
																					{!(!item?.ownTargetValue && !item?.ownStartValue && !item?.targetValue) ? (
																						<Typography variant='h6'>
																							{!isEditable
																								? getPercentageUpperValue(item.realScore)
																								: item.employeeId === userDetail.employeeId && editAllowed()
																								? getPercentageUpperValue(progressValuePercentage)
																								: getPercentageUpperValue(item.realScore)}
																							{'%'}
																						</Typography>
																					) : (
																						<Typography variant='h6'>--</Typography>
																					)}
																				</Box>
																				<Box className='progress-input-area'>
																					{!item?.ownTargetValue &&
																					!item?.ownStartValue &&
																					item.employeeId === userDetail.employeeId ? (
																						<Box className='popup-progress-value-bottom'>
																							<Typography variant='h6'></Typography>
																						</Box>
																					) : item.employeeId === userDetail.employeeId && editAllowed() ? (
																						<Box
																							className={`progress-input-box  ${
																								isProgressUpdated ? 'action-btn-show' : ''
																							}`}
																						>
																							<TextField
																								placeholder={kr.metricId === 2 ? '100' : '100'}
																								className={`${popperError ? 'progress-input-unsaved' : ''}`}
																								value={scoreInNumber}
																								inputProps={{
																									maxLength: Enums.MAX_LENGTH_CURRENT_TARGET,
																									inputType: 'numberFieldProgress',
																								}}
																								onChange={(e: any) => {
																									handleFieldChange(e.target.value);
																								}}
																								name='percentage'
																								InputProps={{
																									inputComponent: NumberFormatComponent,
																									startAdornment: (
																										<InputAdornment position='start'>
																											{kr.metricId === 1
																												? '%'
																												: kr.metricId === 2
																												? getCurrencyIcon(
																														getCurrencyName(
																															kr?.metricId,
																															kr?.currencyId,
																															props.okrMasterData
																														)
																												  )
																												: '#'}
																										</InputAdornment>
																									),
																								}}
																							/>
																							{isProgressUpdated && (
																								<Box className='textfield-action-btn'>
																									<OKRButton
																										className='cancel-btn'
																										id={'cancel-btn'}
																										handleClick={() => resetProgress()}
																										icon={<CancelIcon />}
																									/>
																									<OKRButton
																										className='save-btn'
																										id={'save-btn'}
																										icon={<TickIcon />}
																										disabled={!isProgressUpdated}
																										handleClick={(e: any) => {
																											saveProgress();
																										}}
																									/>
																								</Box>
																							)}
																						</Box>
																					) : (
																						<Box className='popup-progress-value-bottom'>
																							{isEditable && kr?.metricId !== Enums.ONE && (
																								<Typography variant='h6'>
																									{getTargetValueFormated(
																										item?.overallScore,
																										kr?.metricId,
																										kr?.currencyId,
																										props.okrMasterData
																									)}
																								</Typography>
																							)}
																						</Box>
																					)}
																					{isEditable &&
																						!(!item?.ownTargetValue && !item?.ownStartValue) &&
																						kr?.metricId !== Enums.ONE && <Box className='progress-link-icon'></Box>}
																				</Box>
																			</Box>
																			<Box className='progress-kebab-icon'>
																				{item.employeeId !== userDetail.employeeId && isEditable && (
																					<OKRButton
																						className={'more-btn'}
																						title={t('moreActionsLevel')}
																						icon={<KebabIcon />}
																						handleClick={(e: any) => handleMenuClick(e, item)}
																						tooltipPlacement={'top'}
																					/>
																				)}
																			</Box>
																		</Box>
																	</Box>
																	<Box className='progress-contributor-right'>
																		<Typography variant='h5'>
																			{0 &&
																			!item?.ownTargetValue &&
																			!item?.ownStartValue &&
																			item.employeeId === userDetail.employeeId
																				? '--'
																				: kr?.metricId === Enums.ONE
																				? '--'
																				: item.actualContribution
																				? getPercentageUpperValue(item.actualContribution) + '%'
																				: 0 + '%'}
																		</Typography>
																	</Box>
																</Box>
															)
													)
												) : (
													<></>
												)}
											</Box>
										) : (
											<OKRProgressGroupTarget
												{...props}
												loginUserAvatar={loginUserAvatar}
												contributorDetails={contributorDetails}
												okr={okr}
												kr={kr}
												handleMenuClick={handleMenuClick}
												setAnchorEl={setAnchorEl}
												anchorEl={anchorEl}
												openProgressDetail={openProgressDetail}
												setOpenProgressDetail={setOpenProgressDetail}
												isProgressUpdated={isProgressUpdated}
												setIsProgressUpdated={setIsProgressUpdated}
												popperError={popperError}
												setPopperError={setPopperError}
												isEditable={isEditable}
												setErrorOpen={setErrorOpen}
											/>
										)}
									</Box>
								</ClickAwayListener>
							</Paper>
						</Fade>
					)}
				</Popper>
			)}

			<Popper
				open={openPopperMenu}
				anchorEl={anchorElPopperMenu}
				placement='bottom-end'
				transition
				className={`popper-menu-list progress-popper-menu`}
				id={`popper-menu`}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleMenuClose}>
								<List>
									<ListItem id='conversation-icon' onClick={(e: any) => handleProgressCfr(e)}>
										<ConversationIcon />
										<ListItemText primary={t('conversationLabel')} />
									</ListItem>
									<ListItem id='viewOkr-icon' onClick={() => handleViewOkr()}>
										<CreateOKRIcon />
										<ListItemText primary={t('viewOkr')} />
									</ListItem>
								</List>
							</ClickAwayListener>
						</Paper>
					</Fade>
				)}
			</Popper>
		</Box>
	);
};
