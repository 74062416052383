import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '../../../config/svg/formElementIcons';

export const DatePickerField: React.FC<any> = (props) => {
	const { label, onDateChange, selectedDate, disabled } = props;
	const { t } = useTranslation();

	const TextFieldComponent = (props: any) => {
		return (
			<Fragment>
				<TextField {...props} />
			</Fragment>
		);
	};

	return (
		<>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar={true}
					autoOk
					disabled={disabled}
					fullWidth
					// minDate={moment().subtract(3, 'years')}
					variant='inline'
					format={'MMM , yyyy'}
					id={'startFromDatePicker'}
					key={'datepicker'}
					label={
						<>
							<CalendarIcon />
							{label}
						</>
					}
					value={selectedDate}
					onChange={onDateChange}
					keyboardIcon={<CalendarIcon />}
					KeyboardButtonProps={{
						'aria-label': t('CommonChangeDate'),
					}}
					TextFieldComponent={TextFieldComponent}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					name={'datepicker'}
					views={['year', 'month']}
					className='datepicker-text-field'
					PopoverProps={{
						PaperProps: {
							className: 'datepicker-popover',
						},
					}}
				/>
			</MuiPickersUtilsProvider>
		</>
	);
};
