import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Highcharts from 'highcharts';
import borderRadius from 'highcharts-border-radius';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@material-ui/core';

borderRadius(Highcharts);

export const ReportsColumnChart = (props) => {
	const { progress, engagementDataOverall, labels, colors, titleText, xAxisText, yAxisText, chartType } = props;
	const { t } = useTranslation();
	const columnWithGroupedChartData = {
		chart: {
			type: 'column',
			height: chartType && chartType === 'Assessment' ? '270' : '320',
			backgroundColor: chartType && chartType === 'Assessment' ? 'transparent' : '#ffffff',
		},
		title: {
			align: 'left',
			text: titleText || null,
		},
		subtitle: {
			align: 'left',
			text: '',
		},
		credits: {
			enabled: false,
		},
		accessibility: {
			announceNewData: {
				enabled: true,
			},
		},
		xAxis: {
			type: xAxisText || null,
			labels: {
				useHTML: true,
				align: 'center',
				formatter: (detail) => {
					return '';
				},
			},
			tickWidth: 0,
		},
		yAxis: {
			title: {
				text: yAxisText || null,
				style: {
					color: '#416390',
					fontSize: '10px',
					fontWeight: '700',
					fontFamily: 'Lato',
					textTransform: 'uppercase',
				},
			},
			labels: {
				useHTML: true,
				// format: '{value}',
				style: {
					color: '#416390',
					fontSize: '12px',
					fontWeight: '700',
					fontFamily: 'Lato',
					textTransform: 'uppercase',
				},
			},
			min: 0,
			max: chartType && chartType === 'Assessment' ? 100 : progress < 50 ? progress + 50 : progress + 100,
			minTickInterval: chartType && chartType === 'Assessment' ? 20 : Math.round(progress / 3),
			tickInterval: chartType && chartType === 'Assessment' ? 20 : Math.round(progress / 3),
		},
		legend: {
			enabled: true,
			itemDistance: 14,
			padding: 2,
			margin: 0,
			align: chartType && chartType === 'Assessment' ? 'right' : 'center',
			itemMarginTop: chartType && chartType === 'Assessment' ? 30 : 65,
			itemMarginBottom: 0,
			symbolHeight: 8,
			symbolWidth: 8,
			symbolRadius: 6,
			itemStyle: {
				color: '#292929',
				fontWeight: 'bold',
				fontFamily: 'Lato',
				fontSize: '12px',
			},
		},
		plotOptions: {
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					format: '{point.y}',
					style: {
						color: '#292929',
						fontSize: '10px',
						fontWeight: '700',
						fontFamily: 'Lato',
					},
				},
				groupPadding: 0,
				pointWidth: 40,
			},
			column: {
				borderRadiusTopLeft: 6,
				borderRadiusTopRight: 6,
			},
		},

		tooltip: {
			borderWidth: 0,
			shadow: false,
			useHTML: true,
			backgroundColor: '#292929',
			borderColor: 'none',
			padding: 0,
			style: {
				color: '#fff',
				fontWeight: 'normal',
				fontFamily: 'Lato',
				fontSize: '12px',
				zIndex: '1',
			},
			headerFormat: '', //'<span style="font-size:11px">Details</span><br>',
			pointFormat: `<div style='position:relative;padding-left:15px'><span style="font-size:20px;color:{point.color};position:absolute;top:-7px;left:0px;">\u25CF</span> 
							<span >{point.xLabel}</span>:&nbsp<b>{point.y}</b><br/></div>`,
		},

		series: [],
		// 	{
		// 		name: labels[0],
		// 		// colorByPoint: true,
		// 		color: colors[0],
		// 		className: 'round-on-top',
		// 		data: [
		// 			{
		// 				name: labels[0],
		// 				y: 50,
		// 				drilldown: null,
		// 			},
		// 		],
		// 	},
		// 	{
		// 		name: labels[1],
		// 		// colorByPoint: true,
		// 		color: colors[1],
		// 		className: 'round-on-top',
		// 		data: [
		// 			{
		// 				name: labels[1],
		// 				y: 65,
		// 			},
		// 		],
		// 	},
		// 	{
		// 		name: labels[2],
		// 		// colorByPoint: true,
		// 		color: colors[2],
		// 		className: 'round-on-top',
		// 		data: [
		// 			{
		// 				name: labels[2],
		// 				y: 90,
		// 			},
		// 		],
		// 	},
		// ],
	};
	const [chartDetails, setChartDetails] = useState(null);

	useEffect(() => {
		if (engagementDataOverall && engagementDataOverall.length) {
			const updatedSeries = [];
			engagementDataOverall.forEach((item, index) => {
				const { color, month, sales } = item;
				updatedSeries.push({
					name: labels[index],
					color: color || colors[index],
					// className: 'round-on-top',
					data: [
						{
							name: labels[index],
							xLabel: month,
							y: sales ? parseInt(sales) : sales,
						},
					],
				});
			});
			columnWithGroupedChartData.series = updatedSeries;
			setChartDetails(columnWithGroupedChartData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [engagementDataOverall]);

	return (
		<>
			{chartDetails ? (
				<HighchartsReact highcharts={Highcharts} options={chartDetails} />
			) : (
				<Box className='org-loading'>
					<Box textAlign='center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				</Box>
			)}
		</>
	);
};
