import React, { useState } from 'react';
import { Box, ClickAwayListener, Grow, Popper, Typography } from '@material-ui/core';
import { CancelIcon } from '../../config/svg/Action';
import { OKRButton } from '../Common/OKRButton';
import { WavingHandIcon } from '../../config/svg/CommonSvg';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import AlertDialog from '../Common/Dialog';
import { useTranslation } from 'react-i18next';

export const CallOutTooltip: React.FC<any> = (props) => {
	const { open, iconForText, handlePrev, handleNext, showPrevious = false, showNext = false, showDone = false } = props;
	const { t } = useTranslation();
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '' });

	const handleCloseAlert = () => {
		setModalProps({
			open: true,
			type: 'callout',
			message: t('guideMeAlertText'),
		});
	};
	const handleClose = () => {
		props.setAnchorEl(null);
		props.setCallOut({ open: false, text: '' });
	};
	const handleCloseModal = (event: any, type: Number) => {
		if (type === 1) {
			handleClose();
			removeLocalStorageItem('showCallouts');
		}
		setModalProps({
			open: false,
			type: '',
			message: '',
		});
	};
	const clickAwayhandleClose = () => {
		if (!getLocalStorageItem('showCallouts')) {
			props.setAnchorEl(null);
			props.setCallOut({ open: false, text: '' });
		}
	};

	const modifiers = iconForText
		? {
				applyStyle: {
					enabled: true,
					gpuAcceleration: false,
				},
		  }
		: {};
	return (
		<>
			<ClickAwayListener onClickAway={clickAwayhandleClose}>
				<Popper
					className={iconForText ? props.classname : `callout-tooltip  ${props.classname}`}
					open={open}
					anchorEl={props.anchorEl}
					placement={props.placement ? props.placement : 'bottom-start'}
					modifiers={modifiers}
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<>
								<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={handleCloseAlert} />
								{props.tooltipIcon && (
									<Typography variant='body1' className='tooltip-icon'>
										{props.tooltipIcon}
									</Typography>
								)}
								<Typography variant='body1'>
									{iconForText && <WavingHandIcon />}
									<span dangerouslySetInnerHTML={{ __html: props.text }}></span>
								</Typography>
								<Box
									className={`callout-btn ${
										(showNext && showPrevious) || (showDone && showPrevious)
											? ' '
											: showNext || showDone
											? 'callout-btn-onlysingle'
											: ' '
									}`}
								>
									{showPrevious && <OKRButton className='prev-btn' text={t('prevText')} handleClick={handlePrev} />}
									{showNext && <OKRButton className='next-btn' text={t('NextText')} handleClick={handleNext} />}
									{showDone && (
										<OKRButton
											className='done-btn'
											text={t('gotItText')}
											handleClick={() => {
												handleClose();
												setLocalStorageItem(props.activeOnboardingPanel + 'calloutDone', true);
											}}
										/>
									)}
								</Box>
							</>
						</Grow>
					)}
				</Popper>
			</ClickAwayListener>
			{modalProps.open && (
				<AlertDialog
					module={'calloutOnboarding'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
