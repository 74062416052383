import React, { useEffect, useState } from 'react';
import * as Moment from 'moment';
import {
	Avatar,
	Badge,
	Box,
	Checkbox,
	Chip,
	Collapse,
	List,
	ListItem,
	ListItemText,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { CommentIcon, LikeFilledIcon, LikeIcon } from '../../config/svg/CommonSvg';
import {
	formatFeedbackDate,
	getRandomBackgroundColor,
	getRandomColor,
	getTime,
	getUserDetails,
	getUserName,
} from '../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import { extendMoment } from 'moment-range';
import { getLikesList, getRecognizationPostListing, likeRecognization, saveCommentsPost } from '../../action/recognize';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { PopperMenu } from '../Admin/CommonComponent/PopperMenu';
import { useSnackbar } from 'notistack';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import LikeImg from '../../images/Like.svg';

import teamFilledIcon from '../../images/team-filled-icon.svg';
import { CancelIcon } from '../../config/svg/Action';
import { TickIcon } from '../../config/svg/CommonSvgIcon';
import { Enums } from '../../config/enums';
import { showLoader } from '../../action/common';
import { DialogComponent } from '../Admin/CommonComponent/DialogComponent';
import noRecognitionPost from '../../images/no-recognition-post.svg';

export const PraiseWallWidget: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const { handleCardClick } = props;
	const [likeData, setLikeData] = useState<any[]>([]);
	const [likedId, setLikedId] = useState<number>(0);
	const dispatch = useDispatch();
	const currentDate = new Date();
	const startDateValue = new Date();
	startDateValue.setMonth(startDateValue.getMonth() - 3);
	const userDetail = getUserDetails();
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const [recognizeListData, setRecognizeListData] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [commentRecognizationId, setCommentRecognizationId] = useState<number>(0);
	const [postText, setPostText] = React.useState<any>('');
	const [saveClicked, setSaveClicked] = React.useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<any>({ open: false, message: '' });
	const loggedInUserDetail = getUserDetails();

	const { enqueueSnackbar } = useSnackbar();
	const [openOthersList, setOpenOthersList] = useState<boolean>(false);
	const [headLineListId, setHeadLineListId] = useState<number>(0);
	const [likeAnimation, setLikeAnimation] = useState<number>(0);

	const { recognizePostResponse } = useSelector((state: any) => state.recognizeReducer);
	useEffect(() => {
		getAllOrgRecognize();
	}, []);

	useEffect(() => {
		setRecognizeListData(recognizePostResponse);
	}, [recognizePostResponse]);

	const getAllOrgRecognize = () => {
		const startDate = selectedDateRange?.start?._d || startDateValue; //TODO:: fix this data
		const endDate = selectedDateRange?.end?._d || new Date();
		dispatch(
			getRecognizationPostListing({
				id: 0,
				recognitionId: 0,
				startDate: formatFeedbackDate(startDate),
				endDate: formatFeedbackDate(endDate),
				isMyPost: false,
				pageIndex: 1,
				pageSize: 5,
				searchType: 0,
			})
		);
	};
	const handleOpen = async (event: any, recognizeData: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
		setLikedId(recognizeData.recognitionId);
		let data = `moduleDetailsId=${recognizeData.recognitionId}&moduleId=2`;
		const response: any = await dispatch(getLikesList(data));
		if (Boolean(response) && response.data.status === 200) {
			setLikeData(response.data.entity.recognitionLikeResponses);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
		setLikeData([]);
		setLikedId(0);
	};

	const handleOthersListOpen = (event: any, recognizeData: any) => {
		setAnchorEl(event.currentTarget);
		setOpenOthersList(true);
		setHeadLineListId(recognizeData.recognitionId);
	};

	const handleOthersListClose = () => {
		setOpenOthersList(false);
		setAnchorEl(null);
		setHeadLineListId(0);
	};

	const likeRecognizationList = async (item: any, like: any) => {
		setLikeAnimation(item.recognitionId);
		const response: any = await dispatch(
			likeRecognization({
				moduleDetailsId: item.recognitionId,
				moduleId: 2,
				employeeId: userDetail.employeeId,
				isActive: like,
			})
		);

		if (Boolean(response) && response.data.status === 200) {
			let counter = like ? 1 : -1;
			let recognizeListDataCopy = recognizeListData.map((listData: any) => listData);
			const index = recognizeListDataCopy.findIndex((listData: any) => item.recognitionId === listData.recognitionId);
			if (index > -1) {
				recognizeListDataCopy[index].isLiked = like;
				recognizeListDataCopy[index].totalLikeCount += counter;
			}
			setRecognizeListData(recognizeListDataCopy);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const getComments = async (data: any) => {
		setPostText('');
		if (commentRecognizationId !== 0 && commentRecognizationId === data.recognitionId) {
			setCommentRecognizationId(0);
		} else {
			setCommentRecognizationId(data.recognitionId);
		}
	};

	const saveComment = async (item: any) => {
		if (postText) {
			setSaveClicked(true);
			let data: any = {
				commentDetailsId: 0,
				comments: postText,
				moduleDetailsId: item.recognitionId,
				recognitionImageRequests: [],
				recognitionEmployeeTags: [],
				moduleId: 1,
			};
			let response: any = await dispatch(saveCommentsPost(data));
			if (response.data.status === Enums.STATUS_SUCCESS) {
				dispatch(showLoader(false));
				let recognizeListDataCopy = recognizeListData.map((listData: any) => listData);
				const index = recognizeListDataCopy.findIndex((listData: any) => item.recognitionId === listData.recognitionId);
				if (index > -1) {
					recognizeListDataCopy[index].totalCommentCount += 1;
				}
				setPostText('');
				enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
					variant: 'success',
					autoHideDuration: 5000,
				});
				setCommentRecognizationId(0);
				setSaveClicked(false);
			} else {
				setSaveClicked(false);
				if (response?.data?.messageList.BlockedWords) {
					setModalOpen({ open: true, message: response?.data?.messageList.BlockedWords });
				} else {
					enqueueSnackbar(response?.data?.messageList.message, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			}
		}
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();

		setModalOpen({ open: false, message: '' });
	};
	return (
		<Box className='dashboard-praise-wall-widget white-box'>
			{recognizeListData?.length > 0 ? (
				<>
					<Box className='dashboard-widget-head'>
						<Typography variant='h6'>{t('praiseWallLevel')}</Typography>
						<OKRButton
							className='btn-link-type1 '
							text={`${t('allPostsLabel')} `}
							handleClick={(e: any) => {
								handleCardClick(e, 'recognize');
							}}
						/>
					</Box>
					<Box className='praise-wall-widget-content'>
						{recognizeListData?.length > 0 &&
							recognizeListData?.slice(0, 4)?.map((recognizeData: any) => {
								return (
									<Box className='recog-posts-list-area'>
										<Box className='recog-post-list'>
											<Box className='recog-user-info'>
												<Box className='user-img'>
													{recognizeData?.receiverDetails?.length === 1 ? (
														recognizeData?.receiverDetails[0].receiverImagePath ? (
															<Avatar src={recognizeData?.receiverDetails[0].receiverImagePath} />
														) : (
															<Avatar>
																{getUserName({
																	fullName: recognizeData?.receiverDetails[0].receiverName,
																	firstName: null,
																	lastName: null,
																})}
															</Avatar>
														)
													) : (
														<Avatar className='common-avatar' src={teamFilledIcon} />
													)}
												</Box>
											</Box>
											<Box className='recog-post-box'>
												<Box className='recog-post-box-inner'>
													<Box className='recog-post-head'>
														<Box className='recog-post-head-left'>
															<Box className='recog-post-head-left-top'>
																<List className='recog-post-users' disablePadding>
																	{recognizeData.receiverDetails.map((recieverData: any, index: number) => (
																		<>
																			{index < 1 && (
																				<ListItem>
																					{recieverData?.receiverName?.length > 20 ? (
																						<Tooltip
																							arrow
																							title={recieverData?.receiverName}
																							aria-label='Reciever Name'
																						>
																							<span>{recieverData?.receiverName.substring(0, 20)}...</span>
																						</Tooltip>
																					) : (
																						recieverData?.receiverName
																					)}
																				</ListItem>
																			)}
																			{recognizeData.receiverDetails.length > 1 && ''}
																		</>
																	))}
																</List>
																{recognizeData.receiverDetails.length > 1 && (
																	<Box
																		className='others-lists'
																		aria-owns={openOthersList ? 'mouse-over-popover' : undefined}
																		aria-haspopup='true'
																		onMouseOver={(e: any) => {
																			handleOthersListOpen(e, recognizeData);
																		}}
																		onMouseLeave={handleOthersListClose}
																	>
																		<Typography>
																			{'+ '}
																			{recognizeData.receiverDetails.length - 1} others
																		</Typography>
																		{openOthersList && recognizeData.recognitionId === headLineListId && (
																			<Box>
																				<PopperMenu
																					{...props}
																					children={
																						<>
																							<Typography variant='h6' className='font-weight-normal'>
																								{t('recognizedTo')}:
																							</Typography>
																							{recognizeData.receiverDetails.map((headList: any, index: number) => {
																								return (
																									index >= 1 && (
																										<ListItem>
																											<Chip
																												className={`${
																													headList.searchType === 2 ? 'user-team-chip' : 'user-chip'
																												}`}
																												style={{
																													backgroundColor: getRandomBackgroundColor(
																														headList?.backGroundColorCode
																													),
																													color: getRandomColor(headList?.colorCode),
																												}}
																												avatar={
																													headList?.receiverImagePath ? (
																														<Avatar
																															className='avatar-default'
																															alt={headList?.receiverName}
																															src={headList?.receiverImagePath}
																														/>
																													) : (
																														<Avatar
																															style={{
																																backgroundColor: getRandomBackgroundColor(
																																	headList?.backGroundColorCode
																																),
																																color: getRandomColor(headList?.colorCode),
																															}}
																															src={headList?.receiverImagePath}
																														>
																															{getUserName({
																																firstName: '',
																																lastName: '',
																																fullName: headList?.receiverName,
																															})}
																														</Avatar>
																													)
																												}
																												label={headList?.receiverName}
																											/>
																										</ListItem>
																									)
																								);
																							})}
																						</>
																					}
																					popperClassName={'others-lists-popup'}
																					anchorEl={anchorEl}
																					handleClose={handleOthersListClose}
																					open={openOthersList}
																					popperPlacement={'bottom-start'}
																				/>
																			</Box>
																		)}
																	</Box>
																)}
															</Box>
															<Box className='recog-post-head-left-bottom'>
																<Typography variant='h6' className='font-weight-normal'>
																	{t('recognizedBy')}: &nbsp; {recognizeData.senderFullName}
																</Typography>
																<Typography variant='h6' className='font-weight-normal'>
																	{recognizeData.updatedOn
																		? getTime(recognizeData.updatedOn)
																		: getTime(recognizeData.createdOn)}{' '}
																</Typography>
															</Box>
														</Box>
													</Box>
													<Box className='recog-post-content long-text'>
														{recognizeData.attachmentImagePath && (
															<Box className='recognize-badge-box'>
																<Box className='recognize-badge-icon'>
																	<Badge
																		anchorOrigin={{
																			vertical: 'bottom',
																			horizontal: 'right',
																		}}
																		className={'badge-anchor-bottom'}
																	>
																		<img src={recognizeData.attachmentImagePath} alt={recognizeData.AttachmentName} />
																	</Badge>
																</Box>
																<Typography variant='h6'>{recognizeData.attachmentName}</Typography>{' '}
															</Box>
														)}
														<Box className='praised-content'>
															<Typography
																variant='h5'
																className='font-weight-normal'
																dangerouslySetInnerHTML={{ __html: recognizeData.message }}
															></Typography>
														</Box>
													</Box>
													<Box className='recog-post-footer'>
														<Box className='recog-post-actions-left'>
															{!recognizeData.isLiked ? (
																<OKRButton
																	className='like-btn'
																	icon={<LikeIcon />}
																	handleClick={() => {
																		likeRecognizationList(recognizeData, true);
																	}}
																/>
															) : (
																<Box className='like-filled-btn'>
																	<OKRButton
																		className={`like-btn ${
																			likeAnimation === recognizeData.recognitionId ? 'btn-anim' : ''
																		}`}
																		icon={
																			<>
																				{likeAnimation === recognizeData.recognitionId && (
																					<AnimatedIcon
																						className='like-animation-img'
																						width='60'
																						type='image/svg+xml'
																						alt='No user'
																						imagePath={LikeImg}
																					/>
																				)}
																				<LikeFilledIcon />
																			</>
																		}
																		handleClick={() => {
																			likeRecognizationList(recognizeData, false);
																			setLikeAnimation(0);
																		}}
																	/>
																</Box>
															)}

															<OKRButton
																className='comment-btn'
																icon={<CommentIcon />}
																text={t('commentLabel')}
																handleClick={(e: any) => {
																	getComments(recognizeData);
																}}
															/>
														</Box>
														<Box className='recog-post-actions-right'>
															<List disablePadding>
																<ListItem>
																	<Box
																		aria-owns={open ? 'mouse-over-popover' : undefined}
																		aria-haspopup='true'
																		onMouseEnter={(e: any) => {
																			recognizeData.totalLikeCount > 0 && handleOpen(e, recognizeData);
																		}}
																		onMouseLeave={handleClose}
																	>
																		<ListItemText
																			primary={`${recognizeData.totalLikeCount} ${
																				recognizeData.totalLikeCount > 1 ? t('likedLevel') : t('likeLevel')
																			}`}
																		/>
																		{open && recognizeData.recognitionId === likedId && (
																			<Box>
																				<PopperMenu
																					{...props}
																					children={
																						<>
																							{likeData.length > 0 &&
																								likeData.map((likeList: any) => {
																									return (
																										<ListItem>
																											<Box className='user-info'>
																												<Box className='user-img'>
																													{likeList?.imagePath ? (
																														<Avatar src={likeList?.imagePath} />
																													) : (
																														<Avatar>
																															{getUserName({
																																fullName: '',
																																firstName: likeList?.firstName,
																																lastName: likeList?.lastName,
																															})}
																														</Avatar>
																													)}
																												</Box>
																												<Box className='user-info-details'>
																													{likeList.fullName.length > 30 ? (
																														<Tooltip title={likeList.fullName} arrow>
																															<Typography variant='subtitle2'>
																																{likeList.fullName.substring(0, 30)}
																																...
																															</Typography>
																														</Tooltip>
																													) : (
																														<Typography>{likeList.fullName}</Typography>
																													)}

																													{likeList.emailId.length > 28 ? (
																														<Tooltip title={likeList.emailId} arrow>
																															<Typography variant='subtitle2'>
																																{likeList.emailId.substring(0, 28)}
																																...
																															</Typography>
																														</Tooltip>
																													) : (
																														<Typography variant='subtitle2'>
																															{likeList.emailId}
																														</Typography>
																													)}
																												</Box>
																											</Box>
																										</ListItem>
																									);
																								})}
																						</>
																					}
																					popperClassName={'recog-liked-by-dropdown'}
																					anchorEl={anchorEl}
																					handleClose={handleClose}
																					popperPlacement={'bottom'}
																					open={open}
																				/>
																			</Box>
																		)}
																	</Box>
																</ListItem>

																<ListItem>
																	<OKRButton
																		text={`${recognizeData.totalCommentCount} ${t('commentsLabel')}`}
																		handleClick={(e: any) => {
																			handleCardClick(e, 'recognize', { recognitionId: recognizeData.recognitionId });
																		}}
																	/>
																</ListItem>
															</List>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
										{commentRecognizationId === recognizeData.recognitionId && (
											<Box className='recog-dashboard-comment'>
												<Box className='recog-loggedin-user'>
													{loggedInUserDetail && loggedInUserDetail.imagePath ? (
														<Avatar
															className='avatar-default'
															alt={`${loggedInUserDetail.firstName} ${loggedInUserDetail.lastName}`}
															src={loggedInUserDetail.imagePath}
														/>
													) : (
														<Avatar className='avatar-default'>
															{getUserName({
																firstName: loggedInUserDetail.firstName,
																lastName: loggedInUserDetail.lastName,
																fullName: loggedInUserDetail.fullname,
															})}
														</Avatar>
													)}
												</Box>
												<Box className='recog-comment-box'>
													<TextField
														className='recog-comment'
														id='recog-comment'
														placeholder='Write a Comment...'
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
														value={postText}
														onChange={(e: any) => {
															setPostText(e.target.value);
														}}
													/>
													<Box className='recog-comment-actions'>
														<OKRButton
															className='comment-cancel-btn'
															icon={<CancelIcon />}
															handleClick={(e: any) => {
																setPostText('');
																setCommentRecognizationId(0);
															}}
														/>
														<OKRButton
															className='comment-save-btn'
															icon={<TickIcon />}
															handleClick={(e: any) => {
																saveComment(recognizeData);
															}}
															disabled={!postText || saveClicked ? true : false}
														/>
													</Box>
												</Box>
											</Box>
										)}
									</Box>
								);
							})}
					</Box>
				</>
			) : (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography variant='h2'>{t('noRecognizationYet')}</Typography>
						<Typography>{t('noDataForSelfViewSubText')}</Typography>
						<img width='331' alt={t('noRecognizationYet')} src={noRecognitionPost} />
					</Box>
				</Box>
			)}

			{modalOpen.open && (
				<DialogComponent
					module='information'
					message={modalOpen.message}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen.open}
					isCancel={true}
				/>
			)}
		</Box>
	);
};
