import {
	getAllPerspectiveAPI,
	searchObjectiveAPI,
	getAllSoOkrListAPI,
	getAllSoTimeFrameAPI,
	landingMissionVisionAPI,
	createSoAPI,
	updateSoAPI,
	deleteSoAPI,
	getSOLinkedOKRListAPI,
	deleteSoTimeFrameAPI,
	getSoALllPermissionAPI,
	getCycleDetailForSoAPI,
	updateSoProgressAPI,
	CreateUpdateAdminSOMissionVisionAPI,
	getAdminSOMissionVisionHistoryAPI,
	getAdminSOMissionVisionDetailsAPI,
	getAdminSOSettingsAPI,
	CreateUpdateAdminSOSettingsAPI,
	isPerspectiveVisibleAPI,
	getAdminSOPerspectiveAPI,
	CreateAdminSOPerspectiveAPI,
	updateAdminSOPerspectiveAPI,
	deleteAdminSOPerspectiveAPI,
	deleteSoVisibilityUserAPI,
	editSoTimeFrameAPI,
	deleteSoProgressVisibilityUserAPI,
} from '../services/strategicObjective';
import { GET_SO_PERMISSION_FAILED, GET_SO_PERMISSION_SUCCESS, OPEN_SO_CREATE_DRAWER } from './actionTypes';

export const getAllPerspective = (payload) => {
	return () => {
		return getAllPerspectiveAPI(payload);
	};
};

export const searchObjectiveForSO = (payload) => {
	return () => {
		return searchObjectiveAPI(payload);
	};
};

export const getAllSoOkrList = (payload) => {
	return () => {
		return getAllSoOkrListAPI(payload);
	};
};

export const getAllSoTimeFrame = (payload) => {
	return () => {
		return getAllSoTimeFrameAPI(payload);
	};
};

export const landingMissionVision = (payload) => {
	return () => {
		return landingMissionVisionAPI(payload);
	};
};

export const createSo = (payload) => {
	return () => {
		return createSoAPI(payload);
	};
};
export const updateSo = (payload) => {
	return () => {
		return updateSoAPI(payload);
	};
};

export const deleteSo = (payload) => {
	return () => {
		return deleteSoAPI(payload);
	};
};

export const getSOLinkedOKRList = (payload) => {
	return () => {
		return getSOLinkedOKRListAPI(payload);
	};
};

export const deleteSoTimeFrame = (payload) => {
	return () => {
		return deleteSoTimeFrameAPI(payload);
	};
};

export const openSoCreateDrawer = (payload) => ({
	type: OPEN_SO_CREATE_DRAWER,
	payload,
});

const isSoPermissionCompleted = (payload) => ({
	type: GET_SO_PERMISSION_SUCCESS,
	payload,
});
const isSoPermissionFailed = (error) => ({
	type: GET_SO_PERMISSION_FAILED,
	error,
});
export const getAllSoPermission = () => {
	return (dispatch) => {
		return getSoALllPermissionAPI()
			.then((response) => {
				if (response && response?.data?.status === 200) {
					const { entity } = response?.data || {};
					dispatch(isSoPermissionCompleted(entity || {}));
				} else {
					dispatch(isSoPermissionFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(isSoPermissionFailed(error));
			});
	};
};

export const getCycleDetailForSo = (payload) => {
	return () => {
		return getCycleDetailForSoAPI(payload);
	};
};

export const updateSoProgress = (payload, queryParam) => {
	return () => {
		return updateSoProgressAPI(payload, queryParam);
	};
};

export const editSoTimeFrame = (payload) => {
	return () => {
		return editSoTimeFrameAPI(payload);
	};
};

/**
 * SO Admin Related Actions
 */

export const CreateUpdateAdminSOMissionVision = (payload) => {
	return () => {
		return CreateUpdateAdminSOMissionVisionAPI(payload);
	};
};
export const getAdminSOMissionVisionHistory = (payload) => {
	return () => {
		return getAdminSOMissionVisionHistoryAPI(payload);
	};
};
export const getAdminSOMissionVisionDetails = (payload) => {
	return () => {
		return getAdminSOMissionVisionDetailsAPI(payload);
	};
};
export const getAdminSOSettings = (payload) => {
	return () => {
		return getAdminSOSettingsAPI(payload);
	};
};
export const CreateUpdateAdminSOSettings = (payload) => {
	return () => {
		return CreateUpdateAdminSOSettingsAPI(payload);
	};
};
export const isPerspectiveVisible = (payload) => {
	return () => {
		return isPerspectiveVisibleAPI(payload);
	};
};
// export const getAdminSOPerspective = (payload) => {
// 	return () => {
// 		return getAdminSOPerspectiveAPI(payload);
// 	};
// };
export const CreateAdminSOPerspective = (payload) => {
	return () => {
		return CreateAdminSOPerspectiveAPI(payload);
	};
};
export const updateAdminSOPerspective = (payload) => {
	return () => {
		return updateAdminSOPerspectiveAPI(payload);
	};
};
export const deleteAdminSOPerspective = (payload) => {
	return () => {
		return deleteAdminSOPerspectiveAPI(payload);
	};
};
export const deleteSoVisibilityUser = (payload) => {
	return () => {
		return deleteSoVisibilityUserAPI(payload);
	};
};
export const deleteSoProgressVisibilityUser = (payload) => {
	return () => {
		return deleteSoProgressVisibilityUserAPI(payload);
	};
};
