import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import NofoundImg from '../../../images/no-found-conversation.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { EditIcon } from '../../../config/svg/CommonSvgIcon';
import GlobalSearch from '../../Common/GlobalSearch';
import { getUserName } from '../../../config/utils';
import { ImportList } from './ImportList';

export const PeopleSearch: React.FC<any> = (props) => {
	const { t, okrKrSelectionData, setOkrKrSelectionData } = props;
	const [selectedPeople, setSelectedPeople] = useState<any>({});

	const [modalPropsAlert, setModalPropsAlert] = useState({
		open: false,
		data: null,
		message: '',
		type: 0,
		value: '',
		cycleLabel: '',
		cycle: '',
	});

	const fetchMyOkrData = (cycleId: any[], searchString: string, pageSize: number, pageIdx: number) => {
		const payload: any = {
			employeeId: selectedPeople.employeeId,
			cycleId: cycleId,
			search: searchString,
			pageSize: pageSize,
			pageIndex: pageIdx,
		};
		if (!searchString) {
			setOkrKrSelectionData([]);
		}
		if (selectedPeople.employeeId) {
			props.postMyOkrImport(payload);
		}
	};
	const onSelectedUser = (selectedUser: any) => {
		setSelectedPeople(selectedUser[0]);
	};
	const handlePeopleEdit = () => {
		if (Object.keys(okrKrSelectionData).length > 0) {
			setModalPropsAlert({
				...modalPropsAlert,
				open: true,
				message: t('unSavedImportAlert'),
				type: 8,
				value: '',
			});
		} else {
			clearPeopleSearch();
		}
	};
	const clearPeopleSearch = () => {
		setSelectedPeople({});
		setOkrKrSelectionData([]);
	};

	return (
		<Box className='drawer-form-fields'>
			{Object.keys(selectedPeople).length > 0 ? (
				<Box className='drawer-input-field import-src-people'>
					<Box className='searched-people'>
						<Box className='user-info'>
							<Box className='user-img'>
								{selectedPeople && selectedPeople?.imagePath ? (
									<Avatar
										className='avatar-default'
										alt={`${selectedPeople?.firstName} ${selectedPeople?.lastName}`}
										src={selectedPeople?.imagePath}
									/>
								) : (
									<Avatar className='avatar-default'>
										{getUserName({
											firstName: selectedPeople?.firstName,
											lastName: selectedPeople?.lastName,
											fullName: selectedPeople?.fullname,
										})}
									</Avatar>
								)}
							</Box>
							<Box className='user-info-details'>
								<Typography variant='h4'>
									{selectedPeople?.firstName} {selectedPeople?.lastName}
								</Typography>
								<Typography variant='h6'>{selectedPeople?.emailId}</Typography>
							</Box>
						</Box>
						<OKRButton
							handleClick={handlePeopleEdit}
							className='editIcon'
							icon={<EditIcon />}
							title={t('editLabel')}
							tooltipPlacement={'top'}
						/>
					</Box>
				</Box>
			) : (
				<Box className='drawer-input-field '>
					<GlobalSearch
						{...props}
						module={'peopleSearch'}
						customClass={'user-quick-search-type2'}
						placeHolderText={t('srcUser')}
						customId={'import-user-src'}
						fetchCycleUsers={true}
						onSelectedUser={onSelectedUser}
					/>
				</Box>
			)}

			{Object.keys(selectedPeople).length > 0 ? (
				<>
					<ImportList
						{...props}
						modalPropsAlert={modalPropsAlert}
						setModalPropsAlert={setModalPropsAlert}
						clearPeopleSearch={clearPeopleSearch}
						t={t}
						fetchMyOkrData={fetchMyOkrData}
						okrKrSelectionData={okrKrSelectionData}
						selectedUserDetail={Object.keys(selectedPeople).length > 0 && selectedPeople}
						setOkrKrSelectionData={setOkrKrSelectionData}
					/>
				</>
			) : (
				<Box className='search-no-record-global'>
					{/* First Load Screen*/}
					<Box className='no-record-message'>
						<Typography variant='h2' component='h2'>
							{t('peopleOkrHead')}
						</Typography>
						<Typography dangerouslySetInnerHTML={{ __html: t('peopleOkrDes') }}></Typography>
						<AnimatedIcon
							className=''
							width='230'
							type='image/svg+xml'
							alt={t('globalSearchNoRecord')}
							imagePath={NofoundImg}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};
