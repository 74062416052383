import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	showNotification: false,
	updateProgress: null,
	updatedOkr: {},
	showRefreshOptionSnackBar: false,
	isCalendarOpen: false,
	isOneOnOneRefreshValue: false,
	isDashboardRefreshValue: false,
	isRecognizeRefreshValue: false,
	appDrawerInfo: {
		showRefreshOption: false,
		isDrawerOpened: false,
		isNewObjectiveFormOpened: false,
		isProgressPopperOpened: false,
		isKebabMenuOpened: false,
		isOKREdit: false,
		isNewKRAdded: false,
		isFilterApplied: false,
		isImportDrawerOpen: false,
	},
};

export default function signalRReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.HIGHLIGHT_NOTIFICATION:
			return {
				...state,
				showNotification: action.payload,
			};
		case actionTypes.SIGNALR_UPDATE_PROGRESS:
			if (Object.values(state.appDrawerInfo).some((key) => key === true)) {
				if (state.appDrawerInfo.isImportDrawerOpen === true) {
					return {
						...state,
						updateProgress: action.payload,
						showRefreshOptionSnackBar: false,
					};
				}
				return {
					...state,
					showRefreshOptionSnackBar: true,
				};
			} else {
				return {
					...state,
					updateProgress: action.payload,
					showRefreshOptionSnackBar: false,
				};
			}
		case actionTypes.SIGNALR_OKR_UPDATED:
			return {
				...state,
				updatedOkr: action.payload,
			};
		case actionTypes.IS_DRAWER_DIALOG_OPENED:
			return {
				...state,
				appDrawerInfo: action.payload,
			};
		case actionTypes.SHOW_REFRESH_SNACKBAR:
			return {
				...state,
				showRefreshOptionSnackBar: action.payload, // Always false
			};
		case actionTypes.CALENDAR_OPEN_STATE:
			return {
				...state,
				isCalendarOpen: action.payload,
			};
		case actionTypes.IS_NON_DASHBOARD_UPDATED:
			return {
				...state,
				showRefreshOptionSnackBar: action.payload, // Always true
			};
		case actionTypes.IS_ONE_ON_ONE_REFRESH:
			return {
				...state,
				isOneOnOneRefreshValue: action.payload,
			};
		case actionTypes.IS_DASHBOARD_REFRESH:
			return {
				...state,
				isDashboardRefreshValue: action.payload,
			};
		case actionTypes.IS_RECOGNIZE_REFRESH:
			return {
				...state,
				isRecognizeRefreshValue: action.payload,
			};
		default:
			return state;
	}
}
