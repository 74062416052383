import {
	Box,
	Avatar,
	FormControlLabel,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	Typography,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
import { AddIconSvg, BottomArrowIcon, DeleteIcon, EditIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { getTime, getUserDetails, getUserName } from '../../../config/utils';
import { getCommentsList, saveCommentsPost, saveRecognizePost } from '../../../action/recognize';
import { showLoader } from '../../../action/common';
import { Enums } from '../../../config/enums';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { enableTaggingWithImage } from '../../Common/CkEditor/CkEditorEnums';
import { globalSearchAPIWithTeam, recognitionSearch, searchEmployee } from '../../../action/search';
import { RecognizePostBadges } from '../RecognizePostBadges/RecognizePostBadges';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import AlertDialog from '../../Common/Dialog';

export const CreateCommentForm = (props: any) => {
	const { commentPageIndex, setEditEditId } = props;
	const { t } = useTranslation();
	const [postText, setPostText] = React.useState<any>('');
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [imageList, setImageList] = useState<any[]>([]);
	const [saveClicked, setSaveClicked] = React.useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<any>({ open: false, message: '', type: '' });
	const [showEditor, setShowEditor] = React.useState<boolean>(false);
	const [isFormEdited, setIsFormEdited] = React.useState<boolean>(false);

	useEffect(() => {
		if (props.commentsData && props.commentsData.commentDetailsId) {
			setPostText(props.commentsData.comments);
			setShowEditor(true);
		}
	}, [props.commentsData]);
	const searchCustomEmployee = (searchStr: string, page: number, pageSize: number) => {
		return dispatch(recognitionSearch(searchStr, page, pageSize, 1));
	};
	const handleCancel = () => {
		setShowEditor(false);
		setEditEditId(0);
		setPostText('');
	};
	const cancelComment = () => {
		if (isFormEdited) {
			setModalOpen({ open: true, message: t('unSavedItemAlert'), type: 'unsaved' });
		} else {
			handleCancel();
		}
	};
	const saveComment = async () => {
		if (postText) {
			setSaveClicked(true);
			let data: any = {
				commentDetailsId:
					props.commentsData && props.commentsData.commentDetailsId ? props.commentsData.commentDetailsId : 0,
				comments: postText,
				moduleDetailsId: props.recognitionId,
				recognitionImageRequests: [],
				recognitionEmployeeTags: [],
				moduleId: 1,
			};
			const elem = document.createElement('span');
			elem.innerHTML = data.comments;
			let tagged = elem.getElementsByClassName('tagged-name');
			for (let i = 0; i < tagged.length; i++) {
				let employeeId: any = tagged[i].getAttribute('rel');
				if (employeeId) {
					let searchType = 1;
					if (employeeId.includes('_teamId')) {
						searchType = 2;
						employeeId = employeeId.replace('_teamId', '');
					}
					data.recognitionEmployeeTags.push({
						id: parseInt(employeeId),
						searchType: searchType,
					});
				}
			}
			imageList.forEach((value: any) => {
				if (postText.includes(value.filePath)) {
					data.recognitionImageRequests.push({
						fileName: value.fileName,
						guidFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					});
				}
			});
			let response: any = await dispatch(saveCommentsPost(data));
			if (response.data.status === Enums.STATUS_SUCCESS) {
				dispatch(showLoader(false));
				//props.getAllOrgRecognize(1, 10, props.isMyPost);
				props.updateCommentCount(props.recognitionId, true);
				props.getComments(1, commentPageIndex * 10);
				setPostText('');
				// enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
				// 	variant: 'success',
				// 	autoHideDuration: 5000,
				// });
				setSaveClicked(false);
			} else {
				setSaveClicked(false);
				if (response?.data?.messageList.BlockedWords) {
					setModalOpen({ open: true, message: response?.data?.messageList.BlockedWords });
				} else {
					enqueueSnackbar(response?.data?.messageList.message, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			}
		}
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (modalOpen.type === 'unsaved' && type === 1) {
			handleCancel();
			setIsFormEdited(false);
		}
		setModalOpen({ open: false, message: '' });
	};
	return (
		<>
			{!showEditor && (
				<>
					<TextField
						id='add-comment-field'
						className='add-comment-field'
						value={''}
						fullWidth
						placeholder={t('praiseCommentPlaceholder')}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<AddIconSvg />
								</InputAdornment>
							),
						}}
						onClick={(e) => {
							setShowEditor(true);
						}}
					/>
				</>
			)}
			{showEditor && (
				<>
					<Box className='recog-post-box recog-post-editor-box'>
						{' '}
						<Box className='editor-pane'>
							<Box className='cfr-control-section' id='rteImage'>
								<CkEditor
									{...props}
									enableTagging={true}
									focusOnLoad={true}
									placeholder={''}
									handleEditorChange={(value: string) => {
										if (
											value &&
											(value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>')
										) {
											setIsFormEdited(true);
											//setPostText('');
										} else {
											if (postText !== value) {
												setIsFormEdited(true);
											}
											//setIsTaskEdited(true);
											setPostText(value);
										}
										//setEditorText(value);
									}}
									value={postText}
									setImageList={setImageList}
									imageList={imageList}
									uploadCase={'CONVERSATION'}
									uploadType={'3'}
									removedPlugins={enableTaggingWithImage}
									uploadImage={true}
									showEmoticons={true}
									globalSearchAPIWithTeam={searchCustomEmployee}
								/>
							</Box>
						</Box>
						<Box className='recog-add-post-action'>
							<OKRButton
								className='btn-primary'
								text={'Cancel'}
								handleClick={cancelComment}
								//disabled={!postText || saveClicked ? true : false}
							/>
							<OKRButton
								className='btn-primary'
								text={'Post'}
								handleClick={saveComment}
								disabled={!postText || saveClicked ? true : false}
							/>
						</Box>
					</Box>
				</>
			)}
			{modalOpen.open ? (
				<AlertDialog
					module={modalOpen?.type === 'unsaved' ? 'user' : ''}
					message={modalOpen?.message || ''}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen?.open}
					isCancel={modalOpen?.type !== 'unsaved'}
				/>
			) : (
				<></>
			)}
		</>
	);
};
