import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	Avatar,
	Badge,
	Box,
	ClickAwayListener,
	FormControlLabel,
	Grow,
	List,
	ListItem,
	Paper,
	Popper,
	Switch,
	Tooltip,
	Typography,
} from '@material-ui/core';
import '../../../styles/pages/recognize/recognition.scss';
import { getFameOfWallBadges, getRecognizationBadges } from '../../../action/recognize';
import {
	formatFeedbackDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { OKRPopper } from '../../Common/OKRPopper';
import { Skeleton } from '@material-ui/lab';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import NewIcon from '../../../images/newsvg.svg';

export const FameBadgesWall = (props: any) => {
	const { t } = useTranslation();
	const [fameWallBadgesData, setFameWallBadgesData] = useState<any>(null);
	const [badgesGivenByPeers, setBadgesGivenByPeers] = useState<any>(null);
	const [onlyTeamBadges, setOnlyTeamBadges] = useState<any>(null);
	const [isToggle, setIsToggle] = useState<any>(false);

	const dispatch = useDispatch();
	const userDetail = getUserDetails();

	const { fameWallBadgesResponse } = useSelector((state: any) => state.recognizeReducer);
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [badgeUser, setBadgeUser] = useState<any[]>([]);
	const [hoverIndex, setHoverIndex] = useState<any>(null);

	const handleOpen = (event: any, users: any[], index: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
		setBadgeUser(users);
		setHoverIndex(index);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
		setBadgeUser([]);
		setHoverIndex(null);
	};

	useEffect(() => {
		if (props.selectedUser) {
			fetchBadges();
		}
	}, [props.selectedUser]);

	const fetchBadges = () => {
		dispatch(
			getFameOfWallBadges({
				pageIndex: 1,
				pageSize: 10,
				id: props.selectedUserDetail?.teamId
					? props.selectedUserDetail.teamId
					: props.selectedUserDetail.employeeId
					? props.selectedUserDetail?.employeeId
					: userDetail.employeeId,
				//searchType: props.selectedUserDetail?.teamId ? 2 : 1,
				searchType: props.selectedUserDetail?.teamId ? 2 : props.selectedUserDetail.employeeId ? 1 : 0,
			})
		);
		setIsToggle(false);
	};

	useEffect(() => {
		setFameWallBadgesData(fameWallBadgesResponse);
		if (fameWallBadgesResponse && fameWallBadgesResponse.length) {
			fameWallBadgesResponse.forEach((element: any) => {
				if (element.givenByText === 'Badges given by Peers') {
					setBadgesGivenByPeers(element.recognitionImageMappings);
				}
				if (element.givenByText === 'Only Team Badges') {
					setOnlyTeamBadges(element.recognitionImageMappings);
				}
			});
		}
	}, [fameWallBadgesResponse]);

	const toggleMyPost = (event: any) => {
		if (event.target.checked) {
			//setFameWallBadgesData(fameWallBadgesResponse.filter((item: any) => item.isGivenByManager));
			setIsToggle(true);
		} else {
			//setFameWallBadgesData(fameWallBadgesResponse);
			setIsToggle(false);
		}
	};
	const renderBadges = (badgeList: any) => {
		return badgeList.map((item: any, index: number) => {
			return (
				<>
					<Box
						className='recognize-badge-box long-text'
						aria-owns={open && index === hoverIndex ? 'mouse-over-popover' : undefined}
						aria-haspopup='true'
						onMouseEnter={(e: any) => {
							handleOpen(e, item.recognitionUserDetails, index);
						}}
						onMouseLeave={handleClose}
					>
						{item?.isNewBadge && (
							<Box className='recognize-badge-new'>
								<AnimatedIcon
									className='add-user-img'
									width='28'
									type='image/svg+xml'
									alt='New Badge'
									imagePath={NewIcon}
								/>
							</Box>
						)}
						<Box className='recognize-badge-icon'>
							<Badge
								badgeContent={item.totalCount}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								className={'badge-anchor-bottom'}
							>
								<img src={item.imageFilePath} alt='' />
							</Badge>
						</Box>
						<Typography variant='h6'>{item.name}</Typography>
						{open && index === hoverIndex && (
							<Popper
								className={'wall-of-fame-dropdown'}
								open={open}
								anchorEl={anchorEl}
								transition
								placement={'bottom-end'}
								modifiers={{
									preventOverflow: {
										enabled: false,
										boundariesElement: window,
									},

									flip: {
										enabled: false, // true by default
									},
								}}
							>
								{({ TransitionProps }) => (
									<Grow {...TransitionProps}>
										<Paper>
											<ClickAwayListener onClickAway={handleClose}>
												<List>
													{badgeUser.length > 0 &&
														badgeUser.map((fameUserData: any) => {
															return (
																<ListItem>
																	<Box className='user-info'>
																		<Box className='user-img'>
																			{fameUserData?.imagePath ? (
																				<Avatar src={fameUserData?.imagePath} />
																			) : (
																				<Avatar>
																					{getUserName({
																						fullName: '',
																						firstName: fameUserData?.firstName,
																						lastName: fameUserData?.lastName,
																					})}
																				</Avatar>
																			)}
																		</Box>
																		<Box className='user-info-details'>
																			{(fameUserData?.firstName + ' ' + fameUserData.lastName).length > 30 ? (
																				<Tooltip title={fameUserData?.firstName + ' ' + fameUserData?.lastName} arrow>
																					<Typography variant='h4'>
																						{(fameUserData?.firstName + ' ' + fameUserData.lastName).substring(0, 30)}
																						...
																					</Typography>
																				</Tooltip>
																			) : (
																				<Typography variant='h4'>
																					{fameUserData.firstName} {fameUserData.lastName}
																				</Typography>
																			)}
																			{(fameUserData?.emailId).length > 30 ? (
																				<Tooltip title={fameUserData?.emailId} arrow>
																					<Typography variant='subtitle2'>
																						{fameUserData.emailId.substring(0, 30)}
																						...
																					</Typography>
																				</Tooltip>
																			) : (
																				<Typography variant='subtitle2'>{fameUserData.emailId}</Typography>
																			)}
																		</Box>
																	</Box>
																	<Box className='badge-count-details'>
																		{fameUserData?.teamId && fameUserData?.teamId > 0 ? (
																			<Box className='user-team-info'>
																				<Tooltip title={fameUserData?.teamName} arrow>
																					<span>
																						{fameUserData?.logoImagePath ? (
																							<Avatar
																								className='team-avatar'
																								alt={fameUserData?.teamName}
																								src={fameUserData?.logoImagePath}
																							/>
																						) : (
																							<Avatar
																								className='team-avatar'
																								style={{
																									backgroundColor: getRandomBackgroundColor(
																										fameUserData?.backGroundColorCode
																									),
																									color: getRandomColor(fameUserData?.colorCode),
																								}}
																								src={fameUserData?.logoImagePath}
																							>
																								{getUserName({
																									firstName: '',
																									lastName: '',
																									fullName: fameUserData?.teamName,
																								})}
																							</Avatar>
																						)}
																					</span>
																				</Tooltip>
																			</Box>
																		) : (
																			<></>
																		)}
																		<Box className='recognize-badge-count'>{fameUserData.count}</Box>
																	</Box>
																</ListItem>
															);
														})}
												</List>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						)}
					</Box>
				</>
			);
		});
	};

	return (
		<Box className='recognize-badge-wrapper'>
			<Box className='wall-of-fame-head'>
				<Typography variant='h6'>
					{props.selectedUserDetail && props.selectedUserDetail.teamId
						? props.selectedUserDetail.teamName
						: props.selectedUserDetail.employeeId
						? props.selectedUserDetail.firstName + "'s"
						: 'My'}{' '}
					{t('myWallOfFame')}
				</Typography>
				{!props.selectedUserDetail.teamId && (
					<Box className='given-by-switch'>
						<FormControlLabel
							control={<Switch checked={isToggle} name='isMyPostFilter' onChange={toggleMyPost} />}
							label={t('onlyTeamBadges')}
							labelPlacement='end'
							className='toggle-label'
						/>
					</Box>
				)}
			</Box>

			{fameWallBadgesData?.length > 0 ? (
				<Box className='recognize-badge-wrapper-inner'>
					{isToggle ? renderBadges(onlyTeamBadges) : renderBadges(badgesGivenByPeers)}
				</Box>
			) : (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography variant='h2'>{t('noBadgesReceived')}</Typography>
						<Box className='no-recognize-badge'>
							<Box className='recognize-badge-box'>
								<Box className='recognize-badge-icon'>
									<Skeleton animation={false} variant='circle' width={40} height={40}>
										<Badge
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											className={'badge-anchor-bottom'}
										></Badge>
									</Skeleton>
								</Box>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='80%'>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
							<Box className='recognize-badge-box'>
								<Box className='recognize-badge-icon'>
									<Skeleton animation={false} variant='circle' width={40} height={40}>
										<Badge
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											className={'badge-anchor-bottom'}
										></Badge>
									</Skeleton>
								</Box>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='80%'>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
			{/* {fameWallBadgesData?.length > 0 ? (
				<Box className='recognize-badge-wrapper-inner'>
					{console.log(fameWallBadgesData)}
					{fameWallBadgesData.map((badgesData: any) => {
						return (
							<>
								{badgesData.recognitionImageMappings.map((item: any) => {
									return (
										<>
											<Box
												className='recognize-badge-box long-text'
												aria-owns={open ? 'mouse-over-popover' : undefined}
												aria-haspopup='true'
												onMouseOver={(e: any) => {
													handleOpen(e, item.recognitionUserDetails);
												}}
												onMouseLeave={handleClose}
											>
												{item?.isNewBadge && (
													<Box className='recognize-badge-new'>
														<AnimatedIcon
															className='add-user-img'
															width='28'
															type='image/svg+xml'
															alt='New Badge'
															imagePath={NewIcon}
														/>
													</Box>
												)}
												<Box className='recognize-badge-icon'>
													<Badge
														badgeContent={item.totalCount}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'right',
														}}
														className={'badge-anchor-bottom'}
													>
														<img src={item.imageFilePath} alt='' />
													</Badge>
												</Box>
												<Typography variant='h6'>{item.name}</Typography>
											</Box>
											{open && (
												<OKRPopper
													{...props}
													children={
														<>
															<List>
																{badgeUser.length > 0 &&
																	badgeUser.map((fameUserData: any) => {
																		return (
																			<ListItem>
																				<Box className='user-info'>
																					<Box className='user-img'>
																						{fameUserData?.imagePath ? (
																							<Avatar src={fameUserData?.imagePath} />
																						) : (
																							<Avatar>
																								{getUserName({
																									fullName: '',
																									firstName: fameUserData?.firstName,
																									lastName: fameUserData?.lastName,
																								})}
																							</Avatar>
																						)}
																					</Box>
																					<Box className='user-info-details'>
																						<Typography variant='h4'>
																							{fameUserData.firstName} {fameUserData.lastName}
																						</Typography>
																						<Typography variant='subtitle2'>{fameUserData.emailId}</Typography>
																					</Box>
																				</Box>
																				<Box className='badge-count-details'>
																					{fameUserData?.teamId && fameUserData?.teamId > 0 ? (
																						<Box className='user-team-info'>
																							<Tooltip title={fameUserData?.teamName} arrow>
																								<span>
																									{fameUserData?.logoImagePath ? (
																										<Avatar
																											className='team-avatar'
																											alt={fameUserData?.teamName}
																											src={fameUserData?.logoImagePath}
																										/>
																									) : (
																										<Avatar
																											className='team-avatar'
																											style={{
																												backgroundColor: getRandomBackgroundColor(
																													fameUserData?.backGroundColorCode
																												),
																												color: getRandomColor(fameUserData?.colorcode),
																											}}
																											src={fameUserData?.logoImagePath}
																										>
																											{getUserName({
																												firstName: '',
																												lastName: '',
																												fullName: fameUserData?.teamName,
																											})}
																										</Avatar>
																									)}
																								</span>
																							</Tooltip>
																						</Box>
																					) : (
																						<></>
																					)}
																					<Box className='recognize-badge-count'>{fameUserData.count}</Box>
																				</Box>
																			</ListItem>
																		);
																	})}
															</List>
														</>
													}
													openPopper={open}
													anchorEl={anchorEl}
													setOpenPopper={setOpen}
													setAnchorEl={setAnchorEl}
													popperContainer={false}
													popperClassName={'wall-of-fame-dropdown'}
													handlePopperClose={handleClose}
												/>
											)}
										</>
									);
								})}
							</>
						);
					})}
				</Box>
			) : fameWallBadgesData && fameWallBadgesData.length === 0 ? (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography variant='h2'>{t('noBadgesReceived')}</Typography>
						<Box className='no-recognize-badge'>
							<Box className='recognize-badge-box'>
								<Box className='recognize-badge-icon'>
									<Skeleton animation={false} variant='circle' width={40} height={40}>
										<Badge
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											className={'badge-anchor-bottom'}
										></Badge>
									</Skeleton>
								</Box>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='80%'>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
							<Box className='recognize-badge-box'>
								<Box className='recognize-badge-icon'>
									<Skeleton animation={false} variant='circle' width={40} height={40}>
										<Badge
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											className={'badge-anchor-bottom'}
										></Badge>
									</Skeleton>
								</Box>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='80%'>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
						</Box>
					</Box>
				</Box>
			) : (
				<></>
			)} */}
		</Box>
	);
};
