import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Badge, ListItem, ListItemAvatar, ListItemText, Avatar, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PopperMenu } from '../Admin/CommonComponent/PopperMenu';
import { BottomArrowIcon, OrgIcon } from '../../config/svg/CommonSvgIcon';
import AlignmentMapChart from './AlignmentMapChart';
import { useDispatch, useSelector } from 'react-redux';
import { getAlignmentApiCall, getAllAlignmentApiCall } from '../../action/alignment';
import { getUserDetails } from '../../config/utils';
import { getLocalStorageItem } from '../../services/StorageService';
import { getMyTeamDetails, getRecentSearchAndDirectReporterList } from '../../action/myGoal';
import { Enums } from '../../config/enums';

export const AlignmentRoot: React.FC<any> = (props: any) => {
	const { children, value, index, tabMainValue, ...other } = props;
	const dispatch = useDispatch();
	let userDetail = getUserDetails();
	const year = getLocalStorageItem('currentYear' || 'year') || null;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const [treeData, setTreeData] = useState([]);
	const [organisations, setOrganisations] = useState<any[]>([]);
	const [selectedYear, setSelectedYear] = useState<any>(getLocalStorageItem('year') || year);
	const { alignmentStatus, alignDataResult, alignDataAllResult } = useSelector((state: any) => state.alignReducer);
	const [selectedTeamDetails, setSelectedTeamDetails] = useState<any>({});
	const [searchSelectedUser, setSearchSelectedUser] = useState<any>({});
	const [tabValue, setTabValue] = useState(0);
	/**
	 * Added code for RecentSearch
	 */
	const [recentSearchDetails, setRecentSearchData] = useState<any>(null);
	const [directReportDetails, setDirectReportDetails] = useState<any>(null);
	const [selectedUserDetails, setSelectedUserDetails] = useState(userDetail);

	useEffect(() => {
		let userId = userDetail.employeeId;
		let teamId = 0;
		if (searchSelectedUser && searchSelectedUser.employeeId) {
			userId = searchSelectedUser.employeeId;
		}
		if (selectedTeamDetails && selectedTeamDetails.teamId) {
			teamId = selectedTeamDetails.teamId;
		}
		getAlignment(teamId, userId);
		getTeamDetails(userId);
	}, [cycleId]);

	useEffect(() => {
		if (alignDataResult) {
			let parentId = '';
			let alData = alignDataResult.map((item: any) => {
				let data = { ...item, parentId: parentId, id: item.goalObjectiveId, _expanded: true };
				data.okrViewKeyResults = data.okrViewKeyResults.map((item: any) => {
					return {
						...item,
						id: item.goalKeyId,
					};
				});
				parentId = item.goalObjectiveId;
				return data;
			});
			setTreeData(alData);
			// Make API call for Recent Search
			getRecentSearchAndDirectReporter(userDetail?.employeeId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alignDataResult]);
	const loadTeamData = (team: any) => {
		setSelectedTeamDetails(team);
		setSearchSelectedUser({});
		getTeamDetails(userDetail?.employeeId);
		getAlignment(team.teamId, userDetail?.employeeId);
	};
	const getAlignment = (teamId = 0, empId: any) => {
		dispatch(getAlignmentApiCall(`empId=${empId || userDetail?.employeeId}&cycle=${cycleId}&teamId=${teamId}`));

		dispatch(
			getAllAlignmentApiCall(
				`empId=${empId || userDetail?.employeeId}&cycle=${cycleId}${
					teamId ? '&isTeams=true&teamId=' + teamId : ''
				}&year=${selectedYear}`
			)
		);
	};

	const getTeamDetails = async (empId: any) => {
		const requestParam = `empId=${empId || userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
		const response: any = await dispatch(getMyTeamDetails(requestParam));
		if (
			response?.data &&
			response?.data?.status === Enums.STATUS_SUCCESS &&
			response?.data?.entityList &&
			response?.data?.entityList.length > 0
		) {
			response?.data?.entityList?.sort(
				(a: any, b: any) => b.keyCount - a.keyCount || a.teamScore - b.teamScore || a.teamName.localeCompare(b.teamName)
			);
			const { entityList } = response?.data;
			setOrganisations(entityList);
		} else {
			setOrganisations([]);
		}
	};

	const getRecentSearchAndDirectReporter = async (empId: any) => {
		try {
			const requestParam = `empId=${empId || 0}`;
			const response: any = await dispatch(getRecentSearchAndDirectReporterList(requestParam));

			if (response && response?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response?.data || {};
				const { aLignmentDirectResponse, recentSearch } = entity || {};
				recentSearch && recentSearch.length ? setRecentSearchData([...recentSearch]) : setRecentSearchData([]);
				aLignmentDirectResponse && aLignmentDirectResponse.length
					? setDirectReportDetails([...aLignmentDirectResponse])
					: setDirectReportDetails([]);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleUserSelection = (record: any) => {
		setSearchSelectedUser(record);
		record && record?.employeeId && getAlignment(0, record?.employeeId);
		setSelectedTeamDetails({});
		setTabValue(2);
	};

	return (
		<Box>
			{alignmentStatus === 'pending' ? (
				<Box className='loading-center-global'>
					<Typography>loading...</Typography>
				</Box>
			) : (
				<AlignmentMapChart
					loadTeamData={loadTeamData}
					selectedTeamDetail={selectedTeamDetails}
					setSelectedTeamDetails={setSelectedTeamDetails}
					organisations={organisations}
					completeData={treeData}
					data={treeData}
					tabMainValue={tabMainValue}
					recentSearchDetails={recentSearchDetails}
					setRecentSearchData={setRecentSearchData}
					directReportDetails={directReportDetails}
					setDirectReportDetails={setDirectReportDetails}
					handleUserSelection={handleUserSelection}
					selectedUserDetails={selectedUserDetails}
					krData={alignDataAllResult}
					setSearchSelectedUser={setSearchSelectedUser}
					searchSelectedUser={searchSelectedUser}
					tabValue={tabValue}
					setTabValue={setTabValue}
				/>
			)}
		</Box>
	);
};
