import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { checkSupportPermission, getMonthDateYearTime, getOrdinalSuffix, getUserDetails } from '../../config/utils';
import '../../styles/pages/landing/landing-page.scss';
import { RecognizeBadges } from '../Common/RecognizeBadges';
import { useTranslation } from 'react-i18next';
import { CheckinWidget } from './CheckinWidget';
import { DashboardMyProgress } from './DashboardMyProgress';
import { DashboardPillerBox } from './DashboardPillerBox';
import {
	Assessment360DegreeIcon,
	FeedbackColorIcon,
	FinalAssessmentIcon,
	RequestColorIcon,
	SelfAssessmentIcon,
	TeamAssessmentIcon,
} from '../../config/svg/AssessmentSvg';
import AssessmentImg from '../../images/assessment-pending-img.svg';
import { DashboardToDoCourses } from './DashboardToDoCourses';
import { DashboardRecommendedCourses } from './DashboardRecommendedCourses';
import { useDispatch, useSelector } from 'react-redux';
import { checkDetailsDashboard, getOkrMasterData } from '../../action/myGoal';
import {
	currentDashboardTab,
	currentDashboardTabForSO,
	currentEngageTabs,
	getDashboardWidgetsData,
	getLandingFeedbackRequest1On1Count,
	showLoader,
	updateRoute,
	getTenantDowntime,
	getDashboardAssessmentData,
	getPerformanceGoalDashboardWidget,
} from '../../action/common';
import { getMywalloffamedashboard } from '../../action/cfr';
import { ENGAGE, HOME, PERFORM, PERFORM_DEVELOPMENT, RECOGNIZE, REPORTS } from '../../config/app-url';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import Header from '../../containers/Header';
import { BadgesSkeleton } from '../Common/BadgesSkeleton';
import { RewardsIcon } from '../../config/svg/CommonSvg';
import { TaskWidget } from './TaskWidget';
import { getAllGlobalTask, completedGlobalTask } from '../../action/globalTask';
import { Enums } from '../../config/enums';
import { useSnackbar } from 'notistack';
import { isDashboardRefresh } from '../../action/signalR';
import { PraiseWallWidget } from './PraiseWallWidget';
import { praiseRecognizeId } from '../../action/recognize';
import { ReportsTooltipIcon } from '../../config/svg/CommonSvgIcon';
import { ChatBot } from '../ChatBot';
import { OKRButton } from '../Common/OKRButton';
import { supportModuleAlignGoals, supportModuleAlignOkr, supportModulePerformAssessment } from '../Admin/AdminConstant';
import { DashboardMyGoals } from './DashboardMyGoals';

const Home: React.FC<any> = (props: any) => {
	const userDetail: any = getUserDetails();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const loginUser = getUserDetails();
	const [checkInData, setCheckInData] = useState<any>({});
	const [badges, setBadges] = useState([]);
	const [widgetData, setWidgetData] = useState<any>({});
	const [widgetAssessmentData, setWidgetAssessmentData] = useState<any>({});
	const [feebackData, setFeebackData] = useState<any>({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [coursesDataLoaded, setCoursesDataLoaded] = useState(false);
	const [trendingCoursesLoaded, setTrendingCoursesLoaded] = useState(false);
	const { userImpersonated } = useSelector((state: any) => state.commonReducer);
	const [globalTaskData, setGlobalTaskData] = useState<any>([]);
	const [globalTaskLoading, setGlobalTaskLoading] = useState(false);
	const [toDoTaskCount, setToDoTaskCount] = useState(0);
	const [downTimeData, setDownTimeData] = useState<any>('');
	const endDateValue = new Date();
	endDateValue.setMonth(endDateValue.getMonth() + 1);
	const startDateValue = new Date(endDateValue);
	startDateValue.setMonth(startDateValue.getMonth() - 3);
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const [performanceGoalsData, setPerformanceGoalsData] = useState<any>({});

	//Global Task Reducer
	const { globalDashboardTaskListResponse, globalTaskListStatus } = useSelector(
		(state: any) => state.globalTaskReducer
	);
	const { isDashboardRefreshValue } = useSelector((state: any) => state.signalRReducer);
	useEffect(() => {
		getPerformanceGoalsDashboardData();
	}, []);

	useEffect(() => {
		getTenantDowntimeDetails();
		getCheckinDetails();
		getDashboardTilesData();
		getGlobalTaskList();
		dispatch(getOkrMasterData());
		getPerformanceGoalsDashboardData();
	}, [userImpersonated]);

	useEffect(() => {
		if (isDashboardRefreshValue) {
			getCheckinDetails();
			getDashboardTilesData();
			getGlobalTaskList();
			dispatch(isDashboardRefresh(false));
			getPerformanceGoalsDashboardData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDashboardRefreshValue]);

	useEffect(() => {
		if (globalTaskListStatus) {
			if (globalDashboardTaskListResponse?.status === Enums.STATUS_SUCCESS) {
				const { entity } = globalDashboardTaskListResponse || {};
				const { taskListings, toDoCount } = entity || {};
				setGlobalTaskData(taskListings || []);
				setToDoTaskCount(toDoCount || 0);
			} else {
				setGlobalTaskData([]);
				setToDoTaskCount(0);
			}
			setGlobalTaskLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalTaskListStatus]);

	const getTenantDowntimeDetails = async () => {
		const tenantDowntime: any = await dispatch(getTenantDowntime());
		if (tenantDowntime && tenantDowntime.data.status === 200) {
			const entity = tenantDowntime.data.entity;
			if (entity && entity?.downTimeStart) {
				const startDate = new Date(new Date(entity.downTimeStart) + 'UTC');
				const endDate = new Date(new Date(entity.downTimeEnd) + 'UTC');
				const displayDate = new Date(startDate);
				const timezone = (displayDate && displayDate.toString().match(/\(([^)]+)\)/)![1]) || null;
				const msgDisplayDate = new Date(displayDate.setDate(displayDate.getDate() - entity.messageDisplayDays));

				if (new Date() >= msgDisplayDate && new Date() <= endDate) {
					const day = startDate.getDate();
					const month = startDate.toLocaleString('default', { month: 'long' });
					const year = startDate.getFullYear();

					const formattedDate = `${day} ${month} ${year}`;
					const startTime = startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
					const endTime = endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

					const downTimeData = `${formattedDate} from ${startTime} to ${endTime} (${timezone})`;
					setDownTimeData(entity.downTimeMessage.replace('{0}', downTimeData));
				}
			}
		}
	};

	const getCheckinDetails = async () => {
		dispatch(showLoader(true));
		let data = `empId=${loginUser.employeeId}`;

		const wallOfFameResponse: any = await dispatch(getMywalloffamedashboard());
		const apiRes: any = await dispatch(checkDetailsDashboard(data));
		const widgetData: any = await dispatch(getDashboardWidgetsData());
		const widgetAssessmentData: any = await dispatch(getDashboardAssessmentData());

		if (wallOfFameResponse && wallOfFameResponse.data.status === 200 && apiRes && apiRes.data.status === 200) {
			setCheckInData(apiRes.data.entity);
			setBadges(wallOfFameResponse.data.entity.recognitionImageMappings);
			setWidgetData(widgetData.data.entity);
			setWidgetAssessmentData(widgetAssessmentData.data.entity);
			dispatch(showLoader(false));
			setIsDataLoaded(true);
		}
	};

	const resetCycleDetails = () => {
		const curCycle = localStorage.getItem('currentCycle');
		const curCycleData = curCycle ? JSON.parse(curCycle) : null;

		localStorage.setItem('year', curCycleData?.cycleYear || '');
		localStorage.removeItem('navigationId');
		localStorage.setItem('cycleId', curCycleData?.organisationCycleId || '');
		localStorage.setItem('cycleEndDate', curCycleData?.cycleEndDate || '');
		localStorage.setItem('cycleStartDate', curCycleData?.cycleStartDate || '');
	};
	const handleCardClick = (e: any, route: any, data: any = {}) => {
		resetCycleDetails();
		if (route === 'reports') {
			dispatch(currentDashboardTabForSO(false));
			dispatch(currentDashboardTab(0));
			dispatch(updateRoute('/reports'));
			props.switchUser(e, 'user', REPORTS);
		} else if (route === 'myOkrs') {
			removeLocalStorageItem('backUrl');
			dispatch(currentDashboardTabForSO(false));
			dispatch(currentDashboardTab(0));
			dispatch(updateRoute(HOME));
			props.switchUser(e, 'user', HOME);
		} else if (route === 'checkins') {
			dispatch(currentDashboardTabForSO(false));
			removeLocalStorageItem('backUrl');
			dispatch(currentEngageTabs(2));
			dispatch(updateRoute(ENGAGE));
			props.switchUser(e, 'user', ENGAGE);
		} else if (route === 'feedBackRequest') {
			dispatch(currentDashboardTabForSO(false));
			removeLocalStorageItem('backUrl');
			setLocalStorageItem('activeFeedbackStateBox', 4);
			dispatch(currentEngageTabs(1));
			dispatch(updateRoute(PERFORM_DEVELOPMENT));
			props.switchUser(e, 'user', PERFORM_DEVELOPMENT);
		} else if (route === 'assessment') {
			dispatch(currentDashboardTabForSO(false));
			removeLocalStorageItem('backUrl');
			dispatch(currentDashboardTab(0));
			dispatch(updateRoute(PERFORM));
			props.switchUser(e, 'user', PERFORM);
		} else if (route === 'teamAssessment') {
			dispatch(currentDashboardTabForSO(false));
			removeLocalStorageItem('backUrl');
			dispatch(currentDashboardTab(1));
			dispatch(updateRoute(PERFORM));
			props.switchUser(e, 'user', PERFORM);
		} else if (route === 'requestOneOnOne') {
			dispatch(currentDashboardTabForSO(false));
			removeLocalStorageItem('backUrl');
			const { reportingTo } = loginUser || {};
			const redirectInfo = { employeeId: reportingTo || 0, employeeInfo: null, redirectFrom: 'Dashboard' };
			setLocalStorageItem('requestOneOnOneRedirect', JSON.stringify(redirectInfo));
			dispatch(currentEngageTabs(3));
			dispatch(updateRoute(ENGAGE));
			props.switchUser(e, 'user', ENGAGE);
		} else if (route === 'recognize') {
			dispatch(currentDashboardTabForSO(false));
			removeLocalStorageItem('backUrl');
			//dispatch(currentDashboardTab(1));
			if (data && data.recognitionId) {
				dispatch(praiseRecognizeId(data.recognitionId));
			}
			dispatch(updateRoute(RECOGNIZE));
			props.switchUser(e, 'user', RECOGNIZE);
		} else if (route === 'myGoals') {
			removeLocalStorageItem('backUrl');
			dispatch(currentDashboardTabForSO(false));
			dispatch(currentDashboardTab(6));
			dispatch(updateRoute(HOME));
			props.switchUser(e, 'user', HOME);
		} else if (route === 'directReportsGoals') {
			removeLocalStorageItem('backUrl');
			dispatch(currentDashboardTabForSO(false));
			dispatch(currentDashboardTab(7));
			dispatch(updateRoute(HOME));
			props.switchUser(e, 'user', HOME);
		}
	};

	const getDashboardTilesData = async () => {
		let data = loginUser.employeeId;
		const requestOneOnOneResponse: any = await dispatch(getLandingFeedbackRequest1On1Count(data));
		dispatch(showLoader(true));
		if (requestOneOnOneResponse && requestOneOnOneResponse.data.status === 200) {
			dispatch(showLoader(false));
			setFeebackData(requestOneOnOneResponse.data.entity);
		}
	};

	const getPerformanceGoalsDashboardData = async () => {
		const performanceGoalsresponse: any = await dispatch(getPerformanceGoalDashboardWidget());
		dispatch(showLoader(true));
		if (performanceGoalsresponse && performanceGoalsresponse.data.status === 200) {
			dispatch(showLoader(false));
			setPerformanceGoalsData(performanceGoalsresponse.data.entity);
		}
	};

	const assessmentType = (widgetData: any) => {
		if (!widgetData.isSelfAssessmentDone && widgetData?.isSelfRatingPercentage) {
			return 'Self';
		} else if (!widgetData.isManagerAssessmentDone) {
			return 'Manager';
		} else if (!widgetData.finalRating) {
			return 'Final';
		} else if (widgetData.finalRating) {
			return widgetData.finalScore;
		} else {
			return '';
		}
	};

	const handleBadgesViewAllClick = (e: any) => {
		dispatch(currentDashboardTab(0));
		dispatch(updateRoute(RECOGNIZE));
		props.switchUser(e, 'user', RECOGNIZE);
	};

	const getGlobalTaskList = () => {
		let data = {
			isMyTask: true,
			isCompleted: false,
			pageSize: Enums.SIX,
			pageIndex: Enums.ONE,
			order: '',
			sortingText: '',
			finder: [],
			taskFilter: {
				dueDate: [],
				taskType: [],
				priority: [],
			},
			startDate: null,
			endDate: null,
			taskGroupById: Enums.ZERO,
			taskGroupByType: Enums.ZERO,
			moduleId: Enums.FOUR,
		};
		setGlobalTaskLoading(true);
		dispatch(getAllGlobalTask(data, true));
	};
	const handleCompleteTask = async (e: any, task: any) => {
		try {
			dispatch(showLoader(true));
			const currentTasks = [...globalTaskData];
			const { checked } = e.target;

			const updateTask = currentTasks.map((item: any) => {
				if (item.taskId === task.taskId) {
					return {
						...item,
						isCompleted: checked,
					};
				} else {
					return {
						...item,
					};
				}
			});
			const postData = { taskId: task.taskId, isCompleted: checked };
			setGlobalTaskData(updateTask);
			const taskCompleteResponse: any = await dispatch(completedGlobalTask(postData));
			if (Boolean(taskCompleteResponse) && taskCompleteResponse.data.status === 200) {
				getGlobalTaskList();
				// enqueueSnackbar(t('taskUpdate'), {
				// 	variant: 'success',
				// 	autoHideDuration: 3000,
				// });
				dispatch(showLoader(false));
			} else {
				const responseAPI = taskCompleteResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				enqueueSnackbar(messages, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				dispatch(showLoader(false));
			}
		} catch (error) {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};
	const history = useHistory();
	const handlePerformNotification = (tab: number) => {
		dispatch(currentDashboardTabForSO(false));
		dispatch(currentDashboardTab(tab));
		dispatch(updateRoute(PERFORM));
		history.push(PERFORM);
	};
	return (
		<Box key={userDetail?.employeeId}>
			{downTimeData && (
				<Box className='dashboard-ticker'>
					<ReportsTooltipIcon />
					<Typography
						dangerouslySetInnerHTML={{
							__html: `<b>Planned maintenance: </b>` + downTimeData,
						}}
					></Typography>
				</Box>
			)}
			<Box className='page-subheader page-subheader-dashboard'>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'></Box>
					<Box className='page-subheader-right'>
						<Header {...props} fromTab={true} isDashboard={true} />
					</Box>
				</Box>
			</Box>
			<Box className='landing-container pad-container'>
				<Box className='landing-subheader'>
					<Box className='landing-subheader-left'>
						<Typography variant='h2'>{`Welcome, ${userDetail?.firstName} ${userDetail?.lastName}`}</Typography>
						{/* <Typography>We are happy to see you again.</Typography> */}
					</Box>
					<Box className='landing-subheader-right'>
						{/* <Box className='badge-list'>
							{badges && badges.length > 0 ? (
								<>
									{badges.map((item: any, index: number) => (
										<RecognizeBadges data={item} key={index} {...props} icon={item.imageFilePath} text={item.name} />
									))}
								</>
							) : (
								<>
									<BadgesSkeleton {...props} />
									<BadgesSkeleton {...props} />
									<BadgesSkeleton {...props} />
								</>
							)}
							<Box className='recognize-badge-box recognize-badge-view-all' onClick={handleBadgesViewAllClick}>
								<Box className='recognize-badge-inner'>
									<Box className='recognize-badge-icon'>
										<RewardsIcon />
									</Box>
									<Typography variant='h6'>{t('viewPraiseWall')}</Typography>
								</Box>
							</Box>
						</Box> */}
					</Box>
				</Box>
				{checkSupportPermission(supportModuleAlignGoals) ? (
					<>
						<Box className='goals-dashboard-area'>
							<Box
								className={`goal-dashboard-top ${
									performanceGoalsData?.myGoalsView?.isMyGoalViewVisible &&
									performanceGoalsData?.reportingManagerView?.isReportingManagerView
										? 'goal-dashboard-top-col5'
										: performanceGoalsData?.myGoalsView?.isMyGoalViewVisible ||
										  performanceGoalsData?.reportingManagerView?.isReportingManagerView
										? 'goal-dashboard-top-col4'
										: 'goal-dashboard-top-col3'
								}`}
							>
								<DashboardMyGoals performanceGoalsData={performanceGoalsData} handleCardClick={handleCardClick} />
								<DashboardPillerBox
									icon={<FeedbackColorIcon />}
									mainHead={feebackData?.totalFeedbackReceive > 1 ? t('Feedbacks Received') : t('Feedbacks Received')}
									subHead={''}
									description={feebackData.totalFeedbackReceive + ' received in 90 days'}
									feebackData={feebackData}
									isAssessment={false}
									type={'feedBackRequest'}
									bgColor={'white-color'}
									handleCardClick={handleCardClick}
									customClassName={
										widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay ||
										widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
											? 'half-box-rectangle-info'
											: !widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay &&
											  !widgetAssessmentData?.managerDashboardWidget?.isManagerTileDisplay &&
											  !widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
											? 'half-box-rectangle-info'
											: ''
									}
								/>
								<DashboardPillerBox
									icon={<RequestColorIcon />}
									mainHead={feebackData?.request1On1 > 1 ? t('1-on-1 Requests') : t('1-on-1 Request')}
									subHead={''}
									description={feebackData.totalRequest1On1 + ' requested in 90 days'}
									feebackData={feebackData}
									isAssessment={false}
									type={'requestOneOnOne'}
									bgColor={'white-color'}
									handleCardClick={handleCardClick}
									customClassName={
										widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay ||
										widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
											? 'half-box-rectangle-info'
											: !widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay &&
											  !widgetAssessmentData?.managerDashboardWidget?.isManagerTileDisplay &&
											  !widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
											? 'half-box-rectangle-info'
											: ''
									}
								/>
								{checkInData && (
									<CheckinWidget {...props} checkInData={checkInData} handleCardClick={handleCardClick} />
								)}
							</Box>
							<Box className='landing-page-col-layout'>
								<Box className='landing-page-col-left'>
									<PraiseWallWidget handleCardClick={handleCardClick} />
								</Box>
								<Box className='landing-page-col-right'>
									<Box className='landing-task-section'>
										<TaskWidget
											{...props}
											globalTaskData={globalTaskData}
											globalTaskLoading={globalTaskLoading}
											toDoTaskCount={toDoTaskCount}
											handleCompleteTask={handleCompleteTask}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</>
				) : (
					<Box className='landing-page-col-layout'>
						<Box className='landing-page-col-left'>
							<Box className='landing-page-box landing-page-row-1 landing-page-row'>
								<Box className='landing-progress-section white-box'>
									<DashboardMyProgress {...props} handleCardClick={handleCardClick} />
								</Box>
							</Box>
							<Box className='landing-page-box landing-page-row'>
								<Box className='landing-page-middle-left'>
									{isDataLoaded && (
										<Box
											className={`${
												widgetData?.isActiveCycle
													? 'landing-page-row landing-page-row-2 activeCycle'
													: 'landing-page-row landing-page-row-2'
											}`}
										>
											<Box className={`middle-widget-wrap`}>
												{checkInData && (
													<CheckinWidget {...props} checkInData={checkInData} handleCardClick={handleCardClick} />
												)}
												<DashboardPillerBox
													icon={<FeedbackColorIcon />}
													mainHead={
														feebackData?.totalFeedbackReceive > 1 ? t('Feedbacks Received') : t('Feedbacks Received')
													}
													subHead={''}
													description={feebackData.totalFeedbackReceive + ' received in 90 days'}
													feebackData={feebackData}
													isAssessment={false}
													type={'feedBackRequest'}
													bgColor={'white-color'}
													handleCardClick={handleCardClick}
													customClassName={
														widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay ||
														widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
															? 'half-box-rectangle-info'
															: !widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay &&
															  !widgetAssessmentData?.managerDashboardWidget?.isManagerTileDisplay &&
															  !widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
															? 'half-box-rectangle-info'
															: ''
													}
												/>
												<DashboardPillerBox
													icon={<RequestColorIcon />}
													mainHead={feebackData?.request1On1 > 1 ? t('1-on-1 Requests') : t('1-on-1 Request')}
													subHead={''}
													description={feebackData.totalRequest1On1 + ' requested in 90 days'}
													feebackData={feebackData}
													isAssessment={false}
													type={'requestOneOnOne'}
													bgColor={'white-color'}
													handleCardClick={handleCardClick}
													customClassName={
														widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay ||
														widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
															? 'half-box-rectangle-info'
															: !widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay &&
															  !widgetAssessmentData?.managerDashboardWidget?.isManagerTileDisplay &&
															  !widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
															? 'half-box-rectangle-info'
															: ''
													}
												/>
												{
													//here
												}
												{checkSupportPermission(supportModulePerformAssessment) && (
													<>
														{widgetAssessmentData?.selfDashboardWidget?.isSelfTileDisplay && (
															<DashboardPillerBox
																icon={<SelfAssessmentIcon />}
																mainHead={t('selfAssessment') || ''}
																subHead={''} //widgetData?.performanceCycleName
																description={`${getMonthDateYearTime(
																	widgetAssessmentData?.selfDashboardWidget?.selfRatingStartDate
																)} to ${getMonthDateYearTime(
																	widgetAssessmentData?.selfDashboardWidget?.selfRatingEndDate
																)}`}
																bgColor={'white-color'}
																feebackData={feebackData}
																isAssessment={true}
																takeAssessmentText={
																	widgetAssessmentData?.selfDashboardWidget?.isSelfAssessmentDone &&
																	widgetAssessmentData?.selfDashboardWidget?.isSelfScoreVisible ? (
																		<>
																			<Typography variant='h2'>
																				{widgetAssessmentData?.selfDashboardWidget?.selfAssessmentScore}
																			</Typography>
																		</>
																	) : widgetAssessmentData?.selfDashboardWidget?.isSelfAssessmentDone &&
																	  !widgetAssessmentData?.selfDashboardWidget?.isSelfScoreVisible ? (
																		<>
																			<Typography variant='h2'>Done</Typography>
																		</>
																	) : (
																		<>
																			<OKRButton
																				className={`btn-link take-assessment`}
																				text={
																					widgetAssessmentData?.selfDashboardWidget?.selfAssessmentStatus === 1
																						? t('resumeAssessment')
																						: t('takeAssessment')
																				}
																				icon={''}
																			/>
																		</>
																	)
																}
																type={'assessment'}
																widgetData={widgetData}
																assessmentText={widgetData?.finalScore || 0}
																handleCardClick={() => {
																	handlePerformNotification(0);
																}}
																isDirectReports={true}
																assessmentFinalRatingText={widgetData?.finalRating || '--'}
																customClassName={''}
															/>
														)}
														{widgetAssessmentData?.managerDashboardWidget?.isManagerTileDisplay && (
															<DashboardPillerBox
																icon={<TeamAssessmentIcon />}
																mainHead={t('teamAssessmentText')}
																subHead={''} //t('performanceAssessment')
																description={`${getMonthDateYearTime(
																	widgetAssessmentData?.managerDashboardWidget?.managerRatingStartDate
																)} to ${getMonthDateYearTime(
																	widgetAssessmentData?.managerDashboardWidget?.managerRatingEndDate
																)}`}
																bgColor={'white-color'}
																feebackData={feebackData}
																isAssessment={true}
																takeAssessmentText={
																	widgetAssessmentData?.managerDashboardWidget?.isManagerAssessmentDone ? (
																		<>
																			<Typography variant='h2'>Done</Typography>
																		</>
																	) : (
																		<>
																			<OKRButton
																				className={`btn-link take-assessment`}
																				text={t('takeAssessment')}
																				icon={''}
																			/>
																		</>
																	)
																}
																type={'assessment'}
																widgetData={widgetData}
																isDirectReports={true}
																handleCardClick={() => {
																	handlePerformNotification(1);
																}}
																customClassName={
																	widgetAssessmentData?.managerDashboardWidget?.isManagerTileDisplay &&
																	widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
																		? 'half-box-rectangle-info'
																		: ''
																}
															/>
														)}
														{widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay && (
															<DashboardPillerBox
																icon={<Assessment360DegreeIcon />}
																mainHead={t('assessment360degree')}
																subHead={''} //t('performanceAssessment')
																description={`${getMonthDateYearTime(
																	widgetAssessmentData?.threeSixtyDashboardWidget?.threeSixtyStartDate
																)} to ${getMonthDateYearTime(
																	widgetAssessmentData?.threeSixtyDashboardWidget?.threeSixtyEndDate
																)}`}
																bgColor={'white-color'}
																feebackData={feebackData}
																isAssessment={true}
																takeAssessmentText={
																	widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyDone ? (
																		<>
																			<Typography variant='h2'>Done</Typography>
																		</>
																	) : (
																		<>
																			<OKRButton
																				className={`btn-link take-assessment`}
																				text={t('takeAssessment')}
																				icon={''}
																			/>
																		</>
																	)
																}
																type={'assessment'}
																widgetData={widgetData}
																isDirectReports={true}
																handleCardClick={() => {
																	handlePerformNotification(2);
																}}
																customClassName={
																	widgetAssessmentData?.managerDashboardWidget?.isManagerTileDisplay &&
																	widgetAssessmentData?.threeSixtyDashboardWidget?.isThreeSixtyTileDisplay
																		? 'half-box-rectangle-info'
																		: ''
																}
															/>
														)}
													</>
												)}
											</Box>
											{/* <Box
											className={`middle-widget-wrap ${
												widgetData?.isActiveCycle && widgetData?.isSelfRatingPercentage && widgetData?.isDirectReportees
													? 'no-direct-report'
													: widgetData?.isActiveCycle &&
													  !widgetData?.isDirectReportees &&
													  !widgetData?.isSelfRatingPercentage
													? 'no-self-report'
													: widgetData?.isActiveCycle && widgetData?.isExcludedUser
													? 'no-direct-report'
													: ''
											}`}
										>
											{checkInData && (
												<CheckinWidget {...props} checkInData={checkInData} handleCardClick={handleCardClick} />
											)}

											<DashboardPillerBox
												icon={<FeedbackColorIcon />}
												mainHead={
													feebackData?.feedbackRequestPending > 1 ? t('Feedback Requests') : t('Feedback Request')
												}
												subHead={''}
												description={''}
												feebackData={feebackData}
												isAssessment={false}
												type={'feedBackRequest'}
												bgColor={'white-color'}
												handleCardClick={handleCardClick}
											/>
											<DashboardPillerBox
												icon={<RequestColorIcon />}
												mainHead={feebackData?.request1On1 > 1 ? t('1-on-1 Requests') : t('1-on-1 Request')}
												subHead={''}
												description={''}
												feebackData={feebackData}
												isAssessment={false}
												type={'requestOneOnOne'}
												bgColor={'white-color'}
												handleCardClick={handleCardClick}
											/>

											{widgetData?.isActiveCycle && !widgetData?.isExcludedUser ? (
												<>
													{widgetData?.isSelfRatingPercentage && (
														<DashboardPillerBox
															icon={<FinalAssessmentIcon />}
															mainHead={
																!widgetData.isSelfAssessmentDone || !widgetData.isManagerAssessmentDone
																	? t('performanceAssessment')
																	: widgetData.isSelfAssessmentDone &&
																	  widgetData.isManagerAssessmentDone &&
																	  !widgetData.finalScore
																	? t('performanceAssessment')
																	: widgetData.isSelfAssessmentDone &&
																	  widgetData.isManagerAssessmentDone &&
																	  widgetData.finalScore > 0
																	? t('performanceAssessment')
																	: ''
															}
															subHead={''} //widgetData?.performanceCycleName
															description={''}
															bgColor={'white-color'}
															feebackData={feebackData}
															isAssessment={true}
															type={'assessment'}
															widgetData={widgetData}
															assessmentText={assessmentType(widgetData)}
															handleCardClick={handleCardClick}
														/>
													)}

													{widgetData?.isDirectReportees && (
														<DashboardPillerBox
															icon={<DirectReportReviewColorIcon />}
															mainHead={t('teamAssessmentText')}
															subHead={''} //t('performanceAssessment')
															description={''}
															bgColor={'white-color'}
															feebackData={feebackData}
															isAssessment={true}
															type={'assessment'}
															widgetData={widgetData}
															isDirectReports={true}
															handleCardClick={handleCardClick}
														/>
													)}
												</>
											) : !widgetData?.isActiveCycle &&
											  !widgetData?.isExcludedUser &&
											  widgetData?.performanceCycleName &&
											  widgetData?.isSelfRatingPercentage ? (
												<DashboardPillerBox
													icon={<FinalAssessmentIcon />}
													mainHead={t('performanceAssessment') || ''}
													subHead={''} //widgetData?.performanceCycleName
													description={''}
													bgColor={'white-color'}
													feebackData={feebackData}
													isAssessment={true}
													type={'assessment'}
													widgetData={widgetData}
													assessmentText={widgetData?.finalScore || 0}
													handleCardClick={handleCardClick}
													isDirectReports={true}
													assessmentFinalRatingText={widgetData?.finalRating || '--'}
												/>
											) : (
												<>
													<DashboardPillerBox
														mainHead={t('finalAssessmentHead')}
														description={''}
														bgColor={'white-color box-two-col'}
														tileImg={AssessmentImg}
														type={'assessment'}
														handleCardClick={handleCardClick}
													/>

													{widgetData?.isDirectReportees && widgetData?.isActiveCycle && (
														<DashboardPillerBox
															icon={<DirectReportReviewColorIcon />}
															mainHead={t('teamAssessmentText')}
															subHead={''} //t('performanceAssessment')
															description={''}
															bgColor={'white-color'}
															feebackData={feebackData}
															isAssessment={true}
															type={'assessment'}
															widgetData={widgetData}
															isDirectReports={true}
															handleCardClick={handleCardClick}
														/>
													)}
												</>
											)}
										</Box> */}
										</Box>
									)}

									{tenantData.tenantId !== '4f2e7e9e-acd3-4d6a-b83c-216827b87196' && (
										<Box
											className='landing-col-row landing-page-row-3'
											//style={{ display: coursesDataLoaded && trendingCoursesLoaded ? '' : 'none' }}
										>
											<Box className='col-left recommended-courses white-box'>
												<DashboardRecommendedCourses {...props} setCoursesDataLoaded={setCoursesDataLoaded} />
											</Box>
											<Box className='col-right to-do-courses white-box'>
												<Typography variant='h6'>{t('toDoCourses')}</Typography>
												<DashboardToDoCourses {...props} setTrendingCoursesLoaded={setTrendingCoursesLoaded} />
											</Box>
										</Box>
									)}
									{/* {(!coursesDataLoaded || !trendingCoursesLoaded) && (
									<Box className='loading-center-type1'>{t('loading')}</Box>
								)} */}
								</Box>
							</Box>
						</Box>
						<Box className='landing-page-col-right'>
							<Box className='landing-task-section'>
								<TaskWidget
									{...props}
									globalTaskData={globalTaskData}
									globalTaskLoading={globalTaskLoading}
									toDoTaskCount={toDoTaskCount}
									handleCompleteTask={handleCompleteTask}
								/>
							</Box>
							<PraiseWallWidget handleCardClick={handleCardClick} />
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: any) => {
	return {
		ingredients: state.ingredients,
	};
};

export default withRouter(connect(mapStateToProps, {})(Home));
