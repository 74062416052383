import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChatBot from '../../components/ChatBot';

const mapStateToProps = (state) => {
	return {
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ChatBot);
