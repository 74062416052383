/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, makeStyles, Tooltip, Icon } from '@material-ui/core';
import Header from './Header';
import OkrProgressChart from './OkrProgressChart/index';
import WeeklyKRChart from './WeeklyKRChart/index';
import { getLocalStorageItem } from '../../services/StorageService';
import { getQuarterStartEnd } from '../../config/utils';
import { FullScreen, ExitFullScreen } from '../../config/svg/ActionSvg';
import { TipsIcon } from '../../config/svg/CommonSvg';
import OKRChart from './OKRChart';
import '../../styles/pages/insight/insight.scss';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
	fullScreen: {
		width: '100%',
	},
	hideDisplay: {
		display: 'none',
	},
}));

const Reports = (props) => {
	const { showLoader, getAvgOkrReport, getMostWeeklyKrReport, getWeeklyKrGrowthReport, getLeastRiskOkrReport } = props;
	const classes = useStyle();
	const [anchorEl, setAnchorEl] = useState(null);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [chartIndex, setChartIndex] = useState(0);
	const [KRWeek, setKRWeek] = useState(6);
	const [chartData, setChartData] = useState([]);
	const [weeklyKrKey, setWeeklyKrKey] = useState(0);
	const [showAllKR, setShowAllKR] = useState(false);
	const [okrDetail, setOkrDetail] = useState([]);
	const chartOneRef = useRef(null);
	const chartTwoRef = useRef(null);
	const chartThreeRef = useRef(null);
	const chartFourRef = useRef(null);

	const getAllKRCount = (chartData) =>
		chartData.length > 0 &&
		chartData.filter((data) => data.value.config.url.includes('KrUpdateGrowthCycle'))[0].value.data.entity.week2.length;

	useEffect(() => {
		//ReactGA.pageview(window.location.pathname + window.location.search);
		showLoader(true);
		callApis();
	}, []);

	const callApis = () => {
		try {
			const data = getUserDetails('bubble');
			getLeastRiskOkrReport(data).then((response) => {
				showLoader(false);
				if (response.data && response.data.entity) {
					setOkrDetail(response.data.entity);
				}
			});

			Promise.allSettled([
				// getAvgOkrReport(getUserDetails()),
				// getTeamPerformance(getUserDetails()),
				getMostWeeklyKrReport(getUserDetails()),
				getWeeklyKrGrowthReport(getUserDetails()),
			]).then((response) => {
				if (response) {
					setChartData(response);
				}
			});
		} catch (err) {
			showLoader(false);
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event, weekNumber, showAllKR) => {
		setAnchorEl(null);
		weekNumber !== '' && setKRWeek(weekNumber);
		setWeeklyKrKey(weekNumber);
		showAllKR ? setShowAllKR(true) : setShowAllKR(false);
	};

	const getUserDetails = (type) => {
		const { currentYear } = getQuarterStartEnd();
		const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
		const employeeId = userDetail ? userDetail.employeeId : 0;
		const selectedYear = getLocalStorageItem('year') || currentYear;
		const cycleId = getLocalStorageItem('cycleId') || 0;
		const orgId = userDetail.organisationId;
		if (type === 'feedback') {
			return `empId=${employeeId}`;
		} else if (type === 'bubble') {
			return `empId=${employeeId}&cycle=${cycleId}&orgId=${orgId}&year=${selectedYear} `;
		}
		return `empId=${employeeId}&cycleId=${cycleId}&year=${selectedYear} `;
	};

	const clickFullScreenHandle = (event, index, chartName) => {
		setIsFullScreen((prev) => !prev);
		setChartIndex(index);
		setWeeklyKrKey(index);
	};

	const clickExitFullScreenHandle = () => {
		if (chartIndex === 2) {
			if (chartTwoRef.current) {
				setTimeout(() => {
					chartTwoRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}, 100);
			}
		} else if (chartIndex === 3) {
			if (chartThreeRef.current) {
				setTimeout(() => {
					chartThreeRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}, 100);
			}
		} else if (chartIndex === 4) {
			if (chartFourRef.current) {
				setTimeout(() => {
					chartFourRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}, 100);
			}
		}
		setIsFullScreen(false);
		setChartIndex(0);
		setWeeklyKrKey(-1);
	};
	const { t } = useTranslation();
	return (
		<div key={`${chartIndex}_main`}>
			<Box>
				<Header callApis={callApis} />
			</Box>
			<div className='wrapper-container' id='custom-scroll'>
				<div className='reports-graph-container'>
					{!isFullScreen && (
						<Box display='flex'>
							<Box className='graph-full-container'>
								<Box className='graph-title' display='flex'>
									<Typography variant='h4'>{t('okrstoFocus')}</Typography>
									<Tooltip arrow title={t('okrstoFocusTooltip')}>
										<Icon className='allign-tooltip'>
											<TipsIcon />
										</Icon>
									</Tooltip>
								</Box>
								<OKRChart ref={chartOneRef} okrDetail={okrDetail} getUserDetails={getUserDetails} {...props} />
							</Box>
						</Box>
					)}
					<Box display='flex' justifyContent='space-between'>
						<Box
							className={
								isFullScreen && chartIndex === 2
									? 'graph-full-container'
									: isFullScreen && chartIndex !== 2
									? classes.hideDisplay
									: 'graph-container'
							}
						>
							<Box className='graph-title'>
								<Typography variant='h4'>{t('contributorPerformance')}</Typography>
								<Tooltip arrow title={t('teamPerformanceTooltip')}>
									<Icon className='allign-tooltip'>
										<TipsIcon />
									</Icon>
								</Tooltip>
							</Box>
							<Box className='graph-chart'>
								<Box className='graph-actions'>
									{isFullScreen && chartIndex === 2 ? (
										<IconButton className='fit-to-screen-icon' onClick={() => clickExitFullScreenHandle()}>
											<ExitFullScreen />
										</IconButton>
									) : (
										<IconButton
											className='fit-to-screen-icon'
											onClick={(event) => clickFullScreenHandle(event, 2, 'OKRScore')}
										>
											<FullScreen />
										</IconButton>
									)}
								</Box>
								<Box ref={chartTwoRef}>
									<OkrProgressChart
										key={chartIndex}
										{...props}
										getAvgOkrReport={getAvgOkrReport}
										getUserDetails={getUserDetails}
										chartData={chartData && chartData.length > 0 ? chartData[0] : []}
									/>
								</Box>
								<Box className='min-threshold-content' display='flex' justifyContent='center'>
									<Typography variant='body2'>{t('organizationalThreshold')}</Typography>
									<Typography variant='body2' className='avg-org-txt' style={{ color: '#BF3002 ' }}>
										{t('organizationalScore')}
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box
							className={
								isFullScreen && chartIndex === 3
									? 'graph-full-container'
									: isFullScreen && chartIndex !== 3
									? classes.hideDisplay
									: 'graph-container'
							}
						>
							<Box className='graph-title'>
								<Typography variant='h4'>{t('mostActivityKrs')}</Typography>
								<Tooltip arrow title={t('mostActivityKrsTooltip')}>
									<Icon className='allign-tooltip'>
										<TipsIcon />
									</Icon>
								</Tooltip>
							</Box>
							<Box className='graph-chart'>
								<Box className='graph-actions'>
									{isFullScreen && chartIndex === 3 ? (
										<IconButton className='fit-to-screen-icon' onClick={() => clickExitFullScreenHandle()}>
											<ExitFullScreen />
										</IconButton>
									) : (
										<IconButton
											className='fit-to-screen-icon'
											onClick={(event) => clickFullScreenHandle(event, 3, 'KRUpdate')}
										>
											<FullScreen />
										</IconButton>
									)}
								</Box>
								<Box ref={chartThreeRef}>
									<WeeklyKRChart
										key={chartIndex}
										{...props}
										getMostWeeklyKrReport={getMostWeeklyKrReport}
										getUserDetails={getUserDetails}
										chartData={chartData && chartData.length > 0 ? chartData[1] : []}
									/>
									<Box display='flex' justifyContent='center'>
										<Typography variant='body2' style={{ color: '#292929' }}>
											{t('mosttop6KR')}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
					{/* KR weekly progression code comment below */}
					{/* <Box display='flex' justifyContent='space-between'>
						<Box
							className={
								isFullScreen && chartIndex === 4
									? 'graph-full-container'
									: isFullScreen && chartIndex !== 4
									? classes.hideDisplay
									: 'graph-container graph-full-container'
							}
						>
							<Box className='graph-title' display='flex' justifyContent='space-between'>
								<Box display='flex' alignItems='center'>
									<Typography variant='h4'>{t('weeklyProgressionKR')}</Typography>
									<Tooltip title={t('weeklyProgressKRTooltip')}>
										<Icon className='allign-tooltip'>
											<TipsIcon />
										</Icon>
									</Tooltip>
								</Box>
								<Button
									aria-controls='simple-menu'
									aria-haspopup='true'
									onClick={handleClick}
									style={{ backgroundColor: '#fff' }}
								>
									{showAllKR ? t('allKeyResults') : t('topKRWeek', { krweek: krweek })}
									<ExpandMoreOutlinedIcon />
								</Button>
								<Menu
									id='simple-menu'
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={(e) => handleClose(e, '')}
								>
									<MenuItem onClick={(e) => handleClose(e, 3)}>{t('top3KeyResults')}</MenuItem>
									<MenuItem onClick={(e) => handleClose(e, 6)}>{t('top6KeyResults')}</MenuItem>
									<MenuItem onClick={(e) => handleClose(e, 10)}>{t('top10KeyResults')}</MenuItem>
									<MenuItem
										onClick={(e) => {
											handleClose(e, getAllKRCount(chartData), true);
										}}
									>
										{t('allKeyResults')}
									</MenuItem>
								</Menu>
							</Box>
							<Box className='graph-chart' ref={chartFourRef}>
								<Box className='graph-actions'>
									{isFullScreen && chartIndex === 4 ? (
										<IconButton className='fit-to-screen-icon' onClick={() => clickExitFullScreenHandle()}>
											<ExitFullScreen />
										</IconButton>
									) : (
										<IconButton
											className='fit-to-screen-icon'
											onClick={(event) => clickFullScreenHandle(event, 4, 'MostKRUpdate')}
										>
											<FullScreen />
										</IconButton>
									)}
								</Box>
								<Box>
									<WeeklyKRGrowthChart
										key={weeklyKrKey}
										{...props}
										getUserDetails={getUserDetails}
										KRWeek={KRWeek}
										chartData={chartData && chartData.length > 0 ? chartData[2] : []}
									/>
								</Box>
							</Box>
						</Box>
					</Box> */}
				</div>
			</div>
		</div>
	);
};

export default Reports;
