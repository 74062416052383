import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Chip, List, ListItem, Typography } from '@material-ui/core';
import '../../../../styles/pages/performance-development/pipList.scss';
import { OKRButton } from '../../../Common/OKRButton';
import { Skeleton } from '@material-ui/lab';
import SkeletonAvatar from '../../../Common/SkeletonAvatar';

export const FeedbackSkeleton = (props: any) => {
	const { t } = useTranslation();
	return (
		<Box className='pip-card-panel pip-card-panel-skeleton'>
			<Box className='pip-card-header'>
				<Box className='user-info v-center'>
					<Box className='user-img'>
						<Skeleton animation={false} variant='circle' width={30} height={30}>
							<Avatar />
						</Skeleton>
					</Box>
					<Box className='user-info-details'>
						<Typography variant='h4'>
							<Skeleton animation={false} width='136px' height={16}>
								<Typography>.</Typography>
							</Skeleton>
						</Typography>
					</Box>
				</Box>
				<Box className='pip-status'>
					<Skeleton animation={false} width='50px' height={16}>
						<Chip className='draft-chip' label={t('draft')} />
						<Chip className='lagging-chip' label={t('activeLabel')} />
					</Skeleton>
				</Box>
			</Box>
			<Box className='pip-card-content'>
				<Box className='pip-feedback-list'>
					<List disablePadding>
						<ListItem>
							<Typography variant='h4' className='font-weight-normal'>
								<Skeleton animation={false} width='100%' height={22}>
									<Typography>.</Typography>
								</Skeleton>
							</Typography>
							<Box className='feedback-date-range'>
								<Typography variant='h6' className='alert-field'>
									<Skeleton animation={false} width={170} height={16}>
										<Typography>.</Typography>
									</Skeleton>
								</Typography>
							</Box>
						</ListItem>
					</List>
				</Box>
			</Box>
			<Box className='pip-card-footer'>
				<Typography variant='body2'>
					<Skeleton animation={false} width={60} height={16}>
						<Typography>.</Typography>
					</Skeleton>
				</Typography>
				<Box className='pip-card-footer-content'>
					<Box className='pip-card-footer-left'>
						<Box className='pip-reviewers-list'>
							<Box className='review-initiator'>
								<Skeleton animation={false} variant='circle' width={40} height={40}>
									<Avatar />
								</Skeleton>
							</Box>
							<Box className='review-others'>
								<SkeletonAvatar />
							</Box>
						</Box>
					</Box>
					<Box className='pip-card-footer-right'>
						<Skeleton animation={false} variant='circle' width={14} height={14}>
							<OKRButton />
						</Skeleton>
						<Skeleton animation={false} variant='circle' width={14} height={14}>
							<OKRButton />
						</Skeleton>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
