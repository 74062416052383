import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const FilterBox: React.FC<any> = (props: any) => {
	const { icon, mainHead, subHead, description, bgColor, tileImg, tileCount, handleBoxClick, classNameValue } = props;
	const { t } = useTranslation();

	return (
		<Box className={`box-rectangle-info ${bgColor ? bgColor : ''} ${classNameValue || ''}`} onClick={handleBoxClick}>
			<Box className='box-head'>
				<Box className='box-head-title'>
					{icon || ''}
					<Typography variant='h2'>{tileCount || 0}</Typography>
				</Box>
			</Box>
			<Box className='box-content'>
				{mainHead && <Typography variant='h5'>{mainHead}</Typography>}
				{subHead && (
					<Typography variant='h5' className='font-weight-normal'>
						{subHead}
					</Typography>
				)}
				<Typography variant='h6' className='font-weight-normal'>
					{description || ''}
				</Typography>
			</Box>
		</Box>
	);
};
