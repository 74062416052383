import { Box, ClickAwayListener, Link, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Assessment360DegreeIcon,
	CommittedColoredIcon,
	FinalAssessmentIcon,
	ManagerAssessmentIcon,
	PerformanceIcon,
	SelfAssessmentIcon,
} from '../../config/svg/AssessmentSvg';
import { RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';
import { useSnackbar } from 'notistack';
import { OKRButton } from '../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { BottomArrowIcon } from '../../config/svg/CommonSvgIcon';
import { AlertInfoIcon } from '../../config/svg/CommonSvg';
// import { UserSnapshotDrawer } from '../Perform/Snapshot/UserSnapshotDrawer';
import { UserSnapshot } from '../Common/UserSnapshot';
import { getMonthDateYearTime } from '../../config/utils';
import { saveEmployeesFinalRating, setTeamUserDataCompleted } from '../../action/perform';
import { Enums } from '../../config/enums';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { InfoprolearningDomainName } from '../../config/constant';
import { ManagerAssessmentGuideTemplateUrl } from '../../config/url';
import { currentDashboardTabForSO, setEmployeeDetailsForRedirection } from '../../action/common';
import { HOME, PERFORM, PERFORM_DEVELOPMENT } from '../../config/app-url';

export const TeamAssessmentHeader = (props: any) => {
	const {
		ref1,
		ref2,
		handleOneOnOneClick,
		userDetails,
		// performanceCycle,
		fromTeamAssessment,
		employeeId,
		getRatingData,
		assessmentType = '', // Manager OR FinalAssessment
		isThreeSixty,
		// isFinalRating,
		refSelf,
		refManager,
		refThreeSixty,
		selectedUserAssessment,
	} = props;
	const { t } = useTranslation();
	const [open, setOpen] = React.useState<Boolean>(false);
	const [openFinalRating, setOpenFinalRating] = React.useState<Boolean>(false);
	const [openSnapshot, setOpenSnapshot] = useState(false);
	const [selectedUser, setSelectedUser] = useState<any>(userDetails);
	const [selectedRating, setSelectedRating] = useState<any>(null);
	const { enqueueSnackbar } = useSnackbar();
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const [activeTile, setActiveTile] = useState(2);

	const { employeeFinalRatingData, takeAssessmentData, teamAssessmentResponse } = useSelector(
		(state: any) => state.performReducer
	);
	const [finalRatingList, setFinalRatingList] = React.useState<any>([]);
	const [finalRatingScore, setFinalRatingScore] = React.useState<any>(0);
	const [finalRating, setFinalRating] = React.useState<any>('');
	const dispatch = useDispatch();
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const { redirectedEmployeeDetails } = useSelector((state: any) => state.commonReducer);

	useEffect(() => {
		if (redirectedEmployeeDetails && redirectedEmployeeDetails.employeeId) {
			if (redirectedEmployeeDetails.selectedTile && redirectedEmployeeDetails.selectedTile == 1) {
				setActiveTileData(1);
			}
			dispatch(setEmployeeDetailsForRedirection(null));
		}
	}, [redirectedEmployeeDetails]);
	const cycleId = getLocalStorageItem('cycleId') || 0;
	useEffect(() => {
		if (employeeFinalRatingData && employeeFinalRatingData.length) {
			setFinalRatingList(employeeFinalRatingData);
			setFinalRatingScore(employeeFinalRatingData[0].finalScore);
			setFinalRating(employeeFinalRatingData[0].finalRating);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employeeFinalRatingData]);
	useEffect(() => {
		if (takeAssessmentData && takeAssessmentData.finalScore) {
			setFinalRatingScore(takeAssessmentData.finalScore);
			setFinalRating(takeAssessmentData.finalRating);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [takeAssessmentData]);
	useEffect(() => {
		if (assessmentType === 'FinalAssessment') {
			setOpen(true);
			setSelectedRating(null);
			setActiveTile(5);
		}
	}, [assessmentType]);

	useEffect(() => {
		if (isThreeSixty) {
			setActiveTile(3);
		}
	}, [isThreeSixty]);
	const handleClick = (e: any) => {
		if (!e.target.classList.contains('MuiButton-label')) {
			setOpen(true);
			setActiveTile(5);
		}
	};
	const handleClickAway = () => {
		setOpen(false);
		setOpenFinalRating(false);
	};
	const handleRatingDropdown = () => {
		setOpenFinalRating((prev: Boolean) => !prev);
	};
	const ctrlClick = (event: any, selectedTab: number, activeTile: number) => {
		if (event.ctrlKey) {
			let url = 'redirectUrl=perform/' + cycleId + '/' + selectedUserAssessment?.employeeId + '/1/' + (selectedTab + 1);
			setLocalStorageItem('redirectUrl', JSON.stringify({ url: url }));
			window.open(window.location.href, '_blank');
		} else {
			setActiveTileData(activeTile);
		}
	};
	const handleRatingClickAway = () => {
		setOpenFinalRating(false);
	};
	const handleOneOnOneRedirection = (e: any) => {
		let clickType = 'MyAssessMentTab';
		let empId = 0;
		if (fromTeamAssessment) {
			empId = employeeId || 0;
			clickType = 'MyAssessmentList';
		}
		handleOneOnOneClick(e, clickType, empId);
	};
	const handleSnapshot = (e: any) => {
		if (fromTeamAssessment) {
			setSelectedUser(userDetails);
		}
		setOpenSnapshot(true);
	};
	const handleDrawerClose = (e: any, type: any) => {
		setOpenSnapshot(false);
	};
	const setActiveTileData = (type: any) => {
		setActiveTile(type);
		getRatingData(type);
	};

	return (
		<>
			<Box className='user-assessment-box-area'>
				{teamAssessmentResponse && (
					<Box className='user-assessment-box-area-inner'>
						<Box className='user-assessment-box-area-left'>
							{teamAssessmentResponse?.myTeamsAssessment?.isSelfTileDisplay && (
								<div
									className={`assessment-box ${activeTile === 1 && 'active'} ${
										takeAssessmentData.isSelfFormVisble && takeAssessmentData.selfAssessmentScore > 0
											? 'enable-box'
											: ''
									}`}
									onClick={(event: any) => {
										if (takeAssessmentData.isSelfFormVisble && takeAssessmentData.selfAssessmentScore > 0) {
											ctrlClick(event, 0, 1);
										}
									}}
									ref={refSelf}
								>
									<Box className='box-outer-head'>
										<Box className='box-head'>
											<SelfAssessmentIcon />
											<Box className='box-head-right'>
												{takeAssessmentData.isSelfScoreVisible ? (
													<Typography variant='h2'>{takeAssessmentData.selfAssessmentScore}</Typography>
												) : takeAssessmentData.isSelfFormVisble && takeAssessmentData.selfAssessmentScore > 0 ? (
													<OKRButton
														className={`btn-link`}
														color='primary'
														text={<Typography variant='h2'>Done</Typography>}
														icon={''}
													/>
												) : takeAssessmentData.isSelfAssessmentCompleted && !takeAssessmentData.isSelfScoreVisible ? (
													<OKRButton
														className={`btn-link`}
														color='primary'
														text={<Typography variant='h2'>Done</Typography>}
														icon={''}
													/>
												) : (
													<Typography variant='h2' className='blank-text'>
														--
													</Typography>
												)}
											</Box>
										</Box>
									</Box>
									<Box className='box-setting-des'>
										<Typography variant='h6' className='font-weight-normal'>
											{t('performsubText', {
												performsubText: teamAssessmentResponse?.myTeamsAssessment?.selfRatingPercentage,
											})}
										</Typography>
									</Box>
									<Box className={`box-content ${takeAssessmentData?.isSelfAssessmentAlert && 'alert-box-content'}`}>
										<Typography variant='h5'>
											<Tooltip title={'Today is the last day to complete assessment'} arrow>
												<Box>{takeAssessmentData.isSelfAssessmentAlert && <AlertInfoIcon />}</Box>
											</Tooltip>
											{t('selfAssessment')}
										</Typography>
										<Typography variant='h6'>
											{getMonthDateYearTime(teamAssessmentResponse?.myTeamsAssessment?.selfRatingStartDate)} to{' '}
											{getMonthDateYearTime(teamAssessmentResponse?.myTeamsAssessment?.selfRatingEndDate)}
										</Typography>
									</Box>
								</div>
							)}
							<div
								className={`assessment-box enable-box ${activeTile === 2 && 'active'}`}
								onClick={(event: any) => {
									ctrlClick(event, 1, 2);
								}}
								ref={refManager}
							>
								<Box className='box-outer-head'>
									<Box className='box-head'>
										<ManagerAssessmentIcon />
										<Box className='box-head-right'>
											{takeAssessmentData.isManagerScoreVisible ? (
												<Typography variant='h2'>{takeAssessmentData?.managerAssessmentScore}</Typography>
											) : takeAssessmentData.isManagerFormVisible &&
											  !takeAssessmentData.isManagerScoreVisible &&
											  takeAssessmentData.isManagerAssessmentCompleted ? (
												<OKRButton
													className={`btn-link`}
													color='primary'
													text={<Typography variant='h2'>Done</Typography>}
													icon={''}
												/>
											) : (
												<OKRButton
													className={`btn-link take-assessment`}
													text={
														userDetails?.managerStatus === Enums.ZERO ? t('takeAssessment') : t('Resume Assessment')
													}
													icon={''}
												/>
											)}
										</Box>
									</Box>
								</Box>
								<Box className='box-setting-des'>
									<Typography variant='h6' className='font-weight-normal'>
										{t('performsubText', {
											performsubText: teamAssessmentResponse?.myTeamsAssessment?.managerRatingPercentage,
										})}
									</Typography>
								</Box>
								<Box className={`box-content ${takeAssessmentData?.isManagerAssessmentAlert && 'alert-box-content'}`}>
									<Typography variant='h5'>
										<Tooltip title={'Today is the last day to complete assessment'} arrow>
											<Box>{takeAssessmentData?.isManagerAssessmentAlert && <AlertInfoIcon />}</Box>
										</Tooltip>
										{t('managerAssessment')}
									</Typography>
									<Typography variant='h6'>
										{getMonthDateYearTime(teamAssessmentResponse?.myTeamsAssessment?.managerRatingStartDate)} to{' '}
										{getMonthDateYearTime(teamAssessmentResponse?.myTeamsAssessment?.managerRatingEndDate)}
									</Typography>
								</Box>
							</div>
							{teamAssessmentResponse?.myTeamsAssessment?.isThreeSixtyTileDisplay && (
								<div
									className={`assessment-box ${activeTile === 3 && 'active'} ${
										takeAssessmentData.isThreeSixtyScoreVisible ? 'enable-box' : ''
									}`}
									onClick={() => {
										if (takeAssessmentData.isThreeSixtyScoreVisible) {
											setActiveTileData(3);
										}
									}}
									ref={refThreeSixty}
								>
									<Box className='box-outer-head'>
										<Box className='box-head'>
											<Assessment360DegreeIcon />
											<Box className='box-head-right'>
												{takeAssessmentData?.isThreeSixtyScoreVisible ? (
													<Typography variant='h2'>{takeAssessmentData.threeSixtyScore}</Typography>
												) : (
													<Typography variant='h2' className='blank-text'>
														--
													</Typography>
												)}
												<OKRButton
													className={`btn-link`}
													text={`${
														takeAssessmentData?.nomineeApproveCount + '/' + takeAssessmentData.nomineeTotalCount
													} Assessed`}
													icon={''}
												/>
											</Box>
										</Box>
									</Box>
									<Box className='box-setting-des'>
										<Typography variant='h6' className='font-weight-normal'>
											{t('performsubText', {
												performsubText: teamAssessmentResponse?.myTeamsAssessment?.threeSixtyPercentage,
											})}
										</Typography>
									</Box>
									<Box
										className={`box-content ${takeAssessmentData?.isThreeSixtyAssessmentALert && 'alert-box-content'}`}
									>
										<Typography variant='h5'>
											<Tooltip title={'Today is the last day to complete assessment'} arrow>
												<Box>{takeAssessmentData?.isThreeSixtyAssessmentALert && <AlertInfoIcon />}</Box>
											</Tooltip>
											<span dangerouslySetInnerHTML={{ __html: t('assessment360degree') }}></span>
										</Typography>
										<Typography variant='h6'>
											{getMonthDateYearTime(teamAssessmentResponse?.myTeamsAssessment?.threeSixtyStartDate)} to{' '}
											{getMonthDateYearTime(teamAssessmentResponse?.myTeamsAssessment?.threeSixtyEndDate)}
										</Typography>
									</Box>
								</div>
							)}
							{teamAssessmentResponse?.myTeamsAssessment?.isOkrTileDisplay && (
								<Box className='assessment-box '>
									<Box className='box-outer-head'>
										<Box className='box-head'>
											<CommittedColoredIcon />
											<Box className='box-head-right'>
												{<Typography variant='h2'>{takeAssessmentData?.okrScore}</Typography>}
											</Box>
										</Box>
									</Box>
									<Box className='box-setting-des'>
										<Typography variant='h6' className='font-weight-normal'>
											{t('performsubText', {
												performsubText: teamAssessmentResponse?.myTeamsAssessment?.okrProgressPercentage,
											})}
										</Typography>
									</Box>
									<Box className='box-content'>
										<Typography variant='h5'>{t('committedOKRs')}</Typography>
										<Typography variant='h6'></Typography>
									</Box>
								</Box>
							)}
							<Box
								className={`assessment-box assessment-box-type-2
 ${activeTile === 5 && 'active'}
 ${
		teamAssessmentResponse?.myTeamsAssessment?.isFinalRatingAssessmentPeriodOpen &&
		selectedUserAssessment?.isManagerAssessmentCompleted &&
		selectedUserAssessment?.isSelfAssessmentCompleted
			? 'enable-box'
			: ''
 } `}
								onClick={(e: any) => {
									if (
										teamAssessmentResponse?.myTeamsAssessment?.isFinalRatingAssessmentPeriodOpen &&
										selectedUserAssessment?.isManagerAssessmentCompleted &&
										selectedUserAssessment?.isSelfAssessmentCompleted
									) {
										setActiveTileData(5);
									}
								}}
							>
								<Box className='default-final-rating'>
									<Box className='box-outer-head'>
										<Box className='box-head'>
											<FinalAssessmentIcon />
											<Box className='box-head-right'>
												<Typography variant='h2'>
													{takeAssessmentData.finalScore && assessmentType === 'FinalAssessment' ? (
														teamAssessmentResponse?.isFinalScoreVisible ? (
															finalRatingScore
														) : (
															<OKRButton
																className={`btn-link`}
																color='primary'
																text={<Typography variant='h2'>Done</Typography>}
																icon={''}
															/>
														)
													) : selectedUserAssessment?.isManagerAssessmentCompleted &&
													  selectedUserAssessment?.isSelfAssessmentCompleted &&
													  takeAssessmentData.finalScore ? (
														teamAssessmentResponse?.isFinalScoreVisible ? (
															finalRatingScore
														) : (
															<OKRButton
																className={`btn-link`}
																color='primary'
																text={<Typography variant='h2'>Done</Typography>}
																icon={''}
															/>
														)
													) : takeAssessmentData.isFinalRatingScoreVisible && takeAssessmentData.finalScore > 0 ? (
														teamAssessmentResponse?.isFinalScoreVisible ? (
															takeAssessmentData.finalScore
														) : (
															<OKRButton
																className={`btn-link`}
																color='primary'
																text={<Typography variant='h2'>Done</Typography>}
																icon={''}
															/>
														)
													) : (
														<Typography variant='h2' className='blank-text'>
															--
														</Typography>
													)}
												</Typography>
												{assessmentType === 'FinalAssessment' ? (
													finalRating && teamAssessmentResponse?.isFinalScoreVisible ? (
														finalRating
													) : (
														<Typography variant='h6' className='overall-txt'>
															{takeAssessmentData.finalRating && teamAssessmentResponse?.isFinalScoreVisible
																? takeAssessmentData.finalRating
																: ''}
														</Typography>
													)
												) : takeAssessmentData.isFinalRatingScoreVisible ? (
													<Typography variant='h6' className='overall-txt'>
														{teamAssessmentResponse?.isFinalScoreVisible ? takeAssessmentData.finalRating : ''}
													</Typography>
												) : (
													<Typography variant='h6' className='overall-txt'></Typography>
												)}
											</Box>
										</Box>
									</Box>
									<Box className='box-setting-des'></Box>

									<Box className={`box-content ${takeAssessmentData?.isFinalRatingAlert && 'alert-box-content'}`}>
										<Typography variant='h5'>
											<Tooltip title={'Today is the last day to complete assessment'} arrow>
												<Box>{takeAssessmentData?.isFinalRatingAlert && <AlertInfoIcon />}</Box>
											</Tooltip>
											{t('finalRating')}
										</Typography>
										<Typography variant='h6'>
											{getMonthDateYearTime(teamAssessmentResponse?.myTeamsAssessment?.finalRatingStartDate)} to{' '}
											{getMonthDateYearTime(teamAssessmentResponse?.myTeamsAssessment?.finalRatingEndDate)}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box
							className={`user-assessment-box-area-right ${
								teamAssessmentResponse?.myTeamsAssessment?.isOneToOneReview ? '' : 'only-one-tile'
							}`}
						>
							<div className='assessment-right-box' onClick={handleSnapshot} ref={ref1}>
								<Box className='box-content'>
									<Box className='icon performance-icon'>
										<PerformanceIcon />
									</Box>
									<Typography variant='h5'>{t('performanceSnapshot')}</Typography>
								</Box>
							</div>
							{teamAssessmentResponse?.myTeamsAssessment?.isOneToOneReview && (
								<div className='assessment-right-box' onClick={handleOneOnOneRedirection} ref={ref2}>
									<Box className='box-content'>
										<Box className='icon request-1on1-icon'>
											<RequestOneOnOneIcon2 />
										</Box>
										<Typography variant='h5'>{t('requestOnetoOne')}</Typography>
									</Box>
								</div>
							)}
						</Box>
					</Box>
				)}
			</Box>
			{teamAssessmentResponse && (
				<Box className='mid-year-review-info'>
					{tenantData && tenantData.domainName === InfoprolearningDomainName ? (
						<>
							<Typography variant='h6' className='font-weight-normal'>
								{t('Mid-Year Development Review Assessment Period: ')}
								<strong>{t('January 1 to June 30.')}</strong>
							</Typography>
						</>
					) : (
						<>
							<Typography variant='h6' className='font-weight-normal'>
								{t('scoreInfo1')}
								{teamAssessmentResponse?.myTeamsAssessment?.scoreCalculatedUpon}
							</Typography>
							<Typography variant='h6' className='font-weight-normal'>
								{t('scoreInfo2')}
							</Typography>
						</>
					)}
				</Box>
			)}
			{/* <UserSnapshotDrawer
 openSnapshot={openSnapshot}
 selectedUser={selectedUser}
 performanceCycle={performanceCycle}
 handleDrawerClose={handleDrawerClose}
 isTeamAssessment={props.fromTeamAssessment ? true : false}
 /> */}
			{openSnapshot ? (
				<UserSnapshot
					openSnapshot={openSnapshot}
					selectedUser={selectedUser}
					// performanceCycle={performanceCycle}
					handleDrawerClose={handleDrawerClose}
					// isTeamAssessment={props.fromTeamAssessment ? true : false}
				/>
			) : (
				<></>
			)}
		</>
	);
};
