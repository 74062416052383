import {
	Avatar,
	Box,
	Checkbox,
	Chip,
	Collapse,
	FormControlLabel,
	List,
	ListItem,
	Switch,
	Tooltip,
	Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Enums } from '../../../config/enums';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { parentTeamIcon } from '../../../config/svg/CommonSvg';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import {
	getMonthDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserName,
	groupArrayByProperty,
} from '../../../config/utils';
import { getLocalStorageItem } from '../../../services/StorageService';
import { NoRecord } from '../../Admin/CommonComponent/NoRecord';
import { NoSearchRecord } from '../../Admin/CommonComponent/NoSearchRecord';
import { CycleDropdown } from '../../Common/CycleDropdown';
import { CycleDropdownQuickLinks } from '../../Common/CycleDropdownQuickLinks';
import { OKRButton } from '../../Common/OKRButton';
import OkrNature from '../../Common/OkrNature';
import { SearchField } from '../../Common/SearchField';
import { OkrProgressBar } from '../../OkrAction/OkrProgressBar';
import NouserImg from '../../../images/search-placeholder.svg';
import '../../Goals/OkrListing/style-old.scss';
import { InformationMessage } from '../../Admin/CommonComponent/InformationMessage';

export const SOObjectiveList: React.FC<any> = (props) => {
	const {
		cycleId,
		cycleDetail,
		currentCycleYear,
		cycleDuration,
		getObjectiveListBasedOnCycleChange,
		okrMasterData,
		selectedCycleDetails,
		currentCycleSelection,
		currentCycleId,
		currentSelectedCycleYear,
		handleCycleChange,
		isCurrentCycle,
		searchText,
		setSearchText,
		myGoalList,
		setMyGoalList,
		handleObjectiveKRLinkingChange,
		getLinkedKeyResultCount,
		isLinkingTab = false,
		myGoalKRList,
		setMyGoalKRList,
		soPermissionDetails,
		isAnyItemUnlinked = false,
	} = props;
	const { t } = useTranslation();
	const selectedKRCount = getLinkedKeyResultCount(myGoalList);
	const [groupedMyGoalList, setGroupedMyGoalList] = useState<any>(null);
	const [groupedMyGoalKRList, setGroupedMyGoalKRList] = useState<any>(null);

	useEffect(() => {
		if (myGoalList && myGoalList?.length) {
			const groupedList = groupArrayByProperty(myGoalList, 'dueCycle');
			setGroupedMyGoalList(groupedList);
		} else {
			setGroupedMyGoalList(null);
		}
	}, [myGoalList]);
	useEffect(() => {
		if (myGoalKRList && myGoalKRList?.length) {
			const groupedList = groupArrayByProperty(myGoalKRList, 'dueCycle');
			setGroupedMyGoalKRList(groupedList);
		} else {
			setGroupedMyGoalKRList(null);
		}
	}, [myGoalKRList]);
	const showActionButton = (e: any, goal: any, key: any) => {
		const updatedList = myGoalList.map((item: any) => {
			if (item.goalObjectiveId === goal.goalObjectiveId) {
				if (item[key]) {
					item[key] = false;
				} else {
					item[key] = true;
				}
			}
			return item;
		});
		setMyGoalList(updatedList);
	};
	const expandKeyResult = (e: any, okr: any, expand: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		showActionButton(e, okr, expand);
	};

	const getCycleInfo = (item: any) => {
		return item?.dueCycle
			? item?.dueCycle
			: selectedCycleDetails
			? `${selectedCycleDetails?.symbol}, ${selectedCycleDetails?.year}`
			: '';
	};

	const userInfo = (item: any) => {
		return item?.firstName ? (
			<Box className='user-info'>
				<Box className='user-img'>
					<Tooltip arrow title={`${item?.firstName} ${item?.lastName}`}>
						{item?.imagePath && item?.imagePath.trim() !== '' ? (
							<Avatar className='avatar-default' src={item.imagePath} />
						) : item.firstName ? (
							<Avatar className='avatar-default'>{getUserName(item)}</Avatar>
						) : (
							<></>
						)}
					</Tooltip>
				</Box>
			</Box>
		) : (
			<></>
		);
	};
	const getListInformation = () => {
		const okrList = groupedMyGoalList && Object.keys(groupedMyGoalList)?.length ? Object.keys(groupedMyGoalList) : null;
		const krList =
			groupedMyGoalKRList && Object.keys(groupedMyGoalKRList)?.length ? Object.keys(groupedMyGoalKRList) : null;
		return okrList ? okrList : krList ? krList : null;
	};

	return (
		<Box className='so-objective-list'>
			<Box className='drawer-form-fields'>
				{/* {isLinkingTab && isAnyItemUnlinked ? (
					<>
						<InformationMessage messageText={t('soUnlinkedMessage')} icon={true} />
					</>
				) : (
					<></>
				)} */}
				{!isLinkingTab ? (
					<Box className='drawer-input-field src-okr-head-wrap'>
						<Box className='src-okr-head'>
							<Box className='selected-obj-count'>
								{Boolean(selectedKRCount) ? <Typography variant='h6'>{`${selectedKRCount}`}</Typography> : <></>}
							</Box>
							{/* <SearchField
							{...props}
							checkSelection={() => {}}
							clearSelection={() => {}}
							performSearch={() => {}}
							currentCycleSelection={currentCycleSelection}
							searchText={searchText}
							setSearchText={setSearchText}
						/> */}
							{selectedCycleDetails ? (
								<CycleDropdown
									allCycleDetails={cycleDetail}
									currentCycleId={currentCycleId}
									currentSelectedCycleYear={currentSelectedCycleYear}
									cycleDuration={cycleDuration}
									handleCycleChange={handleCycleChange}
									selectedCycleDetails={selectedCycleDetails}
									currentCycleYear={currentCycleYear}
									isCurrentCycle={isCurrentCycle}
									isCycleDisable={false}
									isSoObj={true}
								/>
							) : (
								<></>
							)}
						</Box>
					</Box>
				) : (
					<></>
				)}
				<Box className='drawer-input-field'>
					{(myGoalList && myGoalList.length) || (myGoalKRList && myGoalKRList.length) ? (
						<Box className='department-content'>
							{/* {isLinkingTab ? (
								<Box className='department-title'>
									<Typography variant='h6'>
										<Typography className='department-name' component={'span'}>{`Objectives(${myGoalList.length})`}</Typography>
									</Typography>
								</Box>
							) : (
								<></>
							)} */}
							{getListInformation() && getListInformation()?.length ? (
								getListInformation()?.map((rec: any) => {
									const items =
										myGoalList && myGoalList?.length
											? rec && groupedMyGoalList[rec]
												? groupedMyGoalList[rec]
												: null
											: null;
									const krItems =
										isLinkingTab && myGoalKRList && myGoalKRList?.length
											? rec && groupedMyGoalKRList[rec]
												? groupedMyGoalKRList[rec]
												: null
											: null;
									return (
										<>
											{isLinkingTab ? (
												<Box className='linked-okr-grouped-by'>
													<Typography variant='h4'>{rec || ''}</Typography>
													<Typography variant='h6'>
														{items ? `Objective (${items?.length})` : ''}
														{items && items?.length && krItems && krItems?.length ? ' And' : ''}
														{krItems && krItems?.length ? ` Key Result (${krItems?.length})` : ''}
													</Typography>
												</Box>
											) : (
												<></>
											)}
											{items && items?.length ? (
												items.map((obj: any) => (
													<Box className={`import-okr-list import-myokr-list ${obj.expand ? 'expanded-okr' : ''}`}>
														<Box className='okr-status-info'>
															{obj?.goalStatusId === Enums.GOAL_STATUS_DRAFT ? (
																<Chip className='draft-chip' label={t('draft')} />
															) : obj?.goalStatusId === Enums.GOAL_STATUS_ARCHIVE && obj?.isLastStatusDraft ? (
																<Chip className='draft-chip' label={t('draft')} />
															) : (
																<></>
															)}
															<OkrNature okrMasterData={okrMasterData} okr={obj} />
														</Box>
														<Box className='import-obj-row'>
															<Box className='import-obj-col'>
																<Box className='obj-title-section'>
																	<Box className='import-obj-title'>
																		<Typography variant='h4'>{obj?.objectiveName || ''}</Typography>
																	</Box>
																	<Box className='import-okr-actions'>
																		<OKRButton
																			className={obj?.expand ? 'btn-blue-chip expand' : 'btn-blue-chip collapse'}
																			icon={<RightArrowIcon />}
																			text={`${
																				obj?.linkedKrResponses?.length ? obj?.linkedKrResponses?.length : 0
																			} Key Results`}
																			handleClick={(e) => expandKeyResult(e, obj, 'expand')}
																		/>
																		<Box className='teams-info-chip'>
																			{obj.teamName && obj.teamName.length > 0 ? (
																				<Tooltip title={obj.teamName} arrow>
																					<Chip
																						className='team-avatar-smaller'
																						style={{
																							backgroundColor: getRandomBackgroundColor(obj?.backGroundColorCode),
																							color: getRandomColor(obj?.colorCode),
																						}}
																						label={getUserName({
																							firstName: '',
																							lastName: '',
																							fullName: obj.teamName || '',
																						})}
																					/>
																				</Tooltip>
																			) : (
																				<></>
																			)}
																			{obj.parentTeamDetail && obj.parentTeamDetail.teamName ? (
																				<Tooltip title={obj.parentTeamDetail.teamName} arrow>
																					<Chip
																						className='team-avatar-smaller'
																						style={{
																							backgroundColor: getRandomBackgroundColor(
																								obj.parentTeamDetail.backGroundColorCode
																							),
																							color: getRandomColor(obj.parentTeamDetail.colorCode),
																						}}
																						icon={parentTeamIcon(obj.parentTeamDetail.colorCode)}
																						label={getUserName({
																							firstName: '',
																							lastName: '',
																							fullName: obj.parentTeamDetail.teamName || '',
																						})}
																					/>
																				</Tooltip>
																			) : (
																				<></>
																			)}
																		</Box>
																		{isLinkingTab ? (
																			<Typography variant='h6' className='cycle-info'>
																				{`${getMonthDate(obj.dueDate)} (${getCycleInfo(obj)})`}
																			</Typography>
																		) : (
																			<></>
																		)}
																	</Box>
																</Box>
																{isLinkingTab ? userInfo(obj) : <></>}
															</Box>
															<Box className='import-obj-progress-col'>
																<Box className='import-progress '>
																	<OkrProgressBar
																		{...props}
																		parentOkr={[]}
																		okr={obj}
																		type={'okr'}
																		t={t}
																		showArchiveList={false}
																		updateProgress={() => {}}
																		showEditLoader={false}
																		currentUser={{}}
																		okrMasterData={okrMasterData}
																		isScoreLocked={true}
																		enqueueSnackbar={() => {}}
																		hideProgressText={true}
																	/>
																</Box>
																<Box className='link-toggle'>
																	<Tooltip
																		arrow
																		title={
																			!soPermissionDetails?.isCreatorRights
																				? ''
																				: Boolean(obj?.isSelected)
																				? t('switchToUnLink')
																				: t('switchToLink')
																		}
																	>
																		<FormControlLabel
																			control={
																				<Switch
																					disabled={!soPermissionDetails?.isCreatorRights}
																					checked={Boolean(obj?.isSelected)}
																					onChange={(event: any) =>
																						handleObjectiveKRLinkingChange(event, 'OKR', obj, null)
																					}
																				/>
																			}
																			label={''}
																			className='toggle-label toggle-no-label'
																		/>
																	</Tooltip>
																</Box>
															</Box>
														</Box>

														<Collapse
															className='department-kr-collapsible-content'
															in={obj.expand}
															timeout='auto'
															unmountOnExit
														>
															{obj?.linkedKrResponses && obj?.linkedKrResponses.length ? (
																obj?.linkedKrResponses.map((kr: any) => (
																	<Box className='import-kr-list'>
																		<Box className='import-kr-content'>
																			<Box className='import-kr-row'>
																				<Box className='import-kr-col'>
																					<Box className='kr-title-section'>
																						<Typography>{kr?.keyDescription || ''}</Typography>
																						<Box className='import-kr-actions'>
																							<OKRButton
																								title={t('weightHover')}
																								className='btn-oval-shape'
																								text={`${kr.krWeight}x`}
																								buttonType={'disableButtonTooltip'}
																								wrapClassName='btn-weight'
																								disabled={true}
																							/>
																							<Box className='teams-info-chip'>
																								{kr.teamName && kr.teamName.length > 0 ? (
																									<Tooltip title={kr.teamName} arrow>
																										<Chip
																											className='team-avatar-smaller'
																											style={{
																												backgroundColor: getRandomBackgroundColor(
																													kr?.backGroundColorCode
																												),
																												color: getRandomColor(kr?.colorCode),
																											}}
																											label={getUserName({
																												firstName: '',
																												lastName: '',
																												fullName: kr.teamName || '',
																											})}
																										/>
																									</Tooltip>
																								) : (
																									<></>
																								)}
																								{kr.parentTeamDetail && kr.parentTeamDetail.teamName ? (
																									<Tooltip title={kr.parentTeamDetail.teamName} arrow>
																										<Chip
																											className='team-avatar-smaller'
																											style={{
																												backgroundColor: getRandomBackgroundColor(
																													kr.parentTeamDetail.backGroundColorCode
																												),
																												color: getRandomColor(kr.parentTeamDetail.colorCode),
																											}}
																											icon={parentTeamIcon(kr.parentTeamDetail.colorCode)}
																											label={getUserName({
																												firstName: '',
																												lastName: '',
																												fullName: kr.parentTeamDetail.teamName || '',
																											})}
																										/>
																									</Tooltip>
																								) : (
																									<></>
																								)}
																							</Box>
																							{isLinkingTab ? (
																								<Typography variant='h6' className='cycle-info'>
																									{`${getMonthDate(kr.dueDate)} (${getCycleInfo(kr)})`}
																								</Typography>
																							) : (
																								<></>
																							)}
																						</Box>
																					</Box>
																					{isLinkingTab ? userInfo(kr) : <></>}
																				</Box>
																				<Box className='import-kr-progress-col'>
																					<Box className='import-progress import-kr-progress'>
																						<OkrProgressBar
																							{...props}
																							parentOkr={[]}
																							okr={kr}
																							type={'kr'}
																							t={t}
																							updateProgress={() => {}}
																							showEditLoader={false}
																							currentUser={{}}
																							okrMasterData={okrMasterData}
																							teamsOkr={false}
																							showArchiveList={false}
																							isScoreLocked={true}
																							enqueueSnackbar={() => {}}
																							hideProgressText={true}
																						/>
																					</Box>
																					<Box className='link-toggle'>
																						<Tooltip
																							arrow
																							title={
																								isLinkingTab
																									? ''
																									: Boolean(kr?.isSelected)
																									? t('switchToUnLink')
																									: t('switchToLink')
																							}
																						>
																							<FormControlLabel
																								control={
																									<Switch
																										checked={Boolean(kr?.isSelected)}
																										onChange={(event: any) =>
																											handleObjectiveKRLinkingChange(event, 'KR', obj, kr)
																										}
																										disabled={isLinkingTab}
																									/>
																								}
																								label={''}
																								className='toggle-label toggle-no-label'
																							/>
																						</Tooltip>
																					</Box>
																				</Box>
																			</Box>
																		</Box>
																	</Box>
																))
															) : (
																<></>
															)}
														</Collapse>
													</Box>
												))
											) : (
												<></>
											)}
											{isLinkingTab && myGoalKRList && myGoalKRList.length ? (
												<Box className='department-content so-link-kr-group'>
													<Box className={`import-okr-list import-myokr-list`}>
														{krItems && krItems?.length ? (
															krItems.map((kr: any) => (
																<Box className='import-kr-list '>
																	<Box className='import-kr-content'>
																		<Box className='import-kr-row import-kr-row-linked'>
																			<Box className='import-kr-col'>
																				<Box className='kr-title-section'>
																					<Typography>{kr?.keyDescription || ''}</Typography>
																					<Box className='import-kr-actions'>
																						<OKRButton
																							title={t('weightHover')}
																							className='btn-oval-shape'
																							text={`${kr.krWeight}x`}
																							buttonType={'disableButtonTooltip'}
																							wrapClassName='btn-weight'
																							disabled={true}
																						/>
																						<Box className='teams-info-chip'>
																							{kr.teamName && kr.teamName.length > 0 ? (
																								<Tooltip title={kr.teamName} arrow>
																									<Chip
																										className='team-avatar-smaller'
																										style={{
																											backgroundColor: getRandomBackgroundColor(
																												kr?.backGroundColorCode
																											),
																											color: getRandomColor(kr?.colorCode),
																										}}
																										label={getUserName({
																											firstName: '',
																											lastName: '',
																											fullName: kr.teamName || '',
																										})}
																									/>
																								</Tooltip>
																							) : (
																								<></>
																							)}
																							{kr.parentTeamDetail && kr.parentTeamDetail.teamName ? (
																								<Tooltip title={kr.parentTeamDetail.teamName} arrow>
																									<Chip
																										className='team-avatar-smaller'
																										style={{
																											backgroundColor: getRandomBackgroundColor(
																												kr.parentTeamDetail.backGroundColorCode
																											),
																											color: getRandomColor(kr.parentTeamDetail.colorCode),
																										}}
																										icon={parentTeamIcon(kr.parentTeamDetail.colorCode)}
																										label={getUserName({
																											firstName: '',
																											lastName: '',
																											fullName: kr.parentTeamDetail.teamName || '',
																										})}
																									/>
																								</Tooltip>
																							) : (
																								<></>
																							)}
																						</Box>
																						{isLinkingTab ? (
																							<Typography variant='h6' className='cycle-info'>
																								{`${getMonthDate(kr.dueDate)} (${getCycleInfo(kr)})`}
																							</Typography>
																						) : (
																							<></>
																						)}
																					</Box>
																				</Box>
																				{isLinkingTab ? userInfo(kr) : <></>}
																			</Box>
																			<Box className='import-kr-progress-col'>
																				<Box className='import-progress import-kr-progress'>
																					<OkrProgressBar
																						{...props}
																						parentOkr={[]}
																						okr={kr}
																						type={'kr'}
																						t={t}
																						updateProgress={() => {}}
																						showEditLoader={false}
																						currentUser={{}}
																						okrMasterData={okrMasterData}
																						teamsOkr={false}
																						showArchiveList={false}
																						isScoreLocked={true}
																						enqueueSnackbar={() => {}}
																						hideProgressText={true}
																					/>
																				</Box>
																				<Box className='link-toggle'>
																					<Tooltip
																						arrow
																						title={
																							!soPermissionDetails?.isCreatorRights
																								? ''
																								: Boolean(kr?.isSelected)
																								? t('switchToUnLink')
																								: t('switchToLink')
																						}
																					>
																						<FormControlLabel
																							control={
																								<Switch
																									disabled={!soPermissionDetails?.isCreatorRights}
																									checked={Boolean(kr?.isSelected)}
																									onChange={(event: any) =>
																										handleObjectiveKRLinkingChange(event, 'KR', kr?.okrDetails, kr)
																									}
																								/>
																							}
																							label={''}
																							className='toggle-label toggle-no-label'
																						/>
																					</Tooltip>
																				</Box>
																			</Box>
																		</Box>
																	</Box>
																</Box>
															))
														) : (
															<></>
														)}
													</Box>
												</Box>
											) : (
												<></>
											)}
										</>
									);
								})
							) : (
								<></>
							)}
						</Box>
					) : (
						<></>
					)}

					{isLinkingTab && myGoalKRList && myGoalKRList.length ? (
						<Box className='department-content so-link-kr-group'>
							<Box className={`import-okr-list import-myokr-list `}></Box>
						</Box>
					) : (
						<></>
					)}

					{!myGoalList ? (
						<Box className='loading-center'>
							<Typography>{t('loading')}</Typography>
						</Box>
					) : myGoalList && myGoalList.length === 0 ? (
						!isLinkingTab ? (
							<NoRecord noRecordMessage={t('NoRecord')} subHead={' '} noImg={NouserImg} />
						) : (
							<>
								{myGoalKRList && myGoalKRList.length === 0 ? (
									<NoRecord noRecordMessage={t('linkSoHead')} subHead={t('linkSoSubHead')} />
								) : (
									<></>
								)}
							</>
						)
					) : (
						<></>
					)}
				</Box>
			</Box>
		</Box>
	);
};
