import React from 'react';
import { Avatar, Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import noRecordFound from '../../images/search-placeholder.svg';
import { useTranslation } from 'react-i18next';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import { AddIconSvg } from '../../config/svg/CommonSvg';
import { OKRButton } from '../Common/OKRButton';
import { NewFilterIcon } from '../../config/svg/CommonSvgIcon';
import { DownArrowIcon } from '../../config/svg/ArrowSvg';

export const NoListFound: React.FC<any> = (props) => {
	const { showSkeleton } = props;
	const { t } = useTranslation();
	return (
		<Box className='my-obj-listing pad-container'>
			{showSkeleton ? (
				<Box className='my-obj-listing-content no-okr-listing'>
					<Box className='okr-listing-head '>
						<Box className='okr-listing-head-left'>
							<Box className='expand-all'>
								<Typography className='sort-obj-title' variant='h2'>
									{t('OkrLabel')}
								</Typography>
							</Box>
						</Box>
						<Box className='okr-listing-head-right'>
							<OKRButton
								className={`list-filter-btn`}
								icon={<NewFilterIcon />}
								text={
									<span className='filter-txt'>
										{t('filterLabel')}
										{<DownArrowIcon />}
									</span>
								}
								id='dashboard-filter-btn'
							/>

							<Button className={'add-btn'} color='primary' id='cokr-bn'>
								<AddIconSvg />
							</Button>
						</Box>
					</Box>
					<Box className='my-obj-listing-inner'>
						<Box className='okr-head'>
							<List component='ul'>
								<ListItem className='okr-col1 okr-drag-wrap'>
									<Box className='okr-objective'>
										<Skeleton width='85%' animation={false}>
											<Typography>.</Typography>
										</Skeleton>
										<Skeleton width='50%' animation={false}>
											<Typography>.</Typography>
										</Skeleton>
										<Box className='okr-objective-bottom'>
											<Skeleton width='10%' animation={false}>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton width='5%' animation={false}>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton width='10%' animation={false}>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
									</Box>
								</ListItem>
								<ListItem className='okr-col2'>
									<Box className='okr-list-progressbar'>
										<Box className='okr-list-progressbar-inner'>
											<Box className='okr-list-progress-head'>
												<Box className='contributor-avatar-section'>
													<Skeleton variant='circle' animation={false}>
														<Avatar className='contributor-owner' />
													</Skeleton>
												</Box>
												<Box className='progress-bar-section'>
													<Box className='progress-slider'>
														<Skeleton width='100%' animation={false}>
															<Typography>.</Typography>
														</Skeleton>
													</Box>
													<Box className='progress-time'>
														<Skeleton variant='circle' animation={false}>
															<Avatar />
														</Skeleton>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
									{/* <Box className='contributors'>
										<Box className='contributors-avatar-group'>
											<Skeleton variant='circle' animation={false}>
												<Avatar className='contributor-owner' />
											</Skeleton>
											<AvatarGroup max={3} className='contributor-list'>
												<Skeleton variant='circle' animation={false}>
													<Avatar />
												</Skeleton>
												<Skeleton variant='circle' animation={false}>
													<Avatar />
												</Skeleton>
												<Skeleton variant='circle' animation={false}>
													<Avatar />
												</Skeleton>
											</AvatarGroup>
										</Box>
										<Skeleton variant='circle' width={30} height={30} animation={false}>
											<Button className='add-btn2'></Button>
										</Skeleton>
									</Box> */}
								</ListItem>
							</List>
						</Box>
					</Box>
				</Box>
			) : (
				<Box display='flex' justifyContent='center'>
					<Box className='no-record-message' textAlign='center'>
						<Typography variant='h2' component='h2'>
							{t('NoRecord')}
						</Typography>
						<Typography>{t('allDone')}</Typography>
						{/* <Typography>{t('noOkr')}</Typography> */}
						<AnimatedIcon
							className='no-record-img'
							width='500'
							type='image/svg+xml'
							alt={t('globalSearchNoRecord')}
							imagePath={noRecordFound}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};
