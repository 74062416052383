import React, { useState, useEffect } from 'react';
import { Box, Typography, Link, ListItem, ListItemText, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../../../Common/OKRButton';
import { BottomArrowIcon, RadioTypeIcon, StarTypeIcon, SliderIcon } from '../../../../../config/svg/CommonSvgIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getLabelDescNumber } from '../../../../../config/utils';

export const ScaleNameDropdown: React.FC<any> = (props: any) => {
	const { getScaleListByType, performanceScaleId, handleScaleNameChange, getSelectedScaleDetails } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};

	return (
		<Box className={`okr-team-dropdown scale-type-dropdown  ${open ? 'active' : ''} `}>
			<OKRButton
				className={'dropdown-button'}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				text={getSelectedScaleDetails(performanceScaleId) || ''}
				title={getSelectedScaleDetails(performanceScaleId) || ''}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<Box className='dropdown-multi-list-sub-panel'>
								<List component='div' disablePadding>
									{getScaleListByType().map((item: any) => (
										<ListItem
											component='div'
											onClick={(e: any) => {
												handleScaleNameChange(e, item);
												handleClickAway();
											}}
											className={item?.performanceScaleId === performanceScaleId ? 'active' : ''}
										>
											<ListItemText>{`${item?.scaleName || ''} ${getLabelDescNumber(item)}`}</ListItemText>
										</ListItem>
									))}
								</List>
							</Box>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
