import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { LOGIN_INITIATOR_URL } from '../../config/app-url';

export default function Logout() {
	return (
		<section className='sign-in-area'>
			<Typography style={{ fontSize: '24px' }}>You have successfully logged out.</Typography>
			<Button
				style={{ marginTop: '20px' }}
				type='button'
				className='btn-primary login-btn'
				onClick={() => {
					window.location = LOGIN_INITIATOR_URL;
				}}
			>
				Click here to login
			</Button>
		</section>
	);
}
