import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { GenerateOkrHead } from './GenerateOkrHead';
import { GenerateOkrList } from './GenerateOkrList';
import noRecordFound from '../../../../images/ChatLoader.svg';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import { useDispatch } from 'react-redux';
import { aiAddKeyResult, aiAddObjective, aiOkrData, generateMoreOkrData, getAiFilter } from '../../../../action/OkrAi';
import { Enums } from '../../../../config/enums';
import { getUserDetails } from '../../../../config/utils';
import noGenerateOkr from '../../../../images/copy-import-previous-goal.svg';
import noFound from '../../../../images/search-placeholder.svg';

export const GenerateOKRs: React.FC<any> = (props) => {
	const { mainTabSelected } = props;
	const [advanceSearchData, setAdvanceSearchData] = React.useState<any>({
		department: '',
		designation: '',
		industry: '',
		soDetails: [],
		selectedSo: {},
		userInput: '',
	});
	const [pageNumbers, setPageNumbers] = useState<any[]>([]);
	const pageSize = 5;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [okrListData, setOkrListData] = useState<any[]>([]);
	const [generateDisabled, setGenerateDisabled] = useState<boolean>(false);
	const [isListApiPending, setIsListApiPending] = useState<boolean>(true);
	const [moreOkrApiPending, setMoreOkrApiPending] = useState<string>('');
	const [objectiveToObjectiveIdMapping, setObjectiveToObjectiveIdMapping] = useState<any>({});
	const [krIdMapping, setKrIdMappingMapping] = useState<any>({});
	const [recommendationId, setRecommendationId] = useState<number>(0);
	const [toggleDisabled, setToggleDisabled] = useState<boolean>(false);
	const [isBlankScreen, setIsBlankScreen] = useState<boolean>(true);

	useEffect(() => {
		setIsBlankScreen(true);
		loadSearchCriteria();
	}, [mainTabSelected]);

	const loadSearchCriteria = async () => {
		let response: any = await dispatch(getAiFilter(''));
		if (response.data.status === Enums.STATUS_SUCCESS) {
			let selectedSo: any[] = response.data.entity.soDetails.filter((item: any) => item.isSelected);
			setAdvanceSearchData({
				...response.data.entity,
				selectedSo: selectedSo.length > 0 ? selectedSo[0] : {},
			});
			//getTeamDetailsRecognition(response.data.entity);
		}
	};

	const getTeamDetailsRecognition = async (apiData: any) => {
		setIsListApiPending(true);
		setIsBlankScreen(false);
		setGenerateDisabled(true);
		let data: any = {
			sectionName: apiData.userInput ? apiData.userInput : '',
			userInput: apiData.userInput ? apiData.userInput : '',
			designation: apiData.designation,
			department: apiData.department,
			industry: apiData.industry,
			so: apiData.so ? apiData.so : '',
			objective: '',
			recommendationType: 1,
			isUserGeneration: apiData.isUserGeneration ? true : false,
			recommendationId: recommendationId,
		};
		const response: any = await dispatch(aiOkrData(data));
		if (Boolean(response) && response.data.status === 200) {
			setOkrListData(response.data.entity.sectionDatas);
			setPageNumbers(response.data?.entity?.sectionDatas?.map(() => 1));
			setGenerateDisabled(false);
			setIsListApiPending(false);
			setRecommendationId(response.data.entity.recommendationId);
		} else {
			setIsListApiPending(false);
			setGenerateDisabled(false);
		}
	};

	const generateOkr = async () => {
		setGenerateDisabled(true);
		let generateOkrData = { ...advanceSearchData, isUserGeneration: true };
		if (advanceSearchData?.selectedSo?.soDetailsId) {
			generateOkrData.so = advanceSearchData.selectedSo.soName;
		}
		getTeamDetailsRecognition(generateOkrData);
	};

	const getMoreOkrData = async (sectionId: any) => {
		setMoreOkrApiPending(sectionId);
		let requestPayload: any = {
			sectionId: sectionId,
			recommendationId: recommendationId,
		};
		const response: any = await dispatch(generateMoreOkrData(requestPayload));
		if (Boolean(response) && response.data.status === 200) {
			let list: any[] = Object.assign([], okrListData);
			const index = list.findIndex((ele: any) => ele.sectionId === sectionId);
			list[index] = response.data.entity;
			setOkrListData(list);
			setMoreOkrApiPending('');
		} else {
			setMoreOkrApiPending('');
		}
	};
	const addObjective = async (apiData: any) => {
		let data: any = {
			objectiveName: apiData.objective,
			startDate: props.userSelectedCycle.startDate,
			endDate: props.userSelectedCycle.endDate,
			objectiveCycleId: props.userSelectedCycle.organisationCycleId,
			year: props.userSelectedCycle.year,
		};
		setToggleDisabled(true);
		const response: any = await dispatch(aiAddObjective(data));
		if (Boolean(response) && response.data.status === 200) {
			setObjectiveToObjectiveIdMapping({
				...objectiveToObjectiveIdMapping,
				[apiData.objectiveId]: response.data.entity,
			});
			setToggleDisabled(false);
		} else {
			setIsListApiPending(false);
			setToggleDisabled(false);
		}
	};
	const addKeyResult = async (apiData: any, obj: any) => {
		setToggleDisabled(true);
		let data: any = {
			objectiveName: obj.objective,
			krName: apiData.keyResult,
			startDate: props.userSelectedCycle.startDate,
			dueDate: props.userSelectedCycle.endDate,
			objectiveId: objectiveToObjectiveIdMapping[obj.objectiveId] ? objectiveToObjectiveIdMapping[obj.objectiveId] : 0,
			cycleId: props.userSelectedCycle.organisationCycleId,
			year: props.userSelectedCycle.year,
		};
		const response: any = await dispatch(aiAddKeyResult(data));
		if (Boolean(response) && response.data.status === 200) {
			setKrIdMappingMapping({
				...krIdMapping,
				[apiData.krId]: response.data.entity.goalKeyId,
			});
			setObjectiveToObjectiveIdMapping({
				...objectiveToObjectiveIdMapping,
				[obj.objectiveId]: response.data.entity.goalObjectiveId,
			});
			setToggleDisabled(false);
		} else {
			setIsListApiPending(false);
			setToggleDisabled(false);
		}
	};

	const removeObjective = async (apiData: any) => {
		let deleteDataId = objectiveToObjectiveIdMapping[apiData.objectiveId];
		if (deleteDataId) {
			const userDetail: any = getUserDetails();
			const { employeeId } = userDetail;
			const data = `employeeId=${employeeId}&goalObjectiveId=${deleteDataId}&goalType=${1}`;
			const response = await props.deleteObjective(data);
			if (Boolean(response) && response.data.status === 200) {
				let aiObjList: any = { ...objectiveToObjectiveIdMapping };
				delete aiObjList[apiData.objectiveId];
				setObjectiveToObjectiveIdMapping(aiObjList);
			} else {
				setIsListApiPending(false);
			}
		}
	};

	const removeKeyResult = async (apiData: any) => {
		let deleteDataId = krIdMapping[apiData.krId];
		if (deleteDataId) {
			const userDetail: any = getUserDetails();
			const { employeeId } = userDetail;
			const data = `employeeId=${employeeId}&goalKeyId=${deleteDataId}&isDeleteFromAI=true`;
			const response = await props.deleteContributor(data);
			if (Boolean(response) && response.data.status === 200) {
				let aiObjList: any = { ...krIdMapping };
				delete aiObjList[apiData.krId];
				setKrIdMappingMapping(aiObjList);
			} else {
				setIsListApiPending(false);
			}
		}
	};

	const handlePageChange = (newPageNumber: number, index: number) => {
		setPageNumbers(pageNumbers.map((pageNumber, i) => (i === index ? newPageNumber : pageNumber)));
	};
	return (
		<>
			<GenerateOkrHead
				{...props}
				advanceSearchData={advanceSearchData}
				setAdvanceSearchData={setAdvanceSearchData}
				generateOkr={generateOkr}
				generateDisabled={generateDisabled}
				moreOkrApiPending={moreOkrApiPending}
			/>
			{!isListApiPending ? (
				!okrListData || okrListData?.length === 0 ? (
					<></>
				) : (
					<Box className='generate-okr-note'>
						<Typography variant='h6'>{t('generateOkrNote')}</Typography>
					</Box>
				)
			) : (
				<></>
			)}
			<Box className='generate-okr-list'>
				{!isListApiPending ? (
					!okrListData || okrListData?.length === 0 ? (
						<Box className='search-no-record-global'>
							<Box className='no-record-message'>
								<Typography variant='h5' component='h5'>
									{t('aiNoResponse')}
								</Typography>

								<AnimatedIcon
									className=''
									width='230'
									type='image/svg+xml'
									alt={t('globalSearchNoRecord')}
									imagePath={noFound}
								/>
							</Box>
						</Box>
					) : (
						<GenerateOkrList
							{...props}
							okrListData={okrListData}
							getMoreOkrData={getMoreOkrData}
							pageNumbers={pageNumbers}
							pageSize={pageSize}
							handlePageChange={handlePageChange}
							moreOkrApiPending={moreOkrApiPending}
							addObjective={addObjective}
							addKeyResult={addKeyResult}
							removeObjective={removeObjective}
							removeKeyResult={removeKeyResult}
							toggleDisabled={toggleDisabled}
						/>
					)
				) : isBlankScreen ? (
					<Box className='generate-okr-message'>
						<Box className='no-record-message'>
							<Typography variant='h4'>{t('generateOkrMessage')}</Typography>
							<AnimatedIcon
								className='no-record-img'
								width='300'
								type='image/svg+xml'
								alt={t('generateOkrMessage')}
								imagePath={noGenerateOkr}
							/>
						</Box>
					</Box>
				) : (
					<Box className='generate-okr-loader'>
						<AnimatedIcon
							className=''
							width='300'
							type='image/svg+xml'
							alt={t('globalSearchNoRecord')}
							imagePath={noRecordFound}
						/>
					</Box>
				)}
			</Box>
		</>
	);
};
