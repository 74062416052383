import React, { Fragment, useState } from 'react';
import { Box, FormControlLabel, List, ListItem, ListItemText, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../../styles/pages/admin/settings/addPerformReview.scss';
import { VisibilitySettingDropdown } from './VisibilitySettingDropdown';
import { EnumPerformanceScoreVisibilitySettings, Enums } from '../../../../../config/enums';
import ReactDateRangePicker from '../../../../Common/ReactDateRangePicker';
import { formatFeedbackDate } from '../../../../../config/utils';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';

export const AssessmentSettingVisibility: React.FC<any> = (props: any) => {
	const {
		finalRatingDuration,
		setScoreVisibilityDuration,
		setIsPerformanceFormEdited,
		scoreVisibilityDuration,
		setPerformanceVisibility,
		performanceVisibility,
		performanceFormData,
		setVisibilityError,
		visibilityError,
		staticErrorObj,
		editableDetails,
		setPerformanceFormData,
	} = props;
	const { t } = useTranslation();

	const handleDropdownChange = (name: string, id: any, type: number) => {
		setIsPerformanceFormEdited(true);
		let currentIndex = -1;
		let performanceVisibilityCopy = performanceVisibility.map((item: any, index: number) => {
			if (item.assessmentType === type) {
				currentIndex = index;
			}
			return item;
		});
		if (currentIndex === -1) {
		} else {
			performanceVisibilityCopy[currentIndex][name] = id;
		}
		setPerformanceVisibility(performanceVisibilityCopy);
	};
	const isSelfRatingOn = () => {
		return performanceFormData.isSelfRatingPercentage;
	};
	const isThreeSixtyOn = () => {
		return performanceFormData.isThreeSixtyEnabled;
	};
	const getValue = (name: string, type: number) => {
		let currentIndex = 0;
		let performanceVisibilityCopy = performanceVisibility.map((item: any, index: number) => {
			if (item.assessmentType === type) {
				currentIndex = index;
			}
			return item;
		});
		return performanceVisibilityCopy[currentIndex][name];
	};
	const getNextMonthDate = (date: any) => {
		try {
			const maxDateValue = new Date(date || new Date());
			maxDateValue.setDate(maxDateValue.getDate() + 60);

			return maxDateValue;
		} catch (error) {
			console.error();
		}
	};
	return (
		<>
			<Box className='assessment-form-role-settings'>
				<Box className='okr-list-panel-head'>
					<List component='ul'>
						<ListItem className='assessment-form-role-col1'>
							<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-name'}></Typography>
						</ListItem>
						{
							<ListItem className='assessment-form-role-col2'>
								<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-self'}>
									<span className='txt-wrap'>{`${t('visibleTo')} ${t('Assessee')}`}</span>
								</Typography>
							</ListItem>
						}
						<ListItem className='assessment-form-role-col3'>
							<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-manager'}>
								<span className='txt-wrap'>{`${t('visibleTo')} ${t('managerLabel')}`}</span>
							</Typography>
						</ListItem>
						{
							<ListItem className='assessment-form-role-col4'>
								<Typography variant='body2' className={'sort-title asc'} id={'assessment-form-role-360'}>
									<span
										className='txt-wrap'
										dangerouslySetInnerHTML={{ __html: t('visibleTo') + ' ' + t('assessee360degree') }}
									></span>
								</Typography>
							</ListItem>
						}
					</List>
				</Box>
				<Box className='assessment-form-role-listing'>
					<Box className='assessment-form-role-item'>
						<List disablePadding>
							<ListItem className='assessment-form-role-col1'>
								<Box>
									<Typography>{t('selfRatingScore')}</Typography>
								</Box>
							</ListItem>
							<ListItem className='assessment-form-role-col2'>
								{isSelfRatingOn() ? (
									<VisibilitySettingDropdown
										{...props}
										handleDropdownChange={(name: string, item: any) => {
											handleDropdownChange(name, item.id, 1);
										}}
										selectedValue={getValue('selfScoreVisibilty', 1)}
										itemData={[
											{ id: EnumPerformanceScoreVisibilitySettings.Hide, label: t('hide') },
											{ id: EnumPerformanceScoreVisibilitySettings.AfterSelfRating, label: t('afterSelfRating') },
										]}
										isDisabled={editableDetails?.status === Enums.THREE}
										name={'selfScoreVisibilty'}
									/>
								) : (
									<Box>
										<Typography>{t('Input stream off')}</Typography>
									</Box>
								)}
							</ListItem>
							<ListItem className='assessment-form-role-col3'>
								{isSelfRatingOn() ? (
									<VisibilitySettingDropdown
										{...props}
										handleDropdownChange={(name: string, item: any) => {
											handleDropdownChange(name, item.id, 2);
										}}
										selectedValue={getValue('selfScoreVisibilty', 2)}
										itemData={[
											{ id: EnumPerformanceScoreVisibilitySettings.Hide, label: t('hide') },
											{ id: EnumPerformanceScoreVisibilitySettings.AfterSelfRating, label: t('afterSelfRating') },
											{ id: EnumPerformanceScoreVisibilitySettings.AfterManagerRating, label: t('afterManagerRating') },
											{
												id: EnumPerformanceScoreVisibilitySettings.AfterFinalRatingByManager,
												label: t('afterFinalManagerRating'),
											},

											{
												id: EnumPerformanceScoreVisibilitySettings.AfterFinalScorePublishes,
												label: t('afterFinalScorePublishes'),
											},
										]}
										isDisabled={editableDetails?.status === Enums.THREE}
										name={'selfScoreVisibilty'}
									/>
								) : (
									t('Input stream off')
								)}
							</ListItem>
							{isThreeSixtyOn() ? (
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{isSelfRatingOn() ? t('hide') : t('Input stream off')}</Typography>
									</Box>
								</ListItem>
							) : (
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							)}
						</List>
					</Box>

					<Box className='assessment-form-role-item'>
						<List disablePadding>
							<ListItem className='assessment-form-role-col1'>
								<Box>
									<Typography>{t('managerRatingScore')}</Typography>
								</Box>
							</ListItem>
							<ListItem className='assessment-form-role-col2'>
								<VisibilitySettingDropdown
									{...props}
									handleDropdownChange={(name: string, item: any) => {
										handleDropdownChange(name, item.id, 1);
									}}
									selectedValue={getValue('managerScoreVisibilty', 1)}
									itemData={[
										{ id: EnumPerformanceScoreVisibilitySettings.Hide, label: t('hide') },
										{ id: EnumPerformanceScoreVisibilitySettings.AfterManagerRating, label: t('afterManagerRating') },
										{
											id: EnumPerformanceScoreVisibilitySettings.AfterFinalRatingByManager,
											label: t('afterFinalManagerRating'),
										},
										{
											id: EnumPerformanceScoreVisibilitySettings.AfterFinalScorePublishes,
											label: t('afterFinalScorePublishes'),
										},
									]}
									isDisabled={editableDetails?.status === Enums.THREE}
									name={'managerScoreVisibilty'}
								/>
							</ListItem>
							<ListItem className='assessment-form-role-col3'>
								<VisibilitySettingDropdown
									{...props}
									handleDropdownChange={(name: string, item: any) => {
										handleDropdownChange(name, item.id, 2);
									}}
									selectedValue={getValue('managerScoreVisibilty', 2)}
									itemData={[
										{ id: EnumPerformanceScoreVisibilitySettings.Hide, label: t('hide') },
										{ id: EnumPerformanceScoreVisibilitySettings.AfterManagerRating, label: t('afterManagerRating') },
									]}
									isDisabled={editableDetails?.status === Enums.THREE}
									name={'managerScoreVisibilty'}
								/>
							</ListItem>
							{isThreeSixtyOn() ? (
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							) : (
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							)}
						</List>
					</Box>

					<Box className='assessment-form-role-item'>
						<List disablePadding>
							<ListItem className='assessment-form-role-col1'>
								<Box>
									<Typography dangerouslySetInnerHTML={{ __html: t('threeSixtyRatingScore') }}></Typography>
								</Box>
							</ListItem>
							<ListItem className='assessment-form-role-col2'>
								{isThreeSixtyOn() ? (
									<VisibilitySettingDropdown
										{...props}
										handleDropdownChange={(name: string, item: any) => {
											handleDropdownChange(name, item.id, 1);
										}}
										selectedValue={getValue('threeSixtyScoreVisibilty', 1)}
										itemData={[
											{ id: EnumPerformanceScoreVisibilitySettings.Hide, label: t('hide') },
											{ id: EnumPerformanceScoreVisibilitySettings.AfterManagerRating, label: t('afterManagerRating') },
											{ id: EnumPerformanceScoreVisibilitySettings.After360Rating, label: t('afterTSixty') },
											{
												id: EnumPerformanceScoreVisibilitySettings.AfterFinalRatingByManager,
												label: t('afterFinalManagerRating'),
											},
											{
												id: EnumPerformanceScoreVisibilitySettings.AfterFinalScorePublishes,
												label: t('afterFinalScorePublishes'),
											},
										]}
										isDisabled={editableDetails?.status === Enums.THREE}
										name={'threeSixtyScoreVisibilty'}
									/>
								) : (
									t('Input stream off')
								)}
							</ListItem>
							<ListItem className='assessment-form-role-col3'>
								{isThreeSixtyOn() ? (
									<VisibilitySettingDropdown
										{...props}
										handleDropdownChange={(name: string, item: any) => {
											handleDropdownChange(name, item.id, 2);
										}}
										selectedValue={getValue('threeSixtyScoreVisibilty', 2)}
										itemData={[
											{ id: EnumPerformanceScoreVisibilitySettings.Hide, label: t('hide') },
											{ id: EnumPerformanceScoreVisibilitySettings.AfterManagerRating, label: t('afterManagerRating') },
											{ id: EnumPerformanceScoreVisibilitySettings.After360Rating, label: t('afterTSixty') },
											{
												id: EnumPerformanceScoreVisibilitySettings.AfterFinalRatingByManager,
												label: t('afterFinalManagerRating'),
											},
											{
												id: EnumPerformanceScoreVisibilitySettings.AfterFinalScorePublishes,
												label: t('afterFinalScorePublishes'),
											},
										]}
										isDisabled={editableDetails?.status === Enums.THREE}
										name={'threeSixtyScoreVisibilty'}
									/>
								) : (
									t('Input stream off')
								)}
							</ListItem>
							<ListItem className='assessment-form-role-col4'>
								<Box>
									<Typography>
										{isThreeSixtyOn() ? (
											<VisibilitySettingDropdown
												{...props}
												handleDropdownChange={(name: string, item: any) => {
													handleDropdownChange(name, item.id, 3);
												}}
												selectedValue={getValue('threeSixtyScoreVisibilty', 3)}
												itemData={[
													{ id: EnumPerformanceScoreVisibilitySettings.Hide, label: t('hide') },
													{ id: EnumPerformanceScoreVisibilitySettings.After360Rating, label: t('afterTSixty') },
												]}
												isDisabled={editableDetails?.status === Enums.THREE}
												name={'threeSixtyScoreVisibilty'}
											/>
										) : (
											t('hide')
										)}
									</Typography>
								</Box>
							</ListItem>
						</List>
					</Box>

					<Box className='assessment-form-role-item'>
						<List disablePadding>
							<ListItem className='assessment-form-role-col1'>
								<Box className='required-field'>
									<Typography>
										{t('finalRatingScore')}
										<sup>*</sup>
									</Typography>
								</Box>
							</ListItem>
							{
								<ListItem className='assessment-form-role-col2'>
									<Box
										className={`drawer-date-field ${
											visibilityError?.type === 'assessmentVisibility' && visibilityError?.error
												? 'helperText-bottom custom-error-field'
												: ''
										}`}
									>
										<ReactDateRangePicker
											type={'ContributorList'}
											startDateValue={getValue('finalScoreVisibleFrom', 1)}
											selectedCycleDetails={{}}
											minimumDate={
												finalRatingDuration && finalRatingDuration.start ? new Date(finalRatingDuration.start) : new Date()
											}
											maximumDate={getNextMonthDate(
												finalRatingDuration && finalRatingDuration.end ? new Date(finalRatingDuration.end) : new Date()
											)}
											handleDateSelection={(
												range: any,
												start: any,
												end: any,
												selectionType: any,
												isCycleChanged: any
											) => {
												if (range) {
													//setSelfRatingDurationError(staticErrorObj);
													setScoreVisibilityDuration(range);
													setIsPerformanceFormEdited(true);
													setVisibilityError(staticErrorObj);
													//HERE
													handleDropdownChange('finalScoreVisibleFrom', formatFeedbackDate(new Date(start)), 1);
												}
											}}
											isDateRangeTextFieldVisible={true}
											isOnlyDueDateVisible={false}
											allCycleDetails={[]}
											isCycleVisible={false}
											isEndOfMonthVisible={false}
											numberOfCalendars={Enums.ONE}
											selectionType={'single'}
											selectedDateRange={scoreVisibilityDuration}
											setSelectedDateRange={setScoreVisibilityDuration}
											placeholderText={t('selectedDate')}
											isDatePickerDisable={finalRatingDuration === null || editableDetails?.status === Enums.THREE}
										/>
										{visibilityError?.type === 'assessmentVisibility' && visibilityError?.helperText ? (
											<Typography className='error-field'>
												<ErrorIcon /> {'Select a publish date'}
											</Typography>
										) : (
											<></>
										)}
									</Box>
								</ListItem>
							}
							<ListItem className='assessment-form-role-col3'>
								<Box>
									<Typography>{t('afterFinalManagerRating')}</Typography>
								</Box>
							</ListItem>
							{
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							}
						</List>
					</Box>
					<Box className='assessment-form-role-item'>
						<List disablePadding>
							<ListItem className='assessment-form-role-col1'>
								<Box className='required-field '>
									<Typography>{t('visibilityRemarksToDr')}</Typography>
								</Box>
							</ListItem>
							{
								<ListItem className='assessment-form-role-col2'>
									<VisibilitySettingDropdown
										{...props}
										handleDropdownChange={(name: string, item: any) => {
											handleDropdownChange(name, item.id, 1);
										}}
										selectedValue={getValue('finalRatingCommentsVisiblity', 1)}
										itemData={[
											{ id: EnumPerformanceScoreVisibilitySettings.Hide, label: t('hide') },
											{ id: 7, label: t('Visible') },
										]}
										isDisabled={editableDetails?.status === Enums.THREE}
										name={'finalRatingCommentsVisiblity'}
									/>
								</ListItem>
							}
							<ListItem className='assessment-form-role-col3'>
								<Box>
									<Typography>{t('afterFinalManagerRating')}</Typography>
								</Box>
							</ListItem>
							{
								<ListItem className='assessment-form-role-col4'>
									<Box>
										<Typography>{t('hide')}</Typography>
									</Box>
								</ListItem>
							}
						</List>
						<FormControlLabel
							className='final-rt-comment-lbl'
							control={
								<Switch
									name='isFinalCommentMandatory'
									checked={performanceFormData.isFinalCommentMandatory}
									onChange={(event: any) => {
										setPerformanceFormData({ ...performanceFormData, isFinalCommentMandatory: event.target.checked });
									}}
								/>
							}
							label={t('isRemarksMandatory')}
							disabled={editableDetails?.status === Enums.THREE}
						/>
					</Box>
				</Box>
				<Box className='show-rating-field'>
					<FormControlLabel
						className='show-rating-switch-btn'
						control={
							<Switch
								name='showRatingField'
								checked={performanceFormData.isShowFinalRatingField}
								onChange={(event: any) => {
									setPerformanceFormData({ ...performanceFormData, isShowFinalRatingField: event.target.checked });
								}}
							/>
						}
						label={t('showRatingField')}
						disabled={editableDetails?.status === 2 || editableDetails?.status === 3}
						/>
				</Box>
			</Box>
		</>
	);
};
