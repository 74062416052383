import React from 'react';

export const DownArrowIcon = () => (
	<svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M11.1515 1.80276C11.4503 1.5159 11.46 1.04113 11.1731 0.74232C10.8863 0.443512 10.4115 0.433823 10.1127 0.720679L11.1515 1.80276ZM6.13208 5.58172L5.61268 6.12276C5.9029 6.40137 6.36126 6.40137 6.65148 6.12276L6.13208 5.58172ZM2.15148 0.720678C1.85267 0.433822 1.3779 0.443511 1.09104 0.74232C0.804183 1.04113 0.813873 1.5159 1.11268 1.80276L2.15148 0.720678ZM10.1127 0.720679L5.61268 5.04068L6.65148 6.12276L11.1515 1.80276L10.1127 0.720679ZM6.65148 5.04068L2.15148 0.720678L1.11268 1.80276L5.61268 6.12276L6.65148 5.04068Z'
			fill='#292929'
		/>
	</svg>
);
export const UpArrowIcon = () => (
	<svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1.14076 4.58884C0.841949 4.8757 0.832259 5.35047 1.11912 5.64928C1.40597 5.94809 1.88075 5.95778 2.17955 5.67092L1.14076 4.58884ZM6.16016 0.809884L6.67955 0.268844C6.38933 -0.00976896 5.93098 -0.00976896 5.64076 0.268844L6.16016 0.809884ZM10.1408 5.67092C10.4396 5.95778 10.9143 5.94809 11.2012 5.64928C11.4881 5.35047 11.4784 4.8757 11.1796 4.58884L10.1408 5.67092ZM2.17955 5.67092L6.67955 1.35092L5.64076 0.268844L1.14076 4.58884L2.17955 5.67092ZM5.64076 1.35092L10.1408 5.67092L11.1796 4.58884L6.67955 0.268844L5.64076 1.35092Z'
			fill='#292929'
		/>
	</svg>
);
export const RightArrowIcon = () => (
	<svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M6.77437 29.1441C6.21153 28.5362 6.24803 27.5872 6.85589 27.0244L19.8422 15L6.8559 2.97563C6.24803 2.41279 6.21153 1.46375 6.77437 0.855879C7.33721 0.248013 8.28625 0.21151 8.89412 0.774351L23.0691 13.8994C23.3757 14.1832 23.55 14.5821 23.55 15C23.55 15.4178 23.3757 15.8167 23.0691 16.1006L8.89412 29.2256C8.28625 29.7885 7.33721 29.752 6.77437 29.1441Z'
			fill='#292929'
		/>
	</svg>
);

export const TopArrowIcon = () => (
	<svg width='10' height='10' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M17.01 8.04005L31.54 21.8401C32.13 22.39 32.15 23.32 31.6 23.91C31.04 24.5 30.12 24.5201 29.53 23.9601L16 11.1201L2.47002 23.9601C1.88002 24.5201 0.96002 24.4901 0.400021 23.9101C-0.159981 23.3201 -0.129978 22.4001 0.450022 21.8401L14.99 8.04005C15.56 7.51005 16.44 7.51005 17.01 8.04005Z'
			fill='#292929'
		/>
	</svg>
);

export const LeftArrow = () => (
	<svg width='16' height='16' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M23.2256 0.855895C23.7885 1.46376 23.752 2.41281 23.1441 2.97565L10.1578 15L23.1441 27.0244C23.752 27.5872 23.7885 28.5363 23.2256 29.1441C22.6628 29.752 21.7137 29.7885 21.1059 29.2256L6.93088 16.1006C6.62428 15.8168 6.45 15.4179 6.45 15C6.45 14.5822 6.62428 14.1833 6.93088 13.8994L21.1059 0.774366C21.7137 0.211526 22.6628 0.248028 23.2256 0.855895Z'
			fill='#292929'
		/>
	</svg>
);

export const InfoArrow = () => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.04005 14.99L21.84 0.459979C22.39 -0.130021 23.32 -0.150021 23.91 0.399979C24.5 0.959979 24.52 1.87998 23.96 2.46998L11.12 16L23.96 29.53C24.52 30.12 24.49 31.04 23.91 31.6C23.32 32.16 22.4 32.13 21.84 31.55L8.04005 17.01C7.51005 16.44 7.51005 15.56 8.04005 14.99Z'
			fill='#292929'
		/>
	</svg>
);
export const DownFilledArrow = () => (
	<svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M23.1153 9.52175C23.2986 9.84426 23.2946 10.2404 23.1047 10.559L15.8859 20.4262C15.7 20.7384 15.3634 20.9297 15 20.9297C14.6366 20.9297 14.3 20.7384 14.1141 20.4262L6.8953 10.559C6.70544 10.2404 6.70138 9.84426 6.88465 9.52175C7.06793 9.19923 7.4103 9 7.78125 9L22.2187 9C22.5897 9 22.9321 9.19923 23.1153 9.52175Z'
			fill='#292929'
		/>
	</svg>
);
export const ProgressUpArrow = () => (
	<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M0.253483 6.54139C-0.48516 7.87441 0.478879 9.51074 2.00287 9.51074H7.84511C9.40579 9.51074 10.3651 7.80286 9.55304 6.47008L6.49333 1.44847C5.69502 0.138279 3.77962 0.177782 3.03601 1.51977L0.253483 6.54139Z'
			fill='#0664B1'
		/>
	</svg>
);
export const ProgressDownArrow = () => (
	<svg width='10' height='10' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M18.6038 25.4035C16.8899 27.9762 13.1101 27.9762 11.3962 25.4036L0.733711 9.3979C-1.18336 6.52016 0.879629 2.66699 4.33746 2.66699L25.6625 2.66699C29.1204 2.66699 31.1834 6.52015 29.2663 9.39789L18.6038 25.4035Z'
			fill='#292929'
		/>
	</svg>
);

export const SelectArrow = () => (
	<svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M10.7336 2.57211C11.1089 2.16693 11.0847 1.53423 10.6795 1.15894C10.2744 0.783638 9.64165 0.807863 9.26636 1.21304L10.7336 2.57211ZM5.83331 6.39104L5.09967 7.07057C5.28892 7.27489 5.55481 7.39104 5.83331 7.39104C6.11182 7.39104 6.3777 7.27489 6.56696 7.07057L5.83331 6.39104ZM2.40027 1.21304C2.02497 0.807863 1.39227 0.783638 0.987091 1.15893C0.581911 1.53423 0.557687 2.16693 0.932983 2.57211L2.40027 1.21304ZM9.26636 1.21304L5.09967 5.7115L6.56696 7.07057L10.7336 2.57211L9.26636 1.21304ZM6.56696 5.7115L2.40027 1.21304L0.932983 2.57211L5.09967 7.07057L6.56696 5.7115Z'
			fill='#292929'
		/>
	</svg>
);
export const RightArrow = () => (
	<svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M6.77437 29.1441C6.21153 28.5362 6.24803 27.5872 6.85589 27.0244L19.8422 15L6.8559 2.97563C6.24803 2.41279 6.21153 1.46375 6.77437 0.855879C7.33721 0.248013 8.28625 0.21151 8.89412 0.774351L23.0691 13.8994C23.3757 14.1832 23.55 14.5821 23.55 15C23.55 15.4178 23.3757 15.8167 23.0691 16.1006L8.89412 29.2256C8.28625 29.7885 7.33721 29.752 6.77437 29.1441Z'
			fill='#292929'
		/>
	</svg>
);
export const ForwardArrow = () => (
	<svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M53.4615 61.0769L75 39.5385M75 39.5385L53.4615 18M75 39.5385H5'
			stroke='#626262'
			stroke-width='8'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);
export const ReplyArrow = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M7.97314 4.03967C7.38363 3.45016 6.42783 3.45016 5.83832 4.03967L0.442135 9.43585C-0.147378 10.0254 -0.147378 10.9812 0.442135 11.5707L5.83832 16.9669C6.42783 17.5564 7.38363 17.5564 7.97314 16.9669C8.56266 16.3773 8.56266 15.4215 7.97314 14.832L5.15392 12.0128H15.8994C17.7279 12.0128 20.4334 12.0426 22.7066 13.558C24.8554 14.9905 26.9809 18.0246 26.9809 24.8931C26.9809 25.7268 27.6568 26.4026 28.4905 26.4026C29.3242 26.4026 30 25.7268 30 24.8931C30 17.3718 27.6287 13.2109 24.3813 11.046C21.3011 8.99249 17.7666 8.9933 15.9731 8.9937C15.9482 8.99371 15.9236 8.99372 15.8994 8.99372H5.15392L7.97314 6.17449C8.56266 5.58498 8.56266 4.62919 7.97314 4.03967Z'
			fill='#292929'
		/>
	</svg>
);
export const AlignNewArrow = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M30 23.5042C30 24.9056 28.8639 26.0417 27.4626 26.0417L17.7357 26.0417C11.663 26.0417 6.74009 21.1188 6.74009 15.0461L6.74009 7.85666C6.74009 6.45527 7.87614 5.31922 9.27753 5.31922C10.6789 5.31922 11.815 6.45527 11.815 7.85666L11.815 15.0461C11.815 18.316 14.4658 20.9668 17.7357 20.9668L27.4626 20.9668C28.8639 20.9668 30 22.1028 30 23.5042Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M0.830316 11.1432C-0.160618 10.1523 -0.160618 8.54569 0.830316 7.55475L7.54015 0.844917C8.53108 -0.146017 10.1377 -0.146017 11.1286 0.844917L17.8385 7.55475C18.8294 8.54569 18.8294 10.1523 17.8385 11.1432C16.8475 12.1342 15.2409 12.1342 14.25 11.1432L9.33439 6.22765L4.4188 11.1432C3.42787 12.1342 1.82125 12.1342 0.830316 11.1432Z'
			fill='#292929'
		/>
	</svg>
);
export const SortingUpArrow = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M7.20705 0.75C7.60488 0.75 7.98641 0.908035 8.26771 1.18934L13.5998 6.52139C14.1856 7.10718 14.1856 8.05693 13.5998 8.64272C13.014 9.2285 12.0642 9.2285 11.4784 8.64271L8.70705 5.87132V28.0216C8.70705 28.85 8.03548 29.5216 7.20705 29.5216C6.37863 29.5216 5.70705 28.85 5.70705 28.0216V5.87132L2.93566 8.64271C2.34987 9.2285 1.40013 9.2285 0.81434 8.64272C0.228553 8.05693 0.228553 7.10718 0.81434 6.52139L6.14639 1.18934C6.4277 0.908035 6.80923 0.75 7.20705 0.75ZM15.9609 8.53886C15.9609 7.71044 16.6325 7.03886 17.4609 7.03886H28.125C28.9534 7.03886 29.625 7.71044 29.625 8.53886C29.625 9.36729 28.9534 10.0389 28.125 10.0389H17.4609C16.6325 10.0389 15.9609 9.36729 15.9609 8.53886ZM15.9609 15.6483C15.9609 14.8198 16.6325 14.1483 17.4609 14.1483H24.5703C25.3987 14.1483 26.0703 14.8198 26.0703 15.6483C26.0703 16.4767 25.3987 17.1483 24.5703 17.1483H17.4609C16.6325 17.1483 15.9609 16.4767 15.9609 15.6483ZM15.9609 22.7577C15.9609 21.9292 16.6325 21.2577 17.4609 21.2577H21.0156C21.844 21.2577 22.5156 21.9292 22.5156 22.7577C22.5156 23.5861 21.844 24.2577 21.0156 24.2577H17.4609C16.6325 24.2577 15.9609 23.5861 15.9609 22.7577Z'
			fill='#292929'
		/>
	</svg>
);
export const SortingDownArrow = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M7.20706 29.25C7.60488 29.25 7.98641 29.092 8.26771 28.8107L13.5998 23.4786C14.1856 22.8928 14.1856 21.9431 13.5998 21.3573C13.014 20.7715 12.0642 20.7715 11.4784 21.3573L8.70705 24.1287V1.9784C8.70705 1.14997 8.03548 0.478403 7.20706 0.478403C6.37863 0.478403 5.70705 1.14997 5.70705 1.9784V24.1287L2.93566 21.3573C2.34987 20.7715 1.40013 20.7715 0.81434 21.3573C0.228553 21.9431 0.228553 22.8928 0.81434 23.4786L6.14639 28.8107C6.4277 29.092 6.80923 29.25 7.20706 29.25ZM15.9609 21.4611C15.9609 22.2896 16.6325 22.9611 17.4609 22.9611H28.125C28.9534 22.9611 29.625 22.2896 29.625 21.4611C29.625 20.6327 28.9534 19.9611 28.125 19.9611H17.4609C16.6325 19.9611 15.9609 20.6327 15.9609 21.4611ZM15.9609 14.3517C15.9609 15.1802 16.6325 15.8517 17.4609 15.8517H24.5703C25.3987 15.8517 26.0703 15.1802 26.0703 14.3517C26.0703 13.5233 25.3987 12.8517 24.5703 12.8517H17.4609C16.6325 12.8517 15.9609 13.5233 15.9609 14.3517ZM15.9609 7.24233C15.9609 8.07076 16.6325 8.74233 17.4609 8.74233H21.0156C21.844 8.74233 22.5156 8.07076 22.5156 7.24233C22.5156 6.4139 21.844 5.74233 21.0156 5.74233H17.4609C16.6325 5.74233 15.9609 6.4139 15.9609 7.24233Z'
			fill='#292929'
		/>
	</svg>
);
