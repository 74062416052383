import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, ClickAwayListener, Grow, Paper, Popper, Typography } from '@material-ui/core';
import '../../../styles/pages/recognize/recognition.scss';
import { OKRButton } from '../../Common/OKRButton';
import { DownFilledArrow } from '../../../config/svg/ArrowSvg';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { CancelIcon } from '../../../config/svg/Action';

export const RecognizePostBadges = (props: any) => {
	const { activeBadgeData, setActiveBadgeData, formErrors, setFormErrors } = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openBadgesList, setOpenBadgesList] = useState<boolean>(false);
	const [activeBadgeId, setActiveBadgeId] = useState<number>(0);

	const { recognizeBadges } = useSelector((state: any) => state.recognizeReducer);

	useEffect(() => {
		if (activeBadgeData && activeBadgeData.recognitionCategoryId) {
			setActiveBadgeId(activeBadgeData.recognitionCategoryId);
		}
	}, [activeBadgeData]);

	const handleBadgesListToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenBadgesList(anchorEl ? false : true);
	};
	const handleBadgesListClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setOpenBadgesList(false);
	};
	const handleBadgeSelect = (item: any) => {
		setActiveBadgeData(item);

		setFormErrors({
			...formErrors,
			badge: '',
		});
		setAnchorEl(null);
		setOpenBadgesList(false);
	};
	return (
		<>
			<Box className='recog-post-badges'>
				<Box className='recog-post-badges-left'>
					<Typography variant='h6'>{t('selectBadge')}: </Typography>
					{activeBadgeData.recognitionCategoryId ? (
						<Box className='recog-badges-btns'>
							<OKRButton
								className='badge-btn'
								handleClick={handleBadgesListToggle}
								text={activeBadgeData.name}
								icon={<img src={activeBadgeData.imageFilePath} alt='' />}
							/>
							<OKRButton
								className='cancel-btn'
								handleClick={(e) => {
									setActiveBadgeData({});
									setActiveBadgeId(0);
								}}
								icon={<CancelIcon />}
								title={t('cancelBtn')}
							/>
						</Box>
					) : (
						<Box className='recog-badges-btns' onClick={handleBadgesListToggle}>
							<OKRButton
								className='badge-btn no-badge-btn'
								text={
									<Skeleton animation={false} width={60}>
										<Typography>.</Typography>
									</Skeleton>
								}
								icon={
									<Skeleton animation={false} variant='circle' width={27} height={27}>
										<Badge
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											className={'badge-anchor-bottom'}
										></Badge>
									</Skeleton>
								}
							/>
						</Box>
					)}
					<Box className='badges-filter-dropdown'>
						<OKRButton title={t('More Badges')} icon={<DownFilledArrow />} handleClick={handleBadgesListToggle} />
					</Box>
					<Popper
						className='popper-menu-list badges-list-popper'
						open={openBadgesList}
						anchorEl={anchorEl}
						placement='bottom-start'
						transition
						modifiers={{
							preventOverflow: {
								enabled: false,
								boundariesElement: window,
							},

							flip: {
								enabled: false, // true by default
							},
						}}
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper>
									<ClickAwayListener onClickAway={handleBadgesListClose}>
										<Box className='recognize-badge-wrapper'>
											<Box className='recognize-badge-wrapper-inner'>
												{recognizeBadges &&
													recognizeBadges.length > 0 &&
													recognizeBadges.map((item: any, index: number) => {
														return (
															<Box
																className={`recognize-badge-box long-text${
																	activeBadgeId === item.recognitionCategoryId ? 'active' : ''
																}`}
																onClick={() => {
																	handleBadgeSelect(item);
																}}
															>
																<Box className='recognize-badge-icon'>
																	<Badge
																		anchorOrigin={{
																			vertical: 'bottom',
																			horizontal: 'right',
																		}}
																		className={'badge-anchor-bottom'}
																	>
																		<img src={item.imageFilePath} alt='' />
																	</Badge>
																</Box>
																<Typography variant='h6'>{item.name}</Typography>
															</Box>
														);
													})}
											</Box>
										</Box>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Box>
				<Box>
					<Typography variant='h6'>
						<em>{t('badgeMsgText')}</em>
					</Typography>
				</Box>
			</Box>
		</>
	);
};
