import {
	Avatar,
	Box,
	FormControlLabel,
	FormLabel,
	List,
	ListItem,
	RadioGroup,
	Radio,
	Tooltip,
	Typography,
	ButtonGroup,
	Tabs,
	Tab,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIconSvg } from '../../../config/svg/CommonSvg';
import { CrossIcon, SortingIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { SearchUserPopover } from '../../Common/SearchUserPopover';
import { NameIcon, ReportingToIcon } from '../../../config/svg/formElementIcons';
import { getUserDetails, getUserName } from '../../../config/utils';
import AlertDialog from '../../Common/Dialog';
import { useSnackbar } from 'notistack';
import { InformationMessage } from '../../Admin/CommonComponent/InformationMessage';
import GlobalSearch from '../../Common/GlobalSearch';

export const CheckInVisibilitySettings: React.FC<any> = (props) => {
	const {
		checkinSettingList,
		setCheckinSettingList,
		pagingInfo,
		checkinSettingData,
		deleteCheckin,
		selectedTab,
		setSelectedTab,
		setIsVisibilitySettingEdited,
		sortOrder,
		sortingColumn,
		sortUserList,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [addUserAnchorEl, setAddUserAnchorEl] = useState<HTMLElement | null>(null);
	const [addUserOpen, setAddUserOpen] = React.useState<boolean>(false);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const userDetail: any = getUserDetails();
	const [modalProps, setModalProps] = React.useState<any>({
		open: false,
		data: {},
	});
	const handleAddUsersClick = (event: any) => {
		setAddUserAnchorEl(addUserAnchorEl ? null : event.currentTarget);
		setAddUserOpen(true);
	};
	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	const onSelectedUser = async (user: any) => {
		setAddUserOpen(false);
		setAddUserAnchorEl(null);
		setCustomStyle(false);
		const index = checkinSettingList.findIndex((item: any) => item.employeeId === user[0].employeeId);
		if (index > -1) {
			enqueueSnackbar(t('addedEmployee'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
			return;
		} else {
			const response: any = await props.getCheckInAlreadyAdded(`empId=${user[0].employeeId}`);
			if (Boolean(response) && response.data.status === 200) {
				if (!response.data.entity.isAdded) {
					enqueueSnackbar(response.data.messageList.message, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				} else {
					user[0].isNew = true;
					setCheckinSettingList([...checkinSettingList, user[0]]);
					setAddUserOpen(false);
					setIsVisibilitySettingEdited(true);
				}
			} else {
				enqueueSnackbar(t('someErrorOccurred'), {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		}
	};

	const handleDeleteCheckin = (handleDeleteCheckin: any) => {
		setModalProps({
			open: true,
			data: handleDeleteCheckin,
		});
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			deleteCheckin(modalProps.data);
		}
		setModalProps({
			open: false,
			data: {},
		});
	};

	return (
		<>
			<Tabs className='okr-tabs drawer-tabs' value={0} aria-label='Check-in Tabs'>
				<Tab label={t('myCheckinsVisibility')} value={0} />
			</Tabs>

			<Box className='drawer-tabs-panel'>
				{selectedTab === 2 ? (
					<></>
				) : (
					<InformationMessage className={'information-message-user'} messageText={t('checkinTopMsg')} icon={true} />
				)}
				<Box className='drawer-form-fields checkins-drawer-setting'>
					<Box className='drawer-field-row search-field-row'>
						<GlobalSearch
							{...props}
							module={'myGoal'}
							onSelectedUser={onSelectedUser}
							fetchCycleUsers={true}
							placeHolderText={t('searchPeopleText')}
							defaultFocus={true}
							customClass={'user-quick-search-type2'}
							customId={'indv-member-src'}
						/>
						<Typography variant='h6'>{t('checkinSrcBottomText')}</Typography>
					</Box>
					{/* <Box className='drawer-field-row'>
						<Box className='okr-assign-btn-grp helperText-bottom'>
							<Box className='assigned-btn'>
								<FormLabel id='nature-of-okr' component='legend'>
									{t('myCheckinsVisibility')}
								</FormLabel>
							</Box>
							<ButtonGroup className='kr-btn-group' disableElevation variant='contained' color='primary'>
								<OKRButton
									icon={<NameIcon />}
									text={t('Visible to all')}
									id={'visible-btn'}
									className={`${selectedTab === 1 ? 'active' : ''}`}
									handleClick={() => {
										setSelectedTab(1);
									}}
								/>
								<OKRButton
									icon={<ReportingToIcon />}
									text={t('Visible to Reporting Manager')}
									className={`${selectedTab === 2 ? 'active' : ''}`}
									id={'manager-btn'}
									handleClick={() => {
										setSelectedTab(2);
									}}
								/>
							</ButtonGroup>
						</Box>
					</Box> */}
					{/* <Box className='drawer-blue-bg-full'>
						<Box className='checkins-add-member-sec'>
							<Box className='checkins-des'>
								<Typography variant='h4'>{t('checkinSettingHead')}</Typography>
								<Typography variant='h6'>{t('checkinSettingSubHead')}</Typography>
							</Box>
							<Box className='checkins-add-btn-wrap'>
								<Box className='checkins-add-btn' onClick={handleAddUsersClick}>
									<OKRButton
										id='add-member'
										className='add-btn2'
										icon={<AddIconSvg />}
										//handleClick={handleAddUsersClick}
										//disabled={isDisabled}
									/>
									<Typography variant='subtitle1'>
										<em>{t('addMembersLabel')}</em>
									</Typography>
								</Box>

								<SearchUserPopover
									{...props}
									popperAddClassName={'search-add-contributor-popup user-quick-search-popover check-ins-quick-search'}
									addContributorAnchorEl={addUserAnchorEl}
									addContributorOpen={addUserOpen}
									setAddContributorOpen={(isopen: any) => {
										setAddUserOpen(isopen);
										setCustomStyle(false);
									}}
									setAddContributorAnchorEl={setAddUserAnchorEl}
									isCustomStyle={customStyle}
									setCustomStyle={setCustomStyle}
									onSelectedUser={onSelectedUser}
									handleSearchUserInputChange={handleSearchUserInputChange}
									customId={'checkins-card-src-field'}
								/>
							</Box>
						</Box>
					</Box> */}
					<Box className='drawer-blue-bg-full'>
						<Box className='users-listing'>
							{/* <Box className='userCount'>
								<Typography variant='subtitle2'>{`(${pagingInfo.totalRecords} ${t('membersLabel')})`}</Typography>
							</Box> */}
							<Box className='okr-list-panel-head'>
								<List component='ul' disablePadding>
									<ListItem className='user-col1'>
										<Typography
											onClick={(e) => sortUserList(e, 'firstName')}
											variant='body2'
											className={sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											id={'name-sort'}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>
													{t('nameLabel')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='user-col2'>
										<Typography
											onClick={(e) => sortUserList(e, 'emailId')}
											variant='body2'
											className={sortingColumn === 'emailId' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											id={'emailid-sort'}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>
													{t('emailLabel')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
								</List>
							</Box>
							<Box className='users-list-content'>
								{checkinSettingList.map((item: any, index: number) => (
									<Box className='users-list-row' key={`selectedUser`} id={`selected-user-id`}>
										<List component='ul' disablePadding>
											<ListItem className='user-col1'>
												<Box className='user-info v-center'>
													<Box className='user-img'>
														<Avatar
															className='avatar-default'
															key={`user-icon-${item.employeeId}`}
															src={item.imagePath || ''}
														>
															{getUserName({
																firstName: item.firstName || '',
																lastName: item.lastName || '',
																fullName: item.fullName || '',
															})}
														</Avatar>
													</Box>
													<Box className='user-info-details'>
														<Box className='user-name'>
															<Tooltip arrow title={`${item.firstName} ${item.lastName}`}>
																<Typography>
																	{item.firstName} {item.lastName}
																</Typography>
															</Tooltip>

															{item.isReportingManger && (
																<Box className='owner-tag'>
																	<span className='owner-chip'>{t('reportingManager')}</span>
																</Box>
															)}
														</Box>

														{/* <Typography variant='subtitle2'>{item.designation}</Typography> */}
													</Box>
												</Box>
											</ListItem>
											<ListItem className='user-col2'>
												<Tooltip arrow title={item.emailId}>
													<Typography>{item.emailId}</Typography>
												</Tooltip>

												{userDetail?.reportingTo && index === 0 ? (
													<></>
												) : (
													<OKRButton
														handleClick={() => {
															handleDeleteCheckin(item);
														}}
														icon={<CrossIcon />}
														className={'btn-cross'}
														id={'btn-close'}
													/>
												)}
											</ListItem>
										</List>
									</Box>
								))}
							</Box>
							{pagingInfo?.totalPages > 1 && pagingInfo?.totalPages !== pagingInfo.pageIndex && (
								<List className='load-more-ul' disablePadding>
									<ListItem className='load-more'>
										<OKRButton
											handleClick={(e) => {
												checkinSettingData(pagingInfo.pageIndex + 1 || 1, 10);
											}}
											className={`load-more-btn btn-small-text`}
											text={t('loadMore')}
										/>
									</ListItem>
								</List>
							)}
						</Box>
					</Box>

					{modalProps.open && (
						<AlertDialog
							module='information'
							message={t('deleteCheckin')}
							handleCloseModal={handleDialogCancelClick}
							modalOpen={modalProps.open}
						/>
					)}
				</Box>
			</Box>
		</>
	);
};
