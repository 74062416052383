import { downloadXLSFile, getRequest, postRequest, putRequest, uploadFile } from '../config/auth';
import {
	CYCLE_DETAILS,
	IMPORT_CYCLE_DETAILS,
	GET_USER_BY_ID,
	GUIDED_TOUR,
	GUIDED_TOUR_ANY_ACTIVITY,
	IS_FEDERATION_USER,
	GET_VERSION_DETAILS,
	USER_VERSION,
	GET_RECOMMENDED_COURSES,
	DASHBOARD_WIDGET_DATA,
	DASHBOARD_FEEDBACK_REQUEST_ONE_ON_ONE,
	ASSESSMENT_QUICK_STATUS_REPORT,
	ASSESSMENT_DETAILED_STATUS_REPORT,
	USER_LIST_REPORT,
	USER_FORCE_LOGOUT,
	ONE_ON_ONE_REPORT,
	RECOGNITION_REPORT,
	CHECK_IN_REPORT,
	EXPORT_MY_OKR_REPORT,
	TENANT_DOWNTIME,
	PERFORMANCE_SNAPSHOT_ENGAGEMENT,
	PERFORMANCE_SNAPSHOT_FEEDBACK_DETAILS,
	PERFORMANCE_SNAPSHOT_PROCESS,
	DASHBOARD_ASSESSMENT_DATA,
	PERFORMANCE_GOAL_DASHBOARD_WIDGET,
} from '../config/api-url';

export const getCycleDetailsApi = (data) => getRequest(`${CYCLE_DETAILS}?${data}`, data);
export const getPreviousCycleDetailsApi = (data) => getRequest(`${IMPORT_CYCLE_DETAILS}?${data}`, data);

export const getUsersByIdAPI = (data) => getRequest(`${GET_USER_BY_ID}?${data}`, data);

/**Guided tour */
export const getGuidedTourDataApi = (data) => getRequest(`${GUIDED_TOUR}`, data);
export const getGuidedTourActivityDataApi = (data) => getRequest(`${GUIDED_TOUR_ANY_ACTIVITY}`);

export const isFederationUserApi = (emailId) => getRequest(`${IS_FEDERATION_USER}?emailId=${emailId}`);

// GET version details API
export const getVersionDetailsAPI = () => getRequest(`${GET_VERSION_DETAILS}`);

//User Version
export const getUserVersionAPI = () => getRequest(`${USER_VERSION}`);
export const updateUserVersionAPI = (data) => postRequest(`${USER_VERSION}?${data}`);
export const updateUserForceLogoutAPI = (data) => postRequest(`${USER_FORCE_LOGOUT}`, data);

export const getRecommendedCoursesApi = (data) => postRequest(`${GET_RECOMMENDED_COURSES}`, data);
export const getDashboardWidgetDataApi = (data) => getRequest(`${DASHBOARD_WIDGET_DATA}`);
export const getDashboardAssessmentDataApi = (data) => getRequest(`${DASHBOARD_ASSESSMENT_DATA}`);
export const getLandingFeedbackRequest1On1CountApi = (data) =>
	getRequest(`${DASHBOARD_FEEDBACK_REQUEST_ONE_ON_ONE}?employeeId=${data}`);
/**
 * API Performance Assessment Reports
 */
export const performanceAssessmentQuickStatusReportAPI = (data) =>
	downloadXLSFile(`${ASSESSMENT_QUICK_STATUS_REPORT}?${data}`, data);
export const performanceAssessmentDetailedStatusReportAPI = (data) =>
	downloadXLSFile(`${ASSESSMENT_DETAILED_STATUS_REPORT}?${data}`, data);

export const userListReportAPI = (data) => downloadXLSFile(`${USER_LIST_REPORT}?${data}`, data);

export const oneOnOneReportAPI = (data) => downloadXLSFile(`${ONE_ON_ONE_REPORT}?${data}`, data);
export const recognitionReportAPI = (data) => downloadXLSFile(`${RECOGNITION_REPORT}?${data}`, data);
export const checkInReportAPI = (data) => downloadXLSFile(`${CHECK_IN_REPORT}?${data}`, data);
export const exportMyOkrReportAPI = (data) => downloadXLSFile(`${EXPORT_MY_OKR_REPORT}?${data}`, data);
export const getTenantDowntimeApi = (data) => postRequest(`${TENANT_DOWNTIME}`, data);

export const getChatBotAPI = (data) => postRequest(`${'https://aiauthor-api.unlocku.com/chat'}`, data);
export const getChatHistoryApi = (data) => postRequest(`${'https://aiauthor-api.unlocku.com/chat_history'}`, data);

/**
 * User Performance Snapshot API call
 */
export const getEngagementAPI = (data) => getRequest(`${PERFORMANCE_SNAPSHOT_ENGAGEMENT}?${data}`, data);
export const getFeedbackDetailsAPI = (data) => getRequest(`${PERFORMANCE_SNAPSHOT_FEEDBACK_DETAILS}?${data}`, data);
export const getOkrProgressStatusAndPerformanceAPI = (data) =>
	getRequest(`${PERFORMANCE_SNAPSHOT_PROCESS}?${data}`, data);

// Performance Goals Dashboard Widget
export const getPerformanceGoalDashboardWidgetAPI = (data) => getRequest(`${PERFORMANCE_GOAL_DASHBOARD_WIDGET}`, data);
