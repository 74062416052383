import * as actionTypes from './actionTypes';
import { getOnboardingAPI, updateOnboardingAPI, getOnboardingCountAPI } from '../services/OnboardingService';

const fetchOnboardingPending = () => ({
	type: actionTypes.FETCH_ONBOARDING_PENDING,
});
const fetchOnboardingCompleted = (payload) => ({
	type: actionTypes.FETCH_ONBOARDING_SUCCESS,
	payload,
});
const fetchOnboardingFailed = (error) => ({
	type: actionTypes.FETCH_ONBOARDING_FAILED,
	error,
});

export const getOnboarding = (data) => {
	return (dispatch) => {
		dispatch(fetchOnboardingPending());
		return getOnboardingAPI(data)
			.then((response) => {
				dispatch(fetchOnboardingCompleted(response.data));
			})
			.catch((error) => {
				dispatch(fetchOnboardingFailed(error));
			});
	};
};
export const updateOnboarding = (data) => {
	return () => {
		return updateOnboardingAPI(data);
	};
};

export const updateOnboardingCount = (data) => {
	return {
		type: actionTypes.UPDATE_ONBOARDING_COUNT,
		data,
	};
};
export const updateCalloutPanel = (panel) => {
	return {
		type: actionTypes.UPDATE_CALLOUT_PANEL,
		payload: panel,
	};
};

const fetchOnboardingCountPending = () => ({
	type: actionTypes.ONBOARDING_COUNT_PENDING,
});
const fetchOnboardingCountCompleted = (payload) => ({
	type: actionTypes.ONBOARDING_COUNT_SUCCESS,
	payload,
});
const fetchOnboardingCountFailed = (error) => ({
	type: actionTypes.ONBOARDING_COUNT_FAILED,
	error,
});

export const getOnboardingCount = (data) => {
	return (dispatch) => {
		dispatch(fetchOnboardingCountPending());
		return getOnboardingCountAPI(data)
			.then((response) => {
				dispatch(fetchOnboardingCountCompleted(response.data));
				if (response.data.entity.employeeId) {
					dispatch(
						updateOnboardingCount({
							skipCount: response.data.entity.skipCount,
							readyCount: response.data.entity.readyCount,
						})
					);
				} else {
					dispatch(
						updateOnboardingCount({
							skipCount: 3,
							readyCount: 3,
						})
					);
				}
			})
			.catch((error) => {
				dispatch(fetchOnboardingCountFailed(error));
			});
	};
};
