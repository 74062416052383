import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Typography, InputBase } from '@material-ui/core';
import { CancelIconSvg, SearchIcon } from '../../config/svg/CommonSvg';
import { getTime } from '../../config/utils';
import { ReplyPersonalFeedback } from './ReplyPersonalFeedback';
import noRecordFound from '../../images/search-placeholder.svg';
import { AnimatedIcon } from '../Common/AnimatedIcon';

const ViewPersonalFeedback = (props) => {
	const {
		viewPersonalFeedback,
		setLoader,
		userDetail,
		currentUser,
		setFormDataUpdated,
		loader,
		feedbackData,
		peopleViewFeedback,
		isPeoplePage,
	} = props;
	const { t } = useTranslation();
	const [viewPFList, setViewPFList] = useState([]);
	const [sortFeedback, setSortFeedback] = useState(true);
	const [searchFeedback, setSearchFeedback] = useState('');
	const [expandViewFeedback, setExpandViewFeedback] = useState(null);

	useEffect(() => {
		setLoader(true);
		fetchViewFeedback({
			sort: sortFeedback,
			search: searchFeedback,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sortViewFeedbackList = (e, sort) => {
		setLoader(true);
		e.preventDefault();
		e.stopPropagation();
		setSortFeedback(sort);
		fetchViewFeedback({
			sort: sort,
			search: searchFeedback,
		});
	};

	const fetchViewFeedback = async ({ sort, search }) => {
		if (!currentUser) {
			setExpandViewFeedback(null);
			try {
				const requestPayload = {
					employeeId: userDetail.employeeId,
					sortOrder: sort,
					searchName: search,
					startDate: new Date(),
					endDate: new Date(),
					pageIndex: 1,
					pageSize: 10,
					feedbackDetailId: Boolean(feedbackData) && feedbackData.feedbackDetailId ? feedbackData.feedbackDetailId : 0,
				};
				viewPersonalFeedback(requestPayload)
					.then((response) => {
						if (response && response.data && response.data.status === 200) {
							setViewPFList(response.data.entity?.results);
							setLoader(false);

							if (Boolean(feedbackData) && feedbackData.feedbackDetailId) {
								setTimeout(() => {
									setExpandViewFeedback({ feedbackDetailId: feedbackData.feedbackDetailId });
									var element = document.getElementById('viewPF' + feedbackData.feedbackDetailId);
									if (element) {
										element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
									}
								}, 500);
							}
						} else {
							setLoader(false);
						}
					})
					.catch((error) => {
						setLoader(false);
						console.error(error);
					});
			} catch (e) {
				setLoader(false);
				console.error(e);
			}
		} else {
			fetchPeopleViewFeedback({ sort, search });
		}
	};
	const fetchPeopleViewFeedback = async ({ sort, search }) => {
		setExpandViewFeedback(null);
		try {
			peopleViewFeedback(`empId=${currentUser.employeeId}&sortOrder=${sort}&searchName=${search}`)
				.then((response) => {
					if (response && response.data && response.data.status === 200) {
						setViewPFList(response.data.entityList);
						props.setIsDownloadDisabled(response.data.entityList.length > 0);
						setLoader(false);

						if (Boolean(feedbackData) && feedbackData.feedbackDetailId) {
							setTimeout(() => {
								setExpandViewFeedback({ feedbackDetailId: feedbackData.feedbackDetailId });
								var element = document.getElementById('viewPF' + feedbackData.feedbackDetailId);
								if (element) {
									element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
								}
							}, 500);
						}
					} else {
						setLoader(false);
					}
				})
				.catch((error) => {
					setLoader(false);
					console.error(error);
				});
		} catch (e) {
			setLoader(false);
			console.error(e);
		}
	};
	/*=========== Search User ==============*/
	const handleChange = (e) => {
		const { value } = e.target;
		e.preventDefault();
		e.stopPropagation();
		if (Boolean(value) && value.trim() !== '') {
			setSearchFeedback(value);
		} else {
			setSearchFeedback(value);
			fetchViewFeedback({
				sort: sortFeedback,
				search: value.trim(),
			});
		}
	};
	const searchClicked = (e) => {
		if (e.key === 'Enter') {
			fetchViewFeedback({
				sort: sortFeedback,
				search: e.target.value.trim(),
			});
		}
	};
	/*=========== Search User end ==============*/

	const expandViewFeedbackDetails = (data) => {
		Boolean(expandViewFeedback) && expandViewFeedback.date === data.date
			? setExpandViewFeedback(null)
			: setExpandViewFeedback(data);
	};

	return (
		<Fragment>
			{/* <Box className='pf-filter-actions'>
				{(Boolean(viewPFList) && viewPFList.length > 0) || (Boolean(searchFeedback) && searchFeedback.trim()) ? (
					<Box className='search-btn'>
						<InputBase
							className={searchFeedback.trim() ? 'Mui-focused' : ''}
							placeholder={`${t('searchText')}…`}
							value={searchFeedback}
							inputProps={{ 'aria-label': t('searchText') }}
							onChange={(e) => handleChange(e)}
							onKeyDown={(e) => searchClicked(e)}
							id={'view-pf-src-field'}
						/>
						<Box className='search-icon' id='view-pf-src-icon'>
							{Boolean(searchFeedback) && searchFeedback.trim() ? (
								<Box
									style={{ transform: 'rotate(45deg)', cursor: 'pointer', zIndex: '1' }}
									onClick={(e) => {
										setSearchFeedback('');
										setSortFeedback(true);
										fetchViewFeedback({ sort: true, search: '' });
									}}
								>
									<CancelIconSvg />
								</Box>
							) : (
								<SearchIcon />
							)}
						</Box>
					</Box>
				) : (
					<></>
				)}
				{Boolean(viewPFList) && viewPFList.length > 0 && (
					<Box className='sorting-action' display='flex'>
						<Link
							id='filter-newest-viewpf'
							className={sortFeedback ? 'active' : ''}
							onClick={(e) => sortViewFeedbackList(e, true)}
						>
							Newest
						</Link>
						<Link
							id='filter-oldest-viewpf'
							className={sortFeedback ? '' : 'active'}
							onClick={(e) => sortViewFeedbackList(e, false)}
						>
							Oldest
						</Link>
					</Box>
				)}
			</Box> */}

			{Boolean(viewPFList) && viewPFList.length > 0 ? (
				viewPFList.map((data) => (
					<Box
						className='feedback-comments-area pf-comments-area'
						key={`viewPF_${data.feedbackDetailId}`}
						id={`view-pf-${data.feedbackDetailId}`}
					>
						<Box
							className={
								Boolean(expandViewFeedback) && expandViewFeedback.feedbackDetailId === data.feedbackDetailId
									? 'feedback-comments active'
									: 'feedback-comments'
							}
						>
							{Boolean(expandViewFeedback) && expandViewFeedback.feedbackDetailId === data.feedbackDetailId ? (
								<ReplyPersonalFeedback
									{...props}
									expandGivenFeedbackDetails={expandViewFeedbackDetails}
									data={data}
									userDetail={userDetail}
									formData={data}
									onChangePraiseRating={() => {}}
									onChangeGrowthOpportunityRating={() => {}}
									currentUser={currentUser}
									setFormDataUpdated={setFormDataUpdated}
									fetchGiveFeedback={() =>
										fetchViewFeedback({
											sort: sortFeedback,
											search: searchFeedback,
										})
									}
									isPeoplePage={isPeoplePage}
								/>
							) : (
								<Box
									className='comments-zoom-out'
									style={{ cursor: 'pointer' }}
									onClick={() => expandViewFeedbackDetails(data)}
								>
									<Box className='comments-top' display='flex' justifyContent='space-between'>
										<Typography variant='h6'>
											{t('giveForGivenBy')}: {data.firstName} {data.lastName}
										</Typography>
										<Typography variant='h6'>{getTime(data.date)}</Typography>
									</Box>
									<Typography>{data.sharedRemark}</Typography>
								</Box>
							)}
						</Box>
					</Box>
				))
			) : loader ? (
				<Typography></Typography>
			) : (
				<Box className='search-no-record-global' display='flex' justifyContent='center'>
					<Box className='no-record-message'>
						{(Boolean(viewPFList) && viewPFList.length > 0) || (Boolean(searchFeedback) && searchFeedback.trim()) ? (
							<Fragment>
								<Typography variant='h2' component='h2'>
									{t('CommonOOPS')}
								</Typography>
								<Typography>{t('globalSearchNoRecord')}</Typography>
							</Fragment>
						) : (
							<Typography variant='h2' component='h2'>
								{t('NoRecord')}
							</Typography>
						)}
						<AnimatedIcon
							className='no-record-img'
							width='250'
							type='image/svg+xml'
							alt={t('globalSearchNoRecord')}
							imagePath={noRecordFound}
						/>
					</Box>
				</Box>
			)}
		</Fragment>
	);
};

export default ViewPersonalFeedback;
