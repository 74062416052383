import React from 'react';
import { Box, Chip, FormControlLabel, List, ListItem, Switch, TextField, Typography } from '@material-ui/core';
import { OKRButton } from '../../../../Common/OKRButton';
import { AddIconSvg, DeleteIcon, EditIcon, TickIcon } from '../../../../../config/svg/CommonSvgIcon';
import { CancelIcon } from '../../../../../config/svg/Action';
import { Enums } from '../../../../../config/enums';

export const PipResolutionSettings: React.FC<any> = (props: any) => {
	const {
		t,
		loader,
		performancePlanStatusDetails,
		// isCreateResolution,
		createNewResolution,
		insertEditResolution,
		cancelCreateResolution,
		// isEditResolution,
		editResolution,
		updateEditResolution,
		cancelEditResolution,
		handleDeleteResolution,
		handleStatusNameChangeClick,
		handleStatusChange,
	} = props;

	return (
		<Box className='performance-development-card full-rectangular-card pip-resolution-settings'>
			<Box className='pd-card-header'>
				<Box>
					<Typography variant='h4' className='font-weight-normal'>
						{t('pipResolutionSettingsLabel')}
					</Typography>
				</Box>
				<OKRButton
					className='btn-link add-resolution-btn'
					icon={<AddIconSvg />}
					text={t('addResolution')}
					title={t('addResolution')}
					disabled={loader}
					handleClick={(e) => createNewResolution(e)}
				/>
			</Box>
			{performancePlanStatusDetails && performancePlanStatusDetails?.length ? (
				<Box className='resolution-listing-area'>
					<Box className='okr-list-panel-head'>
						<List component='ul'>
							<ListItem className='resolution-col1'>
								<Typography variant='body2' className='sort-title'>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('resolutionName')}</span>
									</span>
								</Typography>
							</ListItem>
							<ListItem className='resolution-col2'>
								<Typography variant='body2' className='sort-title'>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('typeLabel')}</span>
									</span>
								</Typography>
							</ListItem>
							<ListItem className='resolution-col3'>
								<Typography variant='body2' className='sort-title'>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('statusLabel')}</span>
									</span>
								</Typography>
							</ListItem>
						</List>
					</Box>
					{performancePlanStatusDetails?.map((item: any, itemIndex: number) => {
						const {
							performanceImprovementPlanStatusId,
							name,
							isDefault,
							isEnabled,
							isEditResolution,
							isCreateResolution,
						} = item;

						return (
							<Box
								className='resolution-listing-content'
								key={`plan_status_${performanceImprovementPlanStatusId}_${itemIndex}`}
							>
								<List component='ul'>
									<ListItem className={`resolution-col1 ${isEditResolution ? 'resolution-edit-col' : ''}`}>
										{isCreateResolution ? (
											<TextField
												className='resolution-text-field'
												value={name || ''}
												placeholder={t('addResolution')}
												fullWidth
												onChange={(e: any) => handleStatusNameChangeClick(e, item, itemIndex)}
											/>
										) : isEditResolution ? (
											<>
												<TextField
													className='resolution-text-field'
													value={name || ''}
													placeholder={t('addResolution')}
													fullWidth
													onChange={(e: any) => handleStatusNameChangeClick(e, item, itemIndex)}
												/>
												<Box className='edit-resolution-actions'>
													<OKRButton
														handleClick={(e) => cancelEditResolution(e, item, itemIndex)}
														className='resolution-cancel-btn'
														icon={<CancelIcon />}
														disabled={loader}
													/>
													<OKRButton
														handleClick={(e) => updateEditResolution(e, item, itemIndex)}
														className='resolution-save-btn'
														icon={<TickIcon />}
														disabled={loader}
													/>
												</Box>
											</>
										) : (
											<>
												<Typography>{name || ''}</Typography>
												{!isDefault ? (
													<Box className='resolution-listing-actions'>
														<OKRButton
															handleClick={(e) => editResolution(e, item, itemIndex)}
															icon={<EditIcon />}
															title={t('editLabel')}
															disabled={loader}
														/>
														<OKRButton
															icon={<DeleteIcon />}
															title={t('delete')}
															handleClick={(e: any) => handleDeleteResolution(e, item, itemIndex)}
															disabled={loader}
														/>
													</Box>
												) : (
													<></>
												)}
											</>
										)}
									</ListItem>
									<ListItem className='resolution-col2'>
										<Box className='resolution-cat-chip'>
											{isDefault ? <Chip label={t('defaultLabel')} /> : <Chip label={t('Created by admin')} />}
										</Box>
									</ListItem>
									<ListItem className='resolution-col3'>
										<Box className='status-switch'>
											<FormControlLabel
												control={
													<Switch
														name='resolutionStatus'
														checked={isEnabled || false}
														disabled={isCreateResolution}
														onChange={(e) => handleStatusChange(e, item, itemIndex, performancePlanStatusDetails)}
													/>
												}
												label={''}
											/>
										</Box>
									</ListItem>
								</List>
								{isCreateResolution ? (
									<Box className='pd-setting-card-actions'>
										<Box className='pd-setting-card-actions-left'></Box>
										<Box className='pd-setting-card-actions-right'>
											<OKRButton
												handleClick={(e) => cancelCreateResolution(e, item, itemIndex)}
												className='btn-link'
												text={t('cancelBtn')}
												disabled={loader}
											/>
											<OKRButton
												handleClick={(e) => insertEditResolution(e, item, itemIndex)}
												className='btn-primary'
												text={t('addBtn')}
												disabled={loader}
											/>
										</Box>
									</Box>
								) : (
									<></>
								)}
							</Box>
						);
					})}
				</Box>
			) : performancePlanStatusDetails && performancePlanStatusDetails?.length === Enums.ZERO ? (
				<></>
			) : (
				<></>
			)}
		</Box>
	);
};
