import React, { useState, useEffect, Fragment } from 'react';
import { Box, TextField, ButtonGroup, Button } from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { Enums } from '../../../config/enums';
import '../style.scss';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { OKRDrawer } from '../../Common/OKRDrawer';
import AlertDialog from '../../Common/Dialog';
import { ErrorIcon } from '../../../config/svg/formElementIcons';

export const TeamContributorDetail: React.FC<any> = (props) => {
	const {
		keyData,
		onSaveContributorDetailClick,
		selectedContributorDetails,
		okrMasterData,
		keyAttribute,
		objectiveDetail,
		t,
		type,
		setKRContributorDetailDashboard,
		krContributorDetailDashboard,
		handleBack,
		handleCancel,
	} = props;
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const [krContributorDetail, setKRContributorDetail] = useState<any>(selectedContributorDetails);
	const [krAssignmentTypes, setKRAssignmentTypes] = useState<any>(selectedContributorDetails);
	const [contributorDetail, setContributorDetail] = useState<any>([]);
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);
	const [defaultDetails, setDefaultDetails] = useState<any>(null);
	const [subModalProps, setSubModalProps] = useState<any>(null);

	useEffect(() => {
		if (selectedContributorDetails && defaultDetails === null) {
			let defDetails = Object.assign({}, selectedContributorDetails);
			setDefaultDetails(defDetails);
		}
	}, [selectedContributorDetails]);

	useEffect(() => {
		const { metricId, keyDescription } = keyAttribute;
		const contributorDefaultData = selectedContributorDetails ? selectedContributorDetails : krContributorDetail;

		let myObj = {
			keyResult: selectedContributorDetails?.keyResult || keyData?.keyDescription,
			objectiveName: selectedContributorDetails?.objectiveName || objectiveDetail?.objectiveName || '',
		};
		if (keyData) {
			myObj = {
				...myObj,
				keyResult: selectedContributorDetails?.keyResult || keyData?.keyDescription,
				objectiveName: selectedContributorDetails?.objectiveName || objectiveDetail?.objectiveName || '',
			};
		}
		if (keyDescription) {
			myObj = {
				...myObj,
				keyResult: keyDescription,
			};
		}
		const { metricMasters } = okrMasterData;
		const selectedMetricId = metricId ? metricId : keyData?.metricId === 0 ? 5 : keyData?.metricId;
		const selectedMetric =
			metricMasters && metricMasters.length
				? metricMasters.find((item: any) => item.metricId === selectedMetricId)
				: null;

		setKRContributorDetail({
			...contributorDefaultData,
			...myObj,
			assignmentTypeId: Enums.ONE,
			metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
		});

		if (okrMasterData) {
			const { assignmentTypes } = okrMasterData;
			const updatedData =
				assignmentTypes && assignmentTypes.length
					? assignmentTypes.map((item: any) => {
							item.activeButton = '';
							if (item.isDefault && selectedContributorDetails?.assignmentTypeId === Enums.ZERO) {
								item.activeButton = Enums.ACTIVE;
							}
							return item;
					  })
					: [];
			setKRAssignmentTypes([...updatedData]);
		}
		selectedContributorDetails && setContributorDetail([{ ...selectedContributorDetails }]);
	}, [keyAttribute, keyData, selectedContributorDetails, okrMasterData, type]);

	const validateAddContributorForm = (formData: any) => {
		if (!formData.objectiveName) {
			setKRAttrDateError({
				error: t('objectiveNameError'),
				helperText: t('objectiveNameError'),
				type: 'objectiveName',
			});
			return false;
		} else if (formData.objectiveName.trim() === '') {
			setKRAttrDateError({
				error: t('objectiveNameError'),
				helperText: t('objectiveNameError'),
				type: 'objectiveName',
			});
			return false;
		}

		if (!formData.keyResult) {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			return false;
		} else if (formData.keyResult.trim() === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			return false;
		}
		return true;
	};
	const onHandleSaveClick = (event: any) => {
		if (validateAddContributorForm(krContributorDetail)) {
			setKRAttrDateError(defaultKRDateError);
			onSaveContributorDetailClick && onSaveContributorDetailClick(event, krContributorDetail);
		}
		/*props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
		}, 500);*/
	};

	const onHandleAssignmentTypeClick = (event: any, item: any) => {
		if (type === 3) {
			if (item.name === 'WithParentObjective') {
				setKRContributorDetail({
					...krContributorDetail,
					goalObjectiveId: selectedContributorDetails.goalObjectiveId,
					assignmentTypeId: item?.assignmentTypeId ? item?.assignmentTypeId : 0,
				});
			} else {
				setKRContributorDetail({
					...krContributorDetail,
					goalObjectiveId: Enums.ZERO,
					assignmentTypeId: item?.assignmentTypeId ? item?.assignmentTypeId : 0,
				});
			}
		} else {
			setKRContributorDetail({
				...krContributorDetail,
				assignmentTypeId: item?.assignmentTypeId ? item?.assignmentTypeId : 0,
			});
		}

		type === 1 &&
			setKRContributorDetailDashboard({
				...krContributorDetailDashboard,
				assignmentTypeId: item?.assignmentTypeId ? item?.assignmentTypeId : 0,
			});
		if (okrMasterData) {
			const { assignmentTypes } = okrMasterData;
			const updatedData =
				assignmentTypes && assignmentTypes.length
					? assignmentTypes.map((assignmentType: any) => {
							assignmentType.activeButton = '';
							if (item.assignmentTypeId === assignmentType.assignmentTypeId) {
								assignmentType.activeButton = Enums.ACTIVE;
							}
							return assignmentType;
					  })
					: [];
			setKRAssignmentTypes([...updatedData]);
		}
	};

	const getBecomeAContributor = () => {
		return (
			<Box className='kr-assign-contributor-attr'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{krContributorDetail?.goalTypeId !== 1 && (
						<Box className='drawer-input-field kr-assign-type'>
							{/* <FormLabel component='legend'>{t('KRAssignmentType')}</FormLabel> */}
							{krAssignmentTypes && krAssignmentTypes.length ? (
								<ButtonGroup className='kr-btn-group' disableElevation variant='contained' color='primary'>
									{krAssignmentTypes.map((item: any) =>
										krContributorDetail?.goalTypeId === 1 && item.assignmentTypeId === 1 ? (
											<></>
										) : (
											<OKRButton
												key={`assignmentType-${item.assignmentTypeId}`}
												className={item.activeButton ? item.activeButton : 2 === item.assignmentTypeId ? 'active' : ''}
												disabled={true}
												text={item.description}
												handleClick={(event: any) => onHandleAssignmentTypeClick(event, item)}
											/>
										)
									)}
								</ButtonGroup>
							) : (
								<></>
							)}
						</Box>
					)}
					<Box className='drawer-input-field kr-no-edit-field helperText-bottom'>
						<TextField
							className='kr-text-field'
							value={krContributorDetail?.objectiveName || ''}
							onChange={(event: any) => {
								const { value } = event.target;
								setKRContributorDetail({ ...krContributorDetail, objectiveName: value });
								type === 1 &&
									setKRContributorDetailDashboard({
										...krContributorDetailDashboard,
										objectiveName: value,
									});
								setKRAttrDateError(defaultKRDateError);
							}}
							multiline
							error={krAttrDateError.type === 'objectiveName' && krAttrDateError?.error}
							helperText={
								krAttrDateError.type === 'objectiveName' &&
								krAttrDateError?.helperText && (
									<>
										<ErrorIcon />
										{krAttrDateError?.helperText}
									</>
								)
							}
							rows={2}
							label={t('objective')}
							InputProps={{ 'aria-label': 'Objective', disableUnderline: true }}
							fullWidth
						/>
					</Box>

					<Box className='drawer-input-field kr-no-edit-field helperText-bottom'>
						<TextField
							className='kr-text-field'
							value={krContributorDetail.keyResult}
							onChange={(event: any) => {
								const { value } = event.target;
								setKRContributorDetail({ ...krContributorDetail, keyResult: value });
								setKRAttrDateError(defaultKRDateError);
								type === 1 &&
									setKRContributorDetailDashboard({
										...krContributorDetailDashboard,
										keyResult: value,
									});
							}}
							multiline
							error={krAttrDateError.type === 'keyResult' && krAttrDateError?.error}
							helperText={
								krAttrDateError.type === 'keyResult' &&
								krAttrDateError?.helperText && (
									<>
										<ErrorIcon />
										{krAttrDateError?.helperText}
									</>
								)
							}
							rows={2}
							label={t('keyResult')}
							InputProps={{ 'aria-label': 'key result', disableUnderline: true }}
							fullWidth
						/>
					</Box>
					<Box className='drawer-input-field'>
						<TextField
							className='kr-text-field'
							value={krContributorDetail?.krAssignerMessage}
							onChange={(event: any) => {
								const { value } = event.target;
								selectedContributorDetails.krAssignerMessage = value;
								setKRContributorDetail({ ...krContributorDetail, krAssignerMessage: value });
								type === 1 &&
									setKRContributorDetailDashboard({
										...krContributorDetailDashboard,
										krAssignerMessage: value,
									});
							}}
							multiline
							rows={2}
							label={t('addMessage')}
							InputProps={{ 'aria-label': t('addMessage'), disableUnderline: true }}
							fullWidth
						/>
					</Box>
				</form>
			</Box>
		);
	};
	const handleCloseSubModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
			handleCancel(event);
		}
		setSubModalProps(false);
	};
	const checkDetailsChanged = (e: any) => {
		if (
			defaultDetails.objectiveName !== krContributorDetail?.objectiveName ||
			defaultDetails.keyResult !== krContributorDetail?.keyResult ||
			defaultDetails.krAssignerMessage !== krContributorDetail?.krAssignerMessage
		) {
			setSubModalProps(true);
		} else {
			handleCancel(e);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		}
	};
	return (
		<Fragment>
			<OKRDrawer
				open={true}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('AssignTeamsKr')}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-subpanel'>
							<Box className='kr-assign-contributor'>
								<Box className=''>{getBecomeAContributor()}</Box>
							</Box>
						</Box>
					</Box>
				}
				moreButton={false}
				handleDrawerClose={(e: any) => {
					checkDetailsChanged(e);
				}}
				handleSaveClick={onHandleSaveClick}
				handleCancelClick={(e: any) => {
					checkDetailsChanged(e);
				}}
				hideDefaultButton={false}
				isSaveButtonDisabled={false}
				saveButtonText={'Assign'}
				showBackButton={true}
				backButton={
					<Button
						className='btn-link'
						onClick={() => {
							handleBack(1);
						}}
					>
						{<KeyboardBackspaceIcon />}
						{t('back')}
					</Button>
				}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				// hideCloseIcon={true}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
			{subModalProps && (
				<AlertDialog
					module='myGoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseSubModal}
					modalOpen={subModalProps}
				/>
			)}
		</Fragment>
	);
};
