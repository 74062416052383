import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { ImportOKR } from '.';
import AlertDialog from '../../Common/Dialog';
import { getLocalStorageItem } from '../../../services/StorageService';
import { getUserDetails } from '../../../config/utils';

export const ImportOkrsDrawer: React.FC<any> = (props) => {
	const { t, openImportDrawer, loader, isAnyDrawerOpened, setTabValue, fromCreateImport, appDrawerInfo } = props;
	const [okrKrSelectionData, setOkrKrSelectionData] = useState<any>({});
	const [tabSelected, setTabSelected] = React.useState<Number>(0);
	const [mainTabSelected, setMainTabSelected] = useState<Number>(0);
	const [importButtonDisabled, setImportButtonDisabled] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({
		open: false,
		data: null,
		message: '',
		type: '',
		openType: '',
		tabToOpen: '',
		event: null,
	});
	const [titleValue, setTitleValue] = useState<any>({ krCount: 0, OkrCount: 0 });
	const userDetail: any = getUserDetails();

	useEffect(() => {
		if (openImportDrawer) {
			isAnyDrawerOpened({
				...appDrawerInfo,
				isImportDrawerOpen: true,
			});
		}
	}, [openImportDrawer]);

	useEffect(() => {
		if (fromCreateImport) {
			setMainTabSelected(0);
		}
	}, [fromCreateImport]);

	const handleImportDrawerAction = (event: any, type: string) => {
		if (Object.keys(okrKrSelectionData).length > 0) {
			if (type === 'cancel') {
				setModalProps({
					...modalProps,
					open: true,
					message: t('unSavedImportAlert'),
					type: 'import',
				});
			} else {
				handleImportLibraryDrawerClose(okrKrSelectionData, type);
			}
		} else {
			handleImportLibraryDrawerClose({}, 'cancel');
		}
		isAnyDrawerOpened({
			...appDrawerInfo,
			isImportDrawerOpen: false,
		});
	};

	const handleImportLibraryDrawerClose = async (data: any, type: string) => {
		if (type === 'cancel') {
			props.setOpenImportDrawer(false);
			setTabSelected(0);
		} else {
			setImportButtonDisabled(true);
			let importOkrData: any[] = [];
			const selectedYear = getLocalStorageItem('year') || props.year;
			Object.keys(data).forEach((okrKey: any) => {
				let importKrData: any = {
					employeeId: userDetail.employeeId,
					year: selectedYear,
					objectiveName: data[okrKey].okrName,
					objectiveCycleId: props.userSelectedCycle.organisationCycleId,
					goalNatureId: data[okrKey].goalNatureId,
					importKeyResults: [],
				};
				Object.keys(data[okrKey].krList).forEach((krKey: any) => {
					importKrData.importKeyResults.push({
						keyDescription: data[okrKey].krList[krKey].krName,
						metricId: data[okrKey].krList[krKey].krMetric,
						currencyId: data[okrKey].krList[krKey].krCurrencyId,
						currencyCode: data[okrKey].krList[krKey].krCurrencyCode,
					});
				});
				importOkrData.push(importKrData);
			});
			const response = await props.postOkrImport(importOkrData);
			setImportButtonDisabled(false);
			if (Boolean(response) && response.data.status === 200) {
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				props.showApiMsgs(`${messages} `, 'success');
				props.setOpenImportDrawer(false);
				props.fetchOkr();
				setTabValue(0);
			} else {
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				props.showApiMsgs(`${messages} `, 'error');
				props.setLoader(false);
			}
		}
	};

	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			setOkrKrSelectionData({});
			if (modalProps.type === 'tab') {
				if (modalProps.event) {
					const target = modalProps.event.target;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
					setMainTabSelected(modalProps.data);
				}
			} else {
				if (modalProps.openType !== 'tab') {
					handleImportLibraryDrawerClose({}, 'cancel');
				}
				if (modalProps.openType === 'tab') {
					setTabSelected(parseInt(modalProps.tabToOpen));
				}
			}
		}
		setModalProps({
			...modalProps,
			open: false,
			openType: '',
		});
	};

	useEffect(() => {
		const okrData = Object.keys(okrKrSelectionData);
		if (okrData && okrData.length > 0) {
			let krCount = 0;

			okrData.map((item: any) => {
				krCount = krCount + Object.keys(okrKrSelectionData[item]?.krList).length;
			});

			setTitleValue({ krCount, okrCount: okrData.length });
		} else {
			setTitleValue({ krCount: 0, okrCount: 0 });
		}
	}, [okrKrSelectionData]);

	return (
		<>
			<OKRDrawer
				open={openImportDrawer}
				loader={loader}
				transitionDuration={{ enter: 500, exit: 1000 }}
				drawerClassName={`main-drawer-panel ${mainTabSelected === 0 ? 'main-drawer-no-footer' : ''}`}
				headerTitle={
					<>
						{fromCreateImport ? t('generateOKRs') : t('importObjLevel')}
						{/* {(titleValue.okrCount > 0 || titleValue.krCount > 0) && (
							<span className='import-okr-count'>{`(Selected ${titleValue.okrCount} Objectives and ${titleValue.krCount} Key Results)`}</span>
						)} */}
					</>
				}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-panel'>
							<ImportOKR
								{...props}
								okrKrSelectionData={okrKrSelectionData}
								setOkrKrSelectionData={setOkrKrSelectionData}
								tabSelected={tabSelected}
								setTabSelected={setTabSelected}
								setModalProps={setModalProps}
								modalProps={modalProps}
								setMainTabSelected={setMainTabSelected}
								mainTabSelected={mainTabSelected}
								fromCreateImport={fromCreateImport}
								titleValue={titleValue}
							/>
						</Box>
					</Box>
				}
				isSaveButtonDisabled={Object.keys(okrKrSelectionData).length === 0 || importButtonDisabled}
				moreButton={false}
				saveButtonText={t('copyBtnLevel')}
				isFooterVisible={mainTabSelected === 0 ? false : true}
				handleDrawerClose={(event) => handleImportDrawerAction(event, 'cancel')}
				handleSaveClick={(event) => handleImportDrawerAction(event, 'save')}
				handleCancelClick={(event) => handleImportDrawerAction(event, 'cancel')}
			/>

			{modalProps.open && (
				<AlertDialog message={modalProps.message} handleCloseModal={handleCloseModal} modalOpen={modalProps.open} />
			)}
		</>
	);
};
