import React, { useState, Fragment, useEffect } from 'react';
import { Typography, Button, Link, Box } from '@material-ui/core';
import '../../styles/pages/error-pages/errors.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getLocalStorageItem } from '../../services/StorageService';

export default function UnauthorizedAccess(props) {
	const [open, setOpen] = useState(false);
	const userDetail = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	const { t } = useTranslation();
	const history = useHistory();

	const handlePermission = () => {
		// getEmployeePermission(undefined, history);
		history.goBack();
		// history.push(ADMIN);
	};

	const openJiraIssueCollector = (e) => {
		e.preventDefault();
		e.stopPropagation();
		handleToggle(2);

		document.getElementById('atlwdg-trigger').click();
	};

	useEffect(() => {
		const script = document.createElement('script');
		const script2 = document.createElement('script');

		script.type = 'text/javascript';
		script.src =
			'https://servicedesk.compunnel.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN/8bkccv/814001/1b2186679a631144916156ab6aec18b5/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=7dfe95aa';
		script.async = true;
		script2.type = 'text/javascript';
		script2.src =
			'https://servicedesk.compunnel.com/s/ba53967f66ec0096b89bb4fd64ec1120-T/8bkccv/814001/1b2186679a631144916156ab6aec18b5/4.0.3/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=7dfe95aa';
		script2.async = true;

		document.head.append(script);
		document.head.append(script2);

		window.ATL_JQ_PAGE_PROPS = {
			triggerFunction: function (showCollectorDialog) {
				showCollectorDialog();
			},
			fieldValues: {
				fullname: (userDetail && userDetail.firstName) + ' ' + (userDetail && userDetail.lastName),
				email: userDetail && userDetail.emailId,
				customfield_11308: window.location.hostname,
			},
		};
	}, []);

	const handleToggle = (flag) => {
		if (flag === 1) {
			setOpen((prevOpen) => !prevOpen);
		} else {
			setOpen(false);
		}
	};

	return (
		<Fragment>
			<Box className='not-found-area'>
				<Box className='inner-area-wrapper'>
					<Box className='inner'>
						<Typography variant='h1'>
							{userDetail.firstName ? userDetail.firstName : 'Hey'}, we have a problem!
						</Typography>

						<Typography>{t('UnauthorizedAccess')}</Typography>
						<Box className='btn-group'>
							<Link onClick={(e) => openJiraIssueCollector(e)}>Give us Feedback</Link>
							<Button className='btn-primary' onClick={handlePermission}>
								Back to Home
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</Fragment>
	);
}
