import { Avatar, Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import NotesImg from '../../../images/no-direct-reportees.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { Enums } from '../../../config/enums';

export const MembersList: React.FC<any> = (props) => {
	const { directReportees, directReporteesLoaded, getUserName, handleUserClick } = props;
	const { t } = useTranslation();

	return (
		<Box className='reportees-members'>
			<Box className='reportees-members-inner'>
				{directReportees.directReports && directReportees.directReports.length > 0 && directReporteesLoaded && (
					<>
						<Typography variant='h5'>{t('myTeamLabel')}</Typography>

						<List component='ul' disablePadding className='reportees-members-list'>
							{directReportees.directReports.map((item: any) => (
								<ListItem
									className={item?.isSelected ? 'selected' : ''}
									key={item.employeeId}
									id={`member-list-${item.employeeId}`}
									onClick={() => handleUserClick(item, true)}
								>
									<Box className='user-info user-info-ellipsis v-center'>
										<Box className='user-img'>
											{item.imagePath && item.imagePath.trim() !== '' ? (
												<Avatar className='user-avatar' key={`user-avtar`} src={item.imagePath} />
											) : item.firstName ? (
												<Avatar className='user-avatar' key={`user-avtar`}>
													{getUserName(item)}
												</Avatar>
											) : (
												<Avatar className='user-avatar' key={`user-avtar`}></Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Tooltip
												arrow
												title={
													(item?.firstName + item?.lastName).length > 22 ? item?.firstName + ' ' + item?.lastName : ''
												}
											>
												<Typography variant='h4'>{`${item.firstName} ${item.lastName}`} </Typography>
											</Tooltip>
											{/* <Tooltip arrow title={item?.designation.length > 30 ? item.designation : ''}>
												<Typography variant='subtitle2'>{item.designation}</Typography>
											</Tooltip> */}
										</Box>
									</Box>
									<Box className='member-status'>
										{item.checkInStatus.map((item: any, index: number) => (
											<Tooltip
												key={index}
												arrow
												title={`${item.checkInStatus}`}
												//classes={{ popper: 'tooltip-layout-margin' }}
											>
												<Typography
													className={`week-status ${
														item.checkInStatusId === Enums.CHECKED_IN_MISSED
															? 'checked-in-missed'
															: item.checkInStatusId === Enums.NOT_YET_CHECKED_IN
															? 'not-yet-checked'
															: item.checkInStatusId === Enums.STATUS_CHECKED_ID
															? 'checked-in'
															: item.checkInStatusId === Enums.CHECKED_IN_NO_DATA
															? 'checked-no-data'
															: ''
													}`}
												></Typography>
											</Tooltip>
										))}
									</Box>
								</ListItem>
							))}
						</List>
					</>
				)}
				{directReportees?.otherReports && directReportees?.otherReports?.length > 0 && directReporteesLoaded && (
					<>
						<Box className='other-member-section'>
							<Typography variant='h5' className='other-member-head'>
								<span>{t('Other Member(s)')}</span>
							</Typography>
						</Box>

						<List component='ul' disablePadding className='reportees-members-list'>
							{directReportees?.otherReports?.map((item: any) => (
								<ListItem
									className={item?.isSelected ? 'selected' : ''}
									key={item.employeeId}
									id={`member-list-${item.employeeId}`}
									onClick={() => handleUserClick(item, true)}
								>
									<Box className='user-info user-info-ellipsis v-center'>
										<Box className='user-img'>
											{item.imagePath && item.imagePath.trim() !== '' ? (
												<Avatar className='user-avatar' key={`user-avtar`} src={item.imagePath} />
											) : item.firstName ? (
												<Avatar className='user-avatar' key={`user-avtar`}>
													{getUserName(item)}
												</Avatar>
											) : (
												<Avatar className='user-avatar' key={`user-avtar`}></Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Tooltip
												arrow
												title={
													(item?.firstName + item?.lastName).length > 22 ? item?.firstName + ' ' + item?.lastName : ''
												}
											>
												<Typography variant='h4'>{`${item.firstName} ${item.lastName}`} </Typography>
											</Tooltip>
											{/* <Tooltip arrow title={item?.designation.length > 30 ? item.designation : ''}>
												<Typography variant='subtitle2'>{item.designation}</Typography>
											</Tooltip> */}
										</Box>
									</Box>
									<Box className='member-status'>
										{item.checkInStatus.map((item: any, index: number) => (
											<Tooltip
												key={index}
												arrow
												title={`${item.checkInStatus}`}
												//classes={{ popper: 'tooltip-layout-margin' }}
											>
												<Typography
													className={`week-status ${
														item.checkInStatusId === Enums.CHECKED_IN_MISSED
															? 'checked-in-missed'
															: item.checkInStatusId === Enums.NOT_YET_CHECKED_IN
															? 'not-yet-checked'
															: item.checkInStatusId === Enums.STATUS_CHECKED_ID
															? 'checked-in'
															: item.checkInStatusId === Enums.CHECKED_IN_NO_DATA
															? 'checked-no-data'
															: ''
													}`}
												></Typography>
											</Tooltip>
										))}
									</Box>
								</ListItem>
							))}
						</List>
					</>
				)}

				{directReporteesLoaded && !directReportees.directReports.length && !directReportees.otherReports.length && (
					<Box className='no-record'>
						<Typography variant='h2'>{t('checkinNoDirectReports')}</Typography>
						<Typography>{t('checkinNoDirectReportsDes')}</Typography>
						<AnimatedIcon
							className='no-record-img'
							width={'300'}
							type='image/svg+xml'
							alt={'No Conversation Available'}
							imagePath={NotesImg}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};
