import React, { Fragment } from 'react';
import { Box } from '@material-ui/core';

export const UnderMaintainence: React.FC<any> = (props) => {
	return (
		<Fragment>
			<Box className='internal-server-error-area'>
				<Box className='inner-area-wrapper'>
					<Box className='inner'>
						<h1>We'll be back soon!</h1>
						<div>
							<p>
								Sorry for the inconvenience but we're performing some maintenance at the moment. If you need to you can
								always <a href='mailto:support@unlocku.com'>contact us</a>, otherwise we'll be back online shortly!
							</p>
							<p>- UnlockOKR Support</p>
						</div>
					</Box>
				</Box>
			</Box>
		</Fragment>
	);
};
