const animationDuration = 350;
const shouldResize = true;

// Nodes
const nodeWidth = 280;
const nodeHeight = 93;
const nodeSpacing = 12;
const nodePaddingX = 16;
const nodePaddingY = 16;
const avatarWidth = 48;
const nodeBorderRadius = 6;
const margin = {
	top: 20,
	right: 20,
	bottom: 20,
	left: 20,
};

// Lines
const lineType = 'angle';
const lineDepthY = 120; /* Height of the line for child nodes */

// Colors
const backgroundColor = '#fff';
const borderColor = '#CEE3FF';
const nameColor = '#292929';
const titleColor = '#292929';
const reportsColor = '#92A0AD';

const config = {
	margin,
	animationDuration,
	nodeWidth,
	nodeHeight,
	nodeSpacing,
	nodePaddingX,
	nodePaddingY,
	nodeBorderRadius,
	avatarWidth,
	lineType,
	lineDepthY,
	backgroundColor,
	borderColor,
	nameColor,
	titleColor,
	reportsColor,
	shouldResize,
};

export default config;
