import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@material-ui/core';
import { ImportMyOkrsIcon, SearchPeopleIcon, OkrLibraryIcon } from '../../../config/svg/MyGoalSvg';

export const SearchSoTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`import-okr-tab-${index}`}
			aria-labelledby={`import-okr-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
};

SearchSoTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `import-okr-tabpanel-${index}`,
		'aria-controls': `import-okr-tabpanel-${index}`,
	};
}

export const SearchSoTabs: React.FC<any> = (props) => {
	const { t, handleTabChange = {}, tabSelected = 2 } = props;
	return (
		<Tabs className='okr-oval-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Import OKR Tabs'>
			<Tab icon={<ImportMyOkrsIcon />} label={t('myOkrsLabel')} value={0} {...a11yProps(0)} />
			<Tab icon={<SearchPeopleIcon />} label={t('peopleSearchLevel')} value={1} {...a11yProps(1)} />
		</Tabs>
	);
};
