import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	ClickAwayListener,
	FormLabel,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tab,
	Tabs,
	TextField,
	Typography,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
	DollarIcon,
	EuroIcon,
	KeyResultIcon,
	PoundIcon,
	RupeeIcon,
	YenIcon,
} from '../../../../../../config/svg/MyGoalSvg';
import { CalendarIcon, KababIcon, MetricIcon, ReportsTooltipIcon } from '../../../../../../config/svg/CommonSvgIcon';
import ReactDateRangePicker from '../../../../../Common/ReactDateRangePicker';
import { Enums } from '../../../../../../config/enums';
import { useSelector } from 'react-redux';
import { OKRButton } from '../../../../../Common/OKRButton';
import { getLocalStorageItem } from '../../../../../../services/StorageService';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { ErrorIcon } from '../../../../../../config/svg/formElementIcons';
import { DialogComponent } from '../../../../CommonComponent/DialogComponent';

export const DefaultKrAttributesTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`default-kr-attributes-${index}`}
			aria-labelledby={`default-kr-attributes-${index}`}
			{...props}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
};
DefaultKrAttributesTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `default-kr-attributes-tabpanel-${index}`,
		'aria-controls': `default-kr-attributes-tabpanel-${index}`,
	};
}

export const DefaultKeyResultAttributes = (props: any) => {
	const {
		defaultOkrFormDetails,
		krDetails,
		selectedDefaultKrTab,
		handleTabChange,
		selectedKrCycleDetails,
		setSelectedKrCycleDetails,
		allCycleDetails,
		// selectedKrDateRange,
		// setSelectedKrDateRange,
		isCurrentCycle,
		keyData,
		setKeyData,
		// krAttributeEdited,
		setKrAttributeEdited,
		mode,
	} = props;
	const moment = extendMoment(Moment);
	const { t } = useTranslation();
	const prgressMeasureRef: any = useRef();
	const { okrMasterData } = useSelector((state: any) => state.okrReducer);

	const [selectedKrDateRange, setSelectedKrDateRange] = useState<any>(null);
	const [anchorElCallouts, setAnchorElCallouts] = useState(null);
	const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
	const [isNoUnitOrBoolean, setIsNoUnitOrBoolean] = useState<any>(null);
	const [metricData, setMetricData] = useState<any>([]);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	//const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });

	useEffect(() => {
		if (Boolean(okrMasterData)) {
			setMasterData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [okrMasterData]);
	useEffect(() => {
		if (krDetails?.krItem) {
			setKeyData(krDetails?.krItem);
			const { startDate, endDate } = krDetails?.krItem || {};
			if (startDate && endDate) {
				setSelectedKrDateRange(moment().range(new Date(startDate), new Date(endDate)));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [krDetails]);

	const setMasterData = async () => {
		try {
			const { metricMasters } = okrMasterData || {};
			const updatedData =
				Boolean(metricMasters) && metricMasters.length
					? metricMasters.map(async (item: any, index: number) => {
							let SvgIcon: any = `${item.name}Icon`;
							let Icon: any = await import(`../../../../../../config/svg/MyGoalSvg`);
							item.Component = Icon[SvgIcon];
							item.activeButton = '';
							// if (item.isDefault && keyData.metricId === Enums.ZERO && keyAttribute.metricId === Enums.ZERO) {
							// 	//item.activeButton = Enums.ACTIVE;
							// 	//item.isDisabled = true;
							// 	//updateKeyAttrDetail('metricId', item.metricId);
							// }
							return item;
					  })
					: [];
			setMetricData(await Promise.all(updatedData));
		} catch (e) {}
	};

	const getCurrencyIcon = (currencyName: String) => {
		switch (currencyName) {
			case 'Dollar':
				return <DollarIcon />;
			case 'Euro':
				return <EuroIcon />;
			case 'Rupee':
				return <RupeeIcon />;
			case 'Yen':
				return <YenIcon />;
			case 'Pound':
				return <PoundIcon />;
			default:
				break;
		}
	};

	const setActiveMetric = (item: any) => {
		let metricId = keyData.metricType;
		if (metricId === item.metricId) {
			return 'active';
		} else if (metricId === Enums.ZERO && item.metricId === Enums.FIVE) {
			//return 'active';
		}

		return item.activeButton;
	};
	const CurrencyList: React.FC<any> = ({ open }) => {
		const { metricMasters } = okrMasterData;
		const currencyMetric =
			Boolean(metricMasters) && metricMasters.length && metricMasters.find((item: any) => item.metricId === 2);
		const currencyList = Boolean(currencyMetric) && currencyMetric.metricDataMaster;

		return (
			<MenuList autoFocusItem={open}>
				{Boolean(currencyList) && currencyList.length ? (
					currencyList.map((item: any) => (
						<MenuItem onClick={() => handleCurrencySelection(item)}>
							{getCurrencyIcon(item.name)}
							{item.name}
						</MenuItem>
					))
				) : (
					<></>
				)}
			</MenuList>
		);
	};
	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpenMenu(false);
		setOpen(false);
	};
	const handleMetric = (event: any, item: any) => {
		try {
			if (mode === 'Edit' && defaultOkrFormDetails?.status === Enums.TWO && keyData?.status === Enums.TWO) {
				setModalProps({
					open: true,
					message: '',
					details: {
						deleteType: 'KRMetricChange',
						headingText: t('changingMetricAlert'),
						subHeading: t('stillWantToChange'),
						confirmMsg: t('toConfirmEnterText'),
						matchString: Enums.METRIC_TEXT,
						item,
						event,
					},
				});
			} else {
				if (item?.metricId === Enums.TWO) {
					setAnchorEl(event.currentTarget);
					setOpenMenu(true);
				} else {
					setKeyData({ ...keyData, metricType: item.metricId || Enums.ZERO, currencyId: Enums.ZERO });
					setKrAttributeEdited(true);
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
	const handleCurrencySelection = (selectedCurrency: any) => {
		try {
			if (selectedCurrency) {
				setSelectedCurrency(selectedCurrency);
				setIsNoUnitOrBoolean(selectedCurrency);
				setKeyData({ ...keyData, currencyId: selectedCurrency.dataId || Enums.ZERO, metricType: Enums.TWO });
				setKrAttributeEdited(true);
				setAnchorEl(null);
				setOpenMenu(false);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleIsAddContributorAllowClick = (event: any) => {
		try {
			const { checked } = event?.target || {};
			setKeyData({ ...keyData, isAddContributorAllow: Boolean(checked) });
			setKrAttributeEdited(true);
		} catch (error) {
			console.error(error);
		}
	};
	const handleKrNameChange = (event: any) => {
		try {
			const { value } = event?.target || {};
			setKeyData({ ...keyData, name: value });
			setKrAttributeEdited(true);
		} catch (error) {
			console.error(error);
		}
	};

	const handleConfirmationCloseModal = async (e: any, type: any) => {
		try {
			// e.preventDefault();
			if (type === 1) {
				const { details } = modalProps || {};
				if (details && details?.deleteType === 'KRMetricChange') {
					const { item, event } = details || {};
					if (item?.metricId === Enums.TWO) {
						setAnchorEl(event.currentTarget);
						setOpenMenu(true);
					} else {
						setKeyData({ ...keyData, metricType: item.metricId || Enums.ZERO, currencyId: Enums.ZERO });
						setKrAttributeEdited(true);
					}
				}
				setModalProps({ open: false, message: '', details: '' });
			} else {
				setModalProps({ open: false, message: '', details: '' });
			}
		} catch (error) {
			console.error(error);
		}
	};
	// console.log('DefaultKeyResultAttributes ===> ', defaultOkrFormDetails, krDetails);
	// console.log('DefaultKeyResultAttributes ===> ', keyData, selectedKrCycleDetails, selectedKrDateRange);

	return (
		<>
			<Box className='default-kr-panel-head'>
				<Box className='drawer-input-field helperText-bottom default-kr-name-field'>
					<KeyResultIcon />
					<TextField
						fullWidth
						multiline
						minRows={2}
						maxRows={2}
						value={keyData?.name || ''}
						placeholder={t('KeyResultPlaceholderText')}
						InputProps={{ 'aria-label': t('keyResult'), disableUnderline: true }}
						id='inputKrTitle'
						onChange={handleKrNameChange}
						error={keyData?.isKrNameError}
						helperText={
							keyData?.isKrNameError && keyData?.krNameErrorMsg ? (
								<>
									<ErrorIcon />
									{keyData?.krNameErrorMsg}
								</>
							) : (
								''
							)
						}
					/>
				</Box>
				<Box className='drawer-input-field'>
					<Tabs
						className='okr-drawer-tabs'
						value={selectedDefaultKrTab}
						onChange={handleTabChange}
						aria-label='Admin Tabs'
					>
						<Tab label={`${t('attributesLabel')}s`} value={0} {...a11yProps(0)} />
					</Tabs>
				</Box>
			</Box>
			<DefaultKrAttributesTabPanel value={selectedDefaultKrTab} index={0}>
				<Box className='drawer-tabs-panel default-kr-attributes-tabs-panel'>
					<form className='drawer-form-fields' noValidate autoComplete='off'>
						<Box className='drawer-input-field'>
							<Box className={`helperText-bottom drawer-date-field`}>
								<FormLabel id='set-a-duration' component='legend' className='custom-form-label'>
									<CalendarIcon />
									{t('setATimeframe')}
								</FormLabel>
								<ReactDateRangePicker
									type={'KRAttribute'}
									startDateValue={keyData?.startDate ? new Date(keyData?.startDate) : new Date()}
									endDateValue={keyData?.endDate ? new Date(keyData?.endDate) : new Date()}
									minimumDate={
										defaultOkrFormDetails?.startDate ? new Date(defaultOkrFormDetails?.startDate) : new Date()
									}
									maximumDate={defaultOkrFormDetails?.endDate ? new Date(defaultOkrFormDetails?.endDate) : new Date()}
									handleDateSelection={(
										range: any,
										start: any,
										end: any,
										selectionType: String,
										isCycleChanged: Boolean,
										selectedQuarterData: any
									) => {
										// console.log(range, start, end, selectionType, isCycleChanged, selectedQuarterData);
										if (range && start && end) {
											setSelectedKrDateRange(range);
											setKeyData({ ...keyData, startDate: start, endDate: end });
										}
									}}
									bottomChildComponent={<></>}
									allCycleDetails={allCycleDetails}
									selectedCycleDetails={selectedKrCycleDetails}
									setSelectedCycleDetails={setSelectedKrCycleDetails}
									isCurrentCycle={isCurrentCycle}
									isCycleVisible={true}
									numberOfCalendars={Enums.TWO}
									selectionType={'range'}
									selectedDateRange={selectedKrDateRange}
									setSelectedDateRange={setSelectedKrDateRange}
									isDateRangeTextFieldVisible={true}
									isOnlyDueDateVisible={false}
									isCycleDisable={true}
									resToUseInCalendar={() => {}}
									editModee={true}
									setEditModee={() => {}}
									showBottomText={true}
								/>
							</Box>
						</Box>
						<Box className='drawer-input-field default-kr-measure-unit'>
							<FormLabel id='metric-label' className='label-des' component='legend' ref={prgressMeasureRef}>
								<MetricIcon />
								{t('progressMeasurement')}
							</FormLabel>
							<Box className='choose-metric-msg'>
								<Typography id='metric-helptext' className='short-des-text'>
									{t('chooseMetricTypeMessage')}
								</Typography>
							</Box>
							<Box className='default-kr-choose-metric-field'>
								{metricData.map((item: any, index: number) => {
									const { Component } = item;
									return (
										<OKRButton
											className={`measure-btn ${item.name?.toLowerCase()}-btn ${setActiveMetric(item)}`}
											handleClick={(event: any) => {
												//setCallOut && setCallOut(initialCallout);
												handleMetric(event, item);
												//callOutMetricsField(item);
											}}
											key={index}
											id={`measure-btn-${index}`}
											icon={<Component />}
											text={
												item.name === 'Currency' ? (
													<>
														{item.description}
														<KababIcon />
													</>
												) : (
													item.description
												)
											}
											// title={
											// 	!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? (
											// 		item.name === 'Percentage' ? (
											// 			t('percentageMetricText')
											// 		) : item.name === 'Numbers' ? (
											// 			t('numberMetricText')
											// 		) : item.name === 'Currency' ? (
											// 			t('currencyMetricText')
											// 		) : item.name === 'Boolean' ? (
											// 			t('booleanMetricText')
											// 		) : item.name === 'NoUnits' ? (
											// 			t('noUnitsMetricText')
											// 		) : (
											// 			<></>
											// 		)
											// 	) : (
											// 		''
											// 	)
											// }
											tooltipClassName='tooltip-layout4'
										/>
									);
								})}
								<Popper className='popper-menu-list' open={openMenu} anchorEl={anchorEl} transition placement='right'>
									{({ TransitionProps }) => (
										<Grow {...TransitionProps}>
											<Paper>
												<ClickAwayListener onClickAway={handleClose}>
													<CurrencyList open={openMenu} />
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
								{keyData?.isMetricTypeError || keyData?.isMetricCurrencyError ? (
									<Typography style={{ color: 'red' }}>
										{keyData?.krMetricTypeErrorMsg || keyData?.krMetricCurrencyErrorMsg || t('metricTypeRequired')}
									</Typography>
								) : (
									<></>
								)}
							</Box>
						</Box>
						{/* <Box className='drawer-input-field allow-users-switch'>
							<Box className='switch-button'>
								<FormControlLabel
									control={
										<Switch
											id='isAddContributorAllow'
											name='isAddContributorAllow'
											checked={keyData?.isAddContributorAllow}
											onChange={handleIsAddContributorAllowClick}
										/>
									}
									label={t('Allow users to add contributors')}
								/>
							</Box>
							<OKRButton className='allow-users-info-btn' icon={<ReportsTooltipIcon />} handleClick={handleOpen} />
							<Popper
								className={'role-description-popup report-header-tooltip'}
								open={open}
								anchorEl={anchorEl}
								transition
								placement={'bottom-start'}
							>
								<ClickAwayListener onClickAway={handleClose}>
									<Box className='textarea-expand'>
										<Typography>Test</Typography>
									</Box>
								</ClickAwayListener>
							</Popper>
						</Box> */}
					</form>
				</Box>
			</DefaultKrAttributesTabPanel>

			{modalProps && modalProps.open && (
				<DialogComponent
					module={modalProps.module || 'defaultKR'}
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleConfirmationCloseModal(e, 2)}
					matchString={modalProps?.details?.matchString || Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleConfirmationCloseModal(e, 1)}
					confirmMsg={modalProps?.details?.confirmMsg || ''}
					headingText={modalProps?.details?.headingText || t('deletePerformanceCycle')}
					subHeading={modalProps?.details?.subHeading || t('deletePerformanceCycleSubHead')}
				/>
			)}
		</>
	);
};
