import React, { useRef, Fragment, useState, useEffect } from 'react';
import { Box, Checkbox, ClickAwayListener, List, ListItem, Popper, Tooltip, Typography } from '@material-ui/core';
import { BorderCheckboxIcon, CheckedIcon } from '../../config/svg/formElementIcons';
import { useSnackbar } from 'notistack';

export const ListTooltip: React.FC<any> = (props) => {
	const {
		showTasks,
		taskCompleted,
		getTasks,
		objName,
		description,
		alignmentMapDescription = false,
		okr,
		type,
		kr,
		tasks,
	} = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const divRef = useRef<any>(null);
	const divToolRef = useRef<any>(null);
	const [allowTooltip, setAllowTooltip] = useState(false);
	const [openTask, setOpenTask] = useState<any>(false);
	const [scaleValue, setScaleValue] = useState<any>(1);
	const [allTasks, setAllTasks] = React.useState<any>([]);
	const [isTaskUpdated, setIsTaskUpdated] = React.useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const getAllTask = () => {
		let data = '';
		if (type === 1) {
			data = `goalId=${okr.goalObjectiveId}&goalTypeId=${type}`;
		} else if (type === 2) {
			data = `goalId=${kr.goalKeyId}&goalTypeId=${type}`;
		}
		getTasks(data);
	};

	React.useEffect(() => {
		if (tasks && tasks.length && anchorEl) {
			let allTaskCopy = [...tasks];
			let addKeyList = allTaskCopy.map((item: any) => {
				return {
					...item,
					history: item.taskName,
				};
			});
			setAllTasks(addKeyList);
		}
	}, [tasks]);

	React.useEffect(() => {
		if (anchorEl === null) {
			props.resetTasks && props.resetTasks();
			setAllTasks([]);
			setOpenTask(false);
		}
	}, [anchorEl]);

	useEffect(() => {
		if (openTask) {
			getAllTask();
		}
	}, [openTask]);

	const handleClickUser = () => {
		setOpenTask(true);
	};

	useEffect(() => {
		if (!allowTooltip && divRef.current.scrollHeight > divRef.current.offsetHeight) {
			setAllowTooltip(true);
			if (showTasks) {
				const className = 'description-arrow';
				const elem = document.createElement('span');
				elem.className = className;
				elem.innerHTML = 'arrow';
				elem.onclick = function (event: any) {
					handleClickUser();
					setAnchorEl(anchorEl ? null : event.currentTarget);
				};
				if (divToolRef?.current?.childElementCount === 0) {
					divToolRef?.current?.appendChild(elem);
				}
			}
		} else {
			if (showTasks) {
				const className = 'description-arrow';
				const elem = document.createElement('span');
				elem.className = className;
				elem.innerHTML = 'arrow';
				elem.onclick = function (event: any) {
					handleClickUser();
					setAnchorEl(anchorEl ? null : event.currentTarget);
				};
				if (divToolRef?.current?.childElementCount === 0) {
					divToolRef?.current?.appendChild(elem);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objName, showTasks]);

	const handleCompleteTask = async (e: any, task: any) => {
		const currentTasks = [...allTasks];
		const { checked } = e.target;

		let updateTask = currentTasks.map((item: any) => {
			if (item.taskId === task.taskId) {
				return {
					...item,
					isCompleted: checked,
				};
			} else {
				return {
					...item,
				};
			}
		});

		let postData = { taskId: task.taskId, isCompleted: checked };
		setAllTasks(updateTask);
		const taskCompleteResponse = await taskCompleted(postData);
		if (Boolean(taskCompleteResponse) && taskCompleteResponse.data.status === 200) {
			getAllTask();
			enqueueSnackbar(props.t('taskUpdate'), {
				variant: 'success',
				autoHideDuration: 2000,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'right',
				},
			});
			setIsTaskUpdated(true);
		} else {
			const responseAPI = taskCompleteResponse.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, {
				variant: 'error',
				autoHideDuration: 2000,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'right',
				},
			});
		}
	};

	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		if (props.diagramInstance) {
			setScaleValue(scaleValue ? props.diagramInstance.scrollSettings.currentZoom : 1);
		}
	};

	const handleClose = () => {
		if (isTaskUpdated) {
			props.fetchOkr();
			setAnchorEl(null);
			setIsTaskUpdated(false);
		} else {
			setAnchorEl(null);
		}
	};

	const scaleStyle = {
		transform: `scale(${scaleValue})`,
	};

	return (
		<Fragment>
			{alignmentMapDescription ? (
				<Fragment>
					{objName.length > props.characterCount ? (
						<Typography variant='h4' className={`cursorTitle ${props.titleClassName}`} ref={divRef}>
							<Tooltip arrow title={objName}>
								<span>{objName.substring(0, props.characterCount)}...</span>
							</Tooltip>
							{description && (
								<Tooltip title={'Description'} arrow className={'descriptiontool'}>
									<span className='description-arrow' onClick={handleClick}></span>
								</Tooltip>
							)}
						</Typography>
					) : (
						<Typography variant='h4' className={props.titleClassName} ref={divRef}>
							{objName}
							{description && (
								<Tooltip title={'Description'} arrow>
									<span className='description-arrow' onClick={handleClick}></span>
								</Tooltip>
							)}
						</Typography>
					)}
					<Popper
						className='okr-description-popup alignmentmap-description-popup'
						open={open}
						anchorEl={anchorEl}
						placement='bottom-start'
						modifiers={{
							preventOverflow: {
								enabled: false,
								boundariesElement: window,
							},
							applyStyle: {
								enabled: true,
								gpuAcceleration: false,
							},
						}}
						style={scaleStyle}
					>
						<ClickAwayListener onClickAway={handleClose}>
							<Box className='textarea-expand' dangerouslySetInnerHTML={{ __html: description }}></Box>
						</ClickAwayListener>
					</Popper>
				</Fragment>
			) : (
				<Fragment>
					{allowTooltip ? (
						<Fragment>
							<Typography variant='h4' className='with-tooltip' ref={divRef}>
								<Tooltip title={objName} arrow classes={{ popper: 'tooltip-layout5' }}>
									<span className='obj-tooltip-txt'>{objName}</span>
								</Tooltip>
								{/* {showTasks && (
									<Tooltip title={'Tasks'} arrow>
										<span ref={divToolRef} className='des-without-tooltip'></span>
									</Tooltip>
								)} */}
							</Typography>
						</Fragment>
					) : (
						<Fragment>
							<Typography variant='h4' className='without-tooltip' ref={divRef}>
								<Fragment>
									{objName}
									{/* {showTasks && (
										<Tooltip title={'Tasks'} arrow>
											<span ref={divToolRef} className='des-without-tooltip'></span>
										</Tooltip>
									)} */}
								</Fragment>
							</Typography>
						</Fragment>
					)}
					{/* {showTasks && (
						<Popper className='okr-description-popup' open={open} anchorEl={anchorEl} placement='bottom-start'>
							<ClickAwayListener onClickAway={handleClose}>
								<List className='task-lists tasks-expand'>
									{allTasks &&
										allTasks.length > 0 &&
										allTasks.map((task: any, index: number) => (
											<ListItem key={index} className={task.isCompleted ? 'completed-task' : ''}>
												<Box className='task-box'>
													<Checkbox
														className='task-checkbox'
														icon={<BorderCheckboxIcon />}
														checkedIcon={<CheckedIcon />}
														aria-label='Task Checkbox'
														checked={task.isCompleted}
														onChange={(e) => handleCompleteTask(e, task)}
													/>
													<Typography>{task.taskName}</Typography>
												</Box>
											</ListItem>
										))}
								</List>
							</ClickAwayListener>
						</Popper>
					)} */}
				</Fragment>
			)}
		</Fragment>
	);
};
