import {
	GET_ONE_ON_ONE_REQUEST_LIST_FAILED,
	GET_ONE_ON_ONE_REQUEST_LIST_SUCCESS,
	GET_ONE_ON_ONE_REQUEST_LIST_PENDING,
} from './../action/actionTypes';

const INITIAL_STATE = {
	oneOnOneRequestList: null,
	oneOnOneRequestListError: null,
	oneOnOneRequestListPending: false,
};

export default function oneOnOneRequestReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_ONE_ON_ONE_REQUEST_LIST_SUCCESS:
			return {
				...state,
				oneOnOneRequestList: action.payload || {},
				oneOnOneRequestListError: null,
				oneOnOneRequestListPending: false,
			};
		case GET_ONE_ON_ONE_REQUEST_LIST_PENDING:
			return {
				...state,
				oneOnOneRequestList: null,
				oneOnOneRequestListError: null,
				oneOnOneRequestListPending: true,
			};
		case GET_ONE_ON_ONE_REQUEST_LIST_FAILED:
			return {
				...state,
				oneOnOneRequestList: null,
				oneOnOneRequestListError: action.payload,
				oneOnOneRequestListPending: false,
			};

		default:
			return state;
	}
}
