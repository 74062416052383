import { Box } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Enums } from '../../../config/enums';
import { MainDrawer } from '../CommonComponent/MainDrawer';
import AddUserDrawer from './AddUserDrawer';
import RolePermissionDrawerForm from './RolePermissionDrawerForm';
import _ from 'lodash';
import { DialogComponent } from '../CommonComponent/DialogComponent';
import { checkPermission } from '../../../config/rolePermission';
import { addNewRole, editExistingRole } from './../AdminConstant';
import { useCallback } from 'react';
import { GAevent, isTrialVersion } from '../../../config/utils';
interface RolesnPermissonProps {
	addRolePermission?: (event: any) => void;
	fetchRolePermission?: (event: any) => void;
}

const RolesAndPermisson: React.FC<RolesnPermissonProps> = (props: any) => {
	const {
		addRolePermission,
		openRolePermissionDrawer,
		handleMenuClick,
		fetchRolePermission,
		manageUsers,
		showApiMsgs,
		currentRole,
		isEditRole,
		currentRoleStatus,
		updateRole,
		updateRoleResponse,
		getPermissionResult,
		currentRoleData,
	} = props;

	const [loader, setLoader] = useState(false);
	const [roleData, setRoleData] = useState<any>({
		roleName: '',
		roleDescription: '',
		isActive: true,
		isEditable: true,
	});
	const [addAnother, setAddAnother] = useState<boolean>(false);
	const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);
	const [formError, setFormError] = useState<any>({});
	const [userList, setUserList] = useState<any>([]);
	const [employeeList, setEmployeeList] = useState<any>([]);
	const [selectedUserList, setSelectedUserList] = useState<any>([]);
	const [originalList, setOriginalList] = useState<any>([]);
	const [assignedUser, setAssignedUser] = useState<any>([]);
	const [assignedUsersCopy, setAssignedUsersCopy] = useState<any>([]);
	const { t } = useTranslation();
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedRoleTab, setSelectedRoleTab] = useState<any>(0);
	// const [formEdited, setFormEdited] = useState<boolean>(false);
	const [sortOrder, setSortOrder] = useState<any>('asc');
	const [currentSort, setCurrentSort] = useState<any>('');
	const [userFormEdited, setUserFormEdited] = useState<boolean>(false);
	const [searchEmployeeText, setSearchEmployeeText] = useState<any>('');

	/**
	 * auto status true if any user assigned
	 */
	useEffect(() => {
		if (assignedUser && assignedUser.length) {
			setRoleData({
				...roleData,
				isActive: true,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assignedUser]);

	const [pageIndex, setPageIndex] = useState<any>(1);
	const pageSize = 10;

	const getRoleDetails = () => {
		let data = `?roleId=${currentRoleData.roleId}&finder=${searchEmployeeText}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
		props.getRole(data);
	};

	const loadMoreUser = () => {
		let data = `?roleId=${currentRoleData.roleId}&finder=${searchEmployeeText}&pageIndex=${
			pageIndex + 1
		}&pageSize=${pageSize}`;
		props.getRole(data);
		setPageIndex(pageIndex + 1);
	};

	/**
	 * On unmount reset form
	 */
	useEffect(() => {
		// getRoleDetails();
		return () => {
			resetForm();
			props.resetRole();
			props.setIsEditRole(false);
		};
	}, []);

	/**
	 * Auto redirection to manage user
	 */
	useEffect(() => {
		if (manageUsers) {
			setSelectedRoleTab(1);
		}
	}, [manageUsers]);

	/**
	 * If role edit
	 */
	useEffect(() => {
		if (currentRoleStatus === 'pending') {
			setLoader(true);
		} else if (currentRoleStatus === 'success' && currentRole?.roleId > 0) {
			setLoader(false);
			setRoleData(currentRole);
			let updateList = [];

			updateList =
				currentRole.getAssignUsers.length > 0
					? currentRole.getAssignUsers.map((item: any) => {
							return { ...item, alreadyAssigned: true };
					  })
					: [];
			setAssignedUser([...assignedUser, ...updateList]);
			// setAssignedUsersCopy(JSON.parse(JSON.stringify(updateList)));
		}
	}, [currentRoleStatus, currentRole]);

	/**
	 * validate user form
	 * @returns Boolean
	 */
	const validateForm = () => {
		let formData = { ...roleData };
		let errorData = { ...formError };
		let isValidated = true;
		if (formData.roleName === '') {
			isValidated = false;
			errorData.roleName = t('roleNameValidation');
		}
		setFormError(errorData);
		return isValidated;
	};

	/**
	 * Handle drawer actions
	 * @param event HTMLInputElement
	 * @param type String
	 */
	const handleDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		event.preventDefault();
		if (type === 'save') {
			if (validateForm()) {
				if (roleData.roleDescription === '<p><br></p>') {
					roleData.roleDescription = '';
				}
				if (isEditRole) {
					updateExistingRole();
				} else {
					isTrialVersion() && GAevent('AddRoles', 'Clicked Add Roles', 'Add Roles');
					saveNewRoleAndPermission();
				}
			}
		} else if (type === 'cancel') {
			if (userFormEdited) {
				setModalOpen(true);
			} else {
				handleMenuClick();
			}
		} else if (type === 'close') {
			if (userFormEdited) {
				setModalOpen(true);
			} else {
				handleMenuClick();
			}
		}
	};

	/**
	 * Reset add new form
	 */

	const resetForm = () => {
		setSelectedRoleTab(0);
		setUserList([]);
		setEmployeeList([]);
		setSelectedUserList([]);
		setAssignedUser([]);
		props.setManageUsers(false);
		setAddAnother(false);
		setUserFormEdited(false);
		setRoleData({
			roleName: '',
			roleDescription: '',
			isActive: true,
			isEditable: true,
		});
	};

	/**
	 * Update role
	 */
	const updateExistingRole = () => {
		let usersList = [];
		if (assignedUser.length) {
			usersList = assignedUser.map((item: any) => {
				return { employeeId: item.employeeId, isActive: item.isRemoved ? false : true };
			});
		}

		let postData = {
			roleId: roleData.roleId,
			roleName: roleData.roleName,
			roleDescription: roleData.roleDescription,
			isActive: roleData.isActive,
			assignUsers: usersList,
		};
		setLoader(true);
		updateRole(postData);
	};

	/**
	 * update role status callback
	 */
	useEffect(() => {
		if (props.updatedRoleStatus === 'success') {
			setLoader(false);
			showApiMsgs(t('roleUpdatedMsg'), 'success');
			handleMenuClick();
			fetchRolePermission();
			props.resetRoleStatus('');
		} else if (props.updatedRoleStatus === 'failed') {
			setLoader(false);
			showApiMsgs(updateRoleResponse.roleName, 'error');
		}
	}, [props.updatedRoleStatus]);

	/**
	 * Save role
	 */
	const saveNewRoleAndPermission = async () => {
		const roleObj = { ...roleData };
		roleObj.assignEmployeeIds = assignedUser.map((item: any) => item.employeeId);
		roleObj.permissionIds = [];
		setLoader(true);
		const response: any = await addRolePermission(roleObj);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			setFormError({});
			setLoader(false);
			showApiMsgs(response?.data?.messageList?.messageSuccess, 'success');
			fetchRolePermission(response?.data?.entity?.roleId);
			resetForm();
			if (!addAnother) {
				handleMenuClick();
			}
		} else {
			if (response?.data?.messageList?.roleName) {
				setFormError({ ...formError, roleName: response?.data?.messageList?.roleName });
				setLoader(false);
			}
		}
	};

	/**
	 * Handle Input change values
	 * @param event HTMLInputElement
	 */
	const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		// setFormEdited(true);
		if (name === 'isActive') {
			setRoleData({ ...roleData, [name]: event.target.checked });
			setUserFormEdited(true);
		} else {
			setRoleData({
				...roleData,
				[name]: value,
			});
			setFormError({
				...formError,
				[name]: '',
			});
			setUserFormEdited(true);
		}
	};

	/**
	 * Handle Role description (ck edtor)
	 */
	const handleDescription = (value: string) => {
		setRoleData({
			...roleData,
			roleDescription: value,
		});
		setFormError({ ...formError, roleDescription: '' });
	};

	/**
	 * Toggle assign user drawer
	 */
	const handleAssignModal = () => {
		setSortOrder('asc');
		setSearchEmployeeText('');
		setAssignedUser(assignedUser);
		setOpenUserDrawer(!openUserDrawer);
	};

	/**
	 *
	 * @param event HTML Event
	 * @param type string
	 */
	const handleAddUserDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'save') {
			// setFormEdited(true);
			setAssignedUser([...assignedUser, ...selectedUserList]);
			setAssignedUsersCopy(assignedUser);
			setOpenUserDrawer(!openUserDrawer);
			setUserFormEdited(true);
			if (selectedUserList.length) {
				if (isEditRole) {
					showApiMsgs(t('roleApplied', { roleName: roleData.roleName }), 'success');
				} else {
					showApiMsgs(t('wellDoneMsg', { numberOfUser: selectedUserList.length }), 'success');
				}
			}
		} else if (type === 'close') {
			if (userFormEdited) {
				setModalOpen(true);
			} else {
				setOpenUserDrawer(!openUserDrawer);
			}
		} else {
			setSelectedUserList(assignedUser);
			setOpenUserDrawer(!openUserDrawer);
		}
	};

	/**
	 *
	 * @param event HTML Event
	 */
	const trimValueOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const trimmedVal = value.trim();
		setRoleData({
			...roleData,
			roleName: trimmedVal,
		});
	};

	/**Add users to list panel*/
	const handleAddUserNextClick = () => {
		let filterByEmp = userList.filter((user: any) => {
			return !employeeList.some((item: any) => item.employeeId === user.employeeId);
		});
		let mergeList = [...employeeList, ...selectedUserList];
		setSelectedUserList(mergeList);
		setUserList(filterByEmp);
		setEmployeeList([]);
	};

	/**
	 * Fetch all users
	 */
	const fetchUsers = () => {
		const data = `pageIndex=0&pageSize=${Enums.MAX_USER_SIZE}`;
		props.getAllUsers(data);
	};

	/**
	 * fetch user if not avalable in store
	 */
	useEffect(() => {
		if (openUserDrawer && !props.fetchAllUserStatus) {
			if (!props.allUsers?.records?.length) {
				fetchUsers();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUserDrawer]);

	/** If users avaible set data */
	useEffect(() => {
		if (openUserDrawer && props.allUsers?.records?.length) {
			let allUsersClone = JSON.parse(JSON.stringify(props.allUsers));
			const activeUser = allUsersClone.records.filter((user: any) => user.isActive);
			let filterByEmp = [];
			let updatedList = [];
			filterByEmp = activeUser.filter((user: any) => {
				return assignedUser.length ? !assignedUser.some((item: any) => item.employeeId === user.employeeId) : [];
			});
			updatedList = filterByEmp.map((item: any) => {
				return { ...item, label: `${item.firstName} ${item.lastName}`, value: item.employeeId };
			});
			setOriginalList(updatedList);
			setUserList(updatedList);
		}
		if (!openUserDrawer) {
			setSelectedUserList([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.allUsers, openUserDrawer]);

	/**
	 * Handle when user click on any records
	 * @param selectedOptions Array
	 */
	const onSelectEmployee = (selectedOptions: any, actionMeta: any) => {
		setUserFormEdited(true);
		setEmployeeList(selectedOptions);
	};

	/**
	 * Handle delete from add user drawer
	 * @param event HTML Event
	 * @param index Number
	 */
	const handleAddUserDelete = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const allSelectedEmployee = [...selectedUserList];
		const originalListCopy = [...originalList];
		allSelectedEmployee.splice(index, 1);
		let removeSelectedRecords = originalListCopy.filter((x: any) => {
			return !allSelectedEmployee.some((t: any) => t.value === x.value);
		});
		setUserList(removeSelectedRecords);
		setSelectedUserList(allSelectedEmployee);
	};

	/**
	 * Handle user delete
	 * @param event HTML Event
	 * @param index Number
	 */
	const handleDeleteUser = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const assignedUserList = [...assignedUser];
		if (isEditRole) {
			if (assignedUserList[index].alreadyAssigned) {
				assignedUserList[index].isRemoved = true;
			} else {
				assignedUserList.splice(index, 1);
			}
		} else {
			assignedUserList.splice(index, 1);
		}
		setAssignedUser(assignedUserList);
		setUserFormEdited(true);
	};

	/**
	 * Handle alert dialog box
	 * @param e HTMLInputElement
	 * @param type Number
	 */
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (openUserDrawer) {
			if (type === 1) {
				setUserFormEdited(false);
				setOpenUserDrawer(false);
			}
			setModalOpen(false);
		} else {
			if (type === 1) {
				handleMenuClick();
			}
			setModalOpen(false);
		}
	};

	/**
	 * Handle Role detail and Manage User tab change
	 * @param event HTML Event
	 * @param newValue Number
	 */
	const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		setSelectedRoleTab(newValue);
		// setAssignedUser(assignedUsersCopy);
	};

	/**
	 * Handle sorting in user list drawer
	 * @param type string
	 */
	const handleSortData = (type: any) => {
		let list = _.orderBy(selectedUserList, [(user: any) => user[type].toLowerCase()], sortOrder);
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		setCurrentSort(type === 'label' ? 'label' : 'email');
		setSelectedUserList(list);
	};

	/**
	 * Handle filter in add user drawer
	 * @param option Object
	 * @param searchText string
	 * @returns boolean
	 */
	const customFilter = (option: any, searchText: any) => {
		if (
			option.label.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.emailId.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.employeeId === parseInt(searchText)
		) {
			return true;
		}
	};

	/**
	 * Handle addanother checkbox
	 */
	const handleAddAnother = () => {
		setAddAnother(!addAnother);
	};

	const delayedQuery = useCallback(
		_.debounce((q) => handleSearchUser(q), 800),
		[]
	);

	const handleSearchUser = (query: any) => {
		let data = `?roleId=${currentRoleData.roleId}&finder=${query}&pageIndex=${1}&pageSize=${pageSize}`;
		setPageIndex(1);
		setAssignedUser([]);
		props.getRole(data);
	};

	/**
	 * Handle user input in manage user drawer
	 * @param e HTML Event
	 */
	const searchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearchEmployeeText(value);
		delayedQuery(value);
	};

	/**
	 * Handle sorting for users in manage user listing
	 */
	const handleSortManageUsers = () => {
		let sortManageUserByFirstName = _.orderBy(assignedUser, [(user: any) => user.firstName.toLowerCase()], sortOrder);
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		setAssignedUser(sortManageUserByFirstName);
	};

	return (
		<Fragment>
			<MainDrawer
				open={openRolePermissionDrawer}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={isEditRole ? t('manageRolesAndMembers') : t('addRolePermission')}
				loader={loader}
				children={
					<Box className='drawer-inner-content'>
						<RolePermissionDrawerForm
							{...props}
							loadMoreUser={loadMoreUser}
							pageIndex={pageIndex}
							handleChangeValue={handleChangeValue}
							handleDescription={handleDescription}
							roleData={roleData}
							formError={formError}
							handleAssignModal={handleAssignModal}
							trimValueOnBlur={trimValueOnBlur}
							t={t}
							sortOrder={sortOrder}
							currentRole={currentRole}
							selectedRoleTab={selectedRoleTab}
							assignedUser={assignedUser}
							handleTabChange={handleTabChange}
							searchUser={searchUser}
							searchEmployeeText={searchEmployeeText}
							handleDeleteUser={handleDeleteUser}
							isEditRole={isEditRole}
							handleSortManageUsers={handleSortManageUsers}
						/>
					</Box>
				}
				saveButtonText={isEditRole ? t('update') : selectedRoleTab === 0 ? t('addBtn') : t('saveBtn')}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
				showAddAnother={!isEditRole}
				addAnother={addAnother}
				handleAddAnother={handleAddAnother}
				isSaveButtonDisabled={
					roleData.roleId > 0
						? !checkPermission(getPermissionResult?.employeePermissions, editExistingRole)
						: !checkPermission(getPermissionResult?.employeePermissions, addNewRole)
				}
			/>

			<MainDrawer
				open={openUserDrawer}
				transitionDuration={{ enter: 500, exit: 1000 }}
				children={
					<Box className='drawer-inner-content'>
						<AddUserDrawer
							t={t}
							userList={userList}
							onSelectEmployee={onSelectEmployee}
							handleAddUserNextClick={handleAddUserNextClick}
							selectedUserList={selectedUserList}
							employeeList={employeeList}
							handleAddUserDelete={handleAddUserDelete}
							handleSortData={handleSortData}
							sortOrder={sortOrder}
							customFilter={customFilter}
							currentSort={currentSort}
							currentRole={currentRole}
						/>
					</Box>
				}
				saveButtonText={t('saveBtn')}
				rightCloseBtn={true}
				isSaveButtonDisabled={!selectedUserList.length}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				handleDrawerClose={(event: any) => handleAddUserDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleAddUserDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleAddUserDrawerClose(event, 'cancel')}
			/>

			{modalOpen && (
				<DialogComponent
					module='information'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};

export default RolesAndPermisson;
