import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Badge } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const TaskTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;
	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`task-tabpanel-${index}`}
			aria-labelledby={`task-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

TaskTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `task-tab-${index}`,
		'aria-controls': `task-tabpanel-${index}`,
	};
}

export const TaskHeaderTabs: React.FC<any> = (props) => {
	const { tabSelected = 0, handleTabChange } = props;
	const { t } = useTranslation();
	return (
		<Tabs className='okr-tabs' value={tabSelected} onChange={handleTabChange} aria-label='task Tabs'>
			<Tab value={1} label={t('myTasks')} {...a11yProps(1)} />
			<Tab label={t('delegatedByMe')} value={2} {...a11yProps(2)} />
			<Tab label={t('tagged')} value={3} {...a11yProps(3)} />
		</Tabs>
	);
};
