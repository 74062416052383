import {
	Box,
	Collapse,
	Switch,
	TextField,
	Typography,
	Tabs,
	Tab,
	Grid,
	Tooltip,
	FormControlLabel,
} from '@material-ui/core';
import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { AddIconSvg, DashIcon } from '../../../config/svg/CommonSvgIcon';
import { UserAvatarGroup } from '../CommonComponent/UserAvatarGroup';
import { ManageUserDrawerForm } from './ManageUserDrawerForm';
import { AddStatusIcon, ErrorIcon, RoleIcon } from '../../../config/svg/formElementIcons';
import { Enums } from '../../../config/enums';
import { checkPermission } from '../../../config/rolePermission';
import { addNewRole, editExistingRole } from '../AdminConstant';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { roleRemovePlugin } from '../../Common/CkEditor/CkEditorEnums';

const RolePermissionDrawerForm: React.FC<any> = (props: any) => {
	const {
		handleChangeValue,
		handleDescription,
		roleData,
		formError,
		handleAssignModal,
		trimValueOnBlur,
		assignedUser,
		selectedRoleTab,
		handleTabChange,
		handleDeleteUser,
		searchUser,
		searchEmployeeText,
		isEditRole,
		getPermissionResult,
		currentRole,
	} = props;
	const { t } = useTranslation();
	const [collape, setCollapse] = useState<boolean>(true);
	function a11yProps(index: number) {
		return {
			id: `admin-tab-${index}`,
			'aria-controls': `admin-tabpanel-${index}`,
		};
	}

	const handleDescriptionToggle = () => {
		setCollapse(!collape);
	};

	return (
		<Fragment>
			<Tabs className='okr-tabs drawer-tabs' value={selectedRoleTab} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab label={isEditRole ? t('editRoleDetails') : t('roleDetail')} value={0} {...a11yProps(0)} />
				{(assignedUser.length > 0 || props.isEditRole) && (
					<Tab
						label={`${t('manageMembers')} 
							${currentRole?.totalRecords > 0 ? '(' : ''}${currentRole?.totalRecords > 0 ? currentRole?.totalRecords : ''}${
							currentRole?.totalRecords > 0 ? ')' : ''
						}`}
						value={1}
						{...a11yProps(1)}
					/>
				)}
			</Tabs>
			<Box className='drawer-tabs-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{selectedRoleTab === 0 && (
						<Fragment>
							<Box className='drawer-input-field'>
								<TextField
									id='roleName'
									className='field-col1 add-role-field'
									label={
										<Fragment>
											<RoleIcon />
											{t('roleNameLabel')}
										</Fragment>
									}
									disabled={
										!roleData.isEditable ||
										(roleData.roleId > 0
											? !checkPermission(getPermissionResult?.employeePermissions, editExistingRole)
											: !checkPermission(getPermissionResult?.employeePermissions, addNewRole))
									}
									placeholder={t('rolePlaceholder')}
									error={formError?.roleName}
									value={roleData.roleName}
									name='roleName'
									helperText={
										formError?.roleName && (
											<Fragment>
												<ErrorIcon />
												{formError?.roleName}
											</Fragment>
										)
									}
									onChange={handleChangeValue}
									onBlur={trimValueOnBlur}
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{ maxLength: Enums.MAX_LENGTH_ROLE_NAME }}
									InputProps={{ 'aria-label': t('rolePlaceholder'), disableUnderline: true }}
								/>
							</Box>

							<Box className='drawer-input-field role-desc-input-field'>
								<Button id='toggleButton' className='btn-small-text desc-btn-link' onClick={handleDescriptionToggle}>
									{collape ? <DashIcon /> : <AddIconSvg />}
									{t('descriptionLabel')}
								</Button>
								<Collapse in={collape} className='desc-wrap-field'>
									<Box className='drawer-desc-field editior-pane'>
										<CkEditor
											isDisabled={
												roleData.roleId > 0
													? !checkPermission(getPermissionResult?.employeePermissions, editExistingRole)
													: !checkPermission(getPermissionResult?.employeePermissions, addNewRole)
												// !checkPermission(getPermissionResult?.employeePermissions, editExistingRole) ||
												// (isEditRole && !roleData.isActive)
											}
											placeholder={t('objectiveDescPlaceholder')}
											value={roleData.roleDescription}
											handleEditorChange={(value: string) => {
												handleDescription(value);
											}}
											removedPlugins={roleRemovePlugin}
											showEmoticons={true}
										/>
									</Box>
								</Collapse>
							</Box>
							<Box className='drawer-input-field'>
								<Box className='role-status-field'>
									<FormControlLabel
										control={
											<Tooltip
												title={`${
													roleData.isDefault
														? t('defaultRoleMessage')
														: isEditRole && assignedUser.length
														? t('activeMemberMsg')
														: assignedUser.length > 0
														? t('msgStatus')
														: ''
												}`}
												arrow
												className='role-status-tooltip'
											>
												<Box className={assignedUser.length > 0 ? 'disableStatus' : ''}>
													<Switch
														id='roleStatus'
														name='isActive'
														disabled={
															assignedUser.length > 0 ||
															roleData.isDefault ||
															(roleData.roleId > 0
																? !checkPermission(getPermissionResult?.employeePermissions, editExistingRole)
																: !checkPermission(getPermissionResult?.employeePermissions, addNewRole))
														}
														checked={roleData.isActive}
														onChange={handleChangeValue}
														inputProps={{ 'aria-label': 'controlled' }}
													/>
												</Box>
											</Tooltip>
										}
										label={t('statusLabel')}
										labelPlacement='end'
										className={`toggle-label ${assignedUser.length > 0 ? 'disableStatus' : ''}`}
									/>
								</Box>
							</Box>
							<Box className='drawer-input-field drawer-input-blug-bg'>
								<UserAvatarGroup
									{...props}
									isDisabled={
										roleData.roleId > 0
											? !checkPermission(getPermissionResult?.employeePermissions, editExistingRole)
											: !checkPermission(getPermissionResult?.employeePermissions, addNewRole)
									}
									assignedUserDetails={assignedUser.length > 0 && assignedUser.map((item: any, index: number) => item)}
									handleAddUserClick={handleAssignModal}
									max={6}
									roleData={roleData}
									totalMembers={currentRole?.totalRecords}
								/>
							</Box>
							<Box className='note-text'>
								<Typography className='short-des-text'>
									<strong>{t('note')}</strong> {t('emailNotify')}
								</Typography>
							</Box>
						</Fragment>
					)}

					{selectedRoleTab === 1 && (
						<ManageUserDrawerForm
							t={t}
							{...props}
							searchEmployeeText={searchEmployeeText}
							searchUser={searchUser}
							handleAssignModal={handleAssignModal}
							handleDeleteUser={handleDeleteUser}
						/>
					)}
				</form>
			</Box>
		</Fragment>
	);
};

export default RolePermissionDrawerForm;
