import React, { Fragment, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { TeamContributorDetail } from './TeamContributorDetail';
import { ContributorList } from './ContributorList';
import { TeamMembersList } from './TeamMembersList';
import { AddEditContributorDetail } from './AddEditContributorDetail';
import AlertDialog from '../../Common/Dialog';
import { Enums } from '../../../config/enums';

export const TeamsKr: React.FC<any> = (props) => {
	const {
		treeNodesData,
		open,
		t,
		setOpen,
		keyAttribute,
		selectedContributorDetails,
		okrMasterData,
		contributorDetails,
		deleteContributor,
		setSelectedValues,
		onSaveTeamContributorsDetailClick,
		completeOrgData,
		selectedValues,
		selectedMetricDetail,
		getCurrencyIcon,
		durationCycleDetail,
		objectiveStartDate,
		objectiveEndDate,
		keyData,
		setCompleteOrgData,
		dueCycle,
		userAligned,
		disabledNodes,
		setCallOut,
		setAnchorElCallouts,
		initialCallout,
		showAssignKrDateCallouts,
		setShowAssignKrDateCallouts,
		setShowAssignKrTargetCallouts,
		showAssignKrTargetCallouts,
		showAssignKrTitleCallouts,
		setShowAssignKrTitleCallouts,
		calloutsData,
		onDeleteContributor,
		deletedContributor,
		setDeletedContributor,
		currentScreen,
		setCurrentScreen,
		selectedUsersData,
		setSelectedUsersData,
		selectedTeamIds,
		setSelectedTeamIds,
		openScreen,
		setOpenScreen,
		isDeleteInProgress,
		isLocked,
	} = props;
	const [modalProps, setModalProps] = useState<any>({
		open: false,
		data: null,
		message: '',
	});
	// const [selectedTeamIds, setSelectedTeamIds] = useState<any[]>([]);
	const [isParentChecked, setIsParentChecked] = useState<boolean>(false);
	const [selectedParents, setSelectedParents] = useState<any[]>([]);
	const [teamDetails, setTeamDetails] = useState<any>({});
	const [contributorObj, setContributorObj] = useState<any>(selectedContributorDetails);
	const [targetValue, setTargetValue] = useState<number>(0);
	const [krStartValue, setKRStartValue] = useState<number>(0);
	const [defaultIsGroupTargetValue, setDefaultIsGroupTargetValue] = useState<boolean>(
		keyAttribute?.isGroupTarget || false
	);

	useEffect(() => {
		setDefaultIsGroupTargetValue(keyAttribute?.isGroupTarget || false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (completeOrgData && completeOrgData.length > 0 && completeOrgData[0].children.length > 0) {
			setTeamDetails(completeOrgData[0]);
			if (openScreen !== 0) {
				handleNext(openScreen);
			}
		}
	}, [completeOrgData, openScreen]);

	useEffect(() => {
		if (deletedContributor && deletedContributor.employeeId) {
			let sTeams: any = [];
			let extstingList = selectedUsersData.filter((item: any) => {
				if (item.employeeId !== deletedContributor.employeeId) {
					sTeams.push(item.id);
				}
				return item.employeeId !== deletedContributor.employeeId;
			});
			setSelectedTeamIds(sTeams);
			setSelectedUsersData(extstingList);
			setDeletedContributor({});
		}
	}, [deletedContributor]);

	useEffect(() => {
		if (
			selectedMetricDetail &&
			selectedMetricDetail.selectedMetric &&
			selectedMetricDetail.selectedMetric.metricId !== 5 &&
			selectedMetricDetail.selectedMetric.metricId !== 4
		) {
			setTargetValue(keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue);
			setKRStartValue(keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue);
		} else {
			setTargetValue(0);
			setKRStartValue(0);
		}
	}, [keyAttribute, keyData, selectedMetricDetail]);

	useEffect(() => {
		setSelectedTeamIds(selectedValues);
	}, [selectedValues]);

	const handleClose = () => {
		setOpen(false);
		setCurrentScreen(0);
		setOpenScreen(0);
	};

	const deleteContributorFromKR = async (contributor: any, showMessage: boolean) => {
		await deleteContributor(contributor, false, showMessage, true);
	};

	const handleCloseModal = (e: any, action: any) => {
		if (action === 1) {
			setModalProps({
				...modalProps,
				open: false,
			});
			let showMessage = false;
			let total = modalProps.data.missingContributor.length;
			let counter = 0;
			modalProps.data.missingContributor.forEach((contributor: any) => {
				let childId = parseInt(contributor.split('_')[1]);
				let contributorIs = contributorDetails.filter((cont: any) => childId === cont.employeeId);
				if (contributorIs.length > 0) {
					counter++;
					if (total === counter) {
						showMessage = true;
					}
					deleteContributorFromKR(contributorIs[0], showMessage);
				}
			});
			setSelectedValues(modalProps.data.selectedT);
			//handleClose();
			let selectedTeamsData: any[] = [];
			treeNodesData.forEach((parent: any) => {
				parent.children.forEach((child: any) => {
					if (modalProps.data.selectedT.indexOf(child.id.toString()) !== -1 /*&& child.isAlreadyAligned !== true*/) {
						selectedTeamsData.push(child);
					}
				});
			});
			onSaveTeamContributorsDetailClick(
				modalProps.data.event,
				modalProps.data.krContributorDetail,
				selectedTeamsData,
				isParentChecked === true
			);
			//setCurrentScreen(0);
		} else {
			setModalProps({
				...modalProps,
				open: false,
				data: null,
			});
		}
	};

	const onSaveContributorDetailClick = (event: any, krContributorDetail: any) => {
		let missingContributor: any[] = [];
		let selectedT: any[] = [];
		if (selectedValues.length > 0) {
			selectedValues.forEach((selectedVal: any) => {
				let exists = false;
				selectedTeamIds.forEach((existingTeam: any) => {
					if (existingTeam === selectedVal) {
						exists = true;
					}
				});
				if (!exists) {
					missingContributor.push(selectedVal);
				}
			});
		}
		selectedTeamIds.forEach((existingTeam: any) => {
			selectedT.push(existingTeam);
		});
		if (0 && missingContributor.length > 0) {
			setModalProps({
				open: true,
				message: t('deleteContributorFromTeam'),
				data: {
					missingContributor: missingContributor,
					selectedT: selectedT,
					event: event,
					krContributorDetail: krContributorDetail,
				},
			});
		} else {
			setSelectedValues(selectedT);
			//handleClose();
			let selectedTeamsData: any[] = [];
			treeNodesData.forEach((parent: any) => {
				parent.children.forEach((child: any) => {
					if (selectedT.indexOf(child.id.toString()) !== -1) {
						selectedTeamsData.push(child);
					}
				});
			});
			onSaveTeamContributorsDetailClick(event, krContributorDetail, selectedTeamsData, isParentChecked === true);
			//setCurrentScreen(0);
		}
	};

	const prepareContributorList = () => {
		let selectedT: any[] = [];
		selectedTeamIds.forEach((existingTeam: any) => {
			selectedT.push(existingTeam);
		});

		let selectedTeamsData: any[] = [];
		let ownerData: any[] = [];
		let nonOwnerData: any[] = [];
		treeNodesData.forEach((treeNode: any) => {
			treeNode.children.forEach((child: any) => {
				// if (selectedT.indexOf(child.id.toString()) !== -1 && child.isAlreadyAligned !== true) {
				if (selectedT.indexOf(child.id.toString()) !== -1) {
					// in case AlreadyAligned user not requred in list
					if (child.isKrOwner) {
						ownerData.push(child);
					} else {
						nonOwnerData.push(child);
					}
				}
			});
		});
		selectedTeamsData = [...ownerData, ...nonOwnerData];
		//update contributor start and target value
		if (selectedUsersData.length > 0) {
			selectedUsersData.forEach((usersData: any) => {
				selectedTeamsData.forEach((teamsData: any) => {
					if (teamsData.employeeId === usersData.employeeId) {
						teamsData.startValue = usersData.startValue;
						teamsData.targetValue = usersData.targetValue;
					}
				});
			});
		}
		setSelectedUsersData(selectedTeamsData);
	};

	const handleCancel = () => {
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		setCurrentScreen(0);
		handleClose();
	};

	const handleBack = (screenNum: number) => {
		setCurrentScreen(screenNum);
		screenNum === 0 && setOpenScreen(0);
	};

	const handleNext = async (screenNumber: number) => {
		if (screenNumber === 1) {
			prepareContributorList();
		} else if (screenNumber === 3) {
			let teamDetailData = {
				startDate: teamDetails.children[0].startDate,
				dueDate: teamDetails.children[0].dueDate,
				startValue: teamDetails.children[0].startValue,
				targetValue: teamDetails.children[0].targetValue,
			};
			if (teamDetailData.startValue === 0 || teamDetailData.startValue === '') {
				teamDetailData = {
					startDate: keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate,
					dueDate: keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate,
					startValue: keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue,
					targetValue: keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue,
				};
			}
			setContributorObj({
				...selectedContributorDetails,
				keyResult: keyData?.keyDescription || keyAttribute?.keyDescription || '',
				isAddedNew: true,
				goalTypeId: 1,
				assignmentTypeId: 2,
				startDate: teamDetailData.startDate,
				dueDate: teamDetailData.dueDate,
				startValue: teamDetailData.startValue,
				targetValue: teamDetailData.targetValue,
				isActive: true,
			});
			selectedContributorDetails.isAddedNew = true;
		}
		setCurrentScreen(screenNumber);
	};

	return (
		<Fragment>
			{open ? (
				<Box
					className={
						keyAttribute && !keyAttribute.goalKeyId ? 'createdPopover' : 'contributor-details-popover teams-section-kr'
					}
				>
					{currentScreen === 3 ? (
						<AddEditContributorDetail
							{...props}
							t={t}
							type={2}
							keyData={keyData}
							keyAttribute={keyAttribute}
							durationCycleDetail={durationCycleDetail}
							objectiveStartDate={objectiveStartDate}
							objectiveEndDate={objectiveEndDate}
							selectedContributorDetails={contributorObj}
							onHandleCancelClick={() => {}}
							onSaveContributorDetailClick={onSaveContributorDetailClick}
							getCurrencyIcon={getCurrencyIcon}
							handleBack={handleBack}
							handleCancel={handleCancel}
							setCallOut={setCallOut}
							setAnchorElCallouts={setAnchorElCallouts}
							initialCallout={initialCallout}
							calloutsData={calloutsData}
							hideKrConfidence={true}
						/>
					) : currentScreen === 2 ? (
						<TeamContributorDetail
							{...props}
							t={t}
							selectedContributorDetails={selectedContributorDetails}
							keyAttribute={keyAttribute}
							okrMasterData={okrMasterData}
							onHandleCancelClick={handleCancel}
							handleBack={handleBack}
							onSaveContributorDetailClick={onSaveContributorDetailClick}
							modalProps={modalProps}
							handleCloseModal={handleCloseModal}
							handleCancel={handleCancel}
						/>
					) : currentScreen === 1 ? (
						<ContributorList
							{...props}
							selectedMetricDetail={selectedMetricDetail}
							handleBack={handleBack}
							handleNext={() => {
								onSaveContributorDetailClick({}, selectedContributorDetails);
							}}
							handleCancel={handleCancel}
							onDeleteContributor={onDeleteContributor}
							contributorList={selectedUsersData}
							teamDetails={teamDetails}
							targetValue={targetValue}
							krStartValue={krStartValue}
							dueCycle={dueCycle}
							setCallOut={setCallOut}
							setAnchorElCallouts={setAnchorElCallouts}
							initialCallout={initialCallout}
							showAssignKrDateCallouts={showAssignKrDateCallouts}
							setShowAssignKrDateCallouts={setShowAssignKrDateCallouts}
							setShowAssignKrTargetCallouts={setShowAssignKrTargetCallouts}
							showAssignKrTargetCallouts={showAssignKrTargetCallouts}
							showAssignKrTitleCallouts={showAssignKrTitleCallouts}
							setShowAssignKrTitleCallouts={setShowAssignKrTitleCallouts}
							calloutsData={calloutsData}
							saveButtonText={'Save'}
							isDeleteInProgress={isDeleteInProgress}
							isLocked={isLocked}
						/>
					) : (
						<TeamMembersList
							{...props}
							handleBack={handleBack}
							handleNext={handleNext}
							handleCancel={handleCancel}
							setSelectedTeamIds={setSelectedTeamIds}
							selectedTeamIds={selectedTeamIds}
							isParentChecked={isParentChecked}
							setIsParentChecked={setIsParentChecked}
							prepareContributorList={prepareContributorList}
							setCompleteOrgData={setCompleteOrgData}
							keyAttribute={keyAttribute}
							keyData={keyData}
							userAligned={userAligned}
							disabledNodes={disabledNodes}
							setCallOut={setCallOut}
							setAnchorElCallouts={setAnchorElCallouts}
							initialCallout={initialCallout}
							calloutsData={calloutsData}
							selectedParents={selectedParents}
							setSelectedParents={setSelectedParents}
							selectedMetricDetail={selectedMetricDetail}
							defaultIsGroupTargetValue={defaultIsGroupTargetValue}
							targetValue={targetValue}
							krStartValue={krStartValue}
						/>
					)}
				</Box>
			) : (
				<span></span>
			)}
			{modalProps && modalProps.open && (
				<AlertDialog
					module='organization'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
};
