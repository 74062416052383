import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { checkPermission } from '../../config/rolePermission';
import { EditIcon, NotesIcon, TasksIcon } from '../../config/svg/CommonSvg';
import { SettingsIcon } from '../../config/svg/MyGoalSvg';
import { removeLocalStorageItem } from '../../services/StorageService';
import { editOkrs } from '../Admin/AdminConstant';
import { OKRButton } from '../Common/OKRButton';
import './style.scss';

export const OkrFieldActions: React.FC<any> = (props) => {
	const {
		t,
		handleListAction,
		okr,
		kr,
		type,
		showGoalAndKrAttribute,
		showEditLoader,
		isOnlyEdit,
		settingBtnclassName,
		peopleViewActions,
		handleCfrOpen,
		isPastCycle,
		showTooltip,
	} = props;
	const keyData = type === 2 ? kr : okr;
	const id = type === 1 ? okr.goalObjectiveId : keyData.goalKeyId;

	// commented for now as YW suggested to open Drawer Fast
	const [apiCallWip, setApiCallWip] = useState(false);

	const callAPIForOkr = (e: any) => {
		if (type === 1) {
			Promise.allSettled([props.getOkrDetail(okr.goalObjectiveId)]).then((response: any) => {
				if (response) {
					let getOKRDetail =
						Boolean(response) && response.length && response[0] && response[0].value
							? response[0].value.data.entity
							: '';
					if (getOKRDetail) {
						showGoalAndKrAttribute(e, getOKRDetail, {}, type);
					}
				}
			});
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('okrDrawer');
				setApiCallWip(false);
			}, 1500);
		} else if (type === 2) {
			Promise.allSettled([props.getOkrDetail(okr.goalObjectiveId), props.getKrDetail(keyData.goalKeyId)]).then(
				(response: any) => {
					if (response) {
						let getOKRDetail =
							Boolean(response) && response.length && response[0] && response[0].value
								? response[0].value.data.entity
								: '';
						let getKRDetail =
							Boolean(response) && response.length && response[1] && response[1].value
								? response[1].value.data.entity
								: '';
						if (getOKRDetail && getKRDetail) {
							showGoalAndKrAttribute(e, getOKRDetail, getKRDetail, type);
						}
					}
				}
			);

			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
				setApiCallWip(false);
			}, 1500);
		}
		//type === 3 =======> is for standalone
	};
	const handleOpen = (event: any, tabToOpen?: string) => {
		handleCfrOpen(event, type, kr, okr, tabToOpen);
	};

	return (
		<>
			{checkPermission(props.getPermissionResult?.employeePermissions, editOkrs) ? (
				<Box className='okr-field-actions'>
					{/* {!peopleViewActions && isOnlyEdit === true && !isPastCycle ? (
						<OKRButton
							className={`edit-btn ${keyData.edit ? 'edit-active' : ''} `}
							id={`edit-btn-${id}`}
							icon={<EditIcon />}
							title={'Edit'}
							handleClick={(e: any) => handleListAction(e, okr, kr, type)}
						/>
					) : isPastCycle ? (
						<></>
					) : null}
					{!peopleViewActions && !isOnlyEdit && !isPastCycle ? (
						<OKRButton
							className={`edit-btn ${keyData.edit ? 'edit-active' : ''} `}
							id={`edit-btn-${id}`}
							icon={<EditIcon />}
							title={'Edit'}
							handleClick={(e: any) => handleListAction(e, okr, kr, type)}
						/>
					) : isPastCycle ? (
						<></>
					) : null} */}
					{!peopleViewActions && !isOnlyEdit ? (
						<OKRButton
							className={` ${settingBtnclassName ? settingBtnclassName : 'settings-btn'} ${
								showTooltip ? 'settings-btn-error' : ''
							}`}
							tooltipClassName={`${showTooltip ? 'tooltip-layout3 tooltip-error-new' : ''}`}
							id={`setting-btn-${id}`}
							icon={<SettingsIcon />}
							//title={`${type === 1 ? 'Objective Attributes' : 'Key Result Attributes'}`}
							title={`${!showTooltip ? t('attributesLabel') : t('okrHoverText')}`}
							handleClick={(e: any) => {
								if (!apiCallWip) {
									props.updateCalloutPanel('');

									// Call API
									//setApiCallWip(true);
									//callAPIForOkr(e);
									showGoalAndKrAttribute(e, okr, kr, type);
									removeLocalStorageItem('showCallouts');
								}
							}}
							openTooltip={showTooltip}
						/>
					) : isPastCycle ? (
						<></>
					) : null}
					{type !== 1 && (
						<OKRButton
							className={`task-btn`}
							id={`task-btn-${id}`}
							icon={<TasksIcon />}
							title={
								t('tasksLabel')
								// (type === 1 || type === 2) &&
								// ((type === 2 && kr && !kr?.totalTask) || (type === 1 && okr && !okr?.totalTask))
								// 	? t('zeroTask')
								// 	: t('taskCompletedText', {
								// 			currentTask: type === 2 ? kr.taskCompleted : okr.taskCompleted,
								// 			totalTask: type === 2 ? kr.totalTask : okr.totalTask,
								// 	  })
							}
							handleClick={(e: any) => handleOpen(e, 'task')}
						/>
					)}
					{/* <OKRButton
						className={`note-btn`}
						id={`note-btn-${id}`}
						icon={<NotesIcon />}
						title={
							<>
								{t('notesLabel')}
								{t('notesNumberText', { toalNotes: type === 2 ? kr.totalNotes : okr.totalNotes })}
							</>
						}
						handleClick={(e: any) => handleOpen(e, 'notes')}
					/> */}
				</Box>
			) : (
				<></>
			)}
		</>
	);
};
