import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Badge } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AlertInfoIcon } from '../../config/svg/CommonSvg';
import { useSelector } from 'react-redux';
import { checkSupportPermission } from '../../config/utils';
import { supportModuleAlignGoals } from '../Admin/AdminConstant';
export const UserPerformTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`perform-tabpanel-${index}`}
			aria-labelledby={`perform-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

UserPerformTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `perform-tab-${index}`,
		'aria-controls': `perform-tabpanel-${index}`,
	};
}

export const UserPerformTabs: React.FC<any> = (props) => {
	const { tabSelected = 0, handleTabChange } = props;
	const { performanceAlert } = useSelector((state: any) => state.performReducer);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const { t } = useTranslation();

	return (
		<Tabs className='okr-tabs' value={tabSelected} onChange={handleTabChange} aria-label='perform Tabs'>
			{/* {checkSupportPermission(supportModuleAlignGoals) ? (
				<>
					<Tab
						value={0}
						label={
							<>
								{performanceAlert && performanceAlert.isSelfAssessmentAlert && tabSelected !== 0 ? (
									<>
										<Badge className='badge-icon' overlap='rectangular' badgeContent={<AlertInfoIcon />}>
											{t('selfReviewLevel')}
										</Badge>
									</>
								) : (
									t('selfReviewLevel')
								)}
							</>
						}
						{...a11yProps(0)}
					/>
					{okrMasterData?.performanceGoalReviewPermission.isTeamReview && (
						<Tab
							label={
								<>
									{performanceAlert && performanceAlert.isManagerAssessmentAlert && tabSelected !== 1 ? (
										<>
											<Badge className='badge-icon' overlap='rectangular' badgeContent={<AlertInfoIcon />}>
												{t('teamReviewLevel')}
											</Badge>
										</>
									) : (
										t('teamReviewLevel')
									)}
								</>
							}
							value={1}
							{...a11yProps(1)}
						/>
					)}
					{okrMasterData?.performanceGoalReviewPermission.isApprovals && (
						<Tab label={<>{t('Approvals')}</>} value={2} {...a11yProps(2)} />
					)}
				</>
			) : (
				<> */}
			<Tab
				value={0}
				label={
					<>
						{performanceAlert && performanceAlert.isSelfAssessmentAlert && tabSelected !== 0 ? (
							<>
								<Badge className='badge-icon' overlap='rectangular' badgeContent={<AlertInfoIcon />}>
									{t('myAssessment')}
								</Badge>
							</>
						) : (
							t('myAssessment')
						)}
					</>
				}
				{...a11yProps(0)}
			/>
			{props.userDetails?.directReports && (
				<Tab
					label={
						<>
							{performanceAlert && performanceAlert.isManagerAssessmentAlert && tabSelected !== 1 ? (
								<>
									<Badge className='badge-icon' overlap='rectangular' badgeContent={<AlertInfoIcon />}>
										{t('myTeamsAssessment')}
									</Badge>
								</>
							) : (
								t('myTeamsAssessment')
							)}
						</>
					}
					value={1}
					{...a11yProps(1)}
				/>
			)}
			<Tab
				label={
					<>
						{
							/*performanceAlert && performanceAlert.isThreeSixtyAssessmentAlert && tabSelected !== 1 ? (
							<>
								<Badge className='badge-icon' overlap='rectangular' badgeContent={<AlertInfoIcon />}>
									<span dangerouslySetInnerHTML={{ __html: t('assessment360degree') }}></span>
								</Badge>
							</>
						) : (*/
							<span dangerouslySetInnerHTML={{ __html: t('assessment360degree') }}></span>
							/*)*/
						}
					</>
				}
				value={2}
				{...a11yProps(2)}
			/>
			{/* </>
			)} */}
		</Tabs>
	);
};
