import {
	CREATE_DEFAULT_OKR,
	DEFAULT_OKR_CYCLE_DETAILS,
	DEFAULT_OKR_DESIGNATION_DETAILS,
	DEFAULT_OKR_PERFORMANCE_ROLE_DETAILS,
	// EDIT_DEFAULT_OKR,
	LIST_DEFAULT_OKR,
	COPY_DEFAULT_OKR,
	COPY_OKR,
	CLONE_OKR,
	PUBLISH_LIST,
	DOWNLOAD_DEFAULT_OKR,
	REPUBLISH_LIST,
	VALIDATE_BULK_DEFAULT_OKR,
	UPLOAD_BULK_DEFAULT_OKR

} from '../config/api-url';
import { deleteRequest, downloadXLSFile, getRequest, postRequest, putRequest } from '../config/auth';

export const getDefaultOkrCycleDetailsAPI = (data) => getRequest(`${DEFAULT_OKR_CYCLE_DETAILS}`, data);
export const getDefaultOkrDesignationDetailsAPI = (data) => getRequest(`${DEFAULT_OKR_DESIGNATION_DETAILS}`, data);
export const getDefaultOkrPerformanceRoleDetailsAPI = (data) =>
	getRequest(`${DEFAULT_OKR_PERFORMANCE_ROLE_DETAILS}`, data);
export const createDefaultOkrAPI = (data) => postRequest(`${CREATE_DEFAULT_OKR}`, data);
export const editDefaultOkrAPI = (data) => putRequest(`${CREATE_DEFAULT_OKR}`, data);
export const ListDefaultOkrAPI = (data) => postRequest(`${LIST_DEFAULT_OKR}`, data);
export const getDefaultOkrDetailsForEditCallAPI = (data) => getRequest(`${LIST_DEFAULT_OKR}/${data}`, data);
export const copyDefaultOkrAPI = (data) => postRequest(`${COPY_DEFAULT_OKR}`, data);
export const copyOkrApi = (data) => postRequest(`${COPY_OKR}`, data);

// export const ratingFormUpdateAPI = (data) => putRequest(`${RATING_FORM}`, data);
export const defaultOkrDeleteAPI = (data) => deleteRequest(`${CREATE_DEFAULT_OKR}?${data}`, data);
export const cloneOkrApi = (data) => postRequest(`${CLONE_OKR}`, data);
export const publishFromListingApi = (data) => postRequest(`${PUBLISH_LIST}`, data);
export const RePublishFromListingApi = (data) => postRequest(`${REPUBLISH_LIST}`, data);
export const exportDefaultOkrReportAPI = (data) => downloadXLSFile(`${DOWNLOAD_DEFAULT_OKR}?${data}`, data);
export const validateDefaultOkrReportAPI = (data) => postRequest(`${VALIDATE_BULK_DEFAULT_OKR}`, data);
export const uploadDefaultOkrReportAPI = (data) => postRequest(`${UPLOAD_BULK_DEFAULT_OKR}`, data);
