import React, { useState, useEffect } from 'react';
import { Enums } from '../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, MobileStepper, Typography } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { DownArrowIcon, LeftArrow, RightArrow } from '../../config/svg/ArrowSvg';
import { getMonthDateYear } from '../../config/utils';
import { getGoalCycleSelectorApi, setPerformaceGoalCurrentCycle } from '../../action/adminSettings';
import { setPerformanceGoalForRedirection } from '../../action/common';

export const GoalCycleTab = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { goalUserCycle } = useSelector((state: any) => state.adminSettingReducer);
	const { redirectedPerformanceGoalDetails, hidePerformaceCycle } = useSelector((state: any) => state.commonReducer);
	const [activeStep, setActiveStep] = useState(-1);
	const [cycleData, setCycleData] = useState<any[]>([]);
	const [maxSteps, setMaxSteps] = useState<number>(-1);

	useEffect(() => {
		//notification redirection for My Goal
		if (redirectedPerformanceGoalDetails) {
			setTimeout(() => {
				dispatch(setPerformanceGoalForRedirection(null));
			}, 10000);
		}
	}, []);

	useEffect(() => {
		//notification redirection for My Goal
		if (redirectedPerformanceGoalDetails) {
			const { tabId, cycleId, employeeId, categoryId, conversationId, conversationsReplyId } =
				redirectedPerformanceGoalDetails || {};
			if (cycleId) {
				setCurrentCycle(cycleId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectedPerformanceGoalDetails]);

	useEffect(() => {
		if (cycleData && cycleData[activeStep]) {
			dispatch(setPerformaceGoalCurrentCycle(cycleData[activeStep]));
		}
	}, [activeStep]);

	useEffect(() => {
		dispatch(setPerformaceGoalCurrentCycle(null));
		setActiveStep(-1);
		dispatch(getGoalCycleSelectorApi());
	}, []);

	useEffect(() => {
		if (goalUserCycle && goalUserCycle.length > 0) {
			setCurrentCycle();
		} else {
			dispatch(setPerformaceGoalCurrentCycle(null));
			setActiveStep(-1);
			setCycleData([]);
		}
	}, [goalUserCycle]);

	const setCurrentCycle = (cycleId = 0) => {
		let data: any[] = goalUserCycle.filter((item: any) => item.status === 3 || item.status === 4);
		let currentCycle: number = data.findIndex((item: any) => item.status === 3);
		if (currentCycle === -1) {
			currentCycle = data.findIndex((item: any) =>
				cycleId ? cycleId === item.performanceGoalCycleId : item.isLastCompletedCycle
			);
		} else if (cycleId) {
			currentCycle = data.findIndex((item: any) => cycleId === item.performanceGoalCycleId);
		}
		setActiveStep(currentCycle);
		setCycleData(data);
		setMaxSteps(data.length);
	};
	const handleNext = () => {
		setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
	};

	return (
		<>
			{!hidePerformaceCycle && cycleData && cycleData.length > 0 && (
				<Box className='tenure-navigation goal-cycle-tenure'>
					<Box className={`tenure-stepper`}>
						<OKRButton
							title={`${
								props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
									? t('futureCycle')
									: props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID
									? t('pastCycle')
									: t('currentCycle')
							}`}
							text={
								cycleData[activeStep]
									? getMonthDateYear(cycleData[activeStep].startDate) +
									  ' - ' +
									  getMonthDateYear(cycleData[activeStep].endDate)
									: ''
							}
							className='tenure-stepper-text'
							handleClick={() => {}}
							id='tenure-btn'
						/>

						<MobileStepper
							steps={maxSteps}
							position='static'
							variant='progress'
							activeStep={activeStep}
							nextButton={
								<OKRButton
									title={`${t('nextCycle')}`}
									className='tenure-next'
									id='tenure-btn-next'
									handleClick={handleNext}
									disabled={activeStep === maxSteps - 1}
									icon={<RightArrow />}
								/>
							}
							backButton={
								<OKRButton
									title={`${t('previousCycle')}`}
									className='tenure-previous'
									id='tenure-btn-previous'
									handleClick={handleBack}
									disabled={activeStep === 0}
									icon={<LeftArrow />}
								/>
							}
						/>
					</Box>
				</Box>
			)}
		</>
	);
};
