import { Box, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteIcon, EditIcon } from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import {
	getRandomBackgroundColor,
	getRandomColor,
	getTime,
	getUserDetails,
	getUserName,
} from '../../../../config/utils';
import {
	deleteCommentsPost,
	getCommentsList,
	getConversationReplyList,
	getLikesList,
	likeRecognization,
} from '../../../../action/recognize';
import { PopperMenu } from '../../../Admin/CommonComponent/PopperMenu';
import { useSnackbar } from 'notistack';
import { CreateReply } from './CreateReply';
import AlertDialog from '../../../Common/Dialog';
import { getLocalStorageItem, removeLocalStorageItem } from '../../../../services/StorageService';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import LikeImg from '../../../../images/Like.svg';
import { ReadMore } from '../ReadMore';
import { KebabIcon } from '../../../../config/svg/CommonSvg';
import { Enums } from '../../../../config/enums';
import { deleteConversation, getConversationTaskReply, likeConversation } from '../../../../action/cfr';
import { ReplyArrow } from '../../../../config/svg/ArrowSvg';

export const ReplyList = (props: any) => {
	const {
		conversationId,
		commentsData,
		updateCount,
		showReplyBox,
		setShowReplyBox,
		setReplyHighlight,
		replyHighlight,
		getSourceId,
		setIsTaskEdited,
		isTaskEdited,
		scrollTo,
		replyUser,
		setReplyUser,
		setPrefilledUser,
		setModalProps,
		setHideBox,
		hideBox,
		replyType,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const [openLike, setOpenLike] = useState<boolean>(false);
	const [apiLoading, setApiLoading] = useState<boolean>(false);
	const [editedConversation, setEditedConversation] = useState<any>({});
	const [conversationAction, setconversationAction] = useState<any>('');
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

	const [postText, setPostText] = React.useState<any>('');
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [replyListData, setReplyListData] = useState<any[]>([]);
	const [likedId, setLikedId] = useState<number>(0);

	const [pagingInfo, setPagingInfo] = useState<any>({
		pageIndex: 1,
		pageSize: 5,
		totalPages: 1,
		totalRecords: 0,
	});
	const loggedInUserDetail = getUserDetails();
	const [likeAnimation, setLikeAnimation] = useState<number>(0);
	const [editCommentId, setEditEditId] = useState<number>(0);
	const [likeData, setLikeData] = useState<any[]>([]);
	const [savedId, setSavedId] = useState(0);
	const [clickedData, setClickedData] = useState<any>({});

	let userDetail = getUserDetails();

	useEffect(() => {
		if (replyHighlight) {
			getReplies(1, 99999);
			setReplyHighlight(false);
		} else {
			getReplies();
		}
	}, []);

	React.useEffect(() => {
		if (hideBox) {
			setHideBox(false);
			setShowReplyBox(false);
			setEditedConversation({});
			setClickedData({});
		}
	}, [hideBox]);
	const getReplies = async (pageIndex = 1, pageSize = 5, scrollToEnd = false, isCounterAdd = true) => {
		setEditEditId(0);
		setApiLoading(true);

		let response: any = {};
		if (replyType === 5) {
			response = await dispatch(
				getConversationTaskReply(`conversationId=${conversationId}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
			);
		} else {
			response = await dispatch(
				getConversationReplyList(`conversationId=${conversationId}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
			);
		}
		if (Boolean(response) && response.data.status === 200) {
			setPagingInfo(response.data.pagingInfo ? response.data.pagingInfo : pagingInfo);
			if (response.data.pagingInfo?.pageIndex === 1) {
				setReplyListData(response.data.entityList);
				updateCount(props.conversationId, isCounterAdd, 'comment', response.data.pagingInfo?.totalRecords);
			} else {
				if (response.data.entityList && response.data.pagingInfo?.pageIndex > 1) {
					setReplyListData([...replyListData, ...response.data.entityList]);
				} else if (response.data.entityList === null || response.data.entityList.length === 0) {
					setReplyListData([]);
				}

				if (!response.data.pagingInfo?.pageIndex) {
					updateCount(props.conversationId, isCounterAdd, 'comment', 0);
				}
			}

			let conversationHighlight: any = getLocalStorageItem('conversationHighlight');
			if (Boolean(conversationHighlight)) {
				conversationHighlight = JSON.parse(conversationHighlight);
				if (conversationHighlight.conversationsReplyId > 0) {
					setSavedId(conversationHighlight.conversationsReplyId);
					const selectedEl = document.getElementById(`${'commentPost-' + conversationHighlight.conversationsReplyId}`);
					if (selectedEl) {
						selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
					}
				}
			}
			setApiLoading(false);

			if (scrollToEnd) {
				let responseIndex = response.data.entityList.length - 1;
				if (responseIndex >= 0 && response.data.entityList && response.data.entityList[responseIndex]) {
					setTimeout(() => {
						const selectedEl = document.getElementById(
							`${'commentPost-' + response.data.entityList[responseIndex].conversationId}`
						);
						if (selectedEl) {
							selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
						}
					}, 500);
				}
			} else if (showReplyBox) {
				scrollTo('create-reply-box');
			}

			setTimeout(() => {
				setSavedId(0);
				removeLocalStorageItem('conversationHighlight');
			}, 7000);
		} else {
			setApiLoading(false);
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			handleConfirmClick();
		}
		setOpenDeleteDialog(!openDeleteDialog);
		setEditedConversation({});
		setconversationAction('');
	};
	const handleConfirmClick = async () => {
		let data = `conversationId=${editedConversation.conversationId}`;
		const deleteTaskResponse: any = await dispatch(deleteConversation(data));
		if (Boolean(deleteTaskResponse) && deleteTaskResponse.data.status === 200) {
			getReplies(1, pagingInfo.pageIndex * 5, false, false);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const likeReplyList = async (conversation: any, like: any) => {
		setLikeAnimation(conversation.conversationId);
		let payload = {
			conversationId: conversation.conversationId,
			employeeId: loggedInUserDetail.employeeId,
			isActive: !conversation.isLiked,
			ModuleDetailsId: conversation.conversationId,
			ModuleId: replyType === 5 ? 7 : 1,
		};
		let response: any = await dispatch(likeConversation(payload));
		if (response.data.status === Enums.STATUS_SUCCESS) {
			let counter = like ? 1 : -1;
			let replyListDataCopy = replyListData.map((listData: any) => listData);
			const index = replyListDataCopy.findIndex(
				(listData: any) => conversation.conversationId === listData.conversationId
			);
			if (index > -1) {
				replyListDataCopy[index].isLiked = like;
				replyListDataCopy[index].totalLikeCount += counter;
				if (like) {
					replyListDataCopy[index].conversationReactions.push({
						fullName: loggedInUserDetail.firstName + ' ' + loggedInUserDetail.lastName,
						employeeId: loggedInUserDetail.employeeId,
						imagePath: loggedInUserDetail.imagePath,
						emailId: loggedInUserDetail.emailId,
					});
				} else {
					var indexC = replyListDataCopy[index].conversationReactions.findIndex(
						(x: any) => x.employeeId === loggedInUserDetail.employeeId
					);
					if (indexC !== -1) {
						replyListDataCopy[index].conversationReactions.splice(indexC, 1);
					}
				}
			}
			setReplyListData(replyListDataCopy);
		} else {
			enqueueSnackbar(response?.data?.messageList.message, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleOpen = async (event: any, item: any) => {
		setAnchorEl(event.currentTarget);
		setOpenLike(true);
		setLikedId(item.conversationId);
	};
	const handleClose = () => {
		setOpenLike(false);
		setAnchorEl(null);
		setLikeData([]);
		setLikedId(0);
	};

	const handleClick = (event: any, data: any) => {
		if (isTaskEdited) {
			setModalProps({ open: true, event: event });
		} else {
			setModalProps({ open: false, event: {} });
			setAnchorEl(anchorEl ? null : event.currentTarget);
			setOpen(true);
			setClickedData(data);
		}
		setLikeAnimation(0);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setOpen(false);
		setClickedData({});
	};

	const editConversation = (conversation: any) => {
		handleMenuClose();
		setTimeout(function () {
			setEditedConversation(conversation);
		}, 200);
	};

	const handleDeleteConversation = (conversation: any) => {
		setEditedConversation(conversation);
		setOpenDeleteDialog(!openDeleteDialog);
		handleMenuClose();
		setconversationAction('delete');
	};

	return (
		<>
			{replyListData.length > 0 &&
				replyListData.map((item: any) => {
					return (
						<>
							<Box
								className={`cfr-reply-list-info ${savedId === item.conversationId ? 'highlight' : ''}`}
								id={`${'commentPost-' + item.conversationId}`}
							>
								<Box className='cfr-reply-list-content'>
									{item.imagePath ? (
										<Avatar className={'user-avtar'} src={item.imagePath} />
									) : (
										<Avatar
											className={'user-avtar'}
											style={{
												backgroundColor: getRandomBackgroundColor(item.backGroundColorCode),
												color: getRandomColor(item.colorCode),
											}}
										>
											{getUserName(item)}
										</Avatar>
									)}
									<Box className='comment-section'>
										<Box className='cfr-list-inner'>
											<Box className='cfr-list-content'>
												<Typography className='author-name'>{item.fullName}</Typography>
												<Box className='timestamp'>
													<Typography variant='body2'>{getTime(item.createdOn)}</Typography>{' '}
													{item.isEdited ? <Typography variant='body2'>{t('editedLabel')}</Typography> : <></>}
												</Box>
												<Box className='collapse-content'>
													{editedConversation?.conversationId &&
													item.conversationId === editedConversation.conversationId &&
													conversationAction !== 'delete' ? (
														<>
															{' '}
															<CreateReply
																getReplies={getReplies}
																conversationId={props.conversationId}
																replyUser={replyUser}
																updateCount={updateCount}
																replyPageIndex={pagingInfo.pageIndex}
																getSourceId={getSourceId}
																replyData={editedConversation}
																setEditedConversation={setEditedConversation}
																setShowReplyBox={setShowReplyBox}
																setIsTaskEdited={setIsTaskEdited}
																replyType={replyType}
															/>{' '}
														</>
													) : (
														<ReadMore {...props} text={item.description} isHtmlContent={true} />
													)}
												</Box>
											</Box>
											{loggedInUserDetail.employeeId === item.createdBy && (
												<OKRButton
													title={t('moreOptions')}
													className={`menu-btn ${
														open && clickedData.conversationId === item.conversationId ? 'active' : ''
													}`}
													handleClick={(e: any) => {
														handleClick(e, item);
													}}
													icon={<KebabIcon />}
												/>
											)}
										</Box>
										<Box className='cfr-reply-action'>
											<Box className='cfr-reply-btm-left'>
												{!item.isLiked ? (
													<OKRButton
														className='like-btn'
														handleClick={() => {
															likeReplyList(item, true);
														}}
														text={t('likeLevel')}
													/>
												) : (
													<Box className='like-filled-btn'>
														<OKRButton
															className={`like-btn ${likeAnimation === item.conversationId ? 'btn-anim' : ''}`}
															icon={
																<>
																	{likeAnimation === item.conversationId && (
																		<AnimatedIcon
																			className='like-animation-img'
																			width='60'
																			type='image/svg+xml'
																			alt='No user'
																			imagePath={LikeImg}
																		/>
																	)}
																</>
															}
															text={<span className='remove-img'>{t('likedLevel1')}</span>}
															handleClick={() => {
																likeReplyList(item, false);
																setLikeAnimation(0);
															}}
														/>
													</Box>
												)}

												<OKRButton
													className='reply-btn'
													text={t('reply')}
													handleClick={() => {
														setShowReplyBox(true);
														setPrefilledUser(item);
														scrollTo('create-reply-box');
													}}
												/>
											</Box>
											<Box className='cfr-reply-btm-right'>
												<List disablePadding>
													{
														<ListItem
															aria-owns={open ? 'mouse-over-popover' : undefined}
															aria-haspopup='true'
															onMouseEnter={(e: any) => {
																item.totalLikeCount > 0 && handleOpen(e, item);
															}}
															onMouseLeave={handleClose}
															className={item.totalLikeCount > 0 ? 'active' : ''}
														>
															<ListItemText
																className={item.totalLikeCount > 0 ? 'like-popup' : ''}
																primary={`${item.totalLikeCount} ${
																	item.totalLikeCount > 1 ? t('likedLevel') : t('likeLevel')
																}`}
															/>

															{openLike && item.conversationId === likedId && (
																<Box>
																	<PopperMenu
																		{...props}
																		children={
																			<>
																				{item?.conversationReactions?.length > 0 &&
																					item.conversationReactions.map((likeList: any) => {
																						return (
																							<ListItem>
																								<Box className='user-info'>
																									<Box className='user-img'>
																										{likeList?.imagePath ? (
																											<Avatar src={likeList?.imagePath} />
																										) : (
																											<Avatar>
																												{getUserName({
																													fullName: '',
																													firstName: likeList?.firstName,
																													lastName: likeList?.lastName,
																												})}
																											</Avatar>
																										)}
																									</Box>
																									<Box className='user-info-details'>
																										{likeList?.fullName?.length > 30 ? (
																											<Tooltip title={likeList.fullName} arrow>
																												<Typography variant='subtitle2'>
																													{likeList?.fullName.substring(0, 30)}
																													...
																												</Typography>
																											</Tooltip>
																										) : (
																											<Typography>{likeList.fullName}</Typography>
																										)}

																										{likeList?.emailId?.length > 28 ? (
																											<Tooltip title={likeList.emailId} arrow>
																												<Typography variant='subtitle2'>
																													{likeList?.emailId.substring(0, 28)}
																													...
																												</Typography>
																											</Tooltip>
																										) : (
																											<Typography variant='subtitle2'>{likeList?.emailId}</Typography>
																										)}
																									</Box>
																								</Box>
																							</ListItem>
																						);
																					})}
																			</>
																		}
																		popperClassName={'conversation-drawer-like recog-liked-by-dropdown'}
																		anchorEl={anchorEl}
																		handleClose={handleClose}
																		popperPlacement={'bottom'}
																		open={openLike}
																	/>
																</Box>
															)}
														</ListItem>
													}
												</List>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					);
				})}

			<PopperMenu
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				popperClassName={'popper-menu popper-menu-type2 popper-cfr-menu'}
				{...props}
				children={
					<>
						<ListItem
							onClick={() => {
								editConversation(clickedData);
							}}
						>
							<ListItemAvatar>
								<EditIcon />
							</ListItemAvatar>

							<ListItemText primary={t('editLabel')} />
						</ListItem>
						<ListItem
							onClick={() => {
								handleDeleteConversation(clickedData);
							}}
						>
							<ListItemAvatar>
								<DeleteIcon />
							</ListItemAvatar>
							<ListItemText primary={t('delete')} />
						</ListItem>
					</>
				}
			></PopperMenu>
			{showReplyBox && !editedConversation?.conversationId && !apiLoading && (
				<>
					<Box className='cfr-reply-list-info'>
						<Box className='cfr-reply-list-content'>
							{loggedInUserDetail && loggedInUserDetail.imagePath ? (
								<Avatar
									className='avatar-default'
									alt={`${loggedInUserDetail.firstName} ${loggedInUserDetail.lastName}`}
									src={loggedInUserDetail.imagePath}
								/>
							) : (
								<Avatar className='avatar-default'>
									{getUserName({
										firstName: loggedInUserDetail.firstName,
										lastName: loggedInUserDetail.lastName,
										fullName: loggedInUserDetail.fullname,
									})}
								</Avatar>
							)}

							<Box className='comment-section'>
								<CreateReply
									getReplies={getReplies}
									conversationId={props.conversationId}
									replyUser={replyUser}
									updateCount={updateCount}
									replyPageIndex={pagingInfo.pageIndex}
									getSourceId={getSourceId}
									setEditedConversation={setEditedConversation}
									setShowReplyBox={setShowReplyBox}
									setIsTaskEdited={setIsTaskEdited}
									replyType={replyType}
								/>
							</Box>
						</Box>
					</Box>
				</>
			)}

			<Box className='load-more-area'>
				{pagingInfo?.totalPages > 1 && pagingInfo?.totalPages !== pagingInfo.pageIndex && (
					<Box className='load-more'>
						<OKRButton
							handleClick={(e) => {
								getReplies(pagingInfo.pageIndex + 1 || 1, 5);
							}}
							className={`load-more-btn btn-small-text`}
							text={t('viewMoreReply')}
						/>
					</Box>
				)}
				<Box className='view-less'>
					<OKRButton
						handleClick={() => {
							props.setReplyCommentId(0);
							scrollTo(`${'recogConversationPost-' + props.conversationId}`);
						}}
						className={`load-more-btn btn-small-text`}
						text={t('hideReply')}
					/>
				</Box>
			</Box>

			{openDeleteDialog && (
				<AlertDialog
					module='information'
					message={t('deleteConversationReply')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}
		</>
	);
};
