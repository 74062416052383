import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Chip,
	FormControlLabel,
	FormLabel,
	Grow,
	InputAdornment,
	Link,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@material-ui/core';
import { EmailIcon, ErrorIcon, NameIcon, ReportingToIcon } from '../../../../../config/svg/formElementIcons';
import { ReversalStageDropdown } from './ReversalStageDropdown';
import { OKRButton } from '../../../../Common/OKRButton';
import { GoalFormIcon } from '../../../../../config/svg/MyGoalSvg';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../../../config/enums';
import {
	goalCycleRecordList,
	savePerformanceWorkflow,
	savePerformanceWorkflowCurrentStage,
	searchOngoingEmployee,
} from '../../../../../action/adminSettings';
import { NoSearchRecord } from '../../../CommonComponent/NoSearchRecord';
import { MultiSelect } from '../../../CommonComponent/MultiSelect';
import { useSnackbar } from 'notistack';
import { BackArrow } from '../../../../../config/svg/GlobalSvg';
import NoDataFound from '../../../../Common/NoDataFound';

export const GoalWorkflow: React.FC<any> = (props) => {
	const { workflowReversalOpen, setWorkflowReversalOpen, setTabSelected } = props;
	const { t } = useTranslation();
	const [radioButtonDefaultValue, setRadioButtonDefaultValue] = useState<any>('1');
	const [allEmpChecked, setAllEmployeeChecked] = useState<any>(false);
	const { goalUserCycle } = useSelector((state: any) => state?.adminSettingReducer);
	const [selectedCycle, setSelectedCycle] = useState<any>([]);
	const [reversalStatus, setReversalStatus] = useState<any>([]);
	const [cycleList, setCycleList] = useState<any>([]);
	const [formData, setFormData] = useState<any>({ selectedCycle: [], userData: [], type: 1 });
	const dispatch = useDispatch();
	const [formErrors, setFormErrors] = useState<any>({});
	const { enqueueSnackbar } = useSnackbar();
	const [cycleFetched, setCycleFetched] = useState<boolean>(false);
	const [optionsList, setOptionsList] = useState<any>([]);

	useEffect(() => {
		loadCycles();
		setCycleFetched(true);
	}, [goalUserCycle]);

	const loadCycles = async () => {
		let requestPayload = {
			finder: '',
			pageIndex: 1,
			sortingText: 'createdOn',
			order: 'desc',
			pageSize: 100,
		};
		try {
			const response: any = await dispatch(goalCycleRecordList(requestPayload));
			if (response) {
				if (response && response.data && response.data.status === 200) {
					const { entityList } = response.data;
					if (entityList) {
						let records = entityList;
						if (records && records.length) {
							records = records.filter((item: any) => item.status !== 1 && item.status !== 2 && item.status !== 4);
							let recordsCpy = records.map((item: any) => {
								return {
									...item,
									performanceCycleId: item.performanceGoalCycleId,
									label: item.name,
									id: item.performanceGoalCycleId,
								};
							});
							setCycleList(recordsCpy);
						} else {
							setCycleList([]);
						}
					} else {
						setCycleList([]);
					}
				} else {
					setCycleList([]);
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
	const handleRadioButtonChange = (e: any) => {
		const { target } = e;
		setRadioButtonDefaultValue(target.value);
		setFormData({ ...formData, type: parseInt(target.value), status: {}, userData: {} });
		setAllEmployeeChecked(target.checked && target.value === '1' ? false : true);
	};
	const onSelectUser = (data: any) => {
		if (data && data.length < 2) {
			if (data.length > 0) {
				fetchUserStatus(data);
			} else {
				setFormData({ ...formData, status: {}, userData: data });
			}
		} else {
			setFormData({ ...formData, userData: data[1] });
			fetchUserStatus([data[1]]);
		}
		setFormErrors({ ...formErrors, receiver: '' });
	};
	const fetchUserStatus = async (userId: any[]) => {
		if (formData?.selectedCycle?.id) {
			let requestParam: any = `performanceGoalCycleId=${formData.selectedCycle.id}&employeeId=${userId[0].employeeId}`;
			let response: any = await dispatch(savePerformanceWorkflowCurrentStage(requestParam));
			if (response) {
				if (response?.data && response?.data?.status === 200) {
					const { messageList, isSuccess, entity } = response.data;
					if (isSuccess) {
						setFormData({ ...formData, userData: userId, status: entity });
						let reversalData: any[] = entity?.reversalStatus?.map((item: any, index: any) => {
							return { status: index, statusName: item };
						});
						setReversalStatus(reversalData);
					}
				} else {
					setFormData({ ...formData, userData: userId });
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					enqueueSnackbar(messages || 'Error occurred while adding performance review', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			}
		}
	};
	const searchCustomEmployee = (searchStr: string) => {
		return dispatch(searchOngoingEmployee(searchStr));
	};

	const saveForm = async () => {
		let error: any = {};
		if (formData.type === 2 && formData.userData.length === 0) {
			error.receiver = 'Please select user';
		}
		if (!formData.selectedCycle.id) {
			error.selectedCycle = 'Please select cycle';
		}
		if (!formData.selectedSatus) {
			error.status = 'Please select status';
		}
		if (Object.keys(error).length > 0) {
			setFormErrors(error);
			return false;
		}
		let goalData: any = {
			employeeId:
				formData.type === 2 && formData.userData && formData.userData.length > 0 ? formData.userData[0].employeeId : 0,
			performanceGoalCycleId: formData.selectedCycle.id,
			status: formData.selectedSatus?.status ? formData.selectedSatus?.status : 0,
		};
		let response: any = await dispatch(savePerformanceWorkflow(goalData));
		if (response) {
			if (response?.data && response?.data?.status === 200) {
				const { messageList, isSuccess, entity } = response.data;
				if (isSuccess) {
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					setFormData({ selectedCycle: [], userData: [], type: 1, status: 0 });
					setSelectedCycle([]);
					setRadioButtonDefaultValue('1');
					enqueueSnackbar(messages && messages.length > 0 ? messages : `Form Saved Successfully`, {
						variant: 'success',
						autoHideDuration: 3000,
					});
				} else {
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while saving form', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || 'Error occurred while adding performance review', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		}
	};

	const handleBackButtonOnClick = (event: any) => {
		setWorkflowReversalOpen({ open: false, data: {} });
		setTabSelected(2);
	};

	return (
		<Box className='goal-workflow-settings-area'>
			<Box className='admin-tabpanel-inner'>
				<Box className='admin-tabpanel-head' id='top-section'>
					<Box className='back-to-main' onClick={handleBackButtonOnClick}>
						<Typography variant='body2'>
							<Link id='back-btn'>
								<BackArrow /> {t('back')}
							</Link>
						</Typography>
					</Box>
					<Typography variant='h2'>{t('workflowReversal')}</Typography>
				</Box>
			</Box>
			<Box className='full-rectangular-card'>
				<Box className='goal-workflow-type'>
					<Box className='settings-fields settings-fields-col3'>
						<Box className={`settings-fields-col ${formErrors.selectedCycle ? 'select-error helperText-bottom' : ''}`}>
							<FormLabel component='legend'>{t('selectCycle')}</FormLabel>
							<MultiSelect
								key={'performanceGoalFormDropdown'}
								id='performance-goal-form-dropdown'
								selectedOptions={selectedCycle}
								optionsList={cycleList}
								onSelectOption={(value: any) => {
									setSelectedCycle(value);
									setFormData({ ...formData, selectedCycle: value });
									setFormErrors({ ...formErrors, selectedCycle: '' });
								}}
								customFilter={() => {}}
								placeHolder={t('selectCycle')}
								noOptionsMessage={<NoDataFound />}
								showNoOptions={!cycleList || cycleList.length === 0}
								fetchAsyncData={true}
								isSingleSelection={false}
								performSearch={() => {}}
								closeMenuOnSelect={true}
								labelTemplate={'onlyLabel'}
								selectClassName={`dropdown-default-wrapper`}
								selectClassNamePrefix={'dropdown-default'}
								disableSearch={true}
								helperText={
									formErrors.selectedCycle ? (
										<>
											<ErrorIcon />
											{formErrors.selectedCycle}
										</>
									) : (
										''
									)
								}
							/>
						</Box>
					</Box>
				</Box>
				<Box className='goal-workflow-radio-box'>
					<RadioGroup
						row
						aria-label='workflowEmployee'
						name='workflowEmployee'
						value={`${radioButtonDefaultValue}`}
						onChange={(e) => handleRadioButtonChange(e)}
					>
						<FormControlLabel
							disabled={!formData?.selectedCycle?.id}
							value={'1'}
							control={<Radio />}
							title={!formData?.selectedCycle?.id ? 'Please select cycle to proceed' : ''}
							label={t('AllTabLabel')}
						/>
						<FormControlLabel
							disabled={!formData?.selectedCycle?.id}
							value={'2'}
							title={!formData?.selectedCycle?.id ? 'Please select cycle to proceed' : ''}
							control={<Radio />}
							label={t('employeeLabel')}
						/>
					</RadioGroup>
				</Box>
				{formData.type === 2 && (
					<Box className='settings-fields settings-fields-col3'>
						<Box className='settings-fields-col'>
							<Box className={`goal-workflow-search ${formErrors.receiver ? 'select-error helperText-bottom' : ''}`}>
								<MultiSelect
									key={'reportingTo'}
									id='recognize-to-dropdown'
									selectedOptions={props.selectedUser}
									onSelectOption={onSelectUser}
									blurCalled={(value: any) => {}}
									setOptionsList={setOptionsList}
									optionsList={optionsList}
									blurInputOnSelect={true}
									isMulti={true}
									customFilter={() => {}}
									isLoadMore={true}
									placeHolder={'Search Employee'}
									noOptionsMessage={<NoSearchRecord />}
									//showNoOptions={emptyRecords.reportingTo === true}
									fetchAsyncData={true}
									isSingleSelection={false}
									closeMenuOnSelect={true}
									labelTemplate={'avatarLabel'}
									selectClassName={`select-search-dropdown select-search-icon ${
										props.selectedUser?.length === 0 && props.focussed ? 'error' : ''
									}`}
									selectClassNamePrefix={'react-select'}
									error={(formErrors['receiver'] && formErrors['receiver']) || ''}
									helperText={
										formErrors['receiver'] &&
										formErrors['receiver'] && (
											<>
												<ErrorIcon />
												{(formErrors['receiver'] && formErrors['receiver']) || ''}
											</>
										)
									}
									onInputFocus={() => {}}
									disabled={props.disabled}
									performSearch={searchCustomEmployee}
									customQueryString={'&performanceGoalCycleId=' + selectedCycle?.id}
								/>
							</Box>
						</Box>
					</Box>
				)}
				<>
					{formData.type === 2 && formData.userData && formData.userData.length > 0 ? (
						<Box className='settings-fields settings-fields-col3'>
							<Box className='settings-fields-col'>
								<TextField
									fullWidth
									id='firstName'
									className='field-col2'
									label={
										<Fragment>
											<NameIcon />
											{t('nameLabel')}
										</Fragment>
									}
									disabled={true}
									value={formData.userData.length > 0 ? formData.userData[0].label : ''}
									name={t('nameLabel')}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ 'aria-label': t('nameLabel'), disableUnderline: true }}
								/>
							</Box>
							<Box className='settings-fields-col'>
								<TextField
									fullWidth
									id='emailAddress'
									className='field-col2'
									label={
										<Fragment>
											<EmailIcon />
											{t('emailAddressLabel')}
										</Fragment>
									}
									disabled={true}
									value={formData.userData.length > 0 ? formData.userData[0].emailId : ''}
									name={t('emailAddressLabel')}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										'aria-label': t('emailAddressPlaceholder'),
										disableUnderline: true,
									}}
								/>
							</Box>
							<Box className='settings-fields-col'>
								<TextField
									fullWidth
									id='reportingManager'
									className='field-col2'
									label={
										<Fragment>
											<ReportingToIcon />
											{t('reportingManager')}
										</Fragment>
									}
									disabled={true}
									value={formData.userData.length > 0 ? formData.userData[0].reportingName : ''}
									name={t('reportingManager')}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ 'aria-label': t('reportingManager'), disableUnderline: true }}
								/>
							</Box>
						</Box>
					) : (
						''
					)}
				</>
				{formData.type === 2 && formData.status && formData.status.statusName ? (
					<Box className='settings-fields'>
						<Box className='settings-fields-col current-stage-col'>
							<FormLabel component='legend'>
								<GoalFormIcon />
								{t('currentGoalStatus')}
							</FormLabel>

							<Box className='goal-stage-status'>
								<Chip
									className={
										formData?.status?.status === Enums.ZERO
											? 'default-grey-chip'
											: formData?.status?.status === Enums.ONE
											? 'draft-chip'
											: formData?.status?.status === Enums.TWO
											? 'lagging-chip'
											: formData?.status?.status === Enums.THREE
											? 'default-grey-chip'
											: formData?.status?.status === Enums.FOUR
											? 'on-track-chip'
											: ''
									}
									label={formData?.status?.statusName}
								/>
							</Box>
						</Box>
					</Box>
				) : (
					''
				)}
				{formData.type === 1 || (formData.type === 2 && formData.status && formData.status.statusName) ? (
					<Box className='settings-fields'>
						<Box className='settings-fields-col'>
							<FormLabel component='legend'>
								<GoalFormIcon />
								{t('revertGoalStatus')}
							</FormLabel>
							<ReversalStageDropdown
								type={formData.type}
								reversalStatus={reversalStatus}
								statusValue={formData.status}
								handleChange={(item: any) => {
									setFormData({ ...formData, selectedSatus: item });
									setFormErrors({ ...formErrors, status: '' });
								}}
								errorMessage={formErrors.status}
							/>
						</Box>
					</Box>
				) : (
					''
				)}
			</Box>
			<Box className='goal-settings-actions'>
				<OKRButton
					className='btn-link'
					id='cancel-btn'
					text={t('cancelBtn')}
					handleClick={() => {
						setFormData({ selectedCycle: [], userData: [], type: 1, status: 0 });
						setSelectedCycle([]);
						setRadioButtonDefaultValue('1');
					}}
				/>
				<OKRButton
					className='btn-primary'
					id='save-btn'
					text={t('saveBtn')}
					handleClick={saveForm}
					disabled={formData?.status?.status === Enums.ZERO}
				/>
			</Box>
		</Box>
	);
};
