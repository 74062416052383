import React from 'react';
import { Box, FormControlLabel, FormLabel, List, ListItem, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from './OKRButton';
import { WeeklyTrendsIcon } from '../../config/svg/CommonSvg';
import { confidenceIcon, confidenceIconId } from '../../config/utils';

export const KrConfidence: React.FC<any> = (props) => {
	const {
		confidenceLabel,
		handleOpenWeeklyTrend,
		confidenceValue,
		handleConfidenceClick,
		showWeekelyTrend,
		showOnlySelected,
		showConfidenceLevelLabel,
	} = props;
	const { t } = useTranslation();

	return (
		<Box className='confidence-selection'>
			{confidenceLabel && (
				<>
					<FormLabel id='confidence-label' className='label-des'>
						{t('confidenceLabel')}
					</FormLabel>
					<Typography id='confidence-helptext' className='short-des-text'>
						{t('confidenceDes')}
					</Typography>
				</>
			)}
			<Box className='confidence-row'>
				<List disablePadding component={'div'}>
					{props.okrMasterData?.confidences.map((item: any) =>
						(item.confidenceValue !== 0 && !showOnlySelected) ||
						(showOnlySelected && item.confidenceValue === confidenceValue) ? (
							<ListItem component={'div'}>
								<OKRButton
									className={`${item.confidenceValue === confidenceValue ? 'active ' : ''}`}
									id={confidenceIconId(item.confidenceValue)}
									// tooltipClassName={`${
									// 	item.confidenceValue !== 0 && 'tooltip-layout3 ' + confidenceIconId(item.confidenceValue)
									// }`}
									tooltipClassName={`${item.confidenceId === 3 && 'tooltip-layout4'}`}
									text={confidenceLabel || showConfidenceLevelLabel ? item.confidenceName : ''}
									icon={confidenceIcon(item.confidenceValue)}
									title={
										<>
											<Typography>{item.confidenceName}</Typography>
											{item.confidenceValue !== 0 && <em>{item.description}</em>}
										</>
									}
									handleClick={() => handleConfidenceClick(item.confidenceValue)}
								/>
							</ListItem>
						) : (
							<></>
						)
					)}
				</List>
				{/* {showWeekelyTrend && (
					<OKRButton
						className='btn-link-type1'
						id='weekly-trend'
						text={t('weeklyTrendsLabel')}
						icon={<WeeklyTrendsIcon />}
						handleClick={handleOpenWeeklyTrend}
						//disabled={props.isPastCycle || props.isFutureCycle}
					/>
				)} */}
			</Box>
		</Box>
	);
};
