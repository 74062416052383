import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Chip,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { BackArrow } from '../../../../config/svg/GlobalSvg';
import { OKRButton } from '../../../Common/OKRButton';
import { RequestOneOnOneIcon2 } from '../../../../config/svg/PersonalFeedbackSvg';
import { PopperMenu } from '../../../Admin/CommonComponent/PopperMenu';
import {
	AlertInfoIcon,
	CompletedDateIcon,
	CreatedDateIcon,
	DeleteIcon,
	EditIcon,
	ResolveIcon,
	ExtendTimeframeIcon,
} from '../../../../config/svg/CommonSvg';
import { CalendarIcon, PipIcon } from '../../../../config/svg/CommonSvgIcon';
import { ReadMore } from '../../../Goals/Cfr/ReadMore';
import { UserAvatarGroup } from '../../../Common/UserAvatarGroup';

import { FeedbackAttachmentList } from './FeedbackAttachmentList';
import { FeedbackViewTabs } from './FeedbackViewTabs';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../../config/enums';
import { getFeedbackDetailsForView, reloadDetailsPage } from '../../../../action/performanceImprovementPlan';
import { getDateMonthTime, getMonthDateYearTime, getUserName } from '../../../../config/utils';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import animatedTag from '../../../../images/tag_animation.svg';

export const ViewFeedback = (props: any) => {
	const {
		backClicked,
		feedbackFormType,
		feedbackItem,
		loader,
		setLoader,
		showMsg,
		handleOneOnOneClick,
		setIsFormEdited,
		userDetails,
		isUserHaveFeedbackEditPermission,
		isUserHavePIPPermission,
		resolveStatusTypes,
		handleDeleteFeedback,
		handleEditFeedback,
		handleCreatePIPFormOpenClick,
		handleResolveClick,
		select1on1Tab,
		setSelect1on1Tab,
		handleTabChange,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [feedbackDetails, setFeedbackDetails] = useState<any>(null);
	const [creatorDetailsVal, setCreatorDetailsVal] = useState<any>(null);
	const [reviewerDetailsVal, setReviewerDetailsVal] = useState<any>([]);

	useEffect(() => {
		if (feedbackDetails?.feedbackReviewerResponse && feedbackDetails?.feedbackReviewerResponse?.length) {
			let feedbackReviewerResponse = [...feedbackDetails?.feedbackReviewerResponse];
			const creatorDetails = feedbackDetails?.feedbackReviewerResponse[0];
			feedbackReviewerResponse.shift();
			setCreatorDetailsVal(creatorDetails);
			setReviewerDetailsVal(feedbackReviewerResponse);
		}
	}, [feedbackDetails]);
	useEffect(() => {
		getFeedbackViewDetails(feedbackItem);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { reloadFeeback } = useSelector((state: any) => state?.performanceImprovementPlanReducer);

	useEffect(() => {
		if (reloadFeeback) {
			dispatch(reloadDetailsPage(false));
			getFeedbackViewDetails(feedbackItem);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadFeeback]);
	const getFeedbackViewDetails = async (feedbackItemDetails: any) => {
		try {
			if (feedbackItemDetails?.feedbackId) {
				setLoader(true);
				const requestParam = `${feedbackItemDetails?.feedbackId || Enums.ZERO}`;
				const response: any = await dispatch(getFeedbackDetailsForView(requestParam));
				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					setFeedbackDetails(response?.data?.entity);
					setLoader(false);
					// getReviewerDatesAPICall(feedbackItemDetails?.feedbackId || Enums.ZERO, response?.data?.entity);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Error occurred while fetching personal development plan.`);
					setFeedbackDetails(null);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleCreatePIPClick = (e: any, itemDetails: any) => {
		handleCreatePIPFormOpenClick(e, itemDetails, feedbackItem);
	};

	// console.log('feedbackDetails===>', feedbackDetails);

	return (
		<Box className='pip-view-mode'>
			{feedbackFormType !== 'PIP' ? (
				<Box className='pip-view-sub-head'>
					<Box className='pip-view-sub-head-left'>
						<Box className='back-to-main'>
							<Typography variant='body2' onClick={() => backClicked(false)}>
								<Link id='back-btn'>
									<BackArrow /> {t('back')}
								</Link>
							</Typography>
						</Box>
						<Box className='user-info'>
							<Box className='user-img'>
								{feedbackDetails?.nomineeDetails ? (
									<>
										{feedbackDetails?.nomineeDetails?.imagePath ? (
											<Avatar className='avatar-default' src={feedbackDetails?.nomineeDetails.imagePath} />
										) : (
											<Avatar className={'avatar-default'}>
												{getUserName({
													firstName: feedbackDetails?.nomineeDetails?.firstName || '',
													lastName: feedbackDetails?.nomineeDetails?.lastName || '',
													fullName: feedbackDetails?.nomineeDetails?.fullName || '',
												})}
											</Avatar>
										)}
									</>
								) : (
									<></>
								)}
							</Box>

							<Box className='user-info-details'>
								<Typography variant='h4'>{`${feedbackDetails?.nomineeDetails?.firstName || ''} ${
									feedbackDetails?.nomineeDetails?.lastName || ''
								}`}</Typography>
							</Box>
						</Box>
					</Box>
					<Box className='pip-view-sub-head-right'>
						<OKRButton
							className='btn-link-type1 font-weight-bold'
							icon={<RequestOneOnOneIcon2 />}
							text={t('requestOnetoOne')}
							handleClick={(event: any) =>
								handleOneOnOneClick(event, 'ViewPip', feedbackDetails?.nomineeDetails?.employeeId)
							}
						/>
						{feedbackDetails?.nomineeDetails?.employeeId !== userDetails?.employeeId &&
						(feedbackDetails?.createdBy === userDetails?.employeeId || isUserHaveFeedbackEditPermission()) &&
						(feedbackDetails?.feedbackStatusId === Enums.TWO ||
							feedbackDetails?.feedbackStatusId === Enums.FOUR ||
							feedbackDetails?.feedbackStatusId === Enums.FIVE) &&
						feedbackDetails?.timeframeStartDate &&
						feedbackDetails?.timeframeEndDate ? (
							<>
								{/* Changed this for Unsave popup */}
								<Typography variant='body2' onClick={(e: any) => handleResolveClick(e)}>
									<Link id='open_resolve_form_tab' className='btn-link-type1 font-weight-bold'>
										<ResolveIcon /> {t('resolve')}
									</Link>
								</Typography>
								{/* <OKRButton
									className='btn-link-type1 font-weight-bold'
									icon={<ResolveIcon />}
									text={t('resolve')}
									handleClick={handleResolveClick}
								/> */}
							</>
						) : (
							<></>
						)}
						{feedbackDetails?.feedbackStatusId === 2 &&
							feedbackDetails?.feedbackCategoryResponse?.editTimeFrameType !== 2 &&
							feedbackDetails?.feedbackCategoryResponse?.editTimeFrameType !== 1 &&
							feedbackDetails?.feedbackCategoryResponse?.editEndDateType !== 1 &&
							feedbackDetails?.feedbackCategoryResponse?.editTimeFrameType !== 1 &&
							feedbackDetails?.feedbackCategoryResponse?.editTimeFrameType !== 0 && (
								<OKRButton
									wrapClassName='extend-btn-disabled'
									className='btn-link-type1 font-weight-bold'
									icon={<ExtendTimeframeIcon />}
									text={
										feedbackDetails?.feedbackCategoryResponse?.editEndDateType === 1
											? 'Edit End Date'
											: 'Extend End Date'
									}
									handleClick={(event: any) => {
										handleEditFeedback(event, { ...feedbackDetails, fromExtension: true }, feedbackFormType);
									}}
									disabled={!feedbackDetails?.isExtensionsAllowed}
									title={!feedbackDetails?.isExtensionsAllowed ? t('maxExtention') : ''}
									buttonType={!feedbackDetails?.isExtensionsAllowed ? 'disableButtonTooltip' : ''}
								/>
							)}
						{feedbackDetails?.nomineeDetails?.employeeId !== userDetails?.employeeId && isUserHavePIPPermission() ? (
							<>
								{/* Changed this for Unsave popup */}
								<Typography variant='body2' onClick={(e: any) => handleCreatePIPClick(e, feedbackDetails)}>
									<Link id='open_pip_create_form' className='btn-link-type1 font-weight-bold'>
										<PipIcon /> {t('createPipButtonLabel')}
									</Link>
								</Typography>
								{/* <OKRButton
									id='open_pip_create_form'
									className='btn-link-type1 font-weight-bold'
									icon={<PipIcon />}
									text={t('createPipButtonLabel')}
									handleClick={(e) => handleCreatePIPClick(e, feedbackDetails)}
								/> */}
							</>
						) : (
							<></>
						)}
						{feedbackDetails?.nomineeDetails?.employeeId !== userDetails?.employeeId &&
						(feedbackDetails?.createdBy === userDetails?.employeeId || isUserHaveFeedbackEditPermission()) &&
						(feedbackDetails?.feedbackStatusId === Enums.THREE ||
							feedbackDetails?.feedbackStatusId === Enums.ONE ||
							feedbackDetails?.feedbackStatusId === Enums.TWO) ? (
							<PopperMenu
								className={'user-menu-btn'}
								popperClassName={'alignment-popper-menu popper-menu-list performance-dev-popper-menu'}
								anchorEl={anchorEl}
								handleClose={handleClose}
								includeKebabMenu={true}
								menuClicked={popperMenuClicked}
								setPopperMenuClicked={setPopperMenuClicked}
								btnId={`more-btn-c`}
								{...props}
								children={
									<>
										{feedbackDetails?.feedbackStatusId === Enums.ONE ||
										feedbackDetails?.feedbackStatusId === Enums.TWO ||
										feedbackDetails?.feedbackStatusId === Enums.THREE ? (
											<ListItem
												id={`edit_feedback_details`}
												onClick={(event: any) => {
													handleClose();
													handleEditFeedback(event, feedbackDetails, feedbackFormType);
												}}
											>
												<ListItemAvatar>
													<EditIcon />
												</ListItemAvatar>
												<ListItemText primary={t('editLabel')} />
											</ListItem>
										) : (
											<></>
										)}
										<ListItem
											id={`delete_feedback_details`}
											onClick={(event: any) => {
												handleClose();
												handleDeleteFeedback(event, feedbackDetails, feedbackFormType);
											}}
										>
											<ListItemAvatar>
												<DeleteIcon />
											</ListItemAvatar>
											<ListItemText primary={t('delete')} />
										</ListItem>
									</>
								}
							></PopperMenu>
						) : (
							<></>
						)}
					</Box>
				</Box>
			) : (
				<></>
			)}
			<Box className='pip-view-content'>
				<Box className='pip-view-content-left'>
					<Box className='full-rectangular-card pip-view-details'>
						<Box className='pip-status'>
							{/* <Chip className='lagging-chip' label={'Active'} /> */}
							{/* Draft = 1, Submitted = 2, Active = 3, SuccessfullyCleared = 4, Terminate = 5 */}
							{feedbackDetails?.feedbackTypeId !== 1 &&
							feedbackDetails?.timeframeStartDate &&
							feedbackDetails?.timeframeEndDate ? (
								<>
									{feedbackDetails?.feedbackStatusId === Enums.ONE ? (
										<Chip className='draft-chip' label={feedbackDetails?.feedbackStatus} />
									) : feedbackDetails?.feedbackStatusId === Enums.TWO ? (
										<Chip className='lagging-chip' label={feedbackDetails?.feedbackStatus} />
									) : feedbackDetails?.feedbackStatusId === Enums.THREE ? (
										<Chip className='lagging-chip' label={feedbackDetails?.feedbackStatus} />
									) : feedbackDetails?.feedbackStatusId === Enums.FOUR ? (
										<Box className='timeframe-animation'>
											<AnimatedIcon
												className=''
												width='480'
												type='image/svg+xml'
												alt={t('globalSearchNoRecord')}
												imagePath={animatedTag}
											/>
											<Chip className='on-track-chip' label={feedbackDetails?.feedbackStatus} />
										</Box>
									) : feedbackDetails?.feedbackStatusId === Enums.FIVE ? (
										<Chip className='at-risk-chip' label={feedbackDetails?.feedbackStatus} />
									) : (
										<Chip className='grey-chip' label={feedbackDetails?.feedbackStatus} />
									)}
								</>
							) : (
								<></>
							)}
							{feedbackDetails?.feedbackType || feedbackDetails?.feedbackCategory ? (
								<Box className='feedback-type-chip'>
									{feedbackDetails?.feedbackType ? (
										<Chip
											className='review-chip'
											label={
												<>
													{feedbackDetails?.feedbackType ? `${feedbackDetails?.feedbackType}` : ''}
													{feedbackDetails?.feedbackCategory ? <em></em> : ''}
													{feedbackDetails?.feedbackCategory ? `${feedbackDetails?.feedbackCategory}` : ''}
												</>
											}
										/>
									) : (
										<></>
									)}
								</Box>
							) : (
								<></>
							)}
						</Box>
						<Typography variant='h4'>{feedbackDetails?.title || ''}</Typography>
						<Box className='feedback-date-range'>
							<Tooltip title={t('createOnLabelNew')} arrow>
								<Typography variant='h6'>
									<CreatedDateIcon />{' '}
									{feedbackDetails?.createdOn ? getMonthDateYearTime(feedbackDetails?.createdOn) : ''}
								</Typography>
							</Tooltip>
							<Tooltip
								title={feedbackDetails?.isTimeFrameAlert ? t('timeFramePassed') : t('timeFrameText')}
								arrow
								classes={feedbackDetails?.isTimeFrameAlert ? { popper: 'tooltip-alert' } : {}}
							>
								<Typography variant='h6' className={feedbackDetails?.isTimeFrameAlert ? 'alert-field' : ''}>
									{feedbackDetails?.isTimeFrameAlert ? (
										<span className='alert-icon'>
											<AlertInfoIcon />{' '}
										</span>
									) : (
										<></>
									)}

									{feedbackDetails?.timeframeStartDate && feedbackDetails?.timeframeEndDate ? (
										<>
											<CalendarIcon />
											{`${getMonthDateYearTime(feedbackDetails?.timeframeStartDate)} - ${getMonthDateYearTime(
												feedbackDetails?.timeframeEndDate
											)}`}
										</>
									) : (
										<></>
									)}
								</Typography>
							</Tooltip>
							{feedbackDetails?.resolvedOn ? (
								<Tooltip title={t('Resolved On')} arrow>
									<Typography variant='h6'>
										<CompletedDateIcon />
										{feedbackDetails?.resolvedOn ? getMonthDateYearTime(feedbackDetails?.resolvedOn) : ''}
									</Typography>
								</Tooltip>
							) : (
								<></>
							)}
							{feedbackDetails?.timeFrameChangedCount && feedbackDetails?.timeFrameChangedCount > 0 ? (
								<Tooltip
									classes={{ popper: 'timeframe-tooltip' }}
									title={
										<>
											{t('timeFrameChangedOn')}
											<List>
												{feedbackDetails?.feedbackTimeframeResponse?.map((item: any, index: number) => {
													return (
														<>
															<ListItem>
																<span>
																	{item?.extendTimeframeChangeDate
																		? getDateMonthTime(item?.extendTimeframeChangeDate)
																		: ''}
																</span>
																<span>
																	{item?.extendTimeframeStartDate && item?.extendTimeframeEndDate ? (
																		`${getMonthDateYearTime(item?.extendTimeframeStartDate)} - ${getMonthDateYearTime(
																			item?.extendTimeframeEndDate
																		)}`
																	) : (
																		<></>
																	)}
																</span>
																<span>{item.feedbackReasonName}</span>
															</ListItem>
														</>
													);
												})}
											</List>
										</>
									}
									arrow
								>
									<Box className='timeframe-animation'>
										<AnimatedIcon
											className=''
											width='480'
											type='image/svg+xml'
											alt={t('globalSearchNoRecord')}
											imagePath={animatedTag}
										/>
										<Chip className='grey-chip' label={t('timeframeChanged')} />
									</Box>
								</Tooltip>
							) : (
								<></>
							)}
						</Box>
						<ReadMore {...props} text={feedbackDetails?.summary || ''} isHtmlContent={true} />
					</Box>
					<FeedbackViewTabs
						feedbackItem={feedbackItem}
						feedbackFormType={feedbackFormType}
						feedbackDetails={feedbackDetails}
						creatorDetailsVal={creatorDetailsVal}
						reviewerDetailsVal={reviewerDetailsVal}
						loader={loader}
						setIsFormEdited={setIsFormEdited}
						setLoader={setLoader}
						showMsg={showMsg}
						userDetails={userDetails}
						resolveStatusTypes={resolveStatusTypes}
						getFeedbackViewDetails={getFeedbackViewDetails}
						select1on1Tab={select1on1Tab}
						setSelect1on1Tab={setSelect1on1Tab}
						isUserHaveFeedbackEditPermission={isUserHaveFeedbackEditPermission}
						handleTabChange={handleTabChange}
					/>
				</Box>
				<Box className='pip-view-content-right'>
					<Box className='pip-reviewers-box full-rectangular-card'>
						<Typography variant='h6'>Reviewers</Typography>
						<Box className='pip-reviewers-list'>
							<Box className='review-others'>
								<UserAvatarGroup
									{...props}
									contributorClassName={'contributor-details-popover'}
									isButtonVisible={false}
									handleAddUserClick={() => {}}
									contributorDetailsType={true}
									isOnHoverVisible={true}
									loginUserAvatar={creatorDetailsVal}
									contributorDetails={reviewerDetailsVal && reviewerDetailsVal?.length ? [...reviewerDetailsVal] : []}
									isUserPlaceholder={true}
									subTitle={' '}
									helpText={' '}
									max={5}
									//isUserAvatarClick={type !== 'Add' && editableDetails ? true : false}
									//handleUserAvatarClick={handleUserAvatarClick}
									isDisabled={true}
									viewType='pipReviewers'
								/>
								{/* <UserAvatarGroup
									{...props}
									contributorClassName={'contributor-details-popover'}
									isButtonVisible={true}
									isOnHoverVisible={true}
									assignedUserDetails={[]}
									max={4}
								/> */}
							</Box>
						</Box>
					</Box>

					<FeedbackAttachmentList
						pipFormType={feedbackFormType}
						loader={loader}
						pipDetails={feedbackDetails}
						showMsg={showMsg}
					/>
				</Box>
			</Box>
		</Box>
	);
};
