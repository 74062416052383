import * as React from 'react';
import { useCallback, useState } from 'react';
import Select, { components, StylesConfig } from 'react-select';
import _ from 'lodash';
import { Avatar, Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { getRandomBackgroundColor, getUserName, getRandomColor } from '../../../config/utils';
import { LeaderIcon, TeamCountIcon } from '../../../config/svg/formElementIcons';
import { NoSearchRecord } from './NoSearchRecord';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { Visibility } from '../../../config/svg/ProfileSvg';

export const MultiSelectForm: React.FC<any> = (props) => {
	const {
		selectedOptions,
		key,
		onSelectOption,
		placeHolder,
		isSingleSelection,
		closeMenuOnSelect,
		selectClassName,
		selectClassNamePrefix,
		isMulti,
		helperText,
		id,
		fieldMap,
		getOptions,
		disabled,
		isMenuOpen,
		hideLoadMore,
		handleIconClick,
	} = props;
	const { t } = useTranslation();

	const [optionsList, setOptionsList] = useState<any>([]);
	const [paggingInfo, setPaggingInfo] = useState<any>({ pageIndex: 1, pageSize: 15, totalRecords: 0, totalPages: 1 });
	const [searchText, setSearchText] = useState<string>('');
	const [apiLoading, setApiLoading] = useState<boolean>(false);

	const performSearch = async (finder = '', pageIndex = 1, pageSize = 15, append = false) => {
		setApiLoading(true);
		if (hideLoadMore) {
			pageSize = 200;
		}
		const response = await getOptions({ finder, pageIndex, pageSize });
		if (response.data.status === 200) {
			let options = Object.assign([], optionsList);
			let optionId = 0;
			const { records, pageIndex, pageSize, totalPages, totalRecords } = response.data.entity;
			let finalOptions =
				records && records?.length
					? records
							.filter((item: any) => item?.isPublish)
							.map((fieldValue: any) => {
								if (optionId === 0) {
									optionId = fieldValue['ratingFormId'];
								}
								fieldValue.label = fieldValue['name'];
								fieldValue.value = fieldValue['ratingFormId'];
								return fieldValue;
							})
					: [];
			if (append === true) {
				finalOptions = options.concat(finalOptions);
			}
			setOptionsList(finalOptions);
			setPaggingInfo({
				pageIndex: pageIndex,
				pageSize: pageSize,
				totalPages: totalPages,
				totalRecords: totalRecords,
			});
			setTimeout(() => {
				const selectedEl = document.getElementById('option-' + optionId);
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}
			}, 15);
			setApiLoading(false);
		} else if (response.data.status === 204) {
			setApiLoading(false);
			setPaggingInfo({ pageIndex: 1, pageSize: 15, totalRecords: 0, totalPages: 1 });
		}
	};

	const formatOptionLabel = (props: any, context: any) => {
		const { value, label, imagePath, secondaryText, count, colorCode, backGroundColorCode } = props;
		if (context && context.context === 'value') {
			return (
				<Box className='search-user-result' id={`${'option-' + value}`}>
					<Box className='col-1'>
						<Box className='form-name'>
							<Tooltip title={props.name} arrow>
								<Typography>{props.name}</Typography>
							</Tooltip>
						</Box>
					</Box>
					<Box className='col-2'>
						<Box className='section-count'>
							<Typography>{props.totalSectionsCount}</Typography>
							<Typography variant='h6'>Sections</Typography>
						</Box>
						<Box className='questions-count'>
							<Typography>{props.totalQuestionsCount}</Typography>
							<Typography variant='h6'>Questions</Typography>
						</Box>
					</Box>
					<Box className='col-3'>
						<Box className='preview'>
							<OKRButton className={'editIcon'} icon={<Visibility />} title={t('preview')} handleClick={(e)=>{
								e.preventDefault();
								e.stopPropagation();
								handleIconClick && handleIconClick(props);
							}}/>
						</Box>
					</Box>
				</Box>
			);
		}
		return (
			<Box className='search-user-result' id={`${'option-' + value}`}>
				<Box className='col-1'>
					<Box className='form-name'>
						<Tooltip title={props.name} arrow>
							<Typography>{props.name}</Typography>
						</Tooltip>
					</Box>
				</Box>
				<Box className='col-2'>
					<Box className='section-count'>
						<Typography>{props.totalSectionsCount}</Typography>
						<Typography variant='h6'>Sections</Typography>
					</Box>
					<Box className='questions-count'>
						<Typography>{props.totalQuestionsCount}</Typography>
						<Typography variant='h6'>Questions</Typography>
					</Box>
				</Box>
				<Box className='col-3'>
					<Box className='preview'>
						<OKRButton className={'editIcon'} icon={<Visibility />} title={t('preview')} handleClick={(e)=>{
								e.preventDefault();
								e.stopPropagation();
								handleIconClick && handleIconClick(props);
							}} />
					</Box>
				</Box>
			</Box>
		);
	};

	const handleSearchThrottled = (value: any) => {
		delayedQuery(value);
	};

	const delayedQuery = useCallback(
		_.debounce((q) => _handleSearch(q), 500),
		[]
	);

	const _handleSearch = async (value: string) => {
		if (value !== searchText) {
			setSearchText(value);
			performSearch(value, 1, paggingInfo.pageSize);
		}
	};

	const updateSelection = (selectedVal: any) => {
		if (isSingleSelection === true) {
			if (selectedOptions && selectedOptions.length > 0) {
				onSelectOption(selectedVal.filter((value: any) => value.value !== selectedOptions[0].value));
			} else {
				onSelectOption(selectedVal);
			}
		} else {
			if (isMulti === false) {
				onSelectOption([selectedVal]);
			} else {
				onSelectOption(selectedVal);
			}
		}
	};

	const loadMore = () => {
		performSearch(searchText, paggingInfo.pageIndex + 1, paggingInfo.pageSize, true);
	};

	const MenuList = (props: any) => {
		return (
			<components.MenuList {...props}>
				<Box className='menu-list'>{props.children}</Box>
				{!hideLoadMore &&
				!apiLoading &&
				paggingInfo.totalPages > 1 &&
				paggingInfo.pageIndex !== paggingInfo.totalPages ? (
					<Box className='dropdown-footer'>
						<OKRButton className='btn-link' handleClick={loadMore} text={t('loadMore')}></OKRButton>
					</Box>
				) : (
					<></>
				)}
			</components.MenuList>
		);
	};
	const colourStyles: StylesConfig<any, true> = {
		multiValue: (styles: any, { data }: any) => {
			return {
				...styles,
				borderRadius: '24px',
				color: getRandomColor(data.colorCode),
				backgroundColor: `${getRandomBackgroundColor(data.backGroundColorCode)} !important`,
			};
		},
		multiValueLabel: (styles: any, { data }: any) => ({
			...styles,
			//	borderRadius: '24px 0px 0px 24px',
			color: getRandomColor(data.colorCode),
			//backgroundColor: getRandomBackgroundColor(data.backGroundColorCode),
		}),
		multiValueRemove: (styles: any, { data }: any) => ({
			...styles,
			color: getRandomColor(data.colorCode),
			//borderRadius: '0px 24px 24px 0px',
			//backgroundColor: getRandomBackgroundColor(data.backGroundColorCode),
			':hover': {
				//	backgroundColor: getRandomBackgroundColor(data.backGroundColorCode),
				color: `${getRandomColor(data.colorCode)} !important`,
			},
		}),
	};
	return (
		<Box className={`width-100 `}>
			<Select
				styles={colourStyles}
				key={key}
				id={id || ''}
				isMulti={isMulti}
				closeMenuOnSelect={closeMenuOnSelect === true}
				options={optionsList}
				value={selectedOptions}
				formatOptionLabel={formatOptionLabel}
				placeholder={placeHolder}
				onChange={updateSelection}
				onInputChange={(value) => {
					handleSearchThrottled(value);
				}}
				noOptionsMessage={() => (apiLoading === true ? t('loading') : <NoSearchRecord {...props} selectForm={true} />)}
				className={`${selectClassName} ${optionsList.length > 0 ? 'menu-open' : ''}`}
				classNamePrefix={selectClassNamePrefix}
				isClearable={false}
				isSearchable={true}
				components={{
					MenuList,
				}}
				onMenuOpen={() => {
					performSearch();
					isMenuOpen(true);
				}}
				onMenuClose={() => {
					isMenuOpen(false);
				}}
				isDisabled={disabled === true}
				inputId={`${id || 'input-id'}`}
				instanceId={`${id || 'input-placeholder'}`}
				//menuIsOpen={true}
			/>
			{helperText && helperText !== '' ? <p className='MuiFormHelperText-root Mui-error'>{helperText}</p> : <></>}
		</Box>
	);
};
