/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { CreateBulkGoalForm } from './CreateBulkGoalForm';
import { MainDrawer } from '../../../CommonComponent/MainDrawer';
import { useDispatch } from 'react-redux';
import { bulkUploadGoalForm, reloadGoalFormList } from '../../../../../action/adminSettings';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';

export const AddBulkFormDrawer: React.FC<any> = (props) => {
	const { goalBulkFormOpen, setGoalBulkFormOpen } = props;
	const [selectedTab, setSelectedTab] = useState<any>(0);
	const [validationInProgress, setValidationInProgress] = useState<boolean>(false);
	const [errorLogMessage, setErrorLogMessage] = useState([]);
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const [formError, setFormError] = useState<any>({});
	const [loader, setLoader] = useState(false);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	const [uploadFileData, setUploadFileData] = useState({
		filePath: '',
		fileName: '',
		fileUploaded: false,
	});
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	function a11yProps(index: number) {
		return {
			id: `admin-tab-${index}`,
			'aria-controls': `admin-tabpanel-${index}`,
		};
	}
	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
		event?.preventDefault();
		setSelectedTab(newValue);
	};
	const handleDrawerClose = async (event: React.ChangeEvent<HTMLInputElement>, type: string, noCheck = false) => {
		if (type === 'save') {
			uploadBulkUser(event);
			setValidationInProgress(true);
		} else if (type === 'cancel' || type === 'close') {
			if (formEdited) {
				setModalProps({
					open: true,
					module: 'information',
					type: 'addUser',
					message: t('unSavedItemAlert'),
				});
			} else {
				setGoalBulkFormOpen({ open: false, data: {} });
			}
		}
	};

	const uploadBulkUser = async (e: any) => {
		if (uploadFileData.fileName && uploadFileData.fileUploaded) {
			try {
				const formData = new FormData();
				formData.append('formFile', uploadFileData.filePath);
				let fileUploadDetails: any = await dispatch(bulkUploadGoalForm(formData));
				fileUploadDetails = fileUploadDetails.data;

				const responseAPI = fileUploadDetails?.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				setLoader(true);
				if (fileUploadDetails.status === 200) {
					enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
					//refresh list here
					setFormError({});
					setLoader(false);
					dispatch(reloadGoalFormList(true));
				} else {
					enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
					setFormError({ bulkUserError: messages });
					setLoader(false);
				}
				if (
					fileUploadDetails.entity &&
					fileUploadDetails.entity.bulkErrors &&
					fileUploadDetails.entity.bulkErrors.length > 0
				) {
					const errorDetail = fileUploadDetails.entity.bulkErrors;
					setErrorLogMessage(errorDetail);
					setLoader(false);
				}

				setGoalBulkFormOpen({ open: false, data: {} });
			} catch (err) {
				enqueueSnackbar(t('errorUploadingFile'), { variant: 'error', autoHideDuration: 5000 });
			}
		} else {
			enqueueSnackbar(`${t('UsersBulkUpload')}`, { variant: 'error', autoHideDuration: 5000 });
		}
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (type === 1) {
			setGoalBulkFormOpen({ open: false, data: {} });
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};
	return (
		<>
			<Fragment>
				<MainDrawer
					open={goalBulkFormOpen.open}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={t('addBulkGoalFormUpload')}
					loader={loader}
					children={
						<Box className='drawer-inner-content'>
							<Tabs
								className='okr-tabs drawer-tabs'
								value={selectedTab}
								onChange={handleTabChange}
								aria-label='Admin Tabs'
							>
								<Tab label={t('addBulkGoalForms')} value={0} {...a11yProps(1)} />
							</Tabs>
							{selectedTab === 0 && (
								<CreateBulkGoalForm
									{...props}
									uploadFileData={uploadFileData}
									setUploadFileData={setUploadFileData}
									validationInProgress={validationInProgress}
									setValidationInProgress={setValidationInProgress}
									setLoader={setLoader}
									errorLogMessage={errorLogMessage}
									setErrorLogMessage={setErrorLogMessage}
									formError={formError}
									setFormError={setFormError}
									setFormEdited={setFormEdited}
								/>
							)}
						</Box>
					}
					saveButtonText={t('addBtn')}
					handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
					handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
					handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
					isSaveButtonDisabled={validationInProgress}
					addAnotherTooltipText={t('addAnotherTooltipText')}
					isFooterVisible={true}
				/>
			</Fragment>
			{modalProps.open && (
				<DialogComponent
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
