import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Chip,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { BackArrow } from '../../../../config/svg/GlobalSvg';
import { OKRButton } from '../../../Common/OKRButton';
import { RequestOneOnOneIcon2 } from '../../../../config/svg/PersonalFeedbackSvg';
import { PopperMenu } from '../../../Admin/CommonComponent/PopperMenu';
import {
	AlertInfoIcon,
	CompletedDateIcon,
	CreatedDateIcon,
	DeleteIcon,
	EditIcon,
	ExtendTimeframeIcon,
	ResolveIcon,
} from '../../../../config/svg/CommonSvg';
import { CalendarIcon } from '../../../../config/svg/CommonSvgIcon';
import { ReadMore } from '../../../Goals/Cfr/ReadMore';
import { UserAvatarGroup } from '../../../Common/UserAvatarGroup';
import { AttachmentList } from './AttachmentList';
import { LinkedFeedbackList } from './LinkedFeedbackList';
import { Enums } from '../../../../config/enums';
import { PipViewTabs } from './PipViewTabs';
import { checkUnSavePopUpVisible, getDateMonthTime, getMonthDateYearTime, getUserName } from '../../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	getPIPDetailsForView,
	getPipComments,
	getReviewerDates,
	setPipImprovementData,
} from '../../../../action/performanceImprovementPlan';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import animatedTag from '../../../../images/tag_animation.svg';
import AlertDialog from '../../../Common/Dialog';

export const ViewPip = (props: any) => {
	const {
		pipFormType,
		loader,
		setLoader,
		handleBackToListClick,
		pipItem,
		isUserHavePIPPermission,
		isUserHavePIPEditPermission,
		handleDeletePip,
		handleEditPip,
		handleOneOnOneClick,
		userDetails,
		allTaskList,
		setAllTaskList,
		resolveStatusTypes,
		timeFrameChangeReasonTypes,
		showMsg,
		requestOneOnOneDrawer = {},
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);
	// const ref2 = useRef<any>(null);

	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [select1on1Tab, setSelect1on1Tab] = useState<any>(Enums.ONE);
	const [pipDetails, setPipDetails] = useState<any>(null);
	const [reviewDates, setReviewDates] = useState<any>(null);
	const [reviewDateComments, setReviewDatesComments] = useState<any>(null);
	const [creatorDetailsVal, setCreatorDetailsVal] = useState<any>(null);
	const [reviewerDetailsVal, setReviewerDetailsVal] = useState<any>([]);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });

	const { pipImprovementData } = useSelector((state: any) => state?.performanceImprovementPlanReducer);
	useEffect(() => {
		if (
			pipDetails?.distinctPerformanceImprovementPlanReviewers &&
			pipDetails?.distinctPerformanceImprovementPlanReviewers?.length
		) {
			let distinctPerformanceImprovementPlanReviewers = [...pipDetails?.distinctPerformanceImprovementPlanReviewers];
			const creatorDetails = pipDetails?.distinctPerformanceImprovementPlanReviewers[0];
			distinctPerformanceImprovementPlanReviewers.shift();
			setCreatorDetailsVal(creatorDetails);
			setReviewerDetailsVal(distinctPerformanceImprovementPlanReviewers);
		}
	}, [pipDetails]);
	useEffect(() => {
		getPIPViewDetails(pipItem);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * HANDLING UN_SAVE CHANGES WITH BELOW CODE - Start Here
	 */
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited]);
	const handleClickOutside = (event: any) => {
		const reviewerDateTabDropdown = document.getElementById('reviewer_date_tab_dropdown');
		const ckLinkForm = document.getElementsByClassName('ck-link-form');
		if (ref1?.current?.contains(event.target) || event?.target?.download) {
			//
		} else {
			if (
				ref.current &&
				!ref.current?.contains(event.target) &&
				checkUnSavePopUpVisible(event) &&
				isFormEdited &&
				!reviewerDateTabDropdown &&
				!ckLinkForm.length &&
				(select1on1Tab === Enums.TWO || select1on1Tab === Enums.THREE) &&
				!requestOneOnOneDrawer?.open
			) {
				const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
				if (dialogDiv.length === 0) {
					event.preventDefault();
					event.stopPropagation();
					setModalProps({ open: true, event: event, type: 'UnSaveChanges', message: t('checkinUnsavedMessage') });
				}
			} else {
				//
			}
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'UnSaveChanges') {
				if (modalProps?.event) {
					// setPIPFormDetails(defaultPIPFormDetails);
					setIsFormEdited(false);
					const { target } = modalProps.event;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
			}
		}
		setModalProps({ open: false, event: null, message: '' });
	};
	/**
	 * HANDLING UN_SAVE CHANGES WITH BELOW CODE - End Here
	 */

	const getPIPViewDetails = async (pipItemDetails: any) => {
		try {
			if (pipItemDetails?.performanceImprovementPlanId) {
				setLoader(true);
				const requestParam = `${pipItemDetails?.performanceImprovementPlanId || Enums.ZERO}`;
				const response: any = await dispatch(getPIPDetailsForView(requestParam));
				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					getReviewerDatesAPICall(pipItemDetails?.performanceImprovementPlanId || Enums.ZERO, response?.data?.entity);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Error occurred while fetching personal development plan.`);
					setPipDetails(null);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const getReviewerDatesAPICall = async (performanceImprovementPlanId: any, pipViewDetails: any) => {
		try {
			const requestParam = `performanceImprovementPlanId=${performanceImprovementPlanId || Enums.ZERO}`;
			const response: any = await dispatch(getReviewerDates(requestParam));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				const updatedEntityList =
					entityList && entityList?.length
						? entityList?.map((item: any, index: number) => {
								if (index === 0) {
									item.isActive = true;
								} else {
									item.isActive = false;
								}
								return item;
						  })
						: [];
				if (updatedEntityList && updatedEntityList?.length) {
					let defaultDate = updatedEntityList && updatedEntityList?.length ? updatedEntityList[0] : null;
					if (pipImprovementData?.reviewerId) {
						getPipCommentsApiCall(pipImprovementData?.reviewerId, pipViewDetails, updatedEntityList, true, true);
					} else {
						getPipCommentsApiCall(
							defaultDate?.performanceImprovementPlanReviewDateId,
							pipViewDetails,
							updatedEntityList,
							true,
							true
						);
					}
				} else {
					setReviewDates(null);
					setPipDetails(pipViewDetails || null);
					setLoader(false);
				}
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('error', messages || `Error occurred while fetching personal development plan.`);
				setReviewDates(null);
				setPipDetails(pipViewDetails || null);
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setReviewDates(null);
			setPipDetails(pipViewDetails || null);
			setLoader(false);
		}
	};
	const getPipCommentsApiCall = async (
		reviewersDateId: any,
		pipViewDetails: any,
		reviewDatesData: any,
		highlight = false,
		isDefaultLoad = false
	) => {
		try {
			const requestParam = `moduleDetailsId=${reviewersDateId || Enums.ZERO}&pageIndex=${1}&pageSize=${
				Enums.MAX_USER_SIZE
			}&moduleId=${3}`;
			const response: any = await dispatch(getPipComments(requestParam));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				setReviewDatesComments(response?.data?.entity || null);
				const updatedReviewDates = reviewDatesData.map((date: any) => {
					if (!isDefaultLoad && date.performanceImprovementPlanReviewDateId === reviewersDateId) {
						return { ...date, isReviewDateAlert: false };
					}
					return date;
				});
				setReviewDates(updatedReviewDates || null);
				setPipDetails(pipViewDetails || null);
				setLoader(false);
				if (highlight) {
					setSelect1on1Tab(Enums.TWO);
					setTimeout(() => {
						dispatch(setPipImprovementData(null));
					}, 7000);
				}
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('error', messages || `Error occurred while fetching comments`);
				setReviewDatesComments(null);
				setReviewDates(reviewDatesData || null);
				setPipDetails(pipViewDetails || null);
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setReviewDatesComments(null);
			setReviewDates(reviewDatesData || null);
			setPipDetails(pipViewDetails || null);
			setLoader(false);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleResolveClick = () => {
		setSelect1on1Tab(Enums.FOUR);
	};
	const getTimeFrameChangeReasonName = (timeFrame: any) => {
		const reasonDetail =
			timeFrameChangeReasonTypes && timeFrameChangeReasonTypes?.length
				? timeFrameChangeReasonTypes?.find(
						(rec: any) => rec?.performanceImprovementPlanReasonId === timeFrame?.performanceImprovementPlanReasonId
				  )
				: null;
		return reasonDetail ? reasonDetail?.name || '' : '';
	};
	// console.log('ViewPip ===> ', pipDetails, reviewDates, reviewDateComments);

	return (
		<>
			<Box className='pip-view-mode'>
				<Box className='pip-view-sub-head'>
					<Box className='pip-view-sub-head-left'>
						<Box className='back-to-main'>
							<Typography variant='body2' onClick={() => handleBackToListClick(pipFormType)}>
								<Link id='back-btn'>
									<BackArrow /> {t('back')}
								</Link>
							</Typography>
						</Box>
						<Box className='user-info'>
							<Box className='user-img'>
								{pipDetails?.nomineeDetails ? (
									<>
										{pipDetails?.nomineeDetails?.imagePath ? (
											<Avatar className='avatar-default' src={pipDetails?.nomineeDetails.imagePath} />
										) : (
											<Avatar className={'avatar-default'}>
												{getUserName({
													firstName: pipDetails?.nomineeDetails?.firstName || '',
													lastName: pipDetails?.nomineeDetails?.lastName || '',
													fullName: pipDetails?.nomineeDetails?.fullName || '',
												})}
											</Avatar>
										)}
									</>
								) : (
									<></>
								)}
							</Box>

							<Box className='user-info-details'>
								<Typography variant='h4'>{`${pipDetails?.nomineeDetails?.firstName || ''} ${
									pipDetails?.nomineeDetails?.lastName || ''
								}`}</Typography>
							</Box>
						</Box>
					</Box>
					<Box className='pip-view-sub-head-right'>
						<OKRButton
							className='btn-link-type1 font-weight-bold'
							icon={<RequestOneOnOneIcon2 />}
							text={t('requestOnetoOne')}
							handleClick={(event: any) =>
								handleOneOnOneClick(event, 'ViewPip', pipDetails?.nomineeDetails?.employeeId)
							}
						/>
						{pipDetails?.nomineeDetails?.employeeId !== userDetails?.employeeId &&
						(pipDetails?.createdBy === userDetails?.employeeId || isUserHavePIPEditPermission()) &&
						pipDetails?.performanceImprovementPlanStatusId === Enums.THREE ? (
							<>
								<OKRButton
									className='btn-link-type1 font-weight-bold'
									icon={<ExtendTimeframeIcon />}
									text={t('extendTimeframe')}
									handleClick={(event: any) => handleEditPip(event, pipDetails, pipFormType, true)}
									// disabled={pipDetails?.createdBy !== userDetails?.employeeId || !isUserHavePIPPermission()}
								/>
								<OKRButton
									className='btn-link-type1 font-weight-bold'
									icon={<ResolveIcon />}
									text={t('resolve')}
									// disabled={pipDetails?.createdBy !== userDetails?.employeeId || !isUserHavePIPPermission()}
									handleClick={handleResolveClick}
								/>
							</>
						) : (
							<></>
						)}
						{pipDetails?.nomineeDetails?.employeeId !== userDetails?.employeeId &&
						(pipDetails?.createdBy === userDetails?.employeeId || isUserHavePIPEditPermission()) &&
						(pipDetails?.performanceImprovementPlanStatusId === Enums.THREE ||
							pipDetails?.performanceImprovementPlanStatusId === Enums.ONE ||
							pipDetails?.performanceImprovementPlanStatusId === Enums.TWO) ? (
							<PopperMenu
								className={'user-menu-btn'}
								popperClassName={'alignment-popper-menu popper-menu-list performance-dev-popper-menu'}
								anchorEl={anchorEl}
								handleClose={handleClose}
								includeKebabMenu={true}
								menuClicked={popperMenuClicked}
								setPopperMenuClicked={setPopperMenuClicked}
								btnId={`more-btn-c`}
								{...props}
								children={
									<>
										<ListItem
											id='edit_pip_details'
											onClick={(event: any) => {
												handleClose();
												handleEditPip(event, pipDetails, pipFormType, false);
											}}
										>
											<ListItemAvatar>
												<EditIcon />
											</ListItemAvatar>
											<ListItemText primary={t('editLabel')} />
										</ListItem>

										<ListItem
											id='delete_pip_details'
											onClick={(event: any) => {
												handleClose();
												handleDeletePip(event, pipDetails, pipFormType);
											}}
										>
											<ListItemAvatar>
												<DeleteIcon />
											</ListItemAvatar>
											<ListItemText primary={t('delete')} />
										</ListItem>
									</>
								}
							></PopperMenu>
						) : (
							<></>
						)}
					</Box>
				</Box>
				<Box className='pip-view-content'>
					<Box className='pip-view-content-left'>
						<Box className='full-rectangular-card pip-view-details'>
							<Box className='pip-status'>
								{/* Draft = 1, Submitted = 2, Active = 3, SuccessfullyCleared = 4, Terminate = 5 */}
								{pipDetails?.performanceImprovementPlanStatusId === Enums.ONE ? (
									<Chip className='draft-chip' label={pipDetails?.performanceImprovementPlanStatus} />
								) : pipDetails?.performanceImprovementPlanStatusId === Enums.TWO ? (
									<Chip className='lagging-chip' label={pipDetails?.performanceImprovementPlanStatus} />
								) : pipDetails?.performanceImprovementPlanStatusId === Enums.THREE ? (
									<Chip className='lagging-chip' label={pipDetails?.performanceImprovementPlanStatus} />
								) : pipDetails?.performanceImprovementPlanStatusId === Enums.FOUR ? (
									<Box className='timeframe-animation'>
										<AnimatedIcon
											className=''
											width='480'
											type='image/svg+xml'
											alt={t('globalSearchNoRecord')}
											imagePath={animatedTag}
										/>
										<Chip className='on-track-chip' label={pipDetails?.performanceImprovementPlanStatus} />
									</Box>
								) : pipDetails?.performanceImprovementPlanStatusId === Enums.FIVE ? (
									<Chip className='at-risk-chip' label={pipDetails?.performanceImprovementPlanStatus} />
								) : (
									<Chip className='grey-chip' label={pipDetails?.performanceImprovementPlanStatus} />
								)}
							</Box>
							<Typography variant='h4'>{pipDetails?.title || ''}</Typography>
							<Box className='feedback-date-range'>
								<Tooltip title={t('createOnLabelNew')} arrow>
									<Typography variant='h6'>
										<CreatedDateIcon /> {pipDetails?.createdOn ? getMonthDateYearTime(pipDetails?.createdOn) : ''}
									</Typography>
								</Tooltip>
								<Tooltip
									title={pipDetails?.isTimeFrameAlert ? t('timeFramePassed') : t('timeFrameText')}
									arrow
									classes={pipDetails?.isTimeFrameAlert ? { popper: 'tooltip-alert' } : {}}
								>
									<Typography variant='h6' className={pipDetails?.isTimeFrameAlert ? 'alert-field' : ''}>
										{pipDetails?.isTimeFrameAlert ? (
											<span className='alert-icon'>
												<AlertInfoIcon />{' '}
											</span>
										) : (
											<></>
										)}

										<CalendarIcon />
										{pipDetails?.timeframeStartDate && pipDetails?.timeframeEndDate ? (
											`${getMonthDateYearTime(pipDetails?.timeframeStartDate)} - ${getMonthDateYearTime(
												pipDetails?.timeframeEndDate
											)}`
										) : (
											<></>
										)}
									</Typography>
								</Tooltip>
								{pipDetails?.resolvedOn ? (
									<Tooltip title={t('resolvedOn')} arrow>
										<Typography variant='h6'>
											<CompletedDateIcon />
											{pipDetails?.resolvedOn ? getMonthDateYearTime(pipDetails?.resolvedOn) : ''}
										</Typography>
									</Tooltip>
								) : (
									<></>
								)}
								{pipDetails?.timeFrameChangedCount && pipDetails?.timeFrameChangedCount > 0 ? (
									<Tooltip
										classes={{ popper: 'timeframe-tooltip' }}
										title={
											<>
												{t('timeFrameChangedOn')}
												<List>
													{pipDetails?.extendTimeframes?.map((item: any, index: number) => {
														return (
															<>
																<ListItem>
																	<span>
																		{item?.extendTimeframeChangeDate
																			? getDateMonthTime(item?.extendTimeframeChangeDate)
																			: ''}
																	</span>
																	<span>
																		{item?.extendTimeframeStartDate && item?.extendTimeframeEndDate ? (
																			`${getMonthDateYearTime(item?.extendTimeframeStartDate)} - ${getMonthDateYearTime(
																				item?.extendTimeframeEndDate
																			)}`
																		) : (
																			<></>
																		)}
																	</span>
																	<span>{getTimeFrameChangeReasonName(item)}</span>
																</ListItem>
															</>
														);
													})}

													{/* <ListItem>
													<span>9 Dec</span>
													<span>Nov 26, 2023 - Jun 28, 2024</span>
													<span>Extended</span>
												</ListItem> */}
												</List>
											</>
										}
										arrow
									>
										<Box className='timeframe-animation'>
											<AnimatedIcon
												className=''
												width='480'
												type='image/svg+xml'
												alt={t('globalSearchNoRecord')}
												imagePath={animatedTag}
											/>
											<Chip className='grey-chip' label={t('timeframeChanged')} />
										</Box>
									</Tooltip>
								) : (
									<></>
								)}
							</Box>

							{/* <ReadMore text={pipDetails?.summary || ''} isHtmlContent={true} /> */}
						</Box>
						<div ref={ref}>
							<PipViewTabs
								pipFormType={pipFormType}
								loader={loader}
								setLoader={setLoader}
								pipDetails={pipDetails}
								select1on1Tab={select1on1Tab}
								setSelect1on1Tab={setSelect1on1Tab}
								reviewDates={reviewDates}
								setReviewDates={setReviewDates}
								reviewDateComments={reviewDateComments}
								getPipCommentsApiCall={getPipCommentsApiCall}
								userDetails={userDetails}
								isUserHavePIPPermission={isUserHavePIPPermission}
								isUserHavePIPEditPermission={isUserHavePIPEditPermission}
								showMsg={showMsg}
								handleBackToListClick={handleBackToListClick}
								allTaskList={allTaskList}
								setAllTaskList={setAllTaskList}
								getPIPViewDetails={getPIPViewDetails}
								resolveStatusTypes={resolveStatusTypes}
								setIsFormEdited={setIsFormEdited}
								isFormEdited={isFormEdited}
								setModalProps={setModalProps}
							/>
						</div>
					</Box>
					<Box className='pip-view-content-right'>
						<Box className='pip-reviewers-box full-rectangular-card'>
							<Typography variant='h6'>{t('reviewersLabel')}</Typography>
							<Box className='pip-reviewers-list'>
								<Box className='review-others'>
									<UserAvatarGroup
										{...props}
										contributorClassName={'contributor-details-popover'}
										isButtonVisible={false}
										handleAddUserClick={() => {}}
										contributorDetailsType={true}
										isOnHoverVisible={true}
										loginUserAvatar={creatorDetailsVal}
										contributorDetails={reviewerDetailsVal && reviewerDetailsVal?.length ? [...reviewerDetailsVal] : []}
										isUserPlaceholder={true}
										subTitle={' '}
										helpText={' '}
										max={5}
										//isUserAvatarClick={type !== 'Add' && editableDetails ? true : false}
										//handleUserAvatarClick={handleUserAvatarClick}
										isDisabled={true}
										viewType='pipReviewers'
									/>
								</Box>
							</Box>
						</Box>
						<div ref={ref1}>
							<LinkedFeedbackList
								pipDetails={pipDetails}
								resolveStatusTypes={resolveStatusTypes}
								loader={loader}
								setLoader={setLoader}
								showMsg={showMsg}
								userDetails={userDetails}
							/>
							<AttachmentList pipFormType={pipFormType} loader={loader} pipDetails={pipDetails} showMsg={showMsg} />
						</div>
					</Box>
				</Box>
			</Box>

			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
