import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Box, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { CrossIcon } from '../../../config/svg/CommonSvgIcon';
import { getUserName } from '../../../config/utils';
import { ViewFeedback } from '../PerformanceFeedback/ViewFeedback';
import { Enums } from '../../../config/enums';

export const LinkFeedbackPopup: React.FC<any> = (props: any) => {
	const {
		handleCloseDialog,
		linkFeedbackPopup,
		pipDetails,
		loader,
		setLoader,
		feedbackItem,
		showMsg,
		userDetails,
		resolveStatusTypes,
	} = props;
	const { t } = useTranslation();
	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);

	const [select1on1Tab, setSelect1on1Tab] = useState<any>(Enums.ONE);

	const handleResolveClick = () => {
		setSelect1on1Tab(Enums.TWO);
	};
	const handleTabChange = (event: any, newValue: number) => {
		setSelect1on1Tab(newValue);
	};

	return (
		<Dialog
			onClose={(e) => handleCloseDialog(e, false)}
			aria-labelledby='simple-dialog-title'
			open={linkFeedbackPopup?.open}
			className='assessment-popup link-feedback-popup'
		>
			<Box className='dialog-title'>
				<Box className='title-left'>
					<Typography variant='h2'>{t('Linked Feedback')}</Typography>
					<List>
						<ListItem>
							<Box className='user-info v-center'>
								<Box className='user-img'>
									{/* <Avatar className='avatar-default'>DF</Avatar> */}
									{pipDetails?.nomineeDetails ? (
										<>
											{pipDetails?.nomineeDetails?.imagePath ? (
												<Avatar className='avatar-default' src={pipDetails?.nomineeDetails.imagePath} />
											) : (
												<Avatar className={'avatar-default'}>
													{getUserName({
														firstName: pipDetails?.nomineeDetails?.firstName || '',
														lastName: pipDetails?.nomineeDetails?.lastName || '',
														fullName: pipDetails?.nomineeDetails?.fullName || '',
													})}
												</Avatar>
											)}
										</>
									) : (
										<></>
									)}
								</Box>
								<Box className='user-info-details'>
									{/* <Typography variant='h4'>{'User Name'}</Typography> */}
									<Typography variant='h4'>{`${pipDetails?.nomineeDetails?.firstName || ''} ${
										pipDetails?.nomineeDetails?.lastName || ''
									}`}</Typography>
								</Box>
							</Box>
						</ListItem>
					</List>
				</Box>
				<OKRButton className='close-assessment-popup' icon={<CrossIcon />} handleClick={handleCloseDialog} />
			</Box>
			<DialogContent dividers>
				<Box className=''>
					<ViewFeedback
						feedbackFormType={'PIP'}
						loader={loader}
						setLoader={setLoader}
						feedbackItem={feedbackItem || null}
						backClicked={() => {}}
						showMsg={showMsg}
						handleOneOnOneClick={() => {}}
						setIsFormEdited={() => {}}
						userDetails={userDetails}
						isUserHaveFeedbackEditPermission={() => {
							return false;
						}}
						isUserHavePIPPermission={() => {
							return false;
						}}
						resolveStatusTypes={resolveStatusTypes}
						handleDeleteFeedback={() => {}}
						handleEditFeedback={() => {}}
						ref={ref}
						ref1={ref1}
						handleCreatePIPFormOpenClick={() => {}}
						handleResolveClick={handleResolveClick}
						select1on1Tab={select1on1Tab}
						setSelect1on1Tab={setSelect1on1Tab}
						handleTabChange={handleTabChange}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
