import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import { AddIconSvg, ExternalContributorIcon, LinkIcon, ProfileIcon } from '../../config/svg/CommonSvg';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { OKRButton } from './OKRButton';
import { AvatarGroup } from '@material-ui/lab';
import { ContributorsListPopover } from './ContributorsListPopover';

export const UserAvatar: React.FC<any> = (props) => {
	const { userDetails, t, showParentTag } = props;
	return (
		<Box className='user-avatar-main'>
			{userDetails && (
				<Box className='user-avatar-inner'>
					<Box className='user-info'>
						<div className='user-img'>
							{userDetails &&
								(userDetails.imagePath || userDetails.image ? (
									<Avatar
										className='contributor-owner'
										key={`user-icon-${userDetails.employeeId}`}
										alt={`${userDetails.firstName} ${userDetails.lastName}`}
										src={userDetails.imagePath || userDetails.image || ''}
									/>
								) : (
									<Avatar className='contributor-owner' key={`user-icon-${userDetails.employeeId}`}>
										{getUserName({
											firstName: userDetails.firstName || '',
											lastName: userDetails.lastName || '',
											fullName: userDetails.fullName || '',
										})}
									</Avatar>
								))}
						</div>
						<div className='user-info-details'>
							<Box className='user-info-label'>
								<Typography variant='h4'>
									{userDetails.fullName ? userDetails.fullName : userDetails.firstName + ' ' + userDetails.lastName}
								</Typography>
								{showParentTag && (
									<OKRButton className='btn-gray-chip' icon={<LinkIcon />} text={t('parentLabel')}></OKRButton>
								)}
							</Box>
							<Typography variant='subtitle2'>{userDetails.designation}</Typography>
						</div>
					</Box>
				</Box>
			)}
		</Box>
	);
};
