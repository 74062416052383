import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddText } from '../AddText';

export const EditConversations: React.FC<any> = (props) => {
	const {
		description,
		createConversation,
		setEditedConversation,
		editedConversation,
		canEditOrModify,
		setIsTaskEdited,
		setUnsavedCfrProps,
	} = props;
	const [showEditEditor, setShowEditEditor] = useState(true);
	const [editedConversationsContent, setEditedConversationsContent] = React.useState<string>('');
	const [editedImageList, setEditedImageList] = useState<any[]>([]);
	const [disableEditBtn, setDisableEditBtn] = useState<boolean>(false);
	const { t } = useTranslation();

	React.useEffect(() => {
		setEditedConversationsContent(description);
		setShowEditEditor(true);
		setEditedImageList(editedConversation.conversationFiles ? editedConversation.conversationFiles : []);
	}, [description]);

	React.useEffect(() => {
		if (showEditEditor) {
			setIsTaskEdited(true);
		} else {
			setIsTaskEdited(false);
		}
	}, [showEditEditor]);
	return (
		<>
			<AddText
				{...props}
				setId={'cfr-tab-edit'}
				tabSelected={3}
				showEditor={showEditEditor}
				setShowEditor={(val: any) => {
					setIsTaskEdited(val);
					setShowEditEditor(val);
				}}
				showAvatar={false}
				placeholderText={canEditOrModify() ? t('conversationPlaceholdarText') : t('conversationPeoplePlaceholdarText')}
				setEditorText={setEditedConversationsContent}
				editorText={editedConversationsContent}
				handleSave={(e: any) => {
					createConversation(editedConversationsContent, editedImageList);
				}}
				handleCancel={() => {
					setEditedConversationsContent('');
					setEditedConversation({});
				}}
				errorMessage={t('conversationsRequired')}
				showHelpText={true}
				setImageList={setEditedImageList}
				imageList={editedImageList}
				isEditMode={true}
				disableBtn={disableEditBtn}
				setDisableBtn={setDisableEditBtn}
				uploadCase={'CONVERSATION'}
				avatarUserDetails={editedConversation}
				setUnsavedCfrProps={setUnsavedCfrProps}
			/>
		</>
	);
};
