import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormLabel, ButtonGroup } from '@material-ui/core';
import { TeamSelection } from './TeamSelection';
import { AssignContributors } from './AssignContributors';
import { AddContributorDetail } from '../../Goals/KeyResult/AddContributorDetail';
import { OKRButton } from '../OKRButton';
import moment from 'moment';
import { Enums } from '../../../config/enums';
import { AssignmentSelection } from './AssignmentSelection';
import { UserAvatar } from '../UserAvatar';
import { getUserDetails, getFullName } from '../../../config/utils';
import AlertDialog from '../Dialog';
import { OKRDrawer } from '../OKRDrawer';
import { useSnackbar } from 'notistack';

export const BecomeContributor: React.FC<any> = (props) => {
	const {
		becomeAContributor,
		myGoalList,
		//krContributorDetailDashboard,
		//setKRContributorDetailDashboard,
		krAttrDateError,
		handleAddContributor,
		onSaveContributorDetailClick,
		onSaveBecomeContributorClick,
		getCurrencyIcon,
		selectedContributorDetails,
		objectiveKRDetails,
		setAssignmentTypeId,
		t,
		setKRAttrDateError,
		defaultKRDateError,
		okrMasterData,
		durationCycleDetail,
		type,
		openDrawer,
		loader,
		alignmentMap,
		alignedStatusData,
	} = props;

	const [krContributorDetailDashboard, setKRContributorDetailDashboard] = useState<any>({
		...selectedContributorDetails,
		historyStartValue: selectedContributorDetails.historyStartValue || selectedContributorDetails.startValue,
		historyTargetValue: selectedContributorDetails.historyTargetValue || selectedContributorDetails.targetValue,
		startValue: selectedContributorDetails?.isGroupTarget ? selectedContributorDetails.startValue || 0 : 0,
		targetValue: selectedContributorDetails?.isGroupTarget ? selectedContributorDetails.targetValue || 0 : 0,
		isGroupTarget: selectedContributorDetails?.isGroupTarget || false,
	});
	const [parentData, setParentData] = useState<any>({});
	const loggedInUserDetail = getUserDetails();
	const [modalProps, setModalProps] = useState<any>({
		open: false,
		data: null,
		message: '',
	});
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
	const [ownerData, setOwnerData] = useState<any>({});
	useEffect(() => {
		let kr: any =
			objectiveKRDetails && objectiveKRDetails.krDetails ? objectiveKRDetails.krDetails : objectiveKRDetails.okrDetails;
		let okr: any =
			objectiveKRDetails && objectiveKRDetails.okrDetails
				? objectiveKRDetails.okrDetails
				: objectiveKRDetails.krDetails;

		setOwnerData({
			employeeId: loggedInUserDetail.employeeId,
			assignmentTypeId: 2,
			isExternal: false,
			objectiveName: okr.objectiveName,
			fullName: loggedInUserDetail.fullName,
			firstName: loggedInUserDetail.firstName,
			lastName: loggedInUserDetail.lastName,
			emailId: loggedInUserDetail.emailId,
			designation: loggedInUserDetail.designation,
			keyResult: '',
			krStatusId: Enums.TWO,
			krStatusName: t('acceptedOkr'),
			isSelfCreation: false,
			score: 0,
			startDate: okr.startDate,
			dueDate: okr.dueDate,
			startValue: krContributorDetailDashboard.startValue,
			targetValue: krContributorDetailDashboard.targetValue,
			krAssignerMessage: '',
			imagePath: loggedInUserDetail.imagePath,
			goalStatusId: 2,
			cycleId: props.userSelectedCycle?.organisationCycleId || 0,
			year: props.userSelectedCycle?.year,
			teamId: okr.teamId,
			teamName: okr.teamName,
			backGroundColorCode: okr.teamOkrRequests?.length > 0 ? okr.teamOkrRequests.backGroundColorCode : '',
			colorCode: okr.teamOkrRequests?.length > 0 ? okr.teamOkrRequests.colorCode : '',
			isTeamSelected: false,
			selectedMetric: {},
			isKrOwner: true,
			checkEdited: true,
			goalId: okr.goalObjectiveId,
		});
	}, []);
	useEffect(() => {
		const objectiveData =
			objectiveKRDetails && objectiveKRDetails.okrDetails
				? objectiveKRDetails.okrDetails
				: objectiveKRDetails.krDetails;
		const myObj =
			objectiveKRDetails && objectiveKRDetails.okrDetails
				? {
						objectiveName: objectiveData?.objectiveName || '',
						goalObjectiveId: objectiveData?.goalObjectiveId,
						goalTypeId: objectiveData.goalTypeId,
				  }
				: {};
		setKRContributorDetailDashboard({ ...krContributorDetailDashboard, ...myObj });
		let contributorsList =
			objectiveKRDetails &&
			objectiveKRDetails.krDetails &&
			objectiveKRDetails.krDetails.contributors &&
			objectiveKRDetails.krDetails.contributors.length
				? objectiveKRDetails.krDetails.contributors
						.slice(0, 1)
						.filter((items: any) => items.goalStatusId === Enums.KR_STATUS_ACCEPTED || items.employeeId === 0)
				: [];
		if (contributorsList.length > 0) {
			setParentData(contributorsList[0]);
		}
		isUserAlreadyAligned();
	}, []);

	const isUserAlreadyAligned = async () => {
		const sourceId =
			alignedStatusData.source && alignedStatusData.source > 0
				? alignedStatusData.source
				: alignedStatusData.goalKeyId || 0;
		const apiData = `sourceType=${alignedStatusData.sourceType}&sourceId=${sourceId}&employeeId=${selectedContributorDetails.employeeId}`;
		const resp = await props.checkAlignStatus(apiData);
		const { entity } = resp.data;
		if (entity && entity?.isAligned === false) {
		} else if (entity?.isAligned && entity?.alignStatus) {
			if (entity?.alignStatus === Enums.ONE) {
				//Pending
				enqueueSnackbar(`${t('pendingUserMessage')} ${getFullName(krContributorDetailDashboard)}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			} else if (entity?.alignStatus === Enums.THREE) {
				//Declined
				enqueueSnackbar(`${getFullName(krContributorDetailDashboard)} ${t('declinedUserMessage')}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			} else {
				enqueueSnackbar(`${getFullName(krContributorDetailDashboard)} ${t('alreadyAlign')}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			}
			setSaveEnabled(true);
		} else {
			enqueueSnackbar(`${getFullName(krContributorDetailDashboard)} ${t('alreadyAlign')}`, {
				variant: 'info',
				autoHideDuration: 3000,
			});
			setSaveEnabled(true);
		}
	};
	const moveScrollToBox = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element?.scrollIntoView({ behavior: 'smooth' });
		}
	};
	const validateAddContributorForm = (formData: any) => {
		let isPositiveFlow =
			parseInt(objectiveKRDetails.krDetails.startValue || 0) < parseInt(objectiveKRDetails.krDetails.targetValue || 0);

		if (formData.assignmentTypeId === Enums.TWO) {
			if (!formData.objectiveName) {
				setKRAttrDateError({
					error: t('objectiveNameError'),
					helperText: t('objectiveNameError'),
					type: 'objectiveName',
				});
				moveScrollToBox('obj-name');
				return false;
			} else if (formData.objectiveName.trim() === '') {
				setKRAttrDateError({
					error: t('objectiveNameError'),
					helperText: t('objectiveNameError'),
					type: 'objectiveName',
				});
				moveScrollToBox('obj-name');
				return false;
			}
		}
		if (!formData.keyResult) {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			moveScrollToBox('kr-name');
			return false;
		} else if (formData.keyResult.trim() === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			moveScrollToBox('kr-name');
			return false;
		}
		if (formData.metricId > 0 && formData.metricId !== Enums.FOUR && formData.metricId !== Enums.FIVE) {
			formData.startValue = parseInt(!formData.startValue ? 0 : formData.startValue);
			formData.targetValue = parseInt(!formData.targetValue ? 0 : formData.targetValue);
			if (formData.startValue === formData.targetValue) {
				setKRAttrDateError({
					error: t('startCurrentSameError'),
					helperText: t('startCurrentSameError'),
					type: 'targetValue',
				});
				moveScrollToBox('error-occur');
				return false;
			}
		}
		if (formData.metricName !== '' && formData.metricName !== 'Boolean' && formData.metricName !== 'NoUnits') {
			formData.startValue = parseInt(!formData.startValue ? 0 : formData.startValue);
			formData.targetValue = parseInt(!formData.targetValue ? 0 : formData.targetValue);
			if (formData.startValue === formData.targetValue) {
				setKRAttrDateError({
					error: t('startCurrentSameError'),
					helperText: t('startCurrentSameError'),
					type: 'targetValue',
				});
				moveScrollToBox('error-occur');
				return false;
			}
		}
		if (
			(isPositiveFlow && formData.startValue > formData.targetValue) ||
			(!isPositiveFlow && formData.startValue < formData.targetValue)
		) {
			if (isPositiveFlow) {
				setKRAttrDateError({
					error: t('startValueCannotBeGreater'),
					helperText: t('startValueCannotBeGreater'),
					type: 'targetValue',
				});
				moveScrollToBox('error-occur');
			} else {
				setKRAttrDateError({
					error: t('startValueCannotBeLesser'),
					helperText: t('startValueCannotBeLesser'),
					type: 'startValue',
				});
				moveScrollToBox('error-occur');
			}
			return false;
		}

		return true;
	};

	const goalTypeId = () => {
		let okrData =
			objectiveKRDetails && objectiveKRDetails.okrDetails
				? objectiveKRDetails.okrDetails
				: objectiveKRDetails.krDetails;
		return okrData.goalTypeId;
	};

	const updateData = (data: any) => {
		let teamId = 0;
		if (data.selectedTeams && data.selectedTeams.length > 0) {
			teamId = data.selectedTeams[0].id;
		}
		setKRAttrDateError({
			error: '',
			helperText: '',
			type: '',
		});
		setKRContributorDetailDashboard({
			...krContributorDetailDashboard,
			goalTypeId: data.currentGoalId,
			isSelf: data.currentGoalId === 2,
			teamId: teamId,
			contributors: data.contributors ? data.contributors : [],
			isGroupTarget: data?.isGroupTarget || krContributorDetailDashboard?.isGroupTarget || false,
		});
	};

	const handleCloseModal = (event: any, action: any) => {
		if (action === 1) {
			onSaveBecomeContributorClick && onSaveBecomeContributorClick(event, krContributorDetailDashboard);
		}
		setModalProps({ open: false, data: '', type: '' });
	};

	const handleDrawerCloseClick = (event: any) => {
		if (validateAddContributorForm(krContributorDetailDashboard)) {
			setKRAttrDateError(defaultKRDateError);
			onSaveBecomeContributorClick && onSaveBecomeContributorClick(event, krContributorDetailDashboard);
		}
	};

	return (
		<OKRDrawer
			drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
			open={openDrawer}
			transitionDuration={{ enter: 500, exit: 1000 }}
			headerTitle={becomeAContributor ? t('becomeAContributor') : t('addAContributor')}
			moreButton={<></>}
			handleDrawerClose={(event: any) => handleAddContributor(event, 'close')}
			handleSaveClick={(event: any) => {
				if (!becomeAContributor) {
					const datesDifferenceInDays = moment(new Date(krContributorDetailDashboard.startDate || new Date())).isAfter(
						new Date(krContributorDetailDashboard.dueDate || new Date()),
						'days'
					);
					if (Boolean(datesDifferenceInDays)) {
						setKRAttrDateError({
							error: t('startDateDueDateError'),
							helperText: t('startDateDueDateError'),
							type: 'Date',
						});
					} else {
						if (validateAddContributorForm(krContributorDetailDashboard)) {
							setKRAttrDateError(defaultKRDateError);
							onSaveContributorDetailClick && onSaveContributorDetailClick(event, krContributorDetailDashboard);
						}
					}
				} else {
					const datesDifferenceInDays = moment(new Date(krContributorDetailDashboard.startDate || new Date())).isAfter(
						new Date(krContributorDetailDashboard.dueDate || new Date()),
						'days'
					);
					if (Boolean(datesDifferenceInDays)) {
						setKRAttrDateError({
							error: t('startDateDueDateError'),
							helperText: t('startDateDueDateError'),
							type: 'Date',
						});
					} else if (validateAddContributorForm(krContributorDetailDashboard)) {
						handleDrawerCloseClick(event);
					}
				}
			}}
			handleCancelClick={(event: any) => handleAddContributor(event, 'close')}
			loader={loader}
			isAnyDrawerOpened={props.isAnyDrawerOpened}
			appDrawerInfo={props.appDrawerInfo}
			isSaveButtonDisabled={saveEnabled}
			{...(!becomeAContributor && { saveButtonText: t('send') })}
		>
			<Box className='become-contributor-assignment'>
				<Box className='drawer-inner-content'>
					<Box className='drawer-content-panel'>
						{becomeAContributor && goalTypeId() === Enums.ONE && (
							<Box className='drawer-field-row'>
								<UserAvatar userDetails={parentData} showParentTag={true} t={t} />
							</Box>
						)}
						<AddContributorDetail
							{...props}
							type={type}
							goalList={myGoalList}
							krContributorDetailDashboard={krContributorDetailDashboard}
							setKRContributorDetailDashboard={setKRContributorDetailDashboard}
							defaultKRDashboardDateError={krAttrDateError}
							onHandleCancelClick={(event: any) => handleAddContributor(event, 'close')}
							onSaveContributorDetailClick={onSaveContributorDetailClick}
							onSaveBecomeContributorClick={onSaveBecomeContributorClick}
							selectedContributorDetails={selectedContributorDetails}
							getCurrencyIcon={getCurrencyIcon}
							objectiveDetail={objectiveKRDetails.okrDetails}
							setAssignmentTypeId={setAssignmentTypeId}
							keyAttribute={
								objectiveKRDetails && objectiveKRDetails.krDetails
									? objectiveKRDetails.krDetails
									: objectiveKRDetails.okrDetails
							}
							keyData={
								objectiveKRDetails && objectiveKRDetails.krDetails
									? objectiveKRDetails.krDetails
									: objectiveKRDetails.okrDetails
							}
							setCallOut={() => {}}
							setAnchorElCallouts={() => {}}
							hideKrConfidence={!becomeAContributor}
						/>

						{becomeAContributor /*&& goalTypeId() === Enums.ONE*/ && (
							<Box className='drawer-field-row'>
								<AssignmentSelection
									{...props}
									ownerData={ownerData}
									keyData={krContributorDetailDashboard}
									kr={
										objectiveKRDetails && objectiveKRDetails.krDetails
											? objectiveKRDetails.krDetails
											: objectiveKRDetails.okrDetails
									}
									okr={
										objectiveKRDetails && objectiveKRDetails.okrDetails
											? objectiveKRDetails.okrDetails
											: objectiveKRDetails.krDetails
									}
									okrMasterData={okrMasterData}
									durationCycleDetail={durationCycleDetail}
									krAttrDateError={krAttrDateError}
									updateData={updateData}
									currentUser={props.currentUser}
									t={t}
								/>
							</Box>
						)}
					</Box>
				</Box>

				{modalProps.open && (
					<AlertDialog
						module={'accept'}
						message={modalProps.message}
						handleCloseModal={handleCloseModal}
						modalOpen={modalProps.open}
						isCancel={false}
					/>
				)}
			</Box>
		</OKRDrawer>
	);
};
