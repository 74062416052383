import React from 'react';

export const PersonalFeedbackIcon = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M28.6921 15.0536C27.4101 13.6675 25.1958 13.6255 23.875 14.9486C22.5542 13.6255 20.3496 13.6675 19.0579 15.0536C17.7079 16.5133 17.629 19.051 19.0579 20.3567L23.875 24.5312L28.6921 20.3567C30.121 19.051 30.0421 16.5133 28.6921 15.0536Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0.75 10C0.75 5.99594 3.99594 2.75 8 2.75H20.4437C24.4477 2.75 27.6937 5.99594 27.6937 10V11C27.6937 11.6904 27.134 12.25 26.4437 12.25C25.7533 12.25 25.1937 11.6904 25.1937 11V10C25.1937 7.37665 23.067 5.25 20.4437 5.25H8C5.37665 5.25 3.25 7.37665 3.25 10V22.6087C3.25 23.6018 4.53604 23.9943 5.09059 23.1706C6.16133 21.58 7.95311 20.6262 9.87051 20.6262H14.8906C15.581 20.6262 16.1406 21.1859 16.1406 21.8762C16.1406 22.5666 15.581 23.1262 14.8906 23.1262H9.87051C8.78502 23.1262 7.77064 23.6662 7.16446 24.5666C5.23186 27.4375 0.75 26.0695 0.75 22.6087V10Z'
			fill='#292929'
		/>
	</svg>
);
export const ViewFeedback = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M27.9078 15.2385C26.7181 13.957 24.6632 13.9182 23.4375 15.1414C22.2118 13.9182 20.1659 13.957 18.9672 15.2385C17.7145 16.5878 17.6412 18.9338 18.9672 20.1409L23.4375 24L27.9078 20.1409C29.2338 18.9338 29.1605 16.5878 27.9078 15.2385Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M3.85922 3.4573C3.79845 3.52126 3.75 3.62145 3.75 3.75V26.25C3.75 26.3785 3.79845 26.4787 3.85922 26.5427C3.91871 26.6053 3.97916 26.625 4.03125 26.625H22.9687C23.7972 26.625 24.4687 27.2966 24.4687 28.125C24.4687 28.9534 23.7972 29.625 22.9687 29.625H4.03125C2.14749 29.625 0.75 28.0405 0.75 26.25V3.75C0.75 1.95947 2.14749 0.375 4.03125 0.375H18.2812C18.6924 0.375 19.0856 0.54378 19.3687 0.841876L24.7125 6.46688C24.9773 6.74565 25.125 7.11548 25.125 7.5V9.5625C25.125 10.3909 24.4534 11.0625 23.625 11.0625C22.7966 11.0625 22.125 10.3909 22.125 9.5625V8.09891L17.6373 3.375H4.03125C3.97916 3.375 3.91871 3.39468 3.85922 3.4573ZM6.46875 10.5C6.46875 9.67157 7.14032 9 7.96875 9H15.9375C16.7659 9 17.4375 9.67157 17.4375 10.5C17.4375 11.3284 16.7659 12 15.9375 12H7.96875C7.14032 12 6.46875 11.3284 6.46875 10.5ZM6.84375 17.625C6.84375 16.7966 7.51532 16.125 8.34375 16.125H11.4375C12.2659 16.125 12.9375 16.7966 12.9375 17.625C12.9375 18.4534 12.2659 19.125 11.4375 19.125H8.34375C7.51532 19.125 6.84375 18.4534 6.84375 17.625Z'
			fill='#292929'
		/>
	</svg>
);
export const GiveFeedbackIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12.3353 2.6227C10.9892 1.16724 8.66421 1.12314 7.27735 2.51244C5.89049 1.12314 3.57565 1.16724 2.21939 2.6227C0.801934 4.15534 0.719064 6.81997 2.21939 8.19093L7.27735 12.5742L12.3353 8.19093C13.8356 6.81997 13.7528 4.15534 12.3353 2.6227Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M18.8115 11.6729C18.6361 11.5667 18.4086 11.6056 18.2774 11.7707C18.2715 11.7781 18.2623 11.7899 18.2505 11.8052C18.1891 11.8845 18.0555 12.0572 17.9275 12.1939L13.4609 16.9641V25.1234C13.4728 25.1391 13.4899 25.1599 13.5139 25.1857C13.6429 25.3249 13.8768 25.5037 14.2171 25.6841C14.9248 26.0591 15.7197 26.25 16.1058 26.25H22.3665C22.6766 26.25 22.9737 26.1027 23.1657 25.8441L25.875 22.1967V18.9C25.875 18.3182 25.4117 17.8714 24.8707 17.8714H18.61C18.0912 17.8714 17.6092 17.6033 17.3356 17.1625C17.062 16.7216 17.0356 16.1707 17.2658 15.7058L18.9744 12.2554C19.0805 12.0412 19.0005 11.7874 18.8115 11.6729ZM13.4365 25.0864C13.4367 25.0865 13.4377 25.0882 13.4392 25.0916C13.4372 25.0882 13.4364 25.0864 13.4365 25.0864ZM20.3659 9.10706L19.5887 10.39L20.3659 9.10706C21.8987 10.0356 22.4547 11.9876 21.6629 13.5867L21.0267 14.8714H24.8707C27.0958 14.8714 28.875 16.6888 28.875 18.9V22.6929C28.875 23.015 28.7713 23.3287 28.5792 23.5873L25.574 27.633C24.8202 28.6479 23.6328 29.25 22.3665 29.25H16.1058C15.1088 29.25 13.8312 28.8749 12.8122 28.3348C12.2892 28.0576 11.7461 27.6915 11.3141 27.2256C10.8873 26.7653 10.4609 26.0853 10.4609 25.2214V16.3714C10.4609 15.9906 10.6057 15.6241 10.8659 15.3462L15.7377 10.1434C15.7624 10.1169 15.7755 10.1 15.8102 10.0552C15.8349 10.0233 15.8707 9.97714 15.9295 9.90322C16.9854 8.57564 18.8787 8.20605 20.3659 9.10706ZM7.27734 14.8714C8.10577 14.8714 8.77734 15.543 8.77734 16.3714V27.364C8.77734 28.1924 8.10577 28.864 7.27734 28.864C6.44892 28.864 5.77734 28.1924 5.77734 27.364V16.3714C5.77734 15.543 6.44892 14.8714 7.27734 14.8714Z'
			fill='#292929'
		/>
	</svg>
);
export const AskFeedbackIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M18.0248 14.8856C18.0248 12.609 19.8625 10.7536 22.1415 10.7536C24.4205 10.7536 26.2583 12.609 26.2583 14.8856C26.2583 17.1622 24.4205 19.0176 22.1415 19.0176C19.8625 19.0176 18.0248 17.1622 18.0248 14.8856ZM22.1415 13.0036C21.116 13.0036 20.2748 13.8408 20.2748 14.8856C20.2748 15.9304 21.116 16.7676 22.1415 16.7676C23.1671 16.7676 24.0083 15.9304 24.0083 14.8856C24.0083 13.8408 23.1671 13.0036 22.1415 13.0036ZM17.3019 25.8088H26.9799C26.9133 25.1953 26.6844 24.6329 26.3373 24.1635C25.5906 23.1533 24.5727 22.4106 23.6374 22.4106H20.6457C19.0175 22.4106 17.4876 23.9692 17.3019 25.8088ZM15.0331 26.1818C15.0331 23.1966 17.426 20.1606 20.6457 20.1606H23.6374C25.6555 20.1606 27.2578 21.6235 28.1467 22.826C28.8399 23.7639 29.25 24.9266 29.25 26.1818V26.9338C29.25 27.5551 28.7463 28.0588 28.125 28.0588H16.1581C15.5368 28.0588 15.0331 27.5551 15.0331 26.9338V26.1818Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M11.1397 4.3125C6.7732 4.3125 3.1875 7.9336 3.1875 12.4577C3.1875 14.7924 3.91709 16.5824 5.01282 17.7747C6.10439 18.9625 7.6302 19.6379 9.40257 19.6379H13.2629C13.9877 19.6379 14.5754 20.2255 14.5754 20.9504C14.5754 21.6752 13.9877 22.2629 13.2629 22.2629H9.40257C6.92863 22.2629 4.69065 21.3036 3.08001 19.5509C1.47353 17.8028 0.5625 15.3464 0.5625 12.4577C0.5625 6.53514 5.2727 1.6875 11.1397 1.6875C13.8208 1.6875 15.9703 2.09059 17.7192 2.95819C19.4913 3.83727 20.7499 5.14244 21.7229 6.76406C22.0958 7.38564 21.8943 8.19185 21.2727 8.5648C20.6511 8.93774 19.8449 8.73619 19.472 8.11461C18.7078 6.84102 17.796 5.92651 16.5527 5.30974C15.2862 4.68147 13.5754 4.3125 11.1397 4.3125Z'
			fill='#292929'
		/>
		<path
			d='M14.279 9.57316C13.3922 8.55846 11.8604 8.52771 10.9467 9.49629C10.033 8.52771 8.50792 8.55846 7.61438 9.57316C6.68053 10.6417 6.62593 12.4994 7.61438 13.4552L10.9467 16.511L14.279 13.4552C15.2674 12.4994 15.2128 10.6417 14.279 9.57316Z'
			fill='#292929'
		/>
	</svg>
);

export const RequestOneOnOneIcon = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.84926 14.8026C3.84926 12.0439 6.04495 9.75 8.82353 9.75C11.6021 9.75 13.7978 12.0439 13.7978 14.8026C13.7978 17.5614 11.6021 19.8553 8.82353 19.8553C6.04495 19.8553 3.84926 17.5614 3.84926 14.8026ZM8.82353 12.75C7.76454 12.75 6.84926 13.6373 6.84926 14.8026C6.84926 15.968 7.76454 16.8553 8.82353 16.8553C9.88252 16.8553 10.7978 15.968 10.7978 14.8026C10.7978 13.6373 9.88252 12.75 8.82353 12.75ZM15.9191 14.8026C15.9191 12.0058 18.1948 9.75 20.9877 9.75C23.7807 9.75 26.0564 12.0058 26.0564 14.8026C26.0564 17.5995 23.7807 19.8553 20.9877 19.8553C18.1948 19.8553 15.9191 17.5995 15.9191 14.8026ZM20.9877 12.75C19.8389 12.75 18.9191 13.6753 18.9191 14.8026C18.9191 15.9299 19.8389 16.8553 20.9877 16.8553C22.1366 16.8553 23.0564 15.9299 23.0564 14.8026C23.0564 13.6753 22.1366 12.75 20.9877 12.75ZM3.42173 26.625H14.2252C14.128 25.9992 13.8843 25.4269 13.5331 24.9436C12.8485 24.0013 11.7669 23.4079 10.5607 23.4079H7.0864C5.26412 23.4079 3.70718 24.7791 3.42173 26.625ZM1.875 29.625C1.04657 29.625 0.375 28.9534 0.375 28.125V27.2366C0.375 23.4967 3.34852 20.4079 7.0864 20.4079H10.5607C12.2097 20.4079 13.7144 21.0131 14.8774 22.0078C16.0107 20.9834 17.462 20.4079 19.2034 20.4079H22.7721C26.5504 20.4079 29.625 23.4586 29.625 27.2366V28.125C29.625 28.9534 28.9534 29.625 28.125 29.625H1.875ZM17.2454 26.625H26.576C26.2817 24.8055 24.6958 23.4079 22.7721 23.4079H19.2034C18.1269 23.4079 17.3185 23.7582 16.6827 24.4351C16.9833 25.1143 17.1778 25.8519 17.2454 26.625Z'
			fill='#292929'
		/>
		<path
			d='M8.25 5.25C8.25 4.42157 8.92157 3.75 9.75 3.75C10.5784 3.75 11.25 4.42157 11.25 5.25C11.25 6.07843 10.5784 6.75 9.75 6.75C8.92157 6.75 8.25 6.07843 8.25 5.25Z'
			fill='#292929'
		/>
		<path
			d='M13.5 5.25C13.5 4.42157 14.1716 3.75 15 3.75C15.8284 3.75 16.5 4.42157 16.5 5.25C16.5 6.07843 15.8284 6.75 15 6.75C14.1716 6.75 13.5 6.07843 13.5 5.25Z'
			fill='#292929'
		/>
		<path
			d='M18.75 5.25C18.75 4.42157 19.4216 3.75 20.25 3.75C21.0784 3.75 21.75 4.42157 21.75 5.25C21.75 6.07843 21.0784 6.75 20.25 6.75C19.4216 6.75 18.75 6.07843 18.75 5.25Z'
			fill='#292929'
		/>
	</svg>
);

export const RequestOneOnOneIcon2 = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M14.7118 20.6467C14.8073 20.7285 14.9487 20.7265 15.0419 20.6422C16.1564 19.6348 17.5845 19.0658 19.3112 19.0658H22.9713C26.7096 19.0658 29.75 22.084 29.75 25.8196V26.7308C29.75 27.4424 29.1731 28.0192 28.4615 28.0192H1.53846C0.826864 28.0192 0.25 27.4424 0.25 26.7308V25.8196C0.25 22.1162 3.1934 19.0658 6.88348 19.0658H10.4468C12.0756 19.0658 13.562 19.6633 14.7118 20.6467ZM14.2053 25.4423C14.2783 25.4423 14.3476 25.4104 14.3951 25.355C14.4426 25.2995 14.4635 25.2261 14.4523 25.154C14.3465 24.4721 14.0809 23.848 13.6978 23.3208C12.9513 22.2934 11.7686 21.6427 10.4468 21.6427H6.88348C4.88582 21.6427 3.18857 23.1444 2.87778 25.1541C2.86663 25.2262 2.88755 25.2996 2.93504 25.355C2.98254 25.4104 3.05187 25.4423 3.12485 25.4423H14.2053ZM26.8729 25.4423C26.9461 25.4423 27.0157 25.4102 27.0632 25.3544C27.1107 25.2986 27.1313 25.2247 27.1196 25.1524C26.7984 23.1664 25.0683 21.6427 22.9713 21.6427H19.3112C18.144 21.6427 17.2469 22.0264 16.5436 22.775C16.4749 22.8483 16.4566 22.9555 16.4972 23.0474C16.7946 23.7193 16.9871 24.449 17.054 25.2141C17.0653 25.3432 17.1734 25.4423 17.303 25.4423H26.8729Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M3.81335 13.0668C3.81335 10.3696 5.95911 8.13461 8.66516 8.13461C11.3712 8.13461 13.517 10.3696 13.517 13.0668C13.517 15.764 11.3712 17.999 8.66516 17.999C5.95911 17.999 3.81335 15.764 3.81335 13.0668ZM8.66516 10.7115C7.43689 10.7115 6.39027 11.7376 6.39027 13.0668C6.39027 14.396 7.43689 15.4221 8.66516 15.4221C9.89343 15.4221 10.94 14.396 10.94 13.0668C10.94 11.7376 9.89343 10.7115 8.66516 10.7115Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M16.1927 13.0668C16.1927 10.3375 18.4136 8.13461 21.1413 8.13461C23.8689 8.13461 26.0899 10.3375 26.0899 13.0668C26.0899 15.7961 23.8689 17.999 21.1413 17.999C18.4136 17.999 16.1927 15.7961 16.1927 13.0668ZM21.1413 10.7115C19.8257 10.7115 18.7696 11.7717 18.7696 13.0668C18.7696 14.3619 19.8257 15.4221 21.1413 15.4221C22.4568 15.4221 23.5129 14.3619 23.5129 13.0668C23.5129 11.7717 22.4568 10.7115 21.1413 10.7115Z'
			fill='#292929'
		/>
		<path
			d='M7.69232 3.65385C7.69232 2.59176 8.55331 1.73077 9.6154 1.73077V1.73077C10.6775 1.73077 11.5385 2.59176 11.5385 3.65385V3.65385C11.5385 4.71594 10.6775 5.57693 9.6154 5.57693V5.57693C8.55331 5.57693 7.69232 4.71594 7.69232 3.65385V3.65385Z'
			fill='#292929'
		/>
		<path
			d='M13.0769 3.65385C13.0769 2.59176 13.9379 1.73077 15 1.73077V1.73077C16.0621 1.73077 16.9231 2.59176 16.9231 3.65385V3.65385C16.9231 4.71594 16.0621 5.57693 15 5.57693V5.57693C13.9379 5.57693 13.0769 4.71594 13.0769 3.65385V3.65385Z'
			fill='#292929'
		/>
		<path
			d='M18.4615 3.65385C18.4615 2.59176 19.3225 1.73077 20.3846 1.73077V1.73077C21.4467 1.73077 22.3077 2.59176 22.3077 3.65385V3.65385C22.3077 4.71594 21.4467 5.57693 20.3846 5.57693V5.57693C19.3225 5.57693 18.4615 4.71594 18.4615 3.65385V3.65385Z'
			fill='#292929'
		/>
	</svg>
);
export const CancelRequest = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.84926 14.8026C3.84926 12.0439 6.04495 9.75 8.82353 9.75C11.6021 9.75 13.7978 12.0439 13.7978 14.8026C13.7978 17.5614 11.6021 19.8553 8.82353 19.8553C6.04495 19.8553 3.84926 17.5614 3.84926 14.8026ZM8.82353 12.75C7.76454 12.75 6.84926 13.6373 6.84926 14.8026C6.84926 15.968 7.76454 16.8553 8.82353 16.8553C9.88252 16.8553 10.7978 15.968 10.7978 14.8026C10.7978 13.6373 9.88252 12.75 8.82353 12.75ZM15.9191 14.8026C15.9191 12.0058 18.1948 9.75 20.9877 9.75C23.7807 9.75 26.0564 12.0058 26.0564 14.8026C26.0564 17.5995 23.7807 19.8553 20.9877 19.8553C18.1948 19.8553 15.9191 17.5995 15.9191 14.8026ZM20.9877 12.75C19.8389 12.75 18.9191 13.6753 18.9191 14.8026C18.9191 15.9299 19.8389 16.8553 20.9877 16.8553C22.1366 16.8553 23.0564 15.9299 23.0564 14.8026C23.0564 13.6753 22.1366 12.75 20.9877 12.75ZM3.42173 26.625H14.2252C14.128 25.9992 13.8843 25.4269 13.5331 24.9436C12.8485 24.0013 11.7669 23.4079 10.5607 23.4079H7.0864C5.26412 23.4079 3.70718 24.7791 3.42173 26.625ZM1.875 29.625C1.04657 29.625 0.375 28.9534 0.375 28.125V27.2366C0.375 23.4967 3.34852 20.4079 7.0864 20.4079H10.5607C12.2097 20.4079 13.7144 21.0131 14.8774 22.0078C16.0107 20.9834 17.462 20.4079 19.2034 20.4079H22.7721C26.5504 20.4079 29.625 23.4586 29.625 27.2366V28.125C29.625 28.9534 28.9534 29.625 28.125 29.625H1.875ZM17.2454 26.625H26.576C26.2817 24.8055 24.6958 23.4079 22.7721 23.4079H19.2034C18.1269 23.4079 17.3185 23.7582 16.6827 24.4351C16.9833 25.1143 17.1778 25.8519 17.2454 26.625Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.375 5.57349C9.375 4.74506 10.0466 4.07349 10.875 4.07349H19.3125C20.1409 4.07349 20.8125 4.74506 20.8125 5.57349C20.8125 6.40191 20.1409 7.07349 19.3125 7.07349H10.875C10.0466 7.07349 9.375 6.40191 9.375 5.57349Z'
			fill='#292929'
		/>
	</svg>
);
export const RequestFeedbackAgain = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.84926 14.8026C3.84926 12.0439 6.04495 9.75 8.82353 9.75C11.6021 9.75 13.7978 12.0439 13.7978 14.8026C13.7978 17.5614 11.6021 19.8553 8.82353 19.8553C6.04495 19.8553 3.84926 17.5614 3.84926 14.8026ZM8.82353 12.75C7.76454 12.75 6.84926 13.6373 6.84926 14.8026C6.84926 15.968 7.76454 16.8553 8.82353 16.8553C9.88252 16.8553 10.7978 15.968 10.7978 14.8026C10.7978 13.6373 9.88252 12.75 8.82353 12.75ZM15.9191 14.8026C15.9191 12.0058 18.1948 9.75 20.9877 9.75C23.7807 9.75 26.0564 12.0058 26.0564 14.8026C26.0564 17.5995 23.7807 19.8553 20.9877 19.8553C18.1948 19.8553 15.9191 17.5995 15.9191 14.8026ZM20.9877 12.75C19.8389 12.75 18.9191 13.6753 18.9191 14.8026C18.9191 15.9299 19.8389 16.8553 20.9877 16.8553C22.1366 16.8553 23.0564 15.9299 23.0564 14.8026C23.0564 13.6753 22.1366 12.75 20.9877 12.75ZM3.42173 26.625H14.2252C14.128 25.9992 13.8843 25.4269 13.5331 24.9436C12.8485 24.0013 11.7669 23.4079 10.5607 23.4079H7.0864C5.26412 23.4079 3.70718 24.7791 3.42173 26.625ZM1.875 29.625C1.04657 29.625 0.375 28.9534 0.375 28.125V27.2366C0.375 23.4967 3.34852 20.4079 7.0864 20.4079H10.5607C12.2097 20.4079 13.7144 21.0131 14.8774 22.0078C16.0107 20.9834 17.462 20.4079 19.2034 20.4079H22.7721C26.5504 20.4079 29.625 23.4586 29.625 27.2366V28.125C29.625 28.9534 28.9534 29.625 28.125 29.625H1.875ZM17.2454 26.625H26.576C26.2817 24.8055 24.6958 23.4079 22.7721 23.4079H19.2034C18.1269 23.4079 17.3185 23.7582 16.6827 24.4351C16.9833 25.1143 17.1778 25.8519 17.2454 26.625Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15.136 0C15.9645 0 16.636 0.671573 16.636 1.5V4.07353H19.3125C20.1409 4.07353 20.8125 4.7451 20.8125 5.57353C20.8125 6.40196 20.1409 7.07353 19.3125 7.07353H16.636V9.75C16.636 10.5784 15.9645 11.25 15.136 11.25C14.3076 11.25 13.636 10.5784 13.636 9.75V7.07353H10.875C10.0466 7.07353 9.375 6.40196 9.375 5.57353C9.375 4.7451 10.0466 4.07353 10.875 4.07353H13.636V1.5C13.636 0.671573 14.3076 0 15.136 0Z'
			fill='#292929'
		/>
	</svg>
);

export const PraiseFeedIcon = () => (
	<svg width='25' height='20' viewBox='0 0 25 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1.27429 6.68002L1.27179 6.66238L1.26805 6.64496C0.905505 4.95767 1.50586 3.26738 2.84798 2.13169L2.86826 2.11453L2.88661 2.09532C3.8597 1.07634 5.33712 0.5 6.68806 0.5C8.14795 0.5 9.48746 1.1827 10.5037 1.93341C11.0073 2.30549 11.4194 2.68539 11.7122 2.98574C11.8585 3.13582 11.9734 3.26433 12.0537 3.35998C12.1091 3.426 12.1389 3.46571 12.1516 3.48264C12.1603 3.49418 12.161 3.49513 12.1564 3.48661L12.5362 4.19351L13.0034 3.54114C13.1087 3.39414 13.7427 2.6211 14.7534 1.88938C15.7629 1.15854 17.1049 0.5 18.6401 0.5C20.1191 0.5 21.4643 1.07206 22.4415 2.09532L22.4599 2.11453L22.4801 2.13169C23.8223 3.26738 24.4226 4.95767 24.0601 6.64496L24.0563 6.66238L24.0538 6.68002C23.7389 8.9079 22.2063 11.4416 20.0999 13.7573C18.0063 16.059 15.4044 18.0808 13.0479 19.3011L12.7098 19.4585L12.2627 19.292C9.91087 18.0708 7.31669 16.0533 5.22821 13.7573C3.12179 11.4416 1.58922 8.9079 1.27429 6.68002Z'
			fill='#E3E6EA'
			stroke='#A1ABB9'
		/>
	</svg>
);
export const OkrIcon = () => (
	<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M5.4172 0.566408C5.14734 0.296549 4.70981 0.296549 4.43995 0.566408L0.984859 4.0215C0.715 4.29136 0.715 4.72889 0.984859 4.99875L4.43995 8.45384C4.70981 8.7237 5.14734 8.7237 5.4172 8.45384C5.68706 8.18398 5.68706 7.74645 5.4172 7.47659L3.14175 5.20114H12.7025C13.0842 5.20114 13.3936 4.89176 13.3936 4.51013C13.3936 4.12849 13.0842 3.81911 12.7025 3.81911H3.14175L5.4172 1.54366C5.68706 1.2738 5.68706 0.836268 5.4172 0.566408Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.79072 8.84722C8.79072 7.54111 9.84507 6.47656 11.1527 6.47656C12.4604 6.47656 13.5147 7.54111 13.5147 8.84722C13.5147 10.1533 12.4604 11.2179 11.1527 11.2179C9.84507 11.2179 8.79072 10.1533 8.79072 8.84722ZM11.1527 7.79488C10.5795 7.79488 10.109 8.26287 10.109 8.84722C10.109 9.43158 10.5795 9.89957 11.1527 9.89957C11.726 9.89957 12.1964 9.43158 12.1964 8.84722C12.1964 8.26287 11.726 7.79488 11.1527 7.79488ZM8.41902 15.0456H13.8855C13.8451 14.7079 13.7174 14.3984 13.5257 14.1392C13.1019 13.5658 12.5274 13.1491 12.0041 13.1491H10.3013C9.39222 13.1491 8.53312 14.0155 8.41902 15.0456ZM7.08789 15.2768C7.08789 13.5694 8.45632 11.8308 10.3013 11.8308H12.0041C13.1618 11.8308 14.0787 12.6695 14.5858 13.3555C14.9828 13.8925 15.2175 14.5582 15.2175 15.2768V15.7048C15.2175 16.0688 14.9224 16.364 14.5584 16.364H7.74705C7.38301 16.364 7.08789 16.0688 7.08789 15.7048V15.2768Z'
			fill='#292929'
		/>
	</svg>
);
export const DirectReportsIcon = () => (
	<svg width='28' height='30' viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M9.35906 4.07293C8.98277 4.43944 8.64983 5.13942 8.64983 6.58572C8.64983 8.0314 8.98267 8.75594 9.37 9.14101C9.75918 9.52792 10.4925 9.8599 11.9492 9.8599C13.4049 9.8599 14.1735 9.52812 14.59 9.12584C14.996 8.73375 15.3409 8.00916 15.3409 6.58572C15.3409 5.16199 14.9961 4.4617 14.6011 4.08794C14.1888 3.69778 13.4198 3.375 11.9492 3.375C10.4773 3.375 9.7438 3.69818 9.35906 4.07293ZM7.26583 1.92388C8.45605 0.764576 10.1222 0.375 11.9492 0.375C13.7773 0.375 15.4542 0.764977 16.6631 1.90888C17.8893 3.06917 18.3409 4.72425 18.3409 6.58572C18.3409 8.44751 17.8894 10.11 16.6742 11.2837C15.4695 12.4472 13.7923 12.8599 11.9492 12.8599C10.107 12.8599 8.44067 12.4474 7.25489 11.2685C6.06726 10.0878 5.64983 8.42527 5.64983 6.58572C5.64983 4.74682 6.06715 3.09143 7.26583 1.92388ZM15.9308 18.332L7.96749 18.332C6.86819 18.332 5.76476 18.9168 4.89149 19.9535C4.01661 20.992 3.495 22.3502 3.495 23.6075V26.0699H9.26865C10.0971 26.0699 10.7687 26.7415 10.7687 27.5699C10.7687 28.3983 10.0971 29.0699 9.26865 29.0699H1.995C1.16657 29.0699 0.494995 28.3983 0.494995 27.5699V23.6075C0.494995 21.5777 1.31037 19.5481 2.5971 18.0207C3.88546 16.4913 5.76828 15.332 7.96749 15.332L15.9308 15.332C16.7593 15.332 17.4308 16.0035 17.4308 16.832C17.4308 17.6604 16.7593 18.332 15.9308 18.332Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M21.0218 16.3819C20.4579 16.8374 20.37 17.6637 20.8254 18.2276L23.4651 21.4957L16.528 21.6095C14.1742 21.6481 12.2862 23.5672 12.2862 25.9214V27.7126C12.2862 28.4375 12.8739 29.0251 13.5987 29.0251C14.3236 29.0251 14.9112 28.4375 14.9112 27.7126V25.9214C14.9112 25.0002 15.65 24.2492 16.5711 24.2341L23.3923 24.1223L20.8254 27.3003C20.37 27.8642 20.4579 28.6906 21.0218 29.146C21.5857 29.6015 22.4121 29.5136 22.8675 28.9497L27.8637 22.764L22.8675 16.5782C22.4121 16.0143 21.5857 15.9264 21.0218 16.3819Z'
			fill='#292929'
		/>
	</svg>
);
export const FeedbackAddressedIcon = () => (
	<svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M16.8412 5.875H4.875C2.80393 5.875 1.125 7.55393 1.125 9.625V29.864C1.125 32.4551 4.4806 33.4794 5.92756 31.3299L8.29005 27.8205C8.42942 27.6135 8.66264 27.4893 8.91221 27.4893H14.2582C15.0867 27.4893 15.7582 26.8177 15.7582 25.9893C15.7582 25.1609 15.0867 24.4893 14.2582 24.4893H8.91221C7.66435 24.4893 6.49824 25.11 5.8014 26.1452L4.125 28.6355V9.625C4.125 9.21079 4.46078 8.875 4.875 8.875H16.6402C16.6136 8.5869 16.6 8.29504 16.6 8.00001C16.6 7.26919 16.6834 6.55786 16.8412 5.875Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M33.2 8C33.2 11.9765 29.9764 15.2 26 15.2C22.0235 15.2 18.8 11.9765 18.8 8C18.8 4.02355 22.0235 0.800003 26 0.800003C29.9764 0.800003 33.2 4.02355 33.2 8ZM26 12C28.2091 12 30 10.2091 30 8C30 5.79086 28.2091 4 26 4C23.7908 4 22 5.79086 22 8C22 10.2091 23.7908 12 26 12Z'
			fill='#292929'
		/>
		<path
			d='M27.8047 18.0003C26.4882 16.5768 24.2143 16.5337 22.8579 17.8925C21.5015 16.5337 19.2375 16.5768 17.911 18.0003C16.5247 19.4993 16.4437 22.1054 17.911 23.4462L22.8579 27.7332L27.8047 23.4462C29.2721 22.1054 29.1911 19.4993 27.8047 18.0003Z'
			fill='#292929'
		/>
	</svg>
);
export const RecognizedIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M15.0247 9.08144L13.8958 11.3349C13.5552 12.0149 12.9195 12.4825 12.1886 12.5909L9.50144 12.9894L11.377 14.9362C11.9063 15.4857 12.1434 16.2691 12.0118 17.034L11.5743 19.5772L14.001 18.3177C14.6569 17.9773 15.4302 17.9836 16.0808 18.3348L18.2911 19.5279L17.8621 17.034C17.7305 16.2691 17.9676 15.4857 18.4969 14.9362L20.3164 13.0475L17.7821 12.5778C17.0829 12.4482 16.4813 11.989 16.1536 11.3349L15.0247 9.08144ZM20.1155 16.6163L20.9933 21.7184C21.1688 22.6295 20.1155 23.1761 19.4134 22.8117L15.0247 20.4429L10.4605 22.8117C9.58281 23.3584 8.70508 22.6295 8.88062 21.7184L9.75835 16.6163L6.24744 12.972C5.7208 12.2431 6.07189 11.1498 6.94962 10.9676L11.8649 10.2387L14.147 5.68331C14.4981 4.77223 15.5514 4.77223 15.9024 5.68331L18.1845 10.2387L23.0998 11.1498C23.9775 11.1498 24.3286 12.4253 23.6265 12.972L20.1155 16.6163Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M15 27.1875C21.6967 27.1875 27.1875 21.6967 27.1875 15C27.1875 8.3033 21.6967 2.8125 15 2.8125C8.3033 2.8125 2.8125 8.3033 2.8125 15C2.8125 21.6967 8.3033 27.1875 15 27.1875ZM30 15C30 23.25 23.25 30 15 30C6.75 30 0 23.25 0 15C0 6.75 6.75 0 15 0C23.25 0 30 6.75 30 15Z'
			fill='#292929'
		/>
	</svg>
);
