import React, { Fragment } from 'react';

interface AnimatedIconProps {
	type: string;
	imagePath: any;
	className?: string;
	width?: number | string;
	alt?: string;
}

export const AnimatedIcon: React.FC<AnimatedIconProps> = (props) => {
	const { className, width, type, imagePath, alt } = props;

	return (
		<Fragment>
			<object className={className || ''} width={width || '250'} type={type} data={imagePath}>
				<img src={imagePath} alt={alt || ''} />
			</object>
		</Fragment>
	);
};
