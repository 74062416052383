import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Collapse, Typography } from '@material-ui/core';
import Select, { components, StylesConfig } from 'react-select';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import { OKRButton } from '../../Common/OKRButton';
export const OkrLibrary: React.FC<any> = (props) => {
	const { t, okrKrSelectionData, setOkrKrSelectionData } = props;
	const [optionsList, setOptionsList] = useState<any[]>([]);
	const [filteredList, setFilteredList] = useState<any[]>([]);
	const [dropdownSelection, setDropdownSelection] = useState({ value: '-1', label: t('allokrs') });

	const [expandedList, setExpandedList] = useState<any[]>([]);
	const [expandedKrList, setExpandedKrList] = useState<any[]>([]);
	const [expanded, setExpanded] = useState<string | false>(false);

	const handlePanelChange = (panel: any) => (event: any, isExpanded: boolean) => {
		event.stopPropagation();
		setExpanded(isExpanded ? panel : false);
	};

	const handleManageDepartment = (e: any, departmentId: number) => {
		let list = Object.assign([], expandedList);
		const elementIndex = list.indexOf(departmentId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(departmentId);
		}
		setExpandedList(list);
	};
	const handleExpandKeyResult = (okrId: any) => {
		let list = Object.assign([], expandedKrList);
		const elementIndex = list.indexOf(okrId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(okrId);
		}
		setExpandedKrList(list);
	};
	useEffect(() => {
		props.getOkrImport();
		props.getOkrImportCategory();
		setOkrKrSelectionData({});
	}, []);

	useEffect(() => {
		if (props.okrImportCategoryDataStatus === 'success') {
			let options: any = [{ value: '-1', label: t('allokrs') }];
			props.okrImportCategoryData?.forEach((cateogry: any) => {
				options.push({ value: cateogry.importLibraryCategoryId, label: cateogry.categoryName });
			});
			setOptionsList(options);
		}
	}, [props.okrImportCategoryDataStatus]);

	useEffect(() => {
		if (props.okrImportDataStatus === 'success') {
			const list: any = Object.assign([], props.okrImportData?.importLibraryCategoryResponse);
			setFilteredList(list);
		}
	}, [props.okrImportDataStatus]);

	const handleChange = (e: any) => {
		const { value } = e;
		setDropdownSelection(e);
		let filteredData: any = props.okrImportData?.importLibraryCategoryResponse;
		if (value !== '-1') {
			filteredData = props.okrImportData?.importLibraryCategoryResponse?.filter(
				(cateogry: any) => cateogry.importLibraryCategoryId === value
			);
			handleManageDepartment({}, value);
		} else {
			setExpandedList([]);
		}
		setFilteredList(filteredData);
	};

	const parentSelected = (item: any, e: any) => {
		const okrKey = item.importLibraryOkrId;
		let isChecked: boolean = e.target.checked;
		let okrKrSelectionL: any[] = Object.assign({}, okrKrSelectionData);
		if (isChecked === true) {
			okrKrSelectionL[okrKey] = {
				okrName: item.okrName,
				krList: {},
			};
			item.importLibrarykrResponse.forEach((child: any) => {
				okrKrSelectionL[okrKey].krList[child.importLibraryKrId] = {
					krName: child.krName,
					krMetric: child.metricId,
					krCurrencyId: child.currencyId,
					krCurrencyCode: child.currencyCode,
				};
			});
		} else {
			if (okrKrSelectionL[okrKey] !== undefined) {
				delete okrKrSelectionL[okrKey];
			}
		}
		setOkrKrSelectionData(okrKrSelectionL);
	};

	const childSelected = (krData: any, okrData: any, e: any) => {
		const krKey = krData.importLibraryKrId;
		const okrKey = okrData.importLibraryOkrId;
		let isChecked: boolean = e.target.checked;
		let okrKrSelectionL: any[] = Object.assign({}, okrKrSelectionData);
		if (isChecked === true) {
			if (okrKrSelectionL[okrKey] === undefined) {
				okrKrSelectionL[okrKey] = {
					okrName: okrData.okrName,
					krList: {},
				};
			}
			okrKrSelectionL[okrKey].krList[krKey] = {
				krName: krData.krName,
				krMetric: krData.metricId,
				krCurrencyId: krData.currencyId,
				krCurrencyCode: krData.currencyCode,
			};
		} else {
			if (okrKrSelectionL[okrKey] && okrKrSelectionL[okrKey].krList[krKey]) {
				delete okrKrSelectionL[okrKey].krList[krKey];
				if (Object.keys(okrKrSelectionL[okrKey].krList).length === 0) {
					delete okrKrSelectionL[okrKey];
				}
			}
		}
		setOkrKrSelectionData(okrKrSelectionL);
	};

	const handleMainSelection = (mainData: any, e: any) => {
		e.stopPropagation();
		let isChecked: boolean = e.target.checked;
		let okrKrSelectionL: any[] = Object.assign({}, okrKrSelectionData);
		mainData.importLibraryOkrResponse.forEach((item: any) => {
			let okrKey = item.importLibraryOkrId;
			if (isChecked === true) {
				okrKrSelectionL[okrKey] = {
					okrName: item.okrName,
					krList: {},
				};
				item.importLibrarykrResponse.forEach((child: any) => {
					okrKrSelectionL[okrKey].krList[child.importLibraryKrId] = {
						krName: child.krName,
						krMetric: child.metricId,
						krCurrencyId: child.currencyId,
						krCurrencyCode: child.currencyCode,
					};
				});
			} else {
				if (okrKrSelectionL[okrKey] !== undefined) {
					delete okrKrSelectionL[okrKey];
				}
			}
		});
		setOkrKrSelectionData(okrKrSelectionL);
	};
	const isMainChecked = (mainData: any) => {
		//0 => no, 1 => checked, 2 => intermediate
		let selection = 0;
		let counter = 0;
		mainData.importLibraryOkrResponse.forEach((item: any) => {
			let okrKey = item.importLibraryOkrId;
			if (okrKrSelectionData[okrKey]) {
				counter++;
				if (Object.keys(okrKrSelectionData[okrKey].krList).length !== item.importLibrarykrResponse.length) {
					counter--;
				}
			}
		});
		if (counter === mainData.importLibraryOkrResponse.length) {
			selection = 1;
		} else if (counter > 0 && counter < mainData.importLibraryOkrResponse.length) {
			selection = 2;
		}

		return selection;
	};
	return (
		<>
			<Box className='drawer-form-fields'>
				<Box className='drawer-form-fields-group'>
					<Box className='drawer-input-field'>
						<Box className='field-col1'>
							<Box className={`width-100 `}>
								<Select
									value={dropdownSelection}
									options={optionsList}
									onChange={(e) => handleChange(e)}
									className='okr-select-dropdown'
									classNamePrefix='okr-select'
									isClearable={false}
									isSearchable={false}
								/>
							</Box>
						</Box>
					</Box>
					<Box className='drawer-input-field'>
						{props.okrImportDataStatus !== 'pending' ? (
							<Box className='department-collapsible'>
								{filteredList.map((libraryData: any) => (
									<Box
										className={`department-collapsible-body ${
											expandedList.indexOf(libraryData.importLibraryCategoryId) !== -1 ? 'expand-row' : ''
										}`}
									>
										<Box className='department-collapsible-head'>
											<Box className='department-collapsible-head-content'>
												<Box
													className={`rotating-arrow ${
														expandedList.indexOf(libraryData.importLibraryCategoryId) !== -1 ? 'expand' : 'collapse'
													}`}
													onClick={(e) => handleManageDepartment(e, libraryData.importLibraryCategoryId)}
												>
													<RightArrowIcon />
													<Typography variant='body1'>
														<strong>{libraryData.name}</strong>
													</Typography>
												</Box>
											</Box>
										</Box>
										<Collapse
											className='department-collapsible-content'
											in={expandedList.indexOf(libraryData.importLibraryCategoryId) !== -1}
											timeout='auto'
											unmountOnExit
										>
											<>
												{/* {libraryData.importLibrarySubcategoryResponse.map((librarySubData: any) => (
													<Box className='department-content okr-library-department'>
														<Box className='department-title'>
															<Typography variant='h6'>
																<Checkbox
																	checkedIcon={<CheckedIcon />}
																	aria-label='OKR Checkbox'
																	indeterminateIcon={<UnCheckedIcon />}
																	icon={<BorderCheckboxIcon />}
																	onChange={(e) => {
																		handleMainSelection(librarySubData, e);
																	}}
																	checked={isMainChecked(librarySubData) === 0 ? false : true}
																	indeterminate={isMainChecked(librarySubData) === 2 ? true : false}
																/>
																<Typography className='department-name' component={'span'}>
																	{librarySubData.name}
																</Typography>
															</Typography>
														</Box>
														{librarySubData.importLibraryOkrResponse.map((libraryOkrData: any) => (
															<Box
																className={`import-okr-list ${
																	expandedKrList.indexOf(libraryOkrData.importLibraryOkrId) !== -1 ? 'expanded-okr' : ''
																}`}
															>
																<Box className='import-obj-row'>
																	<Box className='import-obj-col'>
																		<Checkbox
																			checkedIcon={<CheckedIcon />}
																			aria-label='OKR Checkbox'
																			indeterminateIcon={<UnCheckedIcon />}
																			icon={<BorderCheckboxIcon />}
																			onChange={(e) => {
																				parentSelected(libraryOkrData, e);
																			}}
																			checked={
																				okrKrSelectionData[libraryOkrData.importLibraryOkrId] !== undefined
																					? true
																					: false
																			}
																			indeterminate={
																				okrKrSelectionData &&
																				okrKrSelectionData[libraryOkrData.importLibraryOkrId] &&
																				okrKrSelectionData[libraryOkrData.importLibraryOkrId].krList?.length !== 0 &&
																				Object.keys(okrKrSelectionData[libraryOkrData.importLibraryOkrId].krList)
																					.length !== libraryOkrData.importLibrarykrResponse.length
																					? true
																					: false
																			}
																		/>
																		<Typography>{libraryOkrData.okrName}</Typography>
																	</Box>
																	<Box className='import-obj-progress-col'></Box>
																</Box>
																<Box className='import-okr-actions'>
																	<OKRButton
																		className={`btn-blue-chip ${
																			expandedKrList.indexOf(libraryOkrData.importLibraryOkrId) !== -1
																				? 'expand'
																				: 'collapse'
																		}`}
																		icon={<RightArrowIcon />}
																		text={`${libraryOkrData.importLibrarykrResponse.length} Key Results`}
																		handleClick={(e) => handleExpandKeyResult(libraryOkrData.importLibraryOkrId)}
																	/>
																</Box>
																<Collapse
																	className='department-kr-collapsible-content'
																	in={expandedKrList.indexOf(libraryOkrData.importLibraryOkrId) !== -1}
																	timeout='auto'
																	unmountOnExit
																>
																	{libraryOkrData.importLibrarykrResponse.map((libraryKrData: any) => (
																		<Box className='import-kr-list'>
																			<Checkbox
																				checkedIcon={<CheckedIcon />}
																				aria-label='KR Checkbox'
																				icon={<BorderCheckboxIcon />}
																				onChange={(e) => {
																					childSelected(libraryKrData, libraryOkrData, e);
																				}}
																				checked={
																					okrKrSelectionData[libraryOkrData.importLibraryOkrId] &&
																					okrKrSelectionData[libraryOkrData.importLibraryOkrId].krList &&
																					okrKrSelectionData[libraryOkrData.importLibraryOkrId].krList[
																						libraryKrData.importLibraryKrId
																					] !== undefined
																						? true
																						: false
																				}
																			/>
																			<Box className='import-kr-content'>
																				<Box className='import-kr-row'>
																					<Box className='import-kr-col'>
																						<Typography>{libraryKrData.krName}</Typography>
																					</Box>
																					<Box className='import-kr-progress-col'></Box>
																				</Box>
																			</Box>
																		</Box>
																	))}
																</Collapse>
															</Box>
														))}
													</Box>
												))} */}
												{libraryData.importLibrarySubcategoryResponse.map((librarySubData: any, index: number) => (
													<Box className='department-content okr-library-department'>
														<Checkbox
															className='department-checkbox'
															checkedIcon={<CheckedIcon />}
															aria-label='OKR Checkbox'
															indeterminateIcon={<UnCheckedIcon />}
															icon={<BorderCheckboxIcon />}
															onChange={(e) => {
																handleMainSelection(librarySubData, e);
															}}
															checked={isMainChecked(librarySubData) === 0 ? false : true}
															indeterminate={isMainChecked(librarySubData) === 2 ? true : false}
														/>
														<Accordion
															expanded={expanded === `panel${index + '_' + librarySubData.importLibrarySubCategoryId}`}
															onChange={handlePanelChange(
																`panel${index + '_' + librarySubData.importLibrarySubCategoryId}`
															)}
														>
															<AccordionSummary
																expandIcon={<RightArrowIcon />}
																aria-controls='panel1bh-content'
																id='panel1bh-header'
															>
																<Box className='department-title'>
																	<Typography variant='h6'>
																		<Typography className='department-name' component={'span'}>
																			{librarySubData.name}
																		</Typography>
																	</Typography>
																</Box>
															</AccordionSummary>
															<AccordionDetails>
																<Box className='import-okr-list-outer'>
																	{librarySubData.importLibraryOkrResponse.map((libraryOkrData: any) => (
																		<Box
																			className={`import-okr-list ${
																				expandedKrList.indexOf(libraryOkrData.importLibraryOkrId) !== -1
																					? 'expanded-okr'
																					: ''
																			}`}
																		>
																			<Box className='import-obj-row'>
																				<Box className='import-obj-col'>
																					<Checkbox
																						checkedIcon={<CheckedIcon />}
																						aria-label='OKR Checkbox'
																						indeterminateIcon={<UnCheckedIcon />}
																						icon={<BorderCheckboxIcon />}
																						onChange={(e) => {
																							parentSelected(libraryOkrData, e);
																						}}
																						checked={
																							okrKrSelectionData[libraryOkrData.importLibraryOkrId] !== undefined
																								? true
																								: false
																						}
																						indeterminate={
																							okrKrSelectionData &&
																							okrKrSelectionData[libraryOkrData.importLibraryOkrId] &&
																							okrKrSelectionData[libraryOkrData.importLibraryOkrId].krList?.length !==
																								0 &&
																							Object.keys(okrKrSelectionData[libraryOkrData.importLibraryOkrId].krList)
																								.length !== libraryOkrData.importLibrarykrResponse.length
																								? true
																								: false
																						}
																					/>
																					<Typography>{libraryOkrData.okrName}</Typography>
																				</Box>
																				<Box className='import-obj-progress-col'></Box>
																			</Box>
																			<Box className='import-okr-actions'>
																				<OKRButton
																					className={`btn-blue-chip ${
																						expandedKrList.indexOf(libraryOkrData.importLibraryOkrId) !== -1
																							? 'expand'
																							: 'collapse'
																					}`}
																					icon={<RightArrowIcon />}
																					text={`${libraryOkrData.importLibrarykrResponse.length} Key Results`}
																					handleClick={(e) => handleExpandKeyResult(libraryOkrData.importLibraryOkrId)}
																				/>
																			</Box>
																			<Collapse
																				className='department-kr-collapsible-content'
																				in={expandedKrList.indexOf(libraryOkrData.importLibraryOkrId) !== -1}
																				timeout='auto'
																				unmountOnExit
																			>
																				{libraryOkrData.importLibrarykrResponse.map((libraryKrData: any) => (
																					<Box className='import-kr-list'>
																						<Checkbox
																							checkedIcon={<CheckedIcon />}
																							aria-label='KR Checkbox'
																							icon={<BorderCheckboxIcon />}
																							onChange={(e) => {
																								childSelected(libraryKrData, libraryOkrData, e);
																							}}
																							checked={
																								okrKrSelectionData[libraryOkrData.importLibraryOkrId] &&
																								okrKrSelectionData[libraryOkrData.importLibraryOkrId].krList &&
																								okrKrSelectionData[libraryOkrData.importLibraryOkrId].krList[
																									libraryKrData.importLibraryKrId
																								] !== undefined
																									? true
																									: false
																							}
																						/>
																						<Box className='import-kr-content'>
																							<Box className='import-kr-row'>
																								<Box className='import-kr-col'>
																									<Typography>{libraryKrData.krName}</Typography>
																								</Box>
																								<Box className='import-kr-progress-col'></Box>
																							</Box>
																						</Box>
																					</Box>
																				))}
																			</Collapse>
																		</Box>
																	))}
																</Box>
															</AccordionDetails>
														</Accordion>
													</Box>
												))}
											</>
										</Collapse>
									</Box>
								))}
							</Box>
						) : (
							<Box className='loading-center'>
								<Typography>{t('loading')}</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
